import { type ThreatDefenceSendingProfileOverview } from "../features/Library/SimulatedPhishing/SendingProfiles/types/state";

const inherited: string = "Inherited";
const isDirectSend: (sendingProfile: ThreatDefenceSendingProfileOverview) => boolean = (
  sendingProfile: ThreatDefenceSendingProfileOverview,
) => sendingProfile.sendingMethod === "Direct Send";

const utils = {
  getSenderName(sendingProfile: ThreatDefenceSendingProfileOverview) {
    return !isDirectSend(sendingProfile) ? sendingProfile.senderName : inherited;
  },
  getSenderEmail(sendingProfile: ThreatDefenceSendingProfileOverview) {
    return !isDirectSend(sendingProfile) ? sendingProfile.senderEmail : inherited;
  },
};

export default utils;
