import React from "react";

interface ContentContextState {
  branchingQuestionId?: string;
  toggleBranchingQuestion: (index: string) => void;
  turnOffBranchingQuestion: (index: string) => void;
}

export const ContentContext = React.createContext<ContentContextState | undefined>(undefined);

export const useContentContext = () => {
  const context = React.useContext(ContentContext);

  if (!context) {
    throw new Error("ContentContext has not been defined");
  }

  return context;
};
