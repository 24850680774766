import { noop } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { ContentTypesEnum, TemplateTypes } from "../../../enums";

import { NotifyStepConfig } from "../../../features/SystemNotifications/types";
import Observable from "../../../utils/Observable";
import modalUtilsFactory from "../../../utils/modalUtilsFactory";
import DeleteContentConfirmationModal from "../../deleteContentConfirmationModal/DeleteContentConfirmationModal";
import { SwitchEntityTypes } from "enums/switchEntityTypes";
import ModalTypes from "../ModalTypes";
import PublishConfirmationModal from "../PublishConfirmationModal/PublishConfirmationModal";
import RevertConfirmationModal from "../RevertConfirmationModal";

export default class AssetModals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [ModalTypes.PublishVideoAsset]: false,
      [ModalTypes.RevertVideoAsset]: false,
    };

    this.revertModal = modalUtilsFactory();
    this.onTriggerVideoRemovalObserver = new Observable();
    this.onTriggerPublishContentObserver = new Observable();
  }

  componentDidMount() {
    this.props.acceptHandlers({
      publish: this.publish,
      revert: this.revert,
      delete: this.delete,
    });
  }

  publish = (id, flowsCount, assignmentsCount, hasBeenPublished) => {
    const entityState = {
      itemsCount: flowsCount,
      entityId: id,
      assignmentsCount: assignmentsCount,
      templateType: hasBeenPublished ? TemplateTypes.VideoChanged : TemplateTypes.AddedToVideo,
      notifyConfigType: hasBeenPublished ? NotifyStepConfig.WithProgress : NotifyStepConfig.Default,
      entityType: SwitchEntityTypes.Asset,
    };

    this.triggerPublish(Boolean(entityState.assignmentsCount > 0), entityState);
  };

  triggerPublish = (condition, entityState) => {
    return condition
      ? this.onTriggerPublishContentObserver.notify(noop, entityState)
      : this.handlePublish(entityState.entityId);
  };

  handlePublish = (id, notificationSettings, notifyTypes) => {
    this.props.videoEntityStateActions.publishVideoAsset(id, notificationSettings, notifyTypes);
  };

  revert = (videoInfo) => {
    if (!videoInfo.isDraft || !videoInfo.hasBeenPublished) {
      return;
    }
    this.revertModal.execute(videoInfo.id, this.handleRevert, () => {
      this.toggleModal(ModalTypes.RevertVideoAsset, true);
    });
  };

  handleRevert = (assetId) => {
    this.props.backgroundTasksActions.deleteTask(assetId, true);
    this.toggleModal(ModalTypes.RevertVideoAsset, false);
    this.props.videoEntityStateActions.revertVideoAsset(assetId);
  };

  delete = (videoAssetIds, flowsCount, packsCount) => {
    this.onTriggerVideoRemovalObserver.notify(() => this.handleDelete(videoAssetIds), {
      selectedItemsCount: videoAssetIds.length,
      flowsCount,
      packsCount,
    });
  };

  handleDelete = async (videoAssetIds) => {
    await this.props.deleteVideosAction(videoAssetIds, this.props.onDeleted);

    videoAssetIds.forEach((id) => {
      this.props.backgroundTasksActions.deleteTask(id);
    });
  };

  toggleModal = (modal, isVisible) => {
    this.setState({ [modal]: isVisible });
  };

  render() {
    const { isRevertVisible } = this.props;
    return (
      <div>
        <PublishConfirmationModal
          onTriggerPublishContentObserver={this.onTriggerPublishContentObserver}
          onConfirmAction={this.handlePublish}
          contentType="asset"
          dependencyType="flow"
        />
        <DeleteContentConfirmationModal
          contentType={ContentTypesEnum.Videos}
          onTriggerRemoveContentObserver={this.onTriggerVideoRemovalObserver}
        />
        {isRevertVisible && (
          <RevertConfirmationModal
            modalUtils={this.revertModal}
            modalType={ModalTypes.RevertVideoAsset}
            open={this.state[ModalTypes.RevertVideoAsset]}
            onClose={() => this.toggleModal(ModalTypes.RevertVideoAsset, false)}
            isVisible={isRevertVisible}
          />
        )}
      </div>
    );
  }
}

AssetModals.defaultProps = {
  isRevertVisible: false,
};

AssetModals.propTypes = {
  videoEntityStateActions: PropTypes.object,
  acceptHandlers: PropTypes.func,
  isRevertVisible: PropTypes.bool,
  onDeleted: PropTypes.func,
  backgroundTasksActions: PropTypes.object,
  notificationsActions: PropTypes.object,
};
