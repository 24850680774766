import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import userListUtils from "../../../../../../utils/userListUtils";
import GroupUsersList from "../GroupUsersList/GroupUsersList";

export default class ManuallySelect extends Component {
  loadPage = (skip, top, sortingColumnName, sortingDirection, filter) => {
    const orderParams = !isEmpty(sortingColumnName)
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : null;
    this.props.loadUsers(skip, top, orderParams, filter, this.props.search);
  };

  render() {
    const {
      setReloadListItems,
      usersList,
      setUserFilter,
      resetUserFilter,
      selectedItemIds,
      updateSelectedItems,
      usersFilterOptions,
      usersAppliedFilter,
      getFilterOptions,
      search,
      onSearchChanged,
    } = this.props;

    return (
      <GroupUsersList
        isAutoEnrollment={false}
        isGroupCreation
        users={usersList.items}
        usersCount={usersList.itemsCount}
        isLoading={usersList.isLoading}
        withSelection
        loadPage={this.loadPage}
        renderHeaderSection
        setUserFilter={setUserFilter}
        resetUserFilter={resetUserFilter}
        appliedUserFilter={usersAppliedFilter}
        selectedItemIds={selectedItemIds}
        updateSelectedItems={updateSelectedItems}
        filterOptions={usersFilterOptions}
        getFilterOptions={getFilterOptions}
        setReloadListItems={setReloadListItems}
        search={search}
        onSearchChanged={onSearchChanged}
        hideUserRemoveColumn
      />
    );
  }
}

ManuallySelect.propTypes = {
  usersList: PropTypes.shape({
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  usersFilterOptions: PropTypes.object,
  usersAppliedFilter: PropTypes.object,
  selectedItemIds: PropTypes.array,
  loadUsers: PropTypes.func.isRequired,
  setUserFilter: PropTypes.func.isRequired,
  resetUserFilter: PropTypes.func.isRequired,
  updateSelectedItems: PropTypes.func,
  getFilterOptions: PropTypes.func,
  search: PropTypes.string,
  onSearchChanged: PropTypes.func,
  setReloadListItems: PropTypes.func,
};
