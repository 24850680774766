import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import dataService from "../services/videoDataService";
import validationSchemas from "../../../../utils/validationSchemas";
import TaskStatuses from "../../../../enums/taskStatuses";

import { ValidatedForm } from "../../../../components";
import { Progress } from "../../../../components/progress";

import "./videoForm.scss";

const descriptionName = "description";

class VideoForm extends Component {
  constructor(props) {
    super(props);
    this.hasInitialDescription = !!props.values.description;
  }

  componentDidMount() {
    this.props.acceptHandlers?.({});
  }

  componentWillUnmount() {
    this.props.resetClosedCaptions();
  }

  isDraftCreated = () => this.props.entityId > 0;

  isAmsJobsProgressVisible = (videoInfo) => {
    const isDraft = this.props.isCreateAction ? this.isDraftCreated() : videoInfo.isDraft;
    return isDraft && !this.props.canBePublished;
  };

  isAmsJobsFailed = () => {
    const { isEncodingFailed, isThumbnailGenerationFailed } = this.props.amsJobsStatus;
    return isEncodingFailed || (isThumbnailGenerationFailed && !this.props.thumbnailUrl);
  };

  isCurrentTaskFailed = () => {
    const amsJobBoolState = this.isAmsJobsFailed();
    const currentTask = this.props.tasks[this.props.videoInfo.id];
    return currentTask ? amsJobBoolState && TaskStatuses.isFailed(currentTask.status) : amsJobBoolState;
  };

  renderAmsJobsStatus = (isVideoProcessing) => {
    if (this.isCurrentTaskFailed()) {
      return <div className="processing-error-message">Video processing failed!</div>;
    }
    if (isVideoProcessing) {
      return <Progress label="Processing..." indeterminate className="encoding-progress" />;
    }
    return null;
  };

  getUploadingError = (task) => (TaskStatuses.isFailed(task.status) ? task.errorLabel : null);

  renderUploadFileField = (isCreateAction, validatedFieldProps, uploadData) => {
    const { task } = this.props;
    const isVideoProcessing = this.isAmsJobsProgressVisible(uploadData.values);

    return isCreateAction ? (
      <>
        <ValidatedForm.UploadFileField
          id="fileUpload"
          label="Video"
          loadingButtonLabel="Loading..."
          onChangeHandler={uploadData.handleVideoFileChange}
          accept="video/*"
          propertyName="uploadedVideos"
          value={uploadData.values.uploadedVideos || [{ name: uploadData.values.fileName }]}
          disabled={uploadData.disabled || isVideoProcessing}
          filesValidator={validationSchemas.videoInfo.fields.uploadedVideos}
          uploadFilesHandler={uploadData.uploadFilesHandler}
          uploadingError={this.getUploadingError(task)}
          isUploading={task.isAssetVideoUploading}
          uploadingProgress={task.percent}
          onCancelFileUploading={uploadData.onCancelFileUploading}
          markAsRequired
          showPercents={false}
          canCancel={false}
          {...validatedFieldProps}
        />
        {this.renderAmsJobsStatus(isVideoProcessing)}
      </>
    ) : null;
  };

  getSasToken = async () => {
    const { entityId } = this.props;
    const response = await dataService.generateSas(entityId);
    return response.data;
  };

  onTitleBlur = (propertyName) => {
    const { values, setFieldValue, onBlur, isCreateAction, touched } = this.props;
    if (isCreateAction && !touched[descriptionName] && !this.hasInitialDescription) {
      setFieldValue(descriptionName, values.title, true);
    }

    onBlur(propertyName);
  };

  onThumbnailClick = () => {
    this.props.onCroppedThumbnailClick();
    this.props.fetchClosedCaptions(this.props.entityId);
  };

  renderInputs = () => {
    const {
      isCreateAction,
      values,
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
      disabled,
      onBlur,

      generateThumbnail,
      generateThumbnailFromPosition,
      entityId,
      assetManifests,
      isProcessing,
      videosActions,
      uploadFilesHandler,
      uploadingStatus,
      onCancelFileUploading,
      handleVideoFileChange,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      isValid,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
      handleChange,
    };

    const uploadData = {
      values,
      uploadFilesHandler,
      uploadingStatus,
      onCancelFileUploading,
      handleVideoFileChange,
      disabled,
    };

    const isReadOnly = disabled || !entityId;

    return (
      <div className="asset-form-container">
        <div className="upload-block">
          {this.renderUploadFileField(isCreateAction, validatedFieldProps, uploadData)}
          <div className={this.isDraftCreated() ? "display-block" : "hidden"}>
            {this.renderUploadFileField(!isCreateAction, validatedFieldProps, uploadData)}

            <ValidatedForm.InputField
              label="Title"
              propertyName="title"
              placeholder="Title"
              value={values.title}
              markAsRequired
              disabled={isReadOnly}
              onBlur={this.onTitleBlur}
              {...validatedFieldProps}
            />
            <ValidatedForm.TextAreaField
              label="Description"
              value={values.description}
              propertyName={descriptionName}
              placeholder=""
              hidden
              onBlur={onBlur}
              markAsRequired
              disabled={isReadOnly}
              {...validatedFieldProps}
            />
          </div>
        </div>
        <div className={cn("thumbnail-block", this.isDraftCreated() ? "display-block" : "hidden")}>
          <ValidatedForm.ThumbnailPreview
            label="Image"
            value={this.props.thumbnailUrl}
            propertyName="thumbnailUrl"
            sasLinkProviderAsync={this.getSasToken}
            onCroppedThumbnailClick={this.onThumbnailClick}
            assetManifests={assetManifests}
            isProcessing={isProcessing}
            className="field"
            disabled={isReadOnly}
            entityId={Number.parseInt(entityId)}
            {...validatedFieldProps}
            videosActions={videosActions}
            generateThumbnail={generateThumbnail}
            generateThumbnailFromPosition={generateThumbnailFromPosition}
            textTracks={this.props.textTracks}
            areTextTracksLoading={this.props.areTextTracksLoading}
          />
        </div>
      </div>
    );
  };

  render() {
    const { dirty, isLoading } = this.props;

    return (
      <div className="stretch scrollable-content edit-form">
        <ValidatedForm
          title="Information"
          id="asset-configuration-form"
          dirty={dirty}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving this asset? All information entered will be lost.",
          }}
          formWidthComputer={12}
          disablePreventTransitionPrompt
          isLoading={isLoading}
        >
          {this.renderInputs()}
        </ValidatedForm>
      </div>
    );
  }
}

VideoForm.defaultProps = {
  disabled: false,
  uploadingStatus: {
    isAssetVideoUploading: false,
    isAssetVideoUploaded: false,
    progress: 0,
    error: null,
    isAborted: false,
  },
  task: {
    progress: 0,
    isAssetVideoUploading: false,
    error: null,
  },
};

VideoForm.propTypes = {
  acceptHandlers: PropTypes.func,
  handleVideoFileChange: PropTypes.func.isRequired,
  uploadFilesHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  uploadingStatus: PropTypes.object,
  onCancelFileUploading: PropTypes.func,
  onBlur: PropTypes.func,
  onCroppedThumbnailClick: PropTypes.func,
  generateThumbnailFromPosition: PropTypes.func,
  disabled: PropTypes.bool,
  generateThumbnail: PropTypes.func,
  disablePreventTransitionPrompt: PropTypes.bool,
  isCreateAction: PropTypes.bool,
  amsJobsStatus: PropTypes.shape({
    isEncodingFailed: PropTypes.bool,
    isThumbnailGenerationFailed: PropTypes.bool,
  }),
  task: PropTypes.shape({
    progress: PropTypes.number,
    isAssetVideoUploading: PropTypes.bool,
    error: PropTypes.string,
  }),
  assetManifests: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  textTracks: PropTypes.array,
  areTextTracksLoading: PropTypes.bool,
  fetchClosedCaptions: PropTypes.func,
  resetClosedCaptions: PropTypes.func,
};

export default VideoForm;
