import * as events from "../events/library/libraryEvents";
import {
  discardCampaignSuccess,
  fetchLockedTdCampaignSuccess,
  publishDraftThreatDefenceCampaignSuccess,
  threatDefenceCampaignUpdated,
  updateThreatDefenceCampaignCommandSuccess,
} from "../../../../Library/SimulatedPhishing/Campaigns/state/actions/threatDefenceCampaignsEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const campaignMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.CampaignEditSuccess] = dispatch(threatDefenceCampaignUpdated);
  mapping[events.CampaignContentEditSuccess] = dispatch(threatDefenceCampaignUpdated);
  mapping[events.CampaignUpdateCommandCompleted] = dispatch(updateThreatDefenceCampaignCommandSuccess);

  mapping[events.CampaignPublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftThreatDefenceCampaignSuccess,
    (data) => `${data.name} was published.`,
  );

  mapping[events.CampaignDiscardSuccess] = dispatch(discardCampaignSuccess);

  mapping[events.CampaignLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedTdCampaignSuccess,
    (message) => ({ entityId: message.id }),
  );
};
