import cn from "classnames";

import { Tooltip } from "../../../common/tooltip";
import styles from "./packArchivedLabel.module.scss";
import labelStyles from "../packLabel.module.scss";

export interface PackArchivedLabelProps {
  isArchived?: boolean;
  className?: string;
}

export const PackArchivedLabel: React.FC<PackArchivedLabelProps> = ({ isArchived, className }) => {
  if (isArchived) {
    const style = { whiteSpace: "pre-line" };

    return (
      <Tooltip
        showAlways
        position="top center"
        target={<div className={cn(styles.archived, labelStyles.label, className)}>Archived</div>}
        content={`This Pack has been archived.\nClick Restore to use again.`}
        styles={style}
      />
    );
  }
  return null;
};
