import { type MessageContent, type MessageDetails as MessageDetailsForCreate } from ".";
import { type AutosaveEntityProps } from "../../../../../utils";
import { type AssignedUser } from "../../../../../interfaces";
import { type MessageDetails } from "./models";
import { type EntityStateProps, type PurchasableContent } from "../../../../../types";

export interface MessageOverview {
  id: number;
  isDraft: boolean;
  title: string;
  dateCreated: string;
  dateModified: string;
  isPurchased: boolean;
  canBeDeleted: boolean;
  dependencies: OverviewDependencies;
}

interface OverviewDependencies {
  flowsCount: number;
  packsCount: number;
  isEmpty: boolean;
}

export interface MessageDetailsState extends AutosaveEntityProps, EntityStateProps, PurchasableContent {
  message: MessageDetailsForCreate;
  messageContent: MessageContent;
  isLoading: boolean;
  error?: Error | null;
}
export interface AssignedUsersStateType {
  items: AssignedUser[];
  isLoading: boolean;
  itemsCount: number;
  addedUsersCount: number;
  error?: Error | null;
}

export interface MessageDetailsStateForSendSession {
  message: MessageDetails;
  isLoaded: boolean;
  isLoading: boolean;
  error?: Error;
}

export interface PublishedMessageOverview {
  id: number;
  title: string;
  headline: string;
  body: string;
  isDraft: boolean;
  isPurchased: boolean;
  publisherId: number;
  publisher: string;
  dateCreated: string;
  dateModified: string;
}

export enum MessageFilterPropertyNames {
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}
