import * as actionTypes from "../actionTypes/threatDefenceFeedbackPagesOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import { type ThreatDefenceFeedbackPagesOverviewState, type ThreatDefenceFeedbackPageOverview } from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../../interfaces/redux";

const initialState: ThreatDefenceFeedbackPagesOverviewState = {
  feedbackPages: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const threatDefenceFeedbackPagesOverviewHandlers = () => {
  const {
    getThreatDefenceFeedbackPagesBegin: getFeedbackPagesBegin,
    getThreatDefenceFeedbackPagesSuccess: getFeedbackPagesSuccess,
    getThreatDefenceFeedbackPagesFailure: getFeedbackPagesFailure,
  } = actionTypes;

  const getFeedbackPagesBeginHandler = (
    state: ThreatDefenceFeedbackPagesOverviewState,
  ): ThreatDefenceFeedbackPagesOverviewState => ({
    ...state,
    feedbackPages: new Array<ThreatDefenceFeedbackPageOverview>(),
    isLoading: true,
  });

  const getFeedbackPagesSuccessHandler = (
    state: ThreatDefenceFeedbackPagesOverviewState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceFeedbackPageOverview>>,
  ): ThreatDefenceFeedbackPagesOverviewState => ({
    ...state,
    feedbackPages: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getFeedbackPagesFailureHandler = (
    state: ThreatDefenceFeedbackPagesOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceFeedbackPagesOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getFeedbackPagesBegin]: getFeedbackPagesBeginHandler,
    [getFeedbackPagesSuccess]: getFeedbackPagesSuccessHandler,
    [getFeedbackPagesFailure]: getFeedbackPagesFailureHandler,
  };
};

export const threatDefenceFeedbackPagesOverview = createReducer(initialState, [
  threatDefenceFeedbackPagesOverviewHandlers,
]);
