import React, { type ReactElement, type SyntheticEvent, useEffect, useMemo } from "react";
import { type DropdownProps } from "semantic-ui-react";
import cn from "classnames";

import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { type EntityType, type ItemsToDropBase } from "../../../types";
import { type RolePermissions, SortingDirection } from "../../../../../../../enums";
import { LazyDropsList } from "../../../../../../../components/drops/dropsList/LazyDropsList";
import { type DraggableItem } from "../../../../../../../interfaces/assetToDropInfo";
import { type ContextSwitcherOption } from "../../../../../../../components";
import { type ItemsToDropPayload } from "../../../../../../../utils/fetchItemsToDrop";
import { type CardsViewerItem } from "../../../../../../../components/cardsViewer/types";

import ContentAccessRestricted from "../../../ContentAccessRestricted/ContentAccessRestricted";
import Restricted from "../../../../../../Application/Restricted";
import ComponentsFilter from "../../../../../../../components/componentsFilter/ComponentsFilter";
import setDragNDropType from "../common/setDragNDropType";
import DragNDropItemTypes from "../../../../../../../enums/dragNDropItemTypes";
import NoSearchResults from "../../../../../../../components/noSearchResults";
import CardsCount from "./CardsCount";

export type PanelComponentProps = {
  onFilterButtonClick: () => void;
  appliedFilters: FiltersMap;
  resetAppliedFilter: () => void;
  permissions: RolePermissions[];
  renderCard: (props: CardsViewerItem<any>) => ReactElement;
  filterOptions?: ContextSwitcherOption[];
  id: string;
  loadItems: (payload: ItemsToDropPayload) => void;
  itemsToDrop: any;
  search: string;
  entityTypeSelect: EntityType;
  changeEntityType: (value: EntityType) => void;
  resetItems: () => void;
  setSearch: (value: string) => void;
  dispose: () => void;
  dragNDropItemType: DragNDropItemTypes;
  className: string;
  showPurchased: boolean;
};

export const PanelComponent = (props: PanelComponentProps) => {
  const {
    id,
    dispose,
    changeEntityType,
    resetItems,
    permissions,
    resetAppliedFilter,
    loadItems,
    setSearch,
    itemsToDrop,
    entityTypeSelect,
    appliedFilters,
    onFilterButtonClick,
    renderCard,
    filterOptions,
    search,
    dragNDropItemType,
    className,
    showPurchased,
  } = props;
  const { items, areAllLoaded, isLoading } = itemsToDrop;

  useEffect(() => {
    getData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, appliedFilters, entityTypeSelect]);

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (skipCount: number) => {
    loadItems({
      skip: skipCount,
      term: search,
      appliedFilters: appliedFilters,
      showPurchased: showPurchased,
      sortBy: search ? undefined : "dateCreated",
      sortOrder: search ? undefined : SortingDirection.Descending,
    });
  };

  const getDrops = useMemo(
    () => setDragNDropType<ItemsToDropBase & DraggableItem>(items, dragNDropItemType),
    [items, dragNDropItemType],
  );

  return (
    <Restricted permissions={permissions} placeholder={<ContentAccessRestricted />}>
      <ComponentsFilter
        id={id}
        filterOptions={filterOptions}
        appliedFilters={appliedFilters}
        defaultSearchTerm={search}
        onFilterChange={(_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
          dispose();
          changeEntityType(data.value as EntityType);
        }}
        onSearchChange={(value: string) => {
          resetItems();
          setSearch(value);
        }}
        onResetFilter={() => {
          resetItems();
          resetAppliedFilter();
        }}
        defaultOption={entityTypeSelect}
        onFilterButtonClick={onFilterButtonClick}
      />

      <div className={cn(className)}>
        <LazyDropsList
          loadData={getData}
          drops={getDrops}
          isLoadingData={isLoading}
          allDataLoaded={areAllLoaded}
          renderCard={renderCard}
          itemsPerRow={2}
          noResultsContent={<NoSearchResults />}
          renderCardsSelectionControls={
            dragNDropItemType === DragNDropItemTypes.PACK ? () => <CardsCount /> : undefined
          }
        />
      </div>
    </Restricted>
  );
};

export default PanelComponent;
