import { type FC } from "react";
import { Icon, Radio } from "semantic-ui-react";

import { type NotifySettings } from "../../../../features/SystemNotifications/types";
import { type SwitchProps } from "./types";
import Tooltip from "components/common/tooltip/Tooltip";

import styles from "./defaultSwitch.module.scss";

export interface DefaultSwitchProps extends SwitchProps<NotifySettings> {}

export const DefaultSwitch: FC<DefaultSwitchProps> = ({
  onNotifyConfigChange,
  config,
  isDisabled,
  labelText,
  tooltipText,
}) => {
  return (
    <div className={styles.root}>
      <Radio
        label={labelText ?? "Notify recipient(s) now"}
        onChange={(_, { checked }) => onNotifyConfigChange({ key: "shouldNotifyUsers", value: checked })}
        checked={config.shouldNotifyUsers}
        toggle
        disabled={isDisabled}
      />
      {tooltipText && (
        <Tooltip
          target={<Icon className="info circle" />}
          content={tooltipText}
          position="top center"
          styles={{ width: `${19}rem` }}
          hideOnScroll
          showAlways
        />
      )}
    </div>
  );
};
