import { pluralize } from "../../../../../../../utils/stringUtils";
import { type AssignedUserModal } from "../../../../../../../components/assignmentModals/usersAssignmentModal/types";
import type AssignedGroup from "../../../../../../../interfaces/assignedGroup";

export const getCustomTooltipMessage =
  (entity: string, flowsCount: number) => (entityData: AssignedUserModal | AssignedGroup) => {
    if (!entityData.assignsCount) return "";

    const flowPluralize = pluralize("flow", flowsCount);
    const thisPluralize = flowsCount > 1 ? "these" : "this";

    if (flowsCount > entityData.assignsCount) {
      return `This ${entity} already belongs to ${entityData.assignsCount} of the ${flowPluralize} selected and will be skipped`;
    }

    return `This ${entity} already belongs to ${thisPluralize} ${flowPluralize}`;
  };
