import { useCallback } from "react";
import { useStore } from "reactflow";
import { type WithinError } from "../../validator/flowValidatorReducer";
import useBaseErrorHandler from "./useBaseErrorHandler";
import useErrorNavigationUtils from "./useErrorNavigationUtils";

export default function useWithinErrorHandler() {
  const handleBaseError = useBaseErrorHandler();
  const { getTargetNode } = useErrorNavigationUtils();
  const addSelectedNodes = useStore((actions) => actions.addSelectedNodes);

  return useCallback(
    (error: WithinError) => {
      const targetNode = getTargetNode(error.inId);

      if (targetNode) {
        addSelectedNodes([targetNode.id]);
        setTimeout(() => handleBaseError(error), 50);
      }
    },
    [getTargetNode, handleBaseError, addSelectedNodes],
  );
}
