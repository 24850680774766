import { createSelector, type Selector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { type RootState } from "../globaltypes/redux";
import { type Tags as FlowTags } from "../../Library/Flows/types/flowBase";

interface GeneralTags {
  [key: string]: { id: number; title: string }[];
}

type Tags = GeneralTags | FlowTags;

export const getMapTagsSelector = (selector: Selector<RootState, Tags>) =>
  createSelector([selector], (tags) => {
    if (isEmpty(tags)) {
      return;
    }

    return {
      labels: tags.labels?.map((item) => ({ text: item.title, value: item.title })),
      softwareApplications: tags.softwareApplications?.map((item) => ({ text: item.title, value: item.title })),
    };
  });
