import React from "react";

import { type FlowItemMessage } from "../../../../features/Library/Flows/Designer/types";

import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface MessageCardOwnProps {
  item: FlowItemMessage;
}

const MessageCard: React.FC<MessageCardOwnProps> = ({ item }) => (
  <>
    <TopContent {...item} />
    <BottomContent cardLabel={item.elementType} />
  </>
);

export default MessageCard;
