import { connect } from "react-redux";

import GenericItemsView, { type GenericItemsViewProps } from "../../../../../views/ItemsView/GenericItemsView";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { type PdfOverview } from "../../types/state";
import { bindAction } from "../../../../../interfaces/redux";
import { getOverviewFilterOptions } from "../../state/thunks/pdfFiltersThunk";
import { pdfsStateSelector } from "../../selectors";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const pdfsState = pdfsStateSelector(state);
  const overview = pdfsState.overview;

  return {
    dataCount: overview.pdfs.itemsCount,
    filterOptionsLoading: overview.filters.isLoading,
    filterOptions: overview.filters.filterOptions,
    appliedFilter: overview.filters.appliedFilter,
    isAllDataLoaded: overview.pdfs.areAllLoaded,
    className: "alignment-padding",
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getFilterOptions: bindAction(getOverviewFilterOptions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<PdfOverview>) => React.ReactElement);
