import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FeatureFlags } from "featureFlags";
import { type BasePerformanceQueryContext, type PerformancePeriodQueryContext } from "features/Library/Common/models";
import {
  completesColor,
  createDateRange,
  dateRange30,
  getFormattedTimeStringFromPeriod,
  lineChartFactory,
  noBarData,
  noData,
  startsColor,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { fetchMeetingLineChartData, fetchMeetingTotalsData } from "features/Reporting/services/googleReportingService";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useMemo, useState } from "react";

import styles from "../googleReport.module.scss";
import { ChartWrapper, GroupedBarChart, HorizontalBarChart, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";

import { type DataPoint } from "components/charts/types/HorizontalBarChart";
import { type BarChartData } from "components/charts/types/Shared";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import {
  COLOR_SCALE,
  type ChartState,
  handleDropdownChange,
  sharedGoogleReportingBarProps,
} from "../utils/shared";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useGoogleTooltipFormats } from "../utils/useGoogleTooltipFormats";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";

type MeetingBarData = {
  meet: number;
  calendar: number;
};

const LEGEND_LABELS = ["Meet", "Calendar"];

const getLineChartQuery = async ({ queryKey }: PerformancePeriodQueryContext) => {
  const { data } = await fetchMeetingLineChartData({ ...queryKey[1], period: queryKey[2] });

  const lineData = lineChartFactory(
    data.map((d) => {
      const { EventDate, ...rest } = d;
      return {
        ...rest,
        Date: EventDate,
      };
    }),
  );

  const groupedUsersColumnData: BarChartData<MeetingBarData>[] = data.map((d) => ({
    group: moment(d.EventDate, "YYYY-MM-DD").format(SERVER_DATE_ONLY_FORMAT),
    meet: d.MeetUsers,
    calendar: d.CalendarUsers,
  }));

  const activityBarData: BarChartData<MeetingBarData>[] = data.map((d) => ({
    group: moment(d.EventDate, "YYYY-MM-DD").format(SERVER_DATE_ONLY_FORMAT),
    meet: d.MeetInvites,
    calendar: d.CalendarEvents,
  }));

  const order: (keyof MeetingBarData)[] = ["meet", "calendar"];

  return {
    lineData,
    groupedUsersColumnData,
    activityBarData,
    order,
  };
};

const getTotalsQuery = async ({ queryKey }: BasePerformanceQueryContext) => {
  const { data } = await fetchMeetingTotalsData(queryKey[1]);
  const domain = [0, Math.max(1, Math.max(data.CalendarEvents, data.MeetInvites))];
  const barData: DataPoint[] = [
    {
      id: "meet",
      fillColor: startsColor,
      value: data.MeetInvites,
      category: "Meet",
    },
    {
      id: "calendar",
      fillColor: completesColor,
      value: data.CalendarEvents,
      category: "Calendar",
    },
  ];
  return {
    barData,
    domain,
  };
};

const legendLabelFormat = (label: string): string => {
  if (label === "Calendar") {
    return "Total Google Calendar events created within your organization.";
  } else {
    return "Total users invited to Google Meet, encompassing both internal and external users.";
  }
};

export const GoogleMeetings = () => {
  const enabled = useFeatureFlag(FeatureFlags.GoogleReports);
  const [usersChartState, setUsersChartState] = useState<ChartState>("line");
  const [activityChartState, setActivityChartState] = useState<ChartState>("line");

  const [filter, setFilter] = useState(dateRange30());

  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const lineChartQuery = useQuery({
    queryKey: ["googleMeetingLine", filter, chartPeriod],
    queryFn: getLineChartQuery,
    enabled: enabled && chartPeriod !== "UNSET",
  });

  const totalsQuery = useQuery({
    queryKey: ["googleMeetingTotals", filter],
    queryFn: getTotalsQuery,
    enabled,
  });

  const { lineChartFormatter, groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const activeUsersChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return usersChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.MeetUsers, lineChartQuery.data.lineData.CalendarUsers]}
        tooltipFormatter={lineChartFormatter}
        colorRange={COLOR_SCALE}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        order={lineChartQuery.data.order}
        data={lineChartQuery.data.groupedUsersColumnData}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
      />
    );
  }, [chartPeriod, dateRange, groupedBarFormatter, lineChartQuery.data, lineChartFormatter, usersChartState]);

  const activityChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return activityChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        tooltipFormatter={lineChartFormatter}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        colorRange={COLOR_SCALE}
        yData={[lineChartQuery.data.lineData.MeetInvites, lineChartQuery.data.lineData.CalendarEvents]}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
        order={lineChartQuery.data.order}
        data={lineChartQuery.data.activityBarData}
      />
    );
  }, [activityChartState, chartPeriod, dateRange, groupedBarFormatter, lineChartQuery.data, lineChartFormatter]);

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <h2>Usage</h2>
        <ReportingFilter currentFilter={filter} callback={({ dateFrom, dateTo }) => setFilter({ dateFrom, dateTo })} />
      </div>
      <div className={styles.reportBody}>
        <div className={styles.activeUsersContainer}>
          <ChartWrapper
            titles={["Active Users - Line Chart", "Active Users - Column Chart"]}
            showLegend
            legendLabels={LEGEND_LABELS}
            colorRange={COLOR_SCALE}
            onChange={(option) => handleDropdownChange(option, setUsersChartState)}
            legendTooltip={legendLabelFormat}
          >
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess &&
                validLineData([lineChartQuery.data.lineData.CalendarUsers, lineChartQuery.data.lineData.MeetUsers])
                ? activeUsersChart
                : noData(filter)}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className={styles.header}>
          <h2>Activity</h2>
        </div>
        <div className={styles.activityContainer}>
          <div className={styles.activity} ref={measureRef}>
            <ChartWrapper
              titles={["Meeting Activity - Line Chart", "Meeting Activity - Column Chart"]}
              showLegend
              legendLabels={LEGEND_LABELS}
              colorRange={COLOR_SCALE}
              onChange={(option) => handleDropdownChange(option, setActivityChartState)}
              legendTooltip={legendLabelFormat}
            >
              <RequestStatusRenderer state={lineChartQuery.status}>
                {lineChartQuery.isSuccess &&
                  validLineData([lineChartQuery.data.lineData.CalendarEvents, lineChartQuery.data.lineData.MeetInvites])
                  ? activityChart
                  : noData(filter)}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className={styles.barChartContainer}>
          <ChartWrapper
            titles={["Meet vs Calendar"]}
            showLegend
            legendLabels={LEGEND_LABELS}
            colorRange={COLOR_SCALE}
            tooltipFormatter={() =>
              "Compare the total number of users invited to Google Meet (both internal and external) with the total number of Google Calendar events created in your organization."
            }
            legendTooltip={legendLabelFormat}
          >
            <RequestStatusRenderer state={totalsQuery.status}>
              {totalsQuery.isSuccess && !noBarData(...totalsQuery.data.barData.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  domain={totalsQuery.data.domain}
                  data={totalsQuery.data.barData}
                  yAxisTickTooltipFormat={(l) => legendLabelFormat(l as string)}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};
