import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type AssociatedPack } from "../../types/state";

const initialState: FetchingItemsState<AssociatedPack> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const associatedPacksSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.ContentPacks,
    name: "associatedPacks",
  },
  initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = associatedPacksSlice.actions;

export default associatedPacksSlice.reducer;
