export enum TemplateTypes {
  FlowRepublished = "flowRepublished",
  AddedToFlowV2 = "addedToFlowV2",
  AddedToVideo = "addedToVideo",
  VideoChanged = "videoChanged",
  AddedToAssessment = "addedToAssessment",
  AssessmentChanged = "assessmentChanged",
  AddedToPdf = "addedToPdf",
  PdfChanged = "pdfChanged",
  AddedToGroup = "addedToGroup",
  WelcomeEmail = "welcomeEmail",
  EventAssigned = "eventAssigned",
  AddedToSurvey = "addedToSurvey",
  AccountCreated = "accountCreated",
  SurveyChanged = "surveyChanged",
  ApproveGroupTemplate = "approveGroupTemplate",
  Empty = "",
}
