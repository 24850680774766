import { type PdfGroup } from "../../types/models";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface PdfGroupsState extends SelectedItemsState<PdfGroup> {}

const initialState: PdfGroupsState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const pdfGroupsSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Pdfs, name: "groups" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  pdfGroupsSlice.actions;

export default pdfGroupsSlice.reducer;
export type pdfGroupsSliceStateType = ReturnType<typeof pdfGroupsSlice.reducer>;
