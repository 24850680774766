import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { getPrefix } from "features/Application/slices/models";
import enrollmentRulesUtils from "utils/enrollmentRulesUtils";
import { Access, Awareness, Visibility } from "../../../../../../enums/groups";
import Enrollment from "../../../../../../enums/groups/enrollment";
import { type EnrollmentRule } from "../../../AddEditGroupForms/AutomaticallyDetect/models";

export interface GroupInfo {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  dateCreated: string;
  dateModified: string;
  isAware: Awareness;
  isVisibleToAll: Visibility;
  isOpen: Access;
  isWithAutoEnroll: Enrollment;
  enrollmentRules?: EnrollmentRule[];
  readOnly: boolean;
  canEdit: boolean;
}

export interface GroupSettings {
  isAware: boolean | null;
  isOpen: boolean | null;
  isVisibleToAll: boolean | null;
  isWithAutoEnroll: boolean | null;
}

interface GroupState {
  info?: GroupInfo;
  settings: GroupSettings;
  isLoading: boolean;
  error: string | null;
  isAccessAuthorized: boolean;
}

export interface EditGroupState extends GroupState {
  enrollmentRules?: any[];
}

const initialState: EditGroupState = {
  // @ts-ignore
  info: {},
  settings: {
    isAware: null,
    isOpen: null,
    isVisibleToAll: null,
    isWithAutoEnroll: null,
  },
  isLoading: false,
  error: null,
  isAccessAuthorized: true,
};

const extractSettings = (groupInfo?: GroupInfo) => {
  const isAware = groupInfo?.isAware === Awareness.Aware;
  return {
    isAware: isAware,
    isVisibleToAll: isAware ? groupInfo?.isVisibleToAll === Visibility.VisibleToAll : null,
    isOpen: isAware ? groupInfo?.isOpen === Access.Open : null,
    isWithAutoEnroll: groupInfo?.isWithAutoEnroll === Enrollment.Automatic,
  };
};

const editGroupSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.EditGroup,
    name: "info",
  }),
  initialState,
  reducers: {
    fetchBegin(state) {
      state.isLoading = true;
    },
    fetchSuccess(state, action: PayloadAction<GroupInfo>) {
      state.isLoading = false;

      const groupInfo = action.payload;
      state.info = groupInfo;
      state.enrollmentRules = enrollmentRulesUtils.mapRulesToLocalFormat(groupInfo.enrollmentRules);
      state.settings = extractSettings(groupInfo);
    },
    fetchFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchForbidden(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      state.isAccessAuthorized = false;
    },
    resetEditGroupInfo() {
      return { ...initialState };
    },
    updateEnrollmentRulesSuccess(state, action: PayloadAction<any[]>) {
      state.enrollmentRules = action.payload;
    },
    updateGroupInfo(state, action: PayloadAction<{ info: Partial<GroupInfo>; settings: GroupSettings }>) {
      const { info, settings } = action.payload;
      state.info = { ...state.info!, ...info };
      state.settings = settings;
    },
  },
});

export const {
  fetchBegin,
  fetchSuccess,
  fetchFailure,
  fetchForbidden,
  resetEditGroupInfo,
  updateEnrollmentRulesSuccess,
  updateGroupInfo,
} = editGroupSlice.actions;

export const editGroupInfoReducer = editGroupSlice.reducer;
