import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import RoleList from "../RoleList";
import { OverviewHeader } from "../../../components/sectionHeader";
import * as rolesOverviewActions from "./state/rolesOverviewActions";
import * as notificationsActions from "../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import * as filterActionCreators from "./state/filterActionCreators";
import UsersToRolesAssignmentModal from "../Roles/UsersToRolesAssignmentModal/UsersToRolesAssignmentModal";
import usersDataService from "../../Application/services/dataServices/usersDataService";
import { AddMemberLinkButton } from "../../../components/buttons/linkButtons";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import DeleteRoleTask from "../Roles/backgroundTasks/deleteRoleTask";
import DeleteRoleConfirmationModal from "../../../components/people/deleteRoleConfirmationModal/DeleteRoleConfirmationModal";
import RolePermissions from "../../../enums/rolePermissions";
import Restricted from "../../Application/Restricted";
import { AddButtonWithPermission } from "../../../components";
import { withRouter } from "../../../adapters/withRouter/withRouter";

import "./rolesOverview.scss";

export class RolesOverview extends Component {
  state = {
    selectedRoleIds: [],
    focusedRoleIds: [],
    focusedRole: null,
    showAddUsersModal: false,
    showDeleteRoleConfirmation: false,
  };

  renderCreateRoleButton = () => {
    return (
      <AddButtonWithPermission
        permissions={[RolePermissions.RolesCreate]}
        buttonText="Create Role"
        goToLink="/people/roles/create"
      />
    );
  };

  onSelectedRolesChanged = (selectedIds) => {
    this.setState({ selectedRoleIds: selectedIds });
  };

  onAddUsersToRoles = (roleIds) => {
    this.setState({ focusedRoleIds: roleIds, showAddUsersModal: true });
  };

  onCloseAddUsersModal = () => {
    const { resetAddUsersSearch } = this.props;
    resetAddUsersSearch();
    this.setState({ showAddUsersModal: false });
  };

  onAddUsersConfirm = (selectedIds) => {
    const { focusedRoleIds } = this.state;
    this.clearRolesSelection();

    const userInMessageSingular = selectedIds.length === 1;
    const params = {
      id: "AssignUsersToRole",
      title: "Assign users to role",
      indeterminate: true,
      getMessageIds: async () => {
        const response = await usersDataService.assignUsersToRoles(selectedIds, focusedRoleIds);
        return [response.data];
      },
      successTransientMessage: userInMessageSingular
        ? "User has been assigned to role successfully"
        : "Users have been assigned to role successfully",
      failureTransientMessage: userInMessageSingular
        ? "Assigning user to role failed!"
        : "Assigning users to role failed!",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
    this.onCloseAddUsersModal();
  };

  clearRolesSelection = () => {
    this.setState({ selectedRoleIds: [] });
  };

  renderUsersAddModal = () => {
    const { showAddUsersModal, focusedRoleIds } = this.state;
    const { addUsersSearch } = this.props;
    return (
      <UsersToRolesAssignmentModal
        onConfirm={this.onAddUsersConfirm}
        showModal={showAddUsersModal}
        onCancel={this.onCloseAddUsersModal}
        focusedRolesIds={focusedRoleIds}
        addUsersSearch={addUsersSearch}
      />
    );
  };

  renderDeleteRoleConfirmationModal = () => (
    <DeleteRoleConfirmationModal
      open={this.state.showDeleteRoleConfirmation}
      onContinue={this.onDeleteRoleConfirmed}
      onCancel={this.closeDeleteRoleModal}
    />
  );

  closeDeleteRoleModal = () => this.setState({ showDeleteRoleConfirmation: false, focusedRole: null });

  showDeleteRoleModal = (focusedRole) => {
    this.setState({ showDeleteRoleConfirmation: true, focusedRole });
  };

  renderAddMemberButton = () => (
    <AddMemberLinkButton
      onClick={() => {
        this.onAddUsersToRoles(this.state.selectedRoleIds);
      }}
    />
  );

  goToEditRole = (roleId) => {
    this.props.navigate(roleId.toString());
  };

  onDeleteRoleConfirmed = () => {
    this.deleteRole();
    this.closeDeleteRoleModal();
  };

  deleteRole = () => {
    const { focusedRole } = this.state;
    if (!focusedRole) {
      return;
    }

    const task = new DeleteRoleTask(focusedRole.id, focusedRole.name);
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(task, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  render() {
    const { selectedRoleIds } = this.state;
    const { rolesCount, isLoading, filterOptions, appliedFilter, filterActions, onFetchRoles } = this.props;

    const isAnyRoleSelected = !isEmpty(selectedRoleIds);

    return (
      <section className="nested-content roles">
        <OverviewHeader title="Roles" itemName="Role" selectedItemsAmount={selectedRoleIds.length}>
          {isAnyRoleSelected && this.renderAddMemberButton()}
          {!isAnyRoleSelected && this.renderCreateRoleButton()}
        </OverviewHeader>
        <div className="roles-overview">
          <Restricted
            permissions={[RolePermissions.RolesManage]}
            renderContent={(hasManagePermission) => (
              <RoleList
                onGetRoles={onFetchRoles}
                roles={this.props.roles}
                rolesCount={rolesCount}
                isLoading={isLoading}
                onSelectedRolesChanged={this.onSelectedRolesChanged}
                filterOptions={filterOptions}
                appliedFilter={appliedFilter}
                filterActions={filterActions}
                createRoleButton={this.renderCreateRoleButton()}
                rowButtonHandlers={{
                  onAddUsers: (role) => this.onAddUsersToRoles([role.id]),
                  onEditRole: (role) => this.goToEditRole(role.id),
                  onDeleteRole: this.showDeleteRoleModal,
                }}
                selectedRoleIds={selectedRoleIds}
                isReadOnly={!hasManagePermission}
              />
            )}
          />
          {this.renderUsersAddModal()}
          {this.renderDeleteRoleConfirmationModal()}
        </div>
      </section>
    );
  }
}

RolesOverview.propTypes = {
  navigate: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  rolesCount: PropTypes.number.isRequired,
  onFetchRoles: PropTypes.func.isRequired,
  filterOptions: PropTypes.shape({
    areas: PropTypes.array,
    isLoading: PropTypes.bool,
  }),
  appliedFilter: PropTypes.object,
  filterActions: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { rolesOverview } = state.people;
  return {
    roles: rolesOverview.overviewList.items,
    rolesCount: rolesOverview.overviewList.itemsCount,
    isLoading: rolesOverview.overviewList.isLoading,
    filterOptions: rolesOverview.filterOptions,
    appliedFilter: rolesOverview.filterState.appliedFilter,
    addUsersSearch: rolesOverview.addUsersSearch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchRoles: bindActionCreators(rolesOverviewActions.fetchRoles, dispatch),
  resetAddUsersSearch: bindActionCreators(rolesOverviewActions.resetAddUsersSearch, dispatch),
  filterActions: bindActionCreators(filterActionCreators, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RolesOverview));
