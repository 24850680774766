import * as events from "../events/library/libraryEvents";

import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces";
import { createDispatcher } from "../dispatcher";
import {
  fetchDiscardPdfSuccess,
  fetchLockedPdfSuccess,
  pdfUpdated,
  publishDraftPdfSuccess,
  updatePdfCommandSuccess,
} from "../../../../Library/Pdfs/state/actions/pdfEntityStateActions";
import { notificationReceived } from "../../../../Notifications/state/notificationsActions";
import { savePdfThumbnail } from "../../../../Library/Pdfs/state/slices/pdfDetailsSlice";
import * as backgroundTasksActions from "../../../../BackgroundTasks/state/backgroundTasksActions";
import {
  updateFailedTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

export const pdfsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchMap,
    dispatchPublishSuccess,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
  } = createDispatcher(dispatcher);

  mapping[events.PdfPublishSuccess] = dispatchPublishSuccess<{ id: number; message: string }>(
    publishDraftPdfSuccess,
    (data) => data.message,
  );

  mapping[events.PdfDiscardSuccess] = dispatch(fetchDiscardPdfSuccess);
  mapping[events.PdfLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedPdfSuccess,
    (message) => ({ entityId: message.id }),
  );
  mapping[events.PdfEditSuccess] = dispatch(pdfUpdated);
  mapping[events.PdfUpdateCommandCompleted] = dispatch(updatePdfCommandSuccess);

  mapping[events.DeletePdfDistributedSuccess] = (payload) =>
    dispatcher(backgroundTasksActions.updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.DeleteAllPdfSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.DeletePdfDistributedFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.DeleteAllPdfFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.PdfEditVisibilitySuccess] = (payload) =>
    dispatcher(backgroundTasksActions.updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.PdfEditVisibilityAllSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.PdfEditVisibilityFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.PdfEditVisibilityAllFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.PdfTagsAddSuccess] = dispatchBatchOperationSuccess;
  mapping[events.PdfTagsAddFailure] = dispatchBatchOperationFailure;
  mapping[events.PdfTagsEditSuccess] = dispatch(updatePdfCommandSuccess);

  mapping[events.PdfThumbnailEditSuccess] = dispatchMap<{ thumbnailUri: string }, string>(
    savePdfThumbnail,
    (message) => message.thumbnailUri,
  );

  mapping[events.PdfThumbnailEditFailure] = dispatch(notificationReceived);
};
