import { type Dispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import React, { type FC, useRef, useState, useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button, Table } from "semantic-ui-react";

import {
  DeleteLinkButton,
  EditLinkButton,
  RestrictedByTooltip,
  SearchInput,
  NoSearchResults,
  ContentItemMenu,
} from "../../../../../components";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import MessagesFilterForm from "../../../../../components/filterForms/MessagesFilterForm/MessagesFilterForm";
import { Title } from "../../../../../components/listViewTemplates";
import {
  ContentTypesEnum,
  PublishedStatusTypes,
  RolePermissions,
  RouteNames,
  SortingDirection,
  Strings,
  ViewType,
} from "../../../../../enums";
import { bindAction } from "../../../../../interfaces";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { type Filters } from "../../../../../utils/queryUtils";
import { ItemsView } from "../../../../../views";
import MessagesNoResults from "../../../../../views/library/messages/Overview/MessagesNoResults";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { EmailListUtils } from "../../Emails/services/listUtils";
import { messagesSearchSelector, messagesStateSelector } from "../selectors";
import {
  resetAppliedFilter,
  resetSearch,
  resetShouldSort,
  setAppliedFilter,
  setSearch,
  setShouldSort,
  setSortingColumnName,
  setSortingDirection,
} from "../state/slices/messageFiltersSlice";
import { reset } from "../state/slices/messagesOverviewSlice";
import { getOverviewFilterOptions } from "../state/thunks/messagesFiltersThunk";
import { fetchOverviewMessages, deleteMessages } from "../state/thunks/messagesOverviewThunk";
import { type MessageOverview } from "../types/state";
import columnOptions, { Columns } from "./MessagesColumnOptions";
import DeleteContentConfirmationModal, {
  type RemoveContentObserverInput,
} from "../../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import Observable from "../../../../../utils/Observable";
import { normalizedMessagesSelector } from "../state/selector/messageCommonSelector";
import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";
import MessagesOverviewHeader from "./MessagesOverviewHeader";
import {
  MessageDeleteSuccess,
  MessageMadeDraftSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import Restricted from "../../../../Application/Restricted";
import { fetchDraftMessageEntity } from "../state/actions/messageEntityStateActions";
import { useRtn } from "../../../../../hooks/useRtn";
import { DeletionRestrictedTooltip } from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { HandleOnSelectionChanged, type SelectionChangedArgs } from "../../../../../interfaces/onSelectionChanged";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface MessagesOverviewPropsBase {
  customHeaderContent: React.ReactElement;
}

export type MessagesOverviewProps = MessagesOverviewPropsBase & PropsFromRedux;
/* istanbul ignore next */
export const MessagesOverview: FC<MessagesOverviewProps> = (props) => {
  const [activePage, setActivePage] = useState<number>(1);
  const reloadListItemsRef = useRef<((enableSorting: boolean) => void) | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [itemsToDeleteMessage, setItemsToDeleteMessage] = useState<string>("");
  const [purchasedSelectedCount, setPurchasedSelectedCount] = useState(0);
  const [undeletableSelectedCount, setUndeletableSelectedCount] = useState(0);
  const [draftSelectedCount, setDraftSelectedCount] = useState(0);
  const navigate = useNavigate();
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);

  useEffect(() => {
    return () => {
      props.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToEdit = (params: { id: number }) => {
    navigate(`/${RouteNames.contentCommunications}/messages/${params.id}/configuration`);
  };

  useRtn([MessageMadeDraftSuccess], navigateToEdit);

  const buildTableBody = (message: MessageOverview, hasPermission: boolean) => {
    const deps = { packs: message.dependencies.packsCount, flows: message.dependencies.flowsCount };
    const isSelected = selectedIds.some((id) => id === message.id);

    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <Title
            url={`/${RouteNames.contentCommunications}/messages/${message.id}`}
            title={message.title}
            clamp={2}
            className="normal-color"
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          {message.dateCreated ? dateTimeUtils.formatDate(message.dateCreated) : null}
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          {message.dateModified ? dateTimeUtils.formatDate(message.dateModified) : null}
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!message.isDraft)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[4].width} className="controls-column align-right">
          <RestrictedByTooltip hasPermission={hasPermission}>
            <ContentItemMenu
              circle
              outlinedEllipsis
              isChecked={isSelected}
              readonly={isSelected || !hasPermission || message.isPurchased}
              item={message}
              entityType="message"
              dependencies={deps}
              purchasedTooltipContent={
                message.isPurchased
                  ? Strings.tooltipMessages.purchased.deleteDisabled("messages")
                  : Strings.tooltipMessages.purchased.operationsDisabled("messages")
              }
            >
              <EditLinkButton
                key="message-overview-edit-link-button"
                onClick={() => {
                  handleEditClick(message.id, message.isDraft);
                }}
              />
              {!message.isPurchased && (
                <DeleteLinkButton
                  isDisabled={!deleteContentEnabled && !message.canBeDeleted}
                  key="message-overview-delete-link-button"
                  after={
                    !message.isPurchased &&
                    !deleteContentEnabled &&
                    !message.dependencies?.isEmpty && (
                      <DeletionRestrictedTooltip dependencies={deps} entityType="message" />
                    )
                  }
                  onClick={() => handleDeleteClick([message.id], deps.flows, deps.packs)}
                />
              )}
            </ContentItemMenu>
          </RestrictedByTooltip>
        </Table.Cell>
      </>
    );
  };

  const getMessages = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: Columns = Columns.Added,
    sortDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    let column, direction;
    if (props.shouldSort) {
      column = sortingColumnName;
      direction = sortDirection;
    } else {
      column = direction = "";
    }
    props.fetchOverviewMessages({
      skip: skip,
      top: top,
      sortBy: column ? EmailListUtils.formatOrderColumn(sortingColumnName) : "",
      sortDirection: direction,
    });
    setActivePage(Math.floor(skip / top) + 1);
    props.setShouldSort(true); // reset sorting ability if user changes sorting after a search is performed
  };

  const removeObserver = new Observable<
    (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
  >();

  const handleEditClick = (id: number, isDraft?: boolean) => {
    if (!isDraft) props.fetchDraftMessage(id);
    navigateToEdit({ id: id });
  };

  const getDeleteMessageContent = (ids: number[]): string => {
    return ids.length > 1
      ? `Delete ${ids.length} messages? If messages sends were scheduled or in progress then deleting these messages will stop all progress, and remove all data.`
      : `Delete ${ids.length} message? If message send was scheduled or in progress then deleting this message will stop all progress, and remove all data.`;
  };

  const handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => {
    const message = getDeleteMessageContent(ids);
    setItemsToDeleteMessage(message);
    removeObserver.notify(
      () => {
        props.deleteMessages(ids, () => refetchItemsFromStart());
        clearSelection();
      },
      {
        selectedItemsCount: ids.length,
        flowsCount,
        packsCount,
      },
    );
  };

  const clearSelection = () => {
    setSelectedIds([]);
    setItemsToDeleteMessage("");
    setPurchasedSelectedCount(0);
    setUndeletableSelectedCount(0);
  };

  const onSelectionChanged = (args: SelectionChangedArgs<MessageOverview>) => {
    let purchasedCount = 0;
    let undeletableCount = 0;
    let draftCount = 0;

    const onAdded = (msg: MessageOverview) => {
      msg.isPurchased && ++purchasedCount;
      !msg.canBeDeleted && ++undeletableCount;
      msg.isDraft && ++draftCount;
    };

    const onRemoved = (msg: MessageOverview) => {
      msg.isPurchased && --purchasedCount;
      !msg.canBeDeleted && --undeletableCount;
      msg.isDraft && --draftCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, clearSelection);

    setPurchasedSelectedCount(purchasedSelectedCount + purchasedCount);
    setUndeletableSelectedCount(undeletableSelectedCount + undeletableCount);
    setDraftSelectedCount(draftSelectedCount + draftCount);
  };

  const onCreateBtnClick = () => navigate("messages/create");

  const renderCreateBtn = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary onClick={action} disabled={!hasAnyPermission} className="create-message">
        Create Message
      </Button>
    </RestrictedByTooltip>
  );

  const renderRestrictedCreateMessageButton = (): React.ReactElement => (
    <RestrictedByAddOn
      permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
      addOnPermissions={[RolePermissions.CommunicationsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={onCreateBtnClick}
      renderContent={renderCreateBtn}
    />
  );

  const createReloadListMethod = (reloadListItems: (enableSorting: boolean) => void) => {
    reloadListItemsRef.current = reloadListItems;
  };

  const refetchItemsFromStart = (search?: string) => {
    reloadListItemsRef.current?.(isEmpty(search));
  };

  const onSearchChange = (search: string) => {
    clearSelection();
    props.setSearch(search);
    props.setShouldSort(search === "");

    // Done to ensure that the sort updates before running the search
    setTimeout(() => {
      refetchItemsFromStart(search);
    }, 0);
  };

  const applyFilter = (filter: Filters) => {
    clearSelection();
    props.applyFilter(filter);
  };

  const resetFilter = () => {
    clearSelection();
    props.resetFilter();
  };

  return (
    <section className="nested-content messages">
      <MessagesOverviewHeader
        purchasedMessageSelected={purchasedSelectedCount > 0}
        undeletableSelected={undeletableSelectedCount > 0}
        draftMessagesSelected={draftSelectedCount > 0}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onAddedToPacks={() => clearSelection()}
        deletionHandler={() => handleDeleteClick(selectedIds)}
        renderCreateButton={renderRestrictedCreateMessageButton}
      />
      <Restricted
        permissions={[RolePermissions.CommunicationsCreate]}
        renderContent={(hasPermission) => (
          <ItemsView
            customHeaderContent={props.customHeaderContent}
            items={props.messages}
            viewType={ViewType.LIST}
            getData={getMessages}
            paginateOnLoad
            noResultsContent={
              !!props.search || !isEmpty(props.appliedFilter) ? (
                <NoSearchResults />
              ) : (
                <MessagesNoResults createButton={renderRestrictedCreateMessageButton()} />
              )
            }
            isFirstLoad={props.messages.length === 0}
            isLoading={props.areMessagesLoading}
            isAllDataLoaded={!props.areMessagesLoading}
            selectedIds={selectedIds}
            onSelectionChanged={onSelectionChanged}
            onSelectedListItemsChanged={setSelectedIds}
            className="messages-items-view alignment-padding"
            // @ts-ignore
            renderFilterForm={() => <MessagesFilterForm />}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            getFilterOptions={props.getFilterOptions}
            appliedFilter={props.appliedFilter}
            filterOptions={props.filterOptions}
            filterOptionsLoading={props.filterOptionsLoading}
            buildTableBody={(messageOverview: MessageOverview) => buildTableBody(messageOverview, hasPermission)}
            columnOptions={columnOptions}
            itemsInlineCount={props.totalCount}
            renderSearch={() => (
              <SearchInput placeholder="Search for Messages..." onChange={onSearchChange} defaultValue={props.search} />
            )}
            permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
            activePage={activePage}
            sortingColumnName={props.shouldSort ? props.sortingColumnName : ""}
            setSortingColumnName={props.setSortingColumnName}
            sortingDirection={props.shouldSort ? props.sortingDirection || SortingDirection.Descending : ""}
            setSortingDirection={props.setSortingDirection}
            withSelection
            isSelectDisabled={() => !hasPermission}
            setReloadListItems={createReloadListMethod}
            hideListGridViewSwitcherButton
            listViewRtnEvents={[MessageDeleteSuccess]}
          />
        )}
      />
      <DeleteContentConfirmationModal
        content={itemsToDeleteMessage}
        contentType={ContentTypesEnum.Messages}
        onTriggerRemoveContentObserver={removeObserver}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const messagesState = messagesStateSelector(state);

  return {
    messages: state.library.messages.overview.messages.items,
    totalCount: state.library.messages.overview.messages.itemsCount,
    normalizedMessages: normalizedMessagesSelector(state),
    search: messagesSearchSelector(state),
    areMessagesLoading: messagesState.overview.messages.isLoading,
    appliedFilter: messagesState.overview.filters.appliedFilter,
    filterOptions: messagesState.overview.filters.filterOptions,
    filterOptionsLoading: messagesState.overview.filters.isLoading,
    sortingColumnName: messagesState.overview.filters.sortingColumnName,
    sortingDirection: messagesState.overview.filters.sortingDirection,
    shouldSort: messagesState.overview.filters.shouldSort,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchOverviewMessages: bindAction(fetchOverviewMessages, dispatch),
    deleteMessages: bindAction(deleteMessages, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    getFilterOptions: bindAction(getOverviewFilterOptions, dispatch),
    fetchDraftMessage: bindAction(fetchDraftMessageEntity, dispatch),
    setSortingColumnName: bindAction(setSortingColumnName, dispatch),
    setSortingDirection: bindAction(setSortingDirection, dispatch),
    setShouldSort: bindAction(setShouldSort, dispatch),
    reset: () => {
      dispatch(reset());
      dispatch(resetSearch());
      dispatch(resetAppliedFilter());
      dispatch(resetShouldSort());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(MessagesOverview);
export default ConnectedComponent;
