import React from "react";
import { bindActionCreators } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { isEmpty } from "lodash";
import cn from "classnames";

import { ListViewWithHeaderAndPanel } from "../../../../listView";
import UserInfoCell from "../../../../userInfoCell/UserInfoCell";
import { Tooltip } from "../../../../common/tooltip";
import UsersNoResultsDragNDrop from "../../../../../views/people/users/UsersNoResultsDragNDrop";
import { UsersFilterForm } from "../../../../filterForms";
import { SortingDirection } from "../../../../../enums";
import AssignedUserTooltip from "../../../../common/tooltip/AssignedUserTooltip";
import { type AssignedUser, type ColumnOptions } from "../../../../../interfaces";
import { type ModalWithStepsStep } from "../../../../modal/ModalWithSteps";

import * as usersFilterActions from "../../../../../features/People/Users/UsersOverview/state/filterActionCreators";

import "../../../assignmentListStep.scss";
import "./userListStep.scss";
import Restricted from "../../../../../features/Application/Restricted";
import AccessRestrictedMessage from "../../../../restrictedRoute/AccessRestrictedMessage";
import { type AssignedUserModal } from "../../../usersAssignmentModal/types";
import { TextTruncate } from "components/textTruncators/TextTruncators";

export interface UserListStepProps extends ModalWithStepsStep {
  loadPage: Function;
  users: AssignedUser[];
  usersCount: number;
  isLoading: boolean;
  filter?: object;
  selectedIds: number[];
  applyFilter?: Function;
  resetFilter?: Function;
  accountId: number;
  filterOptionsLoading?: boolean;
  onSelectedListItemsChanged?: Function;
  alreadyAssignedUserTooltip?: string;
  multipleAssignment?: boolean;
  selectedParentEntitiesLength?: number;
  assignmentEntityType?: string;
  hasPermissionPredicate: (userPermissions: string[]) => boolean;
  usersFilterActions: {
    getFilterOptions: () => void;
  };
  usersFilterOptions: {
    isLoading: boolean;
  };
  showRoleFilter?: boolean;
  search?: string;
  renderSearch?: () => React.ReactNode;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
  departmentFilterDisabled?: boolean;
  jobTitleFilterDisabled?: boolean;
  getCustomTooltipMessage?: (userData: AssignedUserModal) => string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export const UserListStep = (props: UserListStepProps & PropsFromRedux) => {
  React.useEffect(() => {
    const {
      usersFilterActions: { getFilterOptions },
    } = props;
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnOptions: ColumnOptions[] = [
    {
      name: "Name",
      width: 6,
      isSortable: true,
    },
    {
      name: "Department",
      width: 5,
      isSortable: true,
    },
    {
      name: "Job title",
      width: 4,
      isSortable: true,
    },
  ];

  const buildTableBody = (alreadyAssignedToUserTooltip?: string) => {
    const { multipleAssignment, assignmentEntityType, selectedParentEntitiesLength, getCustomTooltipMessage } = props;

    return (user: AssignedUserModal) => {
      const readOnlyClass = cn({
        rowDisabled: user.isAssigned,
      });

      const jobTitleClass = cn({
        rowDisabled: user.isAssigned,
        "job-title": true,
      });

      return (
        <>
          <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
            <UserInfoCell user={user} />
          </Table.Cell>
          <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
            <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
          </Table.Cell>
          <Table.Cell width={columnOptions[2].width}>
            <Tooltip
              target={<TextTruncate>{user.title}</TextTruncate>}
              content={user.title}
              className={jobTitleClass}
            />
            <AssignedUserTooltip
              user={user}
              alreadyAssignedUser={user.isAssigned}
              content={alreadyAssignedToUserTooltip}
              multipleAssignment={multipleAssignment}
              assignedToNumberOfEntities={user.assignedToNumberOfEntities}
              assignmentEntityType={assignmentEntityType}
              selectedParentEntitiesLength={selectedParentEntitiesLength}
              getCustomTooltipMessage={getCustomTooltipMessage}
            />
          </Table.Cell>
        </>
      );
    };
  };

  const isFiltered = !!props.search || !isEmpty(props.filter);

  const getFilterForm = () => (
    // @ts-ignore
    <UsersFilterForm
      departmentFilterDisabled={props.departmentFilterDisabled}
      jobTitleFilterDisabled={props.jobTitleFilterDisabled}
    />
  );

  const {
    loadPage,
    users,
    usersCount,
    isLoading,
    filter,
    selectedIds,
    applyFilter,
    resetFilter,
    onSelectedListItemsChanged,
    alreadyAssignedUserTooltip,
    usersFilterOptions,
    hasPermissionPredicate,
    renderSearch,
    setReloadListItems,
  } = props;

  return (
    <Restricted
      permissions={[]}
      placeholder={<AccessRestrictedMessage />}
      permissionPredicate={(userPermissions, _) => hasPermissionPredicate(userPermissions)}
    >
      <div className="assignment-list-step">
        <ListViewWithHeaderAndPanel
          columnOptions={columnOptions}
          renderSearch={renderSearch}
          loadPage={loadPage}
          itemsAmount={usersCount}
          isLoading={isLoading}
          items={users}
          onSelectedListItemsChanged={onSelectedListItemsChanged}
          buildTableBody={buildTableBody(alreadyAssignedUserTooltip)}
          filter={filter}
          selectedItemIds={selectedIds}
          noResultsContent={<UsersNoResultsDragNDrop filtered={isFiltered} />}
          applyFilter={applyFilter}
          filterOptions={{ showRoleFilter: props.showRoleFilter, ...props.usersFilterOptions }}
          resetFilter={resetFilter}
          filterOptionsLoading={usersFilterOptions.isLoading}
          renderFilterForm={getFilterForm}
          sortingColumnName={columnOptions[0].name}
          sortingDirection={SortingDirection.Ascending}
          isSelectDisabled={(u: AssignedUser) => u.isAssigned}
          isWarning={(u: AssignedUser) => u.isAssigned}
          setReloadListItems={setReloadListItems}
        />
      </div>
    </Restricted>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
  const { usersOverview } = state.people;

  return {
    accountId: state.userProfile.accountId,
    usersFilterOptions: usersOverview.filterOptions,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  usersFilterActions: bindActionCreators(usersFilterActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedComponent = connector(UserListStep);
export default ConnectedComponent;
