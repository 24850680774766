import { combineReducers } from "@reduxjs/toolkit";
import designerFilters from "./slices/flowDesignerFiltersSlice";
import designerContext from "./slices/flowDesignerContextSlice";
import designerItemsToDrop from "./slices/flowDesignerItemsToDropSlice";
import designerTriggers from "./slices/flowDesignerTriggersSlice";
import designerDefinition from "./slices/flowDesignerDefinitionSlice";
import externalTriggers from "./slices/flowDesignerExternalTriggersSlice";
import nextFlowItems from "../FlowComponentsPanel/flowEnd/state/slice/nextFlowItemsSlice";
import nextFlowFilters from "../FlowComponentsPanel/flowEnd/state/slice/nextFlowFiltersSlice";
import connectedFlows from "./slices/flowDesignerConnectedFlowsSlice";

const nextFlow = combineReducers({
  nextFlowItems: nextFlowItems,
  filters: nextFlowFilters,
});

const flowDesignerReducer = combineReducers({
  filters: designerFilters,
  context: designerContext,
  itemsToDrop: designerItemsToDrop,
  triggers: designerTriggers,
  definition: designerDefinition,
  externalTriggers: externalTriggers,
  nextFlow: nextFlow,
  connectedFlows: connectedFlows,
});

export default flowDesignerReducer;
