import type { ColumnOptions } from "../../../interfaces";

export const identityProviderColumnOptions: ColumnOptions[] = [
  {
    name: "ID Provider",
    width: 8,
    isSortable: true,
    className: "identity-provider-name-header",
  },
  {
    name: "Type",
    width: 4,
    isSortable: true,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];
