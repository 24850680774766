import React from "react";
import ImageDropAreaPlaceholder from "../../../../../../../components/imageDropAreaPlaceholer/ImageDropAreaPlaceholder";
import { type FlowItemPlaceholder } from "../types";

interface IPlaceholderNodeOwnProps {
  data: FlowItemPlaceholder;
}

const Placeholder: React.FC<IPlaceholderNodeOwnProps> = ({ data }) => {
  return (
    <div className="placeholder-wrapper">
      <ImageDropAreaPlaceholder isActive={data.isActive} />
    </div>
  );
};

export default Placeholder;
