export interface BaseIntegrationConfigs {
  isActive: boolean;
}

export interface UsageIntegrationConfig extends BaseIntegrationConfigs {
  syncUsers: boolean;
  importUsageData: boolean;
}

export interface NotificationIntegrationConfig extends BaseIntegrationConfigs {
  userNotificationId: string;
}

export interface BaseOauthIntegrationConfigs {
  authenticationCode?: string;
  redirect?: string;
}

export interface MsGraphIntegrationConfigs extends UsageIntegrationConfig, NotificationIntegrationConfig {
  licensedOnly: boolean;
  importLicenses: boolean;
  importGroups: boolean;
  tenantId?: string;
}

export interface GoogleIntegrationConfigs
  extends UsageIntegrationConfig,
    NotificationIntegrationConfig,
    BaseOauthIntegrationConfigs {
  isAuthorized: boolean;
}

export interface WebexIntegrationConfigs
  extends UsageIntegrationConfig,
    NotificationIntegrationConfig,
    BaseOauthIntegrationConfigs {}

export enum IntegrationTypes {
  MsGraph = "msgraph",
  Google = "google",
  Webex = "webex",
  Viva = "viva",
  SuccessFactors = "successfactors",
}

export interface ConnectorInfo {
  id: string;
  type: IntegrationTypes;
  connectorTitle: string;
  description: string;
  thumbnailUrl: string;
  isConnected: boolean;
}

export interface Integration {
  id: number;
  type: IntegrationTypes;
  details: string;
  status: IntegrationStatuses;
  lastSyncDate: string;
  isActive: boolean;
}

export interface IntegrationMenu {
  type: IntegrationTypes;
  isActive: boolean;
}

export interface IntegrationKey {
  type: string;
  id: number;
}

export interface IntegrationWithCompositeId {
  id: IntegrationKey;
  type: string;
  details: string;
  status: IntegrationStatuses;
  lastSyncDate: string | null;
}

export enum IntegrationStatuses {
  Active = "Active",
  Pending = "Pending",
  Inactive = "Inactive",
  Failed = "Failed",
}

export interface ActivityLog {
  id: string;
  details?: string;
  status: IntegrationStatuses;
  date: Date;
}

export enum IntegrationIds {
  MsGraph = 1,
  Google = 2,
  Webex = 3,
  Viva = 4,
  SuccessFactors = 5,
}

export interface IntegrationDto {
  title: string;
  url: (own: boolean, moboId?: string) => string;
}
