import type Sections from "../../../../../enums/sections";
import { type UsersGroupsContext, type RolePermissions } from "../../../../../enums";

interface PeopleOverview {
  section: Sections;
  isReadOnly: boolean;
  customHeaderContent: React.ReactElement;
  assignedPeopleIds: number[];
}

export interface PeopleWrapperProps {
  permission: RolePermissions;
  onContextChanged?: (context: UsersGroupsContext) => void;
  usersGroupsContext?: UsersGroupsContext;
  renderUsersOverview: (customHeaderContent: React.ReactElement, isReadOnly: boolean) => React.ReactElement;
  renderGroupsOverview: (customHeaderContent: React.ReactElement, isReadOnly: boolean) => React.ReactElement;
  renderTemplateGroupsOverview?: (customHeaderContent: React.ReactElement, isReadOnly: boolean) => React.ReactElement;
}

export interface GroupsOverviewProps extends PeopleOverview {}

export interface TemplateGroupsOverviewProps extends PeopleOverview {
  onlyOwn?: boolean;
  customHeaderContent: React.ReactElement;
  isReadOnly: boolean;
}

export interface UsersOverviewProps extends PeopleOverview {}

export interface TemplateOverview {
  name: string;
  id: number;
  publisher: string;
  imageUrl: Nullable<string>;
  users: number;
  customers: number;
  dateCreated: string;
  dateModified: string;
  status: TemplateStatusEnum;
  isOwn: boolean;
}

export enum TemplateStatusEnum {
  Pending,
  Approved,
  Rejected,
}

export enum TemplatesFiltersEnum {
  DateCreated = "dateCreated",
}
