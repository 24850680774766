import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { type ThreatDefenceCampaignOverview } from "../../features/Library/SimulatedPhishing/Campaigns/types/state";
import { EditLinkButton } from "../buttons/linkButtons";

interface IProps {
  campaign: ThreatDefenceCampaignOverview;
  circle: boolean;
  outlinedEllipsis: boolean;
  onEditCampaign: (id: number, isDraft: boolean) => void;
}

function CampaignEllipsisPopupButton(props: IProps) {
  const { campaign, onEditCampaign, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={() => onEditCampaign(campaign.id, campaign.isDraft)} />
    </EllipsisPopupButton>
  );
}

CampaignEllipsisPopupButton.defaultProps = {
  disabled: false,
};

export default CampaignEllipsisPopupButton;
