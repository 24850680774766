import { type FC } from "react";
import { Form } from "semantic-ui-react";
import { ValidatedForm, CopyableInput } from "../../../../../components";
import { SecretInput } from "./SecretInput";

export interface Props {
  clientId?: string;
  clientSecret?: string;
  loading?: boolean;
  readonly?: boolean;
  onSecretRegenerate(): void;
}

export const ApiCredentials: FC<Props> = ({ clientId, clientSecret, loading, readonly, onSecretRegenerate }) => {
  return (
    <ValidatedForm disablePreventTransitionPrompt>
      <Form.Field disabled={loading}>
        <label>Client ID</label>
        <CopyableInput value={clientId} loading={loading} />
      </Form.Field>
      <Form.Field disabled={loading}>
        <label>Secret</label>
        <SecretInput value={clientSecret} onRegenerate={onSecretRegenerate} loading={loading} readonly={readonly} />
      </Form.Field>
    </ValidatedForm>
  );
};
