import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useStore } from "react-redux";

import { EmailEditorController, type IEmailEditorController } from "../EmailEditor/emailEditorController";
import { script, StripoClient, type StripoInitOpts } from "../../../lib/stripo";
import { type RootState } from "../../../features/Application/globaltypes/redux";

export type HookResult = [boolean, Nullable<IEmailEditorController>, string, string];

export function useEmailEditor(opts: StripoInitOpts): HookResult {
  const store = useStore<RootState>();
  const { initialTemplate, layout, emailPreview, templateSasUrl } = store.getState().systemNotifications.notifyStep;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const controllerRef = useRef<Nullable<IEmailEditorController>>(null);

  useLayoutEffect(() => {
    const isRequiredDataLoaded = !!initialTemplate && !!layout;

    if (!isRequiredDataLoaded) {
      return;
    }

    const stripo = new StripoClient(script);
    const emailEditor = new EmailEditorController(stripo, store);

    emailEditor.initOnce({ ...opts, onTemplateLoaded: () => setIsLoaded(true) });
    controllerRef.current = emailEditor;

    return () => stripo.stop();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialTemplate, layout]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    controllerRef.current?.renderPreview();
  }, [controllerRef.current, isLoaded]);

  return [isLoaded, controllerRef.current, templateSasUrl, emailPreview];
}
