import * as Yup from "yup";
import validationRules from "../validationRules";

export const uploadedVideos = Yup.mixed()
  .test("File does not exist", "Video is required", validationRules.fileListLength)
  .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
  .test("File exceeds maximum size", "File exceeds maximum allowed upload size of 4GB", validationRules.fileMaxSize)
  .test("Wrong file type", "Only mp4, mov or avi file types are allowed", validationRules.mp4MovAviFileType)
  .test(
    "File name size exceeds maximum size",
    "File name must be maximum of 120 characters long",
    validationRules.fileNameSize,
  );

export const uploadedImages = Yup.mixed()
  .test("File does not exist", "Image is required", validationRules.fileListLength)
  .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
  .test(
    "File exceeds maximum size",
    "File exceeds maximum allowed upload size of 5MB",
    validationRules.imageFileMaxSize,
  )
  .test("Wrong file type", "Only image file types are allowed", validationRules.imageFileType);

export const uploadedTeamsImages = Yup.mixed()
  .test("File does not exist", "Image is required", validationRules.fileListLength)
  .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
  .test(
    "File exceeds maximum size",
    "Image cannot be uploaded because it is larger than 1MB",
    validationRules.teamsImageFileMaxSize,
  )
  .test("Wrong file type", "Only image file types are allowed", validationRules.imageFileType)
  .test("Image exceeds allowable dimensions", "Image exceeds allowable dimensions 1024x1024", async (value) => {
    /* istanbul ignore next */
    return await validationRules.teamsImageFileDimensions(value);
  });

export const usersFileUpload = Yup.mixed()
  .test("file_does_not_exist", "File is required", validationRules.fileListLength)
  .test("file_size_equals_zero", "File size is equal to zero", validationRules.fileMinSize)
  .test("wrong_file_type", "Only CSV file type is allowed", validationRules.usersUploadFileType);

export const uploadedClosedCaptions = Yup.mixed()
  .test("File does not exist", "Closed captions is required", validationRules.fileListLength)
  .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
  .test(
    "File exceeds maximum size",
    "File exceeds maximum allowed upload size of 1MB",
    validationRules.closedCaptionsFileMaxSize,
  )
  .test("Wrong file type", "Only vtt file type is allowed", validationRules.closedCaptionsUploadFileType);

export const uploadedPdfs = Yup.mixed()
  .test("File does not exist", "PDF file is required", validationRules.fileListLength)
  .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
  .test("File exceeds maximum size", "File exceeds maximum allowed upload size of 30MB", validationRules.pdfFileMaxSize)
  .test("Wrong file type", "Only PDF file type is allowed", validationRules.pdfUploadFileType);
