import { type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";
import { VideoFiltersEnum } from "../../../features/Library/Videos/types/models";

export const getVideosFilters = (
  includePublisher: boolean = true,
  disableDateFilters: boolean = false,
): FilterItemBase<VideoFiltersEnum>[] => {
  let filters: FilterItemBase<VideoFiltersEnum>[] = [
    {
      type: FilterViewTypes.DateRange,
      label: "Date Added",
      propertyName: VideoFiltersEnum.DateCreated,
      otherProps: { disabled: disableDateFilters },
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Last Modified",
      propertyName: VideoFiltersEnum.DateModified,
      otherProps: { disabled: disableDateFilters },
    },
  ];

  if (includePublisher) {
    filters.push({
      type: FilterViewTypes.MultiSelect,
      label: "Publisher",
      propertyName: VideoFiltersEnum.Publishers,
      placeholder: "All Publishers",
    });
  }

  filters.push(
    {
      type: FilterViewTypes.MultiSelect,
      label: "Packs",
      propertyName: VideoFiltersEnum.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Software Applications",
      propertyName: VideoFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Labels",
      propertyName: VideoFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  );

  return filters;
};
