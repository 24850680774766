import React from "react";
import { Button } from "components/buttons/button/Button";
import OwnerListStep from "../OwnerListStep/OwnerListStep";
import ModalWithSteps from "../../../../../../components/modal/ModalWithSteps";
import userListUtils from "../../../../../../utils/userListUtils";
import { bindActionCreators, type Dispatch } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import * as usersFilterActions from "../../../../Users/UsersOverview/state/filterActionCreators";
import { type Filters } from "../../../../../../utils/queryUtils";
import { type IOwnerItemModel } from "../../../../types";
import * as editGroupActions from "../../editGroupActions";
import { type RootState } from "../../../../../Application/globaltypes/redux";
import { type SortingDirection } from "../../../../../../enums";
import { type FiltersMap } from "../../../../../../utils/filterUtils";

import "./ownersAssignmentModal.scss";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface OwnersAssigmentModalProps {
  onConfirm: (selectedIds: number[]) => void;
  onCancel: () => void;
  showModal: boolean;
  isStepLoading: boolean;
  loadPage: (skip: number, top: number, orderParams: any, filterParams: any) => void;
  owners: IOwnerItemModel[];
  ownersAmount: number;
  isListLoading: boolean;
  groupId: number;
}

export interface OwnersAssigmentModalState {
  selectedIds: number[];
  appliedFilter: Filters;
}

export class OwnersAssigmentModal extends React.Component<
  OwnersAssigmentModalProps & PropsFromRedux,
  OwnersAssigmentModalState
> {
  constructor(props: OwnersAssigmentModalProps & PropsFromRedux) {
    super(props);
    this.state = {
      selectedIds: [],
      appliedFilter: {},
    };
  }

  componentDidMount() {
    this.props.usersFilterActions.getFilterOptions();
    this.props.editGroupActions.fetchAssignedGroupOwners(this.props.groupId);
  }

  componentDidUpdate(prevProps: OwnersAssigmentModalProps & PropsFromRedux) {
    const { showModal } = this.props;

    if (showModal !== prevProps.showModal && !showModal) {
      this.setSelectedIds([]);
      this.resetFilter();
    }
  }

  onLoad = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: FiltersMap,
  ) => {
    const orderParams = userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    this.props.loadPage(skip, top, orderParams, appliedFilter);
  };

  renderModalActions = () => {
    const { isStepLoading, onConfirm, onCancel } = this.props;
    const { selectedIds } = this.state;
    return (closeModal: Function) => {
      return (
        <>
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              closeModal();
              onCancel();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={selectedIds.length === 0 || isStepLoading}
            onClick={() => {
              onConfirm(selectedIds);
              closeModal();
            }}
          />
        </>
      );
    };
  };

  setSelectedIds = (ids: number[]) => {
    this.setState({ selectedIds: ids });
  };

  applyFilter = (filter: Filters) => {
    this.setState({ appliedFilter: filter });
  };

  resetFilter = () => {
    this.setState({ appliedFilter: {} });
  };

  isAlreadyAssigned = (user: IOwnerItemModel) => {
    return this.props.groupAssignedOwners.items.some((us: any) => us.id === user.id);
  };

  render() {
    const { owners, ownersAmount, isListLoading, showModal, isStepLoading, onCancel } = this.props;

    const { selectedIds, appliedFilter } = this.state;

    return (
      <ModalWithSteps
        className="owners-assignment-modal"
        scrolling
        isLoading={isStepLoading}
        showModal={showModal}
        onCancel={onCancel}
      >
        <OwnerListStep
          header="Add Owners"
          renderModalActions={this.renderModalActions}
          loadPage={this.onLoad}
          isLoading={isListLoading}
          owners={owners.map((item) => ({
            ...item,
            isAssigned: this.isAlreadyAssigned(item),
          }))}
          ownersCount={ownersAmount}
          onSelectedListItemsChanged={this.setSelectedIds}
          selectedIds={selectedIds}
          applyFilter={this.applyFilter}
          filter={appliedFilter}
          resetFilter={this.resetFilter}
        />
      </ModalWithSteps>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    groupAssignedOwners: state.people.editGroup.groupAssignedOwners,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  usersFilterActions: bindActionCreators(usersFilterActions, dispatch),
  editGroupActions: bindActionCreators(editGroupActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedComponent = connector(OwnersAssigmentModal);
export default ConnectedComponent;
