import axios from "axios";
import { type NotifyStepSettings } from "../../../../interfaces";
import { type DistributedOperationResult } from "../../../../types";
import {
  type EntityToPeopleAssignments,
  type EntityToPeopleRemovePriorities,
  type EntityToPeopleUnAssignments,
  type AssignPeopleLicense,
  type EntityToContentRemovePriorities,
} from "../../../Library/PeopleAssignments/types";
import { type IAssignmentsDataService } from "./IAssignmentsDataService";

const assignmentsDataService: IAssignmentsDataService = {
  async setPriority(items: EntityToPeopleAssignments[], notificationSettings?: NotifyStepSettings) {
    const url = `/api/priorities`;
    const response = await axios.post(url, { items, notificationSettings });
    return response.data;
  },
  async createAssignmentsV2(items: EntityToPeopleAssignments[], notificationSettings?: NotifyStepSettings) {
    const url = `api/v2/assignments`;
    const response = await axios.post(url, { items, notificationSettings });
    return response.data;
  },
  async assignLicenses(licenses: AssignPeopleLicense[]) {
    const url = `/api/packs/licenses`;
    const response = await axios.post(url, { licenses });
    return response.data;
  },
  async removeLicenses(licenses: AssignPeopleLicense[]) {
    return (await axios.delete<DistributedOperationResult>(`/api/packs/licenses`, { data: { licenses } })).data;
  },
  editAssignmentsPriority(items: EntityToPeopleAssignments[]) {
    return axios.put(`api/assignments/priority`, { items });
  },
  editPriority(items: EntityToPeopleAssignments[]) {
    return axios.post(`api/priorities`, { items });
  },
  async removeAssignmentsV2(items: EntityToPeopleUnAssignments[]) {
    return (await axios.delete<DistributedOperationResult>(`/api/v2/assignments`, { data: { items } })).data;
  },

  async removePriorities(items: EntityToPeopleRemovePriorities[] | EntityToContentRemovePriorities[]) {
    return (await axios.delete<DistributedOperationResult>(`/api/priorities`, { data: { items } })).data;
  },
};

export default assignmentsDataService;
