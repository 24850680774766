import videojs from "video.js";

const Menu = videojs.getComponent("Menu");

class QhMenu extends Menu {
  createEl() {
    const menu = super.createEl();
    const popup = document.createElement("div");
    popup.className = "vjs-settings-menu-popup";
    this.header = document.createElement("div");
    this.header.className = "vjs-settings-menu-header";
    popup.appendChild(this.header);
    popup.appendChild(menu);
    this.footer = document.createElement("div");
    this.footer.className = "vjs-settings-menu-footer";
    popup.appendChild(this.footer);
    return popup;
  }

  addItem(component) {
    this.addChild(component);
  }

  _setTitle(headerContent) {
    this.header.innerHTML = "";
    if (headerContent) {
      this.header.appendChild(headerContent);
    }
  }

  _setFooter(footerContent) {
    this.footer.innerHTML = "";
    if (footerContent) {
      this.footer.appendChild(footerContent);
    }
  }

  _clearMenu() {
    const children = this.children().slice();
    for (let i = 0; i <= children.length; i++) {
      this.removeChild(children[i]);
    }
  }

  // we don't need default videojs focusing functionality
  focus() {} // NOSONAR

  renderMenuItems(items, title, footer) {
    this._clearMenu();
    this._setTitle(title);
    items.forEach((item) => this.addChild(item));
    this._setFooter(footer);
  }
}

export default QhMenu;
