import { type Dispatch } from "@reduxjs/toolkit";
import { noop } from "lodash";
import { batch } from "react-redux";

import DataService from "../../../../Application/services/dataServices/typedDataService";
import fileUtils, { type OnUploadProgressHandler } from "../../../../../utils/fileUtils";
import type UpdateParams from "../../../../../interfaces/updateParams";

import {
  fetchAvailableLanguages,
  fetchFailure,
  fetchAssignedCaptionsBegin,
  fetchClosedCaptionsSuccess,
  refetchClosedCaptionsSuccess,
  fetchClosedCaptionsFailure,
} from "../slices/videoClosedCaptionsSlice";
import { type GetClosedCaptions } from "../../types/requests";
import { type AxiosResponse } from "axios";
import { type ClosedCaptions } from "../../types/models";
import { type AppDispatch, type AppThunk } from "../../../../Application/globaltypes/redux";
import backgroundTask, { type UpdateFeatureProps } from "../../../../BackgroundTasks/backgroundTask";
import { type AddOperationParamsV1 } from "../../../../../interfaces/backgroundAddOperationParams";
import { addOperationV1 } from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { CLOSED_CAPTIONS } from "../../types/constants";
import dataService from "../../../../Application/services/dataServices/dataService";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchAvailableVideoLanguages = (entityId: number) => async (dispatch: Dispatch) => {
  try {
    const res = await DataService.assets.getAvailableLanguages(entityId);
    dispatch(fetchAvailableLanguages(res.data));
  } catch (e) {
    dispatch(fetchFailure(e as Error));
  }
};

export const fetchClosedCaptionsBase =
  (request: GetClosedCaptions, success: (res: AxiosResponse<ClosedCaptions[]>) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchAssignedCaptionsBegin());
    try {
      const res = await DataService.assets.getAssignedClosedCaptions(request);
      success(res as any);
    } catch (e) {
      dispatch(fetchClosedCaptionsFailure(e as Error));
    }
  };

export const fetchClosedCaptions = (request: GetClosedCaptions) => async (dispatch: AppDispatch) => {
  await dispatch(
    fetchClosedCaptionsBase(request, (res) => {
      dispatch(fetchClosedCaptionsSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    }),
  );
};

export const refechClosedCaptions = (videoId: number) => async (dispatch: AppDispatch) => {
  await dispatch(
    fetchClosedCaptionsBase({ videoId: videoId, top: CLOSED_CAPTIONS.TOP, skip: 0 }, (res) => {
      dispatch(refetchClosedCaptionsSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    }),
  );
};

export const setDefaultClosedCaptionAction =
  (languageId: number, entityId: number) => async (dispatch: AppDispatch) => {
    try {
      await DataService.assets.setDefaultClosedCaption(languageId);
      await dispatch(refechClosedCaptions(entityId));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const uploadClosedCaptions =
  (
    file: File,
    languageId: number,
    entityId: number,
    accountId: number,
    userId: number,
    onUploadProgressHandler: OnUploadProgressHandler,
  ) =>
  async (dispatch: Dispatch) => {
    const sasConfig = {
      endpointToGetSasLink: `/api/assets/videos/${entityId}/closed-captions/sas`,
      sasRequest: {},
    };

    try {
      const response = await dataService.createSas(sasConfig, noop);
      const { uri, fileName } = response.data;
      const uploadOptions = {
        metadata: {
          fileName: encodeURIComponent(file.name),
          videoId: entityId.toString(),
          isAutoTranslate: "false",
          languageId: languageId.toString(),
          fileKey: fileName,
          accountId: accountId.toString(),
          actorId: userId.toString(),
        },
      };

      await fileUtils.uploadFileToBlob(uri, file, onUploadProgressHandler, noop, uploadOptions);
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const deleteClosedCaptions =
  (entityId: number, ids: number[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getMessageIds = (captionsIds: number[]) => async () => {
      const result = await DataService.assets.deleteClosedCaptions(entityId, captionsIds);
      return [result?.data.messageId];
    };

    const refetchClosedCaptionsData = () => {
      batch(() => {
        dispatch(refechClosedCaptions(entityId));
        dispatch(fetchAvailableVideoLanguages(entityId));
      });
    };

    const updateParams: UpdateParams = {
      id: "DeleteClosedCaptions",
      title: `Deletion of closed captions`,
      onCompleted: refetchClosedCaptionsData,
      getMessageIds: getMessageIds(ids),
      successTransientMessage: "Closed captions have been deleted!",
      failureTransientMessage: "Closed captions delete failed!",
    };

    await initializeBackgroundTask(updateParams, dispatch);
  };

const initializeBackgroundTask = async (updateParams: UpdateParams, dispatch: AppDispatch) => {
  /* istanbul ignore next */
  const updateFeatureProps: UpdateFeatureProps = {
    addOperation: (addOperationParams: AddOperationParamsV1) => dispatch(addOperationV1(addOperationParams)),
    sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
  };

  await backgroundTask.updateEntity(updateParams, updateFeatureProps);
};
