import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import { ReactComponent as AutoEnroll } from "../../../images/enrollment-automatic.svg";
import { ReactComponent as ManualEnroll } from "../../../images/enrollment-manual.svg";

export default function GroupEnrollIcon(props) {
  const { isWithAutoEnroll } = props;

  const popupTrigger = isWithAutoEnroll ? (
    <AutoEnroll className="auto-enroll-icon" />
  ) : (
    <ManualEnroll className="manual-enroll-icon" />
  );

  const popupHeader = isWithAutoEnroll ? "AUTOMATIC" : "MANUAL";

  const popupContent = isWithAutoEnroll
    ? "Users are automatically imported based on enrollment rules."
    : "Users are only added when you do so manually.";

  return (
    <Popup id="autoEnrollPopup" position="top left" trigger={<span>{popupTrigger}</span>} inverted>
      <Popup.Header>{popupHeader}</Popup.Header>
      <Popup.Content>{popupContent}</Popup.Content>
    </Popup>
  );
}

GroupEnrollIcon.defaultProps = {
  isWithAutoEnroll: false,
};

GroupEnrollIcon.propTypes = {
  isWithAutoEnroll: PropTypes.bool,
};
