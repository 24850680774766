import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "../../../../Application/slices/createFetchingItemsSlice";
import { type Account } from "../../../../../interfaces/Account";

const initialState: FetchingItemsState<Account> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const allAccountsOverviewSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.Accounts,
    name: "allOverview",
  },
  initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = allAccountsOverviewSlice.actions;

export default allAccountsOverviewSlice.reducer;

export type AccountsOverviewState = ReturnType<typeof allAccountsOverviewSlice.reducer>;
