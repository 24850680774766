import { type Group, type User, type BranchingQuestion } from "../../../../interfaces";
import { type ISurveyQuestion } from "../SurveyDetails/ContentForm/QuestionForm/types";
import { type DraftStateEntity } from "../../../../interfaces/draftStateEntity";
import { type Tag } from "../../../../interfaces/Tag";
import { type Removable, type WithId } from "../../../../types";
import { type AssetTypes } from "enums";
import { type FilterTypesMap, FilterType } from "utils/filterUtils";

export interface SurveyOverview {
  id: number;
  title: string;
  publisher: string;
  isDraft: boolean;
  isPurchased: boolean;
  dateCreated: string;
  dateModified: string;
  canBeDeleted: boolean;
  visibility: boolean;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
}

export interface PublishedSurveyOverview {
  id: number;
  title: string;
  publisherId: number;
  publisher: string;
  questionsCount?: number;
  branchingQuestion?: BranchingQuestion;
  cardType: AssetTypes;
}

export interface SurveyPayload {
  id?: number;
  title: string;
  description: string;
  endNoteText: string;
  endNoteTextEnabled: boolean;
}

export interface Content extends DraftStateEntity {
  surveyId: number;
  dateModified: string;
  questions: ISurveyQuestion<any>[];
}

interface Answer {
  id: string;
  answerOrder: number;
  answer: string;
  answerType: number;
}

interface OtherAnswerOptions {
  includeOtherAsAnswer: boolean;
  includeUserTextField: boolean;
  isUserTextFieldRequired: boolean;
}

export interface SurveyPublishedQuestion {
  id: string;
  questionOrder: number;
  question: string;
  questionType: SurveyQuestionType;
  isRequired: boolean;
  isBranching: boolean;
  answers: Answer[];
  otherAnswerOptions: OtherAnswerOptions;
}

/**
 * Key is SurveyQuestionId
 */
export type SurveyPublishedQuestions = Record<string, SurveyPublishedQuestion[]>;

export interface UpdateContentPayload {
  id: number;
  questions: ISurveyQuestion<any>[];
}

interface SurveyPeople extends Removable, WithId {
  priorityLevelId: number;
  dueDate?: Date;
  permissionIssuedDate: Date;
  editable: boolean;
  inherited: boolean;
}

export interface SurveyUser extends User, SurveyPeople {
  userId: number;
  isCompleted: boolean;
  expirationDate?: string;
}

export interface SurveyGroup extends Group, SurveyPeople {
  groupId: string;
}

export enum SurveyQuestionType {
  MultipleChoiceTextQuestion = 1,
  SingleChoiceTextQuestion,
  PictureQuestion,
}

export enum SurveyAnswerType {
  Text = 1,
  Picture,
}

export interface SurveyTags {
  [key: string]: Tag[];
}

export enum SurveyFiltersEnum {
  Publishers = "publisherIds",
  Tags = "tags",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  Packs = "packIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const surveyFilterTypes: FilterTypesMap<SurveyFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
  tags: FilterType.Array,
};

export type EntityMode = "create" | "edit";
