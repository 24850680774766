import { Title } from "components/listViewTemplates";
import { type ColumnOption } from "interfaces/columnOptions";
import { Popup, Icon } from "semantic-ui-react";
import dateTimeUtils from "utils/dateTimeUtils";
import { type CustomerView } from "../../types/models";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { EditLinkButton, RemoveLinkButton } from "components";

export enum Columns {
  Name = "Customer Name",
  Permissions = "Permissions",
  Added = "Added",
  Users = "Users",
}

export const columnToParamMap = {
  [Columns.Name.toLowerCase()]: "name",
  [Columns.Added.toLowerCase()]: "added",
};

const base: ColumnOption<CustomerView>[] = [
  {
    name: Columns.Name,
    width: 6,
    isSortable: true,
    render: (item) => <Title title={item.name} clamp={1} className="normal-color" />,
  },
  {
    name: Columns.Permissions,
    width: 4,
    isSortable: false,
    render: () => <></>,
  },
  {
    name: Columns.Added,
    width: 3,
    isSortable: true,
    render: (item) => dateTimeUtils.formatDate(item.added),
  },
  {
    name: Columns.Users,
    width: 2,
    isSortable: false,
    render: (item) => item.userCount,
  },
];

export const modalColumnOptions: ColumnOption<CustomerView>[] = [
  ...base,
  {
    name: "",
    width: 2,
    isSortable: false,
    render: (item) =>
      item.isAdded ? (
        <Popup
          hideOnScroll
          position="top center"
          size="small"
          className="popup-warning warning"
          content={"This customer is already associated with the template."}
          trigger={<Icon link className="fa fa-exclamation-circle" size="big" />}
        />
      ) : null,
  },
];

export const createColumnOptions = base;

export const editColumnOptions: (args: {
  onEditLicensingClick: (id: number) => void;
  onRemoveClick: (id: number) => void;
  disabled?: boolean;
}) => ColumnOption<CustomerView>[] = ({ onEditLicensingClick, onRemoveClick, disabled }) => {
  return [
    ...base,
    {
      name: "",
      width: 1,
      isSortable: false,
      render: (item, isChecked) => (
        <EllipsisPopupButton circle outlinedEllipsis disabled={isChecked || disabled}>
          {[
            <EditLinkButton
              key="edit"
              onClick={() => {
                onEditLicensingClick(item.id);
              }}
              text="Edit Licensing"
            />,
            <RemoveLinkButton onClick={() => onRemoveClick(item.id)} key="remove" />,
          ]}
        </EllipsisPopupButton>
      ),
    },
  ];
};
