import React, { useState, useEffect, useRef } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { intersection, isEmpty } from "lodash";

import { RolePermissions, type SortingDirection } from "../../../../enums";
import userListUtils from "../../../../utils/userListUtils";
import { type FiltersMap } from "../../../../utils/filterUtils";
import { usersFilter } from "components/filterForms/UsersFilterForm/usersFilter";
import FormFooter from "../../../../components/forms/FormFooter";
import RemoveContactConfirmationModal from "../../../../components/account/removeContactConfirmationModal/RemoveContactConfirmationModal";
import ChangeMainContactConfirmationModal from "../../../../components/account/changeMainContactConfirmationModal/ChangeMainContactConfirmationModal";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";
import AccountContactsList from "./AccountContactsList/AccountContactsList";
import type Contact from "../../../../interfaces/contact";
import { type AssignedUser } from "../../../../interfaces";
import { UsersAssignmentModalOneStep } from "../../../../components/assignmentModals/usersAssignmentModal/userAssignmentModalOneStep/UsersAssignmentModalOneStep";
import { type AssignedUserModal } from "../../../../components/assignmentModals/usersAssignmentModal/types";

import "./editContacts.scss";

export interface EditContactsProps {
  accountId: number;
  contactsList: {
    items: Array<Contact>;
    isLoading: boolean;
    itemsCount: number;
  };
  renderAddContactsButton: () => React.ReactElement;
  onFetchContacts: (accountId: number, skip: number, top: number, orderByParams: string, filterParams: string) => void;
  isAccountInfoLoading: boolean;
  isReadOnly: boolean;
  showAddContactsModal: boolean;
  potentialContacts: { items: AssignedUser[]; itemsCount: number; isLoading: boolean };
  fetchPotentialContacts: (
    accountId: number,
    skip: number,
    top: number,
    orderByParams: string,
    filterParams: FiltersMap,
    search?: string,
  ) => void;
  onCloseAddContactsModal: () => void;
  onConfirmAddContactsModal: (userIds: number[]) => void;
  onContactsUpdated: (changes: {
    mainContactId?: number;
    unassignPreviousMainContactRoles: boolean;
    removedContactIds: number[];
  }) => Promise<void>;
  addContactsSearch?: string;
  setAddContactsSearch: (search: string) => void;
  permissionsToAccount: RolePermissions[];
}

export default function EditContacts(props: EditContactsProps) {
  const [isDirty, setIsDirty] = useState(false);
  const [currentMainContactId, setMainContactId] = useState<number>();
  const [showRemoveContactConfirmationModal, setShowRemoveContactConfirmationModal] = useState<boolean>(false);
  const [contactIdWithOngoingChanges, setContactIdWithOngoingChanges] = useState<number>(0);
  const [contactIdsToRemove, setContactIdsToRemove] = useState<number[]>([]);
  const [unassignPreviousMainContactRoles, setUnassignPreviousMainContactRoles] = useState(false);

  const [showChangeMainContactConfirmationModal, setShowChangeMainContactConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    const mainContactId = getOriginalMainContactId();
    if (mainContactId) {
      setMainContactId(mainContactId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contactsList.items]);

  useEffect(() => {
    resetFormState();
  }, [props.contactsList.items]);

  const getOriginalMainContactId = () => props.contactsList.items.find((c) => c.isMain)?.id;

  const loadContactsPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: any,
  ) => {
    const orderParams = userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    const filterParams = usersFilter.buildFilterQuery(appliedFilter);

    props.onFetchContacts(props.accountId, skip, top, orderParams, filterParams);
  };

  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = async (searchTerm: string) => {
    props.setAddContactsSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  const onFetchPotentialContacts = (skip: number, top: number, orderByParams: string, filterParams: FiltersMap) => {
    const { fetchPotentialContacts, addContactsSearch, accountId } = props;
    fetchPotentialContacts(accountId, skip, top, orderByParams, filterParams, addContactsSearch);
  };

  const getAssignedUsers = (users: AssignedUser[]): AssignedUserModal[] => {
    return users.map(
      (user): AssignedUserModal => ({
        ...user,
        assignedToNumberOfEntities: user.isAssigned === true ? 1 : 0,
      }),
    );
  };

  const renderContactsAddModal = () => {
    const {
      onCloseAddContactsModal,
      onConfirmAddContactsModal,
      showAddContactsModal,
      potentialContacts: { items, itemsCount, isLoading },
      addContactsSearch,
    } = props;

    return (
      <UsersAssignmentModalOneStep
        loadPage={onFetchPotentialContacts}
        usersAmount={itemsCount}
        isListLoading={isLoading}
        users={getAssignedUsers(items)}
        onConfirm={onConfirmAddContactsModal}
        showModal={showAddContactsModal}
        onCancel={onCloseAddContactsModal}
        tooltipMessage="This user is already an account contact."
        hasPermissionPredicate={() => intersection(props.permissionsToAccount, [RolePermissions.UsersView]).length > 0}
        search={addContactsSearch}
        onSearchChanged={onSearchChange}
        setReloadListItems={createReloadListItems}
      />
    );
  };

  const onRemoveContactClick = (id: number) => {
    setShowRemoveContactConfirmationModal(true);
    setContactIdWithOngoingChanges(id);
  };

  const onRemoveContactConfirmed = () => {
    const newRemovedContactIds = [...contactIdsToRemove, contactIdWithOngoingChanges];
    setShowRemoveContactConfirmationModal(false);
    setContactIdsToRemove(newRemovedContactIds);

    onContactListChanged(currentMainContactId, newRemovedContactIds);
  };

  const renderRemoveContactConfirmationModal = () => (
    <RemoveContactConfirmationModal
      open={showRemoveContactConfirmationModal}
      onCancel={() => {
        setShowRemoveContactConfirmationModal(false);
      }}
      onContinue={onRemoveContactConfirmed}
    />
  );

  const onMainContactChangeClick = (id: number) => {
    if (!props.isReadOnly) {
      setShowChangeMainContactConfirmationModal(true);
      setContactIdWithOngoingChanges(id);
    }
  };

  const onMainContactChangeConfirmed = (unassignPrevMainContactRoles: boolean) => {
    setShowChangeMainContactConfirmationModal(false);
    setMainContactId(contactIdWithOngoingChanges);
    setUnassignPreviousMainContactRoles(unassignPrevMainContactRoles);

    onContactListChanged(contactIdWithOngoingChanges, contactIdsToRemove);
  };

  const renderChangeMainContactConfirmationModal = () => (
    <ChangeMainContactConfirmationModal
      open={showChangeMainContactConfirmationModal}
      onCancel={() => {
        setShowChangeMainContactConfirmationModal(false);
      }}
      onContinue={onMainContactChangeConfirmed}
    />
  );

  const resetFormState = () => {
    setIsDirty(false);
  };

  const onCancel = () => {
    resetFormState();
    const originalMainContactId = getOriginalMainContactId();
    setMainContactId(originalMainContactId);
    setContactIdsToRemove([]);
  };

  const onSave = async () => {
    const mainContactId = currentMainContactId !== getOriginalMainContactId() ? currentMainContactId : undefined;

    await props.onContactsUpdated({
      mainContactId: mainContactId,
      removedContactIds: contactIdsToRemove,
      unassignPreviousMainContactRoles: unassignPreviousMainContactRoles,
    });

    setContactIdsToRemove([]);
    resetFormState();
  };

  const onContactListChanged = (mainContactId: number | undefined, contactsToRemove: number[]) => {
    const originalMainContact = getOriginalMainContactId();

    setIsDirty(originalMainContact !== mainContactId || !!contactsToRemove.length);
  };

  const renderAccountContacts = () => {
    const { isReadOnly, renderAddContactsButton, contactsList } = props;
    const contactsCount = contactsList.itemsCount - contactIdsToRemove.length;

    return (
      <>
        <AccountContactsList
          contacts={contactsList.items.filter((item) => contactIdsToRemove.every((id) => id !== item.id))}
          contactsCount={contactsCount}
          isLoading={contactsList.isLoading}
          loadPage={loadContactsPage}
          renderAddContactsButton={renderAddContactsButton}
          isReadOnly={isReadOnly}
          onContactRemoved={onRemoveContactClick}
          onMainContactChanged={onMainContactChangeClick}
          mainContactId={currentMainContactId}
        />
        {renderContactsAddModal()}
        {renderRemoveContactConfirmationModal()}
        {renderChangeMainContactConfirmationModal()}

        <FormFooter isSaveBtnDisabled={!isDirty} isCancelBtnDisabled={!isDirty} onSave={onSave} onCancel={onCancel} />
        <PreventTransitionPrompt
          when={isDirty}
          title="Exit Without Saving?"
          message="You have unsaved changes. Are you sure you want to exit? All changes will be lost."
        />
      </>
    );
  };

  return props.isAccountInfoLoading ? (
    <Dimmer.Dimmable className="edit-contacts-dimmer" as={Segment} dimmed>
      <Dimmer active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  ) : (
    renderAccountContacts()
  );
}
