import { type RootState } from "../../../Application/globaltypes/redux";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ParentAccount } from "../../types";

export interface ParentAccountsState {
  items: ParentAccount[];
  isLoading: boolean;
  error?: string;
}

const initialState: ParentAccountsState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const parentAccountSlice = createSlice({
  name: "parentAccounts",
  initialState: initialState,
  reducers: {
    req(state: ParentAccountsState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: ParentAccountsState, action: PayloadAction<ParentAccount[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: ParentAccountsState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { req, got, err, reset } = parentAccountSlice.actions;

export const parentAccountsLoadingSelector = (state: RootState) => state.accounts.parentAccounts.isLoading;

export default parentAccountSlice.reducer;
export type parentAccountsStateSliceType = ReturnType<typeof parentAccountSlice.reducer>;
