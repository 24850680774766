import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { videosStateSelector } from "./index";
import { getMapTagsSelector } from "../../../../Application/selectors/tagSelectors";
import utils from "../../../../../components/common/video/plugins/settingsMenu/utils/languageUtils";

const getLanguages = (state: RootState) => videosStateSelector(state).edit.closedCaptions.languages;

export const normalizeDropdownItemsSelector = createSelector([getLanguages], (items) => {
  return items.map((item) => ({
    text: utils.getLanguageByCode(item.isoCode)?.name,
    value: item.id.toString(),
    disabled: !item.isAvailable,
  }));
});

export const getEntityIdSelector = (state: RootState) =>
  videosStateSelector(state).base.videoEntityStateReducer.entityId;

export const getAssignedClosedCaptions = (state: RootState) => videosStateSelector(state).edit.closedCaptions.captions;

export const videosTagsSelector = getMapTagsSelector(
  (state: RootState) => videosStateSelector(state).base.tagsBase.tags,
);
