import videojs from "video.js";

const MenuItem = videojs.getComponent("MenuItem");

class QhMenuItem extends MenuItem {
  addChild(component) {
    this.children_.push(component);
  }

  handleClick() {
    if (this.options_.onSelected) {
      this.options_.onSelected(this);
    }
  }

  // videojs implementatin not suitable for us
  createEl() {
    const el = super.createEl();
    el.innerHTML = "";
    el.classList.add("vjs-qh-menu-item");
    return el;
  }
}

export default QhMenuItem;
