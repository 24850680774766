export enum SelectionChangedType {
  Added = 0,
  Removed = 1,
  Cleared = 2,
}

export type SelectionChangedArgs<T> = {
  items: Array<T>;
  type: SelectionChangedType;
};

export type OnSelectionChanged<T> = (args: SelectionChangedArgs<T>) => void;

export function HandleOnSelectionChanged<T>(
  args: SelectionChangedArgs<T>,
  onAdded?: (input: T) => void,
  onRemoved?: (input: T) => void,
  onCleared?: () => void,
) {
  if (args.type === SelectionChangedType.Cleared) {
    onCleared?.();
    return;
  }
  const handler = args.type === SelectionChangedType.Added ? onAdded : onRemoved;
  if (handler) {
    args.items.forEach((item) => handler(item));
  }
}
