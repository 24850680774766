import { useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type FormikProps, withFormik } from "formik";
import { type RootState } from "../../../../../../Application/globaltypes/redux";
import { type VerificationSmtpAuthSendingProfileProps } from "../types";
import * as smtpAuthSendingProfileEntityStateActions from "../../state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import * as smtpAuthSendingProfileDetailsActions from "../../state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";
import { type ThreatDefenceSmtpAuthSendingProfileVerificationView } from "../../types/state";
import validationSchemas from "../../../../../../../utils/validationSchemas";
import VerificationForm from "../VerificationForm/VerificationForm";

export type VerificationAllProps = VerificationSmtpAuthSendingProfileProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileVerificationView>;

export const Verification = (props: VerificationAllProps) => {
  const valuesRef = useRef(props.values);
  useEffect(() => {
    valuesRef.current = props.values;
  }, [props.values]);

  useEffect(() => {
    props.acceptHandlers?.({
      onPrevious: onPrevious,
      onClose: onClose,
      onFinish: onFinish,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrevious = () => {
    props.detailsActions.saveSmtpAuthSendingProfileVerification(valuesRef.current);
  };

  const onFinish = () => {
    props.entityStateActions.publishDraftThreatDefenceSmtpAuthSendingProfileEntity(props.id);
  };

  const onClose = () => {
    props.detailsActions.clearThreatDefenceSmtpAuthSendingProfile();
  };

  return <VerificationForm {...props} />;
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: VerificationSmtpAuthSendingProfileProps) => {
  const sendingProfileEntityStateReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer;
  const sendingProfileDetailsReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer;

  return {
    id: ownProps.entityId || sendingProfileEntityStateReducer.entityId,
    isVerified: sendingProfileDetailsReducer.verification.isVerified,
    verification: sendingProfileDetailsReducer.verification,
    isLoading: sendingProfileDetailsReducer.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(smtpAuthSendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(smtpAuthSendingProfileDetailsActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.smtpAuthSendingProfileVerification,
  enableReinitialize: true,
  mapPropsToValues: (props: VerificationAllProps) => props.verification,
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(Verification);

const ConnectedComponent = connector(component);
export default ConnectedComponent;
