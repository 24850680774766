import { type NotifyStepSettings } from "../../../../interfaces";
import { type DistributedOpUpdateParams } from "../../../../interfaces/updateParams";
import { pluralize } from "../../../../utils/stringUtils";
import { type AppDispatch } from "../../../Application/globaltypes/redux";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import groupsDataService from "../../services/groupsDataService";
import usersDataService from "../../../Application/services/dataServices/usersDataService";

export const addUsersToGroups = (userIds: number[], groupIds: number[], notificationSettings?: NotifyStepSettings) => {
  return async (dispatch: AppDispatch) => {
    const usersCount = userIds.length;
    const groupsCount = groupIds.length;

    if (!usersCount || !groupsCount) {
      return;
    }

    const isSingle = usersCount === 1;
    const updateParams: DistributedOpUpdateParams = {
      id: `AddUsersToGroups_${Date.now()}`,
      title: `Adding ${pluralize("user", usersCount)} to ${pluralize("group", groupsCount)}`,
      getOperationProps: async () => {
        const { data } = await groupsDataService.addUsersToGroups(groupIds, userIds, notificationSettings);
        return {
          operationId: data.operationId,
          stepsIds: data.stepsIds,
          statusUrl: data.statusUrl,
        };
      },
      successTransientMessage: `${pluralize("User", usersCount)} ${isSingle ? "has" : "have"} been added to ${pluralize(
        "group",
        groupsCount,
      )} successfully`,
      failureTransientMessage: `Addition ${pluralize("user", usersCount)} to ${pluralize(
        "group",
        groupsCount,
      )} failed!`,
    };
    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};

export const deleteUsers = (userIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    const usersCount = userIds.length;

    if (!usersCount) {
      return;
    }

    const updateParams: DistributedOpUpdateParams = {
      id: `DeleteUsers`,
      title: `Delete ${pluralize("user", usersCount)}`,
      indeterminate: true,
      getOperationProps: async () => {
        const { data } = await usersDataService.deleteUsers(userIds);
        return data;
      },
      successTransientMessage: `${pluralize("User", usersCount)} deleted successfully`,
      failureTransientMessage: `${pluralize("User", usersCount)} delete failed!`,
    };
    backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
