const fontFamily = `"Helvetica", "Arial", "sans-serif" !important`;

export const inlineStyles = `

.es-button {
  mso-style-priority: 100 !important;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 200%;
  color: #000000;
  mso-line-height-rule: exactly;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: normal;
}

h1,
h1 a {
  font-size: 32px;
}

h2,
h2 a {
  font-size: 26px;
}

h3,
h3 a{
  font-size: 20px;
}

p {
  font-size: 14px !important;
  line-height: 200%;
  color: #000000;
  font-family: ${fontFamily};
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

p, ul li, ol li {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    mso-line-height-rule: exactly;
    font-family: ${fontFamily};
}

.es-button {
  color: #ffffff;
  display: inline-block;
  border-style: solid;
  border-radius: 0px;
  background: #288BED;
  font-family: ${fontFamily};
  font-weight: 700;
  font-style: normal;
  font-size: 14px !important;
  border-color: #288BED;
  border-width: 14px 23px 14px 23px;
  line-height: 16px;
  width: auto;
  text-align: center;
}

.es-button-border {
  border-style: solid solid solid solid;
  border-color: #288BED #288BED #288BED #288BED;
  background: #288BED;
  border-width: 0px 0px 0px 0px;
  display: inline-block;
  border-radius: 0px;
  width: auto;
}

s {
  text-decoration: line-through;
}

p a {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  mso-line-height-rule: exactly;
  font-size: 14px !important;
  font-family: ${fontFamily};
  line-height: 200%;
  color: #288BED;
  text-decoration: none !important;
}

li {
  font-size: 14px !important;
  margin-bottom: 15px;
  line-height: 200%;
}

table {
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
  border-collapse: collapse;
  border-spacing: 0px;
}

td,
th,
html,
body,
.es-wrapper {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  border: 0;
  outline: none;
  text-decoration: none;
  -ms-interpolation-mode: bicubic;
}

table tr {
  border-collapse: collapse;
}

p,
hr {
  margin: 0;
}




.es-left {
  float: left;
}

.es-right {
  float: right;
}

.es-p5 {
  padding: 5px;
}

.es-p5t {
  padding-top: 5px;
}

.es-p5b {
  padding-bottom: 5px;
}

.es-p5l {
  padding-left: 5px;
}

.es-p5r {
  padding-right: 5px;
}

.es-p10 {
  padding: 10px;
}

.es-p10t {
  padding-top: 10px;
}

.es-p10b {
  padding-bottom: 10px;
}

.es-p10l {
  padding-left: 10px;
}

.es-p10r {
  padding-right: 10px;
}

.es-p15 {
  padding: 15px;
}

.es-p15t {
  padding-top: 15px;
}

.es-p15b {
  padding-bottom: 15px;
}

.es-p15l {
  padding-left: 15px;
}

.es-p15r {
  padding-right: 15px;
}

.es-p20 {
  padding: 20px;
}

.es-p20t {
  padding-top: 20px;
}

.es-p20b {
  padding-bottom: 20px;
}

.es-p20l {
  padding-left: 20px;
}

.es-p20r {
  padding-right: 20px;
}

.es-p25 {
  padding: 25px;
}

.es-p25t {
  padding-top: 25px;
}

.es-p25b {
  padding-bottom: 25px;
}

.es-p25l {
  padding-left: 25px;
}

.es-p25r {
  padding-right: 25px;
}

.es-p30 {
  padding: 30px;
}

.es-p30t {
  padding-top: 30px;
}

.es-p30b {
  padding-bottom: 30px;
}

.es-p30l {
  padding-left: 30px;
}

.es-p30r {
  padding-right: 30px;
}

.es-p35 {
  padding: 35px;
}

.es-p35t {
  padding-top: 35px;
}

.es-p35b {
  padding-bottom: 35px;
}

.es-p35l {
  padding-left: 35px;
}

.es-p35r {
  padding-right: 35px;
}

.es-p40 {
  padding: 40px;
}

.es-p40t {
  padding-top: 40px;
}

.es-p40b {
  padding-bottom: 40px;
}

.es-p40l {
  padding-left: 40px;
}

.es-p40r {
  padding-right: 40px;
}

.es-menu td {
  border: 0;
}

.es-menu td a img {
  display: inline !important;
}



.es-menu td a {
  text-decoration: none;
  display: block;
}

.es-wrapper {
  width: 100%;
  height: 100%;
  background-image: ;
  background-repeat: repeat;
  background-position: center top;
}

.es-wrapper-color {
  background-color: #f6f6f6;
}

.es-content-body {
  background-color: #ffffff;
}

.es-content-body p,
.es-content-body ul li,
.es-content-body ol li {
  color: #000000;
}

.es-header {
  background-color: transparent;
  background-image: none;
  background-repeat: repeat;
  background-position: center top;
}

.es-header-body {
  background-color: #ffffff;
}

.es-header-body ul li,
.es-header-body ol li {
  color: #000000;
  font-size: 14px;
}

.es-footer {
  background-color: transparent;
  background-image: none;
  background-repeat: repeat;
  background-position: center top;
}

.es-footer-body {
  background-color: #ffffff;
}

.es-footer-body p,
.es-footer-body ul li,
.es-footer-body ol li {
  color: #000000;
  font-size: 11px;
}

.es-footer-body a {
  color: #288BED;
  font-size: 11px;
}

.es-infoblock,
.es-infoblock p,
.es-infoblock ul li,
.es-infoblock ol li {
  line-height: 120%;
  font-size: 12px;
  color: #cccccc;
}

.es-infoblock a {
  font-size: 12px;
  color: #999999;
}














/* RESPONSIVE STYLES Please do not delete and edit CSS styles below. If you don't need responsive layout, please delete this section. */
@media only screen and (max-width: 600px) {

  h1 {
    font-size: 30px !important;
    text-align: center;
    line-height: 120% !important;
  }

  h2 {
    font-size: 26px !important;
    text-align: center;
    line-height: 120% !important;
  }

  h3 {
    font-size: 20px !important;
    text-align: center;
    line-height: 120% !important;
  }

  h1 a {
    font-size: 30px !important;
  }

  h2 a {
    font-size: 26px !important;
  }

  h3 a {
    font-size: 20px !important;
  }

  *[class="gmail-fix"] {
    display: none !important;
  }

  .es-m-txt-c,
  .es-m-txt-c h1,
  .es-m-txt-c h2,
  .es-m-txt-c h3 {
    text-align: center !important;
  }

  .es-m-txt-r,
  .es-m-txt-r h1,
  .es-m-txt-r h2,
  .es-m-txt-r h3 {
    text-align: right !important;
  }

  .es-m-txt-l,
  .es-m-txt-l h1,
  .es-m-txt-l h2,
  .es-m-txt-l h3 {
    text-align: left !important;
  }

  .es-m-txt-r img,
  .es-m-txt-c img,
  .es-m-txt-l img {
    display: inline !important;
  }

  .es-adaptive table,
  .es-btn-fw,
  .es-btn-fw-brdr,
  .es-left,
  .es-right {
    width: 100% !important;
  }

  .es-content table,
  .es-header table,
  .es-footer table,
  .es-content,
  .es-footer,
  .es-header {
    width: 100% !important;
    max-width: 600px !important;
  }

  .es-adapt-td {
    display: block !important;
    width: 100% !important;
  }

  .adapt-img {
    width: 100% !important;
    height: auto !important;
  }

  .es-m-p0 {
    padding: 0px !important;
  }

  .es-m-p0r {
    padding-right: 0px !important;
  }

  .es-m-p0l {
    padding-left: 0px !important;
  }

  .es-m-p0t {
    padding-top: 0px !important;
  }

  .es-m-p0b {
    padding-bottom: 0 !important;
  }

  .es-m-p20b {
    padding-bottom: 20px !important;
  }

  .es-mobile-hidden,
  .es-hidden {
    display: none !important;
  }

  tr.es-desk-hidden,
  td.es-desk-hidden,
  table.es-desk-hidden {
    display: table-row !important;
    width: auto !important;
    overflow: visible !important;
    float: none !important;
    max-height: inherit !important;
    line-height: inherit !important;
  }

  .es-desk-menu-hidden {
    display: table-cell !important;
  }

  table.es-table-not-adapt {
    width: auto !important;
  }

  table.es-social {
    display: inline-block !important;
  }

  table.es-social td {
    display: inline-block !important;
  }
}

/* END RESPONSIVE STYLES */
`;
