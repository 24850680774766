import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";

import { isEmpty } from "lodash";
import { MultiStateButton } from "../../../../../components";
import {
  type ThreatDefenceSendingProfileOverviewState,
  type ThreatDefenceSendingProfilesOverviewOwnProps,
} from "./types";

import { type RootState } from "../../../../Application/globaltypes/redux";
import columnOptions from "./columnOptions";

import { OverviewHeader } from "../../../../../components/sectionHeader";
import ViewType from "../../../../../enums/ViewType";
import { SortingDirection } from "../../../../../enums";
import { ItemsView } from "../../../../../views";

import { type ThreatDefenceSendingProfileOverview } from "../types/state";

import SendingProfilesNoResults from "../../../../../views/library/sendingProfiles/Overview/ThreatDefenceSendingProfilesNoResults";
import * as sendingProfilesOverviewActions from "../state/actions/threatDefenceSendingProfilesOverviewActions";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import ThreatDefenceSendingProfilesOverviewTableBuilder from "./ThreatDefenceSendingProfileOverviewTableBuilder";
import { withRouter, type WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const defaultSortColumn = "datecreated";

export type ThreatDefenceSendingProfilesOverviewProps = ThreatDefenceSendingProfilesOverviewOwnProps &
  PropsFromRedux &
  WithRouterProps;

export class ThreatDefenceSendingProfilesOverview extends Component<
  ThreatDefenceSendingProfilesOverviewProps,
  ThreatDefenceSendingProfileOverviewState
> {
  constructor(props: ThreatDefenceSendingProfilesOverviewProps) {
    super(props);
    this.state = {
      selectedSendingProfileIds: [],
    };
  }

  componentDidMount() {
    this.props.sendingProfilesOverviewActions.hasAccountDirectSendSendingProfileAsync();
  }

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedSendingProfileIds: ids });
  };

  fetchSendingProfiles = (skip: number, top: number, orderBy: string) => {
    this.props.sendingProfilesOverviewActions.getThreatDefenceSendingProfilesAsync(top, skip, orderBy);
  };

  getSendingProfiles = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    const orderBy = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );

    this.fetchSendingProfiles(skip ?? 0, top ?? 10, orderBy);
  };

  getMenuItems = () => {
    const { navigate, hasAccountCreatedDirectSendProfile } = this.props;

    return [
      {
        text: "Direct Send (Recommended)",
        disabled: hasAccountCreatedDirectSendProfile,
        onClick: () => {
          navigate("direct-send/create");
        },
      },
      {
        text: "SMTP Auth",
        onClick: () => {
          navigate("smtp-auth/create");
        },
      },
    ];
  };

  renderCreateSendingProfileBtn = (): React.ReactElement | null => {
    const { selectedSendingProfileIds } = this.state;
    return isEmpty(selectedSendingProfileIds) ? (
      <div className="create-sending-profile create-button">
        <MultiStateButton
          id="create-sending-Profile"
          trigger="Create Sending Profile"
          simple
          items={this.getMenuItems()}
        />
      </div>
    ) : null;
  };

  render() {
    const { sendingProfiles, totalCount, isLoading } = this.props;
    return (
      <section className="nested-content sendingProfiles">
        <OverviewHeader title="Sending Profiles">{this.renderCreateSendingProfileBtn()}</OverviewHeader>
        <ItemsView
          items={sendingProfiles}
          viewType={ViewType.LIST}
          searchPlaceholder="Search for Sending Profiles..."
          getData={this.getSendingProfiles}
          noResultsContent={<SendingProfilesNoResults createButton={this.renderCreateSendingProfileBtn()} />}
          isLoading={isLoading}
          isAllDataLoaded={false}
          selectedIds={this.state.selectedSendingProfileIds}
          className="sendingProfiles-items-view"
          filterOptionsLoading={false}
          applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          buildTableBody={(sendingProfile: ThreatDefenceSendingProfileOverview) => (
            <ThreatDefenceSendingProfilesOverviewTableBuilder sendingProfile={sendingProfile} {...this.props} />
          )}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          sortingColumnName={defaultSortColumn}
          sortingDirection={SortingDirection.Descending}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.threatDefenceSendingProfiles.threatDefenceSendingProfilesOverview;
  const direct = state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer;
  const smtp = state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer;
  return {
    sendingProfiles: overview.sendingProfiles,
    isLoading: overview.isLoading || direct.changingEntityState || smtp.changingEntityState,
    totalCount: overview.totalCount,
    hasAccountCreatedDirectSendProfile: overview.hasAccountCreatedDirectSendProfile,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendingProfilesOverviewActions: bindActionCreators(sendingProfilesOverviewActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(ThreatDefenceSendingProfilesOverview));
export default ConnectedComponent;
