export enum CreateVideoSteps {
  Configuration,
  Settings,
  ClosedCaptions,
  People,
  Notify,
}

export const CreateVideoStepConfig = {
  [CreateVideoSteps.Configuration]: { showAutoSaveOnTab: true },
  [CreateVideoSteps.Settings]: { showAutoSaveOnTab: false },
  [CreateVideoSteps.ClosedCaptions]: { showAutoSaveOnTab: false },
  [CreateVideoSteps.People]: { showAutoSaveOnTab: false },
  [CreateVideoSteps.Notify]: { showAutoSaveOnTab: false },
};
