import { createReducer } from "../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/threatDefenceCampaignDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/threatDefenceCampaignEntityStateActionTypes";
import {
  type ThreatDefenceCampaignDetailsState,
  type ThreatDefenceCampaignView,
  type ThreatDefenceCampaign,
  type ThreatDefenceCampaignContent,
  type ThreatDefenceCampaignRun,
} from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../../interfaces/redux";
import miscUtils from "../../../../../../utils/miscellaneousUtils";

const initialState: ThreatDefenceCampaignDetailsState = {
  campaign: {
    id: undefined,
    isDraft: true,
    name: "",
    dateModified: undefined,
    senderProfile: -1,
    senderName: "",
    senderEmail: "",
    campaignTypeId: -1,
    hasBeenPublished: false,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
  },
  content: {
    id: -1,
    landingPageId: undefined,
    emailTemplateId: undefined,
  },
  runsOverview: {
    runs: [],
    isLoading: false,
    totalCount: 0,
  },
  isLoading: false,
  isCampaignLoaded: false,
  isContentLoaded: false,
};

const normalizeCampaignPayload = (
  initial: ThreatDefenceCampaignView,
  payload: ThreatDefenceCampaign,
): ThreatDefenceCampaignView => ({
  ...initial,
  ...payload,
  labels: miscUtils.normalizeDropdownItems(payload.labels),
  softwareApplications: miscUtils.normalizeDropdownItems(payload.softwareApplications),
});

const getHandlers = (failureHandler: Function) => {
  const {
    getThreatDefenceCampaignBegin,
    getThreatDefenceCampaignSuccess,
    getThreatDefenceCampaignFailure,
    getThreatDefenceCampaignContentBegin,
    getThreatDefenceCampaignContentSuccess,
    getThreatDefenceCampaignContentFailure,
    getThreatDefenceCampaignRunsBegin,
    getThreatDefenceCampaignRunsSuccess,
    getThreatDefenceCampaignRunsFailure,
  } = actionTypes;

  const getThreatDefenceCampaignBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceCampaignSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<ThreatDefenceCampaign>,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    campaign: {
      ...normalizeCampaignPayload(state.campaign, action.payload),
    },
    isCampaignLoaded: true,
    isLoading: false,
  });

  const getThreatDefenceCampaignContentBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceCampaignContentSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<ThreatDefenceCampaignContent>,
  ): ThreatDefenceCampaignDetailsState => {
    const content = action.payload;
    return {
      ...state,
      isContentLoaded: true,
      isLoading: false,
      content,
    };
  };

  const getThreatDefenceCampaignRunsBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    runsOverview: {
      ...state.runsOverview,
      isLoading: true,
    },
  });

  const getThreatDefenceCampaignRunsSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceCampaignRun>>,
  ): ThreatDefenceCampaignDetailsState => {
    return {
      ...state,
      runsOverview: {
        runs: action.payload.items,
        totalCount: action.payload.totalCount,
        isLoading: false,
      },
    };
  };

  return {
    [getThreatDefenceCampaignBegin]: getThreatDefenceCampaignBeginHandler,
    [getThreatDefenceCampaignSuccess]: getThreatDefenceCampaignSuccessHandler,
    [getThreatDefenceCampaignFailure]: failureHandler,
    [getThreatDefenceCampaignContentBegin]: getThreatDefenceCampaignContentBeginHandler,
    [getThreatDefenceCampaignContentSuccess]: getThreatDefenceCampaignContentSuccessHandler,
    [getThreatDefenceCampaignContentFailure]: failureHandler,
    [getThreatDefenceCampaignRunsBegin]: getThreatDefenceCampaignRunsBeginHandler,
    [getThreatDefenceCampaignRunsSuccess]: getThreatDefenceCampaignRunsSuccessHandler,
    [getThreatDefenceCampaignRunsFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const {
    fetchLockedThreatDefenceCampaignBegin,
    fetchLockedThreatDefenceCampaignSuccess,
    fetchLockedThreatDefenceCampaignFailure,
  } = entityStateActionTypes;

  const fetchLockedThreatDefenceCampaignBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedThreatDefenceCampaignSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    campaign: {
      ...state.campaign,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedThreatDefenceCampaignBegin]: fetchLockedThreatDefenceCampaignBeginHandler,
    [fetchLockedThreatDefenceCampaignSuccess]: fetchLockedThreatDefenceCampaignSuccessHandler,
    [fetchLockedThreatDefenceCampaignFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const {
    publishThreatDefenceCampaignBegin,
    publishThreatDefenceCampaignSuccess,
    publishThreatDefenceCampaignFailure,
  } = entityStateActionTypes;

  const publishThreatDefenceCampaignBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishThreatDefenceCampaignSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    campaign: {
      ...state.campaign,
      isDraft: false,
    },
    isLoading: false,
  });

  return {
    [publishThreatDefenceCampaignBegin]: publishThreatDefenceCampaignBeginHandler,
    [publishThreatDefenceCampaignSuccess]: publishThreatDefenceCampaignSuccessHandler,
    [publishThreatDefenceCampaignFailure]: failureHandler,
  };
};

const discardHandlers = (failureHandler: Function) => {
  const { discardCampaignBegin, discardCampaignSuccess, discardCampaignFailure } = entityStateActionTypes;

  const discardCampaignBeginHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: true,
  });

  const discardCampaignSuccessHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
  });

  return {
    [discardCampaignBegin]: discardCampaignBeginHandler,
    [discardCampaignSuccess]: discardCampaignSuccessHandler,
    [discardCampaignFailure]: failureHandler,
  };
};

const threatDefenceCampaignDetailsHandlers = () => {
  const { saveThreatDefenceCampaign, saveThreatDefenceCampaignContent, clearThreatDefenceCampaign, setCampaign } =
    actionTypes;

  const failureHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<Error>,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveThreatDefenceCampaignHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<ThreatDefenceCampaignView>,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    campaign: action.payload,
  });

  const saveThreatDefenceCampaignContentHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<ThreatDefenceCampaignContent>,
  ): ThreatDefenceCampaignDetailsState => {
    const payload = action.payload;
    return {
      ...state,
      campaign: {
        ...state.campaign,
      },
      content: payload,
    };
  };

  const clearThreatDefenceCampaignHandler = (
    state: ThreatDefenceCampaignDetailsState,
  ): ThreatDefenceCampaignDetailsState => ({
    ...initialState,
    runsOverview: state.runsOverview,
  });

  const setCampaignHandler = (
    state: ThreatDefenceCampaignDetailsState,
    action: PayloadAction<ThreatDefenceCampaign>,
  ): ThreatDefenceCampaignDetailsState => ({
    ...state,
    campaign: {
      ...normalizeCampaignPayload(state.campaign, action.payload),
    },
  });

  return {
    [saveThreatDefenceCampaign]: saveThreatDefenceCampaignHandler,
    [saveThreatDefenceCampaignContent]: saveThreatDefenceCampaignContentHandler,
    [clearThreatDefenceCampaign]: clearThreatDefenceCampaignHandler,
    [setCampaign]: setCampaignHandler,
    ...getHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
    ...discardHandlers(failureHandler),
  };
};

export const threatDefenceCampaignDetails = createReducer(initialState, [threatDefenceCampaignDetailsHandlers]);
