import { FilterFormBuilder } from "components/filterForms/FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "enums/filterViewTypes";
import { type FilterFormPropsBase } from "components/filterForms/types/props";
import { type FC } from "react";
import { TemplatesFiltersEnum } from "../types/models";

export interface FilterFormProps extends FilterFormPropsBase<TemplatesFiltersEnum> {}

export const TemplatesFilterForm: FC<FilterFormProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={[{ label: "Date Created", type: FilterViewTypes.DateRange, propertyName: TemplatesFiltersEnum.DateCreated }]}
  />
);
