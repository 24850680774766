import * as Yup from "yup";

import { labelValues as labels, softwareApplicationValues as softwareApplications } from "./tagsValidationSchema";
import moment from "moment";
import { type ScheduleType } from "../../features/Library/Events/types/models";
import { requiredTextFieldMinMax } from "./commonValidationSchemas";

const titleLengths = {
  min: 3,
  max: 64,
};

const descriptionLengths = {
  min: 3,
  max: 256,
};

const title = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Title is required")
    .min(titleLengths.min, `Title must have at least ${titleLengths.min} characters`)
    .max(titleLengths.max, `Title must be maximum of ${titleLengths.max} characters long`);

const description = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Description is required")
    .min(descriptionLengths.min, `Description must have at least ${titleLengths.min} characters`)
    .max(descriptionLengths.max, `Description must be maximum of ${descriptionLengths.max} characters long`);

const requiredThumbnailUrl = Yup.string().trim().required("Image is required");

/* istanbul ignore next */
const dateIsAfterCurrentDateInTZ = (
  date: Date | undefined,
  timeZone: string,
  granularity: moment.unitOfTime.StartOf | undefined = undefined,
) => moment().tz(timeZone).isSameOrBefore(moment(date).tz(timeZone, true), granularity);

export const reminderSchema = Yup.object().shape({
  title: requiredTextFieldMinMax("Title", 3, 128),
  description: requiredTextFieldMinMax("Description", 3, 256),
  time: Yup.number()
    .required("This field is required")
    .min(1, "This field may only contain positive numbers")
    .max(999, "Time may have a maximum of 3 characters"),
  scheduleType: Yup.mixed<ScheduleType>().required("This field is required"),
});

export const eventInfo = Yup.object().shape({
  title: title(),
  description: description(),
  thumbnailUrl: requiredThumbnailUrl,
});

export const eventSettings = Yup.object().shape({
  labels,
  softwareApplications,
});

/* istanbul ignore next */
const getSessionsSchema = (create: boolean) =>
  Yup.object({
    sessions: Yup.array()
      .min(1, "Should contain at least one session")
      .of(
        Yup.object().shape({
          webinarUrl: Yup.string().url("Invalid url").trim().required("Url is required"),
          startDate: (() => {
            const base = Yup.date().required("Start date is required").typeError("Invalid date format");
            return !create
              ? base
              : base.test(
                  "startNotBeInPast",
                  "Start date cannot be in the past",
                  (startDate: Date | undefined, context: Yup.TestContext<Record<string, any>>) =>
                    dateIsAfterCurrentDateInTZ(startDate, context.parent.timeZone, "day"),
                );
          })(),
          startsAt: Yup.string().required("Starts at is required"),
          endsAt: Yup.mixed()
            .required("Ends at is required")
            .test(
              "endLaterThenStart",
              "End time should be later than start time",
              (value, context) => {
                const endsValue = moment(value, ["h:mm A"]);
                const startsValue = moment(context.parent.startsAt, ["h:mm A"]);
                return endsValue > startsValue;
              },
            ),
          isPeriodic: Yup.boolean(),
          recurrenceSession: Yup.mixed().when("isPeriodic", {
            is: true,
            then: (_) => Yup.object().shape({
              periodType: Yup.number(),
              periodValue: Yup.number().min(1, "Period value is required"),
              seriesEndDate: Yup.mixed().when(
                "periodType",
                (_, node): Yup.MixedSchema<any> => {
                  return node
                    ? Yup.date()
                        .typeError("Invalid format date")
                        .test("endNotBeInPast", "End date cannot be in the past", function (endDate) {
                          // @ts-ignore
                          const { value: sessionValue } = this.from[1];
                          return dateIsAfterCurrentDateInTZ(endDate, sessionValue.timeZone, "day");
                        })
                        .test(
                          "dateEndGreaterThanDateStart",
                          "End date should be greater than start date",
                          function (seriesEndDate) {
                            // @ts-ignore
                            const { value: sessionValue } = this.from[1];
                            return new Date(sessionValue.startDate) < seriesEndDate!;
                          },
                        )
                    : Yup.date();
                },
              ),
              weekDays: Yup.array()
                .of(Yup.number())
                .when("periodType", {
                  is: 1,
                  then: (_) => Yup.array().min(1, "at least one day should be selected"),
                }),
            }),
          }),
        }),
      ),
  });

export const eventSessionsSchemaCreate = getSessionsSchema(true);
export const eventSessionsSchemaEdit = getSessionsSchema(false);
