import { type FC, useRef, useCallback } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../Application/globaltypes/redux";
import RolePermissions from "../../../../enums/rolePermissions";
import * as rolesOverviewActions from "../../RolesOverview/state/rolesOverviewActions";
import { UsersAssignmentModalOneStep } from "../../../../components/assignmentModals/usersAssignmentModal/userAssignmentModalOneStep/UsersAssignmentModalOneStep";
import { type AssignedUserRoles } from "../../../../interfaces/assignedUser";
import { usersFilter } from "components/filterForms/UsersFilterForm/usersFilter";
import { isEmpty } from "lodash";

export interface UsersToRolesAssignmentModalOwnProps {
  showModal: boolean;
  focusedRolesIds: number[];
  onConfirm(selectedUsersIds: number[]): void;
  onCancel(): void;
  addUsersSearch?: string;
}

export type UsersToRolesAssignmentModalProps = UsersToRolesAssignmentModalOwnProps & PropsFromRedux;

export const UsersToRolesAssignmentModal: FC<UsersToRolesAssignmentModalProps> = ({
  focusedRolesIds,
  showModal,
  addUsersSearch,
  fetchRoleUsersToAdd,
  setAddUsersSearch,
  usersToAdd,
  ...modalProps
}) => {
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const createReloadListItems = useCallback((reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  }, []);

  const onSearchChange = useCallback(
    (searchTerm: string) => {
      setAddUsersSearch(searchTerm);
      reloadListItems.current?.(isEmpty(searchTerm));
    },
    [setAddUsersSearch],
  );

  const loadUsers = (skip: any, top: any, orderByParams: any, filterParams: any) => {
    const filter = addUsersSearch ? filterParams : usersFilter.buildFilterQuery(filterParams);
    fetchRoleUsersToAdd(focusedRolesIds, skip, top, orderByParams, filter, addUsersSearch);
  };

  const getAssignedUsers = (users: AssignedUserRoles[], selectedRoleIds: number[]) => {
    return users.map((user) => ({
      ...user,
      isAssigned: selectedRoleIds.every((roleId) => user.assignedRoleIds.includes(roleId)),
      assignedToNumberOfEntities: user.assignedRoleIds.filter((id) => selectedRoleIds.includes(id)).length,
    }));
  };

  return (
    <UsersAssignmentModalOneStep
      {...modalProps}
      search={addUsersSearch}
      onSearchChanged={onSearchChange}
      setReloadListItems={createReloadListItems}
      showModal={showModal}
      loadPage={loadUsers}
      usersAmount={usersToAdd.itemsCount}
      isListLoading={usersToAdd.isLoading}
      users={getAssignedUsers(usersToAdd.items, focusedRolesIds)}
      hasPermissionPredicate={(userPermissions: string[]) => userPermissions.includes(RolePermissions.UsersView)}
      assignmentEntityType="role"
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { usersToAdd } = state.people.rolesOverview;
  return {
    usersToAdd,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRoleUsersToAdd: bindActionCreators(rolesOverviewActions.fetchRoleUsersToAdd, dispatch),
    setAddUsersSearch: bindActionCreators(rolesOverviewActions.setAddUsersSearch, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UsersToRolesAssignmentModal);
export default ConnectedComponent;
