import { useEffect, useRef, useState } from "react";
import { convertToDescendant, convertToHtmlDocument, deserialize } from "./utils";
import { BlockFormats, type CustomElement } from "./types";
import { type BaseEditor, createEditor, type Descendant } from "slate";
import { type ReactEditor, withReact } from "slate-react";

const initialValue: CustomElement[] = [
  {
    type: BlockFormats.Paragraph,
    children: [{ text: "" }],
  },
];

interface Handlers {
  onChange(newValue: Descendant[]): void;
}

type Utils = {
  deserialize: typeof deserialize;
  convertToHtmlDocument: typeof convertToHtmlDocument;
  convertToDescendant: typeof convertToDescendant;
};
type Editor = BaseEditor & ReactEditor;
type HookResult = [Editor, Descendant[], Handlers, Utils];

export const useRichTextEditor = (html?: string): HookResult => {
  const editorRef = useRef<Editor>(withReact(createEditor()));
  const [editorValue, setEditorValue] = useState<Descendant[]>(initialValue);

  useEffect(() => {
    if (html) {
      setEditorValue(convertToDescendant(html));
    }
    if (!editorRef.current) {
      editorRef.current = withReact(createEditor());
    }
  }, [html]);

  return [
    editorRef.current,
    editorValue,
    { onChange: (newValue: Descendant[]) => setEditorValue(newValue) },
    { deserialize, convertToHtmlDocument, convertToDescendant },
  ];
};
