import { useQuery } from "@tanstack/react-query";
import { type CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { type DataPoint } from "components/charts/types/HorizontalBarChart";
import {
  type PerformanceFilter,
  type QueryFilterFlow,
  completes,
  completesColor,
  lineChartFactory,
  roundToTwoDigits,
  starts,
  startsColor,
} from "features/Library/Common/utils/performanceUtils";

import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { mockFlowEmptyEngagement } from "features/Reporting/mocks/contentData";

const fetchDailyData = async ({ queryKey }: QueryFilterFlow) => {
  const [, filter] = queryKey;
  const result = await contentReportingService.getFlowsDailyData(filter);
  return lineChartFactory(result.data);
};

export const fetchEngagementQuery = async ({ queryKey }: QueryFilterFlow) => {
  const [, filter] = queryKey;

  const result = await contentReportingService.getFlowsEngagement(filter);
  const data = {
    ...mockFlowEmptyEngagement,
    ...result.data,
  };

  const barData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: data.Started,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: data.Completed,
      fillColor: completesColor,
    },
  ];

  const cardDataIndividual: CardReportingItem[] = [
    {
      statDescription: starts,
      stat: data.Started,
      popupHeader: starts,
      popupBody: "Number of users who have started this flow but not yet completed it.",
      width: "15.9rem",
    },
    {
      statDescription: completes,
      stat: data.Completed,
      popupHeader: completes,
      popupBody: "Number of users who have completed this flow.",
      width: "12.3rem",
    },
    {
      statDescription: "Retaken",
      stat: data.Retaken,
      popupHeader: "Retaken",
      popupBody: "Total number of times this flow has been retaken by users.",
      width: "15.9rem",
    },
  ];

  const cardData: CardReportingItem[] = [
    {
      stat: data.Customers,
      statDescription: "Customers",
    },
    {
      stat: roundToTwoDigits(data.AverageCustomerSize),
      statDescription: "Avg. Customer Size",
    },
    {
      stat: data.Flows,
      statDescription: "Flows",
    },
    {
      stat: data.Users,
      statDescription: "Users",
    },
    {
      stat: roundToTwoDigits(data.AverageFlowsUser),
      statDescription: "Avg. Flows/User",
    },
  ];

  return { ...data, barData, cardData, cardDataIndividual };
};

export const useFlowLineChartQuery = (filter: PerformanceFilter | null, aggregation: string, flowId?: string | number) => {
  return useQuery({
    queryKey: ["FlowReportDailyData", { ...filter, aggregation, flowId }],
    queryFn: fetchDailyData,
    enabled: !!filter,
  });
};

export const useFlowEngagementQuery = (filter: PerformanceFilter | null, flowId?: string | number) => {
  return useQuery({
    queryKey: ["FlowReportEngagement", { ...filter, flowId }],
    queryFn: fetchEngagementQuery,
    enabled: !!filter,
  });
};
