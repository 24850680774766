import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { type RequestState, RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { mockEmptyVideoEngagementData } from "../../types/mocks";

interface LineDataEvents {
  NotStartedCount: number;
  StartedCount: number;
  CompletedCount: number;
  CumulativeCompletedCount: number;
  CumulativeNotStartedCount: number;
  CumulativeStartedCount: number;
}

export type EngagementLineData = {
  Date: string;
  Events: LineDataEvents;
}[];

export type VideoEngagementData = {
  CompletedCount: number;
  CompletionRate: number; // between 0 & 1
  Customers: number; // unused on asset level
  StartedCount: number;
  VideoDuration: number; // float, in seconds
  Videos: number;
};

export type EngagementLineStatus = "Complete" | "In Progress" | "Not Started";

export type EngagementPeopleData = {
  DateWatched: string;
  Status: EngagementLineStatus;
  UserDepartment: string;
  UserEmail: string;
  UserFirstName: string;
  UserId: number;
  UserLastName: string;
  id: string;
  // v3
  VideoCompletions?: number;
  VideoStarts?: number;
  // v2
  videoCompletions?: number;
  videoStarts?: number;
}[];

export type EngagementPeopleDataSansId = Omit<EngagementPeopleData, "id">;

const namespace = ReducerNamespaceTypes.Videos;
const entityPrefix = ReducerEntityPrefixTypes.Videos_Performance;

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

const initialRequestStateObject: RequestState<any> = {
  status: RStatus.Idle,
  value: {},
  errorMessage: undefined,
};

export interface VideoPerformanceState {
  engagementLineData: RequestState<EngagementLineData>;
  engagementFunnelData: RequestState<VideoEngagementData>;
  engagementPeopleData: RequestState<EngagementPeopleData>;
}

export const initialState: VideoPerformanceState = {
  engagementLineData: initialRequestState,
  engagementFunnelData: {
    ...initialRequestStateObject,
    value: mockEmptyVideoEngagementData,
  },
  engagementPeopleData: initialRequestState,
};

const videoPerformanceSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqEngagementLineData(state) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Pending,
      };
    },
    reqEngagementFunnelData(state) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Pending,
      };
    },
    reqEngagementPeopleData(state) {
      state.engagementPeopleData = {
        ...state.engagementPeopleData,
        status: RStatus.Pending,
      };
    },
    setEngagementLineData(state, action: PayloadAction<{ data: EngagementLineData; }>) {
      state.engagementLineData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementFunnelData(
      state,
      action: PayloadAction<{ data: VideoPerformanceState["engagementFunnelData"]["value"]; }>,
    ) {
      state.engagementFunnelData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementPeopleData(state, action: PayloadAction<{ data: EngagementPeopleData; }>) {
      state.engagementPeopleData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementFunnelError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementPeopleError(
      state,
      action: PayloadAction<{
        errorMessage: string;
      }>,
    ) {
      state.engagementPeopleData = {
        ...state.engagementPeopleData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return {
        engagementLineData: initialRequestState,
        engagementFunnelData: {
          ...initialRequestStateObject,
          value: mockEmptyVideoEngagementData,
        },
        engagementPeopleData: initialRequestState,
      };
    },
  },
});

// Actions
export const {
  reqEngagementLineData,
  reqEngagementFunnelData,
  reqEngagementPeopleData,
  setEngagementLineData,
  setEngagementFunnelData,
  setEngagementPeopleData,
  setEngagementError,
  setEngagementFunnelError,
  setEngagementPeopleError,
  reset,
} = videoPerformanceSlice.actions;

// Selectors
export const selectEngagementLineData = (state: RootState) => state.library.videos.base.performance.engagementLineData;
export const selectEngagementFunnelData = (state: RootState) =>
  state.library.videos.base.performance.engagementFunnelData;
export const selectEngagementPeopleData = (state: RootState) =>
  state.library.videos.base.performance.engagementPeopleData;

// Line Chart
export const selectEngagementDates = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => new Date(activity.Date)),
);

export const selectEngagementNotStart = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => activity.Events.NotStartedCount),
);

export const selectEngagementInProgress = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => activity.Events.StartedCount),
);

export const selectEngagementCompleted = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => activity.Events.CompletedCount),
);

export const selectEngagementLineStatus = createSelector(
  selectEngagementLineData,
  (engagementLineData) => engagementLineData.status,
);
export const selectEngagementLineValue = createSelector(
  selectEngagementLineData,
  (engagementLineData) => engagementLineData.value,
);

export const selectEngagementCumulativeInProgress = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.Events.CumulativeStartedCount);
});

export const selectEngagementCumulativeCompleted = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.Events.CumulativeCompletedCount);
});

// Funnel
export const selectEngagementFunnelValue = createSelector(
  selectEngagementFunnelData,
  (engagementFunnelData) => engagementFunnelData.value,
);

// People
export const selectEngagementPeopleStatus = createSelector(
  selectEngagementPeopleData,
  (engagementPeopleData) => engagementPeopleData.status,
);

export const selectEngagementPeopleValue = createSelector(
  selectEngagementPeopleData,
  (engagementPeopleData) => engagementPeopleData.value,
);

export const selectEngagementPeopleError = createSelector(
  selectEngagementPeopleData,
  (engagementPeopleData) => engagementPeopleData.errorMessage,
);

export default videoPerformanceSlice.reducer;
