export const MAX_ANSWER_FIELD_LENGTH = 255;
export const MAX_ANSWERS_LENGTH = 99;
export const MIN_ANSWERS_LENGTH = 2;

export const MAX_QUESTION_FIELD_LENGTH = 1000;
export const MAX_QUESTIONS_LENGTH = 99;
export const MIN_QUESTIONS_LENGTH = 1;

export const getDefaultNewAnswer = {
  text: "",
  isCorrect: false,
  answerType: 1,
};
