import { type HorizontalBarProps, type LinechartProps, TickValueType } from "components/charts/";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import {
  barPadding,
  lineChartMargins,
  horizontalBarChartMargins,
  transitionTime,
} from "features/Library/Common/utils/performanceUtils";

export const sharedAccountReportingLineProps: Partial<LinechartProps> = {
  margins: lineChartMargins,
  xScaleRatio: timeScale,
  yTickValueType: TickValueType.IntegersOnly,
  transitionDuration: transitionTime,
  yFormatterFunc: ",d",
} as const;

export const sharedAccountReportingHorizontalBarProps: Partial<HorizontalBarProps> = {
  margins: horizontalBarChartMargins,
  barPadding,
  barRounded: true,
  xTickValueType: TickValueType.IntegersOnly,
  tooltipFormatter: (d) => d.value.toLocaleString(),
  excludeIcons: true,
} as const;
