import {
  createCancelingLazyFetchingItemsSlice,
  type CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchUserAvailablePacks } from "../thunks/userAvailablePacksGridThunk";
import { type PackOverview } from "../../types/state";

export type OverviewState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: OverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const userAvailablePacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "userAvailablePacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchUserAvailablePacks,
});

export const { reset } = userAvailablePacksGridSlice.actions;

export default userAvailablePacksGridSlice.reducer;
export type userAvailablePacksGridSliceSliceType = ReturnType<typeof userAvailablePacksGridSlice.reducer>;
