import { type PdfUser } from "../../types/models";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface PdfUsersState extends SelectedItemsState<PdfUser> {}

const initialState: PdfUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const pdfUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Pdfs, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  pdfUsersSlice.actions;

export default pdfUsersSlice.reducer;
export type pdfUsersSliceStateType = ReturnType<typeof pdfUsersSlice.reducer>;
