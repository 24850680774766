import React, { type ReactElement, useEffect } from "react";
import cn from "classnames";
import { type SemanticWIDTHS } from "semantic-ui-react";

import CardsViewer from "../../cardsViewer/CardsViewer";
import LazyLoader from "../../lazyLoader/LazyLoader";
import { type DroppedItem } from "../../../interfaces/assetToDropInfo";
import { type CardsViewerItem } from "../../cardsViewer/types";

import "./lazyDropsList.scss";

export interface LazyDropsListProps {
  renderCard: (props: CardsViewerItem<any>) => ReactElement;
  drops: DroppedItem[];
  loadData: (skipCount: number, search?: string) => void;
  itemsPerRow: SemanticWIDTHS;
  isLoadingData?: boolean;
  allDataLoaded?: boolean;
  className?: string;
  autoFirstLoad?: boolean;
  searchTerm?: string;
  noResultsContent?: React.ReactElement;
  renderCardsSelectionControls?: () => React.ReactElement;
}

export const LazyDropsList: React.FC<LazyDropsListProps> = (props) => {
  const {
    renderCard,
    drops,
    loadData,
    searchTerm,
    isLoadingData,
    allDataLoaded,
    className,
    itemsPerRow,
    autoFirstLoad,
    noResultsContent,
    renderCardsSelectionControls,
  } = props;

  const noResults = noResultsContent && !isLoadingData && allDataLoaded && !drops.length ? noResultsContent : null;

  useEffect(() => {
    if (autoFirstLoad || searchTerm !== undefined) {
      loadData(drops.length, searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFirstLoad, searchTerm]);

  return (
    <div className={cn(className, "drop-list-items")}>
      <LazyLoader
        getData={() => loadData(drops.length, searchTerm)}
        isFirstLoad={drops.length === 0}
        loadingData={isLoadingData}
        isAllDataLoaded={allDataLoaded}
      >
        <CardsViewer
          renderCard={renderCard}
          cardItems={drops}
          itemsPerRow={itemsPerRow}
          noResultsContent={noResults}
          renderCardsSelectionControls={renderCardsSelectionControls}
        />
      </LazyLoader>
    </div>
  );
};
