import { type CancelingLazyFetchingItemsState } from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type Account } from "../../../../../interfaces/Account";
import { createFetchingItemsSlice } from "../../../../Application/slices/createFetchingItemsSlice";
import { type PayloadAction } from "@reduxjs/toolkit";

export interface PackVisibilityForAccountsState extends CancelingLazyFetchingItemsState<Account> {
  visibleAccountIds: number[];
}

export const initialState: PackVisibilityForAccountsState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
  visibleAccountIds: [],
};

const packVisibilityForAccounts = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.PackVisibilityForAccount,
    name: "packVisibilityForAccounts",
  },
  initialState: initialState,
  reducers: {
    fetchVisibleAccountIdsSuccess(state, action: PayloadAction<number[]>) {
      return {
        ...state,
        visibleAccountIds: action.payload,
        isLoading: false,
      };
    },
  },
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset, fetchVisibleAccountIdsSuccess } =
  packVisibilityForAccounts.actions;

export default packVisibilityForAccounts.reducer;
