import { type SliceCaseReducers, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type CreateSlicePayload, getPrefix } from "./models";

export interface SearchState {
  term?: string;
}

export const createSearchSlice = <
  Reducers extends SliceCaseReducers<TSearchState>,
  TSearchState extends SearchState = SearchState,
>({
  namePayload,
  initialState,
  reducers,
}: CreateSlicePayload<TSearchState, Reducers>) => {
  return createSlice({
    name: getPrefix(namePayload),
    initialState,
    reducers: {
      setTerm(state: TSearchState, action: PayloadAction<string>) {
        state.term = action.payload ?? "";
      },
      ...reducers,
    },
  });
};
