import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type FC, useEffect } from "react";
import { type ConnectedProps, connect } from "react-redux";

import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import {
  type AccountType,
  isBsi,
  isUserDeleted,
  type PerformanceWithAccountFilter,
} from "../../../../../Common/utils/performanceUtils";
import { selectSendDetailsData, selectSendDetailsState } from "../../../state/slices/emailPerformanceSlice";
import { EmailSendDetailStatusToColor, EmailSendDetailStatusToIcon, EmailSendDetailStatusToString } from "./constants";

import "../../../../../Common/utils/performanceSCSSUtils.scss";
import "../emailPerformance.scss";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RouteNames } from "enums";
import { type ColumnOption } from "interfaces/columnOptions";
import dateTimeUtils from "utils/dateTimeUtils";
import { type EmailSendDetailEntity } from "../types";

import styles from "./statuses.module.scss";
import { fetchEmailSendDetails } from "../../../state/thunks/emailPerformanceThunk";
import { MoboLink } from "components/MoboLink/MoboLink";

export interface Props extends PropsFromRedux {
  emailId: number;
  sendSessionId: number;
  flowId?: number;
  dateFilter: PerformanceWithAccountFilter & { type: AccountType };
}

const getColumnOptions = (
  filter: PerformanceWithAccountFilter,
  accountId: number,
): ColumnOption<EmailSendDetailEntity>[] => [
  {
    name: "Name",
    width: 4,
    isSortable: false,
    render: (entity) => {
      const useLink =
        !filter.showCustomers && (!filter.accounts?.length || isBsi(accountId)) && !isUserDeleted(entity.UserFullName);
      const isUserFromCurrentAccount = entity.AccountId === accountId;
      const userName = (
        <Tooltip target={<TextTruncate>{entity.UserFullName}</TextTruncate>} content={entity.UserFullName} />
      );
      return (
        <div className="emailListClamp">
          {useLink ? (
            <MoboLink
              to={`/${RouteNames.peopleUsers}/${entity.UserId}`}
              accountId={!isUserFromCurrentAccount ? entity.AccountId : undefined}
              target={isUserFromCurrentAccount ? "_self" : "_blank"}
              className={styles.linkButton}
            >
              {userName}
            </MoboLink>
          ) : (
            userName
          )}
        </div>
      );
    },
  },
  {
    name: "Email",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailListClamp">
        <Tooltip target={<TextTruncate>{entity.UserEmail}</TextTruncate>} content={entity.UserEmail} />
      </div>
    ),
  },
  {
    name: "Send date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.SendDate),
  },
  {
    name: "Open date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.OpenDate),
  },
  {
    name: "Click date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.ClickDate),
  },
  {
    name: "Status",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailSendStatusIcon">
        <FontAwesomeIcon
          icon={EmailSendDetailStatusToIcon[entity.Status]}
          className={`${styles[EmailSendDetailStatusToColor[entity.Status]]}`}
        />
        &nbsp;
        {EmailSendDetailStatusToString[entity.Status]}
      </div>
    ),
  },
];

export const EmailPerformanceDrilldown: FC<Props> = ({
  emailId,
  sendSessionId,
  flowId,
  dateFilter,
  sendDetailsState,
  sendDetailsData,
  setHasExportData,
  fetchEmailSendDetails,
  accountId,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  useEffect(() => {
    setHasExportData(sendDetailsState.status === RStatus.Got && !!sendDetailsData.length);
  }, [sendDetailsData, setHasExportData, sendDetailsState.status]);

  useEffect(() => {
    if (reportEnabled) {
      fetchEmailSendDetails(emailId, sendSessionId, { ...dateFilter, flowId });
    }
  }, [fetchEmailSendDetails, emailId, dateFilter, flowId, sendSessionId, reportEnabled]);

  if (!reportEnabled) return null;

  return (
    <div>
      <RequestStatusRenderer state={sendDetailsState}>
        <GenericPerformanceList
          rows={sendDetailsData}
          columns={getColumnOptions(dateFilter, accountId)}
          title="People"
          filter={dateFilter}
        />
      </RequestStatusRenderer>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    sendDetailsState: selectSendDetailsState(state),
    sendDetailsData: selectSendDetailsData(state),
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchEmailSendDetails: bindAction(fetchEmailSendDetails, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EmailPerformanceDrilldown);
export default ConnectedComponent;
