import React from "react";
import { ReactComponent as ResumeIcon } from "../../../../images/play-circle.svg";
import BaseCircleIconButton from "../BaseCircleIconButton";
import PropTypes from "prop-types";

function ResumeButton(props) {
  let { onClick } = props;
  const tooltipText = "Resume";

  let icon = <ResumeIcon id="resume-circle-button" onClick={onClick} />;

  return <BaseCircleIconButton icon={icon} tooltipText={tooltipText} />;
}

ResumeButton.propTypes = {
  onClick: PropTypes.func,
};

export default ResumeButton;
