import { type FC } from "react";
import { Table } from "semantic-ui-react";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { type PackOverview } from "../../../features/Licensing/Packs/types/state";
import { PackTitleCell } from "./packTitleCell/PackTitleCell";
import { ExpirationCell } from "../../../features/Accounts/EditAccount/EditPacks/AccountPacksList/ExpirationCell/ExpirationCell";
import { issueLicensePermissionPredicate } from "features/People/Users/EditUser/issueLicensePermissionPredicate";
import { RemoveLicenseButton } from "./removeLicenseButton/RemoveLicenseButton";
import styles from "./packListRow.module.scss";
import { getUserPackColumnOptions } from "./getUserPackColumnOptions";

export interface UserPackListRowProps {
  pack: PackOverview;
  accountId: number;
  url: string;
  onRemoveLicenseClick: (id: number) => void;
  licenseRemovalEnabled: boolean;
  isSelected: boolean;
}

export const UserPackListRow: FC<UserPackListRowProps> = ({
  pack,
  accountId,
  url,
  onRemoveLicenseClick,
  licenseRemovalEnabled,
  isSelected,
}) => {
  const isOwnPack = pack.publisherId === accountId;
  const columnOptions = getUserPackColumnOptions(licenseRemovalEnabled);

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <PackTitleCell url={url} pack={pack} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{dateTimeUtils.formatDate(pack.licenseIssued)}</Table.Cell>
      <ExpirationCell
        width={columnOptions[2].width}
        isExpired={pack.isExpired}
        date={isOwnPack ? "Never" : dateTimeUtils.formatDate(pack.expirationDate)}
      />
      {licenseRemovalEnabled && (
        <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
          <RemoveLicenseButton
            id={pack.id}
            isSelected={isSelected}
            onRemoveLicenseClick={onRemoveLicenseClick}
            issueLicensePermissionPredicate={issueLicensePermissionPredicate}
          />
        </Table.Cell>
      )}
    </>
  );
};

export default UserPackListRow;
