export type QuestionToQuestionMapper = {
  [FROM: number]: {
    [TO: number]: (input: any) => any;
  };
};

export const getMapper = () => {
  let questionMap: QuestionToQuestionMapper = {} as QuestionToQuestionMapper;

  const map = () => ({
    from: (from: number) => {
      if (!questionMap[from]) {
        questionMap[from] = {};
      }

      return {
        to: (to: number) => {
          return {
            mapper: <TFrom, TTo>(callback: (input: TFrom) => TTo) => {
              questionMap[from][to] = callback;
              return { map, create };
            },
          };
        },
      };
    },
  });

  const create = (): Readonly<QuestionToQuestionMapper> => {
    return questionMap;
  };

  return { map, create };
};
