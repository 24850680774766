import * as Yup from "yup";

export const nameLength = {
  min: 3,
  max: 128,
};

export const descriptionLength = {
  min: 3,
  max: 512,
};

const name = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Name text is required")
    .min(nameLength.min, `Name must have at least ${nameLength.min} characters`)
    .max(nameLength.max, `Name must be maximum of ${nameLength.max} characters long`);

const description = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Description text is required")
    .min(descriptionLength.min, `Description must have at least ${descriptionLength.min} characters`)
    .max(descriptionLength.max, `Description must be maximum of ${descriptionLength.max} characters long`);

export const sectionHeaderInfo = Yup.object().shape({
  name: name(),
  description: description(),
});
