import { type EnrollmentRule } from "../features/People/Groups/AddEditGroupForms/AutomaticallyDetect/models";
import dateTimeUtils from "./dateTimeUtils";
import {
  mapIntRangeToString,
  mapRangeToString,
  mapStringToIntRange,
  mapStringToRange,
  type NumberRangeFilterValues,
  type RangeFilterValues,
} from "./filterUtils";

export enum FilterOptionsEnum {
  IsEqualTo = 3,
  IsNotEqualTo = 4,
  IsBetween = 7,
  IsNotBetween = 8,
  IsOnOrAfter = 9,
  IsOnOrBefore = 10,
  IsWithinLastDays = 11,
}

const exported = {
  validateEnrollmentRules: (enrollmentRules: EnrollmentRule[]) => {
    return enrollmentRules?.every((rule) => {
      switch (rule.filter) {
        case FilterOptionsEnum.IsBetween:
        case FilterOptionsEnum.IsNotBetween:
          const range = rule.value as RangeFilterValues;
          return range && range.from && range.to;
        case FilterOptionsEnum.IsWithinLastDays:
          const numberRange = rule.value as NumberRangeFilterValues;
          return numberRange && numberRange.start && numberRange.finish;
        case FilterOptionsEnum.IsOnOrAfter:
        case FilterOptionsEnum.IsOnOrBefore:
          return rule.value as Date;
        default:
          return rule.value;
      }
    });
  },

  mapRulesToSeverFormat: (enrollmentRules: EnrollmentRule[]) => {
    return enrollmentRules?.map((rule) => {
      let value;

      switch (rule.filter) {
        case FilterOptionsEnum.IsBetween:
        case FilterOptionsEnum.IsNotBetween:
          value = mapRangeToString(rule.value as RangeFilterValues);
          break;
        case FilterOptionsEnum.IsWithinLastDays:
          value = mapIntRangeToString(rule.value as NumberRangeFilterValues);
          break;
        case FilterOptionsEnum.IsOnOrAfter:
        case FilterOptionsEnum.IsOnOrBefore:
          value = dateTimeUtils.toServerFormatDate(rule.value as Date);
          break;
        default:
          value = rule.value;
          break;
      }

      return { ...rule, value };
    });
  },

  mapRulesToLocalFormat: (enrollmentRules?: EnrollmentRule[]) => {
    if (enrollmentRules) {
      return enrollmentRules.map((rule) => {
        let value;

        switch (rule.filter) {
          case FilterOptionsEnum.IsBetween:
          case FilterOptionsEnum.IsNotBetween:
            value = mapStringToRange(rule.value as string);
            break;
          case FilterOptionsEnum.IsWithinLastDays:
            value = mapStringToIntRange(rule.value as string);
            break;
          case FilterOptionsEnum.IsOnOrAfter:
          case FilterOptionsEnum.IsOnOrBefore:
            value = dateTimeUtils.toLocalFormatDateTime(new Date(rule.value as string), "MM/DD/YYYY");
            break;
          default:
            value = rule.value;
            break;
        }

        return { ...rule, value };
      });
    }
  },
};

export type EnrollmentRulesUtils = typeof exported;

export default exported;
