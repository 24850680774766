import type ColumnOption from "../../../../../interfaces/columnOptions";

export enum Columns {
  Title = "title", // NOSONAR
  Added = "added",
  Modified = "modified",
  Status = "status",
}

const columnOptions: Array<ColumnOption> = [
  {
    name: "Title",
    width: 6,
    isSortable: true,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "Modified",
    width: 3,
    isSortable: true,
  },
  {
    name: "Status",
    width: 2,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default columnOptions;
