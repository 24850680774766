import { Button } from "components/buttons/button/Button";
import { type ModalActionConfig } from "../../types";

export const renderModalActionsFirstStep =
  ({ previous, next }: ModalActionConfig) =>
  (nextStep: () => void) =>
  (closeModal: Function) =>
    (
      <>
        <Button
          basic
          color="blue"
          className="cancel"
          content="Cancel"
          onClick={() => {
            previous?.onClick?.();
            closeModal();
          }}
          disabled={previous?.disabled}
        />
        <Button
          blur
          primary
          className="next"
          content={next?.content || "Next"}
          onClick={() => {
            next?.onClick?.();
            nextStep();
          }}
          disabled={next?.disabled}
        />
      </>
    );

export const renderModalActionsMiddleStep =
  ({ previous, next }: ModalActionConfig) =>
  (nextStep: () => void, prevStep: () => void) =>
  () =>
    (
      <>
        <Button
          blur
          primary
          className="previous"
          content="Previous"
          onClick={() => {
            previous?.onClick?.();
            prevStep();
          }}
          disabled={previous?.disabled}
        />
        <Button
          blur
          primary
          className="next"
          content="Next"
          onClick={() => {
            next?.onClick?.();
            nextStep();
          }}
          disabled={next?.disabled}
        />
      </>
    );
