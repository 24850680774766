export const saveThreatDefenceEmailTemplate = "SAVE_THREAT_DEFENCE_EMAIL_TEMPLATE";
export const saveThreatDefenceEmailTemplateContent = "SAVE_THREAT_DEFENCE_EMAIL_TEMPLATE_CONTENT";
export const clearThreatDefenceEmailTemplate = "CLEAR_THREAT_DEFENCE_EMAIL_TEMPLATE";

export const getThreatDefenceEmailTemplateBegin = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_BEGIN";
export const getThreatDefenceEmailTemplateSuccess = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_SUCCESS";
export const getThreatDefenceEmailTemplateFailure = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_FAILURE";

export const getThreatDefenceEmailTemplateContentBegin = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_CONTENT_BEGIN";
export const getThreatDefenceEmailTemplateContentSuccess = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_CONTENT_SUCCESS";
export const getThreatDefenceEmailTemplateContentFailure = "GET_THREAT_DEFENCE_EMAIL_TEMPLATE_CONTENT_FAILURE";

export const setEmailTemplate = "SET_EMAIL_TEMPLATE";
