import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { FeatureFlags } from "featureFlags";
import { isUserDeleted, type PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { fetchPeopleOverviewUsersTable, fetchPeopleOverviewUsersTableExport } from "../utils";
import { type ColumnOption } from "interfaces/columnOptions";
import { type PeopleOverviewUsersItem } from "features/Reporting/types/people";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { RouteNames } from "enums";

import "../../peopleStyles.scss";
import { MoboLink } from "components/MoboLink/MoboLink";
import { bindActionCreators, type Dispatch } from "@reduxjs/toolkit";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { connect, type ConnectedProps } from "react-redux";
import { queryHasData } from "features/Library/Common/utils/performanceUtils";

export type Props = {
  filter: PerformanceFilter;
  includeHyperlink: boolean;
} & PropsFromRedux;

export const EnterpriseTable = ({ filter, includeHyperlink, setExport, setIsExportEnabled, usersExport }: Props) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const usersTableQuery = useQuery({
    queryFn: fetchPeopleOverviewUsersTable,
    queryKey: ["PeopleOverviewUsersTable", filter],
    enabled: reportEnabled,
  });

  // Setting appropriate export method
  useEffect(() => {
    setExport({
      method: usersExport,
      args: [filter],
    });
  }, [filter, setExport, usersExport]);

  useEffect(() => {
    setIsExportEnabled(queryHasData(usersTableQuery));
  }, [setIsExportEnabled, usersTableQuery]);

  const columns: ColumnOption<PeopleOverviewUsersItem>[] = useMemo(
    () => [
      {
        name: "Name",
        width: 7,
        render(item) {
          const useLink = !isUserDeleted(item.UserFullName) && includeHyperlink;
          return (
            <Tooltip
              target={
                <TextTruncate>
                  {useLink ? (
                    <MoboLink to={`/${RouteNames.peopleUsers}/${item.UserId}`}>{item.UserFullName}</MoboLink>
                  ) : (
                    item.UserFullName
                  )}
                </TextTruncate>
              }
              content={item.UserFullName}
            />
          );
        },
      },
      {
        name: "Department",
        width: 4,
        render(item) {
          return (
            <Tooltip
              target={<TextTruncate>{item.UserDepartment || "-"}</TextTruncate>}
              content={item.UserDepartment || "-"}
            />
          );
        },
      },
      {
        name: "Interactions",
        width: 4,
        render(item) {
          return <span>{item.InteractionCount.toLocaleString()}</span>;
        },
      },
    ],
    [includeHyperlink],
  );

  return (
    <div className="tabSpacer">
      <RequestStatusRenderer state={usersTableQuery.status}>
        <GenericPerformanceList
          title="Most Active Users"
          rows={usersTableQuery.data!}
          columns={columns}
          filter={filter}
        />
      </RequestStatusRenderer>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsExportEnabled: bindAction(setHasDataToExport, dispatch),
  setExport: bindAction(setExportAction, dispatch),
  usersExport: bindActionCreators(fetchPeopleOverviewUsersTableExport, dispatch),
});

const connector = connect(null, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EnterpriseTable);
export default ConnectedComponent;
