import React from "react";

import cn from "classnames";
import { baseIconButtonPropTypes } from "../baseIconButtonPropTypes";

import "./minusButton.scss";

function MinusButton(props) {
  const { onClick, className, isDisabled, ...otherProps } = props;
  const eventHandlers = isDisabled ? {} : { onClick };

  return (
    <div
      {...otherProps}
      className={cn("minus-button", className)}
      disabled={isDisabled || !onClick}
      {...eventHandlers}
      role="button"
    />
  );
}

MinusButton.propTypes = baseIconButtonPropTypes;

export default MinusButton;
