import { type AppDispatch } from "../../../Application/globaltypes/redux";
import { err, got, req } from "../slices/parentAccountSlice";
import accountsDataService from "../../services/accountsDataService";

export const fetchParentAccounts =
  (accountTypeId: number, childAccountId?: number) => async (dispatch: AppDispatch) => {
    dispatch(req());
    try {
      const data = await accountsDataService.getParentAccounts(accountTypeId, childAccountId);
      dispatch(got(data));
    } catch (e) {
      let errorMessage = (e as Error).message;
      dispatch(err(errorMessage));
    }
  };
