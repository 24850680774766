import { type FC, useEffect } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";
import GroupsListContainer from "./GroupsListContainer/GroupsListContainer";
import Restricted from "../../../../../../Application/Restricted";
import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import { resetSelectedItems } from "../../../../state/slices/flowGroupsSlice";
import {
  AddAllFlowGroupSuccess,
  RemoveAllFlowGroupSuccess,
  FlowGroupStartAllSuccess,
  GroupFlowPriorityChangedSuccess,
  SetAllFlowGroupPrioritySuccess,
  RemoveAllFlowGroupPrioritySuccess,
} from "../../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { fetchFlowGroups } from "../../../../state/thunks/flowGroupsThunk";
import { type PeopleAssignmentsHandlers } from "../../../../../PeopleAssignments/types";
import ContentGroupsNoResults from "../../../../../Common/Fields/ContentGroupsNoResults";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";
import {
  FlowColumnToParamMap,
  FlowGroupAssignmentColumns,
  getFlowGroupColumnOptions,
} from "./helpers/getColumnOptions";
import { flowInformationSelector } from "features/Library/Flows/state/selectors";
import { bindAction } from "interfaces/redux";
import { startGroupFlowAction } from "features/Library/Flows/state/actions/flowPeopleAssignmentsActions";

export interface GroupsOverviewPropsBase extends PeopleAssignmentsHandlers {
  flowId: number;
  customHeaderContent: React.ReactElement;
}

export type GroupsOverviewProps = GroupsOverviewPropsBase & PropsFromRedux;

export const GroupsOverview: FC<GroupsOverviewProps> = ({
  flowId,
  customHeaderContent,
  fetchGroups,
  onUnmount,
  onRemove,
  onEditPriority,
  startFlow,
  canAutoStart,
}) => {
  const renderSearchInput = (): React.ReactElement => <SearchInput placeholder="Search for People..." disabled />;

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getFlowGroups = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = FlowGroupAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = FlowColumnToParamMap[orderBy.toLowerCase() as Lowercase<FlowGroupAssignmentColumns>];
    fetchGroups({
      entityId: flowId,
      skip,
      top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: "",
    });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.Group,
    RolePermissions.FlowsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={(hasPermission) => (
        <GroupsListContainer
          defaultSortingColumnName={FlowGroupAssignmentColumns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getFlowGroupColumnOptions({
            readonly: !hasPermission,
            onRemove: onRemove,
            onEditPriority: onEditPriority,
            startFlow: (groupIds: number[]) => startFlow(flowId, groupIds),
            isSortable: false,
            canAutoStart: canAutoStart,
          })}
          applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          fetchData={getFlowGroups}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          listViewRtnEvents={[
            AddAllFlowGroupSuccess,
            RemoveAllFlowGroupSuccess,
            RemoveAllFlowGroupPrioritySuccess,
            GroupFlowPriorityChangedSuccess,
            SetAllFlowGroupPrioritySuccess,
            FlowGroupStartAllSuccess,
          ]}
          isSelectDisabled={(group) => !hasPermission}
          noResultsContent={<ContentGroupsNoResults />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  selectedIds: state.library.flows.edit.groups.selectedIds,
  canAutoStart: flowInformationSelector(state).info.canAutoStart,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchGroups: bindActionCreators(fetchFlowGroups, dispatch),
  onUnmount: () => dispatch(resetSelectedItems()),
  startFlow: bindAction(startGroupFlowAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(GroupsOverview);
export default ConnectedComponent;
