import React from "react";
import { ReactComponent as PauseIcon } from "../../../../images/pause-circle.svg";
import BaseCircleIconButton from "../BaseCircleIconButton";
import PropTypes from "prop-types";

function PauseButton(props) {
  let { onClick } = props;
  const tooltipText = "Pause";

  let icon = <PauseIcon id="pause-circle-button" onClick={onClick} />;

  return <BaseCircleIconButton icon={icon} tooltipText={tooltipText} />;
}

PauseButton.propTypes = {
  onClick: PropTypes.func,
};

export default PauseButton;
