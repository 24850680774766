import AssetTypes from "../../../../../enums/assetTypes";
import CommunicationTypes from "../../../../../enums/communicationTypes";
import EventTypes from "../../../../../enums/eventTypes";
import type TriggerTypeEnum from "../../../../../enums/flowDesigner/triggerType";
import type UnitTypeEnum from "../../../../../enums/flowDesigner/unitTypes";
import { FlowTypes } from "../../../../../enums/flowTypes";
import { ExtrasTypes } from "../Extras/types";
import { type IReactFlowEntityType } from "../ReactFlowCanvas/nodes/types";
import { type BranchingQuestion } from "../../../../../interfaces";
import { type SendTypeEnum } from "../../../../../enums/sendTypeEnum";
import { type FlowGoals } from "../../types/models";

// a merged enum solution
// @see https://stackoverflow.com/a/54562617/13336897
/* eslint-disable  @typescript-eslint/no-redeclare */
export const EntityType = { ...AssetTypes, ...CommunicationTypes, ...EventTypes, ...ExtrasTypes, ...FlowTypes };

export type EntityType = AssetTypes | CommunicationTypes | EventTypes | ExtrasTypes | FlowTypes;

export interface Position {
  top: number;
  left: number;
}

export interface Connection {
  sourceId: string;
  targetId: string;
}

export type FlowSectionHeader = {
  name: string;
  description: string;
};

export type FlowEndInfo = {
  completedMessage: string;
  nextFlow?: NextFlowInfo;
};

export type FlowEndInfoPayload = {
  completedMessage?: string;
  nextFlowId?: number;
};

export interface NextFlowInfo {
  id: number;
  title: string;
  thumbnailUrl: string;
  publisher: string;
  goals?: FlowGoals;
}

export interface FlowItemEssentials {
  title: string;
  publisher?: string;
  thumbnailUrl?: string | null;
  sectionHeader?: FlowSectionHeader;
  hasAccess?: boolean;
}

export interface FlowItemEmail extends FlowItemEssentials {
  elementType: CommunicationTypes.Email;
}

export interface FlowItemMessage extends FlowItemEssentials {
  elementType: CommunicationTypes.Message;
  bag: {
    SendType: SendTypeEnum;
  };
}

export interface FlowItemAssessment extends FlowItemEssentials {
  elementType: AssetTypes.Assessment;
  questionsCount: string;
}

export interface FlowItemSurvey extends FlowItemEssentials {
  branchingQuestion?: BranchingQuestion;
  elementType: AssetTypes.Survey;
  questionsCount: string;
}

export interface FlowItemVideo extends FlowItemEssentials {
  elementType: AssetTypes.Video;
  durationInSeconds: number;
}

export interface FlowItemEvent extends FlowItemEssentials {
  elementType: EventTypes.ExternalEvent;
  hasReminders: boolean;
}

export interface FlowItemPdf extends FlowItemEssentials {
  elementType: AssetTypes.Pdf;
}

export interface FlowItemSectionHeader extends FlowItemEssentials {
  elementType: ExtrasTypes.SectionHeader;
}

export interface FlowItemFlowEnd extends FlowItemEssentials {
  elementType: ExtrasTypes.FlowEnd;
  flowEndInfo?: FlowEndInfo;
}

export interface OnCanvas extends Position {
  id: string;
  head?: boolean; // is a start of the flow
  entityId: string;
  el: {};
}

export interface CanvasFlowItemEmail extends FlowItemEmail, OnCanvas {}

export interface CanvasFlowItemVideo extends FlowItemVideo, OnCanvas {}

export interface CanvasFlowItemAssessement extends FlowItemAssessment, OnCanvas {}

export interface CanvasFlowItemSurvey extends FlowItemSurvey, OnCanvas {}

export interface CanvasFlowItemEvent extends FlowItemEvent, OnCanvas {}

export interface CanvasFlowItemPdf extends FlowItemPdf, OnCanvas {}

export interface CanvasFlowItemSectionHeader extends FlowItemSectionHeader, OnCanvas {}

export interface CanvasFlowItemFlowEnd extends FlowItemFlowEnd, OnCanvas {}

export interface DraggableAsset extends CanvasFlowItemVideo {
  id: string;
}

export type CardItem =
  | FlowItemPdf
  | FlowItemVideo
  | FlowItemAssessment
  | FlowItemEmail
  | FlowItemMessage
  | FlowItemSurvey
  | FlowItemEvent
  | FlowItemSectionHeader
  | FlowItemFlowEnd;

export type DragItem =
  | CanvasFlowItemPdf
  | CanvasFlowItemEmail
  | CanvasFlowItemVideo
  | CanvasFlowItemAssessement
  | CanvasFlowItemSurvey
  | CanvasFlowItemEvent
  | CanvasFlowItemSectionHeader
  | CanvasFlowItemFlowEnd;

export interface TriggersBase {
  inId: string;
  outId: string;
  isDefault?: boolean;
  bulletId?: string | null;
  isAction?: boolean;
}

export interface DefaultUnits {
  typeId: TriggerTypeEnum;
  timeUnitId: number;
}

export interface TriggersData extends TriggersBase, DefaultUnits {
  timeSpan: number | undefined;
}

export type TriggersChanges = TriggersBase & Partial<DefaultUnits> & { timeSpan?: number };

export interface TriggerType {
  title: string;
  id: number;
}

export interface EntityTriggerTypes {
  id: number;
  entityType: EntityType;
  triggerTypes: TriggerType[];
}

export interface TriggerTimeUnit {
  id: number;
  name: TimeUnitName;
}

type TimeUnitName = "Hours" | "Days" | "Weeks";

export interface SavedFlowItem {
  id: string;
  entityId: string;
  entityType: EntityType;
  bag?: { [key: string]: any };
  entity?: SavedFlowItemMetadata;
  sectionHeader?: FlowSectionHeader;
  flowEndInfo?: FlowEndInfo;
  y: number;
  x: number;
}

export interface SavedFlowData {
  flowItems: SavedFlowItem[];
  flowTriggers: TriggersData[] | null;
  headId?: string;
}

export interface FlowDefinitionData {
  flowItems: SavedFlowItem[];
  flowTriggers: TriggersData[] | null;
  headId?: string;
}

export interface FlowItemPosition {
  x: number;
  y: number;
}

export interface FlowItem {
  id: string;
  hasEntity: boolean;
  entityType: EntityType;
  entityId: string;
  position: FlowItemPosition;
  sectionHeader?: FlowSectionHeader;
  flowEndInfo?: FlowEndInfoPayload;
  branchingQuestion?: BranchingQuestion;
  bag?: { [key: string]: any };
}

export interface FlowDesignerData {
  id: number;
  items: FlowItem[];
  triggers: TriggersData[];
  headId: string | undefined;
}

export type SavedFlowItemMetadata =
  | SavedVideo
  | SavedEmail
  | SavedMessage
  | SavedAssessment
  | SavedSurvey
  | SavedEvent
  | SavedPdf
  | SavedFlowEnd;

interface SavedFlowItemDescription {
  id: number;
  title: string;
  type: EntityType;
  requiredPacks: string[];
  hasAccess: boolean;
}

export interface SavedVideo extends SavedFlowItemDescription {
  publisher: string;
  durationInSeconds: number;
  thumbnailUrl: string | null;
  type: AssetTypes.Video;
}

export interface SavedPdf extends SavedFlowItemDescription {
  publisher: string;
  thumbnailUrl: string | null;
  type: AssetTypes.Pdf;
}

export interface SavedAssessment extends SavedFlowItemDescription {
  publisher: string;
  questionsCount: string | undefined;
  type: AssetTypes.Assessment;
}

export interface SavedSurvey extends SavedFlowItemDescription {
  branchingQuestion?: BranchingQuestion;
  publisher: string;
  questionsCount: string | undefined;
  type: AssetTypes.Survey;
}

export interface SavedEmail extends SavedFlowItemDescription {
  publisher: string;
  subject: string;
  type: CommunicationTypes.Email;
}

export interface SavedMessage extends SavedFlowItemDescription {
  publisher: string;
  subject: string;
  type: CommunicationTypes.Message;
}

export interface SavedEvent extends SavedFlowItemDescription {
  publisher: string;
  thumbnailUrl: string | null;
  type: EventTypes.ExternalEvent;
  hasReminders: boolean;
}

export interface SavedFlowEnd extends SavedFlowItemDescription {
  type: ExtrasTypes.FlowEnd;
  flowEndInfo?: FlowEndInfo;
  publisher: string;
}

export interface ResponseTypeContent {
  answerText: string;
  questionText: string;
  answerIndex: number;
}

export interface Trigger extends TriggersData {
  source: string;
  destination: string;
  destinationOption?: {
    type: IReactFlowEntityType;
    text: string;
    thumbnailUrl?: string | null;
  };
  trigger: TriggerTypeEnum;
  triggerTypeContent: {
    [key in TriggerTypeEnum]?: ResponseTypeContent;
  };
  triggerOptions: {
    key: number;
    text: string;
    value: number;
  }[];
  units: UnitTypeEnum;
  unitsOptions: {
    key: number;
    text: TimeUnitName;
    value: UnitTypeEnum;
  }[];
  delay: string;
}

export interface Point {
  x: number;
  y: number;
}

export interface ElementAndPosition {
  id: string;
  type?: IReactFlowEntityType;
  topLeft: Point;
}

export interface ItemsToDropBase {
  id: number;
  title: string;
  thumbnailUrl?: string;
  publisher: string;
  cardType?: EntityType;
}

export interface ConnectedFlow {
  id: number;
  title: string;
  thumbnailUrl: string;
  publisher: string;
  isDraft: boolean;
}
