import React from "react";
import NoResults from "../../../../components/noResults/NoResults";

function ThreatDefenceCampaignRunsNoResults() {
  const description = <span>Looks like you don't have any campaign runs yet.</span>;

  return <NoResults title="You don’t have any campaign runs" description={description} iconClassName="fa-envelope" />;
}

export default ThreatDefenceCampaignRunsNoResults;
