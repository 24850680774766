import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "../../../utils/stringUtils";

interface DeleteUsersConfirmationModalProps {
  isOpen: boolean;
  selectedUsers: number[];
  onCancel: () => void;
  onContinue: () => void;
}

const DeleteUsersConfirmationModal = (props: DeleteUsersConfirmationModalProps) => {
  const { isOpen, selectedUsers, onCancel, onContinue } = props;
  const title = `Delete ${pluralize("User", selectedUsers.length)}`;
  const usersCount = selectedUsers.length;
  const usersCountStr = usersCount > 1 ? `(${usersCount}) ` : "";

  return (
    <ActionConfirmationModal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      onContinue={onContinue}
      withWarningLabel={false}
      confirmLabel="Delete"
    >
      {`Delete ${usersCountStr}${pluralize("user", usersCount)}?
            This will remove all user data and cannot be undone.`}
    </ActionConfirmationModal>
  );
};

export default DeleteUsersConfirmationModal;
