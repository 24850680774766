import { type IFlowEdge, type IFlowNode, type IFlowNodeWithEntity } from "../../ReactFlowCanvas/nodes/types";
import { type EntityType, type SavedFlowData, type SavedFlowItem, type SavedFlowItemMetadata, type TriggersData } from "../../types";
import { DEFAULT_HANDLER_ID, PLACEHOLDER_ELEMENT_ID, START_OF_THE_FLOW_ELEMENT_ID } from "../../constants";
import AssetTypes from "../../../../../../enums/assetTypes";
import { ExtrasTypes } from "../../Extras/types";

export const prepareFlowDefinitionData = ({
  nodes,
  edges,
  headId,
}: {
  nodes: IFlowNode[];
  edges: IFlowEdge[];
  headId?: string;
}): SavedFlowData => {
  const flowTriggers = getTriggers(edges);
  const flowItems = getItems(nodes);

  return {
    flowItems,
    flowTriggers,
    headId,
  };
};

const getTriggers = (edges: IFlowEdge[]) => {
  return edges.map((edge) => {
    const { inId, outId, timeSpan, timeUnitId, typeId, isAction, isDefault, bulletId } = edge.data || {};
    return {
      inId,
      outId,
      timeSpan,
      isAction,
      isDefault,
      timeUnitId: timeUnitId || 0,
      typeId: typeId || 0,
      bulletId: bulletId && bulletId !== DEFAULT_HANDLER_ID ? bulletId : undefined,
    } as TriggersData;
  });
};

const getItems = (nodes: IFlowNode[]) => {
  return nodes
    .filter((node) => node.id !== START_OF_THE_FLOW_ELEMENT_ID && node.id !== PLACEHOLDER_ELEMENT_ID)
    .map((item) => {
      const d = item as IFlowNodeWithEntity;
      const mapped: SavedFlowItem = {
        id: d.id,
        entityId: `${d.data?.entityId || 0}`,
        entityType: d.data?.elementType as EntityType,
        y: d.position.y,
        x: d.position.x,
        bag: d.data?.bag,
      };

      if (item.type !== ExtrasTypes.Deleted) {
        mapped.entity = {
          id: Number(d.data?.entityId) || 0,
          title: d.data?.title || "",
          durationInSeconds: d.data?.elementType === AssetTypes.Video ? d.data?.durationInSeconds : undefined,
          thumbnailUrl: d.data?.thumbnailUrl,
          questionsCount: d.data?.questionsCount,
          branchingQuestion: d.data?.branchingQuestion,
          publisher: d.data?.publisher || "",
          type: d.data?.elementType,
          requiredPacks: d.data?.requiredPacks,
          hasAccess: d.data?.hasAccess
        } as SavedFlowItemMetadata;
      }

      if (d.data?.flowEndInfo) {
        mapped.flowEndInfo = d.data?.flowEndInfo;
      }

      if (d.data?.sectionHeader) {
        mapped.sectionHeader = { name: d.data.sectionHeader.name, description: d.data.sectionHeader.description };
      }

      return mapped;
    });
};
