import { type QueryFunctionContext, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { type PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import {
  getGoalLineChart as goalLineMap,
  getGoalEngagementChart,
  getGoalCards as goalCardsMap,
} from "features/Library/Flows/state/selectors/flowPerformanceGoalSelectors";

export type VALID_GOALS =
  | { application: "Teams"; activity: "Meetings" }
  | { application: "Teams"; activity: "Messages" }
  | { application: "Teams"; activity: "Calls" }
  | { application: "OneDrive"; activity: "Files Shared" }
  | { application: "OneDrive"; activity: "Files Synced" }
  | { application: "OneDrive"; activity: "Files Viewed" }
  | { application: "SharePoint"; activity: "Files Synced" }
  | { application: "SharePoint"; activity: "Files Viewed" }
  | { application: "SharePoint"; activity: "Page Visits" }
  | { application: "SharePoint"; activity: "Shared Files" };

type VALID_GOAL_STRINGS =
  | `${Extract<VALID_GOALS, { application: "Teams" }>["application"]} ${Extract<VALID_GOALS, { application: "Teams" }>["activity"]}`
  | `${Extract<VALID_GOALS, { application: "OneDrive" }>["application"]} ${Extract<VALID_GOALS, { application: "OneDrive" }>["activity"]}`
  | `${Extract<VALID_GOALS, { application: "SharePoint" }>["application"]} ${Extract<VALID_GOALS, { application: "SharePoint" }>["activity"]}`;

type GoalQueryKey = { dateFilter: PerformanceFilter; goal: VALID_GOALS; isBsi?: boolean; enabled?: boolean };

type Params = QueryFunctionContext<[_name: string, dateFilter: PerformanceFilter, goal: VALID_GOALS, isBsi: boolean]>;

const getAccountBsiString = (isBsi: boolean): string => (isBsi ? "true" : "false");

const getGoalLineChart = async ({ queryKey }: Params): Promise<ReturnType<typeof goalLineMap>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/linechart", {
    params: {
      ...queryKey[1],
      ...queryKey[2],
      showCustomers: undefined,
      type: undefined,
      accountBsi: getAccountBsiString(queryKey[3]),
    },
  });

  const goalName = `${queryKey[2].application} ${queryKey[2].activity}`;

  return goalLineMap(data, goalName as VALID_GOAL_STRINGS);
};

const getGoalTotals = async ({ queryKey }: Params): Promise<ReturnType<typeof getGoalEngagementChart>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/totals", {
    params: {
      ...queryKey[1],
      ...queryKey[2],
      showCustomers: undefined,
      type: undefined,
      accountBsi: getAccountBsiString(queryKey[3]),
    },
  });

  const goalName = `${queryKey[2].application} ${queryKey[2].activity}`;
  return getGoalEngagementChart(data, goalName);
};

const getGoalCards = async ({ queryKey }: Params): Promise<ReturnType<typeof goalCardsMap>> => {
  const { data } = await axios.get("/api/reports/v2/flows/goals/cards", {
    params: {
      ...queryKey[1],
      ...queryKey[2],
      showCustomers: undefined,
      type: undefined,
      accountBsi: getAccountBsiString(queryKey[3]),
    },
  });

  return goalCardsMap(data, queryKey[2]);
};

export const useFlowGoalsLineQuery = ({ dateFilter, goal, isBsi = false, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-line", dateFilter, goal, isBsi],
    queryFn: getGoalLineChart,
    enabled,
  });

export const useFlowGoalsTotalsQuery = ({ dateFilter, goal, isBsi = false, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-bar", dateFilter, goal, isBsi],
    queryFn: getGoalTotals,
    enabled,
  });

export const useFlowGoalsCardsQuery = ({ dateFilter, goal, isBsi = false, enabled }: GoalQueryKey) =>
  useQuery({
    queryKey: ["goals-cards", dateFilter, goal, isBsi],
    queryFn: getGoalCards,
    enabled,
  });
