import { type Reducer } from "@reduxjs/toolkit";
import * as searchActionTypes from "../../actions/search/searchActionTypes";

export interface SearchAction {
  payload: { search: string };
  type: string;
}

const withSearch =
  (namespace: string, entityPrefix: string): Reducer<string, SearchAction> =>
  (s, action) => {
    const state = s || "";
    const { type, payload } = action;
    const actionTypePrefix = `${namespace}__${entityPrefix}`;

    switch (type) {
      case `${actionTypePrefix}__${searchActionTypes.SET_SEARCH}`:
        return payload.search ?? "";
      case `${actionTypePrefix}__${searchActionTypes.RESET_SEARCH}`:
        return "";
    }
    return state;
  };
export default withSearch;
