import { type Filters } from "utils/queryUtils";
import { FilterOptionsEnum } from "../../../../../utils/enrollmentRulesUtils";
import { operators } from "../../../../../utils/filterUtils";
import { type EnrollmentRule } from "./models";
import { isNil } from "lodash";
import { UserSource } from "enums";

const mapFilterToOperator = (filter: number): string | symbol => {
  switch (filter) {
    case FilterOptionsEnum.IsEqualTo:
      return operators.or;
    case FilterOptionsEnum.IsNotEqualTo:
      return operators.notIn;
    case FilterOptionsEnum.IsBetween:
      return operators.isBetween;
    case FilterOptionsEnum.IsNotBetween:
      return operators.isNotBetween;
    case FilterOptionsEnum.IsOnOrAfter:
      return operators.isOnOrAfter;
    case FilterOptionsEnum.IsOnOrBefore:
      return operators.isOnOrBefore;
    case FilterOptionsEnum.IsWithinLastDays:
      return operators.isWithinLastDays;
    default:
      throw new Error(`Group. Automatically detect. Unknown filter code: ${filter}`);
  }
};

export const convertEnrollmentRulesToFilter = (enrollmentRules: EnrollmentRule[]) => {
  const enumToColumnNameDictionary = [
    "title",
    "department",
    "createDate",
    "country",
    "officeLocation",
    "hiredDate",
    "manager",
  ];
  const filter: Filters = {};
  enrollmentRules.forEach((x: EnrollmentRule) => {
    if (!x.value) {
      return;
    }
    const columnName = enumToColumnNameDictionary[x.type!];
    const columnFilters = filter[columnName] as Array<any>;
    if (!isNil(x.source) && x.source !== UserSource.None) {
      filter.sourceId = [{ values: [x.source], operator: operators.or }];
    }

    const operator = mapFilterToOperator(x.filter!);

    if (columnFilters) {
      const columnFilter = columnFilters.find((f) => f.operator === operator);

      if (columnFilter) {
        columnFilter.values.push(x.value);
      } else {
        columnFilters.push({ values: [x.value], operator: operator });
      }

      return;
    }

    filter[columnName] = [{ values: [x.value], operator: operator }];
  });

  return filter;
};
