import React from "react";
import { connect, type ConnectedProps } from "react-redux";
import { GroupTemplateCustomers } from "../../Details/GroupTemplateCustomers";
import { type ModalWithStepsStep } from "components/modal/ModalWithSteps";
import { type SortingDirection } from "enums";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { setCustomersToAdd, setSearch } from "../../state/TemplateAvailableCustomersSlice";
import { modalColumnOptions } from "../../Details/Customers/getColumnOptions";
import { bindAction } from "interfaces";
import { getAvailableCustomers } from "../../state/thunks/TemplateAvailableCustomersThunk";

export interface AddCustomersModalStepProps extends ModalWithStepsStep {
  filter?: object;
  templateId: number;
}

export type AddCustomersModalStepPropsAll = PropsFromRedux & AddCustomersModalStepProps;

export const AddCustomersModalStep: React.FC<AddCustomersModalStepPropsAll> = ({
  selectedIds,
  setSelectedIds,
  items,
  isLoading,
  itemsCount,
  onSearchChange,
  getData,
  templateId,
  isPacksLoading,
}) => {
  const loadCustomers = (
    skip: number,
    top: number,
    sortBy: string,
    sortOrder: SortingDirection,
    templateId?: number,
  ) => {
    getData(skip, top, sortBy, sortOrder, templateId);
  };

  return (
    <GroupTemplateCustomers
      getData={loadCustomers}
      items={items}
      isLoading={isLoading || isPacksLoading}
      itemsCount={itemsCount}
      onSearchChange={onSearchChange}
      templateId={templateId}
      columnOptions={modalColumnOptions}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    isPacksLoading: state.people.groupTemplate.addCustomersPacks.isLoading,
    items: state.people.groupTemplate.availableCustomers.items,
    itemsCount: state.people.groupTemplate.availableCustomers.itemsCount,
    isLoading: state.people.groupTemplate.availableCustomers.isLoading,
    selectedIds: state.people.groupTemplate.availableCustomers.customersToAdd,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSelectedIds: (ids: number[]) => dispatch(setCustomersToAdd(ids)),
  getData: bindAction(getAvailableCustomers, dispatch),
  onSearchChange: (search: string) => dispatch(setSearch(search)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AddCustomersModalStep);
export default ConnectedComponent;
