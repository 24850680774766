import React from "react";
import { FlowObjective, type Goal } from "../../types/models";
import { Label, type Color } from "components/common/label/Label";

interface Props {
  objective?: Goal<FlowObjective>;
}

export const ObjectiveLabel = (props: Props) => {
  const { objective } = props;
  if (!objective) {
    return null;
  }
  return <Label {...getLabelProps(objective.id)} text={objective.label} />;
};

const getLabelProps = (objective: FlowObjective): { color: Color; icon?: string } => {
  switch (objective) {
    case FlowObjective.Onboarding:
      return { color: "blue", icon: "random" };
    case FlowObjective.Optimization:
      return { color: "yellow", icon: "chart line" };
    case FlowObjective.Remediation:
      return { color: "black", icon: "sync" };
    default:
      return { color: "black" };
  }
};
