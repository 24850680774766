import { type Dispatch } from "@reduxjs/toolkit";
import integrationsDataService from "../../../services/integrationsDataService";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/integrationsOverviewSlice";

export const fetchIntegrationsAction = (moboId?: string) => async (dispatch: Dispatch) => {
  dispatch(fetchBegin());
  try {
    const result = await integrationsDataService.getIntegrationSettings(moboId);
    dispatch(fetchSuccess({ items: result, totalCount: result.length }));
  } catch (error) {
    dispatch(fetchFailure(error as Error));
  }
};
