import { type FC } from "react";
import { Button } from "components/buttons/button/Button";
import ActionConfirmationModal from "components/modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "utils/stringUtils";

export interface Props {
  groupLicensesCount: number;
  userLicensesCount: number;
  isPackContext: boolean;
  triggerOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const RemoveLicenseConfirmationDialog: FC<Props> = (props: Props) => {
  const { groupLicensesCount, userLicensesCount, isPackContext, triggerOpen, onCancel, onContinue } = props;
  const renderModalActions = (closeModal: Function) => {
    return (
      <>
        <Button
          primary
          content={"Close"}
          onClick={() => {
            props.onCancel();
            closeModal();
          }}
        />
      </>
    );
  };

  return groupLicensesCount > 0 ? (
    <ActionConfirmationModal
      open={triggerOpen}
      title="License(s) can’t be removed"
      customModalActions={renderModalActions}
      withWarningLabel={false}
      onCancel={onCancel}
    >
      {pluralize("User", isPackContext ? userLicensesCount : 1)} received licenses from {groupLicensesCount}{" "}
      {pluralize("Group", groupLicensesCount)} and must be removed from all Groups before the licenses can be removed.
    </ActionConfirmationModal>
  ) : (
    <ActionConfirmationModal
      open={triggerOpen}
      title={`Remove ${pluralize("License", userLicensesCount)}?`}
      withWarningLabel={false}
      confirmLabel="Remove"
      onContinue={onContinue}
      onCancel={onCancel}
    >
      This action will nullify the {isPackContext && userLicensesCount > 1 ? "selected users'" : "user's"}{" "}
      {pluralize("license", userLicensesCount)} and access to this{" "}
      {pluralize("Pack", isPackContext ? 1 : userLicensesCount)},
      <b> deleting associated progress and reporting data permanently.</b> Are you sure you want to proceed?
    </ActionConfirmationModal>
  );
};

export default RemoveLicenseConfirmationDialog;
