export const supportedStreamingTypesMap = new Map([
  ["Dash", "application/dash+xml"],
  ["HLS", "application/x-mpegURL"],
]);

class StreamingTypesHelper {
  static getSupportedTypes = () => supportedStreamingTypesMap;
}

export interface Manifest {
  type: string;
  url: string;
}

export const getSupportedStream = (manifests: Manifest[]) => {
  const hls = manifests.find((m) => m.type === "Hls");

  if (hls) {
    return {
      src: hls.url,
      type: supportedStreamingTypesMap.get("HLS"),
    };
  }

  // In case we don't have polyfill
  const supportedTypes = StreamingTypesHelper.getSupportedTypes();
  let supportedSetting;

  for (let manifest of manifests) {
    if (supportedTypes.has(manifest.type)) {
      supportedSetting = {
        src: manifest.url,
        type: supportedTypes.get(manifest.type),
      };
      break;
    }
  }
  return supportedSetting;
};
