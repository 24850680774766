import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { Button } from "../button/Button";
import cn from "classnames";

import "./buttonWithIcon.scss";

function ButtonWithIcon(props) {
  const { id, label, children, iconName, className, disabled, primary, onClick, blur, ...rest } = props;
  const customClassName = label ? "with-label" : null;
  return (
    <Button
      id={id}
      className={cn("button-with-icon", className)}
      onClick={(e) => {
        onClick();
        if (blur) e.currentTarget.blur();
      }}
      disabled={disabled}
      primary={primary}
      {...rest}
    >
      <Icon className={`${iconName} ${customClassName}`} size="large" disabled={disabled} />
      <span className="title">{label}</span>
      {children}
    </Button>
  );
}

ButtonWithIcon.defaultProps = {
  disabled: false,
};

ButtonWithIcon.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  blur: PropTypes.bool,
};

export default ButtonWithIcon;
