import { sum } from "lodash";
import { type FC } from "react";

import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";
import { RolePermissions } from "enums";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ContentItemMenu } from "../../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { DeleteLinkButton, EditLinkButton, PublishLinkButton } from "../../../../../components/buttons/linkButtons";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import DeletionRestrictedTooltip from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { type VideoOverview } from "../../types/models";

const ENTITY_TYPE = "asset";

export interface VideoContentItemMenuProps {
  item: VideoOverview;
  handleEditClick: (id: number, isDraft: boolean) => () => void;
  handlePublishClick: (
    id: number,
    isDraft: boolean,
    flowsCount: number,
    assignmentsCount: number,
    hasBeenPublished: boolean,
  ) => () => void;
  handleDeleteClick: (id: number[], flowsCount?: number, packsCount?: number) => () => void;
  onToggleVisibility: (id: number[], hidden: boolean) => () => void;
  readonly?: boolean;
  isChecked?: boolean;
  circle?: boolean;
  outlinedEllipsis?: boolean;
  hasPermission?: boolean;
}

export const VideoContentItemMenu: FC<VideoContentItemMenuProps> = (props) => {
  const {
    circle,
    outlinedEllipsis,
    readonly,
    isChecked,
    item,
    handleEditClick,
    handlePublishClick,
    handleDeleteClick,
    onToggleVisibility,
    hasPermission = true,
  } = props;
  const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
  const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);
  return (
    <RestrictedByTooltip hasPermission={hasPermission}>
      <ContentItemMenu
        circle={circle}
        outlinedEllipsis={outlinedEllipsis}
        isChecked={isChecked}
        readonly={readonly}
        dependencies={dependencies}
        entityType={ENTITY_TYPE}
        item={item}
      >
        {visibility && (
          <ToggleVisibilityButton
            onClick={onToggleVisibility([item.id], !item.visibility)}
            hidden={!item.visibility}
            requiredPermissions={[RolePermissions.AssetsCreate]}
          />
        )}
        {!item.isPurchased && (
          <EditLinkButton onClick={handleEditClick(item.id, item.isDraft)} isDisabled={item.isPurchased} />
        )}
        {item.isDraft && !item.isPurchased && (
          <PublishLinkButton
            onClick={handlePublishClick(item.id, item.isDraft, flowsCount, assignmentsCount, item.hasBeenPublished)}
            isDisabled={!item.canBePublished}
          />
        )}
        {!item.isPurchased && (
          <DeleteLinkButton
            isDisabled={!deleteContentEnabled && !item.canBeDeleted}
            after={
              !deleteContentEnabled &&
              !item.isPurchased &&
              !!sum(Object.values(dependencies)) && (
                <DeletionRestrictedTooltip dependencies={dependencies} entityType={ENTITY_TYPE} />
              )
            }
            onClick={handleDeleteClick([item.id], flowsCount, packsCount)}
          />
        )}
      </ContentItemMenu>
    </RestrictedByTooltip>
  );
};
