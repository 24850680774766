import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/emailDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { type EmailView, type Template, type Email, type LoadTemplateResult, type EmailTagsPayload } from "../../types/state";
import { type Dispatcher } from "../../../../../../interfaces/redux";
import { setEmailEntityIdAction } from "./emailEntityStateActions";
import { batch } from "react-redux";
import { type CancelTokenSource } from "axios";

export const saveEmail = (emailMetadata: EmailView) => {
  const saveEmailAction = getActionProvider<EmailView>(actionTypes.saveEmail);

  return (dispatch: Dispatcher) => {
    dispatch(saveEmailAction(emailMetadata));
  };
};

export const saveTemplate = (emailTemplate: Template) => {
  const saveEmailAction = getActionProvider<Template>(actionTypes.saveEmailTemplate);

  return (dispatch: Dispatcher) => {
    dispatch(saveEmailAction(emailTemplate));
  };
};

export const clearEmail = () => {
  const resetEmailAction = getActionBaseProvider(actionTypes.clearEmail);

  return (dispatch: Dispatcher) => {
    dispatch(resetEmailAction());
  };
};

export const getEmail = (id: number, cancelTokenSource?: CancelTokenSource) => {
  const begin = getActionBaseProvider(actionTypes.getEmailBegin);
  const success = getActionProvider<Email>(actionTypes.getEmailSuccess);
  const failure = getActionProvider<Error>(actionTypes.getEmailFailure);

  return (dispatch: Function) => {
    dispatch(begin());
    dataService.emails
      .getEmailAsync(id, cancelTokenSource?.token)
      .then((result: any) => {
        batch(() => {
          dispatch(success(result.data));
          dispatch(setEmailEntityIdAction(result.data.id || 0));
        });
      })
      .catch((error: Error) => dispatch(failure(error)));
  };
};
export const getEmailTemplate = (id: number, getTemplateOnly: boolean = false) => {
  const begin = getActionBaseProvider(actionTypes.getEmailTemplateBegin);
  const success = getActionProvider<LoadTemplateResult>(actionTypes.getEmailTemplateSuccess);
  const failure = getActionProvider<Error>(actionTypes.getEmailTemplateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dataService.emails
      .getEmailTemplateAsync(id)
      .then((result: any) => dispatch(success({ template: result.data, getTemplateOnly })))
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const fetchTags = () => {
  const success = getActionProvider<EmailTagsPayload>(actionTypes.fetchTagsSuccess);
  const failure = getActionProvider<Error>(actionTypes.fetchTagsFailure);

  return async (dispatch: Function) => {
    dataService.emails
      .getTags(true)
      .then((result: any) => dispatch(success(result.data)))
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const setEmailAction = getActionProvider<Email>(actionTypes.setEmail);
