import { type FormikProps } from "formik";
import React, { type FC } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { type AssessmentContentView } from "../../types/state";
import { type ContentFormPropsOwn } from "../types";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { ValidatedForm } from "../../../../../components/forms";
import { WithSelfValidation } from "../../../Common/Fields/WithSelfValidation";
import { AnswersBuilder, QuestionBuilder, QuestionHeaderBuilder } from "./Builders";
import { getQuestionDefaultData } from "./Providers/defaultQuestionProvider";
import { bindActionCreators } from "redux";

import * as validationActions from "../../state/actions/assessmentContentValidatorActions";
import validationSchemas from "../../../../../utils/validationSchemas";
import QuestionForm from "../../../Common/QuestionForm";
import QuestionsBuilder from "../../../Common/QuestionsBuilder";
import { assessmentsStateSelector } from "../../state/selectors";
import { useFormikValidation } from "../../../Common/Hooks/useFormikValidationHook";

export type ContentFormPropsAll = ContentFormPropsOwn & FormikProps<AssessmentContentView> & PropsFromRedux;

export const AssessmentContentForm: FC<ContentFormPropsAll> = (props: ContentFormPropsAll) => {
  const { isLoading, errors, activeErrors, validateActions, isFormValid, touchedSnapshot, resolved, initialized } =
    props;

  useFormikValidation({ errors, resolved, initialized, activeErrors, isFormValid }, { ...validateActions });

  const renderFields = (): React.ReactNode => {
    const { values, onBlur, save, disabled } = props;

    return (
      <WithSelfValidation
        {...props}
        validationSchema={validationSchemas.assessmentContent}
        render={(validatedFieldProps) => (
          <QuestionForm
            renderQuestionBuilder={(arrayHelpers) => (
              <QuestionsBuilder
                defaultDataProvider={getQuestionDefaultData}
                onBlur={onBlur}
                onChanged={save}
                questions={values.questions}
                arrayHelpers={arrayHelpers}
                validatedFieldProps={{ ...validatedFieldProps, touched: !resolved ? touchedSnapshot : {} }}
                disabled={disabled}
                QuestionHeader={QuestionHeaderBuilder}
                QuestionRenderer={QuestionBuilder}
                AnswersBuilder={AnswersBuilder}
                propertyNamePrefix={""}
              />
            )}
          />
        )}
      />
    );
  };

  return (
    <ValidatedForm
      dirty={props.dirty}
      isLoading={isLoading}
      formWidthComputer={16}
      parentWithFormik
      disablePreventTransitionPrompt
    >
      {renderFields}
    </ValidatedForm>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = assessmentsStateSelector(state).base;

  return {
    ...base.assessmentContentValidatorReducer,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  validateActions: bindActionCreators(validationActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(AssessmentContentForm);
export default ConnectedComponent;
