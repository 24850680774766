import { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import Restricted from "../../../../../../Application/Restricted";
import GroupsListContainer from "./GroupsListContainer/GroupsListContainer";
import ContentGroupsNoResults from "../../../../../Common/Fields/ContentGroupsNoResults";
import { SearchInput } from "../../../../../../../components";
import { AssignmentPeopleContext, RolePermissions, type SortingDirection } from "../../../../../../../enums";
import { type AppDispatch } from "../../../../../../Application/globaltypes/redux";
import { resetSelectedItems } from "../../../../state/slices/pdfGroupsSlice";
import { fetchPdfGroups } from "../../../../state/thunks/pdfGroupsThunk";
import { GET_PDF_GROUPS_DEFAULT_PARAMS } from "../../../../types/constants";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import {
  ColumnToParamMap,
  getGroupColumnOptions,
  type PeopleAssignmentColumns,
} from "../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { bindAction } from "../../../../../../../interfaces";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface GroupsOverviewPropsBase {
  pdfId: number;
  customHeaderContent: React.ReactElement;
}

export type GroupsOverviewProps = GroupsOverviewPropsBase & PropsFromRedux;

export const GroupsOverview = ({ pdfId, customHeaderContent, fetchGroups, onUnmount }: GroupsOverviewProps) => {
  const renderSearchInput = (): React.ReactElement => <SearchInput placeholder="Search for Groups..." disabled />;

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.Group,
    RolePermissions.AssetsManage,
  );

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getPdfGroups = (
    skip: number = GET_PDF_GROUPS_DEFAULT_PARAMS.skip,
    top: number = GET_PDF_GROUPS_DEFAULT_PARAMS.top,
    orderBy: string = GET_PDF_GROUPS_DEFAULT_PARAMS.orderBy.toLowerCase(),
    sortingDirection: SortingDirection = GET_PDF_GROUPS_DEFAULT_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = GET_PDF_GROUPS_DEFAULT_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<PeopleAssignmentColumns>];
    fetchGroups({
      entityId: pdfId,
      skip: skip,
      top: top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: "",
    });
  };

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <GroupsListContainer
          defaultSortingColumnName={""}
          columnOptions={getGroupColumnOptions()}
          applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          fetchData={getPdfGroups}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          noResultsContent={<ContentGroupsNoResults />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = () => ({});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchGroups: bindAction(fetchPdfGroups, dispatch),
  onUnmount: bindAction(resetSelectedItems, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(GroupsOverview);
export default ConnectedComponent;
