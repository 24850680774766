import { useCallback, useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, Routes, Route, useParams, useNavigate, useLocation } from "react-router-dom";
import Segments from "../../../components/navigation/segments/Segments";
import { type AppDispatch, type RootState } from "../../Application/globaltypes/redux";
import GenericSectionHeader from "../../../components/genericSectionHeader/GenericSectionHeader";
import EditConfiguration from "./EditConfiguration/EditConfiguration";
import {
  identityProviderConfigurationLoadingSelector,
  identityProviderConfigurationSelector,
} from "../state/slices/identityProviderConfigurationSlice";
import {
  identityProviderSamlIsSettingsLoadingSelector,
  identityProviderSamlSettingsSelector,
} from "../state/slices/identityProviderSamlSettingsSlice";
import * as identityProviderConfigurationThunk from "../state/thunks/identityProviderConfigurationThunk";
import * as identityProviderSamlSettingsThunk from "../state/thunks/identityProviderSamlSettingsThunk";
import {
  type IdentityProviderSamlSettings,
  IdentityProviderType,
  type UpdateIdentityProviderConfiguration,
} from "../types";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import * as notificationsActions from "../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import * as signingCertificatesThunk from "../state/thunks/signingCertificatesThunk";
import * as encodingTypesThunk from "../state/thunks/encodingTypesThunk";
import * as signatureAlgorithmsThunk from "../state/thunks/signatureAlgorithmsThunk";
import identityProvidersDataService from "../services/identityProvidersDataService";
import Restricted from "../../Application/Restricted";
import { RolePermissions, RouteNames } from "../../../enums";

import EditAttributeMapping from "./EditAttributeMapping/EditAttributeMapping";
import EditMiscellaneous from "./EditMiscellaneous/EditMiscellaneous";
import { claimTypesSelector } from "../state/slices/claimTypesSlice";
import { signatureAlgorithmsSelector } from "../state/slices/signatureAlgorithmsSlice";
import {
  signingCertificatesLoadingSelector,
  signingCertificatesSelector,
} from "../state/slices/signingCertificatesSlice";
import { encodingTypesLoadingSelector, encodingTypesSelector } from "../state/slices/encodingTypesSlice";
import { type DistributedOpUpdateParams } from "../../../interfaces";
import { RestrictedResource } from "../../../components";
import navigationUtils from "../../../utils/navigationUtils";
import initializeHubClient from "features/Application/services/realTimeNotification/initializeHubClient";

import "./editIdentityProvider.scss";

enum SegmentPaths {
  attributeMapping = "mapping",
  miscellaneous = "miscellaneous",
}

export type EditIdentityProviderPropsAll = PropsFromRedux;

export const EditIdentityProvider: React.FC<EditIdentityProviderPropsAll> = ({
  fetchIdentityProviderConfiguration,
  identityProviderConfiguration,
  isIdentityProviderConfigurationLoading,
  backgroundNotificationActions,
  dispatchUpdateIdentityProviderConfiguration,
  signatureAlgorithms,
  isSignatureAlgorithmsLoading,
  fetchSignatureAlgorithms,
  encodingTypes,
  isEncodingTypesLoading,
  fetchEncodingTypes,
  signingCertificates,
  isSigningCertificatesLoading,
  fetchSigningCertificates,
  identityProviderSamlSettings,
  isIdentityProviderSamlSettingsLoading,
  fetchIdentityProviderSamlSettings,
  dispatchUpdateIdentityProviderSamlSettings,
  accountId,
  dispatch,
  asyncOperations,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const providerId = Number(params.id);
  const moboId = Number(params.moboId) !== -1 ? Number(params.moboId) : undefined;

  const ownAccount = moboId === accountId || moboId === -1 || Number.isNaN(moboId);

  const overviewPath = ownAccount
    ? `/${RouteNames.accountSettingsManagement}/sso`
    : `/${RouteNames.linkedAccountsManagement}/${moboId}/sso`;

  useEffect(() => {
    fetchIdentityProviderConfiguration(providerId, moboId);
  }, [fetchIdentityProviderConfiguration, providerId, moboId]);

  useEffect(() => {
    if (moboId) {
      const realTimeNotificationClient = initializeHubClient(dispatch, { id: moboId }, () => asyncOperations);
      realTimeNotificationClient.connect();

      return () => {
        realTimeNotificationClient.disconnect();
      };
    }
  }, [moboId, asyncOperations, dispatch]);

  const onConfigurationUpdate = async (config: UpdateIdentityProviderConfiguration) => {
    const params: DistributedOpUpdateParams = {
      id: "EditIdentityProviderConfiguration",
      title: "Update identity provider information",
      indeterminate: true,
      getOperationProps: async () => {
        const data = await identityProvidersDataService.updateIdentityProviderConfiguration(config, moboId);
        dispatchUpdateIdentityProviderConfiguration(config);

        return data;
      },
      successTransientMessage: "Identity provider information update succeeded.",
      failureTransientMessage: "Identity provider information update failed.",
    };

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: backgroundNotificationActions.addOperation,
      sendTransientNotification: backgroundNotificationActions.sendTransientNotification,
    });
  };

  const fetchSamlSettings = useCallback(
    () => fetchIdentityProviderSamlSettings(providerId, moboId),
    [fetchIdentityProviderSamlSettings, providerId, moboId],
  );

  const onSamlSettingsUpdate = async (settings: IdentityProviderSamlSettings) => {
    const params: DistributedOpUpdateParams = {
      id: "EditIdentityProviderSamlSettings",
      title: "Update identity provider SAML settings",
      indeterminate: true,
      getOperationProps: async () => {
        const data = await identityProvidersDataService.updateIdentityProviderSamlSettings(
          providerId,
          settings,
          moboId,
        );
        dispatchUpdateIdentityProviderSamlSettings(settings);

        return data;
      },
      successTransientMessage: "Identity provider information update succeeded.",
      failureTransientMessage: "Identity provider information update failed.",
    };

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: backgroundNotificationActions.addOperation,
      sendTransientNotification: backgroundNotificationActions.sendTransientNotification,
    });
  };

  const shouldRenderMiscStep =
    !isIdentityProviderConfigurationLoading && identityProviderConfiguration?.type === IdentityProviderType.Saml2;

  return (
    <Restricted
      permissions={[RolePermissions.AccountsSettingsManage]}
      renderContent={(hasManagePermission) => (
        <section className="edit-idp nested-content">
          <GenericSectionHeader
            title={identityProviderConfiguration.name}
            titleForGA="Edit ID Provider"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, overviewPath)}
          />

          <Segments to={`${overviewPath}/${params.id}`}>
            <Segments.Segment label="Configure" />
            <Segments.Segment label="Attribute Mapping" to={SegmentPaths.attributeMapping} />
            {shouldRenderMiscStep && <Segments.Segment label="Miscellaneous" to={SegmentPaths.miscellaneous} />}
          </Segments>

          <Routes>
            <Route
              path="/"
              element={
                <RestrictedResource isAuthorized={identityProviderConfiguration.isAccessAuthorized}>
                  <EditConfiguration
                    isLoading={isIdentityProviderConfigurationLoading}
                    providerConfiguration={identityProviderConfiguration}
                    onConfigurationUpdate={onConfigurationUpdate}
                    isReadOnly={!hasManagePermission}
                  />
                </RestrictedResource>
              }
            />
            <Route
              path={SegmentPaths.attributeMapping}
              element={
                <EditAttributeMapping identityId={providerId} moboId={moboId} isReadOnly={!hasManagePermission} />
              }
            />
            {shouldRenderMiscStep && (
              <Route
                path={SegmentPaths.miscellaneous}
                element={
                  <RestrictedResource isAuthorized={identityProviderSamlSettings.isAccessAuthorized}>
                    <EditMiscellaneous
                      signatureAlgorithms={signatureAlgorithms}
                      isSignatureAlgorithmsLoading={isSignatureAlgorithmsLoading}
                      fetchSignatureAlgorithms={fetchSignatureAlgorithms}
                      signingCertificates={signingCertificates}
                      isSigningCertificatesLoading={isSigningCertificatesLoading}
                      fetchSigningCertificates={fetchSigningCertificates}
                      encodingTypes={encodingTypes}
                      isEncodingTypesLoading={isEncodingTypesLoading}
                      fetchEncodingTypes={fetchEncodingTypes}
                      fetchSamlSettings={fetchSamlSettings}
                      isReadOnly={!hasManagePermission}
                      onUpdate={onSamlSettingsUpdate}
                      samlSettings={identityProviderSamlSettings as IdentityProviderSamlSettings}
                      isLoading={isIdentityProviderSamlSettingsLoading}
                    />
                  </RestrictedResource>
                }
              />
            )}
            <Route path="*" element={<Navigate to="../" replace />} />
          </Routes>
        </section>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    asyncOperations: state.asyncOperations,
    accountId: state.userProfile.accountId,
    identityProviderConfiguration: identityProviderConfigurationSelector(state),
    isIdentityProviderConfigurationLoading: identityProviderConfigurationLoadingSelector(state),
    identityProviderSamlSettings: identityProviderSamlSettingsSelector(state),
    isIdentityProviderSamlSettingsLoading: identityProviderSamlIsSettingsLoadingSelector(state),
    claimTypes: claimTypesSelector(state),
    signatureAlgorithms: signatureAlgorithmsSelector(state),
    isSignatureAlgorithmsLoading: signingCertificatesLoadingSelector(state),
    signingCertificates: signingCertificatesSelector(state),
    isSigningCertificatesLoading: signingCertificatesLoadingSelector(state),
    encodingTypes: encodingTypesSelector(state),
    isEncodingTypesLoading: encodingTypesLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    dispatch,
    fetchIdentityProviderConfiguration: bindActionCreators(
      identityProviderConfigurationThunk.fetchIdentityProviderConfiguration,
      dispatch,
    ),
    fetchIdentityProviderSamlSettings: bindActionCreators(
      identityProviderSamlSettingsThunk.fetchIdentityProviderSamlSettings,
      dispatch,
    ),
    fetchSigningCertificates: bindActionCreators(signingCertificatesThunk.fetchSigningCertificates, dispatch),
    fetchEncodingTypes: bindActionCreators(encodingTypesThunk.fetchEncodingTypes, dispatch),
    fetchSignatureAlgorithms: bindActionCreators(signatureAlgorithmsThunk.fetchSignatureAlgorithms, dispatch),
    dispatchUpdateIdentityProviderConfiguration: bindActionCreators(
      identityProviderConfigurationThunk.dispatchUpdateIdentityProviderConfiguration,
      dispatch,
    ),
    dispatchUpdateIdentityProviderSamlSettings: bindActionCreators(
      identityProviderSamlSettingsThunk.dispatchUpdateIdentityProviderSamlSettings,
      dispatch,
    ),
    backgroundNotificationActions: {
      addOperation: bindActionCreators(backgroundTasksActions.addOperationDistributedOp, dispatch),
      sendTransientNotification: bindActionCreators(notificationsActions.sendTransientNotification, dispatch),
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditIdentityProvider);
export default ConnectedComponent;
