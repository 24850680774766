import PropTypes from "prop-types";
import { size } from "lodash";
import { Card, Icon, Popup } from "semantic-ui-react";
import cn from "classnames";

import { Tooltip } from "../../common/tooltip";
import PlaceholderLoader from "../../placeholderLoader/PlaceholderLoader";
import { TextTruncate } from "../../textTruncators/TextTruncators";

import "./cardContent.scss";

function CardContent(props) {
  const {
    children,
    onTitleClick,
    meta,
    title,
    isMetaLoading,
    icons,
    preHeader,
    postHeader,
    disabled,
    skipDefaultFiller,
  } = props;

  const renderIconBar = () => {
    return size(icons) ? (
      <div className="icon-bar">
        {icons
          .filter((x) => x !== null && x !== undefined)
          .map((icon) => (
            <Popup
              key={icon.tooltipContent}
              hideOnScroll
              position={icon.tooltipPosition || "top center"}
              size={icon.tooltipSize || "small"}
              className={icon.tooltipClassName}
              content={icon.tooltipContent}
              trigger={<Icon link className={icon.iconClassName} size={icon.iconSize || "large"} />}
            />
          ))}
      </div>
    ) : null;
  };

  return (
    <Card.Content className={cn("card-content", { disabled: disabled })}>
      {isMetaLoading || meta ? (
        <PlaceholderLoader isLoading={isMetaLoading}>
          <Tooltip
            target={
              <Card.Meta>
                <span className="meta-text">
                  <TextTruncate>{meta}</TextTruncate>
                </span>
              </Card.Meta>
            }
            content={meta}
          />
        </PlaceholderLoader>
      ) : null}

      {renderIconBar()}
      {preHeader}
      <Card.Header className="card-header" onClick={disabled ? () => {} : onTitleClick}>
        <Tooltip
          target={
            <TextTruncate className="title" lines={2}>
              {title}
            </TextTruncate>
          }
          content={title}
        />
        {postHeader && <div className="post-header">{postHeader}</div>}
      </Card.Header>
      {!skipDefaultFiller && <div className="filler" />}
      {children}
    </Card.Content>
  );
}

CardContent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  meta: PropTypes.string,
  onTitleClick: PropTypes.func,
  isMetaLoading: PropTypes.bool,
  icons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        iconClassName: PropTypes.string.isRequired,
        iconSize: PropTypes.string,
        tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
        tooltipPosition: PropTypes.string,
        tooltipSize: PropTypes.string,
        tooltipClassName: PropTypes.string,
      }),
    ]),
  ),
  publisherName: PropTypes.string,
  publisherLogoUri: PropTypes.string,
  preHeader: PropTypes.any,
  postHeader: PropTypes.any,
  disabled: PropTypes.bool,
  skipDefaultFiller: PropTypes.bool,
};

CardContent.defaultProps = {
  isMetaLoading: false,
};

export default CardContent;
