import { combineReducers } from "@reduxjs/toolkit";
import packContentTabContextSlice from "../slices/packContentTabContextSlice";
import packContentTabItemsToDropSlice from "../slices/packContentTabItemsToDropSlice";
import packContentFiltersSlice from "../slices/packsContentFiltersSlice";

const packContentTab = combineReducers({
  filters: packContentFiltersSlice,
  context: packContentTabContextSlice,
  itemsToDrop: packContentTabItemsToDropSlice,
});

export type PackContentTabState = ReturnType<typeof packContentTab>;

export default packContentTab;
