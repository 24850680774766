import { Component } from "react";
import PropTypes from "prop-types";

import { ItemsViewHeader } from "../../itemsViewHeader";

import ViewType from "../../../enums/ViewType";
import LazyCardsViewer from "../lazyCardsViewer/LazyCardsViewer";
import FilterPanel from "../../filterPanel/FilterPanel";
import RestrictedResource from "../../restrictedResource/RestrictedResource";

import "./lazyCardsViewerWithHeaderAndPanel.scss";

export default class LazyCardsViewerWithHeaderAndPanel extends Component {
  render() {
    const {
      getData,
      items,
      itemsAmount,
      isLoading,
      isAllLoaded,
      filter,
      filterOptions,
      applyFilter,
      resetFilter,
      renderFilterForm,
      renderHeaderContent,
      selectedItemIds,
      sortOptions,
      noResultsContent,
      hidePopupMenu,
      onFilterPress,
      blur,
      onSortOptionsChanged,
      onSelectedItemsChanged,
      accessRestricted,
      renderSearch,
      orderBy,
      hideFilterPanel,
      renderCard,
    } = this.props;
    return (
      <div className={"grid-view-header-panel"}>
        <ItemsViewHeader
          blur={blur}
          accessRestricted={accessRestricted}
          viewType={ViewType.GRID}
          orderBy={orderBy}
          onFilterPress={onFilterPress}
          itemsInlineCount={itemsAmount}
          applyFilter={applyFilter}
          sortOptions={sortOptions}
          onSortChange={onSortOptionsChanged}
          appliedFilter={filter}
          isLoading={isLoading}
          search={<div className="items-view-search">{renderSearch && renderSearch(accessRestricted)}</div>}
        >
          {renderHeaderContent && renderHeaderContent()}
        </ItemsViewHeader>
        <div className={"grid-view-n-panel"}>
          <RestrictedResource isAuthorized={!accessRestricted}>
            <LazyCardsViewer
              getData={getData}
              items={items}
              isLoading={isLoading}
              isAllLoaded={isAllLoaded}
              hidePopupMenu={hidePopupMenu}
              selectedItemIds={selectedItemIds}
              noResultsContent={noResultsContent}
              onSelectedItemsChanged={onSelectedItemsChanged}
              itemsPerRow={hideFilterPanel ? 4 : 3}
              renderCard={renderCard}
            />
            {!hideFilterPanel && (
              <FilterPanel
                isLoading={filterOptions.isLoading}
                applyFilter={applyFilter}
                resetFilter={resetFilter}
                appliedFilter={filter}
                filterOptions={filterOptions}
                compactFilterPanel
              >
                {renderFilterForm()}
              </FilterPanel>
            )}
          </RestrictedResource>
        </div>
      </div>
    );
  }
}

LazyCardsViewerWithHeaderAndPanel.defaultProps = {
  filterOptions: {},
};

LazyCardsViewerWithHeaderAndPanel.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  renderCard: PropTypes.func,
  orderBy: PropTypes.string,
  filter: PropTypes.object,
  filterOptions: PropTypes.object,
  getData: PropTypes.func.isRequired,
  isAllLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemsAmount: PropTypes.number,
  noResultsContent: PropTypes.object,
  onFilterPress: PropTypes.func,
  onSelectedItemsChanged: PropTypes.func,
  blur: PropTypes.bool,
  onSortOptionsChanged: PropTypes.func,
  renderHeaderContent: PropTypes.func,
  renderFilterForm: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  sortOptions: PropTypes.array,
  selectedItemIds: PropTypes.array,
  accessRestricted: PropTypes.bool,
  search: PropTypes.node,
  hideFilterPanel: PropTypes.bool,
};
