import PropTypes from "prop-types";

import { TitleSubtitle } from "../listViewTemplates";
import UserProfilePicture from "../userProfilePicture/UserProfilePicture";

import "./userInfoCell.scss";
import { RouteNames } from "enums";

export default function UserInfoCell(props) {
  const { user, goToEditUser, deepLink, moboAccountId, target } = props;

  return (
    <div className="name-column-value-container">
      <UserProfilePicture imageUrl={user.imageUrl} />
      <TitleSubtitle
        moboAccountId={moboAccountId}
        target={target}
        id={user.id}
        title={`${user.firstName} ${user.lastName}`}
        subtitle={user.email}
        url={deepLink && `/${RouteNames.peopleUsers}/${user.id}`}
        onNavigate={user.id ? goToEditUser : undefined}
      />
    </div>
  );
}

UserInfoCell.propTypes = {
  user: PropTypes.object.isRequired,
  goToEditUser: PropTypes.func,
  deepLink: PropTypes.bool,
  moboAccountId: PropTypes.number,
  target: PropTypes.string,
};
