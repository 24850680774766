import { AbortController } from "@azure/abort-controller";
import { BlockBlobClient, type BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import { type AxiosResponse } from "axios";
import { pick } from "lodash";

import dataService from "../features/Application/services/dataServices/dataService";
import arrayUtils from "./arrayUtils";

import { type FileLike, type SasConfig } from "../interfaces";

/**
 * TO-DO
 * add typings to UploadOptions
 */
type UploadOptions = any;
export type OnUploadProgressHandler = (p: { loaded: number; total: number }) => void;
export type AcceptCancelationHandler = (a: AbortController) => void;

const uploadFileToBlob = async (
  url: string,
  file: File,
  onUploadProgressHandler: OnUploadProgressHandler,
  acceptCancelationHandler: AcceptCancelationHandler,
  uploadOptions: UploadOptions,
) => {
  const blobServiceClient = new BlockBlobClient(url);

  let aborter: AbortController = new AbortController();

  if (typeof acceptCancelationHandler === "function") {
    acceptCancelationHandler(aborter);
  }

  const defaultBlockSize = 4 * 2**20;
  const defaultParallelism = 4;

  let options: BlockBlobParallelUploadOptions = {
    blockSize: defaultBlockSize,
    concurrency: defaultParallelism,
    maxSingleShotSize: defaultBlockSize,
    abortSignal: aborter.signal,
  };

  if (uploadOptions) {
    options.blockSize = uploadOptions.blockSize || options.blockSize;
    options.concurrency = uploadOptions.parallelism || options.concurrency;
    options.maxSingleShotSize = uploadOptions.maxSingleShotSize || options.blockSize;

    options.metadata = uploadOptions.metadata;

    if (uploadOptions.contentType) {
      options.blobHTTPHeaders = {
        blobContentType: uploadOptions.contentType,
      };
    }
  }

  if (typeof onUploadProgressHandler === "function") {
    /* istanbul ignore next */
    options.onProgress = (ev) => {
      if (aborter.signal.aborted) {
        return;
      }
      onUploadProgressHandler({
        loaded: ev.loadedBytes,
        total: file.size,
      });
    };
  }

  return blobServiceClient.uploadBrowserData(file, options);
};

const utils = {
  async uploadFileToBlobStorage(
    file: File,
    uploadOptions: UploadOptions,
    onUploadProgressHandler: OnUploadProgressHandler,
    acceptCancelationHandler: AcceptCancelationHandler,
    sasConfig: SasConfig,
  ) {
    return dataService.createSas(sasConfig, acceptCancelationHandler).then(async (result: AxiosResponse<any>) => {
      const { uri, fileName } = result.data;

      return uploadFileToBlob(uri, file, onUploadProgressHandler, acceptCancelationHandler, uploadOptions).then(() => {
        return {
          uri,
          fileName,
        };
      });
    });
  },

  uploadFileToBlob,

  /* istanbul ignore next */
  readFileAsDataUrl(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));
      reader.addEventListener("error", (e) => reject(e));
      reader.readAsDataURL(file);
    });
  },

  resolveMimeType(file: File) {
    const manualResolutionDictionary: { [key: string]: string } = {
      csv: "text/csv",
      xml: "text/xml",
    };
    const fileExtension = file.name.split(".").pop();
    return fileExtension && manualResolutionDictionary[fileExtension]
      ? manualResolutionDictionary[fileExtension]
      : file.type;
  },

  isExtension(extension: string, file: File) {
    const reg = extension ? new RegExp(`.${extension}$`, "i") : new RegExp(/^[^.]*$/);
    return reg.test(file?.name);
  },

  isCsvExtension(file: File) {
    return this.isExtension("csv", file);
  },

  downloadFile(fileUri: string) {
    const link = document.createElement("a");
    link.href = fileUri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  areFileEqual(left: FileLike, right: FileLike) {
    return left.name === right.name && left.size === right.size;
  },

  isDifferentFile(left: FileLike, right: FileLike) {
    return !this.areFileEqual(left, right);
  },

  areFileSequencesEqual(left: FileList | FileLike[], right: FileList | FileLike[]) {
    const leftArray = left ? Array.from(left) : [];
    const rightArray = right ? Array.from(right) : [];
    return arrayUtils.sequencesEqualBy(leftArray, rightArray, this.areFileEqual);
  },

  toFileLike(file: FileLike): FileLike {
    return pick(file, ["name", "size", "type", "lastModified"]);
  },
};
export default utils;
