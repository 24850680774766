import React from "react";
import { FilterFormBuilder, type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";
import { type FilterFormPropsBase } from "../types/props";
import { AccountsFilterPropertyNames } from "../../../enums/accountsFilterPropertyNames";

export interface AccountFilterFormProps extends FilterFormPropsBase<string> {
  datePickersPosition: "top" | "bottom";
}

const AccountsFilterForm: React.FC<AccountFilterFormProps> = ({
  filterOptions,
  filter,
  updateFilter,
  datePickersPosition = "bottom",
}) => {
  const filters: FilterItemBase<string>[] = [
    {
      type: FilterViewTypes.MultiSelectWithAddition,
      label: "Account Types",
      propertyName: AccountsFilterPropertyNames.AccountTypeName,
      placeholder: "All",
      otherProps: { disabled: true },
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Added",
      propertyName: AccountsFilterPropertyNames.Added,
      otherProps: { disabled: true, verticalPosition: datePickersPosition, horizontalPositionSecondPicker: "left" },
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};

export default AccountsFilterForm;
