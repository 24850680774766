import "./progressTasksButton.scss";
import tasksInProgress from "../../../images/tasks-in-progress.gif";
import { ReactComponent as TasksFailed } from "../../../images/tasks-failed.svg";
import { ReactComponent as TasksSuccessful } from "../../../images/tasks-successful.svg";
import taskStatuses from "../../../enums/taskStatuses";
import PropTypes from "prop-types";
import cn from "classnames";

function ProgressTasksButton(props) {
  const { tasks, onClick, isCollapsed } = props;
  const taskItems = Object.values(tasks);

  const isTaskInProgress = () => taskItems.some((task) => taskStatuses.isInProgress(task.status));
  const isFailedTask = () => taskItems.some((task) => taskStatuses.isFailed(task.status));

  const getProgressDescription = () => {
    if (isTaskInProgress()) {
      return {
        label: "Processing…",
        icon: <img className="in-progress" src={tasksInProgress} alt="loading..." />,
      };
    }

    if (isFailedTask()) {
      return { label: "Error!", icon: <TasksFailed /> };
    }

    return { label: "Complete!", icon: <TasksSuccessful /> };
  };

  const { label, icon } = getProgressDescription();

  return (
    <div
      id="progress-tasks-button"
      className={cn("progress-tasks-button", { notCollapsed: !isCollapsed })}
      onClick={onClick}
    >
      <div className={cn("tasks-progress-icon", { collapsed: isCollapsed })}>{icon}</div>
      {!isCollapsed && <span className="progress-task-label">{label}</span>}
    </div>
  );
}

ProgressTasksButton.propTypes = {
  tasks: PropTypes.object,
  onClick: PropTypes.func,
  isCollapsed: PropTypes.bool,
};

export default ProgressTasksButton;
