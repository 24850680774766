import { createReducer } from "../../../../../../utils/reduxUtils";
import miscUtils from "../../../../../../utils/miscellaneousUtils";
import * as actionTypes from "../actionTypes/emailDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/emailEntityStateActionTypes";
import { type EmailDetailsState, type EmailView, type Template, type Email, type LoadTemplateResult, type EmailTagsPayload } from "../../types/state";
import { type PayloadAction } from "@reduxjs/toolkit";

const initialState: EmailDetailsState = {
  email: {
    id: -1,
    isDraft: true,
    hasBeenPublished: false,
    subject: "",
    title: "",
    utmCampaign: "",
    previewText: "",
    dateModified: undefined,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
    isPurchased: false,
    packs: [],
    flows: [],
    isPurchasedDuplicationAllowed: false,
    isDuplicatedFromPurchased: false,
    canBeDuplicated: true,
  },
  callToAction: "Learn more",
  destinationUrl: "https://seup-dev.quickhelp.com/home",
  template: "",
  compiledTemplate: "",
  isLoading: false,
  isEmailLoaded: false,
  isTemplateLoaded: false,
  tags: {},
};

const emailDetailsHandlers = () => {
  const {
    saveEmail,
    saveEmailTemplate,
    clearEmail,
    getEmailBegin,
    getEmailSuccess,
    getEmailFailure,
    getEmailTemplateBegin,
    getEmailTemplateSuccess,
    getEmailTemplateFailure,
    setEmail: setEmailAction,
    fetchTagsSuccess,
    fetchTagsFailure,
  } = actionTypes;

  const {
    publishEmailBegin,
    publishEmailSuccess,
    publishEmailFailure,
    fetchLockedEmailBegin,
    fetchLockedEmailSuccess,
    fetchLockedEmailFailure,
  } = entityStateActionTypes;

  const normalizeEmailPayload = (initial: EmailView, payload: Email): EmailView => ({
    ...initial,
    ...payload,
    labels: miscUtils.normalizeDropdownItems(payload.labels),
    softwareApplications: miscUtils.normalizeDropdownItems(payload.softwareApplications),
    packs: payload.packs.map((pack) => ({ title: pack.name, id: pack.id })),
  });

  const setEmailHandler = (state: EmailDetailsState, action: PayloadAction<Email>): EmailDetailsState => ({
    ...state,
    email: {
      ...normalizeEmailPayload(state.email, action.payload),
    },
  });

  const failureHandler = (state: EmailDetailsState, action: PayloadAction<Error>): EmailDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveEmailHandler = (state: EmailDetailsState, action: PayloadAction<EmailView>): EmailDetailsState => ({
    ...state,
    email: action.payload,
  });

  const saveEmailTemplateHandler = (state: EmailDetailsState, action: PayloadAction<Template>): EmailDetailsState => {
    const payload = action.payload;
    return {
      ...state,
      email: {
        ...state.email,
        subject: payload.subject,
        previewText: payload.previewText,
      },
      callToAction: payload.callToAction,
      destinationUrl: payload.destinationUrl,
      template: payload.template,
      compiledTemplate: payload.compiledTemplate,
    };
  };

  const clearEmailHandler = (): EmailDetailsState => ({
    ...initialState,
  });

  const getEmailBeginHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getEmailSuccessHandler = (state: EmailDetailsState, action: PayloadAction<Email>): EmailDetailsState => ({
    ...state,
    email: {
      ...normalizeEmailPayload(state.email, action.payload),
    },
    isEmailLoaded: true,
    isLoading: false,
  });

  const getEmailTemplateBeginHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getEmailTemplateSuccessHandler = (
    state: EmailDetailsState,
    action: PayloadAction<LoadTemplateResult>,
  ): EmailDetailsState => {
    const { template, callToAction, destinationUrl, compiledTemplate, subject, previewText } = action.payload.template;
    const newState = {
      ...state,
      callToAction: callToAction,
      destinationUrl: destinationUrl,
      template: template,
      compiledTemplate: compiledTemplate,
      isTemplateLoaded: true,
      isLoading: false,
    };
    if (!action.payload.getTemplateOnly) {
      newState.email = {
        ...state.email,
        subject: subject,
        previewText: previewText,
      };
    }
    return newState;
  };

  const publishEmailBeginHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishEmailSuccessHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    email: {
      ...state.email,
      isDraft: false,
    },
    isLoading: false,
  });

  const fetchLockedEmailBeginHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedEmailSuccessHandler = (state: EmailDetailsState): EmailDetailsState => ({
    ...state,
    email: {
      ...state.email,
      isDraft: true,
    },
    isLoading: false,
  });

  const fetchTagsSuccessHandler = (state: EmailDetailsState, action: PayloadAction<EmailTagsPayload>) => ({
    ...state,
    tags: action.payload,
  });

  return {
    [saveEmail]: saveEmailHandler,
    [saveEmailTemplate]: saveEmailTemplateHandler,
    [clearEmail]: clearEmailHandler,
    [getEmailBegin]: getEmailBeginHandler,
    [getEmailSuccess]: getEmailSuccessHandler,
    [getEmailFailure]: failureHandler,
    [getEmailTemplateBegin]: getEmailTemplateBeginHandler,
    [getEmailTemplateSuccess]: getEmailTemplateSuccessHandler,
    [getEmailTemplateFailure]: failureHandler,
    [publishEmailBegin]: publishEmailBeginHandler,
    [publishEmailSuccess]: publishEmailSuccessHandler,
    [publishEmailFailure]: failureHandler,
    [fetchLockedEmailBegin]: fetchLockedEmailBeginHandler,
    [fetchLockedEmailSuccess]: fetchLockedEmailSuccessHandler,
    [fetchLockedEmailFailure]: failureHandler,
    [setEmailAction]: setEmailHandler,
    [fetchTagsSuccess]: fetchTagsSuccessHandler,
    [fetchTagsFailure]: failureHandler,
  };
};

export const emailDetailsReducer = createReducer(initialState, [emailDetailsHandlers]);
