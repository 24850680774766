import axios from "axios";
import { type BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import {
  type EmailCounts,
  type EmailDetails,
  type EngagementCounts,
  type AssetActivityCounts,
  type FlowCards,
  type FlowDetails,
  type VideoActivityCounts,
  type VideoDetails,
  type PdfDetails,
  type AssessmentActivityCounts,
  type AssessmentEngagement,
  type AssessmentDetails,
  type AssessmentCards,
  type SurveyActivityCounts,
  type SurveyDetails,
  type SurveyEngagement,
} from "../types/content";
import { type EngagementHorizontalData, type EngagementLineData } from "features/Library/Pdfs/types/state";
import { type VideoEngagementData } from "features/Library/Videos/state/slices/videoPerformanceSlice";
import { serializeArrayAndFilterNullable } from "utils/queryUtils";

// Email
export const EMAIL_LINE_CHART_ENDPOINT = "/api/reports/v4/emails/linechart";
export const EMAIL_ENGAGEMENT_ENDPOINT = "/api/reports/v4/emails/engagement";
export const EMAIL_TABLE_ENDPOINT = "/api/reports/v4/overview/emails/performance";

// Flows
export const FLOWS_LINE_CHART_ENDPOINT = "/api/reports/v3/flows/linechart";
export const FLOWS_ENGAGEMENT_ENDPOINT = "/api/reports/v3/flows/engagement";
export const FLOWS_TABLE_ENDPOINT = "/api/reports/v3/overview/flows/performance";

// Assessments
export const ASSESSMENT_ACTIVITY_ENDPOINT = "/api/reports/v2/assessments/linechart";
export const ASSESSMENT_ENGAGEMENT_ENDPOINT = "/api/reports/v2/assessments/engagement";
export const ASSESSMENT_CARDS_ENDPOINT = "/api/reports/v2/assessments/cards";
export const ASSESSMENT_DETAILS_ENDPOINT = "/api/reports/v2/overview/assessments/performance";

// Surveys
export const SURVEY_ACTIVITY_ENDPOINT = "/api/reports/v4/surveys/linechart";
export const SURVEY_ENGAGEMENT_ENDPOINT = "/api/reports/v4/surveys/engagement";
export const SURVEY_DETAILS_ENDPOINT = "/api/reports/v4/overview/surveys/performance";

// Videos
export const VIDEO_LINE_CHART_ENDPOINT = "/api/reports/v3/videos/linechart";
export const VIDEO_ENGAGEMENT_ENDPOINT = "/api/reports/v3/videos/engagement";
export const VIDEO_TABLE_ENDPOINT = "/api/reports/v3/overview/videos/performance";

// PDFs
export const PDFS_LINE_CHART_ENDPOINT = "/api/reports/v3/pdfs/linechart";
export const PDFS_ENGAGEMENT_ENDPOINT = "/api/reports/v3/pdfs/engagement";
export const PDFS_TABLE_ENDPOINT = "/api/reports/v3/overview/pdfs/performance";

let assessmentActivityAbortController = new AbortController();
let assessmentCardsAbortController = new AbortController();
let assessmentEngagementAbortController = new AbortController();
let assessmentDetailsAbortController = new AbortController();

let pdfsDailyDataAbortController = new AbortController();
let pdfsEngagementAbortController = new AbortController();
let pdfsTableAbortController = new AbortController();

let videoDailyDataAbortController = new AbortController();
let videoEngagementAbortController = new AbortController();
let videoDetailsAbortController = new AbortController();

let flowsDailyDataAbortController = new AbortController();
let flowsEngagementAbortController = new AbortController();
let flowsDetailsAbortController = new AbortController();

let emailUsageAbortController = new AbortController();
let emailEngagementAbortController = new AbortController();
let emailDetailsAbortController = new AbortController();

let surveyActivityAbortController = new AbortController();
let surveyEngagementAbortController = new AbortController();
let surveyDetailsAbortController = new AbortController();

export const getEmailUsage = (params: BasePerformanceRequestFilterParams): Promise<{ data: EmailCounts[] }> => {
  emailUsageAbortController.abort();
  emailUsageAbortController = new AbortController();
  return axios.get<EmailCounts[]>(EMAIL_LINE_CHART_ENDPOINT, {
    params: {
      type: "account",
      ...params,
    },
    paramsSerializer: serializeArrayAndFilterNullable,
    signal: emailUsageAbortController.signal,
  });
};

export const getEmailEngagement = (
  params: BasePerformanceRequestFilterParams = {},
): Promise<{ data: EngagementCounts }> => {
  emailEngagementAbortController.abort();
  emailEngagementAbortController = new AbortController();
  return axios.get<EngagementCounts>(EMAIL_ENGAGEMENT_ENDPOINT, {
    params: {
      type: "account",
      ...params,
    },
    paramsSerializer: serializeArrayAndFilterNullable,
    signal: emailEngagementAbortController.signal,
  });
};

export const getEmailDetails = (params: BasePerformanceRequestFilterParams = {}): Promise<{ data: EmailDetails[] }> => {
  emailDetailsAbortController.abort();
  emailDetailsAbortController = new AbortController();
  return axios.get<EmailDetails[]>(EMAIL_TABLE_ENDPOINT, {
    params: {
      type: "account",
      ...params,
    },
    paramsSerializer: serializeArrayAndFilterNullable,
    signal: emailDetailsAbortController.signal,
  });
};

export const getFlowsDailyData = (params: BasePerformanceRequestFilterParams) => {
  flowsDailyDataAbortController.abort();
  flowsDailyDataAbortController = new AbortController();
  return axios.get<AssetActivityCounts[]>(FLOWS_LINE_CHART_ENDPOINT, {
    params,
    signal: flowsDailyDataAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getFlowsEngagement = (params: BasePerformanceRequestFilterParams) => {
  flowsEngagementAbortController.abort();
  flowsEngagementAbortController = new AbortController();
  return axios.get<[FlowCards]>(FLOWS_ENGAGEMENT_ENDPOINT, {
    params,
    signal: flowsEngagementAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getFlowsDetails = (params: BasePerformanceRequestFilterParams) => {
  flowsDetailsAbortController.abort();
  flowsDetailsAbortController = new AbortController();
  return axios.get<FlowDetails[]>(FLOWS_TABLE_ENDPOINT, {
    params,
    signal: flowsDetailsAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getVideoDailyData = (params: BasePerformanceRequestFilterParams) => {
  videoDailyDataAbortController.abort();
  videoDailyDataAbortController = new AbortController();
  return axios.get<VideoActivityCounts[]>(VIDEO_LINE_CHART_ENDPOINT, {
    params,
    signal: videoDailyDataAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getVideoEngagement = (params: BasePerformanceRequestFilterParams) => {
  videoEngagementAbortController.abort();
  videoEngagementAbortController = new AbortController();
  return axios.get<VideoEngagementData>(VIDEO_ENGAGEMENT_ENDPOINT, {
    params,
    signal: videoEngagementAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getVideoDetails = (params: BasePerformanceRequestFilterParams) => {
  videoDetailsAbortController.abort();
  videoDetailsAbortController = new AbortController();
  return axios.get<VideoDetails[]>(VIDEO_TABLE_ENDPOINT, {
    params,
    signal: videoDetailsAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getPdfsDailyData = (params: BasePerformanceRequestFilterParams) => {
  pdfsDailyDataAbortController.abort();
  pdfsDailyDataAbortController = new AbortController();
  return axios.get<EngagementLineData[]>(PDFS_LINE_CHART_ENDPOINT, {
    params,
    signal: pdfsDailyDataAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getPdfsEngagement = (params: BasePerformanceRequestFilterParams) => {
  pdfsEngagementAbortController.abort();
  pdfsEngagementAbortController = new AbortController();
  return axios.get<EngagementHorizontalData>(PDFS_ENGAGEMENT_ENDPOINT, {
    params,
    signal: pdfsEngagementAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getPdfsTable = (params: BasePerformanceRequestFilterParams) => {
  pdfsTableAbortController.abort();
  pdfsTableAbortController = new AbortController();
  return axios.get<PdfDetails[]>(PDFS_TABLE_ENDPOINT, {
    params,
    signal: pdfsTableAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getAssessmentActivity = (params: BasePerformanceRequestFilterParams) => {
  assessmentActivityAbortController.abort();
  assessmentActivityAbortController = new AbortController();
  return axios.get<AssessmentActivityCounts[]>(ASSESSMENT_ACTIVITY_ENDPOINT, {
    params,
    signal: assessmentActivityAbortController.signal,
  });
};

export const getAssessmentEngagement = (params: BasePerformanceRequestFilterParams) => {
  assessmentEngagementAbortController.abort();
  assessmentEngagementAbortController = new AbortController();
  return axios.get<AssessmentEngagement>(ASSESSMENT_ENGAGEMENT_ENDPOINT, {
    params,
    signal: assessmentEngagementAbortController.signal,
  });
};

export const getAssessmentCards = (params: BasePerformanceRequestFilterParams) => {
  assessmentCardsAbortController.abort();
  assessmentCardsAbortController = new AbortController();
  return axios.get<AssessmentCards>(ASSESSMENT_CARDS_ENDPOINT, {
    params,
    signal: assessmentCardsAbortController.signal,
  });
};

export const getAssessmentDetails = (params: BasePerformanceRequestFilterParams) => {
  assessmentDetailsAbortController.abort();
  assessmentDetailsAbortController = new AbortController();
  return axios.get<AssessmentDetails[]>(ASSESSMENT_DETAILS_ENDPOINT, {
    params,
    signal: assessmentDetailsAbortController.signal,
  });
};

export const getSurveyActivity = (params: BasePerformanceRequestFilterParams) => {
  surveyActivityAbortController.abort();
  surveyActivityAbortController = new AbortController();
  return axios.get<SurveyActivityCounts[]>(SURVEY_ACTIVITY_ENDPOINT, {
    params,
    signal: surveyActivityAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getSurveyEngagement = (params: BasePerformanceRequestFilterParams) => {
  surveyEngagementAbortController.abort();
  surveyEngagementAbortController = new AbortController();
  return axios.get<SurveyEngagement>(SURVEY_ENGAGEMENT_ENDPOINT, {
    params,
    signal: surveyEngagementAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getSurveyDetails = (params: BasePerformanceRequestFilterParams) => {
  surveyDetailsAbortController.abort();
  surveyDetailsAbortController = new AbortController();
  return axios.get<SurveyDetails[]>(SURVEY_DETAILS_ENDPOINT, {
    params,
    signal: surveyDetailsAbortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getPdfExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v3/overview/pdfs/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getVideoExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v3/overview/videos/performance", {
    responseType: "blob",
    params,
    paramsSerializer: serializeArrayAndFilterNullable,
    signal: abortController.signal,
  });
};

export const getEmailExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v4/overview/emails/performance", {
    responseType: "blob",
    params,
    paramsSerializer: serializeArrayAndFilterNullable,
    signal: abortController.signal,
  });
};

export const getFlowExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v3/overview/flows/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getAssessmentExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/assessments/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};

export const getSurveyExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v4/overview/surveys/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
    paramsSerializer: serializeArrayAndFilterNullable,
  });
};

export const getEventExport = (params: BasePerformanceRequestFilterParams, abortController: AbortController) => {
  return axios.get("/api/dataexport/v2/overview/events/performance", {
    responseType: "blob",
    params,
    signal: abortController.signal,
  });
};
