import { type AppDispatch } from "../../../Application/globaltypes/redux";
import accountDataService from "../../services/accountsDataService";
import { err, got, req } from "../slices/contractTypesSlice";

export const fetchContractTypes = () => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const data = await accountDataService.getContractTypes();
    data.unshift({ id: -1, name: "Existing Agreement in Place" });
    dispatch(got(data));
  } catch (e) {
    let errorMessage = (e as Error).message;
    dispatch(err(errorMessage));
  }
};
