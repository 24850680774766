import { noop } from "lodash";
import { type ReactNode, useEffect, useRef } from "react";
import { type IObservable } from "../../interfaces/IObservable";
import { useObserver } from "../../hooks/useObserver";
import useToggle from "../../hooks/useToggle";

import ConfirmationModalBase from "../modal/ConfirmationModalBase/ConfirmationModalBase";
import ModalSizes from "../../enums/modalSizes";

export type TriggerableConfirmationModalPropsBase<T extends Array<any>> = {
  onTriggerModalObserver: IObservable<(onConfirm: () => void, ...args: T) => void>;
  title: ReactNode;
  content: ReactNode;
  banner?: ReactNode;
  confirmButtonLabel?: string;
  scrolling?: boolean;
  disabled?: boolean;
  size?: ModalSizes;
  styles?: { [key: string]: string };
  classNames?: string;
};

export type TriggerableConfirmationModalProps<T extends Array<any>> = TriggerableConfirmationModalPropsBase<T> & {
  onTrigger?: (...args: T) => void;
  onConfirmed?: () => Promise<void> | void;
  onClose?: () => void;
};

export const TriggerableConfirmationModal = <T extends Array<any>>(props: TriggerableConfirmationModalProps<T>) => {
  const {
    onTriggerModalObserver,
    onTrigger,
    onConfirmed,
    onClose,
    disabled,
    size = ModalSizes.tiny,
    styles,
    classNames,
  } = props;

  const [showModal, toggleModal] = useToggle(false);
  const [subscribeOnModalTrigger] = useObserver(onTriggerModalObserver);
  const onConfirmRef = useRef(noop);

  useEffect(() => {
    const onTriggerModal = (onConfirm: () => void, ...args: T) => {
      onTrigger?.(...args);
      onConfirmRef.current = onConfirm;
      toggleModal();
    };

    return subscribeOnModalTrigger(onTriggerModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnModalTrigger]);

  const onConfirmHandler = async () => {
    onConfirmRef.current();

    if (onConfirmed) {
      await onConfirmed();
    }

    closeModal();
  };

  const closeModal = () => {
    if (showModal) {
      toggleModal();
    }
  };

  const onCloseHandler = () => {
    onClose?.();
    closeModal();
  };

  return (
    <ConfirmationModalBase
      isOpen={showModal}
      title={props.title}
      banner={props.banner}
      content={props.content}
      confirmButtonLabel={props.confirmButtonLabel}
      scrolling={props.scrolling}
      onConfirm={onConfirmHandler}
      size={size}
      styles={styles}
      classNames={classNames}
      onClose={onCloseHandler}
      onCancel={onCloseHandler}
      disabled={disabled}
    />
  );
};

export default TriggerableConfirmationModal;
