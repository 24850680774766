import React from "react";
import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { DeleteLinkButton, EditLinkButton } from "../buttons/linkButtons";
import { type ThreatDefenceEmailTemplateOverview } from "../../features/Library/SimulatedPhishing/EmailTemplates/types/state";

interface IProps {
  emailTemplate: ThreatDefenceEmailTemplateOverview;
  onDeleteEmailTemplate: (id: number) => void;
  onEditEmailTemplate: (id: number, isDraft: boolean) => void;
  circle: boolean;
  outlinedEllipsis: boolean;
}

function EmailTemplateEllipsisPopupButton(props: IProps) {
  const { emailTemplate, onDeleteEmailTemplate, onEditEmailTemplate, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={() => onEditEmailTemplate(emailTemplate.id, emailTemplate.isDraft)} />
      <DeleteLinkButton onClick={() => onDeleteEmailTemplate(emailTemplate.id)} />
    </EllipsisPopupButton>
  );
}

EmailTemplateEllipsisPopupButton.defaultProps = {
  disabled: false,
};

export default EmailTemplateEllipsisPopupButton;
