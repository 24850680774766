import * as Yup from "yup";
import awesomeDebouncePromise from "awesome-debounce-promise";
import accountsDataService from "../../features/Accounts/services/accountsDataService";
import { HiddenError } from "components/forms/constants";
import { type AnyObject } from "yup";

const debounceTimeMs = 300;

const maxAccountNameLength = 64;
const maxAliasLength = 32;

const isUniqueName = awesomeDebouncePromise(accountsDataService.isUniqueName, debounceTimeMs);
const isAccountAliasExists = awesomeDebouncePromise(accountsDataService.isAccountAliasExists, debounceTimeMs);

const thumbnailUrl = Yup.string().nullable().url("Invalid url");

const companyName = (nameInitialValue: string | undefined) => {
  let init = nameInitialValue;
  let isUniq = true;
  return Yup.string()
    .trim()
    .required("Company name is required")
    .max(maxAccountNameLength, `Company name must be maximum of ${maxAccountNameLength} characters long`)
    .test("unique-company-name", "Company name should be unique", async (name) => {
      if (name && name !== init) {
        isUniq = await isUniqueName(name);
        init = name;
      }

      return isUniq;
    });
};

const companyAlias = (initialAliasValue?: string | null) => {
  let init = initialAliasValue;
  let isUniq = true;
  return Yup.string()
    .nullable()
    .trim()
    .matches(
      /^[A-Za-z0-9]+$|^[A-Za-z0-9]+[A-Za-z0-9-]*[A-Za-z0-9]$/,
      "Alias must contain only alphanumeric characters or hyphen",
    )
    .max(maxAliasLength, `Account alias must be maximum of ${maxAliasLength} characters long`)
    .test("unique-alias", "Account alias should be unique", async (alias) => {
      if (!alias) {
        return true;
      }
      if (alias !== init) {
        isUniq = !(await isAccountAliasExists(alias));
        init = alias;
      }

      return isUniq;
    });
};

const accountTypeId = Yup.number().required("Please select account type");
const minSessionTimeout = 10;
const maxSessionTimeout = 525600;

const isValidMinSessionTimeout = (_: number | undefined, context: AnyObject) =>
  context.parent.sessionTimeoutInMinutes >= minSessionTimeout;

const isValidMaxSessionTimeout = (_: number | undefined, context: AnyObject) =>
  context.parent.sessionTimeoutInMinutes <= maxSessionTimeout;

export const getAccountSchema = (
  isRequiredParentAccount: boolean,
  initialValue?: string,
  initialAliasValue?: string | null,
  isEdit?: boolean,
) => {
  return Yup.object({
    name: companyName(initialValue),
    accountTypeId,
    logoUri: thumbnailUrl,
    contractTypeId: Yup.number().required("Please select contract type"),
    alias: companyAlias(initialAliasValue),
    parentAccountId: isRequiredParentAccount
      ? Yup.number().required("Please select parent account")
      : Yup.mixed()
          .nullable()
          .transform((v) => {
            return typeof v === "string" && !v ? null : v;
          }),
    sessionTimeoutDays: isEdit
      ? Yup.number()
          .required("Days value is required")
          .min(0, "Days must not be negative.")
          .max(365, "Days must not exceed 365.")
          .test("is-valid-total", HiddenError, isValidMaxSessionTimeout)
      : Yup.number().notRequired(),
    sessionTimeoutHours: isEdit
      ? Yup.number()
          .required("Hours value is required")
          .min(0, "Hours must not be negative.")
          .max(24, "Hours must not exceed 24.")
          .test("is-valid-total", HiddenError, isValidMaxSessionTimeout)
      : Yup.number().notRequired(),
    sessionTimeoutMinutes: isEdit
      ? Yup.number()
          .required("Minutes value is required")
          .min(0, "Minutes must not be negative.")
          .max(60, "Minutes must not exceed 60.")
          .test(
            "is-valid-total",
            HiddenError,
            (_, context) => isValidMinSessionTimeout(_, context) && isValidMaxSessionTimeout(_, context),
          )
      : Yup.number().notRequired(),
    sessionTimeoutInMinutes: isEdit
      ? Yup.number()
          .required("Minutes value is required")
          .min(minSessionTimeout, "Total session timeout duration must be between 10 minutes and 365 days.")
          .max(maxSessionTimeout, "Total session timeout duration must be between 10 minutes and 365 days.")
      : Yup.number().notRequired(),
  });
};
