import React, { memo, useCallback } from "react";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

import BaseNode from "../Base/Base";
import { type FlowItemBase } from "../types";
import { type FlowItemEssentials } from "../../../types";
import { type ExtrasTypes } from "../../../Extras/types";

import styles from "./deletedNode.module.scss";
import { useReactFlowCanvasState } from "../../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowCanvasActions } from "../../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";

export interface IDeletedNodeOwnProps {
  type: ExtrasTypes.Deleted;
  data: FlowItemEssentials & FlowItemBase;
}

export const DeletedNode = ({ data }: IDeletedNodeOwnProps) => {
  const { removeNode } = useReactFlowCanvasActions();
  const { isReadOnly } = useReactFlowCanvasState();

  const removeNodeHandle = useCallback(() => removeNode(data.id), [removeNode, data.id]);

  return (
    <BaseNode data={data}>
      <div className={styles["deleted-card"]}>
        <div className={styles["card-icon"]}>
          <Icon className="exclamation triangle outline red" size="large" />
        </div>
        <div className={styles["card-base"]}>
          <div className={styles["card-description"]}>
            <strong>This asset has been deleted</strong> and will need to be removed from this flow.
          </div>
          {!isReadOnly && (
            <Button className={styles["card-button"]} basic color="red" onClick={removeNodeHandle}>
              Remove
            </Button>
          )}
        </div>
      </div>
    </BaseNode>
  );
};

export default memo(DeletedNode);
