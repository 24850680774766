import { DropdownWrapper } from "components";
import { type AutocompleteAccount } from "interfaces/Account";
import React, { useMemo } from "react";
import { useGetAutocompleteQuery } from "../state/api/createAccountApi";
import { useThrottledValue } from "hooks/useThrottledValue";

const mapOptions = (accounts: AutocompleteAccount[]) => {
  return accounts.map((account) => {
    return {
      text: account.name,
      value: account.id,
      content: account.isNew ? `${account.name} (New Account)` : account.name,
      data: account,
    };
  });
};

interface Props {
  onAccountSelected: (acc: { name: string; id: number; isNew?: boolean; logoUri: string }) => void;
}

export const AccountName = (props: Props) => {
  const { onAccountSelected } = props;
  const [search, setSearch] = React.useState("");
  const throttledSearch = useThrottledValue(search);

  const { data: accounts } = useGetAutocompleteQuery(throttledSearch);

  const options = useMemo(() => {
    return accounts ? mapOptions(accounts) : [];
  }, [accounts]);

  return (
    <DropdownWrapper
      options={options}
      selection
      blur
      fluid
      lazyLoad
      clearable
      direction="right"
      search={(options) => {
        return options;
      }}
      handleOptionChange={(_, data) => {
        const selected = options.find((opt) => opt.value === data.value);
        selected && onAccountSelected({ ...selected.data });
      }}
      onSearchChange={(_, data) => {
        setSearch(data.searchQuery);
      }}
      selectOnBlur={false}
      noResultsMessage={null}
    />
  );
};
