import { useEffect, useState } from "react";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import MappingRow from "./MappingRow/MappingRow";
import { type MappedField, type MapToField } from "../types";
import { cloneDeep } from "lodash";

import "./attributeMapper.scss";

export interface AttributeMapperProps {
  mappedFields: MappedField[];
  mapToFields: MapToField[];
  updateUserInfo: boolean;
  onUpdateUserInfoChange(): void;
  onMappingChange(newMappedFields: MappedField[]): void;
  addNewMappedField(): void;
  isReadOnly: boolean;
}

const AttributeMapper: React.FC<AttributeMapperProps> = ({
  mappedFields,
  mapToFields,
  updateUserInfo,
  onUpdateUserInfoChange,
  onMappingChange,
  addNewMappedField,
  isReadOnly,
}) => {
  useEffect(() => {
    setRequiredMapToFields(mapToFields.filter((x) => x.isRequired));
  }, [mapToFields]);
  const [requiredMapToFields, setRequiredMapToFields] = useState<MapToField[]>([]);

  const getOptionsForDropdownMapTo = (currentFieldIndex: number) => {
    let filteredMapToFields = mapToFields
      .filter(
        (field) =>
          !field.isRequired &&
          (!mappedFields.some((mappedField) => mappedField.mapTo === field.name) ||
            mappedFields[currentFieldIndex].mapTo === field.name),
      )
      .map((field) => {
        return { text: field.name, value: field.name };
      });

    const currentField = mappedFields[currentFieldIndex];

    if (currentField && currentField.isCustomField) {
      filteredMapToFields.push({
        text: currentField.mapTo!,
        value: currentField.mapTo!,
      });
    }

    return filteredMapToFields;
  };

  const isCustomField = (field: string) => {
    return !!field && !mapToFields.some((f) => f.name.toUpperCase() === field.toUpperCase());
  };

  const onMapFromChange = (index: number, value: any) => {
    const newMappedFields = cloneDeep(mappedFields);
    const currentMappedField = newMappedFields[index];
    currentMappedField.mapFrom = value;
    onMappingChange(newMappedFields);
  };

  const onMapToChange = (index: number, value: any) => {
    const newMappedFields = cloneDeep(mappedFields);
    const currentMappedFields = newMappedFields[index];
    currentMappedFields.mapTo = value;

    if (isCustomField(value)) {
      currentMappedFields.isCustomField = true;
    } else if (currentMappedFields.isCustomField) {
      currentMappedFields.isCustomField = false;
    }
    onMappingChange(newMappedFields);
  };

  const buildMappingRow = (index: number) => {
    let requiredMapToField;
    if (requiredMapToFields.length && requiredMapToFields.length >= index) {
      requiredMapToField = requiredMapToFields[index];
    }
    const currentMappedField = mappedFields[index];

    return (
      <MappingRow
        key={currentMappedField.mapTo ?? index}
        index={index}
        mappedField={currentMappedField}
        requiredMapToField={requiredMapToField}
        onMapFromChange={onMapFromChange}
        onMapToChange={onMapToChange}
        mapToDropdownOptions={getOptionsForDropdownMapTo(index)}
        isReadOnly={isReadOnly}
      />
    );
  };

  return (
    <div className="attribute-mapper">
      <div className="titles">
        <div className="title map-from-title">SSO Information</div>
        <div className="title map-to-title">System Field Name</div>
      </div>

      {mappedFields.map((_, i) => buildMappingRow(i))}
      <div className="control-panel-add" onClick={addNewMappedField}>
        <Icon data-testid="add-row" className="plus circle icon" size="big" color="blue" />
        <span className="add-row">Add row</span>
      </div>
      <div className="checkbox-field">
        <Checkbox
          data-testid="updateUserInfo"
          className="attribute-mapper-checkbox"
          type="checkbox"
          toggle
          label="Update user info based on mapping"
          checked={updateUserInfo}
          onChange={onUpdateUserInfoChange}
        />
        <Popup
          content="User info will be automatically updated according to mapping settings after login with this Identity Provider"
          trigger={<Icon className="info circle" />}
          hideOnScroll
          on="click"
          position="top center"
          style={{ maxWidth: `${25}em` }}
          inverted
        />
      </div>
    </div>
  );
};

export default AttributeMapper;
