import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { noop } from "lodash";
import { Navigate, Routes, Route } from "react-router-dom";

import { AssessmentDetailsSteps, EditAssessmentStepConfig } from "../types/state";
import { type EditAssessmentState } from "./types";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { TemplateTypes, RolePermissions, UsersGroupsContext, PublishedStatusTypes } from "../../../../enums";
import {
  type EnumDictionary,
  type IObservable,
  type ISegment,
  type IModalManager,
  type NotifyStepSettings,
} from "../../../../interfaces";
import { assessmentsStateSelector } from "../state/selectors";
import PublishConfirmationModal, {
  type EntityState,
} from "../../../../components/modal/PublishConfirmationModal/PublishConfirmationModal";
import { NotifyStepConfig } from "../../../SystemNotifications/types";
import { SwitchEntityTypes } from "enums/switchEntityTypes";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";

import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as assessmentEntityStateActions from "../state/actions/assessmentEntityStateActions";
import * as assessmentDetailsActions from "../state/actions/assessmentDetailsActions";
import * as assessmentContentValidatorActions from "../state/actions/assessmentContentValidatorActions";
import * as commonAssessmentActions from "../state/actions/commonAssessmentActions";

import SettingsTab from "../Details/Settings/SettingsTab";
import Content from "../Details/AssessmentContent/AssessmentContent";
import Configure from "../Details/Configure/Configure";
import AssessmentSubheaderContainer from "./Containers/AssessmentSubheaderContainer";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { ModalTypes, Segments } from "../../../../components";
import Observable from "../../../../utils/Observable";
import ModalManager from "../../../../utils/ModalManager";
import RevertConfirmationModalTs from "../../../../components/modal/RevertConfirmationModalTs";
import Restricted from "../../../Application/Restricted";
import EditAssessmentPeople from "./People/EditAssessmentPeople";
import DetailsHeaderContent from "./DetailsHeaderContent/DetailsHeaderContent";
import DetailsHeaderContainer from "./Containers/DetailsHeaderContainer";
import AssessmentPerformance from "../Performance/AssessmentPerformance";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import { type LDProps } from "../../../LDProps";
import { FeatureFlags } from "featureFlags";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

const assessmentOverviewUrl = "/content/assets/assessments";

export type AssessmentDetailsPropsAll = PropsFromRedux & WithRouterProps & LDProps;

export class EditAssessment extends Component<AssessmentDetailsPropsAll, EditAssessmentState> {
  private readonly stepsManager = new WizardStepsManager();
  private readonly pages: EnumDictionary<AssessmentDetailsSteps, ISegment>;
  private readonly assessmentId: number;
  private readonly revertModalManager: IModalManager;
  private readonly onDiscardedObserver: IObservable<any>;
  private readonly onTriggerPublishContentObserver: IObservable<
    (onConfirm: () => void, entityState: EntityState) => void
  >;
  private readonly showPacksTab: boolean;

  constructor(props: AssessmentDetailsPropsAll) {
    super(props);
    this.state = {
      activeStepIndex: AssessmentDetailsSteps.Performance,
      showRevertModal: false,
      isValid: true,
      usersGroupsContext: UsersGroupsContext.Groups,
    };

    this.showPacksTab = !!this.props.flags?.[FeatureFlags.AssociatedPacks];

    this.pages = {
      [AssessmentDetailsSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.Performance),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.Performance });
        },
      },
      [AssessmentDetailsSteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.Configuration),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.Configuration });
        },
      },
      [AssessmentDetailsSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.Settings),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.Settings });
        },
      },
      [AssessmentDetailsSteps.Content]: {
        to: "content",
        label: "Content",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.Content),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.Content });
        },
      },
      [AssessmentDetailsSteps.People]: {
        to: "people",
        label: "People",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.People),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.People });
        },
      },
      [AssessmentDetailsSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => this.stepsManager.goToPage(AssessmentDetailsSteps.AssociatedPacks),
        init: () => {
          this.setState({ activeStepIndex: AssessmentDetailsSteps.AssociatedPacks });
        },
      },
    };
    this.assessmentId = this.getAssessmentId();
    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: AssessmentDetailsSteps) => {
      this.pages[activeStepIndex].init?.();
    });
    this.revertModalManager = new ModalManager(
      ModalTypes.RevertAssessment,
      this.handleRevert,
      false,
      this.props.userId,
    );
    this.onDiscardedObserver = new Observable<() => void>();
    this.onTriggerPublishContentObserver = new Observable();
  }

  getAssessmentId = () => Number.parseInt(this.props.params.id!);

  componentDidMount() {
    this.props.detailsActions.getAssessment(this.assessmentId);
    this.props.detailsActions.getAssessmentContent(this.assessmentId);

    RtnEventsEmitter.subscribe(
      [rtnEvents.AssessmentPublishSuccess, rtnEvents.AssessmentDiscardSuccess, rtnEvents.AssessmentLockSuccess],
      this.onPublishedEvent,
    );
  }

  componentWillUnmount() {
    this.props.onUnmount();
    this.stepsManager.dispose();
    this.revertModalManager.dispose();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.AssessmentPublishSuccess, rtnEvents.AssessmentDiscardSuccess, rtnEvents.AssessmentLockSuccess],
      this.onPublishedEvent,
    );
  }

  onPublishedEvent = () => {
    this.onDiscardedObserver.notify();
    this.props.onDiscard(this.assessmentId);
  };

  onRevertClick = () => this.revertModalManager.execute();

  handleEdit = () => {
    this.props.entityStateActions.fetchDraftAssessmentEntity(this.assessmentId);
  };

  onPublish = async () => {
    const { hasBeenPublished, flows } = this.props.assessment;
    // https://brainstorm.atlassian.net/browse/SAAS-25549
    let usersCount = hasBeenPublished ? 1 : 0;

    const entityState = {
      itemsCount: flows.length,
      entityId: this.assessmentId,
      assignmentsCount: usersCount,
      templateType: hasBeenPublished ? TemplateTypes.AssessmentChanged : TemplateTypes.AddedToAssessment,
      notifyConfigType: hasBeenPublished ? NotifyStepConfig.WithProgress : NotifyStepConfig.Default,
      entityType: SwitchEntityTypes.Asset,
    };

    this.triggerPublish(Boolean(entityState.assignmentsCount > 0), entityState);
  };

  triggerPublish = (condition: boolean, entityState: EntityState) => {
    return condition
      ? this.onTriggerPublishContentObserver.notify(noop, entityState)
      : this.handlePublish(this.assessmentId);
  };

  handlePublish = (id: number, notificationSettings?: NotifyStepSettings, notifyTypes?: number[]) => {
    this.props.validateActions.tryPublish(id, notificationSettings, notifyTypes);
  };

  handleRevert = () => {
    this.props.entityStateActions.fetchDiscardAssessmentEntity(this.assessmentId);
  };

  handleClose = () => {
    this.props.navigate(assessmentOverviewUrl);
  };

  back = () => {
    if (!this.stepsManager.goBack()) {
      return assessmentOverviewUrl;
    }
  };

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onDetailsHeaderErrorClick = () => {
    const { navigate, location } = this.props;
    const redirectTo = `/content/assets/assessments/${this.getAssessmentId()}/content`;
    if (!location.pathname.includes(redirectTo)) {
      navigate(redirectTo);
      this.stepsManager.goToPage(AssessmentDetailsSteps.Content);
      this.setState({ activeStepIndex: AssessmentDetailsSteps.Content });
    }
  };

  render() {
    const { assessment, location, contentResolved } = this.props;
    const { isValid, activeStepIndex } = this.state;
    const disabled = assessment.isDraft === false;
    const isPeopleTab = location.pathname.endsWith("people");

    const isAssociatedPacksStep = this.state.activeStepIndex === AssessmentDetailsSteps.AssociatedPacks;
    const isPublishedAndNotPurchased = !assessment.isDraft && !assessment.isPurchased;

    return (
      <Restricted
        permissions={[RolePermissions.AssetsCreate]}
        renderContent={(crudPermission: boolean) => {
          return (
            <div className="nested-content edit-assessment scrollable-content">
              <DetailsHeaderContainer
                title={assessment.title}
                pageTitle={assessment.title}
                titleForGA="Asset Details"
                backButton={this.back || assessmentOverviewUrl}
                defaultURL={assessmentOverviewUrl}
                canBeEdited={!assessment.isDraft && !assessment.isPurchased}
                crudPermission={crudPermission}
                canBePublished={contentResolved}
                isRevertVisible={assessment.hasBeenPublished}
                publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!assessment.isDraft)}
                invalidFormDetails={!isValid}
                entityStateActions={{
                  onPublish: this.onPublish,
                  onClose: this.handleClose,
                  onEdit: this.handleEdit,
                  onRevert: this.onRevertClick,
                }}
                hideEntityStateButtons={isPeopleTab}
              >
                <DetailsHeaderContent
                  onErrorClick={this.onDetailsHeaderErrorClick}
                  assessmentId={this.assessmentId}
                  canAddPacks={isPublishedAndNotPurchased}
                  showAddPacksButton={isAssociatedPacksStep}
                />
              </DetailsHeaderContainer>
              <RevertConfirmationModalTs modalManager={this.revertModalManager} />
              <PublishConfirmationModal
                onTriggerPublishContentObserver={this.onTriggerPublishContentObserver}
                onConfirmAction={this.handlePublish}
                contentType="assessment"
                dependencyType="flow"
              />
              <Dimmer active={this.props.isLoading} inverted>
                <Loader />
              </Dimmer>
              <AssessmentSubheaderContainer
                publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!assessment.isDraft)}
                isUpdateInProgress={assessment.isDraft}
                lastModifiedDateTime={assessment.dateModified}
                showAutoSaveOnTab={EditAssessmentStepConfig[activeStepIndex]?.showAutoSaveOnTab}
              >
                <Segments to={`${assessmentOverviewUrl}/${this.assessmentId}`}>
                  <Segments.Segment {...this.pages[AssessmentDetailsSteps.Performance]} />
                  <Segments.Segment {...this.pages[AssessmentDetailsSteps.Configuration]} />
                  <Segments.Segment {...this.pages[AssessmentDetailsSteps.Settings]} />
                  <Segments.Segment {...this.pages[AssessmentDetailsSteps.Content]} />
                  <Segments.Segment {...this.pages[AssessmentDetailsSteps.People]} />
                  {this.showPacksTab && <Segments.Segment {...this.pages[AssessmentDetailsSteps.AssociatedPacks]} />}
                </Segments>
              </AssessmentSubheaderContainer>
              <div className="scrollable-content">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <div className="edit-form">
                        <AssessmentPerformance
                          acceptHandlers={(handlers) =>
                            this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.Performance)
                          }
                          entityId={this.assessmentId}
                          entityTitle={assessment.title}
                        />
                      </div>
                    }
                  />
                  <Route
                    path={this.pages[AssessmentDetailsSteps.Configuration].to}
                    element={
                      <div className="edit-form">
                        <Configure
                          entityId={this.assessmentId}
                          acceptHandlers={(handlers) =>
                            this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.Configuration)
                          }
                          onIsValidChange={this.onIsValidChange}
                          subscribeOnDiscarded={this.onDiscardedObserver.subscribe}
                          unSubscribeOnDiscarded={this.onDiscardedObserver.unsubscribe}
                          disabled={disabled}
                        />
                      </div>
                    }
                  />
                  <Route
                    path={this.pages[AssessmentDetailsSteps.Settings].to}
                    element={
                      <SettingsTab
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.Settings)
                        }
                        hasAnyPermission={crudPermission}
                      />
                    }
                  />
                  <Route
                    path={this.pages[AssessmentDetailsSteps.Content].to}
                    element={
                      <div className="edit-form">
                        <Content
                          entityId={this.assessmentId}
                          acceptHandlers={(handlers) =>
                            this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.Content)
                          }
                          subscribeOnDiscarded={this.onDiscardedObserver.subscribe}
                          unSubscribeOnDiscarded={this.onDiscardedObserver.unsubscribe}
                          disabled={!crudPermission || disabled}
                        />
                      </div>
                    }
                  />
                  <Route
                    path={this.pages[AssessmentDetailsSteps.People].to}
                    element={
                      <EditAssessmentPeople
                        entityId={this.assessmentId}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.People)
                        }
                        title={assessment.title}
                        usersGroupsContext={this.state.usersGroupsContext}
                        onContextChanged={(usersGroupsContext: UsersGroupsContext) =>
                          this.setState({ usersGroupsContext })
                        }
                      />
                    }
                  />
                  {this.showPacksTab && (
                    <Route
                      path={this.pages[AssessmentDetailsSteps.AssociatedPacks].to}
                      element={
                        <AssociatedPacks
                          acceptHandlers={(handlers) =>
                            this.stepsManager.acceptHandlers(handlers, AssessmentDetailsSteps.AssociatedPacks)
                          }
                          contentType={"Assessment"}
                          contentId={this.assessmentId}
                          canAddPacks={isPublishedAndNotPurchased}
                          permissions={[
                            RolePermissions.AssetsManage,
                            RolePermissions.AssetsCreate,
                            RolePermissions.PacksManage,
                          ]}
                          permissionPredicate={permissionPredicateForPacks}
                        />
                      }
                    />
                  )}
                  <Route path="*" element={<Navigate to="../" replace />} />
                </Routes>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = assessmentsStateSelector(state).base;

  return {
    assessment: base.assessmentDetailsReducer.assessment,
    isLoading:
      base.assessmentDetailsReducer.isAssessmentLoading ||
      base.assessmentDetailsReducer.isContentLoading ||
      base.assessmentEntityStateReducer.changingEntityState,
    isAssessmentLoaded: base.assessmentDetailsReducer.isAssessmentLoaded,
    isContentLoaded: base.assessmentDetailsReducer.isContentLoaded,
    contentErrorMode: base.assessmentContentValidatorReducer.errorMode,
    contentResolved: base.assessmentContentValidatorReducer.resolved,
    userId: state.userProfile.id,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  detailsActions: bindActionCreators(assessmentDetailsActions, dispatch),
  validateActions: bindActionCreators(assessmentContentValidatorActions, dispatch),
  entityStateActions: bindActionCreators(assessmentEntityStateActions, dispatch),

  onDiscard: (id: number) => {
    dispatch(commonAssessmentActions.discardAction());
    dispatch(assessmentDetailsActions.getAssessment(id));
    dispatch(assessmentDetailsActions.getAssessmentContent(id));
  },

  onUnmount: () => {
    dispatch(assessmentDetailsActions.clearAssessment());
    dispatch(assessmentContentValidatorActions.resetAction());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(withLDConsumer()(EditAssessment)));
export default ConnectedComponent;
