import { type FC } from "react";

import { type Filters } from "../../../utils/queryUtils";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getFlowFilters } from "./flowFilters";

export interface FlowFilterFormForPacksProps {
  filterOptions: Filters;
  updateFilter: (filter: Filters) => void;
  filter: Filters;
}
export const FlowFilterFormForPacks: FC<FlowFilterFormForPacksProps> = ({ filterOptions, filter, updateFilter }) => {
  return (
    <FilterFormBuilder
      filterOptions={filterOptions}
      filter={filter}
      updateFilter={updateFilter}
      items={getFlowFilters(false, false)}
    />
  );
};
