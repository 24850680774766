import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";

import DetailsHeaderTitle from "./DetailsHeaderTitle";
import SectionHeader from "../SectionHeader";
import EllipsisPopupButton from "../../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { ButtonWithIcon } from "../../buttons/buttonWithIcon";
import { PublishedStatusTypes } from "../../../enums";
import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";
import { Tooltip } from "../../common/tooltip";
import { toggleEntityStateButtonsVisibility } from "../../../utils/toggleEntityStateButtonsVisibility";
import { publishedStatusPropType } from "../../common/publishedStatus";

import "./outstandingDetailsHeader.scss";

export function OutstandingDetailsHeader(props) {
  const {
    actionButtons,
    entityStateActions,
    children,
    style,
    publishedStatus,
    isRevertVisible,
    canBePublished,
    canBeEdited,
    invalidFormDetails,
    revertModal,
    revertModalType,
    isRevertDisabled,
    hideEntityStateButtons,
    isEllipsisMenuDisabled,
    crudPermission,
    renderEditButton,
    publishTooltipMessage,
    ...headerTitleProps
  } = props;

  const { id } = useParams();
  const location = useLocation();

  let header = <DetailsHeaderTitle {...headerTitleProps} />;

  const renderPublishedStateButtons = () => {
    return (
      <RestrictedByTooltip hasPermission={crudPermission}>
        <ButtonWithIcon
          id="edit-details"
          key="edit-details"
          className="details-handle-buttons"
          basic
          iconName="edit"
          label="EDIT"
          onClick={entityStateActions.onEdit}
          disabled={!crudPermission || !canBeEdited}
        />
      </RestrictedByTooltip>
    );
  };

  const renderReadOnlySection = () => (
    <div className="read-only-label">
      <Icon className="fas fa-ban icon" size="large" />
      READ ONLY
    </div>
  );

  const renderEmptyReadOnlySection = () => <div />;

  const renderDraftStateButtons = () => {
    return (
      <>
        <RestrictedByTooltip hasPermission={crudPermission}>
          <Tooltip
            showAlways={!!publishTooltipMessage}
            inverted
            hideOnScroll
            position="left center"
            content={publishTooltipMessage}
            target={
              <ButtonWithIcon
                id="publish-details"
                key="publish-details"
                className="details-handle-buttons"
                primary
                iconName="eye"
                label="PUBLISH"
                onClick={entityStateActions.onPublish}
                disabled={!crudPermission || invalidFormDetails || canBePublished === false}
              />
            }
          />
        </RestrictedByTooltip>
        {isRevertVisible && (
          <RestrictedByTooltip hasPermission={crudPermission}>
            <ButtonWithIcon
              id="revert-details"
              key="revert-button"
              className="details-handle-buttons"
              basic
              iconName="undo"
              label="REVERT"
              onClick={entityStateActions.onRevert}
              disabled={!crudPermission || isRevertDisabled}
            />
          </RestrictedByTooltip>
        )}
        <ButtonWithIcon
          id="close-details"
          key="close-details"
          className="details-handle-buttons"
          basic
          iconName="times"
          label="CLOSE"
          onClick={entityStateActions.onClose}
        />
      </>
    );
  };

  const renderEntityStateButtons = () => {
    if (props.isReadOnly) {
      return props.showReadOnlyLabel ? renderReadOnlySection() : renderEmptyReadOnlySection();
    }
    if (canBeEdited && (props.renderEditButton || publishedStatus === PublishedStatusTypes.published)) {
      return renderPublishedStateButtons();
    }

    if (publishedStatus === PublishedStatusTypes.draft) {
      return renderDraftStateButtons();
    }

    return null;
  };
  return (
    <SectionHeader header={header} publishedStatus={publishedStatus} className="details-header" style={style}>
      <div className="top-row">
        {children}
        {!hideEntityStateButtons &&
          toggleEntityStateButtonsVisibility(id, location.pathname) &&
          renderEntityStateButtons()}
        {actionButtons && (
          <RestrictedByTooltip hasPermission={crudPermission}>
            <EllipsisPopupButton disabled={!crudPermission || isEllipsisMenuDisabled}>
              {actionButtons}
            </EllipsisPopupButton>
          </RestrictedByTooltip>
        )}
      </div>
    </SectionHeader>
  );
}

OutstandingDetailsHeader.defaultProps = {
  title: "",
  isRevertDisabled: false,
  canBeEdited: false,
  isReadOnly: false,
  showReadOnlyLabel: true,
  hideEntityStateButtons: false,
  crudPermission: true,
};

OutstandingDetailsHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageTitle: PropTypes.string,
  titleForGA: PropTypes.string,
  publishedStatus: publishedStatusPropType,
  isRevertVisible: PropTypes.bool,
  isRevertDisabled: PropTypes.bool,
  canBePublished: PropTypes.bool,
  canBeEdited: PropTypes.bool,
  invalidFormDetails: PropTypes.bool,
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  entityStateActions: PropTypes.shape({
    onPublish: PropTypes.func,
    onRevert: PropTypes.func,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
  }),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.node, PropTypes.array]),
  className: PropTypes.string,
  style: PropTypes.object,
  isReadOnly: PropTypes.bool,
  showReadOnlyLabel: PropTypes.bool,
  hideEntityStateButtons: PropTypes.bool,
  crudPermission: PropTypes.bool,
  isEllipsisMenuDisabled: PropTypes.bool,
  renderEditButton: PropTypes.bool,
  publishTooltipMessage: PropTypes.string,
  backButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  defaultURL: PropTypes.string,
};

export default OutstandingDetailsHeader;
