import { Table } from "semantic-ui-react";
import { type GroupAvailableForLicensing } from "../../../types";
import { columnOptions } from "./getColumnOptions";

export interface GroupListRowProps {
  group: GroupAvailableForLicensing;
}

export const GroupListRow = ({ group }: GroupListRowProps) => {
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>{group.name}</Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{group.membersCount}</Table.Cell>
    </>
  );
};

export default GroupListRow;
