import { TitleSubtitle } from "../../../../components/listViewTemplates";
import AccountPicture from "../../../../components/accountPicture/AccountPicture";

import "./accountInfo.scss";
import { Label } from "semantic-ui-react";

const AccountInfo = (props: AccountInfoProps) => {
  const { account } = props;
  const subtitle = account.admins?.join(", ");
  return (
    <div className="account-info">
      <AccountPicture className="account-info-picture" imageUrl={account.logoUri} />
      <TitleSubtitle
        title={account.name}
        label={account.isDemo ? <Label className="label" basic color="blue" content="demo" /> : undefined}
        url={props.url}
        subtitle={subtitle}
      />
    </div>
  );
};

export interface AccountInfoProps {
  account: {
    id: number;
    name: string;
    logoUri?: string;
    admins?: string[];
    isDemo: boolean;
    canView?: boolean;
  };
  url: string;
}

export default AccountInfo;
