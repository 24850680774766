import cn from "classnames";
import ValidatedField from "./ValidatedField";
import { type FieldPropsBase } from "./models";
import { DropdownWrapper } from "../../../../components/dropdowns";
import { type DropdownItemProps, type DropdownOnSearchChangeData, type DropdownProps, type LabelProps } from "semantic-ui-react";
import React, { useEffect } from "react";
import { isWhiteSpace } from "../../../../utils/stringUtils";

export const DropdownField = (props: DropdownFieldProps) => {
  const {
    disableValidation,
    selfValidation,
    propertyName,
    onChange,
    setFieldTouched,
    setFieldValue,
    onBlur,
    onMount,
    onUnmount,
    onAddItem,
  } = props;

  useEffect(() => {
    onMount?.(propertyName);

    return () => {
      onUnmount?.(propertyName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setFieldTouched(propertyName, true, false);

    const result = isWhiteSpace(data.value) ? "" : data.value;
    setFieldValue(propertyName, result, !selfValidation && !disableValidation);

    onChange?.(event, data);
    onBlur?.(propertyName);
  };

  const handleAddItem = async (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    await handleChange(event, data);

    if (!isWhiteSpace(data.value)) {
      onAddItem?.(event, data);
    }
  };

  const {
    value,
    placeholder,
    search,
    minCharacters,
    onSearchChange,
    searchQuery,
    multiple,
    clearable,
    lazyLoad,
    disabled,
    isLoading,
    renderLabel,
    allowAdditions,
    defaultSearchQuery,
    options,
    className,
    deburr,
  } = props;

  return (
    <ValidatedField {...props}>
      <DropdownWrapper
        blur
        fluid
        search={!disabled && search}
        selection
        minCharacters={minCharacters}
        // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625
        value={value === undefined ? "" : value}
        id={propertyName}
        name={propertyName}
        handleAddItem={handleAddItem}
        handleOptionChange={handleChange}
        onBlur={() => onBlur?.(propertyName)}
        items={options}
        placeholder={placeholder}
        className={cn("field", className)}
        searchQuery={searchQuery}
        onSearchChange={onSearchChange}
        multiple={multiple}
        clearable={clearable && !disabled}
        lazyLoad={lazyLoad}
        disabled={disabled}
        loading={isLoading}
        renderLabel={renderLabel}
        allowAdditions={allowAdditions}
        defaultSearchQuery={defaultSearchQuery}
        deburr={deburr}
      />
    </ValidatedField>
  );
};

type ValueType = boolean | number | string | (boolean | number | string)[] | undefined;

export interface DropdownFieldProps extends FieldPropsBase<ValueType> {
  options: DropdownItemProps[];
  placeholder?: string;
  defaultSearchQuery?: string;
  isLoading?: boolean;
  lazyLoad?: boolean;
  onChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onAddItem?: (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => void;
  allowAdditions?: boolean;
  renderLabel?: (item: DropdownItemProps, index: number, defaultLabelProps: LabelProps) => any;
  clearable?: boolean;
  multiple?: boolean;
  search?: boolean | ((options: DropdownItemProps[], query: string) => DropdownItemProps[]);
  minCharacters?: number;
  onSearchChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => void;
  searchQuery?: string;
  className?: string;
  deburr?: boolean;
}

export default React.memo(DropdownField);
