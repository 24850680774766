import { useEffect, useState, useCallback } from "react";

const ITEMS_PER_PAGE = 10;
const INITIAL_PAGE = 1;

export const usePagination = (countEntities: number, itemsPerPage?: number) => {
  const itemsOnPage = itemsPerPage || ITEMS_PER_PAGE;
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [pageRange, setPageRange] = useState<{ from: number; to: number }>({ from: 1, to: itemsOnPage });

  useEffect(() => {
    if (countEntities <= itemsOnPage && page !== INITIAL_PAGE) {
      setPage(INITIAL_PAGE);
    }
  }, [countEntities, itemsOnPage, page]);

  useEffect(() => {
    setPageRange({ from: itemsOnPage * (page - 1) + 1, to: Math.min(itemsOnPage * page, countEntities) });
  }, [page, countEntities, itemsOnPage]);

  const onNextPage = useCallback(() => setPage((prevPage) => prevPage + 1), []);

  const onPrevPage = useCallback(() => setPage((prevPage) => prevPage - 1), []);

  return { page, pageRange, setPage, onNextPage, onPrevPage };
};
