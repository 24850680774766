import * as actionTypes from "../actionTypes/threatDefenceSendingProfilesOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import { type ThreatDefenceSendingProfilesOverviewState, type ThreatDefenceSendingProfileOverview } from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../../interfaces/redux";

const initialState: ThreatDefenceSendingProfilesOverviewState = {
  sendingProfiles: [],
  isLoading: false,
  hasAccountCreatedDirectSendProfile: false,
  totalCount: 0,
  error: undefined,
};

const threatDefenceSendingProfileOverviewHandlers = () => {
  const {
    getThreatDefenceSendingProfilesBegin,
    getThreatDefenceSendingProfilesSuccess,
    getThreatDefenceSendingProfilesFailure,
    hasAccountDirectSendSendingProfileBegin,
    hasAccountDirectSendSendingProfileSuccess,
    hasAccountDirectSendSendingProfileFailure,
  } = actionTypes;

  const getSendingProfilesBeginHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    sendingProfiles: new Array<ThreatDefenceSendingProfileOverview>(),
    isLoading: true,
  });

  const getSendingProfilesSuccessHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceSendingProfileOverview>>,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    sendingProfiles: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getSendingProfilesFailureHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const hasAccountDirectSendSendingProfileSuccessHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
    action: PayloadAction<boolean>,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    hasAccountCreatedDirectSendProfile: action.payload,
  });

  const hasAccountDirectSendSendingProfileBeginHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    hasAccountCreatedDirectSendProfile: false,
  });

  const hasAccountDirectSendSendingProfileFailureHandler = (
    state: ThreatDefenceSendingProfilesOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceSendingProfilesOverviewState => ({
    ...state,
    error: action.payload,
  });

  return {
    [getThreatDefenceSendingProfilesBegin]: getSendingProfilesBeginHandler,
    [getThreatDefenceSendingProfilesSuccess]: getSendingProfilesSuccessHandler,
    [getThreatDefenceSendingProfilesFailure]: getSendingProfilesFailureHandler,
    [hasAccountDirectSendSendingProfileBegin]: hasAccountDirectSendSendingProfileBeginHandler,
    [hasAccountDirectSendSendingProfileSuccess]: hasAccountDirectSendSendingProfileSuccessHandler,
    [hasAccountDirectSendSendingProfileFailure]: hasAccountDirectSendSendingProfileFailureHandler,
  };
};

export const threatDefenceSendingProfilesOverview = createReducer(initialState, [
  threatDefenceSendingProfileOverviewHandlers,
]);
