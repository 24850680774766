import { useRef } from "react";
import { isEmpty, noop } from "lodash";

import LabelWithInfoTooltip from "../../../../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";
import BasicLinkButton from "../../../../../../../components/buttons/linkButtons/baseLinkButton/BasicLinkButton";
import NextFlowCard from "./nextFlowCard/NextFlowCard";
import NextFlowModal from "../nextFlowModal/NextFlowModal";
import Observable from "../../../../../../../utils/Observable";

import { AddToLinkButton } from "../../../../../../../components";
import { type FlowEndInfo } from "../../../types";
import { type OnTriggerModalObserver } from "../../../ReactFlowCanvas/nodes/StartOfTheFlow/StartOfTheFlowCardWrapper/StartOfTheFlowCardWrapper";

import styles from "./nextFlow.module.scss";

export interface NextFlowProps {
  isReadOnly: boolean;
  flowEndInfo: FlowEndInfo;
  onFlowEndChange: (flowEndInfo: FlowEndInfo) => void;
}

const NextFlow = (props: NextFlowProps) => {
  const {
    isReadOnly,
    flowEndInfo: { nextFlow, completedMessage },
  } = props;
  const onTriggerModalObserver = useRef<OnTriggerModalObserver>(new Observable());

  const showExternalTriggersModal = () => {
    onTriggerModalObserver.current.notify(noop);
  };

  const onRemove = () => {
    props.onFlowEndChange({
      completedMessage: completedMessage,
      nextFlow: undefined,
    });
  };

  return (
    <div className={styles.root} onKeyDown={(e) => e.stopPropagation()}>
      <div className={styles.header}>
        <LabelWithInfoTooltip width={18.3} label="Next Flow" info="Connect another flow to the end of this flow." />
        {!isEmpty(nextFlow) && (
          <BasicLinkButton isDisabled={isReadOnly} text="Change" onClick={showExternalTriggersModal} />
        )}
      </div>
      <div className={styles.body}>
        {isEmpty(nextFlow) ? (
          <AddToLinkButton isDisabled={isReadOnly} text="Connect Flow" onClick={showExternalTriggersModal} />
        ) : (
          <NextFlowCard isReadOnly={isReadOnly} onRemove={onRemove} {...nextFlow} />
        )}
      </div>
      <NextFlowModal
        flowEndInfo={props.flowEndInfo}
        onFlowEndChange={props.onFlowEndChange}
        onTriggerModalObserver={onTriggerModalObserver.current}
      />
    </div>
  );
};

export default NextFlow;
