import { type FC, useCallback, useEffect, useState } from "react";
import { type PackAccountsContextItem, type PackMap } from "../types";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fetchPackLicenseTypes } from "features/Licensing/Packs/state/thunks/packLicenseTypesThunk";
import { connect, type ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";
import { LicenseTypes } from "enums";
import moment from "moment";
import { PackItem } from "./PackItem";
import styles from "./packAccountItems.module.scss";

interface PackAccountItemsPropsOwn {
  packMap: PackMap;
  setIsDisabled: (value: boolean) => void;
  isEdit?: boolean;
  infoText: string;
}

export type PackAccountItemsProps = PackAccountItemsPropsOwn & PropsFromRedux;

export const PackAccountItems: FC<PackAccountItemsProps> = (props) => {
  const { packMap, setIsDisabled, licenseTypes, isLoading, loadLicenseTypes, isEdit, infoText } = props;
  const [items, setSelectedItems] = useState<PackAccountsContextItem[]>([...packMap.values()]);
  useEffect(() => {
    loadLicenseTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hasInvalidItems = items.some(
      (p) => !p.expirationDate && (!p.licenseTypeId || p.licenseTypeId === LicenseTypes.CustomDate),
    );

    const allItemsUnchanged = items.every((p) => !p.isChanged);

    const hasExpiredTrialItems = items.some(
      (p) => p.isChanged && p.licenseTypeId === LicenseTypes.Trial && p.licenseInfo?.isExpired,
    );

    const isNotValid = !items.length || hasInvalidItems || allItemsUnchanged || hasExpiredTrialItems;

    setIsDisabled(isNotValid);
  }, [items, setIsDisabled]);

  const onExpirationDateChange = useCallback(
    (selectedPackId: number, optionId?: number, date?: Date | null) => {
      setSelectedItems((prevPacks) => {
        const packs = [...prevPacks];

        const packIndex = packs.findIndex((el) => el.id === selectedPackId);

        if (packIndex !== -1) {
          const updatedPack = {
            ...packs[packIndex],
            licenseTypeId: optionId ?? packs[packIndex].licenseTypeId,
            expirationDate:
              packs[packIndex].licenseTypeId === LicenseTypes.CustomDate
                ? date ?? packs[packIndex].expirationDate
                : undefined,
            isChanged:
              (optionId && optionId !== packs[packIndex].licenseTypeId) ||
              (date !== undefined && !moment(date).isSame(packs[packIndex].expirationDate, "day")) ||
              packs[packIndex].isChanged,
          };

          packs[packIndex] = updatedPack;

          setSelectedItems(packs);
          packMap.set(updatedPack.id, updatedPack);

          return packs;
        }

        return prevPacks;
      });
    },
    [setSelectedItems, packMap],
  );

  const renderInfoSection = () => {
    const hasNoItems = !items.length;

    return <div className={styles.info}>{hasNoItems ? "The Customer doesn't have any available packs" : infoText}</div>;
  };

  return (
    <form className={styles.root} autoComplete="off">
      {!isLoading && renderInfoSection()}
      {!isLoading &&
        items.map((item, idx) => (
          <PackItem
            key={idx}
            item={item}
            options={licenseTypes}
            onExpirationDateChange={onExpirationDateChange}
            isEdit={isEdit}
            idx={idx}
          />
        ))}
      <Loader active={isLoading} />
    </form>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    licenseTypes: state.packs.packLicenseTypes.items,
    isLoading: state.packs.packLicenseTypes.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadLicenseTypes: bindActionCreators(fetchPackLicenseTypes, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackAccountItems);
export default ConnectedComponent;
