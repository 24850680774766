import ValidatedField from "./ValidatedField";
import { type ErrorPositions } from "../../enums/errorPositions";
import { TimePicker } from "../timePicker";
import { type TimePickerProps } from "../timePicker/TimePicker";
import { type FormikProps } from "formik";
import { type DropdownProps } from "semantic-ui-react";
import React from "react";

export interface TimePickerFieldProps {
  propertyName: string;
  label: string;
  markAsRequired?: boolean;
  onBlur?: (propertyName: string) => void;
  handleChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  errorPosition?: ErrorPositions;
}

export type TimePickerFieldPropsAll<T> = TimePickerProps & TimePickerFieldProps & FormikProps<T>;

export default function TimePickerField<T>(props: TimePickerFieldPropsAll<T>) {
  const handleChange = async (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { propertyName, setFieldTouched, setFieldValue, onBlur } = props;
    await Promise.all([
      setFieldTouched(propertyName, true, false) as unknown as Promise<void>,
      setFieldValue(propertyName, data.value) as unknown as Promise<void>,
    ]);
    onBlur?.(propertyName);
  };

  return (
    <ValidatedField {...props}>
      <TimePicker {...props} onTimeChange={handleChange} />
    </ValidatedField>
  );
}
