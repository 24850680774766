import { type FC, useMemo } from "react";
import cn from "classnames";
import { Image } from "semantic-ui-react";
import PublishStatusCard from "../../../cards/PublishStatusCard";
import { EditPriorityLinkButton } from "../../../buttons/linkButtons";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import getCardPriorityIcon from "../../priority/getCardPriorityIcon";
import { ContentType, type PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import ExpiresCardTemplate from "../../../ExpiresTemplate/ExpiresCardTemplate/ExpiresCardTemplate";
import { type IPdfAssignmentModelItem } from "../../../../features/People/types";
import { type PdfCardBaseProps } from "../../../cards/PdfCard/types";
import pdfIcon from "../../../../images/pdf-icon.svg";

import "./peoplePdfCard.scss";
import { RouteNames } from "enums";

export interface PeoplePdfCardProps extends PdfCardBaseProps<IPdfAssignmentModelItem> {
  peopleType: PeopleType;
  onRemove?: (id: number) => void;
  onEditPriority?: (id: number) => void;
  showExpirationDate?: boolean;
  showAdded?: boolean;
  deepLink?: boolean;
}

export const PeoplePdfCard: FC<PeoplePdfCardProps> = (props: PeoplePdfCardProps): React.ReactElement => {
  const {
    showExpirationDate,
    onSelect,
    onRemove,
    onEditPriority,
    item,
    selected,
    disabled,
    peopleType,
    showAdded,
    selectable,
    deepLink,
  } = props;

  const { id, isDraft, dateCreated, expirationDate, inherited, priorityLevelId, dueDate } = item;

  const pdfCardMenuButtons = useMemo(() => {
    if (!onEditPriority && !onRemove) {
      return null;
    }
    const buttons = [];
    if (onEditPriority) {
      buttons.push(
        <EditPriorityLinkButton key="edit-priority" onClick={() => onEditPriority?.(id)} isDisabled={inherited} />,
      );
    }

    return buttons;
  }, [onEditPriority, onRemove, inherited, id]);

  const icons = [
    getCardPriorityIcon({
      peopleType: peopleType,
      contentType: ContentType.Pdf,
      priority: priorityLevelId,
      dueDate: dueDate,
      tooltipClass: "people-pdf-priority-tooltip",
      showNone: false,
    }),
  ];

  const renderPlaceholderImage = () => (
    <div className={"thumbnail-placeholder"}>
      <Image className={"thumbnail-placeholder-icon"} src={pdfIcon} alt={item.title} />
    </div>
  );

  return (
    <PublishStatusCard
      {...item}
      id={id}
      url={!item.inherited && deepLink ? `/${RouteNames.contentPdfs}/${id}` : undefined}
      placeholderContent={renderPlaceholderImage()}
      className={cn("people-pdf-card")}
      onSelectionChanged={onSelect}
      popupMenuButtons={pdfCardMenuButtons}
      disablePopupMenu={selected}
      published={!isDraft}
      icons={icons}
      selected={selected}
      disabled={disabled}
      selectable={selectable}
    >
      {showExpirationDate && <ExpiresCardTemplate expirationDate={expirationDate} />}
      {showAdded && <div>Added {dateTimeUtils.formatDate(dateCreated)}</div>}
    </PublishStatusCard>
  );
};
