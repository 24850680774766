import { type DropdownProps } from "semantic-ui-react";

import MultiStateDropdown, { type MultiStateDropdownOption } from "../multiStateDropdown/MultiStateDropdown";
import { ContentTypesEnum } from "../../../enums";
import { Icons } from "./constants";

const contentOptions = [
  {
    label: ContentTypesEnum.Flows,
    iconName: Icons.Flows,
  },
  {
    label: ContentTypesEnum.Assets,
    iconName: Icons.Assets,
    options: [
      {
        label: ContentTypesEnum.Videos,
        iconName: Icons.Videos,
      },
      {
        label: ContentTypesEnum.Surveys,
        iconName: Icons.Surveys,
      },
      {
        label: ContentTypesEnum.Assessments,
        iconName: Icons.Assessments,
      },
      {
        label: ContentTypesEnum.PDFs,
        iconName: Icons.PDFs,
      },
    ],
  },
  {
    label: ContentTypesEnum.Events,
    iconName: Icons.Events,
  },
];

const addOptions: MultiStateDropdownOption<ContentTypesEnum>[] = [
  {
    label: ContentTypesEnum.Flows,
    iconName: Icons.Flows,
  },
];

interface ContentSegmentationDropdownProps extends DropdownProps {
  options?: MultiStateDropdownOption<ContentTypesEnum>[];
  defaultValue?: ContentTypesEnum;
}

const getOption = (label: ContentTypesEnum) => ({
  label: label,
  iconName: Icons[label],
});

// temporal
export function AddContentSegmentationDropdown(props: ContentSegmentationDropdownProps) {
  return (
    <MultiStateDropdown
      className="content-segmentation-dropdown"
      options={addOptions}
      defaultOption={props.defaultValue ? getOption(props.defaultValue) : addOptions[0]}
      {...props}
    />
  );
}

export default function ContentSegmentationDropdown(props: ContentSegmentationDropdownProps) {
  return (
    <MultiStateDropdown
      className="content-segmentation-dropdown"
      options={contentOptions}
      defaultOption={props.defaultValue ? getOption(props.defaultValue) : contentOptions[0]}
      {...props}
    />
  );
}
