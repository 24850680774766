import { Table } from "semantic-ui-react";
import { type Dispatch } from "redux";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { bindAction, type ColumnOptions } from "../../../../../interfaces";
import { ListViewBase } from "../../../../../components/listView";
import { type ActivityLog, type IntegrationTypes } from "../../types";
import { ViewType } from "../../../../../enums";
import SortDirections from "../../../../../enums/sortDirections";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import NoResults from "../../../../../components/noResults/NoResults";
import { fetchLogs } from "../../state/thunks/activityLogsThunk";
import { useCallback } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { integrationBaseSelector } from "../../state/selectors";
import { useParams } from "react-router-dom";
import StatusCell from "components/statusCell/StatusCell";

const defaultSorting = Object.freeze({
  columnName: "DATE",
  direction: SortDirections.Desc,
});

const columnOptions: ColumnOptions[] = [
  {
    name: "DATE",
    width: 3,
    isSortable: true,
  },
  {
    name: "TIME",
    width: 3,
  },
  {
    name: "Status",
    width: 3,
    isSortable: true,
  },
  {
    name: "DETAILS",
    width: 6,
  },
];

const renderRow = (log: ActivityLog) => {
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>{dateTimeUtils.formatDate(log.date)}</Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{dateTimeUtils.dateToLastSavedTime(log.date)}</Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <StatusCell status={log.status} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{log.details}</Table.Cell>
    </>
  );
};

export interface ActivityLogsProps {
  type: IntegrationTypes;
  isLoading: boolean;
  items: ActivityLog[];
  itemsCount: number;
  error?: any;
  fetchData: (
    type: IntegrationTypes,
    skip: number,
    take: number,
    sortColumn: string,
    sortDirection: string,
    moboId?: string,
  ) => Promise<void>;
}

export const ActivityLogs: React.FC<ActivityLogsProps & PropsFromRedux> = (props) => {
  const { type, fetchData, items, isLoading, itemsCount } = props;
  const { moboId } = useParams();

  const loadItems = useCallback(
    (skip: number, take: number, sortingColumnName: string, sortingDirection: string, __: any) => {
      fetchData(type, skip, take, sortingColumnName, sortingDirection, moboId);
    },
    [type, fetchData, moboId],
  );

  return (
    <ListViewBase
      loadPage={loadItems}
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      buildTableBody={renderRow}
      items={items}
      itemsAmount={itemsCount}
      isLoading={isLoading}
      withSelection={false}
      sortingColumnName={defaultSorting.columnName}
      sortingDirection={defaultSorting.direction}
      noResultsContent={<NoResults title="No logs for this integration" />}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state);

  return {
    items: base.edit.activityLogs.items,
    isLoading: base.edit.activityLogs.isLoading,
    itemsCount: base.edit.activityLogs.itemsCount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchData: bindAction(fetchLogs, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ActivityLogs);
export default ConnectedComponent;
