import { type FormikProps } from "formik";

import { ValidatedForm } from "../../../../../../components/forms";
import { type FeedbackPageFormProps } from "../types/index";
import { type FeedbackPageView } from "../../types/state";
import validationSchemas from "../../../../../../utils/validationSchemas";
import TaskStatuses from "../../../../../../enums/taskStatuses";
import { type VideoFeedbackPageTask } from "../../../../../BackgroundTasks/taskPool";

export type FeedbackPageFormPropsAll = FeedbackPageFormProps & FormikProps<FeedbackPageView>;

const FeedbackPageForm = (props: FeedbackPageFormPropsAll) => {
  const renderFields = (): any => {
    const {
      values,
      onBlur,
      disabled,
      onIsValidChange,
      handleFileChange,
      uploadFilesHandler,
      cancelFileUploading,
      task,
    } = props;
    const validatedFieldProps = getValidateFieldProps();
    const getUploadingError = (videoUploadTask: VideoFeedbackPageTask) =>
      TaskStatuses.isFailed(videoUploadTask.status) ? videoUploadTask.errorLabel : null;

    return (
      <div className="feedback-page-form-container">
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.UploadFileField
          id="fileUpload"
          label="Video"
          loadingButtonLabel="Loading..."
          onChangeHandler={handleFileChange}
          accept="video/*"
          propertyName="uploadedVideos"
          value={values.uploadedVideos}
          disabled={disabled}
          filesValidator={validationSchemas.feedbackPageInfo.fields.uploadedVideos}
          uploadFilesHandler={uploadFilesHandler}
          uploadingError={getUploadingError(task)}
          isUploading={task.isFeedbackPageVideoUploading}
          uploadingProgress={task.percent}
          onCancelFileUploading={cancelFileUploading}
          markAsRequired
          showPercents={false}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Software Application"
          propertyName="softwareApplications"
          className="software-applications"
          value={values.softwareApplications.selected}
          options={values.softwareApplications.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Labels"
          propertyName="labels"
          value={values.labels.selected}
          options={values.labels.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  const getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm } = props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };

  const { dirty, isLoading, disablePreventTransitionPrompt } = props;

  return (
    <ValidatedForm
      dirty={dirty}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message:
          "Are you sure you want to exit without saving this Feedback page? All information entered will be lost.",
      }}
      isLoading={isLoading}
      isSubmitting={isLoading}
      formWidthComputer={6}
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      isInitialValid={false}
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default FeedbackPageForm;
