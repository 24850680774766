import { type PropsWithChildren } from "react";
import { Icon } from "semantic-ui-react";
import Strings from "../../../enums/strings";
import { Tooltip } from "../../common/tooltip";
import DeletionRestrictedTooltip from "../../tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import EllipsisPopupButton from "../ellipsisPopupButton/EllipsisPopupButton";

import "./сontentItemMenu.scss";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

interface ContentModelBase {
  isPurchased: boolean;
  canBeDeleted: boolean;
}

export interface ContentItemMenuProps {
  item: ContentModelBase;
  isChecked?: boolean;
  readonly?: boolean;
  entityType: string;
  dependencies: { [key: string]: number };
  purchasedTooltipContent?: string;

  circle?: boolean;
  position?: string;
  outlinedEllipsis?: boolean;
}

export const ContentItemMenu = (props: PropsWithChildren<ContentItemMenuProps>) => {
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const getTooltip = () => {
    const { item, isChecked, entityType, dependencies, purchasedTooltipContent } = props;

    if (!isChecked) {
      return undefined;
    }

    if (item.isPurchased) {
      return (
        <Tooltip
          showAlways
          inverted
          hideOnScroll
          position="bottom left"
          target={<Icon link className="fa fa-exclamation-circle" size="big" />}
          content={purchasedTooltipContent || Strings.tooltipMessages.purchased.operationDisabled(entityType)}
        />
      );
    }

    if (!deleteContentEnabled && !item.canBeDeleted) {
      const properNouns = ["flow", "pack"];
      let newEntityType;
      if (properNouns.includes(entityType)) {
        newEntityType = `${entityType[0].toUpperCase()}${entityType.slice(1).toLowerCase()}`;
      } else {
        newEntityType = entityType;
      }
      return <DeletionRestrictedTooltip dependencies={dependencies} entityType={newEntityType} />;
    }
  };

  const getMenu = () => {
    const { circle, outlinedEllipsis, readonly, isChecked } = props;
    return (
      <EllipsisPopupButton circle={circle} outlinedEllipsis={outlinedEllipsis} disabled={readonly || isChecked}>
        {props.children}
      </EllipsisPopupButton>
    );
  };

  return (
    <div onClick={(e) => e.preventDefault()} className="content-item-menu">
      {getTooltip() || getMenu()}
    </div>
  );
};
