import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import AssignPermissions from "../../CreateRoles/AssignAccountPermissions/AssignPermissions";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { keyBy, noop } from "lodash";
import permissionsHelper from "../../CreateRoles/permissionsHelper";

import "./editAssignPermissions.scss";
import FormFooter from "../../../../components/forms/FormFooter";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";

const EditAssignPermissions = ({
  permissions,
  configuredPermissions,
  isLoading,
  availableAccounts,
  isLoadingAvailableAccounts,
  availableGroups,
  isLoadingAvailableGroups,
  isReadOnly,
  updateRolePermissions,
}) => {
  const mappedConfiguredPermissions = useMemo(() => {
    let result = [];
    if (configuredPermissions.length > 0 && permissions.length > 0) {
      result = permissionsHelper.mapAllToConfiguredPermissions(configuredPermissions, permissions);
    }
    return result;
  }, [configuredPermissions, permissions]);

  const initialPermissionsMap = useMemo(() => {
    return keyBy(mappedConfiguredPermissions, (x) => x.id);
  }, [mappedConfiguredPermissions]);

  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [editingPermissions, setEditingPermissions] = useState(mappedConfiguredPermissions);

  const resetFormState = () => {
    setIsDirty(false);
    setIsValid(true);
  };

  useEffect(() => {
    resetFormState();
    setEditingPermissions(mappedConfiguredPermissions);
  }, [mappedConfiguredPermissions]);

  const onCancel = () => {
    resetFormState();
    setEditingPermissions(mappedConfiguredPermissions);
  };

  const onSave = async () => {
    const rolePermissions = permissionsHelper.mapConfiguredPermissionsToRolePermissions(editingPermissions);
    await updateRolePermissions(rolePermissions);

    resetFormState();
  };

  const onPermissionsChanged = (newPermissions) => {
    const newIsDirty = !permissionsHelper.checkConfiguredPermissionsEqual(mappedConfiguredPermissions, newPermissions);

    setIsDirty(newIsDirty);
    setEditingPermissions(newPermissions);
  };

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="role-edit-permissions-container">
      <Dimmer active={isLoading} inverted>
        <Loader active />
      </Dimmer>
      <div className="stretch edit-assign-permissions">
        <AssignPermissions
          classNameButtons={"edit-buttons"}
          permissions={permissions}
          configuredPermissions={editingPermissions}
          onPermissionsChanged={onPermissionsChanged}
          onIsValidChange={setIsValid}
          onFetchAvailableAccounts={noop}
          availableAccounts={availableAccounts}
          isLoadingAvailableAccounts={isLoadingAvailableAccounts}
          onFetchAvailableGroups={noop}
          availableGroups={availableGroups}
          isLoadingAvailableGroups={isLoadingAvailableGroups}
          isReadOnly={isReadOnly}
          initialPermissions={initialPermissionsMap}
        />
      </div>
      <FormFooter
        isSaveBtnDisabled={!(isDirty && isValid)}
        isCancelBtnDisabled={!isDirty}
        onSave={onSave}
        onCancel={onCancel}
      />
      <PreventTransitionPrompt
        when={isDirty}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving this role? All information entered will be lost."
      />
    </Dimmer.Dimmable>
  );
};

EditAssignPermissions.propTypes = {
  permissions: PropTypes.array,
  configuredPermissions: PropTypes.array,
  availableAccounts: PropTypes.array,
  isLoadingAvailableAccounts: PropTypes.bool,
  availableGroups: PropTypes.array,
  isLoadingAvailableGroups: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  updateRolePermissions: PropTypes.func,
};

export default EditAssignPermissions;
