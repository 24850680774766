import axios from "axios";
import KnownHttpHeaders from "../knownHttpHeaders";
import environmentConfig from "../../../../configuration/environmentConfig";

import emailsDataService from "../../../Library/Communications/Emails/services/emailsDataService";
import assessmentService from "../../../Library/Assessments/services/assessmentsDataService";
import surveysService from "../../../Library/Surveys/services/surveysDataService";
import linksDataService from "../../../Library/Links/services/linksService";
import eventsDataService from "../../../Library/Events/services/eventsDataService";
import packsService from "../../../Licensing/Packs/services/packsDataService";
import flowsDataService from "../../../Library/Flows/services/flowsDataService";
import videoDataService from "../../../Library/Videos/services/videoDataService";
import { threatDefenceDataServices } from "../../../Library/SimulatedPhishing";
import groupsDataService from "../../../People/services/groupsDataService";
import { reportingDataService } from "../../../Reporting/services/reportingService";
import { usersDataService } from "./usersDataService";
import notificationSettingsService from "../../../SystemNotifications/services/notificationSettingsService";
import { templateService } from "../../../SystemNotifications/services/templateService";
import pdfDataService from "../../../Library/Pdfs/services/pdfDataService";
import StatusCode from "../../../../enums/httpStatusCodes";
import messageDataService from "../../../Library/Communications/Messages/services/messageDataService";
import { contentDataService } from "./contentDataService";
import { groupTemplateDataService } from "features/People/GroupTemplate/DataService/groupTemplateDataService";
import accountDataService from "features/Accounts/services/accountsDataService";

interface IDataServiceBase {
  registerAccessTokenFactory(getAccessToken: () => Promise<string | undefined>): void;
  registerMoboHeaderInterceptor(moboId: number): void;
  registerNotFoundInterceptor(cb: () => void): () => void;
}

const initDefaultHeaders = () => {
  axios.defaults.baseURL = environmentConfig.apiUrl;
  axios.defaults.headers.common = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  };
};

initDefaultHeaders();

export namespace DataService {
  export const base: IDataServiceBase = {
    registerAccessTokenFactory(getAccessToken: () => Promise<string | undefined>) {
      axios.interceptors.request.use(async (config) => {
        const token = await getAccessToken();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      });
    },

    registerMoboHeaderInterceptor(moboId: number) {
      axios.interceptors.request.use((config) => {
        if (config.headers[KnownHttpHeaders.MoboId] === undefined) config.headers[KnownHttpHeaders.MoboId] = moboId;

        return config;
      });
    },

    registerNotFoundInterceptor(cb: () => void) {
      const id = axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          const response = error.response;

          if (response && response.status === StatusCode.NotFound && response.config.method.toLowerCase() !== "head") {
            cb && cb();
          }

          return Promise.reject(error);
        },
      );

      return () => axios.interceptors.response.eject(id);
    },
  };

  export const accounts = accountDataService;

  export const surveys = surveysService;

  export const threatDefence = threatDefenceDataServices;

  export const emails = emailsDataService;

  export const assessments = assessmentService;

  export const links = linksDataService;

  export const events = eventsDataService;

  export const packs = packsService;

  export const flows = flowsDataService;

  export const assets = videoDataService;

  export const pdfs = pdfDataService;

  export const messages = messageDataService;

  export const groups = groupsDataService;

  export const groupTemplates = groupTemplateDataService;

  export const users = usersDataService;

  export const content = contentDataService;

  export const reportingService = reportingDataService;

  export const systemNotifications = {
    settings: notificationSettingsService,
    templates: templateService,
  };
}

export default DataService;
