import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/buttons/button/Button";

function PreventTransitionPromptActionButtons(props) {
  const { handleCancel, handleExit, isUploading } = props;

  return (
    <>
      <Button id="cancel-navigate-away-button" className="close" onClick={handleCancel} basic color="blue">
        Cancel
      </Button>
      <Button id="confirm-navigate-away-button" className="exit" onClick={handleExit} primary loading={isUploading}>
        Exit
      </Button>
    </>
  );
}

PreventTransitionPromptActionButtons.propTypes = {
  handleCancel: PropTypes.func,
  handleExit: PropTypes.func,
  isUploading: PropTypes.bool,
};

export default PreventTransitionPromptActionButtons;
