import { combineReducers } from "redux";
import { threatDefenceCampaignsOverviewReducer } from "./threatDefenceCampaignsOverviewReducer";
import { threatDefenceCampaignDetails } from "./threatDefenceCampaignsDetailsReducer";
import { threatDefenceCampaignOptionsReducer } from "./threatDefenceCampaignOptionsReducer";
import threatDefenceCampaignEntityStateReducer from "./threatDefenceCampaignEntityStateReducer";

export default combineReducers({
  threatDefenceCampaignsOverviewReducer,
  threatDefenceCampaignDetails,
  threatDefenceCampaignOptionsReducer,
  threatDefenceCampaignEntityStateReducer,
});
