import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../../Application/slices/models";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

export interface CreatingSendState {
  created: boolean;
  isLoading: boolean;
  error?: string;
}

export const emailCreateSendInitialState: CreatingSendState = {
  created: false,
  isLoading: false,
  error: undefined,
};

const emailCreateSendSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Emails,
    name: "createSend",
  }),
  initialState: emailCreateSendInitialState,
  reducers: {
    createBegin(state: CreatingSendState) {
      state.isLoading = true;
    },
    createSuccess(state: CreatingSendState) {
      state.created = true;
      state.isLoading = false;
    },
    createFailure(state: CreatingSendState, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetCreation() {
      return { ...emailCreateSendInitialState };
    },
  },
});

export const { createBegin, createSuccess, createFailure, resetCreation } = emailCreateSendSlice.actions;

export const emailCreateSendReducer = emailCreateSendSlice.reducer;
