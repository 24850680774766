import cn from "classnames";
import React, { memo, useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import ReactFlow, { type NodeTypes, useReactFlow } from "reactflow";
import { v4 } from "uuid";

import DragNDropTypes from "../../../../../enums/dragNDropItemTypes";
import { defaultSectionHeader, DRAG_SHIFT } from "../constants";
import { ExtrasTypes } from "../Extras/types";
import { type ElementAndPosition, type EntityTriggerTypes, type TriggerTimeUnit } from "../types";
import CustomConnectionLine from "./edges/ConnectionLine";
import SpecialEdge from "./edges/SpecialEdge";
import { startOfTheFlow } from "./hooks/useElements";
import { isFlowEndDragging, isOverlapStartOfTheFlow, useReactFlowCanvas } from "./hooks/useReactFlowCanvas";
import { type IFlowEdge, type IFlowNode, nodeTypes } from "./nodes/types";
import ObjectUtils from "utils/objectUtils";

import "./ReactFlowCanvas.scss";
import "reactflow/dist/base.css";
import { useReactFlowCanvasState } from "./Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowCanvasActions } from "./Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useReactFlowState } from "./Providers/ReactFlowStateProvider/hooks/useReactFlowState";
import { useReactFlowActions } from "./Providers/ReactFlowStateProvider/hooks/useReactFlowActions";

interface IReactFlowCanvasOwnProps {
  isReadOnly: boolean;
  triggerTypes: EntityTriggerTypes[];
  triggerTimeUnits: TriggerTimeUnit[];
  reactFlowWrapper: React.RefObject<HTMLDivElement>;
}

const EDGE_TYPES = { special: SpecialEdge };
const defaultPosition = { x: 0, y: 0 };

const ReactFlowCanvas: React.FC<IReactFlowCanvasOwnProps> = ({
  isReadOnly,
  triggerTypes,
  triggerTimeUnits,
  reactFlowWrapper,
}) => {
  const { headId, itemToReplace, itemForSectionHeader, reactFlowBounds } = useReactFlowCanvasState();
  const { setHeadId } = useReactFlowCanvasActions();
  const { setNodes, setEdges, onNodesChange, onEdgesChange } = useReactFlowActions();
  const { nodes, edges } = useReactFlowState();
  const { getZoom, project } = useReactFlow();
  const {
    isConnection,
    onConnect,
    onConnectStart,
    onConnectEnd,
    onPaneClick,
    onDrop,
    onDragOver,
    onNodeDrag,
    onDragLeave,
    onNodeDragStop,
    onEdgeUpdateStart,
    onEdgeUpdate,
    onNodeDragStart,
  } = useReactFlowCanvas(reactFlowBounds, triggerTypes, triggerTimeUnits, isReadOnly);

  const getPosition = useCallback(
    (x: number, y: number) => {
      return project({
        x: x - reactFlowBounds.left - DRAG_SHIFT * getZoom(),
        y: y - reactFlowBounds.top - DRAG_SHIFT * getZoom(),
      });
    },
    [project, getZoom, reactFlowBounds.left, reactFlowBounds.top],
  );

  const replaceEdges = (flowNode: IFlowNode, id: string) => {
    const generateEdgeId = (source: string, target: string, sourceHandle?: string | null) =>
      sourceHandle ? `el-${target}-${source}-${sourceHandle}` : `el-${target}-${source}`;

    setEdges((edges: IFlowEdge[]) => {
      return edges
        .filter((edge) => !(edge.source === flowNode.id && edge.sourceHandle))
        .map((edge) => {
          if (edge.source === flowNode.id) {
            return flowNode.type === ExtrasTypes.Deleted
              ? null
              : {
                  ...edge,
                  data: { ...edge.data!, inId: id },
                  id: generateEdgeId(edge.source, edge.target, edge.sourceHandle),
                  source: id,
                };
          }

          if (edge.target === flowNode.id) {
            return {
              ...edge,
              data: { ...edge.data!, outId: id },
              id: generateEdgeId(edge.source, edge.target, edge.sourceHandle),
              target: id,
            };
          }
          return edge;
        })
        .filter(ObjectUtils.isNotNull);
    });
  };

  const replaceItem = (itemToReplace: ElementAndPosition | undefined, node: IFlowNode, isStartOfTheFlow: boolean) => {
    if (isStartOfTheFlow) {
      setHeadId(node.id);
    }
    const id = node.id;
    const itemToReplaceIndex = nodes.findIndex((node) => node.id === itemToReplace?.id);
    const flowNode = nodes[itemToReplaceIndex];

    setNodes((nodes) => {
      const updatedNodes = [...nodes];
      let updatedNode = {
        ...node,
        position: isStartOfTheFlow ? startOfTheFlow.position : itemToReplace?.topLeft,
        data: {
          ...node.data,
          id,
          connected: flowNode.data?.connected,
          sectionHeader: flowNode.data?.sectionHeader,
        },
      };
      updatedNodes.splice(itemToReplaceIndex, 1, updatedNode as IFlowNode);
      return updatedNodes;
    });
    replaceEdges(flowNode, id);
  };

  const addSectionHeader = (itemId: string) => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === itemId) {
          return { ...node, data: { ...node.data, sectionHeader: defaultSectionHeader } };
        }
        return node;
      });
    });
    return undefined;
  };

  const addNode = (node: IFlowNode) => {
    setNodes((nodes) => {
      node.data.hasAccess = true;
      if (nodes.length === 2) {
        node.className = "react-flow__node--first";
      }
      if (!nodes[1].hidden) {
        nodes[1].hidden = true; // hide placeholder node
      }
      return nodes.concat(node);
    });
  };

  const [, drop] = useDrop<any, undefined, undefined>({
    accept: DragNDropTypes.DESIGNER,
    drop(item, monitor) {
      // add section header
      if (itemForSectionHeader) {
        addSectionHeader(itemForSectionHeader.id);
      }

      // prevent section headers dropping on free canvas
      if (item.elementType === ExtrasTypes.SectionHeader) {
        return undefined;
      }

      const { x, y } = monitor.getClientOffset() || defaultPosition;
      const id = v4();
      let position = getPosition(x, y) || defaultPosition;
      const _isOverlapStartOfTheFlow = isOverlapStartOfTheFlow(position);

      if (!headId && _isOverlapStartOfTheFlow && !isFlowEndDragging(item.elementType)) {
        position = startOfTheFlow.position;
        setHeadId(id);
      }
      const node: IFlowNode = {
        id,
        type: item.elementType,
        position,
        data: {
          id,
          hasEntity: true,
          entityId: item.id,
          elementType: item.elementType,
          publisher: item.publisher,
          thumbnailUrl: item.thumbnailUrl,
          durationInSeconds: item.durationInSeconds,
          questionsCount: item.questionsCount,
          branchingQuestion: item.branchingQuestion,
          bag: item.bag,
          hasReminders: item.hasReminders,
          title: item.title,
          connected: {
            target: false,
            source: false,
          },
          canConnect: true,
          sectionHeader:
            _isOverlapStartOfTheFlow && !headId && !isFlowEndDragging(item.elementType)
              ? defaultSectionHeader
              : undefined,
          requiredPacks: item.requiredPacks,
          hasAccess: item.hasAccess,
        },
      };

      if (itemToReplace) {
        replaceItem(itemToReplace, node, _isOverlapStartOfTheFlow);
        return undefined;
      }

      addNode(node);

      return undefined;
    },
  });

  const reactFlowProps = useMemo(() => {
    if (isReadOnly) {
      return {};
    }
    return {
      onEdgeUpdate,
      onEdgeUpdateStart,
      onConnect,
      onConnectStart,
      onConnectEnd,
    };
  }, [isReadOnly, onEdgeUpdate, onEdgeUpdateStart, onConnect, onConnectStart, onConnectEnd]);

  return (
    <>
      <div
        ref={reactFlowWrapper}
        className={cn("react-flow__wrapper", {
          "react-flow__wrapper--connection": isConnection,
          readonly: isReadOnly,
          "react-flow__wrapper--pulse-animation-case": nodes.length === 4, // 4 - because it includes start of the flow and placeholder
        })}
      >
        <ReactFlow
          ref={drop}
          nodeTypes={nodeTypes as NodeTypes}
          minZoom={0.2}
          maxZoom={1}
          selectNodesOnDrag={false}
          nodesDraggable={!isReadOnly}
          nodesConnectable={!isReadOnly}
          connectionLineComponent={CustomConnectionLine}
          deleteKeyCode={null}
          selectionKeyCode={null}
          nodes={nodes}
          edges={edges}
          edgeUpdaterRadius={10}
          edgeTypes={EDGE_TYPES}
          onlyRenderVisibleElements
          elevateNodesOnSelect={false}
          disableKeyboardA11y
          nodesFocusable={false}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onDragLeave={onDragLeave}
          onNodeDragStop={onNodeDragStop}
          onPaneClick={onPaneClick}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          proOptions={{ hideAttribution: true }}
          {...reactFlowProps}
        />
      </div>
    </>
  );
};

export default memo(ReactFlowCanvas);
