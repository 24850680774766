import { Icon } from "semantic-ui-react";
import cn from "classnames";

import styles from "./ExpireLabel.module.scss";

export interface Props {
  date: string;
}

export const ExpireLabel: React.FC<Props> = ({ date }) => {
  return (
    <div className={styles.root} data-testid="expire-label">
      <Icon className={cn("fa-exclamation-triangle", styles.icon)} />
      {date}
    </div>
  );
};
