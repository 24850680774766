interface TooltipState {
  isOpen: boolean;
  lineState: {
    x: number;
    y: number;
    text: string;
    useTop: boolean;
  };
  barState: {
    x: number;
    y: number;
    text: string;
  };
}

type TooltipAction =
  | {
      type: "dispatchPopups";
      payload: Omit<TooltipState, "isOpen">;
    }
  | { type: "closePopups" };

export const tooltipReducer = (state: TooltipState, action: TooltipAction): TooltipState => {
  switch (action.type) {
    case "dispatchPopups":
      return {
        ...state,
        isOpen: true,
        lineState: action.payload.lineState,
        barState: action.payload.barState,
      };
    case "closePopups":
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export const initialTooltipState: TooltipState = {
  isOpen: false,
  lineState: { x: 0, y: 0, text: "", useTop: true },
  barState: { x: 0, y: 0, text: "" },
};
