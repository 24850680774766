import { sendTransientNotification, userCreated } from "../../../../Notifications/state/notificationsActions";
import AlertTypes from "../../../../../enums/alertTypes";
import * as peopleEventTypes from "../events/people/peopleEvents";

import * as backgroundTasksActions from "../../../../BackgroundTasks/state/backgroundTasksActions";
import * as editRoleActions from "../../../../People/EditRole/editRoleActions";
import importUsersTasks from "../../../../People/Users/UsersOverview/backgroundTasks/importUsersTasks";
import fileUtils from "../../../../../utils/fileUtils";
import { usersAddedToRole } from "../../../../People/RolesOverview/state/rolesOverviewActions";

const handleIdenticalInMapping = (mapping) => (eventTypes, handler) => {
  for (const eventType of eventTypes) {
    mapping[eventType] = handler;
  }
};

const addRolesMapping = (mapping, dispatch) => {
  const handleIdentical = handleIdenticalInMapping(mapping);

  handleIdentical(
    [
      peopleEventTypes.AssignUsersToRolesFailed,
      peopleEventTypes.UnassignRolesFailure,
      peopleEventTypes.UpdateRoleInfoFailure,
      peopleEventTypes.UpdateRolePermissionsFailure,
      peopleEventTypes.RoleDeleteFailure,
    ],
    (payload) => {
      dispatch(backgroundTasksActions.updateFailedTaskOperation(payload.executionId));
    },
  );

  handleIdentical(
    [peopleEventTypes.UpdateRoleInfoSuccess, peopleEventTypes.UpdateRolePermissionsSuccess],
    (payload) => {
      dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.executionId));
      dispatch(editRoleActions.updateUpdatedOnRoleUpdate(payload.roleId, payload.updated));
    },
  );

  mapping[peopleEventTypes.AssignUsersToRoles] = (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.executionId));
    dispatch(usersAddedToRole(payload.assignments));
  };

  mapping[peopleEventTypes.UnassignRolesSuccess] = (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.executionId));
    dispatch(editRoleActions.updateUsersOnRoleUnassign(payload.unassignments));
  };

  mapping[peopleEventTypes.RoleDeletedSuccess] = (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.executionId));
  };
};

const notifications = [
  {
    type: peopleEventTypes.GroupDuplicated,
    message: "Group has been duplicated!",
    alertType: AlertTypes.success,
  },
  {
    type: peopleEventTypes.GroupDuplicateFailed,
    message: "Group duplicate failed!",
    alertType: AlertTypes.error,
  },
  {
    type: peopleEventTypes.UserUpdated,
    message: "User has been updated!",
    alertType: AlertTypes.success,
  },
  {
    type: peopleEventTypes.GroupInfoUpdateFailed,
    message: "Group update failed!",
    alertType: AlertTypes.error,
  },
  {
    type: peopleEventTypes.UserRemoveFromGroupFail,
    message: "User removal from group is failed!",
    alertType: AlertTypes.error,
  },
];

export const peopleMapping = (mapping, dispatch) => {
  const handleIdentical = handleIdenticalInMapping(mapping);

  const showMessage = (message, alertType) => () => {
    dispatch(
      sendTransientNotification({
        type: alertType,
        message: message,
        dismissInSeconds: 3,
      }),
    );
  };

  notifications.forEach((notification) => {
    mapping[notification.type] = showMessage(notification.message, notification.alertType);
  });

  mapping[peopleEventTypes.FailedToImportUsersFileResult] = (payload) => {
    const { InstanceId, FileUri } = payload;
    dispatch(backgroundTasksActions.updateTask(InstanceId, importUsersTasks.Failure(InstanceId, FileUri)));
  };

  mapping[peopleEventTypes.FailedToImportUsers] = (payload) => {
    const { InstanceId } = payload;
    dispatch(backgroundTasksActions.updateTask(InstanceId, importUsersTasks.FailureWithoutFile(InstanceId)));
    dispatch(
      sendTransientNotification({
        type: AlertTypes.error,
        message: "Cannot parse file.",
        DismissInSeconds: 3,
      }),
    );
  };

  mapping[peopleEventTypes.SucceedUserImport] = (message) => {
    const { InstanceId } = message;
    dispatch(backgroundTasksActions.updateTask(InstanceId, importUsersTasks.Success(InstanceId)));
  };

  mapping[peopleEventTypes.UserCsvCreated] = (message) => {
    const { Index, Count, Payload } = message;

    const percentage = ((Index + 1) / Count) * 100;
    dispatch(backgroundTasksActions.updateProgress(Payload.InstanceId, percentage, false));
  };

  mapping[peopleEventTypes.SucceedUsersExport] = (message) => {
    const { FileUri } = message;

    fileUtils.downloadFile(FileUri);
    dispatch(
      sendTransientNotification({
        type: AlertTypes.success,
        message: "File prepared! Download started...",
        DismissInSeconds: 3,
      }),
    );
  };

  mapping[peopleEventTypes.FailedUsersExport] = () => {
    dispatch(
      sendTransientNotification({
        type: AlertTypes.error,
        message: "Export failed!",
        DismissInSeconds: 3,
      }),
    );
  };

  mapping[peopleEventTypes.GroupCreateFailed] = (payload) => {
    dispatch(
      sendTransientNotification({
        type: AlertTypes.error,
        message: `Failed to create group: ${payload.GroupName}`,
        DismissInSeconds: 3,
      }),
    );
  };

  mapping[peopleEventTypes.GroupInfoUpdated] = (payload) => {
    showMessage("Group information update succeeded!", AlertTypes.success)();
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.MessageId));
  };

  handleIdentical([peopleEventTypes.GroupDeleted], (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.MessageId, payload.GroupId));
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.OperationId));
  });

  handleIdentical([peopleEventTypes.GroupDeleteFailed], (payload) => {
    dispatch(backgroundTasksActions.updateFailedTaskOperation(payload.OperationId));
  });

  handleIdentical([peopleEventTypes.UserUpdated], (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.MessageId, payload.GroupId));
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.OperationId));
  });

  handleIdentical([peopleEventTypes.GroupDuplicated], (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.MessageId));
  });

  handleIdentical([peopleEventTypes.UserRemoveFromGroupSuccess, peopleEventTypes.UserDeleted, peopleEventTypes.UserManagerDelete], (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.OperationId));
  });

  handleIdentical(
    [peopleEventTypes.UserRemoveFromGroupFail, peopleEventTypes.UserDeleteFailed, peopleEventTypes.GroupDeleteFailed, peopleEventTypes.UserManagerDeleteFailed], 
    (payload) => {
      dispatch(backgroundTasksActions.updateFailedTaskOperation(payload.OperationId));
    },
  );

  handleIdentical([peopleEventTypes.GroupDuplicateFailed, peopleEventTypes.GroupInfoUpdateFailed], (payload) => {
    dispatch(backgroundTasksActions.updateFailedTaskOperation(payload.MessageId));
  });

  addRolesMapping(mapping, dispatch);

  mapping[peopleEventTypes.UserCreated] = (payload) => {
    dispatch(userCreated(payload));
  };

  mapping[peopleEventTypes.AddUserToGroupAllSuccess] = (payload) =>
    dispatch(backgroundTasksActions.updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[peopleEventTypes.AddUserToGroupSuccess] = (payload) =>
    dispatch(backgroundTasksActions.updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[peopleEventTypes.AddUserToGroupAllFailure] = (payload) =>
    dispatch(backgroundTasksActions.updateFailedTaskDistributedOperation(payload.operationId));

  mapping[peopleEventTypes.UserDeleteAllSuccess] = (payload) =>
    dispatch(backgroundTasksActions.updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[peopleEventTypes.UserManagerDeleteAllSuccess] = (payload) =>
    dispatch(backgroundTasksActions.updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[peopleEventTypes.UserManagerDelete] = (payload) =>
    dispatch(backgroundTasksActions.updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[peopleEventTypes.UserDeleted] = (payload) =>
    dispatch(backgroundTasksActions.updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[peopleEventTypes.UserDeleteAllFailure] = (payload) =>
    dispatch(backgroundTasksActions.updateFailedTaskDistributedOperation(payload.operationId));
  mapping[peopleEventTypes.UserManagerAddedSuccess] = (payload) => {
    dispatch(backgroundTasksActions.updateSucceededTaskOperation(payload.MessageId));
  };
  mapping[peopleEventTypes.UserManagerAddFailed] = (payload) => {
    dispatch(backgroundTasksActions.updateFailedTaskOperation(payload.MessageId));
  };
  mapping[peopleEventTypes.UserManagerDeleteAllFailure] = (payload) =>
    dispatch(backgroundTasksActions.updateFailedTaskDistributedOperation(payload.operationId));
};
