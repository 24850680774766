import * as events from "../events/library/libraryEvents";
import {
  publishDraftThreatDefenceEmailTemplateSuccess,
  threatDefenceEmailTemplateUpdated,
  updateThreatDefenceEmailTemplateCommandSuccess,
  fetchDiscardThreatDefenceEmailTemplateSuccess,
  fetchLockedTdEmailTemplateSuccess,
} from "../../../../Library/SimulatedPhishing/EmailTemplates/state/actions/threatDefenceEmailTemplateEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const emailTemplatesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.EmailTemplateEditSuccess] = dispatch(threatDefenceEmailTemplateUpdated);
  mapping[events.EmailTemplateContentEditSuccess] = dispatch(threatDefenceEmailTemplateUpdated);
  mapping[events.EmailTemplateUpdateCommandCompleted] = dispatch(updateThreatDefenceEmailTemplateCommandSuccess);

  mapping[events.EmailTemplatePublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftThreatDefenceEmailTemplateSuccess,
    (data) => `${data.name} was published.`,
  );

  mapping[events.EmailTemplateDiscardSuccess] = dispatch(fetchDiscardThreatDefenceEmailTemplateSuccess);

  mapping[events.EmailTemplateLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedTdEmailTemplateSuccess,
    (message) => ({ entityId: message.id }),
  );
};
