import React, { useRef } from "react";

import { type IObservable } from "../../../../interfaces/IObservable";
import { type DropdownItem } from "../../../../components/buttons/multiStateButton/MultiStateButton";
import { AddTagsButton, type TagEntityType } from "./AddTagsButton/AddTagsButton";
import { TagsEnum } from "../../../../interfaces/TagsEnum";
import { type Tags } from "../../../../interfaces/Tags";

import Observable from "../../../../utils/Observable";
import AddTagsModal from "../../../../components/addTagsModal/AddTagsModal";

type AddTagsObservable = IObservable<(onConfirm: () => void, selectedIds: number[]) => void>;

export interface TagsManageProps {
  selectedIds: number[];
  isDisabled: boolean;
  entity: TagEntityType;
  fetchTags: () => void;
  onComplete: () => void;
  onConfirmAction: (selectedItems: number[], tags: string[], entityType: TagsEnum) => void;
  tags?: Tags;
}

const TagsManage = (props: TagsManageProps) => {
  const { isDisabled, selectedIds, entity, tags } = props;
  const onTriggerFlowAddLabelsObserver = useRef<AddTagsObservable>(new Observable());
  const onTriggerFlowAddSoftwareAppObserver = useRef<AddTagsObservable>(new Observable());

  const handleAddTagClick = (observer: AddTagsObservable) => () => {
    props.fetchTags();
    observer.notify(() => {
      props.onComplete();
    }, selectedIds);
  };

  const tagsOptions: DropdownItem[] = [
    {
      text: "Software Applications",
      icon: "fal fa-tags",
      onClick: handleAddTagClick(onTriggerFlowAddSoftwareAppObserver.current),
    },
    {
      text: "Labels",
      icon: "fal fa-tags",
      onClick: handleAddTagClick(onTriggerFlowAddLabelsObserver.current),
    },
  ];

  return (
    <>
      <AddTagsButton isDisabled={isDisabled} tagsOptions={tagsOptions} entity={entity} />

      <AddTagsModal
        onTriggerModalObserver={onTriggerFlowAddLabelsObserver.current}
        tagType={TagsEnum.Label}
        propertyName="labels"
        onConfirmAction={props.onConfirmAction}
        options={tags?.labels || []}
      />

      <AddTagsModal
        onTriggerModalObserver={onTriggerFlowAddSoftwareAppObserver.current}
        tagType={TagsEnum.SoftwareApplication}
        propertyName="softwareApplications"
        onConfirmAction={props.onConfirmAction}
        options={tags?.softwareApplications || []}
      />
    </>
  );
};

export default TagsManage;
