import { type FC, useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { NoFilteredData } from "../../../../../components/charts";
import { ListViewBase } from "../../../../../components/listView";
import { type ColumnOption } from "../../../../../interfaces/columnOptions";
import { PerformanceTabSectionList } from "../../../../Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { type PackLicenses } from "../../../types/packs";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { TextTruncate } from "../../../../../components";
import utils from "../../../../../utils/miscellaneousUtils";

import "./SpPackReportDrilldownList.scss";
import { Tooltip } from "components/common/tooltip";
import { type ConnectedProps, connect } from "react-redux";
import { type RootState } from "features/Application/globaltypes/redux";
import { useAddOnQuery } from "features/Reporting/Content/queries/useAddOnQuery";
import { AddOnIds } from "enums";

export interface Props extends PropsFromRedux {
  reduxFinished: boolean;
  packLicensesData: PackLicenses[];
  setSelectedCustomer: ({ customerId, customerName }: { customerId: number; customerName: string }) => void;
}

export const SpPackReportDrilldownList: FC<Props> = ({
  reduxFinished,
  packLicensesData,
  setSelectedCustomer,
  accountId,
}) => {
  const [slicedEntities, setSlicedEntities] = useState<PackLicenses[]>([]);

  const { data: addons } = useAddOnQuery(accountId);
  const hasCreateAccount = addons?.find((addon) => addon.id === AddOnIds.CreateAccounts)?.isEnabled;

  useEffect(() => {
    if (reduxFinished) {
      setSlicedEntities([...packLicensesData].slice(0, 10));
    }
  }, [packLicensesData, reduxFinished]);

  const columns: ColumnOption<PackLicenses>[] = [
    {
      name: "Customer Name",
      width: 7,
      render(item) {
        return hasCreateAccount ? (
          <Tooltip
            target={
              <TextTruncate className="customerName">
                <a
                  href="./"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedCustomer({ customerId: item.CustomerId, customerName: item.CustomerName });
                  }}
                >
                  {item.CustomerName}
                </a>
              </TextTruncate>
            }
            content={`${item.CustomerName}`}
          />
        ) : (
          <Tooltip target={<TextTruncate>{item.CustomerName}</TextTruncate>} content={`${item.CustomerName}`} />
        );
      },
    },
    {
      name: "Account Type",
      width: 3,
      render(item) {
        return <Tooltip target={<TextTruncate>{item.AccountType}</TextTruncate>} content={`${item.AccountType}`} />;
      },
    },
    {
      name: "Status",
      width: 3,
      render(item) {
        return item.Status;
      },
    },
    {
      name: "Licenses",
      width: 3,
      render(item) {
        return utils.unlimitedNumber(item.TotalLicenses).toLocaleString();
      },
    },
    {
      name: "Enabled",
      width: 3,
      render(item) {
        return dateTimeUtils.formatEmptyDate(item.DateEnabled);
      },
    },
    {
      name: "Expires",
      width: 3,
      render(item) {
        return dateTimeUtils.formatEmptyDate(item.Expiration);
      },
    },
  ];

  const buildTableBody = (item: PackLicenses) => {
    return columns.map((column, index) => (
      <Table.Cell className="table-body-cell" width={column.width} key={`${column.name}_${index}`}>
        {column.render(item)}
      </Table.Cell>
    ));
  };

  const getData = (skip: number, top: number) => {
    setSlicedEntities([...packLicensesData.slice(skip, skip + top)]);
  };
  // NOTE: The company name should drilldown further if BSI
  return (
    <PerformanceTabSectionList
      title="Accounts"
      count={packLicensesData.length}
      renderList={(page) => {
        const parsedData = slicedEntities.map((entity) => ({
          id: entity.CustomerId,
          ...entity,
        }));
        return packLicensesData.length ? (
          <ListViewBase
            columnOptions={columns}
            loadPage={getData}
            items={parsedData}
            itemsAmount={packLicensesData.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
          />
        ) : (
          <NoFilteredData />
        );
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SpPackReportDrilldownList);
export default ConnectedComponent;
