import { type Dispatch } from "@reduxjs/toolkit";
import flowsDataService from "../../services/flowsDataService";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/flowUsersSlice";
import { type GetContentUsersParams } from "../../../../People/types";
import userListUtils from "../../../../../utils/userListUtils";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { flowUserFilterParams } from "../../../../../components/filterForms/FlowUsersFilterForm/flowUserFilters";
import { formatFilters } from "../../../../../utils/filterMapUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchFlowUsers =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const filters = formatFilters(filter, flowUserFilterParams);
      const searchTerm = escapeTerm(term);
      const request = { skip, top, sortBy: sortingColumn, sortOrder: sortingDirection, term: searchTerm, ...filters };
      const { data, headers } = await flowsDataService.getFlowUsersV2(entityId, request);
      dispatch(
        fetchSuccess({ items: userListUtils.mapToContentUsers(data), totalCount: Number(headers[countHeaderName]) }),
      );
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
