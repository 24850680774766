import React, { useEffect, useRef } from "react";
import isParalellWith from "../helpers/isParalellWith";
import { type ElAndPos } from "../types";
import { AlignmentHelpers } from "../AlignmentHelpers/AlignmentHelpers";
import getSortedByAxis from "../helpers/getSortedByAxis";
import { type XYCoord } from "react-dnd";
import { type IViewPort, useNodesFilter, ViewPortType } from "./useNodesFilter";
import { DRAG_SHIFT, ELEMENT_HALF_HEIGHT, ELEMENT_HALF_WIDTH, ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";
import { ExtrasTypes } from "../../Extras/types";
import { useReactFlow } from "reactflow";
import { type Project } from "@reactflow/core";

import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";

type UseDragLayerItem = any; // @see useDragLayer typings

const isOverCanvas = (canvasCoords: XYCoord, viewPort: IViewPort): true | null => {
  return canvasCoords.x <= viewPort.x2 && canvasCoords.y > viewPort.y1 ? true : null;
};

const getCoords = (clientOffset: XYCoord | null, reactFlowBounds: Omit<DOMRect, "toJSON">, scale: number): XYCoord => {
  return {
    x: Math.round(clientOffset?.x || 0) - reactFlowBounds.left - DRAG_SHIFT * scale,
    y: Math.round(clientOffset?.y || 0) - reactFlowBounds.top - DRAG_SHIFT * scale,
  };
};

const getCanvasCoords = (coords: XYCoord, project: Project): XYCoord => {
  return project(coords) || { x: 0, y: 0 };
};
const nodesFilterOptions = { viewPortType: ViewPortType.SmartGuidesOnDragLayer };

export const useGuidesOnOuterLayer = ({
  draggedItem,
  clientOffset,
}: {
  draggedItem: UseDragLayerItem;
  clientOffset: XYCoord | null;
}) => {
  const { reactFlowBounds } = useReactFlowCanvasState();
  const { project, getNodes } = useReactFlow();
  const { nodesFilter, viewPort, scale } = useNodesFilter(nodesFilterOptions);
  const coords = getCoords(clientOffset, reactFlowBounds, scale);
  const canvasCoords = getCanvasCoords(coords, project);
  const overCanvas = isOverCanvas(canvasCoords, viewPort);

  // store nodes data in a ref for reusage between renders
  const nodeCoordsRef = useRef<{
    sortedByX: ElAndPos[];
    sortedByY: ElAndPos[];
  }>({ sortedByX: [], sortedByY: [] });
  const nodes = getNodes().filter(nodesFilter) || [];

  useEffect(() => {
    if (draggedItem === null) {
      return;
    }

    nodeCoordsRef.current = getSortedByAxis(nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggedItem !== null]);

  // should not be aligned when there is no wrapper
  // or when we are dragging a section header
  if (reactFlowBounds.width === 0 || draggedItem?.elementType === ExtrasTypes.SectionHeader) {
    return {
      leftAligned: null,
      middleVerticalAligned: null,
      rightAligned: null,
      topAligned: null,
      middleHorizontalAligned: null,
      bottomAligned: null,
      helpers: null,
    };
  }

  const leftAligned =
    overCanvas && isParalellWith(nodeCoordsRef.current.sortedByX, "x", canvasCoords.x, canvasCoords.y);
  const middleVerticalAligned =
    overCanvas &&
    isParalellWith(
      nodeCoordsRef.current.sortedByX,
      "x",
      Math.round(canvasCoords.x + ELEMENT_HALF_WIDTH),
      canvasCoords.y,
    );
  const rightAligned =
    overCanvas &&
    isParalellWith(nodeCoordsRef.current.sortedByX, "x", Math.round(canvasCoords.x + ELEMENT_WIDTH), canvasCoords.y);

  const topAligned = overCanvas && isParalellWith(nodeCoordsRef.current.sortedByY, "y", canvasCoords.y, canvasCoords.x);
  const middleHorizontalAligned =
    overCanvas &&
    isParalellWith(
      nodeCoordsRef.current.sortedByY,
      "y",
      Math.round(canvasCoords.y + ELEMENT_HALF_HEIGHT),
      canvasCoords.x,
    );
  const bottomAligned =
    overCanvas &&
    isParalellWith(nodeCoordsRef.current.sortedByY, "y", Math.round(canvasCoords.y + ELEMENT_HEIGHT), canvasCoords.x);

  return {
    leftAligned,
    middleVerticalAligned,
    rightAligned,
    topAligned,
    middleHorizontalAligned,
    bottomAligned,
    helpers: (
      <AlignmentHelpers
        rightAligned={rightAligned}
        middleHorizontalAligned={middleHorizontalAligned}
        leftAligned={leftAligned}
        topAligned={topAligned}
        middleVerticalAligned={middleVerticalAligned}
        bottomAligned={bottomAligned}
      />
    ),
  };
};
