import { createFiltersSlice } from "../../../../Application/slices/createFiltersSlice";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { type ContentFilters, type ContentFiltersState } from "../../../types";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export const initialState: ContentFiltersState = {
  filterOptions: {} as GenericFiltersMap<ContentFilters>,
  appliedFilter: {} as GenericFiltersMap<ContentFilters>,
  isLoading: false,
  search: "",
};

const contentAssignmentsFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.AddContent,
    name: "contentAssignmentsFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  contentAssignmentsFiltersSlice.actions;

export default contentAssignmentsFiltersSlice.reducer;
