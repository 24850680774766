import { noop } from "lodash";
import { useEffect, useMemo } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type IObservable } from "../../../../../interfaces/IObservable";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import Observable from "../../../../../utils/Observable";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import CreateReminderPopup from "./CreateReminder/CreateReminderPopup";
import Reminders from "./Reminders";
import { RemindersNoResults } from "./RemindersNoResults";

export interface RemindersTabBaseProps {
  eventId?: number;
  hasAnyPermission?: boolean;
  disabled?: boolean;
  onDiscardedObserver?: IObservable<() => void>;
  onTriggerRemoveRemindersObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
}

export type RemindersTabProps = RemindersTabBaseProps & IWizardStep & PropsFromRedux;

export const RemindersTab = (props: RemindersTabProps) => {
  const {
    id,
    disabled,
    hasAnyPermission = true,
    onDiscardedObserver,
    onTriggerRemoveRemindersObserver,
    acceptHandlers,
  } = props;

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTriggerAddRemindersObserver = useMemo(() => new Observable<(onConfirm: () => void) => void>(), []);
  const triggerModal = () => onTriggerAddRemindersObserver.notify(noop);

  const noResultContent = (
    <RemindersNoResults action={triggerModal} hasAnyPermission={hasAnyPermission} disabled={disabled} />
  );

  return (
    <>
      <Reminders
        id={id}
        disabled={disabled}
        onAddButtonClick={triggerModal}
        noResultContent={noResultContent}
        onDiscardedObserver={onDiscardedObserver}
        onTriggerRemoveRemindersObserver={onTriggerRemoveRemindersObserver}
      />
      <CreateReminderPopup onTriggerModalObserver={onTriggerAddRemindersObserver} />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: RemindersTabBaseProps) => ({
  id: ownProps.eventId ?? state.library.events.eventEntityState.entityId,
});

/* istanbul ignore next */
const mapDispatchToProps = (_: AppDispatch) => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(RemindersTab);
export default ConnectedComponent;
