import { combineReducers } from "redux";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import withGroupsAssignment from "../../../../../Application/reducers/hoc/withGroupsAssignment";
import withUsersAssignment from "../../../../../Application/reducers/hoc/withUsersAssignment";
import { emailDetailsReducer } from "./emailDetailsReducer";
import emailEntityStateReducer from "./emailEntityStateReducer";
import { emailsOverviewReducer } from "./emailsOverviewReducer";

import { type RootState } from "features/Application/globaltypes/redux";
import { emailCancelSendReducer } from "../slices/emailCancelSendSlice";
import { emailCreateSendReducer } from "../slices/emailCreateSendSlice";
import { emailFiltersReducer } from "../slices/emailFilterSlice";
import { emailPerformanceReducer } from "../slices/emailPerformanceSlice";
import { emailSearchReducer } from "../slices/emailSearchSlice";
import { emailSendDetailsReducer } from "../slices/emailSendDetailsSlice";
import emailAssignedUsersReducer from "./emailAssignedUsersReducer";
import { emailPublishInfoReducer } from "./emailPublishInfoReducer";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Emails;

const emailsReducer = combineReducers({
  emailsOverviewReducer,
  emailDetailsReducer,
  emailEntityStateReducer,
  assignedUsers: withUsersAssignment(namespace, entityPrefix),
  assignedGroups: withGroupsAssignment(namespace, entityPrefix),
  emailAssignedUsersReducer,
  emailPublishInfoReducer,
  filters: emailFiltersReducer,
  search: emailSearchReducer,
  items: combineReducers({
    sendDetails: emailSendDetailsReducer,
  }),
  emailSendSession: combineReducers({
    cancel: emailCancelSendReducer,
    create: emailCreateSendReducer,
  }),
  performance: emailPerformanceReducer,
});

export type EmailsState = ReturnType<typeof emailsReducer>;

export const emailPaginationSelector = (state: RootState) => state.library.emails.filters.pagination;
export const emailSortingColumnNameSelector = (state: RootState) => state.library.emails.filters.sortingColumnName;
export const emailSortingDirectionSelector = (state: RootState) => state.library.emails.filters.sortingDirection;

export default emailsReducer;
