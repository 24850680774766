import { type QueryFunctionContext, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { type AddOn } from "features/Accounts/types";

const getAddOns = async ({
  queryKey,
}: QueryFunctionContext<[_: string, accountId?: number], unknown>): Promise<AddOn[]> => {
  if (queryKey[1]) {
    return (await axios.get<AddOn[]>(`api/accounts/${queryKey[1]}/addons`)).data;
  }
  return [];
};

export const useAddOnQuery = (accountId: number) => {
  return useQuery({
    queryKey: ["addons", accountId],
    queryFn: getAddOns,
  });
};
