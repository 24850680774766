import React from "react";
import { type SemanticWIDTHS, Table } from "semantic-ui-react";

import { Tooltip } from "../../../../components/common/tooltip";
import { MinusButton } from "../../../../components/buttons/iconButtons";
import GroupInfoCell from "../../../../components/groupInfoCell/GroupInfoCell";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { type ColumnOptions, type Group } from "../../../../interfaces";

import styles from "./userGroupListRow.module.scss";

export const columnOptions: ColumnOptions[] = [
  {
    name: "name",
    width: 6 as SemanticWIDTHS,
    isSortable: true,
  },
  {
    name: "members",
    width: 3 as SemanticWIDTHS,
    isSortable: false,
  },
  {
    name: "Added",
    width: 4 as SemanticWIDTHS,
    isSortable: true,
  },
  {
    name: "",
    width: 2 as SemanticWIDTHS,
    isSortable: false,
  },
];

export interface UserGroupListRowProps {
  group: Group;
  isSelected: boolean;
  isDisabled?: boolean;
  onRemoveUserFromGroup: (groupId: number) => void;
}

export default class UserGroupListRow extends React.Component<UserGroupListRowProps> {
  removeGroup = () => {
    const { onRemoveUserFromGroup, group } = this.props;
    return onRemoveUserFromGroup(group.id);
  };

  render() {
    const { group, isDisabled, isSelected } = this.props;
    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <GroupInfoCell group={group} deepLink />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>{group.membersCount}</Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(group.dateCreated)}</Table.Cell>
        <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
          {
            <Tooltip
              position="top center"
              hideOnScroll
              showAlways
              target={<MinusButton onClick={this.removeGroup} isDisabled={isDisabled || isSelected} />}
              content="Remove"
            />
          }
        </Table.Cell>
      </>
    );
  }
}
