import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { FeatureFlags } from "../../../../featureFlags";
import { GoogleConnector } from "../connectors/GoogleConnector";
import type IConnector from "../connectors/IConnector";
import { MsGraphConnector } from "../connectors/MsGraphConnector";
import { SfConnector } from "../connectors/SfConnector";
import { VivaConnector } from "../connectors/VivaConnector";
import { WebexConnector } from "../connectors/WebexConnector";
import { IntegrationTypes } from "../types";

const typeToFlagMap: { [key in IntegrationTypes]?: string } = {
  [IntegrationTypes.Webex]: FeatureFlags.WebexIntegration,
};

const factories: { [key in IntegrationTypes]: (isConnected: boolean) => IConnector } = {
  [IntegrationTypes.Google]: (isConnected: boolean) => new GoogleConnector(isConnected),
  [IntegrationTypes.MsGraph]: (isConnected: boolean) => new MsGraphConnector(isConnected),
  [IntegrationTypes.Viva]: (isConnected: boolean) => new VivaConnector(isConnected),
  [IntegrationTypes.Webex]: (isConnected: boolean) => new WebexConnector(isConnected),
  [IntegrationTypes.SuccessFactors]: (isConnected: boolean) => new SfConnector(isConnected),
};

export const useConnectors = (isConnected: (type: IntegrationTypes) => boolean) => {
  const flags = useFlags();

  const connectors = useMemo(() => {
    const res: { [key: string]: IConnector } = {};
    for (const key of Object.values(IntegrationTypes)) {
      const flag = typeToFlagMap[key];
      if (!flag || flags[flag]) {
        res[key] = factories[key](isConnected(key));
      }
    }

    return res;
  }, [isConnected, flags]);

  return [connectors];
};
