import { type Tag } from "../../../../../interfaces/Tag";

export enum LandingPagesFiltersEnum {
  SoftwareApplications = "softwareApplicationIds",
  Labels = "labelIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export interface LandingPagesFilters {
  [key: string]: Tag[];
}
