import { type AxiosError } from "axios";

export const getAxiosError = (error: unknown) => {
  const axiosError = error as AxiosError;
  if (axiosError.request) {
    return axiosError.request.data;
  } else if (axiosError.response) {
    return axiosError.response.data;
  } else {
    return axiosError.message;
  }
};
