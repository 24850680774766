import {
  type IconDefinition,
  faCalendarAlt,
  faFilePdf,
  faList,
  faListOl,
  faVideo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { type FC } from "react";
import { type ColumnOption } from "../../../../../interfaces/columnOptions";

import { type PerformanceFilter, completions, inProgress } from "features/Library/Common/utils/performanceUtils";
import styles from "./trendingTable.module.scss";
import { Tooltip } from "components/common/tooltip";
import { RouteNames } from "enums";
import { TextTruncate } from "components";
import { thisMonthColumn } from "./shared";
import { MoboLink } from "components/MoboLink/MoboLink";

export type TrendingAsset = {
  id: number;
  ContentId: number;
  ContentIdType: "video_id" | "assessment_id" | "survey_id" | "pdf_id" | "external_event_id";
  Title: string;
  Metric1: number;
  Metric1Type: string;
  Metric2: number;
  Metric2Type: string;
  Metric3: number;
  Metric3Type: string;
  AssetTrending: number;
  AssetTrendingLastMonth: number;
  AssetTrendingChange: "Up" | "Down" | "No Change";
  Trending: number;
  TrendingLastMonth: number;
  TrendingChange: "Up" | "Down" | "No Change";
};

export interface TrendingAssetsProps {
  trendingAssets: TrendingAsset[];
  filter: PerformanceFilter;
}

const columnOptions: ColumnOption<TrendingAsset>[] = [
  {
    name: "THIS MONTH",
    width: 2,
    isSortable: false,
    render: thisMonthColumn,
  },
  {
    isSortable: false,
    name: "ASSET",
    width: 4,
    render: (entity) => {
      let href: string;
      let icon: IconDefinition;

      switch (entity.ContentIdType) {
        case "assessment_id":
          href = `/content/assets/assessments/${entity.ContentId}`;
          icon = faListOl;
          break;
        case "video_id":
          href = `/${RouteNames.contentVideos}/${entity.ContentId}`;
          icon = faVideo;
          break;
        case "survey_id":
          href = `/${RouteNames.contentSurveys}/${entity.ContentId}`;
          icon = faList;
          break;
        case "external_event_id":
          href = `/${RouteNames.contentEvents}/${entity.ContentId}`;
          icon = faCalendarAlt;
          break;
        default:
          // pdf
          href = `/${RouteNames.contentPdfs}/${entity.ContentId}`;
          icon = faFilePdf;
      }

      return (
        <div className={styles.title}>
          <FontAwesomeIcon icon={icon} />
          <Tooltip
            target={
              <TextTruncate>
                <MoboLink to={href}>{entity.Title}</MoboLink>
              </TextTruncate>
            }
            content={entity.Title}
          />
        </div>
      );
    },
  },
  {
    name: inProgress.toUpperCase(),
    width: 3,
    isSortable: false,
    render: (entity) => {
      return <div>{entity.Metric1?.toLocaleString() ?? "-"}</div>;
    },
  },
  {
    name: completions.toUpperCase(),
    width: 3,
    isSortable: false,
    render: (entity) => {
      return <div>{entity.Metric2.toLocaleString()}</div>;
    },
  },
  {
    name: "LAST MONTH",
    width: 2,
    render: (entity) => {
      return <div className={styles.previouslyTrending}>{entity.TrendingLastMonth.toLocaleString()}</div>;
    },
    isSortable: false,
  },
];

export const TrendingAssets: FC<TrendingAssetsProps> = (props) => {
  const { trendingAssets, filter } = props;

  return (
    <GenericPerformanceList
      hideControls
      rows={trendingAssets}
      columns={columnOptions}
      title="Trending Assets"
      filter={filter}
    />
  );
};
