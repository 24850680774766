import { type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "features/Application/slices/createFetchingItemsSlice";
import { type RoleUser } from "interfaces";

export interface EditAccountUsersState extends FetchingItemsState<RoleUser> {
  selected: number[];
}

export const initialState: EditAccountUsersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  selected: [],
};

const editAccountUsersSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Users",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  reducers: {
    /* istanbul ignore next */
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, setSelected } = editAccountUsersSlice.actions;

export default editAccountUsersSlice.reducer;
