import { combineReducers } from "redux";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { withFetchingEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Group_Assignments;

const groupsAssignmentReducer = combineReducers({
  groupsToBeAssigned: withFetchingEntityListItems(namespace, entityPrefix),
  filter: withFilteringItems(namespace, entityPrefix),
});

export default groupsAssignmentReducer;
