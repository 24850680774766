import { type VideoGroup } from "../../types";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface VideoGroupsState extends SelectedItemsState<VideoGroup> {}

const initialState: VideoGroupsState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const videoGroupsSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Videos, name: "groups" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  videoGroupsSlice.actions;

export default videoGroupsSlice.reducer;
export type videoGroupsSliceStateType = ReturnType<typeof videoGroupsSlice.reducer>;
