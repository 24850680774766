import { Table } from "semantic-ui-react";
import UserInfoCell from "../../userInfoCell/UserInfoCell";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import React, { type FC } from "react";
import { type User } from "../../../interfaces";
import { Tooltip } from "../../common/tooltip";
import { getUserColumnOptions } from "./getUsersColumnOptions";

interface IUsersTableBody {
  user: User;
}

export const UsersTableBody: FC<IUsersTableBody> = ({ user }): React.ReactElement => {
  const columnOptions = getUserColumnOptions();
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <UserInfoCell user={user} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip target={<TextTruncate>{user.title}</TextTruncate>} content={user.title} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
    </>
  );
};
