import { type Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/videoUsersSlice";
import assetsDataService from "../../services/videoDataService";
import { type GetContentUsersParams } from "../../../../People/types";
import userListUtils from "../../../../../utils/userListUtils";
import { escapeTerm } from "../../../../../utils/searchUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchVideoUsers =
  ({ entityId, skip, top, sortingColumn, sortingDirection, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const searchTerm = escapeTerm(term);
      const request = { skip, top, sortBy: sortingColumn, sortOrder: sortingDirection, term: searchTerm };
      const { data, headers } = await assetsDataService.getVideoUsersV2(entityId, request);

      dispatch(
        fetchSuccess({ items: userListUtils.mapToContentUsers(data), totalCount: Number(headers[countHeaderName]) }),
      );
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
