import { useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import { noop } from "lodash";

import SettingsForm from "./SettingsForm/SettingsForm";
import Restricted from "../../../../Application/Restricted";

import { bindAction } from "../../../../../interfaces/redux";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import { fetchPdfTags } from "../../state/thunks/pdfBaseThunk";
import { updatePdfTags } from "../../state/actions/pdfEntityStateActions";
import { RolePermissions } from "../../../../../enums";
import { pdfDetailsSelector, pdfSettingsSelector, pdfsStateSelector, pdfsTagsSelector } from "../../selectors";
import { saveSettings } from "../../state/slices/pdfDetailsSlice";
import { pdfSettings } from "../../../../../utils/validationSchemas/pdfsValidationSchema";

export type SettingsTabProps = IWizardStep & PropsFromRedux;

export const SettingsTab = (props: SettingsTabProps) => {
  const { settings, normalizedTags, isLoading, acceptHandlers, entityId, disabled } = props;

  useEffect(() => {
    acceptHandlers?.({
      onPrevious: saveTags,
      onNext: saveTags,
    });
    props.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagsFormik = useFormik({
    initialValues: settings,
    validationSchema: pdfSettings,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const valuesRef = useRef(tagsFormik.values);
  useEffect(() => {
    valuesRef.current = tagsFormik.values;
  }, [tagsFormik.values]);

  const saveTags = () => {
    props.saveSettings(valuesRef.current);
  };

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => (
        <SettingsForm
          entityId={entityId}
          tagsFormik={tagsFormik}
          normalizedTags={normalizedTags}
          updateTags={props.updateTags}
          settings={settings}
          isDisabled={disabled || !hasPermission}
          isLoading={isLoading}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = pdfsStateSelector(state).base;

  return {
    entityId: base.pdfEntityStateReducer.entityId,
    settings: pdfSettingsSelector(state),
    normalizedTags: pdfsTagsSelector(state),
    isLoading: pdfDetailsSelector(state).isLoading,
    disabled: pdfDetailsSelector(state).pdf.isPurchased,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAll: bindAction(fetchPdfTags, dispatch),
  saveSettings: bindAction(saveSettings, dispatch),
  updateTags: bindAction(updatePdfTags, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SettingsTab);
export default ConnectedComponent;
