import { packFilterFormPropTypes } from "../types/packFilterFormPropTypes";
import { FilterMultiSelect } from "../../multiSelect";
import { FilterDateRange } from "../../datePicker";
import "./packsFilterForm.scss";

const filterPropertyNames = {
  Publishers: "publisherIds",
  Categories: "categoryIds",
  Industries: "industryIds",
  dateCreated: "dateAdded",
  dateModified: "lastModified",
};

function PacksFilterForm(props) {
  const { filterOptions, filter, updateFilter } = props;
  return (
    <form className="packs-filter-form" autoComplete="off">
      <FilterDateRange
        label="Date Added"
        propertyName={filterPropertyNames.dateCreated}
        filter={filter}
        updateFilter={updateFilter}
        horizontalPositionSecondPicker="left"
      />
      <FilterDateRange
        label="Last Modified"
        propertyName={filterPropertyNames.dateModified}
        filter={filter}
        updateFilter={updateFilter}
        horizontalPositionSecondPicker="left"
      />
      {/* remove inline style afer adding permissions support */}
      <div className="filter-item" style={{ display: "none" }}>
        <div className="label">Publisher</div>
        <FilterMultiSelect
          propertyName={filterPropertyNames.Publishers}
          placeholder="All Publishers"
          items={filterOptions?.Publishers}
          filter={filter}
          updateFilter={updateFilter}
        />
      </div>
      {/* end */}
    </form>
  );
}

PacksFilterForm.propTypes = packFilterFormPropTypes;

export default PacksFilterForm;
