import { type FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faChartPieSimpleCircleDollar, faCircle } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

import styles from "./reportUnavailable.module.scss";

const cx = classNames.bind(styles);

const defaultTitle = "Report temporarily unavailable";
const defaultDescription =
  "Apologies, but this report is currently unavailable. Please check back later as we are working hard to make it available soon.";

export interface Props {
  title?: string;
  description?: string;
}

export const ReportUnavailable: FC<Props> = ({ title = defaultTitle, description = defaultDescription }) => {
  return (
    <div className={styles.root}>
      <div>
        <span
          className={cx("fa-3x", styles.iconContainer)}
          style={{ position: "relative", display: "grid", margin: "0 auto", width: "min-content" }}
        >
          <FontAwesomeIcon className={styles.gray} icon={faChartPieSimpleCircleDollar} size="2x" />
          {/* Below circle is a mask for the "dollar", to show the clock in its place */}
          <FontAwesomeIcon color="white" icon={faCircle} className={styles.bottomRightIcon} />
          <FontAwesomeIcon icon={faClock} className={cx(styles.bottomRightIcon, styles.gray)} />
        </span>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};
