import cn from "classnames";
import React from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { ValidatedForm } from "../../../../../../../components";
import TriggerType from "../../../../../../../enums/flowDesigner/triggerType";
import FlowDesignerCardThumbnail from "../../../../../../../components/cards/flowDesignerCardThumbnail/FlowDesignerCardThumbnail";
import { TriggerCommunicationContent } from "./TriggerCommunicationContent";
import { TriggerTypeDetail } from "./TriggerTypeDetail";
import { flowValidatorSelector } from "../../../../state/selectors";
import { EntityType } from "../../../types";
import "./trigger.scss";

export const Trigger = (props) => {
  const {
    onTriggerChange,
    onIsActionChange,
    isReadOnly,
    isErrorViewMode,
    errorViewErrors,
    destinationOption,
    source,
    trigger,
    triggerOptions,
    isAction,
    destination,
    bulletId,
  } = props;
  if (!destinationOption) return null;

  const delayErrors =
    isErrorViewMode && errorViewErrors.find((error) => error.inId === source && error.outId === destination);
  const isBranchingAnswer = trigger === TriggerType.Response;

  const validatedFieldProps = {
    values: props.values,
    errors: {
      ...props.errors,
      delay: delayErrors?.errorMessage,
    },
    touched: {
      ...props.touched,
      delay: isErrorViewMode && delayErrors,
    },
    dirty: props.dirty,
    isFormValid: props.isValid,
    handleBlur: props.handleBlur,
    setFieldValue: props.setFieldValue,
    setFieldTouched: props.setFieldTouched,
    resetForm: props.resetForm,
  };

  const renderDestination = () => (
    <div className="destination-content">
      <label>Destination</label>
      <div>
        <FlowDesignerCardThumbnail type={destinationOption.type} thumbnailUrl={destinationOption.thumbnailUrl} />
        <span data-testid="triggerDestinationId">{destinationOption.text}</span>
      </div>
    </div>
  );

  const renderDestinationTypeDetail = () => {
    const destinationType = destinationOption.type;
    const { Email, Message, FlowEnd } = EntityType;
    if (destinationType === FlowEnd && trigger !== TriggerType.NotConsumed) return null;

    return (
      <>
        <TriggerTypeDetail validatedFieldProps={validatedFieldProps} {...props} />
        {(destinationType === Email || destinationType === Message) && (
          <TriggerCommunicationContent
            isReadOnly={isReadOnly}
            onIsActionChange={onIsActionChange}
            isAction={isAction}
            source={source}
            destination={destination}
            bulletId={bulletId}
          />
        )}
      </>
    );
  };

  return (
    <div className={cn("flow-item-trigger", { "flow-item-trigger_branching": isBranchingAnswer })}>
      <ValidatedForm disablePreventTransitionPrompt formWidthComputer={16} {...props}>
        {renderDestination()}
        <ValidatedForm.DropdownField
          label="Trigger"
          value={trigger}
          options={triggerOptions}
          propertyName="trigger"
          onChangeCallback={(_, data) => {
            onTriggerChange(data.value, source, destination);
          }}
          disabled={isReadOnly || isBranchingAnswer}
          {...validatedFieldProps}
        />
        {renderDestinationTypeDetail()}
      </ValidatedForm>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isErrorViewMode: flowValidatorSelector(state).isErrorViewMode,
  errorViewErrors: flowValidatorSelector(state).errorViewErrors,
});

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues: (props) => {
      return props;
    },
    enableReinitialize: false,
  })(Trigger),
);
