import { ContentView, TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { type PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { type ColumnOption } from "interfaces/columnOptions";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { useQuery } from "@tanstack/react-query";
import { type OutreachTypes, type InteractionTypes, type UserAssetTypes, type SelectedView } from "./types";

import styles from "./userPerformanceList.module.scss";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { getOutreachQuery, getInteractionQuery } from "./Queries/queries";

export type UserPerformanceListProps = {
  filter: PerformanceFilter;
  userId: number;
  activeTab: number;
  setActiveTab: (num: number) => void;
  handleContentSelection: (type: UserAssetTypes, view: SelectedView) => void;
};

function isOutreach(entity: InteractionTypes | OutreachTypes): entity is OutreachTypes {
  return "OutreachType" in entity;
}

function dateOrDash(data: string | null) {
  return data !== null ? dateTimeUtils.formatDate(data) : "-";
}

export const UserPerformanceList: React.FC<UserPerformanceListProps> = ({
  filter,
  activeTab,
  userId,
  setActiveTab,
  handleContentSelection,
}) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.UserPerformance);

  const outreachQuery = useQuery({
    queryKey: ["userOutreach", filter, userId],
    queryFn: getOutreachQuery,
    enabled: reportEnabled,
  });

  const interactionQuery = useQuery({
    queryKey: ["userInteraction", filter, userId],
    queryFn: getInteractionQuery,
    enabled: reportEnabled,
  });

  const convertDisplayName = (type: UserAssetTypes) => {
    if (type === "Event Registered") return "Event Registrations";
    if (type === "Event Attended") return "Events Attended";
    return type;
  };

  const columnOptions: ColumnOption<InteractionTypes | OutreachTypes>[] = [
    {
      name: "TYPE",
      width: 5,
      isSortable: false,
      render: (entity: InteractionTypes | OutreachTypes) => {
        return (
          <Tooltip
            target={
              <button
                className={styles.linkButton}
                onClick={() => {
                  if (isOutreach(entity)) {
                    handleContentSelection(entity.OutreachType, "outreach");
                  } else {
                    handleContentSelection(entity.InteractionType, "interaction");
                  }
                }}
              >
                <TextTruncate>{isOutreach(entity) ? entity.OutreachType : convertDisplayName(entity.InteractionType)}</TextTruncate>
              </button>
            }
            content={isOutreach(entity) ? entity.OutreachType : convertDisplayName(entity.InteractionType)}
          />
        );
      },
    },
    {
      name: "COUNT",
      width: 5,
      isSortable: false,
      render: (entity: InteractionTypes | OutreachTypes) => {
        return <div>{isOutreach(entity) ? entity.OutreachCount.toLocaleString() : entity.InteractionCount.toLocaleString()}</div>;
      },
    },
    {
      name: "LAST ACTIVITY",
      width: 5,
      isSortable: false,
      render: (entity: InteractionTypes | OutreachTypes) => {
        return <div>{isOutreach(entity) ? dateOrDash(entity.LastOutreach) : dateOrDash(entity.LastInteraction)}</div>;
      },
    },
  ];
  const contentViewTabs = [
    {
      id: "outreach",
      label: "Platform Outreach",
      component: (
        <GenericPerformanceList
          rows={outreachQuery.data!}
          columns={columnOptions}
          filter={filter}
          hideControls
          key="type"
        />
      ),
    },
    {
      id: "interaction",
      label: "User Interactions",
      component: (
        <GenericPerformanceList
          rows={interactionQuery.data!}
          columns={columnOptions}
          filter={filter}
          key="type"
        />
      ),
    },
  ];

  return (
    <div className={styles.body}>
      <RequestStatusRenderer state={outreachQuery.status}>
        <RequestStatusRenderer state={interactionQuery.status}>
          <ContentView
            tabs={contentViewTabs}
            activeIndex={activeTab}
            onTabChange={(_, r: { activeIndex: number; }) => {
              setActiveTab(r.activeIndex);
            }}
          />
        </RequestStatusRenderer>
      </RequestStatusRenderer>
    </div>
  );
};
