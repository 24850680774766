import { type Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/surveyUsersSlice";
import surveysDataService from "../../services/surveysDataService";
import { type GetContentUsersParams, v2FilterMap } from "../../../../People/types";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { formatFiltersV2api, mapToV2Filters } from "../../../../../utils/queryUtils";

export const fetchSurveyUsers =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const filters = formatFiltersV2api(mapToV2Filters(filter, v2FilterMap));
      const searchTerm = escapeTerm(term);
      const request = { skip, top, sortBy: sortingColumn, sortOrder: sortingDirection, term: searchTerm, ...filters };
      const { items, count } = await surveysDataService.getSurveyUsersAsyncV2(entityId, request);
      dispatch(fetchSuccess({ items, totalCount: count }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
