import { useEffect, useRef, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { type FileLike } from "../../../../interfaces";
import FormFooter from "../../../../components/forms/FormFooter";
import IdentityProviderConfiguration from "../../CreateIdentityProvider/IdentityProviderConfiguration/IdentityProviderConfiguration";
import {
  type UpdateIdentityProviderConfiguration,
  type IdentityProviderConfigInfo,
  type IdentityProviderConfiguration as IdentityProviderConfigurationType,
} from "../../types";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";

export interface EditConfigurationProps {
  isLoading: boolean;
  providerConfiguration: IdentityProviderConfigurationType;
  onConfigurationUpdate(providerConfiguration: UpdateIdentityProviderConfiguration): void;
  isReadOnly: boolean;
}

const createStubFile = (name: string = "metadata.xml"): FileLike => ({
  name,
  type: "text/xml",
  size: 1,
  lastModified: Date.now(),
});

const EditConfiguration: React.FC<EditConfigurationProps> = ({
  isLoading,
  providerConfiguration,
  onConfigurationUpdate,
  isReadOnly,
}) => {
  const [metadataContentUrl, setMetadataContentUrl] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isMetadataContentUrlValid, setIsMetadataContentUrlValid] = useState(true);
  const [metadataUrl, setMetadataUrl] = useState("");
  const [configureInfo, setConfigureInfo] = useState<IdentityProviderConfigInfo>({} as IdentityProviderConfigInfo);
  const submitFormRef = useRef<any>();
  const resetFormRef = useRef<any>();

  useEffect(() => {
    const info: IdentityProviderConfigInfo = {
      name: providerConfiguration.name,
      type: providerConfiguration.type,
      metadataAutoRefresh: providerConfiguration.metadataAutoRefresh,
      metadataUrl: providerConfiguration.metadataUrl ?? "",
      identityProviderId: providerConfiguration.identityId,
      identityProvider: "",
    };

    if (isEmpty(providerConfiguration.metadataUrl)) {
      info.metadataFile = createStubFile(providerConfiguration.fileName);
    }

    setConfigureInfo(info);
  }, [providerConfiguration]);

  useEffect(() => {
    let isMetadataValid = true;
    if (configureInfo.metadataUrl !== metadataUrl) {
      isMetadataValid = !isEmpty(metadataContentUrl);
    }
    setIsMetadataContentUrlValid(isMetadataValid);
  }, [configureInfo.metadataUrl, metadataContentUrl, metadataUrl]);

  const bindResetForm = (resForm: any) => {
    resetFormRef.current = resForm;
  };
  const bindSubmitForm = (subForm: any) => {
    submitFormRef.current = subForm;
  };
  const onSubmit = (info: IdentityProviderConfigInfo) => {
    const config = {
      id: providerConfiguration.id,
      metadataContentUrl: metadataContentUrl || undefined,
      name: info.name,
      metadataAutoRefresh: info.metadataAutoRefresh,
      metadataUrl: info.metadataUrl || undefined,
      fileName: !info.metadataUrl ? info.metadataFile?.name : undefined,
    } as UpdateIdentityProviderConfiguration;
    onConfigurationUpdate(config);
    setMetadataContentUrl("");
  };
  const onCancel = () => {
    setMetadataContentUrl("");
    resetFormRef.current();
  };
  const onMetadataUrlChanged = (url?: string) => {
    setMetadataUrl(url ?? "");
  };

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="idp-edit-container">
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <div className="stretch scrollable-content edit-form idp-edit">
        <IdentityProviderConfiguration
          onIsValidChange={setIsValid}
          bindSubmitForm={bindSubmitForm}
          bindResetForm={bindResetForm}
          providerConfiguration={configureInfo}
          onSubmit={onSubmit}
          onMetadataContentUrlChange={setMetadataContentUrl}
          onDirtyChanged={setIsDirty}
          onMetadataUrlChanged={onMetadataUrlChanged}
          isReadOnly={isReadOnly}
          isEdit
        />
      </div>
      <FormFooter
        isSaveBtnDisabled={!(isDirty && isValid && isMetadataContentUrlValid)}
        isCancelBtnDisabled={!isDirty}
        onSave={submitFormRef.current}
        onCancel={onCancel}
      />
      <PreventTransitionPrompt
        when={isDirty}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving this role? All information entered will be lost."
      />
    </Dimmer.Dimmable>
  );
};

export default EditConfiguration;
