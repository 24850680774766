import { type PayloadAction } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, type FiltersState } from "../../../../Application/slices/createFiltersSlice";
import PriorityLevels from "../../../../../enums/priorityLevels";
import { CompletionStatusEnum } from "../../../../../enums/сompletionStatusEnum";
import { FlowUserFiltersEnum } from "../../Edit/People/models";

export interface FlowUserFiltersState extends FiltersState<FlowUserFiltersEnum> {
  search: string;
}

export const initialState: FlowUserFiltersState = {
  search: "",
  filterOptions: {
    [FlowUserFiltersEnum.Priority]: [
      { text: "None", value: PriorityLevels.none },
      { text: "Recommended", value: PriorityLevels.recommended },
      { text: "Important", value: PriorityLevels.important },
      { text: "Required", value: PriorityLevels.required },
    ],
    [FlowUserFiltersEnum.Expired]: [
      { text: "Yes", value: "true" },
      { text: "No", value: "false" },
      { text: "All", value: 0 },
    ],
    [FlowUserFiltersEnum.Status]: [
      { text: "Not Started", value: CompletionStatusEnum.NotStarted },
      { text: "In Progress", value: CompletionStatusEnum.InProgress },
      { text: "Completed", value: CompletionStatusEnum.Completed },
    ],
  },
  appliedFilter: {} as GenericFiltersMap<FlowUserFiltersEnum>,
  isLoading: false,
};

const flowUserFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "filtersOptions",
  },
  initialState: initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    resetSearch(state) {
      state.search = "";
    },
  },
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
} = flowUserFiltersSlice.actions;

export default flowUserFiltersSlice.reducer;
