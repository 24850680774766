import { createReducer } from "../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/messageDetailsActionTypes";
import { type MessageDetailsStateForSendSession } from "../../types/state";
import { type PayloadAction } from "../../../../../../interfaces";
import { type MessageDetails } from "../../types/models";

export const initialState: MessageDetailsStateForSendSession = {
  message: {
    body: "",
    callToAction: "",
    dateCreated: new Date().toISOString(),
    dateModified: new Date().toISOString(),
    destinationUrl: "",
    flows: [],
    hasBeenPublished: false,
    headline: "",
    id: 0,
    imageUrl: "",
    isDraft: false,
    isPurchased: false,
    ownerId: 0,
    packs: [],
    primaryId: 0,
    publisherId: 0,
    title: "",
    utmCampaign: "",
  },
  isLoaded: false,
  isLoading: false,
};

const messageDetailsHandlers = () => {
  const { clearMessage, getMessageBegin, getMessageSuccess, getMessageFailure } = actionTypes;

  const failureHandler = (
    state: MessageDetailsStateForSendSession,
    action: PayloadAction<Error>,
  ): MessageDetailsStateForSendSession => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    error: action.payload,
  });

  const clearMessageHandler = (): MessageDetailsStateForSendSession => ({
    ...initialState,
  });

  const getMessageBeginHandler = (state: MessageDetailsStateForSendSession): MessageDetailsStateForSendSession => ({
    ...state,
    isLoading: true,
  });

  const getMessageSuccessHandler = (
    state: MessageDetailsStateForSendSession,
    action: PayloadAction<MessageDetails>,
  ): MessageDetailsStateForSendSession => ({
    ...state,
    message: {
      ...action.payload,
    },
    isLoaded: true,
    isLoading: false,
  });

  return {
    [clearMessage]: clearMessageHandler,
    [getMessageBegin]: getMessageBeginHandler,
    [getMessageSuccess]: getMessageSuccessHandler,
    [getMessageFailure]: failureHandler,
  };
};

export const messageDetailsReducer = createReducer(initialState, [messageDetailsHandlers]);
