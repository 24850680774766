import { type FC } from "react";
import ActionConfirmationModal from "components/modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "utils/stringUtils";

export interface Props {
  groupsCount: number;
  packsCount: number;
  triggerOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const RemoveGroupLicenseConfirmationDialog: FC<Props> = (props: Props) => {
  const { groupsCount, packsCount, triggerOpen, onCancel, onContinue } = props;

  return (
    <ActionConfirmationModal
      open={triggerOpen}
      title={`Remove ${pluralize("Pack", packsCount)} From ${pluralize("Group", groupsCount)}?`}
      withWarningLabel={false}
      confirmLabel="Remove"
      onContinue={onContinue}
      onCancel={onCancel}
    >
      This action will prevent future members of {groupsCount > 1 ? "these groups" : "this group"} from accessing{" "}
      {packsCount > 1 ? "these Packs" : "this Pack"}, while existing members will retain access. Are you sure you want
      to proceed?
    </ActionConfirmationModal>
  );
};

export default RemoveGroupLicenseConfirmationDialog;
