import type ColumnOptions from "../../../../../../interfaces/columnOptions";

const columnOptions: Array<ColumnOptions> = [
  {
    name: "Account Type",
    width: 8,
    isSortable: false,
  },
  {
    name: "Customer Count",
    width: 4,
    isSortable: false,
  },
  {
    name: "",
    width: 2,
    isSortable: false,
  },
];

export default columnOptions;
