import React, { type Dispatch, type SetStateAction } from "react";
import type { IFlowEdge, IFlowNode } from "../../nodes/types";
import type { NodeChange } from "@reactflow/core";
import type { EdgeChange } from "reactflow";

interface ReactFlowStateContextActionsProps {
  setNodes: Dispatch<SetStateAction<IFlowNode[]>>;
  setEdges: Dispatch<SetStateAction<IFlowEdge[]>>;
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
}

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};
export const ReactFlowStateContextActions = React.createContext<ReactFlowStateContextActionsProps>({
  setNodes: defaultAction("setNodes"),
  setEdges: defaultAction("setEdges"),
  onNodesChange: defaultAction("onNodesChange"),
  onEdgesChange: defaultAction("onEdgesChange"),
});
