import React, { Component } from "react";
import { Dropdown, Icon, type DropdownItemProps } from "semantic-ui-react";
import cn from "classnames";

import { Tooltip } from "../../common/tooltip";

import "./multiStateButton.scss";

interface MultiStateButtonProps {
  id: string;
  simple: boolean;
  isLinkStyle?: boolean;
  trigger: string | React.ReactElement;
  className?: string;
  disabled?: boolean;
  items: DropdownItem[];
  upward?: boolean;
  disableDropdownIcon?: boolean;
  direction?: Direction;
}

type Direction = "left" | "right";

export interface DropdownItem {
  options?: DropdownItem[];
  icon?: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
  postfixIcon?: string;
  tooltipContent?: string;
  position?: string;
  disabled?: boolean;
  dropdown?: boolean;
  divider?: boolean;
}

export class MultiStateButton extends Component<MultiStateButtonProps> {
  renderIcon(menuItem: DropdownItem) {
    return menuItem.icon ? <Icon className={`fa-${menuItem.icon}`} /> : null;
  }

  renderPostfixIcon(menuItem: DropdownItem) {
    return menuItem.postfixIcon ? (
      <Tooltip
        target={<Icon className={`fa-${menuItem.postfixIcon}`} />}
        content={menuItem.tooltipContent}
        position={menuItem.position}
        showAlways
      />
    ) : null;
  }

  renderItem(item: DropdownItem, index: number) {
    let className = cn({ "with-divider": item.divider });

    return (
      <Dropdown.Item key={index} onClick={item.onClick} className={className} disabled={item.disabled}>
        {this.renderIcon(item)}
        <div className="menu-caption">{item.text}</div>
        {this.renderPostfixIcon(item)}
      </Dropdown.Item>
    );
  }

  renderDropdownItem(item: DropdownItem, index: number, direction?: Direction) {
    let className = cn({ "with-divider": item.divider });
    return (
      <Dropdown.Item key={index} className={className} disabled={item.disabled}>
        {this.renderIcon(item)}
        <div className="menu-caption">{item.text}</div>
        <Icon className="fa-chevron-right" />
        <Dropdown.Menu className={"sub-menu"} direction={direction}>
          {item.options?.map((option, idx) => this.renderItem(option, idx))}
        </Dropdown.Menu>
      </Dropdown.Item>
    );
  }

  render() {
    const {
      trigger,
      className = "",
      items,
      isLinkStyle,
      id,
      simple,
      disabled,
      upward,
      disableDropdownIcon,
      direction,
    } = this.props;
    const buttonStyle = isLinkStyle ? "link-style-button" : "multi-state-button blue right labeled icon";

    return (
      <Dropdown
        onClick={(e) => e.currentTarget.blur()}
        id={id}
        trigger={trigger}
        icon={
          disableDropdownIcon ? null : (
            <Icon
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.currentTarget.parentElement?.blur()}
              className={"fa-chevron-down"}
            />
          )
        }
        className={cn(buttonStyle, className)}
        button
        simple={simple}
        disabled={disabled}
        upward={upward}
      >
        <Dropdown.Menu>
          {items.map((item, index) =>
            !item.dropdown ? this.renderItem(item, index) : this.renderDropdownItem(item, index, direction),
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
