import { type Dispatch } from "redux";
import { getAxiosError } from "../../../../../utils/axiosError";
import { type PdfPerformanceRequest } from "../../types/requests";
import {
  reqEngagementHorizontalData,
  reqEngagementLineData,
  reqEngagementPeopleData,
  setEngagementHorizontalData,
  setEngagementHorizontalError,
  setEngagementLineData,
  setEngagementLineError,
  setEngagementPeopleData,
  setEngagementPeopleError,
} from "../slices/pdfPerformanceSlice";
import { type AppDispatch, type AppThunk } from "../../../../Application/globaltypes/redux";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { type BasePerformanceRequestFilterParams } from "../../../Common/models";
import { AlertTypes } from "../../../../../enums";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { downloadExcelExport } from "../../../Common/utils/performanceUtils";
import { setExporting } from "features/Reporting/state/export/exportSlice";

export const fetchEngagementLineData =
  (pdfId: number, filterParams: PdfPerformanceRequest = {}): AppThunk =>
    async (dispatch: AppDispatch) => {
      dispatch(reqEngagementLineData());
      try {
        const { data } = await DataService.pdfs.getPdfEngagementLineData(pdfId, filterParams);
        dispatch(setEngagementLineData(data));
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          const errorMessage = getAxiosError(err);
          dispatch(setEngagementLineError(errorMessage));
        }
      }
    };

export const fetchEngagementHorizontalData =
  (pdfId: number, filterParams: PdfPerformanceRequest = {}): AppThunk =>
    async (dispatch: AppDispatch) => {
      dispatch(reqEngagementHorizontalData());
      try {
        const { data } = await DataService.pdfs.getPdfEngagementHorizontalData(pdfId, filterParams);
        dispatch(setEngagementHorizontalData(data));
      } catch (err) {
        if (err.code !== "ERR_CANCELED") {
          const errorMessage = getAxiosError(err);
          dispatch(setEngagementHorizontalError(errorMessage));
        }
      }
    };

export const fetchEngagementPeopleData = (videoId: number, filterParams: PdfPerformanceRequest = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementPeopleData());
    try {
      const { data } = await DataService.pdfs.getPdfEngagementPeopleData(videoId, filterParams);
      dispatch(setEngagementPeopleData(data));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementPeopleError(errorMessage));
      }
    }
  };
};

export const doExport = (pdfId: number, filterParams: BasePerformanceRequestFilterParams, pdfTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.pdfs.getExport(pdfId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, pdfTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};
