import cn from "classnames";
import Title from "../title/Title";

import "./titleSubtitle.scss";

export interface TitleSubtitleProps {
  title?: string;
  subtitleTitle?: string;
  subtitle?: string;
  subtitleClamp?: number;
  className?: string;
  classNameTitle?: string;
  label?: React.ReactNode;
  url?: string;
  moboAccountId?: number;
  target?: string;
}

const TitleSubtitle: React.FC<TitleSubtitleProps> = ({
  title,
  subtitleTitle,
  subtitle,
  subtitleClamp,
  url,
  className,
  classNameTitle,
  label,
  moboAccountId,
  target,
}) => {
  let titleBlock;

  if (label) {
    titleBlock = (
      <div className="labeled">
        <Title moboAccountId={moboAccountId} target={target} title={title} className="title normal-color" url={url} />
        {label}
      </div>
    );
  } else {
    titleBlock = (
      <Title
        moboAccountId={moboAccountId}
        target={target}
        title={title}
        className={cn("title normal-color", classNameTitle)}
        url={url}
      />
    );
  }

  return (
    <div className={cn("title-subtitle-container", className)}>
      {titleBlock}
      <Title title={subtitleTitle} className="subtitle text-muted" />
      <Title title={subtitle} className="subtitle text-muted" clamp={subtitleClamp} />
    </div>
  );
};

export default TitleSubtitle;
