import { createAsyncThunk } from "@reduxjs/toolkit";

import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import usersDataService from "../../../../Application/services/dataServices/usersDataService";

export const fetchPermissionsToAccountPyaload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.EditAccount,
  name: "fetchPermissionsToAccount",
};

export const fetchPermissionsToAccount = createAsyncThunk(
  getPrefix(fetchPermissionsToAccountPyaload),
  async (accountId: number) => {
    const userPermissions = await usersDataService.getPermissions(accountId);
    const permissions = userPermissions.isSuccess ? userPermissions.data.formattedPermissions : [];

    return {
      items: permissions,
      totalCount: permissions.length,
    };
  },
);
