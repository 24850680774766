import { connect } from "react-redux";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import { flowInspectorTypeSelectSelector } from "../../selectors";
import { fetchFiltersToDrop } from "../../../../../Application/thunks/contentFiltersThunk";
import {
  type FlowContentFilters,
  resetAppliedFilter as resetAppliedFilterAction,
  setAppliedFilter as setAppliedFilterAction,
  setError,
  setFilterOptions,
  setIsLoading,
} from "../../state/slices/flowDesignerFiltersSlice";
import ContentFilterSidebar, {
  type ContentFilterSidebarProps,
} from "../../../../../../components/ContentFilterSidebar/ContentFilterSidebar";
import { reset as resetItemsAction } from "../../state/slices/flowDesignerItemsToDropSlice";
import { bindActionCreators } from "@reduxjs/toolkit";
import { flowDesignerSelector } from "../../../state/selectors";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const filters = flowDesignerSelector(state).filters;
  return {
    entityTypeSelect: flowInspectorTypeSelectSelector(state),
    filterOptions: filters.filterOptions,
    appliedFilter: filters.appliedFilter,
    isLoading: filters.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  applyFilter: bindActionCreators(setAppliedFilterAction, dispatch),
  getFilters: () => dispatch(fetchFiltersToDrop({ setIsLoading, setError, setFilterOptions }, true, true)),
  resetAppliedFilter: bindActionCreators(resetAppliedFilterAction, dispatch),
  resetItems: bindActionCreators(resetItemsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentFilterSidebar as (props: ContentFilterSidebarProps<FlowContentFilters>) => React.ReactElement);
