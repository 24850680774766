import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { getRootEntityActionTypeBuilder } from "../../../../../Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";
import * as filteringItemsActionTypes from "../../../../../Application/actions/filteringItems/filteringItemsActionTypes";

const actionTypeBuilder = getRootEntityActionTypeBuilder(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
);

export const emailAssignedUsersFetchingBegin = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_BEGIN);

export const emailAssignedUsersFetchingSuccess = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS);

export const emailAssignedUsersFetchingFailure = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_FAILURE);

export const emailAssignedUsersSetFilter = actionTypeBuilder(filteringItemsActionTypes.SET_ITEMS_FILTER);

export const emailAssignedUsersResetFilter = actionTypeBuilder(filteringItemsActionTypes.RESET_ITEMS_FILTER);

export const setEmailAssignedUsers = "SET_EMAIL_ASSIGNED_USERS";
export const setEmailAssignedUsersCount = "SET_EMAIL_ASSIGNED_USERS_COUNT";
export const setEmailAddedUsersCount = "SET_EMAIL_ADDED_USERS_COUNT";
export const setAreUsersLoading = "SET_ARE_USERS_LOADING";
export const reset = "RESET_EMAIL_ASSIGNED_USERS";
