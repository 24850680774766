import { type RootState } from "../../../../Application/globaltypes/redux";
import { createSelector } from "@reduxjs/toolkit";
import { isEmpty, isEqual } from "lodash";
import { type PacksContextItem } from "../../../../../interfaces";
import { EntityType, type SavedFlowItem, type SavedVideo } from "../../Designer/types";
import { ExtrasTypes } from "../../Designer/Extras/types";
import ObjectUtils from "../../../../../utils/objectUtils";

export const flowsStateSelector = (state: RootState) => state.library.flows;
export const flowsBaseSelector = (state: RootState) => state.library.flows.base;
export const flowDesignerSelector = (state: RootState) => flowsBaseSelector(state).designer;
export const flowEntitySelector = (state: RootState) => flowsBaseSelector(state).entity;
export const flowBaseSelector = (state: RootState) => flowsBaseSelector(state).flowBase;
export const flowInformationSelector = (state: RootState) => flowsBaseSelector(state).information;
export const flowValidatorSelector = (state: RootState) => flowsBaseSelector(state).validation;
export const flowTagsSelector = (state: RootState) => flowBaseSelector(state).tags;
export const flowsFilterSelector = (state: RootState) => flowsStateSelector(state).overview.filters;

export const flowPackContextItemsSelector = createSelector(
  [(state: RootState) => flowsStateSelector(state).base.designer.definition.items.flowItems, flowEntitySelector],
  (items, flowEntity): PacksContextItem[] =>
    items
      .filter((item) => !isEqual(item.entityType, ExtrasTypes.FlowEnd) && ObjectUtils.isNotEmpty(item.entity))
      .map((item: SavedFlowItem) => ({
        id: item.entityId,
        title: item.entity!.title,
        thumbnailUrl: (item.entity as SavedVideo)?.thumbnailUrl as string,
        durationInSeconds: (item.entity as SavedVideo)?.durationInSeconds,
        type: item.entityType,
        flowIds: [flowEntity.entityId],
      })),
);

export const canAutoStartSelector = createSelector(
  [(state: RootState) => flowsStateSelector(state).base.designer.definition],
  (definition): boolean => {
    const { headId, flowItems } = definition.items || {};
    const notConsumableEntityTypes: EntityType[] = [EntityType.Email, EntityType.Message];
    return Boolean(
      headId && flowItems?.find((item) => item.id === headId && notConsumableEntityTypes.includes(item.entityType)),
    );
  },
);

export const getMapTagsSelector = createSelector([flowTagsSelector, flowInformationSelector], (tags, flow) => {
  if (isEmpty(tags) || isEmpty(flow.info)) {
    return;
  }

  return {
    labels: tags.labels?.map((item) => ({
      text: item.title,
      value: item.title,
      disabled: !!flow.info.labels[item.title]?.isPurchased,
    })),
    softwareApplications: tags.softwareApplications?.map((item) => ({
      text: item.title,
      value: item.title,
      disabled: !!flow.info.softwareApplications[item.title]?.isPurchased,
    })),
  };
});

export const canAddPeopleSelector = createSelector(
  [(state: RootState) => flowInformationSelector(state).info],
  (flow) => {
    const hasBeenPublished = !flow.isDraft || flow.hasOrigin;
    const hasPacks = flow.packs.length !== 0;
    return hasBeenPublished && hasPacks;
  },
);

export const disableRemovePrioritySelector = createSelector(
  [
    (state: RootState) => state.library.flows.edit.users.selectedItems,
    (state: RootState) => state.library.flows.edit.groups.selectedItems,
  ],
  (users, groups) => {
    return users.some((x) => !x.allowRemovePriority) || groups.some((x) => !x.allowRemovePriority);
  },
);

export const flowPaginationSelector = (state: RootState) => flowsStateSelector(state).overview.filters.pagination;

export const flowSortingColumnNameSelector = (state: RootState) =>
  flowsStateSelector(state).overview.filters.sortingColumnName;

export const flowSortingDirectionSelector = (state: RootState) =>
  flowsStateSelector(state).overview.filters.sortingDirection;

export const flowsShouldSort = (state: RootState) => flowsStateSelector(state).overview.filters.shouldSort;
