import { debounce, isEmpty } from "lodash";
import React from "react";
import { Label, Loader } from "semantic-ui-react";

import enrollmentRulesUtils from "../../../../../utils/enrollmentRulesUtils";
import { formatFilterParamsMultOperators } from "../../../../../utils/filterUtils";
import { type Filters } from "../../../../../utils/queryUtils";
import userListUtils from "../../../../../utils/userListUtils";
import { type GetGroupEnrollmentUsersPreviewRequest, type UserInfo } from "../../../types";
import GroupUsersList from "../../AddGroup/GroupUsers/GroupUsersList/GroupUsersList";
import EnrollmentBuilder from "../EnrollmentBuilder/EnrollmentBuilder";

import { getDataSourceOptions } from "../EnrollmentBuilder/options";
import "./automaticallyDetect.scss";
import { convertEnrollmentRulesToFilter } from "./utils";
import { type EnrollmentRule } from "./models";

const debounceTimeout = 1000;
const defaultCosmosRuleEnginePreviewTop = 50;

export interface AutomaticallyDetectPropTypes {
  values: { enrollmentRules: EnrollmentRule[] };
  validatedFieldProps: any;
  users: UserInfo[];
  usersCount: number;
  isLoading: boolean;
  loadUsers: (skip: number, top: number, orderParams: any, filterParams: any) => void;
  fetchGroupEnrollmentUsers: (request: GetGroupEnrollmentUsersPreviewRequest) => void;
  loadGroupEnrollmentPreview?: (top: number, rules: EnrollmentRule[]) => void;
  gridFilter: Filters;
  setGridFilter: (filter: Filters) => void;
  resetEnrollmentUsers: () => void;
  goToEditUser: () => void;
  onIsValidChange: () => void;
  disabled: boolean;
  deepLink?: boolean;
  rulesEngineOnCosmos?: boolean;
  hidePeople?: boolean;
}

export class AutomaticallyDetect extends React.Component<AutomaticallyDetectPropTypes> {
  componentWillUnmount() {
    const { resetEnrollmentUsers } = this.props;
    resetEnrollmentUsers && resetEnrollmentUsers();
  }

  loadPage = (skip: number, top: number, sortingColumnName: any, sortingDirection: any, filter: any) => {
    const {
      validatedFieldProps,
      rulesEngineOnCosmos,
      values: { enrollmentRules },
    } = this.props;

    if (enrollmentRules.some((x: any) => !x.value)) {
      return;
    }

    if (rulesEngineOnCosmos) {
      if (!validatedFieldProps.isFormValid || !enrollmentRulesUtils.validateEnrollmentRules(enrollmentRules)) {
        return;
      }

      const { fetchGroupEnrollmentUsers } = this.props;
      fetchGroupEnrollmentUsers({
        top: defaultCosmosRuleEnginePreviewTop,
        rules: enrollmentRulesUtils.mapRulesToSeverFormat(enrollmentRules),
      });
      return;
    }

    let orderParams = null;
    let filterParams = null;

    if (sortingColumnName) {
      orderParams = userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    }

    if (enrollmentRules.length !== 0 && isEmpty(filter)) {
      filter = convertEnrollmentRulesToFilter(enrollmentRules);
    }

    if (filter) {
      filterParams = formatFilterParamsMultOperators(filter);
    }

    const { loadUsers } = this.props;
    loadUsers(skip, top, orderParams, filterParams);
  };

  debouncedChangeEnrollmentFilter = debounce(() => {
    const {
      values: { enrollmentRules },
      setGridFilter,
    } = this.props;
    setGridFilter(convertEnrollmentRulesToFilter(enrollmentRules));
  }, debounceTimeout);

  handleRuleChange = async (i: number) => {
    const {
      values: { enrollmentRules },
    } = this.props;
    if (enrollmentRules[i].value) {
      await this.debouncedChangeEnrollmentFilter();
    }
  };

  /* istanbul ignore next */
  renderPeopleLabelBadge = () => {
    const { isLoading, usersCount } = this.props;
    return (
      <Label circular color="blue" size="medium">
        {isLoading ? <Loader active size="mini" inline="centered" /> : usersCount}
      </Label>
    );
  };

  render() {
    const { validatedFieldProps, goToEditUser, disabled, onIsValidChange, rulesEngineOnCosmos, hidePeople } =
      this.props;

    return (
      <section className="automatically-detect">
        <EnrollmentBuilder
          values={this.props.values}
          disabled={disabled}
          onChange={this.debouncedChangeEnrollmentFilter}
          validatedFieldProps={validatedFieldProps}
          handleCriteriaChange={this.handleRuleChange}
          onIsValidChange={onIsValidChange}
          dataSourceOptions={getDataSourceOptions(true)}
        />
        {!hidePeople && (
          <>
            <div className="people-label">
              PEOPLE
              {this.renderPeopleLabelBadge()}
            </div>
            <div className="enrollment-rules-list-container">
              <GroupUsersList
                isGroupCreation={false}
                isAutoEnrollment
                filter={this.props.gridFilter}
                users={this.props.users}
                usersCount={this.props.usersCount}
                withSelection={false}
                goToEditUser={goToEditUser}
                isLoading={this.props.isLoading}
                loadPage={this.loadPage}
                renderHeaderSection={false}
                deepLink={this.props.deepLink}
                rulesEngineOnCosmos={rulesEngineOnCosmos}
              />
            </div>
          </>
        )}
      </section>
    );
  }
}

export default AutomaticallyDetect;
