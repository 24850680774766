import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Droppable } from "react-beautiful-dnd";

import "./droppableList.scss";

class DroppableList extends Component {
  render() {
    const { droppableId, direction, type, isDropDisabled, className } = this.props;
    const dropListProps = {
      title: this.props.title,
      headerContent: this.props.headerContent,
    };
    return (
      <div className={cn(className, "droppable-root")}>
        <Droppable
          droppableId={`droppable-item-${  droppableId}`}
          direction={direction}
          type={type}
          isDropDisabled={isDropDisabled}
        >
          {(provided) => (
            <div className={cn(className, "droppable-wrapper")} {...provided.droppableProps}>
              {this.props.render({
                ...dropListProps,
                dropProvided: provided,
              })}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

DroppableList.defaultProps = {
  type: "DEFAULT",
  direction: "vertical",
  isDropDisabled: false,
};

DroppableList.propTypes = {
  render: PropTypes.func.isRequired,
  droppableId: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  title: PropTypes.string,
  headerContent: PropTypes.node,
  isDropDisabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default DroppableList;
