import { PublishedStatusTypes, RolePermissions } from "../../../../enums";
import { type SurveyOverview } from "../types/models";

import dateTimeUtils from "../../../../utils/dateTimeUtils";

import { type ColumnOption } from "../../../../interfaces/columnOptions";

import { Title } from "../../../../components/listViewTemplates";
import { Tooltip } from "../../../../components/common/tooltip";
import { TextTruncate } from "../../../../components";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { EditLinkButton, DuplicateLinkButton, DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { ContentItemMenu } from "../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { DeletionRestrictedTooltip } from "../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

export enum Columns {
  Title = "Title", // NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Modified = "Modified",
  Added = "Added",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  publisher: "publisher",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
};

export interface GetColumnOptionsParams {
  readonly?: boolean;
  deleteContentWithDependenciesFeatureEnabled: boolean;
  visibility: boolean;

  handleEditClick: (id: number, isDraft?: boolean) => () => void;
  handleDuplicateClick: (ids: number[]) => () => void;
  handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => () => void;
  handleToggleVisibility: (id: number[], hidden: boolean) => () => void;
}

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<SurveyOverview>[] => {
  return [
    {
      name: Columns.Title,
      width: 4,
      isSortable: true,
      render: (item) => <Title url={item.id.toString()} title={item.title} clamp={2} className="normal-color" />,
    },
    {
      name: Columns.Publisher,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Status,
      width: 4,
      isSortable: true,
      render: (item) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />
      ),
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.dateModified),
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.dateCreated),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      isSortable: false,
      render: (item, isChecked) => {
        const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
        const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
        const entityType = "survey";
        return (
          <RestrictedByTooltip hasPermission={!params.readonly}>
            <ContentItemMenu
              circle
              outlinedEllipsis
              isChecked={isChecked}
              item={item}
              readonly={params.readonly}
              entityType={entityType}
              dependencies={dependencies}
            >
              {params.visibility && (
                <ToggleVisibilityButton
                  onClick={params.handleToggleVisibility([item.id], !item.visibility)}
                  hidden={!item.visibility}
                  requiredPermissions={[RolePermissions.AssetsCreate]}
                />
              )}
              {!item.isPurchased && (
                <EditLinkButton isDisabled={item.isPurchased} onClick={params.handleEditClick(item.id, item.isDraft)} />
              )}
              {!item.isPurchased && (
                <DuplicateLinkButton isDisabled={item.isPurchased} onClick={params.handleDuplicateClick([item.id])} />
              )}
              {!item.isPurchased && (
                <DeleteLinkButton
                  isDisabled={!params.deleteContentWithDependenciesFeatureEnabled && !item.canBeDeleted}
                  after={
                    !params.deleteContentWithDependenciesFeatureEnabled &&
                    !item.isPurchased &&
                    hasDeletionDependencies(dependencies) && (
                      <DeletionRestrictedTooltip dependencies={dependencies} entityType={entityType} />
                    )
                  }
                  onClick={params.handleDeleteClick([item.id], flowsCount, packsCount)}
                />
              )}
            </ContentItemMenu>
          </RestrictedByTooltip>
        );
      },
    },
  ];
};

export const hasDeletionDependencies = (dependencies: { [key: string]: number }) => {
  return !!Object.values(dependencies).reduce((sum, value) => sum + value);
};
