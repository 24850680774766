import { type FC } from "react";
import TriggeredConfirmationModal from "../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";
import { type PeopleType } from "../types";
import { type IObservable } from "../../../../interfaces/IObservable";
import { UsersGroupsContext } from "enums";

export interface RemovePeopleConfirmationModalProps {
  onTriggerRemovePeopleObserver: IObservable<
    (onRemoveConfirm: () => void, people: { [key in PeopleType]?: number[] }) => void
  >;
  peopleType: UsersGroupsContext;
}

const RemovePriorityConfirmationModal: FC<RemovePeopleConfirmationModalProps> = (
  props: RemovePeopleConfirmationModalProps,
) => {
  const { onTriggerRemovePeopleObserver, peopleType } = props;

  const content =
    peopleType === UsersGroupsContext.Users
      ? "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for selected user(s)."
      : "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for all users in selected group(s).";

  return (
    <TriggeredConfirmationModal
      title={`Clear Priority Settings?`}
      content={content}
      confirmButtonLabel={"Clear Priority"}
      onTriggerModalObserver={onTriggerRemovePeopleObserver}
    />
  );
};

export default RemovePriorityConfirmationModal;
