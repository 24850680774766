import React from "react";
import { type FlowItemEvent } from "../../../../features/Library/Flows/Designer/types";
import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface IEventCardOwnProps {
  item: FlowItemEvent;
}

const EventCard: React.FC<IEventCardOwnProps> = ({ item }) => (
  <>
    <TopContent {...item} />
    <BottomContent cardLabel={"Event"} icon={item.hasReminders ? "fal fa-bell" : ""} />
  </>
);

export default EventCard;
