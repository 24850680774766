import { groupBy } from "lodash";
import TriggerType from "../../../../../../enums/flowDesigner/triggerType";
import ObjectUtils from "../../../../../../utils/objectUtils";
import { type FlowDesignerData, type TriggersData } from "../../types";
import { type TriggerError, ValidatorErrorTypes } from "../flowValidatorReducer";

const checkTriggerTypeIncompatibility = (triggers: TriggersData[]) => {
  const incompatibleTriggerTypes = [
    [TriggerType.Completion, TriggerType.Response],
    [TriggerType.Completion, TriggerType.Pass],
    [TriggerType.Completion, TriggerType.Fail],
    [TriggerType.Open, TriggerType.ClickThrough],
  ];

  const triggerTypes = new Set([...triggers.map((currentTriggerData) => currentTriggerData.typeId)]);

  for (const incompatibleGroup of incompatibleTriggerTypes) {
    const isIncompatible = incompatibleGroup.every((currentTriggerType) => triggerTypes.has(currentTriggerType));
    if (isIncompatible) return true;
  }

  return false;
};

const checkTriggerTypeDuplication = (triggers: TriggersData[]) => {
  const triggerTypeWithDuplication = [TriggerType.Response];
  const triggersWithoutActions = triggers.filter((trigger) => !trigger.isAction);

  const triggersEntriesGroupedByTypeId = ObjectUtils.typedEntries<TriggerType, TriggersData[]>(
    groupBy(triggersWithoutActions, (trigger) => trigger.typeId),
  );

  for (const [triggerType, triggersGroup] of triggersEntriesGroupedByTypeId) {
    if (triggerTypeWithDuplication.includes(Number(triggerType))) continue;
    if (triggersGroup.length > 1) return true;
  }

  return false;
};

const checkOrphanNoActivityTriggers = (triggers: TriggersData[]) => {
  return triggers.every((t) => t.typeId === TriggerType.NotConsumed) && triggers.length === 1;
};

export const validateTriggers = (data: FlowDesignerData): TriggerError[] => {
  const { triggers } = data;
  const errors: TriggerError[] = [];

  const triggersEntriesGroupedByInId = ObjectUtils.typedEntries<string, TriggersData[]>(
    groupBy(triggers, (trigger) => trigger.inId),
  );

  const pushTriggerError = ({ inId, errorMessage }: { inId: string; errorMessage: string }) => {
    errors.push({
      itemId: inId,
      errorMessage,
      type: ValidatorErrorTypes.TriggerError,
      id: `type-${ValidatorErrorTypes.TriggerError}-itemId-${inId}`,
    });
  };

  triggersEntriesGroupedByInId.forEach(([inId, triggersGroup]) => {
    if (checkTriggerTypeDuplication(triggersGroup)) {
      return pushTriggerError({ inId, errorMessage: "Use different output triggers for highlighted asset" });
    }

    if (checkTriggerTypeIncompatibility(triggersGroup)) {
      return pushTriggerError({ inId, errorMessage: "Use compatible output triggers for highlighted asset" });
    }

    if (checkOrphanNoActivityTriggers(triggersGroup)) {
      return pushTriggerError({ inId, errorMessage: "No Activity trigger is not supported in Linear Flow" });
    }
  });

  return errors;
};
