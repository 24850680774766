import * as filterOptions from "../../actions/filterOptions/filterOptionsActionTypes";
import { type Reducer } from "@reduxjs/toolkit";
import { type FilterAction } from "./withFilteringItems";

interface StateType {
  [key: string]: any;
}

const withFilterOptions =
  <T extends StateType = StateType>(
    namespace: string,
    entityPrefix: string,
    reducer?: Reducer<any, any>,
  ): Reducer<T, FilterAction> =>
  (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case `${namespace}__${entityPrefix}__${filterOptions.FETCH_OPTIONS_BEGIN}`:
        return {
          ...state,
          isLoading: true,
        };
      case `${namespace}__${entityPrefix}__${filterOptions.FETCH_OPTIONS_SUCCESS}`:
        return {
          ...state,
          isLoading: false,
          ...payload,
        };
      case `${namespace}__${entityPrefix}__${filterOptions.FETCH_OPTIONS_FAILURE}`:
        return {
          ...state,
          error: payload.error,
          isLoading: false,
        };
      default:
        return reducer ? reducer(state, action) : { ...state };
    }
  };

export default withFilterOptions;
