import cn from "classnames";
import { isEmpty } from "lodash";
import { PropTypes, string } from "prop-types";
import React, { Component } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-eme";

import defaults from "lodash/defaults";
import ObjectUtils from "../../../../utils/objectUtils";

// eslint-disable-next-line no-unused-vars
import videojsPlaylistPlugin from "videojs-playlist"; // NOSONAR

import CloseCaptionToggleButton from "../controls/closeCaptionToggleButton/closeCaptionToggleButton";
import settingsMenuPlugin from "../plugins/settingsMenu/settingsMenu";
import vjsLanguageUtils from "../plugins/settingsMenu/utils/vjsLanguageUtils";

import "./videoPlayer.scss";
import "./yellowTheme.scss";

const DEFAULT_HEIGHT = 462;
const DEFAULT_WIDTH = 820;

const playerButtons = {
  Spacer: "Spacer",
  GenerateThumbnail: "Generate Thumbnail",
  PlayNext: "Play Next",
  CloseCaptionToggleButton: "CloseCaptionToggleButton",
};

const DEFAULT_VIDEO_OPTIONS = {
  preload: "auto",
  autoplay: false,
  controls: true,
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH,
  html5: {
    preloadTextTracks: false,
  },
};

export class VideoPlayer extends Component {
  state = { currentVideoIndex: 0 };

  componentDidMount() {
    this.initComponent();
  }

  initComponent() {
    if (!this.props.isProcessing) {
      const playerOptions = this.getPlayerOptions();
      this._videoPlayer = videojs(this.videoNode, playerOptions);

      const player = this._videoPlayer;
      this.registerPlugins(player);
      this.registerCCButton();
      this.registerControls(player);
      this.registerEventHandlers();

      player.eme?.();
      this.setupPlaylist();

      this.setLanguageCaption();
      this.registerCaptionsToggleButton();

      this.loadPersistentSettings(player);

      player.el_.oncontextmenu = () => false;
    }
  }

  /* istanbul ignore next */
  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isProcessing && prevProps.isProcessing) {
      this.initComponent();
    }
    if (prevState.currentVideoIndex !== this.state.currentVideoIndex) {
      this.registerEventHandlers();
    }
  }

  componentWillUnmount() {
    this._videoPlayer && this._videoPlayer.dispose();
  }

  registerPlugins(player) {
    settingsMenuPlugin.init();
    const assetDetails = {
      assetId: this.props.assetId,
      hasCaptions: ObjectUtils.isNotEmpty(this.props.textTracks),
      savedVideoQuality: this.getSavedVideoQuality(),
    };
    player.settingsMenuPlugin({ videoInfo: assetDetails });
  }

  registerCCButton() {
    if (ObjectUtils.isNotEmpty(this.props.textTracks)) {
      CloseCaptionToggleButton.init(this._videoPlayer);
      this._videoPlayer.closeCaptionToggleButtonPlugin();
    }
  }

  registerControls(player) {
    this.addControl(player, "play-next", "Play Next", this.props.goNextHandler);
    this.addControl(player, "control-bar-spacer", playerButtons.Spacer, this.props.goNextHandler);

    if (this.props.addGenerateThumbnailButton) {
      this.addControl(
        player,
        "generate-thumbnail",
        playerButtons.GenerateThumbnail,
        /* istanbul ignore next */ () => {
          const video = document.getElementsByTagName("video")[0];
          this.props.onGenerateThumbnail(video, player.currentTime());
        },
      );
    }
  }

  addControl(videoPlayer, className, controlName, handleAction) {
    const videoJsButtonClass = videojs.getComponent("Button"); // NOSONAR https://github.com/videojs/video.js/pull/7410

    const button = new videoJsButtonClass(videoPlayer, {
      name: controlName,
      className: className,
      clickHandler: /* istanbul ignore next */ (e) => handleAction && handleAction(e),
    });

    this._videoPlayer.controlBar.addChild(button, { controlName: controlName });
  }

  /* istanbul ignore next */
  registerEventHandlers() {
    const classNames = {
      invisibleElement: "invisible",
      disabledButtonClass: "disabled-button",
      processingClass: "video-processing",
    };

    this._videoPlayer && this._videoPlayer.on("error", this.handleError.bind(this));

    // playNextButton
    const playNextButton = this._videoPlayer.controlBar.getChild("Play Next");
    const hidePlayNextButton = !this.props.itemsCount || this.state.currentVideoIndex + 1 >= this.props.itemsCount;

    if (hidePlayNextButton) {
      this._videoPlayer.on("canplay", () => {
        playNextButton.addClass(classNames.invisibleElement);
      });
    } else {
      this._videoPlayer.on("canplay", () => {
        playNextButton.removeClass(classNames.invisibleElement);
      });
    }

    // generateThumbnailButton
    const generateThumbnailButton = this._videoPlayer.controlBar.getChild(playerButtons.GenerateThumbnail);

    if (this.props.addGenerateThumbnailButton) {
      this._videoPlayer.on("canplay", () => {
        generateThumbnailButton.removeClass(classNames.processingClass);
        generateThumbnailButton.removeClass(classNames.disabledButtonClass);
      });
      this._videoPlayer.on("waiting", () => {
        generateThumbnailButton.addClass(classNames.processingClass);
        generateThumbnailButton.addClass(classNames.disabledButtonClass);
      });
    }

    // change volume
    this._videoPlayer.on("volumechange", () => {
      const volume = this._videoPlayer.volume();
      const isMuted = this._videoPlayer.muted();

      localStorage.setItem("playerVolume", volume);
      localStorage.setItem("playerMute", isMuted);
    });
  }

  /* istanbul ignore next */
  registerCaptionsToggleButton() {
    // caption 'cc' button
    this._videoPlayer.on("texttrackchange", (_) => {
      const ccButton = this._videoPlayer.controlBar.getChild(playerButtons.CloseCaptionToggleButton);
      const textTracks = this._videoPlayer.remoteTextTracks();

      const isCaptionsShown = textTracks.tracks_.filter((item) => {
        return item.mode === "showing";
      }).length;

      if (isCaptionsShown) {
        ccButton.addClass("active");
      } else {
        ccButton.removeClass("active");
      }
    });
  }

  setLanguageCaption() {
    if (isEmpty(this.props.textTracks)) {
      return;
    }

    const defaultTextTrack = vjsLanguageUtils.remoteTextTrack(this.props.textTracks[0]);
    const ccBtn = this._videoPlayer.controlBar.childNameIndex_.CloseCaptionToggleButton;

    ccBtn.setLanguage(defaultTextTrack);
    this._videoPlayer.selectedLanguage = defaultTextTrack.langObject.code;
  }

  /* istanbul ignore next */
  getSavedVolume() {
    return JSON.parse(localStorage.getItem("playerVolume"));
  }

  /* istanbul ignore next */
  getSavedMuteState() {
    return JSON.parse(localStorage.getItem("playerMute"));
  }

  /* istanbul ignore next */
  getSavedSpeed() {
    return JSON.parse(sessionStorage.getItem("playerSpeed"));
  }

  /* istanbul ignore next */
  getSavedVideoQuality() {
    return localStorage.getItem("playerVideoQuality");
  }

  /* istanbul ignore next */
  loadSavedVolumeSettings(player) {
    player.ready(() => {
      const volume = this.getSavedVolume();
      const isMuted = this.getSavedMuteState();

      volume !== null && player.volume(volume);
      isMuted !== null && player.muted(isMuted);
    });
  }

  /* istanbul ignore next */
  loadSavedSpeed(player) {
    player.ready(() => {
      const savedSpeed = this.getSavedSpeed();
      savedSpeed !== null && player.playbackRate(savedSpeed);
    });
  }

  /* istanbul ignore next */
  loadSavedVideoQuality(player) {
    player.ready(() => {
      const videoQuality = this.getSavedVideoQuality();
      videoQuality !== null && player.mediaPlayer && player.setQualityFor("video", videoQuality);
    });
  }

  /* istanbul ignore next */
  loadPersistentSettings(player) {
    this.loadSavedSpeed(player);
    this.loadSavedVideoQuality(player);
    this.loadSavedVolumeSettings(player);
  }

  /* istanbul ignore next */
  handleError(e) {
    const errorMessage = "Oops something went wrong. One of the reasons can be wrong format or manifest is not ready.";
    e.stopImmediatePropagation();
    this._videoPlayer.reset();
    // createModal return one-off modals for temporary purpose.
    // dispose themselves immediately upon closing.
    this.errorModalDialog = this._videoPlayer.createModal(errorMessage); // NOSONAR
  }

  setupPlaylist() {
    this._videoPlayer.playlist([
      {
        sources: this.props.source,
        textTracks: this.props.textTracks,
      },
    ]);
  }

  getPlayerOptions() {
    const options = defaults({}, this.props.options, DEFAULT_VIDEO_OPTIONS);

    options.errorDisplay = false;
    options.controlBar = {
      children: {
        playToggle: true,
        currentTimeDisplay: true,
        timeDivider: true,
        durationDisplay: true,
        progressControl: true,
        volumePanel: {
          inline: false,
        },
        settingsMenuButton: true,
        fullscreenToggle: true,
      },
    };

    options.textTracks = this.props.textTracks;

    return options;
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    const videoPlayerClasses = cn("video-js", "content-player", "vjs-default-skin");

    return (
      <div data-vjs-player>
        {this.props.isProcessing ? (
          <>
            <div className="maxresdefault" />
            <span className="processing-placeholder">Processing...</span>
          </>
        ) : (
          <video crossOrigin="anonymous" ref={(node) => (this.videoNode = node)} className={videoPlayerClasses} />
        )}
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  options: DEFAULT_VIDEO_OPTIONS,
  addGenerateThumbnailButton: false,
  // API-implementation: replace with real API when it will be available
  assetId: 1,
  // end
};

VideoPlayer.propTypes = {
  source: PropTypes.shape({
    src: string,
    type: string,
    keySystems: PropTypes.object,
    emeHeaders: PropTypes.object,
  }),
  options: PropTypes.object,
  addGenerateThumbnailButton: PropTypes.bool,
  onGenerateThumbnail: PropTypes.func,
  isProcessing: PropTypes.bool,
  textTracks: PropTypes.arrayOf(
    PropTypes.shape({
      kind: string,
      srclang: string,
      label: string,
      src: string,
    }),
  ),
  itemsCount: PropTypes.number,
  assetId: PropTypes.number,
  goNextHandler: PropTypes.func,
  accessToken: PropTypes.string,
};

export default VideoPlayer;
