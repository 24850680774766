import { type Dispatcher } from "interfaces";
import { createDispatcher } from "../dispatcher";
import * as events from "../events/people/peopleEvents";
import { type IMapping } from "./types";
import {
  updateFailedTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";
/* istanbul ignore next */
export const templateMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatchTaskOperationSuccess, dispatchTaskOperationFailure } = createDispatcher(dispatcher);
  const payloadMapping = (data: { MessageId: string }) => ({
    messageId: data.MessageId,
  });
  mapping[events.ApproveAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.ApproveAccountTemplateFailed] = dispatchTaskOperationFailure(payloadMapping);
  mapping[events.DeclineAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.DeleteTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.AccountAddedToTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.AccountRemovedFromTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);

  mapping[events.AddTemplateGroupPacksAccountMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddTemplateGroupPacksAccountMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.RemoveAllPackFromTemplateGroupMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllPackFromTemplateGroupMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.UpdateAllTemplateGroupAccountPackMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateAllTemplateGroupAccountPackMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.UpdateAllTemplateGroupPackAccountsMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.UpdateAllTemplateGroupPackAccountsMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
};
