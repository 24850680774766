import React from "react";
import "./flowDesignerTotalItems.scss";

export interface Props {
  totalItems: number;
}

export function FlowDesignerTotalItems({ totalItems }: Props) {
  return <span className="flow-designer-control-panel_total-items">Total Items: {totalItems}</span>;
}

export default FlowDesignerTotalItems;
