import { type DropdownProps } from "semantic-ui-react";
import ContextSwitcherDropdown, {
  type ContextSwitcherOption,
} from "../contextSwitcherDropdown/contextSwitcherDropdown";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import cn from "classnames";
import "./peopleSegmentationDropdown.scss";
import { UsersGroupsContext } from "enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const peopleOptions: ContextSwitcherOption[] = [
  {
    key: "Users",
    label: "Users",
    iconName: "fa-user",
  },
  {
    key: "Groups",
    label: "Groups",
    iconName: "fa-users",
  },
  {
    key: UsersGroupsContext.TemplateGroups,
    label: "Template",
    iconComponent: <FontAwesomeIcon icon={faBuildingUser} className={cn("icon")} />,
  },
];

interface PeopleSegmentationDropdownProps extends DropdownProps {
  options?: ContextSwitcherOption[];
  defaultOption?: string;
  includeTemplateGroupsOption?: boolean;
  disableSelectionChange?: boolean;
}

export default function PeopleSegmentationDropdown(props: PeopleSegmentationDropdownProps) {
  const { includeTemplateGroupsOption, ...restProps } = props;
  const filteredOptions = includeTemplateGroupsOption
    ? peopleOptions
    : peopleOptions.filter((option) => option.key !== UsersGroupsContext.TemplateGroups);

  return (
    <ContextSwitcherDropdown
      data-testid="people-segmentation-dropdown"
      className={includeTemplateGroupsOption ? "people-segmentation-dropdown-lg" : "people-segmentation-dropdown"}
      options={filteredOptions}
      {...restProps}
    />
  );
}
