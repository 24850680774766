import { type AppDispatch, type RootState } from "../../Application/globaltypes/redux";

import typedDataService from "../../Application/services/dataServices/typedDataService";
import dataService from "../../Application/services/dataServices/dataService";
import { makeActionCreator } from "../../Application/actions/actionsBuilder";

import {
  type GetPeopleContentParams,
  type EventAssignmentApi,
  type VideoAssignmentApi,
  type PeopleFlow,
  type PeopleFlowApi,
  type PeopleVideo,
  type PeoplEvent,
  type PeoplePdf,
  type PdfAssignmentApi,
} from "../types";
import { type SortingDirection } from "../../../enums";

import { type GenericFiltersMap } from "../../../utils/filterUtils";
import { type Filters } from "../../../utils/queryUtils";

import * as contentAssignmentActionTypes from "./ContentAssignmentActionTypes";
import { type AssignmentListFiltersEnum } from "../../../components/contentAssignment/types";
import { type AvailableItemBase } from "../ContentAssignments/models";

const countHeaderName: any = process.env.REACT_APP_COUNT_HEADER_NAME;
const takeCollectionCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

// common
const flowMapping = (flow: PeopleFlowApi): PeopleFlow => ({
  id: flow.flowId,
  ...flow,
});

const videoMapping = (video: VideoAssignmentApi): PeopleVideo => ({
  thumbnailUrl: video.bag?.thumbnailUrl,
  durationInSeconds: video.bag?.duration!,
  ...video,
});

const eventMapping = (event: EventAssignmentApi): PeoplEvent => ({
  thumbnailUrl: event.bag?.thumbnailUrl,
  ...event,
});

const pdfMapping = (pdf: PdfAssignmentApi): PeoplePdf => ({
  thumbnailUrl: pdf.bag?.thumbnailUrl,
  ...pdf,
});

const fetchFlowsListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchFlowsGridLazyActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS_LAZY.FETCH_GRID_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS_LAZY.FETCH_GRID_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS_LAZY.FETCH_GRID_ITEMS_FAILURE, "error"),
};

const fetchAssetsListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchAssetsGridLazyActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS_LAZY.FETCH_GRID_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS_LAZY.FETCH_GRID_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS_LAZY.FETCH_GRID_ITEMS_FAILURE, "error"),
};

const fetchEventsListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchEventsGridLazyActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS_LAZY.FETCH_GRID_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS_LAZY.FETCH_GRID_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS_LAZY.FETCH_GRID_ITEMS_FAILURE, "error"),
};

const fetchSurveysListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_SURVEYS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_SURVEYS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_SURVEYS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchAssessmentsListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSESSMENTS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_ASSESSMENTS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_ASSESSMENTS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchPdfsListActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS.FETCH_ITEMS_SUCCESS, "items", "itemsCount"),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS.FETCH_ITEMS_FAILURE, "error"),
};

const fetchPdfsGridLazyActions = {
  begin: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS_LAZY.FETCH_GRID_ITEMS_BEGIN),
  success: makeActionCreator(
    contentAssignmentActionTypes.FETCH_PEOPLE_PDFS_LAZY.FETCH_GRID_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  ),
  failure: makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS_LAZY.FETCH_GRID_ITEMS_FAILURE, "error"),
};

const fetchData = async <T extends AvailableItemBase>(
  getData: () => Promise<{ items: T[]; itemsCount: number }>,
  actions: {
    begin: () => any;
    success: (items: T[], itemsCount: number) => any;
    failure: (error: any) => any;
  },
  dispatch: AppDispatch,
) => {
  try {
    dispatch(actions.begin());
    const result = await getData();
    dispatch(actions.success(result.items, result.itemsCount));
  } catch (err) {
    dispatch(actions.failure(err));
  }
};
// common

// flows Group
const fetchGroupFlows = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    orderBy: string;
    filters: Filters;
    searchTerm: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getGroupFlows(groupId, payload);
  const flows = result.data.map(flowMapping);
  const recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: flows, itemsCount: recordsCount };
};

export const fetchGroupFlowsList =
  (groupId: number, skip: number, top: number, orderBy: string, filters: Filters = {}, searchTerm: string = "") =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchGroupFlows(groupId, {
          skip,
          top: top,
          orderBy,
          filters,
          searchTerm,
        }),
      fetchFlowsListActions,
      dispatch,
    );
  };

export const fetchGroupFlowsGridLazy =
  (groupId: number, skip: number, top: number, orderBy: string, filters: Filters = {}, searchTerm: string = "") =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchGroupFlows(groupId, {
          skip,
          top: top ?? takeCollectionCount,
          orderBy,
          filters,
          searchTerm,
        }),
      fetchFlowsGridLazyActions,
      dispatch,
    );
  };
// flows Group

// assets Group
const fetchGroupAssets = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    filters: Filters;
    searchTerm: string;
    sortBy: string;
    sortOrder: SortingDirection;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.groups.getGroupContent(groupId, { ...payload, contentType: "video" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  const data = result.data.map(videoMapping);
  return { items: data, itemsCount: recordsCount };
};

export const fetchGroupAssetsList =
  (
    groupId: number,
    skip: number,
    top: number,
    sortBy: string,
    sortOrder: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () => fetchGroupAssets(groupId, { skip, top, sortBy, sortOrder, filters, searchTerm }),
      fetchAssetsListActions,
      dispatch,
    );
  };

export const fetchGroupAssetsGridLazy =
  (
    groupId: number,
    skip: number,
    top: number,
    sortBy: string,
    sortOrder: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () => fetchGroupAssets(groupId, { skip, top, sortBy, sortOrder, filters, searchTerm }),
      fetchAssetsGridLazyActions,
      dispatch,
    );
  };
// assets

// surveys Group
const fetchGroupSurveys = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    searchTerm?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.groups.getGroupContent(groupId, { ...payload, contentType: "survey" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: result.data, itemsCount: recordsCount };
};

export const fetchGroupSurveysList =
  (
    groupId: number,
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    term?: string,
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchGroupSurveys(groupId, {
          skip,
          top,
          sortBy: sortingColumnName,
          sortOrder: sortingDirection,
          filters,
          searchTerm: term,
        }),
      fetchSurveysListActions,
      dispatch,
    );
  };
// surveys Group

// assessments Group
const fetchGroupAssessments = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    searchTerm?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.groups.getGroupContent(groupId, { ...payload, contentType: "assessment" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: result.data, itemsCount: recordsCount };
};

export const fetchGroupAssessmentsList =
  (
    groupId: number,
    skip: number,
    top: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    searchTerm?: string,
    filters?: GenericFiltersMap<AssignmentListFiltersEnum>,
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchGroupAssessments(groupId, {
          skip,
          top,
          sortBy,
          sortOrder,
          filters,
          searchTerm,
        }),
      fetchAssessmentsListActions,
      dispatch,
    );
  };
// assessments Group

// assessments User
const fetchUserAssessments = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    searchTerm?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getUserContent(userId, { ...payload, contentType: "assessment" });
  const recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: result.data, itemsCount: recordsCount };
};

export const fetchUserAssessmentsList = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, top, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  await fetchData(
    () =>
      fetchUserAssessments(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters,
        searchTerm,
      }),
    fetchAssessmentsListActions,
    dispatch,
  );
};
// assessments User

// events Group
const fetchGroupEvents = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    term?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.groups.getGroupContent(groupId, { ...payload, contentType: "externalEvent" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  const data = result.data.map(eventMapping);
  return { items: data, itemsCount: recordsCount };
};

export const fetchGroupEventsList =
  (
    groupId: number,
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchGroupEvents(groupId, {
          skip,
          top,
          sortBy: sortingColumnName,
          sortOrder: sortingDirection,
          filters,
          term: searchTerm,
        }),
      fetchEventsListActions,
      dispatch,
    );
  };

export const fetchGroupEventsGridLazy =
  (
    groupId: number,
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchData(
      () =>
        fetchGroupEvents(groupId, {
          skip,
          top,
          sortBy: sortingColumnName,
          sortOrder: sortingDirection,
          filters,
          term: searchTerm,
        }),
      fetchEventsGridLazyActions,
      dispatch,
    );
  };
// events Group

export const resetAssignmentAssetsGrid = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS_LAZY.RESET_GRID_ITEMS,
);

export const resetAssignmentFlowsGrid = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS_LAZY.RESET_GRID_ITEMS,
);

export const resetAssignmentAssetsList = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_ASSETS.RESET_ITEMS,
);

export const resetAssignmentFlowsList = makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_FLOWS.RESET_ITEMS);

export const resetAssignmentEventsList = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS.RESET_ITEMS,
);

export const resetAssignmentEventsGrid = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_EVENTS_LAZY.RESET_GRID_ITEMS,
);

export const resetAssignmentPdfsList = makeActionCreator(contentAssignmentActionTypes.FETCH_PEOPLE_PDFS.RESET_ITEMS);

export const resetAssignmentPdfsGrid = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_PDFS_LAZY.RESET_GRID_ITEMS,
);

export const resetAssignmentSurveysList = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_SURVEYS.RESET_ITEMS,
);

export const resetAssignmentAssessmentsList = makeActionCreator(
  contentAssignmentActionTypes.FETCH_PEOPLE_ASSESSMENTS.RESET_ITEMS,
);

// flows User
const fetchUserFlows = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    orderBy: string;
    filters: Filters;
    searchTerm: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getUserFlows(userId, payload);
  const flows = result.data.map(flowMapping);
  let recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: flows, itemsCount: recordsCount };
};

export const fetchUserFlowsList =
  (userId: number, skip: number, top: number, orderBy: string, filters: Filters = {}, searchTerm: string = "") =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchUserFlows(userId, {
          skip,
          top,
          orderBy,
          filters,
          searchTerm,
        }),
      fetchFlowsListActions,
      dispatch,
    );
  };

export const fetchUserFlowsGridLazy =
  (userId: number, skip: number, top: number, orderBy: string, filters: Filters = {}, searchTerm: string = "") =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () =>
        fetchUserFlows(userId, {
          skip,
          top: top ?? takeCollectionCount,
          orderBy,
          filters,
          searchTerm,
        }),
      fetchFlowsGridLazyActions,
      dispatch,
    );
  };

// assets User
const fetchUserAssets = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    filters: Filters;
    searchTerm: string;
    sortBy?: string;
    sortOrder?: SortingDirection;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getUserContent(userId, { ...payload, contentType: "video" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  const data = result.data.map(videoMapping);
  return { items: data, itemsCount: recordsCount };
};

export const fetchUserAssetsList = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, top, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  await fetchData(
    () =>
      fetchUserAssets(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters: filters!,
        searchTerm: searchTerm!,
      }),
    fetchAssetsListActions,
    dispatch,
  );
};

export const fetchUserAssetsGridLazy = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, top, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  await fetchData(
    () =>
      fetchUserAssets(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters: filters!,
        searchTerm: searchTerm!,
      }),
    fetchAssetsGridLazyActions,
    dispatch,
  );
};

// surveys User
const fetchUserSurveys = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    searchTerm?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.users.getUserContent(userId, { ...payload, contentType: "survey" });
  const recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: result.data, itemsCount: recordsCount };
};

export const fetchUserSurveysList = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, sortingColumnName, sortingDirection, filters, top, searchTerm } = params;
  await fetchData(
    () =>
      fetchUserSurveys(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters,
        searchTerm,
      }),
    fetchSurveysListActions,
    dispatch,
  );
};

// events user
const fetchUserEvents = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    term?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getUserContent(userId, { ...payload, contentType: "externalEvent" });
  const data = result.data.map(eventMapping);
  const events = data.map(eventMapping);
  let recordsCount = parseInt(result.headers[countHeaderName]);
  return { items: events, itemsCount: recordsCount };
};

export const fetchUserEventsList = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  const top = params.top ?? takeCollectionCount;
  await fetchData(
    () =>
      fetchUserEvents(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters,
        term: searchTerm,
      }),
    fetchEventsListActions,
    dispatch,
  );
};

export const fetchUserEventsGridLazy =
  (params: GetPeopleContentParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { userId, skip, sortingColumnName, sortingDirection, filters, searchTerm } = params;
    const top = params.top ?? takeCollectionCount;
    let data = fetchUserEvents(userId, {
      skip,
      top,
      sortBy: sortingColumnName,
      sortOrder: sortingDirection,
      filters,
      term: searchTerm,
    });
    await fetchData(() => data, fetchEventsGridLazyActions, dispatch);
  };

// pdfs user
const fetchUserPdfs = async (
  userId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters?: Filters;
    searchTerm?: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await dataService.getUserContent(userId, { ...payload, contentType: "pdf" });

  const recordsCount = parseInt(result.headers[countHeaderName]);
  const data = result.data.map(pdfMapping);
  return { items: data, itemsCount: recordsCount };
};

export const fetchUserPdfsList = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  const top = params.top ?? takeCollectionCount;
  await fetchData(
    () =>
      fetchUserPdfs(userId, {
        skip,
        top,
        sortBy: sortingColumnName,
        sortOrder: sortingDirection,
        filters,
        searchTerm,
      }),
    fetchPdfsListActions,
    dispatch,
  );
};

export const fetchUserPdfsGridLazy = (params: GetPeopleContentParams) => async (dispatch: AppDispatch) => {
  const { userId, skip, top, sortingColumnName, sortingDirection, filters, searchTerm } = params;
  let data = fetchUserPdfs(userId, {
    skip,
    top,
    sortBy: sortingColumnName,
    sortOrder: sortingDirection,
    filters,
    searchTerm,
  });
  await fetchData(() => data, fetchPdfsGridLazyActions, dispatch);
};

// pdf groups
const fetchGroupPdfs = async (
  groupId: number,
  payload: {
    skip: number;
    top: number;
    sortBy?: string;
    sortOrder?: SortingDirection;
    filters: Filters;
    searchTerm: string;
  },
): Promise<{ items: []; itemsCount: number }> => {
  const result = await typedDataService.groups.getGroupContent(groupId, { ...payload, contentType: "pdf" });
  let recordsCount = parseInt(result.headers[countHeaderName]);
  const data = result.data.map(pdfMapping);
  return { items: data, itemsCount: recordsCount };
};

export const fetchGroupPdfsList =
  (
    groupId: number,
    skip: number,
    top: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () => fetchGroupPdfs(groupId, { skip, top, sortBy, sortOrder, filters, searchTerm }),
      fetchPdfsListActions,
      dispatch,
    );
  };

export const fetchGroupPdfsGridLazy =
  (
    groupId: number,
    skip: number,
    top: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    filters: Filters = {},
    searchTerm: string = "",
  ) =>
  async (dispatch: AppDispatch) => {
    await fetchData(
      () => fetchGroupPdfs(groupId, { skip, top, sortBy, sortOrder, filters, searchTerm }),
      fetchPdfsGridLazyActions,
      dispatch,
    );
  };
