import cn from "classnames";
import { Icon } from "semantic-ui-react";
import "./packStatusLabelWithIcon.scss";

export interface PackStatusLabelWithIconProps {
  isRequested: boolean;
  isPurchased?: boolean;
  isExpired?: boolean;
  isTrial?: boolean;
  isOwn?: boolean;
}

export const PackStatusLabelWithIcon: React.FC<PackStatusLabelWithIconProps> = (props) => {
  const { isRequested, isPurchased, isExpired, isTrial, isOwn } = props;

  const renderLabelActionWithIcon = (containerClass: string, iconClass: string, content: string) => {
    return (
      <div className="container">
        <div className={cn("pack-status-label-with-icon-container", containerClass)}>
          <div className="pack-status-label-with-icon-align">
            <Icon className={iconClass} />
          </div>
          <div className="pack-status-label-with-icon-align">{content}</div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isTrial && isExpired) {
      return renderLabelActionWithIcon(
        "expired-label-container",
        "fa fa-exclamation-triangle pack-status-label-with-icon-white",
        "Trial Ended",
      );
    }

    if (isTrial) {
      return renderLabelActionWithIcon(
        "purchased-label-container",
        "fa fa-check pack-status-label-with-icon-white",
        "Trial Started",
      );
    }

    if (isExpired) {
      return renderLabelActionWithIcon(
        "expired-label-container",
        "fa fa-exclamation-triangle pack-status-label-with-icon-white",
        "Expired",
      );
    }

    if (isRequested) {
      return renderLabelActionWithIcon(
        "requested-label-container",
        "fa fa-clock pack-status-label-with-icon-white",
        "Requested...",
      );
    }

    if (isPurchased && !isOwn) {
      return renderLabelActionWithIcon(
        "purchased-label-container",
        "fa fa-check pack-status-label-with-icon-white",
        "Purchased",
      );
    }

    return null;
  };

  return renderContent();
};
