import dataService from "../../../services/activityLogsDataService";
import { type Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/activityLogsSlice";
import { type IntegrationTypes } from "../../types";

export const fetchLogs =
  (type: IntegrationTypes, skip: number, take: number, sortColumn: string, sortDirection: string, moboId?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const { items, totalCount } = await dataService.getActivityLogs(
        type,
        skip,
        take,
        sortColumn,
        sortDirection,
        moboId,
      );
      dispatch(fetchSuccess({ items, totalCount }));
    } catch (error) {
      dispatch(fetchFailure(error as Error));
    }
  };
