import React, { type FC } from "react";
import { Icon } from "semantic-ui-react";

import { AddLabelsLinkButton } from "../../../../components/buttons/linkButtons";

export interface AddLabelsButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
}

export const AddLabelsAssetButton: FC<AddLabelsButtonProps> = ({ isDisabled, onClick }) => {
  return (
    <AddLabelsLinkButton
      onClick={onClick}
      isDisabled={isDisabled}
      after={isDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
    />
  );
};
