import { type RootState } from "../../../../Application/globaltypes/redux";
import { DetailsSubHeader } from "../../../../../components/sectionHeader";
import { type Props as DetailsSubHeaderProps } from "../../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";
import { connect } from "react-redux";

export type EventsSubheaderContainerProps = DetailsSubHeaderProps & {
  showLastModified: boolean;
};

const mapStateToProps = (state: RootState, ownProps: EventsSubheaderContainerProps) => {
  return {
    isUpdateInProgress: state.library.events.eventEntityState.isEntityCommandInProgress && ownProps.isUpdateInProgress,
    lastModifiedDateTime: ownProps.showLastModified
      ? state.library.events.eventEntityState.lastModifiedDateTime ?? ownProps.lastModifiedDateTime
      : undefined,
  };
};

export default connect(mapStateToProps)(DetailsSubHeader);
