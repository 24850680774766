import { clone } from "lodash";
import { combineReducers } from "redux";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import { createReducer } from "../../../../utils/reduxUtils";
import { withFetchingChildEntityListItems } from "../../../Application/reducers/hoc/withFetchingItems";
import withSearch from "../../../Application/reducers/hoc/withSearch";
import accountPacksGridSlice from "../../../Licensing/Packs/state/slices/accountPacksSlice";
import { type PackOverview } from "../../../Licensing/Packs/types/state";
import { type AccountConfigurationInfo, type AccountType, type AddOn } from "../../types";
import type Contact from "../../../../interfaces/contact";
import * as actionTypes from "./editAccountActionTypes";
import addUserReducer from "./slices/addUser/addUserReducer";
import csvImportReducer from "./slices/csvImport/csvImportReducer";
import editAccountPackLicenseSlice from "./slices/editAccountPackLicenseSlice";
import editAccountUsersSlice from "./slices/editAccountUsersSlice";
import permissionsToAccountReducer from "./slices/permissionsToAccountSlice";
import potentialContactsReducer from "./slices/potentialContactsSlice";
import { resetEditAccountInfo } from "./editAccountActions";
import templatesReducer from "./slices/templates/templatesReducer";

export interface EditAccountState {
  accountTypes: Array<AccountType>;
  accountConfiguration: AccountConfigurationInfo;
  isLoading: boolean;
  isLoadingAccountTypes: boolean;
  error: any;
  isAccessAuthorized: boolean;
}

const initialState: EditAccountState = {
  accountTypes: [],
  accountConfiguration: {
    parentAccountId: -1,
    contractTypeId: -1,
    accountTypeId: -1,
    accountTypeName: "",
    alias: null,
    id: -1,
    name: "",
    dateModified: "",
    parentName: "",
    logoUri: "",
    isDemo: false,
    magicLinkLogin: false,
    canEdit: false,
    canChangeAccountType: false,
    sessionTimeoutInMinutes: 0,
    sessionTimeoutDays: 0,
    sessionTimeoutHours: 0,
    sessionTimeoutMinutes: 0,
  },
  error: null,
  isLoading: false,
  isLoadingAccountTypes: false,
  isAccessAuthorized: true,
};

const accountInfo = (
  state: EditAccountState | undefined,
  action: actionTypes.FetchAccountInfoTypes | actionTypes.FetchAccountTypesActionTypes | actionTypes.UpdateAccountInfo,
): EditAccountState => {
  const currentState = state || initialState;
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_ACCOUNT_TYPES_BEGIN:
      return {
        ...currentState,
        isLoadingAccountTypes: true,
        error: null,
      };
    case actionTypes.FETCH_ACCOUNT_TYPES_FAILURE:
      return {
        ...currentState,
        isLoadingAccountTypes: false,
        error: payload.error,
      };
    case actionTypes.FETCH_ACCOUNT_TYPES_SUCCESS:
      const { accountTypes } = payload;

      return {
        ...currentState,
        accountTypes,
        isLoadingAccountTypes: false,
        error: null,
      };
    case actionTypes.FETCH_ACCOUNT_INFO_BEGIN: {
      return {
        ...currentState,
        isLoading: true,
        error: null,
        isAccessAuthorized: true,
      };
    }
    case actionTypes.FETCH_ACCOUNT_INFO_SUCCESS: {
      return {
        ...currentState,
        accountConfiguration: payload.info,
        isLoading: false,
      };
    }

    case actionTypes.FETCH_ACCOUNT_INFO_FAILURE: {
      return {
        ...currentState,
        isLoading: false,
        error: payload.error,
      };
    }

    case actionTypes.FETCH_ACCOUNT_INFO_FORBIDDEN: {
      return {
        ...currentState,
        isLoading: false,
        isAccessAuthorized: false,
        error: payload.error,
      };
    }

    case actionTypes.UPDATE_ACCOUNT_INFO: {
      return {
        ...currentState,
        accountConfiguration: {
          ...currentState.accountConfiguration,
          ...payload.account,
        },
      };
    }

    case resetEditAccountInfo.type: {
      return {
        ...initialState,
      };
    }

    default:
      return currentState;
  }
};

const updateAccountContactsReducer = (state: any, action: any) => {
  if (action.type !== actionTypes.UPDATE_ACCOUNT_CONTACTS) {
    return state;
  }

  const {
    payload: { changes },
  } = action;

  const newItems = state.items
    .filter((contact: Contact) => !changes.removedContactIds.some((id: number) => id === contact.id))
    .map((contact: Contact) => clone(contact));

  if (changes.mainContactId) {
    updateContactIsMain((c: Contact) => c.isMain, false);
    updateContactIsMain((c: Contact) => changes.mainContactId === c.id, true);
  }

  return {
    ...state,
    items: newItems,
    itemsCount: state.itemsCount - changes.removedContactIds.length,
  };

  function updateContactIsMain(predicate: (contact: Contact) => boolean, isMain: boolean) {
    const index = newItems.findIndex(predicate);
    if (index !== -1) {
      newItems[index].isMain = isMain;
    }
  }
};

export interface AddOnsState {
  items: Array<AddOn>;
  isLoading: boolean;
  isAccessAuthorized: boolean;
  error: string | null;
}

export interface AccountPacksState {
  isAccessAuthorized: boolean;
}

export interface ContactsState {
  items: Array<Contact>;
  itemsCount: number;
  isLoading: boolean;
  isAccessAuthorized: boolean;
  error: string | null;
}

const initialPacksState: AccountPacksState = {
  isAccessAuthorized: true,
};

const initialEntityState: AddOnsState = {
  items: [],
  isLoading: false,
  isAccessAuthorized: true,
  error: null,
};

const initialContactState: ContactsState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  isAccessAuthorized: true,
  error: null,
};

const fetchForbidden = (state: AddOnsState | ContactsState, action: actionTypes.FetchFailure) => ({
  ...state,
  isLoading: false,
  isAccessAuthorized: false,
  error: action.payload.error,
});

const fetchFailure = (state: AddOnsState | ContactsState, action: actionTypes.FetchFailure) => ({
  ...state,
  isLoading: false,
  error: action.payload.error,
});

const fetchSuccess = (state: AddOnsState, action: actionTypes.FetchSuccess<Array<AddOn>>) => ({
  ...state,
  isLoading: false,
  error: null,
  items: action.payload,
});

const fetchContactsSuccess = (
  state: ContactsState,
  action: actionTypes.FetchSuccess<{ items: Array<Contact>; itemsCount: number }>,
) => ({
  ...state,
  isLoading: false,
  error: null,
  items: action.payload.items,
  itemsCount: action.payload.itemsCount,
});

const fetchContactBegin = (state: ContactsState) => ({
  ...state,
  itemsCount: 0,
  isLoading: true,
  isAccessAuthorized: true,
  error: null,
});

const fetchBegin = (state: AddOnsState) => ({
  ...state,
  isLoading: true,
  isAccessAuthorized: true,
  error: null,
});

const reset = (state: AddOnsState | ContactsState) => ({
  ...state,
  isAccessAuthorized: true,
});

const accountAddOnsHandler = () => {
  const {
    FETCH_ACCOUNT_ADDONS_BEGIN,
    FETCH_ACCOUNT_ADDONS_SUCCESS,
    FETCH_ACCOUNT_ADDONS_FAILURE,
    FETCH_ACCOUNT_ADDONS_FORBIDDEN,
    UPDATE_ACCOUNT_ADDONS,
    RESET_ACCOUNT_ADDONS,
  } = actionTypes;

  const fetchAddOnsBegin = (state: AddOnsState) => fetchBegin(state);
  const fetchAddOnsSuccess = (state: AddOnsState, action: actionTypes.FetchSuccess<Array<AddOn>>) =>
    fetchSuccess(state, action);
  const fetchAddOnsFailure = (state: AddOnsState, action: actionTypes.FetchFailure) => fetchFailure(state, action);
  const fetchAddOnsForbidden = (state: AddOnsState, action: actionTypes.FetchFailure) => fetchForbidden(state, action);
  const resetAccountAddOns = (state: AddOnsState) => reset(state);

  const updateAddOns = (state: AddOnsState, action: actionTypes.UpdateAddOns) => {
    const updatedAddOns = state.items.map((addOn) => clone(addOn));

    updatedAddOns.forEach((addOn) => {
      const savedAddOn = addOn.default ? addOn : action.payload.find((a) => a.id === addOn.id);
      if (savedAddOn) {
        addOn.isEnabled = true;
        addOn.expirationDate = savedAddOn.expirationDate;
      } else {
        addOn.isEnabled = false;
      }
    });

    return {
      ...state,
      items: updatedAddOns,
    };
  };

  return {
    [FETCH_ACCOUNT_ADDONS_BEGIN]: fetchAddOnsBegin,
    [FETCH_ACCOUNT_ADDONS_SUCCESS]: fetchAddOnsSuccess,
    [FETCH_ACCOUNT_ADDONS_FAILURE]: fetchAddOnsFailure,
    [FETCH_ACCOUNT_ADDONS_FORBIDDEN]: fetchAddOnsForbidden,
    [UPDATE_ACCOUNT_ADDONS]: updateAddOns,
    [RESET_ACCOUNT_ADDONS]: resetAccountAddOns,
  };
};

const accountContactsHandler = () => {
  const {
    FETCH_ACCOUNT_CONTACTS_BEGIN,
    FETCH_ACCOUNT_CONTACTS_SUCCESS,
    FETCH_ACCOUNT_CONTACTS_FAILURE,
    FETCH_ACCOUNT_CONTACTS_FORBIDDEN,
    UPDATE_ACCOUNT_CONTACTS,
    RESET_ACCOUNT_CONTACTS,
  } = actionTypes;

  const fetchAccountContactsBegin = (state: ContactsState) => fetchContactBegin(state);
  const fetchAccountContactsSuccess = (
    state: ContactsState,
    action: actionTypes.FetchSuccess<{ items: Array<Contact>; itemsCount: number }>,
  ) => fetchContactsSuccess(state, action);
  const fetchAccountContactsFailure = (state: ContactsState, action: actionTypes.FetchFailure) =>
    fetchFailure(state, action);
  const fetchAccountContactsForbidden = (state: ContactsState, action: actionTypes.FetchFailure) =>
    fetchForbidden(state, action);
  const resetAccountContacts = (state: ContactsState) => reset(state);

  return {
    [FETCH_ACCOUNT_CONTACTS_BEGIN]: fetchAccountContactsBegin,
    [FETCH_ACCOUNT_CONTACTS_SUCCESS]: fetchAccountContactsSuccess,
    [FETCH_ACCOUNT_CONTACTS_FAILURE]: fetchAccountContactsFailure,
    [FETCH_ACCOUNT_CONTACTS_FORBIDDEN]: fetchAccountContactsForbidden,
    [UPDATE_ACCOUNT_CONTACTS]: updateAccountContactsReducer,
    [RESET_ACCOUNT_CONTACTS]: resetAccountContacts,
  };
};

const accountPacksHandler = () => {
  const { FETCH_ACCOUNT_PACKS_FORBIDDEN, RESET_ACCOUNT_PACKS_FORBIDDEN } = actionTypes;
  const fetchAccountPacksForbidden = (state: AccountPacksState) => ({
    ...state,
    isAccessAuthorized: false,
  });
  const resetAccountPacksForbidden = (state: AccountPacksState) => ({
    ...state,
    isAccessAuthorized: true,
  });

  return {
    [FETCH_ACCOUNT_PACKS_FORBIDDEN]: fetchAccountPacksForbidden,
    [RESET_ACCOUNT_PACKS_FORBIDDEN]: resetAccountPacksForbidden,
  };
};

const delimiter = "__";

const reducer = combineReducers({
  accountInfo,
  usersList: editAccountUsersSlice,
  addUser: addUserReducer,
  csvImport: csvImportReducer,
  contactsList: createReducer(initialContactState, [accountContactsHandler]),
  potentialContacts: potentialContactsReducer,
  addOns: createReducer(initialEntityState, [accountAddOnsHandler]),
  packsList: withFetchingChildEntityListItems<PackOverview>(
    ReducerNamespaceTypes.Accounts,
    ReducerEntityPrefixTypes.EditAccount,
    actionTypes.AccountPacks,
  ),
  packsGrid: accountPacksGridSlice,
  accountPackLicense: editAccountPackLicenseSlice,
  accountPacks: createReducer(initialPacksState, [accountPacksHandler]),
  addContactsSearch: withSearch(
    ReducerNamespaceTypes.Accounts,
    ReducerEntityPrefixTypes.EditAccount.concat(delimiter, actionTypes.Contacts),
  ),
  permissionsToAccount: permissionsToAccountReducer,
  templates: templatesReducer,
});

export type EditAccountReducer = ReturnType<typeof reducer>;

export default reducer;
