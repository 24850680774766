import { combineReducers } from "@reduxjs/toolkit";
import { type RootState } from "features/Application/globaltypes/redux";
import countriesSlice from "./countriesSlice";
import departmentsSlice from "./departmentsSlice";
import jobTitlesSlice from "./jobTitlesSlice";

const reducer = combineReducers({
  countries: countriesSlice,
  departments: departmentsSlice,
  jobTitles: jobTitlesSlice,
});

export const accountEditAddUserSelector = (state: RootState) => state.accounts.edit.addUser;

export default reducer;
