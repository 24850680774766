import { type Reducer } from "redux";
import { type WithUsersAssignmentState } from "../../../../interfaces/state/withUsersAssignment";
import * as actionTypes from "../../actions/usersAssignment/usersAssignmentActionTypes";
import { type Action, type PayloadAction } from "@reduxjs/toolkit";

const initialState: WithUsersAssignmentState = {
  userIds: [],
};

const withUsersAssignment = (namespace: string, entityPrefix: string): Reducer<WithUsersAssignmentState> => {
  return function usersAssignment(currentState: WithUsersAssignmentState | undefined, action: Action) {
    const prefix = `${namespace}__${entityPrefix}__`;
    const state = currentState || initialState;

    switch (action.type) {
      case `${prefix}${actionTypes.ASSIGN_USERS_PERMISSIONS}`: {
        return { ...state, userIds: [...(action as PayloadAction<number[]>).payload] };
      }
      case `${prefix}${actionTypes.RESET_USERS_PERMISSIONS}`: {
        return { ...initialState };
      }
      default:
        return state;
    }
  };
};

export default withUsersAssignment;
