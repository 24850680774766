import MinusButton from "components/buttons/iconButtons/minusButton/MinusButton";
import Tooltip from "components/common/tooltip/Tooltip";
import { type RolePermissions } from "enums";
import Restricted from "features/Application/Restricted";

export interface RemoveLicenseButtonProps {
  id: number;
  onRemoveLicenseClick: (id: number) => void;
  isSelected: boolean;
  issueLicensePermissionPredicate: (userPermissions: RolePermissions[]) => boolean;
}

export const RemoveLicenseButton: React.FC<RemoveLicenseButtonProps> = ({
  id,
  onRemoveLicenseClick,
  isSelected,
  issueLicensePermissionPredicate,
}) => {
  return (
    <Restricted
      permissions={[]}
      permissionPredicate={issueLicensePermissionPredicate}
      renderContent={(hasAnyPermission) => (
        <Tooltip
          position="top center"
          hideOnScroll
          showAlways
          target={<MinusButton onClick={() => onRemoveLicenseClick(id)} isDisabled={!hasAnyPermission || isSelected} />}
          content="Remove"
          disabled={!hasAnyPermission}
        />
      )}
    />
  );
};
