import * as libraryEvents from "../services/realTimeNotification/events/library/libraryEvents";

const getSurveyAsyncOperations = () => [
  libraryEvents.SurveyUpdateSuccess,
  libraryEvents.SurveyQuestionsUpdateSuccess,
  libraryEvents.SurveyPublishSuccess,
  libraryEvents.SurveyEditSuccess,
  libraryEvents.SurveyDiscardSuccess,
];

const getAssessmentAsyncOperations = () => [
  libraryEvents.AssessmentInfoEditSuccess,
  libraryEvents.AssessmentQuestionsEditSuccess,
  libraryEvents.AssessmentPublishSuccess,
  libraryEvents.AssessmentLockSuccess,
  libraryEvents.AssessmentDiscardSuccess,
];

const getEmailAsyncOperations = () => [
  libraryEvents.EmailEditSuccess,
  libraryEvents.EmailContentEditSuccess,
  libraryEvents.EmailPublishSuccess,
  libraryEvents.EmailEditModeSuccess,
  libraryEvents.EmailRevertSuccess,
];

const getEventAsyncOperations = () => [
  libraryEvents.EventInfoEditSuccess,
  libraryEvents.EventEditSessionsSuccess,
  libraryEvents.EventPublishSuccess,
  libraryEvents.ExternalEventEditModeSuccess,
  libraryEvents.EventDiscardedSuccess,
];

const getThreatDefenceCampaignAsyncOperations = () => [
  libraryEvents.CampaignEditSuccess,
  libraryEvents.CampaignContentEditSuccess,
  libraryEvents.CampaignPublishSuccess,
  libraryEvents.CampaignLockSuccess,
  libraryEvents.CampaignDiscardSuccess,
];

const getEmailTemplateAsyncOperations = () => [
  libraryEvents.EmailTemplateEditSuccess,
  libraryEvents.EmailTemplateContentEditSuccess,
  libraryEvents.EmailTemplatePublishSuccess,
  libraryEvents.EmailTemplateLockSuccess,
  libraryEvents.EmailTemplateDiscardSuccess,
];

const getFeedbackPageAsyncOperations = () => [
  libraryEvents.FeedbackPageEditSuccess,
  libraryEvents.FeedbackPagePublishSuccess,
  libraryEvents.FeedbackPageLockSuccess,
  libraryEvents.FeedbackPageDiscardSuccess,
];

const getLandingPageAsyncOperations = () => [
  libraryEvents.LandingPageEditSuccess,
  libraryEvents.LandingPageContentEditSuccess,
  libraryEvents.LandingPagePublishSuccess,
  libraryEvents.LandingPageLockSuccess,
  libraryEvents.LandingPageDiscardSuccess,
];

const getDirectSendSendingProfileAsyncOperations = () => [
  libraryEvents.DirectSendSendingProfileUpdateSuccess,
  libraryEvents.DirectSendSendingProfilePublishSuccess,
  libraryEvents.DirectSendSendingProfileLockSuccess,
  libraryEvents.DirectSendSendingProfileDiscardSuccess,
];

const getSmtpSendingProfileAsyncOperations = () => [
  libraryEvents.SmtpSendingProfileConfigEditSuccess,
  libraryEvents.SmtpSendingProfileSettingsEditSuccess,
  libraryEvents.SmtpSendingProfilePublishSuccess,
  libraryEvents.SmtpSendingProfileLockSuccess,
  libraryEvents.SmtpSendingProfileDiscardSuccess,
];

const getPackAsyncOperations = () => [
  libraryEvents.PackUpdateSuccess,
  libraryEvents.PackContentEditSuccess,
  libraryEvents.PackPublishSuccess,
  libraryEvents.PackLockSuccess,
  libraryEvents.PackDiscardSuccess,
  libraryEvents.EditAccountPackVisibilitySuccess,
  libraryEvents.EditAccountTypePackVisibilitySuccess,
  libraryEvents.EditPackIsPrivateSuccess,
];

const getVideoAsyncOperations = () => [
  libraryEvents.VideoAssetEditSuccess,
  libraryEvents.VideoAssetPublishSuccess,
  libraryEvents.LockVideoAssetSuccess,
  libraryEvents.VideoAssetsDiscardSuccess,
];

const getFlowsAsyncOperations = () => [
  libraryEvents.FlowEditSuccess,
  libraryEvents.FlowEditDefinitionSuccess,
  libraryEvents.FlowPublishSuccess,
  libraryEvents.FlowDiscardSuccess,
  libraryEvents.FlowLockSuccess,
  libraryEvents.FlowEditGoalsSuccess,
];

const getAsyncOperations = () => [
  ...getSurveyAsyncOperations(),
  ...getAssessmentAsyncOperations(),
  ...getEmailAsyncOperations(),
  ...getEventAsyncOperations(),
  ...getThreatDefenceCampaignAsyncOperations(),
  ...getEmailTemplateAsyncOperations(),
  ...getFeedbackPageAsyncOperations(),
  ...getLandingPageAsyncOperations(),
  ...getDirectSendSendingProfileAsyncOperations(),
  ...getSmtpSendingProfileAsyncOperations(),
  ...getPackAsyncOperations(),
  ...getVideoAsyncOperations(),
  ...getFlowsAsyncOperations(),
];

export default getAsyncOperations;
