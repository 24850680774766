import { find } from "lodash";

function getLabelByValue(items, value) {
  const item = find(items, { value });
  return item && item.label;
}

const videoSettingsProvider = {
  speed: {
    default: { label: "Normal", value: 1 },
    all: [
      { id: 1, label: "0.5x", value: 0.5 },
      { id: 2, label: "Normal", value: 1 },
      { id: 3, label: "1.25x", value: 1.25 },
      { id: 4, label: "1.5x", value: 1.5 },
      { id: 5, label: "2x", value: 2 },
    ],
    getLabel(value) {
      return getLabelByValue(this.all, value);
    },
    getValueById(id) {
      const item = find(this.all, { id });
      return item && item.value;
    },
  },

  quality: {
    default: { label: "720p (auto)", value: 4 },
    all: [
      { label: "720p (auto)", value: 4 },
      { label: "540p", value: 3 },
      { label: "360p", value: 1 },
      { label: "180p", value: 0 },
    ],
    getLabel(value) {
      return getLabelByValue(this.all, value);
    },
  },

  isCaptionsOn: {
    default: false,
  },
};

export default videoSettingsProvider;
