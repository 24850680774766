import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildChildEntityActionType } from "../../../../Application/actions/actionsBuilder";

const infoPrefix = "INFO";

export const FLOW_SAVE_INFO_BEGIN = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "SAVE_INFO_BEGIN",
);

export const FLOW_SAVE_INFO_SUCCESS = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "SAVE_INFO_SUCCESS",
);

export const FLOW_SAVE_INFO_PROPERTY = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "SAVE_INFO_PROPERTY",
);

export const FLOW_SAVE_INFO_FAILURE = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "SAVE_INFO_FAILURE",
);

export const FLOW_RESET_INFO = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "RESET_INFO",
);

export const FLOW_FETCH_INFO_BEGIN = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "FETCH_INFO_BEGIN",
);

export const FLOW_FETCH_INFO_SUCCESS = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "FETCH_INFO_SUCCESS",
);

export const FLOW_FETCH_INFO_FAILURE = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "FETCH_INFO_FAILURE",
);

export const FLOW_UPDATE_INFO_BEGIN = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "UPDATE_INFO_BEGIN",
);

export const FLOW_UPDATE_INFO_SUCCESS = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "UPDATE_INFO_SUCCESS",
);

export const FLOW_UPDATE_INFO_FAILURE = buildChildEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  infoPrefix,
  "UPDATE_INFO_FAILURE",
);
