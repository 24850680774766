import React, { useEffect, useState } from "react";
import { debounce, noop } from "lodash";
import { useFormik } from "formik";

import TextAreaField from "../../../../../../../components/forms/TextAreaField";

import { ValidatedForm } from "../../../../../../../components/forms";
import { type FlowEndInfo } from "../../../types";
import { getFlowEndMessage } from "../../../utils/getFlowEndMessage";

export interface FlowEndFormProps {
  flowEndInfo: FlowEndInfo;
  onFlowEndChange: (flowEndInfo: FlowEndInfo) => void;
  isReadOnly: boolean;
}

const SAVE_DELAY = 700;
const MAX_LENGTH = 120;

const flowEndSetter = (setter: (flowEndInfo: FlowEndInfo) => void, flowEndInfo: FlowEndInfo): void =>
  setter(flowEndInfo);
const debouncedHeaderSetter = debounce(flowEndSetter, SAVE_DELAY, { leading: false, trailing: true });

export const FlowEndForm = (props: FlowEndFormProps) => {
  const [initialValues] = useState(props.flowEndInfo);
  const formik = useFormik<FlowEndInfo>({
    initialValues: initialValues,
    onSubmit: noop,
  });

  const onCompletedMessageChange = async (propertyName: string, value: string, shouldValidate: boolean) => {
    const { setFieldValue } = formik;

    await setFieldValue(propertyName, value, shouldValidate);
    debouncedHeaderSetter(props.onFlowEndChange, {
      nextFlow: props.flowEndInfo.nextFlow,
      completedMessage: value.trim(),
    });
  };

  useEffect(() => {
    return () => {
      debouncedHeaderSetter.flush();
    };
  }, []);

  const renderFields = () => {
    const { values, ...rest } = formik;
    return (
      <TextAreaField
        {...rest}
        rows={"5"}
        label="Message"
        placeholder={getFlowEndMessage(props.flowEndInfo)}
        tooltip={{
          info: "We've created a default message for those who’ve finished this flow, but you can customize this message",
          width: 22,
        }}
        value={values.completedMessage || ""}
        propertyName="completedMessage"
        setFieldValue={onCompletedMessageChange}
        disabled={props.isReadOnly}
        maxLength={MAX_LENGTH}
      />
    );
  };

  return (
    <ValidatedForm disablePreventTransitionPrompt formWidthComputer={16} parentWithFormik dirty={formik.dirty}>
      {renderFields}
    </ValidatedForm>
  );
};

export default FlowEndForm;
