import { type FormikProps } from "formik";
import React from "react";
import { type SurveyContentView } from "../../types/state";
import { ValidatedForm } from "../../../../../components/forms";
import { type SurveyContentFormProps } from "../types";
import QuestionForm from "../../../Common/QuestionForm";
import { SurveyQuestionRenderer } from "./QuestionForm/SurveyQuestionRenderer";
import QuestionsBuilder from "../../../Common/QuestionsBuilder";
import { SurveyAnswersBuilder } from "./QuestionForm/SurveyAnswersBuilder";
import { SurveyQuestionHeader } from "./QuestionForm/SurveyQuestionHeader";
import validationSchemas from "../../../../../utils/validationSchemas";
import { getQuestionDefaultData } from "./QuestionForm/Providers/defaultQuestionProvider";
import { WithSelfValidation } from "../../../Common/Fields/WithSelfValidation";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import * as validationActions from "../../state/actions/surveyContentValidationActions";
import { bindActionCreators } from "redux";
import { surveysStateSelector } from "../../state/surveyReducer";
import { useFormikValidation } from "../../../Common/Hooks/useFormikValidationHook";

import { ContentContextProvider } from "./state/ContentContextProvider";

export type ContentFormPropsAll = SurveyContentFormProps & FormikProps<SurveyContentView> & PropsFromRedux;

export const ContentForm = (props: ContentFormPropsAll) => {
  const {
    isLoading,
    errors,
    activeErrors,
    validateActions,
    isFormValid,
    touchedSnapshot,
    resolved,
    initialized,
    values,
  } = props;

  useFormikValidation({ errors, resolved, initialized, activeErrors, isFormValid }, { ...validateActions });

  const renderFields = (): React.ReactNode => {
    const { onBlur, save, disabled } = props;

    return (
      <ContentContextProvider>
        <WithSelfValidation
          {...props}
          validationSchema={validationSchemas.surveysContent}
          render={(validatedFieldProps) => (
            <QuestionForm
              renderQuestionBuilder={(arrayHelpers) => (
                <QuestionsBuilder
                  defaultDataProvider={getQuestionDefaultData}
                  onBlur={onBlur}
                  onChanged={save}
                  questions={values.questions}
                  arrayHelpers={arrayHelpers}
                  validatedFieldProps={{ ...validatedFieldProps, touched: !resolved ? touchedSnapshot : {} }}
                  disabled={disabled}
                  QuestionRenderer={SurveyQuestionRenderer}
                  AnswersBuilder={SurveyAnswersBuilder}
                  QuestionHeader={SurveyQuestionHeader}
                  propertyNamePrefix={""}
                />
              )}
            />
          )}
        />
      </ContentContextProvider>
    );
  };

  return (
    <ValidatedForm
      dirty={props.dirty}
      isLoading={isLoading}
      formWidthComputer={16}
      parentWithFormik
      disablePreventTransitionPrompt
    >
      {renderFields}
    </ValidatedForm>
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    ...surveysStateSelector(state).base.surveysContentValidationReducer,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  validateActions: bindActionCreators(validationActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(ContentForm);
export default ConnectedComponent;
