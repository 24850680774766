import { type RenderActions, type StepsOptions } from "../types";

export const renderSteps = (stepsOptions: StepsOptions[], renderActions: RenderActions) => {
  const activeStepsOptions = stepsOptions.filter(({ skipStep }) => !skipStep);

  const renderActionsMap = {
    0: renderActions.renderModalActionsFirstStep,
    [activeStepsOptions.length - 1]: renderActions.renderModalActionsLastStep,
  };

  const getRenderActions = (index: number) => renderActionsMap[index] || renderActions.renderModalActionsMiddleStep;

  return activeStepsOptions.map(({ renderStep, modalActionConfig, renderCustomActions }, index) => {
    const renderStepActions = renderCustomActions
      ? renderCustomActions(modalActionConfig)
      : getRenderActions(index)(modalActionConfig);
    return renderStep(renderStepActions);
  });
};
