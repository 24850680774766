import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { batch } from "react-redux";
import { type MessageDetails } from "../../types/models";
import * as actionTypes from "../actionTypes/messageDetailsActionTypes";
import { type Dispatcher } from "../../../../../../interfaces";

export const getMessage = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getMessageBegin);
  const success = getActionProvider<MessageDetails>(actionTypes.getMessageSuccess);
  const failure = getActionProvider<Error>(actionTypes.getMessageFailure);

  return (dispatch: Function) => {
    dispatch(begin());
    dataService.messages
      .getMessageWithCancel({ id: id })
      .getDataPromise.then((result) => {
        batch(() => {
          dispatch(success(result.data));
        });
      })
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const clearMessage = () => {
  const resetMessageAction = getActionBaseProvider(actionTypes.clearMessage);

  return (dispatch: Dispatcher) => {
    dispatch(resetMessageAction());
  };
};
