import { AddButton } from "../../../../../components/buttons/iconButtons/AddButton/AddButton";

import "./remindersHeader.scss";

export interface RemindersHeaderProps {
  onAddButtonClick: () => void;
  disabled?: boolean;
}

export const RemindersHeader = (props: RemindersHeaderProps) => {
  const { onAddButtonClick, disabled } = props;
  return (
    <div className="reminders-header">
      <AddButton
        className="reminders-header__add"
        disabled={disabled}
        onClick={onAddButtonClick}
        label="Add Reminder"
        link
      />
    </div>
  );
};
