import { type Dispatcher } from "../../../../interfaces/redux";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
  makeFetchActionCreator,
  makeFetchActionCreatorForItems,
} from "../../../Application/actions/actionsBuilder";
import dataService from "../../../Application/services/dataServices/dataService";
import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import * as editGroupActionTypes from "./editGroupActionTypes";

export const fetchGroupUsersForList = (
  groupId: number,
  skip: number,
  top: number,
  orderByParams: string,
  queryParams: string,
  isAutoEnrollment: boolean,
  search?: string,
) =>
  makeFetchActionCreatorForItems(async () => {
    if (isAutoEnrollment) {
      const result = await dataService.getUsers(skip, top, orderByParams, queryParams);
      return {
        items: result.items,
        count: result.count,
      };
    }

    const { data, headers } = await dataService.getGroupMembersV2(
      groupId,
      skip,
      top,
      orderByParams,
      queryParams,
      search,
    );

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  }, editGroupActionTypes.FETCH_GROUP_USERS);

export const resetGroupUsers = makeActionCreator(editGroupActionTypes.RESET_GROUP_USERS);

export const fetchOwners = (groupId: number, queryParams: object) => {
  const begin = getActionBaseProvider(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_BEGIN);
  const success = getActionProvider<any>(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_SUCCESS);
  const failure = getActionProvider<Error>(editGroupActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const response = await dataService.getOwners(groupId, queryParams);
      const data = {
        items: response.items,
        itemsCount: response.totalCount,
      };
      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const fetchAssignedGroupOwners = (groupId: number) => {
  const begin = getActionBaseProvider(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_BEGIN);
  const success = getActionProvider<any>(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_SUCCESS);
  const failure = getActionProvider<Error>(editGroupActionTypes.FETCH_ASSIGNED_GROUP_OWNERS.FETCH_ITEMS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await dataService.getOwners(groupId);
      const data = {
        items: result.items,
        totalCount: result.itemsCount,
      };
      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const fetchGroupUsersToAdd = (
  groupIds: number[],
  skip: number,
  top: number,
  orderByParams: string,
  queryParams: string,
  search?: string,
) =>
  makeFetchActionCreator(
    () =>
      search
        ? dataService.getGroupUsersToAddV2(groupIds, skip, top, orderByParams, queryParams, search)
        : dataService.getGroupUsersToAdd(groupIds, skip, top, orderByParams, queryParams),
    editGroupActionTypes.FETCH_GROUP_USERS_TO_ADD,
  );

export const setMembersSearch = makeActionCreator(editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_SET_SEARCH, "search");
export const resetMembersSearch = makeActionCreator(editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_RESET_SEARCH);

export const setAddMembersSearch = makeActionCreator(
  editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_TO_ADD_SET_SEARCH,
  "search",
);
export const resetAddMembersSearch = makeActionCreator(
  editGroupActionTypes.PEOPLE_EDIT_GROUP_USERS_TO_ADD_RESET_SEARCH,
);
