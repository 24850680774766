import { type FilterTypesMap, FilterType } from "utils/filterUtils";
import { type PackName } from "../../../../interfaces";
import { type FlowBase } from "../../../../interfaces/flowInfo";
import { type Filters } from "../../../../utils/queryUtils";

export interface VideoOverview {
  id: number;
  title: string;
  thumbnailUrl: string;
  canBePublished: boolean;
  hasBeenPublished: boolean;
  description: string;
  durationInSeconds: number;
  isDraft: boolean;
  isPurchased: boolean;
  ownerId: number;
  publisher: string;
  dateCreated: string;
  dateModified: string;
  isLoading?: boolean;
  canBeDeleted: boolean;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
  visibility: boolean;
}

export interface ClosedCaptionsActionPayload {
  items: ClosedCaptions[];
  isLoading: boolean;
  areAllLoaded: boolean;
  selectedItems: ClosedCaptions[];
}

export interface ClosedCaptions {
  id: number;
  fileName: string;
  isoCode: string;
  languageId: number;
  isDefault: boolean;
  isAutoTranslated: boolean;
  dateModified: Date;
  dateCreated: Date;
  url: string;
}

export interface AvailableLanguage {
  id: number;
  isoCode: string;
  isAvailable: boolean;
}

export interface ClosedCaptionsValues {
  language: string;
  uploadedClosedCaptions: File;
}

export enum VideoFiltersEnum {
  Tags = "tags",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  Packs = "packIds",
  Publishers = "publisherIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const videoFilterTypes: FilterTypesMap<VideoFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
  tags: FilterType.Array,
};

export enum TagsEnumCamel {
  Label = "labels",
  SoftwareApplication = "softwareApplications",
}

export enum VideoColumns {
  Title = "Title", // NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Added = "Added",
  Modified = "Modified",
}

export interface VideoInfoPayload {
  id: number;
  title: string;
  description: string;
  fileName: string;
  thumbnailUrl?: string;
  uploadedVideos: File[];
  dateModified?: Date;
  isDraft: boolean;
  isPurchased: boolean;
  packs: PackName[];
  flows: FlowBase[];
  labels: string[];
  softwareApplications: string[];
}

export interface VideoOverviewRequest {
  skip?: number;
  top?: number;
  sortBy?: string;
  sortDirection?: string;
  appliedFilter?: Filters;
  append?: boolean;
}

export interface VideoRowNumber {
  id: number;
  count: number;
  position: number;
}
