import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../../../../../../components/common/tooltip";
import { nameofFactory } from "../../../../../../../utils/nameof";
import CheckboxField from "../../../../../Common/Fields/CheckboxField";
import { WithFormikPayload } from "../../../../../Common/Fields/WithFormikPayload";
import { type CommonComponentProps } from "../../../../../Common/props";
import { answersLimitReached } from "../../utils";
import { type ISurveyQuestion, type OtherAnswerOptions } from "../types";

export interface OtherOptionProps extends CommonComponentProps {
  question: ISurveyQuestion<any>;
}

export const OtherOptionToggle = (props: OtherOptionProps) => {
  const { validatedFieldProps, question, propertyNamePrefix, onChanged, disabled } = props;

  const optionsNameOf = nameofFactory<OtherAnswerOptions>();
  const nameof = nameofFactory<ISurveyQuestion<any>>();

  const disableOtherOption = !question.otherAnswerOptions?.includeOtherAsAnswer && answersLimitReached(question);

  const tooltipMessage = disableOtherOption
    ? 'A branching question cannot have more than 5 answers. Turn off the “Branching Question” toggle at the top to include "Other" as Answer.'
    : "";

  return (
    <div className="checkbox-field">
      <WithFormikPayload
        propertyName={`${propertyNamePrefix}${nameof("otherAnswerOptions")}.${optionsNameOf("includeOtherAsAnswer")}`}
        {...validatedFieldProps}
        render={(formikProps) => (
          <Tooltip
            target={
              <CheckboxField
                toggle
                disableValidation
                value={!!question.otherAnswerOptions?.includeOtherAsAnswer}
                disabled={disabled || disableOtherOption}
                setFieldTouched={validatedFieldProps.setFieldTouched}
                setFieldValue={validatedFieldProps.setFieldValue}
                onChange={onChanged}
                label='Include "Other" as Answer'
                {...formikProps}
              />
            }
            content={tooltipMessage}
            position="top center"
            styles={{ width: "20rem" }}
            hideOnScroll
            showAlways={!!tooltipMessage}
            test-id={"toggle-tooltip"}
          />
        )}
      />
      <Tooltip
        target={<Icon className="info circle" />}
        content={
          "You can add Other as an answer option so your respondents can provide details and or alternate answers."
        }
        position="top center"
        styles={{ width: "20rem" }}
        hideOnScroll
        showAlways
        test-id={"info-tooltip"}
      />
    </div>
  );
};
