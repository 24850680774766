import { useCallback } from "react";

import { DefaultSwitch } from "../NotifyStepContent/switches/DefaultSwitch";
import { ButtonGroup } from "../../buttonGroup";
import { type SwitchPropsConfig, type SwitchPropsData } from "../NotifyStepContent/switches/types";
import { type NotifySettings } from "../../../features/SystemNotifications/types";

import styles from "./notifyStepSwitch.module.scss";
import { tabs } from "./constants";

interface NotifyStepSwitchProps extends SwitchPropsData<NotifySettings> {
  switchProps: SwitchPropsConfig;
  labelText?: string;
  tooltipText?: string;
  hideChannelSwitch?: boolean;
}

export const NotifyStepSwitch = ({
  switchProps,
  onNotifyConfigChange,
  config,
  labelText,
  tooltipText,
}: NotifyStepSwitchProps) => {
  const handleCommunicationChannelChange = useCallback(
    (value: string) =>
      onNotifyConfigChange({
        key: "communicationChannel",
        value: tabs.findIndex((name) => name === value),
      }),
    [onNotifyConfigChange],
  );

  return (
    <div className={styles.root}>
      <DefaultSwitch
        config={config}
        onNotifyConfigChange={onNotifyConfigChange}
        labelText={labelText}
        tooltipText={tooltipText}
        {...switchProps}
      />
      <ButtonGroup
        items={tabs}
        defaultValue={tabs[config.communicationChannel]}
        isDisabled={switchProps.isDisabled || !config.shouldNotifyUsers}
        onChange={handleCommunicationChannelChange}
      />
    </div>
  );
};
