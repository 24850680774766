import React from "react";

import CroppedThumbnail from "../../croppedThumbnail/CroppedThumbnail";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";

import styles from "./priorityLevelThumbnail.module.scss";

interface Props {
  thumbnailUrl: string;
  title: string;
}

export const PriorityLevelThumbnail: React.FC<Props> = (props) => {
  const { thumbnailUrl, title } = props;
  return (
    <CroppedThumbnail thumbnailUrl={thumbnailUrl} className={styles.thumbnailContainer}>
      <div className={styles.title}>
        <Tooltip className="container" content={title} target={<TextTruncate lines={2}>{title}</TextTruncate>} />
      </div>
    </CroppedThumbnail>
  );
};
