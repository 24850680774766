import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

import styles from "./addToPacksLinkButton.module.scss";

export interface AddToPacksLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  after?: React.ReactNode;
  iconClassName?: string;
}

export function AddToPacksLinkButton(props: AddToPacksLinkButtonProps) {
  const { onClick, isDisabled, after, iconClassName = "fa-plus-circle" } = props;

  return (
    <div className={styles.root}>
      <BasicLinkButton text="Add To Packs" iconClassName={iconClassName} onClick={onClick} isDisabled={isDisabled} />
      {after}
    </div>
  );
}
