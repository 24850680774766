import { type ColumnOptions } from "interfaces";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 7,
    isSortable: true,
  },
  {
    name: "Owners",
    width: 6,
    isSortable: false,
  },
  {
    name: "Members",
    width: 2,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];
