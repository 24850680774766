import React, { useState } from "react";
import WeekDayButton from "./WeekDayButton";
import { type WeekDays } from "../../enums/weekDays";
import classNames from "classnames";
import { concat, filter, some } from "lodash";
import { defaultWeekDayOrder, dayOfWeekMap } from "./constants";

import "./weekDayPicker.scss";

export interface WeekDayPickerProps {
  selectedPredicate?: (weekDay: WeekDays) => boolean;
  order?: WeekDays[];
  classname?: string;
  onChange?: (weekDays: number[]) => void;
  disabled?: boolean;
  name?: string;
  minSelectionCount?: 0 | 1;
}

const WeekDayPicker: React.FC<WeekDayPickerProps> = ({
  classname,
  onChange,
  selectedPredicate,
  disabled,
  minSelectionCount = 0,
  order = defaultWeekDayOrder,
}): React.ReactElement => {
  const [selectedWeekDays, setSelectedWeekDays] = useState<WeekDays[]>(order.filter((x) => selectedPredicate?.(x)));

  const handleToggle = (weekDay: WeekDays) => {
    if (disabled) {
      return;
    }
    let selection = some(selectedWeekDays, (x) => x === weekDay)
      ? filter(selectedWeekDays, (x) => x !== weekDay)
      : concat(selectedWeekDays, weekDay);

    if (minSelectionCount > selection.length) {
      return;
    }
    setSelectedWeekDays(selection);
    const mapped = selection.map((wd) => dayOfWeekMap[wd]);
    onChange?.(mapped);
  };

  const getWeekDayButtons = () =>
    order.map((day) => (
      <WeekDayButton
        disabled={disabled}
        key={day}
        weekDay={day}
        onToggle={handleToggle}
        selected={selectedWeekDays.some((x) => x === day)}
      />
    ));

  return <div className={classNames("weekday-picker", classname)}>{getWeekDayButtons()}</div>;
};

export default WeekDayPicker;
