import PropTypes from "prop-types";
import { TextArea } from "semantic-ui-react";

import ValidatedField from "./ValidatedField";

export default function TextAreaField(props) {
  const {
    value,
    placeholder,
    propertyName,
    setFieldValue,
    handleBlur,
    handleChange,
    nullable,
    onBlur,
    disabled,
    rows,
    maxLength,
  } = props;

  let initialValue = value;
  const handleFieldChange = (event, data) => {
    const fieldValue = data.value;
    if (nullable && fieldValue === "") {
      setFieldValue(propertyName, null, true);
    } else {
      setFieldValue(propertyName, fieldValue, true);
    }
    handleChange && handleChange(event, data);
  };

  const handleFieldBlur = (e) => {
    handleBlur(e);
    onBlur && onBlur(propertyName);
  };

  if (nullable && value === null) {
    initialValue = "";
  }

  return (
    <ValidatedField {...props}>
      <TextArea
        rows={rows}
        maxLength={maxLength}
        name={propertyName}
        value={initialValue}
        placeholder={placeholder}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        disabled={disabled}
      />
    </ValidatedField>
  );
}

TextAreaField.defaultProps = {
  nullable: false,
  disabled: false,
};

TextAreaField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  propertyName: PropTypes.string,
  nullable: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.string,
  tooltip: PropTypes.shape({
    info: PropTypes.string,
    width: PropTypes.number,
  }),

  // validated field props, to be replaced with type definition from Formik
  markAsRequired: PropTypes.bool,
  errorPosition: PropTypes.string,

  // formik props
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
};
