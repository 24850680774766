import { type BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import {
  type MeetingLineData,
  type MeetingTotalData,
  type FilesLineData,
  type FilesTotalData,
  type CollaborationLineData,
  type CollaborationTotals,
} from "../types/google";
import axios from "axios";

export const googleReportEndpoints = {
  meetingLineChart: "/api/reports/v2/google/meetings/linechart",
  meetingTotals: "/api/reports/v2/google/meetings/totals",
  filesLineChart: "/api/reports/v2/google/files/linechart",
  filesTotals: "/api/reports/v2/google/files/totals",
  collaborationLineChart: "/api/reports/v2/google/collaboration/linechart",
  collaborationTotals: "/api/reports/v2/google/collaboration/totals",
};

type PerformanceFilterWithPeriod = BasePerformanceRequestFilterParams & { period: string };

export const fetchCollaborationLineChartData = (params: PerformanceFilterWithPeriod) => {
  return axios.get<CollaborationLineData[]>(googleReportEndpoints.collaborationLineChart, { params });
};

export const fetchCollaborationTotalsData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<CollaborationTotals>(googleReportEndpoints.collaborationTotals, { params });
};

export const fetchMeetingLineChartData = (params: PerformanceFilterWithPeriod) => {
  return axios.get<MeetingLineData[]>(googleReportEndpoints.meetingLineChart, {
    params,
  });
};

export const fetchMeetingTotalsData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<MeetingTotalData>(googleReportEndpoints.meetingTotals, { params });
};

export const fetchFilesLineChartData = (params: PerformanceFilterWithPeriod) => {
  return axios.get<FilesLineData[]>(googleReportEndpoints.filesLineChart, {
    params,
  });
};

export const fetchFilesTotalsData = (params: BasePerformanceRequestFilterParams) => {
  return axios.get<FilesTotalData>(googleReportEndpoints.filesTotals, {
    params,
  });
};
