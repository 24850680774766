import { DetailsSubHeader } from "../../../../../components/sectionHeader";
import { type Props as DetailsSubHeaderProps } from "../../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { connect } from "react-redux";

const mapStateToProps = (state: RootState, ownProps: DetailsSubHeaderProps) => ({
  isUpdateInProgress:
    state.library.emails.emailEntityStateReducer.isEntityCommandInProgress && ownProps.isUpdateInProgress,
  lastModifiedDateTime:
    state.library.emails.emailEntityStateReducer.lastModifiedDateTime ?? ownProps.lastModifiedDateTime,
});

export default connect(mapStateToProps)(DetailsSubHeader);
