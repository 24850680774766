import { bool } from "prop-types";

import ProgressTimestamp from "./ProgressTimestamp";
import { progressTimestampPropTypes } from "./progressTimestampPropTypes";

import "./progressSaved.scss";

function ProgressSaved(props) {
  const { timestampLabel, inProgress } = props;

  return (
    <div className="progress-saved progress-saved-edit animation">
      {inProgress ? (
        <div className="progress-saved-text">
          <div className="saving-title">Saving...</div>
        </div>
      ) : (
        <ProgressTimestamp timestampLabel={timestampLabel} />
      )}
    </div>
  );
}

ProgressSaved.propTypes = {
  inProgress: bool,
  ...progressTimestampPropTypes,
};

export default ProgressSaved;
