import * as actionTypes from "../actionTypes/eventsOverviewActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { type EventOverview, type EventsOverviewState, type FetchEventsActionPayload } from "../../types/state";
import { type PayloadAction } from "../../../../../interfaces/redux";

const defaultLoadItemsCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);
const initialState: EventsOverviewState = {
  events: [],
  isLoading: false,
  totalCount: 0,
  isAllLoaded: false,
  error: undefined,
};

const eventsOverviewHandlers = () => {
  const { getEventsBegin, getEventsFailure, getEventsSuccess, resetEvents } = actionTypes;

  const getEventsBeginHandler = (state: EventsOverviewState, action: PayloadAction<boolean>): EventsOverviewState => {
    return {
      ...state,
      isLoading: true,
      totalCount: 0,
      events: action.payload ? state.events : new Array<EventOverview>(),
    };
  };

  const getEventsSuccessHandler = (
    state: EventsOverviewState,
    action: PayloadAction<FetchEventsActionPayload<EventOverview>>,
  ): EventsOverviewState => {
    let currentItems = state.events;
    let eventsBatch = action.payload.items;
    let allEvents = [...currentItems, ...eventsBatch];
    let isAllLoaded = eventsBatch.length < defaultLoadItemsCount;

    return {
      ...state,
      isLoading: false,
      isAllLoaded: isAllLoaded,
      events: action.payload.concatItems ? allEvents : eventsBatch,
      totalCount: action.payload.totalCount,
    };
  };

  const getEventsFailureHandler = (state: EventsOverviewState, action: PayloadAction<Error>): EventsOverviewState => ({
    ...state,
    isLoading: false,
    isAllLoaded: false,
    error: action.payload,
  });

  const resetExternalEvents = (state: EventsOverviewState): EventsOverviewState => {
    return {
      ...state,
      events: [],
      totalCount: 0,
    };
  };

  return {
    [getEventsBegin]: getEventsBeginHandler,
    [getEventsSuccess]: getEventsSuccessHandler,
    [getEventsFailure]: getEventsFailureHandler,
    [resetEvents]: resetExternalEvents,
  };
};

export const eventsOverviewReducer = createReducer(initialState, [eventsOverviewHandlers]);
