import React from "react";
import NoResults from "../../../components/noResults/NoResults";
import NoSearchResults from "../../../components/noSearchResults";
import { type PacksNoResultsProps } from "../types";

function PacksNoResults(props: PacksNoResultsProps) {
  const description = (
    <span>
      Looks like there are no packs yet.
      <br />
      {props.createButton ? "Add some using the button below." : ""}
    </span>
  );

  return props.filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults title="There are no packs" description={description} iconClassName="fa-box-full">
      {props.createButton}
    </NoResults>
  );
}

export default PacksNoResults;
