import React, { Component } from "react";
import { Label } from "semantic-ui-react";
import FormSectionHeader from "../../../../components/forms/FormSectionHeader";
import ContactList from "./ContactList/ContactList";
import AddContact from "./AddContact/AddContact";
import type ValidatedFormProps from "../../../../components/forms/validatedFormProps";
import { type GlobalRole } from "../../../../interfaces/role";
import type NewContact from "../../../../interfaces/NewContact";
import { forEach, remove } from "lodash";

import "./Contacts.scss";

export default class Contacts extends Component<ContactsProps> {
  static defaultProps = {
    contacts: [],
    isLoadingGlobalRoles: false,
  };

  componentDidMount() {
    const { accountTypeId, onFetchGlobalRoles } = this.props;
    onFetchGlobalRoles(accountTypeId);
  }

  assignMainContactRoles(contact: NewContact) {
    const mainContactRolesToAssign = this.props.globalRoles.filter(
      (role) => role.isMainContactRole && !contact.roles.some((assignedRole) => assignedRole.id === role.id),
    );
    contact.autoAssignedRoleIds = mainContactRolesToAssign.map((role) => role.id);
    contact.roles = [...contact.roles, ...mainContactRolesToAssign];
  }

  addContact = (contact: NewContact) => {
    const newContact = { ...contact, id: this.props.contacts.length, isMain: false };

    if (this.props.contacts.length === 0) {
      this.assignMainContactRoles(newContact);
      newContact.isMain = true;
    }

    const newContacts = [...this.props.contacts, newContact];

    this.notifyContactsChanged(newContacts);
  };

  removeContact = (contactId: number) => {
    const newContacts = this.props.contacts.filter((c) => c.id !== contactId);
    forEach(newContacts, (contact, index) => (contact.id = index));

    this.notifyContactsChanged(newContacts);
  };

  setMainContact = (contactId: number) => {
    const newMain = this.props.contacts.find((c) => c.id === contactId);
    newMain!.isMain = true;
    this.assignMainContactRoles(newMain!);

    const oldMain = this.props.contacts.find((c) => c.id !== contactId && c.isMain);
    if (oldMain) {
      oldMain.isMain = false;
      remove(oldMain.roles, (role) => oldMain.autoAssignedRoleIds.some((id) => id === role.id));
      oldMain.autoAssignedRoleIds = [];
    }

    this.notifyContactsChanged(this.props.contacts);
  };

  notifyContactsChanged = (contacts: any) => {
    const { onContactsChanged } = this.props;

    if (onContactsChanged) {
      onContactsChanged(contacts);
    }
  };

  isBadgeDisplayed = () => this.props.contacts.length > 0;

  renderBadge = () => {
    if (this.isBadgeDisplayed()) {
      return (
        <Label circular color="blue" className="badge">
          {this.props.contacts.length}
        </Label>
      );
    }

    return null;
  };

  render() {
    const { contacts, globalRoles, isLoadingGlobalRoles } = this.props;

    return (
      <section className="contact-form">
        <FormSectionHeader title="Add contacts" />
        <AddContact
          contacts={contacts}
          onContactAdded={this.addContact}
          globalRoles={globalRoles}
          isLoadingGlobalRoles={isLoadingGlobalRoles}
        />
        <div className="contacts-overview">
          <div className="title-container">
            <FormSectionHeader title="Contacts" />
            {this.renderBadge()}
          </div>
          <ContactList
            contacts={contacts}
            onContactRemoved={this.removeContact}
            onMainContactSet={this.setMainContact}
          />
        </div>
      </section>
    );
  }
}

export interface ContactsProps extends ValidatedFormProps {
  contacts: NewContact[];
  onContactsChanged: (contacts: Array<NewContact>) => void;
  globalRoles: GlobalRole[];
  accountTypeId: number;
  isLoadingGlobalRoles: boolean;
  onFetchGlobalRoles(accountTypeId: number): void;
}
