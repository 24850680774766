import React, { useEffect, useRef, useState } from "react";
import { isEmpty, some, filter, difference, intersection } from "lodash";
import { Button } from "components/buttons/button/Button";

import ModalWithSteps from "../../modal/ModalWithSteps";
import { ConfirmLicensingStep, NotifyStep } from "../commonSteps";
import { GroupListStep } from "./groupAssignmentSteps";
import groupListUtils from "../../../utils/groupListUtils";
import { type TemplateTypes, type SortingDirection, RolePermissions, CommunicationChannels } from "../../../enums";
import { Awareness, Enrollment } from "../../../enums/groups";
import { type Group, type NotifyStepSettings } from "../../../interfaces";
import { Context } from "enums/licenseContext";
import useNotifyConfig from "../../../features/SystemNotifications/hooks/useNotifyConfig";
import { initialNotifyConfigDefault } from "../../../features/SystemNotifications/config";
import { NotifyStepSwitch } from "../../notifyStep/NotifyStepSwitch";

import "./groupsAssignmentModal.scss";

export interface UserGroupsAssignment {
  userId: number;
  groupIds: number[];
}

interface Common {
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (notifyStepSettings?: NotifyStepSettings) => void;
  onGroupsSelected: (groupIds: number[]) => Promise<boolean>;
  selectedUserIds: number[];
  isStepLoading?: boolean;
}

interface GroupsStep {
  accountId: number;
  fetchGroups: (skip: number, top: number, orderParams: any, filterParams: any) => void | any;
  groups: Group[] | any;
  groupsCount: number;
  userGroupsAssignments: UserGroupsAssignment[];
  filterOptions: any;
  fetchFilterOptions: (accountId: number) => void;
  isGroupsLoading: boolean;
  notifyStepDisabled?: boolean;
  notifyTemplateType: TemplateTypes;
}

const initNotifyConfig = {
  ...initialNotifyConfigDefault,
  shouldNotifyUsers: false,
};

export type GroupsAssignmentModalProps = Common & GroupsStep;

const GroupsAssignmentModal = (props: GroupsAssignmentModalProps) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [issuingUsers, setIssuingUsers] = useState<number[]>([]);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [isNotifyStepValid, setIsNotifyStepValid] = useState(true);
  const onPreviousNotifyStepRef = useRef<() => Promise<void>>();
  const getNotifySettingsRef = useRef<() => Promise<NotifyStepSettings | undefined>>();
  const [notifyConfig, { setNotifyConfig, resetNotifyConfig, shouldNotify, communicationChannel }] =
    useNotifyConfig(initNotifyConfig);

  const [skipLicensingStep, setSkipLicensingStep] = useState(false);

  useEffect(() => {
    setSelectedGroupIds([]);
    setIssuingUsers([]);
    setAppliedFilter({});
  }, [props.showModal]);
  const [allGroupsAware, setGroupsAware] = useState(true);

  useEffect(() => {
    const selectedGroups = (props.groups as Group[]).filter((g) => selectedGroupIds.includes(g.id));
    const newValue = selectedGroups.every((g) => g.isAware === Awareness.Aware);
    setGroupsAware(newValue);
  }, [selectedGroupIds, props.groups, setGroupsAware]);

  useEffect(() => {
    !props.showModal && resetNotifyConfig();
  }, [props.showModal, resetNotifyConfig]);

  const onSelectedGroupsChanged = (groupIds: number[]) => {
    const filteredIssuingUsers = filter(props.selectedUserIds, (userId) =>
      some(filter(props.userGroupsAssignments, { userId }), (x: UserGroupsAssignment) =>
        some(difference(groupIds, x.groupIds)),
      ),
    );
    setSelectedGroupIds(groupIds);
    setIssuingUsers(filteredIssuingUsers);
  };

  const renderGroupListStepActions = (nextStep: Function) => (closeModal: Function) => (
    <>
      <Button
        basic
        color="blue"
        className="cancel"
        content="Cancel"
        onClick={() => {
          closeModal();
          props.onCancel();
        }}
      />
      <Button
        blur
        primary
        className="next"
        content="Add"
        onClick={async () => {
          if (issuingUsers.length) {
            const shouldShowModal = await props.onGroupsSelected(selectedGroupIds);
            setSkipLicensingStep(!shouldShowModal);
            nextStep();
          } else {
            props.onConfirm();
            closeModal();
          }
        }}
        disabled={isEmpty(selectedGroupIds) || props.isStepLoading}
      />
    </>
  );

  const renderConfirmLicensingStepActions = (nextStep: Function, prevStep: Function) => (_: Function) => (
    <>
      <Button blur primary content="Previous" className="previous" onClick={() => prevStep()} />
      <Button
        blur
        primary
        className="next"
        content="Next"
        disabled={!isValid}
        onClick={() => {
          setIsValid(false);
          setIsDataLoaded(false);
          nextStep();
        }}
      />
    </>
  );

  const renderNotifyStepActions = (_: Function, prevStep: Function) => (closeModal: Function) => (
    <>
      <Button
        blur
        primary
        content="Previous"
        className="previous"
        onClick={() => {
          onPreviousNotifyStepRef?.current?.();
          prevStep();
        }}
      />
      <Button
        primary
        className="confirm"
        content="Finish"
        disabled={
          shouldNotify &&
          ((communicationChannel === CommunicationChannels.Email && !isDataLoaded) || !isNotifyStepValid)
        }
        onClick={async () => {
          const notifySettingsRef = await getNotifySettingsRef?.current?.();
          props.onConfirm(notifySettingsRef);
          closeModal();
        }}
      />
    </>
  );

  const fetchGroups = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    groupsFilter: any,
  ) => {
    const orderParams = !isEmpty(sortingColumnName)
      ? groupListUtils.formatOrderParams(sortingColumnName, sortingDirection)
      : null;

    const filterParams = groupListUtils.formatFilterParams({
      ...groupsFilter,
      IsWithAutoEnroll: Enrollment.Manual,
    });

    props.fetchGroups(skip, top, orderParams, filterParams);
  };

  return (
    <ModalWithSteps
      className={"groups-assignment-modal"}
      scrolling
      renderTrigger={props.renderTrigger}
      showModal={props.showModal}
      onCancel={props.onCancel}
      isLoading={props.isStepLoading}
    >
      <GroupListStep
        accountId={props.accountId}
        header="Add to Group"
        renderModalActions={renderGroupListStepActions}
        loadPage={fetchGroups}
        isLoading={props.isGroupsLoading}
        groups={props.groups}
        groupsAmount={props.groupsCount}
        userGroupsAssignments={props.userGroupsAssignments}
        onSelectedGroupsChanged={onSelectedGroupsChanged}
        selectedGroupIds={selectedGroupIds}
        selectedUserIds={props.selectedUserIds}
        filterOptions={props.filterOptions}
        fetchFilterOptions={props.fetchFilterOptions}
        filter={appliedFilter}
        applyFilter={setAppliedFilter}
        resetFilter={() => setAppliedFilter({})}
        hasPermissionPredicate={(userPermissions) =>
          intersection(userPermissions, [RolePermissions.GroupsView]).length > 0
        }
      />
      {!skipLicensingStep && (
        <ConfirmLicensingStep
          header="License Confirmation"
          renderModalActions={renderConfirmLicensingStepActions}
          onIsDataValidChange={setIsValid}
          info={{
            groupIds: selectedGroupIds,
            userIds: props.selectedUserIds,
          }}
          context={Context.AddPeopleToGroups}
        />
      )}

      <NotifyStep
        preRender
        header="Notify"
        renderModalActions={renderNotifyStepActions}
        onIsDataValidChange={setIsNotifyStepValid}
        onIsDataLoaded={setIsDataLoaded}
        isDataLoaded={isDataLoaded}
        onPreviousNotifyStepRef={onPreviousNotifyStepRef}
        getNotifySettingsRef={getNotifySettingsRef}
        notifyStepDisabled={props.notifyStepDisabled || !allGroupsAware}
        notifyTemplateType={props.notifyTemplateType}
        renderSwitch={(switchProps) => (
          <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />
        )}
        shouldNotify={shouldNotify}
        communicationChannel={communicationChannel}
      />
    </ModalWithSteps>
  );
};

export default GroupsAssignmentModal;
