import cn from "classnames";
import { type FC } from "react";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import CardContent from "../cardContent/CardContent";
import type Icon from "../../../interfaces/icon";
import SelectableCard from "../selectableCard/SelectableCard";
import { PackStatusLabelWithIcon } from "../../packs/packStatusLabelWithIcon/PackStatusLabelWithIcon";
import { PackPublisherInfo } from "../../packs/packPublisherInfo/PackPublisherInfo";
import { type CardItemBase } from "../../../interfaces";
import { PeoplePackMenu } from "features/Licensing/Packs/shared/PeoplePackMenu/PeoplePackMenu";
import Restricted from "features/Application/Restricted";
import { Tooltip } from "components/common/tooltip";
import { type RolePermissions } from "enums";
import styles from "./peoplePackCard.module.scss";

export interface PeoplePackCardItem extends CardItemBase {
  thumbnailImageUrl: string;
  description: string;
  logoUri: string;
  isExpired: boolean;
  isTrial: boolean;
  isOwn: boolean;
}

export interface PeoplePackCardHandlersProps {
  url?: string;
  onRemoveLicenseClick: (id: number) => void;
  licenseRemovalEnabled: boolean;
  issueLicensePermissionPredicate: (userPermissions: RolePermissions[]) => boolean;
}

export interface PeoplePackCardProps extends PeoplePackCardHandlersProps {
  item: PeoplePackCardItem;
  selected: boolean;
  disabled: boolean;
  onSelect: (id: number) => void;
  icons?: Icon[];
}

export const PeoplePackCard: FC<PeoplePackCardProps> = (props: PeoplePackCardProps) => {
  const {
    selected,
    onSelect,
    url,
    icons,
    disabled,
    item,
    licenseRemovalEnabled,
    onRemoveLicenseClick,
    issueLicensePermissionPredicate,
  } = props;
  const { id, thumbnailImageUrl, title, description, publisher, isExpired, isTrial, isOwn } = item;

  return (
    <SelectableCard
      id={id}
      url={url}
      className={cn(styles["people-pack-card"])}
      selected={selected}
      onSelect={onSelect}
      disabled={disabled}
    >
      <CardThumbnail
        thumbnailUrl={thumbnailImageUrl}
        selectable={!!licenseRemovalEnabled}
        selected={selected}
        disabled={disabled}
      />
      <CardContent title={title} icons={icons} preHeader={<PackPublisherInfo publisherName={publisher} />}>
        <div className={styles["description-row"]}>{description}</div>
        <div className={styles["bottom-row"]}>
          <PackStatusLabelWithIcon
            isPurchased
            isRequested={false}
            isExpired={isExpired}
            isTrial={isTrial}
            isOwn={isOwn}
          />
          {licenseRemovalEnabled && (
            <Restricted
              permissions={[]}
              permissionPredicate={issueLicensePermissionPredicate}
              renderContent={(hasAnyPermission) => (
                <Tooltip
                  showAlways
                  target={
                    <PeoplePackMenu
                      id={id}
                      disabled={selected}
                      onRemoveLicenseClick={onRemoveLicenseClick}
                      hasLicensingPermissions={hasAnyPermission}
                    />
                  }
                  position={"top center"}
                  content={
                    "You don’t have permission to perform this action. Contact your administrator for more information."
                  }
                  disabled={hasAnyPermission}
                />
              )}
            />
          )}
        </div>
      </CardContent>
    </SelectableCard>
  );
};
