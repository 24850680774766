import { type FormEvent, type RefObject, useEffect, useReducer, useRef, useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import axios from "axios";
import { SpeechBubble } from "../SpeechBubble/SpeechBubble";
import { chatService } from "../services";
import { apiReducer } from "../reducers/apiReducer";

import styles from "./ChatWindow.module.scss";
import { transitionReducer } from "../reducers/transitionReducer";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";

interface Props {
  open: boolean;
  setAnswered: (a: boolean) => void;
  transitionRef: RefObject<HTMLDivElement>;
}

export const ChatWindow = ({ setAnswered, open, transitionRef }: Props) => {
  const inputRef = useRef<Input>(null);
  const [input, setInput] = useState("");
  const [apiState, apiDispatch] = useReducer(apiReducer, {
    status: "idle",
    data: null,
    error: null,
    userText: null,
  });
  const [animState, animDispatch] = useReducer(transitionReducer, {
    heightTransitionFinished: false,
    widthTransitionFinished: false,
    allTransitionsFinished: false,
  });

  // Ensure width does not transition when reopening chat window
  useEffect(() => () => setAnswered(false), [setAnswered]);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
      setAnswered(false);
    } else {
      apiDispatch({ type: "reset" });
      animDispatch({ type: "RESET_TRANSITIONS" });
      setInput("");
    }
  }, [setAnswered, open]);

  useEffect(() => {
    const localTransitionRef = transitionRef.current;
    const transitionEndHandler = (e: TransitionEvent) => {
      if (e.propertyName === "width") {
        animDispatch({ type: "SET_WIDTH_TRANSITION_FINISHED" });
      }
      if (e.propertyName === "grid-template-rows") {
        animDispatch({ type: "SET_HEIGHT_TRANSITION_FINISHED" });
      }
    };
    localTransitionRef?.addEventListener("transitionend", transitionEndHandler);

    return () => {
      localTransitionRef?.removeEventListener("transitionend", transitionEndHandler);
    };
  }, [apiState.status, transitionRef]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.trim() === "") return;

    apiDispatch({ type: "loading", payload: input });
    // Prevents width transition from hanging around after user clears their chat
    animDispatch({ type: "RESET_TRANSITIONS" });
    try {
      const data = await chatService.getChatResponse(input);
      apiDispatch({ type: "success", payload: data });
      setAnswered(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        apiDispatch({ type: "error", payload: err.response?.data?.message ?? "An error occurred" });
      } else {
        apiDispatch({ type: "error", payload: "An error occurred" });
      }
    }
  };

  const clear = () => {
    apiDispatch({ type: "reset" });
    animDispatch({ type: "RESET_TRANSITIONS" });
    setInput("");
    setAnswered(false);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const displayText = animState.allTransitionsFinished;

  return (
    <>
      <div className={styles.chatWindowHeader}>
        <SpeechBubble from="bot">Hi! How can I help?</SpeechBubble>
        {apiState.userText && (
          <SpeechBubble from="user">
            <Tooltip target={<TextTruncate>{apiState.userText}</TextTruncate>} content={apiState.userText} />
          </SpeechBubble>
        )}
        {apiState.status !== "idle" && (
          <SpeechBubble from="bot" botAnswer verticallyTransitioning={animState.widthTransitionFinished}>
            {apiState.status === "loading" && <div className={styles.dotTyping} />}
            {apiState.status === "fetched" && (
              <div className={displayText ? styles.visible : styles.hidden}>{apiState.data}</div>
            )}
            {apiState.status === "error" && (
              <div className={displayText ? styles.visible : styles.hidden}>{apiState.error}</div>
            )}
          </SpeechBubble>
        )}
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        {(apiState.status === "fetched" || apiState.status === "error") && (
          <button className={styles.clearButton} tabIndex={-1} title="Clear" onClick={clear} type="button">
            <Icon className={styles.clearIcon} name="chat" fitted size="large" />
          </button>
        )}
        <Input
          fluid
          ref={inputRef}
          onChange={(_e, { value }) => setInput(value)}
          value={input}
          disabled={apiState.status !== "idle"}
          placeholder="Type your message..."
          className={styles.input}
        />
      </form>
    </>
  );
};
