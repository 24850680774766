export const rules: any = {
  "/licensing/packs/": {
    "/": true, // configure
    "/performance": false, // performance
    "/content": true, // content
    "/visibility": true, // visibility
  },
  "/content/flows/": {
    "/": false, // performance
    "/configuration": true, // configuration
    "/settings": false, // settings
    "/items": true, // content
    "/users": false, // people
    "/goal": true, // goal
  },
  "/content/events/": {
    "/": false, // performance
    "/configure": true, // configuration
    "/settings": false, // settings
    "/sessions": true, // details
    "/reminders": true, // reminders
    "/people": false, // people
  },
  "/content/communications/emails/": {
    "/": false, // performance
    "/configuration": true, // configure
    "/content": true, // content
  },
  "/content/communications/messages/": {
    "/configuration": true, // configure
    "/content": true, // content
  },
  "/content/assets/videos/": {
    "/": false, // performance
    "/configuration": true, // configuration
    "/settings": false, // settings
    "/closed-captions": true, // closed captions
    "/users": false, // people
  },
  "/content/assets/surveys/": {
    "/": false, // performance
    "/configuration": true, // configuration
    "/settings": false, // settings
    "/content": true, // content
    "/people": false, // people
  },
  "/content/assets/assessments/": {
    "/": false, // performance
    "/configuration": true, // configuration
    "/settings": false, // settings
    "/content": true, // content
    "/people": false, // people
  },
  "/content/assets/pdfs/": {
    "/": false, // performance
    "/configuration": true, // configuration
    "/settings": false, // settings
    "/people": false, // people
  },
};

export const toggleEntityStateButtonsVisibility = (entityId: string, pathName: string): boolean | null => {
  if (!entityId || !pathName) {
    return null;
  }

  const [mainRoute, subRoute] = pathName.split(entityId);

  if (!rules[mainRoute]) {
    return null;
  }

  return rules[mainRoute][subRoute || "/"];
};
