import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export interface TagsPayload {
  [key: string]: { id: number; title: string }[];
}

export interface VideoBaseState {
  tags: TagsPayload;
  error?: Error;
}

const initialState: VideoBaseState = {
  tags: {},
  error: undefined,
};

const videoBaseSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Videos}/base`,
  initialState: initialState,
  reducers: {
    fetchTags(state: VideoBaseState, action: PayloadAction<TagsPayload>) {
      state.tags = action.payload;
    },
    fetchTagsFailure(state: VideoBaseState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    resetTags: (state: VideoBaseState) => {
      state.tags = initialState.tags;
    },
  },
});

export const { fetchTags, fetchTagsFailure, resetTags } = videoBaseSlice.actions;

export default videoBaseSlice.reducer;
export type videoBaseSliceStateType = ReturnType<typeof videoBaseSlice.reducer>;
