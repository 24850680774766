import { combineReducers } from "@reduxjs/toolkit";

import { withFetchingChildEntityListItems } from "../../Application/reducers/hoc/withFetchingItems";
import * as actionTypes from "./ContentAssignmentActionTypes";
import ReducerEntityPrefixTypes from "../../../enums/reducer/reducerEntityPrefixTypes";
import { withFetchingEntityGridItems } from "../../Application/reducers/hoc/withFetchingItemsLazy";
import ReducerNamespaceTypes from "../../../enums/reducer/reducerNamespaceTypes";
import {
  type AssignmentModelItemBase,
  type IVideoAssignmentModelItem,
  type IEventAssignmentModelItem,
  type IFlowAssignmentModelItem,
  type IPdfAssignmentModelItem,
} from "../types";

const namespace = ReducerNamespaceTypes.People;

export const contentAssignmentReducer = combineReducers({
  assetList: withFetchingChildEntityListItems<IVideoAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Assets,
  ),
  assetGrid: withFetchingEntityGridItems<IVideoAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.AssetsLazy,
  ),
  flowList: withFetchingChildEntityListItems<IFlowAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Flows,
  ),
  flowGrid: withFetchingEntityGridItems<IFlowAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.FlowsLazy,
  ),
  eventList: withFetchingChildEntityListItems<IEventAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Events,
  ),
  eventGrid: withFetchingEntityGridItems<IEventAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.EventsLazy,
  ),
  surveyList: withFetchingChildEntityListItems<AssignmentModelItemBase>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Surveys,
  ),
  assessmentList: withFetchingChildEntityListItems<AssignmentModelItemBase>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Assessments,
  ),
  pdfList: withFetchingChildEntityListItems<IPdfAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.Pdfs,
  ),
  pdfGrid: withFetchingEntityGridItems<IPdfAssignmentModelItem>(
    namespace,
    ReducerEntityPrefixTypes.Content,
    actionTypes.PdfsLazy,
  ),
});

export type ContentAssignmentState = ReturnType<typeof contentAssignmentReducer>;
