import * as filterActionTypes from "./filterActionTypes";
import { makeActionCreator } from "../../../Application/actions/actionsBuilder";
import { fetchFilterRolesOptions } from "./fetchFilterRolesOptions";

export const setRolesFilter = makeActionCreator(filterActionTypes.ROLES_SET_ITEMS_FILTER, "filter");

export const resetRolesFilter = makeActionCreator(filterActionTypes.ROLES_RESET_ITEMS_FILTER);

export const fetchFilterOptions = () =>
  fetchFilterRolesOptions(
    filterActionTypes.ROLES_FILTER_OPTIONS_FETCH_BEGIN,
    filterActionTypes.ROLES_FILTER_OPTIONS_FETCH_SUCCESS,
    filterActionTypes.ROLES_FILTER_OPTIONS_FETCH_FAILURE,
  );
