import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { getRootEntityActionTypeBuilder } from "../../../../../Application/actions/actionsBuilder";
import * as actionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

const emailsBuilder = getRootEntityActionTypeBuilder(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Emails);

export const createEmailBegin = emailsBuilder(actionTypes.CREATE_DRAFT_ENTITY_BEGIN);

export const createEmailSuccess = emailsBuilder(actionTypes.CREATE_DRAFT_ENTITY_SUCCESS);

export const createEmailFailure = emailsBuilder(actionTypes.CREATE_DRAFT_ENTITY_FAILURE);

export const updateEmailBegin = emailsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN);

export const updateEmailCommandSuccess = emailsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS);

export const updateEmailFailure = emailsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE);

export const emailUpdated = emailsBuilder(actionTypes.LOCKED_ENTITY_UPDATED);

export const resetEmailEntityState = emailsBuilder(actionTypes.RESET_ENTITY_STATE);

export const fetchLockedEmailBegin = emailsBuilder(actionTypes.FETCH_LOCKED_ENTITY_BEGIN);
export const fetchLockedEmailSuccess = emailsBuilder(actionTypes.FETCH_LOCKED_ENTITY_SUCCESS);
export const fetchLockedEmailFailure = emailsBuilder(actionTypes.FETCH_LOCKED_ENTITY_FAILURE);

export const publishEmailBegin = emailsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);
export const publishEmailSuccess = emailsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS);
export const publishEmailFailure = emailsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_FAILURE);

export const revertPublishedEmailBegin = emailsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN);
export const revertPublishedEmailFailure = emailsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE);
export const revertPublishedEmailSuccess = emailsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS);

export const setEmailEntityId = emailsBuilder(actionTypes.SET_ENTITY_ID);

export const addPeopleToEmailBegin = "ADD_PEOPLE_TO_EMAIL_BEGIN";
export const addPeopleToEmailSuccess = "ADD_PEOPLE_TO_EMAIL_SUCCESS";
export const addPeopleToEmailFailure = "ADD_PEOPLE_TO_EMAIL_FAILURE";

export const deletePeopleFromEmailBegin = "DELETE_PEOPLE_FROM_EMAIL_BEGIN";
export const deletePeopleFromEmailSuccess = "DELETE_PEOPLE_FROM_EMAIL_SUCCESS";
export const deletePeopleFromEmailFailure = "DELETE_PEOPLE_FROM_EMAIL_FAILURE";

export const sendTestEmailBegin = "SEND_TEST_EMAIL_BEGIN";
export const sendTestEmailSuccess = "SEND_TEST_EMAIL_SUCCESS";
export const sendTestEmailFailure = "SEND_TEST_EMAIL_FAILURE";

export const duplicateEmailBegin = "DUPLICATE_EMAIL_BEGIN";
export const duplicateEmailSuccess = "DUPLICATE_EMAIL_SUCCESS";
export const duplicateEmailFailure = "DUPLICATE_EMAIL_FAILURE";
