import axios from "axios";
import { type IntegrationTypes } from "../Integrations/types";

const integrationsUrl = "api/integrations";

const service = {
  async getActivityLogs(
    type: IntegrationTypes,
    skip: number,
    take: number,
    sortColumn: string,
    sortDirection: string,
    MoboId?: string,
  ) {
    const params = {
      skip,
      take,
      sortColumn,
      sortDirection,
    };

    const query = MoboId
      ? {
          headers: {
            MoboId,
          },
          params,
        }
      : {
          params,
        };

    const response = await axios.get(`${integrationsUrl}/${type}/logs`, query);

    const recordsCount = parseInt(response.headers[process.env.REACT_APP_COUNT_HEADER_NAME as string]);
    return {
      items: response.data,
      totalCount: recordsCount,
    };
  },
};

export default service;
