import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import {
  buildFetchAction,
  buildFetchGridAction,
  buildRootEntityActionType,
} from "../../../../Application/actions/actionsBuilder";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";
import * as fetchingItemsActionTypes from "../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";

export const Groups = "GROUPS";

export const FETCH_GROUPS = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.Groups);

export const FETCH_GROUP_OWNERS = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.GroupOwners);

export const FETCH_GROUPS_LAZY = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  "",
);

export const GROUPS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const GROUPS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const GROUPS_RESET_ITEMS = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  fetchingItemsActionTypes.RESET_GRID_ITEMS,
);
