import { useEffect, useMemo, useReducer, useRef } from "react";
import { type Margin } from "../types/Shared";
import { type ColumnDataPoint } from "../types/ColumnChart";
import { useDimensions } from "hooks/useDimensions";
import {
  APPEND_ONCE,
  calculateChartDimensions,
  drawCenteringGroup,
  hideTicksWithNoText,
  textEllipsis,
} from "../shared/helpers";
import { linearScale } from "../shared/bsi-time-scale";
import * as d3 from "d3";
import { getTicksFromValueTypes, TickValueType } from "../shared/public";
import { widthAndHeightInvalid } from "../utils/utils";
import { useChartResizeTransition } from "../hooks/useChartResizeTransition";
import { DEFAULT_MARGIN } from "../shared/constants";
import moment from "moment";
import { validBarData } from "features/Library/Common/utils/performanceUtils";
import { ChartPopup } from "../shared/ChartPopup/ChartPopup";
import { initialTooltipState, tooltipReducer } from "./ComboChartTooltipReducer";

import "./comboChart.scss";

type FormattedData = {
  x: Date;
  y: number;
};

type TweenDashNodeType = (d3.BaseType | SVGPathElement)[] | (ArrayLike<d3.BaseType> | ArrayLike<SVGPathElement>);

function middleDate(a: Date, b: Date): Date {
  let diff = Math.abs(moment(a).diff(b));
  let middle = diff / 2 + Math.min(moment(a).valueOf(), moment(b).valueOf());
  return moment(middle).toDate();
}

interface Props {
  margins?: Margin;
  xData: Date[];
  lineYData: number[];
  barYData: ColumnDataPoint[];
  lineColor?: string;
  defaultBarColor?: string;
  transitionDuration?: number; // in ms
  xAxisFormatter?: (d: Date) => string;
  lineTooltipFormatter?: (x: Date, y: number, dataIndex: number) => string;
  barTooltipFormatter?: (columnData: ColumnDataPoint, dataIndex: number) => string;
}

export const ComboChart: React.FC<Props> = ({
  margins = DEFAULT_MARGIN,
  xData,
  lineYData,
  barYData,
  lineColor = "#288bed",
  defaultBarColor = "#ED9D08",
  transitionDuration = 1000,
  xAxisFormatter = (date: Date) => moment(date).format("MMM 'YY"),
  lineTooltipFormatter = (_x, y) => y.toLocaleString(),
  barTooltipFormatter = (point) => point.value.toLocaleString(),
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const hoverDotRef = useRef<d3.Selection<SVGGElement, boolean, d3.BaseType, unknown>>();
  const dashedLineRef = useRef<d3.Selection<d3.BaseType | SVGLineElement, boolean, d3.BaseType, unknown>>();
  const leftTooltipRef = useRef<d3.Selection<d3.BaseType | SVGGElement, boolean, d3.BaseType, unknown>>();

  const [dim, measuredRef] = useDimensions();
  const [transitionDurationRef, resizeRef] = useChartResizeTransition(transitionDuration);
  const { totalWidth, totalHeight, innerWidth, innerHeight } = calculateChartDimensions(dim, margins);
  const [tooltipState, dispatch] = useReducer(tooltipReducer, initialTooltipState);

  /**
   * For drawing the line chart points
   */
  const alteredXData = useMemo(() => {
    let first = moment(xData[0]).startOf("month").toDate();
    let last = moment(xData[xData.length - 1])
      .add(1, "month")
      .startOf("month")
      .toDate();
    let middleValues: Date[] = Array(xData.length)
      .fill(0)
      .map((_, i) => {
        if (i === xData.length - 1) {
          return middleDate(xData[i], last);
        }
        return middleDate(xData[i], xData[i + 1]);
      });

    return [first, ...middleValues, last];
  }, [xData]);

  const alteredYData = useMemo(() => {
    return [lineYData[0], ...lineYData, lineYData[lineYData.length - 1]];
  }, [lineYData]);

  /**
   * Creating d3 functions/values necessary to draw the graph
   */
  const formattedDataForLine = useMemo<FormattedData[]>(() => {
    if (alteredXData.length !== alteredYData.length) {
      throw new Error("Data objects must have the same number of elements.");
    }

    return FormatDataForUse(alteredXData, alteredYData);
  }, [alteredXData, alteredYData]);

  const xDomain = useMemo<[Date, Date]>(() => {
    let t = d3.extent(xData);
    t[1] = moment(t[1]).add(1, "month").startOf("month").toDate();
    return t as [Date, Date];
  }, [xData]);

  const barHasData = useMemo(() => validBarData(barYData), [barYData]);
  const lineHasData = useMemo(() => lineYData.some((v) => v > 0), [lineYData]);
  const bothHaveData = useMemo(() => barHasData && lineHasData, [barHasData, lineHasData]);

  // Maybe we should use 3 values in the domain, [0, maxLineValue, maxBarValue]

  const yDomain = useMemo(() => {
    const lineMax = d3.max(lineYData) ?? 0;
    const barMax = d3.max(barYData.map((d) => d.value)) ?? 0;
    if (bothHaveData) {
      // Edge case where our line chart would surpass our bar chart max height
      if (lineMax >= barMax) {
        return [0, lineMax];
      }
      return [0, d3.max(lineYData), d3.max(barYData.map((d) => d.value))!];
    } else if (lineHasData) {
      return [0, d3.max(lineYData)];
    } else if (barHasData) {
      return [0, d3.max(barYData.map((d) => d.value))!];
    } else {
      return [0, 1];
    }
  }, [bothHaveData, lineYData, barYData, lineHasData, barHasData]);
  const yScaleRange = useMemo(() => {
    if (yDomain.length === 3) return [innerHeight, (innerHeight * 2) / 3, 0];
    return [innerHeight, 0];
  }, [yDomain, innerHeight]);

  const xTickScale = useMemo(() => d3.scaleTime().domain(xDomain).range([0, innerWidth]), [innerWidth, xDomain]);
  const xScale = useMemo(() => d3.scaleTime().domain(xDomain).range([0, innerWidth]), [innerWidth, xDomain]);
  const yScale = useMemo(() => linearScale().domain(yDomain).range(yScaleRange).nice(), [yDomain, yScaleRange]);

  const xBandScale = useMemo(
    () => d3.scaleBand<Date>().domain(xData).range([0, innerWidth]).padding(0),
    [innerWidth, xData],
  );

  const barWidth = useMemo(() => xBandScale.bandwidth() * 0.7, [xBandScale]);

  /**
   * Used to draw the text values on the x-axis
   */
  const xTextAxis = useMemo(() => {
    return d3.axisBottom(xBandScale).tickSizeOuter(0).tickSizeInner(-innerHeight).tickFormat(xAxisFormatter);
  }, [innerHeight, xAxisFormatter, xBandScale]);

  /**
   * Used to draw the lines on the x-axis
   */
  const xLineAxis = useMemo(
    () =>
      d3
        .axisBottom(xTickScale)
        .ticks(xData.length + 1)
        .tickSize(-innerHeight),
    [innerHeight, xData.length, xTickScale],
  );

  const yAxis = useMemo(() => {
    const tickValues = getTicksFromValueTypes(yScale, TickValueType.IntegersOnly, 5);

    return d3
      .axisLeft(yScale)
      .ticks(10)
      .tickSize(-innerWidth)
      .tickFormat((num: d3.AxisDomain) => d3.format(".2~s")(+num))
      .tickPadding(7)
      .tickValues(tickValues);
  }, [yScale, innerWidth]);

  const lineGenerator = useMemo(
    () =>
      d3
        .line<FormattedData>()
        .x((d) => xScale(d.x)!)
        .y((d) => yScale(d.y)!),
    [xScale, yScale],
  );

  const preflightChecksFailed = useMemo(
    () => widthAndHeightInvalid(innerWidth, innerHeight),
    [innerWidth, innerHeight],
  );

  // Draw centering groups and axes
  useEffect(() => {
    if (!svgRef.current || preflightChecksFailed) return;

    const svg = d3.select(svgRef.current);
    const mainArea = drawCenteringGroup(svg, margins.left, margins.top, transitionDurationRef.current);

    const axisTween = (_this: SVGGElement) => {
      return function () {
        const textSelection = d3.select(_this).selectAll("text");
        hideTicksWithNoText(textSelection);
      };
    };

    const xAxisTextEllipsis = (_data: unknown, index: number, elements: ArrayLike<SVGTextElement>) => {
      const e: [SVGTextElement, SVGTextElement, SVGTextElement] = [
        elements[index - 1],
        elements[index],
        elements[index + 1],
      ];
      textEllipsis(elements[index], 10, -8, e);
    };

    // Add X grid lines
    const xAxisGridLinesGroup = mainArea
      .selectAll(".x-grid-lines-group")
      .data(APPEND_ONCE)
      .join(
        (enter) => {
          enter
            .append("g")
            .attr("transform", `translate(0, ${innerHeight})`)
            .attr("class", "x-grid-lines-group")
            .call(xLineAxis);
          hideTicksWithNoText(enter.selectAll("text") as any);
          return enter;
        },
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .attr("transform", `translate(0, ${innerHeight})`)
            .tween("hide x ticks", function () {
              const _this = this as unknown as SVGGElement;
              return axisTween(_this);
            })
            // @ts-ignore
            .call(xLineAxis);
          return update;
        },
      );

    xAxisGridLinesGroup.selectAll(".domain").attr("class", "domain axisLine");
    xAxisGridLinesGroup.selectAll("line").attr("class", "axisLine");
    xAxisGridLinesGroup.selectAll("text").remove();

    // Add labels
    const xAxisGroup = mainArea
      .selectAll<SVGGElement, boolean[]>(".x-axis-group")
      .data(APPEND_ONCE)
      .join(
        (enter) => {
          enter
            .append("g")
            .attr("transform", `translate(0, ${innerHeight})`)
            .attr("class", "x-axis-group")
            .call(xTextAxis);
          hideTicksWithNoText(enter.selectAll("text") as any);
          return enter;
        },
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .attr("transform", `translate(0, ${innerHeight})`)
            .tween("hide x ticks", function () {
              const _this = this as unknown as SVGGElement;
              return axisTween(_this);
            })
            .tween("text ellipsis", function () {
              const innerText = d3.select(this).selectAll<SVGTextElement, unknown>("text");

              return function () {
                innerText.each(xAxisTextEllipsis);
              };
            })
            .call(xTextAxis);
          return update;
        },
      );
    xAxisGroup.selectAll(".domain").attr("class", "domain axisLine");
    d3.selectAll(".x-axis-group line").remove();
    xAxisGroup.selectAll("text").attr("class", "axisText").attr("dy", "13");

    // Add y grid lines with labels
    const yAxisGroup = mainArea
      .selectAll<SVGGElement, boolean[]>(".y-axis-group")
      .data(APPEND_ONCE)
      .join(
        (enter) => {
          enter.append("g").attr("class", "y-axis-group").call(yAxis);
          // If scale log is the default, this hides the ticks
          hideTicksWithNoText(enter.selectAll("text") as any);
          return enter;
        },
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .tween("hide y ticks", function () {
              const _this = this as unknown as SVGGElement;
              return axisTween(_this);
            })
            .call(yAxis);
          return update;
        },
      );

    // For some reason, yAxisGroup is selecting all "line" children
    mainArea.selectAll(".y-axis-group .domain").remove();

    function highlightExtraLine(this: d3.Selection<SVGGElement, boolean, d3.BaseType, unknown>) {
      const yAxisLines = mainArea.selectAll<SVGLineElement, unknown>(".y-axis-group .axisLine");
      const yAxisText = mainArea.selectAll<SVGTextContentElement, number>(".y-axis-group .axisText");
      let diffIndex = -1;
      let diffValue = Infinity;
      const yData = yAxisText.data();
      for (let i = 0; i < yData.length - 1; i++) {
        const localDiff = yData[i + 1] - yData[i];
        if (localDiff > diffValue) {
          diffIndex = i + 1;
          break;
        }

        diffValue = localDiff;
      }
      if (diffIndex !== -1) {
        yAxisLines.nodes()[Math.min(diffIndex, yData.length - 1)].classList.add("combochart--extraLine");
      }
    }

    yAxisGroup.selectAll("line").attr("class", "axisLine");
    yAxisGroup.selectAll("text").attr("class", "axisText").attr("dx", "3");
    yAxisGroup.call(highlightExtraLine);
  }, [
    preflightChecksFailed,
    margins.left,
    margins.top,
    innerHeight,
    transitionDurationRef,
    xTextAxis,
    yAxis,
    xLineAxis,
  ]);

  // Bars
  useEffect(() => {
    if (preflightChecksFailed) return;

    const mainArea = d3.select(svgRef.current).select<SVGGElement>(".centering-group");

    function getBarHeight(d: ColumnDataPoint) {
      return innerHeight - yScale(d.value)!;
    }

    function baseBarTransition(
      selection: d3.Selection<SVGRectElement, ColumnDataPoint, d3.BaseType | SVGGElement, unknown>,
    ) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => innerHeight - getBarHeight(d) / 2)
        .attr("height", (d) => getBarHeight(d) / 2);
    }

    function roundedBarTransition(
      selection: d3.Selection<SVGRectElement, ColumnDataPoint, d3.BaseType | SVGGElement, unknown>,
    ) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => yScale(d.value)!)
        .attr("height", getBarHeight);
    }

    function getBoxX(d: ColumnDataPoint) {
      const boxCenteringOffset = (xBandScale.bandwidth() - barWidth) / 2;
      return xBandScale(moment(d.category).startOf("day").toDate())! + boxCenteringOffset;
    }

    // Draw the bars
    mainArea
      .selectAll(".baseRect")
      .data(barYData)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "baseRect")
            .attr("x", getBoxX)
            .attr("y", innerHeight)
            .attr("height", 0)
            .attr("width", barWidth)
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .call(baseBarTransition),
        (update) =>
          update
            .attr("x", getBoxX)
            .attr("width", barWidth)
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            // Ignore is below because of type of argument, but it is acceptable
            // @ts-ignore
            .call(baseBarTransition),
      );

    mainArea
      .selectAll(".roundedRect")
      .data(barYData)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "roundedRect")
            .attr("x", getBoxX)
            .attr("y", innerHeight)
            .attr("height", 0)
            .attr("width", barWidth)
            .attr("rx", barWidth / 2)
            .attr("ry", barWidth / 2)
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .call(roundedBarTransition),
        (update) =>
          update
            .attr("x", getBoxX)
            .attr("width", barWidth)
            .attr("fill", (d) => d.fillColor ?? defaultBarColor)
            .attr("rx", barWidth / 2)
            .attr("ry", barWidth / 2)
            // Ignore is below because of type of argument, but it is acceptable
            // @ts-ignore
            .call(roundedBarTransition),
      );

    mainArea
      .selectAll(".hoverRect")
      .data(barYData)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "hoverRect")
            .attr("x", getBoxX)
            .attr("y", 0)
            .attr("height", innerHeight)
            .attr("width", barWidth)
            .attr("rx", barWidth / 2)
            .attr("ry", barWidth / 2)
            .attr("fill", "none")
            // Required because fill is none
            .style("pointer-events", "all"),
        (update) =>
          update
            .attr("x", getBoxX)
            .attr("y", 0)
            .attr("height", innerHeight)
            .attr("width", barWidth)
            .attr("fill", "none")
            .attr("rx", barWidth / 2)
            .attr("ry", barWidth / 2),
      );
  }, [
    barWidth,
    barYData,
    defaultBarColor,
    innerHeight,
    preflightChecksFailed,
    transitionDurationRef,
    xBandScale,
    yScale,
    margins,
  ]);

  // Lines
  useEffect(() => {
    if (!svgRef.current || preflightChecksFailed) return;

    const svg = d3.select(svgRef.current);
    const mainArea = svg.select(".centering-group");

    // For initial line transitions
    function tweenDash(_data: FormattedData[], index: number, nodes: TweenDashNodeType) {
      // This should only be required by tests, as in prod nodes is a list of path elements
      const getTotalLengthIsDefined = nodes?.[index] && (nodes[index] as SVGPathElement).getTotalLength;
      const l = getTotalLengthIsDefined ? (nodes[index] as SVGPathElement).getTotalLength() : 1;
      const i = d3.interpolateString(`0,${l}`, `${l},${l}`);

      return function (t: number) {
        return i(t);
      };
    }

    function initialTransition(
      path: d3.Selection<SVGPathElement, FormattedData[], d3.BaseType | SVGGElement, unknown>,
    ) {
      path
        .transition()
        .duration(transitionDurationRef.current)
        .ease(d3.easeLinear)
        .attrTween("stroke-dasharray", tweenDash);
    }
    // End line transition helpers

    // Draw the lines
    mainArea
      .selectAll(".path")
      .data([formattedDataForLine])
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("class", "path")
            .attr("stroke", lineColor)
            .attr("d", lineGenerator)
            .attr("stroke-dasharray", "0,1")
            .style("pointer-events", "none")
            .call(initialTransition),
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .attr("d", lineGenerator)
            .attr("stroke-dasharray", null);
          return update;
        },
      );
    // Dashed line that also marks current selection
    const LEFT_TOOLTIP_OFFSET = -15; // Distance from left axis
    const LEFT_TOOLTIP_HEIGHT = 20;
    dashedLineRef.current = mainArea
      .selectAll(".dashedLine")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("line").attr("class", "dashedLine"));

    const leftTooltip = mainArea
      .selectAll(".leftTooltip")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("g").attr("class", "leftTooltip"));
    leftTooltipRef.current = leftTooltip;

    leftTooltip
      .selectAll(".leftTooltipRect")
      .data(APPEND_ONCE)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "leftTooltipRect")
            .attr("height", LEFT_TOOLTIP_HEIGHT)
            .attr("x", LEFT_TOOLTIP_OFFSET)
            .attr("y", -LEFT_TOOLTIP_HEIGHT / 2)
            .attr("rx", "5"),
        (update) =>
          update
            .attr("height", LEFT_TOOLTIP_HEIGHT)
            .attr("x", LEFT_TOOLTIP_OFFSET)
            .attr("y", -LEFT_TOOLTIP_HEIGHT / 2),
      );
    leftTooltip
      .selectAll(".leftTooltipText")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("text").attr("class", "leftTooltipText").attr("y", "0"));

    // Hover states
    const hoverDot = mainArea
      .selectAll<SVGGElement, null>(".dot")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("g").attr("class", "dot").style("pointer-events", "none").style("display", "none"));

    hoverDot
      .selectAll(".outerCircle")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("circle").attr("r", 8).attr("class", "outerCircle"));
    hoverDot
      .selectAll(".innerCircle")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("circle").attr("r", 3).attr("class", "innerCircle"));

    hoverDotRef.current = hoverDot;
  }, [formattedDataForLine, lineColor, lineGenerator, preflightChecksFailed, transitionDurationRef]);

  // Tooltip and hover states
  useEffect(() => {
    const getColumnTooltipPositionString = (d: ColumnDataPoint) => {
      return `translate(${xBandScale(moment(d.category).startOf("day").toDate())! + xBandScale.bandwidth() / 2},${yScale(d.value) || 0})`;
    };

    const mainArea = d3.select(svgRef.current).select<SVGGElement>(".centering-group");

    const columnTooltipPositions = mainArea
      .selectAll(".centers")
      .data(barYData)
      .join(
        (enter) => enter.append("g").attr("class", "centers").attr("transform", getColumnTooltipPositionString),
        (update) => update.attr("transform", getColumnTooltipPositionString),
      );

    function mouseOver(this: d3.BaseType, point: ColumnDataPoint, index: number) {
      // Figure out location for column tooltip
      const center = columnTooltipPositions.nodes()[index] as SVGGElement;
      const { x, y } = center.getBoundingClientRect();

      // Figure out location for line tooltip
      // Calculate x-index for closest line point
      const lineData = formattedDataForLine[index + 1];
      hoverDotRef.current
        ?.attr("transform", `translate(${xScale(lineData.x)!},${yScale(lineData.y)!})`)
        ?.style("display", "block");

      const {
        x: lineX,
        y: lineY,
        width: hoverDotWidth,
        height: hoverDotHeight,
      } = hoverDotRef.current?.node()?.getBoundingClientRect() ?? { x: 0, y: 0, width: 0, height: 0 };

      dashedLineRef.current
        ?.attr("x1", 0)
        .attr("x2", innerWidth)
        .attr("y1", yScale(lineData.y))
        .attr("y2", yScale(lineData.y))
        .style("visibility", "visible");
      leftTooltipRef.current
        ?.attr("transform", `translate(${0}, ${yScale(lineData.y)})`)
        .style("visibility", "visible");

      // Update tooltip text
      const LEFT_TOOLTIP_OFFSET = -15; // Distance from left axis
      const leftTooltipRect = leftTooltipRef.current?.select(".leftTooltipRect");
      const leftTooltipText = leftTooltipRef.current?.select(".leftTooltipText");
      leftTooltipText?.text(lineData.y.toLocaleString());
      const leftTooltipPadding = 10;
      const leftWidth = (leftTooltipText!.node() as SVGTextElement).getBoundingClientRect().width;
      const rectWidth = leftWidth + leftTooltipPadding;
      leftTooltipRect?.attr("width", rectWidth).attr("x", LEFT_TOOLTIP_OFFSET - rectWidth);
      leftTooltipText?.attr("x", LEFT_TOOLTIP_OFFSET - rectWidth / 2);

      const yDistanceBetweenTooltips = y - lineY;
      let lineUseTop = true;
      if (Math.abs(yDistanceBetweenTooltips) < 50) {
        lineUseTop = false;
      }

      dispatch({
        type: "dispatchPopups",
        payload: {
          lineState: {
            x: lineUseTop ? lineX + hoverDotWidth / 2 : lineX + 12 + hoverDotWidth,
            y: lineUseTop ? lineY : lineY + hoverDotHeight / 2,
            text: lineTooltipFormatter(lineData.x, lineData.y, index),
            useTop: lineUseTop,
          },
          barState: {
            x: x,
            y: y,
            text: barTooltipFormatter(point, index),
          },
        },
      });
    }

    function mouseLeave() {
      hoverDotRef.current?.style("display", "none");
      dashedLineRef.current?.style("visibility", "hidden");
      leftTooltipRef.current?.style("visibility", "hidden");
      dispatch({ type: "closePopups" });
    }

    const bars = mainArea
      .selectAll<SVGRectElement, ColumnDataPoint>(".hoverRect")
      .on("mouseover", mouseOver)
      .on("mouseleave", mouseLeave);

    return () => {
      bars.on("mouseover", null).on("mouseleave", null);
    };
  }, [
    barTooltipFormatter,
    barYData,
    formattedDataForLine,
    innerWidth,
    lineTooltipFormatter,
    xBandScale,
    xScale,
    yScale,
  ]);

  return (
    <div ref={measuredRef} className="combo-chart-container">
      <div ref={resizeRef} style={{ width: "100%", height: "100%" }}>
        <svg ref={svgRef} viewBox={`0 0 ${totalWidth} ${totalHeight}`} />
        <ChartPopup
          open={tooltipState.isOpen}
          left={tooltipState.lineState.x}
          top={tooltipState.lineState.y}
          useTop={tooltipState.lineState.useTop}
        >
          <span>{tooltipState.lineState.text}</span>
        </ChartPopup>
        <ChartPopup open={tooltipState.isOpen} left={tooltipState.barState.x} top={tooltipState.barState.y} useTop>
          <span>{tooltipState.barState.text}</span>
        </ChartPopup>
      </div>
    </div>
  );
};

const FormatDataForUse = (x: any[], y: any[]) => {
  return x.map((d, i) => ({
    x: d,
    y: y[i],
  }));
};
