import { createReducer } from "../../../../../utils/reduxUtils";
import { type Survey, type SurveyDetailsState, type SurveyContentView, type SurveyView, type SettingsView } from "../../types/state";
import actionTypes, { type SurveyDetailsActionTypes } from "../actionTypes/surveyDetailsActionTypes";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { END_NOTE_TEXT_DEFAULT } from "../../types/constants";
import { type DraftStateEntity } from "../../../../../interfaces/draftStateEntity";
import { getQuestionDefaultData } from "../../SurveyDetails/ContentForm/QuestionForm/Providers/defaultQuestionProvider";
import {
  fetchLockedSurveyBegin,
  fetchLockedSurveySuccess,
  fetchDiscardSurveyBegin,
} from "../actionTypes/surveyEntityStateActionTypes";
import { type Content, SurveyQuestionType } from "../../types/models";

const initialState: SurveyDetailsState = {
  title: "",
  isDraft: true,
  survey: {
    id: -1,
    title: "",
    description: "",
    hasBeenPublished: false,
    isDraft: true,
    isPurchased: false,
    dateCreated: undefined,
    dateModified: undefined,

    endNoteText: END_NOTE_TEXT_DEFAULT,
    endNoteTextEnabled: false,
    flows: [],
  },
  settings: {
    packs: [],
    flows: [],
    labels: [],
    softwareApplications: [],
  },
  content: {
    questions: [getQuestionDefaultData(SurveyQuestionType.MultipleChoiceTextQuestion)],
  },
  isLoading: false,
  isSurveyInfoLoaded: false,
  error: undefined,

  isContentLoaded: false,
};

const saveSurvey = (state: SurveyDetailsState, action: PayloadAction<SurveyView>): SurveyDetailsState => ({
  ...state,
  survey: action.payload,
});

const clearSurvey = () => ({
  ...initialState,
  content: {
    questions: [getQuestionDefaultData(SurveyQuestionType.MultipleChoiceTextQuestion)],
  },
});

const fetchingBeginHandler = (state: SurveyDetailsState): SurveyDetailsState => ({
  ...state,
  isLoading: true,
  error: undefined,
});

const setIsLoading = (state: SurveyDetailsState): SurveyDetailsState => ({
  ...state,
  isLoading: true,
});

const fetchLockedSurveySuccessHandler = (state: SurveyDetailsState): SurveyDetailsState => ({
  ...state,
  survey: {
    ...state.survey,
    isDraft: true,
  },
  isDraft: true,
  isLoading: false,
});

const getSurveySuccess = (state: SurveyDetailsState, action: PayloadAction<Survey>): SurveyDetailsState => {
  const payload = action.payload;

  return {
    ...state,
    survey: {
      ...state.survey,
      ...action.payload,
      id: action.payload.id!,
      hasBeenPublished: action.payload.hasBeenPublished!,
    },
    settings: {
      flows: payload.flows,
      labels: payload.labels,
      packs: payload.packs.map((pack) => ({ title: pack.name, id: pack.id })),
      softwareApplications: payload.softwareApplications,
    },
    isLoading: false,
    isSurveyInfoLoaded: true,
  };
};

const fetchingFailureHandler = (state: SurveyDetailsState, action: PayloadAction<Error>): SurveyDetailsState => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

const saveContentHandler = (
  state: SurveyDetailsState,
  action: PayloadAction<SurveyContentView>,
): SurveyDetailsState => ({
  ...state,
  content: action.payload,
});

const getContentSuccessHandler = (state: SurveyDetailsState, action: PayloadAction<Content>): SurveyDetailsState => ({
  ...state,
  content: {
    ...state.content,
    questions: action.payload.questions.length ? action.payload.questions : state.content.questions,
  },
  isContentLoaded: true,
  isLoading: false,
});

const setSurveyBaseInfoHandler = (
  state: SurveyDetailsState,
  action: PayloadAction<DraftStateEntity>,
): SurveyDetailsState => ({
  ...state,
  isDraft: action.payload.isDraft,
  title: action.payload.title,
});

const saveSettingsHandler = (state: SurveyDetailsState, action: PayloadAction<SettingsView>): SurveyDetailsState => ({
  ...state,
  settings: {
    ...action.payload,
  },
});
const surveysDetailsHandlers = (): {
  [K in SurveyDetailsActionTypes]: (state: SurveyDetailsState, action: PayloadAction<any>) => SurveyDetailsState;
} => {
  return {
    [actionTypes.SaveSurvey]: saveSurvey,
    [actionTypes.ClearSurvey]: clearSurvey,
    [actionTypes.GetSurveyBegin]: fetchingBeginHandler,
    [actionTypes.GetSurveySuccess]: getSurveySuccess,
    [actionTypes.GetSurveyFailure]: fetchingFailureHandler,
    [actionTypes.GetSurveyContentBegin]: fetchingBeginHandler,
    [actionTypes.GetSurveyContentSuccess]: getContentSuccessHandler,
    [actionTypes.GetSurveyContentFailure]: fetchingFailureHandler,
    [actionTypes.SaveContent]: saveContentHandler,
    [actionTypes.SetSurveyBaseInfo]: setSurveyBaseInfoHandler,
    [actionTypes.SaveSettings]: saveSettingsHandler,
    [fetchLockedSurveyBegin]: setIsLoading,
    [fetchLockedSurveySuccess]: fetchLockedSurveySuccessHandler,
    [fetchDiscardSurveyBegin]: setIsLoading,
  };
};

export const surveysDetailsReducer = createReducer(initialState, [surveysDetailsHandlers]);
