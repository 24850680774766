import React from "react";

import ItemToDropCard from "../ItemToDropCard/ItemToDropCard";

export interface Props {
  id: number;
  title: string;
  thumbnailUrl?: string;
  onClick?: () => void;
  isSelected: boolean;
}

const EventToDropCard: React.FunctionComponent<Props> = (props) => {
  return (
    <ItemToDropCard className="event-to-drop-card" dataHookType="event-to-drop-card" itemType="EVENT" {...props} />
  );
};

export default EventToDropCard;
