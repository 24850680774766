import React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { noop } from "lodash";

import { DeleteLinkButton, DropdownWrapper as Dropdown } from "../../../../../../../../../components";
import { type ExternalTrigger, type ExternalTriggerGroup, TriggerGroupType } from "../../../../../types/externallTriggersTypes";

import useToggle from "../../../../../../../../../hooks/useToggle";
import ExternalTriggerForm from "../ExternalTriggerForm/ExternalTriggerForm";

import styles from "./groupItem.module.scss";

export interface GroupItemProps {
  index: number;
  isValid: boolean;
  isReadOnly: boolean;
  group: ExternalTriggerGroup<ExternalTrigger[]>;
  onGroupChange: (group: ExternalTriggerGroup<ExternalTrigger[]>, index: number) => void;
}

const GroupItem = (props: GroupItemProps) => {
  const { group, isReadOnly, isValid, index, onGroupChange } = props;
  const [isActive, toggle] = useToggle(true);

  return (
    <Accordion className={styles.root}>
      <Accordion.Title className={styles.title} active={isActive} onClick={toggle}>
        <div>
          <Icon className="fa-chevron-down" />
          <span>{`Condition ${index + 1}`}</span>
        </div>
        <DeleteLinkButton onClick={noop} isDisabled />
      </Accordion.Title>
      <Accordion.Content className={styles.content} active={isActive}>
        <div className={styles.group}>
          Add users who meet
          <Dropdown
            compact
            selection
            items={[{ text: "All", value: TriggerGroupType.All }]}
            selected={TriggerGroupType.All}
            disabled
          />
          of the following conditions:
        </div>
        <ExternalTriggerForm
          index={index}
          isValid={isValid}
          group={group}
          onGroupChange={onGroupChange}
          isReadOnly={isReadOnly}
        />
      </Accordion.Content>
    </Accordion>
  );
};

export default GroupItem;
