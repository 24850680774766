import { type GenericFiltersMap } from "utils/filterUtils";
import { MultiSelectWithWindow, type Item, type MultiSelectWithWindowProps } from "./MultiSelectWithWindow";

export type Props = {
  filter: GenericFiltersMap<string | number>;
  propertyName: string;
  updateFilter: Function;
} & MultiSelectWithWindowProps<any>;

export const FilterMultiSelectWithWindow = ({
  filter,
  propertyName,
  updateFilter,
  ...multiSelectWindowProps
}: Props) => {
  function handleChange(newItems: Item[]) {
    const newFilter = { ...filter };

    if (newItems.length === 0) {
      delete newFilter[propertyName];
    } else {
      newFilter[propertyName] = newItems;
    }
    updateFilter(newFilter);
  }

  return <MultiSelectWithWindow {...multiSelectWindowProps} filter={filter.accounts} onCheckedItemsChanged={handleChange} />;
};
