import { useQuery } from "@tanstack/react-query";
import { ChartWrapper, LineChart, getDateFormatByCount } from "components/charts";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  type QueryFilter,
  defaultDateFilter,
  isUserDeleted,
  lineChartFactory,
  noData,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { type ColumnOption } from "interfaces/columnOptions";
import { useState } from "react";
import dateTimeUtils from "utils/dateTimeUtils";
import CardReporting, { type CardReportingItem } from "components/cards/CardReporting/CardReporting";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import { MoboLink } from "components/MoboLink/MoboLink";
import { RouteNames } from "enums";

const getPeopleLineChart = async ({ queryKey, signal }: QueryFilter) => {
  const result = await peopleReportingService.users.getLineChart(queryKey[1], signal!);

  return lineChartFactory(result.data);
};

const getPeopleCards = async ({ queryKey, signal }: QueryFilter): Promise<CardReportingItem[]> => {
  const { data } = await peopleReportingService.users.getStatistics(queryKey[1], signal!);

  return [
    {
      stat: data.Users,
      statDescription: "Users",
    },
    {
      stat: data.Departments,
      statDescription: "Departments",
    },
    {
      stat: data.Groups,
      statDescription: "Groups",
    },
    {
      stat: data.Licenses,
      statDescription: "Licenses",
    },
  ];
};

type Re = {
  name: string;
  id: number | null;
  platformOutreach: number;
  userInteractions: number;
  lastActivity: string;
};

const getPeopleTable = async ({ queryKey, signal }: QueryFilter): Promise<Re[]> => {
  const result = await peopleReportingService.users.getTable(queryKey[1], signal!);

  return result.data.map((r) => ({
    name: `${r.FirstName} ${r.LastName}`,
    id: r.UserId,
    platformOutreach: r.PlatformOutreach,
    userInteractions: r.PlatformInteraction,
    lastActivity: r.LastActivity ? dateTimeUtils.formatDate(r.LastActivity) : "-",
  }));
};

const columns: ColumnOption<Re>[] = [
  {
    name: "Name",
    width: 7,
    render(item) {
      const useLink = !isUserDeleted(item.name);
      return (
        <Tooltip
          target={
            <TextTruncate>
              {useLink ? <MoboLink to={`/${RouteNames.peopleUsers}/${item.id}`}>{item.name}</MoboLink> : item.name}
            </TextTruncate>
          }
          content={`${item.name}`}
        />
      );
    },
  },
  {
    name: "Platform Outreach",
    width: 7,
    render(item) {
      return <span>{(item.platformOutreach ?? 0).toLocaleString()}</span>;
    },
  },
  {
    name: "User Interactions",
    width: 7,
    render(item) {
      return <span>{(item.userInteractions ?? 0).toLocaleString()}</span>;
    },
  },
  {
    name: "Last Activity",
    width: 7,
    render(item) {
      return <span>{item.lastActivity}</span>;
    },
  },
];

export const UsersReport = () => {
  const [dateFilter, setDateFilter] = useState(defaultDateFilter());
  const lineChartQuery = useQuery({
    queryKey: ["people users line query", dateFilter],
    queryFn: getPeopleLineChart,
  });

  const cardQuery = useQuery({
    queryKey: ["people users cards", dateFilter],
    queryFn: getPeopleCards,
  });

  const tableQuery = useQuery({
    queryKey: ["people users table query", dateFilter],
    queryFn: getPeopleTable,
  });

  return (
    <div className="performanceRoot">
      <div className="performanceHeader peopleActivityHeader">
        <h2 className="performanceTitle">Summary</h2>
        <div className="performanceActions">
          <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} includeAccounts={false} />
        </div>
      </div>
      <div className="performanceBody">
        <div className="lineChartContainer">
          <ChartWrapper titles={["Active Users"]}>
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess && validLineData([lineChartQuery.data.UserCount]) ? (
                <LineChart
                  {...sharedAccountReportingLineProps}
                  xData={[lineChartQuery.data.Date]}
                  yData={[lineChartQuery.data.UserCount]}
                  xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                />
              ) : (
                noData(dateFilter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className="cards">
          <RequestStatusRenderer state={cardQuery.status}>
            <CardReporting items={cardQuery.data!} />
          </RequestStatusRenderer>
        </div>
        <RequestStatusRenderer state={tableQuery.status}>
          {tableQuery.isSuccess && (
            <GenericPerformanceList title="People" rows={tableQuery.data} columns={columns} filter={dateFilter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};
