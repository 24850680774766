import { createAsyncThunk } from "@reduxjs/toolkit";
import { type AccountPackOverview } from "../../../../../components/assignmentModals/packAssignmentModal/types";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type FetchActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { type AvailablePacksRequest, type GetPacksRequest } from "../../types/requests";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

export const fetchavAilablePacks = createAsyncThunk<FetchActionPayload<AccountPackOverview>, AvailablePacksRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AvailablePacks,
    name: "availablePacks",
  }),
  async (requestData: AvailablePacksRequest, { signal }) => {
    const request: GetPacksRequest = {
      top: loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
    };

    const { getDataPromise, cancel } = dataService.packs.getAvailablePacksWithCancel(
      requestData.assignedAccountId,
      request,
      requestData.filters || {},
    );

    signal.addEventListener("abort", cancel);

    const result = await getDataPromise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
    };
  },
);
