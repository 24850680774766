import React from "react";
import cn from "classnames";
import { EntityType, type CardItem } from "../../../features/Library/Flows/Designer/types";
import VideoCard from "../flowDesignerDropCard/VideoCard/VideoCard";
import AssessmentCard from "../flowDesignerDropCard/AssessmentCard/AssessmentCard";
import EmailCard from "../flowDesignerDropCard/EmailCard/EmailCard";
import EventCard from "../flowDesignerDropCard/EventCard/EventCard";
import SurveyCard from "../flowDesignerDropCard/SurveyCard/SurveyCard";
import FlowEndCard from "../flowDesignerDropCard/FlowEndCard/FlowEndCard";
import { SectionHeaderCardInner } from "../../../features/Library/Flows/Designer/SectionHeaders/SectionHeaderCardInner/SectionHeaderCardInner";
import PdfCard from "../flowDesignerDropCard/PdfCard/PdfCard";
import MessageCard from "../flowDesignerDropCard/MessageCard/MessageCard";
import { PANZOOM_IGNORE_CLASSNAME } from "../../../features/Library/Flows/Designer/constants";

import "./flowDesignerDragCard.scss";

export interface Props {
  item: CardItem;
}

function FlowDesignerDragCard({ item }: Props) {
  const className = cn("designer-view-card-drag-container", PANZOOM_IGNORE_CLASSNAME, {
    "section-header-drag-card": item.elementType === EntityType.SectionHeader,
  });

  return (
    <div tabIndex={0} className={className}>
      {item.elementType === EntityType.Pdf && <PdfCard item={item} />}
      {item.elementType === EntityType.Video && <VideoCard item={item} />}
      {item.elementType === EntityType.Assessment && <AssessmentCard item={item} />}
      {item.elementType === EntityType.Survey && <SurveyCard item={item} expanded />}
      {item.elementType === EntityType.Email && <EmailCard item={item} />}
      {item.elementType === EntityType.Message && <MessageCard item={item} />}
      {item.elementType === EntityType.ExternalEvent && <EventCard item={item} />}
      {item.elementType === EntityType.SectionHeader && <SectionHeaderCardInner attached={false} />}
      {item.elementType === EntityType.FlowEnd && <FlowEndCard item={item} />}
    </div>
  );
}

export default FlowDesignerDragCard;
