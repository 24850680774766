import React, { type ReactElement } from "react";
import { Card, type SemanticWIDTHS } from "semantic-ui-react";

import arrayUtils from "../../utils/arrayUtils";
import { SelectionChangedType } from "../../interfaces/onSelectionChanged";
import { type CardItem, type CardsViewerItem } from "./types";
import useCardsResize from "./useCardsResize";

export interface Props<T extends CardItem> {
  cardItems: T[];
  itemsPerRow: SemanticWIDTHS;
  isSelectDisabled?: (item: T) => boolean;
  onSelectionChanged?: (selectionChangedProps: { items: T[]; type: SelectionChangedType }) => void;
  onSelectedCardsChanged?: (selectedIds: (number | string)[], selectedItemId: number | string) => void;
  renderCardsSelectionControls?: () => ReactElement;
  noResultsContent?: ReactElement | null;
  selectedIds?: (number | string)[];
  hidePopupMenu?: boolean;
  hasCardURL?: boolean;
  renderCard: (props: CardsViewerItem<T>) => ReactElement;
}

const CardsViewer = <T extends CardItem>(props: Props<T>) => {
  const {
    cardItems,
    itemsPerRow,
    noResultsContent,
    onSelectionChanged,
    onSelectedCardsChanged,
    isSelectDisabled,
    renderCard,
    renderCardsSelectionControls,
    selectedIds = [],
  } = props;

  const responsiveItemsPerRow = useCardsResize();

  const handleSelectedCardsChanged = (item: T) => {
    const id = item.id;
    let selected = selectedIds.slice();
    if (selected.includes(id)) {
      selected = arrayUtils.withoutItem(selectedIds, id);
      raiseOnSelectionChanged([item], SelectionChangedType.Removed);
    } else {
      selected.push(id);
      selected = arrayUtils.sortNumbers(selected);
      raiseOnSelectionChanged([item], SelectionChangedType.Added);
    }
    onSelectedCardsChanged?.(selected, id);
  };

  const raiseOnSelectionChanged = (items: T[], type: SelectionChangedType) => {
    onSelectionChanged?.({ items, type });
  };

  return cardItems.length ? (
    <Card.Group itemsPerRow={itemsPerRow ?? responsiveItemsPerRow}>
      {renderCardsSelectionControls?.()}
      {cardItems.map((item, index) => {
        const selected = selectedIds && selectedIds.includes(item.id);
        const disabled = !!isSelectDisabled?.(item);
        // For some reason keyed fragments(https://reactjs.org/docs/fragments.html#keyed-fragments) don't work here
        return React.cloneElement(
          renderCard({
            item,
            selected,
            disabled,
            onSelect: () => handleSelectedCardsChanged(item),
            index,
          }),
          { key: item.id },
        );
      })}
    </Card.Group>
  ) : (
    noResultsContent ?? null
  );
};

export default CardsViewer;
