import { Button } from "semantic-ui-react";
import { type DraftPackContextRenderModalActionsParams } from "./DraftPackContextStep";

export const getDraftPackContextStepModalActions = (params: DraftPackContextRenderModalActionsParams) => {
  return (nextStep: () => void) => {
    return (closeModal: Function) => {
      const { isDataValid, onIsDataValidChange, onCancel } = params;
      return (
        <>
          <Button
            basic
            className="cancel"
            content="Cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            onClick={() => {
              onIsDataValidChange(false);
              nextStep();
            }}
            disabled={!isDataValid}
          />
        </>
      );
    };
  };
};
