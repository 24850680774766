import { type ElementAndPosition } from "../../types";
import { isOverlapStartOfTheFlow } from "../../ReactFlowCanvas/hooks/useReactFlowCanvas";
import { ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";

export default function detectCoveredElement(
  elementsOnCanvas: ElementAndPosition[],
  currentElement: ElementAndPosition,
  headId: string | undefined,
  filterFunc: (el: ElementAndPosition) => boolean,
): ElementAndPosition | undefined {
  const coveredElements = elementsOnCanvas.filter((el) => {
    return filterFunc(el) && !checkIfStartOfTheFlow(currentElement, headId) && checkTopLeftCorner(el, currentElement);
  });
  if (coveredElements.length === 1) {
    return coveredElements[0];
  }

  // pick nearest element if there are 2 or more
  const sortedByArea = coveredElements
    .map((el) => {
      return {
        ...el,
        intersectionArea:
          (ELEMENT_WIDTH - Math.abs(el.topLeft.x - currentElement.topLeft.x)) *
          (ELEMENT_HEIGHT - Math.abs(el.topLeft.y - currentElement.topLeft.y)),
      };
    })
    .sort((x, y) => y.intersectionArea - x.intersectionArea);
  return sortedByArea[0];
}

const checkIfStartOfTheFlow = (el: ElementAndPosition, headId: string | undefined) => {
  return !headId && isOverlapStartOfTheFlow(el.topLeft);
};

const checkTopLeftCorner = (el1: ElementAndPosition, el2: ElementAndPosition): boolean => {
  return (
    Math.abs(el1.topLeft.x - el2.topLeft.x) < ELEMENT_WIDTH && Math.abs(el1.topLeft.y - el2.topLeft.y) < ELEMENT_HEIGHT
  );
};
