import { type AxiosError } from "axios";
import { isForbidden } from "../../../../../utils/statusCodeUtils";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
} from "../../../../Application/actions/actionsBuilder";
import dataService from "../../../../Application/services/dataServices/dataService";
import { type EditUserInfoState } from "../../../types";
import * as editUserActionTypes from "./editUserActionTypes";

export const resetUserInfo = makeActionCreator(editUserActionTypes.RESET_USER_INFO);

export const fetchUserInfo = (id: any) => {
  const fetchUserInfoBegin = getActionBaseProvider(editUserActionTypes.FETCH_USER_INFO_BEGIN);

  const fetchUserInfoSuccess = getActionProvider<EditUserInfoState>(editUserActionTypes.FETCH_USER_INFO_SUCCESS);

  const fetchUserInfoFailure = getActionProvider<Error>(editUserActionTypes.FETCH_USER_INFO_FAILURE);

  const fetchUserInfoForbidden = getActionProvider<AxiosError>(editUserActionTypes.FETCH_USER_INFO_FORBIDDEN);

  return async (dispatch: any) => {
    dispatch(fetchUserInfoBegin());
    try {
      const result = await dataService.getUserInfo(id);
      dispatch(fetchUserInfoSuccess({ info: result.data }));
    } catch (error) {
      isForbidden(error?.response?.status)
        ? dispatch(fetchUserInfoForbidden(error))
        : dispatch(fetchUserInfoFailure(error));
    }
  };
};

export const updateUserInfo = makeActionCreator(editUserActionTypes.UPDATE_USER_INFO, "info");
