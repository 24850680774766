import React from "react";
import { pluralize } from "../../../utils/stringUtils";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface RemoveRolesConfirmationModalProps {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
  rolesCount: number;
}

export default function RemoveRolesConfirmationModal(props: RemoveRolesConfirmationModalProps) {
  const { onCancel, onContinue, rolesCount, open } = props;

  return (
    <ActionConfirmationModal
      open={open}
      onCancel={onCancel}
      onContinue={onContinue}
      title={`Remove ${pluralize("Role", rolesCount)}?`}
      size={"tiny"}
      withWarningLabel={false}
      confirmLabel="Remove"
    >
      Are you sure you want to remove ({rolesCount}) {pluralize("role", rolesCount)} from this user? They will lose all
      permissions.
    </ActionConfirmationModal>
  );
}

RemoveRolesConfirmationModal.defaultProps = {
  open: false,
};
