import React, { memo } from "react";
import BaseNode from "../Base/Base";
import { type FlowItemBase } from "../types";
import EmailCard from "../../../../../../../components/cards/flowDesignerDropCard/EmailCard/EmailCard";
import type CommunicationTypes from "../../../../../../../enums/communicationTypes";
import { type FlowItemEmail } from "../../../types";

interface IEmailNodeOwnProps {
  type: CommunicationTypes.Email;
  data: FlowItemEmail & FlowItemBase;
}

const EmailNode: React.FC<IEmailNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <EmailCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(EmailNode);
