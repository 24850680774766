/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { batch, connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { type FormikProps } from "formik";
import { Navigate, Route, Routes } from "react-router-dom";

import { type EnumDictionary, type ISegment } from "../../../../../../interfaces";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import ModalManager from "../../../../../../utils/ModalManager";
import Observable from "../../../../../../utils/Observable";
import PublishSmtpSendingProfileValidationInfo from "../../../../../../components/publishValidationInfo/PublishSmtpSendingProfileValidationInfo";
import { DetailsHeader } from "../../../../../../components/sectionHeader";
import Segments from "../../../../../../components/navigation/segments/Segments";
import ModalTypes from "../../../../../../components/modal/ModalTypes";
import RevertConfirmationModalTs from "../../../../../../components/modal/RevertConfirmationModalTs";
import { RouteNames, PublishedStatusTypes } from "../../../../../../enums";
import SendingProfileDetailsSubheaderContainer from "./SendingProfileDetailsSubheaderContainer";
import { EditSendingProfileSteps } from "./types";
import { type ThreatDefenceSmtpAuthSendingProfileVerificationView } from "../types/state";
import * as detailsActions from "../state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";
import * as entityStateActions from "../state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import * as validationActions from "../state/thunks/sendingProfileSettingsValidationThunk";
import {
  sendingProfileSettingsValidationSelector,
  reset,
  discard,
} from "../state/slices/sendingProfileSettingsValidationSlice";
import { useWizardStepsManager } from "../../../../../../hooks/useWizardStepsManager";
import Configure from "../SmtpAuthSendingProfileDetails/Configure/Configure";
import Settings from "../SmtpAuthSendingProfileDetails/Settings/Settings";
import Verification from "../SmtpAuthSendingProfileDetails/Verification/Verification";
import { useRtn } from "../../../../../../hooks/useRtn";
import { withRouter, type WithRouterProps } from "../../../../../../adapters/withRouter/withRouter";

const sendingProfileOverviewUrl = `/${RouteNames.sendingProfilesSimulatedPhishing}`;

export type EditSmtpAuthSendingProfilePropsAll = PropsFromRedux &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileVerificationView> &
  WithRouterProps;

export const EditSmtpAuthSendingProfile: React.FC<EditSmtpAuthSendingProfilePropsAll> = (
  props: EditSmtpAuthSendingProfilePropsAll,
) => {
  const sendingProfileId = Number(props.params.id);
  const pageIndexes = [
    EditSendingProfileSteps.Configure,
    EditSendingProfileSteps.Settings,
    EditSendingProfileSteps.Verification,
  ];
  const [wizardPages, { goToPage }, subscribeOnActiveIndexChanged] = useWizardStepsManager(pageIndexes);
  const revertModalManager = useMemo(
    () =>
      new ModalManager(
        ModalTypes.RevertSmtpAuthSendingProfile,
        () => props.entityStateActions.discardSendingProfileEntity(sendingProfileId),
        false,
        props.userId,
      ),
    [sendingProfileId, props.userId],
  );
  const onDiscardedObserver = useMemo(() => new Observable<() => void>(), []);

  const loadSendingProfileInfo = () => {
    if (!props.isSendingProfileLoaded) {
      props.detailsActions.getSmtpAuthSendingProfile(sendingProfileId);
    }
  };

  const loadSendingProfileSettings = () => {
    if (!props.isSendigProfileSettingsLoaded) {
      props.detailsActions.getSmtpAuthSendingProfileSettings(sendingProfileId);
    }
    loadSendingProfileInfo();
  };

  const onClose = () => props.navigate(sendingProfileOverviewUrl);

  const onEditClick = () => props.entityStateActions.fetchDraftSendingProfileEntity(sendingProfileId);

  const onPublishClick = () => {
    props.validateActions.tryPublish(sendingProfileId);
  };

  const onRevertClick = () => revertModalManager.execute();
  const renderRevertModal = () =>
    props.hasBeenPublished && <RevertConfirmationModalTs modalManager={revertModalManager} />;

  const onPublishedOrDiscardEvent = () => {
    onDiscardedObserver.notify();
    props.onDiscard(sendingProfileId);
  };

  const renderCustomHeader = () => {
    const hasActiveErrors = !props.settingsResolved;
    const { navigate, location, isVerified } = props;

    const onErrorClick = () => {
      const redirectTo = `${sendingProfileOverviewUrl}/${sendingProfileId}/${
        pages[EditSendingProfileSteps.Settings].to
      }`;
      if (!location.pathname.includes(redirectTo)) {
        navigate(redirectTo);
        goToPage(EditSendingProfileSteps.Settings);
      }
    };

    const onVerifyClick = () => {
      const redirectTo = `${sendingProfileOverviewUrl}/${sendingProfileId}/${
        pages[EditSendingProfileSteps.Verification].to
      }`;
      if (!location.pathname.includes(redirectTo)) {
        navigate(redirectTo);
        goToPage(EditSendingProfileSteps.Verification);
      }
    };

    return (
      props.settingsErrorMode && (
        <PublishSmtpSendingProfileValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          isVerified={isVerified}
          onErrorButtonClick={onErrorClick}
          onVerifyButtonClick={onVerifyClick}
        />
      )
    );
  };

  const pages: EnumDictionary<EditSendingProfileSteps, ISegment> = useMemo(() => {
    return {
      [EditSendingProfileSteps.Configure]: {
        label: "Configure",
        onClick: () => {
          goToPage(EditSendingProfileSteps.Configure);
          loadSendingProfileInfo();
        },
        init: () => loadSendingProfileInfo(),
      },
      [EditSendingProfileSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => {
          goToPage(EditSendingProfileSteps.Settings);
        },
      },
      [EditSendingProfileSteps.Verification]: {
        to: "verification",
        label: "Verification",
        onClick: () => {
          goToPage(EditSendingProfileSteps.Verification);
          loadSendingProfileInfo();
        },
        init: () => loadSendingProfileInfo(),
      },
    };
  }, [sendingProfileId, props.isSendingProfileLoaded, props.detailsActions, goToPage]);

  useLayoutEffect(() => {
    const handler = (_: any, activeStepIndex: EditSendingProfileSteps) => {
      pages[activeStepIndex].init?.();
    };

    return subscribeOnActiveIndexChanged(handler);
  }, [pages, subscribeOnActiveIndexChanged]);

  useEffect(() => {
    loadSendingProfileSettings();
    return () => {
      props.onUnmount();
      revertModalManager.dispose();
    };
  }, []);

  useRtn(
    [rtnEvents.SmtpSendingProfilePublishSuccess, rtnEvents.SmtpSendingProfileDiscardSuccess],
    onPublishedOrDiscardEvent,
  );

  const [isValid, setIsValid] = useState(true);
  const [editDisabled, setEditDisabled] = useState(!props.isDraft);
  useEffect(() => {
    setEditDisabled(!props.isDraft);
  }, [props.isDraft]);

  const { sendingProfile } = props;
  return (
    <div className="nested-content scrollable-content">
      <DetailsHeader
        title={sendingProfile.senderName}
        titleForGA="Sending Profile Details"
        backButton={sendingProfileOverviewUrl}
        canBeEdited={editDisabled}
        isRevertVisible={sendingProfile.hasBeenPublished}
        publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(editDisabled)}
        invalidFormDetails={!isValid}
        entityStateActions={{
          onPublish: onPublishClick,
          onClose: onClose,
          onEdit: onEditClick,
          onRevert: onRevertClick,
        }}
        canBePublished={!editDisabled && props.settingsResolved}
      >
        {renderCustomHeader()}
      </DetailsHeader>
      {renderRevertModal()}
      <SendingProfileDetailsSubheaderContainer
        publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(editDisabled)}
        isUpdateInProgress={sendingProfile.isDraft}
        lastModifiedDateTime={sendingProfile.dateModified}
      >
        <Segments to={`/${RouteNames.sendingProfilesSimulatedPhishing}/${sendingProfileId}`}>
          <Segments.Segment {...pages[EditSendingProfileSteps.Configure]} />
          <Segments.Segment {...pages[EditSendingProfileSteps.Settings]} />
          <Segments.Segment {...pages[EditSendingProfileSteps.Verification]} />
        </Segments>
      </SendingProfileDetailsSubheaderContainer>
      <Routes>
        <Route
          path="/"
          element={
            <div className="edit-form">
              <Configure
                entityId={sendingProfileId}
                acceptHandlers={wizardPages[EditSendingProfileSteps.Configure]}
                onIsValidChange={setIsValid}
                disabled={editDisabled}
                {...props}
              />
            </div>
          }
        />
        <Route
          path={pages[EditSendingProfileSteps.Settings].to}
          element={
            <div className="edit-form">
              <Settings
                entityId={sendingProfileId}
                acceptHandlers={wizardPages[EditSendingProfileSteps.Settings]}
                subscribeOnDiscarded={onDiscardedObserver.subscribe}
                unSubscribeOnDiscarded={onDiscardedObserver.unsubscribe}
                onIsValidChange={setIsValid}
                disabled={editDisabled}
                {...props}
              />
            </div>
          }
        />
        <Route
          path={pages[EditSendingProfileSteps.Verification].to}
          element={
            <div className="edit-form">
              <Verification
                entityId={sendingProfileId}
                acceptHandlers={wizardPages[EditSendingProfileSteps.Verification]}
                disabled={editDisabled}
                {...props}
              />
            </div>
          }
        />
        <Route path="*" element={<Navigate to="../" replace />} />
      </Routes>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const sendingProfileDetailsReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer;
  const sendingProfileSettingsValidation = sendingProfileSettingsValidationSelector(state);

  return {
    sendingProfile: sendingProfileDetailsReducer.sendingProfile,
    isVerified: sendingProfileDetailsReducer.verification.isVerified,
    isSendingProfileLoaded: sendingProfileDetailsReducer.isSendigProfileLoaded,
    isSendigProfileSettingsLoaded: sendingProfileDetailsReducer.isSendigProfileSettingsLoaded,
    settingsErrorMode: sendingProfileSettingsValidation.errorMode,
    settingsResolved: sendingProfileSettingsValidation.resolved,
    hasBeenPublished: sendingProfileDetailsReducer.sendingProfile.hasBeenPublished,
    isDraft: sendingProfileDetailsReducer.sendingProfile.isDraft,
    userId: Number(state.userProfile.id),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(entityStateActions, dispatch),
  detailsActions: bindActionCreators(detailsActions, dispatch),
  validateActions: bindActionCreators(validationActions, dispatch),

  onDiscard: (id: number) => {
    batch(() => {
      dispatch(discard());
      dispatch(detailsActions.clearSmtpAuthSendingProfileVerificationStatusAction());
      dispatch(detailsActions.getSmtpAuthSendingProfile(id));
      dispatch(detailsActions.getSmtpAuthSendingProfileSettings(id));
    });
  },

  onUnmount: () => {
    dispatch(reset());
  },
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(EditSmtpAuthSendingProfile));
export default ConnectedComponent;
