import React from "react";
import type { ElementAndPosition, EntityType } from "../../../types";

interface ReactFlowCanvasContextProps {
  isReadOnly: boolean;
  flowId: number;
  headId: string | undefined;
  reactFlowWrapper: React.RefObject<HTMLDivElement> | null;
  reactFlowBounds: Omit<DOMRect, "toJSON">;
  showTriggers: boolean;
  itemToReplace: ElementAndPosition | undefined;
  itemForSectionHeader: ElementAndPosition | undefined;
  sectionHeaderView: string | undefined;
  flowEndView: string | undefined;
  draggedItemFromInspector: EntityType | undefined;
}

const defaultBounds = { x: 0, y: 0, left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0 };

export const ReactFlowCanvasContext = React.createContext<ReactFlowCanvasContextProps>({
  flowId: 0,
  isReadOnly: false,
  headId: undefined,
  reactFlowBounds: defaultBounds,
  reactFlowWrapper: null,
  showTriggers: false,
  sectionHeaderView: undefined,
  flowEndView: undefined,
  itemToReplace: undefined,
  itemForSectionHeader: undefined,
  draggedItemFromInspector: undefined,
});
