import { type FC } from "react";
import { useSelector } from "react-redux";

import { RolePermissions } from "../../../enums";
import { type RootState } from "../../../features/Application/globaltypes/redux";
import { type Group, type GroupActionHandlers } from "../../../interfaces";

import dateTimeUtils from "../../../utils/dateTimeUtils";
import SelectableCard from "../selectableCard/SelectableCard";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import CardContent from "../cardContent/CardContent";
import GroupEllipsisPopupButton from "../../people/groupEllipsisPopupButton/GroupEllipsisPopupButton";
import GroupIcons from "../../groupIcons/GroupIcons";

import "./groupCard.scss";

export interface Props extends GroupActionHandlers {
  item: Group;
  selected: boolean;
  disabled: boolean;
  url?: string;
  onSelect: (id: number) => void;
  handlers?: object;
  disablePopupMenu?: boolean;
}

const GroupCard: FC<Props> = ({
  item,
  onSelect,
  selected,
  url,
  onAddMembers,
  onAddToContent,
  onDelete,
  onDuplicate,
  onEdit,
  onEmailMembers,
}) => {
  const buttonHandlers: GroupActionHandlers = {
    onAddMembers,
    onAddToContent,
    onDelete,
    onDuplicate,
    onEdit,
  };
  const { name, canEdit, isAware, isOpen, isVisibleToAll, isWithAutoEnroll, dateCreated, imageUrl, id } = item;

  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);
  const hasGroupCreatePermissions = userPermissions.includes(RolePermissions.GroupsCreate);
  return (
    <SelectableCard
      id={id}
      className={"group-card"}
      selected={selected}
      disabled={!canEdit && !hasGroupCreatePermissions}
      url={url}
      onSelect={onSelect}
    >
      <CardThumbnail thumbnailUrl={imageUrl} selected={selected} />
      <CardContent title={name}>
        <div className="added-date">Added {dateTimeUtils.formatDate(dateCreated)}</div>
        <div className="bottom-row">
          <GroupIcons
            isAware={isAware}
            isOpen={isOpen}
            isVisibleToAll={isVisibleToAll}
            isWithAutoEnroll={isWithAutoEnroll}
          />
          {onEdit && (
            <GroupEllipsisPopupButton
              disabled={(!canEdit && !hasGroupCreatePermissions) || selected}
              group={item}
              {...buttonHandlers}
            />
          )}
        </div>
      </CardContent>
    </SelectableCard>
  );
};

export default GroupCard;
