import { LicenseConfirmationBlock } from "../licenseConfirmationBlock/LicenseConfirmationBlock";
import { type LicenseConfirmationData, type LicenseConfirmationItem } from "../../../../../interfaces";
import { type FC, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fetchAssignmentInfo, licensesResetAction } from "../../state/thunks/assignmentModalLicensingThunk";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { uniq } from "lodash";
import { Context } from "../../../../../enums/licenseContext";

interface LicenseConfirmationInfoProps {
  info?: LicenseConfirmationData;
  onIsDataValidChange?: (isValid: boolean) => void;
  licenseContext?: Context;
  setIssueLicensePackIds?: (packIds: number[]) => void;
}

export type LicenseConfirmationInfoAllProps = LicenseConfirmationInfoProps & PropsFromRedux;

export const LicenseConfirmationInfo: FC<LicenseConfirmationInfoAllProps> = ({
  info,
  items,
  isLoading,
  packIds,
  packIdForUserGroupLicensing,
  getAssignmentInfo,
  licensesReset,
  error,
  onIsDataValidChange,
  licenseContext = Context.AddPeopleToContent,
  setIssueLicensePackIds,
}) => {
  const getPackIds = (idsContext: Context) => {
    switch (idsContext) {
      case Context.AddPackToUsersOrGroups:
        return [packIdForUserGroupLicensing];
      case Context.AddPacksToUserOrGroup:
        return info?.packIds;
      default:
        return packIds;
    }
  };

  useEffect(() => {
    const packIdsActual = getPackIds(licenseContext);
    setIssueLicensePackIds && packIdsActual && setIssueLicensePackIds(uniq(packIdsActual));

    getAssignmentInfo(licenseContext, packIdsActual, info?.userIds, info?.groupIds, info?.flowId, info?.packId);

    return () => {
      licensesReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onIsDataValidChange &&
      onIsDataValidChange(
        !error &&
          !items.some((i: LicenseConfirmationItem) => i.isAccountPackLicenseExpired && i.neededLicenses > 0) &&
          !isLoading,
      );
  }, [error, onIsDataValidChange, items, isLoading]);

  const isWarningMode = items.some((i: LicenseConfirmationItem) => i.purchasingLicenses > 0);

  if (error) {
    return <div data-testid="error-info">Error loading data!</div>;
  }

  return (
    <div data-testid="confirmation-info">
      <div data-testid="confirmation-text">
        {isWarningMode && <div>These changes will consume licenses which will incur additional charges.</div>}
        <div>You are issuing the following licenses:</div>
      </div>
      <LicenseConfirmationBlock items={items} />
      <Loader active={isLoading} />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.licensing?.availableLicenses?.items ?? [],
    isLoading: state.licensing?.availableLicenses?.isLoading ?? false,
    error: state.licensing?.availableLicenses?.error,
    packIds: [
      ...state.licensing.contentPacks.items.map((i) => i.packId ?? 0),
      ...state.licensing.contentPacks.flowAssignments.flatMap((item) => item.packIds),
    ],
    packIdForUserGroupLicensing: state.packs.purchasedPack.packPurchasedView.pack.id,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  getAssignmentInfo: bindActionCreators(fetchAssignmentInfo, dispatch),
  licensesReset: bindActionCreators(licensesResetAction, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(LicenseConfirmationInfo);
export default ConnectedComponent;
