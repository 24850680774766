import { Button } from "components/buttons/button/Button";
import { useNavigate } from "react-router-dom";

import { RolePermissions } from "../../../../../enums";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";

import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";

export const CreateAssessmentButton = () => {
  const navigate = useNavigate();

  const onCreateBtnClick = () => navigate("create");

  const createAssessmentButton = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary className="create-assessment create-button" onClick={action} disabled={!hasAnyPermission}>
        Create Assessment
      </Button>
    </RestrictedByTooltip>
  );

  return (
    <RestrictedByAddOn
      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate]}
      addOnPermissions={[RolePermissions.AssetsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={onCreateBtnClick}
      renderContent={createAssessmentButton}
    />
  );
};
