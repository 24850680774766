import cn from "classnames";
import React, { type DragEvent, type MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  type Connection,
  type Edge,
  type OnSelectionChangeParams,
  useKeyPress,
  useOnSelectionChange,
  useReactFlow,
  useStore,
  type XYPosition,
} from "reactflow";
import AssetTypes from "../../../../../../enums/assetTypes";
import TriggerType from "../../../../../../enums/flowDesigner/triggerType";
import { type IFlowEdge, type IFlowNode, type IReactFlowEntityType } from "../nodes/types";
import { baseTriggerParams, startOfTheFlow } from "./useElements";
import { type EntityTriggerTypes, EntityType, type TriggerTimeUnit } from "../../types";
import { isEqual } from "lodash";
import { useGuidesOnCanvas } from "../../AlignmentSmartGuides/hooks/useGuidesOnCanvas";
import {
  defaultSectionHeader,
  DRAG_SHIFT,
  PLACEHOLDER_ELEMENT_ID,
  SECTION_HEADER_HEIGHT,
  START_OF_THE_FLOW_ELEMENT_ID,
} from "../../constants";
import { ExtrasTypes } from "../../Extras/types";
import { type EdgeSelectionChange } from "@reactflow/core";
import { useReactFlowCanvasState } from "../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowCanvasActions } from "../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useReactFlowActions } from "../Providers/ReactFlowStateProvider/hooks/useReactFlowActions";
import { ReactFlowCanvasContextEvents } from "../Providers/ReactFlowCanvasProvider/ReactFlowCanvasContextEvents";

const DEFAULT_NODE_WIDTH = 322;
const DEFAULT_NODE_HEIGHT = 116;

export const getSelectedNode = (nodes?: IFlowNode[]): IFlowNode | undefined => {
  return nodes?.find((el) => el.selected);
};

export const isOverlapStartOfTheFlow = (
  position: XYPosition | undefined,
  target?: HTMLElement,
  hasSectionHeader?: boolean,
) => {
  if (!position) {
    return false;
  }
  let nodeWidth = DEFAULT_NODE_WIDTH;
  let nodeHeight = DEFAULT_NODE_HEIGHT;

  const nodeHTMLElement = target?.closest<HTMLDivElement>(".react-flow__node");
  const x = startOfTheFlow.position.x;
  const y = startOfTheFlow.position.y + SECTION_HEADER_HEIGHT;

  if (nodeHTMLElement) {
    nodeWidth = nodeHTMLElement.offsetWidth;
    nodeHeight = nodeHTMLElement.offsetHeight - (hasSectionHeader ? SECTION_HEADER_HEIGHT : 0);
  }
  const dragPositionX = position.x;
  const dragPositionY = position.y;

  return (
    dragPositionX >= x - nodeWidth &&
    dragPositionX <= x + nodeWidth &&
    dragPositionY >= y - nodeHeight &&
    dragPositionY <= y + nodeHeight
  );
};

const getEdgesToSelect = (edges: IFlowEdge[], selectedNodes: IFlowNode[]) => {
  const edgesToSelect: EdgeSelectionChange[] = [];
  edges.forEach((edge) => {
    selectedNodes.forEach((node) => {
      if (node.id === edge.source && !edge.selected) {
        edgesToSelect.push({ id: edge.id, type: "select", selected: true });
      }
      if (node.id !== edge.source && edge.selected) {
        edgesToSelect.push({ id: edge.id, type: "select", selected: false });
      }
    });
  });
  return edgesToSelect;
};

export const isFlowEndDragging = (type?: IReactFlowEntityType | string) => isEqual(type, ExtrasTypes.FlowEnd);
export const ignoreRule = (target: HTMLElement) => target && target.closest && !!target.closest(".form");

export const useReactFlowCanvas = (
  reactFlowBounds: Omit<DOMRect, "toJSON">,
  triggerTypes: EntityTriggerTypes[],
  triggerTimeUnits: TriggerTimeUnit[],
  isReadOnly: boolean,
) => {
  const allowDeletion = useKeyPress(["Backspace", "Delete"]);

  const { headId, draggedItemFromInspector, sectionHeaderView } = useReactFlowCanvasState();
  const { project, getNode, getZoom, getNodes, getEdges } = useReactFlow();
  const { setNodes, setEdges } = useReactFlowActions();
  const zoom = getZoom();
  const {
    setHeadId,
    save,
    removeNode,
    setShowTriggers,
    setSectionHeader,
    setSectionHeaderView,
    setFlowEndView,
    removeSectionHeader,
  } = useReactFlowCanvasActions();

  const [isDragOver, setDragOver] = useState(false);
  const resetSelectedElements = useStore((actions) => actions.resetSelectedElements);
  const [isStartOfTheFlowHovered, setStartOfTheFlowHovered] = useState(false);
  const [isConnection, setConnection] = useState(false);
  const { trackNodeForHelperGuides, refreshViewPort } = useGuidesOnCanvas();

  const trackStartOfTheFlow = useCallback(
    (target: HTMLElement, node: IFlowNode) => {
      if (
        !headId &&
        isOverlapStartOfTheFlow(node.position, target, !!node.data?.sectionHeader) &&
        !isFlowEndDragging(node.type)
      ) {
        setHeadId(node.id);
        !node.data?.sectionHeader && setSectionHeader(node.id, defaultSectionHeader);
        setNodes((nodes) =>
          applyNodeChanges([{ id: node.id, position: startOfTheFlow.position, type: "position" }], nodes),
        );
      } else {
        setHeadId(headId === node.id ? undefined : headId);
      }
    },
    [setNodes, headId, setHeadId, setSectionHeader],
  );

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === START_OF_THE_FLOW_ELEMENT_ID) {
          return {
            ...node,
            className: cn({
              "sof-placeholder--hovered": isStartOfTheFlowHovered,
              "sof-placeholder--connected": headId,
              invalid: node.className?.includes("invalid"),
            }),
          };
        }
        return node;
      }),
    );
  }, [headId, isStartOfTheFlowHovered, setNodes]);

  useEffect(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === PLACEHOLDER_ELEMENT_ID) {
          return { ...node, data: { ...node.data, isActive: isDragOver } };
        }
        return node;
      });
    });
  }, [isDragOver, setNodes]);

  useEffect(() => {
    const selectedNode = getSelectedNode(getNodes());

    if (!selectedNode || !allowDeletion || isReadOnly) {
      return;
    }

    if (sectionHeaderView && headId !== selectedNode.id) {
      removeSectionHeader(selectedNode.id);
      setSectionHeaderView(undefined);
      resetSelectedElements();
    } else if (!sectionHeaderView) {
      removeNode(selectedNode.id);
      resetSelectedElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowDeletion]);

  const getDefaultTriggerTypes = useCallback(
    (connection: Edge | Connection, nodeToSelect?: IFlowNode): TriggerType | undefined => {
      const entityType = nodeToSelect?.data.elementType;

      if (!entityType) return TriggerType.Completion;

      const defaultTriggerTypes: {
        [key in EntityType]?: TriggerType;
      } = {
        [AssetTypes.Survey]: connection.sourceHandle ? TriggerType.Response : TriggerType.Completion,
        [AssetTypes.Assessment]: (() => {
          const targetNode = connection.target ? getNode(connection.target) : null;
          return isEqual(targetNode?.type, EntityType.FlowEnd) ? TriggerType.Completion : TriggerType.Pass;
        })(),
      };

      const itemTriggerTypes =
        triggerTypes.filter((trigger) => trigger.entityType === entityType)[0]?.triggerTypes ?? [];
      return defaultTriggerTypes[entityType] ?? itemTriggerTypes[0]?.id;
    },
    [getNode, triggerTypes],
  );

  const onConnect = useCallback(
    (connection: Edge | Connection) => {
      if (connection.source === connection.target) {
        return;
      }

      const nodeToSelect = connection.source ? getNode(connection.source) : undefined;
      const defaultTriggerType = getDefaultTriggerTypes(connection, nodeToSelect);
      const defaultUnitId = triggerTimeUnits[0]?.id || 0;
      const id = connection.sourceHandle
        ? `el-${connection.target}-${connection.source}-${connection.sourceHandle}`
        : `el-${connection.target}-${connection.source}`;

      const edgeToAdd = {
        ...connection,
        ...baseTriggerParams,
        id,
        selected: nodeToSelect?.selected,
        data: {
          inId: connection.source,
          outId: connection.target,
          bulletId: connection.sourceHandle,
          typeId: defaultTriggerType,
          timeSpan: 0,
          timeUnitId: defaultUnitId,
        },
      };
      setEdges((edges) => addEdge(edgeToAdd, edges));
      setNodes((nodes) => {
        return nodes.map((node) => ({
          ...node,
          selected: nodeToSelect?.id === node.id,
          data: {
            ...node.data,
            connected: {
              source: node.id === connection.source,
              target: node.id === connection.target,
            },
          },
        }));
      });
    },
    [triggerTimeUnits, setEdges, setNodes, getNode, getDefaultTriggerTypes],
  );

  const onConnectStart = useCallback(() => {
    setConnection(true);
  }, []);

  const onConnectEnd = useCallback(() => {
    setConnection(false);
    save();
  }, [save]);

  const flowEndViewChange = useCallback(
    (selectedNodes: IFlowNode[] | undefined) => {
      const flowEndNode = selectedNodes?.find((node) => node.type === ExtrasTypes.FlowEnd);
      setFlowEndView(flowEndNode?.id || undefined);
    },
    [setFlowEndView],
  );

  // timer was introduced because of https://brainstorm.atlassian.net/browse/SAAS-9582
  const trigDebounceTimer = useRef<NodeJS.Timeout | null>(null);

  useOnSelectionChange({
    onChange: (params: OnSelectionChangeParams) => {
      if (trigDebounceTimer.current) {
        clearTimeout(trigDebounceTimer.current);
      }
      if (params && (params.nodes.length || params.edges.length)) {
        const selectedNodes: IFlowNode[] = params.nodes;
        const edgesToSelect: EdgeSelectionChange[] = getEdgesToSelect(getEdges(), selectedNodes);

        edgesToSelect.length && setEdges((edges) => applyEdgeChanges(edgesToSelect, edges));

        if (trigDebounceTimer.current) {
          clearTimeout(trigDebounceTimer.current);
        }
        flowEndViewChange(selectedNodes);
        setShowTriggers(true);
      } else {
        trigDebounceTimer.current = setTimeout(() => setShowTriggers(false), 700);
      }
    },
  });

  const onPaneClick = useCallback(() => {
    setSectionHeaderView(undefined);
    setFlowEndView(undefined);
    setShowTriggers(false);
    resetSelectedElements();
  }, [setSectionHeaderView, setFlowEndView, setShowTriggers, resetSelectedElements]);

  const onDragOver = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      if (draggedItemFromInspector === ExtrasTypes.SectionHeader) {
        return;
      }
      // to-do: Optimize dragover, use only for staring zone hover
      !isDragOver && setDragOver(true);

      let position = project({
        x: event.clientX - reactFlowBounds.left - DRAG_SHIFT * zoom,
        y: event.clientY - reactFlowBounds.top - DRAG_SHIFT * zoom,
      });
      setStartOfTheFlowHovered(
        !isFlowEndDragging(draggedItemFromInspector) && isOverlapStartOfTheFlow(position, event.target as HTMLElement),
      );
    },
    [project, reactFlowBounds, draggedItemFromInspector, zoom, isDragOver],
  );

  const onNodeDragStart = useCallback(() => {
    refreshViewPort();
  }, [refreshViewPort]);

  const onNodeDrag = useCallback(
    (_event: MouseEvent, node: IFlowNode) => {
      _event.preventDefault();

      trackNodeForHelperGuides(node, { dragFinished: false });

      if (headId && node.id === headId) {
        setHeadId(undefined);
      }
      setStartOfTheFlowHovered(
        isOverlapStartOfTheFlow(node.position, _event.target as HTMLElement, !!node.data?.sectionHeader),
      );
    },

    [headId, setHeadId, trackNodeForHelperGuides],
  );

  const onNodeDragStop = useCallback(
    (_event: MouseEvent, node: IFlowNode) => {
      trackNodeForHelperGuides(node, { dragFinished: true });
      if (node.dragging) {
        trackStartOfTheFlow(_event.target as HTMLElement, node);
        setStartOfTheFlowHovered(false);
        save();
      }
    },
    [save, trackStartOfTheFlow, setStartOfTheFlowHovered, trackNodeForHelperGuides],
  );

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      if (isReadOnly) return;

      setDragOver(false);
      setStartOfTheFlowHovered(false);
      save();
    },
    [save, isReadOnly],
  );

  const onDragLeave = useCallback((event: DragEvent) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const onEdgeUpdateStart = useCallback(
    (_event: any, edgeToRemove: IFlowEdge) => {
      const connected = { target: false, source: false };
      setEdges((edges) => applyEdgeChanges([{ id: edgeToRemove.id, type: "remove" }], edges));
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === edgeToRemove.data?.outId) {
            const hasActionEdge = getEdges().some(
              (edge) => edgeToRemove.id !== edge.id && edge.data.outId === node.id && edge.data?.isAction,
            );
            return { ...node, data: { ...node.data, canConnect: !hasActionEdge, connected } };
          }
          if (node.id === edgeToRemove.data?.inId) {
            return { ...node, data: { ...node.data, connected } };
          }

          return node;
        }),
      );
      save();
      onConnectStart();
    },
    [save, onConnectStart, setNodes, setEdges, getEdges],
  );

  const onEdgeUpdate = useCallback(
    (_oldEdge: IFlowEdge, newConnection: Edge | Connection) => {
      onConnect(newConnection);
      onConnectEnd();
    },
    [onConnect, onConnectEnd],
  );

  return {
    headId,
    isConnection,
    sectionHeaderView,
    setSectionHeaderView,
    setFlowEndView,
    onConnect,
    onConnectStart,
    onPaneClick,
    onConnectEnd,
    onNodeDrag,
    onNodeDragStop,
    onDrop,
    onDragOver,
    onDragLeave,
    onEdgeUpdateStart,
    onEdgeUpdate,
    onNodeDragStart,
  };
};

export function useReactFlowCanvasEventEmitter() {
  return React.useContext(ReactFlowCanvasContextEvents);
}
