import { type FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { ValidatedForm } from "../../../../../../components";
import { type RootState } from "../../../../../Application/globaltypes/redux";
import { type ThreatDefenceEmailTemplateContent } from "../../types/state";
import { type ContentEmailTemplateProps } from "../types";
import * as emailTemplateEntityStateActions from "../../state/actions/threatDefenceEmailTemplateEntityStateActions";
import * as emailTemplateDetailsActions from "../../state/actions/threatDefenceEmailTemplateDetailsActions";
import validationSchemas from "../../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { type AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ContentAllProps = ContentEmailTemplateProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceEmailTemplateContent> &
  AutosaveProps;

export class Content extends Component<ContentAllProps> {
  componentDidMount() {
    this.props.acceptHandlers?.({
      onPrevious: this.onPrevious,
      onFinish: this.onFinish,
      onClose: this.onClose,
    });

    // Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onPrevious = () => {
    this.props.isValid && this.saveEmailTemplate();
  };

  onPublishEmail = () => {
    this.props.entityStateActions.publishDraftThreatDefenceEmailTemplateEntity(this.props.id);
  };

  onFinish = () => {
    this.onPublishEmail();
  };

  onClose = () => {
    this.props.detailsActions.clearThreatDefenceEmailTemplate();
  };

  saveEmailTemplate = () => {
    this.props.detailsActions.saveThreatDefenceEmailTemplateContent(this.props.values);
  };

  static getEmailTemplateContent = (values: ThreatDefenceEmailTemplateContent): ThreatDefenceEmailTemplateContent => {
    return {
      id: values.id,
      html: values.html?.trim(),
      contentUrlWithSas: values.contentUrlWithSas,
    };
  };

  render() {
    const { dirty, isLoading, isLoaded } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        isLoading={isLoading || !isLoaded}
        formWidthComputer={6}
        disablePreventTransitionPrompt
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onIsValidChange, onBlur } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="email-form-container">
        <ValidatedForm.TextAreaField
          label="Body"
          value={values.html}
          propertyName="html"
          markAsRequired
          placeholder=""
          disabled={disabled}
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentEmailTemplateProps) => {
  const detailsReducer = state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer;
  const id =
    ownProps.entityId ||
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.entityId;
  return {
    id,
    content: {
      id,
      html: detailsReducer.html,
      contentUrlWithSas: detailsReducer.contentUrlWithSas,
    },
    isLoaded: detailsReducer.isEmailContentLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating:
      state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.changingEntityState,
    isDraft: detailsReducer.email.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(emailTemplateEntityStateActions, dispatch),
  detailsActions: bindActionCreators(emailTemplateDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<
  ContentAllProps,
  ThreatDefenceEmailTemplateContent,
  ThreatDefenceEmailTemplateContent
>({
  getInitValues: (props) => props.content,
  stateProvider: Content.getEmailTemplateContent,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceEmailTemplateContent,
  isPeriodicUpdateEnabled: false,
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.emailTemplateContent,
  enableReinitialize: true,
  isInitialValid: (props: ContentEmailTemplateProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.emailTemplateContent, props.content),
  mapPropsToValues: (props: ContentEmailTemplateProps & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

const ConnectedComponent = connector(component);
export default ConnectedComponent;
