import { FlowFiltersEnum } from "features/Library/Flows/types/models";
import { type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";

export const getFlowFilters = (
  includePublisher: boolean = true,
  disableDateFilters: boolean = false,
): FilterItemBase<FlowFiltersEnum>[] => {
  let filters: FilterItemBase<FlowFiltersEnum>[] = [
    {
      type: FilterViewTypes.DateRange,
      label: "Date Added",
      propertyName: FlowFiltersEnum.DateCreated,
      otherProps: { disabled: disableDateFilters },
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Last Modified",
      propertyName: FlowFiltersEnum.DateModified,
      otherProps: { disabled: disableDateFilters },
    },
  ];

  if (includePublisher) {
    filters.push({
      type: FilterViewTypes.MultiSelect,
      label: "Publisher",
      propertyName: FlowFiltersEnum.Publishers,
      placeholder: "All Publishers",
    });
  }

  filters.push(
    {
      type: FilterViewTypes.MultiSelect,
      label: "Packs",
      propertyName: FlowFiltersEnum.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Software Applications",
      propertyName: FlowFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Labels",
      propertyName: FlowFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  );

  filters.push({
    type: FilterViewTypes.MultiSelect,
    label: "Objective",
    propertyName: FlowFiltersEnum.GoalObjectives,
    placeholder: "All Objectives",
  });
  filters.push({
    type: FilterViewTypes.MultiSelect,
    label: "Type",
    propertyName: FlowFiltersEnum.GoalTypes,
    placeholder: "All Types",
  });

  return filters;
};
