import React from "react";
import BasicLinkButton from "../../../../../../components/buttons/linkButtons/baseLinkButton/BasicLinkButton";

import styles from "./startFlowButton.module.scss";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../../../../../components/common/tooltip";

export interface StartFlowButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

const INFO_TEXT = "Only Flows starting with communication assets can be started manually.";

const StartFlowButton = (props: StartFlowButtonProps) => {
  const { onClick, isDisabled } = props;
  return (
    <div className={styles.root}>
      <Tooltip
        hideOnScroll
        content={INFO_TEXT}
        showAlways={isDisabled}
        position="bottom right"
        tooltipClassName={styles["start-flow-tooltip"]}
        target={
          <div className={styles.content}>
            <BasicLinkButton text="Start Flow" iconClassName="play" onClick={onClick} isDisabled={isDisabled} />
            {isDisabled && <Icon className="fa fa-exclamation-circle" size="large" />}
          </div>
        }
      />
    </div>
  );
};

export default StartFlowButton;
