import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";

import "./elasticLoaderDimmer.scss";

function ElasticLoaderDimmer(props) {
  const { isActive, label, size } = props;

  return (
    <Dimmer className="elastic-loader-dimmer" active={isActive} inverted>
      <Loader className="elastic" content={<div className="text">{label}</div>} active={isActive} size={size} />
    </Dimmer>
  );
}

ElasticLoaderDimmer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(["mini", "tiny", "small", "medium", "large", "big", "huge", "massive"]),
};

ElasticLoaderDimmer.defaultProps = {
  size: "massive",
};

export default ElasticLoaderDimmer;
