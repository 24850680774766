import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { type IWizardStep, bindAction } from "interfaces";
import { useEffect } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { reset, setCustomersToAdd, setSearch } from "../state/TemplateAvailableCustomersSlice";
import { getAvailableCustomers, saveCustomers } from "../state/thunks/TemplateAvailableCustomersThunk";
import { useEventCallback } from "hooks/useEventCallback";
import { GroupTemplateCustomers } from "../Details/GroupTemplateCustomers";
import { createColumnOptions } from "../Details/Customers/getColumnOptions";

type Props = IWizardStep & PropsFromRedux;

export const CreateCustomers = (props: Props) => {
  const {
    acceptHandlers,
    saveCustomers,
    groupId,
    selectedIds,
    setSelectedIds,
    items,
    isLoading,
    itemsCount,
    getData,
    reset,
    onSearchChange,
  } = props;

  const saveCustomersCb = useEventCallback(async () => {
    await saveCustomers(groupId);
    reset();
  }, [groupId]);

  useEffect(() => {
    acceptHandlers?.({
      onClose: reset,
      onFinish: saveCustomersCb,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GroupTemplateCustomers
      getData={getData}
      items={items}
      isLoading={isLoading}
      itemsCount={itemsCount}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onSearchChange={onSearchChange}
      columnOptions={createColumnOptions}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    groupId: state.people.groupTemplate.base.groupId,
    items: state.people.groupTemplate.availableCustomers.items,
    itemsCount: state.people.groupTemplate.availableCustomers.itemsCount,
    isLoading: state.people.groupTemplate.availableCustomers.isLoading,
    selectedIds: state.people.groupTemplate.availableCustomers.customersToAdd,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSelectedIds: (ids: number[]) => dispatch(setCustomersToAdd(ids)),
    getData: bindAction(getAvailableCustomers, dispatch),
    saveCustomers: (templateId: number) => dispatch(saveCustomers(templateId)),
    reset: () => dispatch(reset()),
    onSearchChange: (search: string) => dispatch(setSearch(search)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(CreateCustomers);
export default ConnectedComponent;
