import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "features/Application/slices/createFetchingItemsSlice";
import { type CountryModelItem } from "features/People/types";
import { fetchCountries } from "../../thunks/addUserThunk";

export const initialState: FetchingItemsState<CountryModelItem> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const countriesSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Countries",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  thunk: fetchCountries,
  reducers: {},
});

export default countriesSlice.reducer;
