import { Tooltip } from "components/common/tooltip";
import Form from "components/forms/layout";
import { Icon } from "semantic-ui-react";
import { ValidatedForm } from "../../../../components";
import { type UsageIntegrationConfig } from "../types";
import "./integrationConfiguration.scss";
import { getImportUsageDataTooltipMessage, getSyncUsersTooltipMessage } from "./tooltipsHelpers";

export interface IntegrationConfigurationProps {
  isCreating?: boolean;
  integrationType: string;
  integrationName: string;
  setConfigs: Function;
  configs: UsageIntegrationConfig;
}

const IntegrationConfiguration: React.FC<IntegrationConfigurationProps> = (props) => {
  const { isCreating, integrationType, integrationName, setConfigs, configs } = props;

  const setFieldValue = (id: string) => {
    let changedConfigsState: UsageIntegrationConfig = {
      ...configs,
    };

    switch (id) {
      case "syncUsers":
        if (configs.syncUsers && !configs.importUsageData) {
          changedConfigsState.importUsageData = !configs.importUsageData;
        }
        changedConfigsState.syncUsers = !configs.syncUsers;
        break;
      case "importUsageData":
        if (configs.importUsageData && !configs.syncUsers) {
          changedConfigsState.syncUsers = !configs.syncUsers;
        }
        changedConfigsState.importUsageData = !configs.importUsageData;
        break;
      case "isActive":
        changedConfigsState.isActive = !configs.isActive;
        break;
    }
    setConfigs({ ...changedConfigsState });
  };

  return (
    <>
      <div className="integration-configuration-block">
        {isCreating && <Form.Block title="Integration type">{integrationName}</Form.Block>}
        <div className="options-block">
          <div className="options-title">
            <span>
              <b>SETTINGS</b>
              (At least one selection must be made)
            </span>
          </div>
          <div className="option-with-tooltip">
            <ValidatedForm.CheckboxField
              setFieldValue={setFieldValue}
              toggle
              label="Sync Users"
              value={configs.syncUsers}
              propertyName="syncUsers"
            />
            <Tooltip
              hoverable
              tooltipClassName="integraion-setting-tooltip"
              target={<Icon className="info circle" />}
              content={getSyncUsersTooltipMessage(integrationType)}
              position="top center"
              hideOnScroll
              showAlways
            />
          </div>
          <div className="option-with-tooltip">
            <ValidatedForm.CheckboxField
              setFieldValue={setFieldValue}
              toggle
              label="Import Usage Data"
              value={configs.importUsageData}
              propertyName="importUsageData"
            />
            <Tooltip
              hoverable
              tooltipClassName="integraion-setting-tooltip"
              target={<Icon className="info circle" />}
              content={getImportUsageDataTooltipMessage(integrationType)}
              position="top center"
              hideOnScroll
              showAlways
            />
          </div>
        </div>
        <div className="option-with-tooltip">
          <ValidatedForm.CheckboxField
            setFieldValue={setFieldValue}
            toggle
            label="Make active when finished"
            value={configs.isActive}
            propertyName="isActive"
          />
          <Tooltip
            hoverable
            tooltipClassName="integraion-setting-tooltip"
            target={<Icon className="info circle" />}
            content={
              "If this feature is enabled, the integration will be active and sync daily. If disabled, synchronizing with the BrainStorm platform will be paused."
            }
            position="top center"
            hideOnScroll
            showAlways
          />
        </div>
      </div>
    </>
  );
};

export default IntegrationConfiguration;
