import axios from "axios";
import {
  type BaseIntegrationConfigs,
  type GoogleIntegrationConfigs,
  type Integration,
  IntegrationTypes,
  type MsGraphIntegrationConfigs,
  type WebexIntegrationConfigs,
} from "../Integrations/types";
import { type SfIntegrationConfigs } from "../Integrations/types/successFactors";

import { type VivaIntegrationConfigs } from "../Integrations/types/viva";

export const integrationApiUrls = {
  root: "/api/accounts/integrations",
  msgraph: `api/integrations/${IntegrationTypes.MsGraph}`,
  google: `api/integrations/${IntegrationTypes.Google}`,
  webex: `api/integrations/${IntegrationTypes.Webex}`,
  viva: `api/integrations/${IntegrationTypes.Viva}`,
  successfactors: `api/integrations/${IntegrationTypes.SuccessFactors}`,
};

const service = {
  async authorizeMsGraphIntegration(syncUsers: boolean, importUsageData: boolean, importLicenses: boolean, importGroups: boolean) {
    const response = await axios.post(`${integrationApiUrls.msgraph}/authorize`, {
      syncUsers,
      importUsageData,
      importLicenses,
      importGroups
    });

    return response.data;
  },

  async authorizeGoogleIntegration(syncUsers: boolean, importUsageData: boolean, redirect: string) {
    const response = await axios.post(`${integrationApiUrls.google}/authorize`, {
      syncUsers,
      importUsageData,
      redirect,
    });

    return response.data;
  },

  async authorizeWebexIntegration(syncUsers: boolean, importUsageData: boolean, redirect: string) {
    const response = await axios.post(`${integrationApiUrls.webex}/authorize`, {
      syncUsers,
      importUsageData,
      redirect,
    });

    return response.data;
  },

  async authorizeVivaIntegration(redirect: string) {
    const response = await axios.post(`${integrationApiUrls.viva}/authorize`, {
      redirect,
    });

    return response.data;
  },

  async updateMsGraphIsActive(isActive: boolean) {
    return axios.put(`${integrationApiUrls.msgraph}/is-active`, {
      isActive,
    });
  },

  async getIntegrationSettings(MoboId?: string): Promise<Array<Integration>> {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.get(integrationApiUrls.root, headers);
    return response.data;
  },

  async deleteIntegrations(types: string[], MoboId?: string) {
    const headers = MoboId
      ? {
          headers: { MoboId: MoboId },
          data: {
            types,
          },
        }
      : {
          data: {
            types,
          },
        };

    await axios.delete(integrationApiUrls.root, headers);
  },

  async updateIntegrationsIsActiveState(types: string[], isActive: boolean, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    await axios.put(
      `${integrationApiUrls.root}/state`,
      {
        isActive,
        types,
      },
      headers,
    );
  },

  async createMsGraphIntegration(configs: MsGraphIntegrationConfigs, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.post(integrationApiUrls.msgraph, configs, headers);
  },

  async createGoogleIntegration(configs: GoogleIntegrationConfigs, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.post(integrationApiUrls.google, configs, headers);
  },

  async createWebexIntegration(configs: WebexIntegrationConfigs, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.post(integrationApiUrls.webex, configs, headers);
  },

  createVivaIntegration(configs: VivaIntegrationConfigs, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.post(integrationApiUrls.viva, configs, headers);
  },

  getIntegrationConfigs<T extends BaseIntegrationConfigs>(type: IntegrationTypes, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.get<T>(integrationApiUrls[type], headers);
  },

  updateIntegrationConfigs<T>(type: IntegrationTypes, configs: T, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.put(
      integrationApiUrls[type],
      {
        ...configs,
      },
      headers,
    );
  },

  createSfIntegration(configs: SfIntegrationConfigs, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    return axios.post(integrationApiUrls.successfactors, configs, headers);
  },
};

export default service;
