import React, { Component } from "react";
import { type FormikProps } from "formik";
import { ValidatedForm } from "../../../../../components/forms";
import { type EventView } from "../../types/state";
import { type EventFormPropsOwn } from "../types";
import "./EventForm.scss";
import EndpointsToGetSasLink from "../../../../../enums/endpointsToGetSasLink";

export type EventFormProps = EventFormPropsOwn & FormikProps<EventView>;

const descriptionName = "description";

export default class EventForm extends Component<EventFormProps> {
  onTitleBlur = (propertyName: string) => {
    const { values, setFieldValue, onBlur, touched } = this.props;
    if (values.id <= 0 && !touched[descriptionName]) {
      setFieldValue(descriptionName, values.title, true);
    }

    onBlur(propertyName);
  };

  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message:
            "Are you sure you want to exit without saving this external event? All information entered will be lost.",
        }}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
        isLoading={isLoading}
        formWidthComputer={10}
        parentWithFormik
      >
        {this.renderFields.bind(this)}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, onBlur, onIsValidChange, disabled } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="event-form-container">
        <div className="info-block">
          <ValidatedForm.InputField
            label="Title"
            tooltip={{
              info: "This field is visible to end users.",
              width: 17,
            }}
            placeholder="Enter Event Title"
            value={values.title}
            propertyName="title"
            onBlur={this.onTitleBlur}
            markAsRequired
            onIsFormValidChange={onIsValidChange}
            disabled={disabled}
            {...validatedFieldProps}
          />
          <ValidatedForm.TextAreaField
            label="Description"
            tooltip={{
              info: "This field is visible to users and is displayed by the title.",
              width: 17,
            }}
            placeholder="Enter Description"
            value={values.description}
            propertyName="description"
            hidden
            onBlur={onBlur}
            markAsRequired
            disabled={disabled}
            {...validatedFieldProps}
          />
          <ValidatedForm.ImagePreview
            label="Thumbnail Image"
            value={values.thumbnailUrl}
            propertyName="thumbnailUrl"
            endpointToGetSasLink={EndpointsToGetSasLink.ExternalEvent}
            markAsRequired
            onImageChanged={onBlur}
            {...validatedFieldProps}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
