import { Label, type Color } from "components/common/label/Label";

export interface PackPrivateLabelProps {
  isPrivate: boolean;
}

export const PackPrivateLabel: React.FC<PackPrivateLabelProps> = (props) => {
  const { isPrivate } = props;

  const text = isPrivate ? "private" : "public";

  return <Label {...getLabelProps(isPrivate)} text={text.toUpperCase()} />;
};

const getLabelProps = (isPrivate: boolean): { color: Color; icon?: string } =>
  isPrivate ? { color: "gray", icon: "fa-lock" } : { color: "darker-green", icon: "fa-lock-open" };
