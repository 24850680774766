import { type IFlowEdge, type IFlowNode } from "../ReactFlowCanvas/nodes/types";
import { type IItemsToUpdate } from "../AssetInspector/AssetInspector";

export const setActionNode = (edges: IFlowEdge[], nodes: IFlowNode[], itemsToUpdate: IItemsToUpdate): IFlowNode[] => {
  return nodes.map((node) => {
    const itemToUpdate = itemsToUpdate[node.id];
    const hasActionEdge = edges.some(({ data }) => node.id === data?.outId && data.isAction);
    return {
      ...node,
      data: {
        ...node.data,
        canConnect: itemToUpdate ? !hasActionEdge && itemToUpdate?.canConnect : !hasActionEdge,
      },
    };
  });
};
