import { type Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/pdfUsersSlice";
import { type GetContentUsersParams, v2FilterMap } from "../../../../People/types";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { formatFiltersV2api, mapToV2Filters } from "../../../../../utils/queryUtils";
import { escapeTerm } from "../../../../../utils/searchUtils";

export const fetchPdfUsers =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const filters = formatFiltersV2api(mapToV2Filters(filter, v2FilterMap));
      const searchTerm = escapeTerm(term);
      const request = { skip, top, sortBy: sortingColumn, sortOrder: sortingDirection, term: searchTerm, ...filters };
      const { items, count } = await DataService.pdfs.getPdfUsersAsync(entityId, request);
      dispatch(fetchSuccess({ items, totalCount: count }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
