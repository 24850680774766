import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import { type Filters } from "../../../../utils/queryUtils";
import { PacksFilterForm } from "../../../../components/filterForms";
import { type PackOverviewState, type PacksOverviewOwnProps, type PublisherInfo } from "./types";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import columnOptions from "./columnOptions";
import { SearchInput } from "../../../../components";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { ItemsView } from "../../../../views";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import {
  SortingDirection,
  RolePermissions,
  RouteNames,
  ViewType,
  PublishedStatusTypes,
  SortOptions,
  ItemsTypes,
} from "../../../../enums";
import packFetchDataUtils from "../../../../utils/licensingFetchDataUtils";
import sortOptions from "./sortOptions";
import {
  type EnablePackTrialRequest,
  type PackOverview,
  type PackRequest,
  type EnablePackRequest,
} from "../types/state";
import PacksNoResults from "../../../../views/packs/Overview/PacksNoResults";
import * as packsOverviewActions from "../state/actions/packsOverviewActions";
import * as packEntityStateActions from "../state/actions/packEntityStateActions";
import PackCard from "../../../../components/cards/packCard/PackCard";
import { Title } from "../../../../components/listViewTemplates";
import PackEllipsisPopupButton from "../../../../components/packs/PackEllipsisPopupButton";
import Restricted from "../../../Application/Restricted";
import { PackTypeLabel } from "../../../../components/packs/packTypeLabel/PackTypeLabel";
import { packsOverviewSearchSelector, setTerm } from "../state/slices/packsSearchSlice";
import {
  packsOverviewFiltersSlice,
  resetAppliedFilter,
  setAppliedFilter,
  packsOverviewFiltersSelector,
} from "../state/slices/packsOverviewFiltersSlice";
import { getFilterOptions } from "../state/thunks/packsFiltersThunk";
import { fetchOverviewGridPacks } from "../state/thunks/packsOverviewThunk";
import {
  markCardAsPurchased,
  markCardAsRequested,
  markCardAsTrialStarted,
  reset,
} from "../state/slices/packsOverviewSlice";
import * as packListingActions from "../state/thunks/packListingThunk";
import { ownAccountNameSelector } from "../state/slices/ownAccountInfoSlice";
import { fetchOwnAccountInfo } from "../state/thunks/ownAccountInfoThunk";
import { bindAction } from "../../../../interfaces";
import { RequestPackModals } from "../PackRequestModals/RequestPackModals";
import packsDataService from "../services/packsDataService";
import DeletePackConfirmationDialog from "../../../../components/packs/deletePackConfirmationDialog/DeletePackConfirmationDialog";
import * as backgroundTaskOperations from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as notificationOperations from "../../../Notifications/state/notificationsActions";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { AddAllPacksToOwnAccountSuccess } from "../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { type CardsViewerItem } from "../../../../components/cardsViewer/types";

import "./packsOverview.scss";

const defaultSortColumn = "datecreated";

export type PacksOverviewProps = PacksOverviewOwnProps & PropsFromRedux & WithRouterProps;

export class PacksOverview extends Component<PacksOverviewProps, PackOverviewState> {
  constructor(props: PacksOverviewProps) {
    super(props);
    this.state = {
      selectedViewType: ViewType.GRID,
      gridOrderBy: SortOptions.CreatedDateDesc,
      currentRequestPackId: 0,
      currentRequestPackTitle: "",
      isTrialRequest: false,
      trialDuration: 0,
      packUsageCount: 0,
      packIdToDelete: 0,
      triggerOpenDeletePackDialog: false,
    };
  }

  componentDidMount() {
    this.resetPacks({});
    this.props.fetchOwnAccountInfo();
    RtnEventsEmitter.subscribe([AddAllPacksToOwnAccountSuccess], this.refreshGridItems);
  }

  componentWillUnmount() {
    this.props.resetFilters();
    this.props.setSearch("");
    RtnEventsEmitter.unsubscribe([AddAllPacksToOwnAccountSuccess], this.refreshGridItems);
  }

  handleTitleClick = (id: number, isOwn: boolean, isPurchased: boolean, publisherInfo: PublisherInfo) => {
    if (isOwn) {
      this.handleEditClick(id, true);
    } else {
      const path = `/${RouteNames.licensingPacks}/${isPurchased ? "purchased" : "listings"}/${id}`;
      this.props.navigate(path, {
        state: {
          from: `/${RouteNames.licensingPacks}/${RouteNames.available}`,
          publisher: publisherInfo.name,
          logoUri: publisherInfo.logoUri,
        },
      });
    }
  };

  buildTableBody = (pack: PackOverview) => {
    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <Title
            title={pack.title}
            onTitleClick={() =>
              this.handleTitleClick(pack.id, pack.isOwn, pack.isPurchased, {
                name: pack.publisher,
                logoUri: pack.logoUri,
              })
            }
            clamp={2}
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!pack.isDraft)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(pack.dateCreated)}</Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(pack.dateModified)}</Table.Cell>
        <Table.Cell width={columnOptions[4].width}>
          <PackTypeLabel packType={pack.type} />
        </Table.Cell>
        <Table.Cell width={columnOptions[5].width}>
          <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
            {pack.isOwn && (
              <PackEllipsisPopupButton
                onEditClicked={() => this.handleEditClick(pack.id, pack.isDraft)}
                outlinedEllipsis
                circle
              />
            )}
          </Restricted>
        </Table.Cell>
      </>
    );
  };

  goToPackEdit = (payload: { id: number }) => this.props.navigate(`/${RouteNames.licensingPacks}/${payload.id}`);

  handleEditClick = (id: number, isDraft?: boolean) => {
    if (!isDraft) {
      this.props.packEntityStateActions.fetchDraftPackEntity(id);
    }
    this.goToPackEdit({ id });
  };

  handleDeleteClick = async (packId: number) => {
    let usageCount = await packsDataService.getPackUsageCount(packId);
    this.setState({
      packUsageCount: usageCount,
      packIdToDelete: packId,
      triggerOpenDeletePackDialog: true,
    });
  };

  onCancelPackDelete = () => {
    this.setState({
      packIdToDelete: 0,
      triggerOpenDeletePackDialog: false,
    });
  };

  onContinuePackDelete = async () => {
    this.setState({
      triggerOpenDeletePackDialog: false,
    });

    const { packIdToDelete } = this.state;

    const params = {
      id: "DeletePack",
      title: `Deletion of pack`,
      getMessageIds: async () => {
        const { data } = await packsDataService.deletePack(packIdToDelete);
        return [data];
      },
      onCompleted: this.reloadItems,
      successTransientMessage: `Pack was deleted!`,
      failureTransientMessage: `Pack delete failed!`,
    };

    const { addOperationV1 } = this.props.backgroundTasksActions;
    const { sendTransientNotification } = this.props.notificationsActions;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  handlePreviewClick = (id: number, publisherInfo: PublisherInfo) => {
    this.props.navigate(`/${RouteNames.licensingPacks}/listings/${id}`, {
      state: {
        from: `/${RouteNames.licensingPacks}`,
        publisher: publisherInfo.name,
        logoUri: publisherInfo.logoUri,
      },
    });
  };

  resetPacks = async (filters: Filters) => {
    if (this.state.selectedViewType === ViewType.GRID) {
      this.props.gridActions.resetGrid();
      this.getGridData(0, this.state.gridOrderBy, filters, this.props.search.term);
    }
  };

  getGridData = (skip: number, orderBy: SortOptions, filters: Filters, searchTerm?: string) => {
    this.state.getGridDataRequest && this.state.getGridDataRequest.abort();

    const filtersWithOwnership = {
      ...filters,
      ownership: this.props.ownership,
    };

    const request = this.props.gridActions.fetchPacksOverviewGrid({
      accountId: this.props.accountId,
      skip: skip,
      orderBy: orderBy,
      filters: filtersWithOwnership,
      searchTerm: searchTerm,
    });
    this.setState({ getGridDataRequest: request });
  };

  applyFilter = async (filters: Filters) => {
    this.resetPacks(filters);
    this.props.applyFilters(filters);
  };

  resetFilter = async () => {
    if (!isEmpty(this.props.packsOverviewFilters.appliedFilter)) {
      this.resetPacks({});
      this.props.resetFilters();
    }
  };

  getFilterOptions = () => {
    this.props.getFilters();
  };

  getPacks = (skip?: number, top?: number, sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    const { packsOverviewFilters, search } = this.props;

    if (this.state.selectedViewType === ViewType.GRID) {
      const loadedPacksCount = this.props.gridState.items.length;
      this.getGridData(loadedPacksCount, this.state.gridOrderBy, packsOverviewFilters.appliedFilter, search.term);
    } else {
      let orderParams = packFetchDataUtils.formatOrderParams(
        sortingColumnName || defaultSortColumn,
        sortingDirection || SortingDirection.Descending,
      );
      this.props.packsOverviewActions.getPacksAsync(
        skip ?? 0,
        top ?? 10,
        orderParams,
        packsOverviewFilters.appliedFilter,
        search.term,
      );
    }
  };

  onSortChange = (_: any, data: any) => {
    this.props.gridActions.resetGrid();
    this.setState(
      {
        gridOrderBy: data.value,
      },
      this.getPacks,
    );
  };

  onSearchChange = (search: string) => {
    this.props.setSearch(search);
    this.props.gridActions.resetGrid();
    this.getGridData(0, this.state.gridOrderBy, this.props.packsOverviewFilters.appliedFilter, search);
  };

  createReloadListMethod = (reloadItems: () => void) => {
    this.reloadItems = reloadItems;
  };

  reloadItems?: () => void;

  refreshGridItems = () => {
    const { packsOverviewFilters, search } = this.props;
    this.props.gridActions.resetGrid();
    this.getGridData(0, this.state.gridOrderBy, packsOverviewFilters.appliedFilter, search.term);
  };

  renderCreatePackBtn = (): React.ReactElement | null => {
    return (
      <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
        <Button
          primary
          className="create-pack create-button"
          onClick={() => {
            this.props.navigate("/licensing/packs/create");
          }}
        >
          Create Pack
        </Button>
      </Restricted>
    );
  };

  showPackRequestModal = (
    currentRequestPackId: number,
    currentRequestPackTitle: string,
    isTrialRequest: boolean,
    trialDuration: number,
  ) => {
    this.setState({
      currentRequestPackId,
      currentRequestPackTitle,
      isTrialRequest,
      trialDuration,
    });
    this.props.packActions.showPackRequestModal();
  };

  requestPack = async (packRequest: PackRequest) => {
    this.props.packActions.hidePackRequestModal();
    await this.props.packActions.requestPack(packRequest);
    this.props.gridActions.markCardAsRequested(packRequest.packId);
  };

  enablePackTrial = async (packRequest: EnablePackTrialRequest) => {
    this.props.packActions.hidePackRequestModal();
    await this.props.packActions.enablePackTrial(packRequest);
    this.props.gridActions.markCardAsTrialStarted(packRequest.packId);
  };

  enablePack = async (enablePackRequest: EnablePackRequest) => {
    this.props.packActions.hidePackRequestModal();
    await this.props.packActions.enablePack(enablePackRequest);
    this.props.gridActions.markCardAsPurchased(enablePackRequest.packId);
  };

  render() {
    const {
      gridState,
      packsOverviewFilters,
      search,
      packActions,
      packRequestModal,
      accountId,
      accountName,
      userId,
      areAllLoaded,
    } = this.props;
    const { filterOptions, appliedFilter } = packsOverviewFilters;
    const { selectedViewType, currentRequestPackId, currentRequestPackTitle, isTrialRequest, trialDuration } =
      this.state;

    let items = gridState.items;
    let isLoading = gridState.isLoading;
    let totalCount = gridState.itemsCount;
    let isFiltered = !isEmpty(appliedFilter) || !isEmpty(search.term);

    const cardButtonHandlers = {
      onTitleClick: this.handleTitleClick,
      onEdit: this.handleEditClick,
      onPreview: this.handlePreviewClick,
      onCopy: /* istanbul ignore next */ () => {}, // NOSONAR
      onDelete: this.handleDeleteClick,
      showPackRequestModal: this.showPackRequestModal,
    };
    const renderCard = (params: CardsViewerItem<PackOverview>) => {
      return <PackCard {...params} {...cardButtonHandlers} />;
    };
    return (
      <section className="scrollable-content">
        <ItemsView
          blur
          itemsType={ItemsTypes.Pack}
          items={items}
          searchPlaceholder="Search for Packs..."
          getData={this.getPacks}
          sortOptions={sortOptions}
          onSortChange={this.onSortChange}
          noResultsContent={<PacksNoResults createButton={this.renderCreatePackBtn()} filtered={isFiltered} />}
          isLoading={isLoading}
          isAllDataLoaded={areAllLoaded}
          renderCard={renderCard}
          onTitleClick={this.handleTitleClick}
          className="packs-items-view"
          renderFilterForm={() => <PacksFilterForm />}
          filterOptions={filterOptions}
          resetFilter={this.resetFilter}
          appliedFilter={appliedFilter}
          filterOptionsLoading={packsOverviewFilters?.isLoading}
          applyFilter={this.applyFilter}
          getFilterOptions={this.getFilterOptions}
          buildTableBody={this.buildTableBody}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          viewType={selectedViewType}
          renderSearch={() => (
            <SearchInput placeholder="Search for packs..." onChange={this.onSearchChange} defaultValue={search.term} />
          )}
          withSelection={false}
          setReloadListItems={this.createReloadListMethod}
          hideListGridViewSwitcherButton
          doNotLoadPersistentViewType
        />

        <RequestPackModals
          accountId={accountId}
          packId={currentRequestPackId}
          packTitle={currentRequestPackTitle}
          accountName={accountName}
          userId={userId}
          onCancelPackRequest={packActions.hidePackRequestModal}
          onSubmitEnablePack={this.enablePack}
          isTrialRequest={isTrialRequest}
          showModal={packRequestModal.show}
          onSubmitPackRequest={this.requestPack}
          onTrialRequest={this.enablePackTrial}
          onTrialEnable={this.enablePackTrial}
          trialDuration={trialDuration}
        />
        <DeletePackConfirmationDialog
          packUsageCount={this.state.packUsageCount}
          triggerOpen={this.state.triggerOpenDeletePackDialog}
          onCancel={this.onCancelPackDelete}
          onContinue={this.onContinuePackDelete}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  gridState: state.packs.overviewGrid,
  accountId: state.userProfile.accountId,
  userId: state.userProfile.id,
  search: packsOverviewSearchSelector(state),
  packsOverviewFilters: packsOverviewFiltersSelector(state),
  packRequestModal: state.packs.packListingReducer.details.packRequestModal,
  accountName: ownAccountNameSelector(state),
  areAllLoaded: state.packs.overviewGrid.areAllLoaded,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  packActions: {
    requestPack: bindAction(packListingActions.requestPack, dispatch),
    enablePack: bindAction(packListingActions.enablePack, dispatch),
    enablePackTrial: bindAction(packListingActions.enablePackTrial, dispatch),
    hidePackRequestModal: bindAction(packListingActions.hidePackRequestModal, dispatch),
    showPackRequestModal: bindAction(packListingActions.showPackRequestModal, dispatch),
  },
  packsOverviewActions: bindActionCreators(packsOverviewActions, dispatch),
  packEntityStateActions: bindActionCreators(packEntityStateActions, dispatch),
  setSearch: bindAction(setTerm, dispatch),
  getFilters: () => dispatch(getFilterOptions(packsOverviewFiltersSlice)),
  applyFilters: (filters: any) => dispatch(setAppliedFilter(filters)),
  resetFilters: () => dispatch(resetAppliedFilter()),
  gridActions: {
    resetGrid: () => dispatch(reset()),
    markCardAsTrialStarted: (id: number) => dispatch(markCardAsTrialStarted(id)),
    markCardAsRequested: (id: number) => dispatch(markCardAsRequested(id)),
    markCardAsPurchased: (id: number) => dispatch(markCardAsPurchased(id)),
    fetchPacksOverviewGrid: (requestData: any) => dispatch(fetchOverviewGridPacks(requestData)),
  },
  fetchOwnAccountInfo: () => dispatch(fetchOwnAccountInfo()),
  notificationsActions: bindActionCreators(notificationOperations, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTaskOperations, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(PacksOverview));
export default ConnectedComponent;
