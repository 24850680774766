import * as actionTypes from "./accountInformationActionTypes";
import { combineReducers } from "redux";

export interface AccountDetails {
  accountName: string;
  accountLogo: string | null | undefined;
}

const initialState: AccountDetails = {
  accountName: "",
  accountLogo: "",
};

const accountInfo = (
  state: AccountDetails | undefined,
  action: actionTypes.UpdateAccountInformation,
): AccountDetails => {
  const currentState = state || initialState;

  if (action.type === actionTypes.UPDATE_DATA) {
    return {
      ...currentState,
      accountName: action.payload.accountInfo.name,
      accountLogo: action.payload.accountInfo.logoUri,
    };
  } else {
    return currentState;
  }
};

const reducer = combineReducers({
  accountInfo,
});

export default reducer;
