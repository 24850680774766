import { type RootState } from "../../../../Application/globaltypes/redux";
import { connect } from "react-redux";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";

const mapStateToProps = (state: RootState, ownProps: any) => {
  const selectedUsersCount = state.library.flows.edit.users.selectedIds.length;
  const selectedGroupsCount = state.library.flows.edit.groups.selectedIds.length;
  const selectedPeopleCount = selectedUsersCount + selectedGroupsCount;

  const getHeaderTitle = () => {
    if (selectedPeopleCount) {
      return (
        <SelectedItemsLabel
          itemName={getPeopleEntityLabel(selectedUsersCount, selectedGroupsCount)}
          selectedItemsAmount={selectedPeopleCount}
          shouldPluralize={false}
        />
      );
    } else {
      return ownProps.title;
    }
  };

  return {
    title: getHeaderTitle(),
  };
};

export default connect(mapStateToProps)(DetailsHeader);
