import { type FC } from "react";

import styles from "./trialPackHeader.module.scss";

export interface Props {
  trialDuration: number;
}

export const TrialPackHeader: FC<Props> = (props) => {
  const { trialDuration } = props;

  return (
    <div className={styles["pack-trial-header"]}>
      <div className={styles["trial-period-header"]}>TRIAL PERIOD</div>
      <div className={styles["trial-period-days"]}>{trialDuration} Days</div>
    </div>
  );
};
