import { withFormik } from "formik";
import PropTypes from "prop-types";
import { Component } from "react";
import { batch, connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";

import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { withRouter } from "../../../../adapters/withRouter/withRouter";
import AssetModals from "../../../../components/modal/assetModals/AssetModals";
import Segments from "../../../../components/navigation/segments/Segments";
import { ProtectedRoute } from "../../../../components/restrictedRoute/ProtectedRoute";
import { DetailsSubHeader } from "../../../../components/sectionHeader";
import { ItemsTypes, PublishedStatusTypes, RolePermissions, RouteNames, UsersGroupsContext } from "../../../../enums";
import Autosave from "../../../../utils/Autosave";
import Observable from "../../../../utils/Observable";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import { replaceNumericValuesInString } from "../../../../utils/stringUtils";
import validationSchemas from "../../../../utils/validationSchemas";
import Restricted from "../../../Application/Restricted";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";
import VideoPerformance from "../Performance/VideoPerformance";
import ClosedCaptions from "../VideoDetails/ClosedCaptions/ClosedCaptions";
import SettingsTab from "../VideoDetails/Settings/SettingsTab";
import AssetFormContainer from "../VideoForm/VideoFormContainer/VideoFormContainer";
import VideoUploaderService from "../services/videosUploaderService";
import * as videoEntityStateActionsRedux from "../state/actions/videoEntityStateActionCreators";
import * as videosActionsRedux from "../state/actions/videosActions";
import { videosStateSelector } from "../state/selectors";
import { getAssignedClosedCaptions } from "../state/selectors/detailsSelector";
import { resetTags } from "../state/slices/videoBaseSlice";
import { uploadVideoToAws } from "../state/thunks/videoUploadThunk";
import videosThumbnailUploader from "../videosThumbnailUploader";
import DetailsHeaderContainer from "./Containers/DetailsHeaderContainer";
import DetailsHeaderContent from "./DetailsHeaderContent/DetailsHeaderContent";
import EditVideoPeople from "./People/EditVideoPeople";
import { EditVideoStepConfig, EditVideoSteps } from "./types";
import assetInfoPropTypes from "./videoInfoPropTypes";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

const assetsOverviewUri = `/${RouteNames.contentVideos}`;

export class AssetDetails extends Component {
  constructor(props) {
    super(props);
    this.isAwsEncodingEnabled = !!this.props.flags?.[FeatureFlags.AwsEncoding];
    this.showPacksTab = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
    this.assetsThumbnailUploader = videosThumbnailUploader(this.defaultPropsProvider);
    this.videoUploader = VideoUploaderService(
      this.defaultPropsProvider,
      this.assetIdProvider,
      null,
      null,
      null,
      this.isAwsEncodingEnabled,
    );
    this.state = {
      activeStepIndex: EditVideoSteps.Performance,
      usersGroupsContext: UsersGroupsContext.Groups,
    };

    this.autosave = new Autosave({
      stateProvider: this.autosaveStateProvider,
      isValid: this.autosaveValidator,
      updateHandler: this.props.videosActions.updateProperties,
      stateProjector: this.autosaveStateProjector,
      isDeferredInitialization: this.props.videoInfo.id !== undefined && !this.props.isLoaded,
    });
    this.onAddPeopleButtonClickObserver = new Observable();
    this.onRemovePeopleButtonClickObserver = new Observable();
    this.onChangePriorityButtonClickObserver = new Observable();
    this.onTriggerClosedCaptionsRemovalObserver = new Observable();
    this.onDiscardedObserver = new Observable();

    this.pages = {
      [EditVideoSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.Performance),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.Performance });
        },
      },
      [EditVideoSteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.Configuration),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.Configuration });
        },
      },
      [EditVideoSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.Settings),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.Settings });
        },
      },
      [EditVideoSteps.ClosedCaptions]: {
        to: "closed-captions",
        label: "Closed Captions",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.ClosedCaptions),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.ClosedCaptions });
        },
      },
      [EditVideoSteps.People]: {
        to: "people",
        label: "People",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.People),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.People });
        },
      },
      [EditVideoSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => this.stepsManager.goToPage(EditVideoSteps.AssociatedPacks),
        init: () => {
          this.setState({ activeStepIndex: EditVideoSteps.AssociatedPacks });
        },
      },
    };

    this.stepsManager = new WizardStepsManager();
    this.stepsManager.subscribeOnActiveIndexChanged((_, activeStepIndex) => {
      this.pages[activeStepIndex].init?.();
    });
  }

  componentDidMount() {
    this.props.videosActions.resetVideoInfo();
    this.props.videoEntityStateActions.resetVideoEntityState();

    const { videosActions, params } = this.props;
    videosActions.getVideoInfo(params.id);
    RtnEventsEmitter.subscribe(
      [rtnEvents.VideoAssetPublishSuccess, rtnEvents.VideoAssetsDiscardSuccess],
      this.onPublishedOrDiscardedEvent,
    );
    RtnEventsEmitter.subscribe(rtnEvents.DeleteAllVideoSuccess, this.handleClose);
    RtnEventsEmitter.subscribe(rtnEvents.VideoAssetsUpdateCommandCompleted, this.updateAssetInfo);
    RtnEventsEmitter.subscribe(rtnEvents.LockVideoAssetSuccess, this.updateAsset);
  };

  componentWillUnmount() {
    this.autosave.dispose();
    this.stepsManager.dispose();
    this.cancelFileUpload();
    this.props.onUnmount();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.VideoAssetPublishSuccess, rtnEvents.VideoAssetsDiscardSuccess],
      this.onPublishedOrDiscardedEvent,
    );
    RtnEventsEmitter.unsubscribe(rtnEvents.DeleteAllVideoSuccess, this.handleClose);
    RtnEventsEmitter.unsubscribe(rtnEvents.VideoAssetsUpdateCommandCompleted, this.updateAssetInfo);
    RtnEventsEmitter.unsubscribe(rtnEvents.LockVideoAssetSuccess, this.updateAsset);
  };

  componentDidUpdate(prev) {
    const { isLoaded, videoInfo } = this.props;

    if (isLoaded && prev.isLoaded !== isLoaded) {
      this.autosave.ensureInitialization(this.getAssetProperties(videoInfo));
    }
  }

  onPublishedOrDiscardedEvent = () => {
    this.updateAssetAndResetUpdateEntityCommandState(this.props.videoInfo.id);
  };

  updateAssetAndResetUpdateEntityCommandState = (assetId) => {
    this.onDiscardedObserver.notify();
    this.updateAsset({ id: assetId });
    this.props.videoEntityStateActions.resetUpdateEntityCommandState();
  };

  isPublished = () => !this.props.videoInfo.isDraft;

  back = () => {
    if (!this.stepsManager.goBack()) {
      return assetsOverviewUri;
    }
  };

  updateAsset = (payload) => {
    const { onDiscard, navigate, location } = this.props;
    const { id } = payload;

    const path = location.pathname;
    const draftEntityPath = replaceNumericValuesInString(path, id);
    onDiscard(id);
    navigate(draftEntityPath, { replace: true });
  };

  handleEdit = () => {
    if (!this.isPublished() || this.props.changingEntityState) {
      return;
    }
    const { videoEntityStateActions, videoInfo } = this.props;
    videoEntityStateActions.getEntityLock(videoInfo.id);
  };

  handleClose = () => {
    this.props.navigate(assetsOverviewUri);
  };

  onPublish = async () => {
    const { id, isDraft, flows, hasBeenPublished } = this.props.videoInfo;
    this.autosave.save();
    // https://brainstorm.atlassian.net/browse/SAAS-25549
    let usersCount = hasBeenPublished ? 1 : 0;

    isDraft && this.modalHandlers.publish(id, flows.length, usersCount, hasBeenPublished);
  };

  onRevert = () => {
    this.modalHandlers.revert(this.props.videoInfo);
  };

  autosaveStateProjector = (state) => ({
    ...state,
    id: this.props.videoInfo.id,
  });

  autosaveStateProvider = () => this.getAssetProperties(this.props.values);

  getAssetProperties = (values) => {
    return {
      id: values.id,
      title: values.title.trim(),
      description: values.description.trim(),
    };
  };

  defaultPropsProvider = () => this.props;
  assetIdProvider = () => this.props.videoInfo.id;

  autosaveValidator = () => {
    const { isValid, videoInfo } = this.props;
    return isValid && videoInfo.isDraft;
  };

  isRevertVisible = () => this.props.videoInfo.hasBeenPublished;

  isLoading = () => {
    const { changingEntityState, isLoading } = this.props;
    return changingEntityState || isLoading;
  };

  getTask = () => {
    const { tasks, values } = this.props;
    if (values.uploadedVideos && values.uploadedVideos.length > 0) {
      const fileName = values.uploadedVideos[0].name;
      return tasks[fileName] || {};
    }
    return {};
  };

  cancelFileUpload = () => {
    const task = this.getTask();
    task.onCancel && task.onCancel();
  };

  onBlur = (propertyName) => {
    this.autosave.onBlur(propertyName);
  };

  onFormChanged = async (propertyName) => {
    const { dirty, submitForm } = this.props;
    if (dirty) {
      await submitForm();
    }

    this.onBlur(propertyName);
  };

  updateAssetInfo = () => {
    this.props.videosActions.saveVideoInfo(this.props.values);
  };

  render() {
    const {
      isValid,
      videoInfo,
      params,
      isEntityCommandInProgress,
      dateModified,
      canBePublished,
      videoEntityStateActions,
      backgroundTasksActions,
      location,
      notificationsActions,
      isClosedCaptionsSelected,
    } = this.props;
    const isUsersTab = location.pathname.endsWith("users");
    const isClosedCaptionsTab = location.pathname.endsWith("closed-captions");
    const isPacksTabs = location.pathname.endsWith("packs");
    const isRevertVisible = this.isRevertVisible();
    const isPublishedAndNotPurchased = !videoInfo.isDraft && !videoInfo.isPurchased;

    return (
      <Restricted
        permissions={[RolePermissions.AssetsCreate]}
        renderContent={(crudPermission) => (
          <section className="nested-content edit-asset-details">
            <DetailsHeaderContainer
              title={videoInfo.title}
              pageTitle={videoInfo.title}
              titleForGA="Asset Details"
              backButton={this.back || assetsOverviewUri}
              defaultURL={assetsOverviewUri}
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(this.isPublished())}
              hideEntityStateButtons={isUsersTab || (isClosedCaptionsTab && isClosedCaptionsSelected)}
              invalidFormDetails={!isValid}
              isRevertVisible={isRevertVisible}
              crudPermission={crudPermission}
              canBePublished={canBePublished}
              canBeEdited={isPublishedAndNotPurchased}
              isReadOnly={videoInfo.isPurchased}
              entityStateActions={{
                onPublish: this.onPublish,
                onRevert: this.onRevert,
                onClose: this.handleClose,
                onEdit: this.handleEdit,
              }}
              publishTooltipMessage={
                !canBePublished && videoInfo.isDraft
                  ? "Video is still processing and can be published when finished."
                  : undefined
              }
            >
              <DetailsHeaderContent
                isPeopleTab={isUsersTab}
                isClosedCaptionsTab={isClosedCaptionsTab}
                videoId={parseInt(params.id)}
                onAddPeopleObserver={this.onAddPeopleButtonClickObserver}
                onRemoveVideoPeopleObserver={this.onRemovePeopleButtonClickObserver}
                onChangePriorityObserver={this.onChangePriorityButtonClickObserver}
                onTriggerClosedCaptionsRemovalObserver={this.onTriggerClosedCaptionsRemovalObserver}
                canAddPacks={isPublishedAndNotPurchased}
                showAddPacksButton={isPacksTabs}
                permissionPredicate={permissionPredicateForPacks}
              />
            </DetailsHeaderContainer>
            <AssetModals
              acceptHandlers={(handlers) => (this.modalHandlers = handlers)}
              isRevertVisible={isRevertVisible}
              videoEntityStateActions={videoEntityStateActions}
              backgroundTasksActions={backgroundTasksActions}
              notificationsActions={notificationsActions}
            />
            <Dimmer active={this.isLoading()} inverted>
              <Loader />
            </Dimmer>
            <DetailsSubHeader
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(this.isPublished())}
              lastModifiedDateTime={
                EditVideoStepConfig[this.state.activeStepIndex]?.showAutoSaveOnTab ? dateModified : undefined
              }
              isUpdateInProgress={isEntityCommandInProgress}
            >
              <Segments to={`${assetsOverviewUri}/${parseInt(params.id)}`}>
                <Segments.Segment {...this.pages[EditVideoSteps.Performance]} />
                <Segments.Segment {...this.pages[EditVideoSteps.Configuration]} />
                <Segments.Segment {...this.pages[EditVideoSteps.Settings]} />
                <Segments.Segment {...this.pages[EditVideoSteps.ClosedCaptions]} />
                <Segments.Segment {...this.pages[EditVideoSteps.People]} />
                {this.showPacksTab && <Segments.Segment {...this.pages[EditVideoSteps.AssociatedPacks]} />}
              </Segments>
            </DetailsSubHeader>
            <div className="scrollable-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                      <VideoPerformance
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditVideoSteps.Performance)
                        }
                        videoId={parseInt(params.id)}
                        videoTitle={videoInfo.title}
                        {...this.props}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="configuration"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                      <AssetFormContainer
                        {...this.props}
                        isCreateAction={false}
                        handleVideoFileChange={this.videoUploader.handleFileChange}
                        uploadFilesHandler={this.videoUploader.uploadFilesHandler}
                        onCancelFileUploading={this.cancelFileUpload}
                        disabled={!(crudPermission && !this.isPublished())}
                        onBlur={this.onFormChanged}
                        generateThumbnail={this.assetsThumbnailUploader.generateThumbnail}
                        generateThumbnailFromPosition={this.assetsThumbnailUploader.generateThumbnailFromPosition}
                        onCroppedThumbnailClick={this.assetsThumbnailUploader.onCroppedThumbnailClick}
                        assetManifests={this.props.streamingManifests}
                        task={this.getTask()}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditVideoSteps.Configuration)
                        }
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                      <SettingsTab
                        hasAnyPermission={crudPermission}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditVideoSteps.Settings)
                        }
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="closed-captions"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                      <ClosedCaptions
                        onDiscardedObserver={this.onDiscardedObserver}
                        onTriggerClosedCaptionsRemovalObserver={this.onTriggerClosedCaptionsRemovalObserver}
                        isReadOnly={!crudPermission || this.isPublished()}
                        entityId={parseInt(params.id)}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EditVideoSteps.ClosedCaptions)
                        }
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="people"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                      <EditVideoPeople
                        videoId={parseInt(params.id)}
                        title={videoInfo.title}
                        thumbnailUrl={videoInfo.thumbnailUrl}
                        durationInSeconds={videoInfo.durationInSeconds}
                        usersGroupsContext={this.state.usersGroupsContext}
                        onContextChanged={(usersGroupsContext) => this.setState({ usersGroupsContext })}
                        acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EditVideoSteps.People)}
                      />
                    </ProtectedRoute>
                  }
                />
                {this.showPacksTab && (
                  <Route
                    path="packs"
                    element={
                      <AssociatedPacks
                        acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EditVideoSteps.AssociatedPacks)}
                        contentType={ItemsTypes.Video}
                        contentId={parseInt(params.id)}
                        canAddPacks={isPublishedAndNotPurchased}
                        permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
                        permissionPredicate={permissionPredicateForPacks}
                      />}
                  />)}
                <Route path="*" element={<Navigate to="../" replace />} />
              </Routes>
            </div>
          </section>
        )}
      />
    );
  }
}

AssetDetails.propTypes = {
  videoInfo: assetInfoPropTypes,
  navigate: PropTypes.func,
  params: PropTypes.object,
  videosActions: PropTypes.object,
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
  const base = videosStateSelector(state).base;
  return {
    videoInfo: base.videoInfoReducer.videoInfo,
    isLoading: base.videoInfoReducer.isLoadingVideoInfo,
    isLoaded: base.videoInfoReducer.isLoadedVideoInfo,
    uploadingStatus: base.videoInfoReducer.uploadingStatus,
    entityReducer: base.videoEntityStateReducer,
    entityId: base.videoEntityStateReducer.entityId,
    changingEntityState: base.videoEntityStateReducer.changingEntityState,
    isEntityCommandInProgress: base.videoEntityStateReducer.isEntityCommandInProgress,
    streamingManifests: base.updateVideoReducer.videoAssetStreamingManifests,
    isProcessing: base.updateVideoReducer.isThumbnailGenerationProcessing,
    amsJobsStatus: base.updateVideoReducer.amsJobsStatus,
    dateModified: base.updateVideoReducer.dateModified,
    canBePublished: base.updateVideoReducer.canBePublished,
    thumbnailUrl: base.updateVideoReducer.thumbnailUrl,
    customerId: state.userProfile.accountId,
    tasks: state.backgroundTasks.tasks,
    isClosedCaptionsSelected: !!getAssignedClosedCaptions(state).selectedItems.length,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return {
    videosActions: bindActionCreators(videosActionsRedux, dispatch),
    videoEntityStateActions: bindActionCreators(videoEntityStateActionsRedux, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
    uploadAwsVideo: bindActionCreators(uploadVideoToAws, dispatch),
    onDiscard: (id) => {
      batch(() => {
        dispatch(videosActionsRedux.getVideoInfo(id));
      });
    },
    onUnmount: () => {
      batch(() => {
        dispatch(resetTags());
        dispatch(videosActionsRedux.resetVideoInfo());
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withFormik({
    validationSchema: validationSchemas.videoInfo,
    mapPropsToValues: (props) => props.videoInfo,
    handleSubmit: () => { }, // NOSONAR
    enableReinitialize: true,
  })(withRouter(withLDConsumer()(AssetDetails))),
);
