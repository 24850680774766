import React, { type FC, useMemo } from "react";
import { useFormik } from "formik";

import { type IObservable } from "../../../../../../../../interfaces/IObservable";
import { ModalSizes } from "../../../../../../../../enums";
import {
  type ExternalTriggerGroup,
  type ExternalTriggerGroupView,
  type ExternalTriggerType,
} from "../../../../types/externallTriggersTypes";
import { getInitialValues } from "../TriggerGroups/helpers/utils";
import { externalTrigger } from "../../../../../../../../utils/validationSchemas/externalTriggerValidationSchema";

import TriggeredConfirmationModal from "../../../../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";
import TriggerGroups from "../TriggerGroups/TriggerGroups";

import styles from "./externalTriggerModal.module.scss";

export interface ExternalTriggerModalProps {
  onTriggerModalObserver: IObservable<(onRemoveConfirm: () => void) => void>;
  externalTriggerGroups?: ExternalTriggerGroup<ExternalTriggerType[]>[];
  updateExternalTriggerGroups: (externalTriggerGroups: ExternalTriggerGroup<ExternalTriggerType[]>[]) => Promise<void>;
  isReadOnly: boolean;
  isLoading: boolean;
}

const ExternalTriggerModal: FC<ExternalTriggerModalProps> = (props) => {
  const { onTriggerModalObserver, isReadOnly, externalTriggerGroups, isLoading } = props;
  const initialValues = useMemo(() => getInitialValues(externalTriggerGroups), [externalTriggerGroups]);

  const formik = useFormik<ExternalTriggerGroupView>({
    initialValues: initialValues,
    validationSchema: externalTrigger,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values: ExternalTriggerGroupView) => {
      await props.updateExternalTriggerGroups(
        values.externalTriggerGroups as ExternalTriggerGroup<ExternalTriggerType[]>[],
      );
      formik.resetForm();
    },
  });

  const getContent = <TriggerGroups formik={formik} isReadOnly={isReadOnly} isLoading={isLoading} />;

  return (
    <TriggeredConfirmationModal
      styles={{ root: styles.root }}
      title="External Triggers"
      size={ModalSizes.fullscreen}
      content={getContent}
      confirmButtonLabel="Save"
      onConfirmed={formik.submitForm}
      disabled={!formik.isValid || !formik.dirty}
      onClose={formik.resetForm}
      onTriggerModalObserver={onTriggerModalObserver}
    />
  );
};

export default ExternalTriggerModal;
