import type React from "react";

export interface Props {
  label: string;
  required?: boolean;
  isLocked?: boolean;
  preRender?: boolean;
  children?: any;
  className?: string;
  nextButtonLabel?: string;
  isLockNavigation?: boolean;
}

const Section: React.FC<Props> = (props) => {
  return props.children;
};

export default Section;
