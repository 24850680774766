import { TriggerableConfirmationModal } from "components";
import { type IObservable } from "interfaces";
import React from "react";

interface Props {
  removeObserver: IObservable<(onSubmit: () => void, ids: number[]) => void>;
}

export const RemoveCustomersModal = (props: Props) => {
  const [multi, setMulti] = React.useState(false);
  const { removeObserver } = props;
  const content =
    `This will revoke all access to content Packs in this template for ${multi ? "these customers" : "this customer"},` +
    ` removing all associated users. Are you sure you want to continue?`;
  return (
    <TriggerableConfirmationModal
      onTriggerModalObserver={removeObserver}
      content={content}
      confirmButtonLabel={"Remove"}
      title={`Remove ${multi ? "Customers" : "Customer"}?`}
      onTrigger={(ids: number[]) => setMulti(ids.length > 1)}
    />
  );
};
