import { type ChangeEvent, useEffect, useState } from "react";
import cn from "classnames";

import ValidationErrorPrompt from "./ValidationErrorPrompt";
import { ErrorPositions } from "../../enums/errorPositions";
import { type WithTestId } from "../../types";

import styles from "./inputFieldDigitsOnly.module.scss";

interface InputFieldDigitsOnlyProps extends WithTestId {
  value: number | string;
  onChange: (value?: number) => void;
  validate: (value: number) => string | undefined;
  className?: string;
  errorPosition?: ErrorPositions;
}

export const InputFieldDigitsOnly: React.FC<InputFieldDigitsOnlyProps> = ({
  value,
  onChange,
  validate,
  className,
  dataTestId,
  errorPosition = ErrorPositions.right,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const initialValue = Number(value);
    if (initialValue && initialValue > 0) {
      setErrorMessage(validate(initialValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChangeValidation = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value) || Number.MAX_SAFE_INTEGER <= value) {
      onChange(undefined);
      return;
    }

    setErrorMessage(validate(value));

    // undefined === user can remove a value from the input
    // Math.max(0, value) => disable negative values
    const val = e.target.value ? Math.max(0, value) : undefined;

    onChange(val);
  };

  const onKeyPressValidation = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d/.test(keyValue)) {
      // validate only digital numbers
      e.preventDefault();
    }
  };

  return (
    <span className="ui input">
      <input
        className={cn(className, {
          [styles["digits-only-input-error"]]: errorMessage,
        })}
        data-testid={dataTestId || "digits-only-input"}
        type="text"
        placeholder={"Quantity"}
        value={value.toString() || ""}
        onChange={onChangeValidation}
        onKeyPress={onKeyPressValidation}
        onPaste={onKeyPressValidation}
      />
      {errorMessage && <ValidationErrorPrompt errorPosition={errorPosition} text={errorMessage} />}
    </span>
  );
};
