import {
  makeActionCreator,
  buildFetchAction,
  buildFetchGridAction,
} from "../../../../Application/actions/actionsBuilder";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";

const fetchUserGroupsAction = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  ReducerEntityPrefixTypes.Groups,
);

export const fetchUserGroupsGridAction = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  "",
);

export const fetchGroupOwnersAction = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  ReducerEntityPrefixTypes.GroupOwners,
);

export const fetchUserEditGroupsItemsActions = {
  begin: makeActionCreator(fetchUserGroupsAction.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(fetchUserGroupsAction.FETCH_ITEMS_SUCCESS, "items", "itemsCount"),
  failure: makeActionCreator(fetchUserGroupsAction.FETCH_ITEMS_FAILURE, "error"),
};

export const fetchUserEditGroupsItemsGridActions = {
  begin: makeActionCreator(fetchUserGroupsGridAction.FETCH_GRID_ITEMS_BEGIN),
  success: makeActionCreator(fetchUserGroupsGridAction.FETCH_GRID_ITEMS_SUCCESS, "items", "itemsCount"),
  failure: makeActionCreator(fetchUserGroupsGridAction.FETCH_GRID_ITEMS_FAILURE, "error"),
};

export const fetchUserEditGroupOwnersItemsActions = {
  begin: (groupIds: number[]) => ({
    type: fetchGroupOwnersAction.FETCH_ITEMS_BEGIN,
    groupIds,
  }),
  success: (owners: any) => ({
    type: fetchGroupOwnersAction.FETCH_ITEMS_SUCCESS,
    owners,
  }),
  failure: makeActionCreator(fetchGroupOwnersAction.FETCH_ITEMS_FAILURE, "error"),
};
