import { type IEventAssignmentModelItem } from "../../../features/People/types";
import { type ColumnOption } from "../../../interfaces/columnOptions";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { Title } from "../../listViewTemplates";
import { EditPriorityLinkButton } from "../../buttons/linkButtons";
import EllipsisPopupButton from "../../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { ContentType, type PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { type PriorityLevels, PublishedStatusTypes, RouteNames } from "../../../enums";
import { PriorityCell } from "../../priorityLevels/priorityCell/PriorityCell";
import ExpiresListTemplate from "../../ExpiresTemplate/ExpiresListTemplate/ExpiresListTemplate";
import { PublishedStatus } from "../../common/publishedStatus";

export interface GetColumnButtonHandlers {
  onRemove?: (id: number) => void;
  onEditPriority?: (id: number) => void;
}

export enum Columns {
  Title = "Title", // NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Added = "Added",
  Priority = "Priority level",
  Expires = "Expires",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  publisher: "publisher",
  status: "isDraft",
  added: "dateCreated",
  "priority level": "priorityLevelId",
  expires: "expires",
};

export const getColumnOptions = (
  peopleType: PeopleType,
  buttonHandlers?: GetColumnButtonHandlers,
  selectedIds?: number[],
  hidePriorityColumn?: boolean,
  showExpirationDate?: boolean,
  hideAddedColumn?: boolean,
  showButtons: boolean = true,
): ColumnOption<IEventAssignmentModelItem>[] => [
  // NOSONAR
  {
    name: Columns.Title,
    width: 4,
    render: (item) =>
      !item.inherited ? (
        <Title url={`/${RouteNames.contentEvents}/${item.id}`} title={item.title} className="normal-color" clamp={2} />
      ) : (
        <Title title={item.title} clamp={2} />
      ),
    disabled: (item) => item.inherited,
  },
  {
    name: Columns.Publisher,
    width: 3,
    render: (item) => item.publisher,
    disabled: (item) => item.inherited,
  },
  {
    name: Columns.Status,
    width: hidePriorityColumn && hideAddedColumn ? 6 : 3,
    disabled: (item) => item.inherited,
    render: (item) => (
      <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />
    ),
  },
  {
    name: Columns.Priority,
    width: 4,
    isHide: hidePriorityColumn,
    render: (item) => (
      <PriorityCell
        inherited={item.inherited}
        peopleType={peopleType}
        contentType={ContentType.Event}
        priorityLevel={item.priorityLevelId as PriorityLevels}
        dueDate={item.dueDate}
      />
    ),
  },
  {
    name: Columns.Added,
    width: 3,
    isHide: hideAddedColumn,
    render: (item) => dateTimeUtils.formatDate(item.dateCreated),
    disabled: (item) => item.inherited,
  },
  {
    name: Columns.Expires,
    width: 3,
    isSortable: false,
    isHide: !showExpirationDate,
    disabled: (item) => item.inherited,
    render: (item) => <ExpiresListTemplate expirationDate={item.expirationDate} />,
  },
  {
    name: "",
    width: 1,
    className: "align-right",
    render: (item) =>
      showButtons &&
      !!buttonHandlers?.onRemove && (
        <EllipsisPopupButton circle disabled={item.inherited || selectedIds?.includes(item.id)}>
          <EditPriorityLinkButton onClick={() => buttonHandlers.onEditPriority?.(item.id)} />
        </EllipsisPopupButton>
      ),
  },
];
