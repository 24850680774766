import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import * as filterActionTypes from "./editUserRolesFilterActionTypes";
import { fetchFilterRolesOptions } from "../../../RolesOverview/state/fetchFilterRolesOptions";

export const setRolesFilter = makeActionCreator(filterActionTypes.USER_EDIT_ROLES_SET_ITEMS_FILTER, "filter");

export const resetRolesFilter = makeActionCreator(filterActionTypes.USER_EDIT_ROLES_RESET_ITEMS_FILTER);

export const fetchFilterOptions = () =>
  fetchFilterRolesOptions(
    filterActionTypes.USER_EDIT_ROLES_FILTER_OPTIONS_FETCH_BEGIN,
    filterActionTypes.USER_EDIT_ROLES_FILTER_OPTIONS_FETCH_SUCCESS,
    filterActionTypes.USER_EDIT_ROLES_FILTER_OPTIONS_FETCH_FAILURE,
  );
