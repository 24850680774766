import { combineReducers } from "redux";
import * as actionTypes from "./addUserActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withFetchingChildEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import { type Role } from "../../../../../interfaces";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.AddUser;

interface UserDetailsState {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  department: string;
  jobTitle: string;
  country: string;
  password: string;
  confirmPassword: string;
  roleIds: number[];
  isLoading: boolean;
  error: any;
}

const initialState: UserDetailsState = {
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  department: "",
  jobTitle: "",
  country: "",
  password: "",
  confirmPassword: "",
  roleIds: [],
  isLoading: false,
  error: null,
};

const startLoading = (state: UserDetailsState) => ({
  ...state,
  isLoading: true,
  error: null,
});

const stopLoading = (state: UserDetailsState) => ({
  ...state,
  isLoading: false,
  error: null,
});

const finishUserCreationHandler = () => {
  const { FINISH_USER_CREATION } = actionTypes;

  const finishUserCreation = () => {
    return { ...initialState };
  };

  return {
    [FINISH_USER_CREATION]: finishUserCreation,
  };
};

const userInfoHandler = () => {
  const {
    UPDATE_ROLE_ASSIGNMENT,
    USER_INFO_SAVE_FAILURE,
    USER_INFO_SAVE_SUCCESS,
    USER_INFO_SAVE_BEGIN,
    USER_INFO_ROLES_CHANGED,
  } = actionTypes;

  const rolesChanged = (state: UserDetailsState, action: actionTypes.UserInfoRolesChanged) => ({
    ...state,
    roleIds: [...action.payload.selectedRoles],
  });

  const userInfoBegin = (state: UserDetailsState, action: actionTypes.SaveUserInfoBegin) =>
    startLoading({
      ...state,
      ...action.payload.userInfo,
    });

  const userInfoSaveSuccess = (state: UserDetailsState, action: actionTypes.SaveUserInfoSuccess) =>
    stopLoading({ ...state, id: action.payload.id });

  const userInfoSaveFailure = (state: UserDetailsState, action: actionTypes.SaveUserInfoFailure) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  });

  const updateRolesAssignment = (
    state: UserDetailsState,
    action:
      | actionTypes.SaveUserInfo
      | actionTypes.UserInfoRolesChanged
      | actionTypes.AddUserCancel
      | actionTypes.FinishUserCreation,
  ) => {
    const roleIds = action.payload.roleIds;
    return { ...state, roleIds };
  };

  return {
    [UPDATE_ROLE_ASSIGNMENT]: updateRolesAssignment,
    [USER_INFO_SAVE_FAILURE]: userInfoSaveFailure,
    [USER_INFO_SAVE_SUCCESS]: userInfoSaveSuccess,
    [USER_INFO_SAVE_BEGIN]: userInfoBegin,
    [USER_INFO_ROLES_CHANGED]: rolesChanged,
  };
};

const cancelHandler = () => {
  const { ADD_USER_CANCEL } = actionTypes;
  return {
    [ADD_USER_CANCEL]: () => {
      return { ...initialState };
    },
  };
};

const userInfoReducer = createReducer(initialState, [finishUserCreationHandler, userInfoHandler, cancelHandler]);

export const addUserReducer = combineReducers({
  rolesList: withFetchingChildEntityListItems<Role>(namespace, entityPrefix, actionTypes.Roles),
  permissionsList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.Permissions),
  userDetails: userInfoReducer,
});

export type AddUserState = ReturnType<typeof addUserReducer>;
