import { useState, useCallback, useMemo, useEffect } from "react";
import { isEqual } from "lodash";
import { Icon, Label } from "semantic-ui-react";
import FilterSidebar from "../filterSidebar/FilterSidebar";
import { calculateAppliedFilters, type FiltersMap } from "../../utils/filterUtils";
import { FilterFormBuilder, type FilterItemBase } from "../filterForms/FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "enums/filterViewTypes";
import {
  type AccountIds,
  type Accounts,
  type AccountType,
  accountType,
  allCustomerAccounts,
  dateRange30,
  fetchAccounts,
  longFormDateFilter,
  type PerformanceFilter,
  validDateWithMoment,
} from "features/Library/Common/utils/performanceUtils";
import moment from "moment";
import { ReportingChip } from "components/reportingChip/ReportingChip";

import "./ReportingFilter.scss";
import { type RootState } from "features/Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import { Tooltip } from "components/common/tooltip";

const { dateFrom, dateTo } = dateRange30();

type FilterWithoutAccounts = {
  currentFilter: BaseFilter;
  callback: (filter: BaseFilter) => void;
  initialDateRange?: PerformanceFilter;
  ignoreInCountAndHide?: string[];
  includeAccounts?: never;
  includeAccountsDropdown?: never;
  includeDistinct?: boolean;
};

type FilterWithAccounts = {
  currentFilter: AccountFilter;
  callback: (filter: AccountFilter) => void;
  initialDateRange?: PerformanceFilter;
  ignoreInCountAndHide?: string[];
  includeAccounts: boolean;
  includeAccountsDropdown?: boolean;
  includeDistinct?: boolean;
};

type FilterWithAccountsDropdown = {
  currentFilter: AccountDropdownFilter;
  callback: (filter: AccountDropdownFilter) => void;
  initialDateRange?: PerformanceFilter;
  ignoreInCountAndHide?: string[];
  includeAccounts?: boolean;
  includeAccountsDropdown: boolean;
  includeDistinct?: boolean;
};

export type FilterProps = PropsFromRedux & (FilterWithoutAccounts | FilterWithAccounts | FilterWithAccountsDropdown);

export interface BaseFilter {
  dateFrom: string;
  dateTo: string;
  isDistinct?: boolean;
}

export interface AccountFilter extends BaseFilter {
  showCustomers?: boolean;
}

export interface AccountDropdownFilter extends BaseFilter {
  accounts?: Accounts[] | AccountIds[] | string[];
  includeMyData?: boolean;
  type?: AccountType;
}

const dateFilterMissingValues = (selectedData: BaseFilter) =>
  selectedData.dateFrom === "" || selectedData.dateTo === "";

const filterOutHidden = (filter: any, ignoreInCountAndHide: string[] = []) =>
  Object.fromEntries(Object.entries(filter).filter(([key]) => !ignoreInCountAndHide.includes(key)));

function filtersAreIdentical(newFilter: DateFilterCallback, oldFilter: DateFilterCallback): boolean;
function filtersAreIdentical(newFilter: AccountFilterCallback, oldFilter: AccountFilterCallback): boolean;
function filtersAreIdentical(
  newFilter: AccountDropdownFilterCallback,
  oldFilter: AccountDropdownFilterCallback,
): boolean;

function filtersAreIdentical(newFilter: SubmitCallback, oldFilter: SubmitCallback): boolean {
  return isEqual(newFilter, oldFilter);
}

function valueOrFallback(value: any, fallback: any) {
  return value ?? fallback;
}

const incomingDateFormat: moment.MomentFormatSpecification = "MM/DD/YYYY";

type DateFilterCallback = {
  dates: { from: string; to: string };
  distinct?: "Distinct" | "Total";
};

type AccountFilterCallback = DateFilterCallback & {
  showCustomers?: boolean;
};

type AccountDropdownFilterCallback = DateFilterCallback & {
  accounts?: Accounts[] | AccountIds[] | string[];
  includeMyData?: boolean;
  type?: AccountType;
};

type SubmitCallback = DateFilterCallback | AccountFilterCallback | AccountDropdownFilterCallback;

export const ReportingFilter = ({
  currentFilter,
  callback,
  includeAccounts,
  includeAccountsDropdown,
  includeDistinct,
  accountId,
  ignoreInCountAndHide,
  initialDateRange,
}: FilterProps) => {
  const type = accountType(accountId);

  const filter = useMemo<SubmitCallback>(
    () => ({
      dates: { from: initialDateRange?.dateFrom ?? dateFrom, to: initialDateRange?.dateTo ?? dateTo },
      ...(includeAccounts && { showCustomers: false }),
      ...(includeAccountsDropdown && { accounts: [allCustomerAccounts.value] }),
      ...(includeAccountsDropdown && { includeMyData: true }),
      ...(includeAccountsDropdown && { type }),
      ...(includeDistinct && { distinct: "Total" }),
    }),
    [includeAccounts, includeAccountsDropdown, includeDistinct, type, initialDateRange],
  );

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState<SubmitCallback>(filter);
  const [listOfAccounts, setListOfAccounts] = useState<any[]>([]);

  useEffect(() => {
    if (includeAccountsDropdown) {
      setListOfAccounts([allCustomerAccounts]);
      fetchAccounts(setListOfAccounts);
    }
  }, [includeAccountsDropdown]);

  // Toggle showCustomers to false if the box was checked before and
  // includeAccounts is set to false
  useEffect(() => {
    if (!includeAccounts && (currentFilter as AccountFilter).showCustomers === true) {
      let copy = { ...currentFilter };
      // @ts-ignore
      callback({ ...copy, showCustomers: false });
    }
    // Disabling for now, may put callback back in later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, includeAccounts, selectedData]);

  // Changing performance filter style to be styled in the way FilterSidebar expects
  const formattedActiveFilter = useMemo<any>(() => {
    let filter: Partial<SubmitCallback> = {};
    if (!dateFilterMissingValues(currentFilter)) {
      filter.dates = {
        from: currentFilter.dateFrom,
        to: currentFilter.dateTo,
      };
    }
    if ((currentFilter as AccountFilter).showCustomers !== undefined) {
      (filter as AccountFilterCallback).showCustomers = (currentFilter as AccountFilter).showCustomers;
    }
    if ((currentFilter as AccountDropdownFilter).accounts) {
      (filter as AccountDropdownFilterCallback).accounts = (currentFilter as AccountDropdownFilter).accounts;
    }
    if ((currentFilter as AccountDropdownFilter).includeMyData !== undefined) {
      (filter as AccountDropdownFilterCallback).includeMyData = (currentFilter as AccountDropdownFilter).includeMyData;
    }
    if ((currentFilter as AccountDropdownFilter).type) {
      (filter as AccountDropdownFilterCallback).type = type;
    }
    if ((currentFilter as AccountDropdownFilter).isDistinct === true) {
      (filter as AccountFilterCallback).distinct = "Distinct";
    } else if ((currentFilter as AccountFilter).isDistinct === false) {
      (filter as AccountFilterCallback).distinct = "Total";
    }
    return filter;
  }, [currentFilter, type]);

  const hide = useCallback(() => setShow(false), []);

  const handleSubmit = useCallback(
    (filter: SubmitCallback) => {
      let from = "",
        to = "";
      if (filter.dates) {
        // Manage dates
        from = filter.dates.from;
        to = filter.dates.to;
        if (dateFilterMissingValues({ dateFrom: from, dateTo: to })) {
          return;
        }
      }
      if (filtersAreIdentical(filter, formattedActiveFilter)) {
        return;
      }
      let newStateData: AccountFilterCallback & AccountDropdownFilterCallback = {
        dates: {
          from,
          to,
        },
      };
      if (includeAccounts) {
        newStateData.showCustomers = (filter as any).accounts;
      }
      if (includeAccountsDropdown) {
        newStateData.accounts = valueOrFallback((filter as any).accounts, []);
        newStateData.includeMyData = valueOrFallback((filter as any).includeMyData, false);
        newStateData.type = type;
      }
      if (includeDistinct) {
        newStateData.distinct = valueOrFallback((filter as any).distinct, "Distinct");
      }

      // reset any hidden filters to the state they were when they became hidden
      ignoreInCountAndHide?.forEach((property) => ((newStateData as any)[property] = (currentFilter as any)[property]));

      setSelectedData(newStateData);
      setShow(false);

      const longFormDates = longFormDateFilter(from, to);
      callback({
        dateFrom: longFormDates.dateFrom,
        dateTo: longFormDates.dateTo,
        ...(includeAccounts && { showCustomers: !!(filter as AccountFilterCallback).showCustomers }),
        ...(includeAccountsDropdown && { includeMyData: (newStateData as any).includeMyData }),
        ...(includeAccountsDropdown && {
          accounts: (newStateData as any).accounts.map((account: any) => {
            if (typeof account === "string" || typeof account === "number") return account;
            return account.value;
          }),
        }),
        ...(includeAccountsDropdown && { type: newStateData.type }),
        ...(includeDistinct && { isDistinct: (filter as AccountFilterCallback).distinct === "Distinct" }),
      });
    },
    [
      formattedActiveFilter,
      includeAccounts,
      includeAccountsDropdown,
      includeDistinct,
      callback,
      type,
      ignoreInCountAndHide,
      currentFilter,
    ],
  );

  const handleReset = useCallback(() => {
    const { dates, distinct, ...restOfFilter } = filter;
    const filteredResetOfFilter = filterOutHidden(restOfFilter, ignoreInCountAndHide);
    const formattedActiveFilterSansDates = Object.fromEntries(
      Object.entries(formattedActiveFilter).filter(([key]) => key !== "dates"),
    );
    let callbackValues: any = {
      dateFrom: dates.from,
      dateTo: dates.to,
      ...formattedActiveFilterSansDates,
      ...filteredResetOfFilter,
    };

    setSelectedData({ ...filter, ...formattedActiveFilterSansDates });

    // Applied filter is empty? No need to run callback
    if (
      Object.keys(formattedActiveFilter).length === 0 ||
      (includeAccountsDropdown && filtersAreIdentical(filter, formattedActiveFilter))
    ) {
      return;
    }

    if (distinct !== undefined) {
      callbackValues.isDistinct = distinct === "Distinct";
    }
    callback(callbackValues);
  }, [formattedActiveFilter, callback, includeAccountsDropdown, filter, ignoreInCountAndHide]);

  const filterCount = useMemo(() => {
    let filteredFilter: FiltersMap = {};
    for (let filterName in formattedActiveFilter) {
      if (!ignoreInCountAndHide?.includes(filterName) && !!formattedActiveFilter[filterName]) {
        filteredFilter[filterName] = formattedActiveFilter[filterName];
      }
    }

    return calculateAppliedFilters(filteredFilter);
  }, [formattedActiveFilter, ignoreInCountAndHide]);

  const updateFilter = useCallback((filter: SubmitCallback) => {
    setSelectedData(filter);
  }, []);

  const shouldApplyFilterBeDisabled = useMemo<boolean>(() => {
    if (
      includeAccountsDropdown &&
      !["accounts", "includeMyData"].every((item) => ignoreInCountAndHide?.includes(item))
    ) {
      const noAccountSelected =
        !(selectedData as AccountDropdownFilterCallback).includeMyData &&
        ((selectedData as any).accounts === undefined || (selectedData as any).accounts.length === 0);

      if (noAccountSelected) {
        return true;
      }
    }

    if (filtersAreIdentical(selectedData, formattedActiveFilter)) return true;
    if (!validDateWithMoment(selectedData.dates.from) || !validDateWithMoment(selectedData.dates.to)) return true;
    if (moment(selectedData.dates.from, incomingDateFormat).isAfter(moment(selectedData.dates.to, incomingDateFormat)))
      return true;
    if (Object.keys(selectedData).length === 0) return true;

    return false;
  }, [selectedData, formattedActiveFilter, includeAccountsDropdown, ignoreInCountAndHide]);

  const filterChipData = [
    {
      name: "Date",
      value: `${currentFilter.dateFrom} - ${currentFilter.dateTo}`,
    },
  ];

  const filterChips = filterChipData.map((chipData) => (
    <ReportingChip key={chipData.name} name={chipData.name} value={chipData.value} />
  ));

  return (
    <div className="filterRoot">
      <FilterSidebar
        visible={show}
        hideFilter={hide}
        applyFilter={handleSubmit}
        resetFilter={handleReset}
        appliedFilter={filterOutHidden(formattedActiveFilter, ignoreInCountAndHide)}
        shouldDisableApply={shouldApplyFilterBeDisabled}
        defaultFilter={filterOutHidden(filter, ignoreInCountAndHide)}
      >
        <Filters
          filter={selectedData}
          accounts={listOfAccounts}
          updateSubFilter={updateFilter}
          includeAccounts={includeAccounts}
          includeAccountsDropdown={includeAccountsDropdown}
          ignoreInCountAndHide={ignoreInCountAndHide}
          includeDistinct={includeDistinct}
        />
      </FilterSidebar>
      {filterChips}
      <button
        className="filterVisibilityToggle"
        data-testid="filterVisiblityToggle"
        onClick={() => setShow((s) => !s)}
        type="button"
      >
        <Icon name="filter" />
        Filter
      </button>
      {filterCount > 0 && (
        <Label circular color="blue">
          {filterCount}
        </Label>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
  };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ReportingFilter);
export default ConnectedComponent;

interface SubFilterProps {
  includeAccounts?: boolean;
  includeAccountsDropdown?: boolean;
  includeDistinct?: boolean;
  filter: any;
  updateSubFilter: (filter: SubmitCallback) => void;
  updateFilter?: any;
  accounts?: Accounts[];
  ignoreInCountAndHide?: string[];
}

const Filters = ({
  includeAccounts,
  includeAccountsDropdown,
  includeDistinct,
  filter,
  updateSubFilter,
  updateFilter,
  accounts,
  ignoreInCountAndHide,
}: SubFilterProps) => {
  const items = useMemo(() => {
    let returnVal: FilterItemBase<any>[] = [
      {
        type: FilterViewTypes.DateRange,
        label: "Dates",
        propertyName: "dates",
        items: [],
        placeholder: "",
      },
    ];
    if (includeAccounts) {
      returnVal.push({
        type: FilterViewTypes.Checkbox,
        label: "Accounts",
        propertyName: "showCustomers",
        items: [],
        placeholder: "",
      });
    }
    if (includeAccountsDropdown) {
      returnVal.push({
        type: FilterViewTypes.MultiSelectWithWindow,
        label: "Accounts",
        propertyName: "accounts",
        items: accounts,
        placeholder: "All Customer Accounts",
        otherProps: {
          initialCheckedItems: [allCustomerAccounts],
          searchPredicate: (item: Accounts, term: string) => item.text.toLowerCase().includes(term.toLowerCase()),
          getLabel: (item: Accounts) => item.text,
          // Temporary
          getDescription: (item: Accounts) => item?.text || "",
        },
      });

      returnVal.push({
        type: FilterViewTypes.Checkbox,
        label: "Include My Account Data",
        className: "check-box-v2",
        propertyName: "includeMyData",
        items: [],
        placeholder: "",
        otherProps: {
          noToggle: true,
        },
      });
    }

    if (includeDistinct) {
      returnVal.push({
        type: FilterViewTypes.Select,
        // non-strings are accepted here for "select" components
        // @ts-ignore
        label: (
          <div className="activity-filter-container">
            Activity
            <Tooltip
              target={<Icon className="info circle" />}
              hideOnScroll
              showAlways
              content={`Select “Total” to include all user activities, even repeats. Choose “Distinct” to exclude repeated activities by the same user.`}
              position="top center"
              hoverable
            />
          </div>
        ),
        propertyName: "distinct",
        items: [
          { text: "Total", value: "Total" },
          { text: "Distinct", value: "Distinct" },
        ],
        placeholder: "",
        otherProps: {
          defaultSelected: "Total",
        },
      });
    }
    const removeHiddenFilterOptions = returnVal.filter(
      (filter) => !ignoreInCountAndHide?.includes(filter.propertyName),
    );

    return removeHiddenFilterOptions;
  }, [includeAccounts, includeAccountsDropdown, includeDistinct, ignoreInCountAndHide, accounts]);

  const handleUpdate = useCallback(
    (newFilter: any) => {
      updateSubFilter(newFilter);
      updateFilter(newFilter);
    },
    [updateFilter, updateSubFilter],
  );

  return (
    <FilterFormBuilder
      items={items}
      filterOptions={{}}
      filter={filter}
      // Ignoring because GenericFiltersMap does not definitively
      // assert that the values we expect are present
      // @ts-ignore
      updateFilter={handleUpdate}
    />
  );
};
