import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type PacksContextItemModel } from "../../../../../interfaces";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type FetchActionPayload } from "../../../../../interfaces/redux";
import { getPrefix } from "../../../../Application/slices/models";
import { type PackCombination } from "../../../../../interfaces/licenseConfirmation";

export interface PacksContextState extends FetchingItemsState<PacksContextItemModel> {
  isAllDataLoaded: boolean;
  flowConflicts: Record<number, PackCombination[]>;
  // flowAssignments is the result of resolved flow conflicts
  flowAssignments: FlowAssignment[];
}

export interface FlowAssignment {
  flowId: number;
  packIds: number[];
}

export interface SetContentPackModel {
  itemId: string;
  itemType: string;
  packId?: number;
}

export interface FetchSuccessPayload extends FetchActionPayload<PacksContextItemModel> {
  flowConflicts: Record<number, PackCombination[]>;
  flowAssignments: FlowAssignment[];
}

const initialState: PacksContextState = {
  items: [],
  flowConflicts: {},
  flowAssignments: [],
  itemsCount: 0,
  isLoading: false,
  isAllDataLoaded: false,
  error: undefined,
};

export const packsContextSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.ContentPacks,
    name: "contentPacks",
  }),
  initialState,
  reducers: {
    fetchBegin(state: PacksContextState) {
      state.isLoading = true;
    },
    fetchSuccess(state: PacksContextState, action: PayloadAction<FetchSuccessPayload>) {
      state.items = action.payload.items.map((item) => {
        const itemWithAlreadySelectedPack = state.items.filter((i) => i.id === item.id && i.type === item.type)[0];
        if (itemWithAlreadySelectedPack && item.packs.some((p) => p.id === itemWithAlreadySelectedPack.packId)) {
          item.packId = itemWithAlreadySelectedPack.packId;
        }

        return item;
      });

      state.itemsCount = action.payload.totalCount;
      state.flowConflicts = action.payload.flowConflicts;
      state.flowAssignments = action.payload.flowAssignments;
      state.isLoading = false;
      state.isAllDataLoaded = true;
    },
    fetchFailure(state: PacksContextState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setContentPack(state: PacksContextState, action: PayloadAction<SetContentPackModel>) {
      const items = state.items.map((i) => {
        return {
          ...i,
          packId:
            i.id === action.payload.itemId && i.type === action.payload.itemType ? action.payload.packId : i.packId,
        };
      });

      return { ...state, items };
    },
    resolveFlowConflict(state: PacksContextState, action: PayloadAction<FlowAssignment>) {
      const assignment = state.flowAssignments.find((item) => item.flowId === action.payload.flowId);

      if (assignment) {
        assignment.packIds = action.payload.packIds;
      } else {
        state.flowAssignments = [...state.flowAssignments, action.payload];
      }
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { fetchBegin, fetchSuccess, setContentPack, fetchFailure, resolveFlowConflict, reset } =
  packsContextSlice.actions;

export default packsContextSlice.reducer;
