import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type AssignedUser } from "../../../../../interfaces";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

export interface FlowUsersToAddState extends FetchingItemsState<AssignedUser> {}

const initialState: FlowUsersToAddState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const flowUsersToAddSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Flows, name: "usersToAdd" },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = flowUsersToAddSlice.actions;

export default flowUsersToAddSlice.reducer;
export type flowUsersToAddSliceStateType = ReturnType<typeof flowUsersToAddSlice.reducer>;
