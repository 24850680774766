import React, { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { type FormikProps } from "formik";

import { type RootState } from "../../../../../Application/globaltypes/redux";
import { type Pages, type SendingProfileDetailsState } from "./types";
import EditSendingProfileSubheaderContainer from "./EditSendingProfileSubheaderContainer";
import { DetailsHeader } from "../../../../../../components/sectionHeader";
import WizardStepsManager from "../../../../../../utils/WizardStepsManager";
import Segments from "../../../../../../components/navigation/segments/Segments";
import * as sendingProfileEntityStateActions from "../state/actions/threatDefenceSendingProfileEntityStateActions";
import * as sendingProfileDetailsActions from "../state/actions/threatDefenceSendingProfileDetailsActions";
import Configure from "../DirectSendSendingProfileDetails/Configure/Configure";
import { RouteNames, PublishedStatusTypes } from "../../../../../../enums";
import { type ThreatDefenceDirectSendSendingProfileView } from "../types/state";
import RtnEventsEmitter from "../../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import modalUtilsFactory, { type ModalUtils } from "../../../../../../utils/modalUtilsFactory";
import RevertConfirmationModal from "../../../../../../components/modal/RevertConfirmationModal";
import ModalTypes from "../../../../../../components/modal/ModalTypes";
import { DirectSendSendingProfileDiscardSuccess } from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { withRouter, type WithRouterProps } from "../../../../../../adapters/withRouter/withRouter";

const sendingProfileOverviewUrl = `/${RouteNames.sendingProfilesSimulatedPhishing}`;

export type EditSendingProfileDetailsPropsAll = FormikProps<ThreatDefenceDirectSendSendingProfileView> &
  PropsFromRedux &
  WithRouterProps;

export class EditDirectSendSendingProfile extends Component<
  EditSendingProfileDetailsPropsAll,
  SendingProfileDetailsState
> {
  private readonly pages: Pages;
  private readonly id: number;
  private readonly revertModal: ModalUtils;

  private stepsManager = new WizardStepsManager();

  constructor(props: EditSendingProfileDetailsPropsAll) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.pages = {
      configure: {
        index: 0,
        segment: {
          label: "Configure",
          onClick: this.openConfigurePage,
        },
      },
      content: {
        index: 1,
        segment: {
          to: "settings",
          label: "Settings",
          onClick: this.openSettingsPage,
        },
      },
    };
    this.id = Number(this.props.params.id);
    this.revertModal = modalUtilsFactory();
  }

  componentDidMount() {
    this.props.sendingProfileDetailsActions.getThreatDefenceSendingProfile(this.id);
    RtnEventsEmitter.subscribe(
      [rtnEvents.DirectSendSendingProfilePublishSuccess, DirectSendSendingProfileDiscardSuccess],
      this.onPublishedEvent,
    );
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.DirectSendSendingProfilePublishSuccess, rtnEvents.DirectSendSendingProfileDiscardSuccess],
      this.onPublishedEvent,
    );
    this.stepsManager.dispose();
  }

  openConfigurePage = () => {
    this.stepsManager.goToPage(this.pages.configure.index);
  };

  openSettingsPage = () => {
    this.stepsManager.goToPage(this.pages.content.index);
  };

  onPublishedEvent = () => {
    this.props.sendingProfileDetailsActions.getThreatDefenceSendingProfile(this.id);
  };

  onIsValidChange = (isValid: boolean) => {
    this.setState({ isValid });
  };

  handleEdit = () => {
    this.props.sendingProfileEntityStateActions.fetchDraftThreatDefenceSendingProfileEntity(this.id);
  };

  handlePublish = () => {
    this.props.sendingProfileEntityStateActions.publishDraftThreatDefenceSendingProfileEntity(this.id);
  };

  onRevert = () => {
    this.revertModal.execute(this.props.sendingProfile.id, this.handleRevert, () =>
      this.setState({
        ...this.state,
        showRevertModal: true,
      }),
    );
  };

  renderRevertModal = (isRevertVisible: boolean) => {
    return (
      <RevertConfirmationModal
        modalUtils={this.revertModal}
        modalType={ModalTypes.RevertSendingProfile}
        open={this.state.showRevertModal}
        onClose={this.closeRevertPopup}
        isVisible={isRevertVisible}
      />
    );
  };

  closeRevertPopup = () => {
    this.setState({
      ...this.state,
      showRevertModal: false,
    });
  };

  handleRevert = () => {
    this.props.sendingProfileEntityStateActions.revertLockedThreatDefenceSendingProfile(this.id);
    this.closeRevertPopup();
  };

  handleClose = () => {
    this.props.navigate(sendingProfileOverviewUrl);
  };

  render() {
    const { sendingProfile } = this.props;
    const { configure, content } = this.pages;
    const { isValid } = this.state;
    const disabled = !sendingProfile.isDraft;

    return (
      <div className="nested-content scrollable-content">
        <DetailsHeader
          backButton
          title={sendingProfile.host}
          titleForGA="Sending Profile Details"
          canBeEdited={!sendingProfile.isDraft}
          isRevertVisible={sendingProfile.hasBeenPublished}
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!sendingProfile.isDraft)}
          invalidFormDetails={!isValid}
          entityStateActions={{
            onEdit: this.handleEdit,
            onPublish: this.handlePublish,
            onRevert: this.onRevert,
            onClose: this.handleClose,
          }}
        />
        {this.renderRevertModal(sendingProfile.hasBeenPublished)}
        <EditSendingProfileSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!sendingProfile.isDraft)}
          isUpdateInProgress={!!sendingProfile.isDraft}
          lastModifiedDateTime={sendingProfile.dateModified}
        >
          <Segments to={`/${RouteNames.sendingProfilesSimulatedPhishing}/${this.id}`}>
            <Segments.Segment {...configure.segment} />
            <Segments.Segment {...content.segment} />
          </Segments>
        </EditSendingProfileSubheaderContainer>

        <Routes>
          <Route
            path="/"
            element={
              <div className="edit-form">
                <Configure
                  entityId={this.id}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
                  onIsValidChange={this.onIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route
            path={content.segment.to}
            element={
              <div className="edit-form">
                <p>Content</p>
              </div>
            }
          />
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  sendingProfile:
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileDetailsReducer.sendingProfile,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendingProfileEntityStateActions: bindActionCreators(sendingProfileEntityStateActions, dispatch),
  sendingProfileDetailsActions: bindActionCreators(sendingProfileDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(EditDirectSendSendingProfile));
export default ConnectedComponent;
