import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";

import "./chartPopup.scss";

export interface Props {
  left: number;
  top: number;
  open: boolean;
  children?: React.ReactNode;
  useTop?: boolean;
  useLightTheme?: boolean;
}

export const ChartPopup: React.FC<Props> = ({ children, left, top, open, useTop, useLightTheme }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const measuredRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!measuredRef.current) return;
    setHeight(measuredRef.current.getBoundingClientRect().height);
    setWidth(measuredRef.current.getBoundingClientRect().width);
    // Children required in array to get accurate measurement when mounted
  }, [children]);

  const leftTop = useMemo(() => {
    if (useTop) {
      return {
        left: left - width / 2,
        top: top - height,
      };
    }
    return {
      left,
      top: top - height / 2,
    };
  }, [useTop, left, top, width, height]);

  return ReactDOM.createPortal(
    <div
      className="popupRoot"
      ref={measuredRef}
      style={{
        left: leftTop.left,
        top: leftTop.top,
      }}
    >
      <div
        className={cn("popup", {
          visible: open,
          top: useTop,
          light: useLightTheme,
        })}
        data-testid="chart popup"
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};
