import { type SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Icon, Input, Label } from "semantic-ui-react";

import cn from "classnames";

import { sortBy, keyBy, omit, pick } from "lodash";

import { FixedSizeList as LazyList } from "react-window";

import "./multiSelectWithWindow.scss";
import { TextDotDotDot } from "components/textTruncators/TextTruncators";
import globalEvents from "utils/globalEvents";

const getOrderedItems = <T extends Item>(
  itemsMap: ItemsMap<T>,
  checkedItemsMap: ItemsMap<T>,
  sortPredicate?: (items: T) => unknown,
): T[] => {
  const keys = Object.keys(checkedItemsMap);
  const uncheckedItems = Object.values(omit(itemsMap, keys));
  const checkedItems = Object.values(pick(itemsMap, keys));

  return sortPredicate
    ? sortBy(checkedItems, sortPredicate).concat(sortBy(uncheckedItems, sortPredicate))
    : checkedItems.concat(uncheckedItems);
};

const getCheckedItems = <T extends Item>(a: T[], c?: (T | number)[]) => {
  if (c === undefined || c.length === 0) {
    return {};
  }
  return c.map((itemB: T | number) => {
    if (typeof itemB === "number") {
      return a.find((item: T) => item.value === itemB);
    } else if (typeof itemB === "string") {
      return a.find((item: T) => item.value === itemB);
    } else {
      return a.find((item: T) => item.value === itemB.value);
    }
  });
};

export type Item = { text: string; key?: string; value: number | string; };
type ItemsMap<T extends Item> = { [id: string | number]: T; };

const ITEM_SIZE = 40;
const MAX_LIST_HEIGHT = 350;
const getListHeight = (itemsCount: number) => {
  const itemsHeight = itemsCount * ITEM_SIZE + 1; // 1 pixel for correct scroll behavior
  return Math.min(itemsHeight, MAX_LIST_HEIGHT);
};

export interface MultiSelectWithWindowProps<T extends Item> {
  items: T[];
  initialCheckedItems?: T[];
  placeholder?: string;
  isLoading?: boolean;
  onCheckedItemsChanged: (newChecked: T[]) => void;
  isReadOnly?: boolean;
  sortPredicate?: (items: T) => unknown;
  searchPredicate: (item: T, term: string) => boolean;
  getDescription: (item: T) => string;
  filter?: Item[] | string[];
}

export const MultiSelectWithWindow = <T extends Item>(props: MultiSelectWithWindowProps<T>) => {
  const { items, filter, initialCheckedItems, searchPredicate, sortPredicate, getDescription } = props;

  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<Input>(null);
  const listenerId = useRef("");
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewItems, setViewItems] = useState<T[]>([]);

  const [checkedItemsMap, setCheckedItemsMap] = useState<ItemsMap<T> | {}>(getCheckedItems(items, initialCheckedItems));

  useEffect(() => {
    return () => globalEvents.unsubscribe(listenerId.current);
  }, []);

  const updateListView = useCallback((allItems: T[], term: string, transformFiltered?: (filtered: T[]) => T[]) => {
    const filtered = !term ? allItems : allItems.filter((item) => searchPredicate(item, term));

    const newFiltered = filter !== undefined ? filtered.filter(
      (item: T) => !filter.some((filterItem) => typeof filterItem === "string" ? filterItem === item.value : filterItem.value === item.value)) : filtered;
    setViewItems(newFiltered);
  }, [filter, searchPredicate]);

  const initListView = (checkedItems: ItemsMap<T>, term: string) => {
    updateListView(items, term, (filtered) =>
      getOrderedItems(
        keyBy(filtered, (x) => x.value as string),
        checkedItems,
        sortPredicate,
      ),
    );
  };

  const toggle = () => {
    if (!isOpen) {
      open();
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    setCheckedItemsMap(getCheckedItems(items, filter as Item[]));
    updateListView(items, "");
  }, [filter, items, updateListView]);

  const open = () => {
    listenerId.current = globalEvents.subscribeToOutsideClick(listRef.current!, close);
    setIsOpen(true);
    initListView(checkedItemsMap, search);
  };

  const close = () => {
    globalEvents.unsubscribe(listenerId.current);
    setIsOpen(false);
    setSearch("");
  };

  const searchItem = (_: any, { value }: { value: string; }) => {
    setSearch(value);
    updateListView(items, value);
  };

  const clearSearchInput = () => {
    // issue with outside click handler
    setTimeout(() => {
      setSearch("");
      initListView(checkedItemsMap, "");
      inputRef.current?.focus();
    }, 0);
  };

  const isClearable = () => {
    return search.length !== 0;
  };

  const checkItem = (e: SyntheticEvent, checkedItem: T) => {
    let newItems = { ...checkedItemsMap };
    if (Object.values(newItems).some(item => item.value === checkedItem.value)) {
      newItems = Object.fromEntries(
        Object.entries(newItems).filter(([key, item]) => item.value !== checkedItem.value)
      );
      e?.stopPropagation();
      // Fix in a future iteration to not have reportingFilter specific logic here.
    } else if (checkedItem.value === "All Customer Accounts" || Object.values(newItems).some(item => item.value === 'All Customer Accounts')) {
      newItems = {};
      newItems[checkedItem.value] = checkedItem;
    } else {
      newItems[checkedItem.value] = checkedItem;
    }

    setTimeout(() => {
      setCheckedItemsMap(newItems);
      props.onCheckedItemsChanged?.(Object.values(newItems));
      setSearch("");
      inputRef.current?.focus();
    }, 0);
  };

  const renderDescription = (item: T) => {
    const desc = getDescription(item);
    // TextTruncate with lines={2} is not working correct here
    return (
      <TextDotDotDot clamp={2} className="item-description">
        {desc}
      </TextDotDotDot>
    );
  };

  const renderLazyItem = (index: number, style: object) => {
    const item = viewItems[index];
    return (
      <div style={style}>
        {/* These are not rendered if they are selected already */}
        <div
          aria-selected={false}
          role="option"
          className="dropdown-item"
          data-testid="dropdown-item"
          onClick={(e) => checkItem(e, item)}
        >
          {renderDescription(item)}
        </div>
      </div>
    );
  };

  const renderLazyList = () => {
    if (isOpen) {
      return (
        <LazyList
          className="checklist"
          height={getListHeight(viewItems.length)}
          itemCount={viewItems.length}
          itemSize={ITEM_SIZE}
          width={"100%"}
        >
          {({ index, style }) => renderLazyItem(index, style)}
        </LazyList>
      );
    }
  };

  const { isLoading, isReadOnly, placeholder } = props;

  return (
    <div
      ref={listRef}
      className={cn("checklist-dropdown-v3 ui fluid multiple search selection dropdown dropdown-control", {
        disabled: isLoading || isReadOnly,
        isOpen: isOpen,
      })}
      tabIndex={1}
      role="listbox"
      aria-label="Checklist Dropdown"
      aria-expanded={isOpen}
      onClick={toggle}
    >
      {
        // For each selected, draw a label
        Object.values(checkedItemsMap).map((item) => {
          if (item === undefined) {
            return null;
          }
          return (
            <Label key={item.value}>
              {item.text} <Icon name="times" link onClick={(e: SyntheticEvent) => checkItem(e, item)} />
            </Label>
          );
        })
      }
      <Input
        ref={inputRef}
        onChange={searchItem}
        autoFocus
        placeholder={filter !== undefined && filter.length !== 0 ? null : placeholder}
        icon="search"
        iconPosition="left"
        action={{
          icon: isClearable() ? <Icon disabled className="times" /> : undefined,
          onClick: clearSearchInput,
        }}
        value={search}
        fluid
        className={"search"}
      />
      <i aria-hidden="true" className="dropdown icon" />
      {renderLazyList()}
    </div>
  );
};