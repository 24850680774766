import {
  createCancelingLazyFetchingItemsSlice,
  type CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import { type PackOverview } from "../../types/state";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchGroupPacksGrid } from "../thunks/groupPacksGridThunk";

export type GroupPacksState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: GroupPacksState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const groupPacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "groupPacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchGroupPacksGrid,
});

export const resetGroupPacksGrid = groupPacksGridSlice.actions.reset;

export default groupPacksGridSlice.reducer;
