import React, { useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import FlowDesignerControlPanel from "../FlowDesignerControlPanel/FlowDesignerControlPanel";
import { type SavedFlowData } from "../types";
import { useReactFlow } from "reactflow";
import { PLACEHOLDER_NODES_NUMBER } from "../constants";
import { ExtrasTypes } from "../Extras/types";
import { getDesignerPosition } from "../utils/getDefaultPosition";
import { useReactFlowCanvasState } from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowState } from "../ReactFlowCanvas/Providers/ReactFlowStateProvider/hooks/useReactFlowState";

interface IControlPanelOwnProps {
  flowData: SavedFlowData | undefined;
  externalTriggerGroupsLength: number;
}

const ControlPanel: React.FC<IControlPanelOwnProps> = ({ externalTriggerGroupsLength }): React.ReactElement => {
  const { setViewport, getZoom } = useReactFlow();
  const { headId } = useReactFlowCanvasState();
  const { nodes } = useReactFlowState();

  const nodeLength = useMemo(() => {
    return nodes.filter((node) => !isEqual(node.type, ExtrasTypes.FlowEnd)).length - PLACEHOLDER_NODES_NUMBER || 0;
  }, [nodes]);

  const moveToStartOfFlow = useCallback(() => {
    setViewport(getDesignerPosition(externalTriggerGroupsLength, getZoom()));
  }, [getZoom, externalTriggerGroupsLength, setViewport]);

  return (
    <>
      <FlowDesignerControlPanel
        totalItems={nodeLength}
        moveToStartOfFlow={moveToStartOfFlow}
        isJumpToStartOfFlowDisabled={!headId}
      />
    </>
  );
};

export default ControlPanel;
