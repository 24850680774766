import React, { type FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { type FilterFormPropsBase } from "../types/props";
import { getVideosFilters } from "./videosFilters";
import { type VideoFiltersEnum } from "../../../features/Library/Videos/types/models";

export interface VideosFilterFormForPacksProps extends FilterFormPropsBase<VideoFiltersEnum> {}

const VideosFilterFormForPacks: FC<VideosFilterFormForPacksProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getVideosFilters(false)}
  />
);

export default VideosFilterFormForPacks;
