import { Route, Routes, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";

import { AppAuth } from "./auth/AppAuth";
import { SignoutCallback } from "./auth/SignoutCallback";
import AppErrorHandler from "./components/AppError/AppErrorHandler";
import ErrorContainer from "./components/AppError/ErrorContainer";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/*"
      element={
        <>
          <AppErrorHandler />
          <ErrorContainer id="app-error-root">
            <Routes>
              <Route path="signout-callback" element={<SignoutCallback />} />
              <Route path="ms-consent" element={<div />} />
              <Route path="consent" element={<div />} />
              <Route path="*" element={<AppAuth />} />
            </Routes>
          </ErrorContainer>
        </>
      }
    />,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
);

const Root = () => <RouterProvider router={router} />;
export default Root;
