import { ImagePreview } from "components/forms";
import Form from "components/forms/layout";
import { EndpointsToGetSasLink } from "enums";
import ImageAspectRatio from "enums/imageAspectRatio";
import { AccountInfoLayout } from "./AccountInfoLayout";
import InputField from "components/forms/InputField";
import { isEmpty } from "lodash";
import { type IWizardStep } from "interfaces";
import { useEffect } from "react";

interface Props extends IWizardStep {
  logoUri: Nullable<string>;
  name: string;
  onRemoved: () => void;
}

export const ExistingAccount = (props: Props) => {
  const { logoUri, name, onRemoved } = props;

  useEffect(() => {
    props.acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Root>
      <Form.Block title="Account Details">
        <AccountInfoLayout
          image={
            <ImagePreview
              label="Logo"
              placeholder="Logo"
              value={logoUri}
              isHidden
              aspectRatio={ImageAspectRatio.SQUARE_1X1}
              shouldValidate={false}
              endpointToGetSasLink={EndpointsToGetSasLink.Account}
              disabled
            />
          }
          name={
            <InputField
              label="Company Name"
              markAsRequired
              propertyName=""
              value={name}
              clearable
              setFieldValue={(_, v) => {
                if (isEmpty(v)) {
                  onRemoved();
                }
              }}
            />
          }
        />
      </Form.Block>
    </Form.Root>
  );
};
