import { type ContentTypesEnum } from "enums";

export const Icons: {
  [key in ContentTypesEnum]: string;
} = {
  Flows: "fa-code-branch",
  Assets: "fa-copy",
  Videos: "fa-video",
  Links: "fa-link",
  Surveys: "list ul",
  Assessments: "list ol",
  Events: "fa-calendar-alt",
  Emails: "fa-envelope",
  Messages: "fa-messages",
  PDFs: "fa-file-pdf",
};
