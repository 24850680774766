import React from "react";
import AssetTypes from "../../../enums/assetTypes";
import CommunicationTypes from "../../../enums/communicationTypes";
import "./flowDesignerCardThumbnail.scss";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import { type EntityType } from "../../../features/Library/Flows/Designer/types";
import { ReactComponent as TeamsIcon } from "../../../images/teams-icon.svg";
import { ReactComponent as GenericMessagesIcon } from "../../../images/generic-messages-icon.svg";
import EventTypes from "../../../enums/eventTypes";
import { Icon, Image } from "semantic-ui-react";
import { ExtrasTypes } from "../../../features/Library/Flows/Designer/Extras/types";
import FlowEndIcon from "../../../images/flow-end-icon.png";

export interface Props {
  type: EntityType;
  thumbnailUrl?: string;
}

type ThumbnailIcons = {
  [key: string]: {
    icon: React.ReactNode;
  };
};

const thumbnailIcons: ThumbnailIcons = {
  [AssetTypes.Assessment]: {
    icon: <i className="list ol large icon" />,
  },
  [AssetTypes.Survey]: {
    icon: <i className="list ul large icon" />,
  },
  [CommunicationTypes.Email]: {
    icon: <i className="big fa-envelope icon bolder" />,
  },
  [CommunicationTypes.Message]: {
    icon: <i className="big fa-message icon bolder" />,
  },
  [CommunicationTypes.Teams]: {
    icon: <TeamsIcon className="filter-icon bolder" />,
  },
  [CommunicationTypes.GenericMessages]: {
    icon: <GenericMessagesIcon className="filter-icon bolder" />,
  },
  [EventTypes.ExternalEvent]: {
    icon: <Icon className="calendar alternate" size="big" />,
  },
  [ExtrasTypes.FlowEnd]: {
    icon: <Image src={FlowEndIcon} />,
  },
  [AssetTypes.Pdf]: {
    icon: <Icon className="fa-file-pdf" size="big" />,
  },
};

function FlowDesignerCardThumbnail({ type, thumbnailUrl }: Props) {
  const renderWithThumbnail = () => (
    <div className="flow-designer-card-thumbnail">
      <div className="picture">
        <CardThumbnail thumbnailUrl={thumbnailUrl} draggable={false} />
      </div>
    </div>
  );

  const renderWithDefaultThumbnail = () => (
    <div className="flow-designer-card-thumbnail">
      <div className="thumbnail-icon">{thumbnailIcons[type].icon}</div>
    </div>
  );

  switch (type) {
    case AssetTypes.Assessment:
    case AssetTypes.Survey:
    case CommunicationTypes.Email:
    case CommunicationTypes.Message:
    case CommunicationTypes.Teams:
    case CommunicationTypes.GenericMessages:
    case EventTypes.ExternalEvent:
    case ExtrasTypes.FlowEnd:
      return renderWithDefaultThumbnail();
    case AssetTypes.Pdf:
      return thumbnailUrl ? renderWithThumbnail() : renderWithDefaultThumbnail();
    default:
      return renderWithThumbnail();
  }
}

FlowDesignerCardThumbnail.defaultProps = {
  type: AssetTypes.Video,
};

export default FlowDesignerCardThumbnail;
