import type ColumnOption from "../../../../../../interfaces/columnOptions";

export const columnsMap: { [key: string]: string } = {
  members: "membersCount",
  "license issued": "dateAdded",
  access: "isFullAccess",
};

export const getColumnOptions = (licenseRemovalEnabled: boolean): Array<ColumnOption> => {
  const options: ColumnOption[] = [
    {
      name: "Name",
      width: 6,
      isSortable: false,
    },
    {
      name: "Members",
      width: 4,
      isSortable: false,
    },
    {
      name: "License Issued",
      width: 4,
      isSortable: true,
    },
  ];

  licenseRemovalEnabled &&
    options.push({
      name: "",
      width: 2,
      isSortable: false,
    });

  return options;
};
