import { LeftArrowButton } from "../../../../../../components/buttons/arrowButtons";

import styles from "./backButton.module.scss";

export interface BackButtonProsp {
  title: string;
  onClick: () => void;
}

export const BackButton = (props: BackButtonProsp) => {
  const { title, onClick } = props;

  return (
    <div className={styles.root}>
      <LeftArrowButton onClick={onClick} size="large" />
      <h4>{title}</h4>
    </div>
  );
};
