import { type FC } from "react";
import { Button } from "components/buttons/button/Button";

import { Modal } from "../../modal";
import { type PaywallModalProps, type PaywallModalOption, type PaywallModalOptions, PaywallModalTypes } from "./types";
import ModalSizes from "../../../enums/modalSizes";

import { ReactComponent as CreateContentRestrictedIcon } from "../../../images/create-content-restricted.svg";
import { ReactComponent as CreateContentExpiredIcon } from "../../../images/create-content-expired.svg";

import "./paywallModal.scss";

const PaywallModalsOptions: PaywallModalOptions = {
  [PaywallModalTypes.UpgradeToCreateContent]: {
    icon: CreateContentRestrictedIcon,
    title: "Upgrade to create content",
    description:
      "In order to create content, you will need the Create Content add-on. Upgrade today to start creating content!",
    actionText: "Request add-on",
  },
  [PaywallModalTypes.RenewCreateContent]: {
    icon: CreateContentExpiredIcon,
    title: "Renew your Create Content add-on",
    description: "Your Create add-on expired on MM/DD/YY. Renew today to continue creating content!",
    actionText: "Renew add-on",
  },
};

const PaywallModal: FC<PaywallModalProps> = ({ onCancel, show, modalType }) => {
  const modalOptions: PaywallModalOption = PaywallModalsOptions[modalType];
  const handleRequest = () => {}; // NOSONAR

  const renderModalActions = () => (
    <Button onClick={handleRequest} className={"blue"}>
      {modalOptions.actionText}
    </Button>
  );

  return (
    <Modal
      size={ModalSizes.small}
      renderModalActions={renderModalActions}
      open={show}
      onCancel={onCancel}
      className={"paywall-modal"}
    >
      <div className={"paywall-modal-content"}>
        <modalOptions.icon className={"paywall-modal-icon"} />
        <span className={"paywall-modal-title"}>{modalOptions.title}</span>
        <span className={"paywall-modal-description"}>{modalOptions.description}</span>
      </div>
    </Modal>
  );
};

export default PaywallModal;
