import { type VideoStatusCompute } from "./VideoPerformancePeople";

export const VideoPerformancePeopleStatusToCell: VideoStatusCompute = {
  Complete: {
    icon: "check circle",
    color: "icon-send-complete",
  },
  "In Progress": {
    icon: "clock",
    color: "icon-send-progress",
  },
  "Not Started": {
    icon: "circle outline",
    color: "icon-send-canceled",
  },
};
