import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import { ReactComponent as MembersOnly } from "../../../images/visibility-members-only.svg";
import { ReactComponent as VisibleToAll } from "../../../images/visibility-visible-to-all.svg";

export default function GroupVisibilityIcon(props) {
  const { isVisibleToAll } = props;

  const popupTrigger = isVisibleToAll ? (
    <VisibleToAll className="visible-to-all-icon" />
  ) : (
    <MembersOnly className="members-only-icon" />
  );

  const popupHeader = isVisibleToAll ? "VISIBLE TO ALL" : "MEMBERS ONLY";

  const popupContent = isVisibleToAll
    ? "This group is visible to all end users."
    : "This group is visible to members only.";

  return (
    <Popup id="visiblePopup" position="top left" trigger={<span>{popupTrigger}</span>} inverted>
      <Popup.Header>{popupHeader}</Popup.Header>
      <Popup.Content>{popupContent}</Popup.Content>
    </Popup>
  );
}

GroupVisibilityIcon.defaultProps = {
  isVisibleToAll: false,
};

GroupVisibilityIcon.propTypes = {
  isVisibleToAll: PropTypes.bool,
};
