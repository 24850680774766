import { createSlice, type PayloadAction, type SliceCaseReducers } from "@reduxjs/toolkit";
import { type FetchActionPayload } from "../../../interfaces/redux";
import { type CreateSlicePayload, getPrefix } from "./models";

export interface LazyFetchingItemsState<T> {
  items: T[];
  itemsCount: number;
  isLoading: boolean;
  error?: Error;
  areAllLoaded: boolean;
}

export const createLazyFetchingItemsSlice = <
  T,
  TState extends LazyFetchingItemsState<T> = LazyFetchingItemsState<T>,
  Reducers extends SliceCaseReducers<TState> = SliceCaseReducers<TState>,
>({
  namePayload,
  initialState,
  reducers,
}: CreateSlicePayload<TState, Reducers>) => {
  return createSlice({
    name: getPrefix(namePayload),
    initialState,
    reducers: {
      fetchBegin(state) {
        state.isLoading = true;
      },
      fetchSuccess(state: TState, action: PayloadAction<FetchActionPayload<T>>) {
        state.items = state.items.concat(action.payload.items);
        state.itemsCount = state.items.length;
        state.isLoading = false;
        state.areAllLoaded = state.itemsCount >= action.payload.totalCount;
      },
      refetchSuccess(state: TState, action: PayloadAction<FetchActionPayload<T>>) {
        state.items = action.payload.items;
        state.itemsCount = state.items.length;
        state.isLoading = false;
        state.areAllLoaded = state.itemsCount >= action.payload.totalCount;
      },
      fetchFailure(state: TState, action: PayloadAction<Error>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      reset() {
        return initialState;
      },
      ...reducers,
    },
  });
};
