import { TitleSubtitle } from "../../../../../../components/listViewTemplates";
import UserProfilePicture from "../../../../../../components/userProfilePicture/UserProfilePicture";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { type IOwnerItemModel } from "../../../../types";
import "./ownerInfoCell.scss";

const style = {
  width: "17rem",
};

export interface OwnerInfoCellProps {
  user: IOwnerItemModel;
  isSelected: boolean;
  url?: string;
}

const OwnerInfoCell = ({ user, isSelected, url }: OwnerInfoCellProps) => (
  <div className="name-column-value-container">
    <UserProfilePicture imageUrl={user.imageUrl} />
    <TitleSubtitle
      title={`${user.firstName} ${user.lastName}`}
      subtitle={user.email}
      url={url}
      label={
        isSelected ? (
          <Tooltip
            target={<div className={"owner"}>OWNER</div>}
            content="A group owner has permissions to add, remove or approve group members."
            position="top center"
            styles={style}
            hideOnScroll
            showAlways
          />
        ) : undefined
      }
    />
  </div>
);

export default OwnerInfoCell;
