import { type FC, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "../../../../../../../components/modal/ModalWithSteps";
import { PackVisibilityForAccountTypesStep } from "./PackVisibilityForAccountTypesStep";
import { type PackVisibilityForAccountTypesModalProps } from "../types";
import { type AppDispatch } from "../../../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../../../interfaces/redux";
import { reset } from "../../../../state/slices/accountTypesAvailableForPackSlice";
import "./packVisibilityForAccountTypesModal.scss";

export type PackVisibilityForAccountTypesModalPropsWithRedux = PropsFromRedux & PackVisibilityForAccountTypesModalProps;

export const PackVisibilityForAccountTypesModal: FC<PackVisibilityForAccountTypesModalPropsWithRedux> = ({
  showModal,
  onCancel,
  onConfirm,
  loadPage,
  id,
  accountTypes,
  isLoading,
  resetAvailablePackAccountTypes,
}) => {
  const [selectedIds, setSelectionIds] = useState<number[]>([]);

  useEffect(() => {
    if (!showModal) {
      setSelectionIds([]);
      resetAvailablePackAccountTypes();
    }
  }, [showModal, resetAvailablePackAccountTypes]);

  const renderModalActions = () => {
    return (closeModal: Function) => {
      return (
        <>
          <Button
            basic
            className="cancel"
            content="Cancel"
            onClick={() => {
              closeModal();
              onCancel();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={selectedIds.length === 0}
            onClick={() => {
              onConfirm(selectedIds);
              closeModal();
            }}
          />
        </>
      );
    };
  };

  return (
    <ModalWithSteps
      className="pack-account-types-visibility-modal"
      scrolling={false}
      isLoading={false}
      showModal={showModal}
      onCancel={onCancel}
    >
      <PackVisibilityForAccountTypesStep
        id={id}
        header="Add Account Types"
        renderModalActions={renderModalActions}
        loadPage={loadPage}
        isLoading={isLoading}
        accountTypes={accountTypes}
        onSelectedListItemsChanged={setSelectionIds}
        selectedIds={selectedIds}
      />
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetAvailablePackAccountTypes: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackVisibilityForAccountTypesModal);
export default ConnectedComponent;
