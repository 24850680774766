import { connect } from "react-redux";
import { getMapTagsSelector } from "../../../../Application/selectors/tagSelectors";
import VideoForm from "../VideoForm";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { fetchVideoTags } from "../../state/thunks/videoBaseThunk";
import { videosStateSelector } from "../../state/selectors";
import { bindAction } from "../../../../../interfaces/redux";
import { refechClosedCaptions } from "../../state/thunks/videoClosedCaptionsThunk";
import vjsLanguageUtils from "../../../../../components/common/video/plugins/settingsMenu/utils/vjsLanguageUtils";
import { getAssignedClosedCaptions } from "../../state/selectors/detailsSelector";
import { resetClosedCaptions } from "../../state/slices/videoClosedCaptionsSlice";

const getTags = (state: RootState) => videosStateSelector(state).base.tagsBase.tags;
const mapTagsToView = getMapTagsSelector(getTags);

const mapStateToProps = (state: RootState) => ({
  tags: mapTagsToView(state),
  textTracks: vjsLanguageUtils.allLanguagesRemoteTextTracks(getAssignedClosedCaptions(state).items),
  areTextTracksLoading: getAssignedClosedCaptions(state).isLoading,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchVideoTags: () => dispatch(fetchVideoTags()),
  fetchClosedCaptions: bindAction(refechClosedCaptions, dispatch),
  resetClosedCaptions: bindAction(resetClosedCaptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoForm);
