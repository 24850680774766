import { type FormikProps } from "formik";
import { ValidatedForm } from "../../../../../../components/forms";
import { clipboardInterceptors, keyboardInterceptors } from "../../../../../../utils/components/inputUtils";
import { nameofFactory } from "../../../../../../utils/nameof";
import Dropdown from "../../../../Common/Fields/Dropdown";
import InputField from "../../../../Common/Fields/InputField";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { type ReminderBase, ScheduleType } from "../../../types/models";

import "./createReminderForm.scss";

export interface CreateReminderFormProps extends FormikProps<ReminderBase> {
  disabled?: boolean;
}

const ScheduleTypeOptions = [
  { text: "[x] day(s) before", value: ScheduleType.DaysBefore },
  { text: "[x] minute(s) before", value: ScheduleType.MinutesBefore },
  { text: "[x] hour(s) before", value: ScheduleType.HoursBefore },
];

export const CreateReminderForm = (props: CreateReminderFormProps) => {
  const { values, disabled, setFieldTouched, setFieldValue, touched } = props;
  const nameof = nameofFactory<ReminderBase>();

  const onTitleBlur = () => {
    const descriptionField = nameof("description");
    if (!touched[descriptionField]) {
      setFieldValue(descriptionField, values.title, true);
      setTimeout(() => setFieldTouched(descriptionField, true));
    }
  };

  const getFields = () => (
    <>
      <WithFormikPayload
        propertyName={nameof("title")}
        {...props}
        render={(formikProps) => (
          <InputField
            label="Title"
            placeholder={"Enter reminder title"}
            value={values.title}
            disabled={disabled}
            errorPosition={"bottom"}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onBlur={onTitleBlur}
            {...formikProps}
          />
        )}
      />
      <WithFormikPayload
        propertyName={nameof("description")}
        {...props}
        render={(formikProps) => (
          <InputField
            label="Description"
            placeholder={"Enter reminder description"}
            value={values.description}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            errorPosition={"bottom"}
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
      <div className="create-reminder-form__schedule">
        <div>
          <WithFormikPayload
            {...props}
            propertyName={nameof("scheduleType")}
            render={(formikProps) => (
              <Dropdown
                {...formikProps}
                label="Schedule Type"
                value={values.scheduleType}
                options={ScheduleTypeOptions}
                errorPosition={"bottom"}
                disabled={disabled}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                placeholder={"Select schedule type"}
              />
            )}
          />
        </div>
        <div>
          <WithFormikPayload
            propertyName={nameof("time")}
            {...props}
            render={(formikProps) => (
              <InputField
                label="Time"
                placeholder={"Enter Time Value"}
                value={values.time}
                disabled={disabled}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                errorPosition={"bottom"}
                type={"number"}
                onKeyPress={keyboardInterceptors.integerOnly}
                onPaste={clipboardInterceptors.integerOnly}
                {...formikProps}
              />
            )}
          />
        </div>
      </div>
    </>
  );

  return (
    <div className="create-reminder-form">
      <ValidatedForm dirty={props.dirty} formWidthComputer={16} parentWithFormik>
        {getFields}
      </ValidatedForm>
    </div>
  );
};

export default CreateReminderForm;
