import { type ColumnOptions } from "interfaces";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 4,
    isSortable: true,
    className: "user-name-header",
  },
  {
    name: "Department",
    width: 3,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 3,
    isSortable: true,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "Last Login",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 2,
  },
];
