import { connect, type ConnectedProps } from "react-redux";
import { intersection } from "lodash";
import cn from "classnames";

import type Icon from "../../../interfaces/icon";
import { PackStatusLabelWithIcon } from "../../packs/packStatusLabelWithIcon/PackStatusLabelWithIcon";
import { PackPublisherInfo } from "../../packs/packPublisherInfo/PackPublisherInfo";
import { RolePermissions } from "../../../enums";
import { AccountPackMenu } from "../../../features/Licensing/Packs/shared/AccountPackMenu/AccountPackMenu";
import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";
import { type RootState } from "../../../features/Application/globaltypes/redux";
import { PackArchivedLabel } from "../../packs/packsList/packArchivedLabel/PackArchivedLabel";
import { permissionsToAccountSelector } from "../../../features/Accounts/EditAccount/state/slices/permissionsToAccountSlice";
import SelectableCard from "../selectableCard/SelectableCard";
import CardContent from "../cardContent/CardContent";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import { type AccountPack } from "../../../features/Accounts/types";

import "./accountPackCard.scss";

export interface AccountPackCardProps {
  item: AccountPack;
  selected: boolean;
  disabled: boolean;
  url?: (id: number) => string;
  onSelect: (id: number) => void;
  icons?: Icon[];
  onEditLicensing: (id: number) => void;
  onExtendLicenses?: (id: number, title: string, thumbnailImageUrl: string) => void;
  onRemovePackFromAccount?: (id: number) => void;
  isAllowedToRemovePackFromAccount?: () => boolean;
  onArchivePack?: (id: number) => void;
  onRestorePack?: (id: number, title: string, thumbnailImageUrl: string) => void;
  hasPurchasePower: boolean;
  isAccountHome: boolean;
}

export type AccountPacksCardAllProps = AccountPackCardProps & PropsFromRedux;

export function AccountPackCard(props: AccountPacksCardAllProps) {
  const {
    onSelect,
    url,
    icons,
    onEditLicensing,
    onRemovePackFromAccount,
    isAllowedToRemovePackFromAccount,
    onExtendLicenses,
    onArchivePack,
    onRestorePack,
    userPermissions,
    hasPurchasePower,
    isAccountHome,
    item,
  } = props;
  const { id, thumbnailImageUrl, title, description, isTrial, isArchived, isOwn, isExpired, publisher, hasSource } =
    item;

  const hasAccountManageRole = intersection(userPermissions, [RolePermissions.AccountsSettingsManage]).length > 0;
  const isOwnPack = isOwn;
  const cardUrl = url ? url(id) : "";

  return (
    <SelectableCard
      id={id}
      className={cn("account-pack-card")}
      onSelect={onSelect}
      disabled={isArchived}
      url={isArchived ? "" : cardUrl}
    >
      <CardThumbnail thumbnailUrl={thumbnailImageUrl} disabled={isArchived} />
      <CardContent
        title={title}
        icons={icons}
        preHeader={<PackPublisherInfo publisherName={publisher} disabled={isArchived} />}
        postHeader={<PackArchivedLabel isArchived={isArchived} />}
        disabled={isArchived}
      >
        <div className="description-row">{description}</div>
        <div className="bottom-row">
          <PackStatusLabelWithIcon
            isPurchased
            isRequested={false}
            isExpired={isExpired}
            isTrial={isTrial}
            isOwn={isOwn}
          />
          <RestrictedByTooltip hasPermission={hasAccountManageRole}>
            <div onClick={(e) => e.preventDefault()}>
              <AccountPackMenu
                id={id}
                title={title}
                thumbnailImageUrl={thumbnailImageUrl}
                isExpired={isExpired}
                isTrial={isTrial}
                isArchived={isArchived || false}
                onEditLicensing={onEditLicensing}
                onExtendLicenses={onExtendLicenses}
                onRemovePackFromAccount={onRemovePackFromAccount}
                isAllowedToRemovePackFromAccount={isAllowedToRemovePackFromAccount}
                onArchivePack={!isArchived ? onArchivePack : undefined}
                hasAccountManageRole={hasAccountManageRole}
                onRestorePack={isArchived ? onRestorePack : undefined}
                hasPurchasePower={hasPurchasePower}
                isAccountHome={isAccountHome}
                isOwnPack={isOwnPack}
                hasSource={hasSource}
              />
            </div>
          </RestrictedByTooltip>
        </div>
      </CardContent>
    </SelectableCard>
  );
}

const mapStateToProps = (state: RootState) => ({
  userPermissions: permissionsToAccountSelector(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AccountPackCard);
export default ConnectedComponent;
