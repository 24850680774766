import { type FormikProps } from "formik";
import { Component } from "react";

import { ValidatedForm } from "../../../../../../../components";
import { type SmtpAuthSendingProfileFormPropsOwn } from "../types";
import { type ThreatDefenceSmtpAuthSendingProfileView } from "../../types/state";

export type SmtpAuthSendingProfileFormPropsAll = SmtpAuthSendingProfileFormPropsOwn &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileView>;

export default class SmtpAuthSendingProfileForm extends Component<SmtpAuthSendingProfileFormPropsAll> {
  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message:
            "Are you sure you want to exit without saving this sending profile? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onBlur, onIsValidChange } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="smtp-sending-profile-form-container">
        <ValidatedForm.InputField
          label="Sender Name"
          value={values.senderName}
          propertyName="senderName"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Sender Email"
          value={values.senderEmail}
          propertyName="senderEmail"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
