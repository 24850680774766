import { useQuery } from "@tanstack/react-query";
import CardReporting, { type CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { type PerformanceFilter, type QueryFilter, roundToTwoDigits } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { type PeopleInteractionItem } from "features/Reporting/types/people";
import { type ColumnOption } from "interfaces/columnOptions";
import { useMemo } from "react";
import dateTimeUtils from "utils/dateTimeUtils";
import { usePeopleInteractionTableQuery } from "../queries";

const fetchPeopleInteractionStatistics = async ({ queryKey }: QueryFilter): Promise<CardReportingItem[]> => {
  const result = await peopleReportingService.activity.getPeopleInteractionStatistics(queryKey[1]);

  return [
    {
      stat: (result.data.Users ?? 0).toLocaleString(),
      statDescription: "Users",
      popupBody:
        "Total number of users who interacted with the platform, opened an email, started a flow, etc. during the specified time period.",
    },
    {
      stat: (result.data.Interactions ?? 0).toLocaleString(),
      statDescription: "Interactions",
      popupBody:
        "Total number of user interactions for all participating users across all interaction types during the specified time period.",
    },
    {
      stat: roundToTwoDigits(result.data.AvgInteractions || 0).toLocaleString(),
      statDescription: "Avg. Interactions/User",
      popupBody:
        "Total number of user interactions across all types divided by the total number of participating users during the specified time period.",
    },
  ];
};

export interface Props {
  dateFilter: PerformanceFilter;
  setSelectedDrilldown: (category: string) => void;
}

export const UserInteraction = ({ dateFilter, setSelectedDrilldown }: Props) => {
  const peopleInteractionStatisticsQuery = useQuery({
    queryFn: fetchPeopleInteractionStatistics,
    queryKey: ["people interaction statistics", dateFilter],
  });

  const peopleInteractionTableQuery = usePeopleInteractionTableQuery(dateFilter);

  const columns: ColumnOption<PeopleInteractionItem>[] = useMemo(
    () => [
      {
        name: "Type",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <button className="invisibleButton" onClick={() => setSelectedDrilldown(item.InteractionType)}>
              {item.InteractionType}
            </button>
          );
        },
      },
      {
        name: "Users",
        width: 7,
        isSortable: false,
        render(item) {
          return <span>{(item.Users).toLocaleString()}</span>;
        },
      },
      {
        name: "Interactions",
        width: 7,
        isSortable: false,
        render(item) {
          return <span>{(item.InteractionCount).toLocaleString()}</span>;
        },
      },
      {
        name: "Last Activity",
        width: 7,
        isSortable: false,
        render(item) {
          return <span>{item.LastInteraction ? dateTimeUtils.formatDate(item.LastInteraction) : "-"}</span>;
        },
      },
    ],
    [setSelectedDrilldown],
  );

  return (
    <div className="tabSpacer">
      <div>
        <RequestStatusRenderer state={peopleInteractionStatisticsQuery.status}>
          <CardReporting items={peopleInteractionStatisticsQuery.data!} />
        </RequestStatusRenderer>
      </div>
      <div className="tabSpacer">
        <RequestStatusRenderer state={peopleInteractionTableQuery.status}>
          <GenericPerformanceList
            rows={peopleInteractionTableQuery.data!}
            columns={columns}
            filter={dateFilter}
            hideControls={false}
          />
        </RequestStatusRenderer>
      </div>
    </div>
  );
};
