import { BlockFormats, type CustomElement } from "./types";
import { type ReactNode } from "react";

export interface Props {
  attributes: any;
  children: ReactNode;
  element: CustomElement;
}

export const Element = ({ attributes, children, element }: Props) => {
  switch (element.type) {
    case BlockFormats.BulletedList:
      return <ul {...attributes}>{children}</ul>;
    case BlockFormats.HeadingOne:
      return <h1 {...attributes}>{children}</h1>;
    case BlockFormats.HeadingTwo:
      return <h2 {...attributes}>{children}</h2>;
    case BlockFormats.ListItem:
      return <li {...attributes}>{children}</li>;
    case BlockFormats.NumberedList:
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
