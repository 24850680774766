import { type FC, useCallback, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type TemplateGroupsOverviewProps } from "../types";
import { RolePermissions, SortingDirection } from "../../../../../enums";
import { bindAction } from "../../../../../interfaces";
import { NoResultsWithButton, SearchInput } from "../../../../../components";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { isEmpty } from "lodash";
import {
  Columns,
  columnToParamMap,
  getReadonlyColumnOptions,
} from "features/People/GroupTemplate/Overview/columnOptions";
import { TemplatesFilterForm } from "./TemplatesFilterForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";

import Restricted from "features/Application/Restricted";
import { setSelected } from "../../state/slice/TemplateOverviewSlice";
import { fetchTemplates } from "../../state/thunk/TemplateOverviewThunk";
import { resetAppliedFilter, setAppliedFilter, setSearch } from "../../state/slice/TemplateFiltersSlice";

export type TemplateGroupsOverviewPropsAll = TemplateGroupsOverviewProps & PropsFromRedux;

export const TemplateGroupsOverview: FC<TemplateGroupsOverviewPropsAll> = (
  props: TemplateGroupsOverviewPropsAll,
): React.ReactElement => {
  const {
    fetchTemplates,
    items,
    itemsCount,
    isLoading,
    onSearchChanged,
    applyFilter,
    resetFilter,
    filters,
    customHeaderContent,
    assignedPeopleIds,
    isReadOnly,
    setSelected,
  } = props;

  const reloadListItemsRef = useRef<(enableSorting: boolean) => void>();

  const handleSearchChanged = (search: string) => {
    onSearchChanged(search);
    reloadListItemsRef.current?.(isEmpty(search));
  };

  const handleFetch = useCallback(
    (
      skip: number = 0,
      top: number = 10,
      sortingColumnName: string = Columns.Added,
      sortingDirection: SortingDirection = SortingDirection.Descending,
    ) => {
      const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<Columns>];
      fetchTemplates(skip, top, sortBy, sortingDirection);
    },
    [fetchTemplates],
  );

  const renderNoResults = () => {
    return (
      <NoResultsWithButton
        title="You don’t have any templates"
        description="Looks like you don’t have any templates."
        icon={<FontAwesomeIcon icon={faBuildingUser} className="no-results-icon" />}
        filtered={!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)}
      />
    );
  };

  return (
    <Restricted
      permissions={[RolePermissions.TemplatesView]}
      renderContent={(hasAnyPermission: boolean) => (
        <GenericItemsView
          items={items}
          isLoading={isLoading}
          columnOptions={getReadonlyColumnOptions()}
          fetchData={handleFetch}
          dataCount={itemsCount}
          onSelectedItemChanged={setSelected}
          selectedIds={assignedPeopleIds}
          customHeaderContent={customHeaderContent}
          renderSearch={() => <SearchInput placeholder="Search for template" onChange={handleSearchChanged} />}
          // @ts-ignore
          getFilterForm={() => <TemplatesFilterForm />}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          setReloadListItems={(reloadListItems) => (reloadListItemsRef.current = reloadListItems)}
          appliedFilter={filters.appliedFilter}
          noResultsContent={renderNoResults()}
          isSelectDisabled={() => isReadOnly}
          accessRestricted={!hasAnyPermission}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { templatesToBeAssigned, filters } = state.library.groupTemplatesAssignment;

  return {
    ...templatesToBeAssigned,
    filters,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSelected: (ids: number[]) => dispatch(setSelected(ids)),
    fetchTemplates: bindAction(fetchTemplates, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(TemplateGroupsOverview);
export default ConnectedComponent;
