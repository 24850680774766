import { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { setSignalRState } from "../slices/appStateSlice";

import initializeHubClient from "../services/realTimeNotification/initializeHubClient";

export class NotificationProvider extends Component {
  constructor(props) {
    super(props);
    this.realTimeNotificationClient = initializeHubClient(
      this.props.dispatch,
      this.props.mobo,
      () => this.props.asyncOperations,
      this.props.setSignalRState,
    );
  }

  componentDidMount() {
    return this.realTimeNotificationClient.connect();
  }

  async componentDidUpdate(prevProps) {
    const { mobo } = this.props;
    if (!isEqual(mobo, prevProps.mobo)) {
      await this.realTimeNotificationClient.reconnect();
    }
  }

  componentWillUnmount() {
    return this.realTimeNotificationClient.disconnect();
  }

  render() {
    return null;
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setSignalRState: (isConnected) => dispatch(setSignalRState(isConnected)),
  };
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    mobo: state.userProfile.isMobo ? { id: state.userProfile.accountId } : null,
    asyncOperations: state.asyncOperations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationProvider);
