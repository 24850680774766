import * as Yup from "yup";
import validationRules from "../validationRules";

import { labels, softwareApplications } from "./tagsValidationSchema";

const subjectLengths = {
  min: 3,
  max: 500,
};

const nameLengths = {
  min: 3,
  max: 64,
};

const subject = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Subject Line is required")
    .test(
      "subject_restricted_domains",
      "Subject Line contains restricted domain(s)",
      validationRules.hasNoBrainstormUrls,
    )
    .min(subjectLengths.min, `Subject Line must have at least ${subjectLengths.min} characters`)
    .max(subjectLengths.max, `Subject Line must be maximum of ${subjectLengths.max} characters long`);

const name = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Name is required")
    .min(nameLengths.min, `Name must have at least ${nameLengths.min} characters`)
    .max(nameLengths.max, `Name must be maximum of ${nameLengths.max} characters long`);

export const emailTemplateInfo = Yup.object().shape({
  name: name(),
  subject: subject(),
  labels,
  softwareApplications,
});

export const emailTemplateContent = Yup.object().shape({
  html: validationRules.htmlWithoutBraistormDomains(),
});
