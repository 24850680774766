import { type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";
import { MessageFilterPropertyNames } from "../../../features/Library/Communications/Messages/types/state";

export const getMessageFilters = (): FilterItemBase<MessageFilterPropertyNames>[] => {
  return [
    {
      type: FilterViewTypes.DateRange,
      label: "Date Added",
      propertyName: MessageFilterPropertyNames.DateCreated,
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Last Modified",
      propertyName: MessageFilterPropertyNames.DateModified,
    },
  ];
};
