import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "../../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type EmailSendDetailEntitySansId } from "../../EmailDetails/Performance/types";

export interface EmailsSendDetailsState extends FetchingItemsState<EmailSendDetailEntitySansId> {}

export const initialState: EmailsSendDetailsState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const emailSendDetailsSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Emails,
    name: "sendDetails",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = emailSendDetailsSlice.actions;

export const emailSendDetailsReducer = emailSendDetailsSlice.reducer;
export type emailsSendDetailsSliceStateType = ReturnType<typeof emailSendDetailsReducer>;
