export const AUTO_COLLAPSE_SIDEBAR = "AUTO_COLLAPSE_SIDEBAR";
export const COLLAPSE_SIDEBAR_BY_USER = "COLLAPSE_SIDEBAR_BY_USER";
export const EXPAND_SIDEBAR = "EXPAND_SIDEBAR";

export type AutoCollapseSidebar = {
  type: typeof AUTO_COLLAPSE_SIDEBAR;
};
export type CollapseSidebarByUser = {
  type: typeof COLLAPSE_SIDEBAR_BY_USER;
};
export type ExpandSidebar = {
  type: typeof EXPAND_SIDEBAR;
};
