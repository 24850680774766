import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { type ColumnOption } from "../../../../interfaces/columnOptions";
import { Title } from "../../../listViewTemplates";
import { PublishedStatus } from "../../../common/publishedStatus";
import { type AssignmentModelItemBase } from "../../../../features/People/types";
import { EditPriorityLinkButton } from "../../../buttons/linkButtons";
import EllipsisPopupButton from "../../../buttons/ellipsisPopupButton/EllipsisPopupButton";
import PriorityCell from "../../../priorityLevels/priorityCell/PriorityCell";
import { type ContentType, type PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { type PriorityLevels, PublishedStatusTypes, RouteNames } from "../../../../enums";
import { TextTruncate } from "../../../textTruncators/TextTruncators";
import { Tooltip } from "../../../common/tooltip";
import ExpiresListTemplate from "../../../ExpiresTemplate/ExpiresListTemplate/ExpiresListTemplate";

export enum Columns {
  Title = "Title", // NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Modified = "Modified",
  Added = "Added",
  Priority = "Priority level",
  Expires = "Expires",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  publisher: "publisher",
  "priority level": "priorityLevelId",
  expires: "expires",
};

export interface GetColumnButtonHandlers {
  onEditPriority?: (id: number) => void;
  onRemove?: (id: number) => void;
}

export interface ColumnOptionParams {
  assetType: string;
  peopleType: PeopleType;
  contentType: ContentType;
  selectedIds: number[];
  buttonHandlers?: GetColumnButtonHandlers;
  isReadOnly?: boolean;
  isPriorityHide?: boolean;
  isExpiresHide?: boolean;
  isStatusHide?: boolean;
  isAddedHide?: boolean;
  disablePopupMenu?: boolean;
}

export const getColumnOptions = ({
  assetType,
  peopleType,
  contentType,
  selectedIds,
  buttonHandlers,
  isReadOnly,
  isPriorityHide,
  isExpiresHide,
  isStatusHide,
  disablePopupMenu,
  isAddedHide,
}: ColumnOptionParams): ColumnOption<AssignmentModelItemBase>[] => {
  return [
    {
      name: Columns.Title,
      width: 4,
      isSortable: true,
      render: (item) => renderTitle(item, assetType),
      disabled: (item) => item.inherited,
    },
    {
      name: Columns.Publisher,
      width: 3,
      isSortable: false,
      disabled: (item) => item.inherited,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Status,
      width: isPriorityHide && isAddedHide ? 6 : 4,
      isSortable: false,
      isHide: isStatusHide,
      render: (item) => renderStatus(item),
      disabled: (item) => item.inherited,
    },
    {
      name: Columns.Priority,
      width: 4,
      isSortable: false,
      isHide: isPriorityHide,
      render: (item) => (
        <PriorityCell
          inherited={item.inherited}
          peopleType={peopleType}
          contentType={contentType}
          priorityLevel={item.priorityLevelId as PriorityLevels}
          dueDate={item.dueDate}
        />
      ),
    },
    {
      name: Columns.Added,
      width: 4,
      isSortable: true,
      isHide: isAddedHide,
      render: (item) => dateTimeUtils.formatDate(item.dateCreated),
      disabled: (item) => item.inherited,
    },
    {
      name: Columns.Expires,
      width: 3,
      isSortable: false,
      isHide: isExpiresHide,
      disabled: (item) => item.inherited,
      render: (item) => <ExpiresListTemplate expirationDate={item.expirationDate} />,
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      render: (item) =>
        (!!buttonHandlers?.onRemove || !!buttonHandlers?.onEditPriority) && (
          <EllipsisPopupButton
            circle
            // Possible UX improvement: show tooltip with restriction clarification
            disabled={item.inherited || selectedIds.includes(item.id) || isReadOnly || disablePopupMenu}
          >
            {buttonHandlers?.onEditPriority && (
              <EditPriorityLinkButton
                onClick={() => {
                  buttonHandlers.onEditPriority?.(item.id);
                }}
              />
            )}
          </EllipsisPopupButton>
        ),
    },
  ];
};

const renderTitle = (item: AssignmentModelItemBase, assetType: string) =>
  !item.inherited ? (
    <Title
      url={`/${RouteNames.contentAssets}/${assetType}/${item.id}`}
      title={item.title}
      className="normal-color"
      clamp={2}
    />
  ) : (
    <Title title={item.title} clamp={2} />
  );

const renderStatus = (item: AssignmentModelItemBase) => {
  return <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />;
};
