import React, { type FC } from "react";
import { Tooltip } from "../common/tooltip";

export interface RestrictedByTooltipProps {
  className?: string;
  hasPermission: boolean;
  children: React.ReactElement;
}

const RestrictedByTooltip: FC<RestrictedByTooltipProps> = ({ children, hasPermission, className }) => {
  return (
    <Tooltip
      showAlways={!hasPermission}
      target={children}
      position={"left center"}
      className={className}
      disabled={hasPermission}
      content={"You don’t have permission to perform this action. Contact your administrator for more information."}
    />
  );
};

export default RestrictedByTooltip;
