import axios from "axios";
import { type GetPagedDataRequest } from "../../../../../interfaces/getPagedDataRequest";
import { type IThreatDefenceFeedbackPagesDataService } from "../types/services";
import { type FeedbackPage } from "../types/state";

const instance: IThreatDefenceFeedbackPagesDataService = {
  getThreatDefenceFeedbackPagesAsync(data: GetPagedDataRequest) {
    const config = {
      params: data,
    };

    return axios.get("/api/threat-defence/feedback-pages", config);
  },

  updateFeedbackPageAsync(data: FeedbackPage) {
    return axios.put(`/api/threat-defence/feedback-pages/${data.id}`, data);
  },

  getFeedbackPageAsync(id: number) {
    return axios.get(`/api/threat-defence/feedback-pages/${id}`);
  },

  generateSas() {
    return axios.get(`/api/feedback-pages/video/sas`);
  },
};

export default instance;
