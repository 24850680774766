import PackPicture from "../../../packPicture/PackPicture";
import { PackArchivedLabel } from "../packArchivedLabel/PackArchivedLabel";
import { PackTrialLabel } from "../packTrialLabel/PackTrialLabel";
import { Title } from "../../../listViewTemplates";

import styles from "./packTitleCell.module.scss";

export interface PackTitleCellProps {
  pack: PackTitleInfo;
  url?: string;
  target?: string;
  moboAccountId?: number;
}

export interface PackTitleInfo {
  title: string;
  isTrial: boolean;
  isArchived?: boolean;
}

export const PackTitleCell: React.FC<PackTitleCellProps> = ({ pack, url, target, moboAccountId }) => {
  return (
    <div className={styles.container}>
      <PackPicture className={styles.picture} />
      <Title
        moboAccountId={moboAccountId}
        target={target}
        url={pack.isArchived ? undefined : url}
        title={pack.title}
        className="normal-color"
      />
      <PackTrialLabel isTrial={pack.isTrial} className={styles.label} />
      <PackArchivedLabel isArchived={pack.isArchived} className={styles.label} />
    </div>
  );
};
