import * as actionTypes from "../actionTypes/surveyOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { type FetchActionPayload } from "../../../../../interfaces/redux";
import { type SurveyOverview } from "../../types/models";

export const resetAction = getActionBaseProvider(actionTypes.reset);

export const selectedItemsChangedAction = getActionProvider<number[]>(actionTypes.selectedItemsChanged);

export const getSurveysBeginAction = getActionBaseProvider(actionTypes.getSurveysBegin);
export const getSurveysSuccessAction = getActionProvider<FetchActionPayload<SurveyOverview>>(
  actionTypes.getSurveysSuccess,
);
export const getSurveysFailureAction = getActionProvider<Error>(actionTypes.getSurveysFailure);
