import cn from "classnames";
import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import { ListViewBase } from "../../../../../../../components/listView";
import { Title } from "../../../../../../../components/listViewTemplates";
import { ViewType } from "../../../../../../../enums";
import PackVisibilityForAccountTypesNoResults from "../../../../../../../views/packs/PackVisibility/PackVisibilityForAccountTypesNoResults";
import columnOptions from "../columnOptions";
import { type AccountTypeVisibility, type PackVisibilityForAccountTypesStepProps } from "../types";

export const PackVisibilityForAccountTypesStep: React.FC<PackVisibilityForAccountTypesStepProps> = ({
  selectedIds,
  onSelectedListItemsChanged,
  loadPage,
  accountTypes,
  isLoading,
}) => {
  const getTooltip = (accountTypePackVisibility: AccountTypeVisibility) => {
    if (!accountTypePackVisibility.isPackVisible) {
      return null;
    }

    const tooltipMessage = "This account type already associated with pack.";

    return (
      <Popup
        hideOnScroll
        position="top center"
        size="small"
        className="popup-warning warning"
        content={tooltipMessage}
        trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
      />
    );
  };

  const buildTableBody = (accountTypePackVisibility: AccountTypeVisibility) => {
    const readOnlyClass = cn({
      rowDisabled: accountTypePackVisibility.isPackVisible,
    });
    return (
      <>
        <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
          <Title title={accountTypePackVisibility.name} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
          {accountTypePackVisibility.customerCount}
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
          {getTooltip(accountTypePackVisibility)}
        </Table.Cell>
      </>
    );
  };

  return (
    <div className="assignment-list-step">
      <ListViewBase
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        buildTableBody={buildTableBody}
        loadPage={loadPage}
        items={accountTypes}
        updateSelectedItems={onSelectedListItemsChanged}
        selectedItemIds={selectedIds}
        isSelectDisabled={(p: AccountTypeVisibility) => p.isPackVisible}
        isLoading={isLoading}
        withFooter={false}
        noResultsContent={<PackVisibilityForAccountTypesNoResults />}
      />
    </div>
  );
};
