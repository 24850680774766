import React from "react";
import cn from "classnames";
import { type FlowSectionHeader } from "../../types";

import "./sectionHeaderAttached.scss";
import { SectionHeaderCardInner } from "../SectionHeaderCardInner/SectionHeaderCardInner";

interface Props extends FlowSectionHeader {
  id: string;
  name: string;
  showRemoveButton: boolean;
  removeHandler: (id: string) => void;
  isSelected: boolean;
  onSelectHandler: () => void;
}

const SectionHeaderAttached: React.FC<Props> = ({
  removeHandler,
  id,
  showRemoveButton,
  onSelectHandler,
  isSelected,
  name,
}) => {
  return (
    <div
      className={cn("react-flow__node-section-header-attached", { selected: isSelected })}
      onClick={onSelectHandler}
      data-testid="node-section-header"
    >
      {showRemoveButton && (
        <div
          data-testid="remove-section-header-icon"
          className="react-flow__node-section-header-attached-remove"
          onClick={(e) => {
            e.stopPropagation();
            removeHandler(id);
          }}
        />
      )}
      <SectionHeaderCardInner attached name={name} />
    </div>
  );
};

export default SectionHeaderAttached;
