import { bindActionCreators } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { type CommunicationChannels, type TemplateTypes } from "../../../enums";
import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";
import {
  fetchNotificationTemplate,
  fetchSmtpSenderSettings,
} from "../../../features/SystemNotifications/state/thunks/notifyStepThunks";
import { NotifyStepContent } from "../NotifyStepContent/NotifyStepContent";
import { useEmailEditorOptions } from "../hooks/useEmailEditorOptions";
import { type SwitchPropsConfig } from "../NotifyStepContent/switches/types";
import { type INotifyByEmailController, NotifyByEmailController } from "../notifyByEmailController";
import { type IEmailEditorController } from "../EmailEditor/emailEditorController";
import { resetState } from "features/SystemNotifications/state/slices/notifyStepSlice";
import { bindAction } from "interfaces/redux";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { useParams } from "react-router-dom";

export interface NotifyWizardStepOwnProps {
  templateType: TemplateTypes;
  onLoaded(controller: INotifyByEmailController): void;
  onValidChange(isValid: boolean): void;
  renderSwitch: (props: SwitchPropsConfig) => React.ReactElement;
  shouldNotify: boolean;
  communicationChannel: CommunicationChannels;
  onDirtyChange?(isDirty: boolean): void;
  notificationId?: string;
  onTemplateChange?(): void;
  isDisabled?: boolean;
  hideCustomSender?: boolean;
}

export type Props = NotifyWizardStepOwnProps & PropsFromRedux;

export const NotifyWizardStep = (props: Props) => {
  const {
    templateType,
    state,
    fetchTemplate,
    resetNotificationFormData,
    onLoaded,
    onValidChange,
    isDisabled = false,
    accountId,
    renderSwitch,
    shouldNotify,
    communicationChannel,
    notificationId,
    onTemplateChange,
    onDirtyChange,
    fetchSmtpSender,
    hideCustomSender,
  } = props;

  const smtpSettingsFeature = useFeatureFlag(FeatureFlags.SmtpDirectSend);
  const params = useParams();
  const { moboId } = params;

  useEffect(() => {
    return () => {
      resetNotificationFormData();
    };
  }, [resetNotificationFormData]);

  const [store, form, emailEditorOpts] = useEmailEditorOptions({
    state,
    fetchTemplate,
    templateType,
    onValidChange,
    accountId,
    shouldNotify: isDisabled ? false : shouldNotify,
    notificationId,
    onTemplateChange,
    communicationChannel,
    fetchSmtpSender,
    smtpSettingsFeature,
    moboId,
    hideCustomSender,
  });

  const handleLoad = useCallback(
    (emailEditor: IEmailEditorController) => {
      onLoaded(new NotifyByEmailController(emailEditor, store));
    },
    [onLoaded, store],
  );

  return (
    <NotifyStepContent
      emailEditorOptions={emailEditorOpts}
      emailForm={form}
      onLoaded={handleLoad}
      isDisabled={isDisabled}
      renderSwitch={renderSwitch}
      shouldNotify={shouldNotify}
      communicationChannel={communicationChannel}
      onValidChange={onValidChange}
      onDirtyChange={onDirtyChange}
      templateType={templateType}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    state: state.systemNotifications.notifyStep,
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTemplate: bindActionCreators(fetchNotificationTemplate, dispatch),
  fetchSmtpSender: bindActionCreators(fetchSmtpSenderSettings, dispatch),
  resetNotificationFormData: bindAction(resetState, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(NotifyWizardStep);
export default ConnectedComponent;
