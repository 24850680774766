import { datadogRum } from "@datadog/browser-rum";
import config from "../../configuration/environmentConfig";

/* istanbul ignore next */
const init = () => {
  if (!config.enableDatadog) return;

  datadogRum.init({
    applicationId: "dbab5b54-72af-4f68-b326-43bfe679ea15",
    clientToken: "pub96c4ea9563807721b3499dbf9692a919",
    site: "us3.datadoghq.com",
    service: "saasy-admin",
    env: config.env,
    version: process.env.REACT_APP_VERSION || "default",
    trackUserInteractions: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [config.apiUrl, config.stsUrl],
  });

  datadogRum.startSessionReplayRecording();
};

export default init;
