import { type PayloadAction } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type PdfOverview } from "../../types/state";
import { fetchOverviewPdfs } from "../thunks/pdfsOverviewThunk";

export type PdfOverviewState = FetchingItemsState<PdfOverview>;

export const initialState: PdfOverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const pdfOverviewSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Pdfs, name: "overview" },
  initialState: initialState,
  reducers: {
    updatePdfBegin(state: PdfOverviewState) {
      state.isLoading = true;
    },
    updatePdfSuccess(state: PdfOverviewState, action: PayloadAction<PdfOverview>) {
      const index = findIndex(state.items, { id: action.payload.id });
      state.items.splice(index, 1, action.payload);
      state.isLoading = false;
    },
  },
  thunk: fetchOverviewPdfs,
});

export const { reset, fetchFailure, updatePdfBegin, updatePdfSuccess } = pdfOverviewSlice.actions;

export const reducer = pdfOverviewSlice.reducer;
