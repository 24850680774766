import React from "react";
import styles from "./SubBlock.module.scss";

export const SubBlock = (props: React.PropsWithChildren<{ role?: string }>) => {
  return (
    <div role={props.role} className={styles.root}>
      {props.children}
    </div>
  );
};
