import { useEffect } from "react";
import { type Store } from "@reduxjs/toolkit";
import { useDispatch, useStore } from "react-redux";

import { type NotifyStepState } from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { type CommunicationChannels, TemplateTypes } from "../../../enums";
import { useForm } from "./useForm";
import { type EmailNotificationFormProps } from "../NotifySettingsForm/EmailNotificationForm";
import { createEmailEditorOptions } from "../utils/createEmailEditorOptions";
import { type StripoInitOpts } from "../../../lib/stripo";

export interface Props {
  templateType: TemplateTypes;
  fetchTemplate(type: TemplateTypes, notificationId?: string): void;
  onValidChange(isValid: boolean): void;
  state: NotifyStepState;
  accountId: number;
  shouldNotify: boolean;
  communicationChannel: CommunicationChannels;
  notificationId?: string;
  onTemplateChange?(): void;
  fetchSmtpSender(accountId: number): void;
  smtpSettingsFeature: boolean;
  moboId: string | undefined;
  hideCustomSender?:boolean
}

export type HookResult = [Store, EmailNotificationFormProps, StripoInitOpts];

export function useEmailEditorOptions({
  state,
  fetchTemplate,
  templateType,
  onValidChange,
  accountId,
  shouldNotify,
  notificationId,
  onTemplateChange,
  fetchSmtpSender,
  smtpSettingsFeature,
  moboId,
  hideCustomSender
}: Props): HookResult {
  const store = useStore();
  const { updatedTemplate, initialTemplate } = state;
  const dispatch = useDispatch();

  const form = useForm({ state, onValidChange, dispatch, shouldNotify });

  const emailEditorOptions = createEmailEditorOptions({
    template: updatedTemplate || initialTemplate,
    accountId: accountId,
    onDataChanged: onTemplateChange,
  });

  useEffect(() => {
    templateType !== TemplateTypes.Empty && fetchTemplate(templateType, notificationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateType]);

  useEffect(() => {
    if (smtpSettingsFeature && !hideCustomSender) {
      const id = moboId ? parseInt(moboId) : accountId;
      fetchSmtpSender(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smtpSettingsFeature, accountId, moboId, hideCustomSender]);

  return [store, form, emailEditorOptions];
}
