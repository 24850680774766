import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { type ContentPriorityLevel } from "../../../interfaces";
import PriorityLevelBulk from "../../priorityLevels/priorityLevelItem/PriorityLevelBulk";
import PriorityLevelWithThumbnail from "../../priorityLevels/priorityLevelItem/PriorityLevelWithThumbnail";
import { type ChangePeopleContentPriorityModalProps } from "./types";
import ChangePriorityModal from "./ChangePriorityModal";
import { InheritedPriorityWarning } from "components/priorityLevels/InheritedPriorityWarning/InheritedPriorityWarning";

export const ChangePeopleContentPriorityModal: React.FC<ChangePeopleContentPriorityModalProps> = (props) => {
  const getContent = (
    items: { [key in PeopleType]?: ContentPriorityLevel[] },
    onChange: (value: { [key in PeopleType]?: ContentPriorityLevel[] }) => void,
    onValidChange: (isValid: boolean) => void,
  ) => {
    let peopleType: PeopleType = PeopleType.User;
    if (items[PeopleType.Group]) {
      peopleType = PeopleType.Group;
    }
    let peopleItems = items[peopleType] || [];
    if (peopleItems && peopleItems.length > 1) {
      return (
        <PriorityLevelBulk
          item={peopleItems[0]}
          peopleType={peopleType}
          onChange={(propertyName: string, value: any) => {
            const updatedItems = peopleItems.map((item) => {
              return { ...item, [propertyName]: value };
            });
            onChange({ ...items, [peopleType]: updatedItems });
          }}
          onValidChange={onValidChange}
          count={peopleItems.length}
          disableDefault
        />
      );
    } else {
      return (
        <PriorityLevelWithThumbnail
          item={peopleItems[0]}
          peopleType={peopleType}
          onChange={(propertyName: string, value: any) => {
            onChange({ ...items, [peopleType]: [{ ...peopleItems[0], [propertyName]: value }] });
          }}
          onValidChange={onValidChange}
          disableDefault
        />
      );
    }
  };

  const renderInheritedWarning = (multi: boolean) => <InheritedPriorityWarning context={"people"} multi={multi} />;

  return <ChangePriorityModal {...{ ...props, getContent, renderInheritedWarning }} />;
};

export default ChangePeopleContentPriorityModal;
