import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/globaltypes/redux";
import { type IdentityProviderAttributeMapping } from "../../types";

export interface IdentityProviderAttributeMappingState extends IdentityProviderAttributeMapping {
  isLoading: boolean;
  error: string | undefined;
  isAccessAuthorized: boolean;
}

const initialState: IdentityProviderAttributeMappingState = {
  id: 0,
  claimsMappings: [],
  claimsCustomMappings: [],
  updateUserInfo: false,
  isLoading: false,
  error: undefined,
  isAccessAuthorized: true,
};

const identityProviderAttributeMappingSlice = createSlice({
  name: "identityProviderAttributeMapping",
  initialState: initialState,
  reducers: {
    req(state: IdentityProviderAttributeMappingState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: IdentityProviderAttributeMappingState, action: PayloadAction<IdentityProviderAttributeMapping>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    err(state: IdentityProviderAttributeMappingState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    forbidden(state: IdentityProviderAttributeMappingState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isAccessAuthorized: false,
        error: action.payload,
      };
    },
    updated(state: IdentityProviderAttributeMappingState, action: PayloadAction<IdentityProviderAttributeMapping>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { req, got, err, updated, forbidden } = identityProviderAttributeMappingSlice.actions;

export const identityProviderAttributeMappingSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.attributeMapping;
export const identityProviderAttributeMappingLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.attributeMapping.isLoading;

export default identityProviderAttributeMappingSlice.reducer;
export type identityProviderAttributeMappingStateSliceType = ReturnType<
  typeof identityProviderAttributeMappingSlice.reducer
>;
