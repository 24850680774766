import React, { Component, cloneElement } from "react";
import { Segment, Icon, Dimmer, Loader } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import cn from "classnames";
import FilterPanelHeader from "./filterPanelHeader/FilterPanelHeader";

import "./filterPanel.scss";

class FilterPanel extends Component {
  state = { filter: this.props.appliedFilter };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.appliedFilter, this.props.appliedFilter) &&
      !isEqual(this.props.appliedFilter, this.state.filter)
    ) {
      this.setState({ filter: this.props.appliedFilter });
    }
  }

  // is being used in FilterSidebar via ref
  // prettier-ignore
  getFilterOptions() { // NOSONAR
    const { filterOptions, getFilterOptions, appliedFilter } = this.props;
    // updated to simple check as reducer returns non empty options object with default state
    if (filterOptions) {
      getFilterOptions();
    }

    this.setState({ filter: JSON.parse(JSON.stringify(appliedFilter)) });
  }

  applyFilter = () => {
    const { applyFilter } = this.props;
    const { filter } = this.state;

    applyFilter(JSON.parse(JSON.stringify(filter)));
  };

  resetFilter = () => {
    this.props.resetFilter();

    this.setState({ filter: this.props.defaultFilter ? this.props.defaultFilter : {} });
  };

  updateFilter = (filter) => {
    const { instantFiltration } = this.props;

    this.setState({ filter: filter }, () => {
      if (instantFiltration) {
        this.applyFilter();
      }
    });
  };

  render() {
    const {
      children,
      appliedFilter,
      defaultFilter,
      isLoading,
      filterOptions,
      instantFiltration,
      compactFilterPanel,
      className,
      isCompact,
      shouldDisableApply,
    } = this.props;
    const { filter } = this.state;

    return (
      <div className={cn("filter-panel", className, { isCompact, isFull: !isCompact })}>
        <Dimmer.Dimmable
          as={Segment}
          dimmed={isLoading}
          className={cn("scroll-area", !instantFiltration && !compactFilterPanel ? "two-btn" : "one-btn")}
        >
          <Dimmer active={isLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          {compactFilterPanel && <FilterPanelHeader filter={appliedFilter} resetFilter={this.resetFilter} />}
          <div className="filter-form">
            {children &&
              cloneElement(children, {
                appliedFilter: appliedFilter,
                filterOptions: filterOptions,
                updateFilter: this.updateFilter,
                filter: this.state.filter,
              })}
          </div>
        </Dimmer.Dimmable>

        <div className="sticky-buttons">
          {!compactFilterPanel && (
            <Button
              primary={isCompact}
              inverted={isCompact}
              basic={!isCompact}
              className="filter-btn reset"
              onClick={this.resetFilter}
              disabled={
                defaultFilter
                  ? // If a default filter is given, then the Reset button should be disabled
                    // only if the currently applied filter is the default AND the current
                    // selection in the filter panel is also the default
                    isEqual(appliedFilter, defaultFilter) && isEqual(filter, defaultFilter)
                  : isEmpty(filter)
              }
            >
              <Icon className="fa-redo" size="large" color={isCompact ? "blue" : undefined} />
              <span className="label">Reset</span>
            </Button>
          )}

          {!instantFiltration && (
            <Button
              primary
              className="filter-btn apply"
              content="Apply"
              onClick={this.applyFilter}
              disabled={shouldDisableApply || isEqual(appliedFilter, filter)}
            />
          )}
        </div>
      </div>
    );
  }
}

FilterPanel.defaultProps = {
  appliedFilter: {},
  compactFilterPanel: false,
  isCompact: false,
  instantFiltration: localStorage.instantFilter === undefined ? false : JSON.parse(localStorage.instantFilter),
};

FilterPanel.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  appliedFilter: PropTypes.object,
  defaultFilter: PropTypes.object, // used to help control reset logic when an initial value should be selected
  getFilterOptions: PropTypes.func,
  instantFiltration: PropTypes.bool,
  isCompact: PropTypes.bool, // this mode is relevant according to zeplin designs
  compactFilterPanel: PropTypes.bool, // this mode a tech debt and should be removed later
  className: PropTypes.string,
  shouldDisableApply: PropTypes.bool, // if component managing filter has opinion on filter being applied (should as with dates)
};

export default FilterPanel;
