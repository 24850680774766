import { useState } from "react";

import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

import { Restricted } from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";

import { OverviewHeader } from "../../../../../components/sectionHeader";

import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import { DeleteButton } from "../../../../../components/buttons/linkButtons/headerButtons/DeleteButton";
import { DuplicateButton } from "../../../../../components/buttons/linkButtons/headerButtons/DuplicateButton";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import TagsManageContainer from "../containers/TagsManageContainer";

export interface SurveyOverviewHeaderProps {
  purchasedSurveySelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  selectedIds: Array<number>;
  hiddenSelected: boolean;
  visibleSelected: boolean;
  surveyDuplicationHandler: (selectedIds: number[]) => () => void;
  surveyDeletionHandler: (selectedIds: number[]) => () => void;
  renderCreateButton: () => React.ReactElement;
  clearSelection: () => void;
  onToggleVisibility: (selectedIds: number[], hidden: boolean) => () => void;
}

const addToPacksPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

function SurveyOverviewHeader(props: SurveyOverviewHeaderProps) {
  const { 
    selectedIds,
    purchasedSurveySelected,
    undeletableSelected,
    draftSelected,
    hiddenSelected,
    visibleSelected,
    onToggleVisibility } = props;
  const [packsModalShown, setPacksModalShown] = useState(false);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility) && !(visibleSelected && hiddenSelected);

  const renderBatchButtons = () => {
    return (
      <>
        {visibility && (
          <ToggleVisibilityButton 
            onClick={onToggleVisibility(selectedIds, hiddenSelected)}
            hidden={hiddenSelected}
            requiredPermissions={[RolePermissions.AssetsCreate]}/>
        )}
        <Restricted permissions={addToPacksPermissions}>
          <AddToPacksButton
            onClick={() => setPacksModalShown(true)}
            isDisabled={purchasedSurveySelected || draftSelected}
          />
        </Restricted>
        <DuplicateButton
          entityName="surveys"
          purchasedSelected={purchasedSurveySelected}
          onClick={props.surveyDuplicationHandler(selectedIds)}
        />
        <DeleteButton
          entityName="surveys"
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSurveySelected}
          onClick={props.surveyDeletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSurveySelected}
          selectedIds={selectedIds}
          onComplete={props.clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Survey"
        />
      </>
    );
  };

  const renderHeaderButtons = () => (selectedIds.length ? renderBatchButtons() : props.renderCreateButton());

  return (
    <OverviewHeader
      title="Surveys"
      titleForGA="Assets - Surveys"
      itemName="Survey"
      selectedItemsAmount={selectedIds.length}
    >
      {renderHeaderButtons()}
    </OverviewHeader>
  );
}

export default SurveyOverviewHeader;
