import React, { useEffect, useMemo, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import { noop } from "lodash";

import { bindAction } from "../../../../../interfaces/redux";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import { assessmentsTagsSelector, assessmentsStateSelector } from "../../state/selectors";
import { fetchAssessmentTags } from "../../state/thunks/assessmentBaseThunk";
import { type SettingsOwnProps } from "./types";
import { updateAssessmentTags } from "../../state/actions/assessmentEntityStateActions";
import { saveAssessmentTags } from "../../state/actions/assessmentDetailsActions";

import SettingsForm from "./SettingsForm/SettingsForm";
import validationSchemas from "../../../../../utils/validationSchemas";

export type SettingsProps = SettingsOwnProps & IWizardStep & PropsFromRedux;

export const SettingsTab = (props: SettingsProps) => {
  const { assessmentInfo, normalizedTags, isLoading, hasAnyPermission, acceptHandlers, entityId } = props;
  const initialValues = useMemo(
    () => ({
      labels: assessmentInfo.labels,
      softwareApplications: assessmentInfo.softwareApplications,
    }),
    [assessmentInfo],
  );
  const tagsFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemas.assessmentSettings,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const valuesRef = useRef(tagsFormik.values);
  useEffect(() => {
    valuesRef.current = tagsFormik.values;
  }, [tagsFormik.values]);

  useEffect(() => {
    const cb = () => {
      props.saveTags(valuesRef.current);
    };
    acceptHandlers?.({
      onNext: cb,
      onPrevious: cb,
    });
    props.fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="stretch scrollable-content edit-form">
      <SettingsForm
        entityId={entityId}
        tagsFormik={tagsFormik}
        normalizedTags={normalizedTags}
        updateTags={props.updateTags}
        assessmentInfo={assessmentInfo}
        isDisabled={assessmentInfo.isPurchased || !hasAnyPermission}
        isLoading={isLoading}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = assessmentsStateSelector(state).base;

  return {
    entityId: base.assessmentEntityStateReducer.entityId,
    assessmentInfo: base.assessmentDetailsReducer.assessment,
    normalizedTags: assessmentsTagsSelector(state),
    isLoading: base.assessmentDetailsReducer.isAssessmentLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchAssessmentTags, dispatch),
  updateTags: bindAction(updateAssessmentTags, dispatch),
  saveTags: bindAction(saveAssessmentTags, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SettingsTab);
export default ConnectedComponent;
