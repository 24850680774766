import { createReducer } from "../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/threatDefenceEmailTemplateDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/threatDefenceEmailTemplateEntityStateActionTypes";
import {
  type ThreatDefenceEmailTemplateDetailsState,
  type ThreatDefenceEmailTemplateView,
  type ThreatDefenceEmailTemplate,
  type ThreatDefenceEmailTemplateContent,
  type LoadThreatDefenceEmailTemplateContentResult,
} from "../../types/state";
import { type PayloadAction } from "../../../../../../interfaces/redux";
import miscUtils from "../../../../../../utils/miscellaneousUtils";

const initialState: ThreatDefenceEmailTemplateDetailsState = {
  email: {
    id: -1,
    isDraft: true,
    hasBeenPublished: false,
    subject: "",
    name: "",
    dateModified: undefined,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
  },
  html: "",
  contentUrlWithSas: "",
  isLoading: false,
  isEmailLoaded: false,
  isEmailContentLoaded: false,
};

const normalizeEmailTemplatePayload = (
  initial: ThreatDefenceEmailTemplateView,
  payload: ThreatDefenceEmailTemplate,
): ThreatDefenceEmailTemplateView => ({
  ...initial,
  ...payload,
  labels: miscUtils.normalizeDropdownItems(payload.labels),
  softwareApplications: miscUtils.normalizeDropdownItems(payload.softwareApplications),
});

const getHandlers = (failureHandler: Function) => {
  const {
    getThreatDefenceEmailTemplateBegin,
    getThreatDefenceEmailTemplateSuccess,
    getThreatDefenceEmailTemplateFailure,
    getThreatDefenceEmailTemplateContentBegin,
    getThreatDefenceEmailTemplateContentSuccess,
    getThreatDefenceEmailTemplateContentFailure,
  } = actionTypes;

  const getThreatDefenceEmailTemplateBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceEmailTemplateSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<ThreatDefenceEmailTemplate>,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    email: {
      ...normalizeEmailTemplatePayload(state.email, action.payload),
    },
    isEmailLoaded: true,
    isLoading: false,
  });

  const getThreatDefenceEmailTemplateContentBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceEmailTemplateContentSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<LoadThreatDefenceEmailTemplateContentResult>,
  ): ThreatDefenceEmailTemplateDetailsState => {
    const content = action.payload.content;
    const newState: ThreatDefenceEmailTemplateDetailsState = {
      ...state,
      html: content.html,
      contentUrlWithSas: content.contentUrlWithSas,
      isEmailContentLoaded: true,
      isLoading: false,
    };
    if (!action.payload.getTemplateOnly) {
      newState.email = {
        ...state.email,
      };
    }
    return newState;
  };

  return {
    [getThreatDefenceEmailTemplateBegin]: getThreatDefenceEmailTemplateBeginHandler,
    [getThreatDefenceEmailTemplateSuccess]: getThreatDefenceEmailTemplateSuccessHandler,
    [getThreatDefenceEmailTemplateFailure]: failureHandler,
    [getThreatDefenceEmailTemplateContentBegin]: getThreatDefenceEmailTemplateContentBeginHandler,
    [getThreatDefenceEmailTemplateContentSuccess]: getThreatDefenceEmailTemplateContentSuccessHandler,
    [getThreatDefenceEmailTemplateContentFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const {
    fetchLockedThreatDefenceEmailTemplateBegin,
    fetchLockedThreatDefenceEmailTemplateSuccess,
    fetchLockedThreatDefenceEmailTemplateFailure,
  } = entityStateActionTypes;

  const fetchLockedThreatDefenceEmailTemplateBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedThreatDefenceEmailTemplateSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    email: {
      ...state.email,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedThreatDefenceEmailTemplateBegin]: fetchLockedThreatDefenceEmailTemplateBeginHandler,
    [fetchLockedThreatDefenceEmailTemplateSuccess]: fetchLockedThreatDefenceEmailTemplateSuccessHandler,
    [fetchLockedThreatDefenceEmailTemplateFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const {
    publishThreatDefenceEmailTemplateBegin,
    publishThreatDefenceEmailTemplateSuccess,
    publishThreatDefenceEmailTemplateFailure,
  } = entityStateActionTypes;

  const publishThreatDefenceEmailTemplateBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishThreatDefenceEmailTemplateSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    email: {
      ...state.email,
      isDraft: false,
    },
    isLoading: false,
  });

  return {
    [publishThreatDefenceEmailTemplateBegin]: publishThreatDefenceEmailTemplateBeginHandler,
    [publishThreatDefenceEmailTemplateSuccess]: publishThreatDefenceEmailTemplateSuccessHandler,
    [publishThreatDefenceEmailTemplateFailure]: failureHandler,
  };
};

const discardHandlers = (failureHandler: Function) => {
  const {
    fetchDiscardThreatDefenceEmailTemplateBegin,
    fetchDiscardThreatDefenceEmailTemplateSuccess,
    fetchDiscardThreatDefenceEmailTemplateFailure,
  } = entityStateActionTypes;

  const fetchDiscardThreatDefenceEmailTemplateBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchDiscardThreatDefenceEmailTemplateSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
  });

  return {
    [fetchDiscardThreatDefenceEmailTemplateBegin]: fetchDiscardThreatDefenceEmailTemplateBeginHandler,
    [fetchDiscardThreatDefenceEmailTemplateSuccess]: fetchDiscardThreatDefenceEmailTemplateSuccessHandler,
    [fetchDiscardThreatDefenceEmailTemplateFailure]: failureHandler,
  };
};

const threatDefenceEmailTemplateDetailsHandlers = () => {
  const {
    saveThreatDefenceEmailTemplate,
    saveThreatDefenceEmailTemplateContent,
    clearThreatDefenceEmailTemplate,
    setEmailTemplate,
  } = actionTypes;

  const failureHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<Error>,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveThreatDefenceEmailTemplateHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<ThreatDefenceEmailTemplateView>,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    email: action.payload,
  });

  const saveThreatDefenceEmailTemplateContentHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<ThreatDefenceEmailTemplateContent>,
  ): ThreatDefenceEmailTemplateDetailsState => {
    const payload = action.payload;
    return {
      ...state,
      email: {
        ...state.email,
      },
      html: payload.html,
    };
  };

  const clearThreatDefenceEmailTemplateHandler = (): ThreatDefenceEmailTemplateDetailsState => ({
    ...initialState,
  });

  const setEmailTemplateHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
    action: PayloadAction<ThreatDefenceEmailTemplate>,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    email: {
      ...normalizeEmailTemplatePayload(state.email, action.payload),
    },
  });

  return {
    [saveThreatDefenceEmailTemplate]: saveThreatDefenceEmailTemplateHandler,
    [saveThreatDefenceEmailTemplateContent]: saveThreatDefenceEmailTemplateContentHandler,
    [clearThreatDefenceEmailTemplate]: clearThreatDefenceEmailTemplateHandler,
    [setEmailTemplate]: setEmailTemplateHandler,
    ...getHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
    ...discardHandlers(failureHandler),
  };
};

export const threatDefenceEmailTemplateDetailsReducer = createReducer(initialState, [
  threatDefenceEmailTemplateDetailsHandlers,
]);
