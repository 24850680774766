import { SortOptions, SortingDirection } from "../../../enums";

import { Columns } from "./getColumnOptions";

export const START_POSITION = 0;
export const DEFAULT_ITEMS_PER_PAGE = 10;

export const DEFAULT_FETCH_TOP = 10;
export const DEFAULT_FETCH_GRID_TOP = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string) || 30;
export const DEFAULT_FETCH_SKIP = 0;
export const DEFAULT_FETCH_SORT_COLUMN = Columns.Modified.toLowerCase();
export const DEFAULT_FETCH_SORT_DIRECTION = SortingDirection.Descending;
export const DEFAULT_FETCH_ORDER_BY = SortOptions.ModifiedDateDesc;
