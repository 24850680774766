import { NoResultsWithButton, SearchInput } from "components";
import { RolePermissions, SortingDirection } from "enums";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { Columns, columnToParamMap, getColumnOptions } from "./columnOptions";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { connect, type ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Observable } from "utils";
import { TemplatesFilterForm } from "../../../People/GroupTemplate/Overview/TemplatesFilterForm";
import { deleteTemplate, fetchTemplates } from "../state/thunks/templatesThunk";
import { resetAppliedFilter, setAppliedFilter, setSearch } from "../state/slices/templates/templatesFilterSlice";
import { useRestrictedCheck } from "../../../Application/hooks";
import { DeleteTemplateModal } from "../../../People/GroupTemplate/Overview/DeleteTemplateModal";
import { reset } from "../../../People/GroupTemplate/state/TemplateOverviewSlice";
import { resetFilter } from "../../../People/GroupTemplate/state/TemplateFiltersSlice";
import { AccountRemovedFromTemplateGroupSuccess } from "../../../Application/services/realTimeNotification/events/people/peopleEvents";

type Props = PropsFromRedux & {
  renderContentSwitcher?: () => React.ReactElement;
  onlyOwn?: boolean;
  title?: string;
  accountId: number;
};

export const EditTemplates = (props: Props) => {
  const {
    fetchTemplates,
    items,
    itemsCount,
    isLoading,
    onSearchChanged,
    applyFilter,
    resetAppliedFilter,
    filters,
    deleteTemplate,
    accountId,
    resetOverview,
  } = props;
  const hasManagePermission = useRestrictedCheck([RolePermissions.TemplatesManage]);
  const reloadListItemsRef = useRef<(enableSorting: boolean) => void>();
  const isSearchEmpty = isEmpty(filters.search);

  const deleteObserver = useMemo(() => new Observable<(onSubmit: () => void) => void>(), []);

  const handleSearchChanged = (search: string) => {
    onSearchChanged(search);
    reloadListItemsRef.current?.(isEmpty(search));
  };

  useEffect(() => {
    return () => {
      resetOverview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetch = useCallback(
    (
      skip: number = 0,
      top: number = 10,
      sortingColumnName: string = Columns.Added,
      sortOrder: SortingDirection = SortingDirection.Descending,
    ) => {
      const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<Columns>];
      fetchTemplates({ accountId, skip, top, sortBy, sortOrder });
    },
    [accountId, fetchTemplates],
  );

  const handleDelete = useCallback(
    (id: number) => () => {
      deleteObserver.notify(() => {
        deleteTemplate(id, accountId, () => reloadListItemsRef.current?.(isSearchEmpty));
      });
    },
    [accountId, deleteObserver, deleteTemplate, isSearchEmpty],
  );

  const columnOptions = useMemo(() => {
    return getColumnOptions({
      hasManagePermission,
      handleDelete,
    });
  }, [hasManagePermission, handleDelete]);

  const renderNoResults = () => {
    return (
      <NoResultsWithButton
        title="You don’t have any templates"
        description="Looks like you don’t have any templates."
        icon={<FontAwesomeIcon icon={faBuildingUser} className="no-results-icon" />}
        filtered={!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)}
      />
    );
  };

  return (
    <>
      <GenericItemsView
        items={items}
        isLoading={isLoading}
        columnOptions={columnOptions}
        fetchData={handleFetch}
        dataCount={itemsCount}
        customHeaderContent={props.renderContentSwitcher?.()}
        renderSearch={() => <SearchInput placeholder="Search for template" onChange={handleSearchChanged} />}
        // @ts-ignore
        getFilterForm={() => <TemplatesFilterForm />}
        applyFilter={applyFilter}
        resetFilter={resetAppliedFilter}
        setReloadListItems={(reloadListItems) => (reloadListItemsRef.current = reloadListItems)}
        appliedFilter={filters.appliedFilter}
        noResultsContent={renderNoResults()}
        isSelectDisabled={() => !hasManagePermission}
        listViewRtnEvents={[AccountRemovedFromTemplateGroupSuccess]}
      />

      <DeleteTemplateModal deleteObserver={deleteObserver} />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { items, filters } = state.accounts.edit.templates;

  return {
    ...items,
    filters,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetOverview: () => {
      dispatch(reset());
      dispatch(resetFilter());
    },
    fetchTemplates: bindAction(fetchTemplates, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetAppliedFilter: bindAction(resetAppliedFilter, dispatch),
    deleteTemplate: bindAction(deleteTemplate, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditTemplates);
export default ConnectedComponent;
