import { type RootState } from "../../../Application/globaltypes/redux";
import { createSelector } from "@reduxjs/toolkit";
import { pdfsStateSelector } from "./index";

// Line chart
export const selectEngagementLineData = (state: RootState) =>
  pdfsStateSelector(state).base.performance.engagementLineData;

export const selectEngagementDates = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => new Date(activity.Date)),
);

export const selectEngagementCompleted = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => activity.CompletedCount),
);

export const selectEngagementCumulativeCompleted = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.CumulativeCompletedCount);
});

// Horizontal
export const selectEngagementHorizontalData = (state: RootState) =>
  pdfsStateSelector(state).base.performance.engagementHorizontalData;

export const selectEngagementHorizontalValue = createSelector(
  selectEngagementHorizontalData,
  (engagementHorizontalData) => engagementHorizontalData.value,
);

// People
export const selectEngagementPeopleData = (state: RootState) =>
  state.library.pdfs.base.performance.engagementPeopleData;

export const selectEngagementPeopleStatus = createSelector(
  selectEngagementPeopleData,
  (engagementPeopleData) => engagementPeopleData.status,
);

export const selectEngagementPeopleValue = createSelector(
  selectEngagementPeopleData,
  (engagementPeopleData) => engagementPeopleData.value,
);
