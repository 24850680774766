import * as actionTypes from "./wizardWrapperActionTypes";

export function openWizard() {
  return {
    type: actionTypes.OPEN_WIZARD,
  };
}

export const closeWizard = () => ({
  type: actionTypes.CLOSE_WIZARD,
});
