import { type PaginationAndSortingOptions } from "features/Library/Common/models";
import { type Link } from "../../../../../components/ExpandableItems/types";
import { type AssignedUser, type PackName } from "../../../../../interfaces";
import { type FlowBase } from "../../../../../interfaces/flowInfo";
import { type NormalizedDropdown } from "../../../../../utils/miscellaneousUtils";
import { type PublishInfo } from "./models";

export interface EmailOverview {
  id: number;
  isDraft: boolean;
  title: string;
  subject: string;
  dateSent: string;
  dateCreated: string;
  dateModified: string;
  isPurchased: boolean;
  canBeDuplicated: boolean;
  canBeDeleted: boolean;
  dependencies?: {
    flowsCount: number;
    packsCount: number;
    isEmpty: boolean;
  };
}

export interface PublishedEmailOverview {
  id: number;
  title: string;
  publisherId: number;
  publisher: string;
  dateCreated: Date;
  dateModified: Date;
}

export type EmailsOverviewState = {
  emails: EmailOverview[];
  isLoading: boolean;
  totalCount: number;
  isAllLoaded: boolean;
  error?: Error;
};

export interface EmailView {
  id: number;
  isDraft: boolean;
  hasBeenPublished: boolean;
  title: string;
  subject: string;
  previewText: string;
  dateCreated?: Date;
  dateModified?: Date;
  labels: NormalizedDropdown;
  softwareApplications: NormalizedDropdown;
  isPurchased: boolean;
  isPurchasedDuplicationAllowed: boolean;
  isDuplicatedFromPurchased: boolean;
  canBeDuplicated: boolean;
  packs: Link[];
  flows: Link[];
  utmCampaign: string;
}

export interface Email extends PaginationAndSortingOptions {
  id?: number;
  isDraft?: boolean;
  hasBeenPublished: boolean;
  title: string;
  subject: string;
  previewText: string;
  dateCreated?: Date;
  dateModified?: Date;
  labels: string[];
  softwareApplications: string[];
  isPurchasedDuplicationAllowed: boolean;
  packs: PackName[];
  flows: FlowBase[];
  utmCampaign: string;
}

export interface EmailDetailsState {
  email: EmailView;
  callToAction: string;
  destinationUrl: string;
  template: string;
  compiledTemplate: string;
  isEmailLoaded: boolean;
  isTemplateLoaded: boolean;
  isLoading: boolean;
  error?: Error;
  tags: EmailTagsPayload;
}

export interface Template {
  id: number;
  subject: string;
  previewText: string;
  callToAction: string;
  destinationUrl: string;
  template: string;
  compiledTemplate: string;
}

export interface LoadTemplateResult {
  template: Template;
  getTemplateOnly: boolean;
}

export interface AssignedUsersStateType {
  items: AssignedUser[];
  isLoading: boolean;
  itemsCount: number;
  addedUsersCount: number;
  error?: Error | null;
}

export interface EmailAssignedUsersState {
  assignedUsers: AssignedUsersStateType;
  filter: {
    appliedFilter?: any;
  };
}

export interface EmailPublishInfoState {
  publishInfo: PublishInfo;
  isLoading: boolean;
}

export interface DataItem {
  text: string;
  value: number;
}

export interface PublisherDataItem {
  name: string;
  id: number;
}

export interface EmailTagsPayload {
  [key: string]: TagDataItem[];
}

export interface TagDataItem {
  title: string;
  id: number;
}

export enum EmailFilterPropertyNames {
  Publishers = "publisherIds",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
  Packs = "packIds",
}
