import { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type AppDispatch, type RootState } from "../../features/Application/globaltypes/redux";
import { setContainer } from "../../features/Application/slices/appErrorSlice";

export interface Props {
  id: string;
  children?: React.ReactNode;
}

export const ErrorContainer = (props: Props & PropsFromRedux) => {
  const { id, isErrorState, setContainer, currentContainer, children } = props;

  // saves the id of the last mounted component
  useEffect(() => {
    setContainer(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContainer]);

  return (
    <>
      <div id={id} data-testid={id} style={{ height: currentContainer === id && isErrorState ? "100%" : "0px" }} />
      {(currentContainer !== id || !isErrorState) && children}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isErrorState: state.appError.isErrorState,
  currentContainer: state.appError.container,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setContainer: (id: string) => dispatch(setContainer(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(ErrorContainer);
export default ConnectedComponent;
