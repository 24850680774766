export type ExternalTriggerType = GoogleTrigger | MicrosoftTrigger | BrainStormTrigger;
export type SourceType = "Google" | "Microsoft" | "BrainStorm";
export type OperatorAlias = ">" | "<" | ">=" | "<=" | "=" | "!=";

export interface ExternalTriggerGroupView {
  externalTriggerGroups: ExternalTriggerGroup<ExternalTrigger[]>[];
}

export interface ExternalTriggerGroup<T> {
  groupId: string;
  type: TriggerGroupType;
  triggers: T;
}

export interface ExternalTrigger {
  id: string;
  eventId?: number;
  fullText?: string;
  hasIntegration: boolean;
  software: SourceType | null;
  application: string | null;
  action: string | null;
}

export interface GoogleTrigger extends ExternalTrigger {
  software: "Google";
}

export interface ExpressionTrigger extends ExternalTrigger {
  filter: OperatorAlias;
  criteria: string;
}

export interface MicrosoftTrigger extends ExpressionTrigger {
  software: "Microsoft";
}

export interface BrainStormTrigger extends ExpressionTrigger {
  software: "BrainStorm";
}

export interface SourceEvent<T = object> {
  id: number;
  data: T;
}

export interface BaseEvent {
  application: string;
  fullText: string;
}

export interface ExpressionEvent extends BaseEvent {
  field: string;
  operators: OperatorAlias[];
  type: string;
}

export interface GoogleEvent extends BaseEvent {
  action: string;
  type: string;
}

export interface ExternalTriggerPayload {
  sourceType: SourceType;
  eventId: number;
  filter?: OperatorAlias;
  criteria?: string;
}

export interface ExternalTriggerGroupPayload {
  groupId: string;
  type: TriggerGroupType;
  triggers: ExternalTriggerPayload[];
}

export enum TriggerGroupType {
  Or,
  All,
}

export type SourceEvents = { [key in SourceType]: object | undefined };
export type SourceEventsPayload = { [key in SourceType]?: { [key: number]: SourceEvent<BaseEvent> } };
