import {
  loadAssessmentsBeginAction,
  loadAssessmentsFailureAction,
  loadAssessmentsSuccessAction,
} from "../actions/assessmentsOverviewActions";
import { assessmentsAppliedFilterSelector, assessmentSearchSelector } from "../selectors";
import { type DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import DataService from "../../../../Application/services/dataServices/typedDataService";

import { type AppDispatch, type AppThunk, type RootState } from "../../../../Application/globaltypes/redux";
import { type PayloadAction, type FetchActionPayload } from "../../../../../interfaces/redux";
import { type AssessmentFiltersEnum, type AssessmentOverview, assessmentFilterTypes } from "../../types/state";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { pluralize } from "../../../../../utils/stringUtils";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { SortingDirection } from "../../../../../enums";
import { Columns, ColumnToParamMap } from "../../Overview/getColumnOptions";
import { type AssessmentArgs } from "features/Application/services/dataServices/contentDataService";
import { formatFilters } from "utils/filterMapUtils";
import { type Action } from "@reduxjs/toolkit";

interface Actions {
  begin: () => Action;
  success: (payload: FetchActionPayload<AssessmentOverview>) => PayloadAction<FetchActionPayload<AssessmentOverview>>;
  fail: (payload: Error) => PayloadAction<Error>;
}

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const loadAssessments = (
  skip: number = 0,
  top: number = 10,
  sortingColumnName: string = Columns.Added.toLowerCase(),
  sortingDirection: SortingDirection = SortingDirection.Descending,
): AppThunk =>
  getAssessments(skip, top, sortingColumnName, sortingDirection, {
    begin: loadAssessmentsBeginAction,
    success: loadAssessmentsSuccessAction,
    fail: loadAssessmentsFailureAction,
  });

const getAssessments =
  (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    { begin, success, fail }: Actions,
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(begin());

    try {
      const sortColumn = ColumnToParamMap[sortingColumnName as Lowercase<Columns>];
      const state = getState();
      const appliedFilter = assessmentsAppliedFilterSelector(state);
      const searchTerm = assessmentSearchSelector(state);

      const result = await sendGetAssessmentsRequest(
        skip,
        top,
        searchTerm,
        appliedFilter,
        sortColumn,
        sortingDirection,
      );

      const recordsCount = Number.parseInt(result.headers[countHeaderName]);
      const data: FetchActionPayload<AssessmentOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(fail(error));
    }
  };

export const sendGetAssessmentsRequest = (
  skip: number,
  top: number,
  searchTerm: string,
  appliedFilter: GenericFiltersMap<AssessmentFiltersEnum>,
  sortBy?: string,
  sortOrder?: SortingDirection,
) => {
  const filterQueryParams = formatFilters(appliedFilter, assessmentFilterTypes);

  const requestV4: AssessmentArgs = {
    top,
    skip,
    sortBy,
    sortOrder,
    filters: filterQueryParams,
    showPurchased: true,
    term: escapeTerm(searchTerm) ?? "",
    contentType: "assessment",
  };
  return DataService.content.getContent(requestV4);
};

export const duplicateAssessments =
  (ids: number[], fetchAssessments: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const { data } = await DataService.assessments.duplicateAssessmentsAsync(ids);
      return data;
    };

    const idsCount = ids.length;
    const bannerPrefix = pluralize("Assessment", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DuplicateAssessment",
      title: `Duplication of assessments`,
      onCompleted: () => fetchAssessments(),
      getOperationProps,
      successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been duplicated!`,
      failureTransientMessage: `${bannerPrefix} duplicate failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const deleteAssessments =
  (ids: number[], fetchAssessments: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const { data } = await DataService.assessments.deleteAssessmentAsync(ids);
      return data;
    };

    const idsCount = ids.length;
    const entity = pluralize("Assessment", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DeleteAssessment",
      title: `Deletion of assessments`,
      onCompleted: () => fetchAssessments(),
      getOperationProps,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${entity} delete failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const changeVisibility =
  (ids: number[], visibility: boolean): AppThunk => async (dispatch: AppDispatch) => {
    const itemCount = ids.length;
    const assessment = pluralize("Assessment", itemCount);
    const asset = pluralize("Asset", itemCount);

    const params: DistributedOpUpdateParams = {
      id: "VisibilityAssessmentDistributedOperation",
      title: `${visibility ? "Unhiding" : "Hiding"} ${asset}`,
      getOperationProps: async () => {
        const { data } = await DataService.assessments.changeVisibility(ids, visibility);
        return data;
      },
      successTitle:`${assessment} ${visibility ? "Unhidden" : "Hidden"}!`,
      successTransientMessage: `${assessment} ${itemCount === 1 ? "has" : "have"} been ${visibility ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${assessment} ${visibility ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(params, dispatch);
  };
