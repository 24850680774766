import { type UpdateContentPayload } from "../../types/models";
import { type AutosaveInputProps } from "./Content";

export const mapPropsToUpdateContentPayload = (_: any, props: AutosaveInputProps): UpdateContentPayload => ({
  id: props.id,
  questions: props.values.questions.map((question, questionIndex) => {
    const otherOptions = question.otherAnswerOptions;
    const includeOtherAsAnswer = !!otherOptions?.includeOtherAsAnswer;
    const includeUserTextField = !!(includeOtherAsAnswer && otherOptions?.includeUserTextField);
    return {
      ...question,
      questionOrder: questionIndex,
      otherAnswerOptions: {
        includeOtherAsAnswer,
        includeUserTextField,
        isUserTextFieldRequired: !!(includeUserTextField && otherOptions?.isUserTextFieldRequired),
      },
      answers: question.answers.map((answer, answerIdex) => ({
        ...answer,
        answerOrder: answerIdex,
      })),
    };
  }),
});
