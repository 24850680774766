import { type FC } from "react";
import cn from "classnames";

import { Icon, Popup, Table } from "semantic-ui-react";
import GroupInfoCell from "../../../../groupInfoCell/GroupInfoCell";
import { type GroupOwner } from "../../../../../interfaces";
import { type IGroupListRowProps } from "../../types";
import { TextTruncate } from "../../../../textTruncators/TextTruncators";
import PlaceholderLoader from "../../../../placeholderLoader/PlaceholderLoader";
import { Tooltip } from "components/common/tooltip";
import { columnOptions } from "./getColumnOptions";

export const GroupListRow: FC<IGroupListRowProps> = ({
  group,
  alreadyAssignedGroupTooltip,
  getCustomTooltipMessage,
}) => {
  const tooltipMessage = getCustomTooltipMessage?.(group) ?? (group.isAssigned && alreadyAssignedGroupTooltip);

  const readOnlyClass = cn({
    rowDisabled: group.isAssigned,
  });

  return (
    <>
      <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
        <GroupInfoCell group={group} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
        <PlaceholderLoader isLoading={group.isLoadingOwner}>
          <Tooltip
            target={<TextTruncate>{group.owners?.map((x: GroupOwner) => `${x.name}`).join(", ") ?? ""}</TextTruncate>}
            content={group.owners?.map((x: GroupOwner) => `${x.name}`).join(", ") ?? ""}
          />
        </PlaceholderLoader>
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
        {group.membersCount}
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width} className="cell-warning">
        {tooltipMessage && (
          <Popup
            hideOnScroll
            position="top center"
            size="small"
            className="popup-warning warning"
            content={tooltipMessage}
            trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
          />
        )}
      </Table.Cell>
    </>
  );
};

export default GroupListRow;
