import * as actionTypes from "../actionTypes/threatDefenceEmailTemplateDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import {
  type ThreatDefenceEmailTemplate,
  type ThreatDefenceEmailTemplateContent,
  type ThreatDefenceEmailTemplateView,
  type LoadThreatDefenceEmailTemplateContentResult,
} from "../../types/state";
import { type Dispatcher } from "../../../../../../interfaces/redux";
import {
  setEmailTemplateEntityIdAction,
  updateThreatDefenceEmailTemplateCommandSuccess,
} from "./threatDefenceEmailTemplateEntityStateActions";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import azureStorageUtils from "../../../../../../utils/azureStorageUtils";

export const saveThreatDefenceEmailTemplate = (emailTemplateMetadata: ThreatDefenceEmailTemplateView) => {
  const saveThreatDefenceEmailTemplateAction = getActionProvider<ThreatDefenceEmailTemplateView>(
    actionTypes.saveThreatDefenceEmailTemplate,
  );

  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceEmailTemplateAction(emailTemplateMetadata));
  };
};

export const saveThreatDefenceEmailTemplateContent = (
  threatDefenceEmailTemplateContent: ThreatDefenceEmailTemplateContent,
) => {
  const saveThreatDefenceEmailTemplateContentAction = getActionProvider<ThreatDefenceEmailTemplateContent>(
    actionTypes.saveThreatDefenceEmailTemplateContent,
  );

  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceEmailTemplateContentAction(threatDefenceEmailTemplateContent));
  };
};

export const clearThreatDefenceEmailTemplate = () => {
  const resetThreatDefenceEmailTemplateAction = getActionBaseProvider(actionTypes.clearThreatDefenceEmailTemplate);

  return (dispatch: Dispatcher) => {
    dispatch(resetThreatDefenceEmailTemplateAction());
  };
};

export const getThreatDefenceEmailTemplate = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceEmailTemplateBegin);
  const success = getActionProvider<ThreatDefenceEmailTemplate>(actionTypes.getThreatDefenceEmailTemplateSuccess);
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceEmailTemplateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dataService.threatDefence
      .getThreatDefenceEmailTemplateAsync(id)
      .then((result: { data: ThreatDefenceEmailTemplate }) => {
        dispatch(success(result.data));
        dispatch(setEmailTemplateEntityIdAction(result.data.id || 0));
        dispatch(updateThreatDefenceEmailTemplateCommandSuccess({ id: result.data.id || 0 }));
      })
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const getThreatDefenceEmailTemplateContent = (id: number, getTemplateOnly: boolean = false) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceEmailTemplateContentBegin);
  const success = getActionProvider<LoadThreatDefenceEmailTemplateContentResult>(
    actionTypes.getThreatDefenceEmailTemplateContentSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceEmailTemplateContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const contentUrlWithSas: string =
        await dataService.threatDefence.getThreatDefenceEmailTemplateContentUrlWithSasAsync(id);

      const html = await azureStorageUtils.downloadAsString(contentUrlWithSas);
      dispatch(success({ content: { id, contentUrlWithSas, html }, getTemplateOnly }));
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const setEmailTemplateAction = getActionProvider<ThreatDefenceEmailTemplate>(actionTypes.setEmailTemplate);
