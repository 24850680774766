import { type Dispatch } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { bindAction } from "../../../../../interfaces";
import { type RootState } from "../../../../Application/globaltypes/redux";
import * as accountsEventTypes from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import integrationsDataService from "../../../services/integrationsDataService";
import { login } from "../../connectors/MsGraphConnector";
import { IntegrationDtoMap } from "../../Shared";
import { integrationBaseSelector } from "../../state/selectors";
import { reset } from "../../state/slices/integrationDetailsSlice";
import { fetchIntegrationConfigsAction } from "../../state/thunks/integrationDetailsThunk";
import { IntegrationTypes, type MsGraphIntegrationConfigs } from "../../types";
import EditIntegrationBase from "../EditIntegrationBase/EditIntegrationBase";

const updateMsGraphWithAuthentication = async (
  originConfigs: MsGraphIntegrationConfigs,
  updatedConfigs: MsGraphIntegrationConfigs,
  moboId?: string,
) => {
  const resultConfig = { ...updatedConfigs };
  if (
    originConfigs.syncUsers !== updatedConfigs.syncUsers ||
    originConfigs.importUsageData !== updatedConfigs.importUsageData ||
    originConfigs.licensedOnly !== updatedConfigs.licensedOnly ||
    originConfigs.importLicenses !== updatedConfigs.importLicenses ||
    originConfigs.importGroups !== updatedConfigs.importGroups
  ) {
    const result = await login(
      updatedConfigs.importUsageData,
      updatedConfigs.syncUsers,
      updatedConfigs.importLicenses,
      updatedConfigs.importGroups,
    );
    if (!result.isSuccess) {
      return { isSuccess: false };
    }
    resultConfig.tenantId = result.result;
  }
  await integrationsDataService.updateIntegrationConfigs(IntegrationTypes.MsGraph, resultConfig, moboId);
  return { isSuccess: true };
};

const events = [
  accountsEventTypes.UpdateMsGraphIntegrationSettingsSuccess,
  accountsEventTypes.UpdateMsGraphIntegrationSettingsFailure,
];

const EditMsGraph: React.FC<PropsFromRedux> = (props) => {
  const { integration, isLoading, fetchConfigs, resetConfigs } = props;
  const { moboId } = useParams();

  const ownAccount = Number.isNaN(moboId) || moboId === undefined;

  const integrationUrl = IntegrationDtoMap.msgraph.url(ownAccount, moboId);

  useEffect(() => {
    fetchConfigs(moboId);

    return () => {
      resetConfigs();
    };
  }, [resetConfigs, fetchConfigs, moboId]);

  return (
    <>
      <EditIntegrationBase
        moboId={ownAccount ? undefined : moboId}
        integration={integration as MsGraphIntegrationConfigs}
        isLoading={isLoading}
        rtnEvents={events}
        integrationUrl={integrationUrl}
        integrationType={IntegrationTypes.MsGraph}
        onSaveHandler={updateMsGraphWithAuthentication}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state);
  return {
    integration: base.edit.details.data,
    isLoading: base.edit.details.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchConfigs: bindAction((id) => fetchIntegrationConfigsAction(IntegrationTypes.MsGraph, id), dispatch),
    resetConfigs: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditMsGraph);
export default ConnectedComponent;
