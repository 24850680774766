import { combineReducers } from "redux";

import { reducer as overviewReducer } from "./slices/pdfOverviewSlice";
import pdfFiltersSlice from "./slices/pdfFiltersSlice";
import pdfEntityStateReducer from "./reducers/pdfEntityStateReducer";
import pdfBaseSlice from "./slices/pdfBaseSlice";
import pdfDetailsSlice from "./slices/pdfDetailsSlice";
import pdfUsersSlice from "./slices/pdfUsersSlice";
import pdfUsersToAddSlice from "./slices/pdfUsersToAddSlice";
import pdfGroupsSlice from "./slices/pdfGroupsSlice";
import pdfGroupsToAddSlice from "./slices/pdfGroupsToAddSlice";
import withUsersAssignment from "../../../Application/reducers/hoc/withUsersAssignment";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import withGroupsAssignment from "../../../Application/reducers/hoc/withGroupsAssignment";
import pdfPerformanceSlice from "./slices/pdfPerformanceSlice";

const base = combineReducers({
  pdfEntityStateReducer,
  tagsBase: pdfBaseSlice,
  details: pdfDetailsSlice,
  performance: pdfPerformanceSlice,
});

const overview = combineReducers({
  pdfs: overviewReducer,
  filters: pdfFiltersSlice,
});

const create = combineReducers({
  assignedUsers: withUsersAssignment(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Pdfs),
  assignedGroups: withGroupsAssignment(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Pdfs),
});

const edit = combineReducers({
  users: pdfUsersSlice,
  usersToAdd: pdfUsersToAddSlice,
  groups: pdfGroupsSlice,
  groupsToAdd: pdfGroupsToAddSlice,
});

const reducers = combineReducers({
  base,
  create,
  edit,
  overview,
});

export type PdfState = ReturnType<typeof reducers>;

export default reducers;
