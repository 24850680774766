import { Button } from "components/buttons/button/Button";
import { Component } from "react";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../Application/globaltypes/redux";
import { Columns, getColumnOptions, sortingOptions } from "./columnOptions";

import { connect, type ConnectedProps } from "react-redux";
import ViewType from "../../../../enums/ViewType";
import * as eventEntityStateActions from "../state/actions/eventEntityStateActions";
import * as eventsOverviewActions from "../state/actions/eventsOverviewActions";

import { AssetVisibilityConfirmationModal } from "components/assetVisibilityConfirmationModal/AssetVisibilityConfirmationModal";
import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { type CardsViewerItem } from "../../../../components/cardsViewer/types";
import DeleteContentConfirmationModal, {
  type RemoveContentObserverInput,
} from "../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import { EventFilterForm } from "../../../../components/filterForms/EventFilterForm/EventFilterForm";
import RestrictedByAddOn from "../../../../components/restrictedByAddOn/RestrictedByAddOn";
import { PaywallModalTypes } from "../../../../components/restrictedByAddOn/paywallModal/types";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import SearchInput from "../../../../components/searchInput/SearchInput";
import { ContentTypesEnum, ItemsTypes, RolePermissions, SortingDirection } from "../../../../enums";
import SortOptions from "../../../../enums/SortOptions";
import { type EventsOverviewOwnState } from "./types";
import EventsNoResults from "../../../../views/library/events/Overview/EventsNoResults";
import Restricted from "../../../Application/Restricted";
import { ExternalEventEditModeSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as backgroundTasksActions from "../../../BackgroundTasks/state/backgroundTasksActions";
import { type LDProps } from "../../../LDProps";
import * as notificationsActions from "../../../Notifications/state/notificationsActions";
import { resetAppliedFilter, setAppliedFilter } from "../state/slices/eventFilterSlice";
import { setTerm } from "../state/slices/eventSearchSlice";
import { changeVisibility } from "../state/thunks/eventCommonThunk";
import { getFilterOptions } from "../state/thunks/eventFiltersThunk";
import { type EventOverview } from "../types/state";
import { OverviewCard } from "./Card/OverviewCard";
import { EventsOverviewHeader } from "./EventsOverviewHeader/EventsOverviewHeader";
import { Observable } from "../../../../utils";
import { type Filters } from "../../../../utils/queryUtils";
import eventListUtils from "../../../../utils/eventListUtils";
import { HandleOnSelectionChanged, type SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import { bindAction } from "../../../../interfaces";
import GenericItemsView from "../../../../views/ItemsView/GenericItemsView";

const takeDefaultCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

export type EventsOverviewProps = PropsFromRedux & WithRouterProps & LDProps;

export class EventsOverview extends Component<EventsOverviewProps, EventsOverviewOwnState> {
  private purchasedSelectedCount = 0;
  private undeletableSelectedCount = 0;
  private draftSelectedCount = 0;
  private hiddenSelectedCount = 0;
  private visibleSelectedCount = 0;

  private readonly deleteContentWithDependenciesFeatureEnabled: boolean;
  private readonly removeObserver = new Observable<
    (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
  >();
  private readonly onTriggerChangeVisibilityObserver = new Observable<
    (onConfirm: () => void, visible: boolean, ids: number[]) => void
  >();

  constructor(props: EventsOverviewProps) {
    super(props);

    this.state = {
      selectedEventIds: [],
      selectedViewType: ViewType.GRID,
      orderBy: SortOptions.Id,
      bypassSortingForSearch: false,
      isPurchasedSelected: false,
      isUndeletableSelected: false,
      isHiddenSelected: false,
      isVisibleSelected: false,
      isDraftSelected: false,
    };
    this.deleteContentWithDependenciesFeatureEnabled =
      !!this.props.flags?.[FeatureFlags.DeleteContentWithDependenciesFeature];
  }

  componentDidMount() {
    this.props.setSearch("");
    RtnEventsEmitter.subscribe(ExternalEventEditModeSuccess, this.goToEventEdit);
  }

  componentWillUnmount() {
    this.props.setSearch("");
    RtnEventsEmitter.unsubscribe(ExternalEventEditModeSuccess, this.goToEventEdit);
  }

  reloadListItems?: (enableSorting: boolean) => void;

  createReloadListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  getFilterOptions = () => {
    this.props.getFilterOptions();
  };

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedEventIds: ids || [] });
  };

  getEvents = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
    filter?: Filters,
  ) => {
    const take = this.isGridView() ? takeDefaultCount : top;
    const { appliedFilter } = this.props;

    const skipAllGrids = this.isGridView() ? skip ?? this.props.events?.length : skip;

    this.props.eventsOverviewActions.getEvents({
      lazy: this.isGridView(),
      term: this.props.search,
      take,
      skip: skipAllGrids,
      sortColumn: this.getOrderByColumnName(sortingColumnName),
      sortDirection: this.getOrderByDirection(sortingDirection),
      filters: filter ?? appliedFilter,
    });
  };

  getOrderByColumnName(sortingColumnName: string) {
    if (this.state.bypassSortingForSearch && this.isGridView()) {
      return undefined;
    }
    const [sortBy] = this.state.orderBy?.split(" ");
    return this.isGridView() ? sortBy : eventListUtils.formatOrderColumn(sortingColumnName);
  }

  getOrderByDirection(sortingDirection: SortingDirection) {
    if (this.state.bypassSortingForSearch && this.isGridView()) {
      return undefined;
    }
    if (this.isGridView()) {
      const [, sortOrder] = this.state.orderBy?.split(" ");
      return eventListUtils.convertToSortingDirection(sortOrder);
    }
    return sortingDirection;
  }

  getData = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    const loadedEventsCount = this.isGridView() ? this.props.events?.length || 0 : skip;
    this.getEvents(loadedEventsCount, top, sortingColumnName, sortingDirection);
  };

  goToEventEdit = (payload: { id: number }) => this.props.navigate(`${payload.id.toString()}/configure`);

  handleEditClick = (id: number, isDraft?: boolean) => () => {
    if (!isDraft) this.props.entityStateActions.fetchDraftEventEntity(id);
    this.goToEventEdit({ id });
  };

  renderCreateEventBtn = (action: () => void, hasAnyPermission: boolean = true): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary className="create-event create-button" onClick={action} disabled={!hasAnyPermission}>
        Create Event
      </Button>
    </RestrictedByTooltip>
  );

  goToCreateEvent = () => this.props.navigate("create");

  renderRestrictedCreateEventButton = (): React.ReactElement => (
    <RestrictedByAddOn
      permissions={[RolePermissions.EventsManage, RolePermissions.EventsCreate]}
      addOnPermissions={[RolePermissions.EventsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={this.goToCreateEvent}
      renderContent={this.renderCreateEventBtn}
    />
  );

  isGridView = () => {
    return this.state.selectedViewType === ViewType.GRID;
  };

  onViewTypeChange = (viewType: ViewType) => {
    this.setState(
      {
        selectedViewType: viewType,
        orderBy: SortOptions.Id,
      },
      () => {
        if (viewType === ViewType.GRID) {
          this.getEvents();
        }
      },
    );
  };

  getCardButtonHandlers = () => {
    return {
      onEdit: this.handleEditClick,
      onCopy: this.handleDuplicateClick,
      onDelete: this.handleDeleteClick,
      onToggleVisibility: this.handleToggleVisibility,
    };
  };

  applyFilter = (filter: Filters) => {
    this.onClearedSelection();
    this.props.applyFilter(filter);
    this.getEvents(0, undefined, undefined, undefined, filter);
  };

  resetFilter = () => {
    this.onClearedSelection();
    this.props.resetFilter();

    this.getEvents(0, undefined, undefined, undefined, {});
  };

  // This component is being cloned and receives needed props inside FilterPanel component.
  // @ts-ignore
  renderFilterForm = (): React.ReactElement => <EventFilterForm />;

  onSearchChange = (newSearchText: string) => {
    this.onClearedSelection();
    this.props.setSearch(newSearchText);
    this.setState(
      {
        bypassSortingForSearch: !isEmpty(newSearchText),
      },
      () => {
        this.reloadListItems?.(isEmpty(newSearchText));
        if (this.isGridView()) {
          this.props.eventsOverviewActions.resetEvents();
          this.getEvents(0);
        }
      },
    );
  };

  onListLoad = (loadedCount: number) => {
    if (this.isGridView()) {
      this.getEvents(loadedCount);
    }
  };

  onSortChange = (_: any, data: any) => {
    this.setState(
      {
        ...this.state,
        orderBy: data.value,
        bypassSortingForSearch: false,
      },
      () => this.getEvents(),
    );
  };

  isFiltered(): boolean {
    return !!this.props.search || !isEmpty(this.props.appliedFilter);
  }

  onClearedSelection = () => {
    this.purchasedSelectedCount = this.undeletableSelectedCount = this.draftSelectedCount = 0;
    this.setState({
      selectedEventIds: [],
      isPurchasedSelected: false,
      isUndeletableSelected: false,
      isHiddenSelected: false,
      isVisibleSelected: false,
      isDraftSelected: false,
    });
  };

  /* istanbul ignore next */
  onSelectionChanged = (args: SelectionChangedArgs<EventOverview>) => {
    const onAdded = (event: EventOverview) => {
      event.isPurchased && ++this.purchasedSelectedCount;
      !event.canBeDeleted && ++this.undeletableSelectedCount;
      event.isDraft && ++this.draftSelectedCount;
      !event.visibility && ++this.hiddenSelectedCount;
      event.visibility && ++this.visibleSelectedCount;
    };
    const onRemoved = (event: EventOverview) => {
      event.isPurchased && --this.purchasedSelectedCount;
      !event.canBeDeleted && --this.undeletableSelectedCount;
      event.isDraft && --this.draftSelectedCount;
      !event.visibility && --this.hiddenSelectedCount;
      event.visibility && --this.visibleSelectedCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, this.onClearedSelection);

    this.setState({
      isPurchasedSelected: this.purchasedSelectedCount > 0,
      isUndeletableSelected: this.undeletableSelectedCount > 0,
      isDraftSelected: this.draftSelectedCount > 0,
      isHiddenSelected: this.hiddenSelectedCount > 0,
      isVisibleSelected: this.visibleSelectedCount > 0,
    });
  };

  /* istanbul ignore next */
  reloadEvents = () => {
    this.props.eventsOverviewActions.resetEvents();
    if (this.isGridView()) {
      this.getEvents();
    } else {
      this.reloadListItems?.(true);
    }
  };

  handleDuplicateClick = (selectedIds: number[]) => () => {
    this.props.eventsOverviewActions.duplicateEvents(selectedIds, this.reloadEvents);
    this.onClearedSelection();
  };

  handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => () => {
    this.removeObserver.notify(
      () => {
        this.props.eventsOverviewActions.deleteEvents(ids, this.reloadEvents);
        this.onClearedSelection();
      },
      {
        selectedItemsCount: ids.length,
        flowsCount,
        packsCount,
      },
    );
  };

  /* istanbul ignore next */
  handleToggleVisibility = (ids: number[], visible: boolean) => () => {
    this.onTriggerChangeVisibilityObserver.notify(
      () => {
        this.props.toggleVisibility(ids, visible, () => {
          this.reloadEvents();
          this.onClearedSelection();
        });
      },
      visible,
      ids,
    );
  };

  render() {
    const { events, totalCount, isLoading, areAllEventsLoaded, appliedFilter, filterOptions, filtersLoading } =
      this.props;

    return (
      <section className="nested-content events">
        <EventsOverviewHeader
          undeletableSelected={this.state.isUndeletableSelected}
          purchasedSelected={this.state.isPurchasedSelected}
          draftSelected={this.state.isDraftSelected}
          selectedIds={this.state.selectedEventIds}
          duplicationHandler={this.handleDuplicateClick}
          deletionHandler={this.handleDeleteClick}
          renderCreateButton={this.renderRestrictedCreateEventButton}
          clearSelection={this.onClearedSelection}
          visibility={
            !!this.props.flags?.[FeatureFlags.ContentVisibility] &&
            !(this.state.isVisibleSelected && this.state.isHiddenSelected)
          }
          hiddenSelected={this.state.isHiddenSelected}
          onToggleVisibility={this.handleToggleVisibility}
        />
        <Restricted
          permissions={[RolePermissions.EventsCreate, RolePermissions.EventsManage]}
          renderContent={(hasPermission) => (
            <GenericItemsView
              className="alignment-padding"
              blur
              items={events}
              itemsType={ItemsTypes.Event}
              setReloadListItems={this.createReloadListItems}
              sortOptions={sortingOptions}
              onSortChange={this.onSortChange}
              viewType={this.state.selectedViewType}
              isAllDataLoaded={areAllEventsLoaded}
              fetchData={this.getData}
              noResultsContent={
                <EventsNoResults
                  createEventButton={this.renderRestrictedCreateEventButton()}
                  filtered={this.isFiltered()}
                />
              }
              isLoading={isLoading}
              selectedIds={this.state.selectedEventIds}
              renderCard={(props: CardsViewerItem<EventOverview>) => {
                return <OverviewCard {...props} {...this.getCardButtonHandlers()} readonly={!hasPermission} />;
              }}
              onViewTypeChange={this.onViewTypeChange}
              onLoad={this.onListLoad}
              columnOptions={getColumnOptions({
                visibility:
                  !!this.props.flags?.[FeatureFlags.ContentVisibility] &&
                  this.props.userPermissions.includes(RolePermissions.EventsCreate),
                readonly: !hasPermission,
                deleteContentWithDependenciesFeatureEnabled: this.deleteContentWithDependenciesFeatureEnabled,
                buttonHandlers: {
                  handleEditClick: this.handleEditClick,
                  handleDuplicateClick: this.handleDuplicateClick,
                  handleDeleteClick: this.handleDeleteClick,
                  onToggleVisibility: this.handleToggleVisibility,
                },
              })}
              dataCount={totalCount}
              onSelectedGridItemsChanged={this.onSelectedItemsChanged}
              onSelectedItemChanged={this.onSelectedItemsChanged}
              isSelectDisabled={() => !hasPermission}
              getFilterForm={this.renderFilterForm}
              appliedFilter={appliedFilter}
              getFilterOptions={this.getFilterOptions}
              filterOptionsLoading={filtersLoading}
              filterOptions={filterOptions}
              applyFilter={this.applyFilter}
              resetFilter={this.resetFilter}
              sortingDirection={SortingDirection.Descending}
              defaultSortingColumnName={this.state.bypassSortingForSearch ? "" : Columns.Added}
              renderSearch={() => (
                <SearchInput
                  placeholder="Search for Events..."
                  onChange={this.onSearchChange}
                  defaultValue={this.props.search}
                />
              )}
              permissions={[RolePermissions.EventsManage]}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        />
        <DeleteContentConfirmationModal
          contentType={ContentTypesEnum.Events}
          onTriggerRemoveContentObserver={this.removeObserver}
        />
        <AssetVisibilityConfirmationModal observer={this.onTriggerChangeVisibilityObserver} assetType="event" />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.events.eventsOverview;
  const entityState = state.library.events.eventEntityState;
  return {
    events: state.library.events.eventsOverview.events,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: state.library.events.eventsOverview.totalCount,
    areAllEventsLoaded: state.library.events.eventsOverview.isAllLoaded,
    search: state.library.events.search.term,
    filterOptions: state.library.events.filters.filterOptions,
    appliedFilter: state.library.events.filters.appliedFilter,
    filtersLoading: state.library.events.filters.isLoading,
    userPermissions: state.userProfile.permissions,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  eventsOverviewActions: bindActionCreators(eventsOverviewActions, dispatch),
  entityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  applyFilter: bindAction(setAppliedFilter, dispatch),
  resetFilter: bindAction(resetAppliedFilter, dispatch),
  getFilterOptions: bindAction(getFilterOptions, dispatch),
  setSearch: bindAction(setTerm, dispatch),
  toggleVisibility: bindAction(changeVisibility, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(withLDConsumer()(EventsOverview)));
export default ConnectedComponent;
