export const CreateAccountStepsWithTemplates = {
  Configure: 0,
  Contacts: 1,
  Templates: 2,
  Packs: 3,
  AddOn: 4,
  Notify: 5,
};
export const CreateAccountSteps = {
  Configure: 0,
  Contacts: 1,
  Packs: 2,
  AddOn: 3,
  Notify: 4,
};
