import type UpdateParams from "../../../../interfaces/updateParams";
import rolesDataService from "../../services/rolesDataService";

export default class DeleteRoleTask implements UpdateParams {
  id: string;
  title: string;
  successTransientMessage: string;
  failureTransientMessage: string;
  indeterminate: boolean;
  roleId: number;

  constructor(roleId: number, roleName: number) {
    this.id = "DeleteRole";
    this.title = `Deleting role '${roleName}'`;
    this.successTransientMessage = "Role has been deleted!";
    this.failureTransientMessage = "Role deletion failed!";
    this.indeterminate = true;
    this.roleId = roleId;
  }

  getMessageIds = async () => {
    const messageId = await rolesDataService.deleteRole(this.roleId);
    return [messageId];
  };
}
