import { Checkbox } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import moment from "moment";
import AddOnList from "../../AddOnsList/AddOnsList";
import DatePickerWrapper from "../../../../stories/wrappers/DatePickerWrapper";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { type EditingAddOn } from "../EditAddOns/EditAddOns";
import { ReactComponent as ProgressSavedIcon } from "../../../../components/progressSaved/progress-saved-icon.svg";
import { Button } from "components/buttons/button/Button";

import "./addOnsConfiguration.scss";

export interface AddOnsConfigurationProps {
  addOns: EditingAddOn[];
  onMount?: () => void;
  onChangeAddons(addOns: EditingAddOn[]): void;
  isLoading: boolean;
  isReadOnly: boolean;
}

export default function AddOnsConfiguration({
  addOns,
  onMount,
  onChangeAddons,
  isLoading,
  isReadOnly,
}: AddOnsConfigurationProps) {
  useEffect(() => {
    onMount?.();
    // eslint-disable-next-line
  }, []);

  const marketplaceAddonId = 3;
  const contentAddonId = 1;

  const toggleAddOn = (addOn: EditingAddOn) => {
    const newAddOns = cloneDeep(addOns);
    newAddOns.find((toToggle) => toToggle.id === addOn.id)?.toggle();

    if (addOn.id === marketplaceAddonId && newAddOns.find((toToggle) => toToggle.id === addOn.id)?.isEnabled) {
      newAddOns.find((toToggle) => toToggle.id === contentAddonId && !toToggle.isEnabled)?.toggle();
    }

    onChangeAddons(newAddOns);
  };

  const isToggleDisabled = (addOn: EditingAddOn) => {
    return (
      (addOn.id === contentAddonId &&
        addOn.isEnabled &&
        addOns.find((toToggle) => toToggle.id === marketplaceAddonId)?.isEnabled) ||
      addOn.default
    );
  };

  const handleDateChange = (value: string, addOn: EditingAddOn) => {
    let newDate = moment(new Date(value));
    const newAddOns = cloneDeep(addOns);
    newAddOns.find((toToggle) => toToggle.id === addOn.id)?.setExpirationDate(newDate < minDate ? minDate : newDate);
    onChangeAddons(newAddOns);
  };

  const renderEnabledCell = (addOn: EditingAddOn) => {
    return addOn.canEdit ? (
      <Checkbox
        toggle
        checked={addOn.isEnabled}
        className="addon-enabled-checkbox"
        onChange={() => toggleAddOn(addOn)}
        readOnly={isReadOnly}
        disabled={isReadOnly || isToggleDisabled(addOn)}
      />
    ) : (
      renderReadOnlyEnabledCell(addOn)
    );
  };

  const renderReadOnlyEnabledCell = (addOn: EditingAddOn) =>
    addOn.isEnabled ? <ProgressSavedIcon /> : <Button content="Request" disabled={isReadOnly} primary />;

  const minDate = moment();

  const renderExpirationDateCell = (addOn: EditingAddOn) => {
    const expirationDate = addOn.expirationDate ? dateTimeUtils.formatDate(addOn.expirationDate) : "";
    return addOn.canEdit ? (
      <DatePickerWrapper
        selected={expirationDate}
        propertyName="expirationDate"
        minDate={dateTimeUtils.formatDate(minDate)}
        disabled={isReadOnly || !addOn.isEnabled || addOn.default}
        onDateChange={(_, data: any) => handleDateChange(data.value, addOn)}
      />
    ) : (
      <span>{expirationDate}</span>
    );
  };

  return (
    <section className="addons-table">
      <div className="addons-header">Feature Add-Ons</div>
      <AddOnList
        addOns={addOns}
        isLoading={isLoading}
        renderEnabledCell={renderEnabledCell}
        renderExpirationDateCell={renderExpirationDateCell}
      />
    </section>
  );
}
