import { type AppDispatch } from "../../../../../../../Application/globaltypes/redux";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slice/nextFlowItemsSlice";
import DataService from "features/Application/services/dataServices/typedDataService";
import { type GetFlowsRequest } from "../../../../../types/requests";
import { type NextFlowInfo } from "features/Library/Flows/Designer/types";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchFlowsItems = (request: GetFlowsRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchBegin());
    const result = await DataService.flows.getFlowsWithCancel(request).promise;
    dispatch(
      fetchSuccess({
        items: result.data.map((x) => {
          return {
            id: x.id,
            title: x.title,
            thumbnailUrl: x.thumbnailUrl,
            publisher: x.publisher,
            goals: x.goals,
          } as NextFlowInfo;
        }),
        totalCount: Number.parseInt(result.headers[countHeaderName]),
      }),
    );
  } catch (e) {
    // Request was aborted manually, no need to change anything
    if (e.code === "ERR_CANCELED") {
      return;
    }
    dispatch(fetchFailure(e));
  }
};
