import { type FC } from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";

import { GroupEllipsisPopupButton } from "..";
import { RolePermissions } from "../../../enums";
import { type RootState } from "../../../features/Application/globaltypes/redux";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import GroupInfoCell from "../../groupInfoCell/GroupInfoCell";

import { type Group, type GroupActionHandlers } from "../../../interfaces";
import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";

import styles from "./groupListRow.module.scss";
import { Link } from "react-router-dom";
import { columnOptions } from "./getColumnOptions";

export interface GroupListRowProps extends GroupActionHandlers {
  group: Group;
  selected: boolean;
}

export const GroupListRow: FC<GroupListRowProps> = ({ group, selected, ...buttonHandlers }) => {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);
  const disableActions = !group.canEdit && !userPermissions.includes(RolePermissions.GroupsCreate);
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <GroupInfoCell group={group} deepLink />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Link className={styles.memberCount} to={`${group.id}/members`}>
          {group.membersCount}
        </Link>
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(group.dateCreated)}</Table.Cell>
      <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
        {buttonHandlers?.onEdit && (
          <RestrictedByTooltip hasPermission={!disableActions}>
            <GroupEllipsisPopupButton disabled={disableActions || selected} group={group} {...buttonHandlers} />
          </RestrictedByTooltip>
        )}
      </Table.Cell>
    </>
  );
};

export default GroupListRow;
