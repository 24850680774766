import React, { useMemo } from "react";
import { Loader, Message } from "semantic-ui-react";
import cn from "classnames";
import { type RequestState, RStatus } from "../../features/Application/globaltypes/fetchRequest";
import "./requestStatusRenderer.scss";
import { type UseQueryResult } from "@tanstack/react-query";

const STANDARD_ERROR_MESSAGE =
  "An unexpected error occured. Please refresh the page. \n\nIf this error persists, contact your BrainStorm representative.";

const anyIsPending = (state: RequestState<unknown>[]) => {
  return state.some((s) => s.status === RStatus.Pending);
};

const anyHasError = (state: RequestState<unknown>[]) => {
  return state.some((s) => s.status === RStatus.Error);
};

const allHaveSucceeded = (state: RequestState<unknown>[]) => {
  return state.every((s) => s.status === RStatus.Got);
};

export interface Props {
  state: RequestState<unknown> | RequestState<unknown>[] | UseQueryResult["status"];
  children?: React.ReactNode;
  additionalLoadingClass?: string;
  additionalErrorClass?: string;
}

const error = (additionalErrorClass?: string) => (
  <Message className={cn("requestErrorMessage", additionalErrorClass)} color="red">
    {STANDARD_ERROR_MESSAGE}
  </Message>
);

const loading = (additionalLoadingClass?: string) => (
  <div className={cn("centeredLoader", additionalLoadingClass)} data-testid="loading status container">
    <Loader active />
  </div>
);

const renderChildren = (children: React.ReactNode) => <>{children}</>;

const empty = <></>;

const statusType = (
  stateArray: UseQueryResult["status"] | RequestState<unknown>[],
  children: React.ReactNode,
  additionalLoadingClass?: string,
  additionalErrorClass?: string,
) => {
  if (typeof stateArray !== "string") {
    if (anyHasError(stateArray)) {
      return error(additionalErrorClass);
    }
    if (anyIsPending(stateArray)) {
      return loading(additionalLoadingClass);
    }
    if (allHaveSucceeded(stateArray)) {
      return renderChildren(children);
    }
    return empty;
  } else {
    if (stateArray === "error") {
      return error(additionalErrorClass);
    }
    if (stateArray === "loading") {
      return loading(additionalLoadingClass);
    }
    if (stateArray === "success") {
      return renderChildren(children);
    }
    return empty;
  }
};

/**
 * Renders different items depending on the state of the request.
 * If idle, render nothing.
 * If pending, render a loader.
 * If an error, render the error message in a semantic message component.
 * If got, render the children.
 * @param props
 * @returns
 */

export const RequestStatusRenderer: React.FC<Props> = ({
  state,
  children,
  additionalLoadingClass,
  additionalErrorClass,
}) => {
  const stateArray = useMemo(() => {
    if (typeof state !== "string" && !Array.isArray(state)) return [state];
    return state;
  }, [state]);

  return statusType(stateArray, children, additionalLoadingClass, additionalErrorClass);
};
