import React from "react";
import { Icon } from "semantic-ui-react";

import "./fixedError.scss";

interface Props {
  id: string;
  title: string;
  message: string;
  onClose: (id: string) => void;
}

export const FixedError: React.FC<Props> = ({ title, message, onClose, id }) => {
  return (
    <div className="fixed-flow-designer-errors-item" data-testid="flow-validator-fixed-error-item">
      <div className="designer-errors-item-icon">
        <Icon className="check circle outline error-icon" size="huge" color="green" />
      </div>
      <div className="designer-error-message">
        <div className="designer-errors-item-title">{title}</div>
        {message}
      </div>
      <div
        role="button"
        data-hook-type="remove-fixed-error"
        data-hook-id={id}
        className="designer-errors-item-close-icon"
        onClick={() => {
          onClose(id);
        }}
      >
        <Icon className="times circle outline error-icon" size="big" color="grey" />
      </div>
    </div>
  );
};
