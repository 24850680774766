export const templateUtils = {
  mergeTemplate(layout: string, template: string): string {
    return layout.replace("[[TemplateContent]]", template);
  },
  extractTemplateFromLayout(template: string): string {
    // @ts-ignore
    let newTemplate = template.replace(/^.*?<!-- Begin Template Section -->/s, "");
    // @ts-ignore
    newTemplate = newTemplate.replace(/<!-- End Template Section -->.*$/s, "");
    return newTemplate.trim();
  },
};
