import { type FormikProps, withFormik } from "formik";
import { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../../../Application/globaltypes/redux";
import { type FeedbackPageContent } from "../../types/state";
import { type ContentFeedbackPageProps } from "../types";
import * as feedbackPageEntityStateActions from "../../state/actions/FeedbackPageEntityStateActions";
import * as feedbackPageDetailsActions from "../../state/actions/FeedbackPageDetailsActions";

export type ContentAllProps = ContentFeedbackPageProps & PropsFromRedux & FormikProps<FeedbackPageContent>;

const Content = (props: ContentAllProps) => {
  const { acceptHandlers, onIsValidChange, isValid, id } = props;
  const { publishDraftFeedbackPage } = props.entityStateActions;

  useEffect(() => {
    const onPublishFeedbackPage = () => {
      publishDraftFeedbackPage(id);
    };

    const onFinish = () => {
      onPublishFeedbackPage();
    };

    acceptHandlers?.({
      onFinish: onFinish,
    });

    // Synchronizes parrent component state with formik onMount validation
    onIsValidChange?.(isValid);
  }, [acceptHandlers, publishDraftFeedbackPage, onIsValidChange, isValid, id]);

  return <> Coming Soon </>;
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentFeedbackPageProps) => {
  const detailsReducer = state.library.threatDefenceFeedbackPages.feedbackPageDetails;
  const id = ownProps.entityId || state.library.threatDefenceFeedbackPages.feedbackPageEntityState.entityId;
  return {
    id,
    content: {
      id,
    },
    isLoaded: detailsReducer.isFeedbackPageLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating: state.library.threatDefenceFeedbackPages.feedbackPageEntityState.changingEntityState,
    isDraft: detailsReducer.feedbackPage.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(feedbackPageEntityStateActions, dispatch),
  detailsActions: bindActionCreators(feedbackPageDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const component = withFormik({
  validationSchema: undefined,
  enableReinitialize: true,
  isInitialValid: true,
  mapPropsToValues: (props: ContentFeedbackPageProps & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(Content);

const ConnectedComponent = connector(component);
export default ConnectedComponent;
