import React from "react";
import { Button } from "../button/Button";
import cn from "classnames";

import "./errrorButton.scss";

export interface Props {
  label: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

function ErrorButton(props: Props) {
  const { label, className, disabled, onClick, ...rest } = props;
  return (
    <Button className={cn("error-button", className)} disabled={disabled} {...rest} basic color="red" onClick={onClick}>
      <span className="title">{label}</span>
    </Button>
  );
}

export default ErrorButton;
