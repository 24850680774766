import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { bindActionCreators, type Dispatch } from "redux";

import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import { type CreateEventOwnState, CreateEventSteps, showLastModified } from "./types";
import { type PagesBase } from "../Edit/types";
import { EventSteps } from "../types/state";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import { type RootState } from "../../../Application/globaltypes/redux";
import * as eventDetailsActions from "../state/actions/eventDetailsActions";
import * as eventEntityStateActions from "../state/actions/eventEntityStateActions";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as eventSessionsValidatorActions from "../state/actions/eventSessionsValidatorActions";
import PublishValidationInfo from "../../../../components/publishValidationInfo/PublishValidationInfo";
import { PublishedStatusTypes, RouteNames } from "../../../../enums";
import Sections from "../../../../enums/sections";
import userPermissionActions from "../../PeopleAssignments/state/actions/userPermissionActions";
import groupPermissionActions from "../../PeopleAssignments/state/actions/groupPermissionActions";
import Configure from "../EventDetails/Configure/Configure";
import RemindersTab from "../EventDetails/Reminders/RemindersTab";
import Sessions from "../EventDetails/Sessions/Sessions";
import { eventSessionsSchemaCreate } from "../../../../utils/validationSchemas/eventValidationSchemas";
import navigationUtils from "../../../../utils/navigationUtils";
import SettingsTab from "../EventDetails/Settings/SettingsTab";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Observable from "../../../../utils/Observable";
import { type IObservable } from "interfaces";

import "./createEvent.scss";
import { deleteReminders } from "../state/thunks/remindersThunk";
import { pluralize } from "utils/stringUtils";
import { DeleteLinkButton } from "components";
import { FeatureFlags } from "featureFlags";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { type LDProps } from "../../../LDProps";
import EntityTypes from "../../../../enums/eventTypes";

const eventOverviewUrl = `/${RouteNames.contentEvents}`;

export type CreateEventProps = PropsFromRedux & WithRouterProps & LDProps;
export type Props = CreateEventProps;

export class CreateEvent extends Component<Props, CreateEventOwnState> {
  private stepsManager = new WizardStepsManager();
  private isAssociatedPackEnabled: boolean;
  private pages: PagesBase;
  private readonly onTriggerRemoveRemindersObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;

  constructor(props: Props) {
    super(props);
    this.isAssociatedPackEnabled = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
    this.state = {
      isValid: false,
      showAddPacksModal: false,
      showUsersAssignModal: false,
      isNotifyStepLoaded: false,
      isNotifyStepValid: false,
      activePage: CreateEventSteps.Configure,
    };

    this.pages = {
      configure: {
        index: CreateEventSteps.Configure,
        segment: {
          label: "Configuration",
        },
      },
      settings: {
        index: CreateEventSteps.Settings,
        segment: {
          label: "Settings",
          to: "settings",
        },
      },
      content: {
        index: CreateEventSteps.Content,
        segment: {
          to: "sessions",
          label: "Sessions",
        },
      },
      reminders: {
        index: CreateEventSteps.Reminders,
        segment: {
          to: "reminders",
          label: "Reminders",
        },
      },
    };

    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: number) =>
      this.setState({ activePage: activeStepIndex }),
    );

    this.onTriggerRemoveRemindersObserver = new Observable<
      (onRemoveConfirm: () => void, selectedItemsCount: number) => void
    >();
  }

  componentDidMount() {
    this.props.detailsActions.clearEvent();
    RtnEventsEmitter.subscribe(rtnEvents.EventPublishSuccess, this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    this.props.validateActions.resetAction();
    RtnEventsEmitter.unsubscribe(rtnEvents.EventPublishSuccess, this.onPublishedEvent);
  }

  onPublishedEvent = () => {
    this.isAssociatedPackEnabled && this.setState({ showAddPacksModal: true });
    return !this.isAssociatedPackEnabled && this.props.navigate(eventOverviewUrl);
  };

  onCancel = async () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, `/${RouteNames.contentEvents}`);
  };

  onFinishAsync = () => {
    this.props.validateActions.tryPublish(this.props.id, true);
  };

  onRemoveReminders = () => {
    const { id, deleteReminders, selectedReminders } = this.props;
    this.onTriggerRemoveRemindersObserver.notify(() => {
      deleteReminders(
        id,
        selectedReminders.map((reminder) => reminder.id),
      );
    }, selectedReminders.length);
  };

  setValidationState = (isValid: boolean) => {
    this.setState({ isValid });
  };

  renderCustomHeader = (changeActiveIndex: (index: number) => void) => {
    const isRemindersTab = this.state.activePage === CreateEventSteps.Reminders;
    const anyRemindersSelected = this.props.selectedReminders.length > 0;
    if (isRemindersTab && anyRemindersSelected) {
      return <DeleteLinkButton onClick={this.onRemoveReminders} />;
    }

    const { sessionsErrorMode, sessionsResolved } = this.props;
    const hasActiveErrors = !sessionsResolved;
    const onErrorClick = () => {
      changeActiveIndex(this.pages[EventSteps.Sessions].index);
      this.stepsManager.goToPage(this.pages[EventSteps.Sessions].index);
    };
    return (
      sessionsErrorMode && (
        <PublishValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          onErrorButtonClick={onErrorClick}
        />
      )
    );
  };

  getTitle = () => {
    const selectedRemindersCount = this.props.selectedReminders.length;
    if (selectedRemindersCount > 0) {
      return `${selectedRemindersCount} ${pluralize("Reminder", selectedRemindersCount)} Selected`;
    }

    return "Create Event";
  };

  onRegressAsync = async (_: number, nextIndex: number) => {
    this.stepsManager.onPrevious(nextIndex);
  };

  get entityStatus() {
    return this.props.id > 0 ? PublishedStatusTypes.draft : undefined;
  }

  get isEventCreating() {
    return this.props.isChanging && !this.entityStatus;
  }

  render() {
    const { isValid, activePage, showAddPacksModal } = this.state;
    const { isSaving, dateModified, isChanging, sessionsResolved } = this.props;
    const { configure, content, reminders } = this.pages;

    return (
      <>
        <Dimmer active={this.isEventCreating} inverted>
          <Loader active={this.isEventCreating} />
        </Dimmer>
        <Wizard
          title={this.getTitle()}
          finishButtonLabel="Finish"
          className="create-event"
          onCancel={this.onCancel}
          onFinishAsync={this.onFinishAsync}
          onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
          onRegressAsync={this.onRegressAsync}
          publishedStatus={this.entityStatus}
          isSaveInProgress={isSaving || isChanging}
          progressSavedDate={showLastModified.has(activePage) ? dateModified : undefined}
          isFinishButtonDisabled={!sessionsResolved}
          renderCustomHeader={this.renderCustomHeader}
        >
          <Wizard.Step label="Configuration" className="scrollable-content" required isLocked={!isValid}>
            <Configure
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
              onIsValidChange={this.setValidationState}
            />
          </Wizard.Step>
          <Wizard.Step label="Settings" className="scrollable-content" isLocked={!isValid}>
            <SettingsTab
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, this.pages.settings.index)}
            />
          </Wizard.Step>
          <Wizard.Step label="Details" isLocked={isChanging}>
            <Sessions
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, content.index)}
              schema={eventSessionsSchemaCreate}
              {...this.props}
            />
          </Wizard.Step>
          <Wizard.Step label="Reminders" isLocked={isChanging} className={"tab__content"}>
            <RemindersTab
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, reminders.index)}
              onTriggerRemoveRemindersObserver={this.onTriggerRemoveRemindersObserver}
            />
          </Wizard.Step>
        </Wizard>
        <AddAssetsToPackModal
          showModal={showAddPacksModal}
          onClose={/* istanbul ignore next */ () => this.props.navigate(`/${RouteNames.contentEvents}`)}
          onComplete={/* istanbul ignore next */ () => this.props.navigate(`/${RouteNames.contentEvents}`)}
          selectedItemIds={[this.props.id]}
          contentType={EntityTypes.ExternalEvent}
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  detailsActions: bindActionCreators(eventDetailsActions, dispatch),
  entityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  validateActions: bindActionCreators(eventSessionsValidatorActions, dispatch),
  usersAssignmentActions: bindActionCreators(userPermissionActions(Sections.events), dispatch),
  groupPermissionActions: bindActionCreators(groupPermissionActions(Sections.events), dispatch),
  deleteReminders: bindActionCreators(deleteReminders, dispatch),
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const eventValidatorReducer = state.library.events.eventSessionsValidationReducer;
  const eventReminders = state.library.events.eventReminders;
  return {
    id: state.library.events.eventEntityState.entityId,
    isLoading: state.library.events.eventDetails.isLoading,
    event: state.library.events.eventDetails.event,
    dateModified: state.library.events.eventEntityState.lastModifiedDateTime,
    isSaving: state.library.events.eventEntityState.isEntityCommandInProgress,
    sessionsErrorMode: eventValidatorReducer.errorMode,
    sessionsResolved: eventValidatorReducer.resolved,
    isFormValid: eventValidatorReducer.isFormValid,
    isChanging: state.library.events.eventEntityState.changingEntityState,
    selectedReminders: eventReminders.selectedItems,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(withLDConsumer()(CreateEvent)));
export default ConnectedComponent;
