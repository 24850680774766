import { type AssignedUser } from "../../../../../../../interfaces";
import type AssignedGroup from "../../../../../../../interfaces/assignedGroup";
import { type FetchingItemsState } from "../../../../../../Application/slices/createFetchingItemsSlice";

export const getEntityData = <T extends AssignedUser | AssignedGroup>(
  entityData: FetchingItemsState<T>,
  flowsCount: number,
): T[] =>
  entityData.items.map((entity) => ({
    ...entity,
    isAssigned: entity.assignsCount === flowsCount,
  }));
