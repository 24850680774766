import { type QueryFunctionContext } from "@tanstack/react-query";
import { type CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { type LinechartProps } from "components/charts";
import { type ColumnDataPoint } from "components/charts/types/ColumnChart";
import {
  type PerformanceFilter,
  type PerformanceWithAccountFilter,
  lineChartFactory,
  longFormDateFilter,
  roundToTwoDigits,
  sendExportRequest,
} from "features/Library/Common/utils/performanceUtils";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import moment from "moment";

export const yearDateRange = (): PerformanceFilter => {
  const today = moment().startOf("day").toISOString();
  const todayMinus30 = moment().subtract(1, "year").startOf("month").startOf("day").toISOString();
  return longFormDateFilter(todayMinus30, today);
};

type QueryContext = QueryFunctionContext<[_name: string, dateFilter: PerformanceWithAccountFilter], AbortSignal>;

type LineResponse = Pick<LinechartProps, "xData" | "yData">;

// For now, these are 2 separate charts
export const fetchPeopleOverviewLineCharts = async ({
  queryKey,
  signal,
}: QueryContext): Promise<{
  activeUsers: LineResponse;
  interactionsAsBarData: ColumnDataPoint[];
  activeUsersTooltips: string[];
}> => {
  const { data } = await peopleReportingService.overview.getLineChart(
    {
      ...queryKey[1],
    },
    signal!,
  );

  const lineChartData = lineChartFactory(data);

  return {
    activeUsers: { xData: [lineChartData.Date], yData: [lineChartData.ActiveUsersCount] },
    interactionsAsBarData: data.map((d) => ({ id: d.Date, category: d.Date, value: d.InteractionCount })),
    activeUsersTooltips: data.map(
      (d) => `${d.ActiveUsersCount.toLocaleString()} (${Math.round(d.LicenseAssignedRate * 100)}% of licensed)`,
    ),
  };
};

export const fetchPeopleOverviewStatistics = async ({
  queryKey,
  signal,
}: QueryContext): Promise<CardReportingItem[]> => {
  const { data } = await peopleReportingService.overview.getStatistics({ ...queryKey[1] }, signal!);

  return [
    {
      stat: data.ActiveUserCount?.toLocaleString() ?? 0,
      statDescription: "Licensed Users",
    },
    {
      stat: data.InteractionCount?.toLocaleString() ?? 0,
      statDescription: "Interactions",
    },
    {
      stat: roundToTwoDigits(data.AvgInteractionPerUser ?? 0).toLocaleString(),
      statDescription: "Avg. Interactions / User",
    },
  ];
};

export const fetchPeopleOverviewAccountsTable = async ({ queryKey, signal }: QueryContext) => {
  const { data } = await peopleReportingService.overview.getAccountsTable({ ...queryKey[1] }, signal!);

  // Add id field for table keys
  return data.map((d) => ({ ...d, id: d.AccountId }));
};

export const fetchPeopleOverviewUsersTable = async ({ queryKey, signal }: QueryContext) => {
  const { data } = await peopleReportingService.overview.getUsersTable({ ...queryKey[1] }, signal!);

  // Add id field for table keys
  return data.map((d) => ({ ...d, id: d.UserId }));
};

let abortController = new AbortController();

export const fetchPeopleOverviewAccountsTableExport = (filterParams: PerformanceWithAccountFilter) => {
  return sendExportRequest(
    (abortController) => peopleReportingService.overview.getAccountsTableExport(filterParams, abortController.signal),
    "Accounts",
    abortController,
  );
};

export const fetchPeopleOverviewUsersTableExport = (filterParams: PerformanceWithAccountFilter) => {
  return sendExportRequest(
    (abortController) => peopleReportingService.overview.getUsersTableExport(filterParams, abortController.signal),
    "Users",
    abortController,
  );
};

export const sharedOverviewLineProps = {
  xFormatterFunc: (date: Date) => moment(date).format("MMM 'YY"),
  tooltipFormatter: (date: Date, value: number) =>
    `${moment(date).format("MMM 'YY")}: ${value.toLocaleString()} Interactions`,
} as const;
