import midnightService from "../../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/threatDefenceSendingProfileEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../../enums/draftEntityTypes";
import { type ThreatDefenceDirectSendSendingProfile } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../../interfaces/redux";
import dataService from "../../../../../../Application/services/dataServices/typedDataService";
import { setSendingProfileAction, setSendingProfileIsLoaded } from "./threatDefenceSendingProfileDetailsActions";
import { beginAsyncOperation } from "../../../../../../Application/slices/asyncOperationSlice";
import {
  DirectSendSendingProfileUpdateSuccess,
  DirectSendSendingProfilePublishSuccess,
  DirectSendSendingProfileLockSuccess,
  DirectSendSendingProfileDiscardSuccess,
} from "../../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createThreatDefenceSendingProfile = (data: ThreatDefenceDirectSendSendingProfile) => {
  const begin = getActionBaseProvider(actionTypes.createThreatDefenceSendingProfileBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceSendingProfileSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.DirectSendSendingProfile, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
      dispatch(setSendingProfileIsLoaded());
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceSendingProfileEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishThreatDefenceSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishThreatDefenceSendingProfileFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: DirectSendSendingProfilePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.DirectSendSendingProfile, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchDraftThreatDefenceSendingProfileEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedThreatDefenceSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedThreatDefenceSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: DirectSendSendingProfileLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.DirectSendSendingProfile, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedTdSendingProfileSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedThreatDefenceSendingProfileSuccess,
);

export const revertLockedThreatDefenceSendingProfile = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.revertLockedThreatDefenceSendingProfileBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.revertLockedThreatDefenceSendingProfileSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.revertLockedThreatDefenceSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: DirectSendSendingProfileDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.DirectSendSendingProfile, id);
      dispatch(success({ entityId: id }));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceSendingProfileSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishThreatDefenceSendingProfileSuccess,
);

export const updateThreatDefenceSendingProfile = (metadata: ThreatDefenceDirectSendSendingProfile) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: DirectSendSendingProfileUpdateSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceDirectSendSendingProfileAsync(metadata);
      dispatch(setSendingProfileAction(metadata));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const threatDefenceSendingProfileUpdated = getActionProvider(
  actionTypes.threatDefenceSendingProfileUpdated,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const updateThreatDefenceSendingProfileCommandSuccess = getActionProvider(
  actionTypes.updateThreatDefenceSendingProfileCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const resetThreatDefenceSendingProfileEntityState = getActionBaseProvider(
  actionTypes.resetThreatDefenceSendingProfileEntityState,
);

export const setSendingProfileEntityIdAction = getActionProvider<number>(actionTypes.setSendingProfileEntityId);
