import { batch } from "react-redux";
import { type AppDispatch, type AppThunk, type RootState } from "../globaltypes/redux";
import { getFilterOptions as getVideoFilters } from "../../Library/Videos/state/thunks/videoFiltersThunk";
import { getFilterOptions as getSurveyFilters } from "../../Library/Surveys/state/thunks/surveyFiltersThunk";
import { getFilterOptions as getAssessmentFilters } from "../../Library/Assessments/state/thunks/assessmentFiltersThunk";
import { getFilterOptions as getEventsFilters } from "../../Library/Events/state/thunks/eventFiltersThunk";
import { getFilterOptions as getEmailFilters } from "../../Library/Communications/Emails/state/thunks/emailFiltersThunk";
import { getFilterOptions as getFlowsFilters } from "../../Library/Flows/state/thunks/flowFiltersThunk";
import { getFilterOptions as getPdfsFilters } from "../../Library/Pdfs/state/thunks/pdfFiltersThunk";
import { flowInspectorTypeSelectSelector } from "../../Library/Flows/Designer/selectors";
import { type EntityType } from "../../Library/Flows/Designer/types";
import { type ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { type GenericFiltersMap } from "../../../utils/filterUtils";

export type ContentFilterSliceActions = {
  setError: ActionCreatorWithPayload<Error, string>;
  setFilterOptions: ActionCreatorWithPayload<GenericFiltersMap<string | number>, string>;
  setIsLoading: ActionCreatorWithPayload<boolean, string>;
};

export const fetchFiltersToDrop =
  (
    sliceActions: ContentFilterSliceActions,
    includePublishers: boolean,
    showPurchased: boolean,
    contextProvider?: (state: RootState) => EntityType,
  ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const map: { [key in EntityType]?: (sliceActions: ContentFilterSliceActions) => AppThunk } = {
        Video: () => fetchVideoFilters(sliceActions, includePublishers, showPurchased),
        Survey: () => fetchSurveyFilters(sliceActions, includePublishers, showPurchased),
        Assessment: () => fetchAssessmentsFilters(sliceActions, includePublishers, showPurchased),
        ExternalEvent: () => fetchEventFilters(sliceActions, includePublishers, showPurchased),
        Flow: () => fetchFlowFilters(sliceActions, includePublishers, showPurchased),
        Email: () => fetchEmailFilters(sliceActions, includePublishers, showPurchased),
        Pdf: () => fetchPdfFilters(sliceActions, includePublishers, showPurchased),
      };

      const context = contextProvider ? contextProvider(getState()) : flowInspectorTypeSelectSelector(getState());

      if (!context || !map[context]) {
        return;
      } else {
        await dispatch(map[context](sliceActions));
      }
    };

const templateMethod = (dispatch: AppDispatch, sliceActions: ContentFilterSliceActions) => {
  const { setError, setIsLoading, setFilterOptions } = sliceActions;
  return {
    begin: () => dispatch(setIsLoading(true)),
    success: (options: any) =>
      batch(() => {
        dispatch(setFilterOptions(options));
        dispatch(setIsLoading(false));
      }),
    failure: (error: any) =>
      batch(() => {
        dispatch(setError(error));
        dispatch(setIsLoading(false));
      }),
  };
};

const fetchVideoFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getVideoFilters({ showPurchased, ...templateMethod(dispatch, sliceActions) }, includePublishers);
    };

const fetchSurveyFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getSurveyFilters({ showPurchased, ...templateMethod(dispatch, sliceActions) }, includePublishers);
    };

const fetchAssessmentsFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getAssessmentFilters({ showPurchased, ...templateMethod(dispatch, sliceActions) }, includePublishers);
    };

const fetchEventFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getEventsFilters(templateMethod(dispatch, sliceActions), includePublishers, showPurchased)(dispatch);
    };

const fetchFlowFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getFlowsFilters({ showPurchased, ...templateMethod(dispatch, sliceActions) }, includePublishers);
    };

const fetchEmailFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getEmailFilters(templateMethod(dispatch, sliceActions), includePublishers, showPurchased)(dispatch);
    };

const fetchPdfFilters =
  (sliceActions: ContentFilterSliceActions, includePublishers: boolean, showPurchased: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
      await getPdfsFilters({ showPurchased, ...templateMethod(dispatch, sliceActions) }, includePublishers);
    };
