import { getAxiosError } from "../../../../utils/axiosError";
import { type AppDispatch } from "../../../Application/globaltypes/redux";
import * as graphActions from "./graphSlice";
import { graphReportingService } from "../../services/graphReportingService";
import { type BasePerformanceRequestFilterParams } from "../../../Library/Common/models";

export const fetchTeamsDeviceUsage = (filterParams: BasePerformanceRequestFilterParams & { aggregation: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(graphActions.reqTeamsDeviceUsage());

    try {
      let { data } = await graphReportingService.getTeamsDeviceUsage(filterParams);
      // Temporary fix for while backend fixes issue with
      // empty result returning an empty string instead of
      // empty array
      if (data.length === 0) {
        data = [];
      }
      dispatch(graphActions.setTeamsDeviceUsage({ data }));
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatch(graphActions.setTeamsDeviceUsageError({ errorMessage }));
    }
  };
};
export const fetchTeamsUserActivityCounts = (
  filterParams: BasePerformanceRequestFilterParams & { aggregation: string },
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(graphActions.reqTeamsUserActivityCounts());

    try {
      let { data } = await graphReportingService.getTeamsUserActivityCounts(filterParams);
      // Temporary fix for while backend fixes issue with
      // empty result returning an empty string instead of
      // empty array
      if (data.length === 0) {
        data = [];
      }
      dispatch(graphActions.setTeamsUserActivityCounts({ data }));
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatch(graphActions.setTeamsUserActivityCountsError({ errorMessage }));
    }
  };
};
export const fetchSharepointActivityFileCounts = (
  filterParams: BasePerformanceRequestFilterParams & { aggregation: string },
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(graphActions.reqSharepointActivityFileCounts());

    try {
      const { data } = await graphReportingService.getSharepointActivityFileCounts(filterParams);
      data.sort((a, b) => new Date(a.ReportDate).getTime() - new Date(b.ReportDate).getTime());
      dispatch(graphActions.setSharepointActivityFileCounts({ data }));
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatch(graphActions.setSharepointActivityFileCountsError({ errorMessage }));
    }
  };
};
export const fetchSharepointActivityPages = (
  filterParams: BasePerformanceRequestFilterParams & { aggregation: string },
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(graphActions.reqSharepointActivityPages());

    try {
      const { data } = await graphReportingService.getSharepointActivityPages(filterParams);
      data.sort((a, b) => new Date(a.ReportDate).getTime() - new Date(b.ReportDate).getTime());
      dispatch(graphActions.setSharepointActivityPages({ data }));
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatch(graphActions.setSharepointActivityPagesError({ errorMessage }));
    }
  };
};

export const fetchOnedriveActivityFileCounts = (
  filterParams: BasePerformanceRequestFilterParams & { period: string },
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(graphActions.reqOnedriveActivityFileCounts());

    try {
      const { data } = await graphReportingService.getOnedriveActivityFileCounts(filterParams);
      dispatch(graphActions.setOnedriveActivityFileCounts({ data }));
    } catch (error) {
      const errorMessage = getAxiosError(error);
      dispatch(graphActions.setOnedriveActivityFileCountsError({ errorMessage }));
    }
  };
};
