import { EditLinkButton, DeleteLinkButton } from "../../../../../../components/buttons/linkButtons";
import EllipsisPopupButton from "../../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";

export interface IdentityProviderEllipsisPopupButtonProps {
  isDisabled: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const IdentityProviderEllipsisPopupButton: React.FC<IdentityProviderEllipsisPopupButtonProps> = ({
  onEdit,
  onDelete,
  isDisabled,
  ...ellipsisProps
}) => {
  return (
    <EllipsisPopupButton circle outlinedEllipsis {...ellipsisProps} disabled={isDisabled}>
      <EditLinkButton onClick={onEdit} isDisabled={isDisabled} />
      <DeleteLinkButton onClick={onDelete} isDisabled={isDisabled} />
    </EllipsisPopupButton>
  );
};

export default IdentityProviderEllipsisPopupButton;
