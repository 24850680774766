import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";

export interface PdfBaseState {
  tags: TagsPayloadCamelPlural;
  error?: Error;
}

const initialState: PdfBaseState = {
  tags: {},
  error: undefined,
};

const pdfBaseSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Pdfs}/base`,
  initialState: initialState,
  reducers: {
    fetchTags(state: PdfBaseState, action: PayloadAction<TagsPayloadCamelPlural>) {
      state.tags = action.payload;
    },
    fetchTagsFailure(state: PdfBaseState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    resetTags: (state: PdfBaseState) => {
      state.tags = initialState.tags;
    },
  },
});

export const { fetchTags, fetchTagsFailure, resetTags } = pdfBaseSlice.actions;

export default pdfBaseSlice.reducer;
