export const ADD_BACKGROUND_TASK = "ADD_BACKGROUND_TASK";
export const ADD_BACKGROUND_TASK_DISTRIBUTED_OP = "ADD_BACKGROUND_TASK_DISTRIBUTED_OP";
export const ADD_OR_UPDATE = "ADD_OR_UPDATE";
export const UPDATE_STATUS_OF_BACKGROUND_TASK = "UPDATE_STATUS_OF_BACKGROUND_TASK";
export const UPDATE_PROGRESS_OF_BACKGROUND_TASK = "UPDATE_PROGRESS_OF_BACKGROUND_TASK";
export const UPDATE_ID_OF_BACKGROUND_TASK = "UPDATE_ID_OF_BACKGROUND_TASK";
export const UPDATE_BACKGROUND_TASK_PROPERTIES = "UPDATE_BACKGROUND_TASK_PROPERTIES";

export const DELETE_BACKGROUND_TASK = "DELETE_BACKGROUND_TASK";

export const CANCEL_BACKGROUND_TASK = "CANCEL_BACKGROUND_TASK";

export const DISMISS_COMPLETED_TASKS = "DISMISS_COMPLETED_TASKS";

export const UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK = "UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK";

export const UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK = "UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK";

export const UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK = "UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK";
export const UPDATE_DISTRIBUTED_OP_PROGRESS_OF_BACKGROUND_TASK = "UPDATE_DISTRIBUTED_OP_PROGRESS_OF_BACKGROUND_TASK";
export const UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK = "UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK";

export const SHOW_PROGRESS_DRAWER = "SHOW_PROGRESS_DRAWER";
export const HIDE_PROGRESS_DRAWER = "HIDE_PROGRESS_DRAWER";

export const TRANSIENT_NOTIFICATION_RECEIVED = "TRANSIENT_NOTIFICATION_RECEIVED";
