import cn from "classnames";
import { Label } from "semantic-ui-react";

import "./stepLabel.scss";

interface Props {
  index: number;
  text: string;
  active?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  onClick?: (index: number) => void;
}

const onClickHandler = (props: Props) => {
  const { onClick, index, disabled = false } = props;
  if (!disabled) {
    onClick && onClick(index);
  }
};

const StepLabel: React.FC<Props> = (props) => {
  const { text, className, index, active = false, disabled = false, required = false } = props;

  return (
    <div
      className={cn("step-label", className, { required }, { active }, { disabled })}
      onClick={() => onClickHandler(props)}
      role="tab"
    >
      <Label circular className={active ? "current" : "passive"}>
        {index + 1}
      </Label>
      <div className="step-title-container">
        <div className="step-title">{text}</div>
        {required && <div className="step-required-label">Required</div>}
      </div>
    </div>
  );
};

export default StepLabel;
