import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";
import { Badge } from "../../Badge/Badge";

function TopMenuItem(props) {
  const { label, iconName, notificationsCount } = props;

  return (
    <>
      <Icon className={cn("menu-icon", iconName)} />
      {notificationsCount > 0 && <Badge count={notificationsCount} size="small" />}
      <span className="label">{label}</span>
    </>
  );
}

TopMenuItem.propTypes = {
  label: PropTypes.string,
  iconName: PropTypes.string,
  notificationsCount: PropTypes.number,
};

export default TopMenuItem;
