import { combineReducers } from "@reduxjs/toolkit";

import { type AccountType } from "../../../types";
import type Role from "../../../../../interfaces/role";
import type { GlobalRole } from "../../../../../interfaces/role";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "../../../../Application/slices/createFetchingItemsSlice";
import {
  fetchAccountTypes,
  accountTypesPrefixPayload,
  fetchGlobalRoles,
  fetchChildAccountRoles,
  globalRolesStatePrefixPayload,
  childRolesStatePrefixPayload,
} from "../thunks/createAccountThunk";

const accountTypesState: FetchingItemsState<AccountType> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const accountTypesSlice = createFetchingItemsSlice({
  namePayload: accountTypesPrefixPayload,
  initialState: accountTypesState,
  reducers: {},
  thunk: fetchAccountTypes,
});

const globalRolesState: FetchingItemsState<GlobalRole> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const childRolesState: FetchingItemsState<Role> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const globalRolesSlice = createFetchingItemsSlice({
  namePayload: globalRolesStatePrefixPayload,
  initialState: globalRolesState,
  reducers: {},
  thunk: fetchGlobalRoles,
});

const childRolesSlice = createFetchingItemsSlice({
  namePayload: childRolesStatePrefixPayload,
  initialState: childRolesState,
  reducers: {},
  thunk: fetchChildAccountRoles,
});

const createAccountReducer = combineReducers({
  accountTypes: accountTypesSlice.reducer,
  globalRoles: globalRolesSlice.reducer,
  childRoles: childRolesSlice.reducer,
});

export default createAccountReducer;

export type CreateAccountState = ReturnType<typeof createAccountReducer>;
