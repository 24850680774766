import PropTypes from "prop-types";
import ImageUploaderModal from "../imageUploader/ImageUploaderModal";
import BasePreview from "./basePreview/basePreview";
import { ReactComponent as AddImage } from "../../images/add-image-icon.svg";
import { ReactComponent as AddImageCombined } from "../../images/add-image-combined-icon.svg";
import ImageAspectRatio from "../../enums/imageAspectRatio";
import ValidatedField from "./ValidatedField";

import "./imagePreview.scss";

export function ImagePreview(props) {
  const {
    value,
    className,
    label,
    disabled,
    placeholder,
    editBtnEnabled,
    getImageCustomValidator,
    removeBtnEnabled,
    endpointToGetSasLink,
    onImageChanged,
    aspectRatio,
    isHidden,
    markAsRequired,
    propertyName,
    headerPlaceholder,
    combinedIcon,
    ...fieldProps
  } = props;

  const handleImageChange = async (imageUri, imageName) => {
    const { setFieldValue, shouldValidate } = props;
    await setFieldValue(propertyName, imageUri, shouldValidate);
    onImageChanged && onImageChanged(propertyName, imageName);
  };

  const imagePlaceholderContent = (
    <div className="placeholder">
      {combinedIcon ? (
        <AddImageCombined className="add-image-combined-icon" />
      ) : (
        <AddImage className="add-image-icon" />
      )}
      <span className="header-placeholder">{headerPlaceholder}</span>
      <span>{placeholder}</span>
    </div>
  );

  const onRemove = async () => {
    await handleImageChange("");
  };

  const renderPreview = (onClick) => {
    return (
      <ValidatedField {...fieldProps}>
        <BasePreview
          value={value}
          className={className}
          label={label}
          disabled={disabled}
          isImagePreview
          onClick={onClick}
          onRemove={onRemove}
          placeholder={imagePlaceholderContent}
          aspectRatio={aspectRatio}
          markAsRequired={markAsRequired}
          editBtnEnabled={editBtnEnabled}
          removeBtnEnabled={removeBtnEnabled}
        />
      </ValidatedField>
    );
  };

  const imageUploaderKey = `${propertyName}.imageuploader`;

  return (
    <ImageUploaderModal
      value={value}
      trigger={renderPreview}
      onImageChange={handleImageChange}
      endpointToGetSasLink={endpointToGetSasLink}
      aspectRatio={aspectRatio}
      isHidden={isHidden}
      key={imageUploaderKey}
      index={imageUploaderKey}
      getImageCustomValidator={getImageCustomValidator}
    />
  );
}

ImagePreview.defaultProps = {
  placeholder: "No Thumbnail",
  aspectRatio: ImageAspectRatio.HD_16X9,
  editBtnEnabled: true,
  getImageCustomValidator: null,
  removeBtnEnabled: false,
  combinedIcon: false,
};

ImagePreview.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  propertyName: PropTypes.string,
  setFieldValue: PropTypes.func,
  onImageChanged: PropTypes.func,
  placeholder: PropTypes.node,
  endpointToGetSasLink: PropTypes.string,
  editBtnEnabled: PropTypes.bool,
  getImageCustomValidator: PropTypes.func,
  removeBtnEnabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  aspectRatio: PropTypes.oneOf([ImageAspectRatio.HD_16X9, ImageAspectRatio.SQUARE_1X1]),
  markAsRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
  headerPlaceholder: PropTypes.string,
};

export default ImagePreview;
