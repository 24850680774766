import SortOptions from "../../../../enums/SortOptions";

export interface AccountPacksGridSortingOptions {
  text: string;
  value: SortOptions;
  default: boolean;
}

export const gridSortingOptions: Array<AccountPacksGridSortingOptions> = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
    default: false,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
    default: false,
  },
];
