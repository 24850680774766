import React, { type SyntheticEvent, useEffect } from "react";
import { type DropdownProps } from "semantic-ui-react";
import SortOptions from "../../../../enums/SortOptions";
import { SortingDirection, ViewType } from "../../../../enums";
import SearchInput from "../../../../components/searchInput/SearchInput";
import NoResults from "../../../../components/noResults/NoResults";
import ItemsView from "../../../../views/ItemsView/ItemsView";
import { gridSortingOptions } from "./sortOptions";
import { isEmpty } from "lodash";
import NoSearchResults from "../../../../components/noSearchResults";
import { type SearchState } from "../../../Application/slices/createSearchSlice";
import { type AppDispatch } from "../../../Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import { ItemsTypes } from "../../../../enums/itemsTypes";
import { type PackFilter } from "../../EditAccount/EditPacks/types/models";
import { type Filters } from "../../../../utils/queryUtils";
import { type AssignAccountPack } from "../../../../components/assignmentModals/packAssignmentModal/types";
import AddToAccountPackCard, {
  type Item,
} from "../../../../components/assignmentModals/packAssignmentModal/packAssignmentSteps/packGridStep/addToAccountPackCard/AddToAccountPackCard";
import { PacksFilterForm } from "../../../../components/filterForms";
import { type OverviewState } from "../../../Licensing/Packs/state/slices/availablePacksSlice";
import { resetAppliedFilter } from "../../../Licensing/Packs/state/slices/availablePacksFiltersSlice";
import { type CardsViewerItem } from "../../../../components/cardsViewer/types";
import "./createAccountPacksList.scss";

export interface CreateAccountPacksListProps {
  isReadOnly: boolean;
  loadListItems: (
    assignedAccountId: number,
    ownerAccountId: number,
    skip: number,
    orderBy: string,
    filters?: Filters,
  ) => void;
  loadGridItems: (
    assignedAccountId: number,
    ownerAccountId: number,
    skip: number,
    order: string,
    appliedFilter: Filters,
    searchTerm?: string,
  ) => any;
  resetGridItems: () => void;
  filter: PackFilter;
  packsGrid: OverviewState;
  createdAccountId: number;
  accountId: number;
  searchState?: SearchState;
  setSearch: (term: string) => { payload: string; type: string };
  gridOrderBy: SortOptions;
  setGridOrderBy: (value: any) => void;
  selectedPacks: AssignAccountPack[];
  onSelectedItemsChanged: (ids: number[]) => void;
}

export type CreateAccountPacksListAllProps = CreateAccountPacksListProps & PropsFromRedux;

export function CreateAccountPacksList(props: CreateAccountPacksListAllProps) {
  const [gridDataRequest, setGridDataRequest] = React.useState<{ abort: Function }>();

  const defaultSortingColumnName = "DateCreated";
  const { resetGridItems, setSearch, searchState, gridOrderBy, setGridOrderBy } = props;

  useEffect(() => {
    getPacksGridItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, gridOrderBy]);

  useEffect(() => {
    return () => {
      resetGridItems();
      props.resetPacksFilter();
      setSearch("");
      setGridOrderBy(SortOptions.CreatedDateDesc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPacksGridItems = () => {
    const {
      accountId,
      createdAccountId,
      filter: { appliedFilter },
    } = props;
    if (createdAccountId === 0) {
      return;
    }
    gridDataRequest && gridDataRequest.abort();
    const loadedPacksCount = props.packsGrid.items.length;

    const request = props.loadGridItems(
      createdAccountId,
      accountId,
      loadedPacksCount,
      gridOrderBy,
      appliedFilter,
      searchState?.term,
    );
    setGridDataRequest(request);
  };

  const isFiltered = !isEmpty(searchState?.term);

  const noResultsContent = () =>
    isFiltered ? (
      <NoSearchResults />
    ) : (
      <NoResults
        title="No packs for this account"
        description={
          <span>
            You don't have any packs that can be added to this account.
            <br />
          </span>
        }
        iconClassName="fa-box-full"
      />
    );

  const onSortChange = (_: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    resetGridItems();
    setGridOrderBy(data.value as SortOptions);
  };

  const onSearchChange = (search: string) => {
    resetGridItems();
    setSearch(search);
  };

  const renderCard = (props: CardsViewerItem<Item>) => <AddToAccountPackCard {...props} />;

  const { selectedPacks, filter, packsGrid } = props;
  return (
    <ItemsView
      className="account-packs-list"
      viewType={ViewType.GRID}
      renderCard={renderCard}
      getData={getPacksGridItems}
      itemsInlineCount={packsGrid.itemsCount}
      isLoading={packsGrid.isLoading}
      isAllDataLoaded={packsGrid.areAllLoaded}
      items={packsGrid.items}
      itemsType={ItemsTypes.Pack}
      sortingColumnName={defaultSortingColumnName}
      sortingDirection={SortingDirection.Ascending}
      noResultsContent={noResultsContent()}
      withSelection
      renderSearch={() => (
        <SearchInput placeholder="Search for packs..." onChange={onSearchChange} defaultValue={searchState?.term} />
      )}
      sortOptions={gridSortingOptions}
      blur
      onSortChange={onSortChange}
      doNotLoadPersistentViewType
      hideListGridViewSwitcherButton
      onSelectedItemsChanged={props.onSelectedItemsChanged}
      selectedIds={selectedPacks.map((pack) => pack.packId)}
      renderFilterForm={() => <PacksFilterForm />}
      filterOptions={filter.filterOptions}
      resetFilter={filter.resetFilter}
      appliedFilter={filter.appliedFilter}
      filterOptionsLoading={filter?.isLoading}
      applyFilter={filter.applyFilter}
      getFilterOptions={filter.fetchFilterOptions}
    />
  );
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetPacksFilter: () => dispatch(resetAppliedFilter()),
  };
};

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(CreateAccountPacksList);

export default ConnectedComponent;
