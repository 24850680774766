import { useCallback } from "react";
import { type IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import { type FlowValidationError } from "../../validator/flowValidatorReducer";
import useErrorNavigationUtils from "./useErrorNavigationUtils";
import useHandleTransform from "./useHandleTransform";
import { useOnViewportChange } from "reactflow";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useErrorNavigationActions } from "../Providers/hooks/useErrorNavigationActions";

const TRANSITION_TIME = 1000;

let setInvalidElementTimeout: ReturnType<typeof setTimeout>;

export default function useBaseErrorHandler() {
  const { setCurrentError } = useErrorNavigationActions();
  const { setElementInvalid } = useReactFlowCanvasActions();
  const { visibleNodes, getItemId, getTargetNode, refreshViewPort } = useErrorNavigationUtils();
  const { handleTransform } = useHandleTransform(refreshViewPort);

  useOnViewportChange({
    onEnd: useCallback(() => refreshViewPort(), [refreshViewPort]),
  });

  return useCallback(
    (error: FlowValidationError) => {
      const errorNodeId = getItemId(error);

      if (!errorNodeId) {
        return;
      }

      const nodeFilter = (node: IFlowNode) => node.id === errorNodeId;
      const shouldNavigate = !visibleNodes.find(nodeFilter);
      const targetNode = getTargetNode(errorNodeId);

      if (shouldNavigate && targetNode && targetNode?.position) {
        const transform = { x: targetNode?.position?.x, y: targetNode?.position?.y };
        handleTransform(transform);
        if (setInvalidElementTimeout) {
          clearTimeout(setInvalidElementTimeout);
        }
        setInvalidElementTimeout = setTimeout(() => {
          setElementInvalid(errorNodeId, error);
          setCurrentError(error);
        }, TRANSITION_TIME);
      } else {
        setElementInvalid(errorNodeId, error);
        setCurrentError(error);
      }
    },
    [visibleNodes, setCurrentError, getItemId, getTargetNode, handleTransform, setElementInvalid],
  );
}
