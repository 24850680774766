import * as Yup from "yup";

const stringLengths = {
  min: 1,
  utmMin: 3,
  titleMax: 128,
  headingMax: 256,
  bodyMax: 2048,
  callToActionMax: 64,
  destinationUrlMax: 2048,
};

export const messageInfo = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Title is required")
    .min(stringLengths.min, `Title must have at least ${stringLengths.min} characters`)
    .max(stringLengths.titleMax, `Title must be maximum of ${stringLengths.titleMax} characters long`),
  utmCampaign: Yup.string()
    .trim()
    .required("Campaign Name is required")
    .min(stringLengths.utmMin, `Campaign Name must have at least ${stringLengths.utmMin} characters`)
    .max(stringLengths.titleMax, `Campaign Name must be maximum of ${stringLengths.titleMax} characters long`),
});

export const messageContent = Yup.object().shape({
  headline: Yup.string()
    .trim()
    .required("Heading is required")
    .min(stringLengths.min, `Heading must have at least ${stringLengths.min} characters`)
    .max(stringLengths.headingMax, `Heading must be maximum of ${stringLengths.headingMax} characters long`),
  imageUrl: Yup.string().required("Image Cap is required"),
  body: Yup.string()
    .required("Body is required")
    .min(stringLengths.min, `Body must have at least ${stringLengths.min} characters`)
    .max(stringLengths.bodyMax, `Body must be maximum of ${stringLengths.bodyMax} characters long`),
  callToAction: Yup.string()
    .required("Call to Action is required")
    .min(stringLengths.min, `Call to Action must have at least ${stringLengths.min} characters`)
    .max(
      stringLengths.callToActionMax,
      `Call to Action must be maximum of ${stringLengths.callToActionMax} characters long`,
    ),
  destinationUrl: Yup.string()
    .required("Destination URL is required")
    .url("Destination must be valid URL")
    .min(stringLengths.min, `Destination URL must have at least ${stringLengths.min} characters`)
    .max(
      stringLengths.destinationUrlMax,
      `Destination URL must be maximum of ${stringLengths.destinationUrlMax} characters long`,
    ),
});
