import { batch } from "react-redux";
import { AlertTypes } from "../../../../enums";
import { type Converter } from "../../../../interfaces/functionTypes/Converter";
import { type Dispatcher, type MidnightActionPayload } from "../../../../interfaces/redux";
import { sendTransientNotification } from "../../../Notifications/state/notificationsActions";
import {
  updateSucceededTaskOperation,
  updateFailedTaskOperation,
} from "../../../BackgroundTasks/state/backgroundTasksActions";
import { type Action, type PayloadActionCreator, type PrepareAction } from "@reduxjs/toolkit";

export interface TaskOperationPayload {
  messageId: string;
  entityId?: number;
  label?: string;
}

type TaskOperationAction = (messageId: string, entityId?: number, label?: string) => Action;

export const createDispatcher = (dispatcher: Dispatcher) => {
  const dispatch =
    <P, PA extends PrepareAction<P> = PrepareAction<any>>(action: PayloadActionCreator<P, string, PA>) =>
    (message: P) => {
      dispatcher(action(message));
    };

  const dispatchMany =
    <P, PA extends PrepareAction<P> = PrepareAction<any>>(actions: Array<PayloadActionCreator<P, string, PA>>) =>
    (message: P) => {
      batch(() => {
        actions.forEach((action) => {
          dispatcher(action(message));
        });
      });
    };

  const dispatchMap =
    <TMessage, TMap>(action: PayloadActionCreator<TMap>, converter: Converter<TMessage, TMap>) =>
    (message: TMessage) => {
      dispatcher(action(converter(message)));
    };

  const dispatchPublishSuccess =
    <TMessage extends { id: number }>(
      action: PayloadActionCreator<MidnightActionPayload>,
      messageProvider: (message: TMessage) => string,
    ) =>
    (message: TMessage) => {
      batch(() => {
        dispatcher(action({ entityId: message.id }));
        dispatcher(
          sendTransientNotification({
            type: AlertTypes.success,
            message: messageProvider(message),
          }),
        );
      });
    };

  const dispatchTaskOperationSuccess = <TMessage>(converter: Converter<TMessage, TaskOperationPayload>) =>
    dispatchTaskOperationAction(converter, updateSucceededTaskOperation);

  const dispatchTaskOperationFailure = <TMessage>(converter: Converter<TMessage, TaskOperationPayload>) =>
    dispatchTaskOperationAction(converter, updateFailedTaskOperation);

  const dispatchTaskOperationAction =
    <TMessage>(converter: Converter<TMessage, TaskOperationPayload>, action: TaskOperationAction) =>
    (message: TMessage) => {
      const payload = converter(message);
      dispatcher(action(payload.messageId, payload.entityId, payload.label));
    };

  const dispatchAlert =
    (type: AlertTypes, message: string, dismissInSeconds: number = 3) =>
    () =>
      dispatcher(sendTransientNotification({ type, message }, dismissInSeconds));

  const dispatchAlertMap =
    <TRtnMessage>(type: AlertTypes, messageProvider: Converter<TRtnMessage, string>, dismissInSeconds: number = 3) =>
    (rtnMessage: TRtnMessage) =>
      dispatcher(sendTransientNotification({ type, message: messageProvider(rtnMessage) }, dismissInSeconds));

  return {
    dispatch,
    dispatchMany,
    dispatchMap,
    dispatchPublishSuccess,
    dispatchTaskOperationSuccess,
    dispatchTaskOperationFailure,
    dispatchAlert,
    dispatchAlertMap,
    dispatchBatchOperationSuccess: dispatchTaskOperationSuccess<{ messageId: string }>((data) => data),
    dispatchBatchOperationFailure: dispatchTaskOperationFailure<{ messageId: string }>((data) => data),
  };
};
