import * as rolesOverviewActionTypes from "./editUserRolesActionTypes";
import { fetchUserEditRoles } from "../../../state/peopleActions";

const fetchRoles = (
  userId: number,
  skip: number,
  top: number,
  orderProp: string,
  orderDirection: string,
  filter: { [key: string]: any },
) =>
  fetchUserEditRoles(
    userId,
    { skip, top, orderProp, orderDirection, filter },
    rolesOverviewActionTypes.fetchUserEditRolesItemsActions,
  );

export { fetchRoles };
