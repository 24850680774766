import { useEffect, useState, useCallback, type ReactNode } from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import cn from "classnames";
import DraggableListItem from "../DraggableListItem/DraggableListItem";
import { RemovableCard } from "../../cards";
import type DragNDropItemType from "../../../enums/dragNDropItemTypes";
import { type DraggableAsset, type DraggableDroppedAsset, type DroppedItem } from "../../../interfaces/assetToDropInfo";
import EventTypes from "../../../enums/eventTypes";
import { getAssets, mapToDragItem } from "./utils";

import "./dropItemsList.scss";
import AssetTypes from "../../../enums/assetTypes";

export interface Props {
  acceptType: DragNDropItemType;
  items: DroppedItem[];
  displayItemsCount: number;
  onDropFailed: (message?: string) => void;
  addItems?: (assets: DroppedItem[]) => void;
  removeItem?: (id: number, type?: string) => void;
  isReadonly?: boolean;
  isDraggingDisabled?: boolean;
  noResults?: ReactNode;
  selectedCards: DraggableAsset[];
  resetSelectedCards: () => void;
}

const addIndexes = (items: DroppedItem[]) => {
  return items.map((asset, index) => {
    return { ...asset, index: index };
  });
};

export default function DropItemsList(props: Props) {
  const {
    items,
    removeItem,
    isReadonly,
    acceptType,
    isDraggingDisabled,
    displayItemsCount,
    noResults,
    selectedCards,
    resetSelectedCards,
  } = props;
  const [cards, setCards] = useState(addIndexes(items));

  /* istanbul ignore next */
  const [{ isOver }, drop] = useDrop<DraggableDroppedAsset, any, any>(() => {
    return {
      accept: acceptType,
      drop: (item: DraggableDroppedAsset) => {
        if (item.index === undefined) {
          let draggedItems: DraggableAsset[] = selectedCards.length
            ? selectedCards.map(mapToDragItem)
            : [mapToDragItem(item)];

          const haveDuplicatedItems = draggedItems.some((asset) =>
            cards.some((c) => c.id === asset.id && c.type === asset.type),
          );

          const [assets, message] = getAssets(haveDuplicatedItems, draggedItems, cards);

          if (haveDuplicatedItems) {
            props.onDropFailed(message);
          }
          if (assets.length) {
            props.addItems?.(assets);
            resetSelectedCards();
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    };
  }, [props.selectedCards, cards]);

  useEffect(() => {
    setCards(addIndexes(items));
  }, [items]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (isDraggingDisabled) {
        return;
      }

      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [cards, isDraggingDisabled],
  );

  const createDescription = (type?: string) => {
    switch (type) {
      case EventTypes.ExternalEvent:
        return "Event";
      case AssetTypes.Pdf:
        return "PDF";
      default:
        return type;
    }
  };

  if (!items.length) {
    return (
      <div ref={drop} className={cn("drop-items-list", { "hover-over": isOver })}>
        {noResults ?? null}
      </div>
    );
  }

  return (
    <div ref={drop} className={cn("drop-items-list", { "hover-over": isOver })}>
      {cards.slice(0, displayItemsCount).map((item: DroppedItem, index: number) => {
        return isReadonly ? (
          <RemovableCard
            id={item.id}
            title={item.title}
            type={item.type}
            thumbnailUrl={item.thumbnailUrl}
            key={item.id.toString() + (item.type ?? "")}
            meta={createDescription(item.type)}
          />
        ) : (
          <DraggableListItem
            id={item.id}
            type={item.type}
            title={item.title}
            thumbnailUrl={item.thumbnailUrl}
            meta={createDescription(item.type)}
            index={index}
            moveCard={moveCard}
            key={item.id.toString() + (item.type ?? "")}
            onRemoveItemClick={removeItem}
            acceptType={acceptType}
            isReadOnly={item.isReadOnly}
          />
        );
      })}
    </div>
  );
}
