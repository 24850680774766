import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type AssignedUser } from "../../../../../interfaces";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

export interface EventUsersToAddState extends FetchingItemsState<AssignedUser> {}

const initialState: EventUsersToAddState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const eventUsersToAddSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Events,
    name: "usersToAdd",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = eventUsersToAddSlice.actions;

export default eventUsersToAddSlice.reducer;
export type eventUsersToAddSliceStateType = ReturnType<typeof eventUsersToAddSlice.reducer>;
