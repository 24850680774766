import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withEntityState } from "../../../../../Application/reducers/hoc/withEntityState";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Emails;

const emailEntityStateReducer = withEntityState(namespace, entityPrefix);

export type EmailEntityState = ReturnType<typeof emailEntityStateReducer>;

export default emailEntityStateReducer;
