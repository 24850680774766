import { type FC } from "react";

import { ContentType, type PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { type IVideoAssignmentModelItem } from "../../../../features/People/types";
import PeopleVideoCard from "../../../cards/VideoCard/People/PeopleVideoCard";
import { type VideoCardBaseProps } from "../../../cards/VideoCard/types";
import getCardPriorityIcon from "../../priority/getCardPriorityIcon";

import "./peopleOverviewVideoCard.scss";

export interface PeopleVideoCardProps extends VideoCardBaseProps<IVideoAssignmentModelItem> {
  peopleType: PeopleType;
  showExpirationDate?: boolean;
  showAdded?: boolean;
  deepLink?: boolean;
}

const PeopleOverviewVideoCard: FC<PeopleVideoCardProps> = (props: PeopleVideoCardProps) => {
  const {
    item,
    disabled,
    peopleType,
    selected,
    onSelect,
    showExpirationDate,
    handlers,
    disablePopupMenu,
    showAdded,
    selectable,
    deepLink,
  } = props;

  const icons = [
    getCardPriorityIcon({
      peopleType: peopleType,
      contentType: ContentType.Video,
      priority: item.priorityLevelId,
      dueDate: item.dueDate,
      tooltipClass: "people-video-priority-tooltip",
      showNone: false,
    }),
  ];

  return (
    <PeopleVideoCard
      hasDeepLink={deepLink}
      item={item}
      selected={selected}
      onSelect={onSelect}
      showExpirationDate={showExpirationDate}
      className="people-video-card"
      icons={icons}
      disabled={item.inherited || !!disabled}
      disablePopupMenu={disablePopupMenu}
      handlers={handlers}
      showAdded={showAdded}
      selectable={selectable}
    />
  );
};

export default PeopleOverviewVideoCard;
