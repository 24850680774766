import { type FC, useEffect, useRef } from "react";
import { batch, connect, type ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { bindAction } from "../../../../../../../interfaces/redux";

import UsersListContainer from "./UsersListContainer/UsersListContainer";
import Restricted from "../../../../../../Application/Restricted";
import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";

import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import {
  AddAllFlowUserSuccess,
  RemoveAllFlowUserSuccess,
  FlowStartAllSuccess,
  UserFlowPriorityChangedSuccess,
  SetAllFlowUserPrioritiesSuccess,
  RemoveAllFlowUserPrioritiesSuccess,
} from "../../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import { fetchFlowUsers } from "../../../../state/thunks/flowUsersThunk";
import { resetSelectedItems } from "../../../../state/slices/flowUsersSlice";
import { type PeopleAssignmentsHandlers } from "../../../../../PeopleAssignments/types";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { SearchInput } from "../../../../../../../components";
import { getFlowUserColumnOptions, FlowColumnToParamMap, FlowUserAssignmentColumns } from "./helpers/getColumnOptions";
import { startFlowAction } from "../../../../state/actions/flowPeopleAssignmentsActions";
import { flowInformationSelector, flowsStateSelector } from "../../../../state/selectors";
import { resetAppliedFilter, resetSearch, setSearch } from "../../../../state/slices/flowUserFiltersSlice";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface UsersOverviewPropsBase extends PeopleAssignmentsHandlers {
  flowId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = ({
  flowId,
  customHeaderContent,
  fetchUsers,
  search,
  setSearch,
  onUnmount,
  onRemove,
  onEditPriority,
  startFlow,
  canAutoStart,
  filtered,
}) => {
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder={"Search for People..."} onChange={onSearchChange} defaultValue={search} />
  );

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = async (searchTerm: string) => {
    setSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  const getFlowUsers = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = FlowUserAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = FlowColumnToParamMap[orderBy.toLowerCase() as Lowercase<FlowUserAssignmentColumns>];
    fetchUsers({ entityId: flowId, skip, top, sortingColumn, sortingDirection, filter: appliedFilter, term: search });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.FlowsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={(hasPermission) => (
        <UsersListContainer
          defaultSortingColumnName={FlowUserAssignmentColumns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getFlowUserColumnOptions({
            readonly: !hasPermission,
            onRemove: onRemove,
            onEditPriority: onEditPriority,
            startFlow: (userIds: number[]) => startFlow(flowId, userIds),
            isSortable: false,
            canAutoStart: canAutoStart,
          })}
          // @ts-ignore
          getFilterForm={undefined}
          fetchData={getFlowUsers}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          listViewRtnEvents={[
            AddAllFlowUserSuccess,
            RemoveAllFlowUserSuccess,
            RemoveAllFlowUserPrioritiesSuccess,
            SetAllFlowUserPrioritiesSuccess,
            FlowStartAllSuccess,
            UserFlowPriorityChangedSuccess,
          ]}
          isSelectDisabled={() => !hasPermission}
          setReloadListItems={createReloadListItems}
          noResultsContent={<ContentUsersNoResults filtered={filtered || !!search} />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  search: flowsStateSelector(state).edit.userFilters.search,
  canAutoStart: flowInformationSelector(state).info.canAutoStart,
  filtered: !isEmpty(flowsStateSelector(state).edit.userFilters.appliedFilter),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindAction(fetchFlowUsers, dispatch),
  setSearch: bindAction(setSearch, dispatch),
  startFlow: bindAction(startFlowAction, dispatch),
  onUnmount: () => {
    batch(() => {
      dispatch(resetSelectedItems());
      dispatch(resetAppliedFilter());
      dispatch(resetSearch());
    });
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UsersOverview);
export default ConnectedComponent;
