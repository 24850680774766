import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/globaltypes/redux";
import { type ContractType } from "../../types";

export interface ContractTypesState {
  items: ContractType[];
  isLoading: boolean;
  error: string | undefined;
}

const initialState: ContractTypesState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const contractTypesSlice = createSlice({
  name: "contractTypes",
  initialState: initialState,
  reducers: {
    req(state: ContractTypesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: ContractTypesState, action: PayloadAction<ContractType[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: ContractTypesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = contractTypesSlice.actions;

export const contractTypesSelector = (state: RootState) => state.accounts.contractTypes.items;
export const contractTypesLoadingSelector = (state: RootState) => state.accounts.contractTypes.isLoading;

export default contractTypesSlice.reducer;
