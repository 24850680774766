import { type FC } from "react";
import { Table } from "semantic-ui-react";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { type PackOverview } from "../../../features/Licensing/Packs/types/state";
import { PackTitleCell } from "./packTitleCell/PackTitleCell";
import { issueLicensePermissionPredicate } from "features/People/Groups/EditGroup/issueLicensePermissionPredicate";
import { RemoveLicenseButton } from "./removeLicenseButton/RemoveLicenseButton";
import styles from "./packListRow.module.scss";
import { getColumnOptions } from "./getGroupPackColumnOptions";

export interface GroupPackListRowProps {
  pack: PackOverview;
  url: string;
  onRemoveLicenseClick: (id: number) => void;
  licenseRemovalEnabled: boolean;
  isSelected: boolean;
}

export const GroupPackListRow: FC<GroupPackListRowProps> = ({
  pack,
  url,
  onRemoveLicenseClick,
  licenseRemovalEnabled,
  isSelected,
}) => {
  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <PackTitleCell url={url} pack={pack} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{dateTimeUtils.formatDate(pack.licenseIssued)}</Table.Cell>
      {licenseRemovalEnabled && (
        <Table.Cell className={styles["align-right"]} width={columnOptions[2].width}>
          <RemoveLicenseButton
            id={pack.id}
            isSelected={isSelected}
            onRemoveLicenseClick={onRemoveLicenseClick}
            issueLicensePermissionPredicate={issueLicensePermissionPredicate}
          />
        </Table.Cell>
      )}
    </>
  );
};

export default GroupPackListRow;
