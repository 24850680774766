import { keyBy } from "lodash";
import * as infoActionTypes from "../actionTypes/infoActionTypes";
import * as flowActionTypes from "../actionTypes/flowActionTypes";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";

const initialState = {
  info: {
    title: "",
    description: "",
    thumbnailUrl: "",
    labels: {},
    softwareApplications: {},
    packs: [],
    isEditable: true,
    updateType: 0,
  },
  isLoadingInfo: false,
  isInfoLoaded: false,
  isSavingInfo: false,
  error: null,
};

const mapTags = (tags) => keyBy(tags, "title");

function normalizeFlowInfo(flowInfo) {
  const normalizedFlowInfo = {};

  const flowInfoPropertyNormalizers = {
    labels: mapTags,
    softwareApplications: mapTags,
    dateModified: dateTimeUtils.normalizeUtcDate,
    packs: (packs) => packs.map((pack) => ({ title: pack.name, id: pack.id })),
  };

  for (const propName of Object.getOwnPropertyNames(flowInfo)) {
    normalizedFlowInfo[propName] = flowInfoPropertyNormalizers.hasOwnProperty(propName)
      ? flowInfoPropertyNormalizers[propName](flowInfo[propName])
      : flowInfo[propName];
  }

  return normalizedFlowInfo;
}

const infoReducer = (currentState, action) => {
  const state = currentState || initialState;
  const { type, payload } = action;
  switch (type) {
    case infoActionTypes.FLOW_SAVE_INFO_SUCCESS: {
      return {
        ...state,
        info: {
          ...state.info,
          ...payload.info,
        },
        isSavingInfo: false,
      };
    }

    case infoActionTypes.FLOW_SAVE_INFO_PROPERTY: {
      return {
        ...state,
        info: {
          ...state.info,
          [payload.info.property]: payload.info.value,
        },
      };
    }

    case infoActionTypes.FLOW_RESET_INFO: {
      return { ...initialState };
    }

    case infoActionTypes.FLOW_FETCH_INFO_BEGIN: {
      return {
        ...state,
        isLoadingInfo: true,
        error: null,
      };
    }

    case infoActionTypes.FLOW_FETCH_INFO_SUCCESS: {
      return {
        ...state,
        info: normalizeFlowInfo(payload.info),
        isLoadingInfo: false,
        isInfoLoaded: true,
      };
    }

    case flowActionTypes.PROCESS_EXTERNAL_FLOW_UPDATE: {
      const currentFlowId = state.info.id;
      const updatedFlowId = action.payload.id;

      if (updatedFlowId === currentFlowId) {
        return {
          ...state,
          info: {
            ...state.info,
            ...normalizeFlowInfo(action.payload.info),
          },
        };
      }

      return state;
    }

    case infoActionTypes.FLOW_FETCH_INFO_FAILURE: {
      return {
        ...state,
        isLoadingInfo: false,
        error: payload.error,
      };
    }

    case infoActionTypes.FLOW_UPDATE_INFO_BEGIN:
    case infoActionTypes.FLOW_SAVE_INFO_BEGIN: {
      return {
        ...state,
        isSavingInfo: true,
        error: null,
      };
    }

    case infoActionTypes.FLOW_UPDATE_INFO_SUCCESS: {
      // onBlur action.payload.info do not have isDraft, so it is made to not erase this field
      return {
        ...state,
        info: {
          ...state.info,
          ...normalizeFlowInfo(payload.info),
        },
        isSavingInfo: false,
      };
    }

    case infoActionTypes.FLOW_UPDATE_INFO_FAILURE:
    case infoActionTypes.FLOW_SAVE_INFO_FAILURE: {
      return {
        ...state,
        isSavingInfo: false,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};

export default infoReducer;
