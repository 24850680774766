import { PropTypes } from "prop-types";
import { Header, Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

import Modal from "../Modal";
import ModalSizes from "../../../enums/modalSizes";

import "./actionConfirmationModal.scss";

export default function ActionConfirmationModal(props) {
  const {
    onCancel,
    onContinue,
    title,
    size,
    children,
    open,
    withWarningLabel,
    confirmLabel,
    customModalActions,
    confirmButtonTestId,
  } = props;

  const renderModalActions = (closeModal) => {
    return (
      <>
        <Button
          basic
          color="blue"
          content="Cancel"
          onClick={() => {
            onCancel && onCancel();
            closeModal();
          }}
        />
        <Button
          primary
          content={confirmLabel || "Continue"}
          onClick={() => {
            onContinue && onContinue();
            closeModal();
          }}
          data-testid={confirmButtonTestId || "confirm-button"}
        />
      </>
    );
  };

  return (
    <Modal
      title={title || ""}
      open={open}
      size={size}
      onCancel={onCancel}
      renderModalActions={customModalActions || renderModalActions}
    >
      {withWarningLabel && (
        <Header icon={<Icon className="fa-exclamation-circle" />} color="yellow" as="h5" content="Warning!" />
      )}
      {children}
    </Modal>
  );
}

ActionConfirmationModal.defaultProps = {
  size: ModalSizes.tiny,
  withWarningLabel: true,
};

ActionConfirmationModal.protoTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  withWarningLabel: PropTypes.bool,
  confirmLabel: PropTypes.string,
  customModalActions: PropTypes.func,
  confirmButtonTestId: PropTypes.string,
};
