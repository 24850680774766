import { Card } from "semantic-ui-react";
import cn from "classnames";

import "./flowDesignerSectionHeaderCard.scss";
import { Tooltip } from "../../common/tooltip";
import { TextDotDotDot } from "../../textTruncators/TextTruncators";
import { ReactComponent as SectionHeaderIcon } from "../../../images/section-header-icon.svg";

export interface Props {
  id: string;
  title: string;
  className?: string;
}

function FlowDesignerSectionHeaderCard(props: Props) {
  const { id, title, className } = props;

  return (
    <Card
      key={id}
      className={cn("flow-designer-section-header-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-section-header-card"
      data-hook-id={id}
    >
      <div className="thumbnail">
        <SectionHeaderIcon />
      </div>
      <div className="type">
        <Tooltip target={<TextDotDotDot clamp={2}>{title}</TextDotDotDot>} content={title} />
      </div>
    </Card>
  );
}

export default FlowDesignerSectionHeaderCard;
