import { connect } from "react-redux";
import GenericItemsView, { type GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { type AppDispatch, type RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/videoGroupsSlice";
import { type VideoGroup } from "../../../../../types";
import { videosStateSelector } from "../../../../../state/selectors";

const mapStateToProps = (state: RootState) => {
  const edit = videosStateSelector(state).edit;
  return {
    items: edit.groups.items,
    isLoading: edit.groups.isLoading,
    dataCount: edit.groups.itemsCount,
    selectedIds: edit.groups.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: (ids: number[]) => dispatch(selectedItemsChanged(ids)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<VideoGroup>) => React.ReactElement);
