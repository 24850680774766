import React, { type ReactElement } from "react";
import {
  DraggableEventCard,
  DraggableFlowCard,
  EventToDropCard,
  FlowToDropCard,
  StaticCommunicationCard,
} from "../../../../../components";
import { RolePermissions } from "../../../../../enums";
import { type DesignerContextSwitcherOption } from "../../../../Library/Flows/Designer/FlowComponentsPanel/contentView/PanelComponent/helpers/models";
import DraggableAssetCard from "../../../../Library/Flows/Designer/FlowComponentsPanel/dragndropCards/DraggableAssetCard";
import StaticAssetCard from "../../../../Library/Flows/Designer/FlowComponentsPanel/dragndropCards/StaticAssetCard";
import { assetsOptions } from "./assetsOptions";
import { type CardsViewerItem } from "../../../../../components/cardsViewer/types";
import { communicationsOptions } from "./communicationsOptions";
import DraggableCommunicationCard from "../../../../Library/Flows/Designer/FlowComponentsPanel/dragndropCards/DraggableCommunicationCard";

interface TabsOptions {
  label: string;
  filtersId: string;
  tabId: string;
  permissions: RolePermissions[];
  filterOptions?: DesignerContextSwitcherOption[];
  renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) => ReactElement;
}

export const getContentViewTabsOptions = (): TabsOptions[] => [
  {
    label: "Assets",
    filtersId: "pack-assets-to-drop-filter",
    tabId: "add-assets-to-pack-panel",
    permissions: [RolePermissions.AssetsView],
    filterOptions: assetsOptions,
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <StaticAssetCard {...props.item} /> : <DraggableAssetCard {...props.item} />,
  },
  {
    label: "Flows",
    filtersId: "pack-flows-to-drop-filter",
    tabId: "add-flows-to-pack-panel",
    permissions: [RolePermissions.FlowsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <FlowToDropCard {...props.item} /> : <DraggableFlowCard {...props.item} />,
  },
  {
    label: "Communications",
    filtersId: "pack-communications-to-drop-filter",
    tabId: "add-communications-to-pack-panel",
    filterOptions: communicationsOptions(),
    permissions: [RolePermissions.CommunicationsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <StaticCommunicationCard {...props.item} /> : <DraggableCommunicationCard {...props.item} />,
  },
  {
    label: "Events",
    filtersId: "pack-events-to-drop-filter",
    tabId: "add-events-to-pack-panel",
    permissions: [RolePermissions.EventsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <EventToDropCard {...props.item} /> : <DraggableEventCard {...props.item} />,
  },
];
