import { type ActionCreatorWithoutPayload, type ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { type AxiosResponse } from "axios";
import { type ContentResponse } from "features/Application/services/dataServices/contentDataService";
import { assessmentFilterTypes, type PublishedAssessmentOverview } from "features/Library/Assessments/types/state";
import { emailFilterTypes } from "features/Library/Communications/Emails/types/models";
import { messageFilterTypes } from "features/Library/Communications/Messages/types/models";
import { eventFilterTypes } from "features/Library/Events/types/state";
import { flowFilterTypes } from "features/Library/Flows/types/models";
import { pdfFilterTypes } from "features/Library/Pdfs/types/models";
import surveysDataService from "features/Library/Surveys/services/surveysDataService";
import {
  type PublishedSurveyOverview,
  surveyFilterTypes,
  type SurveyPublishedQuestion,
} from "features/Library/Surveys/types/models";
import { videoFilterTypes } from "features/Library/Videos/types/models";
import { type BranchingQuestion } from "interfaces";
import { type ExternalEvent, type PdfAsset, type VideoAsset } from "interfaces/assetToDropInfo";
import { SortingDirection } from "../enums";
import AssetTypes from "../enums/assetTypes";
import CommunicationTypes from "../enums/communicationTypes";
import EventTypes from "../enums/eventTypes";
import { FlowTypes } from "../enums/flowTypes";
import { type AppDispatch, type AppThunk, type RootState } from "../features/Application/globaltypes/redux";
import DataService from "../features/Application/services/dataServices/typedDataService";
import { type EntityType, type ItemsToDropBase } from "../features/Library/Flows/Designer/types";
import { packContentTypeSelectSelector } from "../features/Licensing/Packs/state/selectors";
import { type FetchActionPayload } from "../interfaces/redux";
import { formatFilters } from "./filterMapUtils";
import { type FiltersMap } from "./filterUtils";

let itemsToDropAbortController = new AbortController();

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadItemsCount = Number(process.env.REACT_APP_LOAD_ITEMS_COUNT);

export interface ItemsToDropPayload {
  skip: number;
  showPurchased: boolean;
  sortBy?: string;
  sortOrder?: string;
  term?: string;
  appliedFilters?: FiltersMap;
}

const defaultPayload = {
  skip: 0,
  showPurchased: true,
  term: "",
  appliedFilters: {},
  sortBy: "dateCreated",
  sortOrder: SortingDirection.Descending,
};

export const getPayload = (payload: ItemsToDropPayload) => ({ ...defaultPayload, ...payload});

export const fetchVideoItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { skip, sortBy, sortOrder, appliedFilters, showPurchased, term } = getPayload(payload);

  const request = {
    top: loadItemsCount,
    skip,
    showPurchased,
    term,
    sortBy,
    sortOrder,
    filters: formatFilters(appliedFilters, videoFilterTypes),
    contentType: "video",
    published: true,
  } as const;

  return doFetch(
    (abortController: AbortController) => DataService.content.getContent(request, abortController.signal),
    AssetTypes.Video,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
    (a): VideoAsset => ({
      ...a,
      thumbnailUrl: a.bag.thumbnailUrl,
      cardType: AssetTypes.Video,
      durationInSeconds: a.bag.duration,
      fileName: a.title,
      description: "",
      type: AssetTypes.Video,
      ownerId: a.ownerId,
    }),
  );
};

export const fetchAssessmentsItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { skip, sortBy, sortOrder, appliedFilters, showPurchased, term } = getPayload(payload);
  const request = {
    skip,
    term,
    top: loadItemsCount,
    sortBy,
    sortOrder,
    filters: formatFilters(appliedFilters, assessmentFilterTypes),
    showPurchased,
    published: true,
    contentType: "assessment",
  } as const;

  return doFetch(
    (abortController: AbortController) => DataService.content.getContent(request, abortController.signal),
    AssetTypes.Assessment,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
    (a): PublishedAssessmentOverview => {
      return {
        ...a,
        cardType: AssetTypes.Assessment,
        questionsCount: a.bag.questionsCount,
      };
    },
  );
};

export const fetchPdfsItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { skip, sortBy, sortOrder, appliedFilters, showPurchased, term } = getPayload(payload);
  const request = {
    skip,
    showPurchased,
    sortBy,
    sortOrder,
    term,
    top: loadItemsCount,
    filters: formatFilters(appliedFilters, pdfFilterTypes),
    published: true,
    contentType: "pdf",
  } as const;

  return doFetch(
    (abortController) => DataService.content.getContent(request, abortController.signal),
    AssetTypes.Pdf,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
    (a): PdfAsset => ({
      ...a,
      thumbnailUrl: a.bag.thumbnailUrl!,
      cardType: AssetTypes.Pdf,
      fileName: a.title,
      description: "",
      ownerId: a.ownerId,
    }),
  );
};

export const fetchEmailsItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { appliedFilters, skip, term, sortBy, showPurchased, sortOrder } = getPayload(payload);
  const request = {
    published: true,
    top: loadItemsCount,
    filters: formatFilters(appliedFilters, emailFilterTypes),
    sortBy,
    showPurchased,
    term,
    sortOrder,
    skip,
    contentType: "email",
  } as const;

  return doFetch(
    (abortController) => DataService.content.getContent(request, abortController.signal),
    CommunicationTypes.Email,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
  );
};

export const fetchMessagesItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { term, skip, showPurchased, appliedFilters, sortOrder, sortBy } = getPayload(payload);
  const request = {
    top: loadItemsCount,
    sortBy,
    published: true,
    sortOrder,
    showPurchased,
    term,
    skip,
    filters: formatFilters(appliedFilters, messageFilterTypes),
  };

  return doFetch(
    () => DataService.content.getContent({ ...request, contentType: "message" }),
    CommunicationTypes.Message,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
  );
};

export const fetchEventsItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
): AppThunk => {
  const { skip, appliedFilters, showPurchased, term, sortOrder, sortBy } = getPayload(payload);
  const request = {
    skip,
    showPurchased,
    sortBy,
    term,
    top: loadItemsCount,
    filters: formatFilters(appliedFilters, eventFilterTypes),
    sortOrder,
    published: true,
  };

  return doFetch(
    (abortController) =>
      DataService.content.getContent({ ...request, contentType: "externalevent" }, abortController.signal),
    EventTypes.ExternalEvent,
    fetchBegin,
    fetchSuccess,
    fetchFailure,
    contentTabSelectSelector,
    (a): ExternalEvent => ({
      ...a,
      thumbnailUrl: a.bag.thumbnailUrl,
      hasReminders: a.bag.hasReminders,
      cardType: EventTypes.ExternalEvent,
    }),
  );
};

export const fetchSurveyItemsV2 = (
  payload: ItemsToDropPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType = packContentTypeSelectSelector,
  aggregateBranching = false,
): AppThunk => {
  const { skip, sortBy, sortOrder, appliedFilters, term, showPurchased } = getPayload(payload);
  const request = {
    term,
    showPurchased,
    skip,
    top: loadItemsCount,
    sortBy,
    sortOrder,
    filters: formatFilters(appliedFilters, surveyFilterTypes),
    published: true,
  };

  // Fetch start
  itemsToDropAbortController.abort();
  itemsToDropAbortController = new AbortController();

  const mapSurveyToOverview = (survey: ContentResponse): PublishedSurveyOverview => ({
    id: Number(survey.id),
    title: survey.title,
    publisherId: survey.publisherId,
    publisher: survey.publisher,
    cardType: AssetTypes.Survey,
    questionsCount: survey.bag.questionsCount,
  });

  const mapQuestionDataToBranchingQuestion = (questions: SurveyPublishedQuestion[]): BranchingQuestion | undefined => {
    const branchingQuestion = questions.find((item) => item.isBranching);

    if (branchingQuestion) {
      return {
        questionText: branchingQuestion.question,
        includeOtherAsAnswer: branchingQuestion.otherAnswerOptions.includeOtherAsAnswer,
        answers: branchingQuestion.answers.map((a) => ({
          id: a.id,
          answerText: a.answer,
        })),
      };
    }
  };

  return async (dispatch: AppDispatch, getState) => {
    try {
      const { data, headers } = await DataService.content.getContent(
        { ...request, contentType: "survey" },
        itemsToDropAbortController.signal,
      );

      const surveyOverviews: PublishedSurveyOverview[] = data.map(mapSurveyToOverview);

      if (aggregateBranching) {
        const idToIdx = new Map<number, number>();

        data.forEach((survey, i) => {
          if (survey.bag?.isBranching) {
            idToIdx.set(Number(survey.id), i);
          }
        });

        if (idToIdx.size) {
          const { data: publishedQuestionsData } = await surveysDataService.getSurveyPublishedQuestions(
            [...idToIdx.keys()],
            itemsToDropAbortController,
          );
          for (const [surveyId, index] of idToIdx) {
            const questionsData = publishedQuestionsData[surveyId];
            surveyOverviews[index].questionsCount = questionsData?.length;
            surveyOverviews[index].branchingQuestion = mapQuestionDataToBranchingQuestion(questionsData);
          }
        }
      }

      // data should not be re-writed by outdated requests
      if (contentTabSelectSelector(getState()) !== AssetTypes.Survey) {
        return;
      }

      dispatch(
        fetchSuccess({
          items: surveyOverviews,
          totalCount: Number.parseInt(headers[countHeaderName]),
        }),
      );
    } catch (error) {
      const err = error as Error;
      dispatch(fetchFailure({ message: err.message, name: err.name }));
    }
  };
};

export const fetchFlowsItemsV2 = (
  payload: ItemsToDropPayload,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
): AppThunk => {
  const { skip, sortBy, sortOrder, term, appliedFilters, showPurchased } = getPayload(payload);
  const request = {
    top: loadItemsCount,
    skip,
    sortBy,
    sortOrder,
    term,
    showPurchased,
    filterQueryParams: formatFilters(appliedFilters, flowFilterTypes),
    published: true,
  };

  return async (dispatch: AppDispatch) => {
    await dispatch(
      doFetch(
        () => DataService.flows.getFlowsWithCancel(request).promise,
        FlowTypes.Flow,
        fetchBegin,
        fetchSuccess,
        fetchFailure,
        packContentTypeSelectSelector,
      ),
    );
  };
};

export const doFetch = <T extends ItemsToDropBase>(
  request: (abortController: AbortController) => Promise<AxiosResponse<T[]>>,
  context: EntityType,
  fetchBegin: ActionCreatorWithoutPayload,
  fetchSuccess: ActionCreatorWithPayload<FetchActionPayload<ItemsToDropBase>>,
  fetchFailure: ActionCreatorWithPayload<Error>,
  contentTabSelectSelector: (state: RootState) => EntityType,
  mappingFunction: (a: T) => ItemsToDropBase = (a: T) => ({ ...a, cardType: context }),
): AppThunk => {
  itemsToDropAbortController.abort();
  itemsToDropAbortController = new AbortController();
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(fetchBegin());
    try {
      const result = await request(itemsToDropAbortController);

      // data should not be re-writed by outdated requests
      if (contentTabSelectSelector(getState()) !== context) {
        return;
      }
      dispatch(
        fetchSuccess({
          items: result.data.map(mappingFunction),
          totalCount: Number.parseInt(result.headers[countHeaderName]),
        }),
      );
    } catch (error: any) {
      const err = error as Error;
      dispatch(fetchFailure({ message: err.message, name: err.name }));
    }
  };
};
