import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { type FeatureFlags } from "../../featureFlags";

interface FeatureFlagProps {
  flag: FeatureFlags;
  renderContent?: (flagEnabled: boolean) => React.ReactNode;
  fallback?: React.ReactNode;
}

const FeatureFlag: React.FC<React.PropsWithChildren<FeatureFlagProps>> = ({
  flag,
  children,
  renderContent,
  fallback = null,
}): any => {
  const flagEnabled = useFeatureFlag(flag);

  if (children && flagEnabled) {
    return children;
  }

  return (renderContent && renderContent(flagEnabled)) ?? fallback;
};

export default FeatureFlag;
