import { type FC } from "react";
import { BlockFormats, MarkFormats } from "../types";
import { BlockButton } from "./BlockButton";
import { MarkButton } from "./MarkButton";

export const Toolbar: FC = () => {
  return (
    <div className="toolbar">
      <BlockButton format={BlockFormats.HeadingOne} iconType="h1" />
      <BlockButton format={BlockFormats.HeadingTwo} iconType="h2" />
      <BlockButton format={BlockFormats.BulletedList} iconType="ul" />
      <MarkButton format={MarkFormats.Bold} iconType="bold" />
      <MarkButton format={MarkFormats.Italic} iconType="italic" />
      <MarkButton format={MarkFormats.Underline} iconType="underline" />
    </div>
  );
};
