import { useFeatureFlag } from "hooks/useFeatureFlag";
import { AddButtonWithPermission } from "../../../../../../components/buttons/addButtonWithPermission/AddButtonWithPermission";
import { RolePermissions } from "../../../../../../enums";
import { FeatureFlags } from "featureFlags";

export const CreateFlowButton: React.FC = () => {
  const flowVersioningMigrationEnabled = useFeatureFlag(FeatureFlags.FlowVersioningMigration);
  return (
    <AddButtonWithPermission
      permissions={[RolePermissions.FlowsCreate]}
      buttonText={"Create Flow"}
      goToLink="/content/flows/createFlow"
      className="create-flow"
      disabled={flowVersioningMigrationEnabled}
    />
  );
};
