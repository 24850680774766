import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/globaltypes/redux";
import { type Subscription } from "../../types";

export interface SubscriptionAgreementState {
  agreement: Subscription;
  isAccessAuthorized: boolean;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: SubscriptionAgreementState = {
  agreement: {
    isSigned: false,
  },
  isAccessAuthorized: true,
  isLoading: false,
  error: undefined,
};

const subscriptionAgreementSlice = createSlice({
  name: "subscriptionAgreement",
  initialState: initialState,
  reducers: {
    req(state: SubscriptionAgreementState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: SubscriptionAgreementState, action: PayloadAction<Subscription>) {
      return {
        ...state,
        isLoading: false,
        agreement: action.payload,
      };
    },
    err(state: SubscriptionAgreementState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    forbidden(state: SubscriptionAgreementState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isAccessAuthorized: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err, forbidden } = subscriptionAgreementSlice.actions;

export const subscriptionAgreementSelector = (state: RootState) => state.accounts.subscriptionAgreement.agreement;
export const subscriptionAgreementLoadingSelector = (state: RootState) =>
  state.accounts.subscriptionAgreement.isLoading;
export const subscriptionHasAccessSelector = (state: RootState) =>
  state.accounts.subscriptionAgreement.isAccessAuthorized;

export default subscriptionAgreementSlice.reducer;
