import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type FormikProps } from "formik";
import { type RootState } from "../../../../../Application/globaltypes/redux";
import { WizardWrapper as Wizard } from "../../../../../WizardWrapper";
import WizardStepsManager from "../../../../../../utils/WizardStepsManager";
import RtnEventsEmitter from "../../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { RouteNames } from "../../../../../../enums";
import navigationUtils from "../../../../../../utils/navigationUtils";

import { type CreateSmtpAuthSendingState } from "./types";
import { type ThreatDefenceSmtpAuthSendingProfileVerificationView } from "../types/state";
import * as smtpAuthSendingProfileEntityStateActions from "../state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import * as smtpAuthSendingProfileDetailsActions from "../state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";

import Configure from "../SmtpAuthSendingProfileDetails/Configure/Configure";
import Settings from "../SmtpAuthSendingProfileDetails/Settings/Settings";
import Verification from "../SmtpAuthSendingProfileDetails/Verification/Verification";
import { withRouter, type WithRouterProps } from "../../../../../../adapters/withRouter/withRouter";

const sendingProfilesOverviewUrl = `/${RouteNames.sendingProfilesSimulatedPhishing}`;

export type CreateSmtpAuthSendingProfilePropsAll = PropsFromRedux &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileVerificationView> &
  ThreatDefenceSmtpAuthSendingProfileVerificationView &
  WithRouterProps;

export class CreateThreatDefenceSmtpAuthSendingProfile extends Component<
  CreateSmtpAuthSendingProfilePropsAll,
  CreateSmtpAuthSendingState
> {
  private stepsManager = new WizardStepsManager();
  constructor(props: CreateSmtpAuthSendingProfilePropsAll) {
    super(props);
    this.state = {
      isValid: false,
      isSettingsValid: false,
    };
  }

  onCancel = () => {
    navigationUtils.goBackOrDefault(
      this.props.location,
      this.props.navigate,
      "/content/simulated-phishing/sending-profiles",
    );
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe([rtnEvents.SmtpSendingProfilePublishSuccess], this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.SmtpSendingProfilePublishSuccess], this.onPublishedEvent);
  }

  onPublishedEvent = () => {
    this.props.navigate(sendingProfilesOverviewUrl);
  };

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onSettingsIsValidChange = (isSettingsValid: boolean) => {
    if (this.state.isSettingsValid !== isSettingsValid) {
      this.setState({ isSettingsValid });
    }
  };

  render() {
    const { isValid, isSettingsValid } = this.state;
    const { isSaving, dateModified, isVerified } = this.props;

    return (
      <Wizard
        title="Create SMTP Auth Sending Profile"
        finishButtonLabel="Publish"
        onCancel={this.onCancel}
        onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
        onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
        onFinishAsync={this.stepsManager.onFinish}
        isSaveInProgress={isSaving}
        progressSavedDate={dateModified}
        isFinishButtonDisabled={!isVerified}
      >
        <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 0)}
            onIsValidChange={this.onIsValidChange}
            disabled={false}
          />
        </Wizard.Step>
        <Wizard.Step label="Settings" className="scrollable-content" isLocked={!isSettingsValid} required>
          <Settings
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 1)}
            onIsValidChange={this.onSettingsIsValidChange}
            disabled={false}
            {...this.props}
          />
        </Wizard.Step>
        <Wizard.Step label="Verification" className="scrollable-content">
          <Verification
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 2)}
            disabled={false}
            {...this.props}
          />
        </Wizard.Step>
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isSaving:
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer
      .isEntityCommandInProgress,
  dateModified:
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer
      .lastModifiedDateTime,
  isVerified:
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer.verification
      .isVerified,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(smtpAuthSendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(smtpAuthSendingProfileDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(CreateThreatDefenceSmtpAuthSendingProfile));
export default ConnectedComponent;
