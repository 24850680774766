import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./closeButton.scss";

import { ReactComponent as CloseButtonSvg } from "../../../images/window-close-icon-20.svg";

function CloseButton(props) {
  const { onClick, className, id, inverted, testId } = props;
  return (
    <div
      id={id}
      className={cn("close-icon-container", className, { inverted: inverted })}
      data-testid={testId ?? "close-button"}
      onClick={onClick}
    >
      <CloseButtonSvg />
    </div>
  );
}

CloseButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
};

export default CloseButton;
