import { difference, intersection } from "lodash";
import { RolePermissions } from "../../../../enums";

const groupDomainUtils = {
  getAutoEnrolledGroupNotificationSettingsId: (groupId: number) => `AutoEnrolledGroup_${groupId}`,

  hasReadOnlyOwnersAccess(userPermissions: RolePermissions[]) {
    const readOnlyRoles = [RolePermissions.GroupsView, RolePermissions.GroupsCreate];
    const manageOwnersRoles = [RolePermissions.GroupsManage, RolePermissions.UsersView, RolePermissions.RolesView];
    return (
      intersection(manageOwnersRoles, userPermissions).length === 0 &&
      difference(readOnlyRoles, userPermissions).length === 0
    );
  },
};

export default groupDomainUtils;
