import { type FormikProps } from "formik";
import React, { Component } from "react";

import { ValidatedForm } from "../../../../../../components";
import { type EmailTemplateFormPropsOwn } from "../types";
import { type ThreatDefenceEmailTemplateView } from "../../types/state";

export type EmailTemplateFormPropsAll = EmailTemplateFormPropsOwn & FormikProps<ThreatDefenceEmailTemplateView>;

export default class EmailTemplateForm extends Component<EmailTemplateFormPropsAll> {
  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this email? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onBlur, onIsValidChange } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="email-form-container">
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Subject Line"
          value={values.subject}
          propertyName="subject"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Software Application"
          propertyName="softwareApplications"
          className="software-applications"
          value={values.softwareApplications.selected}
          options={values.softwareApplications.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Labels"
          propertyName="labels"
          value={values.labels.selected}
          options={values.labels.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
