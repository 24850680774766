import type { FlowValidationError } from "../../validator/flowValidatorReducer";
import React from "react";

interface IErrorNavigationContext {
  state: {
    currentError: FlowValidationError | null;
  };
  actions: {
    setCurrentError: (error: FlowValidationError | null) => void;
  };
}

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};

export const ErrorNavigationContext = React.createContext<IErrorNavigationContext>({
  state: {
    currentError: null,
  },
  actions: {
    setCurrentError: defaultAction("setCurrentError"),
  },
});
