import React, { type FC, useEffect } from "react";

import { type AssignmentModelItemBase } from "../../../features/People/types";
import { updateSelectedContentItems } from "../utils/contentAssignmentUtils";
import { type Filters } from "../../../utils/queryUtils";

import { type FiltersMap } from "../../../utils/filterUtils";
import { type ColumnOption } from "../../../interfaces/columnOptions";
import { type SortingDirection, type SortOptions } from "../../../enums";
import type SortingOptions from "../../../interfaces/SortingOptions";
import GenericItemsView from "../../../views/ItemsView/GenericItemsView";
import { type AssignmentListPropsBase } from "../types";

export interface AssignmentListProps extends AssignmentListPropsBase {
  fetchData: (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    appliedFilter?: FiltersMap,
  ) => void;
  items: AssignmentModelItemBase[];
  isLoading: boolean;
  columnOptions: ColumnOption<AssignmentModelItemBase>[];
  getFilterForm: () => React.ReactNode;
  getSearchBar: (accessRestricted: boolean) => React.ReactElement;
  noResultsContent?: React.ReactElement;
  sortingDirection?: SortingDirection;
  defaultSortingColumnName: string;
  applyFilter: (filters: FiltersMap) => void;
  resetFilter: () => void;
  sortOptions?: SortingOptions[];
  onSortChange?: (_ev: any, data: { value?: SortOptions }) => void;
  getFilterOptions?: () => void;
  setReloadListItems?: (reloadListItems: (enableSorting: boolean) => void) => void;
}

const AssignmentList: FC<AssignmentListProps> = (props) => {
  const {
    createButton,
    resetList,
    fetchData,
    onSelectedChanged,
    getFilterForm,
    getSearchBar,
    applyFilter,
    onViewTypeChange,
    peopleType,
    accessRestricted,
    isReadOnly = false,
    ...rest
  } = props;

  useEffect(() => {
    return () => resetList();
  }, [resetList]);

  const onSelectedListChanged = (ids: number[]) => {
    updateSelectedItems(rest.items, ids);
  };

  const updateSelectedItems = (collection: AssignmentModelItemBase[], ids: number[]) => {
    const updatedSelectedItems = updateSelectedContentItems(collection, ids);
    onSelectedChanged && onSelectedChanged(updatedSelectedItems);
  };

  const applyAssignmentFilter = (filter: Filters) => {
    applyFilter(filter);
    onSelectedChanged([]);
  };

  return (
    <GenericItemsView
      {...rest}
      applyFilter={applyAssignmentFilter}
      renderSearch={getSearchBar}
      fetchData={fetchData}
      onSelectedItemChanged={onSelectedListChanged}
      isSelectDisabled={(item) => isReadOnly || !!item.inherited}
      getFilterForm={getFilterForm}
      onViewTypeChange={onViewTypeChange}
      accessRestricted={accessRestricted}
    />
  );
};

export default AssignmentList;
