import { useQuery } from "@tanstack/react-query";
import { FeatureFlags } from "featureFlags";
import { type BasePerformanceQueryContext, type PerformancePeriodQueryContext } from "features/Library/Common/models";
import {
  lineChartMargins,
  completesColor,
  createDateRange,
  dateRange30,
  fourthColor,
  getFormattedTimeStringFromPeriod,
  largestToSmallest,
  lineChartFactory,
  noBarData,
  noData,
  opensColor,
  startsColor,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { fetchFilesLineChartData, fetchFilesTotalsData } from "features/Reporting/services/googleReportingService";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import moment from "moment";
import { useMemo, useState } from "react";

import { ChartWrapper, ColumnChart, GroupedBarChart, HorizontalBarChart, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import styles from "../googleReport.module.scss";

import { type ColumnDataPoint } from "components/charts/types/ColumnChart";
import { type DataPoint } from "components/charts/types/HorizontalBarChart";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useGoogleTooltipFormats } from "../utils/useGoogleTooltipFormats";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";
import { sharedGoogleReportingBarProps } from "../utils/shared";

type ChartState = "line" | "column";

type FilesBarData = {
  other: number;
  folders: number;
  sheets: number;
  slides: number;
  forms: number;
  docs: number;
  drawings: number;
};

const LEGEND_LABELS = ["Internal", "External"];
const COLOR_SCALE = [startsColor, completesColor];

const getLineChartQuery = async ({ queryKey }: PerformancePeriodQueryContext) => {
  const { data } = await fetchFilesLineChartData({ ...queryKey[1], period: queryKey[2] });
  const lineData = lineChartFactory(
    data.map((d) => {
      const { EventDate, ...rest } = d;
      return {
        ...rest,
        Date: EventDate,
      };
    }),
  );

  const groupedUsersColumnData: ColumnDataPoint[] = data.map((d, i) => ({
    id: i.toString(),
    category: moment(d.EventDate).local(true).format(SERVER_DATE_ONLY_FORMAT),
    value: d.DriveUsers,
    fillColor: startsColor,
  }));

  const activityBarData: ColumnDataPoint[] = data.map((d, i) => ({
    id: i.toString(),
    category: moment(d.EventDate).local(true).format(SERVER_DATE_ONLY_FORMAT),
    value: d.FilesViewedOrEdited,
    fillColor: startsColor,
  }));

  const fileSharingBarData: any[] = data.map((d) => ({
    group: moment(d.EventDate, "YYYY-MM-DD").format(SERVER_DATE_ONLY_FORMAT),
    internal: d.FilesSharedInternal,
    external: d.FilesSharedExternal,
  }));

  const order: (keyof FilesBarData)[] = ["other", "folders", "sheets", "slides", "forms", "docs", "drawings"];

  return {
    lineData,
    groupedUsersColumnData,
    activityBarData,
    fileSharingBarData,
    order,
  };
};

const getTotalsQuery = async ({ queryKey }: BasePerformanceQueryContext) => {
  const { data } = await fetchFilesTotalsData(queryKey[1]);
  const domain = [
    0,
    Math.max(1, Math.max(data.Other, data.Spreadsheets, data.Presentations, data.Forms, data.Documents, data.Drawings)),
  ];
  const barData: DataPoint[] = [
    {
      id: "other",
      fillColor: startsColor,
      value: data.Other,
      category: "Other",
    },
    {
      id: "folders",
      fillColor: completesColor,
      value: data.Spreadsheets,
      category: "Folders",
    },
    {
      id: "sheets",
      fillColor: opensColor,
      value: data.Spreadsheets,
      category: "Sheets",
    },
    {
      id: "slides",
      fillColor: fourthColor,
      value: data.Presentations,
      category: "Slides",
    },
    {
      id: "forms",
      fillColor: "#60c794",
      value: data.Forms,
      category: "Forms",
    },
    {
      id: "documents",
      fillColor: "#7b6fb8",
      value: data.Documents,
      category: "Documents",
    },
    {
      id: "drawings",
      fillColor: "#7acadb",
      value: data.Drawings,
      category: "Drawings",
    },
  ];

  barData.sort(largestToSmallest);
  return {
    barData,
    domain,
  };
};

export const GoogleFiles = () => {
  const enabled = useFeatureFlag(FeatureFlags.GoogleReports);
  const [usersChartState, setUsersChartState] = useState<ChartState>("line");
  const [filesViewedChartState, setFilesViewedChartState] = useState<ChartState>("line");
  const [activityChartState, setActivityChartState] = useState<ChartState>("line");

  const [filter, setFilter] = useState(dateRange30());

  const handleDropdownChange = (dropdownValue: string, setFunc: (newState: ChartState) => void) => {
    dropdownValue.indexOf("Column") === -1 ? setFunc("line") : setFunc("column");
  };

  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);
  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const totalsQuery = useQuery({
    queryKey: ["googleFilesTotals", filter],
    queryFn: getTotalsQuery,
  });

  const lineChartQuery = useQuery({
    queryKey: ["googleFilesLine", filter, chartPeriod],
    queryFn: getLineChartQuery,
    enabled: enabled && chartPeriod !== "UNSET",
  });

  const { lineChartFormatter, groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const activeUsersChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return usersChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.DriveUsers]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={COLOR_SCALE}
        tooltipFormatter={lineChartFormatter}
      />
    ) : (
      <ColumnChart
        margins={lineChartMargins}
        data={lineChartQuery.data.groupedUsersColumnData}
        domain={[0, Math.max(...lineChartQuery.data.groupedUsersColumnData.map((d) => d.value))]}
        xAxisTickFormat={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
      />
    );
  }, [chartPeriod, dateRange, groupedBarFormatter, lineChartQuery.data, lineChartFormatter, usersChartState]);

  const filesViewedEditedChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return filesViewedChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.FilesViewedOrEdited]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={COLOR_SCALE}
        tooltipFormatter={lineChartFormatter}
      />
    ) : (
      <ColumnChart
        margins={lineChartMargins}
        data={lineChartQuery.data.activityBarData}
        domain={[0, Math.max(...lineChartQuery.data.activityBarData.map((d) => d.value))]}
        xAxisTickFormat={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
      />
    );
  }, [lineChartQuery.data, filesViewedChartState, lineChartFormatter, groupedBarFormatter, chartPeriod, dateRange]);

  const filesSharedChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return activityChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.FilesSharedInternal, lineChartQuery.data.lineData.FilesSharedExternal]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={COLOR_SCALE}
        tooltipFormatter={lineChartFormatter}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        data={lineChartQuery.data.fileSharingBarData}
        order={["internal", "external"]}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
      />
    );
  }, [lineChartQuery.data, activityChartState, lineChartFormatter, groupedBarFormatter, chartPeriod, dateRange]);

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <h2>Usage</h2>
        <ReportingFilter currentFilter={filter} callback={({ dateFrom, dateTo }) => setFilter({ dateFrom, dateTo })} />
      </div>
      <div className={styles.filesReportBody}>
        <div className={styles.activeUsersContainer}>
          <ChartWrapper
            titles={["Active Users - Line Chart", "Active Users - Column Chart"]}
            onChange={(option) => handleDropdownChange(option, setUsersChartState)}
          >
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess && validLineData([lineChartQuery.data.lineData.DriveUsers])
                ? activeUsersChart
                : noData(filter)}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className={styles.header}>
          <h2>Activity</h2>
        </div>
        <div className={styles.filesViewedOrEdited}>
          <ChartWrapper
            titles={["Files Viewed or Edited - Line Chart", "Files Viewed or Edited - Column Chart"]}
            onChange={(option) => handleDropdownChange(option, setFilesViewedChartState)}
          >
            <RequestStatusRenderer state={lineChartQuery.status}>
              {lineChartQuery.isSuccess && validLineData([lineChartQuery.data.lineData.FilesViewedOrEdited])
                ? filesViewedEditedChart
                : noData(filter)}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>

        <div className={styles.activityContainer}>
          <div className={styles.activity} ref={measureRef}>
            <ChartWrapper
              titles={["File Sharing - Line Chart", "File Sharing - Column Chart"]}
              showLegend
              legendLabels={LEGEND_LABELS}
              colorRange={COLOR_SCALE}
              onChange={(option) => handleDropdownChange(option, setActivityChartState)}
            >
              <RequestStatusRenderer state={lineChartQuery.status}>
                {lineChartQuery.isSuccess &&
                  validLineData([
                    lineChartQuery.data.lineData.FilesSharedInternal,
                    lineChartQuery.data.lineData.FilesSharedExternal,
                  ])
                  ? filesSharedChart
                  : noData(filter)}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className={styles.barChartContainer}>
          <ChartWrapper titles={["File Sharing by Type"]}>
            <RequestStatusRenderer state={totalsQuery.status}>
              {totalsQuery.isSuccess && !noBarData(...totalsQuery.data.barData.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  domain={totalsQuery.data.domain}
                  data={totalsQuery.data.barData}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};
