import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import moboConfigurator from "moboConfigurator";
import { useMemo } from "react";

function Segment(props) {
  const { to, label, className, onClick, disabled } = props;
  const accountId = useMemo(() => moboConfigurator.getMoboId(), []);

  return (
    <div role={"tab"}>
      {disabled ? (
        <span style={{ cursor: "pointer" }} className={cn(className, "segment")}>
          {label}
        </span>
      ) : (
        <NavLink
          end
          to={{ pathname: to, hash: accountId ? `#moboId=${accountId}` : undefined }}
          className={({ isActive }) => cn(className, "segment", { "active-segment": isActive })}
          onClick={onClick}
          role="link"
        >
          {label}
        </NavLink>
      )}
    </div>
  );
}

Segment.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Segment;
