import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums";
import { type MessageDetails, type MessageContent } from "../../types";
import { type MessageDetailsState } from "../../types/state";
import { type MessageDetails as MessageDetailsResponce } from "../../types/models";

export const initialState: MessageDetailsState = {
  message: {
    id: 0,
    title: "",
    utmCampaign: "",
    softwareApplications: [],
    labels: [],
    dateModified: undefined,
  },
  messageContent: {
    id: 0,
    headline: "",
    imageUrl: "",
    body: "",
    callToAction: "",
    destinationUrl: "",
  },
  isLoading: false,
  isLoaded: false,
  isCreating: false,
  isDraft: true,
  error: undefined,
  hasBeenPublished: false,
  isPurchased: false,
  id: 0,
};

const messageDetailsSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Messages}/values`,
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    saveMessage(state: MessageDetailsState, action: PayloadAction<MessageDetails>) {
      state.message = action.payload;
    },
    saveMessageContent(state: MessageDetailsState, action: PayloadAction<MessageContent>) {
      state.messageContent = action.payload;
    },
    getMessageBegin(state: MessageDetailsState, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    getMessageSuccess(state: MessageDetailsState, action: PayloadAction<MessageDetailsResponce>) {
      const result = action.payload;
      state.message = {
        id: result.id,
        title: result.title,
        utmCampaign: result.utmCampaign,
      };
      state.messageContent = {
        id: result.id,
        headline: result.headline,
        imageUrl: result.imageUrl,
        body: result.body,
        callToAction: result.callToAction,
        destinationUrl: result.destinationUrl,
      };
      state.isDraft = result.isDraft;
      state.hasBeenPublished = result.hasBeenPublished;
      state.isPurchased = result.isPurchased;
      state.isLoading = false;
      state.isLoaded = true;
    },
    getMessageFailure(state: MessageDetailsState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload;
    },
  },
});

export const { saveMessage, saveMessageContent, reset, getMessageBegin, getMessageSuccess, getMessageFailure } =
  messageDetailsSlice.actions;

export default messageDetailsSlice.reducer;
export type messageDetailsSliceStateType = ReturnType<typeof messageDetailsSlice.reducer>;
