interface TransitionState {
  heightTransitionFinished: boolean;
  widthTransitionFinished: boolean;
  allTransitionsFinished: boolean;
}

type TransitionAction =
  | { type: "SET_HEIGHT_TRANSITION_FINISHED" }
  | { type: "SET_WIDTH_TRANSITION_FINISHED" }
  | { type: "RESET_TRANSITIONS" };

const initialState: TransitionState = {
  heightTransitionFinished: false,
  widthTransitionFinished: false,
  allTransitionsFinished: false,
};

export const transitionReducer = (state: TransitionState = initialState, action: TransitionAction): TransitionState => {
  switch (action.type) {
    case "SET_HEIGHT_TRANSITION_FINISHED":
      return {
        ...state,
        heightTransitionFinished: true,
        allTransitionsFinished: state.widthTransitionFinished === true,
      };

    case "SET_WIDTH_TRANSITION_FINISHED":
      return {
        ...state,
        widthTransitionFinished: true,
        allTransitionsFinished: state.heightTransitionFinished === true,
      };

    case "RESET_TRANSITIONS":
      return initialState;

    default:
      return state;
  }
};
