import React, { Component } from "react";
import { Icon, Label } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import "./filterPanelHeader.scss";
import { calculateAppliedFilters } from "../../../utils/filterUtils";

class FilterPanelHeader extends Component {
  render() {
    const { filter, resetFilter } = this.props;

    return (
      <div className="filter-panel-header">
        <div className="filters-label">
          <span>Filters</span>
          {!isEmpty(filter) && <Label circular>{calculateAppliedFilters(filter)}</Label>}
        </div>
        <Button basic className="reset-btn" onClick={resetFilter} disabled={isEmpty(filter)}>
          <Icon className="fa-redo" size="large" />
          <span className="label">Reset</span>
        </Button>
      </div>
    );
  }
}

FilterPanelHeader.defaultProps = {
  filter: {},
};

FilterPanelHeader.propTypes = {
  filter: PropTypes.object,
  resetFilter: PropTypes.func,
};

export default FilterPanelHeader;
