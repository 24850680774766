import { type PackLicenseType } from "features/Licensing/Packs/types/state";
import { type PackAccountsContextItem } from "../types";
import { memo, useMemo } from "react";
import { LicenseTypes } from "enums";
import CardThumbnail from "components/cards/cardThumbnail/CardThumbnail";
import LicensePicker from "components/LicensePicker/LicensePicker";
import { Popup } from "semantic-ui-react";
import cn from "classnames";
import styles from "./packAccountItems.module.scss";

interface PackItemProps {
  item: PackAccountsContextItem;
  options: PackLicenseType[];
  onExpirationDateChange: (selectedPackId: number, optionId?: number, date?: Date | null) => void;
  isEdit?: boolean;
  idx: number;
}

export const PackItem = memo((props: PackItemProps) => {
  const { item, options, onExpirationDateChange, isEdit, idx } = props;

  const licenseOptions = useMemo(
    () =>
      options
        .filter(
          (option) => (item.isTrialAllowed && (!item.licenseInfo || item.isTrial)) || option.id !== LicenseTypes.Trial,
        )
        .map((option) => ({
          title: option.id === LicenseTypes.Trial ? `${item.trialPeriod?.name} ${option.name}` : option.name,
          id: option.id,
          duration: option.monthsCount,
        }))
        .reverse(),
    [item, options],
  );

  const isTrialExpired = item.licenseTypeId === LicenseTypes.Trial && item.licenseInfo?.isExpired;

  const disableItem =
    item.licenseInfo?.isFromOtherSource || (item.isTrial && item.licenseInfo && !item.licenseInfo.isExpired);

  const getDisabledTooltip = (item: PackAccountsContextItem) =>
    item.isTrial && !item.licenseInfo?.isFromOtherSource
      ? "The trial period for this pack is already active and cannot be modified until it ends."
      : "This pack has already been added to this customer's account.";

  const itemElement = (
    <div key={item.id}>
      <div className={cn(styles.item, { [styles.disabled]: disableItem })} key={item.id}>
        <div className={styles.thumbnail}>
          <CardThumbnail thumbnailUrl={item.thumbnailImageUrl} />
        </div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.licensing}>
          <div className={styles.licenses}>
            <div className="required-asterisk">Licenses</div>
            <b>Auto-increment</b>
          </div>
          <div className={styles.expiration}>
            <LicensePicker
              options={licenseOptions}
              onOptionChange={(optionId?: number) => onExpirationDateChange(item.id, optionId)}
              onExpirationDateChange={(date?: null | Date) => onExpirationDateChange(item.id, undefined, date)}
              preselectedOptionId={item.licenseTypeId}
              preselectedExpirationDate={item.expirationDate}
              verticalPosition={idx > 0 ? "top" : "bottom"}
              highlightExpired={isEdit}
              highlightExpiredTrial={isTrialExpired}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return disableItem ? (
    <Popup
      className={styles.popup}
      trigger={itemElement}
      content={getDisabledTooltip(item)}
      position={"top center"}
      inverted
      hideOnScroll
    />
  ) : (
    itemElement
  );
});
