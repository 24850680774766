import ReducerNamespaceTypes from "../../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../../Application/actions/entityState/entityStateActionTypes";

const smtpAuthSendingProfileActionBuilder = (type: string) => {
  return buildRootEntityActionType(
    ReducerNamespaceTypes.Library,
    ReducerEntityPrefixTypes.ThreatDefenceSmtpAuthSendingProfiles,
    type,
  );
};

export const createThreatDefenceSmtpAuthSendingProfileBegin = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createThreatDefenceSmtpAuthSendingProfileSuccess = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createThreatDefenceSmtpAuthSendingProfileFailure = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const updateThreatDefenceSmtpAuthSendingProfileBegin = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateThreatDefenceSmtpAuthSendingProfileCommandSuccess = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const updateThreatDefenceSmtpAuthSendingProfileFailure = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const threatDefenceSmtpAuthSendingProfileUpdated = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const resetThreatDefenceSmtpAuthSendingProfileEntityState = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const publishThreatDefenceSmtpAuthSendingProfileBegin = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);

export const publishThreatDefenceSmtpAuthSendingProfileSuccess = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);

export const publishThreatDefenceSmtpAuthSendingProfileFailure = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const fetchLockedSendingProfileBegin = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const fetchLockedSendingProfileSuccess = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const fetchLockedSendingProfileFailure = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const discardSendingProfileBegin = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const discardSendingProfileSuccess = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const discardSendingProfileFailure = smtpAuthSendingProfileActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const setSendingProfileEntityId = smtpAuthSendingProfileActionBuilder(entityStateActionTypes.SET_ENTITY_ID);
