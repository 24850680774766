import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

export const CREATE_VIDEO_DRAFT_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const CREATE_VIDEO_DRAFT_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const CREATE_VIDEO_DRAFT_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const RESET_VIDEO_ENTITY_STATE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const GET_VIDEO_LOCK_ENTITY_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const GET_VIDEO_LOCK_ENTITY_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const GET_VIDEO_LOCK_ENTITY_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const DRAFT_VIDEO_INFO_UPDATED = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const UPDATE_VIDEO_PROPERTIES_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const UPDATE_VIDEO_PROPERTIES_SUCCEED = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const UPDATE_VIDEO_PROPERTIES_FAILED = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const PUBLISH_VIDEO_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);

export const PUBLISH_VIDEO_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);

export const PUBLISH_VIDEO_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const REVERT_VIDEO_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const REVERT_VIDEO_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const REVERT_VIDEO_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  entityStateActionTypes.RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE,
);
