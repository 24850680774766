import { type FC } from "react";
import { LandingPagesFiltersEnum } from "../../../features/Library/SimulatedPhishing/LandingPages/types/models";
import { FilterFormBuilder, type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";
import { type FilterFormPropsBase } from "../types/props";

export interface LandingPagesFilterFormProps extends FilterFormPropsBase<LandingPagesFiltersEnum> {}

const LandingPagesFilterForm: FC<LandingPagesFilterFormProps> = ({ filterOptions, filter, updateFilter }) => {
  const filters: FilterItemBase<LandingPagesFiltersEnum>[] = [
    {
      type: FilterViewTypes.DateRange,
      label: "Date Added",
      propertyName: LandingPagesFiltersEnum.DateCreated,
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Last Modified",
      propertyName: LandingPagesFiltersEnum.DateModified,
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Software Applications",
      propertyName: LandingPagesFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Labels",
      propertyName: LandingPagesFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};

export default LandingPagesFilterForm;
