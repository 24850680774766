import { Component } from "react";
import { bindActionCreators } from "redux";
import { batch, connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { type FormikProps, withFormik } from "formik";
import { difference, isEmpty, isNumber, some, uniq } from "lodash";
import { Navigate, Routes, Route } from "react-router-dom";

import dataService from "../../../Application/services/dataServices/dataService";
import rolesDataService from "../../services/rolesDataService";
import groupsDataService from "../../services/groupsDataService";

import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import removeUserFromGroupsTask from "./backgroundTasks/removeUserFromGroupsTask";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import DeleteUsersConfirmationModal from "../../../../components/people/deleteUsersConfirmationModal/DeleteUsersConfirmationModal";
import RemoveRolesConfirmationModal from "../../../../components/people/removeRolesConfirmationModel/RemoveRolesConfirmationModal";
import RemovePriorityConfirmationModal from "../../../../components/people/removeContentConfirmationModal/RemovePriorityConfirmationModal";
import EditUserInfo from "./EditUserInfo";
import UserGroupList from "../UserGroupList/UserGroupList";
import GenericSectionHeader from "../../../../components/genericSectionHeader/GenericSectionHeader";
import EditUserPacksIssueLicenseModalContainer from "components/assignmentModals/peopleIssueLicenseModal/EditUserIssueLicenseModalContainer/EditUserPacksIssueLicenseModalContainer";
import RemoveGroupsConfirmationModal from "../../../../components/people/removeGroupsConfirmationModal/RemoveGroupsConfirmationModal";
import UserRoleList from "./RoleListInfo";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as editUserRolesActionsRedux from "./state/editUserRolesActions";
import * as editUserGroupsActionsRedux from "./state/editUserGroupsActions";
import * as editUserGroupsFilterActionsRedux from "./state/editUserGroupsFilterActionCreators";
import * as editUserActionsRedux from "./state/editUserActions";
import * as filterActionsRedux from "./state/editUserRolesFilterActionCreators";
import * as groupsOverviewActionsRedux from "../../Groups/GroupsOverview/state/groupsOverviewActions";
import * as groupsFilterActionsRedux from "../../Groups/GroupsOverview/state/filterActions";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";
import * as userCommonActionsRedux from "../state/userCommonActions";
import * as usersOverviewActionsRedux from "../UsersOverview/state/usersOverviewActions";
import * as filterActionCreators from "../UsersOverview/state/filterActionCreators";
import * as rolesOverviewActionsRedux from "../../RolesOverview/state/rolesOverviewActions";
import * as rolesFilterActionsRedux from "../../RolesOverview/state/filterActionCreators";
import { getFilterOptions as eventGetFilterOptions } from "../../../Library/Events/state/thunks/eventFiltersThunk";
import * as contentAssignmentActionsRedux from "../../ContentAssignmentState/ContentAssignmentActions";
import * as addToContentActionsRedux from "../../state/slices/addToContentSlice";
import { assignUsersToRoleTask } from "../backgroundTasks/assignUsersToRoleTask";
import { stringUtils, validationSchemas, groupListUtils, Observable, userListUtils } from "../../../../utils";
import {
  AddPeopleToContentTypes,
  AlertTypes,
  AssignmentPeopleContext,
  SortingDirection,
  ViewType,
  Strings,
  TemplateTypes,
  RolePermissions,
  RouteNames,
} from "../../../../enums";
import {
  RestrictedAddButton,
  RestrictedResource,
  RestrictedByTooltip,
  DeleteLinkButton,
  RemoveLinkButton,
  Segments,
  EditPriorityLinkButton,
  ContentAssignmentModal,
  GroupsAssignmentModal,
  RolesAssignmentModal,
  type UserGroupsAssignment,
  type UserRolesAssignment,
  ContentAssignment,
} from "../../../../components";
import {
  AddUserToGroupAllSuccess,
  AssignUsersToRoles,
  UnassignRolesSuccess,
  UserRemoveFromGroupSuccess,
} from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import { UserFlowPriorityChangedSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { type FiltersMap, type GenericFiltersMap } from "../../../../utils/filterUtils";
import { type Filters } from "../../../../utils/queryUtils";
import { type PriorityItem } from "../../types";
import UserPacks from "./UserPacks/UserPacks";
import { fetchUserPacksGrid } from "../../../Licensing/Packs/state/thunks/userPacksGridThunk";
import { resetUserPacksGrid } from "../../../Licensing/Packs/state/slices/userPacksGridSlice";
import { type UserPacksRequest } from "../../../Licensing/Packs/types/requests";
import { PeopleType, ContentType } from "../../../Library/PeopleAssignments/types";
import { contentToPayloadPropMap } from "../../../../utils/userListUtils";
import {
  issuePackLicenseToPeople,
  removePackLicenseFromPeople,
} from "../../../Licensing/Packs/state/thunks/packPurchasedViewThunk";
import Restricted from "../../../Application/Restricted";
import { issueLicensePermissionPredicate } from "./issueLicensePermissionPredicate";
import ChangePeopleContentPriorityModal from "../../../../components/assignmentModals/changePriorityModal/ChangePeopleContentPriorityModal";
import { bindAction, type ContentPriorityLevel, type NotifyStepSettings } from "../../../../interfaces";
import {
  getAssignmentByContentId,
  getUpdatedPriorityItems,
  mapUserAssignmentsToPriorityItems,
  selectedStateInitial,
} from "../../../Library/PeopleAssignments/utils/peopleAssignmentUtils";
import {
  editPeopleContentPriority,
  unassignContentDistributed,
  removeContentPrioritiesDistributed,
} from "../../../Library/PeopleAssignments/state/actions/commonActions";
import { type WithId } from "../../../../types";
import { addUsersToGroups, deleteUsers } from "../../state/thunks/peopleThunk";
import {
  type AssignmentListFiltersEnum,
  type IContentAssignmentActions,
  type SelectedItem,
} from "../../../../components/contentAssignment/types";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LICENCE_CONTENT } from "features/People/constants";
import navigationUtils from "../../../../utils/navigationUtils";
import UserPerformance from "../Performance/UserPerformance";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import { shouldShowModalForGroupMembership } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import RemoveLicenseConfirmationDialog from "components/packs/removeLicenseConfirmationDialog/RemoveLicenseConfirmationDialog";
import packsDataService from "features/Licensing/Packs/services/packsDataService";
import "./editUser.scss";
import { type LDProps } from "../../../LDProps";
import { FeatureFlags } from "featureFlags";

const groupsListViewRtnEvents = [AddUserToGroupAllSuccess, UserRemoveFromGroupSuccess];
const roleListViewRtnEvents = [AssignUsersToRoles, UnassignRolesSuccess];
const contentListViewRtnEvents = [UserFlowPriorityChangedSuccess];

const segmentPaths = {
  configure: "configure",
  roles: "roles",
  groups: "groups",
  content: "content",
  licenses: "licenses",
};

type PropsFromRedux = ConnectedProps<typeof connector>;

interface EditUserState {
  selectedGroups: SelectedItem[];
  selectedRoles: SelectedItem[];
  selectedPacks: SelectedItem[];
  viewType: ViewType;
  clickedUserGroupIdToRemove: number;
  clickedUserRoleIdToRemove: number;
  clickedUserPackIdToRemove: number;
  userContent: Record<ContentType, { ids: number[]; undeletableIds: number[]; inherited: Set<number> }>;
  confirmation: {
    priorityItems: PriorityItem[];
    show: boolean;
    proceed?: () => Promise<boolean>;
  };
  addGroupsModal: {
    show: boolean;
    selectedGroupIds: number[];
  };
  showRemoveRoleUserConfirmation: boolean;
  showDeleteConfirmation: boolean;
  showRemoveGroupsConfirmation: boolean;
  showAddToRoleModal: boolean;
  showPeopleIssueLicenseModal: boolean;
  removeLicenseModal: {
    show: boolean;
    groupLicensesCount: number;
    userLicensesCount: number;
  };
  contentType: AddPeopleToContentTypes;
}

export interface EditUserProps extends WithRouterProps {
  assetsFilterOptions: any;
}

type Props = EditUserProps & PropsFromRedux & FormikProps<any> & LDProps;

export class EditUser extends Component<Props, EditUserState> {
  private readonly flexiblePriorityDueDate: boolean;
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedGroups: [] as SelectedItem[],
      selectedRoles: [] as SelectedItem[],
      selectedPacks: [] as SelectedItem[],
      viewType: ViewType.LIST,
      clickedUserGroupIdToRemove: 0,
      clickedUserRoleIdToRemove: 0,
      clickedUserPackIdToRemove: 0,
      userContent: { ...selectedStateInitial },
      confirmation: {
        priorityItems: [],
        show: false,
      },
      addGroupsModal: {
        show: false,
        selectedGroupIds: [],
      },
      removeLicenseModal: {
        show: false,
        groupLicensesCount: 0,
        userLicensesCount: 0,
      },
      showRemoveRoleUserConfirmation: false,
      showDeleteConfirmation: false,
      showRemoveGroupsConfirmation: false,
      showAddToRoleModal: false,
      showPeopleIssueLicenseModal: false,
      contentType: AddPeopleToContentTypes.Flows,
    };

    this.flexiblePriorityDueDate = !!this.props.flags?.[FeatureFlags.FlexiblePriorityDueDate];
  }

  userId = parseInt(this.props.params.id!);

  onChangePriorityObserver = new Observable();
  onClearPriorityObserver = new Observable();

  showUserDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  closeDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  onDeleteUserConfirm = async (userId: number) => {
    this.closeDeleteConfirmation();
    this.props.navigate("/people/users");

    this.props.deleteUsers([userId]);
  };

  componentDidMount() {
    const {
      editUserActions: { fetchUserInfo },
      userCommonActions: { fetchCountries },
    } = this.props;

    fetchCountries();
    fetchUserInfo(this.userId);
    RtnEventsEmitter.subscribe(contentListViewRtnEvents, this.resetUserContent);
  }

  componentWillUnmount() {
    this.props.editUserActions.resetUserInfo();
    RtnEventsEmitter.unsubscribe(contentListViewRtnEvents, this.resetUserContent);
  }

  addUserToRoleHeaderButton = (hasPermissions: boolean) => {
    return (
      <RestrictedAddButton
        buttonText="Add Role"
        onClick={() => this.onAddToRoleModal()}
        hasPermission={hasPermissions}
      />
    );
  };

  removeContentBtn = (ids: number[], unremovableSelected: boolean) => (
    <RemovePriorityButton
      onClick={() => this.onClearPriorityClick(ids)}
      isDisabled={unremovableSelected}
      context="people"
      entityType={ContentType.Flow}
      peopleType={PeopleType.User}
      showTooltip={unremovableSelected}
      multiple
    />
  );

  resetUserContent = () => {
    this.setState({ userContent: { ...selectedStateInitial } });
  };

  onEditPriorityClick = (contentType: ContentType) => (contentId: number) => {
    const { userContentAssignment } = this.props;
    let priorityItems: ContentPriorityLevel[] = [];

    const assignments = userListUtils.getContentItems(contentType, userContentAssignment);
    const selectedAssignments = getAssignmentByContentId(assignments, [contentId]);
    priorityItems = mapUserAssignmentsToPriorityItems(selectedAssignments, contentType, this.flexiblePriorityDueDate);
    this.onChangePriorityObserver.notify(() => {}, { [PeopleType.User]: priorityItems });
  };

  /* istanbul ignore next */
  onClearPriorityClick = (ids: number[]) => {
    this.onClearPriorityObserver.notify(() => {
      const flowItems = { [ContentType.Flow]: ids };
      this.props.removeContentPriorities(this.userId, AssignmentPeopleContext.User, flowItems);

      this.setState((state) => ({
        userContent: userListUtils.mapToContentTypeRecord(state.userContent),
      }));
    });
  };

  onBulkEditPriorityClick = () => {
    const { userContent } = this.state;
    const { userContentAssignment } = this.props;

    // To display thumbnail and title for single selected item we should prefill first priority item
    // I feel dirty writing this code 🤮, obvious improvement would be saving selected items (not just id-s)
    const contentType = contentToPayloadPropMap[this.state.contentType];
    const assignments = userListUtils.getContentItems(contentType, userContentAssignment);
    const selectedAssignment = getAssignmentByContentId(assignments, userContent[contentType].ids);
    const priorityItems = mapUserAssignmentsToPriorityItems(
      selectedAssignment,
      contentType,
      this.flexiblePriorityDueDate,
    );

    this.onChangePriorityObserver.notify(() => {}, { [PeopleType.User]: priorityItems });
  };

  editPriorityBtn = () => {
    const hasPermission = userListUtils.getCanEditContent(this.state.userContent, this.props.userPermissions);
    return (
      <RestrictedByTooltip hasPermission={hasPermission}>
        <EditPriorityLinkButton onClick={this.onBulkEditPriorityClick} isDisabled={!hasPermission} />
      </RestrictedByTooltip>
    );
  };

  onCancelAddToGroupModal = () => {
    this.props.resetLicensingModal();
    this.setState(() => ({
      addGroupsModal: {
        selectedGroupIds: [],
        show: false,
      },
    }));
  };

  openAddToGroupModal = () => {
    this.props.usersOverviewActions.fetchUserGroupsAssignments([this.userId]);
    this.setState((state) => ({
      addGroupsModal: {
        ...state.addGroupsModal,
        show: true,
      },
    }));
  };

  addUserToGroupHeaderButton = (hasPermission: boolean) => {
    return (
      <RestrictedAddButton buttonText="Add Group" onClick={this.openAddToGroupModal} hasPermission={hasPermission} />
    );
  };

  fetchRoles = (skip = 0, top = 10, orderProp = "", orderDirection = "", filter: any = "") => {
    this.props.rolesActions.fetchRoles(this.userId, skip, top, orderProp, orderDirection, filter);
  };

  fetchFlowList = (skip?: number, top?: number, orderBy?: string, filters?: Filters, term?: string) => {
    this.props.contentAssignmentActions.fetchUserFlowsList(
      this.userId,
      skip ?? 0,
      top ?? 10,
      orderBy ?? "",
      filters,
      term,
    );
  };

  fetchFlowGrid = (skip?: number, _top?: number, orderBy?: string, filters?: Filters, term?: string) => {
    this.props.contentAssignmentActions.fetchUserFlowsGridLazy(
      this.userId,
      skip ?? 0,
      _top ?? 10,
      orderBy ?? "",
      filters,
      term,
    );
  };

  fetchAssetList = (
    skip?: number,
    top?: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    filters?: Filters,
    term?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserAssetsList({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      filters,
      searchTerm: term,
      sortingColumnName: sortBy,
      sortingDirection: sortOrder,
    });
  };

  fetchAssetGrid = (
    skip?: number,
    top?: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    filters?: Filters,
    term?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserAssetsGridLazy({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      filters,
      searchTerm: term,
      sortingColumnName: sortBy,
      sortingDirection: sortOrder,
    });
  };

  fetchEventsList = (
    skip = 0,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserEventsList({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName,
      sortingDirection,
      filters,
      searchTerm,
    });
  };

  fetchEventsGrid = (
    skip = 0,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserEventsGridLazy({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName,
      sortingDirection,
      filters,
      searchTerm,
    });
  };

  fetchPdfsList = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserPdfsList({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName,
      sortingDirection,
      filters,
      searchTerm,
    });
  };

  fetchPdfsGrid = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserPdfsGridLazy({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName,
      sortingDirection,
      filters,
      searchTerm,
    });
  };

  fetchAssessmentsList = (
    skip: number,
    top: number,
    sortBy?: string,
    sortOrder?: SortingDirection,
    searchTerm?: string,
    filters?: GenericFiltersMap<AssignmentListFiltersEnum>,
  ) => {
    this.props.contentAssignmentActions.fetchUserAssessmentsList({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName: sortBy,
      sortingDirection: sortOrder,
      filters,
      searchTerm,
    });
  };

  fetchSrveysList = (
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    term?: string,
  ) => {
    this.props.contentAssignmentActions.fetchUserSurveysList({
      userId: this.userId,
      skip: skip ?? 0,
      top: top ?? 10,
      sortingColumnName,
      sortingDirection,
      filters,
      searchTerm: term,
    });
  };

  onSelectedContentChanged = (prop: ContentType) => (items: SelectedItem[]) => {
    const ids: number[] = [];
    const undeletableIds: number[] = [];
    const inherited = new Set<number>();

    items.forEach((x) => {
      const id = x.id;
      ids.push(id);
      !x.canRemove && undeletableIds.push(id);
      x.isInherited && inherited.add(id);
    });

    const newState = { ...this.state };
    newState.userContent[prop] = { ids, undeletableIds, inherited };
    this.setState(newState);
  };

  onSelectedContentTypeChange = (type: AddPeopleToContentTypes) => {
    this.onSelectedContentChanged(ContentType.Flow)([]);
    this.setState({ contentType: type });
  };

  hideContentCreate = () => !LICENCE_CONTENT.includes(this.state.contentType);

  createButton = (contentType: AddPeopleToContentTypes, hasPermission: boolean) => {
    return this.hideContentCreate() ? undefined : (
      <RestrictedAddButton
        buttonText={`Add ${contentType}`}
        onClick={() => {
          this.props.onAddContent(this.userId, contentType);
        }}
        hasPermission={hasPermission}
      />
    );
  };

  renderEditUserContent = () => {
    const { userContent, confirmation } = this.state;

    const { contentAssignmentActions, userInfo, userPermissions } = this.props;

    const contentActions: IContentAssignmentActions = {
      fetchSurveysList: this.fetchSrveysList,
      resetSurveysList: contentAssignmentActions.resetAssignmentSurveysList,
      fetchAssessmentsList: this.fetchAssessmentsList,
      resetAssessmentsList: contentAssignmentActions.resetAssignmentAssessmentsList,
      fetchFlowsList: this.fetchFlowList,
      fetchFlowsGrid: this.fetchFlowGrid,
      resetFlowsGrid: contentAssignmentActions.resetAssignmentFlowsGrid,
      resetFlowsList: contentAssignmentActions.resetAssignmentFlowsList,
      fetchVideosList: this.fetchAssetList,
      fetchVideosGrid: this.fetchAssetGrid,
      resetVideoGrid: contentAssignmentActions.resetAssignmentAssetsGrid,
      resetVideoList: contentAssignmentActions.resetAssignmentAssetsList,
      fetchEventsList: this.fetchEventsList,
      fetchEventsGrid: this.fetchEventsGrid,
      resetEventsGrid: contentAssignmentActions.resetAssignmentEventsGrid,
      resetEventsList: contentAssignmentActions.resetAssignmentEventsList,
      resetPdfsGrid: contentAssignmentActions.resetAssignmentPdfsGrid,
      resetPdfsList: contentAssignmentActions.resetAssignmentPdfsList,
      fetchPdfsGrid: this.fetchPdfsGrid,
      fetchPdfsList: this.fetchPdfsList,
    };

    return (
      <div className="edit-user-content-step">
        <ContentAssignment
          flowButton={this.createButton(
            AddPeopleToContentTypes.Flows,
            difference([RolePermissions.UsersManage, RolePermissions.FlowsView], userPermissions).length === 0,
          )}
          eventButton={this.createButton(
            AddPeopleToContentTypes.Events,
            difference([RolePermissions.UsersManage, RolePermissions.EventsView], userPermissions).length === 0,
          )}
          videoButton={this.createButton(
            AddPeopleToContentTypes.Videos,
            difference([RolePermissions.UsersManage, RolePermissions.AssetsView], userPermissions).length === 0,
          )}
          surveyButton={this.createButton(
            AddPeopleToContentTypes.Surveys,
            difference([RolePermissions.UsersManage, RolePermissions.AssetsView], userPermissions).length === 0,
          )}
          assessmentButton={this.createButton(
            AddPeopleToContentTypes.Assessments,
            difference([RolePermissions.UsersManage, RolePermissions.AssetsView], userPermissions).length === 0,
          )}
          pdfButton={this.createButton(
            AddPeopleToContentTypes.PDFs,
            difference([RolePermissions.UsersManage, RolePermissions.AssetsView], userPermissions).length === 0,
          )}
          deepLink
          contentAssignmentActions={contentActions}
          selectedFlows={userContent[ContentType.Flow].ids}
          onSelectedFlowsChanged={this.onSelectedContentChanged(ContentType.Flow)}
          onSelectedVideosChanged={this.onSelectedContentChanged(ContentType.Video)}
          onSelectedEventsChanged={this.onSelectedContentChanged(ContentType.Event)}
          onSelectedPdfsChanged={this.onSelectedContentChanged(ContentType.Pdf)}
          selectedVideos={userContent[ContentType.Video].ids}
          selectedEvents={userContent[ContentType.Event].ids}
          confirmation={confirmation}
          isReadOnly={userInfo.canEdit === false}
          onSelectedSurveysChanged={this.onSelectedContentChanged(ContentType.Survey)}
          selectedSurveys={userContent[ContentType.Survey].ids}
          selectedPdfs={userContent[ContentType.Pdf].ids}
          // assessments
          onSelectedAssessmentsChanged={this.onSelectedContentChanged(ContentType.Assessment)}
          selectedAssessments={userContent[ContentType.Assessment].ids}
          peopleType={PeopleType.User}
          flowContextMenuButtonHandlers={{
            onEditPriority: this.onEditPriorityClick(ContentType.Flow),
            onRemove: this.onClearPriorityClick,
          }}
          showExpirationDate
          setSelectedContentType={this.onSelectedContentTypeChange}
          onViewTypeChange={(viewType: ViewType) => {
            this.setState({ viewType });
          }}
          userPermissions={userPermissions}
        />
      </div>
    );
  };

  renderEditUserLicenses = () => {
    const { userPacks, userPacksActions, accountId, userInfo } = this.props;

    const packsActions = {
      fetchPacksGrid: userPacksActions.fetchUserPacksGrid,
      resetPacksGrid: userPacksActions.resetUserPacksGrid,
    };

    return (
      <>
        <UserPacks
          userId={this.userId}
          accountId={accountId}
          packsGrid={userPacks.grid}
          packsActions={packsActions}
          issueLicenseButton={this.issueLicenseHeaderButton}
          sendTransientNotification={this.props.notificationsActions.sendTransientNotification}
          onRemoveLicenseClick={this.onRemoveLicenseClick}
          isReadOnly={!userInfo.canEdit}
          selectedPackIds={this.getSelectedPackIds()}
          onSelectedPacksChanged={this.onSelectedPacksChanged}
        />
        {this.state.showPeopleIssueLicenseModal && this.renderPeopleIssueLicenseModal()}
      </>
    );
  };

  saveUserInfo = () => {
    const {
      editUserActions: { updateUserInfo },
      values,
    } = this.props;

    const coercedUserInfo = validationSchemas.getCreateUserSchema().cast(values);

    updateUserInfo(coercedUserInfo);

    const params = {
      id: "UpdateUserInformation",
      title: "Update user information",
      getMessageIdsV2: () => [`UserUpdated_${this.userId}`],
      callApi: () => dataService.updateUser(coercedUserInfo),
      successTransientMessage: "User information update succeeded!",
      failureTransientMessage: "User information update failed!",
    };

    const {
      backgroundTasksActions: { addOperationV2 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntityV2(params, {
      addOperation: addOperationV2,
      sendTransientNotification,
    });
  };

  fetchGroups = (skip: number, top: number, orderByParams: string, filterParams: any) => {
    this.props.userGroupsActions.fetchUserGroups(this.userId, skip, top, orderByParams, filterParams);
  };

  onSelectedRolesChanged = (roleIds: number[]) => {
    const { selectedRoles } = this.state;
    const updatedSelection = selectedRoles.filter((g) => roleIds.includes(g.id));

    const roles = this.props.userRoles.items;
    const addedRoles = roles
      .filter((g) => roleIds.includes(g.id) && selectedRoles.every((sg) => sg.id !== g.id))
      .map((r) => ({ id: r.id, title: r.name }));

    this.setState({
      selectedRoles: [...updatedSelection, ...(addedRoles as SelectedItem[])],
    });
  };

  onSelectedGroupsChanged = (groupIds: number[]) => {
    const { selectedGroups } = this.state;
    const updatedSelection = selectedGroups.filter((g) => groupIds.includes(g.id));

    const groups = this.props.userGroups.list.items;

    const addedGroups = groups.filter(
      (g: WithId) => groupIds.includes(g.id) && selectedGroups.every((sg) => sg.id !== g.id),
    );

    this.setState({
      selectedGroups: [...updatedSelection, ...addedGroups],
    });
  };

  onSelectedPacksChanged = (packIds: number[]) => {
    const { selectedPacks } = this.state;
    const updatedSelection = selectedPacks.filter((p) => packIds.includes(p.id));

    const packs = this.props.userPacks.grid.items;
    const addedPacks = packs.filter((p) => packIds.includes(p.id) && selectedPacks.every((sp) => sp.id !== p.id));

    this.setState({
      selectedPacks: [...updatedSelection, ...addedPacks],
    });
  };

  closeRemoveRoleConfirmation = () => {
    this.setState({
      showRemoveRoleUserConfirmation: false,
      selectedRoles: [],
      clickedUserRoleIdToRemove: 0,
    });
  };

  rolesToRemove = () => {
    const { selectedRoles, clickedUserRoleIdToRemove } = this.state;
    return clickedUserRoleIdToRemove > 0 ? [clickedUserRoleIdToRemove] : selectedRoles.map((x) => x.id);
  };

  onRemoveUserRole = async () => {
    const userId = this.userId;
    const rolesToRemove = this.rolesToRemove();

    if (isNumber(userId) && some(rolesToRemove)) {
      const roleInMessageSingular = rolesToRemove.length === 1;
      const params = {
        id: "UnassignUserFromRoles",
        title: "Unassign user from roles",
        getMessageIds: async () => {
          const data = await rolesDataService.unassignRolesFromUsers([userId], rolesToRemove);
          return [data];
        },
        successTransientMessage: roleInMessageSingular
          ? "User has been unassigned from role successfully"
          : "User have been unassigned from roles successfully",
        failureTransientMessage: roleInMessageSingular
          ? "Unassigning user from role failed!"
          : "Unassigning user from roles failed!",
      };

      const {
        backgroundTasksActions: { addOperationV1 },
        notificationsActions: { sendTransientNotification },
      } = this.props;

      await backgroundTask.updateEntity(params, {
        addOperation: addOperationV1,
        sendTransientNotification,
      });
    }

    this.closeRemoveRoleConfirmation();
  };

  renderRemoveRoleUsersModal = () => {
    const { selectedRoles, clickedUserRoleIdToRemove } = this.state;

    const rolesCount = clickedUserRoleIdToRemove > 0 ? 1 : selectedRoles.length;

    return (
      <RemoveRolesConfirmationModal
        open={this.state.showRemoveRoleUserConfirmation}
        rolesCount={rolesCount}
        onCancel={this.closeRemoveRoleConfirmation}
        onContinue={this.onRemoveUserRole}
      />
    );
  };

  renderRemoveContentUsersModal = () => (
    <RemovePriorityConfirmationModal peopleType={PeopleType.User} removeObserver={this.onClearPriorityObserver} />
  );

  onRemoveLicenseClick = async (packId: number = 0) => {
    const { selectedPacks } = this.state;

    const packsCount = packId > 0 ? 1 : selectedPacks.length;
    const packIds = packId > 0 ? [packId] : this.getSelectedPackIds();

    const userGroupLicenses = await packsDataService.getUserGroupLicenses([this.userId], packIds);
    const groupLicensesCount = uniq(userGroupLicenses.flatMap((i) => i.groupIds)).length;

    this.setState({
      removeLicenseModal: {
        show: true,
        groupLicensesCount: groupLicensesCount,
        userLicensesCount: packsCount,
      },
      clickedUserPackIdToRemove: packId,
    });
  };

  closeRemoveLicenseConfirmation = () => {
    this.setState({
      removeLicenseModal: {
        show: false,
        groupLicensesCount: 0,
        userLicensesCount: 0,
      },
      selectedPacks: [],
      clickedUserPackIdToRemove: 0,
    });
  };

  packsToRemove = () => {
    const { selectedPacks, clickedUserPackIdToRemove } = this.state;
    return clickedUserPackIdToRemove > 0 ? [clickedUserPackIdToRemove] : selectedPacks.map((x) => x.id);
  };

  onRemoveUserLicense = () => {
    const packsToRemove = this.packsToRemove();

    const licenses = packsToRemove.map((packId) =>
      userListUtils.mapToAssignmentItem(this.userId, { id: packId }, "pack", AssignmentPeopleContext.User),
    );

    this.props.removePackLicense({
      peopleType: AssignmentPeopleContext.User,
      items: licenses,
    });

    this.closeRemoveLicenseConfirmation();
  };

  renderRemoveUserLicenseModal = () => {
    const { removeLicenseModal } = this.state;
    return (
      <RemoveLicenseConfirmationDialog
        groupLicensesCount={removeLicenseModal.groupLicensesCount}
        userLicensesCount={removeLicenseModal.userLicensesCount}
        triggerOpen={removeLicenseModal.show}
        onCancel={this.closeRemoveLicenseConfirmation}
        onContinue={this.onRemoveUserLicense}
        isPackContext={false}
      />
    );
  };

  onAddToRoleModal = () => {
    this.props.usersOverviewActions.fetchUserRolesAssignments([this.userId]);
    this.setState({
      showAddToRoleModal: true,
    });
  };

  onCloseAddToRoleModal = () => {
    this.setState({
      showAddToRoleModal: false,
    });
  };

  onAddToRolesConfirm = async (roleIds: number[]) => {
    this.onCloseAddToRoleModal();

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    const params = assignUsersToRoleTask([this.userId], roleIds);

    await backgroundTask.updateEntity(params!, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  renderAddUserToRoleModal = () => {
    const {
      rolesFilterOptions,
      accountId,
      rolesFilterActions,
      rolesToAdd,
      userRolesAssignments,
      rolesOverviewActions: { fetchRoles },
    } = this.props;

    const { showAddToRoleModal } = this.state;

    return (
      showAddToRoleModal && (
        <RolesAssignmentModal
          dataLoader={fetchRoles}
          accountId={accountId}
          rolesCount={rolesToAdd.itemsCount}
          isListLoading={rolesToAdd.isLoading || userRolesAssignments.isLoading}
          roles={rolesToAdd.items}
          filterActions={rolesFilterActions}
          filterOptions={rolesFilterOptions}
          selectedUserIds={[this.userId]}
          onConfirm={this.onAddToRolesConfirm}
          showModal={showAddToRoleModal}
          onCancel={this.onCloseAddToRoleModal}
          userRolesAssignments={userRolesAssignments.items as UserRolesAssignment[]}
        />
      )
    );
  };

  renderDropdownButtons = () => {
    const { selectedGroups, selectedRoles, selectedPacks } = this.state;
    return some(selectedGroups) || some(selectedRoles) || some(selectedPacks)
      ? null
      : [<DeleteLinkButton key="delete-user" onClick={this.showUserDeleteConfirmation} />];
  };

  onGroupsSelected = (groupIds: number[]) => {
    const { shouldShowLicensingModal } = this.props;
    this.setState((state) => ({
      addGroupsModal: {
        ...state.addGroupsModal,
        selectedGroupIds: groupIds,
      },
    }));

    return new Promise<boolean>(async (resolve) => {
      const shouldShow = await shouldShowLicensingModal(groupIds, [this.userId]);
      resolve(shouldShow);
    });
  };

  onConfirmAddToGroupModal = async (notifyStepSettings?: NotifyStepSettings) => {
    const { addGroupsModal } = this.state;

    this.setState(() => ({
      addGroupsModal: {
        show: false,
        selectedGroupIds: [],
      },
    }));

    if (isEmpty(addGroupsModal.selectedGroupIds)) {
      return;
    }

    this.props.addUsersToGroups([this.userId], addGroupsModal.selectedGroupIds, notifyStepSettings);
  };

  renderAddToGroupModal = () => {
    const {
      accountId,
      groupsFilterOptions,
      groupsFilterActions,
      groupsToAdd,
      userGroupsAssignments,
      groupsOverviewActions,
      isShouldShowModalLoading,
    } = this.props;

    const { addGroupsModal } = this.state;

    return (
      <GroupsAssignmentModal
        accountId={accountId}
        showModal={addGroupsModal.show}
        fetchGroups={groupsOverviewActions.fetchGroups}
        groups={groupsToAdd.items}
        groupsCount={groupsToAdd.itemsCount}
        isGroupsLoading={groupsToAdd.isLoading || userGroupsAssignments.isLoading}
        userGroupsAssignments={userGroupsAssignments.items as UserGroupsAssignment[]}
        fetchFilterOptions={groupsFilterActions.fetchFilterOptions}
        filterOptions={groupsFilterOptions}
        selectedUserIds={[this.userId]}
        onGroupsSelected={this.onGroupsSelected}
        onConfirm={this.onConfirmAddToGroupModal}
        onCancel={this.onCancelAddToGroupModal}
        notifyTemplateType={TemplateTypes.AddedToGroup}
        isStepLoading={isShouldShowModalLoading}
      />
    );
  };

  renderChangePriorityModal = () => (
    <ChangePeopleContentPriorityModal
      onSave={(assignments: { [key in PeopleType]?: ContentPriorityLevel[] }) => {
        getUpdatedPriorityItems(assignments, (items: ContentPriorityLevel[]) => {
          this.props.editPriority(items, this.userId, this.flexiblePriorityDueDate);
        });
        this.setState((state) => ({
          userContent: userListUtils.mapToContentTypeRecord(state.userContent),
        }));
      }}
      onTriggerModalObserver={this.onChangePriorityObserver}
    />
  );

  onAddPacksToUserModal = () => {
    this.setState({
      showPeopleIssueLicenseModal: true,
    });
  };

  onCancelPeopleIssueLicenseModal = () => {
    this.hideIssueLicenseModal();
  };

  hideIssueLicenseModal = () => {
    this.setState({
      showPeopleIssueLicenseModal: false,
    });
  };

  requestLicenseIssue = (selectedPackIds: number[]) => {
    if (some(selectedPackIds)) {
      const assignments = selectedPackIds.map((packId) =>
        userListUtils.mapToAssignmentItem(this.userId, { id: packId }, "pack", AssignmentPeopleContext.User),
      );
      this.props.issuePackLicense({
        peopleType: AssignmentPeopleContext.User,
        items: assignments,
      });
    }

    this.hideIssueLicenseModal();

    return Promise.resolve();
  };

  renderPeopleIssueLicenseModal = () => {
    const { accountId } = this.props;

    return (
      <EditUserPacksIssueLicenseModalContainer
        showModal={this.state.showPeopleIssueLicenseModal}
        onCancel={this.onCancelPeopleIssueLicenseModal}
        accountId={accountId}
        onConfirm={this.requestLicenseIssue}
        peopleId={this.userId}
        assignmentPeopleContext={AssignmentPeopleContext.User}
      />
    );
  };

  issueLicenseHeaderButton = (
    <Restricted
      permissions={[]}
      permissionPredicate={issueLicensePermissionPredicate}
      renderContent={(hasAnyPermission) => (
        <RestrictedByTooltip hasPermission={hasAnyPermission}>
          <Button
            primary
            className="create-button"
            content="Issue License"
            onClick={this.onAddPacksToUserModal}
            disabled={!hasAnyPermission}
          />
        </RestrictedByTooltip>
      )}
    />
  );

  loadUserGroups = (
    skip = 0,
    top = 10,
    sortingColumnName = "",
    sortingDirection: SortingDirection = SortingDirection.Ascending,
    filter?: FiltersMap,
  ) => {
    let orderParams = "";
    let filterParams = {};

    if (sortingColumnName) {
      orderParams = groupListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    }

    if (filter) {
      filterParams = groupListUtils.formatFilterParams(filter);
    }

    return this.fetchGroups(skip, top, orderParams, filterParams);
  };

  groupsToRemove = () => {
    const { selectedGroups, clickedUserGroupIdToRemove } = this.state;
    return clickedUserGroupIdToRemove > 0 ? [clickedUserGroupIdToRemove] : selectedGroups.map((x) => x.id);
  };

  onRemoveUserGroups = async () => {
    const userId = this.userId;
    const groupsToRemove = this.groupsToRemove();

    if (isNumber(userId) && some(groupsToRemove)) {
      const params = {
        task: { ...removeUserFromGroupsTask.InProgress(`RemoveUserFromGroups_${Date.now()}`), indeterminate: false },
        getMessageIdsV2: () => groupsToRemove.map((groupId) => `RemoveUserFromGroup_${userId}:${groupId}`),
        callApi: () => groupsDataService.removeUserFromGroups(userId, groupsToRemove),
        onCompletedInTime: ({ isSuccessful }: { isSuccessful: boolean }) => {
          let message = {
            type: AlertTypes.error,
            message: `User removal from ${stringUtils.pluralize("group", groupsToRemove.length)} failed!`,
          };

          if (isSuccessful) {
            message = {
              type: AlertTypes.success,
              message: `User has been removed from ${stringUtils.pluralize("group", groupsToRemove.length)}!`,
            };
          }

          this.props.notificationsActions.sendTransientNotification(message);
        },
      };
      this.props.backgroundTasksActions.addOperationV2(params);
    }

    this.setState({
      showRemoveGroupsConfirmation: false,
      selectedGroups: [],
      clickedUserGroupIdToRemove: 0,
    });
  };

  renderModals = () => {
    const { selectedGroups, clickedUserGroupIdToRemove } = this.state;

    const groupsCount = clickedUserGroupIdToRemove > 0 ? 1 : selectedGroups.length;

    return (
      <>
        <RemoveGroupsConfirmationModal
          open={this.state.showRemoveGroupsConfirmation}
          groupsCount={groupsCount}
          onCancel={this.hideRemoveGroupModal}
          onContinue={this.onRemoveUserGroups}
        />
      </>
    );
  };

  renderAddToContentModal = () =>
    this.props.showAddToContentModal && <ContentAssignmentModal peopleType={PeopleType.User} />;

  removeGroupsBtn = (<RemoveLinkButton className="remove-group-button" onClick={() => this.showRemoveGroupModal()} />);

  removeRolesBtn = (<RemoveLinkButton className="remove-role-button" onClick={() => this.showRemoveRoleModal()} />);

  removePacksBtn = (
    <Restricted
      permissions={[]}
      permissionPredicate={issueLicensePermissionPredicate}
      renderContent={(hasAnyPermission) => (
        <RestrictedByTooltip hasPermission={hasAnyPermission}>
          <RemoveLinkButton
            className="remove-pack-button"
            onClick={() => this.onRemoveLicenseClick()}
            isDisabled={!hasAnyPermission}
          />
        </RestrictedByTooltip>
      )}
    />
  );

  addContentBtn = () =>
    this.hideContentCreate() ? undefined : (
      <Button
        className="add-content create-button"
        primary
        onClick={() => {
          this.props.onAddContent(this.userId, this.state.contentType);
        }}
      >
        {Strings.modalTitles.addContent}
      </Button>
    );

  showRemoveRoleModal = (roleId = 0) => {
    this.setState({
      showRemoveRoleUserConfirmation: true,
      clickedUserRoleIdToRemove: roleId,
    });
  };

  showRemoveGroupModal = (groupId = 0) => {
    this.setState({
      showRemoveGroupsConfirmation: true,
      clickedUserGroupIdToRemove: groupId,
    });
  };

  hideRemoveGroupModal = () => {
    this.setState({
      showRemoveGroupsConfirmation: false,
    });
  };

  renderHeaderButtons = () => {
    const {
      location: { pathname },
    } = this.props;

    const { selectedGroups, selectedRoles, selectedPacks, userContent } = this.state;

    switch (pathname.split("/").at(-1)) {
      case segmentPaths.roles:
        if (some(selectedRoles)) {
          return [this.removeRolesBtn];
        }
        return [this.addUserToRoleHeaderButton(true)];
      case segmentPaths.groups:
        if (some(selectedGroups)) {
          return [this.removeGroupsBtn];
        }
        return [this.addUserToGroupHeaderButton(true)];
      case segmentPaths.content:
        if (!this.hasSelectedContent()) {
          return [this.addContentBtn()];
        }
        const flows = userContent[ContentType.Flow];
        const unremovableFlowSelected = flows.undeletableIds.length > 0;
        return [this.editPriorityBtn(), this.removeContentBtn(flows.ids, unremovableFlowSelected)];
      case segmentPaths.licenses:
        if (some(selectedPacks)) {
          return [this.removePacksBtn];
        }
        return [this.issueLicenseHeaderButton];
      default:
        return null;
    }
  };

  hasSelectedContent = () => {
    const { userContent } = this.state;
    return (
      Object.values(userContent)
        .map((item) => item.ids)
        .flat().length > 0
    );
  };

  getSelectedGroupIds = () => {
    const { selectedGroups } = this.state;
    return selectedGroups.map((x) => x.id);
  };

  getSelectedRoleIds = () => {
    const { selectedRoles } = this.state;
    return selectedRoles.map((x) => x.id);
  };

  getSelectedPackIds = () => {
    const { selectedPacks } = this.state;
    return selectedPacks.map((x) => x.id);
  };

  render() {
    const {
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      values,
      resetForm,
      initialValues,

      params,
      accountId,
      countries,
      departments,
      jobTitles,
      filterOptionActions,
      userInfo,
      userRoles,
      filterActions,
      userPermissions,
      userGroups,
      userGroupsFilterActions,
      location,
      navigate,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      values,
      resetForm,
      initialValues,
    };

    return (
      <RestrictedResource isAuthorized={!!userInfo.isAccessAuthorized}>
        <section className="edit-user nested-content">
          <GenericSectionHeader
            title={userInfo.isLoading ? "" : `${userInfo.firstName} ${userInfo.lastName}`}
            titleForGA="User Details"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.peopleUsers}`)}
            buttons={this.renderHeaderButtons()}
            dropdownButtons={this.renderDropdownButtons()}
            isReadOnly={userInfo.canEdit === false}
          />
          <DeleteUsersConfirmationModal
            selectedUsers={[this.userId]}
            isOpen={this.state.showDeleteConfirmation}
            onCancel={this.closeDeleteConfirmation}
            onContinue={() => {
              this.onDeleteUserConfirm(this.userId);
            }}
          />

          {this.renderModals()}
          {this.renderAddToContentModal()}
          {this.renderRemoveContentUsersModal()}
          {this.renderRemoveRoleUsersModal()}
          {this.renderAddUserToRoleModal()}
          {this.renderAddToGroupModal()}
          {this.renderChangePriorityModal()}
          {this.renderRemoveUserLicenseModal()}

          <Segments to={`/people/users/${params.id}`}>
            <Segments.Segment label="Performance" />
            <Segments.Segment to={segmentPaths.configure} label="Configure" />
            <Segments.Segment to={segmentPaths.roles} label="Roles" />
            <Segments.Segment to={segmentPaths.groups} label="Groups" />
            <Segments.Segment to={segmentPaths.content} label="Content" />
            <Segments.Segment to={segmentPaths.licenses} label="Licenses" />
          </Segments>
          <Routes>
            <Route path={"/"} element={<UserPerformance userId={this.userId} />} />
            <Route
              path={segmentPaths.configure}
              element={
                <EditUserInfo
                  {...validatedFieldProps}
                  accountId={accountId}
                  countries={countries.items}
                  departments={departments}
                  jobTitles={jobTitles}
                  filterOptionActions={filterOptionActions}
                  saveUserInfo={this.saveUserInfo}
                  isLoadingUserInfo={userInfo.isLoading}
                  isReadOnly={userInfo.canEdit === false}
                />
              }
            />
            <Route
              path={segmentPaths.roles}
              element={
                <UserRoleList
                  createRoleButton={this.addUserToRoleHeaderButton(
                    difference([RolePermissions.UsersManage, RolePermissions.RolesView], userPermissions).length === 0,
                  )}
                  dataLoader={this.fetchRoles}
                  roles={userRoles.items}
                  rolesCount={userRoles.itemsCount}
                  isLoading={userRoles.isLoading}
                  onSelectedRolesChanged={this.onSelectedRolesChanged}
                  filterOptions={userRoles.filterOptions}
                  appliedFilter={userRoles.appliedFilter}
                  filterActions={filterActions}
                  selectedRoleIds={this.getSelectedRoleIds()}
                  onRemoveMemberClick={(id: number) => this.showRemoveRoleModal(id)}
                  readOnly={userInfo.canEdit === false}
                  listViewRtnEvents={roleListViewRtnEvents}
                />
              }
            />

            <Route
              path={segmentPaths.groups}
              element={
                <UserGroupList
                  addGroupButton={this.addUserToGroupHeaderButton(
                    difference([RolePermissions.UsersManage, RolePermissions.GroupsView], userPermissions).length === 0,
                  )}
                  groups={userGroups.list.items}
                  groupsCount={userGroups.list.itemsCount}
                  loadPage={this.loadUserGroups}
                  selectedItemIds={this.getSelectedGroupIds()}
                  updateSelectedItems={this.onSelectedGroupsChanged}
                  isLoading={userGroups.list.isLoading}
                  appliedUserGroupFilter={userGroups.appliedFilter}
                  filterOptions={userGroups.filterOptions}
                  setUserGroupFilter={userGroupsFilterActions.setGroupsFilter}
                  resetUserGroupFilter={userGroupsFilterActions.resetGroupsFilter}
                  getFilterOptions={userGroupsFilterActions.fetchFilterOptions}
                  onRemoveUserFromGroup={(id: number) => this.showRemoveGroupModal(id)}
                  readOnly={userInfo.canEdit === false}
                  listViewRtnEvents={groupsListViewRtnEvents}
                />
              }
            />
            <Route path={segmentPaths.content} element={this.renderEditUserContent()} />
            <Route path={segmentPaths.licenses} element={this.renderEditUserLicenses()} />
            <Route path="*" element={<Navigate to="../" replace />} />
          </Routes>
        </section>
      </RestrictedResource>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { editUser, userCommon, usersOverview, groupsOverview, rolesOverview, addToContentModal } = state.people;
  const { userInfo, userRoles, userGroups, userPacks } = editUser;

  return {
    accountId: state.userProfile.accountId,
    backgroundTasks: state.backgroundTasks.tasks,
    groupsToAdd: groupsOverview.groupsList,
    groupsFilterOptions: groupsOverview.filterOptions,
    userGroupsAssignments: usersOverview.userGroupsAssignments,
    userRolesAssignments: usersOverview.userRolesAssignments,
    userContentAssignment: state.people.contentAssignment,
    userInfo: {
      id: userInfo.info?.id,
      firstName: userInfo.info?.firstName,
      lastName: userInfo.info?.lastName,
      country: userInfo.info?.country,
      officeLocation: userInfo.info?.officeLocation,
      department: userInfo.info?.department,
      jobTitle: userInfo.info?.jobTitle,
      email: userInfo.info?.email,
      isLoading: userInfo.isLoading,
      canEdit: userInfo.info?.canEdit,
      isBypassSso: userInfo.info?.isBypassSso,
      isAccessAuthorized: userInfo.isAccessAuthorized,
    },
    userRoles: {
      items: userRoles.rolesList.items,
      itemsCount: userRoles.rolesList.itemsCount,
      isLoading: userRoles.rolesList.isLoading,
      filterOptions: userRoles.filterOptions,
      appliedFilter: userRoles.filterState.appliedFilter,
    },
    userGroups: {
      list: userGroups.groupsList,
      grid: userGroups.groupsGrid,
      filterOptions: userGroups.filterOptions,
      appliedFilter: userGroups.filterState.appliedFilter,
    },
    countries: userCommon.countriesList,
    departments: state.people.usersOverview.filterOptions.departments,
    jobTitles: state.people.usersOverview.filterOptions.jobTitles,
    rolesFilterOptions: rolesOverview.filterOptions,
    rolesToAdd: rolesOverview.overviewList,
    showAddToContentModal: addToContentModal.showModal,
    userPacks: {
      grid: userPacks.packsGrid,
    },
    userPermissions: state.userProfile.permissions,
    isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    contentAssignmentActions: bindActionCreators(contentAssignmentActionsRedux, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
    editUserActions: bindActionCreators(editUserActionsRedux, dispatch),
    filterActions: bindActionCreators(filterActionsRedux, dispatch),
    rolesActions: bindActionCreators(editUserRolesActionsRedux, dispatch),
    rolesOverviewActions: bindActionCreators(rolesOverviewActionsRedux, dispatch),
    rolesFilterActions: bindActionCreators(rolesFilterActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
    userCommonActions: bindActionCreators(userCommonActionsRedux, dispatch),
    usersOverviewActions: bindActionCreators(usersOverviewActionsRedux, dispatch),
    filterOptionActions: bindActionCreators(filterActionCreators, dispatch),
    userGroupsActions: bindActionCreators(editUserGroupsActionsRedux, dispatch),
    groupsOverviewActions: bindActionCreators(groupsOverviewActionsRedux, dispatch),
    groupsFilterActions: bindActionCreators(groupsFilterActionsRedux, dispatch),
    userGroupsFilterActions: bindActionCreators(editUserGroupsFilterActionsRedux, dispatch),
    eventsGetFilterAction: bindActionCreators(eventGetFilterOptions, dispatch),
    addToContentActions: bindActionCreators(addToContentActionsRedux, dispatch),
    onAddContent: (userId: number, contentType: AddPeopleToContentTypes) => {
      batch(() => {
        dispatch(addToContentActionsRedux.setSelectedContentType(contentType));
        dispatch(addToContentActionsRedux.setSelectedPeopleIds([userId]));
        dispatch(addToContentActionsRedux.setSelectedPeopleType(AssignmentPeopleContext.User));
      });
    },
    userPacksActions: {
      resetUserPacksGrid: () => dispatch(resetUserPacksGrid()),
      fetchUserPacksGrid: (requestData: UserPacksRequest) => dispatch(fetchUserPacksGrid(requestData)),
    },
    editPriority: (priorityLevels: ContentPriorityLevel[], userId: number, useFlexibleDueDate: boolean) =>
      dispatch(editPeopleContentPriority(priorityLevels, userId, PeopleType.User, useFlexibleDueDate)),
    unassignContent: bindAction(unassignContentDistributed, dispatch),
    removeContentPriorities: bindAction(removeContentPrioritiesDistributed, dispatch),
    issuePackLicense: bindActionCreators(issuePackLicenseToPeople, dispatch),
    removePackLicense: bindActionCreators(removePackLicenseFromPeople, dispatch),
    addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
    shouldShowLicensingModal: bindAction(shouldShowModalForGroupMembership, dispatch),
    resetLicensingModal: bindAction(reset, dispatch),
    deleteUsers: bindActionCreators(deleteUsers, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: (props: any) => {
    return validationSchemas.getEditUserSchema(props.params.id);
  },
  mapPropsToValues: (props: any) => {
    return { ...props.userInfo };
  },
  enableReinitialize: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(EditUser);

const ConnectedComponent = connector(withRouter(withLDConsumer()(component)));
export default ConnectedComponent;
