import React, { Fragment } from "react";
import { sortBy } from "lodash";

import { TextDotDotDot } from "../../../../../../../../components";
import { Tooltip } from "../../../../../../../../components/common/tooltip";
import TriggerType from "../../../../../../../../enums/flowDesigner/triggerType";
import { type ResponseTypeContent, type Trigger } from "../../../../types";
import { type LayoutBase } from "./types";
import ExpandableHeader from "../../ExpandableHeader/ExpandableHeader";
import { layoutTooltip } from "./constants";

import "./responseTriggerLayout.scss";

const getContent = (trigger: Trigger) => trigger.triggerTypeContent[TriggerType.Response];

export const ResponseTriggerLayout: React.FC<LayoutBase> = ({ triggers, renderTrigger }) => {
  const branchingQuestion = getContent(triggers[0])?.questionText;
  const sortedByType = sortBy(triggers, (trigger) => getContent(trigger)?.answerIndex);

  const renderAnswer = (trigger: Trigger) => {
    const { answerIndex, answerText } = getContent(trigger) as ResponseTypeContent;

    return (
      <>
        <div className="response__answer-title">Answer {answerIndex + 1}</div>
        <div className="response__answer-text">
          <Tooltip target={<TextDotDotDot clamp={1}>{answerText}</TextDotDotDot>} content={answerText} />
        </div>
      </>
    );
  };

  return (
    <div className="response-layout-container">
      <ExpandableHeader tooltip={layoutTooltip} title="Answer-based Trigger(s)" active>
        <div className="response__question-title">Question</div>
        <div className="response__question-text">
          <Tooltip target={<TextDotDotDot clamp={1}>{branchingQuestion}</TextDotDotDot>} content={branchingQuestion} />
        </div>
        {sortedByType.map((trigger, i) => (
          <Fragment key={i}>
            {renderAnswer(trigger)}
            {renderTrigger(i, trigger)}
          </Fragment>
        ))}
      </ExpandableHeader>
    </div>
  );
};
