import { createAsyncThunk } from "@reduxjs/toolkit";
import { pluralize } from "../../../../../utils/stringUtils";
import { type AppDispatch, type AppThunk, type RootState } from "../../../../Application/globaltypes/redux";
import { type GetFlowsRequest } from "../../types/requests";
import { type FetchActionPayload, type DistributedOpUpdateParams } from "../../../../../interfaces";
import { type FlowOverview, type FlowOverviewRequest, flowFilterTypes } from "../../types/models";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { type Filters } from "../../../../../utils/queryUtils";
import { getPrefix } from "../../../../Application/slices/models";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { formatFilters } from "../../../../../utils/filterMapUtils";
import { flowsFilterSelector } from "../selectors";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const DEFAULT_LOAD_COUNT = Number(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

export const fetchOverviewFlows = createAsyncThunk<FetchActionPayload<FlowOverview>, FlowOverviewRequest>(
  getPrefix({ namespace: ReducerNamespaceTypes.Flows, entity: ReducerEntityPrefixTypes.Flows, name: "overviewGrid" }),
  async (requestData: FlowOverviewRequest, { getState, signal }) => {
    const rootState = getState() as RootState;
    const flowsFilter = flowsFilterSelector(rootState);
    const searchTerm = escapeTerm(flowsFilter.search);

    const { promise, cancel } = sendGetFlowRequest(
      flowsFilter.appliedFilter,
      requestData.skip ?? 0,
      requestData.top ?? DEFAULT_LOAD_COUNT,
      searchTerm ?? "",
      requestData.sortBy,
      requestData.sortDirection,
    );

    signal.addEventListener("abort", () => cancel());

    const result = await promise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
      append: requestData.append,
    };
  },
);

export const sendGetFlowRequest = (
  appliedFilter: Filters,
  skip: number,
  top: number,
  term: string,
  sortBy?: string,
  sortOrder?: string,
) => {
  const filterQueryParams = formatFilters(appliedFilter, flowFilterTypes);

  const request: GetFlowsRequest = {
    top,
    skip,
    sortBy,
    sortOrder,
    filterQueryParams,
    term,
    showPurchased: true,
  };

  return DataService.flows.getFlowsWithCancel(request);
};

export const duplicateFlowsV2 =
  (ids: number[], onCompleted: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const entity = pluralize("Flow", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DuplicateFlowsDistributedOperation",
      title: `Duplication of flows`,
      onCompleted,
      getOperationProps: async () => {
        const { data } = await DataService.flows.duplicateFlowsV2(ids);
        return {
          operationId: data.operationId,
          stepsIds: data.stepsIds,
          statusUrl: data.statusUrl,
        };
      },
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been duplicated!`,
      failureTransientMessage: `${entity} duplicate failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const changeVisibility =
  (ids: number[], hidden: boolean, onCompleted: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const entity = pluralize("Flow", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "VisibilityFlowsDistributedOperation",
      title: `${hidden ? "Unhiding" : "Hiding"} ${entity}`,
      onCompleted,
      getOperationProps: async () => {
        const { data } = await DataService.flows.changeVisibility(ids, !hidden);
        return data;
      },
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been ${hidden ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${entity} ${hidden ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const deleteFlows =
  (ids: number[], onCompleted: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const { data } = await DataService.flows.deleteFlows(ids);
      return {
        operationId: data.operationId,
        stepsIds: data.stepsIds,
        statusUrl: data.statusUrl,
      };
    };

    const idsCount = ids.length;
    const entity = pluralize("Flow", idsCount);

    const updateParams = {
      id: "DeleteFlows",
      title: `Deletion of flows`,
      onCompleted,
      getOperationProps,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${entity} delete failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
