import { type PackView } from "../types/models";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { fetchAvailablePacks } from "./thunks/TemplateAvailablePacksThunk";
import { type PayloadAction } from "@reduxjs/toolkit";
import { createFetchingItemsSlice, type FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";

export interface TemplateAvailablePacksState extends FetchingItemsState<PackView> {
  packsToAdd: number[];
}

export const initialState: TemplateAvailablePacksState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  packsToAdd: [],
  currentRequestId: "",
};

const slice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "AvailablePacks",
  },
  initialState: initialState,
  reducers: {
    resetGrid(state) {
      return { ...state, ...initialState, packsToAdd: state.packsToAdd };
    },
    setPacksToAdd: (state, action: PayloadAction<number[]>) => {
      state.packsToAdd = action.payload;
    },
  },
  thunk: fetchAvailablePacks,
});

export const { setPacksToAdd, reset, resetGrid } = slice.actions;

export default slice.reducer;
