import { useCallback, useEffect, useRef, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect, type ConnectedProps } from "react-redux";
import { type Dispatch } from "@reduxjs/toolkit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { type RootState } from "../../../Application/globaltypes/redux";
import routeNames from "../../../../enums/routeNames";
import type IConnector from "../connectors/IConnector";
import { createIntegrationEvents, generateNotificationId } from "../connectors/factory";
import { CardsViewer } from "../../../../components";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";
import { Wizard } from "../../../../components/wizard";
import { TemplateTypes } from "../../../../enums";
import InfoBanner from "../../../../components/banners/InfoBanner";
import { ConnectorCard } from "./ConnectorCard";
import { type INotifyByEmailController } from "../../../../components/notifyStep/notifyByEmailController";
import dataService from "../../../Application/services/dataServices/dataService";
import useNotifyConfig from "../../../SystemNotifications/hooks/useNotifyConfig";
import { initialNotifyConfigDefault } from "../../../SystemNotifications/config";
import NotifyWizardStep from "../../../../components/notifyStep/NotifyWizardStep/NotifyWizardStep";
import { type SwitchPropsConfig } from "../../../../components/notifyStep/NotifyStepContent/switches/types";
import navigationUtils from "../../../../utils/navigationUtils";
import { NotifyBannerText } from "./NotifyBannerText";
import IntegrationConfigurationWrapper from "../Configuration/IntegrationConfigurationWrapper";
import { integrationsOverviewSelector, isConnectedSelector } from "../state/selectors";
import { bindAction } from "../../../../interfaces";
import { fetchIntegrationsAction } from "../state/thunks/integrationsOverviewThunk";
import { reset } from "../state/slices/integrationsOverviewSlice";
import { type BaseIntegrationConfigs, type IntegrationTypes, type NotificationIntegrationConfig } from "../types";
import { useConnectors } from "../hooks/useConnectors";
import { useEventCallback } from "../../../../hooks/useEventCallback";
import { NotifyStepSwitch } from "components/notifyStep/NotifyStepSwitch";
import { overviewMoboPath } from "../Shared";
import { useRtn } from "hooks/useRtn";

import styles from "./createIntegration.module.scss";

const CreateIntegrationSteps = {
  Integration: 0,
  Configuration: 1,
  Notify: 2,
};

export const CreateIntegration: React.FC<PropsFromRedux> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { moboId } = useParams();
  const { isLoadingConnectors, resetIntegrations, fetchIntegrations, isConnected, asyncOperations, dispatch } = props;
  const [connectors] = useConnectors(isConnected);

  const [selectedIntegration, setSelectedConnector] = useState<IntegrationTypes>();
  const connector = selectedIntegration ? connectors[selectedIntegration] : Object.values(connectors)[0];

  const [configuration, setConfiguration] = useState<BaseIntegrationConfigs>(connector.configuration);
  const connectorInfo = connector.info;

  const [isAuthSuccess, setIsAuthSuccess] = useState(!connector.shouldLogin());

  const [isWizardFinished, setIsWizardFinished] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isCreationRunning, setIsCreationRunning] = useState(false);
  const [isNotifyStepLoaded, setIsNotifyStepLoaded] = useState(false);
  const [isNotifyStepValid, setIsNotifyStepValid] = useState(false);

  const onCancel = () => navigationUtils.goBackOrUpSegment(location, navigate);

  const onPublishedEvent = useCallback(() => {
    setIsWizardFinished(true);
    setIsCreationRunning(false);
  }, []);

  const notifyByEmailControllerRef = useRef<INotifyByEmailController | undefined>(undefined);
  const [notifyConfig, { setNotifyConfig, shouldNotify, communicationChannel }] =
    useNotifyConfig(initialNotifyConfigDefault);

  const integrationsOverview = moboId ? overviewMoboPath(moboId) : routeNames.integrationsOverviewUrl;

  useEffect(() => {
    if (isWizardFinished) navigationUtils.goBackOrDefault(location, navigate, integrationsOverview);
  }, [isWizardFinished, navigate, moboId, location, integrationsOverview]);

  useEffect(() => {
    return () => {
      notifyByEmailControllerRef.current?.resetState();
      resetIntegrations();
    };
  }, [resetIntegrations]);

  const updateConfig = useEventCallback(
    (c: BaseIntegrationConfigs) => {
      if (configuration.isActive === c.isActive) {
        setIsAuthSuccess(false);
      }

      setConfiguration(c);
    },
    [configuration, setIsAuthSuccess, setConfiguration],
  );

  const onCardSelection = (_: Array<number | string>, id: number | string): void => {
    const type = id as IntegrationTypes;
    setSelectedConnector(type);
    setConfiguration(connectors[type].configuration);
  };

  const isStepLocked = (): boolean => {
    return !selectedIntegration;
  };

  useRtn(createIntegrationEvents, onPublishedEvent, moboId, dispatch, asyncOperations);

  useEffect(() => {
    fetchIntegrations(moboId);
  }, [fetchIntegrations, moboId]);

  const createNotificationSettings = async (connector: IConnector) => {
    const notifyByEmailController = notifyByEmailControllerRef.current;
    const notificationId = generateNotificationId(connector.info.type);
    const notifySettings = await notifyByEmailController?.getSettings(
      shouldNotify,
      communicationChannel,
      !isAuthSuccess,
    );
    if (notifySettings) {
      notifySettings.id = notificationId;
      await dataService.systemNotifications.settings.saveSettings(notifySettings);
    }

    return notificationId;
  };

  const onFinish = async () => {
    const c = { ...configuration };

    if (connector.shouldLogin() && !isAuthSuccess && !(await connector.login(configuration)).isSuccess) {
      return;
    }

    setIsCreationRunning(true);

    if (connector.shouldNotify()) {
      (c as NotificationIntegrationConfig).userNotificationId = await createNotificationSettings(connector);
    }

    await connector.save(c, moboId);
  };

  const onProgressAsync = async (activeStepIndex: number) => {
    if (activeStepIndex === CreateIntegrationSteps.Configuration && !isAuthSuccess) {
      const authResult = await connector.login(configuration);
      setIsAuthSuccess(authResult.isSuccess);
      return authResult.isSuccess || ["authentication is invalid"];
    }
  };

  const onNotifyStepLoaded = (notifyByEmailController: INotifyByEmailController) => {
    notifyByEmailControllerRef.current = notifyByEmailController;
    setIsNotifyStepLoaded(true);
  };

  const onNotifyTabValidChanged = (newValue: boolean) => {
    setIsNotifyStepValid(newValue);
  };

  const renderNotifySwitch = (switchProps: SwitchPropsConfig) => {
    switchProps.labelText = "Notify recipient(s) once synced";
    return <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />;
  };

  const canProceed = (currentIndex: number, nextIndex: number) =>
    Promise.resolve(
      currentIndex === CreateIntegrationSteps.Integration && nextIndex === CreateIntegrationSteps.Notify
        ? isAuthSuccess
        : true,
    );

  const notifyButtonLabel = shouldNotify ? "Notify & Finish" : "Finish";

  const finishButtonLabel = (() => {
    if (connector.shouldNotify()) {
      return notifyButtonLabel;
    }
    if (connector.shouldLogin()) {
      return "Authenticate";
    }
    return "Save";
  })();

  return (
    <>
      <Dimmer active={isCreationRunning || isLoadingConnectors} inverted>
        <Loader active={isCreationRunning || isLoadingConnectors} />
      </Dimmer>
      <Wizard
        id="create-integration-wizard"
        connectorTitle="Add Integration"
        onCancel={onCancel}
        finishButtonLabel={finishButtonLabel}
        title="Add Integration"
        onFinish={onFinish}
        isFinishButtonDisabled={!isValid || (connector.shouldNotify() && shouldNotify && !isNotifyStepLoaded)}
        onProgressAsync={onProgressAsync}
        canProceedAsync={canProceed}
      >
        <Wizard.Step label="Integration" required nextButtonLabel="Next" isLocked={isStepLocked()}>
          <div className="fill-container">
            <div className="right-gutter">
              <p> Select integration to configure </p>
              <CardsViewer
                itemsPerRow={4}
                cardItems={Object.values(connectors).map((x) => x.info)}
                onSelectedCardsChanged={onCardSelection}
                selectedIds={selectedIntegration ? [selectedIntegration] : []}
                renderCard={(props) => {
                  const { item, onSelect, selected } = props;
                  return <ConnectorCard {...item} onSelectionChanged={onSelect} selected={selected} />;
                }}
              />
            </div>
          </div>
        </Wizard.Step>
        <Wizard.Step label="Configuration" required nextButtonLabel={isAuthSuccess ? "Next" : "Authenticate"}>
          <IntegrationConfigurationWrapper
            connectorTitle={connectorInfo.connectorTitle}
            configuration={configuration}
            updateConfig={updateConfig}
            integrationType={connectorInfo.id}
            setIsValid={setIsValid}
          />
        </Wizard.Step>
        {connector.shouldNotify() && (
          <Wizard.Step
            label="Notify"
            required
            isLocked={shouldNotify && (!isAuthSuccess || !(isNotifyStepLoaded && isNotifyStepValid))}
            preRender
          >
            <InfoBanner className={styles.banner}>
              <NotifyBannerText />
            </InfoBanner>
            <NotifyWizardStep
              templateType={TemplateTypes.WelcomeEmail}
              onLoaded={onNotifyStepLoaded}
              onValidChange={onNotifyTabValidChanged}
              isDisabled={!isAuthSuccess}
              shouldNotify={shouldNotify}
              communicationChannel={communicationChannel}
              renderSwitch={renderNotifySwitch}
            />
          </Wizard.Step>
        )}
      </Wizard>
      <PreventTransitionPrompt
        when={!isWizardFinished}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving this integration? All information entered will be lost."
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = integrationsOverviewSelector(state);
  return {
    isConnected: isConnectedSelector(state),
    isLoadingConnectors: overview.isLoading,
    asyncOperations: state.asyncOperations,
  };
};
/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    fetchIntegrations: bindAction(fetchIntegrationsAction, dispatch),
    resetIntegrations: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(CreateIntegration);
export default ConnectedComponent;
