import { type SwitchPropsConfig } from "./switches/types";
import { EditNotifyMessage, type EditNotifyMessageProps } from "./EditNotifyMessage";

import styles from "./notifyStepContent.module.scss";

export interface Props extends EditNotifyMessageProps {
  renderSwitch: (props: SwitchPropsConfig) => React.ReactElement;
}

export const NotifyStepContent = (props: Props) => {
  const {
    emailEditorOptions,
    emailForm,
    onLoaded,
    isDisabled,
    renderSwitch,
    shouldNotify,
    communicationChannel,
    templateType,
    onValidChange,
    onDirtyChange,
  } = props;

  return (
    <div className={styles["notify-step-content"]}>
      <div className={styles.switcher}>
        {renderSwitch({
          isDisabled,
        })}
      </div>
      <EditNotifyMessage
        emailEditorOptions={emailEditorOptions}
        emailForm={emailForm}
        onLoaded={onLoaded}
        shouldNotify={shouldNotify}
        isDisabled={false}
        communicationChannel={communicationChannel}
        templateType={templateType}
        onValidChange={onValidChange}
        onDirtyChange={onDirtyChange}
      />
    </div>
  );
};
