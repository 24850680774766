import React, { memo } from "react";

import BaseNode from "../Base/Base";
import AssessmentCard from "../../../../../../../components/cards/flowDesignerDropCard/AssessmentCard/AssessmentCard";
import type AssetTypes from "../../../../../../../enums/assetTypes";
import { type FlowItemAssessment } from "../../../types";
import { type FlowItemBase } from "../types";

interface IAssessmentNodeOwnProps {
  type: AssetTypes.Assessment;
  data: FlowItemAssessment & FlowItemBase;
}

const AssessmentNode: React.FC<IAssessmentNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <AssessmentCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(AssessmentNode);
