import { type FC } from "react";
import cn from "classnames";

import PublishStatusCard from "../PublishStatusCard";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";
import { type FlowGoals } from "features/Library/Flows/types/models";
import { type CardBase, type CardItemBase } from "../../../interfaces/CardPropsBase";
import "./flowCard.scss";

type Item = CardItemBase & {
  published: boolean;
  goals?: FlowGoals;
};

export interface FlowCardProps extends CardBase<Item> {
  isRadioButton?: boolean;
}

const FlowCard: FC<FlowCardProps> = (props: FlowCardProps) => {
  const {
    item,
    className,
    selected,
    popupMenu,
    url,
    onSelect,
    disabled,
    disablePopupMenu,
    icons,
    onTitleClick,
    popupMenuButtons,
    isRadioButton,
  } = props;
  const { id, dateModified, published, goals } = item;

  return (
    <PublishStatusCard
      {...item}
      url={url}
      id={id}
      icons={icons}
      selected={selected}
      isRadioButton={isRadioButton}
      disabled={disabled}
      popupMenu={popupMenu}
      published={published}
      onTitleClick={onTitleClick}
      onSelectionChanged={onSelect}
      popupMenuButtons={popupMenuButtons}
      disablePopupMenu={disablePopupMenu}
      className={cn("flow-card", className)}
      thumbnailContent={<ObjectiveLabel objective={goals?.objective} />}
    >
      <div className="flow-card__added-date">Modified {dateTimeUtils.formatDate(dateModified)}</div>
    </PublishStatusCard>
  );
};

export default FlowCard;
