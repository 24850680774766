import { useEffect } from "react";
import { type DropdownItemProps } from "semantic-ui-react";
import { first } from "lodash";
import { ValidatedForm } from "../../../../../components/forms";
import { type TrialPeriodSettingsProps } from "../types";

import "./trialPeriodSettings.scss";

function TrialPeriodSettings(props: TrialPeriodSettingsProps) {
  const { onBlur, onChanged, validatedFieldProps, disabled, trialOptions } = props;
  const { isTrialAllowed, trialPeriodId } = props.values;

  const dropdownOptions: DropdownItemProps[] = trialOptions.map((option) => {
    return {
      text: option.name,
      value: option.id,
    };
  });

  useEffect(() => {
    const selectedTrialPeriod = isTrialAllowed ? trialPeriodId ?? first(dropdownOptions)?.value : null;
    validatedFieldProps.setFieldValue("trialPeriodId", selectedTrialPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrialAllowed]);

  return (
    <div className="trial-periods">
      <div className="checkbox-field">
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          value={isTrialAllowed}
          propertyName="isTrialAllowed"
          toggle
          label={"Offer free trial period?"}
          disabled={disabled}
          handleChange={onChanged}
        />
      </div>
      {isTrialAllowed && (
        <ValidatedForm.DropdownField
          {...validatedFieldProps}
          value={trialPeriodId}
          propertyName="trialPeriodId"
          options={dropdownOptions}
          disabled={disabled}
          onChangeCallback={onChanged}
          handleBlur={onBlur}
          testID={"trial-period-dropdown"}
        />
      )}
    </div>
  );
}

export default TrialPeriodSettings;
