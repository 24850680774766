import React from "react";
import { Popup, Table } from "semantic-ui-react";
import { type ListRowProps } from "./types";
import classNames from "classnames";

import styles from "./DisabledRow.module.scss";

interface Props<T> extends ListRowProps<T> {
  tooltip?: string;
}

export const DisabledRow = <T,>(props: React.PropsWithChildren<Props<T>>) => {
  const { isDisabled, children, tooltip, className, item, ...other } = props;

  const row = (
    <Table.Row {...other} className={classNames(className, { [styles.disabled]: isDisabled })}>
      {children}
    </Table.Row>
  );

  return isDisabled && tooltip ? (
    <Popup className={styles.popup} trigger={row} content={tooltip} position={"top center"} inverted hideOnScroll />
  ) : (
    row
  );
};
