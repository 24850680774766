import {
  UpdateMsGraphIntegrationSettingsSuccess,
  UpdateGoogleSuccess,
  DeleteIntegrationsSuccess,
  GoogleIntegrationCreateSuccess,
  IntegrationCreateSuccess,
  UpdateIntegrationsIsActiveSuccess,
} from "features/Application/services/realTimeNotification/events/accounts/accountsEvents";

export const events = [
  UpdateMsGraphIntegrationSettingsSuccess,
  UpdateGoogleSuccess,
  DeleteIntegrationsSuccess,
  GoogleIntegrationCreateSuccess,
  IntegrationCreateSuccess,
  UpdateIntegrationsIsActiveSuccess,
];
