import { type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "../../../../../Application/slices/createFetchingItemsSlice";
import { type TemplateStatusEnum } from "../../../../../People/GroupTemplate/types/models";

export type AccountTemplate = {
  customers: number;
  dateCreated: string;
  dateModified: string;
  id: number;
  imageUrl: string | null;
  name: string;
  publisher: string;
  publisherId: number;
  status: TemplateStatusEnum;
  users: number;
  isOwn: boolean;
};

export interface TemplateCustomersState extends FetchingItemsState<AccountTemplate> {
  selected: number[];
}

export const initialState: TemplateCustomersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  selected: [],
};

const itemsSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Overview",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, setSelected } = itemsSlice.actions;

export default itemsSlice.reducer;
