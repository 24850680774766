import moment from "moment";
import { getDefaultSession } from "../EventDetails/Sessions/Utils/utils";
import { type RecurrenceSession, type SessionPayload, type SessionsPayload, type SessionView } from "../types/state";

const conversions = {
  getSessionsView(externalEventSessions: SessionsPayload): SessionView[] {
    return externalEventSessions?.sessions.map((item) => {
      return {
        id: item.id,
        webinarUrl: item.webinarUrl,
        startDate: moment.utc(item.sessionStartDate).format("MM/DD/YYYY"),
        startsAt: moment.utc("2021-01-01").add(item.startsAtTicksMinutes, "minutes").format("hh:mm A"),
        endsAt: moment.utc("2021-01-01").add(item.endsAtTicksMinutes, "minutes").format("hh:mm A"),
        isPeriodic: item.isPeriodic,
        timeZone: item.timeZone,
        recurrenceSession: recurrenceViewMapping(item.recurrenceSession),
      };
    });
  },

  getPayloadFromViewSessions(sessions: SessionView[]): SessionPayload[] {
    return sessions.map((item, index) => {
      let startsAt = moment.utc(`01/01/2021 ${item.startsAt}`);
      let endsAt = moment.utc(`01/01/2021 ${item.endsAt}`);
      return {
        id: item.id,
        webinarUrl: item.webinarUrl,
        sessionStartDate: moment.utc(`${item.startDate}`).toISOString(),
        startsAtTicksMinutes: startsAt.hours() * 60 + startsAt.minutes(),
        endsAtTicksMinutes: endsAt.hours() * 60 + endsAt.minutes(),
        orderIndex: index,
        isPeriodic: item.isPeriodic,
        timeZone: item.timeZone,
        recurrenceSession: recurrencePayloadMapping(item.recurrenceSession),
      };
    });
  },
};

export default conversions;

const recurrenceViewMapping = (recurrenceSession: RecurrenceSession | undefined) =>
  recurrenceSession?.seriesEndDate
    ? { ...recurrenceSession, seriesEndDate: moment.utc(recurrenceSession.seriesEndDate).format("MM/DD/YYYY") }
    : recurrenceSession;

const recurrencePayloadMapping = (recurrenceSession: RecurrenceSession | undefined) => {
  const defaultSession = getDefaultSession(true);
  if (!recurrenceSession) {
    return { ...defaultSession.recurrenceSession! };
  }

  const { seriesEndDate, weekDays, periodType, periodValue } = recurrenceSession;

  return {
    ...recurrenceSession,
    seriesEndDate: seriesEndDate ? moment.utc(seriesEndDate).toISOString() : seriesEndDate,
    weekDays: weekDays || [],
    periodType: periodType ?? defaultSession.recurrenceSession?.periodType,
    periodValue: periodValue || defaultSession.recurrenceSession?.periodValue,
  } as RecurrenceSession;
};
