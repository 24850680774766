import midnightService from "../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/threatDefenceEmailTemplateEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { type ThreatDefenceEmailTemplate, type ThreatDefenceEmailTemplateContent } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import azureStorageUtils from "../../../../../../utils/azureStorageUtils";
import { setEmailTemplateAction } from "./threatDefenceEmailTemplateDetailsActions";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import {
  EmailTemplateEditSuccess,
  EmailTemplateContentEditSuccess,
  EmailTemplatePublishSuccess,
  EmailTemplateLockSuccess,
  EmailTemplateDiscardSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createThreatDefenceEmailTemplate = (data: ThreatDefenceEmailTemplate) => {
  const begin = getActionBaseProvider(actionTypes.createThreatDefenceEmailTemplateBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceEmailTemplateSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceEmailTemplateFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.EmailTemplates, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceEmailTemplateEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishThreatDefenceEmailTemplateBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishThreatDefenceEmailTemplateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailTemplatePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.EmailTemplates, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceEmailTemplateSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishThreatDefenceEmailTemplateSuccess,
);

export const updateThreatDefenceEmailTemplate = (metadata: ThreatDefenceEmailTemplate) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceEmailTemplateBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceEmailTemplateFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: EmailTemplateEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceEmailTemplateAsync(metadata);
      dispatch(setEmailTemplateAction(metadata));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateThreatDefenceEmailTemplateContent = (data: ThreatDefenceEmailTemplateContent) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceEmailTemplateBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceEmailTemplateFailure);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceEmailTemplateSuccess);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: EmailTemplateContentEditSuccess }));
    try {
      await azureStorageUtils.uploadString(data.contentUrlWithSas, data.html);
      dispatch(success({ entityId: data.id }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateThreatDefenceEmailTemplateCommandSuccess = getActionProvider(
  actionTypes.updateThreatDefenceEmailTemplateSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const threatDefenceEmailTemplateUpdated = getActionProvider(
  actionTypes.threatDefenceEmailTemplateUpdated,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const resetThreatDefenceEmailTemplateEntityState = getActionBaseProvider(
  actionTypes.resetThreatDefenceEmailTemplateEntityState,
);

export const setEmailTemplateEntityIdAction = getActionProvider<number>(actionTypes.setEmailTemplateEntityId);

export const fetchDraftThreatDefenceEmailTemplateEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedThreatDefenceEmailTemplateBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedThreatDefenceEmailTemplateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailTemplateLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.EmailTemplates, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedTdEmailTemplateSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedThreatDefenceEmailTemplateSuccess,
);

export const fetchDiscardThreatDefenceEmailTemplateEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardThreatDefenceEmailTemplateBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardThreatDefenceEmailTemplateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailTemplateDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.EmailTemplates, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchDiscardThreatDefenceEmailTemplateSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchDiscardThreatDefenceEmailTemplateSuccess,
);
