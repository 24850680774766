import { type FC, useCallback } from "react";
import { type BaseEditor, type Descendant } from "slate";
import { Slate, Editable, type ReactEditor } from "slate-react";
import { Element } from "./Element";
import { Toolbar } from "./components/Toolbar";
import "./textEditor.scss";
import cn from "classnames";
import { type RenderElementProps, type RenderLeafProps } from "slate-react/dist/components/editable";

export interface Props {
  editor: ReactEditor & BaseEditor;
  value: Descendant[];
  placeholder?: string;
  propertyName?: string;
  showToolbar: boolean;
  isReadOnly: boolean;
  handleChange?: (event: any) => void;
  onChange: (value: Descendant[]) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
}

export const RichTextEditor: FC<Props> = ({
  editor,
  value,
  onChange,
  onBlur,
  propertyName,
  placeholder,
  showToolbar,
  isReadOnly,
  handleChange,
}) => {
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  return (
    <div
      className={cn({
        "rich-text-editor": showToolbar,
        disabled: isReadOnly && showToolbar,
      })}
    >
      <Slate editor={editor} value={value} onChange={onChange}>
        {showToolbar && <Toolbar />}
        <Editable
          readOnly={isReadOnly}
          onBlur={onBlur}
          onChange={handleChange}
          className="editor"
          name={propertyName}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
        />
      </Slate>
    </div>
  );
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
