import React from "react";
import cn from "classnames";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { Tooltip } from "components/common/tooltip";

import styles from "./Block.module.scss";

export interface BlockProps {
  title: string;
  before?: React.ReactNode;
  tooltip?: {
    content: React.ReactNode;
    width?: string;
  };
  styles?: { [key: string]: string };
  style?: React.CSSProperties;
}

export const Block = (props: React.PropsWithChildren<BlockProps>) => {
  const { styles: propStyles = {}, title, children, tooltip, before, style } = props;
  return (
    <div role="group" aria-label={title} className={cn(styles.root, propStyles.root)} style={style}>
      <div className={cn(styles.header, propStyles.header)}>
        {before}
        <div className={cn(styles.title, propStyles.title)}>
          <b>{title}</b>
          {tooltip && (
            <Tooltip
              hoverable
              target={<Icon className="info circle big" />}
              className={styles.tooltip}
              position="top left"
              content={tooltip.content}
              styles={{ width: tooltip.width }}
              showAlways
            />
          )}
        </div>
      </div>
      <div className={cn(styles.content, propStyles.content)}>{children}</div>
    </div>
  );
};
