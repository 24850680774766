import ModalSizes from "../../../enums/modalSizes";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";
import "./deleteAccountConfirmationModal.scss";
import { List } from "semantic-ui-react";

export interface DeleteAccountConfirmationModalProps {
  onCancel(): void;
  onContinue(): void;
  open: boolean;
}

const DeleteAccountConfirmationModal: React.FC<DeleteAccountConfirmationModalProps> = ({
  onCancel,
  onContinue,
  open,
}) => {
  return (
    <ActionConfirmationModal
      size={ModalSizes.small}
      open={open}
      onCancel={onCancel}
      onContinue={onContinue}
      title="Delete This Account?"
      withWarningLabel={false}
      confirmLabel="Delete"
    >
      <List.Header className="delete-account-modal-header">
        <b>Deleting this account will:</b>
      </List.Header>
      <List bulleted className="delete-account-modal-list">
        <List.Item>Delete any linked child accounts.</List.Item>
        <List.Item>Delete all account data.</List.Item>
        <List.Item>Delete all users and user data.</List.Item>
        <List.Item>Delete any custom content uploaded by this account.</List.Item>
      </List>
      <div className="delete-account-modal-desc">
        <b>This action cannot be undone</b>
      </div>
    </ActionConfirmationModal>
  );
};

export default DeleteAccountConfirmationModal;
