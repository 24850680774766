import React, { useState } from "react";
import { type DropdownProps, Icon, Dropdown } from "semantic-ui-react";
import cn from "classnames";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";

import "./multiStateDropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";

type BaseOption = {
  iconName?: string;
  icon?: IconProp;
  disabled?: boolean;
};
type Option<T> = BaseOption & { label: T };
type NestedOption<T> = BaseOption & { label: string; options: MultiStateDropdownOption<T>[] };

const isNested = <T,>(option: MultiStateDropdownOption<T>): option is NestedOption<T> => {
  return (option as NestedOption<T>).options !== undefined;
};

export type MultiStateDropdownOption<T> = Option<T> | NestedOption<T>;

export interface MultiStateDropdownProps<T> extends Omit<DropdownProps, "options"> {
  options: MultiStateDropdownOption<T>[];
  defaultOption: MultiStateDropdownOption<T>;
  onContextChange?: (context: T) => void;
  className?: string;
}

const MultiStateDropdown = <T extends string>(props: MultiStateDropdownProps<T>) => {
  const [selectedItem, setSelectedItem] = useState<MultiStateDropdownOption<T>>(props.defaultOption);

  const onChangeHandler = (option: Option<T>) => () => {
    if (isEqual(option, selectedItem)) return;
    setSelectedItem(option);
    props.onContextChange?.(option.label);
  };

  const renderContent = (label: string, iconName?: string, icon?: IconProp) => (
    <>
      {icon && <FontAwesomeIcon icon={icon} className={cn("icon", "big")} />}
      {iconName && <Icon className={cn(iconName)} size="big" />}
      <span className="text"> {label}</span>
    </>
  );

  const renderItem = (option: Option<T>) => (
    <Dropdown.Item
      disabled={option.disabled}
      active={selectedItem.label === option.label}
      onClick={onChangeHandler(option)}
      key={option.label}
      content={renderContent(option.label, option.iconName, option.icon)}
    />
  );

  const renderDropdownItem = ({ iconName, icon, label, options }: NestedOption<T>) => (
    <Dropdown
      key={label}
      item
      trigger={renderContent(label, iconName, icon)}
      icon={<Icon className={cn("right-icon", "fa-chevron-right")} />}
    >
      <Dropdown.Menu>{renderItems(options)}</Dropdown.Menu>
    </Dropdown>
  );

  const renderItems = (options: MultiStateDropdownOption<T>[]) => {
    return options.map((item) => (isNested(item) ? renderDropdownItem(item) : renderItem(item)));
  };

  const { icon, iconName } = selectedItem;

  return (
    <div className={cn("multi-state-dropdown", props.className)}>
      {icon && <FontAwesomeIcon icon={icon} className={cn("icon", "big")} />}
      {iconName && <Icon className={cn(iconName)} size="big" />}
      <Dropdown className={"dropdown-control"} fluid text={selectedItem.label}>
        <Dropdown.Menu className={"dropdown-menu"}>{renderItems(props.options)}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MultiStateDropdown;
