export enum AssessmentDetailsActionTypes {
  SaveAssessment = "SAVE_ASSESSMENT",
  SaveAssessmentTags = "SAVE_ASSESSMENT_TAGS",
  ClearAssessment = "CLEAR_ASSESSMENT",
  SaveContent = "SAVE_CONTENT",

  GetAssessmentBegin = "GET_ASSESSMENT_BEGIN",
  GetAssessmentSuccess = "GET_ASSESSMENT_SUCCESS",
  GetAssessmentFailure = "GET_ASSESSMENT_FAILURE",

  GetContentBegin = "GET_CONTENT_BEGIN",
  GetContentSuccess = "GET_CONTENT_SUCCESS",
  GetContentFailure = "GET_CONTENT_FAILURE",
}
