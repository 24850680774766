import { Component } from "react";
import { Table } from "semantic-ui-react";
import { Tooltip } from "../../../../components/common/tooltip";
import SortDirections from "../../../../enums/sortDirections";
import { type ColumnOptions } from "../../../../interfaces";
import { type Account } from "../../../../interfaces/Account";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import AccountInfo from "../AccountInfo";
import AccountEllipsisPopupButton from "./AccountEllipsisPopupButton";

import "./accountRow.scss";
import { PermissionTypeToString } from "../constants";

export interface AccountRowProps {
  account: Account;
  onDelete?: (id: number, name: string) => void;
  actionsDisabled?: boolean;
  onOpenMobo: (id: number) => void;
  url: string;
}

export interface AccountColumnOptions extends ColumnOptions {
  propName: string;
}

class AccountRow extends Component<AccountRowProps> {
  static columnOptions: AccountColumnOptions[] = [
    {
      name: "Name",
      width: 7,
      isSortable: true,
      propName: "name",
      className: "linked-account-name-header",
    },
    {
      name: "Account type",
      width: 5,
      isSortable: true,
      propName: "accountTypeName",
    },
    {
      name: "Permissions",
      width: 5,
      isSortable: true,
      propName: "permissionType",
    },
    {
      name: "Added",
      width: 3,
      isSortable: true,
      propName: "added",
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      isSortable: false,
      propName: "",
    },
  ];

  static defaultSorting = Object.freeze({
    columnName: "added",
    direction: SortDirections.Desc,
  });

  static getColumnPropertyName = (columnName: string) => {
    const column = AccountRow.columnOptions.find((c) => c.name.toLowerCase() === columnName.toLowerCase());
    return column!.propName;
  };

  render() {
    const { account, onDelete, actionsDisabled, onOpenMobo, url } = this.props;

    return (
      <>
        <Table.Cell width={AccountRow.columnOptions[0].width}>
          <AccountInfo account={account} url={url} />
        </Table.Cell>
        <Table.Cell width={AccountRow.columnOptions[1].width}>
          <Tooltip target={account.accountTypeName} content={account.accountTypeName} />
        </Table.Cell>
        <Table.Cell width={AccountRow.columnOptions[2].width}>
          {PermissionTypeToString[account.permissionType]}
        </Table.Cell>
        <Table.Cell width={AccountRow.columnOptions[3].width}>{dateTimeUtils.formatDate(account.added)}</Table.Cell>
        <Table.Cell className="align-right" width={AccountRow.columnOptions[4].width}>
          <AccountEllipsisPopupButton
            accountId={account.id}
            accountName={account.name}
            onOpenMobo={onOpenMobo}
            disabled={actionsDisabled || !account.canView}
            onDelete={onDelete}
            canView={account.canView}
            canDelete={account.canDelete}
            hasAssignedPacks={account.hasAssignedPacks}
          />
        </Table.Cell>
      </>
    );
  }
}

export default AccountRow;
