import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

const createFeedbackPageAction = (actionType: string) =>
  buildRootEntityActionType(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.FeedbackPages, actionType);

export const createFeedbackPageBegin = createFeedbackPageAction(entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN);
export const createFeedbackPageSuccess = createFeedbackPageAction(entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS);
export const createFeedbackPageFailure = createFeedbackPageAction(entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE);
export const updateFeedbackPageBegin = createFeedbackPageAction(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);
export const updateFeedbackPageCommandSuccess = createFeedbackPageAction(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);
export const updateFeedbackPageFailure = createFeedbackPageAction(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);
export const feedbackPageUpdated = createFeedbackPageAction(entityStateActionTypes.LOCKED_ENTITY_UPDATED);
export const resetFeedbackPageEntityState = createFeedbackPageAction(entityStateActionTypes.RESET_ENTITY_STATE);
export const setFeedbackPageEntityId = createFeedbackPageAction(entityStateActionTypes.SET_ENTITY_ID);
export const fetchLockedFeedbackPageBegin = createFeedbackPageAction(entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN);
export const fetchLockedFeedbackPageSuccess = createFeedbackPageAction(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);
export const fetchLockedFeedbackPageFailure = createFeedbackPageAction(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);
export const publishFeedbackPageBegin = createFeedbackPageAction(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);
export const publishFeedbackPageSuccess = createFeedbackPageAction(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishFeedbackPageFailure = createFeedbackPageAction(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);
export const discardFeedbackPageBegin = createFeedbackPageAction(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);
export const discardFeedbackPageFailure = createFeedbackPageAction(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
