import React from "react";
import { type FormikContextType } from "formik";

import GroupItem from "./GroupItem/GroupItem";

import { AddToLinkButton } from "../../../../../../../../components/buttons/linkButtons";
import {
  type ExternalTriggerGroup,
  type ExternalTriggerGroupView,
  type ExternalTrigger,
} from "../../../../types/externallTriggersTypes";
import { ValidatedForm } from "../../../../../../../../components/forms";

import styles from "./triggerGroup.module.scss";

export interface TriggerGroupsProps {
  isReadOnly: boolean;
  isLoading: boolean;
  formik: FormikContextType<ExternalTriggerGroupView>;
}

const TriggerGroups = (props: TriggerGroupsProps) => {
  const { formik, isReadOnly, isLoading } = props;

  const onGroupChange = (group: ExternalTriggerGroup<ExternalTrigger[]>, index: number) => {
    const externalTriggerGroups = [...formik.values.externalTriggerGroups];
    externalTriggerGroups[index] = group;
    formik.setValues({ externalTriggerGroups: externalTriggerGroups }, true);
  };

  const renderGroups = () => {
    return formik.values.externalTriggerGroups.map((group, index) => (
      <React.Fragment key={group.groupId || index}>
        <div className={styles.header}>
          <span>Define actions that will add a user to the flow or deliver flow content.</span>
          <AddToLinkButton text="Add Condition" isDisabled />
        </div>
        <GroupItem
          isValid={formik.isValid}
          onGroupChange={onGroupChange}
          group={group}
          isReadOnly={isReadOnly}
          index={index}
        />
      </React.Fragment>
    ));
  };

  return (
    <div className={styles.root}>
      <ValidatedForm
        className={styles.root}
        disablePreventTransitionPrompt
        formWidthComputer={16}
        dirty={formik.dirty}
        isLoading={isLoading}
        initialValues={formik.initialValues}
      >
        {renderGroups}
      </ValidatedForm>
    </div>
  );
};

export default TriggerGroups;
