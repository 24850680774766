import React from "react";

import { type RolePermissions } from "../../enums";
import RestrictedToAnyAccount from "../restricted/RestrictedToAnyAccount";
import Restricted from "../../features/Application/Restricted";
import AccessRestrictedMessage from "./AccessRestrictedMessage";
import { Outlet } from "react-router-dom";

export interface ProtectedRouteProps {
  permissions: RolePermissions[];
  anyAccount?: boolean;
  children?: React.ReactNode
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ permissions, anyAccount, children }) => {
  const RestrictedComponent = anyAccount ? RestrictedToAnyAccount : Restricted;

  return (
    <RestrictedComponent permissions={permissions} placeholder={<AccessRestrictedMessage />}>
      <>{children || <Outlet />}</>
    </RestrictedComponent>
  );
};
