import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type SessionReporting } from "../../types/state";
import {
  createLazyFetchingItemsSlice,
  type LazyFetchingItemsState,
} from "../../../../Application/slices/createLazyFetchingItemsSlice";

export interface SessionReportingState extends LazyFetchingItemsState<SessionReporting> {}

const initialState: SessionReportingState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
  areAllLoaded: false,
};

const eventSessionReportingSlice = createLazyFetchingItemsSlice<SessionReporting, SessionReportingState, {}>({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Events,
    name: "sessionReporting",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = eventSessionReportingSlice.actions;

export type EventSessionReportingState = ReturnType<typeof eventSessionReportingSlice.reducer>;

export const eventSessionReportingReducer = eventSessionReportingSlice.reducer;
