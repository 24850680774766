import { useCallback, useEffect, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import AttributeMapping from "../../CreateIdentityProvider/AttributeMapping/AttributeMapping";
import FormFooter from "../../../../components/forms/FormFooter";
import { type MappedField } from "../../CreateIdentityProvider/AttributeMapping/types";
import { type ClaimType, type IdentityProviderAttributeMapping } from "../../types";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";
import { isEmpty, isEqual } from "lodash";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { claimTypesLoadingSelector, claimTypesSelector } from "../../state/slices/claimTypesSlice";
import {
  identityProviderAttributeMappingLoadingSelector,
  identityProviderAttributeMappingSelector,
} from "../../state/slices/identityProviderAttributeMappingSlice";
import * as claimTypesThunk from "../../state/thunks/claimTypesThunk";
import * as identityProviderAttributeMappingThunk from "../../state/thunks/identityProviderAttributeMappingThunk";
import { bindActionCreators } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import * as notificationsActions from "../../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../../BackgroundTasks/state/backgroundTasksActions";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import { RestrictedResource } from "../../../../components";
import { type DistributedOpUpdateParams } from "../../../../interfaces/updateParams";

export interface EditAttributeMappingProps {
  identityId: number;
  isReadOnly: boolean;
  moboId?: number;
}

export type EditAttributeMappingPropsAll = EditAttributeMappingProps & PropsFromRedux;

const EditAttributeMapping: React.FC<EditAttributeMappingPropsAll> = ({
  identityId,
  moboId,
  claimTypes,
  fetchClaimTypes,
  isClaimTypesLoading,
  identityProviderAttributeMapping,
  fetchIdentityProviderAttributeMapping,
  isIdentityProviderAttributeMappingLoading,
  dispatchUpdateIdentityProviderAttrinbuteMapping,
  backgroundTasksActions: { addOperationDistributedOp },
  notificationsActions: { sendTransientNotification },
}) => {
  useEffect(() => {
    fetchClaimTypes();
  }, [fetchClaimTypes]);

  useEffect(() => {
    fetchIdentityProviderAttributeMapping(identityId, moboId);
  }, [fetchIdentityProviderAttributeMapping, identityId, moboId]);

  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [updateUserInfo, setUpdateUserInfo] = useState(false);
  const [mappedFields, setMappedFields] = useState<MappedField[]>([]);
  const [originalMappedFields, setOriginalMappedFields] = useState<MappedField[]>([]);

  const onCancel = () => {
    setMappedFields(originalMappedFields);
    setUpdateUserInfo(identityProviderAttributeMapping.updateUserInfo);
  };

  const onAttributeMappingUpdate = async (mapping: IdentityProviderAttributeMapping) => {
    const params: DistributedOpUpdateParams = {
      id: "EditIdentityProviderAttributeMapping",
      title: "Update identity provider attribute mappings",
      indeterminate: true,
      getOperationProps: async () => {
        const data = await identityProvidersDataService.updateIdentityProviderAttributeMapping(mapping, moboId);
        dispatchUpdateIdentityProviderAttrinbuteMapping(mapping);

        return data;
      },
      successTransientMessage: "Identity provider attribute  mappings update succeeded.",
      failureTransientMessage: "Identity provider attribute mappings update failed.",
    };

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: addOperationDistributedOp,
      sendTransientNotification,
    });
  };

  useEffect(() => {
    let fields: MappedField[] = [];
    let customMappedFields: MappedField[];

    if (claimTypes && claimTypes.length > 0) {
      if (
        identityProviderAttributeMapping.claimsMappings &&
        identityProviderAttributeMapping.claimsMappings.length > 0
      ) {
        let claimsMappedFields = identityProviderAttributeMapping.claimsMappings.map((x) => ({
          mapFrom: x.accountClaimTypeName,
          mapTo: claimTypes.find((ct: ClaimType) => ct.id === x.claimTypeId)!.alias,
          isCustomField: false,
          id: x.id,
        }));
        fields = claimsMappedFields;
      }

      if (
        identityProviderAttributeMapping.claimsCustomMappings &&
        identityProviderAttributeMapping.claimsCustomMappings.length > 0
      ) {
        customMappedFields = identityProviderAttributeMapping.claimsCustomMappings.map((x) => ({
          mapFrom: x.fieldName,
          mapTo: x.fieldValue,
          isCustomField: true,
          id: x.id,
        }));
        fields = fields.concat(customMappedFields);
      }
    }
    setMappedFields(fields);
    setOriginalMappedFields(fields);
    setUpdateUserInfo(identityProviderAttributeMapping.updateUserInfo);
  }, [identityProviderAttributeMapping, claimTypes]);

  const onSave = async () => {
    const claimsCustomMappings = mappedFields
      .filter((x) => x.isCustomField)
      .filter(isMappedFieldConfigured)
      .map((x) => ({
        fieldName: x.mapFrom!,
        fieldValue: x.mapTo!,
        id: x.id,
      }));

    const claimsMappings = mappedFields
      .filter((x) => !x.isCustomField)
      .filter(isMappedFieldConfigured)
      .map((x) => ({
        id: x.id,
        claimTypeId: claimTypes.find((ct: ClaimType) => ct.alias === x.mapTo)!.id,
        accountClaimTypeName: x.mapFrom!,
      }));

    const attributeMapping: IdentityProviderAttributeMapping = {
      id: identityId,
      claimsMappings,
      claimsCustomMappings,
      updateUserInfo,
    };

    onAttributeMappingUpdate(attributeMapping);
  };

  const toggleUpdateUserInfo = useCallback(() => {
    setUpdateUserInfo(!updateUserInfo);
  }, [updateUserInfo]);

  const isAttributeMappingChanged = useCallback(
    (fields: MappedField[]) => {
      const nonDefaultFields = fields.filter((f) => !isEmpty(f.mapFrom) || !isEmpty(f.mapTo));
      return !isEqual(nonDefaultFields, originalMappedFields);
    },
    [originalMappedFields],
  );

  const isMappedFieldConfigured = (field: MappedField) => !isEmpty(field.mapFrom) && !isEmpty(field.mapTo);

  const onMappedFieldsChange = useCallback((fields: MappedField[], isStepValid: boolean) => {
    setMappedFields(fields);
    setIsValid(isStepValid);
  }, []);

  useEffect(() => {
    setIsDirty(
      updateUserInfo !== identityProviderAttributeMapping.updateUserInfo || isAttributeMappingChanged(mappedFields),
    );
  }, [isAttributeMappingChanged, mappedFields, updateUserInfo, identityProviderAttributeMapping.updateUserInfo]);

  return (
    <RestrictedResource isAuthorized={identityProviderAttributeMapping.isAccessAuthorized}>
      <Dimmer.Dimmable
        as={Segment}
        dimmed={isIdentityProviderAttributeMappingLoading || isClaimTypesLoading}
        className="idp-edit-container"
      >
        <Dimmer active={isIdentityProviderAttributeMappingLoading || isClaimTypesLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="stretch scrollable-content edit-form idp-edit">
          <AttributeMapping
            updateUserInfo={updateUserInfo}
            onUpdateUserInfoChange={toggleUpdateUserInfo}
            onMappedFieldsChange={onMappedFieldsChange}
            mappedFields={mappedFields}
            claimTypes={claimTypes}
          />
        </div>
        <FormFooter
          isSaveBtnDisabled={!(isDirty && isValid)}
          isCancelBtnDisabled={!isDirty}
          onSave={onSave}
          onCancel={onCancel}
        />
        <PreventTransitionPrompt
          when={isDirty}
          title="Exit Without Saving?"
          message="You have unsaved changes. Are you sure you want to exit? All changes will be lost."
        />
      </Dimmer.Dimmable>
    </RestrictedResource>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    claimTypes: claimTypesSelector(state),
    isClaimTypesLoading: claimTypesLoadingSelector(state),
    identityProviderAttributeMapping: identityProviderAttributeMappingSelector(state),
    isIdentityProviderAttributeMappingLoading: identityProviderAttributeMappingLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchClaimTypes: bindActionCreators(claimTypesThunk.fetchClaimTypes, dispatch),
    fetchIdentityProviderAttributeMapping: bindActionCreators(
      identityProviderAttributeMappingThunk.fetchIdentityProviderAttributeMapping,
      dispatch,
    ),
    dispatchUpdateIdentityProviderAttrinbuteMapping: bindActionCreators(
      identityProviderAttributeMappingThunk.dispatchUpdateIdentityProviderAttributeMapping,
      dispatch,
    ),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditAttributeMapping);
export default ConnectedComponent;
