import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { some, flatMap } from "lodash";

import { type NotifyStepSettings } from "../../../../interfaces";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { AddPeopleToContentTypes, AssignmentPeopleContext, PriorityLevels } from "../../../../enums";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "../../../../enums/reducer";
import userListUtils from "../../../../utils/userListUtils";

import { type AddToContentModalState, type FlowPriorityItem, type PriorityItem } from "../../types";
import { type EntityToPeopleAssignments, type FlowPayload } from "../../../Library/PeopleAssignments/types";
import { type FlowAssignment } from "../../../Licensing/ContentAssignmentModalLicensingSteps/state/slices/packsContextSlice";
import { assignContentDistributed } from "../../../Library/PeopleAssignments/state/actions/commonActions";
import {
  getDaysToComplete,
  getDueDate,
  getFixedDueDate,
} from "features/Library/PeopleAssignments/utils/peopleAssignmentUtils";

const initialState: AddToContentModalState = {
  selectedPeopleIds: [],
  selectedContentType: AddPeopleToContentTypes.Flows,
  selectedPeopleType: AssignmentPeopleContext.User,
  showModal: false,
  priorityItems: [],
};

export const contentContextSelector = (state: RootState) => state.people.addToContentModal.selectedContentType;

const addToContentSlice = createSlice({
  name: `${ReducerNamespaceTypes.People}/${ReducerEntityPrefixTypes.AddContent}`,
  initialState,
  reducers: {
    setSelectedPeopleIds(state: AddToContentModalState, action: PayloadAction<number[]>) {
      state.showModal = true;
      state.selectedPeopleIds = action.payload;
    },
    setSelectedContentType(state: AddToContentModalState, action: PayloadAction<AddPeopleToContentTypes>) {
      state.selectedContentType = action.payload;
    },
    setSelectedPeopleType(state: AddToContentModalState, action: PayloadAction<AssignmentPeopleContext>) {
      state.selectedPeopleType = action.payload;
    },
    setPriorityItems(state: AddToContentModalState, action: PayloadAction<PriorityItem[]>) {
      state.priorityItems = action.payload;
    },
    resetPriorityItems(state: AddToContentModalState) {
      state.priorityItems.forEach((priorityItem) => {
        priorityItem.priorityLevel = PriorityLevels.none;
      });
    },
    resetModal: () => initialState,
  },
});

export const onSubmitModal =
  (useFlexibleDueDate: boolean, notificationSettings?: NotifyStepSettings) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedPeopleIds, priorityItems, selectedPeopleType } = getState().people.addToContentModal;
    const packContextItems = getState().licensing.contentPacks.items;
    const flowAssignments = getState().licensing.contentPacks.flowAssignments;

    if (flowAssignments.length) {
      const assignmentsList = mapFlowsToPeopleAssignments(
        selectedPeopleIds,
        flowAssignments,
        priorityItems as FlowPriorityItem[],
        selectedPeopleType,
        useFlexibleDueDate,
      );

      dispatch(
        assignContentDistributed({ peopleType: selectedPeopleType, items: assignmentsList, notificationSettings }),
      );
    } else {
      const assignmentItems = userListUtils.mapAssignmentItems(priorityItems, packContextItems);

      if (some(priorityItems) && some(selectedPeopleIds)) {
        const assignments = flatMap(selectedPeopleIds, (peopleId) =>
          assignmentItems.map((item) =>
            userListUtils.mapToAssignmentItem(peopleId, item, item.blockType, selectedPeopleType),
          ),
        );

        dispatch(
          assignContentDistributed({ peopleType: selectedPeopleType, items: assignments, notificationSettings }),
        );
      }
    }

    dispatch(resetModal());
  };

function mapFlowsToPeopleAssignments(
  peopleIds: number[],
  flowAssignments: FlowAssignment[],
  priorityItems: FlowPriorityItem[],
  peopleType: AssignmentPeopleContext,
  useFlexibleDueDate: boolean,
): EntityToPeopleAssignments<FlowPayload>[] {
  const assignments = flatMap(peopleIds, (peopleId) =>
    flowAssignments.map((item) => ({
      contentId: item.flowId,
      contentType: "flow",
      dueDate: null,
      packIds: item.packIds,
      peopleId: peopleId,
      peopleType: peopleType,
      priority: 0,
    })),
  );

  return assignments.map((assignmentItem) => {
    const priorityItem = priorityItems.find((pi) => pi.id === assignmentItem.contentId);
    return {
      ...assignmentItem,
      priority: priorityItem?.priorityLevel ?? PriorityLevels.none,
      dueDate:
        priorityItem?.priorityLevel === PriorityLevels.required ? getDueDate(useFlexibleDueDate, priorityItem) : null,
      daysToComplete:
        priorityItem?.priorityLevel === PriorityLevels.required
          ? getDaysToComplete(useFlexibleDueDate, priorityItem)
          : null,
      fixedDueDate:
        priorityItem?.priorityLevel === PriorityLevels.required
          ? getFixedDueDate(useFlexibleDueDate, priorityItem)
          : null,
      payload: {
        autoStart: Boolean(priorityItem?.autoStart),
      },
    };
  });
}

export const {
  setSelectedPeopleIds,
  setSelectedContentType,
  setSelectedPeopleType,
  setPriorityItems,
  resetModal,
  resetPriorityItems,
} = addToContentSlice.actions;

export default addToContentSlice.reducer;
export type addToContentSliceType = ReturnType<typeof addToContentSlice.reducer>;
