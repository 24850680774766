import { type SyntheticEvent } from "react";
import { type DropdownProps } from "semantic-ui-react";
import { type FiltersMap } from "../../utils/filterUtils";

import { ContextSwitcherDropdown, type ContextSwitcherOption } from "../dropdowns";
import SearchInput from "../searchInput/SearchInput";
import FilterButtons from "../itemsViewHeader/filterButtons/FilterButtons";

import "./componentsFilter.scss";

export interface Props {
  id: string;
  filterOptions?: ContextSwitcherOption[];
  onFilterChange?: (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onSearchChange?: (value: string) => void;
  defaultOption?: string;
  defaultSearchTerm?: string;
  onFilterButtonClick?: () => void;
  appliedFilters?: FiltersMap;
  onResetFilter?: () => void;
}

function ComponentsFilter(props: Props) {
  const {
    id,
    filterOptions,
    onFilterChange,
    onSearchChange,
    defaultOption,
    defaultSearchTerm,
    onFilterButtonClick,
    appliedFilters = {},
    onResetFilter,
  } = props;

  return (
    <div className="components-panel-filter">
      {filterOptions ? (
        <div className="filter-dropdown-container">
          <ContextSwitcherDropdown
            className="components-filter-dropdown"
            options={filterOptions}
            onChange={onFilterChange}
            key={id}
            defaultOption={defaultOption}
          />
        </div>
      ) : null}
      <SearchInput
        placeholder="Search"
        id={id}
        className="components-search"
        expandable
        onChange={onSearchChange}
        defaultValue={defaultSearchTerm}
      />
      <FilterButtons
        fluid
        className="components-filter"
        onlyIcon
        appliedFilter={appliedFilters}
        onlyResetIcon
        onFilterPress={onFilterButtonClick}
        onResetFilter={onResetFilter}
      />
    </div>
  );
}

export default ComponentsFilter;
