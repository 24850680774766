import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as usersAssignmentActionTypes from "../../../../Application/actions/usersAssignment/usersAssignmentActionTypes";

export const FLOW_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const FLOW_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const ASSET_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const ASSET_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const EMAIL_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const EMAIL_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const MESSAGE_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Messages,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const MESSAGE_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Messages,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const ASSESSMENT_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const ASSESSMENT_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const PDF_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Pdfs,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const PDF_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Pdfs,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const SURVEY_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Surveys,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const SURVEY_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Surveys,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);

export const EVENT_ASSIGN_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  usersAssignmentActionTypes.ASSIGN_USERS_PERMISSIONS,
);

export const EVENT_RESET_USERS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  usersAssignmentActionTypes.RESET_USERS_PERMISSIONS,
);
