import { isEqual } from "lodash";

import { type AppDispatch } from "../../../../../Application/globaltypes/redux";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/flowDesignerDefinitionSlice";
import { filterResponseTriggers } from "../../../utils/triggersDataUtils";
import { type FlowValidator } from "../../validator/FlowValidator";
import { type FlowDesignerData, type SavedFlowData, type SavedSurvey } from "../../types";
import flowsDataService from "../../../services/flowsDataService";
import { ExtrasTypes } from "../../Extras/types";

export const fetchFlowDefinition = (flowId: number, flowValidator: FlowValidator) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchBegin());

    const flowDataResponse = await flowsDataService.getFlowDesigner(flowId);
    const filteredFlowData = filterResponseTriggers(flowDataResponse.data);

    if (filteredFlowData) {
      flowValidator.validate(getFlowDesignerData(flowId, filteredFlowData));
      dispatch(fetchSuccess(filteredFlowData));
    }
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};

export const getFlowDesignerData = (flowId: number, flowData: SavedFlowData): FlowDesignerData => ({
  id: flowId,
  items: flowData.flowItems.map((it) => {
    const { entity, x, y, ...rest } = it;
    return {
      ...rest,
      position: { x, y },
      hasEntity: !!it.entity || isEqual(rest.entityType, ExtrasTypes.FlowEnd),
      branchingQuestion: (entity as SavedSurvey)?.branchingQuestion,
    };
  }),
  triggers: flowData.flowTriggers ?? [],
  headId: flowData.headId,
});
