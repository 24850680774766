import { type RootState } from "features/Application/globaltypes/redux";
import { useFormik } from "formik";
import { noop } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { type TemplateConfigurationView } from "../state/GroupTemplateSlice";
import Form from "components/forms/layout";
import { ValidatedForm } from "components/forms";
import { EndpointsToGetSasLink } from "enums";
import { templateSchema } from "utils/validationSchemas/templateValidationSchema";
import { FlowsAutoStartToggle } from "./FlowsAutoStartToggle/FlowsAutoStartToggle";

interface Props {
  onIsValidChange?: (isValid: boolean) => void;
  onIsDirtyChange: (isDirty: boolean) => void;
  isLoadingGroupInfo: boolean;
  disabled?: boolean;
}

export type ConfigurationRef = {
  resetForm: () => void;
  getValues: () => TemplateConfigurationView;
};

type AllProps = Props & PropsFromRedux;

export const Configuration = forwardRef<ConfigurationRef, AllProps>((props, ref) => {
  const { onIsDirtyChange, template, onIsValidChange, disabled } = props;

  const initialValues = useMemo(() => {
    return {
      name: template.name,
      description: template.description,
      imageUrl: template.imageUrl,
      isWithAutoEnroll: true,
      autoStartFlows: template.autoStartFlows,
    };
  }, [template]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: noop,
    validationSchema: templateSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { values, resetForm, isValid, dirty } = formik;

  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    onIsDirtyChange?.(dirty);
  }, [dirty, onIsDirtyChange]);

  useImperativeHandle(
    ref,
    () => ({
      resetForm: () => resetForm(),
      getValues: () => values,
    }),
    [resetForm, values],
  );

  return (
    <Form.Root
      preventTransition={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this template? All information entered will be lost.",
      }}
      dirty={dirty}
      isLoading={props.isLoadingGroupInfo}
    >
      <Form.Block title="Template Information">
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          placeholder="Enter Template Name"
          markAsRequired
          disabled={disabled}
          {...formik}
        />
        <ValidatedForm.TextAreaField
          label="Description"
          markAsRequired
          value={values.description}
          propertyName="description"
          placeholder="Enter Brief Description"
          disabled={disabled}
          {...formik}
        />
        <ValidatedForm.ImagePreview
          label="Image"
          value={values.imageUrl}
          propertyName="imageUrl"
          endpointToGetSasLink={EndpointsToGetSasLink.People.TempImages}
          shouldValidate={false}
          className="custom-image-preview"
          headerPlaceholder="Upload Image"
          placeholder="PNG, JPG, GIF up to 5MB"
          combinedIcon
          disabled={disabled}
          {...formik}
        />
      </Form.Block>
      <Form.Block title="Settings">
        <FlowsAutoStartToggle {...formik} />
      </Form.Block>
    </Form.Root>
  );
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;
  return {
    groupId: groupTemplate.base.groupId,
    template: groupTemplate.base.configuration,
  };
};

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Configuration);
export default ConnectedComponent;
