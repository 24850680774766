import { Button } from "semantic-ui-react";
import { type RenderModalActionsParams } from "./PacksContextStep";

export const getPacksContextStepModalActions = (params: RenderModalActionsParams) => {
  return (nextStep: () => void, prevStep: () => void) => {
    return () => {
      const { isDataValid, onIsDataValidChange } = params;
      return (
        <>
          <Button
            primary
            className="previous"
            content="Previous"
            onClick={() => {
              onIsDataValidChange(true);
              prevStep();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            onClick={() => {
              onIsDataValidChange(false);
              nextStep();
            }}
            disabled={!isDataValid}
          />
        </>
      );
    };
  };
};
