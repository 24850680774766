import { connect, type ConnectedProps } from "react-redux";
import { useMemo } from "react";

import { type RootState } from "../../../../features/Application/globaltypes/redux";
import { imageUtils } from "utils";

import environmentConfig from "configuration/environmentConfig";

import styles from "./preview.module.scss";

export const Preview = ({ headline, body, destinationUrl, callToAction, imageUrl }: PropsFromRedux) => {
  const bodyParagraphs = useMemo(() => body?.split("\n"), [body]);
  const formattedImageUrl = useMemo(() => imageUtils.formatStorageUrl(imageUrl), [imageUrl]);
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        {headline && <h4 className={styles.heading}>{headline}</h4>}
        {imageUrl && <img className={styles.image} src={formattedImageUrl} alt="preview" />}
        {bodyParagraphs.map((paragraph, index) => (
          <p key={`paragraph_${index}`} className={styles.body}>
            {paragraph}
          </p>
        ))}
        <div className={styles["cta-container"]}>
          {callToAction && (
            <a target="_blank" rel="noreferrer" className={styles["cta-btn"]} href={destinationUrl}>
              <img
                src={`${environmentConfig.systemNotificationsImageStorageUrl}/system-notification-email-templates/messages/externalLinkIcon.png`}
                alt="external link icon"
                className={styles.externalLinkIcon}
              />
              {callToAction}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapState = (state: RootState) => ({
  ...state.systemNotifications.notifyStep.teamsForm,
});

const connector = connect(mapState);

export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Preview);
export default ConnectedComponent;
