import { type FC, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, type ConnectedProps } from "react-redux";

import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import {
  fetchApiClients,
  createApiClient,
  updateApiClientStatus,
  regenerateApiClientSecret,
} from "../state/thunks/apiKeysThunk";
import { ApiConfiguration } from "./ApiConfiguration/ApiConfiguration";
import { selectApiClient } from "../state/selectors/apiSelectors";
import { ApiAccessType, type ApiClient } from "../../types";
import { useParams } from "react-router-dom";
import "./ApiKeys.scss";
import { Divider } from "semantic-ui-react";

interface OwnProps {
  readonly?: boolean;
}

export type Props = OwnProps & PropsFromRedux;

export const ApiKeys: FC<Props> = ({
  loading,
  readonly,
  readOnlyClient,
  fullClient,
  onFetchApiClients,
  onCreateApiClient,
  onUpdateApiClientStatus,
  onRegenerateApiClientSecret,
}) => {
  const { moboId: accountId } = useParams();
  useEffect(() => {
    onFetchApiClients(accountId);
  }, [onFetchApiClients, accountId]);

  const renderClientConfig = (label: string, apiAccessType: ApiAccessType, client?: ApiClient) => (
    <ApiConfiguration
      data-testid={`api-config-${apiAccessType}`}
      label={label}
      enabled={client?.enabled ?? false}
      credentials={client}
      loading={loading || (client?.pendingState?.loading ?? false)}
      readonly={readonly}
      onEnabledChange={(enabled) => {
        if (client) {
          onUpdateApiClientStatus({ moboId: client.id, enabled });
        } else {
          onCreateApiClient({ apiAccessType, moboId: accountId });
        }
      }}
      onSecretRegenerate={() => {
        if (client) {
          onRegenerateApiClientSecret(client.id);
        }
      }}
    />
  );

  return (
    <div className="api-keys">
      <Divider />
      {renderClientConfig("Read Only Access", ApiAccessType.ReadOnly, readOnlyClient)}
      {renderClientConfig("Full Access", ApiAccessType.Full, fullClient)}
      <h3>ACCEPTANCE OF TERMS OF USE</h3>
      <div className="disclaimer">
        By accessing or using our APIs, you are agreeing to our{" "}
        <a href="https://legal.brainstorminc.com/#api-terms" target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>
        .
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.accounts.apiKeys.isLoading,
  readOnlyClient: selectApiClient(state, ApiAccessType.ReadOnly),
  fullClient: selectApiClient(state, ApiAccessType.Full),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onFetchApiClients: bindActionCreators(fetchApiClients, dispatch),
  onCreateApiClient: bindActionCreators(createApiClient, dispatch),
  onUpdateApiClientStatus: bindActionCreators(updateApiClientStatus, dispatch),
  onRegenerateApiClientSecret: bindActionCreators(regenerateApiClientSecret, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ApiKeys);
export default ConnectedComponent;
