import React, { type FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { type FilterFormPropsBase } from "../types/props";
import { getMessagesFilters } from "./messagesFilters";
import { type MessageFiltersEnum } from "../../../features/Library/Communications/Messages/types/models";

export interface MessagesFilterFormProps extends FilterFormPropsBase<MessageFiltersEnum> {
  disableDateFilters: boolean;
}

const MessagesFilterForm: FC<MessagesFilterFormProps> = ({
  filterOptions,
  filter,
  updateFilter,
  disableDateFilters,
}) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getMessagesFilters(disableDateFilters)}
  />
);

export default MessagesFilterForm;
