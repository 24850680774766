import moboConfigurator from "moboConfigurator";
import { Fragment, type ReactNode, useCallback, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";

export interface BreadcrumbElement {
  text: ReactNode;
  action?: () => void;
}

// Override and reset is false by default
export type RegisterBreadcrumbCallback = (crumb: BreadcrumbElement, overrideAndReset?: boolean) => void;

export const usePerformanceBreadcrumbs = (initialText: string = "Summary") => {
  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbElement[]>([]);

  const handleBreadcrumbClick = useCallback(
    (breadcrumbIndex: number) => {
      const funcToRun = breadCrumbs[breadcrumbIndex].action;
      setBreadcrumbs((b) => {
        let returnVal = b.slice(0, breadcrumbIndex + 1);
        returnVal[returnVal.length - 1].action = undefined;
        return returnVal;
      });
      funcToRun?.();
    },
    [breadCrumbs],
  );

  const registerBreadcrumb: RegisterBreadcrumbCallback = useCallback(
    (next: BreadcrumbElement, overrideAndReset = false) => {
      setBreadcrumbs((prev) => {
        let returnVal: BreadcrumbElement[] = [];
        if (prev.length === 0 || overrideAndReset) {
          returnVal.push({ text: initialText, action: next.action });
          returnVal.push({ text: next.text });
        } else {
          returnVal = [...prev, { text: next.text }];
          returnVal[returnVal.length - 2].action = next.action;
        }

        return returnVal;
      });
    },
    [initialText],
  );

  const domElements = useMemo<React.ReactElement[]>(
    () =>
      breadCrumbs.map((breadCrumb, i) => {
        const key = `${breadCrumb.text}_${i}`;
        if (i === breadCrumbs.length - 1) {
          return <span key={key}>{breadCrumb.text}</span>;
        }
        return (
          <Fragment key={key}>
            <a
              href={moboConfigurator.withMobo(".")}
              onClick={(e) => {
                e.preventDefault();
                handleBreadcrumbClick(i);
              }}
            >
              {breadCrumb.text}
            </a>
            <span>
              &nbsp;
              <Icon className="chevron right" size="mini" />
              &nbsp;
            </span>
          </Fragment>
        );
      }),
    [breadCrumbs, handleBreadcrumbClick],
  );

  const hasBreadcrumbsToDisplay = useMemo(() => breadCrumbs.length > 1, [breadCrumbs]);

  const resetBreadcrumbs = useCallback(() => setBreadcrumbs([]), []);

  return {
    domElements,
    registerBreadcrumb,
    hasBreadcrumbsToDisplay,
    resetBreadcrumbs,
  };
};
