import React, { type FC, useState, useEffect } from "react";
import { isEmpty, noop } from "lodash";
import { Button } from "components/buttons/button/Button";
import { type Filters } from "../../../utils/queryUtils";
import PackGridStep from "../../assignmentModals/packAssignmentModal/packAssignmentSteps/packGridStep/PackGridStep";
import SortOptions from "../../../enums/SortOptions";
import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";
import {
  packsModalSearchSelector,
  setTerm,
} from "../../../features/Licensing/Packs/state/slices/availablePacksSearchSlice";
import { connect, type ConnectedProps } from "react-redux";
import sortOptions from "../../../features/Licensing/Packs/Overview/sortOptions";
import {
  resetAppliedFilter,
  setAppliedFilter,
  appliedFiltersSelector,
  isLoadingSelector,
  filterOptionsSelector,
} from "../../../features/Licensing/Packs/state/slices/accountPacksFiltersSlice";
import PacksOwnership from "../../../enums/licensing/packsOwnership";
import { reset } from "../../../features/Licensing/Packs/state/slices/packsOverviewSlice";
import { fetchOverviewGridPacks } from "../../../features/Licensing/Packs/state/thunks/packsOverviewThunk";
import { type PacksRequest } from "../../../features/Licensing/Packs/types/requests";
import ConfirmationModalBase from "../ConfirmationModalBase/ConfirmationModalBase";
import { getModalMeta } from "./addAssetToPackConfirmationModalUtils";
import ModalSizes from "../../../enums/modalSizes";
import packsDataService from "../../../features/Licensing/Packs/services/packsDataService";
import backgroundTask from "../../../features/BackgroundTasks/backgroundTask";
import { bindActionCreators } from "redux";
import * as notificationOperations from "../../../features/Notifications/state/notificationsActions";
import * as backgroundTaskOperations from "../../../features/BackgroundTasks/state/backgroundTasksActions";
import CreatePackButton from "./CreatePackButton";
import ModalWithSteps from "../ModalWithSteps";
import SearchInput from "../../searchInput/SearchInput";
import { RolePermissions } from "enums";
import { useRestrictedCheck } from "features/Application/hooks";

export interface Props {
  showModal: boolean;
  onClose: () => void;
  onComplete: () => void;
  contentType: string;
  selectedItemIds: number[];
}

export type AddAssetToPackModalAllProps = Props & PropsFromRedux;
export const AddAssetsToPackModal: FC<AddAssetToPackModalAllProps> = ({
  showModal,
  onClose,
  onComplete,
  permissions,
  selectedItemIds,
  contentType,
  packActions,
  filterActions,
  packsSearch,
  availablePacks,
  packsFilterOptions,
  packsAppliedFilter,
  notificationsActions,
  backgroundTasksActions,
  accountId,
}) => {
  const [selectedIds, setSelectionIds] = useState<number[]>([]);
  const [orderBy, setOrderBy] = useState<string>(SortOptions.CreatedDateDesc);
  const [confirmationModalOpen, setConfirmationModal] = useState<boolean>(false);

  const selectedPacks = availablePacks.items.filter((pack) => selectedIds.some((id) => pack.id === id));
  const confirmationModalMeta = getModalMeta(selectedPacks);
  const hasManagePermission = useRestrictedCheck([RolePermissions.PacksManage, RolePermissions.FlowsCreate]);

  useEffect(() => {
    showModal && resetPacks({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (isEmpty(selectedIds)) {
      const defaultPackIds = availablePacks.items.filter((item) => item.isDefault).map((pack) => pack.id);
      setSelectionIds(defaultPackIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePacks]);

  const resetPacks = (filters: Filters, orderBy?: string, searchTerm?: string) => {
    setSelectionIds([]);
    packActions.resetPacks();
    loadPacksForModal(0, filters, orderBy, searchTerm);
  };
  const loadItemsToAddPage = () => {
    loadPacksForModal(availablePacks.items.length, packsAppliedFilter);
  };
  const loadPacksForModal = (skip: number, filter: Filters, ordering?: string, searchTerm?: string) => {
    const filtersWithOwnership = {
      ...filter,
      ownership: PacksOwnership.Own,
    };

    packActions.fetchPacks({
      accountId,
      skip,
      orderBy: ordering ?? orderBy,
      filters: filtersWithOwnership,
      searchTerm: searchTerm ?? packsSearch.term,
    });
  };

  const applyFilter = (filter: Filters) => {
    resetPacks(filter);
    filterActions.applyPacksFilter(filter);
  };

  const resetFilter = () => {
    if (!isEmpty(packsAppliedFilter)) {
      resetPacks({});
      filterActions.resetPacksFilter();
    }
  };

  const onSearchChange = (search: string) => {
    resetPacks(packsAppliedFilter, undefined, search);
    packActions.setPacksSearch(search);
  };

  const getSortOptionWithSelected = () => {
    const selectedOption = orderBy;
    sortOptions.forEach((option) => (option.default = selectedOption === option.value));
    return sortOptions;
  };
  const onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    setOrderBy(data.value);
    resetPacks(packsAppliedFilter, data.value);
  };

  const resetModal = () => {
    packActions.resetPacks();
    filterActions.resetPacksFilter();
    setSelectionIds([]);
    setOrderBy(SortOptions.CreatedDateDesc);
    packActions.setPacksSearch("");
  };

  const onCloseModal = () => {
    resetModal();
    onClose();
  };
  const onFinishClick = () => {
    setConfirmationModal(true);
  };
  const onSubmit = async () => {
    onCloseModal();
    setConfirmationModal(false);
    onComplete();
    await handleSubmit();
  };

  const handleSubmit = async () => {
    const params = {
      id: "AddAssetsToPack",
      title: "Adding assets to packs",
      onCompleted: noop,
      successTransientMessage: "Content added.",
      failureTransientMessage: "Failed to add asset(s).",
      getMessageIds: async () => {
        const { data } = await packsDataService.addContentToPacks(selectedIds, selectedItemIds, contentType);
        return data.messageIds;
      },
    };
    const { addOperationV1 } = backgroundTasksActions;
    const { sendTransientNotification } = notificationsActions;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  const renderModalActions = () => {
    return () => {
      return (
        <>
          <Button basic color="blue" className="cancel" content="Cancel" onClick={onCloseModal} />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={selectedIds.length === 0}
            onClick={onFinishClick}
          />
        </>
      );
    };
  };

  return (
    <>
      <ModalWithSteps className="pack-assignment-modal" scrolling showModal={showModal} onCancel={onCloseModal}>
        <PackGridStep
          header="My Packs"
          renderModalActions={renderModalActions}
          selectedIds={selectedIds}
          onSelectedItemsChanged={(ids: number[]) => setSelectionIds(ids)}
          assignedAccountId={accountId}
          applyFilter={applyFilter}
          loadItemsToAddPage={loadItemsToAddPage}
          blur
          onSortOptionsChanged={onSortOptionsChanged}
          resetFilter={resetFilter}
          accountId={accountId}
          appliedFilter={packsAppliedFilter}
          filterOptions={packsFilterOptions}
          isLoading={availablePacks.isLoading}
          areAllLoaded={availablePacks.areAllLoaded}
          items={availablePacks.items.map((item: any) => ({
            ...item,
          }))}
          itemsCount={availablePacks.itemsCount}
          sortOptions={getSortOptionWithSelected()}
          renderSearch={() => (
            <SearchInput placeholder="Search for packs..." onChange={onSearchChange} defaultValue={packsSearch?.term} />
          )}
          isPacksModalFiltered={!isEmpty(packsSearch?.term) || !isEmpty(packsAppliedFilter)}
          createPackButton={
            hasManagePermission
              ? () => {
                  return <CreatePackButton />;
                }
              : undefined
          }
        />
      </ModalWithSteps>

      <ConfirmationModalBase
        isOpen={confirmationModalOpen}
        title={confirmationModalMeta.title}
        content={confirmationModalMeta.text}
        confirmButtonLabel="Add"
        size={ModalSizes.tiny}
        onCancel={() => setConfirmationModal(false)}
        onClose={() => setConfirmationModal(false)}
        onConfirm={onSubmit}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    permissions: state.userProfile.permissions,
    availablePacks: state.packs.overviewGrid,
    packsSearch: packsModalSearchSelector(state),
    packsFilterOptions: filterOptionsSelector(state),
    packsAppliedFilter: appliedFiltersSelector(state),
    packsFilterIsLoading: isLoadingSelector(state),
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    packActions: {
      fetchPacks: (requestData: PacksRequest) => dispatch(fetchOverviewGridPacks(requestData)),
      setPacksSearch: (searchTerm: string) => dispatch(setTerm(searchTerm)),
      resetPacks: () => dispatch(reset()),
    },
    filterActions: {
      applyPacksFilter: (filters: any) => dispatch(setAppliedFilter(filters)),
      resetPacksFilter: () => dispatch(resetAppliedFilter()),
    },
    notificationsActions: bindActionCreators(notificationOperations, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTaskOperations, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AddAssetsToPackModal);
export default ConnectedComponent;
