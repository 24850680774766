import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { WizardWrapper as Wizard } from "../../../../WizardWrapper";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import Content from "../EmailDetails/Content/Content";
import { type CreateEmailTemplateState } from "./types";
import * as emailTemplateEntityStateActions from "../state/actions/threatDefenceEmailTemplateEntityStateActions";
import * as emailTemplateDetailsActions from "../state/actions/threatDefenceEmailTemplateDetailsActions";
import Configure from "../EmailDetails/Configure/Configure";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import navigationUtils from "../../../../../utils/navigationUtils";
import { withRouter, type WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const emailTemplatesOverviewUrl = "/content/simulated-phishing/email-templates";

export type CreateEmailTemplatePropsAll = PropsFromRedux & WithRouterProps;

export class CreateThreatDefenceEmailTemplate extends Component<CreateEmailTemplatePropsAll, CreateEmailTemplateState> {
  private stepsManager = new WizardStepsManager();

  constructor(props: CreateEmailTemplatePropsAll) {
    super(props);
    this.state = {
      isValid: false,
      canPublish: false,
    };
  }

  onCancel = () => {
    navigationUtils.goBackOrDefault(
      this.props.location,
      this.props.navigate,
      "/content/simulated-phishing/email-templates",
    );
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe([rtnEvents.EmailTemplatePublishSuccess], this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.EmailTemplatePublishSuccess], this.onPublishedEvent);
  }

  componentDidUpdate(prev: CreateEmailTemplatePropsAll) {
    const { id } = this.props;
    const emailTemplateWasJustCreated = (prevPropsId: number, newPropsId: number): boolean =>
      prevPropsId === 0 && newPropsId > 0;

    if (emailTemplateWasJustCreated(prev.id, id)) {
      this.props.detailsActions.getThreatDefenceEmailTemplateContent(id, true);
    }
  }

  onPublishedEvent = () => this.props.navigate(emailTemplatesOverviewUrl);

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.canPublish !== isValid) {
      this.setState({ canPublish: isValid });
    }
  };

  render() {
    const { isValid, canPublish } = this.state;
    const { isSaving, dateModified } = this.props;
    return (
      <Wizard
        title="Create Email Template"
        titleForGA="Create Phishing Email Template"
        finishButtonLabel="Publish"
        onCancel={this.onCancel}
        onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
        onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
        onFinishAsync={this.stepsManager.onFinish}
        isSaveInProgress={isSaving}
        progressSavedDate={dateModified}
        isFinishButtonDisabled={!canPublish}
      >
        <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 0)}
            onIsValidChange={this.onIsValidChange}
            disabled={false}
          />
        </Wizard.Step>
        <Wizard.Step label="Content" className="scrollable-content" isLocked={!isValid} required>
          <Content
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 1)}
            onIsValidChange={this.onContentIsValidChange}
            disabled={false}
            {...this.props}
          />
        </Wizard.Step>
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isSaving:
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.isEntityCommandInProgress,
  dateModified:
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.lastModifiedDateTime,
  id: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.entityId,
  content: {
    contentUrlWithSas:
      state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.contentUrlWithSas,
  },
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(emailTemplateEntityStateActions, dispatch),
  detailsActions: bindActionCreators(emailTemplateDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(CreateThreatDefenceEmailTemplate));
export default ConnectedComponent;
