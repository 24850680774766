import type { ColumnOptions } from "../../../interfaces";
import type { IntegrationWithCompositeId } from "./types";

interface IntegrationColumnOption extends ColumnOptions {
  propName: keyof IntegrationWithCompositeId | null;
}

export const columnOptions: IntegrationColumnOption[] = [
  {
    name: "Integration",
    width: 3,
    isSortable: true,
    propName: "type",
  },
  {
    name: "Last Sync Date",
    width: 3,
    isSortable: true,
    propName: "lastSyncDate",
  },
  {
    name: "Status",
    width: 3,
    isSortable: true,
    propName: "status",
  },
  {
    name: "Details",
    width: 6,
    isSortable: true,
    propName: "details",
  },
  {
    name: "",
    width: 1,
    isSortable: false,
    propName: null,
  },
];
