import { useCallback, useEffect, useRef } from "react";
import { useNodesInitialized, useStore } from "reactflow";

import { type FlowValidationError, type IFixedError, ValidatorErrorTypes } from "../../validator/flowValidatorReducer";
import useBaseErrorHandler from "./useBaseErrorHandler";
import useErrorNavigationUtils from "./useErrorNavigationUtils";
import useSectionHeaderErrorHandler from "./useSectionHeaderErrorHandler";
import useWithinErrorHandler from "./useWithinErrorHandler";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useErrorNavigationState } from "../Providers/hooks/useErrorNavigationState";
import { useErrorNavigationActions } from "../Providers/hooks/useErrorNavigationActions";

interface IUseNavigation {
  currentError: FlowValidationError | null;
  navigateTo: (error: FlowValidationError) => void;
}

export function useNavigation(
  errors: FlowValidationError[] | undefined,
  fixedErrors: IFixedError[],
  triggerView: boolean,
  showErrorMessagesBar: boolean,
  showSectionHeaderPanel: boolean,
): IUseNavigation {
  const isDefaultErrorSelected = useRef(false);
  const { currentError } = useErrorNavigationState();
  const { setCurrentError } = useErrorNavigationActions();
  const { setElementInvalid, setSectionHeaderView } = useReactFlowCanvasActions();
  const nodesInitialized = useNodesInitialized();

  const handleBaseError = useBaseErrorHandler();
  const handleWithinError = useWithinErrorHandler();
  const handleSectionHeaderError = useSectionHeaderErrorHandler();

  const { getItemId, getTargetNode } = useErrorNavigationUtils();

  const resetSelectedElements = useStore((store) => store.resetSelectedElements);

  const prevState = useRef({
    triggerView: false,
    showErrorMessagesBar: false,
    showSectionHeaderPanel: false,
  });

  const deselectInvalidElement = useCallback(() => {
    setElementInvalid("");
    setCurrentError(null);
  }, [setElementInvalid, setCurrentError]);

  const navigateTo = useCallback(
    (error: FlowValidationError) => {
      const errorNodeId = getItemId(error);

      if (!showErrorMessagesBar || triggerView || !errorNodeId) {
        return;
      }

      if (
        error.type === ValidatorErrorTypes.ItemError ||
        error.type === ValidatorErrorTypes.StartError ||
        error.type === ValidatorErrorTypes.TriggerError ||
        error.type === ValidatorErrorTypes.ConnectionActionTriggersError ||
        error.type === ValidatorErrorTypes.DeletedItemError ||
        error.type === ValidatorErrorTypes.FlowEndError
      ) {
        handleBaseError(error);
      } else if (error.type === ValidatorErrorTypes.WithinError) {
        handleWithinError(error);
      } else if (
        error.type === ValidatorErrorTypes.SectionHeaderNameError ||
        error.type === ValidatorErrorTypes.SectionHeaderDescriptionError
      ) {
        handleSectionHeaderError(error);
      }
    },
    [showErrorMessagesBar, triggerView, getItemId, handleBaseError, handleWithinError, handleSectionHeaderError],
  );

  // if errors are fixed then clear invalid state for canvas elements
  useEffect(() => {
    if (!errors?.find((e: FlowValidationError) => e.id === currentError?.id)) {
      deselectInvalidElement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedErrors]);

  useEffect(() => {
    // remove selection from the item when we switch to error fix mode
    if (
      triggerView &&
      showErrorMessagesBar &&
      prevState.current.triggerView &&
      !prevState.current.showErrorMessagesBar
    ) {
      resetSelectedElements();
    }

    // close secton header panel when go to error fix mode
    if (
      showSectionHeaderPanel &&
      showErrorMessagesBar &&
      prevState.current.showSectionHeaderPanel &&
      !prevState.current.showErrorMessagesBar
    ) {
      setSectionHeaderView(undefined);
    }

    // remove selection from the item with Within error when switch back to error inspector from trigger view
    if (
      !triggerView &&
      showErrorMessagesBar &&
      prevState.current.triggerView &&
      prevState.current.showErrorMessagesBar &&
      currentError?.type === ValidatorErrorTypes.WithinError
    ) {
      deselectInvalidElement();
    }

    // remove selection from the item with Section Header error when switch back to error inspector from section header panel
    if (
      !showSectionHeaderPanel &&
      showErrorMessagesBar &&
      prevState.current.showSectionHeaderPanel &&
      prevState.current.showErrorMessagesBar &&
      (currentError?.type === ValidatorErrorTypes.SectionHeaderNameError ||
        currentError?.type === ValidatorErrorTypes.SectionHeaderDescriptionError)
    ) {
      deselectInvalidElement();
    }

    prevState.current = {
      triggerView,
      showErrorMessagesBar,
      showSectionHeaderPanel,
    };
  }, [
    currentError,
    triggerView,
    showErrorMessagesBar,
    setSectionHeaderView,
    showSectionHeaderPanel,
    resetSelectedElements,
    deselectInvalidElement,
  ]);

  useEffect(() => {
    isDefaultErrorSelected.current = false;
  }, [showErrorMessagesBar]);

  // by default navigate to the first error
  useEffect(() => {
    if (!nodesInitialized || !errors || !errors.length || isDefaultErrorSelected.current) {
      return;
    }

    const error = errors[0];
    const errorNodeId = getItemId(error);

    if (!errorNodeId) {
      return;
    }

    const targetNode = getTargetNode(errorNodeId);

    if (
      targetNode &&
      currentError === null &&
      [
        ValidatorErrorTypes.ItemError,
        ValidatorErrorTypes.StartError,
        ValidatorErrorTypes.TriggerError,
        ValidatorErrorTypes.ConnectionActionTriggersError,
        ValidatorErrorTypes.DeletedItemError,
        ValidatorErrorTypes.FlowEndError,
      ].includes(error.type)
    ) {
      isDefaultErrorSelected.current = true;
      navigateTo(error);
    }
  }, [errors, currentError, getItemId, getTargetNode, navigateTo, nodesInitialized]);

  return { currentError, navigateTo };
}
