import GroupInfoCell from "../../../../../components/groupInfoCell/GroupInfoCell";
import { type ColumnOption } from "../../../../../interfaces/columnOptions";
import { type PeopleAssignment } from "../../types";
import { type Group } from "../../../../../interfaces";

export enum PeopleAssignmentColumns {
  Name = "Name",
}

export const ColumnToParamMap: {
  [P in Lowercase<PeopleAssignmentColumns>]: string;
} = {
  name: "firstName",
};

export interface PeopleGroupAssignment extends PeopleAssignment, Group {}

export function getGroupColumnOptions<T extends PeopleGroupAssignment>(): ColumnOption<T>[] {
  return [
    {
      name: PeopleAssignmentColumns.Name,
      width: 5,
      isSortable: false,
      render: (item) => <GroupInfoCell deepLink={!item.isInherited} group={item} />,
    },
  ];
}
