import { type FC, useEffect } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";

import GroupsListContainer from "./GroupsListContainer/GroupsListContainer";
import Restricted from "../../../../../../Application/Restricted";
import ContentGroupsNoResults from "../../../../../Common/Fields/ContentGroupsNoResults";

import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";

import { fetchVideoGroups } from "../../../../state/thunks/videoGroupsThunk";
import { resetSelectedItems } from "../../../../state/slices/videoGroupsSlice";
import {
  PeopleAssignmentColumns,
  ColumnToParamMap,
  getGroupColumnOptions,
} from "../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface GroupOverviewPropsBase {
  videoId: number;
  customHeaderContent: React.ReactElement;
}

export type GroupsOverviewProps = GroupOverviewPropsBase & PropsFromRedux;

export const GroupsOverview: FC<GroupsOverviewProps> = ({ videoId, customHeaderContent, fetchGroups, onUnmount }) => {
  const renderSearchInput = (): React.ReactElement => <SearchInput placeholder="Search for People..." disabled />;

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getVideoGroups = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = PeopleAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<PeopleAssignmentColumns>];
    fetchGroups({
      entityId: videoId,
      skip,
      top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: "",
    });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.Group,
    RolePermissions.AssetsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <GroupsListContainer
          defaultSortingColumnName={PeopleAssignmentColumns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getGroupColumnOptions()}
          applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          fetchData={getVideoGroups}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          noResultsContent={<ContentGroupsNoResults />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchGroups: bindActionCreators(fetchVideoGroups, dispatch),
  onUnmount: () => dispatch(resetSelectedItems()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(GroupsOverview);
export default ConnectedComponent;
