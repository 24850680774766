import { isEmpty } from "lodash";
import React from "react";

import { MultiStateButton } from "../../../../components/buttons/multiStateButton/MultiStateButton";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { type RolePermissions } from "../../../../enums";
import Restricted from "../../../Application/Restricted";

export interface AddUserButtonProps {
  selectedUsers: number[];
  createPermissions: RolePermissions[];
  onCreateUser?: () => void;
  onImportUsers?: () => void;
}

const AddUserButton: React.FC<AddUserButtonProps> = ({
  selectedUsers,
  createPermissions,
  onCreateUser,
  onImportUsers,
}) => {
  const getMenuItems = () => {
    const items = [];
    if (onCreateUser) {
      items.push({
        icon: "user-plus",
        text: "Manually Create",
        onClick: onCreateUser,
      });
    }

    if (onImportUsers) {
      items.push({
        icon: "file-import",
        text: "Import CSV",
        onClick: onImportUsers,
      });
    }

    return items;
  };

  if (isEmpty(selectedUsers)) {
    return (
      <Restricted
        permissions={createPermissions}
        renderContent={(hasAnyPermission) => (
          <RestrictedByTooltip hasPermission={hasAnyPermission} className="create-button user">
            <MultiStateButton
              id="add-users"
              trigger="Add Users"
              simple
              items={getMenuItems()}
              disabled={!hasAnyPermission}
            />
          </RestrictedByTooltip>
        )}
      />
    );
  }
  return null;
};

export default AddUserButton;
