import { type FC } from "react";
import { Icon } from "semantic-ui-react";
import "./packPicture.scss";

export interface PackPictureProps {
  className?: string;
}

const PackPicture: FC<PackPictureProps> = ({ className }) => {
  return (
    <div className={className}>
      <Icon className="pack-icon fa-box-full" />
    </div>
  );
};

export default PackPicture;
