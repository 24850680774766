import { type EventDetailsState, type Event, type EventView, type SessionsView } from "../../types/state";
import * as actionTypes from "../actionTypes/eventDetailsActionTypes";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { createReducer } from "../../../../../utils/reduxUtils";
import * as entityActionTypes from "../actionTypes/eventEntityStateActionTypes";
import ViewPayloadConversions from "../../utils/ViewPayloadConversions";
import { isEmpty } from "lodash";
import { type SettingsView } from "../../../Surveys/types/state";
import { getDefaultSession } from "../../EventDetails/Sessions/Utils/utils";

const initialState: EventDetailsState = {
  event: {
    id: -1,
    isDraft: true,
    hasBeenPublished: false,
    thumbnailUrl: "",
    title: "",
    dateModified: undefined,
    dateCreated: undefined,
    description: "",
    externalEventSessions: {
      sessions: [],
    },
  },
  settings: {
    flows: [],
    packs: [],
    labels: [],
    softwareApplications: [],
  },
  isLoading: false,
  isSessionsLoaded: false,
  error: undefined,
  isEventLoaded: false,
};

const eventDetailsHandlers = () => {
  const { saveEvent, clearEvent, getEventBegin, getEventSuccess, getEventFailure, saveSessions, saveSettings } =
    actionTypes;
  const {
    publishEventBegin,
    publishEventSuccess,
    publishEventFailure,
    fetchLockedEventBegin,
    fetchLockedEventSuccess,
    fetchLockedEventFailure,
    fetchDiscardEventBegin,
    fetchDiscardEventSuccess,
    fetchDiscardEventFailure,
  } = entityActionTypes;

  const saveEventHandler = (state: EventDetailsState, action: PayloadAction<EventView>): EventDetailsState => ({
    ...state,
    event: action.payload,
  });

  const saveSessionsHandler = (state: EventDetailsState, action: PayloadAction<SessionsView>): EventDetailsState => ({
    isLoading: state.isLoading,
    error: state.error,
    isEventLoaded: state.isEventLoaded,
    isSessionsLoaded: state.isSessionsLoaded,
    settings: state.settings,
    event: {
      ...state.event,
      externalEventSessions: action.payload,
    },
  });

  const clearEventHandler = (): EventDetailsState => ({
    ...initialState,
  });

  const failureHandler = (state: EventDetailsState, action: PayloadAction<Error>): EventDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const getEventBeginHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getEventSuccessHandler = (state: EventDetailsState, action: PayloadAction<Event>): EventDetailsState => {
    const payload = action.payload;
    let sessions = ViewPayloadConversions.getSessionsView(payload.externalEventSessions);
    if (isEmpty(sessions)) {
      sessions = [getDefaultSession(false)];
    }

    return {
      ...state,
      event: {
        ...state.event,
        ...payload,
        externalEventSessions: { sessions },
      },
      settings: {
        labels: payload.labels,
        softwareApplications: payload.softwareApplications,
        packs: payload.packs.map((pack) => ({ title: pack.name, id: pack.id })),
        flows: payload.flows ?? [],
      },
      isEventLoaded: true,
      isSessionsLoaded: true,
      isLoading: false,
    };
  };

  const publishEventBeginHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishEventSuccessHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    event: {
      ...state.event,
      isDraft: false,
    },
    isLoading: false,
  });

  const fetchLockedEventBeginHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedEventSuccessHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    event: {
      ...state.event,
      isDraft: true,
    },
    isLoading: false,
  });

  const fetchDiscardEventBeginHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchDiscardEventSuccessHandler = (state: EventDetailsState): EventDetailsState => ({
    ...state,
  });

  const saveSettingsHandler = (state: EventDetailsState, action: PayloadAction<SettingsView>): EventDetailsState => ({
    ...state,
    settings: {
      ...action.payload,
    },
  });

  return {
    [saveEvent]: saveEventHandler,
    [clearEvent]: clearEventHandler,
    [saveSessions]: saveSessionsHandler,
    [saveSettings]: saveSettingsHandler,

    [getEventBegin]: getEventBeginHandler,
    [getEventSuccess]: getEventSuccessHandler,
    [getEventFailure]: failureHandler,

    [publishEventBegin]: publishEventBeginHandler,
    [publishEventSuccess]: publishEventSuccessHandler,
    [publishEventFailure]: failureHandler,

    [fetchLockedEventBegin]: fetchLockedEventBeginHandler,
    [fetchLockedEventSuccess]: fetchLockedEventSuccessHandler,
    [fetchLockedEventFailure]: failureHandler,

    [fetchDiscardEventBegin]: fetchDiscardEventBeginHandler,
    [fetchDiscardEventSuccess]: fetchDiscardEventSuccessHandler,
    [fetchDiscardEventFailure]: failureHandler,
  };
};

export const eventDetailsReducer = createReducer(initialState, [eventDetailsHandlers]);
