import GenericItemsView from "views/ItemsView/GenericItemsView";
import { type CustomerView } from "../types/models";
import { type ColumnOption } from "interfaces/columnOptions";
import { SortingDirection } from "enums";
import { NoResults, SearchInput } from "components";
import { useReloadItemViewListItems } from "hooks/useReloadItemViewListItems";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import { useEffect } from "react";
import { Columns, columnToParamMap } from "./Customers/getColumnOptions";

type Props = {
  selectedIds?: number[];
  setSelectedIds: (ids: number[]) => void;
  items: CustomerView[];
  isLoading: boolean;
  itemsCount: number;
  getData: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    templateId?: number,
  ) => void;
  onSearchChange: (term: string) => void;
  templateId?: number;
  columnOptions: ColumnOption<CustomerView>[];
  disabled?: boolean;
};

export const GroupTemplateCustomers = (props: Props) => {
  const {
    selectedIds,
    setSelectedIds,
    items,
    isLoading,
    itemsCount,
    getData,
    onSearchChange,
    templateId,
    columnOptions,
    disabled,
  } = props;

  useEffect(() => {
    return () => {
      onSearchChange("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [setReloadListItems, onSearchChanged] = useReloadItemViewListItems(onSearchChange);

  const loadCustomers = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added,
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    const sortColumn = columnToParamMap[sortingColumnName];
    getData(skip, top, sortColumn, sortingDirection, templateId);
  };

  return (
    <GenericItemsView
      fetchData={loadCustomers}
      items={items}
      isLoading={isLoading}
      dataCount={itemsCount}
      columnOptions={columnOptions}
      isSelectDisabled={(item) => item.isAdded || !!disabled}
      onSelectedItemChanged={setSelectedIds}
      noResultsContent={<NoResults title="You don't have any available customers." iconClassName="building" />}
      renderSearch={() => <SearchInput placeholder="Search for customers" onChange={onSearchChanged} />}
      setReloadListItems={setReloadListItems}
      selectedIds={selectedIds}
      listViewRtnEvents={[
        rtnEvents.AccountAddedToTemplateGroupSuccess,
        rtnEvents.AccountRemovedFromTemplateGroupSuccess,
        rtnEvents.UpdateAllTemplateGroupAccountPackMessageSuccess,
      ]}
      defaultSortingColumnName={Columns.Added}
    />
  );
};
