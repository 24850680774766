import React, { useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, useParams, useLocation, useNavigate } from "react-router-dom";
import { intersection } from "lodash";

import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { type PacksListingProps } from "./types";
import * as packListingActions from "../state/thunks/packListingThunk";
import * as packEntityStateActions from "../state/actions/packEntityStateActions";
import Segments from "../../../../components/navigation/segments/Segments";
import { type EnablePackTrialRequest, type MediaItem, type PackRequest, type EnablePackRequest } from "../types/state";
import { PackListingsOverview } from "./Overview/PackListingsOverview";
import { RolePermissions, RouteNames } from "../../../../enums";
import { ListingOverviewHeader } from "./ListingOverviewHeader/ListingOverviewHeader";
import { fetchOwnAccountInfo as fetchOwnAccountInfoAction } from "../state/thunks/ownAccountInfoThunk";
import { ownAccountNameSelector } from "../state/slices/ownAccountInfoSlice";
import * as backgroundTaskOperations from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as notificationOperations from "../../../Notifications/state/notificationsActions";
import ModalManager from "../../../../utils/ModalManager";
import ModalTypes from "../../../../components/modal/ModalTypes";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import { DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import packsDataService from "../services/packsDataService";
import { MediaItemsCarousel } from "../../../../components/packs/mediaItemsCarousel/MediaItemsCarousel";
import MediaItemType from "../../../../enums/licensing/mediaItemType";
import DeletePackConfirmationDialog from "../../../../components/packs/deletePackConfirmationDialog/DeletePackConfirmationDialog";
import { RequestPackModals } from "../PackRequestModals/RequestPackModals";

const pages = {
  overview: {
    index: 0,
    segment: {
      label: "Overview",
    },
  },
} as const;

export type Props = PacksListingProps & PropsFromRedux;

export const PackListingsSegments: React.FC<Props> = ({
  pack,
  accountName,
  packActions,
  packEntityActions,
  fetchOwnAccountInfo,
  packRequestModal,
  isLoading,
  accountId,
  currentUserId,
  backgroundTasksActions,
  notificationsActions,
  userPermissions,
}) => {
  const params = useParams();
  const {
    id,
    title,
    isPurchased,
    isExpired = false,
    isTrialAllowed,
    isOwn,
    isRequested,
    isTrial,
    expirationDate,
    trialDuration,
  } = pack;
  const packId = Number(params.id);
  const location = useLocation();
  const navigate = useNavigate();

  const { publisher, logoUri } = useMemo(() => {
    return {
      publisher: location.state?.publisher,
      logoUri: location.state?.logoUri,
    };
  }, [location.state]);
  const [isTrialRequest, setIsTrialRequest] = useState(false);

  const [packUsageCount, setPackUsageCount] = React.useState(0);
  const [triggerOpenDeletePackDialog, setTriggerOpenDeletePackDialog] = React.useState(false);

  const hasPacksManageRole =
    intersection(userPermissions, [RolePermissions.PacksManage]).length > 0 ||
    intersection(userPermissions, [RolePermissions.FlowsCreate]).length > 0;

  const requestPack = (packRequest: PackRequest) => {
    packActions.hidePackRequestModal();
    packActions.requestPack(packRequest);
  };

  const enablePackTrial = (req: EnablePackTrialRequest) => {
    packActions.hidePackRequestModal();
    packActions.enablePackTrial(req);
  };

  const enablePack = (req: EnablePackRequest) => {
    packActions.hidePackRequestModal();
    packActions.enablePack(req);
  };

  const handleEditClick = () => {
    const goToPackEdit = (payload: { id: number }) => navigate(`/${RouteNames.licensingPacks}/${payload.id}`);

    if (!pack.isDraft) {
      packEntityActions.fetchDraftPackEntity(id);
    }
    goToPackEdit({ id });
  };

  const handleClose = () => {
    navigate(`/${RouteNames.availablePacks}`);
  };

  const deletePack = async (deletePackId: number) => {
    const params = {
      id: "DeletePack",
      title: `Deletion of pack`,
      getMessageIds: async () => {
        const { data } = await packsDataService.deletePack(deletePackId);
        return [data];
      },
      onCompleted: handleClose,
      successTransientMessage: `Pack has been deleted!`,
      failureTransientMessage: `Pack delete failed!`,
    };

    const { addOperationV1 } = backgroundTasksActions;
    const { sendTransientNotification } = notificationsActions;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  const mediaItems: MediaItem[] = [{ url: pack.thumbnailImageUrl, typeId: MediaItemType.Image }, ...pack.mediaItems];

  const deleteModalManager = useMemo(() => {
    return new ModalManager(ModalTypes.DeletePack, () => deletePack(packId), false, currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packId, currentUserId]);

  useEffect(() => {
    packActions.getPackListing(packId);
    fetchOwnAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => deleteModalManager.dispose, [deleteModalManager]);

  const onCancelPackDelete = () => {
    setTriggerOpenDeletePackDialog(false);
  };

  const onContinuePackDelete = async () => {
    setTriggerOpenDeletePackDialog(false);

    const params = {
      id: "DeletePack",
      title: `Deletion of pack`,
      getMessageIds: async () => {
        const { data } = await packsDataService.deletePack(packId);
        return [data];
      },
      onCompleted: handleClose,
      successTransientMessage: `Pack was deleted!`,
      failureTransientMessage: `Pack delete failed!`,
    };

    const { addOperationV1 } = backgroundTasksActions;
    const { sendTransientNotification } = notificationsActions;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  const handleDeleteClick = async () => {
    let usageCount = await packsDataService.getPackUsageCount(packId);
    setPackUsageCount(usageCount);
    setTriggerOpenDeletePackDialog(true);
  };

  const renderActionButtons = () => [
    <DeleteLinkButton key={1} onClick={handleDeleteClick} isDisabled={!hasPacksManageRole} />,
  ];

  return (
    <div className="pack-overview-container">
      <div className="nested-content pack-listing-overview pack-listing-overview-header">
        <ListingOverviewHeader
          {...pack}
          titleForGA="Pack Details"
          backButton
          defaultURL={`/${RouteNames.availablePacks}`}
          onPackRequest={() => {
            setIsTrialRequest(false);
            packActions.showPackRequestModal();
          }}
          onPackTrialRequest={() => {
            setIsTrialRequest(true);
            packActions.showPackRequestModal();
          }}
          onEditClick={handleEditClick}
          renderActionButtons={renderActionButtons}
          isExpired={isExpired}
          isTrial={isTrial}
          expirationDate={expirationDate}
          isPurchased={isPurchased}
          isOwn={isOwn}
          isRequested={isRequested}
          isTrialAllowed={isTrialAllowed}
          publisher={publisher}
          logoUri={logoUri}
        />
      </div>

      <MediaItemsCarousel items={mediaItems} />
      <div className="nested-content pack-listing-overview pack-overview-content">
        <Segments to={`/licensing/packs/listings/${packId}`}>
          {[<Segments.Segment key={pages.overview.index} {...pages.overview.segment} />]}
        </Segments>
        <Routes>
          <Route path="/" element={<PackListingsOverview pack={pack} accountId={accountId} isLoading={isLoading} />} />
        </Routes>

        <RequestPackModals
          accountId={accountId}
          packId={packId}
          packTitle={title}
          accountName={accountName}
          userId={currentUserId}
          onCancelPackRequest={packActions.hidePackRequestModal}
          onSubmitEnablePack={enablePack}
          isTrialRequest={isTrialRequest}
          showModal={packRequestModal.show}
          onSubmitPackRequest={requestPack}
          onTrialRequest={enablePackTrial}
          onTrialEnable={enablePackTrial}
          trialDuration={trialDuration}
        />

        <DeletePackConfirmationDialog
          packUsageCount={packUsageCount}
          triggerOpen={triggerOpenDeletePackDialog}
          onCancel={onCancelPackDelete}
          onContinue={onContinuePackDelete}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  pack: state.packs.packListingReducer.details.pack,
  isLoading: state.packs.packListingReducer.details.isLoading,
  packRequestModal: state.packs.packListingReducer.details.packRequestModal,
  accountId: state.userProfile.accountId,
  accountName: ownAccountNameSelector(state),
  currentUserId: state.userProfile.id,
  userPermissions: state.userProfile.permissions,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  packActions: bindActionCreators(packListingActions, dispatch),
  packEntityActions: bindActionCreators(packEntityStateActions, dispatch),
  fetchOwnAccountInfo: () => dispatch(fetchOwnAccountInfoAction()),
  backgroundTasksActions: bindActionCreators(backgroundTaskOperations, dispatch),
  notificationsActions: bindActionCreators(notificationOperations, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackListingsSegments);
export default ConnectedComponent;
