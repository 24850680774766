import { Icon } from "semantic-ui-react";
import cn from "classnames";

import { type AssessmentAsset } from "../../../interfaces/assetToDropInfo";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { SelectableAssetCard } from "../SelectableAssetCard/SelectableAssetCard";

import "./FlowDesignerAssessmentAssetCard.scss";

export interface Props {
  id: AssessmentAsset["id"];
  title: AssessmentAsset["title"];
  className?: string;
  isSelected?: boolean;
  onClick?: () => void;
}

function FlowDesignerAssessmentAssetCard({ id, title, className, ...props }: Props) {
  return (
    <SelectableAssetCard
      key={id}
      className={cn("flow-designer-assessment-asset-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-assessment-asset-card"
      data-hook-id={id}
      {...props}
    >
      <div className="thumbnail">
        <Icon className="list ol" size="big" />
      </div>
      <div className="type">Assessment</div>
      <div className="title">
        <Tooltip target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
      </div>
    </SelectableAssetCard>
  );
}

export default FlowDesignerAssessmentAssetCard;
