import React, { type FC, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";

import { type AppDispatch, type RootState } from "./features/Application/globaltypes/redux";
import { setUserProfileMoboData } from "./features/Application/thunks/userProfileThunk";
import moboConfigurator from "./moboConfigurator";
import { useLocation } from "react-router-dom";

export interface Props extends PropsFromRedux {
  children?: React.ReactNode;
}

export const MoboProvider: FC<Props> = ({ isUserMoboDataSet, originId, onSetUserMoboData, children }) => {
  const [isMoboDataLoaded, setIsMoboDataLoaded] = useState(false);
  const location = useLocation();
  const moboId = useMemo(() => moboConfigurator.getMoboId(), []);

  useEffect(() => {
    if (moboId && moboId !== originId) {
      moboConfigurator.configureMoboMode(moboId);
      onSetUserMoboData(moboId);
    } else {
      setIsMoboDataLoaded(true);
    }

    return () => moboConfigurator.clearMoboData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moboId]);

  useEffect(() => {
    if (isUserMoboDataSet && !isMoboDataLoaded) {
      setIsMoboDataLoaded(true);
    }
  }, [isUserMoboDataSet, isMoboDataLoaded]);

  useEffect(() => {
    if (location.hash.includes("moboId")) {
      const cleanedUrl = window.location.href.replace(/#moboId=\d+/, "");
      window.history.replaceState(null, "", cleanedUrl);
    }
  }, [location]);

  return isMoboDataLoaded ? <>{children}</> : <Loader active />;
};

const mapStateToProps = (state: RootState) => ({
  isUserMoboDataSet: state.userProfile.isUserMoboDataSet,
  originId: state.userProfile.originAccountId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSetUserMoboData: bindActionCreators(setUserProfileMoboData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(MoboProvider);
export default ConnectedComponent;
