import React from "react";
import { Icon } from "semantic-ui-react";

import { type FlowItemSurvey } from "../../../../features/Library/Flows/Designer/types";
import { pluralize } from "../../../../utils/stringUtils";
import { OTHER_ANSWER } from "../../../../features/Library/Flows/Designer/constants";
import TopContent from "../BaseCard/TopContent";

import styles from "./surveyCard.module.scss";

export interface ISurveyCardOwnProps {
  item: FlowItemSurvey;
  expanded?: boolean;
}

const SurveyCard: React.FC<ISurveyCardOwnProps> = ({ item, expanded }) => {
  const metaLabel = item.questionsCount
    ? `${item.questionsCount} ${pluralize("Question", parseInt(item.questionsCount))}`
    : "";
  const { branchingQuestion, elementType } = item;

  return (
    <div className={styles.root}>
      <TopContent {...item} />
      <div className={styles.bottom}>
        <div className={styles["bottom-labels"]}>
          {branchingQuestion && <Icon className="fa-code-branch" size="small" />}
          <div data-testid="card-label" className={styles["bottom-labels--card"]}>
            {elementType.toUpperCase()}
          </div>
          {!branchingQuestion && metaLabel && <div className={styles["bottom-labels--meta"]}>{metaLabel}</div>}
        </div>
        {!expanded && branchingQuestion && (
          <div className={styles["bottom-question"]}>
            <div className={styles["bottom-question-label"]}>{branchingQuestion?.questionText}</div>
            <div className={styles["bottom-question-answers"]}>
              {branchingQuestion?.answers.map((answer, index) => (
                <span key={index}>{`Answer ${index + 1} ${answer.answerText}`}</span>
              ))}
              {branchingQuestion.includeOtherAsAnswer && (
                <span>{`Answer ${branchingQuestion?.answers.length + 1} ${OTHER_ANSWER.answerText}`}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyCard;
