import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type AccountTypeVisibility } from "../../PackDetails/Visibility/AccountTypes/types";

export const initialState: FetchingItemsState<AccountTypeVisibility> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const accountTypesAvailableForPackSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.PackAccountTypesVisibility,
    name: "accountTypesAvailableForPack",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = accountTypesAvailableForPackSlice.actions;

export default accountTypesAvailableForPackSlice.reducer;

export type accountTypesAvailableForPackSliceType = ReturnType<typeof accountTypesAvailableForPackSlice.reducer>;
