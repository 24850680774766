import { useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type Dispatch } from "@reduxjs/toolkit";
import { Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import Segments from "../../../../../components/navigation/segments/Segments";
import FormFooter from "../../../../../components/forms/FormFooter";
import PreventTransitionPrompt from "../../../../../components/preventTransition/PreventTransitionPrompt";
import VivaIntegrationConfiguration from "../../Configuration/VivaIntegrationConfiguration";
import ActivityLogs from "../ActivityLogs/ActivityLogs";

import { type RootState } from "../../../../Application/globaltypes/redux";
import { GenericSectionHeader } from "../../../../../components";
import { IntegrationTypes } from "../../types";
import { IntegrationDtoMap, overviewMoboPath } from "../../Shared";
import { RouteNames } from "../../../../../enums";
import { type VivaIntegrationConfigs } from "../../types/viva";
import {
  fetchIntegrationConfigsAction,
  updateIntegrationConfigsAction,
} from "../../state/thunks/integrationDetailsThunk";
import { bindAction } from "../../../../../interfaces";
import { reset, fetchSuccess } from "../../state/slices/integrationDetailsSlice";
import { integrationDetailsSelector } from "../../state/selectors";
import { isEmpty, isEqual } from "lodash";
import { useRtn } from "../../../../../hooks/useRtn";
import * as accountsEventTypes from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { useEventCallback } from "../../../../../hooks/useEventCallback";
import navigationUtils from "../../../../../utils/navigationUtils";

export interface EditVivaProps extends PropsFromRedux {}

const rtnEvents = [accountsEventTypes.UpdateVivaSuccess, accountsEventTypes.UpdateVivaFailure];

export const EditViva = (props: EditVivaProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { moboId } = useParams();
  const { fetchConfigs, resetConfigs, originConfigs, isLoading, updateData, dispatch, asyncOperations } = props;
  const [configs, setConfigs] = useState<VivaIntegrationConfigs>(originConfigs);
  const { url, title } = IntegrationDtoMap[IntegrationTypes.Viva];
  const isDirty = useMemo(() => !isEqual(configs, originConfigs), [originConfigs, configs]);

  const ownAccount = Number.isNaN(moboId) || moboId === undefined;

  const overviewPath = url(ownAccount, moboId);

  const onSave = () => {
    props.updateConfigs(IntegrationTypes.Viva, configs, moboId);
  };

  const onCancel = () => {
    setConfigs(originConfigs);
  };

  const onOperationFinished = useEventCallback(() => {
    updateData(configs);
  }, [updateData, configs]);

  useEffect(() => {
    setConfigs(originConfigs);
  }, [originConfigs]);

  useEffect(() => {
    fetchConfigs(moboId);

    return () => {
      resetConfigs();
    };
  }, [resetConfigs, fetchConfigs, moboId]);

  useRtn(rtnEvents, onOperationFinished, moboId, dispatch, asyncOperations);

  return (
    <section className="nested-content">
      <GenericSectionHeader
        title={title}
        goBackAction={() =>
          navigationUtils.goBackOrDefault(
            location,
            navigate,
            moboId ? overviewMoboPath(moboId) : RouteNames.integrationsOverviewUrl,
          )
        }
      />
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <Segments to={overviewPath}>
        <Segments.Segment label="Configuration" />
        <Segments.Segment label="Activity Log" to="activity-log" />
      </Segments>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {!isEmpty(configs) && (
                <>
                  <VivaIntegrationConfiguration integrationName={title} setConfigs={setConfigs} configs={configs} />
                  <FormFooter
                    isSaveBtnDisabled={!isDirty}
                    isCancelBtnDisabled={!isDirty}
                    onSave={onSave}
                    onCancel={onCancel}
                    saveButtonTitle="Save Changes"
                  />
                </>
              )}

              <PreventTransitionPrompt
                when={isDirty}
                title="Exit Without Saving?"
                message="Are you sure you want to exit without saving your changes? All information entered will be lost."
              />
            </>
          }
        />

        <Route path="activity-log" element={<ActivityLogs type={IntegrationTypes.Viva} />} />
      </Routes>
    </section>
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = integrationDetailsSelector<VivaIntegrationConfigs>(state);
  return {
    asyncOperations: state.asyncOperations,
    originConfigs: base.data,
    isLoading: base.isLoading,
  };
};
/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    fetchConfigs: bindAction((id) => fetchIntegrationConfigsAction(IntegrationTypes.Viva, id), dispatch),
    updateData: bindAction(fetchSuccess, dispatch),
    updateConfigs: bindAction(updateIntegrationConfigsAction, dispatch),
    resetConfigs: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditViva);
export default ConnectedComponent;
