import PacksList from "../../../../../components/packs/packsList/PacksList";
import NoResults from "../../../../../components/noResults/NoResults";
import { getPeoplePacksGridSortingOptions } from "../../../Users/EditUser/UserPacks/userPackGridSortingOptions";
import { type GroupPacksRequest } from "../../../../Licensing/Packs/types/requests";
import { type Filters } from "../../../../../utils/queryUtils";
import UserPacksUtils from "../../../../../utils/userPacksUtils";
import { type GroupPacksState } from "../../../../Licensing/Packs/state/slices/groupPacksGridSlice";
import { type GroupPacksGridFetchType } from "../../../../Licensing/Packs/state/thunks/groupPacksGridThunk";
import { RouteNames, type SortingDirection, ViewType } from "../../../../../enums";
import { type PackOverview } from "../../../../Licensing/Packs/types/state";
import GroupPackListRow from "../../../../../components/packs/packsList/GroupPackListRow";
import {
  AddAllPackGroupSuccess,
  AddAllGroupLicenseMessageSuccess,
  RemoveAllGroupLicenseMessageSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { type sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { issueLicensePermissionPredicate } from "../issueLicensePermissionPredicate";
import { getColumnOptions } from "components/packs/packsList/getGroupPackColumnOptions";

export interface GroupPacksActions {
  fetchPacksGrid: (requestData: GroupPacksRequest) => GroupPacksGridFetchType;
  resetPacksGrid: () => void;
}

export interface GroupPacksProps {
  groupId: number;
  accountId: number;
  packsActions: GroupPacksActions;
  packsGrid: GroupPacksState;
  issueLicenseBtn: React.ReactElement;
  sendTransientNotification: (
    payload: Parameters<typeof sendTransientNotification>[0],
    dismissInSeconds: number,
  ) => void;
  onRemoveLicenseClick: (id: number) => void;
  isReadOnly: boolean;
  selectedPackIds: number[];
  onSelectedPacksChanged: (ids: number[]) => void;
}

const rtnEvents = [AddAllPackGroupSuccess, AddAllGroupLicenseMessageSuccess, RemoveAllGroupLicenseMessageSuccess];

export const GroupPacks = (props: GroupPacksProps) => {
  const { isReadOnly } = props;
  const licenseRemovalEnabled = !!useFeatureFlag(FeatureFlags.LicenseRemoval) && !isReadOnly;
  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  const renderNoResults = () => (
    <NoResults
      title="This group does not have any pack licenses."
      description={
        <span>
          Looks like there are no pack licenses for this group yet.{" "}
          {!isReadOnly && "Issue a license now using the button below."}
        </span>
      }
      iconClassName="fa-box-full"
    >
      {!isReadOnly && props.issueLicenseBtn}
    </NoResults>
  );

  const loadPacksGrid = (
    skip: number,
    sortBy: string,
    sortDirection: SortingDirection,
    appliedFilter: Filters,
    searchTerm?: string,
    top?: number,
  ) => {
    const params = {
      groupId: props.groupId,
      accountId: props.accountId,
      skip: skip,
      top: top,
      filters: appliedFilter,
      searchTerm: searchTerm,
    };

    const requestData: GroupPacksRequest = {
      ...params,
      sortBy: UserPacksUtils.mapSortBy(sortBy),
      sortOrder: sortDirection,
    };

    return props.packsActions.fetchPacksGrid(requestData);
  };

  const getListRow = (pack: PackOverview) => {
    const url = `/${RouteNames.purchasedPacks}/${pack.id}`;
    const isSelected = props.selectedPackIds.some((x) => x === pack.id);

    return (
      <GroupPackListRow
        pack={pack}
        url={url}
        onRemoveLicenseClick={props.onRemoveLicenseClick}
        licenseRemovalEnabled={licenseRemovalEnabled}
        isSelected={isSelected}
      />
    );
  };

  return (
    <section className="scrollable-content">
      <PacksList
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getListRow={getListRow}
        gridItems={props.packsGrid}
        loadGridItems={loadPacksGrid}
        resetGridItems={props.packsActions.resetPacksGrid}
        gridSortingOptions={getPeoplePacksGridSortingOptions()}
        renderNoResults={renderNoResults}
        refreshListEvents={rtnEvents}
        withSelection={licenseRemovalEnabled}
        isReadOnly={isReadOnly}
        selectedPackIds={props.selectedPackIds}
        onSelectedPacksChanged={props.onSelectedPacksChanged}
        packCardProps={{
          onRemoveLicenseClick: props.onRemoveLicenseClick,
          licenseRemovalEnabled: licenseRemovalEnabled,
          issueLicensePermissionPredicate: issueLicensePermissionPredicate,
        }}
      />
    </section>
  );
};

export default GroupPacks;
