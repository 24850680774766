import ValidatedField from "./ValidatedField";
import { type FormikProps } from "formik";
import { Radio, type RadioProps } from "semantic-ui-react";
import React from "react";

export interface RadioGroupFieldProps {
  disabled?: boolean;
  propertyName: string;
  options: any[];
  value: any;
  label?: string;
  markAsRequired?: boolean;
  onBlur?: (propertyName: string) => void;
}

export type RadioGroupFieldPropsAll<T> = RadioGroupFieldProps & FormikProps<T>;

export default function RadioGroupField<T>(props: RadioGroupFieldPropsAll<T>) {
  const { options, value, propertyName } = props;

  const handleChange = async (_: React.SyntheticEvent<HTMLElement, Event>, data: RadioProps) => {
    const { setFieldTouched, setFieldValue, onBlur } = props;
    setFieldTouched(propertyName, true, false);
    await (setFieldValue(propertyName, data.value) as unknown as Promise<void>);
    onBlur?.(propertyName);
  };

  return (
    <ValidatedField {...props}>
      {options.map((option, index) => (
        <Radio
          {...props}
          key={index}
          label={option.text}
          name={propertyName}
          checked={value === option.value}
          value={option.value}
          onChange={handleChange}
        />
      ))}
    </ValidatedField>
  );
}
