import React from "react";
import { Button } from "components/buttons/button/Button";
import NoResults from "../../../../../components/noResults/NoResults";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";

export interface RemindersNoResultsProps {
  action: () => void;
  hasAnyPermission: boolean;
  disabled?: boolean;
}

export const RemindersNoResults = (props: RemindersNoResultsProps) => {
  const { action, hasAnyPermission, disabled } = props;
  const title = "You don’t have any reminders";

  const description = (
    <span>
      Looks like you don’t have any reminders yet.
      <br />
      Create one now using the button below.
    </span>
  );

  const createButton = (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button className="add-content create-button" primary onClick={action} disabled={!hasAnyPermission || disabled}>
        Add Reminder
      </Button>
    </RestrictedByTooltip>
  );

  return (
    <NoResults title={title} description={description} iconClassName="fa-bell">
      {createButton}
    </NoResults>
  );
};
