import { type FC, useCallback, useMemo, useRef, useState } from "react";

import { type IObservable } from "../../../interfaces/IObservable";
import { type NotifyByEmailController } from "../../notifyStep/notifyByEmailController";
import { TemplateTypes } from "../../../enums";
import { ModalBanner } from "./ModalBanner/ModalBanner";
import { type NotifyStepSettings } from "../../../interfaces";
import { SwitchEntityTypes } from "enums/switchEntityTypes";
import { NotifyStepConfig } from "../../../features/SystemNotifications/types";
import { getNotifyConfig } from "../../../features/SystemNotifications/helpers";

import NotifyWizardStep from "../../notifyStep/NotifyWizardStep/NotifyWizardStep";
import useNotifyConfig from "../../../features/SystemNotifications/hooks/useNotifyConfig";
import ModalSizes from "../../../enums/modalSizes";
import TriggerableConfirmationModal from "../../triggeredConfirmationModal/TriggerableConfirmationModal";
import { ButtonGroup } from "../../buttonGroup";
import { tabs } from "components/notifyStep/NotifyStepSwitch/constants";

export interface PublishConfirmationModalProps {
  onTriggerPublishContentObserver: IObservable<(onConfirm: () => void, entityState: EntityState) => void>;
  onConfirmAction: (entityId: number, notificationSettings?: NotifyStepSettings, notifyTypes?: number[]) => void;
  dependencyType: string;
  contentType: string;
}

export interface EntityState {
  itemsCount: number;
  entityId: number;
  assignmentsCount: number;
  templateType: TemplateTypes;
  notifyConfigType?: NotifyStepConfig;
  entityType?: SwitchEntityTypes;
}

export const PublishConfirmationModal: FC<PublishConfirmationModalProps> = (props) => {
  const controllerRef = useRef<NotifyByEmailController>();
  const [isDataValid, setIsDataValid] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [entityState, setEntityState] = useState<EntityState>();
  const { contentType, dependencyType } = props;
  const { config, NotifyConfigSwitch } = getNotifyConfig(entityState?.notifyConfigType || NotifyStepConfig.Default);
  const [notifyConfig, { setNotifyConfig, resetNotifyConfig, shouldNotify, communicationChannel, notifyTypes }] =
    useNotifyConfig<any>(config);

  const handleCommunicationChannelChange = useCallback(
    (value: string) =>
      setNotifyConfig({
        key: "communicationChannel",
        value: tabs.findIndex((name) => name === value),
      }),
    [setNotifyConfig],
  );

  const onNotifyStepLoaded = (controller: NotifyByEmailController) => {
    controllerRef.current = controller;
    setIsDataLoaded(true);
  };

  const onResetNotifyStep = () => {
    controllerRef.current?.resetState();
    resetNotifyConfig();
  };

  const onConfirmed = async () => {
    const notificationSettings = await controllerRef.current?.getSettings(
      shouldNotify,
      communicationChannel,
      !entityState?.assignmentsCount,
    );
    props.onConfirmAction(entityState!.entityId, notificationSettings, notifyTypes);
    onResetNotifyStep();
  };

  const modalContent = useMemo(() => {
    return (
      <NotifyWizardStep
        templateType={entityState?.templateType || TemplateTypes.Empty}
        onLoaded={onNotifyStepLoaded}
        onValidChange={setIsDataValid}
        isDisabled={!entityState?.assignmentsCount}
        shouldNotify={shouldNotify}
        communicationChannel={communicationChannel}
        renderSwitch={(switchProps) => (
          <div>
            <NotifyConfigSwitch
              config={notifyConfig}
              onNotifyConfigChange={setNotifyConfig}
              entityType={entityState?.entityType || SwitchEntityTypes.Empty}
              {...switchProps}
            />
            {entityState?.notifyConfigType === NotifyStepConfig.WithProgress && (
              <ButtonGroup
                items={tabs}
                defaultValue={tabs[communicationChannel]}
                isDisabled={
                  !notifyConfig.shouldNotifyNotStarted &&
                  !notifyConfig.shouldNotifyInProgress &&
                  !notifyConfig.shouldNotifyCompleted
                }
                onChange={handleCommunicationChannelChange}
              />
            )}
          </div>
        )}
      />
    );
  }, [
    entityState?.templateType,
    entityState?.assignmentsCount,
    entityState?.entityType,
    entityState?.notifyConfigType,
    shouldNotify,
    communicationChannel,
    NotifyConfigSwitch,
    notifyConfig,
    setNotifyConfig,
    handleCommunicationChannelChange,
  ]);

  const bannerContent = entityState?.itemsCount ? (
    <ModalBanner contentType={contentType} dependencyType={dependencyType} dependencyCount={entityState?.itemsCount} />
  ) : undefined;

  return (
    <TriggerableConfirmationModal
      title="Publish"
      size={ModalSizes.large}
      confirmButtonLabel="Finish"
      content={modalContent}
      banner={bannerContent}
      disabled={shouldNotify && !(isDataValid && isDataLoaded)}
      onTriggerModalObserver={props.onTriggerPublishContentObserver}
      onTrigger={setEntityState}
      onConfirmed={onConfirmed}
      onClose={onResetNotifyStep}
    />
  );
};

export default PublishConfirmationModal;
