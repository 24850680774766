import React, { memo, useMemo } from "react";
import { DropdownWrapper } from "../../../../../components/dropdowns";
import FlowDesignerTotalItems from "./FlowDesignerTotalItems/FlowDesignerTotalItems";
import Divider from "./Divider/Divider";

import "./flowDesignerControlPanel.scss";

export interface Props {
  totalItems: number;
  isJumpToStartOfFlowDisabled: boolean;
  moveToStartOfFlow: () => void;
}

export function FlowDesignerControlPanel({ totalItems, moveToStartOfFlow, isJumpToStartOfFlowDisabled }: Props) {
  const jumpToOptions = useMemo(
    () => [
      {
        key: "Start of Flow",
        text: "Start of Flow",
        value: "Start of Flow",
        onClick: () => {
          !isJumpToStartOfFlowDisabled && moveToStartOfFlow();
        },
        disabled: isJumpToStartOfFlowDisabled,
      },
    ],
    [isJumpToStartOfFlowDisabled, moveToStartOfFlow],
  );

  return (
    <div className="flow-designer-control-panel">
      <FlowDesignerTotalItems totalItems={totalItems} />
      <Divider />
      <DropdownWrapper inline className="jump-to-dropdown" text="Jump To" items={jumpToOptions} />
    </div>
  );
}

export default memo(FlowDesignerControlPanel);
