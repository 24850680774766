import { combineReducers, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FetchingItemsState, createFetchingItemsSlice } from "features/Application/slices/createFetchingItemsSlice";
import { type AvailableTemplate } from "../../CreateAccount/types";
import { createFiltersSlice, type FiltersState } from "features/Application/slices/createFiltersSlice";
import { type TemplatesFiltersEnum } from "features/People/GroupTemplate/types/models";
import { type GenericFiltersMap } from "utils/filterUtils";
import { fetchAvailableTemplates } from "../thunks/AccountTemplatesThunk";

export interface AvailableTemplatesState extends FetchingItemsState<AvailableTemplate> {
  selected: number[];
}

export const initialState: AvailableTemplatesState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  selected: [],
};

const itemsSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.AccountCreation,
    name: "Templates",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
  },
  thunk: fetchAvailableTemplates,
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset: resetOverview, setSelected } = itemsSlice.actions;

export interface TemplatesFilterState extends FiltersState<TemplatesFiltersEnum> {}
const initialFilterState: TemplatesFilterState = {
  appliedFilter: {} as GenericFiltersMap<TemplatesFiltersEnum>,
  filterOptions: {} as GenericFiltersMap<TemplatesFiltersEnum>,
  isLoading: false,
};
const filterSlice = createFiltersSlice({
  initialState: initialFilterState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.AccountCreation,
    name: "OverviewFilters",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  reducers: {},
});
export const { setSearch, setAppliedFilter, resetAppliedFilter, resetFilter } = filterSlice.actions;

export default combineReducers({
  overview: itemsSlice.reducer,
  filters: filterSlice.reducer,
});
