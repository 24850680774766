import { combineReducers } from "redux";

import { withFetchingEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilterOptions from "../../../../Application/reducers/hoc/withFilterOptions";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withSearch from "../../../../Application/reducers/hoc/withSearch";
import { createReducer } from "../../../../../utils/reduxUtils";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import * as usersOverviewActionTypes from "./usersOverviewActionTypes";
import { type UsersOverviewState } from "../../../types";
import { type PayloadAction } from "../../../../../interfaces/redux";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.Users_Overview;

const initialState: UsersOverviewState = {
  isUploading: false,
  progress: 0,
  error: null,
};

const getVideoUploadHandlers = () => {
  const {
    UPLOAD_USERS_FILE_BEGIN,
    UPLOAD_USERS_FILE_SUCCESS,
    UPLOAD_USERS_FILE_FAIL,
    UPDATE_USERS_FILE_UPLOAD_PROGRESS,
    CANCEL_USERS_FILE_UPLOAD,
    RESET_USERS_FILE_UPLOAD,
  } = usersOverviewActionTypes;

  const updateFileBegin = (_state: UsersOverviewState) => {
    return {
      isUploading: true,
      progress: 0,
      error: null,
    };
  };

  const uploadFileSuccess = (_state: UsersOverviewState) => {
    return {
      isUploading: false,
      progress: 100,
      error: null,
    };
  };

  const uploadFileFail = (_state: UsersOverviewState, action: PayloadAction<UsersOverviewState>) => {
    let error = action.payload.error;
    return {
      isUploading: false,
      progress: 100,
      error,
    };
  };

  const uploadingFileProgress = (state: UsersOverviewState, action: PayloadAction<UsersOverviewState>) => {
    return {
      ...state,
      progress: action.payload.progress,
    };
  };

  const cancelFileUpload = (state: UsersOverviewState) => {
    return {
      ...state,
      progress: 0,
      isUploading: false,
    };
  };

  const resetFileUpload = (_state: UsersOverviewState) => {
    return initialState;
  };

  return {
    [UPLOAD_USERS_FILE_BEGIN]: updateFileBegin,
    [UPLOAD_USERS_FILE_SUCCESS]: uploadFileSuccess,
    [UPLOAD_USERS_FILE_FAIL]: uploadFileFail,
    [UPDATE_USERS_FILE_UPLOAD_PROGRESS]: uploadingFileProgress,
    [CANCEL_USERS_FILE_UPLOAD]: cancelFileUpload,
    [RESET_USERS_FILE_UPLOAD]: resetFileUpload,
  };
};

const usersOverviewReducer = combineReducers({
  usersList: withFetchingEntityListItems(namespace, entityPrefix),
  userGroupsAssignments: withFetchingEntityListItems(namespace, ReducerEntityPrefixTypes.UserGroupsAssignments),
  userRolesAssignments: withFetchingEntityListItems(namespace, ReducerEntityPrefixTypes.UserRolesAssignments),
  uploadedFileColumns: withFetchingEntityListItems(namespace, ReducerEntityPrefixTypes.UploadedFileColumns),
  usersImportPreviewData: withFetchingEntityListItems(namespace, ReducerEntityPrefixTypes.UsersImportPreviewData),
  filterOptions: withFilterOptions(namespace, entityPrefix),
  appliedFilter: createReducer({}, []),
  search: withSearch(namespace, entityPrefix),
  usersFileUploadStatus: createReducer(initialState, [getVideoUploadHandlers]),
});

export const usersOverview = withFilteringItems(namespace, entityPrefix, usersOverviewReducer);
export type PeopleUsersOverviewState = ReturnType<typeof usersOverviewReducer>;
