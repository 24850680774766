import * as actionTypes from "../actionTypes/threatDefenceSendingProfileDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../../Application/actions/actionsBuilder";
import {
  type ThreatDefenceDirectSendSendingProfile,
  type ThreatDefenceDirectSendSendingProfileView,
  type ThreatDefenceDirectSendSendingProfileVerification,
} from "../../types/state";
import { type Dispatcher } from "../../../../../../../interfaces/redux";
import { setSendingProfileEntityIdAction } from "./threatDefenceSendingProfileEntityStateActions";
import dataService from "../../../../../../Application/services/dataServices/typedDataService";

export const saveThreatDefenceDirectSendSendingProfile = (
  sendingProfileMetadata: ThreatDefenceDirectSendSendingProfileView,
) => {
  const saveThreatDefenceSendingProfileAction = getActionProvider<ThreatDefenceDirectSendSendingProfileView>(
    actionTypes.saveThreatDefenceSendingProfile,
  );
  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceSendingProfileAction(sendingProfileMetadata));
  };
};

export const saveThreatDefenceDirectSendSendingProfileVerification = (
  sendingProfileVerification: ThreatDefenceDirectSendSendingProfileVerification,
) => {
  const saveThreatDefenceSendingProfileActionVerification =
    getActionProvider<ThreatDefenceDirectSendSendingProfileVerification>(
      actionTypes.saveThreatDefenceDirectSendSendingProfileVerification,
    );
  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceSendingProfileActionVerification(sendingProfileVerification));
  };
};

export const clearThreatDefenceSendingProfile = () => {
  const resetThreatDefenceSendingProfileAction = getActionBaseProvider(actionTypes.clearThreatDefenceSendingProfile);
  return (dispatch: Dispatcher) => {
    dispatch(resetThreatDefenceSendingProfileAction());
  };
};

export const getThreatDefenceSendingProfile = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceSendingProfileBegin);
  const success = getActionProvider<ThreatDefenceDirectSendSendingProfile>(
    actionTypes.getThreatDefenceSendingProfileSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceSendingProfileFailure);

  return (dispatch: Function) => {
    dispatch(begin());
    dataService.threatDefence
      .getThreatDefenceDirectSendSendingProfileAsync(id)
      .then((result: { data: ThreatDefenceDirectSendSendingProfile }) => {
        dispatch(success(result.data));
        dispatch(setSendingProfileEntityIdAction(result.data.id || 0));
      })
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const setSendingProfileAction = getActionProvider<ThreatDefenceDirectSendSendingProfile>(
  actionTypes.setSendingProfile,
);

export const setSendingProfileIsLoaded = getActionBaseProvider(actionTypes.setSendingProfileIsLoaded);
