import { type Dispatch } from "@reduxjs/toolkit";
import assessmentDataService from "../../services/assessmentsDataService";
import { fetchTags, fetchTagsFailure } from "../slices/assessmentBaseSlice";
import { type TagsEnum } from "../../../../../interfaces/TagsEnum";
import { getBatchAddEntityTagsThunk } from "../../../Common/thunkBuilders/batchAddEntityTagsThunkBuilder";

export const fetchAssessmentTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await assessmentDataService.getTagsFilterOptions(true);
    dispatch(fetchTags(res.data));
  } catch (e) {
    dispatch(fetchTagsFailure(e as Error));
  }
};

const getMessageIds = (assessmentIds: number[], tags: string[], tagType: TagsEnum) => async () => {
  const response = await assessmentDataService.addAssessmentTags({ ids: assessmentIds, tags, tagType });
  return response?.data.messageIds;
};

export const addAssessmentTags = getBatchAddEntityTagsThunk("Assessment", getMessageIds);
