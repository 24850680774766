import React from "react";
import { Icon } from "semantic-ui-react";
import { MAX_ANSWERS_LENGTH, MIN_ANSWERS_LENGTH } from "../../constants";
import { type ValidationContext, type IAnswerBase, type ValidationValueBase, type IQuestion } from "../../models";
import { type AnswersBuilderPropsBase } from "../../props";
import { get, isEqual } from "lodash";
import { AddButton } from "../../base/AddButton";

export type AnswersBuilderBaseProps = AnswersBuilderPropsBase<IQuestion<any>> & {
  defaultDataProvider: (questionType: number) => IAnswerBase;
  renderAnswer: (
    answer: IAnswerBase,
    index: number,
    validatedFieldProps: ValidationContext<ValidationValueBase>,
    propertyNamePrefix: string,
  ) => React.ReactNode;
  renderAdditionalOptions?: (
    validatedFieldProps: ValidationContext<ValidationValueBase>,
    propertyNamePrefix: string,
  ) => React.ReactElement | null;
  renderAddAnswerButton?: (onClick: () => void) => React.ReactElement;
};

export const AnswersBuilderBase = (props: AnswersBuilderBaseProps) => {
  const {
    question,
    arrayHelpers,
    disabled,
    renderAnswer,
    validatedFieldProps,
    defaultDataProvider,
    propertyNamePrefix,
    renderAdditionalOptions,
    onChanged,
    renderAddAnswerButton,
  } = props;

  const onAddAnswer = () => {
    arrayHelpers.push(defaultDataProvider(question.questionType));
    setTimeout(() => {
      onChanged?.();
    }, 0);
  };

  const onRemoveAnswer = (index: number) => () => {
    arrayHelpers.remove(index);
    setTimeout(() => {
      onChanged?.();
    }, 0);
  };

  return (
    <>
      {question.answers.map((answer, index: number) => (
        <React.Fragment key={answer.id ?? index}>
          <div className="answers-fields">
            {renderAnswer(answer, index, validatedFieldProps, `${propertyNamePrefix}answers.${index}.`)}
            <div className={"control-panel-minus"}>
              {!disabled && index >= MIN_ANSWERS_LENGTH && (
                <Icon className={"minus circle"} size={"big"} color={"blue"} onClick={onRemoveAnswer(index)} />
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
      {renderAdditionalOptions?.(validatedFieldProps, propertyNamePrefix)}
      {renderAddAnswerButton?.(onAddAnswer) ??
        (!disabled && question.answers.length < MAX_ANSWERS_LENGTH && <AddButton onClick={onAddAnswer} />)}
    </>
  );
};

/* istanbul ignore next */
const getNeededProps = (props: AnswersBuilderBaseProps) => {
  const { arrayHelpers, validatedFieldProps, ...rest } = props;
  const errors = get(props.validatedFieldProps.errors, `${props.propertyNamePrefix}answers`);
  const touched = get(props.validatedFieldProps.touched, `${props.propertyNamePrefix}answers`);

  return { ...rest, errors, touched };
};

/* istanbul ignore next */
export default React.memo(AnswersBuilderBase, (prev, next) => {
  return isEqual(getNeededProps(prev), getNeededProps(next));
});
