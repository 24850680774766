import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";

export interface ApiCallStatus {
  isLoading: boolean;
  result: any;
  error: any;
}

export type UseApiCallResult = [(input?: any) => Promise<any>, ApiCallStatus, () => void];

const initialStatus = {
  isLoading: false,
  result: null,
  error: null,
};

export const useApiCall = (
  callApi: (input?: any) => any,
  onSuccess?: (result: any) => void,
  dependencies: any[] = [],
): UseApiCallResult => {
  const [status, setStatus] = useState<ApiCallStatus>({ ...initialStatus });
  useEffect(() => {
    if (!isEqual(status, initialStatus)) {
      return setStatus({ ...initialStatus });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  const execute = useCallback(
    async (input: any) => {
      setStatus((s) => ({ ...s, isLoading: true, result: null, error: null }));

      try {
        const result = await callApi(input);
        setStatus((s) => ({ ...s, isLoading: false, result, error: null }));
        onSuccess?.(result);
      } catch (error: any) {
        setStatus((s) => ({ ...s, isLoading: false, result: null, error }));
      }
    },
    [callApi, onSuccess],
  );

  const resetStatus = useCallback(() => {
    setStatus({ ...initialStatus });
  }, []);

  return [execute, status, resetStatus];
};
