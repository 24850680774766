import cn from "classnames";

import { Badge } from "../../Badge/Badge";
import { ReactComponent as MultipleAssetsIcon } from "../../../images/copy-solid.svg";

import styles from "./multipleAssetsThumbnail.module.scss";
import thumbnailStyles from "./priorityLevelThumbnail.module.scss";

export interface Props {
  count: number;
}

const MultipleAssetsThumbnail: React.FC<Props> = ({ count }: React.PropsWithChildren<Props>) => {
  return (
    <div className={cn("multiple-assets-thumnail-container", styles.container, thumbnailStyles.thumbnailContainer)}>
      <div className={styles.iconContainer}>
        <Badge count={count} />
        <MultipleAssetsIcon className={styles.icon} />
      </div>
      <span className={styles.text}>Multiple Assets</span>
    </div>
  );
};

export default MultipleAssetsThumbnail;
