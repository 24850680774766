enum DraftEntityTypes {
  Collections = "collections",
  Flows = "flows",
  Videos = "videoAssets",
  Emails = "emails",
  Messages = "messages",
  Assessments = "assessments",
  EmailTemplates = "threat-defence/email-templates",
  Campaigns = "threat-defence/campaigns",
  LandingPages = "threat-defence/landing-pages",
  FeedbackPages = "threat-defence/feedback-pages",
  DirectSendSendingProfile = "directSendSendingProfile",
  SmtpAuthSendingProfile = "smtpAuthSendingProfile",
  Surveys = "surveys",
  Packs = "packs",
  Events = "externalevents",
  Pdfs = "pdfs",
}
export default DraftEntityTypes;
