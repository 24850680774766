import { type AlertProps } from "components/alerts/types/Alert";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { type ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastNotifications } from "../../components";
import * as notificationsActions from "./state/notificationsActions";

export const Alerts = ({ alerts, notificationsActions }: Props) => {
  const handleRemove = (id: number | string) => notificationsActions.dismissAlerts([id]);

  const handleDismiss = (alertsToDismiss: AlertProps[]) => {
    const ids = alertsToDismiss.map((alert) => alert.id);
    notificationsActions.dismissAlerts(ids);
  };

  return <ToastNotifications alerts={alerts} onRemoveAlert={handleRemove} dismissAlerts={handleDismiss} />;
};

const mapStateToProps = (state: RootState) => ({
  alerts: state.notifications.pending,
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(Alerts);
export default ConnectedComponent;
