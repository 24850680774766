import { SortingDirection } from "../../../../../enums";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";

import { createFiltersSlice, type FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { type PdfFiltersEnum } from "../../types/models";

export const initialState: FiltersState<PdfFiltersEnum> = {
  filterOptions: {},
  appliedFilter: {},
  isLoading: false,
  pagination: 1,
  sortingColumnName: "",
  sortingDirection: SortingDirection.Descending,
};

const pdfFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Pdfs,
    name: "overviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
} = pdfFiltersSlice.actions;

export default pdfFiltersSlice.reducer;
