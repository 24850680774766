import axios from "axios";
import StatusCode from "../../../enums/httpStatusCodes";
import { type DistributedOperationResult } from "../../../types";
import {
  type ClaimType,
  type IdentityProvider,
  type CreateIdentityProvider,
  type EncodingType,
  type SignatureAlgorithm,
  type SigningCertificate,
  type IdentityProviderConfiguration,
  type UpdateIdentityProviderConfiguration,
  type IdentityProviderAttributeMapping,
  type IdentityProviderSamlSettings,
} from "../types";

const apiRoot = "/api/identity-providers";

interface IdentityProvidersDataService {
  getIdentityProviders(MoboId?: string): Promise<Array<IdentityProvider>>;

  getClaimTypes(MoboId?: string): Promise<Array<ClaimType>>;

  isProviderNameExists(name: string, MoboId?: string): Promise<boolean>;

  uploadMetadataFromFile(fileInput: File): Promise<string>;

  uploadMetadataFromUrl(fileUrl: string): Promise<string>;

  getEncodingTypes(): Promise<Array<EncodingType>>;

  getSignatureAlgorithms(): Promise<Array<SignatureAlgorithm>>;

  getSigningCertificates(): Promise<Array<SigningCertificate>>;

  createIdentityProvider(provider: CreateIdentityProvider, MoboId?: string): Promise<DistributedOperationResult>;

  deleteIdentityProviders(providerIds: number[], MoboId?: string): Promise<DistributedOperationResult>;

  updateIdentityProviderAttributeMapping(
    providerMapping: IdentityProviderAttributeMapping,
    MoboId?: number,
  ): Promise<DistributedOperationResult>;

  getIdentityProviderAttributeMapping(providerId: number, MoboId?: number): Promise<IdentityProviderAttributeMapping>;

  getIdentityProviderConfiguration(id: number, MoboId?: number): Promise<IdentityProviderConfiguration>;

  updateIdentityProviderConfiguration(
    provider: UpdateIdentityProviderConfiguration,
    MoboId?: number,
  ): Promise<DistributedOperationResult>;

  getIdentityProviderSamlSettings(id: number, MoboId?: number): Promise<IdentityProviderSamlSettings>;

  updateIdentityProviderSamlSettings(
    providerId: number,
    samlSettings: IdentityProviderSamlSettings,
    MoboId?: number,
  ): Promise<DistributedOperationResult>;
}

export default {
  async getIdentityProviders(MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;
    const response = await axios.get(apiRoot, headers);
    return response.data;
  },

  async getClaimTypes(MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.get(`${apiRoot}/claim-types`, headers);
    return response.data;
  },

  async isProviderNameExists(name: string, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    try {
      await axios.head(`${apiRoot}/${name}`, headers);
      return true;
    } catch (error: any) {
      if (error.response?.status === StatusCode.NotFound) {
        return false;
      }
      throw error;
    }
  },

  async uploadMetadataFromFile(fileInput: File) {
    const formData = new FormData();
    formData.append("fileInput", fileInput);

    const response = await axios.post(`${apiRoot}/metadata/file`, formData);
    return response.data;
  },

  async uploadMetadataFromUrl(fileUrl: string) {
    const response = await axios.post(`${apiRoot}/metadata/url`, { fileUrl });
    return response.data;
  },

  async getEncodingTypes() {
    const response = await axios.get(`${apiRoot}/encoding-types`);
    return response.data;
  },

  async getSignatureAlgorithms() {
    const response = await axios.get(`${apiRoot}/signature-algorithms`);
    return response.data;
  },

  async getSigningCertificates() {
    const response = await axios.get(`${apiRoot}/signing-certificates`);
    return response.data;
  },

  async createIdentityProvider(provider: CreateIdentityProvider, MoboId?: string) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.post(apiRoot, provider, headers);
    return response.data;
  },

  async deleteIdentityProviders(providerIds: number[], MoboId?: string) {
    const data = {
      identityProviderIds: providerIds,
    };
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
          data,
        }
      : { data };

    const response = await axios.delete<DistributedOperationResult>(apiRoot, headers);
    return response.data;
  },

  async getIdentityProviderConfiguration(id: number, MoboId?: number) {
    const headers = MoboId
      ? {
          headers: { MoboId },
        }
      : undefined;

    const response = await axios.get(`${apiRoot}/${id}`, headers);
    return response.data;
  },

  async updateIdentityProviderConfiguration(provider: UpdateIdentityProviderConfiguration, MoboId?: number) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.put<DistributedOperationResult>(`${apiRoot}/${provider.id}`, provider, headers);
    return response.data;
  },
  async updateIdentityProviderAttributeMapping(providerMapping: IdentityProviderAttributeMapping, MoboId?: number) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.put<DistributedOperationResult>(
      `${apiRoot}/${providerMapping.id}/mapping`,
      providerMapping,
      headers,
    );
    return response.data;
  },

  async getIdentityProviderAttributeMapping(providerId: number, MoboId?: number) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.get(`${apiRoot}/${providerId}/mapping`, headers);
    return response.data;
  },

  async getIdentityProviderSamlSettings(id: number, MoboId?: number) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.get(`${apiRoot}/${id}/saml`, headers);
    return response.data;
  },

  async updateIdentityProviderSamlSettings(
    providerId: number,
    samlSettings: IdentityProviderSamlSettings,
    MoboId?: number,
  ) {
    const headers = MoboId
      ? {
          headers: {
            MoboId,
          },
        }
      : undefined;

    const response = await axios.put<DistributedOperationResult>(
      `${apiRoot}/${providerId}/saml`,
      samlSettings,
      headers,
    );
    return response.data;
  },
} as IdentityProvidersDataService;
