import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../Application/globaltypes/redux";
import { type SigningCertificate } from "../../types";

export interface SigningCertificatesState {
  items: SigningCertificate[];
  isLoading: boolean;
  error: string | undefined;
}

const initialState: SigningCertificatesState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const signingCertificatesSlice = createSlice({
  name: "signingCertificates",
  initialState: initialState,
  reducers: {
    req(state: SigningCertificatesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: SigningCertificatesState, action: PayloadAction<SigningCertificate[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: SigningCertificatesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = signingCertificatesSlice.actions;

export const signingCertificatesSelector = (state: RootState) =>
  state.accounts.identityProviders.signingCertificates.items;
export const signingCertificatesLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.signingCertificates.isLoading;

export default signingCertificatesSlice.reducer;
export type signingCertificatesStateSliceType = ReturnType<typeof signingCertificatesSlice.reducer>;
