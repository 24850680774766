import { type AppDispatch } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import { type PacksContextItem } from "../../../../../../interfaces";
import { type ModalWithStepsStep } from "../../../../../../components/modal/ModalWithSteps";
import { type RenderModalActionsParams } from "components/assignmentModals/commonSteps/packsContextStep/PacksContextStep";
import { fetchDraftPackContextAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import PacksContextInfo from "features/Licensing/ContentAssignmentModalLicensingSteps/PackContextModalContent/packsContextInfo/PacksContextInfo";

import "./draftPackContextStep.scss";

export interface DraftPackContextRenderModalActionsParams extends RenderModalActionsParams {
  onCancel(): void;
}

export interface DraftPackContextStepProps extends ModalWithStepsStep {
  info: Array<PacksContextItem>;
  onIsDataValidChange: (isValid: boolean) => void;
  noDataLoaded: () => void;
  setModalStepHeader: () => void;
  onCancel(): void;
}

export type DraftPackContextStepAllProps = DraftPackContextStepProps & PropsFromRedux;

function DraftPackContextStep(props: DraftPackContextStepAllProps) {
  return (
    <div className="packs-context-step">
      <PacksContextInfo {...props} />
    </div>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchContentPacks: bindActionCreators(fetchDraftPackContextAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(DraftPackContextStep);
export default ConnectedComponent;
