import React, { useState } from "react";

import { RolePermissions, UsersGroupsContext } from "../../../../../../enums";
import { AddGroupsLinkButton, AddUsersLinkButton } from "../../../../../../components/buttons/linkButtons";
import { type FlowItem } from "../../../../../../features/Library/PeopleAssignments/types";
import { type FlowMap, type SelectionContext } from "../../types";

import EditFlowPeopleAssignment from "../../../../../../features/Library/Flows/Edit/People/Assignments/Modal/EditFlowPeopleAssignment";
import Restricted from "../../../../../../features/Application/Restricted";
import { Tooltip } from "components/common/tooltip";

export interface FlowPeopleAssignmentProps {
  selectedIds: Array<number>;
  flowMap: FlowMap;
  setSelectedIds: (ids: number[]) => void;
  selectionCtx: SelectionContext;
}

const FlowPeopleAssignment = (props: FlowPeopleAssignmentProps) => {
  const { selectedIds, flowMap, selectionCtx } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [usersGroupsContext, setUsersGroupsContext] = useState<UsersGroupsContext>(UsersGroupsContext.Users);
  const [selectedFlows, setSelectedFlows] = useState<FlowItem[]>([]);

  const allHaveBeenPublished = !selectionCtx.neverPublished;
  const allHavePack = !!selectionCtx.withPack || !!selectionCtx.purchased;
  const canAddPeople = allHaveBeenPublished && allHavePack;

  const showAddPeopleModal = (context: UsersGroupsContext) => () => {
    setSelectedFlows(selectedIds.map((id) => flowMap.get(id)) as FlowItem[]);
    setUsersGroupsContext(context);
    setShowModal(true);
  };

  const onCloseAddPeopleModal = () => {
    setShowModal(false);
    props.setSelectedIds([]);
  };

  const getAddPeopleBtn = (hasPermissions: boolean, content: JSX.Element) => {
    return (
      <Tooltip
        target={content}
        content={"To add people to the flow(s), it must first be added to a pack."}
        position="left center"
        styles={{ width: "16rem" }}
        hideOnScroll
        showAlways={hasPermissions && !canAddPeople}
      />
    );
  };

  return (
    <Restricted
      permissions={[RolePermissions.FlowsManage]}
      renderContent={(hasAnyPermission) => (
        <>
          {getAddPeopleBtn(
            hasAnyPermission,
            <AddUsersLinkButton
              onClick={showAddPeopleModal(UsersGroupsContext.Users)}
              disabled={!hasAnyPermission || !canAddPeople}
              warning={!canAddPeople}
            />,
          )}
          {getAddPeopleBtn(
            hasAnyPermission,
            <AddGroupsLinkButton
              onClick={showAddPeopleModal(UsersGroupsContext.Groups)}
              disabled={!hasAnyPermission || !canAddPeople}
              warning={!canAddPeople}
            />,
          )}
          <EditFlowPeopleAssignment
            entityItems={selectedFlows}
            showModal={showModal}
            onShowModalChanged={onCloseAddPeopleModal}
            usersGroupsContext={usersGroupsContext}
            flowIds={selectedIds}
            notifyStepDisabled={!allHaveBeenPublished}
          />
        </>
      )}
    />
  );
};

export default FlowPeopleAssignment;
