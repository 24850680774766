import { groupBy, sortBy } from "lodash";
import TriggerGroupType from "../../../../../../../../enums/flowDesigner/triggerGroupType";
import TriggerType from "../../../../../../../../enums/flowDesigner/triggerType";
import { type Trigger } from "../../../../types";
import ObjectUtils from "../../../../../../../../utils/objectUtils";

const getTriggerGroupType = (triggerType: TriggerType) => {
  const triggerGroupType: { [key in TriggerType]?: TriggerGroupType } = {
    [TriggerType.Response]: TriggerGroupType.Response,
  };
  return triggerGroupType[triggerType] || TriggerGroupType.General;
};

const getSortOrder = (triggerGroupType: TriggerGroupType) => {
  const sortOrderByTriggerGroupType: { [key in TriggerGroupType]?: number } = { [TriggerGroupType.Response]: 1 };
  return sortOrderByTriggerGroupType[triggerGroupType] || 0;
};

export const getTriggersMap = (triggers: Trigger[]) => {
  const groupedByType = ObjectUtils.typedEntries<TriggerGroupType, Trigger[]>(
    groupBy(triggers, (trigger) => getTriggerGroupType(trigger.typeId)),
  );
  return sortBy(groupedByType, (item: [TriggerGroupType, Trigger[]]) => getSortOrder(item[0]));
};
