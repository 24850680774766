import React from "react";

import { type LayoutBase } from "./types";
import ExpandableHeader from "../../ExpandableHeader/ExpandableHeader";
import { layoutTooltip } from "./constants";

export const DefaultTriggerLayout: React.FC<LayoutBase> = ({ triggers, renderTrigger }) => {
  return (
    <div className="base-triggers">
      <ExpandableHeader tooltip={layoutTooltip} title="Triggers" active>
        {triggers.map((trigger, i) => renderTrigger(i, trigger))}
      </ExpandableHeader>
    </div>
  );
};
