import React from "react";
import { type ColumnOptions } from "../../../../../interfaces";
import MainContactInfo from "./MainContactInfo";

const columnOptions: ColumnOptions[] = [
  {
    name: "Main",
    width: 1,
    isSortable: false,
    renderIcon: () => <MainContactInfo />,
    className: "main-contact",
  },
  {
    name: "Name",
    width: 4,
    isSortable: true,
  },
  {
    name: "Department",
    width: 3,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 3,
    isSortable: true,
  },
  {
    name: "Roles",
    width: 4,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default columnOptions;
