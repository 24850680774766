import uniqueId from "lodash/uniqueId";

type SubscriptionNode = HTMLElement;
type Ev = Event;
type InnerEventListener = (e: Ev) => void;
type EventType = keyof WindowEventMap;
type Subscriptions = {
  [key: string]: { eventName: EventType; innerListener: InnerEventListener };
};

const isOutsideEvent = (node: SubscriptionNode, eventTargetNode: Ev["target"]) => {
  return eventTargetNode instanceof Node && node !== eventTargetNode && !node.contains(eventTargetNode);
};

const subscriptions: Subscriptions = {};

const events = {
  subscribeToOutsideClick(node: SubscriptionNode, listener: () => void) {
    const eventName: EventType = "click";

    const innerListener: InnerEventListener = (e) => {
      if (isOutsideEvent(node, e.target)) {
        listener();
      }
    };

    document.addEventListener(eventName, innerListener);

    const listenerId = uniqueId(eventName);

    subscriptions[listenerId] = {
      eventName,
      innerListener,
    };

    return listenerId;
  },

  unsubscribe(listenerId: string) {
    if (!subscriptions[listenerId]) {
      return;
    }

    const { eventName, innerListener } = subscriptions[listenerId];
    document.removeEventListener(eventName, innerListener);
    delete subscriptions[listenerId];
  },

  unsubscribeAll() {
    Object.keys(subscriptions).forEach(this.unsubscribe);
  },
};

export default events;
