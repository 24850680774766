import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { getPrefix } from "../../../../../Application/slices/models";
import { type DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {
  type ExternalTriggerGroup,
  type ExternalTriggerType,
  type SourceEvents,
  type SourceType,
} from "../../types/externallTriggersTypes";

export interface FlowDesignerExternalTriggersState {
  externalTriggerGroups: ExternalTriggerGroup<ExternalTriggerType[]>[];
  sourceEvents: SourceEvents;
  vendorOptions: DropdownItemProps[];
  loading: {
    events: boolean;
    triggerGroups: boolean;
  };
  error: {
    events?: Error;
    triggerGroups?: Error;
  };
}

export const initialState: FlowDesignerExternalTriggersState = {
  loading: {
    events: false,
    triggerGroups: false,
  },
  error: {},
  externalTriggerGroups: [],
  sourceEvents: {
    Microsoft: undefined,
    Google: undefined,
    BrainStorm: undefined,
  },
  vendorOptions: [
    { text: "Google", value: "Google" },
    { text: "Microsoft", value: "Microsoft" },
  ],
};

const flowDesignerExternalTriggersSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "designerExternalTriggers",
  }),
  initialState: initialState,
  reducers: {
    fetchSourceEventsBegin(state) {
      state.loading.events = true;
    },

    fetchSourceEventsSuccess(
      state: FlowDesignerExternalTriggersState,
      action: PayloadAction<{ type: SourceType; events: object }>,
    ) {
      state.loading.events = false;
      state.sourceEvents[action.payload.type] = action.payload.events;
    },

    fetchSourceEventsFailure(state: FlowDesignerExternalTriggersState, action: PayloadAction<Error>) {
      state.loading.events = false;
      state.error.events = action.payload;
    },

    fetchTriggerGroupsBegin(state) {
      state.loading.triggerGroups = true;
    },

    fetchTriggerGroupsSuccess(
      state: FlowDesignerExternalTriggersState,
      action: PayloadAction<ExternalTriggerGroup<ExternalTriggerType[]>[]>,
    ) {
      state.loading.triggerGroups = false;
      state.externalTriggerGroups = action.payload;
    },

    updateTriggerGroups(
      state: FlowDesignerExternalTriggersState,
      action: PayloadAction<ExternalTriggerGroup<ExternalTriggerType[]>[]>,
    ) {
      state.externalTriggerGroups = action.payload;
    },

    fetchTriggerGroupsFailure(state: FlowDesignerExternalTriggersState, action: PayloadAction<Error>) {
      state.loading.triggerGroups = false;
      state.error.triggerGroups = action.payload;
    },

    reset: () => {
      return initialState;
    },
  },
});

export const {
  fetchSourceEventsBegin,
  fetchSourceEventsSuccess,
  fetchSourceEventsFailure,
  fetchTriggerGroupsBegin,
  fetchTriggerGroupsSuccess,
  fetchTriggerGroupsFailure,
  updateTriggerGroups,
  reset,
} = flowDesignerExternalTriggersSlice.actions;

export default flowDesignerExternalTriggersSlice.reducer;
