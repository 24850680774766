import React, { useCallback, useEffect, useState } from "react";
import { type FormikProps, withFormik } from "formik";
import { connect, type ConnectedProps } from "react-redux";

import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

import validationSchemas from "../../../../../../utils/validationSchemas";
import DropdownField from "../../../../../../components/forms/DropdownField";
import UploadFileField from "../../../../../../components/forms/UploadFileField";

import { ValidatedForm } from "../../../../../../components/forms";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import { getEntityIdSelector, normalizeDropdownItemsSelector } from "../../../state/selectors/detailsSelector";
import { bindAction } from "../../../../../../interfaces";
import {
  fetchAvailableVideoLanguages,
  refechClosedCaptions as refechClosedCaptionsAction,
  uploadClosedCaptions,
} from "../../../state/thunks/videoClosedCaptionsThunk";
import { resetLanguages } from "../../../state/slices/videoClosedCaptionsSlice";
import { type ClosedCaptionsValues } from "../../../types/models";
import { fileFieldTooltip, getValidateFieldProps, languageFieldTooltip } from "../helpers";
import { nameofFactory } from "../../../../../../utils/nameof";
import { type OmitFormik } from "../../../../../../types";
import { type UploadClosedCaptionsProps } from "../types";
import { useRtn } from "../../../../../../hooks/useRtn";

import "./uploadClosedCaptions.scss";
import { uploadedClosedCaptions } from "../../../../../../utils/validationSchemas/filesValidationSchema";

export type UploadClosedCaptionsPropsAll = UploadClosedCaptionsProps &
  FormikProps<ClosedCaptionsValues> &
  PropsFromRedux;

const ClosedCaptionsRtns = [rtnEvents.ClosedCaptionsAddSuccess, rtnEvents.ClosedCaptionsAddFailure];

export const UploadClosedCaptions = (props: UploadClosedCaptionsPropsAll) => {
  const { id, isReadOnly, availableLanguages, accountId, userId, resetForm, fetchLanguages, refechClosedCaptions } =
    props;
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    !isReadOnly && props.fetchLanguages(id);
    return () => props.resetLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isReadOnly]);

  const onCancelFileUploading = useCallback(() => {
    setIsUploading(false);
    resetForm();
  }, [resetForm]);

  const onClosedCaptionsEvent = useCallback(
    (params: { id: number }) => {
      if (params.id === id) {
        onCancelFileUploading();
        fetchLanguages(params.id);
        refechClosedCaptions(params.id);
      }
    },
    [onCancelFileUploading, fetchLanguages, refechClosedCaptions, id],
  );

  useRtn(ClosedCaptionsRtns, onClosedCaptionsEvent);

  const handleFileChange = async (files: File[]) => {
    const onUploadFileProgress = (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    };

    try {
      await uploadedClosedCaptions.validate(files);
      setIsUploading(true);
      props.uploadClosedCaptions(files[0], Number(props.values.language), id, accountId, userId, onUploadFileProgress);
    } catch (e) {}
  };

  const getFields = () => {
    const { values } = props;
    const validatedFieldProps = getValidateFieldProps(props);
    const nameof = nameofFactory<ClosedCaptionsValues>();

    return (
      <>
        <div className="upload-closed-caption-form">
          <div>
            <DropdownField
              placeholder="Select language"
              label="Language"
              propertyName={nameof("language")}
              value={values.language || null}
              disabled={isReadOnly || isUploading}
              options={availableLanguages}
              tooltip={languageFieldTooltip}
              {...validatedFieldProps}
            />
          </div>

          <UploadFileField
            id="file-upload-section"
            label="File"
            disabled={isReadOnly || Boolean(!values.language)}
            loadingButtonLabel="Loading..."
            accept=".vtt"
            propertyName={nameof("uploadedClosedCaptions")}
            onChangeHandler={handleFileChange}
            tooltip={fileFieldTooltip}
            showPercents={false}
            isUploading={isUploading}
            uploadingProgress={percent}
            value={values.uploadedClosedCaptions}
            onCancelFileUploading={onCancelFileUploading}
            filesValidator={uploadedClosedCaptions}
            {...validatedFieldProps}
          />
        </div>
      </>
    );
  };

  return (
    <div className="upload-block">
      <h4 className="header">Upload CC file</h4>
      <ValidatedForm formWidthComputer={16} parentWithFormik disablePreventTransitionPrompt>
        {getFields}
      </ValidatedForm>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: UploadClosedCaptionsProps) => {
  return {
    id: ownProps.entityId || getEntityIdSelector(state),
    availableLanguages: normalizeDropdownItemsSelector(state),
    accountId: state.userProfile.accountId,
    userId: state.userProfile.id,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchLanguages: bindAction(fetchAvailableVideoLanguages, dispatch),
  refechClosedCaptions: bindAction(refechClosedCaptionsAction, dispatch),
  resetLanguages: bindAction(resetLanguages, dispatch),
  uploadClosedCaptions: bindAction(uploadClosedCaptions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const component = withFormik<UploadClosedCaptionsPropsAll, ClosedCaptionsValues>({
  validationSchema: validationSchemas.closedCaptions,
  enableReinitialize: true,
  mapPropsToValues: () => ({}) as ClosedCaptionsValues,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(UploadClosedCaptions) as React.ComponentType<OmitFormik<UploadClosedCaptionsPropsAll, ClosedCaptionsValues>>;

const ConnectedComponent = connector(component);
export default ConnectedComponent;
