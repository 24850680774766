import React from "react";
import ActionConfirmationModal from "../modal/ActionConfirmationModal/ActionConfirmationModal";

interface IProps {
  isOpen: boolean;
  selectedEmailTemplate: number;
  onCancel: () => void;
  onContinue: () => void;
}

const DeleteEmailTemplatesConfirmationModal = (props: IProps) => {
  const { isOpen, onCancel, onContinue } = props;
  const title = "Delete Email Template";

  return (
    <ActionConfirmationModal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      onContinue={onContinue}
      withWarningLabel={false}
      confirmLabel="Delete"
    >
      {`Delete email template? This will remove all email template data and cannot be undone.`}
    </ActionConfirmationModal>
  );
};

export default DeleteEmailTemplatesConfirmationModal;
