import { type ComponentDecorator, type FormikProps, type FormikValues, type WithFormikConfig, withFormik as formik } from "formik";
import { type OmitFormik } from "../types";
import ObjectUtils from "./objectUtils";

export function withFormik<OuterProps extends object, Values extends FormikValues, Payload = Values>({
  mapPropsToValues,
  ...config
}: WithFormikConfig<OuterProps, Values, Payload>): ComponentDecorator<
  OmitFormik<OuterProps, Values>,
  OuterProps & FormikProps<Values>
> {
  return formik({ mapPropsToValues, ...config }) as ComponentDecorator<
    OmitFormik<OuterProps, Values>,
    OuterProps & FormikProps<Values>
  >;
}

/* istanbul ignore next */
export const extractFormikProps = <TValues, TProps extends FormikProps<TValues>>(
  props: TProps,
): { [key in keyof FormikProps<TValues>]: TProps[key] } => {
  return ObjectUtils.pick(
    props,
    "dirty",
    "errors",
    "getFieldHelpers",
    "getFieldMeta",
    "getFieldProps",
    "handleBlur",
    "handleChange",
    "handleReset",
    "handleSubmit",
    "initialErrors",
    "initialTouched",
    "initialValues",
    "isSubmitting",
    "isValid",
    "isValidating",
    "registerField",
    "resetForm",
    "setErrors",
    "setFieldError",
    "setFieldTouched",
    "setFieldValue",
    "setFormikState",
    "setStatus",
    "setSubmitting",
    "setTouched",
    "setValues",
    "submitCount",
    "submitForm",
    "touched",
    "unregisterField",
    "validateField",
    "validateForm",
    "values",
    "enableReinitialize",
    "initialStatus",
    "isInitialValid",
    "status",
    "validateOnBlur",
    "validateOnChange",
    "validateOnMount",
  );
};

// NOSONAR TODO: find (getValidateFieldProps) and replace all similar code with this function
export const getValidateFieldProps = <T extends Object>(formikProps: FormikProps<T>) => {
  const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
    formikProps;
  return {
    errors: errors,
    touched: touched,
    isValid: isValid,
    handleChange: handleChange,
    handleBlur: handleBlur,
    setFieldValue: setFieldValue,
    setFieldTouched: setFieldTouched,
    dirty: dirty,
    resetForm: resetForm,
  };
};
