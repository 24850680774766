import { capitalize, isEmpty } from "lodash";

import {type DistributedOpUpdateParams} from "../../../../interfaces/updateParams";
import type UpdateParams from "../../../../interfaces/updateParams";
import { type NotifyStepSettings } from "../../../../interfaces";
import {
  ContentType,
  type EntityToPeopleAssignments,
  type EntityToPeopleRemovePriorities,
  type EntityToPeopleUnAssignments,
  type EntityToContentRemovePriorities,
} from "../types";
import { pluralize } from "../../../../utils/stringUtils";

import assignmentsDataService from "../../../Application/services/dataServices/assignmentsDataService";
import Strings from "../../../../enums/strings";
import { type AssignmentPeopleContext } from "../../../../enums";

enum AssignmentType {
  People = "People",
  User = "User",
  Group = "Group",
}

interface PeopleToContentCommonProps<T> {
  items: T[];
  contentType: ContentType;
  usersLength?: number;
  groupsLength?: number;
  notificationSettings?: NotifyStepSettings;
}

export interface PeopleToContentProps<T> extends PeopleToContentCommonProps<T> {
  action: (params: UpdateParams) => Promise<void>;
}

export interface PeopleToContentPriorityProps<T> {
  action: (params: UpdateParams) => Promise<void>;
  items: T[];
}

export interface EditPriorityPayload<T> {
  action: (params: DistributedOpUpdateParams) => Promise<void>;
  items: T[];
}

export interface PeopleToContentDistributedOpProps<T> extends PeopleToContentCommonProps<T> {
  action: (params: DistributedOpUpdateParams) => Promise<void>;
}

export interface ContentToPeopleCommonProps<T> {
  items: T[];
  peopleType: AssignmentPeopleContext;
  notificationSettings?: NotifyStepSettings;
}

export interface ContentToPeopleDistributedOpProps<T> extends ContentToPeopleCommonProps<T> {
  action: (params: DistributedOpUpdateParams) => Promise<void>;
}

const toString = (type: ContentType) =>
  ({
    [ContentType.Assessment]: "Assessment",
    [ContentType.Survey]: "Survey",
    [ContentType.Video]: "Video",
    [ContentType.Pdf]: "PDF",
    [ContentType.Flow]: "Flow",
    [ContentType.Event]: "Event",
  }[type]);

const getPeopleType = (peopleLength: number, users?: number, groups?: number) => {
  if (users && groups) {
    return AssignmentType.People;
  }
  return pluralize((users ? AssignmentType.User : AssignmentType.Group).toString(), peopleLength);
};

export const assignPeopleToContentDistributed = async (
  props: PeopleToContentDistributedOpProps<EntityToPeopleAssignments>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const isSingle = props.items.length === 1;
  const peopleType = getPeopleType(props.items.length, props.usersLength, props.groupsLength);
  const contentType = toString(props.contentType);

  const params: DistributedOpUpdateParams = {
    id: `AddingTo_${props.contentType}_${new Date()}`,
    title: `Adding ${peopleType} to ${contentType}`,
    info: `Some ${peopleType} ${
      isSingle ? "is" : "are"
    } still being added and may not have access to this ${contentType} immediately.`,
    getOperationProps: async () => {
      return assignmentsDataService.setPriority(props.items, props.notificationSettings);
    },
    successTransientMessage: `${peopleType} ${
      isSingle ? "has" : "have"
    } been added to ${contentType}. (User(s) may not have access to this ${contentType} immediately.)`,
    failureTransientMessage: `Addition ${peopleType} to ${contentType} failed!`,
  };

  await props.action(params);
};

export const unassignPeopleDistributed = async (
  props: PeopleToContentDistributedOpProps<EntityToPeopleUnAssignments>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const isSingle = props.items.length === 1;
  const peopleType = getPeopleType(props.items.length, props.usersLength, props.groupsLength);

  const params: DistributedOpUpdateParams = {
    id: `UnassigningFrom_${props.contentType}_${new Date()}`,
    title: `Removing ${peopleType.toLowerCase()} access`,
    getOperationProps: () => {
      return assignmentsDataService.removeAssignmentsV2(props.items);
    },
    successTransientMessage: `${peopleType} ${isSingle ? "has" : "have"} been removed from ${toString(
      props.contentType,
    )} successfully`,
    failureTransientMessage: `Removing ${peopleType} to ${toString(props.contentType)} failed!`,
  };

  await props.action(params);
};

export const removePeoplePrioritiesDistributed = async (
  props: PeopleToContentDistributedOpProps<EntityToPeopleRemovePriorities>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const peopleType = getPeopleType(props.items.length, props.usersLength, props.groupsLength);

  const params: DistributedOpUpdateParams = {
    id: `RemovingPrioritiesFrom_${props.contentType}_${new Date()}`,
    title: `Clearing priority settings...`,
    getOperationProps: () => {
      return assignmentsDataService.removePriorities(props.items);
    },
    successTransientMessage: `Priority settings cleared successfully.`,
    failureTransientMessage: `Removing ${peopleType} to ${toString(props.contentType)} failed!`,
  };

  await props.action(params);
};

export const assignContentToPeopleDistributed = async (
  props: ContentToPeopleDistributedOpProps<EntityToPeopleAssignments>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const peopleType = capitalize(props.peopleType);

  const params: DistributedOpUpdateParams = {
    id: `AddingTo_${props.peopleType}(s)_${Date.now()}`,
    title: `Adding to ${props.peopleType}(s)`,
    info: `Some content is still being added and user(s) may not have access to it immediately`,
    getOperationProps: async () => {
      return assignmentsDataService.setPriority(props.items, props.notificationSettings);
    },
    successTransientMessage: `${peopleType}(s) successfully updated! (User(s) may not have access to this content immediately.)`,
    failureTransientMessage: `Failed to add content to ${props.peopleType}(s)`,
  };

  await props.action(params);
};

export const unassignContentDistributed = async (
  props: ContentToPeopleDistributedOpProps<EntityToPeopleUnAssignments>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const peopleType = props.peopleType;
  const itemsCount = props.items.length;

  const params: DistributedOpUpdateParams = {
    id: `UnassigningFrom_${peopleType}_${new Date()}`,
    title: `Remove from ${peopleType}`,
    getOperationProps: () => {
      return assignmentsDataService.removeAssignmentsV2(props.items);
    },
    successTransientMessage: `(${itemsCount}) content ${pluralize("item", itemsCount)} ${
      itemsCount > 1 ? "have" : "has"
    } been removed from this ${peopleType} successfully.`,
    failureTransientMessage: `Failed to remove content from this ${peopleType}.`,
  };

  await props.action(params);
};

export const removeContentPrioritiesDistributed = async (
  props: ContentToPeopleDistributedOpProps<EntityToContentRemovePriorities>,
) => {
  if (isEmpty(props.items)) {
    return;
  }
  const peopleType = props.peopleType;

  const params: DistributedOpUpdateParams = {
    id: `RemovingPrioritiesFrom_${peopleType}_${new Date()}`,
    title: `Clearing priority settings...`,
    getOperationProps: () => {
      return assignmentsDataService.removePriorities(props.items);
    },
    successTransientMessage: `Priority settings cleared successfully.`,
    failureTransientMessage: `Failed to remove content from this ${peopleType}.`,
  };

  await props.action(params);
};

export const editAssignmentsPriority = async (props: PeopleToContentPriorityProps<EntityToPeopleAssignments>) => {
  if (isEmpty(props.items)) {
    return;
  }
  const taskStrings = Strings.people.assignments.changePriority;

  const params: UpdateParams = {
    id: `EditPriority_${new Date()}`,
    title: taskStrings.title,
    getMessageIds: async () => {
      const response = await assignmentsDataService.editAssignmentsPriority(props.items);

      return response?.data?.messageIds;
    },
    successTitle: taskStrings.successTitle,
    successTransientMessage: taskStrings.successMessage,
    failureTransientMessage: taskStrings.failedMessage,
    indeterminate: true,
  };

  await props.action(params);
};

export const editPriority = async (props: EditPriorityPayload<EntityToPeopleAssignments>) => {
  if (isEmpty(props.items)) {
    return;
  }
  const taskStrings = Strings.people.changePriority;

  const params: DistributedOpUpdateParams = {
    id: `EditPriority_${new Date()}`,
    title: taskStrings.title,
    getOperationProps: async () => {
      const response = await assignmentsDataService.editPriority(props.items);

      return response?.data;
    },
    successTitle: taskStrings.successTitle,
    successTransientMessage: taskStrings.successMessage,
    failureTransientMessage: taskStrings.failedMessage,
    indeterminate: true,
  };

  await props.action(params);
};
