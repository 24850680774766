import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import { ChartWrapper } from "components/charts";
import { ComboChart } from "components/charts/ComboChart/ComboChart";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import {
  defaultDateFilter,
  isBsi,
  noData,
  validBarData,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import AccountsTable from "./components/AccountsTable";
import EnterpriseTable from "./components/EnterpriseTable";
import {
  fetchPeopleOverviewLineCharts,
  fetchPeopleOverviewStatistics,
  sharedOverviewLineProps,
  yearDateRange,
} from "./utils";
import moment from "moment";
import { type RootState } from "features/Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";

import styles from "./peopleOverviewReport.module.scss";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";
import CardReporting from "components/cards/CardReporting/CardReporting";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";
import {
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  reset as resetExports,
} from "features/Reporting/state/export/exportSlice";
import { type Dispatch } from "@reduxjs/toolkit";
import { bindAction } from "interfaces";
import { ReportingExport } from "components/reportingExport/ReportingExport";

export type Props = PropsFromRedux & {
  scrollToTop: () => void;
};

// Remove account id once data team is finished with bsi table.
export const PeopleOverviewReport = ({
  scrollToTop,
  accountId,
  accountType,
  resetExport,
  hasDataToExport,
  exportInfo,
  exporting,
}: Props) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const reportEnabled = !!useFeatureFlag(FeatureFlags.MauReports);
  const [filter, setFilter] = useState(
    defaultDateFilter({
      initialDateRange: yearDateRange(),
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
    }),
  );

  useEffect(() => {
    setFilter(
      defaultDateFilter({
        initialDateRange: yearDateRange(),
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  // Remove account id once data team is finished with bsi table.
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(accountId);

  const lineChartQuery = useQuery({
    queryFn: fetchPeopleOverviewLineCharts,
    queryKey: ["PeopleOverviewLineCharts", filter],
    enabled: reportEnabled,
  });

  const cardsQuery = useQuery({
    queryFn: fetchPeopleOverviewStatistics,
    queryKey: ["PeopleOverviewStatistics", filter],
    enabled: reportEnabled,
  });

  const { registerBreadcrumb, hasBreadcrumbsToDisplay, domElements } = usePerformanceBreadcrumbs();

  const handleAccountSelect = useCallback(
    (id: number, name: string) => {
      setSelectedAccountId(id);
      const isOwnAccount = id === accountId;
      setFilter({ ...filter, accounts: isOwnAccount ? [] : [id], includeMyData: !!isOwnAccount });
      registerBreadcrumb({ text: name, action: () => setSelectedAccountId(null) });
      scrollToTop();
    },
    [registerBreadcrumb, scrollToTop, accountId, filter],
  );

  useEffect(() => {
    if (accountFilterShouldShow && isBsi(accountId)) setSelectedAccountId(null);
  }, [accountFilterShouldShow, accountId]);

  const tooltipFormatter = useCallback(
    (x: Date, y: number, index: number) => {
      if (lineChartQuery.data) {
        return lineChartQuery.data.activeUsersTooltips[index];
      }

      return `${moment(x).format("MMM 'YY")}: ${y.toLocaleString()} Users`;
    },
    [lineChartQuery.data],
  );

  useEffect(() => {
    return () => resetExport();
  }, [resetExport]);

  const handleExportClick = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  return (
    <div className="performanceRoot">
      <div className="performanceHeader peopleActivityHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="performanceTitle">Summary</h2>
        )}
        {accountFilterShouldShow && isBsi(accountId) && (
          <div className="videoActions">
            <ReportingFilter
              currentFilter={{
                ...filter,
              }}
              callback={setFilter}
              includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
              initialDateRange={yearDateRange()}
              ignoreInCountAndHide={["type", ...(hasBreadcrumbsToDisplay ? ["accounts", "includeMyData"] : "")]}
            />
            <ReportingExport onClick={handleExportClick} currentlyExporting={exporting} disabled={!hasDataToExport} />
          </div>
        )}
      </div>

      <div className={styles.comboChartContainer}>
        <ChartWrapper
          titles={"Monthly Active Users vs Interactions"}
          showLegend
          legendLabels={["Active Users", "Interactions"]}
          colorRange={["#288bed", "#ED9D08"]}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isFetched &&
            (validLineData(lineChartQuery.data!.activeUsers.yData) ||
              validBarData(lineChartQuery.data?.interactionsAsBarData!)) ? (
              <ComboChart
                {...sharedAccountReportingLineProps}
                xData={lineChartQuery.data!.activeUsers.xData[0]}
                lineYData={lineChartQuery.data!.activeUsers.yData[0]}
                barYData={lineChartQuery.data!.interactionsAsBarData}
                lineTooltipFormatter={tooltipFormatter}
                {...sharedOverviewLineProps}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>

      <div className="tabSpacer">
        <RequestStatusRenderer state={cardsQuery.status}>
          <CardReporting items={cardsQuery.data!} />
        </RequestStatusRenderer>
      </div>

      {selectedAccountId === null ? (
        <AccountsTable filter={filter} handleAccountSelection={handleAccountSelect} />
      ) : (
        <EnterpriseTable
          includeHyperlink={
            (accountFilterShouldShow &&
              isBsi(accountId) &&
              filter.accounts?.length > 0 &&
              selectedAccountId === accountId) ||
            selectedAccountId === accountId
          }
          filter={filter}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
    exportInfo: selectExportInfo(state),
    exporting: selectExporting(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetExport: bindAction(resetExports, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PeopleOverviewReport);
export default ConnectedComponent;
