import { setExporting } from "features/Reporting/state/export/exportSlice";
import AlertTypes from "../../../../../../enums/alertTypes";
import { getAxiosError } from "../../../../../../utils/axiosError";
import { type AppDispatch } from "../../../../../Application/globaltypes/redux";
import { sendTransientNotification } from "../../../../../Notifications/state/notificationsActions";
import { downloadExcelExport } from "../../../../Common/utils/performanceUtils";
import { type EmailActivity } from "../../EmailDetails/Performance/types";
import emailsDataService from "../../services/emailsDataService";
import {
  reqSendDensity,
  setSendDensity,
  setSendDensityError,
  reqEngagementData,
  setEngagementData,
  setEngagementError,
  reqSendsDetails,
  setSendsDetails,
  setSendsDetailsError,
  reqSendSessions,
  setSendSessions,
  setSendSessionsError,
} from "../slices/emailPerformanceSlice";
import { type AccountTypePerformanceRequestFilterParams } from "features/Library/Common/models";
import moment from "moment";

type EmailInformation = {
  emailId: number;
  sendSessionId?: number;
};

export const fetchEmailSendDensity = (
  emailInfo: EmailInformation,
  filterParams: AccountTypePerformanceRequestFilterParams,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendDensity());
    try {
      const { data } = await emailsDataService.getEmailLineChart(
        emailInfo.emailId,
        emailInfo.sendSessionId,
        filterParams,
      );
      dispatch(setSendDensity({ data }));
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSendDensityError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendFunnel = (
  emailInfo: EmailInformation,
  filterParams: AccountTypePerformanceRequestFilterParams,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqEngagementData());
    try {
      const missingRates = (
        await emailsDataService.getEmailEngagement(emailInfo.emailId, emailInfo.sendSessionId, filterParams)
      ).data;
      const data: EmailActivity = {
        ...missingRates,
        OpenRate: 0,
        ClickRate: 0,
      };
      dispatch(setEngagementData({ data }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendSessions = (emailId: number, filterParams: AccountTypePerformanceRequestFilterParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendSessions());
    try {
      const { data } = await emailsDataService.getEmailSendSessions(emailId, filterParams);
      dispatch(
        setSendSessions({
          data: data.map((d) => ({
            ...d,
            id: d.SendSessionId,
            SendSession: d.SendSession
              ? // Convert send to correct timezone (according to the timezone in the response)
                // Timezone is either null or a string in the format of "Europe/Kiev"
                // By default, timezone is in UTC
                moment
                  .utc(d.SendSession)
                  .tz(d.TimeZone || "UTC")
                  .format("MM/DD/YYYY h:mm A")
              : "-",
          })),
        }),
      );
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSendSessionsError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendDetails = (
  emailId: number,
  sendSessionId: number,
  filterParams: AccountTypePerformanceRequestFilterParams,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendsDetails());

    try {
      const results = (await emailsDataService.getEmailSendDetailsV3(emailId, sendSessionId, filterParams)).data;

      const resultsWithId = results.map((item, index) => {
        return {
          ...item,
          id: index.toString(),
        };
      });

      dispatch(
        setSendsDetails({
          data: resultsWithId,
        }),
      );
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setSendsDetailsError({ errorMessage }));
    }
  };
};

export const handleCommunicationsExport = (
  emailId: number,
  filterParams: AccountTypePerformanceRequestFilterParams,
  emailTitle: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await emailsDataService.getExport(emailId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, emailTitle);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      const errorMessage = getAxiosError(err);
      dispatch(
        sendTransientNotification(
          {
            title: "Export Failed!",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      console.error("Unable to export", errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const handleSendSessionExport = (
  sendSessionId: number,
  filterParams: AccountTypePerformanceRequestFilterParams,
  fileName: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await emailsDataService.getSendSessionExport(sendSessionId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, fileName);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      const errorMessage = getAxiosError(err);
      dispatch(
        sendTransientNotification(
          {
            title: "Export Failed!",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      console.error("Unable to export", errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};
