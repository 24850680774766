import { type SyntheticEvent } from "react";
import cn from "classnames";

import CardThumbnail from "../cardThumbnail/CardThumbnail";
import CardContent from "../cardContent/CardContent";
import { PublishedStatus } from "../../common/publishedStatus";
import EllipsisPopupButton from "../../buttons/ellipsisPopupButton/EllipsisPopupButton";
import type Icon from "../../../interfaces/icon";
import { DeleteLinkButton, EditLinkButton } from "../../buttons/linkButtons";
import SelectableCard from "../selectableCard/SelectableCard";
import Restricted from "../../../features/Application/Restricted";
import { RolePermissions, PublishedStatusTypes, RouteNames } from "../../../enums";
import { PackStatusActionLabel } from "../../packs/packStatusActionLabel/PackStatusActionLabel";
import { PackPublisherInfo } from "../../packs/packPublisherInfo/PackPublisherInfo";
import PreviewLinkButton from "../../buttons/linkButtons/previewLinkButton/PreviewLinkButton";
import { PackArchivedLabel } from "../../packs/packsList/packArchivedLabel/PackArchivedLabel";
import { type PublisherInfo } from "features/Licensing/Packs/Overview/types";
import { type PackOverview } from "../../../features/Licensing/Packs/types/state";
import { PackPrivateLabel } from "../../packs/packPrivateLabel/PackPrivateLabel";

import "./packCard.scss";

export interface PackCardProps {
  item: PackOverview;
  selected: boolean;
  disabled: boolean;
  onSelect: (id: number) => void;
  onTitleClick: (id: number, isOwn: boolean, isPurchased: boolean, publisherInfo: PublisherInfo) => void;
  onPreview: (id: number, publisherInfo: PublisherInfo) => void;
  showPackRequestModal: (id: number, title: string, isTrial: boolean, trialDuration: number) => void;
  onEdit: (id: number, isDraft?: boolean) => void;
  onDelete: (id: number) => void;
  icons?: Icon[];
}

function PackCard(props: PackCardProps) {
  const { onSelect, onEdit, onDelete, onTitleClick, onPreview, icons, showPackRequestModal, item } = props;

  const {
    id,
    thumbnailImageUrl,
    title,
    description,
    isDraft,
    isOwn,
    isRequested,
    isPurchased,
    isExpired,
    publisher,
    logoUri,
    isTrialAllowed,
    trialDuration,
    isTrial,
    isArchived,
    isPrivate,
  } = item;
  const previewButton = (
    <PreviewLinkButton
      onClick={() => {
        onPreview(id, { name: publisher, logoUri });
      }}
      isDisabled={isArchived}
    />
  );
  const editButton = (
    <EditLinkButton
      key={cn("EditLinkButton-", id)}
      onClick={() => {
        onEdit(id, isDraft);
      }}
    />
  );
  const deleteButton = (
    <DeleteLinkButton
      key={cn("DeleteLinkButton-", id)}
      onClick={() => {
        onDelete(id);
      }}
    />
  );

  const menuButtons = [previewButton, editButton, deleteButton];

  const openPackRequestModal = (e: SyntheticEvent, isTrialPeriod: boolean): void => {
    e.preventDefault();
    e.stopPropagation();
    showPackRequestModal(id, title, isTrialPeriod, trialDuration);
  };

  const onCardClick = () => {
    onTitleClick && onTitleClick(id, isOwn, isPurchased, { name: publisher, logoUri });
  };

  const renderBottomRowContent = () => {
    if (isOwn && !isRequested && !isPurchased) {
      return (
        <div className="bottom-row">
          <PublishedStatus publishedStatus={status} />
          <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
            {isOwn && (
              <div onClick={(e: SyntheticEvent) => e.preventDefault()}>
                <EllipsisPopupButton circle>{menuButtons}</EllipsisPopupButton>
              </div>
            )}
          </Restricted>
        </div>
      );
    }
    return (
      <>
        <div className="bottom-row">
          {/* Moved PublishedStatus in div until icon for own pack status is defined */}
          {isOwn && <PublishedStatus publishedStatus={status} />}
          <PackStatusActionLabel
            isExpired={isExpired}
            isOwn={isOwn}
            isPurchased={isPurchased}
            isRequested={isRequested}
            isTrialAllowed={isTrialAllowed}
            isTrial={isTrial}
            onRequestClick={(e: SyntheticEvent) => {
              openPackRequestModal(e, false);
            }}
            onTrialClick={(e: SyntheticEvent) => {
              openPackRequestModal(e, true);
            }}
          />
          <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
            {isOwn && <EllipsisPopupButton circle>{menuButtons}</EllipsisPopupButton>}
          </Restricted>
          {!isOwn && isPurchased && <EllipsisPopupButton circle>{[previewButton]}</EllipsisPopupButton>}
        </div>
      </>
    );
  };

  const renderCardLabels = () => {
    return (
      <div className="label-row">
        <Restricted permissions={[RolePermissions.PacksManage]}>
          {isOwn && <PackPrivateLabel isPrivate={isPrivate} />}
        </Restricted>
      </div>
    );
  };

  const status = isDraft ? PublishedStatusTypes.draft : PublishedStatusTypes.published;
  return (
    <SelectableCard
      id={id}
      className={cn("pack-card")}
      onCardClick={isArchived ? null : onCardClick}
      onSelect={onSelect}
      disabled={isArchived}
      url={isOwn ? `/${RouteNames.licensingPacks}/${id}` : ""}
    >
      <CardThumbnail thumbnailUrl={thumbnailImageUrl} disabled={isArchived}>
        {renderCardLabels()}
      </CardThumbnail>
      <CardContent
        title={title}
        icons={icons}
        preHeader={<PackPublisherInfo publisherName={publisher} disabled={isArchived} />}
        postHeader={<PackArchivedLabel isArchived={isArchived} />}
        disabled={isArchived}
        skipDefaultFiller
      >
        <div className="description-row">{description}</div>
        <div className="filler" />
        {renderBottomRowContent()}
      </CardContent>
    </SelectableCard>
  );
}

export default PackCard;
