import { type FC, useEffect, useState } from "react";
import { Button } from "components/buttons/button/Button";
import { UserListStep } from "../../commonSteps/index";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import userListUtils from "../../../../utils/userListUtils";
import { type UsersAssignmentModalOneStepProps } from "./types";
import { type SortingDirection } from "../../../../enums";
import { type FiltersMap } from "../../../../utils/filterUtils";
import { type Filters } from "../../../../utils/queryUtils";
import SearchInput from "../../../searchInput/SearchInput";

import "./usersAssignmentModalOneStep.scss";

export const UsersAssignmentModalOneStep: FC<UsersAssignmentModalOneStepProps> = (
  props: UsersAssignmentModalOneStepProps,
) => {
  const [selectedIds, setSelectionIds] = useState<number[]>([]);
  const [appliedFilter, setFilter] = useState<Filters>({});

  const { showModal, onCancel } = props;
  useEffect(() => {
    if (!showModal) {
      setSelectionIds([]);
      setFilter({});
    }
  }, [showModal]);

  const onFetchPotentialUsers = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: FiltersMap,
  ) => {
    const { loadPage } = props;
    const orderParams = search
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    loadPage(skip, top, orderParams, filter);
  };

  const renderModalActions = () => {
    const { onConfirm } = props;
    return (closeModal: Function) => {
      return (
        <>
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              closeModal();
              onCancel();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={selectedIds.length === 0}
            onClick={() => {
              onConfirm(selectedIds);
              closeModal();
            }}
          />
        </>
      );
    };
  };

  const applyUserFilter = (filter: Filters) => setFilter(filter);

  const resetUserFilter = () => setFilter({});

  const {
    users,
    usersAmount,
    isListLoading,
    tooltipMessage,
    assignmentEntityType,
    hasPermissionPredicate,
    search,
    onSearchChanged,
    setReloadListItems,
  } = props;

  return (
    <ModalWithSteps
      className="users-assignment-modal-one-step"
      scrolling
      isLoading={false}
      showModal={showModal}
      onCancel={onCancel}
    >
      <UserListStep
        renderSearch={() => (
          <SearchInput placeholder="Search for users..." onChange={onSearchChanged} defaultValue={search} />
        )}
        search={search}
        header="Add People"
        renderModalActions={renderModalActions}
        loadPage={onFetchPotentialUsers}
        isLoading={isListLoading}
        users={users}
        usersCount={usersAmount}
        onSelectedListItemsChanged={(ids: number[]) => setSelectionIds(ids)}
        selectedIds={selectedIds}
        applyFilter={applyUserFilter}
        filter={appliedFilter}
        resetFilter={resetUserFilter}
        multipleAssignment
        alreadyAssignedUserTooltip={tooltipMessage}
        showRoleFilter={false}
        hasPermissionPredicate={hasPermissionPredicate}
        assignmentEntityType={assignmentEntityType}
        departmentFilterDisabled
        jobTitleFilterDisabled
        setReloadListItems={setReloadListItems}
      />
    </ModalWithSteps>
  );
};
