import React from "react";
import PropTypes from "prop-types";
import { Accordion, Icon } from "semantic-ui-react";

export function MenuAccordionItem(props) {
  const { path, className, expanded, onClick, children } = props;

  const handleClick = () => {
    onClick && onClick(path);
  };

  return (
    <Accordion.Title className={className} active={expanded} onClick={handleClick}>
      {children}
      <div className="expand-collapse">
        <Icon className="fa-chevron-right" size="tiny" />
      </div>
    </Accordion.Title>
  );
}

MenuAccordionItem.propTypes = {
  path: PropTypes.string,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuAccordionItem;
