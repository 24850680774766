import { TriggerableConfirmationModal } from "components";
import { type IObservable } from "interfaces";
import React, { useState } from "react";

interface Props {
  processTemplateRequestObserver: IObservable<(onSubmit: () => void, approve: boolean, owner: string) => void>;
}

export const ProcessTemplateRequestModal = (props: Props) => {
  const { processTemplateRequestObserver } = props;
  const [cfg, setCfg] = useState({ approve: false, owner: "" });

  const content = cfg.approve ? (
    <>
      <b>{cfg.owner}</b> is requesting permission to manage users via this group and enable the corresponding Packs in
      your account. By approving this request, you also consent to allow <b>{cfg.owner}</b> to prioritize content for
      these users.
    </>
  ) : (
    <>
      By declining this request, you will not have access to the Packs inherited from this group, and <b>{cfg.owner}</b>{" "}
      will not be able to manage any users in your account. Are you sure you want to continue?
    </>
  );

  return (
    <TriggerableConfirmationModal
      content={content}
      onTriggerModalObserver={processTemplateRequestObserver}
      confirmButtonLabel={cfg.approve ? "Approve" : "Decline"}
      title={cfg.approve ? "Approve Request?" : "Decline Request?"}
      onTrigger={(approve: boolean, owner: string) => {
        setCfg({ approve, owner });
      }}
    />
  );
};
