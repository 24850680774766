import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type PackOverview } from "../../types/state";
import { createCancelingLazyFetchingItemsSlice } from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import { fetchAccountPackLicenses } from "../thunks/accountPackLicensesThunk";

export interface AccountPackLicensesState {
  areAllLoaded: boolean;
  itemsCount: number;
  items: PackOverview[];
  isLoading: boolean;
}

export const initialState: AccountPackLicensesState = {
  areAllLoaded: false,
  itemsCount: 0,
  items: [],
  isLoading: false,
};

const accountPacksSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "accountPackLicenses",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchAccountPackLicenses,
});

export const { reset } = accountPacksSlice.actions;

export default accountPacksSlice.reducer;
