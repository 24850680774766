import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

export const createEventBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createEventSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createEventFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const resetEventEntityState = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const updateEventBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateEventFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const updateEventCommandSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const publishEventBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishEventSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishEventFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const fetchLockedEventBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const fetchLockedEventSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const fetchLockedEventFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const fetchDiscardEventBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const fetchDiscardEventSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const fetchDiscardEventFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const eventUpdated = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);
export const setEventEntityId = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  entityStateActionTypes.SET_ENTITY_ID,
);
