import React from "react";
import PropTypes from "prop-types";
import { useDrop, useDragLayer } from "react-dnd";

const DragAndDropArea = (props) => {
  const { canDrop: canDropHandler, onDrop, renderDragAndDropSection, acceptType } = props;
  const [{ canDrop }, drop] = useDrop({
    accept: [acceptType],
    drop: (item, monitor) => {
      onDrop && onDrop(item, monitor);
    },
    canDrop: (item, monitor) => {
      if (canDropHandler) {
        return canDropHandler(item, monitor);
      }
      return true;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  const { isDragging, itemType } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || itemType !== acceptType) {
    return null;
  }

  return <div ref={drop}>{canDrop && renderDragAndDropSection()}</div>;
};

DragAndDropArea.propTypes = {
  canDrop: PropTypes.func,
  onDrop: PropTypes.func,
  renderDropSection: PropTypes.func,
  acceptType: PropTypes.string,
};

export default DragAndDropArea;
