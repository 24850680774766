import StatusCode from "../../../../enums/httpStatusCodes";
import { type AppDispatch } from "../../../Application/globaltypes/redux";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import { type IdentityProviderAttributeMapping } from "../../types";
import { req, err, got, updated, forbidden } from "../slices/identityProviderAttributeMappingSlice";

export const fetchIdentityProviderAttributeMapping = (id: number, moboId?: number) => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const data = await identityProvidersDataService.getIdentityProviderAttributeMapping(id, moboId);
    dispatch(got(data));
  } catch (error) {
    const errorMessage = (error as Error).message;
    const errorAction = error?.response.status === StatusCode.Forbidden ? forbidden(errorMessage) : err(errorMessage);
    dispatch(errorAction);
  }
};

export const dispatchUpdateIdentityProviderAttributeMapping =
  (attributeMapping: IdentityProviderAttributeMapping) => (dispatch: AppDispatch) => {
    dispatch(updated(attributeMapping));
  };
