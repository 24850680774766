import arrayUtils from "../../../utils/arrayUtils";

const localStorageKey = "backgroundTasksIds";

const get = () => JSON.parse(localStorage.getItem(localStorageKey));
const set = (item) => localStorage.setItem(localStorageKey, JSON.stringify(item));

export default class BackgroundTaskLocalStorage {
  saveTask(taskId) {
    let ids = get();
    if (ids && !ids.includes(taskId)) {
      ids.push(taskId);
    } else {
      ids = [taskId];
    }
    set(ids);
  }

  removeTask(taskId) {
    let ids = get();
    if (!ids) {
      return;
    }
    ids = arrayUtils.withoutItem(ids, taskId);
    set(ids);
  }

  getAll() {
    return get() || [];
  }

  clearAll() {
    localStorage.removeItem(localStorageKey);
  }
}
