import {
  type ContentAssignmentModelItem,
  type IFlowAssignmentModelItem,
  type IVideoAssignmentModelItem,
} from "../../../features/People/types";
import { some } from "lodash";
import { type SelectedItem } from "../types";

const mapToSelectedItem = (item: ContentAssignmentModelItem): SelectedItem => {
  const result = {
    id: item.id,
    title: item.title,
    thumbnailUrl: item.thumbnailUrl,
    durationInSeconds: (item as IVideoAssignmentModelItem)?.durationInSeconds,
    canAutoStart: (item as IFlowAssignmentModelItem)?.canAutoStart,
    canRemove: (item as IFlowAssignmentModelItem)?.canRemove,
    inherited: item.isInherited,
  };

  return result;
};

export const updateSelectedContentItems = (collection: ContentAssignmentModelItem[], ids: number[]): SelectedItem[] => {
  const toAdd = ids.map((id) => ({ id }));

  const selectedItems = collection.filter((f) => some(toAdd, (item) => item.id === f.id)).map(mapToSelectedItem);
  return [...selectedItems];
};
