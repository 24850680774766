import * as actionTypes from "../actionTypes/threatDefenceEmailTemplatesOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import {
  type ThreatDefenceEmailTemplatesOverviewState,
  type ThreatDefenceEmailTemplateOverview,
  type DeleteThreatDefenceEmailTemplateOverviewResult,
} from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../../interfaces/redux";

const initialState: ThreatDefenceEmailTemplatesOverviewState = {
  emailTemplates: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const threatDefenceEmailTemplatesOverviewHandlers = () => {
  const {
    getThreatDefenceEmailTemplatesBegin: getEmailTemplatesBegin,
    getThreatDefenceEmailTemplatesSuccess: getEmailTemplatesSuccess,
    getThreatDefenceEmailTemplatesFailure: getEmailTemplatesFailure,
    deleteThreatDefenceEmailTemplatesBegin: deleteEmailTemplatesBegin,
    deleteThreatDefenceEmailTemplatesSuccess: deleteEmailTemplatesSuccess,
    deleteThreatDefenceEmailTemplatesFailure: deleteEmailTemplatesFailure,
  } = actionTypes;

  const getEmailTemplatesBeginHandler = (
    state: ThreatDefenceEmailTemplatesOverviewState,
  ): ThreatDefenceEmailTemplatesOverviewState => ({
    ...state,
    emailTemplates: new Array<ThreatDefenceEmailTemplateOverview>(),
    isLoading: true,
  });

  const getEmailTemplatesSuccessHandler = (
    state: ThreatDefenceEmailTemplatesOverviewState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceEmailTemplateOverview>>,
  ): ThreatDefenceEmailTemplatesOverviewState => ({
    ...state,
    emailTemplates: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const deleteEmailTemplatesBeginHandler = (
    state: ThreatDefenceEmailTemplatesOverviewState,
  ): ThreatDefenceEmailTemplatesOverviewState => ({
    ...state,
    isLoading: true,
  });

  const deleteEmailTemplatesSuccessHandler = (
    state: ThreatDefenceEmailTemplatesOverviewState,
    action: PayloadAction<DeleteThreatDefenceEmailTemplateOverviewResult>,
  ): ThreatDefenceEmailTemplatesOverviewState => {
    if (!action.payload.isSuccess) {
      return {
        ...state,
        isLoading: false,
      };
    } else {
      return {
        ...state,
        emailTemplates: state.emailTemplates.filter((et) => et.id !== action.payload.id),
        totalCount: state.totalCount - 1,
        isLoading: false,
      };
    }
  };

  const failureHandler = (
    state: ThreatDefenceEmailTemplatesOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceEmailTemplatesOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getEmailTemplatesBegin]: getEmailTemplatesBeginHandler,
    [getEmailTemplatesSuccess]: getEmailTemplatesSuccessHandler,
    [getEmailTemplatesFailure]: failureHandler,
    [deleteEmailTemplatesBegin]: deleteEmailTemplatesBeginHandler,
    [deleteEmailTemplatesSuccess]: deleteEmailTemplatesSuccessHandler,
    [deleteEmailTemplatesFailure]: failureHandler,
  };
};

export const threatDefenceEmailTemplatesOverviewReducer = createReducer(initialState, [
  threatDefenceEmailTemplatesOverviewHandlers,
]);
