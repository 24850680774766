import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { userManager } from "./userManager";

export const SignoutCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function processSignout() {
      const response = await userManager.signoutRedirectCallback(window.location.href);
      const redirectLocation = response.userState ?? "/";
      navigate(redirectLocation, { replace: true });
    }

    processSignout();
  }, [navigate]);

  return <Loader active />;
};
