import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { type UserWithPackAccessType } from "../../../../../interfaces";

export interface PackUsersAvailableForLicensingState extends FetchingItemsState<UserWithPackAccessType> {
  usersSearchTerm: string;
}

const initialState: PackUsersAvailableForLicensingState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
  usersSearchTerm: "",
};

const packUsersAvailableForLicensingSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.PackUsersAvailableForLicensing,
    name: "packUsersAvailableForLicensing",
  },
  initialState: initialState,
  reducers: {
    setSearchTerm: (state: PackUsersAvailableForLicensingState, action: PayloadAction<string>) => {
      state.usersSearchTerm = action.payload;
    },
    resetSearchTerm: (state: PackUsersAvailableForLicensingState) => {
      state.usersSearchTerm = "";
    },
  },
});

export const { fetchBegin, fetchSuccess, fetchFailure, setSearchTerm, resetSearchTerm } =
  packUsersAvailableForLicensingSlice.actions;

export default packUsersAvailableForLicensingSlice.reducer;
export type packUsersAvailableForLicensingSliceType = ReturnType<typeof packUsersAvailableForLicensingSlice.reducer>;
