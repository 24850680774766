import type UpdateParams from "../../../../../../interfaces/updateParams";
import { type AppDispatch, type AppThunk } from "../../../../../Application/globaltypes/redux";
import backgroundTask from "../../../../../BackgroundTasks/backgroundTask";
import DataService from "../../../../../Application/services/dataServices/typedDataService";
import { pluralize } from "../../../../../../utils/stringUtils";

export const duplicateEmails =
  (ids: number[], fetchEmails: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getMessageIds = (emailIds: number[]) => async () => {
      const result = await DataService.emails.duplicateEmails(emailIds);
      return result?.data.messageIds;
    };

    const idsCount = ids.length;
    const entity = pluralize("Email", idsCount);

    let successMessage = `${entity} ${idsCount === 1 ? "has" : "have"} been duplicated!`;
    let failureMessage = `${entity} duplicate failed!`;

    const updateParams: UpdateParams = {
      id: "DuplicateEmail",
      title: `Duplicate email`,
      getMessageIds: getMessageIds(ids),
      onCompleted: () => fetchEmails(),
      successTransientMessage: successMessage,
      failureTransientMessage: failureMessage,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };

export const deleteEmails =
  (ids: number[], fetchEmails: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getMessageIds = (emailIds: number[]) => async () => {
      const result = await DataService.emails.deleteEmails(emailIds);
      return result?.data.messageIds;
    };

    const idsCount = ids.length;
    const entity = pluralize("Email", idsCount);

    let successMessage = `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`;
    let failureMessage = `${entity} delete failed!`;

    const updateParams: UpdateParams = {
      id: "DeleteEmail",
      title: `Deletion of emails`,
      getMessageIds: getMessageIds(ids),
      onCompleted: () => fetchEmails(),
      successTransientMessage: successMessage,
      failureTransientMessage: failureMessage,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };

export const addTags =
  (ids: number[], tags: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!tags.length) {
      return;
    }

    const getMessageIds = (emailIds: number[]) => async () => {
      const result = await DataService.emails.addTags(emailIds, tags);
      return result?.data.messageIds;
    };

    const labelsCount = tags.length;
    const entity = pluralize("Label", labelsCount);

    let successMessage = `${entity} ${labelsCount === 1 ? "has" : "have"} been added successfully!`;
    let failureMessage = `${entity} adding failed!`;

    const updateParams: UpdateParams = {
      id: "AddEmailsTags",
      title: `Adding tags`,
      getMessageIds: getMessageIds(ids),
      successTransientMessage: successMessage,
      failureTransientMessage: failureMessage,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
