import { type ReactNode } from "react";
import { faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames/bind";
import styles from "./trendingTable.module.scss";

const cx = cn.bind(styles);

type TrendingEntity = { Trending: number; TrendingChange: "Up" | "Down" | "No Change" };
export const thisMonthColumn = (entity: TrendingEntity): ReactNode => {
  const icon = entity.TrendingChange === "Down" ? faSortDown : faSortUp;
  return (
    <div
      className={cx(styles.currentlyTrending, {
        [styles.downTrend]: entity.TrendingChange === "Down",
        [styles.upTrend]: entity.TrendingChange === "Up",
        [styles.staticTrend]: entity.TrendingChange === "No Change",
      })}
    >
      <div>{entity.Trending.toLocaleString()}</div>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};
