import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import { type RootState } from "../../../../Application/globaltypes/redux";
import { type EmailTemplateDetailsState, type Pages } from "./types";
import * as emailTemplateDetailsActions from "../state/actions/threatDefenceEmailTemplateDetailsActions";
import * as emailTemplateEntityStateActions from "../state/actions/threatDefenceEmailTemplateEntityStateActions";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import Segments from "../../../../../components/navigation/segments/Segments";
import { PublishedStatusTypes } from "../../../../../enums";
import Configure from "../EmailDetails/Configure/Configure";
import Content from "../EmailDetails/Content/Content";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import EmailTemplateDetailsSubheaderContainer from "./EmailTemplateDetailsSubheaderContainer";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import dataService from "../services/threatDefenceEmailTemplatesDataService";
import modalUtilsFactory, { type ModalUtils } from "../../../../../utils/modalUtilsFactory";
import RevertConfirmationModal from "../../../../../components/modal/RevertConfirmationModal";
import ModalTypes from "../../../../../components/modal/ModalTypes";
import azureStorageUtils from "../../../../../utils/azureStorageUtils";
import { withRouter, type WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const emailsOverviewUrl = "/content/simulated-phishing/email-templates";

export type EmailTemplateDetailsPropsAll = PropsFromRedux & WithRouterProps;

export class EmailTemplateDetails extends Component<EmailTemplateDetailsPropsAll, EmailTemplateDetailsState> {
  private pages: Pages;
  private emailTemplateId: number;
  private readonly revertModal: ModalUtils;

  private stepsManager = new WizardStepsManager();

  constructor(props: EmailTemplateDetailsPropsAll) {
    super(props);
    this.state = {
      isSaving: false,
      modifiedDate: undefined,
      isValid: true,
      isContentValid: true,
      isErrorViewMode: false,
      areErrorsResolved: false,
      showRevertModal: false,
    };
    this.pages = {
      configure: {
        index: 0,
        segment: {
          label: "Configuration",
          onClick: this.loadConfigurePageOnce,
        },
      },
      content: {
        index: 1,
        segment: {
          to: "content",
          label: "Content",
          onClick: this.loadContentPageOnce,
        },
      },
    };
    this.emailTemplateId = this.getEmailTemplateId();
    this.revertModal = modalUtilsFactory();
  }
  async componentDidMount() {
    this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplate(this.emailTemplateId);
    this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplateContent(this.emailTemplateId, true);

    RtnEventsEmitter.subscribe(
      [rtnEvents.EmailTemplatePublishSuccess, rtnEvents.EmailTemplateDiscardSuccess],
      this.onPublishedEvent,
    );

    const urlWithSas = await dataService.getThreatDefenceEmailTemplateContentUrlWithSasAsync(
      this.emailTemplateId,
      !this.isReadOnlyMode(),
    );
    const html = await azureStorageUtils.downloadAsString(urlWithSas);

    if (!html) {
      this.setState({ isContentValid: false });
    }

    this.setCurrentStepIndex();
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.EmailTemplatePublishSuccess, rtnEvents.EmailTemplateDiscardSuccess],
      this.onPublishedEvent,
    );
  }

  loadConfigurePageOnce = () => {
    this.stepsManager.goToPage(this.pages.configure.index);
    if (!this.props.isEmailLoaded) {
      this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplate(this.emailTemplateId);
    }
  };

  loadContentPageOnce = () => {
    this.stepsManager.goToPage(this.pages.content.index);
    if (!this.props.isEmailContentLoaded) {
      this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplateContent(this.emailTemplateId, true);
    }
  };

  setCurrentStepIndex = () => {
    const {
      location: { pathname },
      params,
    } = this.props;
    const { content } = this.pages;
    let stepIndex = 0;

    if (pathname === `${emailsOverviewUrl}/${params.id}/${content.segment.to}`) {
      stepIndex = content.index;
    }

    this.stepsManager.goToPage(stepIndex);
  };

  onPublishedEvent = () =>
    [
      this.props.emailTemplateDetailsActions.clearThreatDefenceEmailTemplate,
      this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplate,
      this.props.emailTemplateDetailsActions.getThreatDefenceEmailTemplateContent,
    ].forEach((action) => action(this.emailTemplateId));

  getEmailTemplateId = () => {
    return Number.parseInt(this.props.params.id!);
  };

  isReadOnlyMode = () => this.props.emailTemplate.isDraft === false;

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.isContentValid !== isValid) {
      this.setState({
        isContentValid: isValid,
        isErrorViewMode: false,
        areErrorsResolved: this.state.isErrorViewMode && isValid,
      });
    }
  };

  handleClose = () => {
    this.props.navigate(emailsOverviewUrl);
  };

  handlePublish = () => {
    if (!this.state.isContentValid) {
      this.setState({ isErrorViewMode: true });
      return;
    }
    this.props.emailTemplateEntityStateActions.publishDraftThreatDefenceEmailTemplateEntity(this.emailTemplateId);
    this.resetValidation();
  };

  handleEdit = () => {
    this.props.emailTemplateEntityStateActions.fetchDraftThreatDefenceEmailTemplateEntity(this.emailTemplateId);
  };

  handleRevert = () => {
    this.closeRevertPopup();
    this.props.emailTemplateEntityStateActions.fetchDiscardThreatDefenceEmailTemplateEntity(this.getEmailTemplateId());
  };

  onRevert = () => {
    this.revertModal.execute(this.getEmailTemplateId(), this.handleRevert, () =>
      this.setState({
        ...this.state,
        showRevertModal: true,
      }),
    );
    this.resetValidation();
  };

  renderRevertModal = (isRevertVisible: boolean) => {
    return (
      <RevertConfirmationModal
        modalUtils={this.revertModal}
        modalType={ModalTypes.RevertEmailTemplate}
        open={this.state.showRevertModal}
        onClose={this.closeRevertPopup}
        isVisible={isRevertVisible}
      />
    );
  };

  closeRevertPopup = () => {
    this.setState({
      ...this.state,
      showRevertModal: false,
    });
  };

  resetValidation = () => {
    this.setState({ isErrorViewMode: false, areErrorsResolved: false });
  };

  renderPublishValidationInfo = () => {
    const { navigate, location } = this.props;
    const { isErrorViewMode, areErrorsResolved } = this.state;
    const redirectTo = `/content/simulated-phishing/email-templates/${this.getEmailTemplateId()}/content`;

    return (
      <PublishValidationInfo
        unableToPublish={isErrorViewMode}
        onErrorButtonClick={() => {
          if (!location.pathname.includes(redirectTo)) {
            navigate(redirectTo);
            this.loadContentPageOnce();
          }
        }}
        readyToPublish={areErrorsResolved}
      />
    );
  };

  render() {
    const { emailTemplate } = this.props;
    const { configure, content } = this.pages;
    const { isValid, isErrorViewMode } = this.state;
    const disabled = this.isReadOnlyMode();

    return (
      <div className="nested-content edit-email-template-details scrollable-content">
        <DetailsHeader
          title={emailTemplate.name}
          titleForGA="Phishing Email Template Details"
          backButton={emailsOverviewUrl}
          canBeEdited={!emailTemplate.isDraft}
          isRevertVisible={emailTemplate.hasBeenPublished}
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!emailTemplate.isDraft)}
          invalidFormDetails={!isValid}
          canBePublished={!isErrorViewMode}
          entityStateActions={{
            onPublish: this.handlePublish,
            onClose: this.handleClose,
            onEdit: this.handleEdit,
            onRevert: this.onRevert,
          }}
        >
          {this.renderPublishValidationInfo()}
        </DetailsHeader>
        {this.renderRevertModal(emailTemplate.hasBeenPublished)}
        <EmailTemplateDetailsSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!emailTemplate.isDraft)}
          isUpdateInProgress={emailTemplate.isDraft}
          lastModifiedDateTime={emailTemplate.dateModified}
        >
          <Segments to={`/content/simulated-phishing/email-templates/${this.emailTemplateId}`}>
            <Segments.Segment {...configure.segment} />
            <Segments.Segment {...content.segment} />
          </Segments>
        </EmailTemplateDetailsSubheaderContainer>
        <Dimmer active={this.props.isLoading} inverted>
          <Loader />
        </Dimmer>

        <Routes>
          <Route
            path="/"
            element={
              <div className="edit-form">
                <Configure
                  entityId={this.emailTemplateId}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
                  onIsValidChange={this.onIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route
            path={content.segment.to}
            element={
              <div className="edit-form">
                <Content
                  entityId={this.emailTemplateId}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, content.index)}
                  onIsValidChange={this.onContentIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  emailTemplate: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.email,
  isLoading: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.isLoading,
  isEmailLoaded: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.isEmailLoaded,
  isEmailContentLoaded:
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.isEmailContentLoaded,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  emailTemplateDetailsActions: bindActionCreators(emailTemplateDetailsActions, dispatch),
  emailTemplateEntityStateActions: bindActionCreators(emailTemplateEntityStateActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(EmailTemplateDetails));
export default ConnectedComponent;
