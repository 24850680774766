import { type ColumnOptions } from "../../interfaces";
import SortOptions from "../../enums/SortOptions";
import { SortingDirection } from "../../enums/sortingDirection";

export interface ColumnOptionParams {
  isPriorityHide?: boolean;
  isExpiresHide?: boolean;
  isStatusHide?: boolean;
  isAddedHide?: boolean;
  goals?: boolean;
}

export const defaultSortingColumn = "Modified";

export const sortOptionsListView = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export const sortOptionsByIdListView = [
  {
    text: "Most Recent",
    value: SortOptions.Id,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export const flowColumnOptionsListView = ({
  isPriorityHide,
  isExpiresHide,
  isStatusHide,
  isAddedHide
}: ColumnOptionParams): ColumnOptions[] => {
  return [
    {
      name: "Title",
      width: 3,
      isSortable: true,
    },
    {
      name: "Objective",
      width: 3,
      isSortable: true,
    },
    {
      name: "Publisher",
      width: 2,
    },
    {
      name: "Status",
      width: isPriorityHide && isAddedHide && isExpiresHide ? 6 : 2,
      isHide: isStatusHide,
    },
    {
      name: "Priority Level",
      width: 5,
      isHide: isPriorityHide,
    },
    {
      name: "Added",
      width: 3,
      isSortable: true,
      isHide: isAddedHide,
    },
    {
      name: "Expires",
      width: 3,
      isSortable: false,
      isHide: isExpiresHide ?? true,
    },
    {
      name: "",
      width: 1,
    },
  ];
};

export const videoColumnOptionsListView = ({
  isPriorityHide,
  isExpiresHide,
  isStatusHide,
  isAddedHide,
}: ColumnOptionParams): ColumnOptions[] => {
  return [
    {
      name: "Title",
      width: 4,
      isSortable: true,
    },
    {
      name: "Publisher",
      width: 3,
    },
    {
      name: "Status",
      width: isPriorityHide && isAddedHide && isExpiresHide ? 6 : 3,
      isHide: isStatusHide,
    },
    {
      name: "Priority Level",
      width: 3,
      isHide: isPriorityHide,
    },
    {
      name: "Added",
      width: 3,
      isSortable: true,
      isHide: isAddedHide,
    },
    {
      name: "Expires",
      width: 3,
      isSortable: false,
      isHide: isExpiresHide ?? true,
    },
    {
      name: "",
      width: 1,
    },
  ];
};

export const SortDirectionLong: { [key: string]: SortingDirection } = {
  asc: SortingDirection.Ascending,
  desc: SortingDirection.Descending,
};
