import { useEffect, useRef } from "react";
import { debounce } from "lodash";

export const useChartResizeTransition = (
  transitionDurationInMs: number,
): [React.MutableRefObject<number>, React.RefObject<HTMLDivElement>] => {
  // Resize observer will call once when its mounted.
  // We ignore this call so any enter transitions will run
  const initialCallRef = useRef(false);
  const resizeRef = useRef<HTMLDivElement>(null);
  const transitionDurationToReturn = useRef(transitionDurationInMs);

  useEffect(() => {
    if (!resizeRef.current) return;

    const ele = resizeRef.current;

    const resetTransitionDebounce = debounce(() => {
      transitionDurationToReturn.current = transitionDurationInMs;
      // Delay to account for multiple resize events (ie user dragging browser smaller)
    }, 100);

    const resizeObs = new ResizeObserver(() => {
      // Ignoring the first call to resize observer, as its called when mounted
      if (!initialCallRef.current) {
        initialCallRef.current = true;
        return;
      }
      transitionDurationToReturn.current = 0;
      resetTransitionDebounce.cancel();
      resetTransitionDebounce();
    });
    resizeObs.observe(ele);
    return () => {
      resetTransitionDebounce.cancel();
      resizeObs.unobserve(ele);
    };
  }, [transitionDurationInMs]);

  return [transitionDurationToReturn, resizeRef];
};
