import React, { type FC, useRef } from "react";
import { type FormikProps } from "formik";

import { ValidatedForm } from "../../../../../components/forms";
import { type AssessmentView } from "../../types/state";
import { type AssessmentFormPropsOwn } from "../types";

import QuestionSettings from "../QuestionSettings/QuestionSettings";

export type AssessmentFormProps = AssessmentFormPropsOwn & FormikProps<AssessmentView>;

const descriptionName = "description";

export const AssessmentForm: FC<AssessmentFormProps> = ({
  dirty,
  isLoading,
  disablePreventTransitionPrompt,
  values,
  onBlur,
  save,
  onIsValidChange,
  disabled,
  ...restProps
}) => {
  const hasInitialDescription = useRef(values.description.length > 0);
  const { id, title } = values;

  const onTitleBlur = (propertyName: string) => {
    if (id <= 0 && !restProps.touched[descriptionName] && !hasInitialDescription.current) {
      restProps.setFieldValue(descriptionName, title, true);
    }

    onBlur(propertyName);
  };

  const getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm } = restProps;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };

  const renderFields = (): any => {
    const validatedFieldProps = getValidateFieldProps();

    return (
      <div className="assessment-form-container">
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Name"
          value={values.title}
          onBlur={onTitleBlur}
          tooltip={{
            info: "This field is for organizational purposes and is surfaced to end users.",
            width: 17,
          }}
          placeholder={"Enter Assessment Name"}
          propertyName="title"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
        />
        <ValidatedForm.TextAreaField
          {...validatedFieldProps}
          label="Description"
          tooltip={{
            info: "This field is for organizational purposes and is surfaced to end users.",
            width: 17,
          }}
          placeholder={"Enter Description"}
          value={values.description}
          propertyName="description"
          hidden
          onBlur={onBlur}
          disabled={disabled}
          markAsRequired
        />
        <QuestionSettings
          onBlur={onBlur}
          onChanged={save}
          validatedFieldProps={validatedFieldProps}
          values={values}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <ValidatedForm
      dirty={dirty}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this assessment? All information entered will be lost.",
      }}
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      isLoading={isLoading}
      formWidthComputer={6}
      parentWithFormik
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default AssessmentForm;
