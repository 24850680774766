import { DeleteLinkButton, RestrictedByTooltip, TextTruncate } from "../../../../../components";
import EllipsisPopupButton from "../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { Tooltip } from "../../../../../components/common/tooltip";
import { type ColumnOption } from "../../../../../interfaces/columnOptions";
import { type Reminder, ScheduleType } from "../../types/models";

export const ScheduleTypeToStringMap: {
  [P in ScheduleType]: string;
} = {
  [ScheduleType.None]: "",
  [ScheduleType.MinutesBefore]: "minute(s) before event",
  [ScheduleType.HoursBefore]: "hour(s) before event",
  [ScheduleType.DaysBefore]: "day(s) before event",
};

export enum RemindersTabColumns {
  Title = "title",
  Description = "description",
  Execute = "execute",
}

export interface GetColumnOptionsParams {
  readonly?: boolean;
  handleDeleteClick: (ids: number) => () => void;
}

export const getRemindersColumnOptions = (params: GetColumnOptionsParams): ColumnOption<Reminder>[] => {
  return [
    {
      name: "",
      width: 1,
      render: () => <i className="bell icon" />,
    },
    {
      name: RemindersTabColumns.Title,
      width: 4,
      render: (item) => <Tooltip target={<TextTruncate>{item.title}</TextTruncate>} content={item.title} />,
    },
    {
      name: RemindersTabColumns.Description,
      width: 6,
      render: (item) => <Tooltip target={<TextTruncate>{item.description}</TextTruncate>} content={item.description} />,
    },
    {
      name: RemindersTabColumns.Execute,
      width: 4,
      render: (item) => `${item.time} ${ScheduleTypeToStringMap[item.scheduleType]}`,
    },
    {
      name: "",
      width: 2,
      className: "align-right",
      isSortable: false,
      render: (item, isChecked) => (
        <RestrictedByTooltip hasPermission={!params.readonly}>
          <EllipsisPopupButton circle outlinedEllipsis disabled={params.readonly || isChecked}>
            <DeleteLinkButton onClick={params.handleDeleteClick(item.id)} />
          </EllipsisPopupButton>
        </RestrictedByTooltip>
      ),
    },
  ];
};
