import { type IQuestion } from "../../../../../Common/models";
import { type Picture } from "../../../../../Common/Question/Questions/PictureQuestion";
import { type ISurveyQuestion } from "../types";
import { getAnswerDefaultData } from "./defaultAnswerProvider";
import { SurveyQuestionType, SurveyAnswerType } from "../../../../types/models";
import { v4 } from "uuid";

const defaultQuestionData = ((): { [key in SurveyQuestionType]: () => ISurveyQuestion<any> } => {
  const getMultipleChoiceTextQuestion = (): ISurveyQuestion<string> => ({
    questionType: SurveyQuestionType.MultipleChoiceTextQuestion,
    question: "",
    isRequired: false,
    isBranching: false,
    questionOrder: undefined,
    answers: [getAnswerDefaultData(SurveyAnswerType.Text), getAnswerDefaultData(SurveyAnswerType.Text)],
    id: v4(),
  });

  const getSingleChoiceTextQuestion = (): ISurveyQuestion<string> => ({
    questionType: SurveyQuestionType.SingleChoiceTextQuestion,
    question: "",
    isRequired: false,
    isBranching: false,
    questionOrder: undefined,
    answers: [getAnswerDefaultData(SurveyAnswerType.Text), getAnswerDefaultData(SurveyAnswerType.Text)],
    id: v4(),
  });

  return {
    [SurveyQuestionType.MultipleChoiceTextQuestion]: getMultipleChoiceTextQuestion,
    [SurveyQuestionType.SingleChoiceTextQuestion]: getSingleChoiceTextQuestion,
    /* istanbul ignore next */
    [SurveyQuestionType.PictureQuestion]: () =>
      ({ questionType: SurveyQuestionType.PictureQuestion } as IQuestion<Picture>),
  };
})();

export const getQuestionDefaultData = (questionType?: SurveyQuestionType): ISurveyQuestion<any> =>
  defaultQuestionData[questionType ?? SurveyQuestionType.MultipleChoiceTextQuestion]();
