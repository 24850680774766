import UserInfoCell from "../../../../../../../components/userInfoCell/UserInfoCell";
import { type ColumnOption } from "../../../../../../../interfaces/columnOptions";
import { ColumnToParamMap } from "../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { type PeopleAssignment } from "../../../../../PeopleAssignments/types";

export enum EventAssignmentColumns {
  Name = "Name",
}

export const EventColumnToParamMap: {
  [P in Lowercase<EventAssignmentColumns>]: string;
} = {
  ...ColumnToParamMap,
};

export interface EventPeopleAssignment extends PeopleAssignment {
  expirationDate?: string;
}

export const getEventUserColumnOptions = <T extends EventPeopleAssignment>(): ColumnOption<T>[] => {
  return [
    {
      name: EventAssignmentColumns.Name,
      width: 5,
      isSortable: true,
      disabled: (item) => !!item.isInherited,
      render: (item) => <UserInfoCell deepLink={!item.isInherited} user={item} />,
    },
  ];
};
