import React, { useCallback } from "react";
import { Dropdown, type DropdownProps, type DropdownItemProps } from "semantic-ui-react";

import cn from "classnames";
import "./dropdownWrapper.scss";

interface DropdownWrapperProps extends DropdownProps {
  items?: DropdownItemProps[];
  handleOptionChange?: DropdownProps["onChange"];
  direction?: "left" | "right";
}

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  items = [],
  placeholder = "",
  className,
  selected,
  blur,
  handleOptionChange,
  handleAddItem,
  disabled,
  direction,
  ...additionalProps
}) => {
  const onChangeAction = useCallback(
    (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const { currentTarget } = e;
      handleOptionChange?.(e, data);
      if (blur) {
        setTimeout(() => {
          currentTarget.parentElement?.parentElement?.blur();
        }, 0);
      }
    },
    [blur, handleOptionChange],
  );

  return (
    <Dropdown
      className={cn("dropdown-control", className)}
      value={selected}
      options={items}
      onAddItem={handleAddItem}
      disabled={disabled}
      onChange={onChangeAction}
      placeholder={placeholder}
      direction={direction}
      {...additionalProps}
    />
  );
};

export default DropdownWrapper;
