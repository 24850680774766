import { stripoLayoutStyles } from "./stripoLayoutStyles";

export const baseLayout = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns:o="urn:schemas-microsoft-com:office:office"
      xmlns:w="urn:schemas-microsoft-com:office:word"
      xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
      xmlns="http://www.w3.org/TR/REC-html40">
<head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="format-detection" content="address=no" />

    <!--[if mso]>
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <style> body,table tr,table td,a, span,table.MsoNormalTable {  font-family:Arial, Helvetica, sans-serif !important;  }</style>
    <!--<![endif]-->
    <style type="text/css">
        /* Force Outlook to provide a "view in browser" message */
        #outlook a {
            padding: 0;
        }

        .ReadMsgBody {
            width: 100%;
        }
        /* Force Hotmail to display emails at full width */
        .ExternalClass {
            width: 100%;
        }
        /* Force Hotmail to display normal line spacing */
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        /* Prevent WebKit and Windows mobile changing default text sizes */
        body, table, td, p, a, li, blockquote {
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }
        /* Remove spacing between tables in Outlook 2007 and up */
        table, td {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }
        /* Custom styles for card layout email templates */
        body {
            padding: 50px 50px 0;
            box-sizing: border-box;
            margin: 0;
            height: 100% !important;
            width: 100% !important;
            font-family: Arial, Helvetica, sans-serif !important;
            font-size: 16px !important;
            color: #4c4441; /* Was #857874 */
            -webkit-text-size-adjust: none;
        }
        
        img {
            border: 0;
            line-height: 100%;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        a img {
            border: 0 none;
        }

        .imageFix {
            display: block;
        }

        a[class="button"] {
            min-width: 200px !important;
            color: #ffffff;
            text-decoration: none;
        }

        tr[class="five pusher"] {
            height: 5px !important;
        }

        tr[class="ten pusher"] {
            height: 10px !important;
        }

        tr[class="twenty pusher"] {
            height: 20px !important;
        }

        tr[class="thirty pusher"] {
            height: 30px !important;
        }

        tr[class="forty pusher"] {
            height: 40px !important;
        }

        tr[class="fifty pusher"] {
            height: 50px !important;
        }

        .text.center, td[class="text center"] {
            text-align: center !important;
        }

        .text.right, td[class="text right"] {
            text-align: right !important;
        }

        /* Card Classes */
        .card,
        .button {
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            box-shadow: 0px 1px 3px 0px #d9d9d6;
        }

        .button {
            box-shadow: none;
        }

        .image.cap {
            max-width: 100% !important;
        }

        .bottom.border {
            border-bottom: 1px solid #c4bcb7;
        }

        /* BSI colors */
        .white {
            background-color: #ffffff;
        }

        .darkest.gray {
            color: #4c4441;
        }

        .dark.gray {
            color: #6d6360;
        }

        .medium.gray {
            color: #857874;
        }

        .light.gray {
            color: #c4bcb7;
        }

        .lightest.gray {
            color: #d9d9d6;
        }

        .light.yellow {
            color: #ffe582;
        }

        .medium.yellow {
            color: #ffcb05;
        }

        .dark.yellow {
            color: #e6b200;
        }

        .blue.accent {
            color: #1e8bd0;
        }

        /* BSI Font Sizes */
        .tiny.text {
            font-size: 0.867em !important;
        }

        .tiny.uppercase.text {
            text-transform: uppercase;
        }

        .small.text {
            font-size: 0.933em !important;
        }

        .default.text,
        .medium.text {
            font-size: 1em !important;
        }

        .large.text {
            font-size: 1.067em !important;
        }

        .big.text {
            font-size: 1.200em !important;
        }

        .huge.text {
            font-size: 1.333em !important;
        }

        .massive.text {
            font-size: 1.600em !important;
        }

        .muted {
            opacity: 0.75;
        }
        
        
        
        
        
        /* Stripo styles */
        ${stripoLayoutStyles}
        .es-content,
        .es-header,
        .es-footer {
            table-layout: fixed !important;
            width: 100%;
        }
        .es-desk-hidden {
            display: none;
            float: left;
            overflow: hidden;
            width: 0;
            max-height: 0;
            line-height: 0;
            mso-hide: all;
        }
        .esd-add-stripe {
            display: none !important;
        }
        
        /* block buttons fix */
        @media (min-width: 866px) {
            .esd-container-frame > .esd-block-btn {
                left: -3px !important;
                right: auto !important;
                border-radius: 0 20px 20px 0 !important;
            }
            .esd-stripe .esd-container-frame > .esd-block-btn > div {
                float: left !important;
            }
            .esd-structure > .esd-block-btn {
                right: 0;
                left: auto !important;
                width: auto;
                border-radius: 20px 0 0 20px !important;
            }
            .esd-stripe .esd-structure > .esd-block-btn > div {
                float: right !important;
            }
            .esdev-amp-form-container > .esd-block-btn > div:last-child > a,
            .esd-container-frame > .esd-block-btn > div:last-child > a {
                border-radius: 0px 20px 20px 0px !important;
                padding-left: 9px;
                padding-right: 11px;
            }
        }
        


        @media only screen and (max-width: 480px) {
            /* Prevent Webkit platforms from changing default text sizes */
            body, table, td, p, a, li, blockquote {
                -webkit-text-size-adjust: none !important;
            }
            /* Prevent iOS Mail from adding padding to the body */
            body {
                width: 100% !important;
                min-width: 100% !important;
            }
        }

        .due-date-badge {
            text-align: initial !important;
        }

        .due-date-badge p {
            display: inline-flex;
            width: auto !important;
            min-width: 90px;
            padding: 0px 10px;
            text-align: center;
        }
    </style>
</head>
<body>

    <table class="es-content" cellspacing="0" cellpadding="0" border="0" align="center">
        <!-- Begin Stripe -->
        <td class="esd-stripe" align="center">
            <table class="es-content-body" align="center" cellpadding="0" cellspacing="0" border="0" width="600">
                <tbody>
                    <!-- Begin Template Section -->
                    [[TemplateContent]]
                    <!-- End Template Section -->
                </tbody>
            </table>
        </td>
        <!-- End Stripe -->
    </table>
</body>
</html>`;
