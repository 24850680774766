import { type BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { sendExportRequest } from "features/Library/Common/utils/performanceUtils";
import {
  getAssessmentExport,
  getEmailExport,
  getEventExport,
  getFlowExport,
  getPdfExport,
  getSurveyExport,
  getVideoExport,
} from "features/Reporting/services/contentReportingService";

let abortController = new AbortController();

export const doPdfExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getPdfExport(filterParams, abortController), "PDFs", abortController);
};

export const doVideoExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest(
    (abortController) => getVideoExport(filterParams, abortController),
    "Videos",
    abortController,
  );
};

export const doEmailExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest(
    (abortController) => getEmailExport(filterParams, abortController),
    "Emails",
    abortController,
  );
};

export const doFlowExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest((abortController) => getFlowExport(filterParams, abortController), "Flows", abortController);
};

export const doAssessmentExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest(
    (abortController) => getAssessmentExport(filterParams, abortController),
    "Assessments",
    abortController,
  );
};

export const doSurveyExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest(
    (abortController) => getSurveyExport(filterParams, abortController),
    "Surveys",
    abortController,
  );
};

export const doEventExport = (filterParams: BasePerformanceRequestFilterParams) => {
  return sendExportRequest(
    (abortController) => getEventExport(filterParams, abortController),
    "Events",
    abortController,
  );
};
