import { type Dispatch, type SetStateAction, useLayoutEffect } from "react";
import { type EdgeChange, type Node, useEdgesState, useNodesState } from "reactflow";
import {
  EntityType,
  type FlowItemAssessment,
  type FlowItemEmail,
  type FlowItemEvent,
  type FlowItemFlowEnd,
  type FlowItemMessage,
  type FlowItemPdf,
  type FlowItemSurvey,
  type FlowItemVideo,
  type SavedAssessment,
  type SavedEvent,
  type SavedFlowData,
  type SavedFlowItem,
  type SavedPdf,
  type SavedSurvey,
  type SavedVideo,
  type TriggersData,
} from "../../types";
import {
  type FlowItemBase,
  type FlowItemPlaceholder,
  type FlowItemStartOfTheFlow,
  type IFlowEdge,
  type IFlowNode,
  type IReactFlowCardItem,
} from "../nodes/types";
import AssetTypes from "../../../../../../enums/assetTypes";
import CommunicationTypes from "../../../../../../enums/communicationTypes";
import EventTypes from "../../../../../../enums/eventTypes";
import { ExtrasTypes } from "../../Extras/types";
import { DEFAULT_HANDLER_ID, PLACEHOLDER_ELEMENT_ID, START_OF_THE_FLOW_ELEMENT_ID } from "../../constants";
import TriggerType from "../../../../../../enums/flowDesigner/triggerType";
import { type NodeChange } from "@reactflow/core";

export const startOfTheFlowPosition = {
  x: 60,
  y: 190,
};

export const baseTriggerParams = {
  type: "special",
  style: {
    strokeWidth: 2,
  },
};

const isConnected = (nodeId: any, triggersData: TriggersData[] | null): { target: boolean; source: boolean } => {
  let connected = {
    target: false,
    source: false,
  };

  triggersData?.forEach(({ inId, outId }) => {
    if (nodeId === outId) {
      connected.target = true;
    }

    if (nodeId === inId) {
      connected.source = true;
    }
  });

  return connected;
};

const getNodeBaseOptions = (
  flowItem: SavedFlowItem,
  flowTriggers: TriggersData[] | null,
): Node<FlowItemBase> & { data: FlowItemBase } => {
  const { id, entityType, entity, entityId, sectionHeader } = flowItem;

  return {
    id,
    type: entityType,
    data: {
      id,
      hasEntity: !!entity || entityType === ExtrasTypes.FlowEnd,
      entityId,
      title: entity?.title || "",
      publisher: entity?.publisher,
      connected: isConnected(id, flowTriggers),
      canConnect: !flowTriggers?.some((t) => t.outId === id && t.isAction),
      sectionHeader,
      questionsCount: (entity as SavedAssessment | SavedSurvey)?.questionsCount,
      branchingQuestion: (entity as SavedSurvey)?.branchingQuestion,
      requiredPacks: entity?.requiredPacks || [],
      hasAccess: entity?.hasAccess ?? true
    },
    selectable: true,
    position: {
      x: flowItem.x,
      y: flowItem.y,
    },
  };
};

const preparePdfNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemPdf> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      elementType: AssetTypes.Pdf,
      thumbnailUrl: (flowItem?.entity as SavedPdf)?.thumbnailUrl,
    },
  };
};

const prepareVideoNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemVideo> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      elementType: AssetTypes.Video,
      thumbnailUrl: (flowItem?.entity as SavedVideo)?.thumbnailUrl,
      durationInSeconds: (flowItem?.entity as SavedVideo)?.durationInSeconds,
    },
  };
};

const prepareEmailNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemEmail> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      elementType: CommunicationTypes.Email,
    },
  };
};

const prepareMessageNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemMessage> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      elementType: CommunicationTypes.Message,
      bag: {
        SendType: flowItem.bag?.SendType,
      },
    },
  };
};

const prepareAssessmentNode = (
  flowItem: SavedFlowItem,
  flowTriggers: TriggersData[] | null,
): Node<FlowItemAssessment> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      questionsCount: node?.data?.questionsCount || "",
      elementType: AssetTypes.Assessment,
    },
  };
};

const prepareSurveyNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemSurvey> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);

  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      questionsCount: node?.data?.questionsCount || "",
      elementType: AssetTypes.Survey,
    },
  };
};

const prepareEventNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemEvent> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);
  return {
    ...node,
    type: flowItem.entity ? node.type : ExtrasTypes.Deleted,
    data: {
      ...node.data,
      elementType: EventTypes.ExternalEvent,
      thumbnailUrl: (flowItem?.entity as SavedEvent)?.thumbnailUrl,
      hasReminders: (flowItem?.entity as SavedEvent)?.hasReminders,
    },
  };
};

const prepareFlowEndNode = (flowItem: SavedFlowItem, flowTriggers: TriggersData[] | null): Node<FlowItemFlowEnd> => {
  const node = getNodeBaseOptions(flowItem, flowTriggers);
  return {
    ...node,
    type: node.type,
    data: {
      ...node.data,
      elementType: ExtrasTypes.FlowEnd,
      flowEndInfo: flowItem?.flowEndInfo,
    },
  };
};

const sortByPosition = (firstEndpoint: SavedFlowItem, secondEndpoint: SavedFlowItem) => {
  if (firstEndpoint.y > secondEndpoint.y) {
    return 1;
  }
  if (firstEndpoint.y === secondEndpoint.y) {
    return firstEndpoint.x > secondEndpoint.x ? 1 : -1;
  }
  return -1;
};

const prepareNodes = (flowData: SavedFlowData): IFlowNode[] => {
  const nodes: IFlowNode[] = [];
  const flowItemsOrdered = [...flowData.flowItems].sort(sortByPosition);

  flowItemsOrdered.forEach((flowItem: SavedFlowItem) => {
    switch (flowItem.entityType) {
      case EntityType.Pdf:
        nodes.push(preparePdfNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.Video:
        nodes.push(prepareVideoNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.Email:
        nodes.push(prepareEmailNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.Message:
        nodes.push(prepareMessageNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.Assessment:
        nodes.push(prepareAssessmentNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.Survey:
        nodes.push(prepareSurveyNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.ExternalEvent:
        nodes.push(prepareEventNode(flowItem, flowData.flowTriggers) as any);
        break;
      case EntityType.FlowEnd:
        nodes.push(prepareFlowEndNode(flowItem, flowData.flowTriggers) as any);
        break;
    }
  });

  if (nodes.length) {
    nodes[0].className = "react-flow__node--first";
  }

  return nodes;
};

const getSourceHandle = (typeId: TriggerType, bulletId?: string | null): string | null | undefined => {
  const getDefaultSourceHandle: {
    [key in TriggerType]?: string | null;
  } = {
    [TriggerType.Response]: !bulletId ? DEFAULT_HANDLER_ID : bulletId,
  };

  return getDefaultSourceHandle[typeId];
};

const prepareTriggers = (flowData: SavedFlowData): IFlowEdge[] => {
  const triggers: IFlowEdge[] = [];
  const { flowTriggers } = flowData;

  flowTriggers?.forEach((trigger) => {
    const sourceHandle = getSourceHandle(trigger.typeId, trigger.bulletId);
    const id = sourceHandle
      ? `el-${trigger.outId}-${trigger.inId}-${getSourceHandle(trigger.typeId, trigger.bulletId)}`
      : `el-${trigger.outId}-${trigger.inId}`;

    triggers.push({
      ...baseTriggerParams,
      id,
      target: trigger.outId,
      source: trigger.inId,
      sourceHandle,
      data: { ...trigger },
    });
  });

  return triggers;
};

export const startOfTheFlow: Node<FlowItemStartOfTheFlow> = {
  id: START_OF_THE_FLOW_ELEMENT_ID,
  type: "StartOfTheFlow",
  position: startOfTheFlowPosition,
  draggable: false,
  selectable: false,
  hidden: false,
  data: {
    id: START_OF_THE_FLOW_ELEMENT_ID,
    title: "Start of the flow",
    connected: {
      target: false,
      source: false,
    },
    requiredPacks: [],
    hasAccess: true,
  },
};

export const placeholderNode: Node<FlowItemPlaceholder> = {
  id: PLACEHOLDER_ELEMENT_ID,
  type: "Placeholder",
  position: { x: 405, y: 100 },
  hidden: false,
  draggable: false,
  selectable: false,
  data: {
    id: PLACEHOLDER_ELEMENT_ID,
    title: "Placeholder",
    isActive: false,
    connected: {
      target: false,
      source: false,
    },
    requiredPacks: [],
    hasAccess: true,
  },
};

const initialNodes: IFlowNode[] = [startOfTheFlow, placeholderNode];
const initialEdges: IFlowEdge[] = [];

export const useElements = (
  flowData: SavedFlowData | undefined,
): [
  IFlowNode[],
  IFlowEdge[],
  Dispatch<SetStateAction<IFlowNode[]>>,
  Dispatch<SetStateAction<IFlowEdge[]>>,
  (changes: NodeChange[]) => void,
  (changes: EdgeChange[]) => void,
] => {
  const [nodes, setNodes, onNodesChange] = useNodesState<IReactFlowCardItem>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<TriggersData>(initialEdges);

  useLayoutEffect(() => {
    if (flowData) {
      setNodes((nodes) => {
        const _startOfTheFlow = nodes.find((el) => el.id === START_OF_THE_FLOW_ELEMENT_ID)!;
        const nodesToAdd = prepareNodes(flowData);
        return [_startOfTheFlow, { ...placeholderNode, hidden: nodesToAdd.length > 0 }, ...nodesToAdd];
      });
      setEdges(prepareTriggers(flowData));
    }
  }, [flowData, setEdges, setNodes]);

  return [nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange];
};
