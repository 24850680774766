import React, { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";

import SettingsForm from "../../Info/forms/SettingsForm/SettingsForm";
import Restricted from "../../../../Application/Restricted";
import { createSettingsInfoSelector } from "../../Info/forms/SettingsForm/helper";
import { fetchFlowTags } from "../../state/thunks/flowBaseThunk";
import { resetFlowBaseEntity } from "../../state/slices/flowBaseSlice";
import { flowBaseSelector, flowInformationSelector } from "../../state/selectors";
import { bindAction } from "../../../../../interfaces/redux";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { RolePermissions } from "../../../../../enums";
import { FlowBaseEntities } from "../../types/flowBase";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";

export type SettingsProps = IWizardStep & PropsFromRedux;

export const Settings = (props: SettingsProps) => {
  const { settingsInfo, isLoading, acceptHandlers, isOwn } = props;

  useEffect(() => {
    acceptHandlers?.({});

    return () => {
      props.resetFlowBase({ entity: FlowBaseEntities.Tags });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="stretch scrollable-content edit-flow-info edit-form">
      <Restricted
        permissions={[RolePermissions.FlowsCreate]}
        renderContent={(hasAnyPermission) => (
          <SettingsForm
            settingsInfo={settingsInfo}
            isDisabled={!hasAnyPermission}
            showReceiveUpdatesSection={!isOwn}
            isLoading={isLoading}
          />
        )}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flowInformation = flowInformationSelector(state);
  const settingsInfo = createSettingsInfoSelector(state);

  return {
    settingsInfo,
    isLoading: flowInformation.isLoadingInfo || Boolean(flowBaseSelector(state).loading.tags),
    isOwn: flowInformation.info.ownerId === flowInformation.info.publisherId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchFlowTags, dispatch),
  resetFlowBase: bindAction(resetFlowBaseEntity, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Settings);
export default ConnectedComponent;
