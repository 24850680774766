import { type Dispatch } from "@reduxjs/toolkit";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import flowsDataService from "../../services/flowsDataService";
import type UpdateParams from "../../../../../interfaces/updateParams";
import backgroundTask, { type UpdateFeatureProps } from "../../../../BackgroundTasks/backgroundTask";
import { FlowBaseEntities, type Integration } from "../../types/flowBase";

import {
  startFetchFlowBaseEntity,
  fetchFlowBaseEntitySuccess,
  fetchFlowBaseEntityFailure,
} from "../slices/flowBaseSlice";
import { pluralize } from "../../../../../utils/stringUtils";
import { type AppDispatch, type AppThunk } from "../../../../Application/globaltypes/redux";
import { type AddOperationParamsV1 } from "../../../../../interfaces/backgroundAddOperationParams";
import { addOperationV1 } from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { tagEntity } from "../../../../../components/addTagsModal/helpers";
import { type SourceType } from "../../Designer/types/externallTriggersTypes";

const mapIntegrationType: { [key: string]: SourceType } = {
  Google: "Google",
  MSGraph: "Microsoft",
};

const normalizeIntegrations = ({ id, type, status }: Integration) => ({
  id,
  type: mapIntegrationType?.[type] || type,
  status,
});

export const fetchFlowTags = () => async (dispatch: Dispatch) => {
  dispatch(startFetchFlowBaseEntity({ entity: FlowBaseEntities.Tags }));

  try {
    const labelsResponse = await flowsDataService.getFilterOptions([TagsEnum.Label]);
    const softwareApplicationsResponse = await flowsDataService.getFilterOptions([TagsEnum.SoftwareApplication]);

    const tags = {
      labels: labelsResponse.data || [],
      softwareApplications: softwareApplicationsResponse.data || [],
    };

    dispatch(fetchFlowBaseEntitySuccess({ entity: FlowBaseEntities.Tags, data: tags }));
  } catch (e) {
    dispatch(fetchFlowBaseEntityFailure({ entity: FlowBaseEntities.Tags, error: e }));
  }
};

export const addFlowTags =
  (ids: number[], tags: string[], entityType: TagsEnum): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!tags.length) {
      return;
    }

    const getMessageIds = (flowIds: number[]) => async () => {
      const response = await flowsDataService.addFlowTags({ ids: flowIds, tags, tagType: entityType });
      return response?.data.messageIds;
    };

    const transientMessagePrefix = pluralize(tagEntity[entityType], tags.length);

    const updateParams: UpdateParams = {
      getMessageIds: getMessageIds(ids),
      id: "AddFlowTags",
      title: `${transientMessagePrefix} adding`,
      successTransientMessage: `${transientMessagePrefix} ${tags.length === 1 ? "has" : "have"} been added!`,
      failureTransientMessage: `${transientMessagePrefix} add failed!`,
    };

    await startBackgroundTask(updateParams, dispatch);
  };

const startBackgroundTask = async (params: UpdateParams, dispatch: AppDispatch) => {
  /* istanbul ignore next */
  const props: UpdateFeatureProps = {
    addOperation: (addOperationParams: AddOperationParamsV1) => dispatch(addOperationV1(addOperationParams)),
    sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
  };

  await backgroundTask.updateEntity(params, props);
};

export const fetchIntegrations = () => async (dispatch: Dispatch) => {
  dispatch(startFetchFlowBaseEntity({ entity: FlowBaseEntities.Integrations }));

  try {
    const sourceTypesResponse = await flowsDataService.geIntegrations();
    const normalizedIntegrations = sourceTypesResponse.data.map(normalizeIntegrations);

    dispatch(
      fetchFlowBaseEntitySuccess({
        entity: FlowBaseEntities.Integrations,
        data: normalizedIntegrations,
      }),
    );
  } catch (e) {
    dispatch(fetchFlowBaseEntityFailure({ entity: FlowBaseEntities.Integrations, error: e }));
  }
};
