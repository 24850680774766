import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../../Application/slices/models";
import { type EntityTriggerTypes, type TriggerTimeUnit } from "../../types";

export interface FlowDesignerTriggersState {
  triggerTypes: EntityTriggerTypes[];
  triggerTimeUnits: TriggerTimeUnit[];
  error?: Error;
  isLoaded: boolean;
}

export const initialState: FlowDesignerTriggersState = {
  triggerTypes: [],
  triggerTimeUnits: [],
  isLoaded: false,
};

const flowDesignerTriggersSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "designerBase",
  }),
  initialState: initialState,
  reducers: {
    fetchSuccess(
      state: FlowDesignerTriggersState,
      action: PayloadAction<{ triggerTypes: EntityTriggerTypes[]; triggerTimeUnits: TriggerTimeUnit[] }>,
    ) {
      state.triggerTimeUnits = action.payload.triggerTimeUnits;
      state.triggerTypes = action.payload.triggerTypes;
      state.isLoaded = true;
    },

    fetchFailure(state: FlowDesignerTriggersState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },

    reset: () => {
      return initialState;
    },
  },
});

export const { fetchSuccess, fetchFailure, reset } = flowDesignerTriggersSlice.actions;

export default flowDesignerTriggersSlice.reducer;
