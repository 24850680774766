import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { type GetGroupEnrollmentUsersPreviewRequest } from "features/People/types";
import { getPrefix } from "../../../../Application/slices/models";
import { type EnrollmentUser } from "../types";

export const fetchGroupEnrollmentUsers = createAsyncThunk<
  { items: EnrollmentUser[]; itemsCount: number },
  GetGroupEnrollmentUsersPreviewRequest
>(
  getPrefix({
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.Groups,
    name: "enrollmentUsers",
  }),
  async (request: GetGroupEnrollmentUsersPreviewRequest) => {
    if(!request){
      return {
        items: [],
        itemsCount: 0,
      };
    }
    
    const result = await DataService.groups.getGroupEnrollmentUsersPreview(request);

    return {
      items: result.data.items,
      itemsCount: result.data.count,
    };
  },
);
