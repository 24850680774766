import { combineReducers } from "redux";
import licenseConfirmationSlice from "../slices/licenseConfirmationSlice";
import packsContextSlice from "../slices/packsContextSlice";
import licensingModalSlice from "../slices/licensingModalSlice";

/* istanbul ignore next */
const reducers = combineReducers({
  availableLicenses: licenseConfirmationSlice,
  contentPacks: packsContextSlice,
  licensingModal: licensingModalSlice,
});

export type AssignmentModalLicensing = ReturnType<typeof reducers>;

export default reducers;
