import IntegrationCard, { type IntegrationCardProps } from "../../../../components/cards/IntegrationCard/IntegrationCard";
import { RolePermissions } from "../../../../enums";

export const ConnectorCard = (cardProps: IntegrationCardProps) => {
  return (
    <IntegrationCard
      {...cardProps}
      permissions={[RolePermissions.AccountsSettingsCreate, RolePermissions.AccountsSettingsManage]}
    />
  );
};
