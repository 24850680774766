import React from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { type EmailsNoResultsProps } from "../types";

function EmailsNoResults(props: EmailsNoResultsProps) {
  const description = (
    <span>
      Looks like you don't have any emails yet.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults fontAwesomeIcon="faEnvelope" title="You don’t have any emails" description={description}>
      {props.createButton}
    </NoResults>
  );
}

export default EmailsNoResults;
