import ObjectUtils from "../../../../utils/objectUtils";
import { type Tags, type TagsPayloadCamelPlural } from "../../../../interfaces/Tags";

export const mapTagsToView = (tags: TagsPayloadCamelPlural): Tags | undefined => {
  if (ObjectUtils.isNotEmpty(tags)) {
    return {
      labels: tags.labels?.map((item) => ({ text: item, value: item })),
      softwareApplications: tags.softwareApplications?.map((item) => ({ text: item, value: item })),
    };
  }
};
