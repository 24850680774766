import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FiltersState, createFiltersSlice } from "features/Application/slices/createFiltersSlice";
import { type GenericFiltersMap } from "utils/filterUtils";
import { type TemplatesFiltersEnum } from "../types/models";

interface TemplatesFilterState extends FiltersState<TemplatesFiltersEnum> {}
const initialFilterState: TemplatesFilterState = {
  appliedFilter: {} as GenericFiltersMap<TemplatesFiltersEnum>,
  filterOptions: {} as GenericFiltersMap<TemplatesFiltersEnum>,
  isLoading: false,
};
const filterSlice = createFiltersSlice({
  initialState: initialFilterState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "OverviewFilters",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {},
});
export const { setSearch, setAppliedFilter, resetAppliedFilter, resetFilter } = filterSlice.actions;

export default filterSlice.reducer;
