import { type FilterTypesMap, FilterType } from "utils/filterUtils";
import { type AssignedUser } from "../../../../../interfaces";

export interface EmailAssignedUser extends AssignedUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  deliveredDate?: Date;
  clickedDate?: Date;
  openedDate?: Date;
  scheduledDate?: Date;
}

export interface PublishInfo {
  notNotifiedPeopleCount: number;
}

export enum EmailsFilterEnum {
  Publishers = "publisherIds",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
  Packs = "packIds",
}

export const emailFilterTypes: FilterTypesMap<EmailsFilterEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
};
