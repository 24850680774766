import { Button } from "components/buttons/button/Button";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { xor } from "lodash";
import isEmpty from "lodash/isEmpty";
import React, { type FC, useCallback, useEffect, useRef, useState } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table } from "semantic-ui-react";
import { withRouter } from "../../../../../adapters/withRouter/withRouter";
import { ContentItemMenu } from "../../../../../components";
import { DeleteLinkButton, DuplicateLinkButton, EditLinkButton } from "../../../../../components/buttons/linkButtons";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import DeleteContentConfirmationModal, {
  type RemoveContentObserverInput,
} from "../../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import { EmailFilterForm } from "../../../../../components/filterForms/EmailFilterForm/EmailFilterForm";
import { Title } from "../../../../../components/listViewTemplates";
import NoSearchResults from "../../../../../components/noSearchResults";
import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import SearchInput from "../../../../../components/searchInput/SearchInput";
import { type FiltersMap } from "../../../../../utils/filterUtils";
import { getFilterOptions } from "../state/thunks/emailFiltersThunk";
import { emailSearchSelector, setTerm } from "../state/slices/emailSearchSlice";
import { deleteEmails, duplicateEmails } from "../state/thunks/emailOverviewThunk";
import { bindAction } from "../../../../../interfaces";
import Observable from "../../../../../utils/Observable";
import { DeletionRestrictedTooltip } from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import {
  ContentTypesEnum,
  PublishedStatusTypes,
  RouteNames,
  SortingDirection,
  Strings,
  ViewType,
} from "../../../../../enums";
import RolePermissions from "../../../../../enums/rolePermissions";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import arrayUtils from "../../../../../utils/arrayUtils";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { type Filters } from "../../../../../utils/queryUtils";
import { ItemsView } from "../../../../../views";
import EmailsNoResults from "../../../../../views/library/emails/Overview/EmailsNoResults";
import Restricted from "../../../../Application/Restricted";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import {
  EmailDeleteSuccess,
  EmailDuplicationSuccess,
  EmailPublishSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { EmailListUtils } from "../services/listUtils";
import * as emailEntityStateActions from "../state/actions/emailEntityStateActions";
import * as emailsOverviewActions from "../state/actions/emailsOverviewActions";
import { normalizedEmailsSelector } from "../state/selector/emailCommonSelector";
import {
  resetPagination,
  resetSortingColumnName,
  resetSortingDirection,
  setPagination,
  setSortingColumnName,
  setSortingDirection,
  resetAppliedFilter,
  setAppliedFilter,
} from "../state/slices/emailFilterSlice";
import { type EmailOverview } from "../types/state";
import EmailsOverviewHeader from "./EmailsOverviewHeader";
import columnOptions, { Columns } from "./columnOptions";
import { type EmailsOverviewProps } from "./types";

import {
  emailPaginationSelector,
  emailSortingColumnNameSelector,
  emailSortingDirectionSelector,
} from "../state/reducers/emailsReducer";
import "./emailsOverview.scss";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type LDProps } from "../../../../LDProps";

const ENTITY_TYPE = "email";

export type EmailsOverviewPropsAll = EmailsOverviewProps & PropsFromRedux & LDProps;

type ReloadListItemsTrigger = (enableSorting: boolean) => void;

type SelectionState = {
  purchasedEmailIds: Set<number>;
  undeletableEmailIds: Set<number>;
  unduplicatableEmailIds: Set<number>;
  publishedEmailIds: Set<number>;
};

export const EmailsOverview: FC<EmailsOverviewPropsAll> = (props: EmailsOverviewPropsAll) => {
  const {
    setSearch,
    emailsOverviewActions,
    navigate,
    normalizedEmails,
    search,
    appliedFilter,
    deleteEmails,
    duplicateEmails,
    emailEntityStateActions,
    customHeaderContent,
    emails,
    isLoading,
    totalCount,
    filtersLoading,
    filterOptions,
    getFilterOptions,
    applyFilterDispatch,
    resetFilterDispatch,
    pagination,
    setPagination,
    sortingColumnName,
    setSortingColumnName,
    sortingDirection,
    setSortingDirection,
    resetSearchFilters,
  } = props;

  const [selectedEmailIds, setSelectedEmailIds] = useState<number[]>([]);
  const [sortBy, setSortBy] = useState(Columns.Added);
  const [page, setPage] = useState(1);
  const [deleteEmailDefaultMessage, setDeleteEmailDefaultMessage] = useState("");
  const [selectedEmailTypes, setSelectedEmailTypes] = useState<SelectionState>({
    purchasedEmailIds: new Set(),
    undeletableEmailIds: new Set(),
    unduplicatableEmailIds: new Set(),
    publishedEmailIds: new Set(),
  });

  const reloadListItemsRef = useRef<((enableSorting: boolean) => void) | undefined>(undefined);
  const deleteFlag = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);

  const onTriggerEmailRemovalObserver = new Observable<
    (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
  >();

  const goToEmailEdit = useCallback(
    (payload: { id: number }) => navigate(`emails/${payload.id}/configuration`),
    [navigate],
  );

  useEffect(() => {
    RtnEventsEmitter.subscribe(rtnEvents.EmailEditModeSuccess, goToEmailEdit);

    return () => {
      RtnEventsEmitter.unsubscribe(rtnEvents.EmailEditModeSuccess, goToEmailEdit);
    };
  }, [goToEmailEdit]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes(`/${RouteNames.contentCommunications}`)) resetSearchFilters();
    };
  }, [resetSearchFilters]);

  const buildTableBody = (email: EmailOverview, hasPermission: boolean) => {
    const isSelected = selectedEmailIds.some((id) => id === email.id);
    const { flowsCount = 0, packsCount = 0 } = email.dependencies || {};
    const dependencies = { flows: flowsCount, packs: packsCount };

    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <Title
            url={`/${RouteNames.contentCommunications}/emails/${email.id}`}
            title={email.title}
            clamp={2}
            className="normal-color"
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Title title={email.subject} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          {email.dateSent ? dateTimeUtils.formatDate(email.dateSent) : null}
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(email.dateCreated)}</Table.Cell>
        <Table.Cell width={columnOptions[4].width}>{dateTimeUtils.formatDate(email.dateModified)}</Table.Cell>
        <Table.Cell width={columnOptions[5].width}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!email.isDraft)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[6].width} className="controls-column align-right">
          <RestrictedByTooltip hasPermission={hasPermission}>
            <ContentItemMenu
              circle
              outlinedEllipsis
              isChecked={isSelected}
              readonly={!hasPermission || (email.isPurchased && !email.canBeDuplicated)}
              item={{ canBeDeleted: email.canBeDeleted, isPurchased: email.isPurchased }}
              purchasedTooltipContent={
                email.isPurchased && email.canBeDuplicated
                  ? Strings.tooltipMessages.purchased.deleteDisabled("emails")
                  : Strings.tooltipMessages.purchased.operationsDisabled("emails")
              }
              entityType="email"
              dependencies={dependencies}
            >
              {!email.isPurchased && (
                <EditLinkButton
                  isDisabled={email.isPurchased}
                  key="email-overview-edit-link-button"
                  onClick={() => handleEditClick(email.id, email.isDraft)}
                />
              )}
              <DuplicateLinkButton
                isDisabled={!email.canBeDuplicated}
                key="email-overview-duplicate-link-button"
                onClick={() => handleDuplicateClick([email.id])}
              />
              {!email.isPurchased && (
                <DeleteLinkButton
                  key="email-overview-delete-link-button"
                  isDisabled={!deleteFlag && !email.canBeDeleted}
                  after={
                    !email.isPurchased &&
                    !deleteFlag &&
                    !email.dependencies?.isEmpty && (
                      <DeletionRestrictedTooltip dependencies={dependencies} entityType={ENTITY_TYPE} />
                    )
                  }
                  onClick={() => handleDeleteClick([email.id], dependencies.flows, dependencies.packs)}
                />
              )}
            </ContentItemMenu>
          </RestrictedByTooltip>
        </Table.Cell>
      </>
    );
  };

  const onSelectedItemsChanged = (newlySelectedIds: Array<number>) => {
    const purchasedEmailIds = new Set<number>(selectedEmailTypes.purchasedEmailIds);
    const unduplicatableIds = new Set<number>(selectedEmailTypes.unduplicatableEmailIds);
    const undeletableIds = new Set<number>(selectedEmailTypes.unduplicatableEmailIds);
    const publishedIds = new Set<number>(selectedEmailTypes.publishedEmailIds);

    // determine that the purchased item has been selected on at least one of the pages
    xor(newlySelectedIds, selectedEmailIds).forEach((id) => {
      arrayUtils.pushOrRemoveIfExist(purchasedEmailIds, id, (itemId) => !!normalizedEmails[itemId]?.isPurchased);
      arrayUtils.pushOrRemoveIfExist(unduplicatableIds, id, (itemId) => !normalizedEmails[itemId]?.canBeDuplicated);
      arrayUtils.pushOrRemoveIfExist(undeletableIds, id, (itemId) => !normalizedEmails[itemId]?.canBeDeleted);
      arrayUtils.pushOrRemoveIfExist(publishedIds, id, (itemId) => !normalizedEmails[itemId]?.isDraft);
    });

    setSelectedEmailIds(newlySelectedIds);
    setSelectedEmailTypes({
      purchasedEmailIds: purchasedEmailIds,
      undeletableEmailIds: undeletableIds,
      unduplicatableEmailIds: unduplicatableIds,
      publishedEmailIds: publishedIds,
    });
  };

  const getEmails = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added,
    sortingDirection: string = SortingDirection.Descending,
  ) => {
    setSortBy(sortingColumnName as Columns);
    setPage(Math.floor(skip / top) + 1);

    emailsOverviewActions.getEmails(
      top,
      skip,
      EmailListUtils.formatOrderColumn(sortingColumnName as Columns),
      sortingDirection as SortingDirection,
      search,
      appliedFilter,
    );
  };

  const handleEditClick = (id: number, isDraft?: boolean) => {
    if (!isDraft) emailEntityStateActions.fetchDraftEmailEntity(id);
    goToEmailEdit({ id });
  };

  const getDeleteEmailMessage = (ids: number[]): string => {
    return ids.length > 1
      ? `Delete ${ids.length} emails? If emails sends were scheduled or in progress then deleting these emails will stop all progress, and remove all data.`
      : `Delete ${ids.length} email? If email send was scheduled or in progress then deleting this email will stop all progress, and remove all data.`;
  };

  const handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => {
    setDeleteEmailDefaultMessage(getDeleteEmailMessage(ids));

    onTriggerEmailRemovalObserver.notify(
      () => {
        deleteEmails(ids, getEmails);
        onSelectedItemsChanged(selectedEmailIds.filter((id) => !ids?.includes(id)));
      },
      {
        selectedItemsCount: ids.length,
        flowsCount,
        packsCount,
      },
    );
  };

  const handleDuplicateClick = async (ids: number[]) => {
    onSelectedItemsChanged([]);
    duplicateEmails(ids, getEmails);
  };

  const goToCreateEmail = () => navigate("emails/create");

  const renderCreateEmailBtn = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary className="create-email create-button" onClick={action} disabled={!hasAnyPermission}>
        Create Email
      </Button>
    </RestrictedByTooltip>
  );

  const renderRestrictedCreateEventButton = (): React.ReactElement => (
    <RestrictedByAddOn
      permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
      addOnPermissions={[RolePermissions.CommunicationsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={goToCreateEmail}
      renderContent={renderCreateEmailBtn}
    />
  );

  // This component will be cloned and receives needed props inside FilterPanel component.
  // @ts-ignore
  const renderFilterForm = () => <EmailFilterForm />;

  const onSearchChange = (search: string) => {
    onSelectedItemsChanged([]);
    setSearch(search);
    if (!sortBy) {
      setSortBy(Columns.Added);
    }

    reloadListItemsRef.current?.(isEmpty(search));
  };

  const applyFilter = (filter: Filters) => {
    onSelectedItemsChanged([]);
    applyFilterDispatch(filter);
  };

  const resetFilter = () => {
    onSelectedItemsChanged([]);
    resetFilterDispatch();
  };

  const createReloadListMethod = (reloadListItems: ReloadListItemsTrigger) => {
    reloadListItemsRef.current = reloadListItems;
  };

  const onPaginationChange = (page: number) => setPagination(page);
  const onSortingColumnNameChange = (name: string) => setSortingColumnName(name);
  const onSortingDirectionChange = (direction: SortingDirection) => setSortingDirection(direction);

  return (
    <section className="nested-content emails">
      <EmailsOverviewHeader
        purchasedEmailSelected={selectedEmailTypes.purchasedEmailIds.size > 0}
        publishedEmailsSelected={selectedEmailTypes.publishedEmailIds.size > 0}
        draftEmailsSelected={selectedEmailTypes.publishedEmailIds.size !== selectedEmailIds.length}
        undeletableSelected={selectedEmailTypes.undeletableEmailIds.size > 0}
        unduplicatableSelected={selectedEmailTypes.unduplicatableEmailIds.size > 0}
        selectedIds={selectedEmailIds}
        setSelectedIds={setSelectedEmailIds}
        deletionHandler={() => handleDeleteClick(selectedEmailIds)}
        duplicationHandler={() => handleDuplicateClick(selectedEmailIds)}
        onAddedToPacks={() => onSelectedItemsChanged([])}
        renderCreateButton={renderRestrictedCreateEventButton}
      />
      <Restricted
        permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
        renderContent={(hasPermission) => (
          <ItemsView
            customHeaderContent={customHeaderContent}
            items={emails}
            viewType={ViewType.LIST}
            getData={getEmails}
            noResultsContent={
              !!search || !isEmpty(appliedFilter) ? (
                <NoSearchResults />
              ) : (
                <EmailsNoResults createButton={renderRestrictedCreateEventButton()} />
              )
            }
            isFirstLoad={emails.length === 0}
            isLoading={isLoading}
            isAllDataLoaded={false}
            selectedIds={selectedEmailIds}
            className="emails-items-view alignment-padding"
            renderFilterForm={renderFilterForm}
            appliedFilter={appliedFilter}
            getFilterOptions={getFilterOptions}
            filterOptions={filterOptions}
            filterOptionsLoading={filtersLoading}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            buildTableBody={(emailOverview: EmailOverview) => buildTableBody(emailOverview, hasPermission)}
            columnOptions={columnOptions}
            itemsInlineCount={totalCount}
            onSelectedListItemsChanged={onSelectedItemsChanged}
            renderSearch={() => (
              <SearchInput placeholder="Search for Emails..." onChange={onSearchChange} defaultValue={search} />
            )}
            isSelectDisabled={() => !hasPermission}
            sortingDirection={sortingDirection}
            sortingColumnName={sortingColumnName}
            permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
            activePage={page}
            setReloadListItems={createReloadListMethod}
            hideListGridViewSwitcherButton
            listViewRtnEvents={[EmailDeleteSuccess, EmailPublishSuccess, EmailDuplicationSuccess]}
            setPagination={onPaginationChange}
            pagination={pagination}
            setSortingColumnName={onSortingColumnNameChange}
            setSortingDirection={onSortingDirectionChange}
          />
        )}
      />
      <DeleteContentConfirmationModal
        content={deleteEmailDefaultMessage}
        contentType={ContentTypesEnum.Emails}
        onTriggerRemoveContentObserver={onTriggerEmailRemovalObserver}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.emails.emailsOverviewReducer;
  const entityState = state.library.emails.emailEntityStateReducer;

  return {
    emails: overview.emails,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: overview.totalCount,
    search: emailSearchSelector(state),
    filterOptions: state.library.emails.filters.filterOptions,
    appliedFilter: state.library.emails.filters.appliedFilter,
    filtersLoading: state.library.emails.filters.isLoading,
    normalizedEmails: normalizedEmailsSelector(state),
    pagination: emailPaginationSelector(state),
    sortingColumnName: emailSortingColumnNameSelector(state),
    sortingDirection: emailSortingDirectionSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  emailsOverviewActions: bindActionCreators(emailsOverviewActions, dispatch),
  emailEntityStateActions: bindActionCreators(emailEntityStateActions, dispatch),
  applyFilterDispatch: (filters: FiltersMap) => dispatch(setAppliedFilter(filters)),
  resetFilterDispatch: () => dispatch(resetAppliedFilter()),
  getFilterOptions: () => dispatch(getFilterOptions()),
  setSearch: (searchTerm: string) => dispatch(setTerm(searchTerm)),
  deleteEmails: bindAction(deleteEmails, dispatch),
  duplicateEmails: bindAction(duplicateEmails, dispatch),
  setPagination: bindAction(setPagination, dispatch),
  setSortingColumnName: bindAction(setSortingColumnName, dispatch),
  setSortingDirection: bindAction(setSortingDirection, dispatch),
  resetSearchFilters: () => {
    emailsOverviewActions.resetEmails();
    dispatch(setTerm(""));
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
    dispatch(resetAppliedFilter());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(withLDConsumer()(EmailsOverview)));
export default ConnectedComponent;
