import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import FilterButtons from "./filterButtons/FilterButtons";
import PaginationButtons from "./paginationButtons/PaginationButtons";
import ViewTypeButtons from "./viewTypeButtons/ViewTypeButtons";
import ViewType from "../../enums/ViewType";
import { pluralize } from "../../utils/stringUtils";
import { DropdownWrapper } from "../dropdowns";

import "./itemsViewHeader.scss";

class ItemsViewHeader extends Component {
  renderNumberOfResultsLabel() {
    const { itemsInlineCount, itemsPageRange, viewType } = this.props;

    if (itemsInlineCount && viewType === ViewType.LIST && itemsPageRange) {
      const pr = itemsPageRange;
      const count = itemsInlineCount.toLocaleString();
      return (
        <div className="number-of-results list">
          {pr.from.toLocaleString()}-{pr.to.toLocaleString()} / {count}
          {pluralize(" result", itemsInlineCount)}
        </div>
      );
    }
    return null;
  }

  getDefaultOption(options) {
    if (options && options.length > 0) {
      return (options.find((option) => option.default) || options[0]).value;
    } else {
      return "";
    }
  }

  resetAppliedFilter = () => {
    this.props.applyFilter({});
  };

  renderSortingDropdown() {
    const { sortOptions, blur, onSortChange, viewType, accessRestricted, orderBy } = this.props;

    if (viewType === ViewType.GRID) {
      return (
        <>
          <span className="sort-by-label">Sort by</span>
          <div className="sorting-dropdown-container">
            <DropdownWrapper
              blur={blur}
              data-testid="sorting-dropdown"
              fluid
              selection
              value={orderBy}
              defaultValue={orderBy ? undefined : this.getDefaultOption(sortOptions)}
              className="sorting-dropdown"
              items={sortOptions || []}
              handleOptionChange={onSortChange}
              disabled={accessRestricted || !sortOptions || sortOptions.length < 1}
            />
          </div>
        </>
      );
    }
    return null;
  }

  onSwitchToListView = () => {
    const { onViewTypeChange } = this.props;
    onViewTypeChange && onViewTypeChange(ViewType.LIST);
  };

  onSwitchToGridView = () => {
    const { onViewTypeChange } = this.props;
    onViewTypeChange && onViewTypeChange(ViewType.GRID);
  };

  render() {
    const {
      className,
      onFilterPress,
      appliedFilter,
      isFiltersButtonDisplayed,
      onPrevPage,
      onNextPage,
      itemsPageRange,
      itemsInlineCount,
      viewType,
      activePage,
      children,
      disableListViewButton,
      search,
      accessRestricted,
      hideListGridViewSwitcherButton,
      disabled,
    } = this.props;

    const headerClass = cn("items-view-header", className, { disabled: disabled });

    return (
      <div className={headerClass}>
        <div className="custom-content">{children}</div>
        <div className="search-control">
          {search}
          {isFiltersButtonDisplayed && (
            <FilterButtons
              disabled={accessRestricted}
              appliedFilter={appliedFilter}
              onFilterPress={onFilterPress}
              onResetFilter={this.resetAppliedFilter}
            />
          )}
        </div>
        <div className="right-aligned-container">
          {this.renderNumberOfResultsLabel()}
          {this.renderSortingDropdown()}
          {viewType === ViewType.LIST && itemsPageRange && (
            <PaginationButtons
              onPrevPage={onPrevPage}
              onNextPage={onNextPage}
              itemsInlineCount={itemsInlineCount}
              itemsPageRange={itemsPageRange}
              activePage={activePage}
            />
          )}

          {!hideListGridViewSwitcherButton && (
            <ViewTypeButtons
              disableListViewButton={disableListViewButton || accessRestricted}
              viewType={viewType}
              onListView={this.onSwitchToListView}
              onGridView={this.onSwitchToGridView}
            />
          )}
        </div>
      </div>
    );
  }
}

ItemsViewHeader.propTypes = {
  viewType: PropTypes.string.isRequired,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  className: PropTypes.string,
  orderBy: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onFilterPress: PropTypes.func,
  itemsInlineCount: PropTypes.number,
  itemsPageRange: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  appliedFilter: PropTypes.object,
  applyFilter: PropTypes.func,
  isFiltersButtonDisplayed: PropTypes.bool,
  isLoading: PropTypes.bool,
  activePage: PropTypes.number,
  onViewTypeChange: PropTypes.func,
  children: PropTypes.node,
  search: PropTypes.node,
  disableListViewButton: PropTypes.bool,
  accessRestricted: PropTypes.bool,
  hideListGridViewSwitcherButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ItemsViewHeader;
