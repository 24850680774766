import * as actionTypes from "./createRoleActionsTypes";
import permissionsHelper from "../permissionsHelper";

const initialState = {
  permissions: [],
  availableAccounts: [],
  availableGroups: [],
  error: null,
  isLoading: false,
  isLoadingAvailableAccounts: false,
  isLoadingAvailableGroups: false,
};

const createRoleReducer = (currentState, action) => {
  const state = currentState || initialState;

  switch (action.type) {
    case actionTypes.FETCH_PERMISSIONS_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.FETCH_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.FETCH_PERMISSIONS_SUCCESS:
      const updatedPermissions = permissionsHelper.addAllAreaAndAllFeatures(action.payload.permissions);

      return {
        ...state,
        permissions: updatedPermissions,
        isLoading: false,
        error: null,
      };

    case actionTypes.FETCH_AVAILABLE_ACCOUNTS_BEGIN:
      return {
        ...state,
        isLoadingAvailableAccounts: true,
        error: null,
      };

    case actionTypes.FETCH_AVAILABLE_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingAvailableAccounts: false,
        error: action.payload.error,
      };

    case actionTypes.FETCH_AVAILABLE_ACCOUNTS_SUCCESS:
      const availableAccounts = action.payload.availableAccounts;

      return {
        ...state,
        availableAccounts,
        isLoadingAvailableAccounts: false,
        error: null,
      };

    case actionTypes.FETCH_AVAILABLE_GROUPS_BEGIN:
      return {
        ...state,
        isLoadingAvailableGroups: true,
        error: null,
      };

    case actionTypes.FETCH_AVAILABLE_GROUPS_FAILURE:
      return {
        ...state,
        isLoadingAvailableGroups: false,
        error: action.payload.error,
      };

    case actionTypes.FETCH_AVAILABLE_GROUPS_SUCCESS:
      const availableGroups = action.payload.availableGroups;

      return {
        ...state,
        availableGroups,
        isLoadingAvailableGroups: false,
        error: null,
      };

    default:
      return state;
  }
};

export default createRoleReducer;
