import React from "react";
import cn from "classnames";
import { Icon, type IconProps } from "semantic-ui-react";
import "./iconButtons.scss";

export type BaseIconButtonProps = {
  className?: string;
  isOutlineIcon?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
} & IconProps;

function BaseIconButton({
  size = "large",
  isOutlineIcon = false,
  className,
  isDisabled,
  onClick,
  iconClassName,
  ...props
}: BaseIconButtonProps) {
  const newClassName = cn("icon-button", iconClassName, className);

  return (
    <Icon
      size={size}
      className={isOutlineIcon ? cn("fal outline icon-button", newClassName) : newClassName}
      onClick={isDisabled ? null : onClick}
      disabled={isDisabled || !onClick}
      link={!!onClick}
      role="button"
      {...props}
    />
  );
}

export default BaseIconButton;
