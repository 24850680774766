import React from "react";
import AccessRestrictedMessage from "../restrictedRoute/AccessRestrictedMessage";

export interface RestrictedResourceProps {
  children: React.ReactElement;
  isAuthorized: boolean;
}

function RestrictedResource({ children, isAuthorized }: RestrictedResourceProps) {
  return isAuthorized ? children : <AccessRestrictedMessage />;
}

export default RestrictedResource;
