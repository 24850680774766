import packDataService from "../../services/packsDataService";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import { req, err, got } from "../slices/packTypesSlice";

export const fetchPackTypes = () => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const res = await packDataService.getPackTypesAsync();
    dispatch(got({ items: res.data, totalCount: res.data.length }));
  } catch (e) {
    let errorMessage = (e as Error).message;
    dispatch(err(errorMessage));
  }
};
