import { createReducer } from "../../../../../utils/reduxUtils";

import {
  type AssessmentContentView,
  type AssessmentDetailsState,
  AssessmentQuestionType,
  type AssessmentTags,
  type AssessmentView,
  type Question,
} from "../../types/state";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { type IQuestion } from "../../../Common/models";
import { getQuestionDefaultData } from "../../Details/AssessmentContentForm/Providers/defaultQuestionProvider";
import { AssessmentDetailsActionTypes } from "../actionTypes/assessmentDetailsActionTypes";

const initialState: AssessmentDetailsState = {
  assessment: {
    id: -1,
    isDraft: true,
    isPurchased: false,
    hasBeenPublished: false,
    title: "",
    description: "",
    dateModified: undefined,
    labels: [],
    softwareApplications: [],
    showCorrectAnswers: false,
    percentageRequired: false,
    percentageValue: 0,
    attemptsAllowed: false,
    attemptsCount: 0,
    periodValue: 0,
    periodType: 1,
    flows: [],
    packs: [],
  },
  content: {
    questions: [getQuestionDefaultData(AssessmentQuestionType.MultipleChoiceTextQuestion)],
  },
  isAssessmentLoaded: false,
  isContentLoaded: false,
  isAssessmentLoading: false,
  isContentLoading: false,
};

const assessmentDetailsHandlers = () => {
  const saveAssessmentHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<AssessmentView>,
  ): AssessmentDetailsState => ({
    ...state,
    assessment: {
      ...state.assessment,
      ...action.payload,
    },
  });

  const saveAssessmentTagsHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<AssessmentTags>,
  ): AssessmentDetailsState => ({
    ...state,
    assessment: {
      ...state.assessment,
      softwareApplications: action.payload.softwareApplications,
      labels: action.payload.labels,
    },
  });

  const saveContentHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<AssessmentContentView>,
  ): AssessmentDetailsState => ({
    ...state,
    content: action.payload,
  });

  const clearAssessmentHandler = (): AssessmentDetailsState => ({
    ...initialState,
  });

  const getAssessmentFailureHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<Error>,
  ): AssessmentDetailsState => ({
    ...state,
    isAssessmentLoading: false,
    error: action.payload,
  });

  const getContentFailureHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<Error>,
  ): AssessmentDetailsState => ({
    ...state,
    isContentLoading: false,
    error: action.payload,
  });

  const getAssessmentBeginHandler = (state: AssessmentDetailsState): AssessmentDetailsState => ({
    ...state,
    isAssessmentLoading: true,
  });

  const getAssessmentSuccessHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<AssessmentView>,
  ): AssessmentDetailsState => {
    return {
      ...state,
      assessment: {
        ...state.assessment,
        ...action.payload,
      },
      isAssessmentLoaded: true,
      isAssessmentLoading: false,
    };
  };

  const getAssessmentContentBeginHandler = (state: AssessmentDetailsState): AssessmentDetailsState => ({
    ...state,
    isContentLoading: true,
  });

  const getAssessmentContentSuccessHandler = (
    state: AssessmentDetailsState,
    action: PayloadAction<AssessmentContentView>,
  ): AssessmentDetailsState => {
    const questions: IQuestion<Question>[] = action.payload.questions;

    return {
      ...state,
      content: { questions: questions.length ? questions : initialState.content.questions },
      isContentLoaded: true,
      isContentLoading: false,
    };
  };

  return {
    [AssessmentDetailsActionTypes.SaveAssessment]: saveAssessmentHandler,
    [AssessmentDetailsActionTypes.SaveAssessmentTags]: saveAssessmentTagsHandler,
    [AssessmentDetailsActionTypes.SaveContent]: saveContentHandler,

    [AssessmentDetailsActionTypes.ClearAssessment]: clearAssessmentHandler,

    [AssessmentDetailsActionTypes.GetAssessmentBegin]: getAssessmentBeginHandler,
    [AssessmentDetailsActionTypes.GetAssessmentSuccess]: getAssessmentSuccessHandler,
    [AssessmentDetailsActionTypes.GetAssessmentFailure]: getAssessmentFailureHandler,

    [AssessmentDetailsActionTypes.GetContentBegin]: getAssessmentContentBeginHandler,
    [AssessmentDetailsActionTypes.GetContentSuccess]: getAssessmentContentSuccessHandler,
    [AssessmentDetailsActionTypes.GetContentFailure]: getContentFailureHandler,
  };
};

export const assessmentDetailsReducer = createReducer(initialState, [assessmentDetailsHandlers]);
