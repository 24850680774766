import { type FC } from "react";

import { OverviewHeader } from "../../../../../components/sectionHeader";
import { DeletePdfButton } from "./DeletePdfButton";
import { CreatePdfButton } from "./CreatePdfButton";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";

import Restricted from "../../../../Application/Restricted";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import TagsManageContainer from "../containers/TagsManageContainer";
import styles from "./pdfOverviewHeader.module.scss";
import useToggle from "../../../../../hooks/useToggle";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

export interface PdfOverviewHeaderProps {
  selectedIds: number[];
  pdfDeletionHandler: (selectedIds: number[], flowsCount?: number, packsCount?: number) => void;
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  hiddenSelected: boolean;
  visibleSelected: boolean;
  clearSelection: () => void;
  onToggleVisibility: (selectedIds: number[], hidden: boolean) => () => void;
}

const addToPacksPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

export const PdfOverviewHeader: FC<PdfOverviewHeaderProps> = (props) => {
  const { purchasedSelected, undeletableSelected, draftSelected, pdfDeletionHandler, selectedIds, clearSelection,  onToggleVisibility, hiddenSelected, visibleSelected } =
    props;

  const [packsModalShown, togglePackModal] = useToggle();
  
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);

  const renderBatchButtons = () => {
    return (
      <>
        {visibility && !(visibleSelected && hiddenSelected) && (
          <Restricted permissions={[RolePermissions.AssetsCreate]}>
            <ToggleVisibilityButton onClick={onToggleVisibility(selectedIds, hiddenSelected)} hidden={hiddenSelected}/>
          </Restricted>)}
        <Restricted permissions={addToPacksPermissions}>
          <AddToPacksButton onClick={togglePackModal} isDisabled={purchasedSelected || draftSelected} />
        </Restricted>
        <DeletePdfButton
          purchasedSelected={purchasedSelected}
          undeletableSelected={undeletableSelected}
          onClick={() => pdfDeletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="PDFs"
          isDisabled={purchasedSelected}
          selectedIds={selectedIds}
          onComplete={clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={togglePackModal}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Pdf"
        />
      </>
    );
  };

  return (
    <OverviewHeader title="PDFs" itemName="PDF" titleForGA="Assets - PDFs" selectedItemsAmount={selectedIds.length}>
      <div className={styles.root}>{selectedIds.length ? renderBatchButtons() : <CreatePdfButton />}</div>
    </OverviewHeader>
  );
};
