import React, { memo, useEffect, useRef } from "react";
import { type EdgeProps, Position } from "reactflow";
import { select } from "d3";
import { calculateHalfWayToTarget } from "./utils";
import arrowImageGrey from "../../../../../../images/endpoints/endpoint_grey_arrow_down@3x.png";
import arrowImageBlue from "../../../../../../images/endpoints/endpoint_blue_arrow_down@3x.png";
import { startsColor } from "features/Library/Common/utils/performanceUtils";

const SpecialEdge: React.FC<EdgeProps> = ({ sourceX, sourceY, targetX, targetY, selected, sourcePosition, id }) => {
  let d;
  const { x: halfToTargetX, y: halfToTargetY } = calculateHalfWayToTarget({
    fromX: sourceX,
    fromY: sourceY,
    toX: targetX,
    toY: targetY,
  });
  const lineRef = useRef(null);

  if (sourcePosition === Position.Bottom && sourceY >= targetY) {
    d = `M ${sourceX} ${sourceY} L ${sourceX} ${sourceY + 20}  L ${halfToTargetX} ${sourceY + 20} L ${halfToTargetX} ${targetY - 20
      } L ${targetX} ${targetY - 20} L ${targetX} ${targetY}`;
  } else if (sourcePosition === Position.Right && (sourceY <= targetY || sourceY >= targetY)) {
    d = `M ${sourceX} ${sourceY} L ${sourceX} ${sourceY}  L ${halfToTargetX - 80} ${sourceY} L ${halfToTargetX - 80} ${targetY - 20
      } L ${targetX} ${targetY - 20} L ${targetX} ${targetY}`;
  } else {
    d = `M ${sourceX} ${sourceY} L ${sourceX} ${halfToTargetY} L ${halfToTargetX} ${halfToTargetY} L ${targetX} ${halfToTargetY} L ${targetX} ${targetY}`;
  }

  useEffect(() => {
    if (selected && lineRef && lineRef.current) {
      // @ts-ignore
      const parent = lineRef.current.parentElement;
      select(parent).raise();
    }
  }, [selected, lineRef]);

  return (
    <g ref={lineRef}>
      <defs>
        <pattern id={`image-${id}`} x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
          <image x="0%" y="0%" width="512" height="512" xlinkHref={arrowImageGrey as any} />
        </pattern>
        <pattern id={`imageSelected-${id}`} x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
          <image x="0%" y="0%" width="512" height="512" xlinkHref={arrowImageBlue as any} />
        </pattern>
      </defs>
      <path fill="none" d={d} stroke="none" strokeWidth={15} style={{ cursor: "pointer" }} />
      <path fill="none" stroke={selected ? startsColor : "#999888"} strokeWidth={2} d={d} style={{ cursor: "pointer" }} />
      <circle cx={targetX} cy={targetY + 4} fill={`url(#${selected ? "imageSelected" : "image"}-${id}`} r={10} />
    </g>
  );
};

export default memo(SpecialEdge);
