import midnightService from "../../../../../Application/services/midnightService/midnightService";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/emailEntityStateActionTypes";
import * as actionTypesDetails from "../actionTypes/emailDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { type Email, type LoadTemplateResult, type Template } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../interfaces/redux";
import { type AddPeopleToEmailRequest, type DeletePeopleFromEmailRequest, type SendTestEmailRequest } from "../../types/requests";
import { setEmailAction } from "./emailDetailsActions";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import {
  EmailEditSuccess,
  EmailContentEditSuccess,
  EmailPublishSuccess,
  EmailEditModeSuccess,
  EmailRevertSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { type UpdateParams } from "interfaces";
import backgroundTask from "../../../../../BackgroundTasks/backgroundTask";
import { type AppDispatch } from "features/Application/globaltypes/redux";

export const createEmail = (data: Email) => {
  const begin = getActionBaseProvider(actionTypes.createEmailBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createEmailSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createEmailFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.Emails, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateEmail = (metadata: Email) => {
  const begin = getActionBaseProvider(actionTypes.updateEmailBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateEmailFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: EmailEditSuccess }));
    try {
      dispatch(setEmailAction(metadata));
      await dataService.emails.updateEmailAsync(metadata);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const duplicateEmail = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.duplicateEmailBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.duplicateEmailFailure);
  const success = getActionBaseProvider(actionTypes.duplicateEmailSuccess);

  return async (dispatch: AppDispatch) => {
    dispatch(begin());

    try {
      const result = await dataService.emails.duplicateEmails([id]);
      dispatch(success());

      const getMessageIds = (id: number) => async () => {
        return result?.data.messageIds;
      };

      let successMessage = "Email has been duplicated!";
      let failureMessage = "Email duplicate failed!";

      const updateParams: UpdateParams = {
        id: "DuplicateEmail",
        title: `Duplicate email`,
        getMessageIds: getMessageIds(id),
        successTransientMessage: successMessage,
        failureTransientMessage: failureMessage,
      };

      await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateEmailTemplate = (data: Template) => {
  const begin = getActionBaseProvider(actionTypes.updateEmailBegin);
  const success = getActionProvider<LoadTemplateResult>(actionTypesDetails.getEmailTemplateSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateEmailFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: EmailContentEditSuccess }));
    try {
      await dataService.emails.updateEmailTemplateAsync(data);
      dispatch(success({ template: data, getTemplateOnly: false }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const addPeopleToEmail = (id: number, peopleIds: number[]) => {
  const begin = getActionBaseProvider(actionTypes.addPeopleToEmailBegin);
  const success = getActionBaseProvider(actionTypes.addPeopleToEmailSuccess);
  const failure = getActionProvider<Error>(actionTypes.addPeopleToEmailFailure);

  const request: AddPeopleToEmailRequest = { id, peopleIds };

  return (dispatch: Dispatcher) => {
    dispatch(begin());
    dataService.emails
      .addPeopleToEmailAsync(request)
      .then(() => dispatch(success()))
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const deletePeopleFromEmail = (id: number, peopleIds: number[]) => {
  const begin = getActionBaseProvider(actionTypes.deletePeopleFromEmailBegin);
  const success = getActionBaseProvider(actionTypes.deletePeopleFromEmailSuccess);
  const failure = getActionProvider<Error>(actionTypes.deletePeopleFromEmailFailure);

  const request: DeletePeopleFromEmailRequest = { id, peopleIds };

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      await dataService.emails.deletePeopleFromEmailAsync(request);
      dispatch(success());
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const fetchDraftEmailEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedEmailBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedEmailFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailEditModeSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.Emails, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchLockedEmailSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedEmailSuccess);

export const publishDraftEmailEntity = (id: number, needToSend?: boolean) => {
  const begin = getActionBaseProvider(actionTypes.publishEmailBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishEmailFailure);

  const payload = needToSend !== undefined ? { needToSend } : null;
  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailPublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.Emails, id, payload);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftEmailSuccess = getActionProvider<MidnightActionPayload>(actionTypes.publishEmailSuccess);

export const revertEmailEntityToPublished = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.revertPublishedEmailBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.revertPublishedEmailFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EmailRevertSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.Emails, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const revertEmailEntityToPublishedSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.revertPublishedEmailSuccess,
);

export const updateEmailCommandSuccess = getActionProvider(
  actionTypes.updateEmailCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const emailUpdated = getActionProvider(actionTypes.emailUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const resetEmailEntityState = getActionBaseProvider(actionTypes.resetEmailEntityState);

export const setEmailEntityIdAction = getActionProvider<number>(actionTypes.setEmailEntityId);

export function sendEmailEntity(id: number, email: string) {
  const begin = getActionBaseProvider(actionTypes.sendTestEmailBegin);
  const success = getActionBaseProvider(actionTypes.sendTestEmailSuccess);
  const failure = getActionProvider<Error>(actionTypes.sendTestEmailFailure);

  const request: SendTestEmailRequest = { id, email };

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      await dataService.emails.sendTestEmailAsync(request);
      dispatch(success());
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
}
