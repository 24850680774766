import { bindActionCreators } from "@reduxjs/toolkit";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { isBsi, isUserDeleted, type PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { type FC, useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { selectPeopleDetails, selectPeopleDetailsValue } from "../state/slices/surveyPerformanceSlice";
import * as surveyPerformanceActions from "../state/thunks/surveyPerformanceThunk";
import { type SurveyPeopleDetails } from "../types/state";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { RouteNames } from "enums";

import styles from "./surveyPerformancePeople.module.scss";
import { type ColumnOption } from "interfaces/columnOptions";
import { MoboLink } from "components/MoboLink/MoboLink";

export type SurveyPerformancePeopleProps = {
  surveyId: number;
  flowId?: number;
  filter: PerformanceFilter;
  includeAccounts?: boolean;
} & PropsFromRedux;

const getColumnOptions = (accountId: number, includeAccounts: boolean = false) => {
  const baseColumnOptions: ColumnOption<SurveyPeopleDetails>[] = [
    {
      name: "NAME",
      width: 5,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const useLink = !isUserDeleted(entity) && (!includeAccounts || (accountId && isBsi(accountId)));
        const isUserFromCurrentAccount = entity.AccountId === accountId;

        const userName = (
          <Tooltip
            target={
              <TextTruncate>
                {entity.FirstName} {entity.LastName}
              </TextTruncate>
            }
            content={`${entity.FirstName} ${entity.LastName}`}
          />
        );
        const secondaryInfo = [entity.UserTitle, entity.Department].filter((x) => x).join(", ");
        return (
          <div>
            {useLink ? (
              <MoboLink
                className={styles.linkButton}
                target={isUserFromCurrentAccount ? "_self" : "_blank"}
                to={`/${RouteNames.peopleUsers}/${entity.UserId}`}
                accountId={!isUserFromCurrentAccount ? entity.AccountId : undefined}
              >
                {userName}
              </MoboLink>
            ) : (
              userName
            )}
            <Tooltip
              content={secondaryInfo}
              target={<TextTruncate className="workTitle">{secondaryInfo}</TextTruncate>}
            />
          </div>
        );
      },
    },
    {
      name: "EMAIL",
      isSortable: false,
      width: 5,
      render: (entity: SurveyPeopleDetails) => (
        <div>
          <Tooltip target={<TextTruncate>{entity.Email}</TextTruncate>} content={entity.Email} />
          <div>&nbsp;</div>
        </div>
      ),
    },
    {
      name: "QUESTIONS COMPLETED",
      width: 6,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => (
        <div>
          <div>{entity.SurveysCompleted}</div>
          <div>&nbsp;</div>
        </div>
      ),
    },
    {
      name: "TIMES TAKEN",
      width: 4,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => (
        <div>
          <div>{entity.SurveysStarted}</div>
          <div>&nbsp;</div>
        </div>
      ),
    },
    {
      name: "STARTED",
      width: 4,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const date = dateTimeUtils.formatDate(entity.StartDate);

        return (
          <div>
            <div>{date}</div>
            <div>&nbsp;</div>
          </div>
        );
      },
    },
    {
      name: "COMPLETED",
      width: 4,
      isSortable: false,
      render: (entity: SurveyPeopleDetails) => {
        const date = dateTimeUtils.formatWithValidation(entity.CompletionDate);

        return (
          <div>
            <div>{date}</div>
            <div>&nbsp;</div>
          </div>
        );
      },
    },
  ];

  return baseColumnOptions;
};

export const SurveyPerformancePeopleComponent: FC<SurveyPerformancePeopleProps> = ({
  actions,
  surveyId,
  flowId,
  filter,
  peopleDetails,
  peopleDetailsStatus,
  setHasExportData,
  includeAccounts,
  accountId,
}) => {
  const columnOptions = getColumnOptions(accountId, includeAccounts);
  useEffect(() => {
    actions.fetchPeopleDetails(surveyId, { ...filter, flowId });
  }, [surveyId, actions, filter, flowId]);

  useEffect(() => {
    setHasExportData(peopleDetailsStatus.status === RStatus.Got && !!peopleDetails.length);
  }, [setHasExportData, peopleDetailsStatus.status, peopleDetails]);

  return (
    <RequestStatusRenderer state={peopleDetailsStatus}>
      <GenericPerformanceList rows={peopleDetails} columns={columnOptions} filter={filter} title="People" />
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    peopleDetails: selectPeopleDetailsValue(state),
    peopleDetailsStatus: selectPeopleDetails(state),
    accountId: state.userProfile.accountId,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(surveyPerformanceActions, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SurveyPerformancePeopleComponent);
export default ConnectedComponent;
