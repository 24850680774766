import { Button } from "semantic-ui-react";
import { type DeletePackItemsRenderModalActionsParams } from "./DeletePackItemsStep";

export const getConfirmDeletePackItemsStepModalActions = (params: DeletePackItemsRenderModalActionsParams) => {
  return (_: () => void, prevStep: () => void) => {
    return (closeModal: Function) => {
      const { isDataValid, onIsDataValidChange, onConfirm } = params;
      return (
        <>
          <Button
            primary
            className={"previous"}
            content={"Previous"}
            onClick={() => {
              onIsDataValidChange(true);
              prevStep();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Confirm"
            onClick={() => {
              onConfirm();
              closeModal();
            }}
            disabled={!isDataValid}
          />
        </>
      );
    };
  };
};
