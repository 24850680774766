import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type RequestedPackLicenses } from "../../types/state";

export interface RequestedPacksLicensesState extends FetchingItemsState<RequestedPackLicenses> {}

const initialState: RequestedPacksLicensesState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

export const requestedPacksLicensesSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "requestedPacksLicenses",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = requestedPacksLicensesSlice.actions;

export const requestedPacksLicensesSelector = (state: RootState) => state.packs.requestedPacksLicenses.items;

export default requestedPacksLicensesSlice.reducer;
export type RequestedPacksLicensesSliceType = ReturnType<typeof requestedPacksLicensesSlice.reducer>;
