import type PacksFilters from "enums/licensing/PacksFilters";
import { FilterType, type FilterTypesMap } from "utils/filterUtils";

export interface PackUrlParams {
  id: string;
}

export const packFilterTypes: FilterTypesMap<PacksFilters> = {
  dateAdded: FilterType.DateRange,
  lastModified: FilterType.DateRange,
  categoryIds: FilterType.Array,
  industryIds: FilterType.Array,
  publisherIds: FilterType.Array,
};
