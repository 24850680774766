import { EntityType } from "../../types/Sankey";
import { type Margin } from "../../types/Shared";
import { type ComputedLink, type ComputedNode } from "../src/d3Sankey";

export const TOP_TEXT_OFFSET = 15;
export const TEXT_LEFT_PADDING = 10;
export const TEXT_VERTICAL_PADDING = 10;
export const ICON_SIZE = 14;

export const DEFAULT_MARGIN: Margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const DEFAULT_NODE_PROPS: ComputedNode = {
  width: 0,
  height: 0,
  name: "",
  sourceLinks: [],
  targetLinks: [],
  uniqueId: "",
  value: 0,
  x: 0,
  y: 0,
  type: EntityType.Video,
  order: 0,
};

export const DEFAULT_LINK_PROPS: ComputedLink = {
  thickness: 0,
  sourceYPosition: 0,
  targetYPosition: 0,
  source: DEFAULT_NODE_PROPS,
  target: DEFAULT_NODE_PROPS,
  value: 0,
};
