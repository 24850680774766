import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import { ReactComponent as Open } from "../../../images/access-open.svg";
import { ReactComponent as Restricted } from "../../../images/access-restricted.svg";

export default function GroupRestrictionIcon(props) {
  const { isOpen } = props;

  const popupTrigger = isOpen ? <Open className="open-icon" /> : <Restricted className="restricted-icon" />;

  const popupHeader = isOpen ? "OPEN" : "RESTRICTED";

  const popupContent = isOpen ? "Anyone is allowed to join this group." : "Requires approval to join this group.";

  return (
    <Popup id="openPopup" position="top left" trigger={<span>{popupTrigger}</span>} inverted>
      <Popup.Header>{popupHeader}</Popup.Header>
      <Popup.Content>{popupContent}</Popup.Content>
    </Popup>
  );
}

GroupRestrictionIcon.defaultProps = {
  isOpen: false,
};

GroupRestrictionIcon.propTypes = {
  isOpen: PropTypes.bool,
};
