import { Card, Icon } from "semantic-ui-react";
import cn from "classnames";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import CardThumbnail from "../cardThumbnail/CardThumbnail";

import "./FlowDesignerEventCard.scss";

export interface Props {
  id: number;
  title: string;
  thumbnailUrl?: string;
  className?: string;
  publisher: string;
  hasReminders: boolean;
}

function FlowDesignerEventCard(props: Props) {
  const { id, title, className, thumbnailUrl, hasReminders } = props;

  return (
    <Card
      key={id}
      className={cn("flow-designer-event-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-event-card"
      data-hook-id={id}
    >
      {thumbnailUrl ? (
        <CardThumbnail thumbnailUrl={thumbnailUrl} draggable={false} />
      ) : (
        <div className="thumbnail">
          <Icon className="calendar alternate" size="big" />
        </div>
      )}
      <div className="type-block">
        {hasReminders && <Icon className="fa-bell" size="small" />}
        <span className="type">Event</span>
      </div>
      <div className="title">
        <Tooltip target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
      </div>
    </Card>
  );
}

export default FlowDesignerEventCard;
