import { type SortingDirection } from "enums";
import { type AppThunk } from "features/Application/globaltypes/redux";
import DataService from "features/Application/services/dataServices/typedDataService";
import { formatFilters } from "utils/filterMapUtils";
import { FilterType } from "utils/filterUtils";
import { escapeTerm } from "utils/searchUtils";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { type UpdateParams } from "interfaces";
import { fetchBegin, fetchSuccess } from "../slices/templates/templatesSlice";

type FetchTemplatesPayload = {
  skip: number;
  top: number;
  sortBy: string;
  sortOrder: SortingDirection;
  accountId: number;
};

export const fetchTemplates =
  ({ top, skip, accountId, sortBy, sortOrder }: FetchTemplatesPayload): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());
      const filtersState = getState().people.groupTemplate.filters;
      const cb = DataService.accounts.getTemplates;
      const result = await cb(accountId, {
        top,
        skip,
        sortBy,
        sortOrder,
        term: escapeTerm(filtersState.search),
        ...formatFilters(filtersState.appliedFilter, {
          dateCreated: FilterType.DateRange,
        }),
      });

      const accId = getState().userProfile.accountId;

      dispatch(
        fetchSuccess({
          items: result.items.map((x) => ({ ...x, isOwn: x.publisherId === accId })),
          totalCount: result.count,
        }),
      );
    } catch {}
  };

export const deleteTemplate =
  (templateId: number, accountId: number, onSuccess: () => void): AppThunk =>
  async (dispatch) => {
    const updateParams: UpdateParams = {
      id: "DeletingTemplateOperation",
      title: `Deleting Template`,
      onCompleted: onSuccess,
      getMessageIds: async () => {
        const { data } = await DataService.accounts.deleteTemplate(templateId, {
          accountIds: [accountId.toString()],
        });

        return data.messageIds;
      },
      successTransientMessage: `Template has been deleted successfully.`,
      successTitle: "Template deleted!",
      failureTransientMessage: `Template deleting failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
