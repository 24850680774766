import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./arrowButtons.scss";

function LeftArrowButton(props) {
  const { onClick, size } = props;
  return (
    <div className={cn("arrow-button left", size)} onClick={onClick}>
      <Icon className="fa-chevron-left" size={size} />
    </div>
  );
}

LeftArrowButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default LeftArrowButton;
