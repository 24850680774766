import { isEmpty, set, uniq } from "lodash";

import {
  type EntityToPeopleAssignments,
  ContentType,
  type PeopleType,
  type FlowPayload,
} from "../features/Library/PeopleAssignments/types";
import {
  type ContentAssignmentModelItem,
  type FlowPriorityItem,
  type GetUsersListAction,
  type PriorityItem,
} from "../features/People/types";
import {
  AddPeopleToContentTypes,
  type AssignmentPeopleContext,
  PriorityLevels,
  RolePermissions,
  SortingDirection,
} from "../enums";
import { type PacksContextItemModel } from "../interfaces";
import ItemTypes from "../enums/packItemsTypes";
import { type FiltersMap } from "./filterUtils";
import { usersFilter } from "components/filterForms/UsersFilterForm/usersFilter";
import { type FlowAssignment } from "../features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/packsContextSlice";
import nameof from "./nameof";
import { type ContentAssignmentState } from "../features/People/ContentAssignmentState/ContentAssignmentReducer";

export const contentToPayloadPropMap = {
  [AddPeopleToContentTypes.Flows]: ContentType.Flow,
  [AddPeopleToContentTypes.Videos]: ContentType.Video,
  [AddPeopleToContentTypes.Events]: ContentType.Event,
  [AddPeopleToContentTypes.Surveys]: ContentType.Survey,
  [AddPeopleToContentTypes.Assessments]: ContentType.Assessment,
  [AddPeopleToContentTypes.PDFs]: ContentType.Pdf,
};

// Using temp solution with non-empty array until we can rely on view type being relevant
// (items view state with container components state sync issue)
// https://brainstorm.atlassian.net/browse/SAAS-14351
// NOSONAR
// eslint-disable-next-line no-unused-vars
const getContentItems = (contentType: ContentType, contentAssignment: ContentAssignmentState) => {
  function getNotEmptyArray(array1: any[], array2: any[]) {
    if (array1.length) {
      return array1;
    }
    return array2;
  }

  let assignments: ContentAssignmentModelItem[] = [];
  if (contentType === ContentType.Flow) {
    assignments = getNotEmptyArray(contentAssignment.flowList.items, contentAssignment.flowGrid.items);
  }
  if (contentType === ContentType.Video) {
    assignments = getNotEmptyArray(contentAssignment.assetList.items, contentAssignment.assetGrid.items);
  }
  if (contentType === ContentType.Event) {
    assignments = getNotEmptyArray(contentAssignment.eventList.items, contentAssignment.eventGrid.items);
  }
  if (contentType === ContentType.Survey) {
    assignments = contentAssignment.surveyList.items;
  }
  if (contentType === ContentType.Assessment) {
    assignments = contentAssignment.assessmentList.items;
  }
  if (contentType === ContentType.Pdf) {
    assignments = getNotEmptyArray(contentAssignment.pdfList.items, contentAssignment.pdfGrid.items);
  }
  return assignments;
};

const getSelectedAssignments = (
  selected: { ids: number[]; inherited?: Set<number> },
  contentType: ContentType,
  priorityLevel: PriorityLevels,
) => {
  return selected.ids.map((id) => ({
    id: id,
    priorityLevel: priorityLevel,
    contentType: contentType,
    // prefill days to complete with default value,
    // see https://brainstorm.atlassian.net/browse/SAAS-22564 (#2)
    daysToComplete: 1,
    inherited: selected.inherited?.has(id),
  }));
};

const userListUtils = {
  formatOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection) => {
    const paramMap: { [key: string]: string } = {
      name: `firstName ${sortingDirection},lastName ${sortingDirection}`,
      added: `id ${sortingDirection}`,
      "last login": `lastLoginDate ${sortingDirection}`,
      "job title": `title ${sortingDirection}`,
    };

    const name = sortingColumnName.toLowerCase();

    return paramMap[name] || `${name} ${sortingDirection}`;
  },
  formatOrderParamsV2: (sortingColumnName: string, sortingDirection: SortingDirection): string => {
    const paramMap: { [key: string]: string } = {
      name: `firstName ${sortingDirection},lastName ${sortingDirection}`,
      added: `createDate ${sortingDirection}`,
      "last login": `lastLoginDate ${sortingDirection}`,
      "job title": `jobTitle ${sortingDirection}`,
    };

    const name = sortingColumnName.toLowerCase();

    return paramMap[name] || `${name} ${sortingDirection}`;
  },
  formatOrderParamsByColumnName: (sortingColumnName: string): string => {
    const paramMap: { [key: string]: string } = {
      name: "firstName",
      "job title": `jobTitle`,
    };

    const name = sortingColumnName.toLowerCase();

    return paramMap[name] || `${name}`;
  },
  formatODataOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection): string[] => {
    return userListUtils.formatOrderParams(sortingColumnName, sortingDirection).split(",");
  },
  mapToPriorityItem: (item: any, contentType: ContentType): PriorityItem => {
    const result = {
      id: item.id,
      title: item.title,
      thumbnailUrl: item.thumbnailUrl,

      daysToComplete: 1,
      priorityLevel: PriorityLevels.none,
      blockType: contentType,
      hasBeenPublished: !!item.hasBeenPublished,
      durationInSeconds: item.durationInSeconds,
    };
    if (contentType === ContentType.Flow) {
      set(result, nameof<FlowPriorityItem>("canAutoStart"), item.canAutoStart);
    }
    return result;
  },
  mapToAssignmentItem: (peopleId: number, item: any, contentType: string, peopleType: AssignmentPeopleContext) => {
    const result: EntityToPeopleAssignments = {
      contentId: item.id,
      peopleId: peopleId,
      priority: item.priorityLevel ?? 1,
      dueDate: item.priorityLevel === PriorityLevels.required ? item.daysToComplete : null,
      contentType: contentType,
      peopleType: peopleType,
      packIds: item.packIds,
    };
    if (contentType === ContentType.Flow) {
      (result as EntityToPeopleAssignments<FlowPayload>).payload = {
        autoStart: Boolean(item.autoStart),
      };
    }
    return result;
  },
  mapAssignmentFlows(
    peopleId: number,
    flowAssignments: FlowAssignment[],
    priorityItems: FlowPriorityItem[],
    peopleType: AssignmentPeopleContext,
  ): EntityToPeopleAssignments<FlowPayload>[] {
    const assignments = flowAssignments.map((item) => ({
      contentId: item.flowId,
      contentType: ItemTypes.Flow,
      packIds: item.packIds,
      peopleId: peopleId,
      peopleType: peopleType,
    }));

    return assignments.map((assignmentItem) => {
      const priorityItem = priorityItems.find((pi) => pi.id === assignmentItem.contentId);
      return {
        ...assignmentItem,
        priority: priorityItem?.priorityLevel ?? PriorityLevels.none,
        dueDate: priorityItem?.priorityLevel === PriorityLevels.required ? priorityItem.daysToComplete : null,
        payload: {
          autoStart: Boolean(priorityItem?.autoStart),
        },
      };
    });
  },
  mapAssignmentItems: (priorityItems: PriorityItem[], packContextItems: PacksContextItemModel[]): PriorityItem[] =>
    priorityItems.map((priorityItem) => {
      let packIds: number[];
      const blockTypeLower = priorityItem.blockType.toLowerCase();
      if (blockTypeLower === ItemTypes.Flow.toLowerCase()) {
        packIds = uniq(packContextItems.filter((p) => p.flowIds.indexOf(priorityItem.id) > -1).map((p) => p.packId!));
      } else {
        packIds = packContextItems
          .filter((p) => p.type.toLowerCase() === blockTypeLower && p.id === priorityItem.id.toString())
          .map((p) => p.packId!);
      }

      return { ...priorityItem, packIds };
    }),
  mapToContentTypeRecord: (
    content: Record<ContentType, { ids: number[]; undeletableIds: number[] }>,
  ): Record<ContentType, { ids: number[]; undeletableIds: number[]; inherited: Set<number> }> => {
    return Object.keys(content).reduce(
      (result, type) => ({
        ...result,
        [type]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
      }),
      {},
    ) as Record<ContentType, { ids: number[]; undeletableIds: number[]; inherited: Set<number> }>;
  },
  mapToContentRemovePayload: (
    content: Record<ContentType, { ids: number[]; undeletableIds: number[] }>,
    peopleId: number,
    peopleType: PeopleType,
  ) => {
    return Object.keys(content)
      .map((contentType: string) =>
        content[contentType as ContentType].ids.map((contentId: number) => ({
          peopleId: peopleId,
          peopleType: peopleType,
          contentId,
          contentType,
        })),
      )
      .flat();
  },
  getUsersFactory: (fetchUsersAction: GetUsersListAction, search: string) => {
    return function (
      skip: number,
      top: number,
      sortingColumnName: string = "",
      sortingDirection: SortingDirection = SortingDirection.Descending,
      filter: FiltersMap = {},
    ) {
      const { orderParams, filterParams } = userListUtils.getFetchUsersParams(
        search,
        sortingColumnName,
        sortingDirection,
        filter,
        () => sortingColumnName !== "last login" && isEmpty(filter.lastLoginDate),
      );
      fetchUsersAction(skip, top, orderParams, filterParams, search);
    };
  },
  getOverviewUsers: (fetchUsersAction: GetUsersListAction, search: string) => {
    return function (
      skip: number,
      top: number,
      sortingColumnName: string = "",
      sortingDirection: SortingDirection = SortingDirection.Descending,
      filter: FiltersMap = {},
    ) {
      const orderParams = userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection);
      fetchUsersAction(skip, top, orderParams, filter, search);
    };
  },
  getFetchUsersParams: (
    search: string,
    sortingColumn: string = "",
    sortingDirection: SortingDirection = SortingDirection.Descending,
    filter: FiltersMap = {},
    predicate?: () => boolean,
  ) => {
    const getParamsV1 = () => ({
      orderParams: userListUtils.formatOrderParams(sortingColumn, sortingDirection),
      filterParams: usersFilter.buildFilterQuery(filter),
    });

    const getParamsV2 = () => ({
      orderParams: userListUtils.formatOrderParamsV2(sortingColumn, sortingDirection),
      filterParams: filter,
    });

    return isEmpty(search) && (!predicate || predicate()) ? getParamsV1() : getParamsV2();
  },
  mapToContentUsers: <T extends { id?: number; userId?: number }>(userList: T[]) =>
    userList.map((x) => ({ ...x, id: x.id ?? x.userId })),

  getContentItems,

  getContentItemsBulk: (
    groupContent: Record<ContentType, { ids: number[]; inherited?: Set<number> }>,
    preselectedPriorityLevel: PriorityLevels,
  ) => {
    const flowPriorityItems = getSelectedAssignments(groupContent.flow, ContentType.Flow, preselectedPriorityLevel);
    const videoPriorityItems = getSelectedAssignments(groupContent.video, ContentType.Video, preselectedPriorityLevel);
    const surveyPriorityItems = getSelectedAssignments(
      groupContent.survey,
      ContentType.Survey,
      preselectedPriorityLevel,
    );
    const assessmentPriorityItems = getSelectedAssignments(
      groupContent.assessment,
      ContentType.Assessment,
      preselectedPriorityLevel,
    );
    const pdfPriorityItems = getSelectedAssignments(groupContent.pdf, ContentType.Pdf, preselectedPriorityLevel);
    const eventPriorityItems = getSelectedAssignments(
      groupContent.externalEvent,
      ContentType.Event,
      preselectedPriorityLevel,
    );
    return flowPriorityItems
      .concat(videoPriorityItems)
      .concat(surveyPriorityItems)
      .concat(assessmentPriorityItems)
      .concat(pdfPriorityItems)
      .concat(eventPriorityItems);
  },
  getCanEditContent: (
    userContent: Record<
      ContentType,
      {
        ids: number[];
        undeletableIds: number[];
      }
    >,
    userPermissions: RolePermissions[],
  ) => {
    return (
      (!userContent.flow.ids.length || userPermissions.includes(RolePermissions.FlowsView)) &&
      ((!userContent.video.ids.length &&
        !userContent.assessment.ids.length &&
        !userContent.survey.ids.length &&
        !userContent.pdf.ids.length) ||
        userPermissions.includes(RolePermissions.AssetsView)) &&
      (!userContent.externalEvent.ids.length || userPermissions.includes(RolePermissions.EventsView))
    );
  },
};

export default userListUtils;
