import { type FC } from "react";
import { type SurveyFiltersEnum } from "../../../features/Library/Surveys/types/models";
import { type FilterFormPropsBase } from "../types/props";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getSurveyFilters } from "./surveyFilters";

export interface SurveyFilterFormProps extends FilterFormPropsBase<SurveyFiltersEnum> {}

const SurveyFilterForm: FC<SurveyFilterFormProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getSurveyFilters()}
  />
);

export default SurveyFilterForm;
