import cn from "classnames";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { type PerformanceFilter, completes, noData } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type FC, useEffect, useMemo } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "semantic-ui-react";
import { type ColumnOption } from "../../../../../interfaces/columnOptions";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import {
  selectEngagementQuestionsData,
  selectEngagementQuestionsValue,
} from "../../state/slices/assessmentPerformanceSlice";
import * as assessmentPerformanceActions from "../../state/thunks/assessmentPerformanceThunk";
import { type AssessmentQuestionAnswer, type EngagementQuestionsData } from "../../types/state";

import "./AssessmentPerformanceList.scss";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import moboConfigurator from "moboConfigurator";

export type Props = {
  entityId: number;
  flowId?: number;
  selectQuestion: (question: EngagementQuestionsData) => void;
  selectAnswer: (answer: AssessmentQuestionAnswer) => void;
  dateFilter: PerformanceFilter;
  hasData: boolean;
  hideControls?: boolean;
} & PropsFromRedux;

export interface AssessmentEntity extends EngagementQuestionsData {
  questionPosition: number;
}

export type EmailStatusColors = "icon-send-complete" | "icon-send-progress" | "icon-send-canceled";

export const AssessmentPerformanceQuestionsComponent: FC<Props> = ({
  actions,
  entityId,
  flowId,
  questionDetailsState,
  selectQuestion,
  selectAnswer,
  questionsDetailsEntities,
  dateFilter,
  hasData,
  hideControls,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.AssessmentReport);

  useEffect(() => {
    actions.fetchEngagementQuestionsData(entityId, { ...dateFilter, flowId });
  }, [actions, dateFilter, entityId, flowId]);

  const columnOptions: ColumnOption<AssessmentEntity>[] = [
    {
      name: "QUESTION",
      width: 7,
      isSortable: false,
      render: (entity) => {
        let answersGenerator = entity.Answers.map((option: AssessmentQuestionAnswer) => {
          const iconStyle = cn(
            { "fas fa-comment-check green outline": option.IsAnswerCorrect },
            { "fas fa-comment outline": !option.IsAnswerCorrect },
          );
          const textColor = cn({ correctAnswer: option.IsAnswerCorrect });
          return (
            <div className="answerData" key={option.AnswerId}>
              <Tooltip
                target={
                  <TextTruncate>
                    <a
                      href={moboConfigurator.withMobo("")}
                      onClick={(e) => {
                        e.preventDefault();
                        selectQuestion(entity);
                        selectAnswer(option);
                      }}
                    >
                      <div className="questionAnswers">{option.AnswerTitle}</div>
                    </a>
                  </TextTruncate>
                }
                content={option.AnswerTitle}
              />
              <div className={`${textColor} assessmentAnswerPercentage`}>
                <Icon className={iconStyle} />
                {Math.round(option.ChoicePercent * 100).toLocaleString()}%
              </div>
            </div>
          );
        });
        return (
          <div className="questionAnswerRoot">
            <div>
              <div className="asterisk">{entity.IsRequired ? <div>*</div> : ""}</div>
              {entity.questionPosition}.
            </div>
            <div className="questionAnswer">
              <div className="question">
                <Tooltip
                  target={
                    <TextTruncate>
                      <a
                        href={moboConfigurator.withMobo("")}
                        onClick={(e) => {
                          e.preventDefault();
                          selectQuestion(entity);
                        }}
                      >
                        <div className="questionAnswers">{entity.QuestionText}</div>
                      </a>
                    </TextTruncate>
                  }
                  content={entity.QuestionText}
                />
              </div>
              {answersGenerator}
            </div>
          </div>
        );
      },
    },
    {
      name: "TYPE",
      width: 2,
      isSortable: false,
      render: (entity: AssessmentEntity) => {
        return <div className="entityAlignment">{entity.QuestionType}</div>;
      },
    },
    {
      name: completes.toUpperCase(),
      width: 2,
      isSortable: false,
      render: (entity: AssessmentEntity) => {
        return <div className="entityAlignment">{entity.Completed ? entity.Completed.toLocaleString() : 0}</div>;
      },
    },
    {
      name: "COMPLETION RATE",
      width: 3,
      isSortable: false,
      render: (entity: AssessmentEntity) => {
        const completionRate = entity.CompletionRate ?? 0;
        return (
          <div className="entityAlignment">
            {reportEnabled ? Math.round(completionRate * 100) : Math.round(completionRate * 100) / 100}%
          </div>
        );
      },
    },
    {
      name: "ATTEMPTS",
      width: 2,
      isSortable: false,
      render: (entity: AssessmentEntity) => {
        return <div className="entityAlignment">{entity.Attempts ? entity.Attempts.toLocaleString() : 0}</div>;
      },
    },
    {
      name: "AVG. ATTEMPTS",
      width: 2,
      isSortable: false,
      render: (entity: AssessmentEntity) => {
        const avgAttempts = entity.AvgAttempts ?? 0;
        return <div className="entityAlignment">{(Math.round(avgAttempts * 100) / 100).toLocaleString()}</div>;
      },
    },
  ];

  const entities = useMemo<AssessmentEntity[]>(() => {
    return questionsDetailsEntities.map<AssessmentEntity>((e, i) => {
      return {
        ...e,
        questionPosition: i + 1,
        id: e.QuestionId,
      };
    });
  }, [questionsDetailsEntities]);

  return (
    <RequestStatusRenderer state={questionDetailsState}>
      {hasData ? (
        <GenericPerformanceList
          hideControls={hideControls}
          rows={entities}
          columns={columnOptions}
          filter={dateFilter}
          title="Questions & Answers"
        />
      ) : (
        noData(dateFilter)
      )}
    </RequestStatusRenderer>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    questionDetailsState: selectEngagementQuestionsData(state),
    questionsDetailsEntities: selectEngagementQuestionsValue(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(assessmentPerformanceActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AssessmentPerformanceQuestionsComponent);
export default ConnectedComponent;
