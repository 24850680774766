import { pluralize } from "../../utils/stringUtils";
import { type FC } from "react";

export type PageFrame = { from: number; to: number };

export type PaginationFrameProps = {
  range: PageFrame;
  count: number;
};

export const PaginationFrame: FC<PaginationFrameProps> = (props) => {
  const { count: countEntities, range } = props;
  const count = countEntities.toLocaleString();
  return (
    <div className="number-of-results list">
      {range.from.toLocaleString()}-{range.to.toLocaleString()} / {count}
      {pluralize(" result", countEntities)}
    </div>
  );
};
