import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withEntityState } from "../../../../../Application/reducers/hoc/withEntityState";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.FeedbackPages;

const feedbackPageEntityState = withEntityState(namespace, entityPrefix);

export default feedbackPageEntityState;
