import { type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";
import { FlowUserFiltersEnum } from "../../../features/Library/Flows/Edit/People/models";
import { FilterType, type FilterTypesMap } from "../../../utils/filterUtils";

export const flowUserFilters: FilterItemBase<FlowUserFiltersEnum>[] = [
  {
    type: FilterViewTypes.DateRange,
    label: "Permission issued date",
    propertyName: FlowUserFiltersEnum.PermissionIssuedDate,
  },
  {
    type: FilterViewTypes.MultiSelect,
    label: "Priority",
    propertyName: FlowUserFiltersEnum.Priority,
    placeholder: "All Priorities",
  },
  {
    type: FilterViewTypes.Select,
    label: "Expired",
    propertyName: FlowUserFiltersEnum.Expired,
    placeholder: "All Expires",
  },
  {
    type: FilterViewTypes.MultiSelect,
    label: "Status",
    propertyName: FlowUserFiltersEnum.Status,
    placeholder: "All Statuses",
  },
];

export const flowUserFilterParams: FilterTypesMap<FlowUserFiltersEnum> = {
  [FlowUserFiltersEnum.PermissionIssuedDate]: FilterType.DateRange,
  [FlowUserFiltersEnum.Priority]: FilterType.DateRange,
  [FlowUserFiltersEnum.Expired]: FilterType.Value,
  [FlowUserFiltersEnum.Status]: FilterType.Array,
};
