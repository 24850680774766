import { type Dispatch } from "@reduxjs/toolkit";
import { type TagsEnum } from "../../../../../interfaces/TagsEnum";
import { getBatchAddEntityTagsThunk } from "../../../Common/thunkBuilders/batchAddEntityTagsThunkBuilder";
import eventsDataService from "../../services/eventsDataService";
import { setError, setTags } from "../slices/eventCommonSlice";
import { type AppDispatch, type AppThunk } from "features/Application/globaltypes/redux";
import { pluralize } from "utils/stringUtils";
import { type DistributedOpUpdateParams } from "interfaces/updateParams";
import backgroundTask from "features/BackgroundTasks/backgroundTask";

export const fetchEventsTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await eventsDataService.getEventsTagsAsync(true);
    dispatch(setTags(res.data));
  } catch (e) {
    dispatch(setError(e as Error));
  }
};

const getMessageIds = (eventIds: number[], tags: string[], tagType: TagsEnum) => async () => {
  const response = await eventsDataService.addEventTags({ ids: eventIds, tags, tagType });
  return response?.data.messageIds;
};

export const changeVisibility =
  (ids: number[], visible: boolean, onCompleted: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const entity = pluralize("Event", idsCount);
    const asset = pluralize("Asset", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "VisibilityEventsDistributedOperation",
      title: `${visible ? "Unhiding" : "Hiding"} ${asset}`,
      onCompleted,
      getOperationProps: async () => {
        const { data } = await eventsDataService.changeVisibility(ids, visible);
        return data;
      },
      successTitle: `${entity} ${visible ? "Unhidden" : "Hidden"}!`,
      successTransientMessage: `${entity} ${idsCount === 1 ? "has" : "have"} been ${visible ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${entity} ${visible ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const addExternalEventTags = getBatchAddEntityTagsThunk("ExternalEvent", getMessageIds);
