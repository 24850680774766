import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

export const LOCKED_FLOW_UPDATED = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const RESET_FLOW_ENTITY_STATE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const UPDATE_LOCKED_FLOW_COMMAND_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const UPDATE_LOCKED_FLOW_COMMAND_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const RESET_UPDATE_LOCKED_FLOW_COMMAND_STATE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE,
);

export const FETCH_LOCKED_FLOW_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const FETCH_LOCKED_FLOW_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const FETCH_LOCKED_FLOW_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const USE_EXISTING_LOCKED_FLOW_ENTITY = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.USE_EXISTING_LOCKED_ENTITY,
);

export const PUBLISH_LOCKED_FLOW_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);

export const PUBLISH_LOCKED_FLOW_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);

export const PUBLISH_LOCKED_FLOW_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const REVERT_LOCKED_FLOW_TO_PUBLISHED_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const REVERT_LOCKED_FLOW_TO_PUBLISHED_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const REVERT_LOCKED_FLOW_TO_PUBLISHED_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
