import React, { useCallback, useEffect, useMemo } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { LazyLoadingList } from "../../../../../../components/lazyLoadingList/LazyLoadingList";
import { SortingDirection } from "../../../../../../enums";
import { getColumnOptions, ClosedCaptionsTabColumns } from "./getColumnOptions";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import { getAssignedClosedCaptions, getEntityIdSelector } from "../../../state/selectors/detailsSelector";
import { bindAction } from "../../../../../../interfaces";
import {
  deleteClosedCaptions,
  fetchClosedCaptions,
  refechClosedCaptions,
  setDefaultClosedCaptionAction,
} from "../../../state/thunks/videoClosedCaptionsThunk";
import { resetClosedCaptions, selectedItemsChanged } from "../../../state/slices/videoClosedCaptionsSlice";
import { CLOSED_CAPTIONS } from "../../../types/constants";
import { ClosedCaptionsNoResults } from "./ClosedCaptionsNoResults";
import { type ClosedCaptionsListProps } from "../types";
import { useLazyLoadingController } from "../../../../../../components/lazyLoadingList/useLazyLoadingController";
import { useObserver } from "../../../../../../hooks/useObserver";

import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";

import "./closedCaptionsList.scss";

export type ClosedCaptionsListPropsAll = ClosedCaptionsListProps & PropsFromRedux;

export const ClosedCaptionsList = (props: ClosedCaptionsListPropsAll) => {
  const [subscribeOnDiscarded] = useObserver(props.onDiscardedObserver);
  const {
    id,
    isReadOnly,
    closedCaptions,
    selectedItems,
    setDefaultClosedCaption,
    deleteClosedCaptions,
    refechClosedCaptions,
    fetchClosedCaptions,
    onTriggerClosedCaptionsRemovalObserver,
    resetClosedCaptions,
  } = props;

  const { reset, ...lazyLoadingListProps } = useLazyLoadingController({
    items: props.closedCaptions,
    onChangeSelected: props.selectedItemsChanged,
    selectedItems: props.selectedItems,
  });

  const handleFetchClosedCaptions = useCallback(() => {
    fetchClosedCaptions({ videoId: id, top: CLOSED_CAPTIONS.TOP, skip: closedCaptions.length });
  }, [id, closedCaptions.length, fetchClosedCaptions]);

  useEffect(() => {
    refechClosedCaptions(id);
    return () => {
      resetClosedCaptions();
    };
  }, [refechClosedCaptions, resetClosedCaptions, id]);

  useEffect(() => {
    return subscribeOnDiscarded(() => refechClosedCaptions(id));
  }, [id, subscribeOnDiscarded, refechClosedCaptions]);

  const handleDeleteClick = useCallback(
    (selectedId: number) => () => {
      onTriggerClosedCaptionsRemovalObserver.notify(() => {
        deleteClosedCaptions(id, [selectedId]);
      }, 1);
    },
    [id, deleteClosedCaptions, onTriggerClosedCaptionsRemovalObserver],
  );

  const handleSetDefaultClosedCaption = useCallback(
    (languageId: number) => () => {
      setDefaultClosedCaption(languageId, id);
    },
    [setDefaultClosedCaption, id],
  );

  const columnOptions = useMemo(() => {
    return getColumnOptions({
      handleDeleteClick,
      handleSetDefaultClosedCaption,
      isReadOnly: isReadOnly,
      selectedItems: selectedItems,
    });
  }, [isReadOnly, selectedItems, handleSetDefaultClosedCaption, handleDeleteClick]);

  return (
    <div className="closed-captions-list">
      <h4 className="header">Files</h4>
      <div className="closed-captions-list-content">
        <LazyLoadingList
          {...lazyLoadingListProps}
          hasPermission
          withSelection
          items={props.closedCaptions}
          isLoading={props.isLoading}
          isAllDataLoaded={props.areAllDataLoaded}
          disabled={!!props.isReadOnly}
          fetchItems={handleFetchClosedCaptions}
          columnOptions={columnOptions}
          defaultSortingColumnName={ClosedCaptionsTabColumns.Added}
          sortingDirection={SortingDirection.Descending}
          noResultsContent={<ClosedCaptionsNoResults />}
        />
      </div>
      <DeleteConfirmationModal
        onTriggerRemoveContentObserver={onTriggerClosedCaptionsRemovalObserver}
        onConfirmed={reset}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ClosedCaptionsListProps) => {
  return {
    id: ownProps.entityId || getEntityIdSelector(state),
    closedCaptions: getAssignedClosedCaptions(state).items,
    selectedItems: getAssignedClosedCaptions(state).selectedItems,
    isLoading: getAssignedClosedCaptions(state).isLoading,
    areAllDataLoaded: getAssignedClosedCaptions(state).areAllLoaded,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchClosedCaptions: bindAction(fetchClosedCaptions, dispatch),
  refechClosedCaptions: bindAction(refechClosedCaptions, dispatch),
  setDefaultClosedCaption: bindAction(setDefaultClosedCaptionAction, dispatch),
  resetClosedCaptions: bindAction(resetClosedCaptions, dispatch),
  deleteClosedCaptions: bindAction(deleteClosedCaptions, dispatch),
  selectedItemsChanged: bindAction(selectedItemsChanged, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ClosedCaptionsList);
export default ConnectedComponent;
