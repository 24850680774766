import React from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import DropdownWrapper from "../../dropdowns/dropdownWrapper/DropdownWrapper";
import RequiredLabel from "../requiredLabel/RequiredLabel";
import PropTypes from "prop-types";
import { mapFromDirectionName, mapToDirectionName } from "./constants";

import "./mappingRow.scss";

function MappingRow(props) {
  const {
    index,
    mappedFields,
    mapToField,
    onMappingChange,
    onSkipChange,
    mapToDropdownOptions,
    mapFromDropdownOptions,
    allowCustomFields,
    onAddItem,
  } = props;

  return (
    <div className="mapping-row" key={index}>
      <DropdownWrapper
        className="mapping-dropdown from"
        options={mapFromDropdownOptions}
        placeholder="Enter Field Name"
        disabled={mappedFields.isSkipped}
        selection
        clearable
        search
        value={mappedFields.mapFrom}
        onChange={(_, data) => onMappingChange(index, mapFromDirectionName, data.value)}
      />
      <Icon className="fa-arrow-right" disabled={mappedFields.isSkipped} />

      {mapToField.isRequired ? (
        <div className="required-field">
          <div className="field-name">{mapToField.name}</div>
          <RequiredLabel />
        </div>
      ) : (
        <>
          <DropdownWrapper
            className="mapping-dropdown to"
            options={mapToDropdownOptions}
            placeholder="Enter Field Name"
            selection
            clearable
            search
            disabled={mappedFields.isSkipped}
            value={mappedFields.mapTo}
            allowAdditions={allowCustomFields}
            onChange={(_, data) => onMappingChange(index, mapToDirectionName, data.value)}
            onAddItem={(_, data) => onAddItem(index, data.value)}
          />
          <Checkbox
            className="skip-checkbox"
            onChange={(_, data) => onSkipChange(index, data.checked)}
            checked={mappedFields.isSkipped}
          />
        </>
      )}
    </div>
  );
}

MappingRow.propTypes = {
  mappedFields: PropTypes.object.isRequired,
  mapToField: PropTypes.object.isRequired,
  onMappingChange: PropTypes.func.isRequired,
  onSkipChange: PropTypes.func.isRequired,
  mapToDropdownOptions: PropTypes.array.isRequired,
  mapFromDropdownOptions: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  allowCustomFields: PropTypes.bool,
};

export default MappingRow;
