import StatusCode from "../../enums/httpStatusCodes";
import ErrorView from "../ErrorView/ErrorView";
import NotFoundMessage from "../notFound/NotFoundMessage";
import AccessRestrictedMessage from "../restrictedRoute/AccessRestrictedMessage";

export interface Props {
  shouldRender: boolean;
  errorCode?: StatusCode;
  message?: string;
}

export const ErrorViewProvider = (props: Props) => {
  const { errorCode, shouldRender } = props;
  if (!shouldRender) {
    return null;
  }

  switch (errorCode) {
    case StatusCode.NotFound:
      return <NotFoundMessage />;
    case StatusCode.Forbidden:
      return <AccessRestrictedMessage />;
    default:
      return <ErrorView />;
  }
};
