import { type FC } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { NotImplemented } from "../../../../../components";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";

import "./packPerformance.scss";

export type PackPerformanceProps = PropsFromRedux;

const PackPerformance: FC<PackPerformanceProps> = () => (
  <NotImplemented message="Check back for analytics, reports, and more!" />
);

/* istanbul ignore next */
const mapStateToProps = (_state: RootState) => ({});

/* istanbul ignore next */
const mapDispatchToProps = (_dispatch: AppDispatch) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackPerformance);
export default ConnectedComponent;
