import { v4 as uuidv4 } from "uuid";

import { WebexConnector } from "./WebexConnector";
import {
  IntegrationCreateSuccess,
  IntegrationCreateFailure,
  GoogleIntegrationCreateSuccess,
  GoogleIntegrationCreateFailure,
  WebexIntegrationCreateFailure,
  WebexIntegrationCreateSuccess,
  VivaIntegrationCreateSuccess,
  VivaIntegrationCreateFailure,
  SfIntegrationCreateFailure,
  SfIntegrationCreateSuccess,
} from "../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { type IntegrationTypes } from "../types";

export const getWebexConnector = () => {
  return new WebexConnector(true);
};

export const createIntegrationEvents = [
  IntegrationCreateSuccess,
  IntegrationCreateFailure,
  GoogleIntegrationCreateSuccess,
  GoogleIntegrationCreateFailure,
  WebexIntegrationCreateFailure,
  WebexIntegrationCreateSuccess,
  VivaIntegrationCreateSuccess,
  VivaIntegrationCreateFailure,
  SfIntegrationCreateSuccess,
  SfIntegrationCreateFailure,
];

export const generateNotificationId = (type: IntegrationTypes) => `integration_${type}_${uuidv4()}`;
