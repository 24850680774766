import { useCallback } from "react";
import { Checkbox, Divider, List } from "semantic-ui-react";
import type ModalSizes from "../../../enums/modalSizes";
import useToggle from "../../../hooks/useToggle";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

import "./changeMainContactConfirmationModal.scss";

export interface ChangeMainContactConfirmationModalProps {
  onCancel(): void;
  onContinue(unassignPreviousMainContactRoles: boolean): void;
  size?: ModalSizes;
  open: boolean;
}

const ChangeMainContactConfirmationModal = ({
  onCancel,
  onContinue,
  size,
  open,
}: ChangeMainContactConfirmationModalProps) => {
  const [unassignPreviousMainContactRoles, toggleunassignPreviousMainContactRoles] = useToggle(false);
  const onContinueCallback = useCallback(
    () => onContinue(unassignPreviousMainContactRoles),
    [onContinue, unassignPreviousMainContactRoles],
  );

  return (
    <ActionConfirmationModal
      open={open}
      onCancel={onCancel}
      onContinue={onContinueCallback}
      title="Change Main Contact?"
      size={size}
      withWarningLabel={false}
      confirmLabel="Continue"
    >
      <List.Header>
        <b>Changing the main contact to this user will:</b>
      </List.Header>
      <List bulleted className="contact-modal-list">
        <List.Item>
          Grant the user all rights and permissions to make changes to your BrainStorm account and platform.
        </List.Item>
        <List.Item>
          Authorize the user to accept Terms and Conditions or other agreements with BrainStorm for your organization.
        </List.Item>
        <List.Item>Allow the user to make additional platform purchases for your organization.</List.Item>
        <List.Item>
          Designate the user to be the primary point-of-contact for account notices and other communications from
          BrainStorm.
        </List.Item>
      </List>

      <Divider />

      <Checkbox
        label="Unassign account admin role from the previous main contact"
        className="unassign-previous-main-contact"
        checked={unassignPreviousMainContactRoles}
        onClick={toggleunassignPreviousMainContactRoles}
      />
    </ActionConfirmationModal>
  );
};

export default ChangeMainContactConfirmationModal;
