import { isNil } from "lodash";
import videojs from "video.js";
import QhMenuButton from "./qhMenuButton";

/* istanbul ignore next */
const settingsMenuPlugin = function initSettingsMenu(options) {
  const player = this;
  const qhMenuButton = new QhMenuButton(player, options);
  player.controlBar.addChild(qhMenuButton, {});
};

const menu = {
  init() {
    const Plugin = videojs.getPlugin("plugin");
    const registeredPlugin = Plugin.getPlugin("settingsMenuPlugin");
    /* istanbul ignore next */
    if (isNil(registeredPlugin)) {
      Plugin.registerPlugin("settingsMenuPlugin", settingsMenuPlugin);
    }
  },
};

export default menu;
