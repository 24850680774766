import React, { type ReactNode } from "react";

import LabelWithInfoTooltip from "../../../../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";

import styles from "./expandableHeader.module.scss";
import { Accordion, Icon } from "semantic-ui-react";
import useToggle from "../../../../../../../hooks/useToggle";
import cn from "classnames";

export interface ExpandableHeaderProps {
  title: string;
  tooltip: { info: string; width: number };
  active?: boolean;
  children: ReactNode;
}

const ExpandableHeader = (props: ExpandableHeaderProps) => {
  const { title, tooltip, active, children } = props;
  const [isActive, toggle] = useToggle(active);

  return (
    <Accordion className={styles.root}>
      <Accordion.Title className={cn(styles.title, { [styles.active]: isActive })} active={isActive} onClick={toggle}>
        <LabelWithInfoTooltip
          labelClass={styles.title_label}
          label={title}
          width={tooltip.width}
          info={tooltip.info}
        />
        <Icon className={cn("fa-chevron-down", styles["title_arrow-icon"])} />
      </Accordion.Title>
      <Accordion.Content className={styles.content} active={isActive}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
};

export default ExpandableHeader;
