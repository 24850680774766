import SortOptions from "../../../../../../enums/SortOptions";
import { type SyntheticEvent, useCallback, useState } from "react";
import { type DropdownProps } from "semantic-ui-react";

type HookResult = [SortOptions, (e: SyntheticEvent, props: DropdownProps) => void];

interface Props {
  resetPacks(): void;
}

export function useSorting({ resetPacks }: Props): HookResult {
  const [sorting, setSorting] = useState<SortOptions>(SortOptions.CreatedDateDesc);

  const onChange = useCallback(
    (_: any, { value }: DropdownProps) => {
      resetPacks();
      setSorting(value as SortOptions);
    },
    [resetPacks, setSorting],
  );

  return [sorting, onChange];
}
