import React from "react";
import { Button } from "components/buttons/button/Button";
import PropTypes from "prop-types";

import "./imageUploaderModalButtons.scss";

function ImageUploaderModalButtons(props) {
  const { closeModal, handleLooksGood, canSubmit, isUploading } = props;

  return (
    <div className="image-uploader-modal-buttons">
      <Button className="close" onClick={closeModal} basic color="blue">
        Cancel
      </Button>
      <Button className="looks-good" onClick={handleLooksGood} disabled={!canSubmit} primary loading={isUploading}>
        Looks Good
      </Button>
    </div>
  );
}

ImageUploaderModalButtons.defaultProps = {
  canSubmit: true,
};

ImageUploaderModalButtons.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleLooksGood: PropTypes.func,
  canSubmit: PropTypes.bool,
  isUploading: PropTypes.bool,
};

export default ImageUploaderModalButtons;
