import { asAnswersMap } from "../../../../Common/Answer/Answers/AnswerProvider";
import { PictureAnswer } from "../../../../Common/Answer/Answers/PictureAnswer";
import { type Picture } from "../../../../Common/Question/Questions/PictureQuestion";
import { type IAnswer, type IAnswerBase } from "../../../../Common/models";
import { MultipleTextAnswer, SingleTextAnswer } from "../Answers";
import { type Answer, AssessmentAnswerType } from "../../../types/state";

/* istanbul ignore next */
export const AnswersMap = asAnswersMap({
  [AssessmentAnswerType.SingleText]: {
    Component: SingleTextAnswer,
    castAnswer: (answer: IAnswerBase) => answer as IAnswer<Answer>,
  },
  [AssessmentAnswerType.MultipleText]: {
    Component: MultipleTextAnswer,
    castAnswer: (answer: IAnswerBase) => answer as IAnswer<Answer>,
  },
  [AssessmentAnswerType.Picture]: {
    Component: PictureAnswer,
    castAnswer: (answer: IAnswerBase) => answer as IAnswer<Picture>,
  },
});
