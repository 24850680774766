import React from "react";

import { Tooltip } from "../../../../../components/common/tooltip";
import { type DropdownItem, MultiStateButton } from "../../../../../components/buttons/multiStateButton/MultiStateButton";
import { AddLabelsAssetButton } from "../../Buttons/AddLabelsAssetButton";

import styles from "./addTagsButton.module.scss";

export type TagEntityType = "Flows" | "assets" | "PDFs";

export interface AddTagsButtonProps {
  isDisabled: boolean;
  tagsOptions: DropdownItem[];
  entity: TagEntityType;
}

export const AddTagsButton = (props: AddTagsButtonProps) => {
  const { tagsOptions, isDisabled, entity } = props;

  const targetContent = (
    <MultiStateButton
      id="add-tags"
      className="pointing top right"
      isLinkStyle
      trigger={<AddLabelsAssetButton isDisabled={isDisabled} />}
      items={tagsOptions}
      simple
      disableDropdownIcon={isDisabled}
      disabled={isDisabled}
    />
  );

  return (
    <Tooltip
      className={styles.root}
      showAlways={isDisabled}
      inverted
      hideOnScroll
      position="left center"
      target={targetContent}
      content={`One or more selected ${entity} is from a Marketplace Pack and can’t be added in a bulk`}
    />
  );
};
