import objUtils from "../../../../../utils/objectUtils";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { type AccountBase, type PackName } from "../../../../../interfaces";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import { TagsEnumCamelPlural } from "../../../../../interfaces/TagsEnum";
import { type FiltersMap, mapFilterOption } from "../../../../../utils/filterUtils";
import { SurveyFiltersEnum } from "../../types/models";
import {
  setError as setOverviewError,
  setFilterOptions as setOverviewFilterOptions,
  setIsLoading as setOverviewIsLoading,
} from "../slices/surveyFiltersSlice";
import {
  setError,
  setFilterOptions,
  setIsLoading,
} from "../../../../People/ContentAssignments/state/slices/contentAssignmentsFiltersSlice";
import { batch } from "react-redux";
import _ from "lodash";
import PromiseStatuses from "../../../../../enums/promiseStatuses";

const defaultFiltersOptions = [SurveyFiltersEnum.Publishers, SurveyFiltersEnum.Tags, SurveyFiltersEnum.Packs];

export interface GetFilterOptionsProps {
  showPurchased?: boolean;
  filters?: SurveyFiltersEnum[];
}

interface BaseFilterOptionsProps {
  begin: () => void;
  success: (filterOptions: FiltersMap) => void;
  failure: (error: Error) => void;
}

export const getFilterOptions = async (
  props: BaseFilterOptionsProps & GetFilterOptionsProps,
  includePublishers: boolean = true,
) => {
  const { showPurchased, begin, success, failure } = props;
  const filters = !props.filters?.length ? defaultFiltersOptions : props.filters;

  let filterOptions: FiltersMap = {};

  const tagToFilterMap: { [key in TagsEnumCamelPlural]?: SurveyFiltersEnum } = {
    [TagsEnumCamelPlural.Label]: SurveyFiltersEnum.Labels,
    [TagsEnumCamelPlural.SoftwareApplication]: SurveyFiltersEnum.SoftwareApplications,
  };

  const readPublishersData = async () => {
    const publishers = await DataService.surveys.getPublishersFilterOptionsAsync();
    filterOptions = mapFilterOption(
      filterOptions,
      publishers.data,
      SurveyFiltersEnum.Publishers,
      (item: AccountBase) => ({
        text: item.name,
        value: item.id,
      }),
    );
    return filterOptions;
  };

  const readPacksData = async () => {
    const packs = await DataService.packs.getPacksFilterOptions("survey", showPurchased ?? true);
    filterOptions = mapFilterOption(filterOptions, packs.data, SurveyFiltersEnum.Packs, (item: PackName) => ({
      text: item.name,
      value: item.id,
    }));
    return filterOptions;
  };

  const readTagsData = async () => {
    const tags = await DataService.surveys.getSurveysTagsAsync(!!showPurchased);
    for (let [key, value] of objUtils.typedEntries<TagsEnumCamelPlural, string[]>(tags.data)) {
      if (tagToFilterMap[key]) {
        filterOptions = mapFilterOption(filterOptions, value, tagToFilterMap[key], (item: string) => ({
          text: item,
          value: item,
        }));
      }
    }
    return filterOptions;
  };

  const surveyFilterTypeToFilterHandler: { [key in SurveyFiltersEnum]?: () => Promise<FiltersMap> } = {
    [SurveyFiltersEnum.Packs]: readPacksData,
    [SurveyFiltersEnum.Tags]: readTagsData,
  };

  if (includePublishers) {
    surveyFilterTypeToFilterHandler[SurveyFiltersEnum.Publishers] = readPublishersData;
  }

  begin();

  const filtersHandlers: Promise<FiltersMap>[] = Object.values(_.pick(surveyFilterTypeToFilterHandler, filters)).map(
    (handler: () => Promise<FiltersMap>) => handler(),
  );

  const result = await Promise.allSettled(filtersHandlers);

  batch(() => {
    result
      .filter((item) => item.status === PromiseStatuses.rejected)
      .forEach((item) => failure(item.reason));

    if (result.some((item) => item.status === PromiseStatuses.fulfilled)) {
      success(filterOptions);
    }
  });
};

export const getOverviewFilterOptions = () => {
  return (dispatch: AppDispatch) =>
    getFilterOptions({
      showPurchased: true,
      filters: [SurveyFiltersEnum.Publishers, SurveyFiltersEnum.Packs, SurveyFiltersEnum.Tags],
      begin: () => {
        dispatch(setOverviewIsLoading(true));
      },
      success: (filterOptions: FiltersMap) => {
        batch(() => {
          dispatch(setOverviewFilterOptions(filterOptions));
          dispatch(setOverviewIsLoading(false));
        });
      },
      failure: (error: Error) => {
        batch(() => {
          dispatch(setOverviewError(error));
          dispatch(setOverviewIsLoading(false));
        });
      },
    });
};

export const getContentAssignmentSurveyFilterOptions = () => {
  return (dispatch: AppDispatch) =>
    getFilterOptions({
      showPurchased: true,
      filters: [SurveyFiltersEnum.Publishers, SurveyFiltersEnum.Tags, SurveyFiltersEnum.Packs],
      begin: () => {
        dispatch(setIsLoading(true));
      },
      success: (filterOptions: FiltersMap) => {
        batch(() => {
          dispatch(setFilterOptions(filterOptions));
          dispatch(setIsLoading(false));
        });
      },
      failure: (error: Error) => {
        batch(() => {
          dispatch(setError(error));
          dispatch(setIsLoading(false));
        });
      },
    });
};
