import { type Node, type Edge } from "reactflow";

import Video from "./Video/Video";
import StartOfTheFlow from "./StartOfTheFlow/StartOfTheFlow";
import Placeholder from "./Placeholder/Placeholder";
import Email from "./Email/Email";
import Event from "./Event/Event";
import Assessment from "./Assessment/Assessment";
import Survey from "./Survey/Survey";
import Deleted from "./DeletedNode/DeletedNode";
import FlowEnd from "./FlowEnd/FlowEnd";
import Pdf from "./Pdf/Pdf";
import Message from "./Message/Message";
import { type EntityType, type CardItem, type TriggersData, type FlowItemEssentials, type FlowEndInfo } from "../../types";
import { type BranchingQuestion } from "../../../../../../interfaces";

export type IReactFlowEntityType = EntityType | "StartOfTheFlow" | "Placeholder";
export type IReactFlowCardItem = (FlowItemBase & CardItem) | FlowItemPlaceholder | FlowItemStartOfTheFlow;

export type IFlowEdge = Edge<TriggersData>;

export interface IFlowNodeBase<T> extends Node<T> {
  type?: IReactFlowEntityType | string;
}

export type IFlowNode = IFlowNodeBase<IReactFlowCardItem>;
export type IFlowNodeWithEntity = IFlowNodeBase<FlowItemBase & CardItem>;

export interface IFlowNodeConnected {
  target: boolean;
  source: boolean;
}

export interface FlowItemBase extends FlowItemEssentials {
  id: string;
  hasEntity?: boolean;
  entityId?: string;
  elementType?: EntityType;
  connected: IFlowNodeConnected;
  connection?: boolean;
  canConnect?: boolean;
  questionsCount?: string;
  branchingQuestion?: BranchingQuestion;
  flowEndInfo?: FlowEndInfo;
  requiredPacks: string[];
  hasAccess?: boolean;
  bag?: { [key: string]: any };
}

export interface FlowItemPlaceholder extends Omit<FlowItemBase, "publisher"> {
  isActive: boolean;
}

export interface FlowItemStartOfTheFlow extends Omit<FlowItemBase, "publisher"> {
  className?: string;
}

type NodeTypeValue =
  | typeof Pdf
  | typeof Video
  | typeof Email
  | typeof Message
  | typeof Event
  | typeof Assessment
  | typeof Survey
  | typeof FlowEnd
  | typeof StartOfTheFlow
  | typeof Deleted
  | typeof Placeholder;

type NodeTypes = Partial<Record<IReactFlowEntityType, NodeTypeValue>>;

export const nodeTypes: NodeTypes = {
  Pdf: Pdf,
  Video: Video,
  Email: Email,
  Message: Message,
  Assessment: Assessment,
  Survey: Survey,
  ExternalEvent: Event,
  FlowEnd: FlowEnd,
  StartOfTheFlow: StartOfTheFlow,
  Deleted: Deleted,
  Placeholder: Placeholder,
};
