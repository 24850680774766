import { useSelector } from "react-redux";
import intersection from "lodash/intersection";
import { type ReactElement, useState } from "react";

import { type RootState } from "../../features/Application/globaltypes/redux";
import { type RolePermissions } from "../../enums";
import { PaywallModalTypes } from "./paywallModal/types";
import PaywallModal from "./paywallModal/PaywallModal";

export interface RestrictedByAddOnProps<T extends Array<any>> {
  permissions: RolePermissions[];
  addOnPermissions: RolePermissions[];
  action: (...args: T) => void;
  renderContent: (action: (...args: T) => void, hasAnyPermission: boolean) => ReactElement | undefined;
  modalType: PaywallModalTypes;
}

export const RestrictedByAddOn = <T extends Array<any>>({
  addOnPermissions,
  permissions,
  renderContent,
  action,
}: RestrictedByAddOnProps<T>) => {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);
  const [showPaywallModal, setShowPaywallModal] = useState<boolean>(false);
  const hasAnyAddOnPermission = intersection(userPermissions, addOnPermissions).length > 0;
  const hasAnyPermission = intersection(userPermissions, permissions).length > 0;

  const wrappedAction = (...args: T) => {
    if (!hasAnyAddOnPermission) {
      setShowPaywallModal(true);
    } else {
      action(...args);
    }
  };

  const paywallModalClosedHandler = () => {
    setShowPaywallModal(false);
  };

  return (
    <>
      {renderContent(wrappedAction, hasAnyPermission)}
      <PaywallModal
        modalType={PaywallModalTypes.UpgradeToCreateContent}
        show={showPaywallModal}
        onCancel={paywallModalClosedHandler}
      />
    </>
  );
};

export default RestrictedByAddOn;
