import { useEffect } from "react";

export interface ValidationThrottlerProps<T> {
  value: T;
  validateField?: (propName: string, value: T) => Promise<void> | void;
  propertyName: string;
  disableValidation?: boolean;
  children: React.ReactElement;
}

export const ValidationThrottler = <T>(props: ValidationThrottlerProps<T>) => {
  const { validateField, propertyName, value, disableValidation, children } = props;

  useEffect(() => {
    (async () => {
      if (!disableValidation) {
        await validateField?.(propertyName, value);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return children;
};
