import { stripoLayoutStyles } from "../../components/notifyStep/EmailEditor/stripoLayoutStyles";

export const DEFAULT_EMAIL_TEMPLATE = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="telephone=no" name="format-detection">
    <title></title>
    <style>${stripoLayoutStyles}</style>
</head>
<body>
<div>
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr>
            <td class="esd-email-paddings" style="padding-top: 0px" valign="top">
                <table class="es-header esd-footer-popover" cellspacing="0" cellpadding="0" align="center">
                    <tbody>
                    <tr>
                        <td class="esd-stripe" align="center">
                            <table class="es-header-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff"
                                   align="center">
                                <tbody>
                                <!-- Begin Template Section -->
                                [[TemplateContent]]
                                <!-- End Template Section -->
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>
</div>
</body>
</html>`;
