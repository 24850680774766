import { type IPdfAssignmentModelItem } from "../../../features/People/types";
import { type ColumnOption } from "../../../interfaces/columnOptions";
import { Title } from "../../listViewTemplates";
import { PublishedStatusTypes, RouteNames } from "../../../enums";
import { PublishedStatus } from "../../common/publishedStatus";

export interface GetColumnButtonHandlers {
  onRemove?: (id: number) => void;
  onEditPriority?: (id: number) => void;
}

export enum Columns {
  Title = "Title", // NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Added = "Added",
  Priority = "Priority level",
  Expires = "Expires",
  Modified = "Modified",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  status: "isDraft",
  publisher: "publisher",
  title: "title",
  expires: "expires",
  "priority level": "priorityLevelId",
  added: "dateCreated",
  modified: "dateModified",
};

export const getColumnOptions = (deepLink?: boolean): ColumnOption<IPdfAssignmentModelItem>[] => [
  {
    render: (item) =>
      deepLink && !item.inherited ? (
        <Title url={`/${RouteNames.contentPdfs}/${item.id}`} title={item.title} className="normal-color" clamp={2} />
      ) : (
        <Title title={item.title} clamp={2} />
      ),
    disabled: (item) => item.inherited,
    name: Columns.Title,
    width: 4,
    isSortable: true,
  },
  {
    render: (item) => item.publisher,
    disabled: (item) => item.inherited,
    name: Columns.Publisher,
    width: 3,
  },
  {
    render: (item) => (
      <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />
    ),
    name: Columns.Status,
    width: 6,
    disabled: (item) => item.inherited,
  },
];
