import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { buildFetchAction } from "../../../../Application/actions/actionsBuilder";

export const savePack = "SAVE_PACK";
export const clearPack = "CLEAR_PACK";

export const getPackBegin = "GET_PACK_BEGIN";
export const getPackSuccess = "GET_PACK_SUCCESS";
export const getPackFailure = "GET_PACK_FAILURE";

export const getPackContentBegin = "GET_PACK_CONTENT_BEGIN";
export const getPackContentSuccess = "GET_PACK_CONTENT_SUCCESS";
export const getPackContentFailure = "GET_PACK_CONTENT_FAILURE";

export const getPdfsDetailsBegin = "GET_PDFS_DETAILS_BEGIN";
export const getPdfsDetailsSuccess = "GET_PDFS_DETAILS_SUCCESS";
export const getPdfsDetailsFailure = "GET_PDFS_DETAILS_FAILURE";

export const getAssetsDetailsBegin = "GET_ASSETS_DETAILS_BEGIN";
export const getAssetsDetailsSuccess = "GET_ASSETS_DETAILS_SUCCESS";
export const getAssetsDetailsFailure = "GET_ASSETS_DETAILS_FAILURE";

export const getFlowsDetailsBegin = "GET_FLOWS_DETAILS_BEGIN";
export const getFlowsDetailsSuccess = "GET_FLOWS_DETAILS_SUCCESS";
export const getFlowsDetailsFailure = "GET_FLOWS_DETAILS_FAILURE";

export const getSurveysDetailsBegin = "GET_SURVEY_DETAILS_BEGIN";
export const getSurveysDetailsSuccess = "GET_SURVEY_DETAILS_SUCCESS";
export const getSurveysDetailsFailure = "GET_SURVEY_DETAILS_FAILURE";

export const getAssessmentsDetailsBegin = "GET_ASSESSMENTS_DETAILS_BEGIN";
export const getAssessmentsDetailsSuccess = "GET_ASSESSMENTS_DETAILS_SUCCESS";
export const getAssessmentsDetailsFailure = "GET_ASSESSMENTS_DETAILS_FAILURE";

export const getEventsDetailsBegin = "GET_EVENTS_DETAILS_BEGIN";
export const getEventsDetailsSuccess = "GET_EVENTS_DETAILS_SUCCESS";
export const getEventsDetailsFailure = "GET_EVENTS_DETAILS_FAILURE";

export const getEmailsDetailsBegin = "GET_EMAILS_DETAILS_BEGIN";
export const getEmailsDetailsSuccess = "GET_EMAILS_DETAILS_SUCCESS";
export const getEmailsDetailsFailure = "GET_EMAIS_DETAILS_FAILURE";

export const getMessagesDetailsBegin = "GET_MESSAGES_DETAILS_BEGIN";
export const getMessagesDetailsSuccess = "GET_MESSAGES_DETAILS_SUCCESS";
export const getMessagesDetailsFailure = "GET_MESSAGES_DETAILS_FAILURE";

export const setPack = "SET_PACK";

export const addItemToPack = "ADD_ITEM_TO_PACK";
export const removeItemFromPack = "REMOVE_ITEM_FROM_PACK";

export const updatePackDisplayItemsCount = "UPDATE_PACK_DISPLAY_ITEMS_COUNT";

export const getPackUserLicensesBegin = "GET_PACK_USER_LICENSE_BEGIN";
export const getPackUserLicensesSuccess = "GET_PACK_USER_LICENSE_SUCCESS";
export const getPackUserLicensesFailure = "GET_PACK_USER_LICENSE_FAILURE";

export const fetchUsersAvailableForLicensing = buildFetchAction(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.PackUsersAvailableForLicensing,
);

export const addSelectedCards = "ADD_SELECTED_CARDS";
export const removeSelectedCard = "REMOVE_SELECTED_CARD";
export const clearSelectedCards = "CLEAR_SELECTED_CARDS";

export const getPackIsPrivateBegin = "GET_PACK_IS_PRIVATE_BEGIN";
export const getPackIsPrivateSuccess = "GET_PACK_IS_PRIVATE_SUCCESS";
export const getPackIsPrivateFailure = "GET_PACK_IS_PRIVATE_FAILURE";
