import { connect } from "react-redux";
import { type GroupPacksRequest } from "../../../../../Licensing/Packs/types/requests";
import { fetchGroupPacksGrid } from "../../../../../Licensing/Packs/state/thunks/groupPacksGridThunk";
import { resetGroupPacksGrid } from "../../../../../Licensing/Packs/state/slices/groupPacksGridSlice";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import GroupPacks from "../GroupPacks";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    packsGrid: state.people.editGroup.groupPacks.packsGrid,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  packsActions: {
    resetPacksGrid: () => dispatch(resetGroupPacksGrid()),
    fetchPacksGrid: (requestData: GroupPacksRequest) => dispatch(fetchGroupPacksGrid(requestData)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPacks);
