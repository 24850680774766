import React from "react";
import { useBlocker } from "react-router-dom";

import Modal from "../modal/Modal";
import PreventTransitionPromptActionButtons from "./PreventTransitionPromptActionButtons";
import ModalSizes from "../../enums/modalSizes";

export interface PreventTransitionPromptProps {
  when: boolean;
  title: string;
  message: string;
}
const PreventTransitionPrompt = (props: PreventTransitionPromptProps) => {
  const { when, title, message } = props;
  const blocker = useBlocker(when);

  const handleCancel = () => blocker.reset?.();
  const handleExit = () => blocker.proceed?.();

  const renderModalActions = () => (
    <PreventTransitionPromptActionButtons handleCancel={handleCancel} handleExit={handleExit} isUploading={false} />
  );

  return (
    <Modal
      title={title}
      open={blocker.state === "blocked"}
      size={ModalSizes.tiny}
      renderModalActions={renderModalActions}
      onCancel={handleCancel}
    >
      {message}
    </Modal>
  );
};

export default PreventTransitionPrompt;
