import { partial } from "lodash";
import React, { type FC } from "react";

import { type AddPeopleToContentTypes, Strings } from "../../../enums";
import { EllipsisPopup, type EllipsisPopupOption } from "../../buttons/ellipsisPopup/EllipsisPopup";
import { getAddContentEllipsisPopupOptions } from "../addContentButtonEllipsisOptions/AddContentButtonEllipsisOptions";

export interface Props {
  disabled: boolean;
  user: any;
  goToEditUser: (id: number) => void;
  onAddToContent: (id: number, contentType: AddPeopleToContentTypes) => void;
  onAddToGroup: (id: number) => void;
  onAddToRole: (id: number) => void;
  onDeleteUser: (id: number) => void;
}

const UserEllipsisPopupButton: FC<Props> = ({
  user,
  goToEditUser,
  onAddToContent,
  onAddToGroup,
  onAddToRole,
  onDeleteUser,
  disabled,
}) => {
  const contentOptions: EllipsisPopupOption[] = [
    {
      label: "Edit",
      iconName: "fa-edit",
      onClick: () => goToEditUser(user.id),
    },
    {
      label: Strings.modalTitles.addContent,
      iconName: "far fa-plus-circle",
      options: getAddContentEllipsisPopupOptions(partial(onAddToContent, user.id)),
    },
    {
      label: "Add To Group",
      iconName: "far fa-plus-circle",
      onClick: () => onAddToGroup(user.id),
    },
    {
      label: "Add To Role",
      iconName: "far fa-plus-circle",
      onClick: () => onAddToRole(user.id),
    },
    {
      label: "Delete",
      iconName: "fa-trash-alt",
      onClick: () => onDeleteUser(user.id),
    },
  ];

  return <EllipsisPopup options={contentOptions} disabled={disabled} />;
};

export default UserEllipsisPopupButton;
