import { type FC, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { batch, connect, type ConnectedProps } from "react-redux";

import { SearchInput } from "../../../../../../../components";
import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { Columns, ColumnToParamMap, getColumnOptions } from "./getColumnOptions";
import { fetchPdfUsers } from "../../../../state/thunks/pdfUsersThunk";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { GET_PDF_USERS_DEFAULT_PARAMS } from "../../../../types/constants";
import { resetSelectedItems } from "../../../../state/slices/pdfUsersSlice";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import { bindAction } from "../../../../../../../interfaces";
import {
  resetSearch as resetSearchAction,
  setSearch as setSearchAction,
} from "../../../../../PeopleAssignments/state/actions/userAssignmentActions";

import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";
import Restricted from "../../../../../../Application/Restricted";
import UsersListContainer from "./UsersListContainer/UsersListContainer";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface UsersOverviewPropsBase {
  pdfId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = ({ pdfId, customHeaderContent, fetchUsers, onUnmount }) => {
  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder="Search for Users..." onChange={onSearchChange} defaultValue={search} />
  );

  useEffect(
    () => () => onUnmount(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [search, setSearch] = useState<string>("");
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = async (searchTerm: string) => {
    setSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  const getPdfUsers = (
    skip: number = GET_PDF_USERS_DEFAULT_PARAMS.skip,
    top: number = GET_PDF_USERS_DEFAULT_PARAMS.top,
    orderBy: string = GET_PDF_USERS_DEFAULT_PARAMS.orderBy.toLowerCase(),
    sortingDirection: SortingDirection = GET_PDF_USERS_DEFAULT_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<Columns>];
    fetchUsers({ entityId: pdfId, skip, top, sortingColumn, sortingDirection, filter: appliedFilter, term: search });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.AssetsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <>
          <UsersListContainer
            defaultSortingColumnName={Columns.Name}
            sortingDirection={SortingDirection.Ascending}
            columnOptions={getColumnOptions()}
            applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
            resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
            fetchData={getPdfUsers}
            renderSearch={renderSearchInput}
            customHeaderContent={customHeaderContent}
            isSelectDisabled={() => true}
            setReloadListItems={createReloadListItems}
            noResultsContent={<ContentUsersNoResults filtered={!!search} />}
          />
        </>
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  search: state.library.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindAction(fetchPdfUsers, dispatch),
  setSearch: bindAction(setSearchAction, dispatch),
  onUnmount: () => {
    batch(() => {
      dispatch(resetSearchAction());
      dispatch(resetSelectedItems());
    });
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UsersOverview);
export default ConnectedComponent;
