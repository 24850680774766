import { type FC, useCallback, useMemo } from "react";
import { Dimmer } from "semantic-ui-react";
import classNames from "classnames";

import { CommunicationChannels, type TemplateTypes } from "enums";
import NotifyByTeams from "../NotifyByTeams/NotifyByTeams";
import { EmailEditor } from "../EmailEditor";
import { type StripoInitOpts } from "lib/stripo";
import { type IEmailEditorController } from "../EmailEditor/emailEditorController";
import { type EmailNotificationFormProps } from "../NotifySettingsForm/EmailNotificationForm";

import styles from "./editNotifyMessage.module.scss";

export interface EditNotifyMessageProps {
  shouldNotify: boolean;
  isDisabled: boolean;
  communicationChannel: CommunicationChannels;
  emailEditorOptions: StripoInitOpts;
  onLoaded(controller: IEmailEditorController): void;
  emailForm: EmailNotificationFormProps;
  templateType: TemplateTypes;
  onValidChange?(isValid: boolean): void;
  onDirtyChange?: (isDirty: boolean) => void;
}

export const EditNotifyMessage: FC<EditNotifyMessageProps> = ({
  shouldNotify,
  isDisabled,
  communicationChannel,
  emailEditorOptions,
  onLoaded,
  emailForm,
  templateType,
  onValidChange,
  onDirtyChange,
}) => {
  const handleLoad = useCallback(
    (controller: IEmailEditorController) => {
      onLoaded(controller);
    },
    [onLoaded],
  );

  const renderEmailEditor = useMemo(() => {
    return (
      emailEditorOptions &&
      emailForm && (
        <EmailEditor opts={emailEditorOptions} onLoaded={handleLoad} form={emailForm} onDirtyChange={onDirtyChange} />
      )
    );
  }, [emailEditorOptions, emailForm, handleLoad, onDirtyChange]);

  return (
    <div className="relative-position">
      <Dimmer active={isDisabled || !shouldNotify} inverted />
      <div
        className={classNames(
          {
            [styles.hidden]: communicationChannel !== CommunicationChannels.Email,
            active: communicationChannel === CommunicationChannels.Email,
          },
          "edit-notify-message",
        )}
      >
        {renderEmailEditor}
      </div>
      <div
        className={classNames(
          {
            [styles.hidden]: communicationChannel !== CommunicationChannels.MsTeams,
            active: communicationChannel === CommunicationChannels.MsTeams,
          },
          "edit-notify-message",
        )}
      >
        <NotifyByTeams onValidChange={onValidChange} onIsDirtyChange={onDirtyChange} templateType={templateType} />
      </div>
    </div>
  );
};
