import { combineReducers } from "@reduxjs/toolkit";
import csvImportPreviewSlice from "./csvImportPreviewSlice";
import uploadCsvFileColumnsSlice from "./uploadCsvFileColumnsSlice";
import uploadCsvFileSlice from "./uploadCsvFileSlice";

const reducer = combineReducers({
  uploadStatus: uploadCsvFileSlice,
  csvColumns: uploadCsvFileColumnsSlice,
  preview: csvImportPreviewSlice,
});

export default reducer;
