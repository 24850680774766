import { Icon, Popup, Radio } from "semantic-ui-react";

import { type ClosedCaptions } from "../../../types/models";
import { type ColumnOption } from "../../../../../../interfaces/columnOptions";
import { TextTruncate } from "../../../../../../components";
import { Tooltip } from "../../../../../../components/common/tooltip";
import utils from "../../../../../../components/common/video/plugins/settingsMenu/utils/languageUtils";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { defaultInfo, getUploadType } from "../helpers";
import RemoveClosedCaptionLinkButton from "../RemoveClosedCaptionLinkButton/RemoveClosedCaptionLinkButton";
import { TextAlign } from "../../../../../../enums/textAlign";

export enum ClosedCaptionsTabColumns {
  FileName = "File Name",
  Default = "Default",
  Language = "Language",
  UploadType = "Upload Type",
  Added = "created",
  Modified = "last modified",
}

export interface GetColumnOptionsParams {
  handleDeleteClick: (id: number) => () => void;
  handleSetDefaultClosedCaption: (id: number) => () => void;
  selectedItems: ClosedCaptions[];
  isReadOnly?: boolean;
}

const renderIcon = () => (
  <Popup
    hideOnScroll
    inverted
    size="small"
    position="top center"
    content={defaultInfo}
    style={{ maxWidth: `${24.188}rem` }}
    trigger={<Icon link className="info circle" />}
  />
);

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<ClosedCaptions>[] => [
  {
    name: ClosedCaptionsTabColumns.Default,
    renderIcon,
    className: "default-header",
    collapsing: true,
    textAlign: TextAlign.Center,
    render: (item) => (
      <div className="closed-captions-list-content--radio-field">
        <Radio
          radio
          key={item.id}
          onChange={params.handleSetDefaultClosedCaption(item.id)}
          checked={item.isDefault}
          disabled={params.isReadOnly || item.isAutoTranslated}
        />
      </div>
    ),
  },
  {
    name: ClosedCaptionsTabColumns.FileName,
    width: 4,
    render: (item) => <Tooltip target={<TextTruncate>{item.fileName}</TextTruncate>} content={item.fileName} />,
  },
  {
    name: ClosedCaptionsTabColumns.Language,
    width: 2,
    render: (item) => (
      <Tooltip
        target={<TextTruncate>{utils.getLanguageByCode(item.isoCode)?.name}</TextTruncate>}
        content={utils.getLanguageByCode(item.isoCode)?.name}
      />
    ),
  },
  {
    name: ClosedCaptionsTabColumns.UploadType,
    width: 2,
    render: (item) => getUploadType(item.isAutoTranslated),
  },
  {
    name: ClosedCaptionsTabColumns.Added,
    width: 3,
    render: (item) => dateTimeUtils.formatDate(item.dateCreated),
  },
  {
    name: ClosedCaptionsTabColumns.Modified,
    width: 3,
    render: (item) => dateTimeUtils.formatDate(item.dateModified),
  },
  {
    name: "",
    width: 1,
    isSortable: false,
    render: (item, isChecked) => (
      <RemoveClosedCaptionLinkButton
        isDisabled={params.isReadOnly || item.isDefault || isChecked}
        showTooltip={item.isDefault}
        onClick={params.handleDeleteClick(item.id)}
      />
    ),
  },
];
