export const saveThreatDefenceSendingProfile = "SAVE_THREAT_DEFENCE_SENDING_PROFILE";
export const saveThreatDefenceDirectSendSendingProfileVerification = "SAVE_THREAT_DEFENCE_SENDING_PROFILE_VERIFICATION";
export const clearThreatDefenceSendingProfile = "CLEAR_THREAT_DEFENCE_SENDING_PROFILE";

export const getThreatDefenceSendingProfileBegin = "GET_THREAT_DEFENCE_SENDING_PROFILE_BEGIN";
export const getThreatDefenceSendingProfileSuccess = "GET_THREAT_DEFENCE_SENDING_PROFILE_SUCCESS";
export const getThreatDefenceSendingProfileFailure = "GET_THREAT_DEFENCE_SENDING_PROFILE_FAILURE";

export const setSendingProfile = "SET_SENDING_PROFILE";
export const setSendingProfileIsLoaded = "SET_SENDING_PROFILE_ISLOADED";
