import cuid from "cuid";

import * as notificationActionTypes from "./actionTypes";

const initialState = {
  pending: [],
};

export function notificationsReducer(currentState, action) {
  const state = currentState || initialState;
  switch (action.type) {
    case notificationActionTypes.NOTIFICATION_RECEIVED:
      return {
        ...state,
        pending: [
          ...state.pending,
          {
            id: cuid(),
            type: action.payload.type,
            title: action.payload.title,
            message: action.payload.message,
          },
        ],
      };
    case notificationActionTypes.TRANSIENT_NOTIFICATION_RECEIVED:
      return {
        ...state,
        pending: [
          ...state.pending,
          {
            id: cuid(),
            title: action.payload.title,
            type: action.payload.type,
            message: action.payload.message,
            dismissInSeconds: action.payload.dismissInSeconds,
            createTime: action.payload.createTime,
          },
        ],
      };
    case notificationActionTypes.NOTIFICATION_DISMISS:
      return {
        ...state,
        pending: [...state.pending.filter((notification) => !action.payload.includes(notification.id))],
      };
    default:
      return state;
  }
}
