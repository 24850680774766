import { AlertTypes } from "../../enums";
import { type AddOperationParamsDistributedOp, type AddOperationParamsV1 } from "../../interfaces/backgroundAddOperationParams";
import {type AddOperationParamsV2, type DistributedOpUpdateParams, type UpdateParamsV2} from "../../interfaces/updateParams";
import type UpdateParams from "../../interfaces/updateParams";
import { type AppDispatch } from "../Application/globaltypes/redux";
import { sendTransientNotification } from "../Notifications/state/notificationsActions";
import { addOperationDistributedOp, addOperationV1 } from "./state/backgroundTasksActions";

export type AddOperationFunction = (params: AddOperationParamsV1) => void;

type SendTransientNotificationFunction = (message: { type: AlertTypes; message: string | React.ReactElement }) => void;

interface UpdateFeatureCommon {
  sendTransientNotification: SendTransientNotificationFunction;
}

export interface UpdateFeatureProps extends UpdateFeatureCommon {
  addOperation: AddOperationFunction;
}

export interface UpdateFeatureDistributedOpProps extends UpdateFeatureCommon {
  addOperation: (params: AddOperationParamsDistributedOp) => void;
}

export interface UpdateFeaturePropsV2 extends UpdateFeatureCommon {
  addOperation: (params: AddOperationParamsV2) => void;
}

const createTask = (id: string | number, title: string, indeterminate?: boolean, info?: string) => ({
  id: `${id}_${Date.now()}`,
  title,
  indeterminate: !!indeterminate,
  info,
});

const onCompletedInTimeHandler =
  (
    taskId: string,
    successTitle: string | undefined,
    successTransientMessage: string | React.ReactElement,
    failureTransientMessage: string | React.ReactElement,
    sendTransientNotification: SendTransientNotificationFunction,
    onCompleted?: (taskId: string) => void,
  ) =>
  (task: { isSuccessful: boolean }) => {
    const message = {
      title: task.isSuccessful ? successTitle : undefined,
      type: task.isSuccessful ? AlertTypes.success : AlertTypes.error,
      message: task.isSuccessful ? successTransientMessage : failureTransientMessage,
    };
    sendTransientNotification(message);
    onCompleted?.(taskId);
  };

const updateEntity = async (updateParams: UpdateParams, props: UpdateFeatureProps) => {
  const { addOperation, sendTransientNotification } = props;
  const {
    id,
    title,
    indeterminate,
    getMessageIds,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    onCompleted,
  } = updateParams;

  const task = createTask(id, title, indeterminate);
  const onCompletedInTime = onCompletedInTimeHandler(
    task.id,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    sendTransientNotification,
    onCompleted,
  );

  const params: AddOperationParamsV1 = { task, getMessageIds, onCompletedInTime };

  addOperation(params);
};

const updateEntityV2 = async (updateParams: UpdateParamsV2, props: UpdateFeaturePropsV2) => {
  const { addOperation, sendTransientNotification } = props;

  const {
    id,
    title,
    getMessageIdsV2,
    callApi,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    indeterminate,
    onCompleted,
  } = updateParams;

  const task = createTask(id, title, indeterminate);
  const onCompletedInTime = onCompletedInTimeHandler(
    task.id,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    sendTransientNotification,
    onCompleted,
  );

  const params: AddOperationParamsV2 = { task, getMessageIdsV2, callApi, onCompletedInTime };

  addOperation(params);
};

const updateEntityDistributedOp = async (
  updateParams: DistributedOpUpdateParams,
  props: UpdateFeatureDistributedOpProps,
) => {
  const { addOperation, sendTransientNotification } = props;
  const {
    id,
    title,
    info,
    indeterminate,
    getOperationProps,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    onCompleted,
  } = updateParams;

  const task = createTask(id, title, indeterminate, info);
  const onCompletedInTime = onCompletedInTimeHandler(
    task.id,
    successTitle,
    successTransientMessage,
    failureTransientMessage,
    sendTransientNotification,
    onCompleted,
  );

  const params: AddOperationParamsDistributedOp = { task, getOperationProps, onCompletedInTime };

  addOperation(params);
};

/* istanbul ignore next */
const tasks = {
  updateEntity: updateEntity,
  updateEntityV2: updateEntityV2,
  updateEntityDistributedOp: updateEntityDistributedOp,
  initializeBackgroundTask: async (updateParams: UpdateParams, dispatch: AppDispatch) => {
    const updateFeatureProps: UpdateFeatureProps = {
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
      addOperation: (addOperationParams: AddOperationParamsV1) => dispatch(addOperationV1(addOperationParams)),
    };
    await updateEntity(updateParams, updateFeatureProps);
  },
  initializeDistributedBackgroundTask: async (updateParams: DistributedOpUpdateParams, dispatch: AppDispatch) => {
    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };
    await updateEntityDistributedOp(updateParams, updateFeatureProps);
  },
};

export default tasks;
