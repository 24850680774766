import { open } from "./consentWindowService";
import integrationsDataService from "../../services/integrationsDataService";
import { IntegrationTypes } from "../types";
import { ContentSettingsEnum, type VivaIntegrationConfigs } from "../types/viva";
import type IConnector from "./IConnector";

export const getAuthRedirect = async (redirect: string) => {
  const { authRedirect, consentRedirect } = await integrationsDataService.authorizeVivaIntegration(redirect);

  return { authRedirect, consentRedirect };
};

export const getMsConsent = async (consentRedirect: string) => {
  return open({
    location: consentRedirect,
    title: "Microsoft Viva Sign In",
    parseResult: (params) => {
      const tenantId = params.get("tenant") as string;

      return {
        isSuccess: !!tenantId,
        error: params.get("error"),
        result: tenantId,
      };
    },
  });
};

export const authorize = async (authRedirect: string) => {
  return open({
    location: authRedirect,
    title: "Microsoft Viva Sign In",
    parseResult: (params) => {
      const code = params.get("code") as string;

      return {
        isSuccess: !!code,
        error: params.get("error"),
        result: code,
      };
    },
  });
};

export class VivaConnector implements IConnector<VivaIntegrationConfigs> {
  private _authenticationCode: string = "";
  private _tenantId: string = "";
  private _redirect: string | undefined = undefined;
  private readonly _isConnected: boolean;

  constructor(isConnected: boolean) {
    this._isConnected = isConnected;
  }

  public get info() {
    return {
      id: IntegrationTypes.Viva,
      connectorTitle: "Microsoft Viva Learning",
      type: IntegrationTypes.Viva,
      description: "Microsoft Viva Learning",
      thumbnailUrl: "/connectors/viva.png",
      isConnected: this._isConnected,
    };
  }

  public get configuration() {
    return {
      isActive: true,
      customContentSettings: {
        contentType: ContentSettingsEnum.AllContent,
      },
      packContentSettings: {
        contentType: ContentSettingsEnum.AllContent,
        packIds: [],
      },
    };
  }

  readonly shouldLogin = () => true;

  readonly shouldNotify = () => false;

  readonly login = async (_: VivaIntegrationConfigs) => {
    const redirect = `${window.location.origin}/viva-authorize`;

    const { authRedirect, consentRedirect } = await getAuthRedirect(redirect);
    const consentResult = await getMsConsent(consentRedirect);

    if (!consentResult.isSuccess) {
      return consentResult;
    }

    const authResult = await authorize(authRedirect);

    if (!authResult.isSuccess) {
      return authResult;
    }

    this._authenticationCode = authResult.result;
    this._redirect = redirect;
    this._tenantId = consentResult.result;

    return authResult;
  };

  readonly save = async (config: VivaIntegrationConfigs, moboId?: string) => {
    await integrationsDataService.createVivaIntegration(
      {
        ...config,
        redirect: this._redirect,
        authenticationCode: this._authenticationCode,
        tenantId: this._tenantId,
      },
      moboId,
    );
  };
}
