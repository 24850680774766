import cn from "classnames";
import { Dropdown, Icon, Placeholder, Ref } from "semantic-ui-react";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { Tooltip } from "../../common/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import environmentConfig from "../../../configuration/environmentConfig";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
import { type MutableRefObject, type SyntheticEvent, useEffect, useMemo, useState } from "react";
import placeholderImage from "../../../images/gear-solid.svg";
import { RouteNames } from "../../../enums";
import * as editAccountActions from "../../../features/Accounts/EditAccount/state/editAccountActions";

import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";

import { useFeatureFlag } from "../../../hooks/useFeatureFlag";
import { FeatureFlags } from "../../../featureFlags";

import "./accountSettingsMenu.scss";

export interface AccountSettingsMenuProps extends PropsFromRedux {
  collapsed: boolean;
  accountId: number;
  disableTooltip: boolean;
  iconRef: MutableRefObject<HTMLDivElement | null>;
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  onSignout: () => void;
}

export const AccountSettingsMenu: React.FC<AccountSettingsMenuProps> = ({
  collapsed,
  accountId,
  disableTooltip,
  iconRef,
  dropdownRef,
  onSignout,
  fetchAccountInfo,
  accountName,
  accountLogo,
  accountError,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    fetchAccountInfo(accountId, true);
  }, [fetchAccountInfo, accountId]);

  const accountImage = useMemo(() => {
    if (accountError !== null) return placeholderImage;
    if (accountLogo === "") return undefined;
    return accountLogo ?? placeholderImage;
  }, [accountLogo, accountError]);

  const smtpDirectSend = useFeatureFlag(FeatureFlags.SmtpDirectSend);

  return (
    <div
      className={cn("account-settings-menu", {
        "account-menu-dropdown": !collapsed,
      })}
    >
      <Dropdown
        trigger={
          <div
            className={cn("account-menu", {
              "account-menu-collapsed": collapsed,
            })}
          >
            {(!imageLoaded || !accountImage) && (
              <Placeholder style={{ height: 32, width: 32 }}>
                <Placeholder.Image />
              </Placeholder>
            )}
            <img
              className={cn({
                "image-loading": !imageLoaded || !accountImage,
                "placeholder-logo": !accountLogo,
              })}
              src={accountImage}
              alt="Company Logo"
              onLoad={() => setImageLoaded(true)}
            />

            <div>
              <Tooltip
                styles={{ "margin-left": "29px" }}
                position="right center"
                target={
                  <TextTruncate className={"menu-account-name"}>
                    {accountName !== "" ? accountName : "Account Settings"}
                  </TextTruncate>
                }
                content={accountName}
                disabled={disableTooltip}
              />
              <Ref innerRef={iconRef}>
                <Icon className={cn({ "fa-chevron-right": collapsed, "fa-chevron-down": !collapsed })} size="tiny" />
              </Ref>
            </div>
          </div>
        }
        pointing={collapsed ? "left" : "top"}
        className="homeMenu"
        icon={null}
      >
        <Ref innerRef={dropdownRef}>
          <Dropdown.Menu id="account-menu-dropdown-list">
            {/* Prevents the menu from closing if the user clicks on non-link areas. */}
            <Dropdown.Header id="header" onClick={(e: SyntheticEvent) => e.stopPropagation()}>
              ACCOUNT SETTINGS
            </Dropdown.Header>
            <Dropdown.Divider id="divider" onClick={(e: SyntheticEvent) => e.stopPropagation()} />
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Configuration
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/packs`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Packs
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/addons`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Add-ons
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/contacts`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Contacts
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/legal`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Legal
            </Dropdown.Item>
            <Dropdown.Divider id="divider" onClick={(e: SyntheticEvent) => e.stopPropagation()} />
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/integrations`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              Integrations
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/sso`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              SSO
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: `/${RouteNames.accountSettingsManagement}/api`, hash: `#moboId=${accountId}` }}
              id="accountMenuItem"
            >
              API
            </Dropdown.Item>
            {smtpDirectSend && (
              <Dropdown.Item
                as={Link}
                to={{
                  pathname: `/${RouteNames.accountSettingsManagement}/email-settings`,
                  hash: `#moboId=${accountId}`,
                }}
                id="accountMenuItem"
              >
                Email Settings
              </Dropdown.Item>
            )}
            <Dropdown.Divider id="divider" onClick={(e: SyntheticEvent) => e.stopPropagation()} />
            <Dropdown.Item as={Link} to={environmentConfig.eupUrl} target="_blank" id="accountMenuItemIcons">
              <FontAwesomeIcon icon={icon({ name: "arrow-up-right-from-square", style: "solid" })} />
              End User Portal
            </Dropdown.Item>
            <Dropdown.Item id="accountMenuItemIcons" onClick={onSignout}>
              <FontAwesomeIcon icon={icon({ name: "right-from-bracket", style: "solid" })} size="lg" />
              Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Ref>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountName: state.accounts.accountInformation.accountInfo.accountName,
  accountLogo: state.accounts.accountInformation.accountInfo.accountLogo,
  accountError: state.accounts.edit.accountInfo.error,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAccountInfo: bindActionCreators(editAccountActions.fetchAccountInfo, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AccountSettingsMenu);
export default ConnectedComponent;
