import { SortingDirection } from "../enums";

const utils = {
  formatOrderColumn: (sortingColumnName: string): string =>
    ({
      title: `Title`,
      status: `IsDraft`,
      added: `Id`,
      modified: `DateModified`,
    }[sortingColumnName] || `${sortingColumnName}`),

  convertToSortingDirection(sortOrder: string): SortingDirection {
    return (Object as any).values(SortingDirection).includes(sortOrder)
      ? (sortOrder as SortingDirection)
      : SortingDirection.Ascending;
  },
};

export default utils;
