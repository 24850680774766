import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import RolePermissions from "../../enums/rolePermissions";
import { RouteNames } from "../../enums";
import {
  AllAccountsOverview,
  CreateIdentityProvider,
  CreateIntegration,
  EditAccount,
  EditIdentityProvider,
} from "../../features/Accounts";
import EditMsGraph from "../../features/Accounts/Integrations/EditIntegration/EditMsGraph/EditMsGraph";
import EditGoogle from "../../features/Accounts/Integrations/EditIntegration/EditGoogle/EditGoogle";
import EditWebex from "../../features/Accounts/Integrations/EditIntegration/EditWebex/EditWebex";
import EditViva from "../../features/Accounts/Integrations/EditIntegration/EditViva/EditViva";
import { IntegrationTypes } from "../../features/Accounts/Integrations/types";
import EditSf from "../../features/Accounts/Integrations/EditIntegration/SuccessFactors/EditSf";
import { ProtectedRoute } from "../../components/restrictedRoute/ProtectedRoute";
import TemplatesOverview from "../../features/People/GroupTemplate/Overview/TemplatesOverview";
import CreateGroupTemplate from "../../features/People/GroupTemplate/Create/CreateGroupTemplate";
import EditTemplate from "../../features/People/GroupTemplate/Edit/EditTemplate";
import { AccountPrecreation } from "features/Accounts/CreateAccount/AccountPrecreation/AccountPrecreation";

class AccountsContent extends Component {
  render() {
    const { isMobo } = this.props;
    return (
      <div className="route-content-container">
        <Routes>
          <Route
            path="home/sso/create"
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <CreateIdentityProvider />
              </ProtectedRoute>
            }
          />
          <Route
            path="home/sso/:id/*"
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsView]}>
                <EditIdentityProvider />
              </ProtectedRoute>
            }
          />
          <Route
            path={`home/integrations/${IntegrationTypes.Webex}/*`}
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <EditWebex />
              </ProtectedRoute>
            }
          />
          <Route
            path={`home/integrations/${IntegrationTypes.SuccessFactors}/*`}
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <EditSf />
              </ProtectedRoute>
            }
          />
          <Route
            path={`home/integrations/${IntegrationTypes.Viva}/*`}
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <EditViva />
              </ProtectedRoute>
            }
          />
          <Route
            path={`home/integrations/${IntegrationTypes.Google}/*`}
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <EditGoogle />
              </ProtectedRoute>
            }
          />
          <Route
            path={`home/integrations/${IntegrationTypes.MsGraph}/*`}
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <EditMsGraph />
              </ProtectedRoute>
            }
          />
          <Route
            path="home/integrations/create"
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                <CreateIntegration />
              </ProtectedRoute>
            }
          />
          <Route
            path="home/*"
            element={
              <ProtectedRoute permissions={[RolePermissions.AccountsSettingsView, RolePermissions.AddonsView]}>
                <EditAccount key="home" />
              </ProtectedRoute>
            }
          />
          <Route path={RouteNames.linkedAccounts}>
            <Route index element={<AllAccountsOverview />} />
            {!isMobo && (
              <Route
                path="create"
                element={
                  <ProtectedRoute
                    permissions={[RolePermissions.AccountsSettingsCreate, RolePermissions.TemplatesManage]}
                  >
                    <AccountPrecreation />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path=":moboId/sso/create"
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <CreateIdentityProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path=":moboId/sso/:id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsView]}>
                  <EditIdentityProvider />
                </ProtectedRoute>
              }
            />
            <Route
              path={`:moboId/${RouteNames.subAccountMsGraphIntegrationUrl}/*`}
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <EditMsGraph />
                </ProtectedRoute>
              }
            />
            <Route
              path={`:moboId/${RouteNames.subAccountGoogleIntegrationUrl}/*`}
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <EditGoogle />
                </ProtectedRoute>
              }
            />
            <Route
              path={`:moboId/${RouteNames.subAccountVivaIntegrationUrl}/*`}
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <EditViva />
                </ProtectedRoute>
              }
            />
            <Route
              path={`:moboId/${RouteNames.subAccountSfIntegrationUrl}/*`}
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <EditSf />
                </ProtectedRoute>
              }
            />
            <Route
              path={`:moboId/${RouteNames.subAccountWebexIntegrationUrl}/*`}
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <EditWebex />
                </ProtectedRoute>
              }
            />
            <Route
              path=":moboId/integrations/create"
              element={
                <ProtectedRoute permissions={[RolePermissions.AccountsSettingsManage]}>
                  <CreateIntegration />
                </ProtectedRoute>
              }
            />
            <Route path=":moboId/*" element={<EditAccount key="child" />} />
          </Route>
          <Route path={RouteNames.templates}>
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.TemplatesView]}>
                  <TemplatesOverview onlyOwn title="Templates" />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-template"
              element={
                <ProtectedRoute permissions={[RolePermissions.TemplatesManage]}>
                  <CreateGroupTemplate />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.TemplatesView]}>
                  <EditTemplate />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </div>
    );
  }
}

AccountsContent.propTypes = {
  isMobo: PropTypes.bool.isRequired,
};

export default AccountsContent;
