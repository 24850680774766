import React from "react";
import PropTypes from "prop-types";
import ThumbnailUploaderModal from "../../imageUploader/thumbnailUploaderModal/ThumbnailUploaderModal";
import BasePreview from "../basePreview/basePreview";

import "./thumbnailPreview.scss";

function ThumbnailPreview(props) {
  const {
    value,
    className,
    label,
    disabled,
    isProcessing,
    onCroppedThumbnailClick,
    assetManifests,
    sasLinkProviderAsync,
    videosActions,
    entityId,
    generateThumbnail,
    generateThumbnailFromPosition,
    textTracks,
    areTextTracksLoading,
  } = props;

  const renderPreview = (onClick) => {
    return (
      <BasePreview
        value={value}
        className={className}
        label={label}
        disabled={disabled}
        isProcessing={isProcessing}
        onClick={onClick}
        onCroppedThumbnailClick={onCroppedThumbnailClick}
        placeholder={"No Thumbnail"}
      />
    );
  };

  return (
    <ThumbnailUploaderModal
      trigger={renderPreview}
      assetManifests={assetManifests}
      sasLinkProviderAsync={sasLinkProviderAsync}
      videosActions={videosActions}
      entityId={entityId}
      generateThumbnail={generateThumbnail}
      generateThumbnailFromPosition={generateThumbnailFromPosition}
      textTracks={textTracks}
      areTextTracksLoading={areTextTracksLoading}
    />
  );
}

ThumbnailPreview.defaultProps = {
  disabled: false,
  isImagePreview: false,
};

ThumbnailPreview.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  entityId: PropTypes.number,
  generateThumbnail: PropTypes.func,
  generateThumbnailFromPosition: PropTypes.func,
  className: PropTypes.string,
  videosActions: PropTypes.object,
  sasLinkProviderAsync: PropTypes.func,
  onCroppedThumbnailClick: PropTypes.func,
  assetManifests: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  isProcessing: PropTypes.bool,
  areTextTracksLoading: PropTypes.bool,
  textTracks: PropTypes.array,
};

export default ThumbnailPreview;
