import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "@reduxjs/toolkit";

import { type RootState } from "../../../Application/globaltypes/redux";
import * as reportingActions from "../../state/packs/packActions";
import { selectOwnerPacksLicensingState } from "../../state/packs/packSlice";

import SpPackReportList from "./SpPackReportList";
import SpPackReportDrilldown from "./drilldown/SpPackReportDrilldown";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";

import styles from "./spPackReport.module.scss";
import { OverviewHeader } from "components";

export const SpPackReport: FC<PropsFromRedux> = ({ customerId, ownerPacksLicensingState, actions }) => {
  const [selectedPack, setSelectedPack] = useState<{ id: number; name: string }>();

  useEffect(() => {
    actions.fetchOwnerPacksLicensing();
  }, [actions]);

  const handlePackSelectionReset = useCallback(() => setSelectedPack(undefined), []);

  const drilldownActive = useMemo(() => !!selectedPack, [selectedPack]);

  const renderRoot = useMemo(
    () => (
      <div className={styles.body}>
        <OverviewHeader title="Packs and Licensing" />
        <div className={styles.table}>
          <RequestStatusRenderer state={ownerPacksLicensingState}>
            {
              <SpPackReportList
                reduxFinished
                ownerPackLicenseData={ownerPacksLicensingState.value}
                onPackSelection={setSelectedPack}
              />
            }
          </RequestStatusRenderer>
        </div>
      </div>
    ),
    [ownerPacksLicensingState],
  );

  const renderDrilldown = useMemo(
    () => (
      <>
        {selectedPack && (
          <SpPackReportDrilldown
            packId={selectedPack.id}
            packName={selectedPack.name}
            resetSelectedPack={handlePackSelectionReset}
          />
        )}
      </>
    ),
    [handlePackSelectionReset, selectedPack],
  );

  return <div className={styles["full-height"]}>{drilldownActive ? renderDrilldown : renderRoot}</div>;
};

const mapStateToProps = (state: RootState) => ({
  customerId: state.userProfile.accountId,
  ownerPacksLicensingState: selectOwnerPacksLicensingState(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(reportingActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SpPackReport);
export default ConnectedComponent;
