import { NoResultsWithButton, PreventTransitionPrompt, SearchInput } from "../../../../components";
import { type RolePermissions, type SortingDirection, ViewType } from "../../../../enums";

import UsersFileUploadModal from "components/usersFileUploadModal/UsersFileUploadModal";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { connect, type ConnectedProps } from "react-redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { Columns, getColumnOptions } from "./columnOptions";

import {
  AssignUsersToRoles,
  SucceedUserImport,
  UserManagerAddedSuccess,
  UserManagerDeleteAllSuccess,
} from "features/Application/services/realTimeNotification/events/people/peopleEvents";

import { type IObservable } from "interfaces";
import { bindAction } from "interfaces/redux";
import { showGeneralLoaderWithTimeout } from "../../../Application/actions/generalLoader/generalLoaderActions";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import { reset as resetPreview } from "../state/slices/csvImport/csvImportPreviewSlice";
import { reset as resetFileUpload, updateUploadProgress } from "../state/slices/csvImport/uploadCsvFileSlice";
import { fetchCountries, fetchDepartments, fetchJobTitles } from "../state/thunks/addUserThunk";
import { fetchUploadedFileColumns, fetchUsersImportPreviewData, uploadCsvFile } from "../state/thunks/csvImportThunk";
import CreateUserModal from "./CreateUserModal";
import RemoveUsersConfirmationModal from "../../../../components/account/removeUsersConfirmationModal/RemoveUsersConfirmationModal";
import { fetchChildAccountRoles } from "features/Accounts/CreateAccount/state/thunks/createAccountThunk";
import type { NormalizedDropdown } from "utils/miscellaneousUtils";
import FormFooter from "components/forms/FormFooter";
import { bindActionCreators } from "redux";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import usersDataService from "../../../Application/services/dataServices/usersDataService";
import { type RoleUser } from "interfaces/user";
import { pluralize } from "utils/stringUtils";

export interface EditAccountUsersListOwnProps {
  editAccountId: number;
  users: RoleUser[];
  usersCount: number;
  isLoading: boolean;
  selectedIds: number[];
  disabledByPermission?: boolean;
  showUsersFileUploadModal: boolean;
  permissions?: RolePermissions[];
  renderAddUsersButton: () => JSX.Element;
  onSelectedUsersChanged: (ids: number[]) => void;
  onCloseUsersFileUploadModal: () => void;
  fetchUsers: (skip: number, top: number, sortBy?: string, sortOrder?: SortingDirection) => void;
  onAddUserModalObserver: IObservable<(onConfirm: () => void) => void>;
  deleteUsersFromAccount: (userId: number[], accountId: number) => void;
  isNavigationEnabled?: boolean;
}

export type UserRoles = {
  userId: string;
  roleIds: string[];
};

export type EditAccountUsersListProps = EditAccountUsersListOwnProps & PropsFromRedux;

const EditAccountUsersList = (props: EditAccountUsersListProps) => {
  const {
    users,
    usersCount,
    editAccountId,
    accountId,
    isLoading,
    fetchUsers,
    onSelectedUsersChanged,
    selectedIds,
    permissions,
    renderAddUsersButton,
    deleteUsersFromAccount,
    onAddUserModalObserver,
    moboAccountId,
    childRoles,
    isNavigationEnabled = true,
    disabledByPermission = false,
    ...rest
  } = props;

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const onFileDisposed = useCallback(() => setUploadedFile(null), [setUploadedFile]);
  const [isDirty, setIsDirty] = useState(false);
  const [usersWithNewRoles, setUsersWithNewRoles] = useState<UserRoles[]>([]);

  useEffect(() => {
    rest.onFetchChildRoles(editAccountId);
    rest.fetchDepartments();
    rest.fetchJobTitles();
    rest.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);

  const handleDelete = useCallback((userId: number) => {
    setUserIdToDelete(userId);
    setShowDeleteConfirmationModal(true);
  }, []);

  const onDeleteConfirmed = useCallback(() => {
    if (userIdToDelete !== null) {
      deleteUsersFromAccount([userIdToDelete], editAccountId);
      setShowDeleteConfirmationModal(false);
      setUserIdToDelete(null);
    }
  }, [userIdToDelete, deleteUsersFromAccount, editAccountId]);

  const onDeleteCancelled = useCallback(() => {
    setShowDeleteConfirmationModal(false);
    setUserIdToDelete(null);
  }, []);

  const renderRemoveContactConfirmationModal = () => (
    <RemoveUsersConfirmationModal
      count={1}
      open={showDeleteConfirmationModal}
      onCancel={onDeleteCancelled}
      onContinue={onDeleteConfirmed}
    />
  );

  const handleChangeRole = useCallback(
    (userId: string, value: NormalizedDropdown) => {
      setIsDirty(true);

      const user = users.find((u: RoleUser) => u.id.toString() === userId);
      if (!user) {
        return;
      }

      const newlySelectedRoleIds = value.selected.filter(
        (roleId) => !user.assignedRoles.some((role) => role.id === Number(roleId)),
      );

      if (newlySelectedRoleIds.length === 0) {
        setUsersWithNewRoles((prev) => prev.filter((item) => item.userId !== userId));
        return;
      }

      setUsersWithNewRoles((prev) => {
        const existing = prev.find((item) => item.userId === userId);
        if (existing) {
          return prev.map((item) => (item.userId === userId ? { ...item, roleIds: newlySelectedRoleIds } : item));
        }
        return [...prev, { userId, roleIds: newlySelectedRoleIds }];
      });
    },
    [users],
  );

  const onCancel = () => {
    setUsersWithNewRoles([]);
    setIsDirty(false);
  };

  const onFinishAsync = useCallback(async () => {
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = props;

    const userCount = usersWithNewRoles.length;
    const roleCount = userCount > 1 ? userCount : usersWithNewRoles[0].roleIds.length;

    const userToRoleAssignments = usersWithNewRoles.flatMap((user) =>
      user.roleIds.map((roleId) => ({
        userId: Number(user.userId),
        roleId: Number(roleId),
      })),
    );

    setUsersWithNewRoles([]);
    setIsDirty(false);

    const params = {
      id: `AssignUsersToRole_${new Date()}`,
      title: `Assign ${pluralize("role", roleCount)} to ${pluralize("user", userCount)}`,
      getMessageIds: async () => {
        const response = await usersDataService.assignUsersToRolesV2(userToRoleAssignments, editAccountId);
        return [response.data];
      },
      successTransientMessage: `${pluralize("Role", roleCount)} ${roleCount > 1 ? "have" : "has"} been assigned to ${pluralize("user", userCount)} successfully`,
      failureTransientMessage: `Assigning ${pluralize("role", roleCount)} to ${pluralize("user", userCount)} failed!`,
    };

    if (params) {
      await backgroundTask.updateEntity(params, {
        addOperation: addOperationV1,
        sendTransientNotification,
      });
    }
  }, [usersWithNewRoles, props, editAccountId]);

  const columnOptions = useMemo(() => {
    if (usersWithNewRoles.length < 1) {
      setIsDirty(false);
    }

    return getColumnOptions({
      handleDelete: handleDelete,
      hasPermission: !disabledByPermission,
      removeButtonDisabled: disabledByPermission,
      selectedIds: selectedIds,
      moboAccountId: moboAccountId !== accountId ? moboAccountId : undefined,
      target: moboAccountId !== accountId ? "_blank" : undefined,
      isNavigationEnabled,
      defaultRoles: childRoles.filter((x) => x.isDefault),
      handleChangeRole: handleChangeRole,
      usersWithNewRoles: usersWithNewRoles,
    });
  }, [
    usersWithNewRoles,
    handleDelete,
    disabledByPermission,
    selectedIds,
    moboAccountId,
    accountId,
    isNavigationEnabled,
    childRoles,
    handleChangeRole,
  ]);

  const csvUsersUploadModal = useMemo(() => {
    const {
      actorId,
      csvImport,
      showUsersFileUploadModal,
      onCloseUsersFileUploadModal,
      backgroundTasks,
      addBackgroundTask,
      removeBackgroundTask,
      cancelBackgroundTask,
      showGeneralLoaderWithTimeout,
      uploadUsersFile,
      fetchUploadedFileColumns,
      fetchUsersImportPreviewData,
      resetFileUpload,
      updateUploadProgress,
      resetUsersImportPreviewData,
    } = rest;

    const { uploadStatus, csvColumns, preview } = csvImport;

    return (
      <UsersFileUploadModal
        showGeneralLoaderWithTimeout={showGeneralLoaderWithTimeout}
        addBackgroundTask={addBackgroundTask}
        removeBackgroundTask={removeBackgroundTask}
        cancelBackgroundTask={cancelBackgroundTask}
        importAccountId={editAccountId}
        accountId={accountId}
        actorId={actorId}
        showModal={showUsersFileUploadModal}
        onClose={onCloseUsersFileUploadModal}
        isFileUploading={uploadStatus.isUploading}
        droppedFile={uploadedFile}
        onFileDisposed={onFileDisposed}
        uploadProgress={uploadStatus.progress}
        uploadError={uploadStatus.error?.message ?? null}
        uploadUsersFile={uploadUsersFile}
        getUploadedFileColumns={fetchUploadedFileColumns}
        getUsersImportPreviewData={fetchUsersImportPreviewData}
        uploadedUsersFileColumns={csvColumns}
        cancelUsersFileUpload={resetFileUpload}
        resetUsersFileUpload={resetFileUpload}
        updateUsersFileUploadProgress={(...args: any[]) => updateUploadProgress(args[0])}
        usersImportPreviewData={preview}
        resetUsersImportPreviewData={resetUsersImportPreviewData}
        backgroundTasks={backgroundTasks}
      />
    );
  }, [rest, uploadedFile, onFileDisposed, accountId, editAccountId]);

  const renderPreventTransitionPrompt = (dirty: boolean) => {
    return (
      <PreventTransitionPrompt
        when={dirty}
        title="Exit Without Saving?"
        message="You have unsaved changes. Are you sure you want to exit? All changes will be lost."
      />
    );
  };

  return (
    <>
      <GenericItemsView
        viewType={ViewType.LIST}
        items={users}
        isLoading={isLoading}
        columnOptions={columnOptions}
        fetchData={fetchUsers}
        dataCount={usersCount}
        renderSearch={() => <SearchInput placeholder="Search for Users..." disabled />}
        // @ts-ignore
        noResultsContent={
          <NoResultsWithButton
            title="No Users"
            description="It looks like you don't have any users yet."
            iconClassName="fal fa-user"
            actionButton={renderAddUsersButton()}
          />
        }
        selectedIds={selectedIds}
        onSelectedItemChanged={onSelectedUsersChanged}
        listViewRtnEvents={[
          SucceedUserImport,
          UserManagerAddedSuccess,
          UserManagerDeleteAllSuccess,
          AssignUsersToRoles,
        ]}
        permissions={permissions}
        defaultSortingColumnName={Columns.Added}
      />
      {csvUsersUploadModal}
      {renderRemoveContactConfirmationModal()}
      <CreateUserModal onTriggerModalObserver={onAddUserModalObserver} accountId={editAccountId} />
      {renderPreventTransitionPrompt(isDirty)}
      <FormFooter
        isSaveBtnDisabled={!isDirty}
        isCancelBtnDisabled={!isDirty}
        onSave={onFinishAsync}
        onCancel={onCancel}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { csvImport } = state.accounts.edit;
  const { creation } = state.accounts;
  return {
    accountId: state.userProfile.accountId,
    moboAccountId: state.accounts.edit.accountInfo.accountConfiguration.id,
    actorId: state.userProfile.id,
    csvImport: csvImport,
    backgroundTasks: state.backgroundTasks.tasks,
    childRoles: creation.childRoles.items,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addBackgroundTask: bindAction(backgroundTasksActionsRedux.addTask, dispatch),
    removeBackgroundTask: bindAction(backgroundTasksActionsRedux.deleteTask, dispatch),
    cancelBackgroundTask: bindAction(backgroundTasksActionsRedux.cancelTask, dispatch),
    showGeneralLoaderWithTimeout: bindAction(showGeneralLoaderWithTimeout, dispatch),
    uploadUsersFile: bindAction(uploadCsvFile, dispatch),
    fetchUploadedFileColumns: bindAction(fetchUploadedFileColumns, dispatch),
    fetchUsersImportPreviewData: bindAction(fetchUsersImportPreviewData, dispatch),
    resetFileUpload: bindAction(resetFileUpload, dispatch),
    updateUploadProgress: bindAction(updateUploadProgress, dispatch),
    resetUsersImportPreviewData: bindAction(resetPreview, dispatch),
    fetchDepartments: bindAction(fetchDepartments, dispatch),
    fetchJobTitles: bindAction(fetchJobTitles, dispatch),
    fetchCountries: bindAction(fetchCountries, dispatch),
    onFetchChildRoles: bindAction(fetchChildAccountRoles, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditAccountUsersList);
export default ConnectedComponent;
