import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./addOnIcon.scss";

export interface AddOnIconProps {
  id: number;
}

const iconMap = new Map([
  [1, "folder"], // Create Content
  [2, "building"], // Create child Account
  [3, "fa-box-full"], // Marketplace
  [4, "fa-shield-alt"], // Threat Defence
  [5, "fa-box-full"], // Purchase Packs
]);

export default function AddOnIcon({ id }: AddOnIconProps) {
  const className = cn(iconMap.get(id), "addon-icon ");
  return (
    <div className="addon-icon-container">
      <Icon className={className} />
    </div>
  );
}
