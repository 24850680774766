import React, { useEffect, type KeyboardEvent, type ClipboardEvent } from "react";
import { Input, type InputOnChangeData } from "semantic-ui-react";
import { type FieldPropsBase } from "./models";

import ValidatedField from "./ValidatedField";

export const InputField = (props: InputFieldProps) => {
  const {
    disableValidation,
    value,
    placeholder,
    propertyName,
    onBlur,
    disabled,
    autoFocus,
    maxLength,
    validateField,
    setFieldValue,
    selfValidation,
    touched,
    setFieldTouched,
    onMount,
    onUnmount,
    onPaste,
    onKeyPress,
    type = "text",
  } = props;

  useEffect(() => {
    onMount?.(propertyName);

    (async () => {
      await runValidation(value);
    })();

    return () => {
      onUnmount?.(propertyName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnBlur = async () => {
    onBlur?.(propertyName);
    setTouched();
    await runValidation(value);
  };

  const handleOnChange = async (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setFieldValue(propertyName, data.value, !selfValidation && !disableValidation);
    await runValidation(data.value);
  };

  const runValidation = async (input: string | number) => {
    if (selfValidation && !disableValidation) {
      await validateField?.(propertyName, input);
    }
  };

  const setTouched = () => {
    if (!touched) {
      setFieldTouched(propertyName, true, !selfValidation && !disableValidation);
    }
  };

  return (
    <ValidatedField {...props}>
      <Input
        fluid
        name={propertyName}
        type={type}
        value={value || ""}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled}
        autoFocus={autoFocus}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
      />
    </ValidatedField>
  );
};

export interface InputFieldProps extends FieldPropsBase<number | string> {
  placeholder?: string;
  autoFocus?: boolean;
  maxLength?: number;
  type?: "text" | "number";
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export default React.memo(InputField);
