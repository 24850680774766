import { isEmpty, once } from "lodash";
import { type FC, useCallback, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { AssignmentPeopleContext, SortOptions, Strings } from "../../../enums";
import { type AppDispatch } from "../../../features/Application/globaltypes/redux";
import sortOptions from "../../../features/Licensing/Packs/Overview/sortOptions";
import { type PeopleAvailablePacksRequest } from "../../../features/Licensing/Packs/types/requests";
import { ModalWithSteps } from "../../modal";
import { renderSteps } from "../utils/renderSteps";
import SearchInput from "../../searchInput/SearchInput";
import { ConfirmLicensingStep, PacksContextStep } from "../commonSteps";
import PackGridStep from "../packAssignmentModal/packAssignmentSteps/packGridStep/PackGridStep";
import { bindAction } from "../../../interfaces/redux";
import { type LicenseConfirmationData } from "../../../interfaces";
import { type SearchState } from "../../../features/Application/slices/createSearchSlice";
import { type LazyFetchingItemsState } from "../../../features/Application/slices/createLazyFetchingItemsSlice";
import {
  setPeopleType,
  resetPeopleType,
} from "../../../features/Licensing/Packs/state/slices/peopleIssueLicenseModalSlice";
import { resetContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { type PeopleAvailablePackOverview } from "../packAssignmentModal/types";
import { type ModalActionConfig, type RenderActions, type RenderModalActionsType, type StepsOptions } from "../types";

import "./peopleLicenseModal.scss";

export interface EditPeoplePacksIssueLicenseModalProps {
  showModal: boolean;
  accountId: number;
  peopleId: number;
  assignmentPeopleContext: AssignmentPeopleContext;
  packsGrid: LazyFetchingItemsState<PeopleAvailablePackOverview>;
  search?: SearchState;
  loadPacks: (requestData: PeopleAvailablePacksRequest) => void;
  onCancel: () => void;
  onConfirm: (selectedPackIds: number[]) => void;
  setSearch?: (payload: string) => void;
  resetGridItems: () => void;
}

export type EditPeoplePacksIssueLicenseModalAllProps = PropsFromRedux & EditPeoplePacksIssueLicenseModalProps;

export const EditPeoplePacksIssueLicenseModal: FC<EditPeoplePacksIssueLicenseModalAllProps> = (
  props: EditPeoplePacksIssueLicenseModalAllProps,
) => {
  const [isDataValid, setIsDataValid] = useState(true);
  const [orderBy, setOrderBy] = useState<SortOptions>(SortOptions.CreatedDateDesc);
  const [selectedPackIds, setSelectedPackIds] = useState<number[]>([]);
  const [issueLicensePackIds, setIssueLicensePackIds] = useState<number[]>([]);

  const [skipPacksContextStep, setSkipPacksContextStep] = useState(false);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();

  const { packsGrid, showModal, accountId, peopleId, assignmentPeopleContext, search } = props;

  const { loadPacks, setSearch, resetGridItems, onCancel, onConfirm, setPeople, resetPeople, resetContentPacks } =
    props;

  const onLoadFirstPageCallback = useCallback(() => {
    setSelectedPackIds([]);
    loadPacks({
      peopleId: peopleId,
      accountId: accountId,
      skip: 0,
      orderBy: orderBy,
      filters: {},
      searchTerm: search?.term,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, loadPacks, accountId]);

  useEffect(() => {
    return () => {
      resetGridItems();
      resetContentPacks();
      setSearch && setSearch("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPeople(assignmentPeopleContext);
    onLoadFirstPageCallback();
  }, [orderBy, assignmentPeopleContext, setPeople, onLoadFirstPageCallback]);

  useEffect(() => {
    setSkipPacksContextStep(false);
    setPacksContextStepHeader(undefined);

    if (!showModal) {
      setSelectedPackIds([]);
      setOrderBy(SortOptions.CreatedDateDesc);
      resetContentPacks();
      resetGridItems();
      resetPeople();
    }
  }, [showModal, resetPeople, resetGridItems, resetContentPacks]);

  const getSortOptionWithSelected = () => {
    const selectedOption = orderBy;
    sortOptions.forEach((option) => (option.default = selectedOption === option.value));
    return sortOptions;
  };

  const onSearchChange = (searchData: string) => {
    setSearch && setSearch(searchData);
    resetGridItems();
    onLoadFirstPageCallback();
  };

  const onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    resetGridItems();
    setOrderBy(data.value);
  };

  const loadItemsToAddPage = () => loadItems(packsGrid.items.length);

  const isFiltered = !isEmpty(search?.term);

  const loadItems = (loadedPacksCount: number) => {
    loadPacks({
      peopleId: peopleId,
      accountId: accountId,
      skip: loadedPacksCount,
      orderBy: orderBy,
      filters: {},
      searchTerm: search?.term,
    });
  };

  const getConfirmationInfo = () => {
    const confirmationInfo: LicenseConfirmationData = {
      userIds: assignmentPeopleContext === AssignmentPeopleContext.User ? [peopleId] : [],
      groupIds: assignmentPeopleContext === AssignmentPeopleContext.Group ? [peopleId] : [],
    };
    return confirmationInfo;
  };

  const changeSelectedItems = (ids: number[]) => {
    setSelectedPackIds(ids);
    setSkipPacksContextStep(false);
    resetContentPacks();
  };

  const getPacksContextItems = () =>
    packsGrid.items
      .filter((a: { id: number }) => selectedPackIds.includes(a.id))
      .map((x) => {
        return { type: "pack", id: x.id.toString(), title: x.title, thumbnailUrl: x.thumbnailImageUrl };
      });

  const renderPacksStep = (renderModalActions: RenderModalActionsType) => (
    <PackGridStep
      blur
      header="Issue License"
      accountId={accountId}
      assignedAccountId={accountId}
      selectedIds={selectedPackIds}
      items={packsGrid.items}
      itemsCount={packsGrid.itemsCount}
      renderModalActions={renderModalActions}
      onSelectedItemsChanged={changeSelectedItems}
      loadItemsToAddPage={loadItemsToAddPage}
      onSortOptionsChanged={onSortOptionsChanged}
      filterOptions={/* istanbul ignore next */ {}} // NOSONAR
      applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
      resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
      appliedFilter={/* istanbul ignore next */ () => {}} // NOSONAR
      hideFilterPanel
      isPacksModalFiltered={isFiltered}
      isLoading={packsGrid.isLoading}
      areAllLoaded={packsGrid.areAllLoaded}
      sortOptions={getSortOptionWithSelected()}
      renderSearch={() => (
        <SearchInput
          placeholder="Search for packs..."
          disabled={!setSearch}
          onChange={onSearchChange}
          defaultValue={search?.term}
        />
      )}
    />
  );

  const renderPacksContextStep = (renderModalActions: RenderModalActionsType) => (
    <PacksContextStep
      header={packsContextStepHeader}
      renderModalActions={renderModalActions}
      info={getPacksContextItems()}
      onIsDataValidChange={setIsDataValid}
      noDataLoaded={() => setSkipPacksContextStep(true)}
      setModalStepHeader={() => setPacksContextStepHeader(Strings.modalTitles.licensing)}
    />
  );

  const renderConfirmLicensingStep = (renderModalActions: RenderModalActionsType) => (
    <ConfirmLicensingStep
      header={Strings.modalTitles.licenseConfirmation}
      renderModalActions={renderModalActions}
      onIsDataValidChange={setIsDataValid}
      info={getConfirmationInfo()}
      setIssueLicensePackIds={setIssueLicensePackIds}
    />
  );

  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderPacksStep,
      modalActionConfig: {
        next: {
          disabled: isEmpty(selectedPackIds),
        },
      },
    },
    {
      renderStep: renderPacksContextStep,
      skipStep: skipPacksContextStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        next: {
          disabled: !isDataValid,
          onClick: () => setIsDataValid(false),
        },
      },
    },
    {
      renderStep: renderConfirmLicensingStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        confirm: {
          disabled: !isDataValid,
          onClick: () => issueLicensePackIds,
        },
      },
    },
  ];

  const renderModalActionsFirstStep =
    ({ next }: ModalActionConfig) =>
    (nextStep: () => void) =>
    (closeModal: Function) => {
      return (
        <>
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            disabled={next?.disabled}
            onClick={() => {
              next?.onClick?.();
              nextStep();
            }}
          />
        </>
      );
    };

  const renderModalActionsMiddleStep =
    ({ previous, next }: ModalActionConfig) =>
    (nextStep: () => void, prevStep: () => void) =>
    () => (
      <>
        <Button
          blur
          primary
          className="previous"
          content="Previous"
          disabled={previous?.disabled}
          onClick={() => {
            previous?.onClick?.();
            prevStep();
          }}
        />
        <Button
          blur
          primary
          className="next"
          content="Next"
          disabled={next?.disabled}
          onClick={() => {
            next?.onClick?.();
            nextStep();
          }}
        />
      </>
    );

  const renderModalActionsLastStep =
    ({ previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => {
      const confirmHandler = async () => {
        const onClickResult = await confirm?.onClick?.();
        onConfirm(onClickResult);
        closeModal();
      };
      return (
        <>
          <Button
            primary
            blur
            className="previous"
            content="Previous"
            onClick={() => {
              previous?.onClick?.();
              prevStep();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={confirm?.disabled}
            onClick={once(confirmHandler)}
          />
        </>
      );
    };

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsMiddleStep,
    renderModalActionsLastStep,
  };

  return (
    <ModalWithSteps className="people-license-modal" scrolling showModal={showModal} onCancel={onCancel}>
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setPeople: bindAction(setPeopleType, dispatch),
    resetPeople: bindAction(resetPeopleType, dispatch),
    resetContentPacks: bindAction(resetContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditPeoplePacksIssueLicenseModal);
export default ConnectedComponent;
