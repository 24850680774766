import midnightService from "../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/FeedbackPageEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { type FeedbackPage, type IFeedbackPageRequest } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { setFeedbackPageAction } from "./FeedbackPageDetailsActions";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import {
  FeedbackPageEditSuccess,
  FeedbackPagePublishSuccess,
  FeedbackPageLockSuccess,
  FeedbackPageDiscardSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createFeedbackPage = (data: FeedbackPage) => {
  const begin = getActionBaseProvider(actionTypes.createFeedbackPageBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createFeedbackPageSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createFeedbackPageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const request = getFeedbackPageRequest(data);
      const result = await midnightService.createLock(draftEntityTypes.FeedbackPages, request);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateFeedbackPage = (metadata: FeedbackPage) => {
  const begin = getActionBaseProvider(actionTypes.updateFeedbackPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateFeedbackPageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: FeedbackPageEditSuccess }));
    try {
      const request = getFeedbackPageRequest(metadata);
      await dataService.threatDefence.updateFeedbackPageAsync(request);
      dispatch(setFeedbackPageAction(metadata));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

const getFeedbackPageRequest = (feedbackPage: FeedbackPage): IFeedbackPageRequest => ({
  ...feedbackPage,
  videoName: feedbackPage.uploadedVideos?.[0]?.name,
  videoSize: feedbackPage.uploadedVideos?.[0]?.size,
});

export const updateFeedbackPageCommandSuccess = getActionProvider(
  actionTypes.updateFeedbackPageCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const feedbackPageUpdated = getActionProvider(actionTypes.feedbackPageUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const resetFeedbackPageEntityState = getActionBaseProvider(actionTypes.resetFeedbackPageEntityState);

export const setFeedbackPageEntityIdAction = getActionProvider<number>(actionTypes.setFeedbackPageEntityId);

export const fetchDraftFeedbackPageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedFeedbackPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedFeedbackPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: FeedbackPageLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.FeedbackPages, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedFeedbackPageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedFeedbackPageSuccess,
);

export const discardFeedbackPage = (id: number) => async (dispatch: Function) => {
  const begin = getActionBaseProvider(actionTypes.discardFeedbackPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.discardFeedbackPageFailure);

  dispatch(begin());
  dispatch(beginAsyncOperation({ id, action: FeedbackPageDiscardSuccess }));
  try {
    await midnightService.discardLock(draftEntityTypes.FeedbackPages, id);
  } catch (error: any) {
    dispatch(failure({ error }));
  }
};

export const publishDraftFeedbackPage = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishFeedbackPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishFeedbackPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: FeedbackPagePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.FeedbackPages, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftFeedbackPageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishFeedbackPageSuccess,
);
