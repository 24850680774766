import { type FormikProps, withFormik } from "formik";
import { useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type Dispatch } from "redux";
import { noop } from "lodash";

import validationSchemas from "../../../../../utils/validationSchemas";
import Restricted from "../../../../Application/Restricted";
import PdfForm from "../PdfForm/PdfForm";

import { type RootState } from "../../../../Application/globaltypes/redux";
import { type ConfigureProps, type PdfFormFields } from "../types";
import { type AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import { type PdfPayload } from "../../types/models";
import { RolePermissions } from "../../../../../enums";
import { pdfFormFieldsSelector, pdfsStateSelector } from "../../selectors";
import { resetPdf, updatePdf } from "../../state/actions/pdfEntityStateActions";
import { savePdf } from "../../state/slices/pdfDetailsSlice";
import { bindAction } from "../../../../../interfaces/redux";
import { uploadPdfThumbnail } from "../../state/thunks/pdfDetailsThunk";

export type ConfigurePropsAll = ConfigureProps & FormikProps<PdfFormFields> & PropsFromRedux & AutosaveProps;

export const Configure = (props: ConfigurePropsAll) => {
  const isPdfCreated = props.id > 0 || props.isCreating;

  const { isValid, onIsValidChange } = props;
  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  const values = useRef(props.values);
  useEffect(() => {
    values.current = props.values;
  }, [props.values]);

  const savePdf = (paramValues?: PdfFormFields) => {
    props.savePdf(paramValues || values.current);
  };

  useEffect(() => {
    props.acceptHandlers?.({
      onNext: savePdf,
      onPrevious: savePdf,
      onClose: props.resetPdf,
    });
    props.subscribeOnDiscarded?.(props.resetForm);
    return () => {
      props.unSubscribeOnDiscarded?.(props.resetForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThumbnailChanged = (fileKey: string) => {
    props.uploadPdfThumbnail(props.id, fileKey);
    savePdf();
  };

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => (
        <PdfForm
          {...props}
          disablePreventTransitionPrompt={isPdfCreated}
          disabled={props.disabled || !hasPermission}
          onImageChanged={handleThumbnailChanged}
          onFileUpload={savePdf}
        />
      )}
    />
  );
};

const mapToPdfPayload = (pdfView: PdfFormFields): PdfPayload => ({
  title: pdfView.title.trim(),
  description: pdfView.description.trim(),
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = pdfsStateSelector(state).base;

  return {
    id: base.pdfEntityStateReducer.entityId,
    isCreating: base.pdfEntityStateReducer.changingEntityState,
    pdfFormFields: pdfFormFieldsSelector(state),
    isLoaded: base.details.isLoaded,
    isUploaded: base.details.isUploaded,
    isLoading: base.details.isLoading,
    isDraft: base.details.pdf.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePdf: bindAction(updatePdf, dispatch),
  resetPdf: bindAction(resetPdf, dispatch),
  savePdf: bindAction(savePdf, dispatch),
  uploadPdfThumbnail: bindAction(uploadPdfThumbnail, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigurePropsAll, PdfFormFields, PdfPayload>({
  getInitValues: (props) => mapToPdfPayload(props.pdfFormFields),
  stateProvider: mapToPdfPayload,
  entityUpdater: (props) => props.updatePdf,
  isDeffered: (props) => !props.isLoaded && !props.isUploaded,
})(Configure);

const component = withFormik({
  validationSchema: (props: ConfigureProps & PropsFromRedux) =>
    validationSchemas.getPdfConfigurationSchema(props.mode === "create" || props.pdfFormFields.uploadedPdfs.length > 0),
  enableReinitialize: true,
  mapPropsToValues: (props: ConfigureProps & PropsFromRedux): PdfFormFields => props.pdfFormFields,
  validateOnMount: true,
  handleSubmit: noop,
})(withAutosaveComponent);

const ConnectedComponent = connector(component);
export default ConnectedComponent;
