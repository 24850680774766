import React from "react";
import { func, object, string } from "prop-types";

import { multiSelectPropTypes } from "./multiSelectPropTypes";
import MultiSelect from "./MultiSelect";

import isEmpty from "lodash/isEmpty";

function FilterMultiSelect(props) {
  const { filter, updateFilter, propertyName, ...multiSelectProps } = props;

  const handleFilterChange = (_, { value }) => {
    const newFilter = { ...filter };

    if (value.length !== 0) {
      newFilter[propertyName] = value;
    } else {
      delete newFilter[propertyName];
    }

    updateFilter(newFilter);
  };

  /// 'propertyName' will be used in query string while sending request to backend
  /// so backend filter model prop should have the same name

  const selected = isEmpty(filter[propertyName]) ? [] : filter[propertyName];

  return <MultiSelect handleMultiSelectChange={handleFilterChange} selected={selected} {...multiSelectProps} />;
}

FilterMultiSelect.propTypes = {
  filter: object,
  updateFilter: func,
  propertyName: string,
  ...multiSelectPropTypes,
};

export default FilterMultiSelect;
