import React from "react";
import PropTypes from "prop-types";
import { Progress as SemanticProgress } from "semantic-ui-react";
import { default as cn } from "classnames";
import { CancelButton } from "../buttons/circleIconButtons";

import "./progress.scss";

function renderPercent(props) {
  const { percent, indeterminate, showPercents } = props;
  if (showPercents && !indeterminate && percent) {
    return <span className="progress-label">{percent}%</span>;
  }
  return null;
}

function Progress(props) {
  const { percent, onCancel, error, indeterminate, label, className, isUploading, warning, completed } = props;
  const operationHasCompleted = (percent === 100 && !isUploading) || completed;

  return (
    <div
      data-testid={`upload-progress${operationHasCompleted && error ? "-completed-error" : ""}`}
      className={cn(className, "upload-progress", {
        success: operationHasCompleted && !error,
        error: error,
      })}
    >
      <SemanticProgress
        size="small"
        color="yellow"
        active={!operationHasCompleted}
        success={operationHasCompleted && !error}
        error={error}
        warning={warning}
        percent={indeterminate ? 100 : percent}
        className={cn({ indeterminate: indeterminate })}
      />
      {renderPercent(props)}
      {label && <span className="status-label">{label}</span>}
      {onCancel && !operationHasCompleted && !error && <CancelButton className="progress-cancel" onClick={onCancel} />}
    </div>
  );
}

Progress.defaultProps = {
  error: false,
  indeterminate: false,
  showPercents: true,
};

Progress.propTypes = {
  percent: PropTypes.number,
  isUploading: PropTypes.bool,
  onCancel: PropTypes.func,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  showPercents: PropTypes.bool,
  completed: PropTypes.bool,
};

export default Progress;
