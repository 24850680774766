import { useNavigate } from "react-router-dom";
import { type RolePermissions } from "../../../enums";
import Restricted from "../../../features/Application/Restricted";
import RestrictedAddButton from "./RestrictedAddButton";

interface Props {
  buttonText: string;
  goToLink: string;
  permissions: RolePermissions[];
  className?: string;
  replaceEntry?: boolean;
  disabled?: boolean;
}

export const AddButtonWithPermissionComponent: React.FC<Props> = ({
  buttonText,
  goToLink,
  permissions,
  className,
  replaceEntry = false,
  disabled,
}) => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate(goToLink, { replace: replaceEntry });
  };
  return (
    <Restricted
      permissions={permissions}
      renderContent={(hasAnyPermission) => (
        <RestrictedAddButton
          hasPermission={hasAnyPermission}
          onClick={goTo}
          buttonText={buttonText}
          className={className}
          disabled={disabled}
        />
      )}
    />
  );
};

export const AddButtonWithPermission = AddButtonWithPermissionComponent;
