import React, { useLayoutEffect, useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { batch, connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";

import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import * as entityStateActions from "../state/actions/FeedbackPageEntityStateActions";
import * as detailsActions from "../state/actions/FeedbackPageDetailsActions";
import { EditFeedbackPageSteps } from "./types";
import { useWizardStepsManager } from "../../../../../hooks/useWizardStepsManager";
import { RouteNames, PublishedStatusTypes } from "../../../../../enums";
import { type EnumDictionary, type ISegment } from "../../../../../interfaces";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import EditFeedbackPageSubheaderContainer from "./EditFeedbackPageSubheaderContainer";
import Segments from "../../../../../components/navigation/segments/Segments";
import Configure from "../Details/Configure/Configure";
import Content from "../Details/Content/Content";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import ModalManager from "../../../../../utils/ModalManager";
import ModalTypes from "../../../../../components/modal/ModalTypes";
import RevertConfirmationModalTs from "../../../../../components/modal/RevertConfirmationModalTs";
import { useRtn } from "../../../../../hooks/useRtn";
import { withRouter, type WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

export type EditFeedbackPagePropsAll = PropsFromRedux & WithRouterProps;

const overviewPageUrl = `/${RouteNames.feedbackPagesSimulatedPhishing}`;

export const EditFeedbackPage: React.FC<EditFeedbackPagePropsAll> = (props: EditFeedbackPagePropsAll) => {
  const feedbackPageId = Number.parseInt(props.params.id!);
  const pageIndexes = [EditFeedbackPageSteps.Configure, EditFeedbackPageSteps.Content];
  const [wizardPages, { goToPage }, subscribeOnActiveIndexChanged] = useWizardStepsManager(pageIndexes);

  const onClose = () => props.navigate(overviewPageUrl);
  const onPublish = () => props.entityStateActions.publishDraftFeedbackPage(feedbackPageId);
  const onEdit = () => props.entityStateActions.fetchDraftFeedbackPageEntity(feedbackPageId);

  const revertModalManager = useMemo(
    () =>
      new ModalManager(
        ModalTypes.RevertFeedbackPage,
        () => props.entityStateActions.discardFeedbackPage(feedbackPageId),
        false,
        props.userId,
      ),
    [feedbackPageId, props.userId, props.entityStateActions],
  );
  const onRevert = () => revertModalManager.execute();
  const renderRevertModal = () =>
    props.hasBeenPublished && <RevertConfirmationModalTs modalManager={revertModalManager} />;

  const pages: EnumDictionary<EditFeedbackPageSteps, ISegment> = useMemo(() => {
    const loadFeedbackPage = () => {
      if (!props.isLoaded) {
        props.detailsActions.getFeedbackPage(feedbackPageId);
      }
    };

    return {
      [EditFeedbackPageSteps.Configure]: {
        label: "Configure",
        onClick: () => {
          goToPage(EditFeedbackPageSteps.Configure);
          loadFeedbackPage();
        },
        init: loadFeedbackPage,
      },
      [EditFeedbackPageSteps.Content]: {
        to: "settings",
        label: "Settings",
        onClick: () => {
          goToPage(EditFeedbackPageSteps.Content);
        },
        init: loadFeedbackPage,
      },
    };
  }, [goToPage, feedbackPageId, props.detailsActions, props.isLoaded]);

  useRtn([rtnEvents.FeedbackPagePublishSuccess, rtnEvents.FeedbackPageDiscardSuccess], () =>
    props.refreshFeedbackPage(feedbackPageId),
  );

  useLayoutEffect(() => {
    const handler = (_: any, activeStepIndex: EditFeedbackPageSteps) => {
      pages[activeStepIndex].init?.();
    };

    return subscribeOnActiveIndexChanged(handler);
  }, [pages, subscribeOnActiveIndexChanged]);
  const [isValid, setIsValid] = useState(true);
  const editDisabled = !props.isDraft;
  const { feedbackPage } = props;

  return (
    <div className="nested-content scrollable-content">
      <DetailsHeader
        title={feedbackPage.name}
        titleForGA="Feedback Page Details"
        backButton={overviewPageUrl}
        canBeEdited={editDisabled}
        isRevertVisible={feedbackPage.hasBeenPublished}
        publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(editDisabled)}
        invalidFormDetails={!isValid}
        entityStateActions={{
          onClose: onClose,
          onPublish: onPublish,
          onEdit: onEdit,
          onRevert: onRevert,
        }}
        canBePublished={isValid}
      />
      {renderRevertModal()}
      <EditFeedbackPageSubheaderContainer
        publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(editDisabled)}
        isUpdateInProgress={feedbackPage.isDraft}
        lastModifiedDateTime={feedbackPage.dateModified}
      >
        <Segments to={`/${RouteNames.feedbackPagesSimulatedPhishing}/${feedbackPageId}`}>
          <Segments.Segment {...pages[EditFeedbackPageSteps.Configure]} />
          <Segments.Segment {...pages[EditFeedbackPageSteps.Content]} />
        </Segments>
      </EditFeedbackPageSubheaderContainer>
      <Dimmer active={props.isLoading} inverted>
        <Loader />
      </Dimmer>
      <Routes>
        <Route
          path="/"
          element={
            <div className="edit-form">
              <Configure
                entityId={feedbackPageId}
                acceptHandlers={wizardPages[EditFeedbackPageSteps.Configure]}
                onIsValidChange={setIsValid}
                disabled={editDisabled}
              />
            </div>
          }
        />
        <Route
          path={pages[EditFeedbackPageSteps.Content].to}
          element={
            <div className="edit-form">
              <Content
                entityId={feedbackPageId}
                acceptHandlers={wizardPages[EditFeedbackPageSteps.Content]}
                onIsValidChange={setIsValid}
                disabled={editDisabled}
              />
            </div>
          }
        />
      </Routes>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const detailsReducer = state.library.threatDefenceFeedbackPages.feedbackPageDetails;

  return {
    feedbackPage: detailsReducer.feedbackPage,
    isLoading: detailsReducer.isLoading,
    isLoaded: detailsReducer.isFeedbackPageLoaded,
    hasBeenPublished: detailsReducer.feedbackPage.hasBeenPublished,
    isDraft: detailsReducer.feedbackPage.isDraft,
    userId: Number(state.userProfile.id),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(entityStateActions, dispatch),
  detailsActions: bindActionCreators(detailsActions, dispatch),

  refreshFeedbackPage: (id: number) => {
    batch(() => {
      dispatch(detailsActions.clearFeedbackPage());
      dispatch(detailsActions.getFeedbackPage(id));
    });
  },
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(EditFeedbackPage));
export default ConnectedComponent;
