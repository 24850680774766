import React from "react";
import { isEmpty } from "lodash";

import { GroupFilterForm } from "../../../../components/filterForms";
import { SearchInput } from "../../../../components";
import NoResults from "../../../../components/noResults/NoResults";
import { ItemsView } from "../../../../views";
import { ViewType, SortingDirection } from "../../../../enums";
import { Enrollment } from "../../../../enums/groups";
import UserGroupListRow, { columnOptions } from "./UserGroupListRow";
import { type Filters } from "../../../../utils/queryUtils";
import { type Group } from "../../../../interfaces";

export interface UserGroupListProps {
  listViewRtnEvents: string[];
  addGroupButton?: React.ReactNode;
  groups: Group[];
  groupsCount: number;
  loadPage: () => void;
  selectedItemIds: number[];
  updateSelectedItems: (items: number[]) => void;
  isLoading: boolean;
  appliedUserGroupFilter: Filters;
  filterOptions: Filters;
  setUserGroupFilter: () => void;
  resetUserGroupFilter: () => void;
  getFilterOptions: () => void;
  onRemoveUserFromGroup: (groupId: number) => void;
  readOnly: boolean;
}

export default class UserGroupList extends React.Component<UserGroupListProps> {
  buildTableBody = (group: Group): React.ReactElement => {
    const { selectedItemIds, onRemoveUserFromGroup } = this.props;
    const isSelected = selectedItemIds.some((x) => x === group.id);

    return (
      <UserGroupListRow
        group={group}
        isSelected={isSelected}
        isDisabled={this.isSelectDisabled(group)}
        onRemoveUserFromGroup={onRemoveUserFromGroup}
      />
    );
  };

  isSelectDisabled = (group: Group) => {
    return this.props.readOnly || !group.canEdit || group.isWithAutoEnroll === Enrollment.Automatic;
  };

  noResultsContent = () => {
    if (isEmpty(this.props.appliedUserGroupFilter)) {
      return (
        <NoResults
          title="You don't have any groups"
          iconClassName="fal fa-users"
          description="Looks like you don't have any groups yet. Add some using the button below."
        >
          {this.props.addGroupButton}
        </NoResults>
      );
    }
    return <NoResults title="No groups were found" iconClassName="fal fa-users" />;
  };

  getFilterForm = () => <GroupFilterForm />;

  render() {
    const {
      groups,
      groupsCount,
      loadPage,
      appliedUserGroupFilter,
      filterOptions,
      setUserGroupFilter,
      resetUserGroupFilter,
      isLoading,
      updateSelectedItems,
      selectedItemIds,
      getFilterOptions,
      listViewRtnEvents,
    } = this.props;

    return (
      <ItemsView
        className="user-selection-list"
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getData={loadPage}
        itemsInlineCount={groupsCount}
        isLoading={isLoading}
        items={groups}
        buildTableBody={this.buildTableBody}
        onSelectedListItemsChanged={updateSelectedItems}
        renderFilterForm={this.getFilterForm}
        filterOptionsLoading={filterOptions.isLoading}
        appliedFilter={appliedUserGroupFilter}
        filterOptions={filterOptions}
        getFilterOptions={getFilterOptions}
        applyFilter={setUserGroupFilter}
        resetFilter={resetUserGroupFilter}
        sortingDirection={SortingDirection.Ascending}
        noResultsContent={this.noResultsContent()}
        selectedIds={selectedItemIds}
        isSelectDisabled={this.isSelectDisabled}
        renderSearch={() => <SearchInput placeholder="Search for Users..." disabled />}
        listViewRtnEvents={listViewRtnEvents}
        hideListGridViewSwitcherButton
      />
    );
  }
}
