import { useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import { noop } from "lodash";

import { bindAction } from "../../../../../interfaces/redux";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";

import SettingsForm from "./SettingsForm/SettingsForm";
import { surveysStateSelector } from "../../state/surveyReducer";
import { surveysTagsSelector } from "../../state/selectors/surveyCommonSelectors";
import { setTags } from "../../state/slices/surveyCommonSlice";
import { fetchSurveysTags } from "../../state/thunks/surveyCommonThunk";
import { saveSettingsAction } from "../../state/actions/surveyDetailsActions";
import { updateTags } from "../../state/thunks/surveyTagsThunk";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import { surveySettings } from "../../../../../utils/validationSchemas/surveysValidationSchema";

export type SettingsProps = IWizardStep &
  PropsFromRedux & {
    entityId?: number;
  };

export const SettingsTab = (props: SettingsProps) => {
  const { settings, normalizedTags, isLoading, acceptHandlers, entityId, disabled } = props;

  const tagsFormik = useFormik({
    initialValues: settings,
    validationSchema: surveySettings,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const valuesRef = useRef(tagsFormik.values);
  useEffect(() => {
    valuesRef.current = tagsFormik.values;
  }, [tagsFormik.values]);

  useEffect(() => {
    acceptHandlers?.({
      onNext: saveTags,
      onPrevious: saveTags,
    });
    props.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTags = () => {
    props.saveSettings(valuesRef.current);
  };

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => (
        <SettingsForm
          entityId={entityId}
          tagsFormik={tagsFormik}
          normalizedTags={normalizedTags}
          updateTags={props.updateTags}
          settings={settings}
          isDisabled={disabled || !hasPermission}
          isLoading={isLoading}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: { entityId?: number }) => {
  const base = surveysStateSelector(state).base;

  return {
    entityId: ownProps.entityId || base.surveysEntityStateReducer.entityId,
    settings: base.surveysDetailsReducer.settings,
    normalizedTags: surveysTagsSelector(state),
    isLoading: base.surveysDetailsReducer.isLoading,
    disabled: base.surveysDetailsReducer.survey.isPurchased,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAll: bindAction(fetchSurveysTags, dispatch),
  cleanAll: () => dispatch(setTags({})),
  saveSettings: bindAction(saveSettingsAction, dispatch),
  updateTags: bindAction(updateTags, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SettingsTab);
export default ConnectedComponent;
