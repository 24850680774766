import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

const surveyActionBuilder = (type: string) => {
  return buildRootEntityActionType(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Surveys, type);
};

export const createSurveyBegin = surveyActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN);

export const createSurveySuccess = surveyActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS);

export const createSurveyFailure = surveyActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE);

export const resetSurveyEntityState = surveyActionBuilder(entityStateActionTypes.RESET_ENTITY_STATE);

export const updateSurveyBegin = surveyActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN);

export const updateSurveyFailure = surveyActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE);

export const updateSurveyCommandSuccess = surveyActionBuilder(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const surveyUpdated = surveyActionBuilder(entityStateActionTypes.LOCKED_ENTITY_UPDATED);

export const publishSurveyBegin = surveyActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);
export const publishSurveySuccess = surveyActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS);
export const publishSurveyFailure = surveyActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE);

export const fetchLockedSurveyBegin = surveyActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN);

export const fetchLockedSurveySuccess = surveyActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS);

export const fetchLockedSurveyFailure = surveyActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE);

export const fetchDiscardSurveyBegin = surveyActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const fetchDiscardSurveySuccess = surveyActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const fetchDiscardSurveyFailure = surveyActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const setSurveyEntityId = surveyActionBuilder(entityStateActionTypes.SET_ENTITY_ID);

export const setDataForDetailsMode = surveyActionBuilder(entityStateActionTypes.SET_DATA_FOR_DETAILS_MODE);
