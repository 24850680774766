import { Columns } from "../features/Library/Pdfs/Overview/getColumnOptions";

const utils = {
  getSortBy: (sortingColumnName?: Columns) => {
    if (!sortingColumnName) {
      return "";
    }

    return {
      [Columns.Title.toLowerCase()]: "title",
      [Columns.Status.toLowerCase()]: "isDraft",
      [Columns.Added.toLowerCase()]: "DateCreated",
      [Columns.Modified.toLowerCase()]: "DateModified",
      [Columns.Publisher.toLowerCase()]: "publisher",
    }[sortingColumnName.toLowerCase()];
  },
};

export default utils;
