import { connect } from "react-redux";

import GenericItemsView, { type GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { type AppDispatch, type RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/pdfUsersSlice";
import { pdfsStateSelector } from "../../../../../selectors";
import { type PdfUser } from "../../../../../types/models";
import { bindAction } from "../../../../../../../../interfaces/redux";

const mapStateToProps = (state: RootState) => {
  const edit = pdfsStateSelector(state).edit;
  return {
    items: edit.users.items,
    isLoading: edit.users.isLoading,
    dataCount: edit.users.itemsCount,
    selectedIds: edit.users.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: bindAction(selectedItemsChanged, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<PdfUser>) => React.ReactElement);
