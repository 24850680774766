import { cloneDeep, isEmpty, isEqual, keyBy, partial, set } from "lodash";
import { v4 } from "uuid";

import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import {
  fetchSourceEventsBegin,
  fetchSourceEventsSuccess,
  fetchSourceEventsFailure,
  fetchTriggerGroupsBegin,
  fetchTriggerGroupsSuccess,
  fetchTriggerGroupsFailure,
  updateTriggerGroups,
} from "../slices/flowDesignerExternalTriggersSlice";
import {
  type BaseEvent,
  type ExpressionEvent,
  type ExternalTriggerGroup,
  type ExternalTriggerGroupPayload,
  type ExternalTriggerPayload,
  type ExternalTriggerType,
  type GoogleEvent,
  type SourceEvent,
  type SourceEventsPayload,
  type SourceType,
} from "../../types/externallTriggersTypes";
import flowsDataService from "../../../services/flowsDataService";
import { flowBaseSelector, flowDesignerSelector, flowInformationSelector } from "../../../state/selectors";
import { mapOperators } from "../../ReactFlowCanvas/nodes/StartOfTheFlow/TriggerGroups/helpers/utils";
import { type Integration } from "../../../types/flowBase";
import { fetchIntegrations } from "../../../state/thunks/flowBaseThunk";
import { IntegrationStatuses } from "../../../../../Accounts/Integrations/types";

export const removeExternalTriggerAction =
  (groupId: string, triggerId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      let triggerGroups = cloneDeep(flowDesignerSelector(getState()).externalTriggers.externalTriggerGroups);
      const group = triggerGroups.find((group) => groupId === group.groupId);
      if (group && group.triggers.length > 1) {
        group.triggers = group.triggers.filter((trigger) => trigger.id !== triggerId);
        triggerGroups[triggerGroups.indexOf(group)] = group;
      } else {
        triggerGroups = triggerGroups.filter((group) => groupId !== group.groupId);
      }

      await dispatch(updateExternalTriggerGroupsAction(triggerGroups));
    } catch (e) {}
  };

export const updateExternalTriggerGroupsAction =
  (externalTriggerGroups: ExternalTriggerGroup<ExternalTriggerType[]>[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const flowId = flowInformationSelector(getState()).info.id;
      const result = externalTriggerGroups.map((group) => ({
        type: group.type,
        groupId: group.groupId,
        triggers: group.triggers.map(getTriggerPayload),
      }));

      dispatch(updateTriggerGroups(externalTriggerGroups));
      await flowsDataService.updateExternalTriggerGroups(flowId, result);
    } catch (e) {}
  };

export const fetchExternalTriggerGroupsAction =
  (flowId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(fetchTriggerGroupsBegin());
      const externalTriggerGroups = (await flowsDataService.getExternalTriggerGroups(flowId)).data;
      const sourceEvents = await dispatch(getSourceEvents(externalTriggerGroups));
      let integrations: Integration[] = [];
      if (!flowInformationSelector(getState()).info.isEditable) {
        await dispatch(fetchIntegrations());
        integrations = flowBaseSelector(getState()).integrations;
      }
      const payload = externalTriggerGroups.map((group) => ({
        type: group.type,
        groupId: group.groupId,
        triggers: group.triggers.map(partial(getTriggerView, sourceEvents, integrations)),
      }));
      dispatch(fetchTriggerGroupsSuccess(payload));
    } catch (e) {
      dispatch(fetchTriggerGroupsFailure(e));
    }
  };

export const fetchSourceEventsAction =
  (sourceType: SourceType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const events = flowDesignerSelector(getState()).externalTriggers.sourceEvents;
    if (events[sourceType]) return;
    try {
      dispatch(fetchSourceEventsBegin());
      const sourceEventsResponse = await flowsDataService.getSourceEvents(sourceType);
      const sourceEvents = mapSourceEvents(sourceType, sourceEventsResponse.data);
      dispatch(fetchSourceEventsSuccess({ type: sourceType, events: sourceEvents }));
    } catch (e) {
      dispatch(fetchSourceEventsFailure(e));
    }
  };

const mapSourceEvents = (sourceType: SourceType, sourceEvents: SourceEvent<BaseEvent>[]) => {
  const result = {};
  switch (sourceType) {
    case "Google": {
      (sourceEvents as SourceEvent<GoogleEvent>[]).forEach((item) => {
        set(result, `${item.data.application}.${item.data.action}`, {
          id: item.id,
          fullText: item.data.fullText,
        });
      });
      break;
    }
    case "BrainStorm":
    case "Microsoft": {
      (sourceEvents as SourceEvent<ExpressionEvent>[]).forEach((item) => {
        set(result, `${item.data.application}.${item.data.field}`, {
          id: item.id,
          operators: mapOperators(item.data.operators),
          fullText: item.data.fullText,
        });
      });
      break;
    }
  }
  return result;
};

const getSourceEvents = (externalTriggerGroups: ExternalTriggerGroupPayload[]) => async (dispatch: AppDispatch) => {
  const sourceTypeSet = new Set<SourceType>();
  const promiseArr: Promise<void>[] = [];
  const sourceEvents: SourceEventsPayload = {};
  externalTriggerGroups.forEach((group) => {
    group.triggers.forEach(({ sourceType }) => {
      if (!sourceTypeSet.has(sourceType)) {
        sourceTypeSet.add(sourceType);
        promiseArr.push(
          (async () => {
            const result = await flowsDataService.getSourceEvents(sourceType);
            dispatch(fetchSourceEventsSuccess({ type: sourceType, events: mapSourceEvents(sourceType, result.data) }));
            sourceEvents[sourceType] = keyBy(result.data, "id");
          })(),
        );
      }
    });
  });

  await Promise.all(promiseArr);
  return sourceEvents;
};

const getTriggerView = (
  sourceEvents: SourceEventsPayload,
  integrations: Integration[],
  trigger: ExternalTriggerPayload,
) => {
  const hasIntegration = isEmpty(integrations) || getIntegration(trigger.sourceType, integrations);

  switch (trigger.sourceType) {
    case "Google": {
      const googleEvent = sourceEvents[trigger.sourceType]?.[trigger.eventId] as SourceEvent<GoogleEvent>;
      return {
        id: v4(),
        hasIntegration: hasIntegration,
        eventId: trigger.eventId,
        software: trigger.sourceType,
        application: googleEvent.data.application,
        action: googleEvent.data.action,
        fullText: googleEvent.data.fullText,
      };
    }
    case "BrainStorm":
    case "Microsoft": {
      const expressionEvent = sourceEvents[trigger.sourceType]?.[trigger.eventId] as SourceEvent<ExpressionEvent>;
      return {
        id: v4(),
        hasIntegration: hasIntegration,
        eventId: trigger.eventId,
        software: trigger.sourceType,
        application: expressionEvent.data.application,
        action: expressionEvent.data.field,
        fullText: expressionEvent.data.fullText,
        criteria: trigger?.criteria!,
        filter: trigger?.filter!,
      };
    }
  }
};

const getTriggerPayload = (trigger: ExternalTriggerType): ExternalTriggerPayload => {
  switch (trigger.software) {
    case "Google":
      return {
        sourceType: trigger.software,
        eventId: trigger.eventId!,
      };
    case "BrainStorm":
    case "Microsoft":
      return {
        sourceType: trigger.software,
        eventId: trigger.eventId!,
        filter: trigger.filter,
        criteria: trigger.criteria,
      };
  }
};

const getIntegration = (sourceType: SourceType, integrations: Integration[] = []): boolean => {
  return (
    sourceType === "BrainStorm" ||
    !!integrations?.some((item) => item.status === IntegrationStatuses.Active && isEqual(item.type, sourceType))
  );
};
