import { type Dispatch } from "@reduxjs/toolkit";
import { AlertTypes } from "enums";
import { type BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { downloadExcelExport } from "features/Library/Common/utils/performanceUtils";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { setExporting } from "features/Reporting/state/export/exportSlice";

type DetailsFilterParams = BasePerformanceRequestFilterParams & { type: string };

export const getPeopleActivityExport = (params: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await peopleReportingService.activity.getActivityExport(params);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, "People Activity");
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const getPeopleOutreachExport = (params: DetailsFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await peopleReportingService.activity.getPeopleOutreachExport(params);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, params.type);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const getPeopleInteractionExport = (params: DetailsFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await peopleReportingService.activity.getPeopleInteractionExport(params);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, params.type);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const getPeopleAcquisitionExport = (params: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await peopleReportingService.acquisition.getAcquisitionExport(params);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, "Medium Interactions");
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};
