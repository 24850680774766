import React from "react";
import { FormSectionHeader, ValidatedForm } from "../../../../../components/forms";
import { type QuestionSettingsProps } from "./types";

import "./questionSettings.scss";

const inputMasks = new Map<string, RegExp>([
  ["percentageValue", /^[1-9]\d?$|^100$|^$/],
  ["attemptsCount", /^(?:999|[1-9]\d{0,2})$/],
  ["periodValue", /^(?:99|[1-9]\d{0,2})$/],
]);

function QuestionSettings(props: QuestionSettingsProps) {
  const { values, onBlur, onChanged, validatedFieldProps, disabled, mask } = props;
  const inMask = mask === undefined ? inputMasks.get("percentageValue") : mask;

  return (
    <div className={"question-settings-block"}>
      <FormSectionHeader title="Question settings" />

      <ValidatedForm.CheckboxField
        {...validatedFieldProps}
        toggle
        label="Show which answers are correct after completed"
        value={values.showCorrectAnswers}
        propertyName="showCorrectAnswers"
        handleChange={onChanged}
        disabled={disabled}
      />

      <div className={"percentage-block"}>
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          toggle
          label="Percentage required to pass"
          value={values.percentageRequired}
          propertyName="percentageRequired"
          handleChange={onChanged}
          disabled={disabled}
        />

        <ValidatedForm.InputField
          placeholder="0"
          maxLength={3}
          value={values.percentageValue}
          propertyName="percentageValue"
          onBlur={onBlur}
          disabled={disabled || !values.percentageRequired}
          {...validatedFieldProps}
          handleChange={null}
          mask={inMask}
        />
      </div>

      {/* Code will be uncomment in the scope of SAAS-9242 Assessments: Attempts calculation */}

      {/* <div className={"attempts-block"}> */}
      {/*  <ValidatedForm.CheckboxField */}
      {/*    toggle */}
      {/*    label="Maximum attempts allowed" */}
      {/*    value={values.attemptsAllowed} */}
      {/*    propertyName="attemptsAllowed" */}
      {/*    onBlur={onBlur} */}
      {/*    disabled={disabled} */}
      {/*    {...validatedFieldProps} */}
      {/*  /> */}

      {/*  <ValidatedForm.InputField */}
      {/*    placeholder="# of attemps" */}
      {/*    value={values.attemptsCount} */}
      {/*    maxLength={3} */}
      {/*    errorPosition="bottom" */}
      {/*    propertyName="attemptsCount" */}
      {/*    onBlur={onBlur} */}
      {/*    disabled={disabled || !values.attemptsAllowed} */}
      {/*    {...validatedFieldProps} */}
      {/*    handleChange={handleChangeMaskInput} */}
      {/*  /> */}

      {/*  <Divider className="divider-content" /> */}

      {/*  <span>Within</span> */}

      {/*  <ValidatedForm.InputField */}
      {/*    placeholder="Value" */}
      {/*    value={values.periodValue} */}
      {/*    maxLength={2} */}
      {/*    errorPosition="bottom" */}
      {/*    propertyName="periodValue" */}
      {/*    onBlur={onBlur} */}
      {/*    disabled={disabled || !values.attemptsAllowed} */}
      {/*    {...validatedFieldProps} */}
      {/*    handleChange={handleChangeMaskInput} */}
      {/*  /> */}
      {/*  <ValidatedForm.DropdownField */}
      {/*    placeholder="Minutes" */}
      {/*    value={values.periodType} */}
      {/*    options={periodOptions} */}
      {/*    propertyName="periodType" */}
      {/*    onBlur={onBlur} */}
      {/*    disabled={disabled || !values.attemptsAllowed} */}
      {/*    {...validatedFieldProps} */}
      {/*  /> */}
      {/* </div> */}
    </div>
  );
}

export default QuestionSettings;
