import { type ModalWithStepsStep } from "../../../modal/ModalWithSteps";
import "./confirmLicensingStep.scss";
import LicenseConfirmationInfo from "../../../../features/Licensing/ContentAssignmentModalLicensingSteps/LicenseConfirmationModalContent/licenseConfirmationInfo/LicenseConfirmationInfo";
import { type Context } from "enums/licenseContext";
import { type LicenseConfirmationData } from "../../../../interfaces";

export interface ConfirmLicensingStepProps extends ModalWithStepsStep {
  info?: LicenseConfirmationData;
  context?: Context;
  onIsDataValidChange?: (isValid: boolean) => void;
  setIssueLicensePackIds?: (packIds: number[]) => void;
}

const ConfirmLicensingStep: React.FC<ConfirmLicensingStepProps> = (props) => {
  const { info, context, onIsDataValidChange, setIssueLicensePackIds } = props;
  return (
    <div className="confirm-licensing-step">
      <LicenseConfirmationInfo
        licenseContext={context}
        info={info}
        onIsDataValidChange={onIsDataValidChange}
        setIssueLicensePackIds={setIssueLicensePackIds}
      />
    </div>
  );
};

export default ConfirmLicensingStep;
