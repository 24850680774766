import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import PropTypes from "prop-types";

function HideLinkButton(props) {
  const { onClick, isDisabled } = props;

  return <BasicLinkButton text="Hide" iconClassName="fal fa-eye-slash" onClick={onClick} isDisabled={isDisabled} />;
}

HideLinkButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default HideLinkButton;
