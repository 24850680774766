import React, { type ReactNode, type SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { type DropdownProps } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { ViewType, SortingDirection, RouteNames } from "../../../enums";
import { type Filters } from "../../../utils/queryUtils";
import SearchInput from "../../searchInput/SearchInput";
import ItemsView from "../../../views/ItemsView/ItemsView";
import NoSearchResults from "../../noSearchResults";
import { type SearchState } from "../../../features/Application/slices/createSearchSlice";
import UserPacksUtils from "../../../utils/userPacksUtils";
import { ItemsTypes } from "../../../enums/itemsTypes";
import { type PackOverview } from "../../../features/Licensing/Packs/types/state";
import { type ColumnOptions, type Sorting } from "../../../interfaces";
import { type CardsViewerItem } from "../../cardsViewer/types";
import { useRtn } from "hooks/useRtn";
import {
  PeoplePackCard,
  type PeoplePackCardHandlersProps,
  type PeoplePackCardItem,
} from "components/cards/peoplePackCard/PeoplePackCard";
import "./packsList.scss";

export interface PacksListProps {
  viewType: ViewType;
  gridItems: {
    items: Array<any>;
    isLoading: boolean;
    itemsCount: number;
    areAllLoaded: boolean;
  };
  columnOptions: ColumnOptions[];
  loadGridItems: (
    skip: number,
    sortBy: string,
    sortDirection: SortingDirection,
    appliedFilter: Filters,
    searchTerm?: string,
    top?: number,
  ) => any;
  resetGridItems: () => void;
  getListRow: (pack: PackOverview) => ReactNode;
  gridSortingOptions: Array<any>;
  searchState?: SearchState;
  setSearch?: (term: string) => { payload: string; type: string };
  renderNoResults: () => React.ReactElement;
  refreshListEvents: Array<string>;
  withSelection: boolean;
  isReadOnly: boolean;
  selectedPackIds: number[];
  onSelectedPacksChanged: (ids: number[]) => void;
  packCardProps: PeoplePackCardHandlersProps;
}

const PacksList: React.FC<PacksListProps> = (props: PacksListProps) => {
  const selectedViewTypeRef = useRef<ViewType>(props.viewType || ViewType.LIST);
  const [gridDataRequest, setGridDataRequest] = React.useState<any>();

  const defaultSortingColumnName = "License Issued";
  const defaultSortOrder = SortingDirection.Descending;
  const [gridSorting, setGridSorting] = React.useState({
    sortBy: defaultSortingColumnName,
    sortOrder: defaultSortOrder,
  } as Sorting);

  const {
    resetGridItems,
    gridSortingOptions,
    searchState,
    setSearch,
    refreshListEvents,
    withSelection,
    isReadOnly,
    selectedPackIds,
    onSelectedPacksChanged,
  } = props;

  useEffect(() => {
    refreshPacksGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, gridSorting]);

  useEffect(() => {
    return () => {
      resetGridItems();
      setSearch && setSearch("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPacksForGrid = useCallback(
    (loadedPacksCount: number) => {
      gridDataRequest && gridDataRequest.abort();
      const request = props.loadGridItems(
        loadedPacksCount,
        gridSorting.sortBy,
        gridSorting.sortOrder,
        {},
        searchState?.term,
      );
      setGridDataRequest(request);
    },
    [gridDataRequest, gridSorting.sortBy, gridSorting.sortOrder, props, searchState?.term],
  );

  const refreshPacksGrid = useCallback(() => {
    if (selectedViewTypeRef.current === ViewType.GRID) {
      resetGridItems();
      getPacksForGrid(0);
    }
  }, [resetGridItems, getPacksForGrid]);

  useRtn(refreshListEvents, refreshPacksGrid);

  const onViewTypeChange = (view: ViewType) => {
    selectedViewTypeRef.current = view;
    setGridSorting({ sortBy: defaultSortingColumnName, sortOrder: defaultSortOrder });
  };

  const loadPage = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    props.loadGridItems(
      skip,
      sortingColumnName ?? defaultSortingColumnName,
      sortingDirection ?? defaultSortOrder,
      {},
      searchState?.term,
      top,
    );
  };

  const getPackItems = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    if (selectedViewTypeRef.current === ViewType.GRID) {
      getPacksForGrid(props.gridItems.items.length);
    } else {
      resetGridItems();
      loadPage(skip, top, sortingColumnName, sortingDirection);
    }
  };

  const isFiltered = !isEmpty(searchState?.term);

  const noResultsContent = () => (isFiltered ? <NoSearchResults /> : props.renderNoResults());

  const onSortChange = (_: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    resetGridItems();
    const sorting = (data.value as string).split(" ");
    setGridSorting({ sortBy: UserPacksUtils.columnsMap[sorting[0]], sortOrder: sorting[1] as SortingDirection });
  };

  const onSearchChange = async (search: string) => {
    setSearch && setSearch(search);
  };

  const renderCard = (params: CardsViewerItem<PeoplePackCardItem>) => {
    const { onRemoveLicenseClick, licenseRemovalEnabled, issueLicensePermissionPredicate } = props.packCardProps;

    return (
      <PeoplePackCard
        {...params}
        url={`/${RouteNames.purchasedPacks}/${params.item.id}`}
        onRemoveLicenseClick={onRemoveLicenseClick}
        licenseRemovalEnabled={licenseRemovalEnabled}
        issueLicensePermissionPredicate={issueLicensePermissionPredicate}
      />
    );
  };

  const viewState = props.gridItems;

  return (
    <ItemsView
      data-testid="packs-list"
      className="packs-list"
      viewType={selectedViewTypeRef.current}
      onViewTypeChange={onViewTypeChange}
      columnOptions={props.columnOptions}
      renderCard={renderCard}
      disableListViewButton={false}
      getData={getPackItems}
      itemsInlineCount={viewState.itemsCount}
      isLoading={viewState.isLoading}
      isAllDataLoaded={viewState.areAllLoaded}
      items={viewState.items}
      itemsType={ItemsTypes.Pack}
      buildTableBody={props.getListRow}
      sortingColumnName={defaultSortingColumnName}
      sortingDirection={SortingDirection.Descending}
      noResultsContent={noResultsContent()}
      withSelection={withSelection}
      renderSearch={() => (
        <SearchInput
          placeholder="Search for packs..."
          onChange={onSearchChange}
          defaultValue={searchState?.term}
          disabled={!setSearch}
        />
      )}
      sortOptions={gridSortingOptions}
      blur
      onSortChange={onSortChange}
      doNotLoadPersistentViewType
      hideListGridViewSwitcherButton={false}
      listViewRtnEvents={refreshListEvents}
      isSelectDisabled={() => isReadOnly}
      selectedIds={selectedPackIds}
      onSelectedItemsChanged={onSelectedPacksChanged}
      onSelectedListItemsChanged={onSelectedPacksChanged}
    />
  );
};

export default PacksList;
