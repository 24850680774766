import { type FC } from "react";

import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface Props {
  triggerOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const ArchivePackConfirmationDialog: FC<Props> = (props: Props) => {
  return (
    <ActionConfirmationModal
      open={props.triggerOpen}
      title="Archive this pack?"
      withWarningLabel={false}
      confirmLabel="Archive"
      confirmButtonTestId="archive-confirm-button"
      onContinue={props.onContinue}
      onCancel={props.onCancel}
    >
      All Pack licenses will be paused and the license expiration date set to today. Users will not be able to access
      Pack content.
      <br />
      <br />
      All Pack content and associated user data will be preserved and can be restored when the Pack is unarchived.
    </ActionConfirmationModal>
  );
};

export default ArchivePackConfirmationDialog;
