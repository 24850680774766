import { type SortingDirection } from "../enums";
import { type Filters } from "./queryUtils";
import { operators, Filter, type FiltersMap } from "./filterUtils";
import isEmpty from "lodash/isEmpty";

const utils = {
  formatOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection) => {
    const name = sortingColumnName.toLowerCase();

    switch (name) {
      case "status":
        return `isDraft ${sortingDirection}`;
      case "added":
        return `dateCreated ${sortingDirection}`;
      case "modified":
        return `dateModified ${sortingDirection}`;
      case "pack type":
        return `typeId ${sortingDirection}`;
      default:
        return `${name} ${sortingDirection}`;
    }
  },
  formatPrimaryIdFilter: (appliedFilters: Filters) => {
    let filters = { ...appliedFilters };
    let queryParams: FiltersMap = {};

    const filterParameters = [
      {
        key: "primaryId",
        operator: operators.equal,
      },
    ];

    const filterObject = new Filter(filterParameters);
    const filterParam = filterObject.buildFilterQuery(filters);

    if (!isEmpty(filterParam)) {
      queryParams.filter = filterParam;
    }

    return queryParams;
  },
};

export default utils;
