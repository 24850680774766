import { type SortingDirection } from "../enums";

const columnsMap: { [key: string]: string } = {
  title: "pack",
  licenseIssued: "license issued",
  expirationDate: "expiration date",
};

const utils = {
  formatOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection) => {
    const columnName = sortingColumnName.toLowerCase();
    let orderParams;

    switch (columnName) {
      case "pack":
        orderParams = `title ${sortingDirection}`;
        break;
      case "license issued":
        orderParams = `licenseIssued ${sortingDirection}`;
        break;
      case "expiration date":
        orderParams = `expirationDate ${sortingDirection}`;
        break;
      default:
        orderParams = `${columnName} ${sortingDirection}`;
    }

    return orderParams;
  },
  columnsMap,
  mapSortBy: (sortingColumnName: string) => {
    const columnName = sortingColumnName.toLowerCase();
    let orderParams;

    switch (columnName) {
      case "pack":
        orderParams = `title`;
        break;
      case "license issued":
        orderParams = `licenseIssued`;
        break;
      case "expiration date":
        orderParams = `expirationDate`;
        break;
      default:
        orderParams = `${columnName}`;
    }

    return orderParams;
  },
};

export default utils;
