import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";
import cn from "classnames";

import "./lazyLoader.scss";

export default class LazyLoader extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onScroll);
  }

  onScroll = () => {
    if (this.props.loadingData || this.props.isAllDataLoaded) return;

    const scrollAccuracyRate = 5;
    const { scrollTop, clientHeight, scrollHeight } = this.containerRef.current || {};
    const isScrollBottom = scrollTop + clientHeight + scrollAccuracyRate >= scrollHeight;

    if (isScrollBottom) {
      this.props.getData && this.props.getData();
    }
  };

  render() {
    const { children, isFirstLoad, loadingData, isAllDataLoaded, className } = this.props;
    const bottomLoader = cn({
      bottom: !isFirstLoad && loadingData,
    });
    const scrollableContainer = cn(
      "lazy-loader-container",
      "scrollable-content",
      {
        "with-padding": !isAllDataLoaded,
      },
      className,
    );

    return (
      <div className={scrollableContainer} onScroll={this.onScroll} onLoad={this.onScroll} ref={this.containerRef}>
        {children}

        <Loader className={bottomLoader} active={loadingData && !isAllDataLoaded} />
      </div>
    );
  }
}

LazyLoader.defaultProps = {
  isFirstLoad: true,
  isAllDataLoaded: false,
};

LazyLoader.propTypes = {
  getData: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFirstLoad: PropTypes.bool,
  loadingData: PropTypes.bool,
  isAllDataLoaded: PropTypes.bool,
  className: PropTypes.string,
};
