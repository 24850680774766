import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, type DropdownItemProps, Icon } from "semantic-ui-react";
import { DropdownWrapper, FilterSidebar, TextTruncate } from "../../../../../components";
import { type DateRange } from "../../types/performance";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { Tooltip } from "components/common/tooltip";

import "./FlowPerformanceFilter.scss";
import { isEqual } from "lodash";
import { allCustomerAccounts, type Accounts, type AccountType } from "features/Library/Common/utils/performanceUtils";
import { FilterMultiSelectWithWindow } from "components/multiSelect/FilterMultiSelectWithWindow";

type VersionFilter = {
  date: number;
  type: AccountType;
  isDistinct: boolean;
  includeOwnData?: boolean;
  accounts?: any[];
  versionNumber: number;
};

function CreateDateString(range?: DateRange): string {
  if (!range) return "";
  const stringStartDate = dateTimeUtils.formatDateWithHour(range.DATETIME_START);
  const stringEndDate = range.DATETIME_END !== null ? dateTimeUtils.formatDateWithHour(range.DATETIME_END) : "Present";

  return `${stringStartDate} - ${stringEndDate}`;
}

// All the +1/-1 requirements are because when a number is 0 the FilterSidebar's
// change handler returns undefined instead. So counting instead goes 1,2,...
const FilterToSidebarFilter = (num: number) => num + 1;
const SidebarFilterToFilter = (num: number) => num - 1;

export interface Props {
  dateRanges: DateRange[];
  currentFilter: {
    date: number;
    includeOwnData?: boolean;
    accounts?: any[];
    type: AccountType;
    isDistinct: boolean;
    versionNumber: number;
  };
  setCurrentFilter: (filter: {
    date: number;
    includeOwnData?: boolean;
    accounts?: any[];
    type: AccountType;
    isDistinct: boolean;
    versionNumber: number;
  }) => void;
  includeAccountsDropdown: boolean;
  handleReset: () => void;
  disabled?: boolean;
  accounts: any[];
}

export const FlowFilter: FC<Props> = ({
  currentFilter,
  dateRanges,
  setCurrentFilter,
  handleReset,
  accounts,
  includeAccountsDropdown,
  disabled = false,
}) => {
  const [currentBoxFilter, setCurrentBoxFilter] = useState({
    date: FilterToSidebarFilter(currentFilter.date),
    ...(includeAccountsDropdown && { includeOwnData: currentFilter.includeOwnData }),
    ...(includeAccountsDropdown && { accounts: currentFilter.accounts }),
    isDistinct: currentFilter.isDistinct ?? false,
    type: currentFilter.type,
    versionNumber: currentFilter.versionNumber,
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCurrentBoxFilter({
      date: FilterToSidebarFilter(currentFilter.date),
      ...(includeAccountsDropdown && { includeOwnData: currentFilter.includeOwnData }),
      ...(includeAccountsDropdown && { accounts: currentFilter.accounts }),
      isDistinct: currentFilter.isDistinct ?? false,
      type: currentFilter.type,
      versionNumber: currentFilter.versionNumber,
    });
  }, [currentFilter, includeAccountsDropdown]);

  const defaultFilter = useMemo<VersionFilter>(
    () => ({
      date: dateRanges.length,
      ...(includeAccountsDropdown && { includeOwnData: true }),
      ...(includeAccountsDropdown && { accounts: ["All Customer Accounts"] }),
      isDistinct: false,
      type: currentFilter.type,
      versionNumber: currentFilter.versionNumber,
    }),
    [dateRanges.length, includeAccountsDropdown, currentFilter.type, currentFilter.versionNumber],
  );

  const updateFilter = useCallback((newFilter: VersionFilter) => {
    setCurrentBoxFilter(newFilter);
  }, []);

  const handleSubmit = useCallback(
    (newFilter: VersionFilter) => {
      setCurrentFilter({
        date: SidebarFilterToFilter(newFilter.date),
        ...(includeAccountsDropdown && { includeOwnData: newFilter.includeOwnData }),
        ...(includeAccountsDropdown && {
          accounts: newFilter.accounts?.map((account: any) => {
            if (typeof account === "string" || typeof account === "number") return account;
            return account.value;
          }),
        }),
        isDistinct: newFilter.isDistinct,
        type: currentFilter.type,
        versionNumber: newFilter.versionNumber,
      });
      setVisible(false);
    },
    [setCurrentFilter, includeAccountsDropdown, currentFilter.type],
  );

  const handleLocalReset = useCallback(() => {
    setCurrentBoxFilter({
      date: dateRanges.length,
      ...(includeAccountsDropdown && { includeOwnData: true }),
      ...(includeAccountsDropdown && { accounts: ["All Customer Accounts"] }),
      isDistinct: false,
      type: currentFilter.type,
      versionNumber: currentFilter.versionNumber,
    });
    handleReset();
  }, [dateRanges.length, handleReset, includeAccountsDropdown, currentFilter.type, currentFilter.versionNumber]);

  const mappedRanges = useMemo<DropdownItemProps[]>(() => {
    return dateRanges.map<DropdownItemProps>((v, i) => ({
      key: i,
      value: i + 1,
      text: CreateDateString(v),
    }));
  }, [dateRanges]);

  const shouldApplyFilterBeDisabled = useMemo<boolean>(() => {
    // The + 1 is due to currentFilter doing - 1 within FlowPerformance, to get the correct index.
    if (isEqual(currentBoxFilter, { ...currentFilter, date: currentFilter.date + 1 })) return true;
    return false;
  }, [currentBoxFilter, currentFilter]);

  return (
    <div className="flowPerformanceFilter">
      <FilterSidebar
        visible={visible}
        hideFilter={() => setVisible(false)}
        resetFilter={handleLocalReset}
        applyFilter={handleSubmit}
        appliedFilter={{
          date: FilterToSidebarFilter(currentFilter.date),
          ...(includeAccountsDropdown && { includeOwnData: currentFilter.includeOwnData }),
          ...(includeAccountsDropdown && { accounts: currentFilter.accounts }),
          isDistinct: currentFilter.isDistinct,
          type: currentFilter.type,
          versionNumber: currentFilter.versionNumber,
        }}
        defaultFilter={defaultFilter}
        shouldDisableApply={shouldApplyFilterBeDisabled}
        filterOptions={mappedRanges}
        getFilterOptions={() => mappedRanges}
        dis
      >
        <LocalDropdownFilter
          currentBoxFilter={currentBoxFilter}
          updateLocalFilter={updateFilter}
          includeAccountsDropdown={includeAccountsDropdown}
          filterOptions={dateRanges}
          accounts={accounts}
        />
      </FilterSidebar>
      {currentFilter.date >= 0 && (
        <div className="chip">
          <span>Date: </span>
          {CreateDateString(dateRanges[currentFilter.date])}
        </div>
      )}
      <button
        data-testid="flowFilterVisibilityToggle"
        className="flowFilterButton"
        onClick={() => setVisible((v) => !v)}
        type="button"
        disabled={!dateRanges.length || disabled}
      >
        <Icon name="filter" fitted /> Filter
      </button>
    </div>
  );
};

interface FiltersFromSidebarParent {
  updateFilter?: (...args: any[]) => void;
}
interface CustomDropdownProps extends FiltersFromSidebarParent {
  currentBoxFilter: {
    date: number;
    type: AccountType;
    includeOwnData?: boolean;
    accounts?: any[];
    isDistinct: boolean;
    versionNumber: number;
  };
  updateLocalFilter: (newIndex: VersionFilter) => void;
  filterOptions: DropdownItemProps[];
  accounts: any[];
  includeAccountsDropdown: boolean;
}

const LocalDropdownFilter: FC<CustomDropdownProps> = ({
  currentBoxFilter,
  filterOptions,
  updateLocalFilter,
  updateFilter,
  includeAccountsDropdown,
  accounts,
}) => {
  const mappedDistinct = [
    { key: 0, value: false, text: "Total" },
    { key: 1, value: true, text: "Distinct" },
  ];

  const handleChange = useCallback(
    (data: any, type: "date" | "includeOwnData" | "accountsDropdown" | "isDistinct") => {
      if (!updateFilter || !updateLocalFilter) return;
      if (type === "date") {
        updateFilter({
          ...currentBoxFilter,
          date: data.value,
          versionNumber: data.value,
        });
        updateLocalFilter({
          ...currentBoxFilter,
          date: data.value as number,
          versionNumber: data.value as number,
        });
      }
      if (type === "includeOwnData") {
        updateFilter({
          ...currentBoxFilter,
          ...(includeAccountsDropdown && { includeOwnData: !currentBoxFilter.includeOwnData }),
        });
        updateLocalFilter({
          ...currentBoxFilter,
          ...(includeAccountsDropdown && { includeOwnData: !currentBoxFilter.includeOwnData }),
        });
      }
      if (type === "accountsDropdown") {
        updateFilter({
          ...currentBoxFilter,
          ...(includeAccountsDropdown && { accounts: data }),
        });
        updateLocalFilter({
          ...currentBoxFilter,
          ...(includeAccountsDropdown && { accounts: data }),
        });
      }
      if (type === "isDistinct") {
        updateFilter({
          ...currentBoxFilter,
          isDistinct: data.value,
        });
        updateLocalFilter({
          ...currentBoxFilter,
          isDistinct: data.value,
        });
      }
    },
    [updateFilter, updateLocalFilter, includeAccountsDropdown, currentBoxFilter],
  );

  return (
    <>
      <label>Date</label>
      <DropdownWrapper
        items={filterOptions}
        className={"flowPerformanceFilter__dropdown"}
        selection
        fluid
        onChange={(_, data) => handleChange(data, "date")}
        value={currentBoxFilter.date}
        trigger={
          <Tooltip
            inverted
            target={<TextTruncate>{filterOptions?.[SidebarFilterToFilter(currentBoxFilter.date)]?.text}</TextTruncate>}
            content={filterOptions?.[SidebarFilterToFilter(currentBoxFilter.date)]?.text}
          />
        }
      />
      {includeAccountsDropdown && (
        <>
          <label className={"accounts"}>{"Accounts"}</label>
          {/* @ts-ignore */}
          <FilterMultiSelectWithWindow
            propertyName={"accounts"}
            placeholder={"All Customer Accounts"}
            items={accounts}
            filter={currentBoxFilter as any}
            updateFilter={(data: any) => handleChange(data.accounts, "accountsDropdown")}
            initialCheckedItems={[allCustomerAccounts]}
            searchPredicate={(item: Accounts, term: string) => item.text.toLowerCase().includes(term.toLowerCase())}
            // Temporary
            getDescription={(item: Accounts) => item?.text || ""}
          />
          <Checkbox
            checked={currentBoxFilter.includeOwnData}
            onChange={(_, data) => handleChange(data, "includeOwnData")}
            label="Include My Account Data"
          />
        </>
      )}
      <div className={"flowPerformanceFilter__distinct"}>
        <label>
          Distinct
          <Tooltip
            target={<Icon className="info circle" />}
            hideOnScroll
            showAlways
            content={`Select “Total” to include all user activities, even repeats. Choose “Distinct” to exclude repeated activities by the same user.`}
            position="top center"
            hoverable
          />
        </label>
        <DropdownWrapper
          items={mappedDistinct}
          selection
          fluid
          onChange={(_, data) => handleChange(data, "isDistinct")}
          value={currentBoxFilter.isDistinct}
        />
      </div>
    </>
  );
};
