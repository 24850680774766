import React, { useMemo } from "react";
import { Input, Grid } from "semantic-ui-react";
import { get, partial } from "lodash";

import { type ExpressionTrigger } from "../../../../../../types/externallTriggersTypes";
import { type DependentFieldsBase } from "./types";
import { DropdownTrigger } from "../../../../../../../../../../components/dropdowns/dropdownTrigger/DropdownTrigger";

const criteriaRegex = /^([1-9][0-9]{0,9}|^$)$/; // NOSONAR

const ExpressionFields = (props: DependentFieldsBase) => {
  const { sourceEvents, isReadOnly, handleChange, item } = props;

  const onCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    if (criteriaRegex.exec(data.value)) {
      handleChange("criteria", event, data);
    }
  };

  const filterOptions = useMemo(() => {
    return get(sourceEvents, `${item.application}.${item.action}.operators`) || [];
  }, [sourceEvents, item]);

  return (
    <>
      <Grid.Column>
        <DropdownTrigger
          blur
          fluid
          items={filterOptions}
          placeholder="Select Filter"
          disabled={!item.action || isReadOnly}
          selected={(item as ExpressionTrigger).filter}
          handleOptionChange={partial(props.handleChange, "filter")}
        />
      </Grid.Column>
      <Grid.Column>
        <Input
          fluid
          maxLength={9}
          placeholder="Enter Criteria"
          onChange={onCriteriaChange}
          value={(item as ExpressionTrigger).criteria || ""}
          disabled={!(item as ExpressionTrigger).filter || isReadOnly}
        />
      </Grid.Column>
    </>
  );
};

export default ExpressionFields;
