import { type FC } from "react";
import { Radio } from "semantic-ui-react";
import cn from "classnames";

import { type ShouldNotifyWithProgress } from "../../../../features/SystemNotifications/types";
import { type SwitchProps } from "./types";

import styles from "./switchWithProgress.module.scss";
import { type SwitchEntityTypes } from "../../../../enums/switchEntityTypes";

export interface SwitchWithProgressProps extends SwitchProps<ShouldNotifyWithProgress> {
  entityType: SwitchEntityTypes;
}

export const SwitchWithProgress: FC<SwitchWithProgressProps> = ({
  onNotifyConfigChange,
  config,
  isDisabled,
  entityType,
}) => {
  return (
    <div className={cn(styles.root, { [styles.disabled]: isDisabled })}>
      <div className={styles["switch-header"]}>Notify users</div>
      <div className={styles["switch-description"]}>
        Select which users to notify of changes based on their status. Notify users who:
      </div>
      <div className={styles["switch-body"]}>
        <Radio
          label={`Are in progress with this ${entityType}`}
          onChange={(_, { checked }) => onNotifyConfigChange({ key: "shouldNotifyInProgress", value: checked })}
          checked={!isDisabled && config.shouldNotifyInProgress}
          toggle
        />
        <Radio
          label={`Have completed this ${entityType}`}
          onChange={(_, { checked }) => onNotifyConfigChange({ key: "shouldNotifyCompleted", value: checked })}
          checked={!isDisabled && config.shouldNotifyCompleted}
          toggle
        />
      </div>
    </div>
  );
};
