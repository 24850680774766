import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type PackAccountType } from "../../PackDetails/Visibility/AccountTypes/types";

export const initialState: FetchingItemsState<PackAccountType> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const packAccountTypesVisibilitySlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.PackAccountTypesVisibility,
    name: "packAccountTypesVisibility",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = packAccountTypesVisibilitySlice.actions;

export default packAccountTypesVisibilitySlice.reducer;

export type packAccountTypesVisibilitySliceType = ReturnType<typeof packAccountTypesVisibilitySlice.reducer>;
