import { clone, difference, isEmpty, noop } from "lodash";
import { Component, createRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import { withRouter, type WithRouterProps } from "../../../adapters/withRouter/withRouter";
import { WizardWrapper as Wizard } from "../../WizardWrapper";
import { fetchAccountTypes, fetchGlobalRoles } from "./state/thunks/createAccountThunk";
import * as notificationsActions from "../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import Contacts from "./Contacts/Contacts";
import accountsDataService from "../services/accountsDataService";
import { type AccountConfigurationInfo, AccountTypes, type AddOn, type SavingAddOn } from "../types";
import { bindAction, type DistributedOpUpdateParams, type IObservable, type MessageIdsType } from "../../../interfaces";
import { type AppDispatch, type RootState } from "../../Application/globaltypes/redux";
import type NewContact from "../../../interfaces/NewContact";
import * as contractTypesThunk from "../state/thunks/contractTypesThunk";
import { packsModalSearchSelector, setTerm } from "../../Licensing/Packs/state/slices/availablePacksSearchSlice";
import { getFilterOptions } from "../../Licensing/Packs/state/thunks/packsFiltersThunk";
import { fetchavAilablePacks } from "../../Licensing/Packs/state/thunks/availablePacksThunk";
import { reset } from "../../Licensing/Packs/state/slices/availablePacksSlice";
import navigationUtils from "../../../utils/navigationUtils";
import { type INotifyByEmailController } from "../../../components/notifyStep/notifyByEmailController";
import { withNotifyConfig, type WithNotifyConfigProps } from "../../SystemNotifications/containers/withNotifyConfig";
import { initialNotifyConfigDefault } from "../../SystemNotifications/config";
import { type NotifySettings } from "../../SystemNotifications/types";
import {
  NotifyWizardStep,
  PackAssignmentModal,
  PreventTransitionPrompt,
  RestrictedResource,
} from "../../../components";
import { type AssignAccountPack } from "../../../components/assignmentModals/packAssignmentModal/types";
import { NotifyStepSwitch } from "../../../components/notifyStep/NotifyStepSwitch";
import AccessRestrictedMessage from "../../../components/restrictedRoute/AccessRestrictedMessage";
import { RolePermissions, RouteNames, SortOptions, TemplateTypes, ViewType } from "../../../enums";
import { type Filters } from "../../../utils/queryUtils";
import Restricted from "../../Application/Restricted";
import dataService from "../../Application/services/dataServices/dataService";
import * as accountsEventTypes from "../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import RtnEventsEmitter from "../../Application/services/realTimeNotification/rtnEventsEmitter";
import {
  appliedFiltersSelector as availablePacksAppliedFiltersSelector,
  availablePacksFiltersSlice,
  filterOptionsSelector as availablePacksFilterOptionsSelector,
  isLoadingSelector as availablePacksFilterIsLoadingSelector,
  resetAppliedFilter as availablePacksResetAppliedFilter,
  setAppliedFilter as availablePacksSetAppliedFilter,
} from "../../Licensing/Packs/state/slices/availablePacksFiltersSlice";
import { addPacksToAccount } from "../../Licensing/Packs/state/thunks/accountPacksThunk";
import { type AvailablePacksRequest } from "../../Licensing/Packs/types/requests";
import { AccountConfiguration } from "../Configuration/AccountConfiguration";
import AddOnsConfiguration from "../EditAccount/AddOnsConfiguration/AddOnsConfiguration";
import { EditingAddOn } from "../EditAccount/EditAddOns/EditAddOns";
import { fetchAddOns, updateAccountAddOns } from "../EditAccount/state/editAccountActions";
import CreateAccountPacksList from "./CreateAccountPacksList/CreateAccountPacksList";
import { fetchParentAccounts } from "../state/thunks/parentAccountThunk";
import { FeatureFlags } from "featureFlags";
import { type LDProps } from "features/LDProps";
import CreateAccountTemplates from "./Templates/CreateAccountTemplates";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import ConfirmTemplatesPacksModal from "../Templates/ConfirmTemplatesPacksModal";
import { Observable } from "utils";
import { type PackAccountsContextItem } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { addTemplatesThunk, saveTemplatesPacks } from "../state/thunks/AccountTemplatesThunk";
import { CreateAccountSteps, CreateAccountStepsWithTemplates } from "./constants";

export interface CreateAccountState {
  orderBy: SortOptions;
  isAccountConfigValid: boolean;
  isAccountConfigDirty: boolean;
  isWizardFinished: boolean;
  contacts: Array<NewContact>;
  editingAddOns: Array<EditingAddOn>;
  accountConfiguration: AccountConfigurationInfo;
  selectedPacks: Array<AssignAccountPack>;
  getAvailablePacksForModalRequest?: { abort: Function };
  createdAccountId: number;
  activeIndex: number;
  areTemplatesConfirmed: boolean;
  packsStep: {
    showAddPacksModal: boolean;
    arePacksConfirmed: boolean;
  };
  notifyStep: {
    isValid: boolean;
    isLoaded: boolean;
  };
  isAccountIdPending: boolean;
}
export type CreateAccountProps = PropsFromRedux &
  WithRouterProps &
  WithNotifyConfigProps<NotifySettings> & {
    prefilled?: {
      name: string;
      logoUri: Nullable<string>;
    };
    onRemoveName?: () => void;
  } & LDProps;

export class CreateAccount extends Component<CreateAccountProps, CreateAccountState> {
  private readonly wizardSteps: {
    Configure: number;
    Contacts: number;
    Templates?: number;
    Packs: number;
    AddOn: number;
    Notify: number;
  };
  private readonly showTemplatesStep: boolean;
  private readonly onTriggerConfirmLicensingObserver: IObservable<
    (onConfirm: (packs: PackAccountsContextItem[]) => void, templateIds: number[]) => void
  >;

  constructor(props: any) {
    super(props);
    const accountTemplatesEnabled = !!this.props.flags?.[FeatureFlags.GroupTemplates];
    const hasTemplatesViewPermissions = this.props.userPermissions.includes(RolePermissions.TemplatesView);
    const shouldShowTemplatesTab = accountTemplatesEnabled && hasTemplatesViewPermissions;

    this.showTemplatesStep = shouldShowTemplatesTab;
    this.onTriggerConfirmLicensingObserver = new Observable<
      (onConfirm: (packs: PackAccountsContextItem[]) => void, templateIds: number[]) => void
    >();
    this.wizardSteps = shouldShowTemplatesTab ? CreateAccountStepsWithTemplates : CreateAccountSteps;
  }

  state = {
    orderBy: SortOptions.CreatedDateDesc,
    isAccountConfigValid: false,
    isAccountConfigDirty: false,
    isWizardFinished: false,
    contacts: [],
    editingAddOns: [],
    accountConfiguration: {} as AccountConfigurationInfo,
    selectedPacks: [],
    getAvailablePacksForModalRequest: undefined,
    createdAccountId: 0,
    areTemplatesConfirmed: false,
    packsStep: {
      showAddPacksModal: false,
      arePacksConfirmed: false,
    },
    notifyStep: {
      isValid: false,
      isLoaded: false,
    },
    activeIndex: 0,
    isAccountIdPending: false,
  } as CreateAccountState;

  showAddOnsStep =
    this.props.hasGlobalAccess ||
    this.props.accountTypeId === AccountTypes.StrategicPartner ||
    this.props.accountTypeId === AccountTypes.ChannelPartner;

  resolvePacksConfirmationProgress?: () => void;
  rejectPacksConfirmationProgress?: () => void;

  NotifyByEmailControllerRef = createRef<INotifyByEmailController>();

  componentDidMount() {
    this.props.onFetchAccountTypes();
    this.props.fetchContractTypes();

    RtnEventsEmitter.subscribeOnce(accountsEventTypes.AccountCreatedSuccess, this.handleAccountCreatedEvent);
  }

  componentDidUpdate(prevProps: CreateAccountProps) {
    const { addOns } = this.props;
    if (prevProps.addOns !== addOns) {
      this.setState({
        editingAddOns: addOns.items.map((addOn: AddOn) => new EditingAddOn(addOn)),
      });
    }
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(accountsEventTypes.AccountCreatedSuccess, this.handleAccountCreatedEvent);
  }

  handleAccountCreatedEvent = (event: { payload: { id: number } }) => {
    this.setState({ isAccountIdPending: false });
    this.onAccountCreated(event.payload.id);
  };

  onCancel = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, RouteNames.linkedAccountsManagement);
  };

  updateIsAccountConfigValid = (isAccountConfigValid: boolean) => this.setState({ isAccountConfigValid });

  updateIsAccountConfigDirty = (isAccountConfigDirty: boolean) => this.setState({ isAccountConfigDirty });

  fetchParentAccounts = (accountTypeId: number) => this.props.onFetchParentAccounts(accountTypeId);

  loadPacksForModal = (
    assignedAccountId: number,
    ownerAccountId: number,
    skip: number,
    orderBy: string,
    filters?: Filters,
  ) => {
    this.state.getAvailablePacksForModalRequest && this.state.getAvailablePacksForModalRequest?.abort();
    const request = this.props.accountPacksActions.fetchAccountPacksGrid({
      accountId: ownerAccountId,
      assignedAccountId: assignedAccountId,
      skip: skip,
      orderBy: orderBy,
      filters: { ...filters },
      searchTerm: this.props.packsSearch.term,
    });
    this.setState({ getAvailablePacksForModalRequest: request });
  };

  resetPackItems = () => {
    this.props.accountPacksActions.resetAccountPacksGrid();
  };

  resetPacks = async (filters: Filters) => {
    this.onSelectedItemsChanged([]);
    const { accountId, accountPacksActions } = this.props;
    const { orderBy, createdAccountId } = this.state;
    accountPacksActions.resetAccountPacksGrid();
    this.loadPacksForModal(createdAccountId, accountId, 0, orderBy, filters);
  };

  resetPacksFilter = () => {
    this.props.accountPacksActions.resetPacksFilter();
    this.resetPacks({});
  };

  applyPacksFilter = (filter: Filters) => {
    this.resetPacks(filter);
    this.props.accountPacksActions.applyPacksFilter(filter);
  };

  getPacksFilterOptions = () => this.props.accountPacksActions.getPacksFilterOptions();

  submitAccountConfiguration: any;

  bindSubmitForm = (submitForm: any) => {
    this.submitAccountConfiguration = submitForm;
  };

  updateContacts = (contacts: NewContact[]) => {
    this.setState({ contacts });
  };

  onSubmitAccountConfiguration = (accountConfiguration: any) => this.setState({ accountConfiguration });

  onAccountCreated = (id: number) => {
    this.setState({ createdAccountId: id }, () => {
      !this.showTemplatesStep && this.resetPacks({});
      this.props.fetchAddOns(id);
    });
  };

  onFinish = async () => {
    await this.notifyUser();
    this.setState({ isWizardFinished: true }, () => this.props.navigate("/accounts/linked"));
  };

  createAccount = async () => {
    const { accountConfiguration } = this.state;
    const {
      backgroundTasksActions: { addOperationDistributedOp },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    const contacts = this.state.contacts.map((contact: NewContact) => {
      const { roles, title, ...contactInfo } = contact;

      return {
        ...contactInfo,
        roleIds: roles ? roles.map((x) => x.id) : [],
        jobTitle: title,
      };
    });

    const account = {
      ...accountConfiguration,
      alias: isEmpty(accountConfiguration.alias) ? null : accountConfiguration.alias,
      contractTypeId: accountConfiguration.contractTypeId === -1 ? null : accountConfiguration.contractTypeId,
      contacts,
      timeZone: isEmpty(accountConfiguration.timeZone) ? null : accountConfiguration.timeZone,
    };

    this.setState({ isAccountIdPending: true });

    const params: DistributedOpUpdateParams = {
      id: "CreateAccount",
      title: `Creating '${account.name}' account`,
      indeterminate: true,
      getOperationProps: async () => {
        const data = await accountsDataService.createAccount(account);

        return data;
      },
      successTransientMessage: "Account has been created successfully.",
      failureTransientMessage: "Account creation failed!",
    };

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: addOperationDistributedOp,
      sendTransientNotification,
    });
  };

  onProgressAsync = async (activeIndex: number, nextIndex: number) => {
    const result = [];
    const { isAccountConfigValid, editingAddOns } = this.state;

    if (activeIndex === this.wizardSteps.Configure) {
      await this.submitAccountConfiguration();
      if (!isAccountConfigValid) {
        result.push("cannot submit invalid form");
      }
    } else if (activeIndex === this.wizardSteps.Contacts) {
      await this.createAccount();
    } else if (
      this.showAddOnsStep &&
      activeIndex === this.wizardSteps.AddOn &&
      editingAddOns.some((addOn) => addOn.isChanged)
    ) {
      const enabledAddOns = editingAddOns
        .filter((addOn) => addOn.isEnabled)
        .map((addOn) => ({ id: addOn.id, expirationDate: addOn.expirationDate }));
      this.updateAddOns(enabledAddOns);
    }
    this.setState({ activeIndex: nextIndex });
    return result;
  };

  onRegressAsync = (activeIndex: number, nextIndex: number): Promise<any> => {
    this.setState({ activeIndex: nextIndex });
    const notifyTab = this.props.hasGlobalAccess ? this.wizardSteps.Notify : this.wizardSteps.AddOn;
    if (activeIndex !== notifyTab || !this.state.notifyStep.isLoaded) {
      return Promise.resolve();
    }
    return this.NotifyByEmailControllerRef.current!.saveChanges();
  };

  /* istanbul ignore next */
  canProceed = (currentIndex: number) => {
    const { createdAccountId, selectedPacks } = this.state;
    const { selectedTemplates, addTemplatePacksToAccount, addTemplatesToAccount } = this.props;

    if (currentIndex > this.wizardSteps.Contacts && createdAccountId === 0) {
      return Promise.resolve(false);
    }

    if (currentIndex === this.wizardSteps.Templates && selectedTemplates.length !== 0) {
      this.onTriggerConfirmLicensingObserver.notify((packs: PackAccountsContextItem[]) => {
        this.setState({ areTemplatesConfirmed: true });
        this.resolvePacksConfirmationProgress?.();
        addTemplatePacksToAccount(createdAccountId, packs);
        addTemplatesToAccount(createdAccountId, selectedTemplates, true);
      }, selectedTemplates);

      return new Promise((resolve) => {
        this.resolvePacksConfirmationProgress = () => resolve(true);
      });
    }

    if (currentIndex === this.wizardSteps.Packs && selectedPacks.length !== 0) {
      this.setState({ packsStep: { ...this.state.packsStep, showAddPacksModal: true } });

      return new Promise((resolve) => {
        this.resolvePacksConfirmationProgress = () => resolve(true);
        this.rejectPacksConfirmationProgress = () => resolve(false);
      });
    }

    return Promise.resolve(true);
  };

  canRecede = (_currentIndex: number, nextIndex: number) => {
    const {
      createdAccountId,
      areTemplatesConfirmed,
      packsStep: { arePacksConfirmed },
      isAccountIdPending,
    } = this.state;
    const canRecede =
      nextIndex > this.wizardSteps.Contacts &&
      (nextIndex !== this.wizardSteps.Templates || (!areTemplatesConfirmed && !arePacksConfirmed)) &&
      (nextIndex !== this.wizardSteps.Packs || !arePacksConfirmed);

    return (createdAccountId === 0 || canRecede) && !isAccountIdPending;
  };

  onSelectedItemsChanged = (ids: number[]) => {
    const selectedPacks: AssignAccountPack[] = ids.map((id) => {
      const pack = this.state.selectedPacks.find((sp) => sp.packId === id);
      if (pack) {
        return pack;
      }
      return { packId: id, isRequested: false, edited: false };
    });
    this.setState({ selectedPacks: selectedPacks });
  };

  onSearchChanged = (searchTerm: string) => {
    this.setState({ selectedPacks: [] });
    return this.props.accountPacksActions.setPacksSearch(searchTerm);
  };

  onSortOptionsChanged = (value: any) => {
    this.setState({ orderBy: value });
  };

  updateAddOns = async (addOns: Array<SavingAddOn>) => {
    const { createdAccountId } = this.state;
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    const params = {
      id: "UpdateAccountAddOns",
      title: "Assign account Add-Ons",
      indeterminate: true,
      getMessageIds: async (): Promise<MessageIdsType> => {
        const messageId = await accountsDataService.updateAddOns(createdAccountId, addOns);
        this.updateAddOnsState(addOns);
        return [messageId];
      },
      successTransientMessage: "Account Add-Ons have been assigned successfully!",
      failureTransientMessage: "Account Add-Ons have not been assigned!",
    };
    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  updateAddOnsState = (addons: Array<SavingAddOn>) => {
    const updatedAddOns = this.state.editingAddOns.map((addOn) => clone(addOn));

    updatedAddOns.forEach((addOn) => {
      const savedAddOn = addons.find((a) => a.id === addOn.id);
      if (savedAddOn) {
        addOn.origin.isEnabled = true;
        addOn.origin.expirationDate = savedAddOn.expirationDate;
      } else {
        addOn.origin.isEnabled = false;
        addOn.origin.expirationDate = null;
      }
    });

    this.setState({ editingAddOns: updatedAddOns });
  };

  shouldPreventTransition = () => {
    const {
      isWizardFinished,
      isAccountConfigDirty,
      contacts,
      accountConfiguration,
      createdAccountId,
      editingAddOns,
      selectedPacks,
    } = this.state;

    return (
      !isWizardFinished &&
      ((createdAccountId === 0 && (isAccountConfigDirty || contacts.length > 0 || !isEmpty(accountConfiguration))) ||
        this.props.selectedTemplates.length > 0 ||
        selectedPacks.length > 0 ||
        editingAddOns.some((addOn) => addOn.isChanged))
    );
  };

  notifyUser = async () => {
    const notificationSettings = await this.NotifyByEmailControllerRef.current!.getSettings(
      this.props.notifyConfigPayload.shouldNotify,
      this.props.notifyConfigPayload.communicationChannel,
    );
    if (!notificationSettings.suppressNotification) {
      const { contacts, createdAccountId } = this.state;
      const mainContact = contacts.find((x) => x.isMain);
      await dataService.notifyUserByEmail(
        mainContact?.email,
        createdAccountId,
        TemplateTypes.AccountCreated,
        notificationSettings,
      );
    }
  };

  onNotifyStepLoaded = (controller: INotifyByEmailController) => {
    // @ts-ignore
    this.NotifyByEmailControllerRef.current = controller;
    this.setState({ notifyStep: { ...this.state.notifyStep, isLoaded: true } });
  };

  onNotifyStepValidChange = (isValid: boolean) => {
    this.setState({ notifyStep: { ...this.state.notifyStep, isValid } });
  };

  onConfirmAddPacksModal = (packs: AssignAccountPack[]) => {
    this.setState({ packsStep: { ...this.state.packsStep, showAddPacksModal: false, arePacksConfirmed: true } });
    this.resolvePacksConfirmationProgress?.();
    const { createdAccountId } = this.state;
    this.props.addPacksToAccount(packs, createdAccountId);
  };

  getSteps = () => {
    const {
      isAccountConfigValid,
      accountConfiguration,
      editingAddOns,
      notifyStep,
      contacts,
      selectedPacks,
      createdAccountId,
      orderBy,
    } = this.state;
    const {
      accountTypes,
      parentAccounts,
      globalRoles,
      onFetchGlobalRoles,
      addOns,
      isLoadingAccountTypes,
      isLoadingParentAccounts,
      isLoadingGlobalRoles,
      isLoadingContractTypes,
      accountId,
      isMobo,
      hasGlobalAccess,
      contractTypes,
      packsFilterOptions,
      packsAppliedFilter,
      packsFilterIsLoading,
      availablePacks,
      packsSearch,
      notifyConfig,
      notifyConfigPayload: { setNotifyConfig, shouldNotify, communicationChannel },
    } = this.props;
    return (
      <>
        <Wizard.Step label="Configuration" isLocked={!isAccountConfigValid} required>
          <AccountConfiguration
            accountTypes={accountTypes}
            parentAccounts={parentAccounts}
            contractTypes={contractTypes}
            currentConfiguration={accountConfiguration}
            isLoadingAccountTypes={isLoadingAccountTypes}
            isLoadingParentAccounts={isLoadingParentAccounts}
            isLoadingContractTypes={isLoadingContractTypes}
            onIsValidChange={this.updateIsAccountConfigValid}
            bindSubmitForm={this.bindSubmitForm}
            onSubmit={this.onSubmitAccountConfiguration}
            onDirtyChanged={this.updateIsAccountConfigDirty}
            accountId={accountId}
            isMobo={isMobo}
            hasGlobalAccess={hasGlobalAccess}
            onFetchParentAccounts={this.fetchParentAccounts}
            prefilled={this.props.prefilled}
            onRemoveName={this.props.onRemoveName}
          />
        </Wizard.Step>
        <Wizard.Step label="Contacts" isLocked={contacts.length === 0} required>
          <Contacts
            contacts={contacts}
            onContactsChanged={this.updateContacts}
            globalRoles={globalRoles}
            accountTypeId={accountConfiguration.accountTypeId}
            onFetchGlobalRoles={onFetchGlobalRoles}
            isLoadingGlobalRoles={isLoadingGlobalRoles}
          />
        </Wizard.Step>
        {this.showTemplatesStep && (
          <Wizard.Step label="Templates" isLocked={createdAccountId === 0}>
            <CreateAccountTemplates accountId={createdAccountId} />
          </Wizard.Step>
        )}
        <Wizard.Step label="Packs" isLocked={createdAccountId === 0}>
          <CreateAccountPacksList
            onSelectedItemsChanged={this.onSelectedItemsChanged}
            createdAccountId={createdAccountId}
            selectedPacks={selectedPacks}
            accountId={accountId}
            packsGrid={availablePacks}
            loadListItems={this.loadPacksForModal}
            loadGridItems={this.loadPacksForModal}
            resetGridItems={this.resetPackItems}
            isReadOnly={false}
            searchState={packsSearch}
            setSearch={this.onSearchChanged}
            gridOrderBy={orderBy}
            setGridOrderBy={this.onSortOptionsChanged}
            filter={{
              filterOptions: packsFilterOptions,
              appliedFilter: packsAppliedFilter,
              applyFilter: this.applyPacksFilter,
              resetFilter: this.resetPacksFilter,
              fetchFilterOptions: this.getPacksFilterOptions,
              isLoading: packsFilterIsLoading,
            }}
          />
        </Wizard.Step>
        {this.showAddOnsStep && (
          <Wizard.Step label="Add-ons" isLocked={addOns.isLoading}>
            <Restricted
              permissions={[RolePermissions.AddonsManage]}
              permissionPredicate={(userPermissionsList, permissionsList) =>
                difference(permissionsList, userPermissionsList).length === 0
              }
              placeholder={<AccessRestrictedMessage />}
            >
              <RestrictedResource isAuthorized={addOns.isAccessAuthorized}>
                <AddOnsConfiguration
                  addOns={editingAddOns}
                  onMount={() => createdAccountId !== 0 && this.props.fetchAddOns(createdAccountId)}
                  onChangeAddons={(addons) => this.setState({ editingAddOns: addons })}
                  isLoading={addOns.isLoading}
                  isReadOnly={false}
                />
              </RestrictedResource>
            </Restricted>
          </Wizard.Step>
        )}
        <Wizard.Step label="Notify" isLocked={shouldNotify && !notifyStep.isValid} preRender>
          <NotifyWizardStep
            templateType={TemplateTypes.AccountCreated}
            onLoaded={this.onNotifyStepLoaded}
            onValidChange={this.onNotifyStepValidChange}
            shouldNotify={shouldNotify}
            communicationChannel={communicationChannel}
            hideCustomSender
            renderSwitch={(switchProps) => (
              <NotifyStepSwitch
                config={notifyConfig}
                onNotifyConfigChange={setNotifyConfig}
                switchProps={switchProps}
              />
            )}
          />
        </Wizard.Step>
      </>
    );
  };

  render() {
    const {
      selectedPacks,
      createdAccountId,
      packsStep: { showAddPacksModal },
      activeIndex,
    } = this.state;
    const { accountId, availablePacks } = this.props;

    return (
      <>
        <Wizard
          id="create-account-wizard"
          className="create-account"
          title="Create Account"
          onProgressAsync={this.onProgressAsync}
          onCancel={this.onCancel}
          onFinishAsync={this.onFinish}
          onRegressAsync={this.onRegressAsync}
          finishButtonLabel="Finish"
          isSaveInProgress={false}
          canProceedAsync={this.canProceed}
          canRecedeAsync={this.canRecede}
          isPreviousDisabled={!this.canRecede(0, activeIndex - 1)}
        >
          {this.getSteps().props.children.filter((x: any) => !!x)}
        </Wizard>
        <PreventTransitionPrompt
          when={this.shouldPreventTransition()}
          title="Exit Without Saving?"
          message="Are you sure you want to exit without saving this account? All information entered will be lost."
        />
        <PackAssignmentModal
          onConfirm={this.onConfirmAddPacksModal}
          onCancel={() => {
            this.setState({ packsStep: { ...this.state.packsStep, showAddPacksModal: false } });
            this.rejectPacksConfirmationProgress?.();
          }}
          showModal={showAddPacksModal}
          selectAccountId={createdAccountId}
          accountId={accountId}
          loadPacksForModal={noop}
          loadPackForModal={noop}
          applyFilter={noop}
          resetFilter={noop}
          appliedFilter={noop}
          filterOptions={noop}
          fetchFilterOptions={noop}
          resetGrid={noop}
          isLoading={availablePacks.isLoading}
          areAllLoaded={availablePacks.areAllLoaded}
          items={availablePacks.items}
          itemsCount={availablePacks.itemsCount}
          selectedViewType={ViewType.GRID}
          isPacksModalFiltered
          predefinedPackIds={selectedPacks.map((pack) => {
            return pack.packId;
          })}
        />
        <ConfirmTemplatesPacksModal onTriggerModalObserver={this.onTriggerConfirmLicensingObserver} />
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { creation, contractTypes, edit } = state.accounts;
  const { userProfile } = state;

  return {
    accountTypes: creation.accountTypes.items,
    parentAccounts: state.accounts.parentAccounts.items,
    globalRoles: creation.globalRoles.items,
    contractTypes: contractTypes.items,
    isLoadingAccountTypes: creation.accountTypes.isLoading,
    isLoadingParentAccounts: state.accounts.parentAccounts.isLoading,
    isLoadingGlobalRoles: creation.globalRoles.isLoading,
    isLoadingContractTypes: contractTypes.isLoading,
    accountId: userProfile.accountId,
    isMobo: userProfile.isMobo,
    addOns: edit.addOns,
    hasGlobalAccess: userProfile.hasGlobalAccess,
    accountTypeId: userProfile.accountTypeId,
    availablePacks: state.packs.packsModal,
    packsSearch: packsModalSearchSelector(state),
    packsFilterOptions: availablePacksFilterOptionsSelector(state),
    packsAppliedFilter: availablePacksAppliedFiltersSelector(state),
    packsFilterIsLoading: availablePacksFilterIsLoadingSelector(state),
    userPermissions: state.userProfile.permissions,
    selectedTemplates: state.accounts.availableTemplates.overview.selected,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFetchAccountTypes: bindAction(fetchAccountTypes, dispatch),
    onFetchParentAccounts: bindAction(fetchParentAccounts, dispatch),
    onFetchGlobalRoles: bindAction(fetchGlobalRoles, dispatch),
    fetchAddOns: bindAction(fetchAddOns, dispatch),
    updateAccountAddOns: bindAction(updateAccountAddOns, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
    accountPacksActions: {
      resetAccountPacksGrid: () => dispatch(reset()),
      fetchAccountPacksGrid: (requestData: AvailablePacksRequest) => dispatch(fetchavAilablePacks(requestData)),
      applyPacksFilter: (filters: any) => dispatch(availablePacksSetAppliedFilter(filters)),
      resetPacksFilter: () => dispatch(availablePacksResetAppliedFilter()),
      getPacksFilterOptions: () => dispatch(getFilterOptions(availablePacksFiltersSlice)),
      setPacksSearch: (searchTerm: string) => dispatch(setTerm(searchTerm)),
    },
    fetchContractTypes: bindAction(contractTypesThunk.fetchContractTypes, dispatch),
    addPacksToAccount: bindAction(addPacksToAccount, dispatch),
    addTemplatePacksToAccount: bindAction(saveTemplatesPacks, dispatch),
    addTemplatesToAccount: bindAction(addTemplatesThunk, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(
  withNotifyConfig(withRouter(withLDConsumer()(CreateAccount)), initialNotifyConfigDefault),
);

export default ConnectedComponent;
