import { some } from "lodash";
import * as fetchingItemsActionTypes from "../../actions/fetchingItems/fetchingItemsActionTypes";

export interface LazyState<T extends object = {}> {
  items: Array<T>;
  itemsCount: number;
  error?: any;
}

export interface LazyLoadableState<T extends object> extends LazyState<T> {
  isLoading: boolean;
  isAllLoaded: boolean;
}

export interface LazyAction<T extends object> {
  payload?: LazyState<T>;
  type: string | null;
}

const getinitialState = <T extends object>() => {
  return { items: [] as T[], itemsCount: 0, isLoading: false, isAllLoaded: false, error: null };
};

// __ delimeter beetwen namespace, reducer entityPrefix and corresponding action type
// example LIBRARY__FLOWS__FETCH_GRID_ITEMS_BEGIN
// should be used for ViewType.GRID (example FlowsOverview)
export const withFetchingEntityGridItems = <T extends object = {}>(
  namespace: string,
  entityPrefix: string,
  ...childEntityPrefix: string[]
) => {
  if (some(childEntityPrefix)) {
    entityPrefix += `__${  childEntityPrefix.join("__")}`;
  }
  return function fetchingGridItems(currentState?: LazyState<T>, action?: LazyAction<T>): LazyLoadableState<T> {
    const state = currentState || getinitialState<T>();
    const payload = action?.payload || state;
    const type = action?.type || null;

    switch (type) {
      case `${namespace}__${entityPrefix}__${fetchingItemsActionTypes.FETCH_GRID_ITEMS_BEGIN}`:
        return {
          isAllLoaded: false,
          ...state,
          isLoading: true,
          error: null,
        };
      case `${namespace}__${entityPrefix}__${fetchingItemsActionTypes.FETCH_GRID_ITEMS_SUCCESS}`:
        let currentItems = state.items;
        let items = currentItems.concat(payload.items);
        let isAllItemsLoaded = items.length >= payload.itemsCount;

        return {
          ...state,
          isLoading: false,
          isAllLoaded: isAllItemsLoaded,
          items: items,
          itemsCount: payload.itemsCount,
        };
      case `${namespace}__${entityPrefix}__${fetchingItemsActionTypes.FETCH_GRID_ITEMS_FAILURE}`:
        return {
          ...state,
          isLoading: false,
          isAllLoaded: false,
          error: payload.error,
        };
      case `${namespace}__${entityPrefix}__${fetchingItemsActionTypes.RESET_GRID_ITEMS}`:
        return { ...getinitialState<T>() };
      default:
        return {
          ...state,
          isLoading: false,
          isAllLoaded: false,
        };
    }
  };
};
