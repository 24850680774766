import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const aliasKey = "alias";

type UseAliasHook = () => [string | undefined, boolean];

export const useAlias: UseAliasHook = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [alias, setAlias] = useState<string>();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const aliasParam = params.get(aliasKey);

    const removeAliasParam = () => {
      params.delete(aliasKey);
      const locationWithoutAlias = { ...location, search: params.toString() };
      navigate(locationWithoutAlias, { replace: true });
    };

    if (aliasParam) {
      setAlias(aliasParam);
      removeAliasParam();
    }

    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [alias, initialized];
};
