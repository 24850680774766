import { Filter, operators } from "utils/filterUtils";

export const usersFilter = new Filter([
  {
    key: "title",
    operator: operators.or,
  },
  {
    key: "department",
    operator: operators.or,
  },
  {
    key: "createDate",
    operator: operators.range,
  },
  {
    key: "roleId",
    operator: operators.ids,
  },
]);
