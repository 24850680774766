import { multiSelectPropTypes } from "./multiSelectPropTypes";
import DropdownWrapper from "../dropdowns/dropdownWrapper/DropdownWrapper";

import "./multiSelect.scss";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

function MultiSelect(props) {
  const { placeholder, items, selected, handleMultiSelectChange, className, ...additionalProps } = props;

  let options = items;
  const { propertyName, updateFilter, ...remainingProps } = additionalProps;

  if (isEmpty(items) && isArray(selected)) {
    options = selected.map((x) => ({ text: x, value: x }));
  }

  return (
    <DropdownWrapper
      className={className}
      multiple
      selection
      search
      fluid
      selected={selected}
      items={options}
      handleOptionChange={handleMultiSelectChange}
      placeholder={placeholder}
      {...remainingProps}
    />
  );
}

MultiSelect.defaultProps = {
  placeholder: "",
  items: [],
  selected: [],
};

MultiSelect.propTypes = multiSelectPropTypes;

export default MultiSelect;
