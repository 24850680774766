import { TextTruncate } from "components";
import { type ColumnOption } from "interfaces/columnOptions";
import { Title } from "components/listViewTemplates";
import dateTimeUtils from "utils/dateTimeUtils";
import { Tooltip } from "components/common/tooltip";
import { TemplateImage } from "components/people/templates/TemplateImage";
import { TemplateStatusEnum } from "../../../People/GroupTemplate/types/models";
import { TemplateStatus } from "../../../People/GroupTemplate/Overview/TemplateStatus";
import { DeleteButton } from "../../../../components/buttons/iconButtons";
import { type AccountTemplate } from "../state/slices/templates/templatesSlice";

export enum Columns {
  Name = "Name",
  Owner = "Owner",
  Added = "Added",
  Modified = "Modified",
  Users = "Users",
  Status = "Status",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  owner: "publisher",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  users: "users",
};

export const getColumnOptions = ({
  handleDelete,
  hasManagePermission,
}: {
  handleDelete: (id: number) => () => void;
  hasManagePermission: boolean;
}): ColumnOption<AccountTemplate>[] => {
  return [
    {
      name: "",
      width: 1,
      isSortable: false,
      render: (item) => {
        return <TemplateImage imageUrl={item.imageUrl} />;
      },
    },
    {
      name: Columns.Name,
      width: 3,
      isSortable: true,
      render: (item) => {
        return (
          <Title
            title={item.name}
            url={item.isOwn ? `/accounts/templates/${item.id}` : ""}
            clamp={2}
            className="normal-color"
          />
        );
      },
    },
    {
      name: Columns.Owner,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateCreated) : "-",
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateModified) : "-",
    },
    {
      name: Columns.Users,
      width: 1,
      isSortable: false,
      render: (item) => item.users,
    },

    {
      name: Columns.Status,
      width: 2,
      isSortable: false,
      render: (item) => <TemplateStatus status={item.status} />,
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      render: (item) => (
        <DeleteButton
          onClick={handleDelete(item.id)}
          isDisabled={!hasManagePermission}
          key="delete"
          alternate={false}
          color={"blue"}
          data-testid={"remove"}
        />
      ),
    },
  ];
};
