import { useReducer } from "react";

type PopupState = {
  isOpen: boolean;
  textContent: string;
  isTop: boolean;
  position: { x: number; y: number };
};

type PopupAction =
  | {
      type: "SET_POPUP";
      payload: { isOpen: boolean; textContent: string; isTop: boolean; position: { x: number; y: number } };
    }
  | { type: "CLOSE_POPUP" };

export const chartPopupReducer = (state: PopupState, action: PopupAction): PopupState => {
  switch (action.type) {
    case "SET_POPUP":
      return {
        ...state,
        isOpen: action.payload.isOpen,
        textContent: action.payload.textContent,
        isTop: action.payload.isTop,
        position: action.payload.position,
      };
    case "CLOSE_POPUP":
      return {
        ...state,
        isOpen: false,
        textContent: "",
        isTop: false,
        position: { x: 0, y: 0 },
      };
    default:
      return state;
  }
};

export const useChartPopupReducer = () =>
  useReducer(chartPopupReducer, { isOpen: false, textContent: "", isTop: false, position: { x: 0, y: 0 } });
