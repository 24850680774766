import { useMemo } from "react";

import { type PacksContextItem, type PacksContextItemModel } from "../../../../../interfaces";
import { Headline } from "./Headline";
import { ContentPlaceholder } from "./ContentPlaceholder";
import { CommonAssetList } from "./AssetList/CommonAssetList";
import { type PackCombination } from "../../../../../interfaces/licenseConfirmation";
import { type FlowAssignment } from "../../state/slices/packsContextSlice";
import { FlowAssetList } from "./AssetList/FlowAssetList";

import "./packsContextItemsBlock.scss";

export interface PacksContextItemsBlockProps {
  isLoading: boolean;
  items: PacksContextItemModel[];
  info: PacksContextItem[];
  setPack: (itemId: string, itemType: string, packId?: number) => void;
  flowConflicts: Record<number, PackCombination[]>;
  flowAssignments: FlowAssignment[];
  resolveFlowConflict(assignment: FlowAssignment): void;
}

export const PacksContextItemsBlock: React.FC<PacksContextItemsBlockProps> = ({
  isLoading,
  items,
  flowConflicts,
  flowAssignments,
  setPack,
  resolveFlowConflict,
  info,
}) => {
  const isDataLoaded = useMemo(() => !isLoading || items.length > 0, [isLoading, items.length]);
  const flowsOnly = useMemo<boolean>(() => {
    return !!Object.keys(flowConflicts).length;
  }, [flowConflicts]);

  const headlineText = useMemo(() => {
    return Object.keys(flowConflicts).length || items.length > 0
      ? "For each item shown, select which pack to issue licenses from."
      : "No data to display";
  }, [flowConflicts, items]);

  return (
    <div id="pack-context-items-block" className="pack-context-items-block">
      <Headline isDataLoaded={isDataLoaded} text={headlineText} />
      {!isDataLoaded && <ContentPlaceholder />}

      {isDataLoaded && flowsOnly ? (
        <FlowAssetList
          items={info}
          conflicts={flowConflicts}
          assignments={flowAssignments}
          resolveFlowConflict={resolveFlowConflict}
        />
      ) : (
        <CommonAssetList items={items} setPack={setPack} />
      )}
    </div>
  );
};
