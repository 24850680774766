import dataService from "../../../../Application/services/dataServices/typedDataService";
import actionTypes from "../actionTypes/surveyDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { type Dispatcher } from "../../../../../interfaces/redux";
import { batch } from "react-redux";
import { type SettingsView, type Survey, type SurveyContentView, type SurveyView } from "../../types/state";
import { type DraftStateEntity } from "../../../../../interfaces/draftStateEntity";
import { type EntityDetailsPayload } from "../../../../Application/reducers/hoc/withEntityState";
import * as entityStateActionTypes from "../actionTypes/surveyEntityStateActionTypes";
import { type Content } from "../../types/models";
import { setSurveyContentData } from "../slices/surveyPerformanceSlice";

const setDataForDetailsMode = getActionProvider<EntityDetailsPayload>(entityStateActionTypes.setDataForDetailsMode);

export const saveSettingsAction = getActionProvider<SettingsView>(actionTypes.SaveSettings);

export const saveSurvey = (survey: SurveyView) => {
  const saveSurveyAction = getActionProvider<SurveyView>(actionTypes.SaveSurvey);

  return (dispatch: Dispatcher) => {
    dispatch(saveSurveyAction(survey));
  };
};

export const clearSurvey = () => {
  const resetSurveyAction = getActionBaseProvider(actionTypes.ClearSurvey);

  return (dispatch: Dispatcher) => {
    dispatch(resetSurveyAction());
  };
};

export const getSurvey = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.GetSurveyBegin);
  const success = getActionProvider<Survey>(actionTypes.GetSurveySuccess);
  const setSurveyBaseInfo = getActionProvider<DraftStateEntity>(actionTypes.SetSurveyBaseInfo);
  const failure = getActionProvider<Error>(actionTypes.GetSurveyFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.surveys.getSurveyAsync(id);
      batch(() => {
        const { title, isDraft, dateModified } = result.data;
        dispatch(setSurveyBaseInfo({ id: result.data.id, title, isDraft }));
        dispatch(success(result.data));
        dispatch(setDataForDetailsMode({ id: result.data.id!, isDraft, dateModified }));
      });
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const saveContent = (contentMetadata: SurveyContentView) => {
  const saveContentAction = getActionProvider<SurveyContentView>(actionTypes.SaveContent);

  return (dispatch: Dispatcher) => {
    dispatch(saveContentAction(contentMetadata));
  };
};

export const getSurveyContent = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.GetSurveyContentBegin);
  const success = getActionProvider<Content>(actionTypes.GetSurveyContentSuccess);
  const setSurveyBaseInfo = getActionProvider<DraftStateEntity>(actionTypes.SetSurveyBaseInfo);
  const failure = getActionProvider<Error>(actionTypes.GetSurveyContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.surveys.getSurveyContentAsync(id);
      batch(() => {
        const { title, isDraft, dateModified } = result.data;
        const surveyId = result.data.id || result.data.surveyId;
        dispatch(setSurveyBaseInfo({ id: surveyId, title, isDraft }));
        dispatch(success(result.data));
        dispatch(setDataForDetailsMode({ id: surveyId, isDraft, dateModified }));
        // For performance page
        dispatch(setSurveyContentData(result.data));
      });
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
