import { ViewType } from "../../enums";
import type ColumnOptions from "../../interfaces/columnOptions";
import { ListViewBase, ListViewWithInMemorySorting } from "../listView";

import "./overviewWithInMemorySorting.scss";

export interface OverviewWithInMemorySortingProps<TId> {
  items: Array<any>;
  isLoading: boolean;
  isSelectDisabled?: () => boolean;
  columnOptions: ColumnOptions[];
  updateSelectedItems?: (ids: TId[]) => void;
  buildTableBody: (item: any) => React.ReactElement;
  mapColumnName: (columnName: string) => string;
  sortingColumnName: string;
  sortingDirection: string;
  noResultsContent?: React.ReactElement;
  selectedItemIds?: TId[];
}

export const OverviewWithInMemorySorting = <TId,>({
  items,
  isLoading,
  columnOptions,
  sortingColumnName,
  sortingDirection,
  buildTableBody,
  isSelectDisabled,
  mapColumnName,
  updateSelectedItems,
  noResultsContent,
  selectedItemIds,
}: OverviewWithInMemorySortingProps<TId>) => {
  return (
    <div className="in-memory-overview">
      <ListViewWithInMemorySorting
        columnOptions={columnOptions}
        buildTableBody={buildTableBody}
        items={items}
        isLoading={isLoading}
        isSelectDisabled={isSelectDisabled}
        mapColumnName={mapColumnName}
        updateSelectedItems={updateSelectedItems}
        as={ListViewBase}
        viewType={ViewType.LIST}
        sortingColumnName={sortingColumnName}
        sortingDirection={sortingDirection}
        withFooter={false}
        noResultsContent={noResultsContent}
        selectedItemIds={selectedItemIds}
      />
    </div>
  );
};
