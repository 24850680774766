import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FiltersState, createFiltersSlice } from "features/Application/slices/createFiltersSlice";
import { type GenericFiltersMap } from "utils/filterUtils";
import { type ContentFilters } from "features/People/types";

interface TemplateContentFilterState extends FiltersState<ContentFilters> {}
const initialFilterState: TemplateContentFilterState = {
  appliedFilter: {} as GenericFiltersMap<ContentFilters>,
  filterOptions: {} as GenericFiltersMap<ContentFilters>,
  isLoading: false,
};
const filterSlice = createFiltersSlice({
  initialState: initialFilterState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "TemplateContentFilters",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {},
});
export const {
  setSearch,
  setAppliedFilter,
  resetAppliedFilter,
  setFilterOptions,
  setError,
  setIsLoading,
  resetFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
