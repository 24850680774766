import { type BaseEditor } from "slate";
import { type ReactEditor } from "slate-react";

export type CustomElement = { type: allFormats; children: CustomText[] };
type textOptions = { [format in MarkFormats]?: boolean };
export type CustomText = { text: string } & textOptions;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export interface BaseProps {
  className: string;
  [key: string]: unknown;
}
export type OrNull<T> = T | null;

export enum MarkFormats {
  Bold = "bold",
  Italic = "italic",
  Underline = "underline",
}
export enum BlockFormats {
  HeadingOne = "heading-one",
  HeadingTwo = "heading-two",
  Paragraph = "paragraph",
  BulletedList = "bulleted-list",
  NumberedList = "numbered-list",
  ListItem = "list-item",
}
export type allFormats = BlockFormats | MarkFormats;
