import TaskStatuses from "../../enums/taskStatuses";
import TaskActions from "../../enums/TaskActions";
import backgroundTasksEventsEmitter from "./events/backgroundTasksEventsEmitter";

const tasks = {
  getFailed: (id, error, onClose) => {
    backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.close, id, onClose);
    return {
      id,
      status: TaskStatuses.failed,
      errorLabel: error || "Video processing failed!",
      percent: 100,
      indeterminate: false,
      canClose: true,
    };
  },

  getReadyToPublish: (id, onClose) => {
    backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.close, id, onClose);
    return {
      id,
      status: TaskStatuses.successful,
      label: "Ready to publish!",
      percent: 100,
      indeterminate: false,
      canClose: true,
    };
  },

  getProcessing: (id) => {
    backgroundTasksEventsEmitter.unsubscribeAll(TaskActions.close, id);
    return {
      id,
      status: TaskStatuses.inProgress,
      label: "Processing...",
      errorLabel: "Video processing failed!",
      percent: 0,
      indeterminate: true,
      canClose: false,
    };
  },

  getUploadedSuccessfuly: (id) => {
    backgroundTasksEventsEmitter.unsubscribeAll(TaskActions.cancel, id);
    return {
      id,
      label: "Uploaded successfully!",
      errorLabel: "Create failed!",
      isAssetVideoUploading: false,
      isAssetVideoUploaded: true,
      canCancel: false,
    };
  },

  getStarted: (id) => {
    backgroundTasksEventsEmitter.unsubscribeAll(TaskActions.cancel, id);
    return {
      id,
      status: TaskStatuses.inProgress,
      isAssetVideoUploading: true,
      canCancel: true,
    };
  },
};

export default tasks;
