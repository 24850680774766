import { Table } from "semantic-ui-react";
import { type IdentityProvider, IdentityProviderType } from "../../../types";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import IdentityProviderTitle from "./IdentityProviderTitle/identityProviderTitle";
import IdentityProviderEllipsisPopupButton from "./identityProviderEllipsisPopupButton/identityProviderEllipsisPopupButton";

import "./identityProviderRow.scss";
import { identityProviderColumnOptions } from "../../constants";

export type IdentityProviderRowProps = IdentityProvider & IdentityProviderRowOwnProps;

const IdentityProviderRow: React.FC<IdentityProviderRowProps> = ({
  id,
  name,
  type,
  addedDate,
  identityId,
  hasManagePermission,
  isSelected,
  onEdit,
  onDelete,
}) => {
  return (
    <>
      <Table.Cell width={identityProviderColumnOptions[0].width} collapsing>
        <IdentityProviderTitle name={name} identityId={identityId} url={id.toString()} />
      </Table.Cell>
      <Table.Cell width={identityProviderColumnOptions[1].width}>{IdentityProviderType[type]}</Table.Cell>
      <Table.Cell width={identityProviderColumnOptions[2].width}>{dateTimeUtils.formatDate(addedDate)}</Table.Cell>
      <Table.Cell className="align-right" width={identityProviderColumnOptions[3].width}>
        <IdentityProviderEllipsisPopupButton
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
          isDisabled={isSelected || !hasManagePermission}
        />
      </Table.Cell>
    </>
  );
};

export interface IdentityProviderRowOwnProps {
  hasManagePermission: boolean;
  isSelected: boolean;
  onEdit: (identityProviderId: number) => void;
  onDelete: (identityProviderId: number) => void;
}

export default IdentityProviderRow;
