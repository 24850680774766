import { combineReducers } from "redux";
import integrationsOverviewReducer from "./state/slices/integrationsOverviewSlice";
import integrationDetailsReducer from "./state/slices/integrationDetailsSlice";
import integrationPacksReducer from "./state/slices/integrationPacksSlice";
import activityLogsReducer from "./state/slices/activityLogsSlice";

const base = combineReducers({
  packs: integrationPacksReducer,
});

const overview = combineReducers({
  integrationsOverviewReducer,
});

const edit = combineReducers({
  details: integrationDetailsReducer,
  activityLogs: activityLogsReducer,
});

const reducers = combineReducers({
  base,
  edit,
  overview,
});

export type IntegrationState = ReturnType<typeof reducers>;

export default reducers;
