import { takeWhile } from "lodash";

export const calculateHiddenItems = (length: number, listRef: HTMLDivElement | null) => {
  if (!listRef) {
    return length;
  }
  const visibleItems = takeWhile(
    listRef.children as HTMLCollectionOf<HTMLElement>,
    (child) => child.offsetTop < listRef.offsetHeight,
  ).length;

  return length - visibleItems;
};
