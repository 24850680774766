import MultiStateDropdown, {
  type MultiStateDropdownOption,
} from "components/dropdowns/multiStateDropdown/MultiStateDropdown";
import GroupsOverview from "../Groups/GroupsOverview/GroupsOverview";
import TemplatesOverview from "../GroupTemplate/Overview/TemplatesOverview";
import { faUsers, faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { useNavigate } from "react-router-dom";
import { GroupContentTypesEnum } from "./constants";

const contentOptions: MultiStateDropdownOption<GroupContentTypesEnum>[] = [
  {
    label: GroupContentTypesEnum.Groups,
    icon: faUsers,
  },
  {
    label: GroupContentTypesEnum.Templates,
    icon: faBuildingUser,
  },
];

const routes = {
  [GroupContentTypesEnum.Groups]: "..",
  [GroupContentTypesEnum.Templates]: "inherited",
};

/* istanbul ignore next */
export const GroupsSwitcher = (props: { ctx: GroupContentTypesEnum }) => {
  const templatesFlag = useFeatureFlag(FeatureFlags.GroupTemplates);

  const navigate = useNavigate();

  const handleChangeContext = (newContext: GroupContentTypesEnum) => {
    navigate(routes[newContext]);
  };

  const switcher = () => {
    return (
      <MultiStateDropdown
        className="groups-segmentation-dropdown"
        options={contentOptions}
        defaultOption={contentOptions.find((option) => option.label === props.ctx)!}
        onContextChange={handleChangeContext}
        defaultValue={props.ctx}
      />
    );
  };

  const components = {
    [GroupContentTypesEnum.Groups]: <GroupsOverview renderContentSwitcher={templatesFlag ? switcher : undefined} />,
    [GroupContentTypesEnum.Templates]: <TemplatesOverview renderContentSwitcher={switcher} />,
  };

  return components[props.ctx];
};
