import accountsDataService from "../../../services/accountsDataService";
import { type Dispatcher } from "../../../../../interfaces";
import { SortingDirection } from "../../../../../enums";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/allAccountsOverviewSlice";

export const fetchAccounts =
  (
    skip?: number,
    take?: number,
    orderProp: string = "",
    orderDirection: SortingDirection = SortingDirection.Ascending,
    searchText?: string,
    filter?: string,
  ) =>
  async (dispatch: Dispatcher) => {
    dispatch(fetchBegin());

    try {
      const { items, itemsCount } = await accountsDataService.getAccounts(
        skip,
        take,
        orderProp,
        orderDirection,
        searchText,
        filter,
      );

      dispatch(fetchSuccess({ items, totalCount: itemsCount }));
    } catch (error) {
      dispatch(fetchFailure(error));
    }
  };
