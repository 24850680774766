import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { type ContentPriorityLevel } from "../../../interfaces";
import PriorityLevelWithThumbnail from "../../priorityLevels/priorityLevelItem/PriorityLevelWithThumbnail";
import { type ChangeContentPeoplePriorityModalProps } from "./types";
import ChangePriorityModal from "./ChangePriorityModal";
import { InheritedPriorityWarning } from "components/priorityLevels/InheritedPriorityWarning/InheritedPriorityWarning";

export const ChangeContentPeoplePriorityModal: React.FC<ChangeContentPeoplePriorityModalProps> = (props) => {
  const { contentItem } = props;

  const getContent = (
    items: { [key in PeopleType]?: ContentPriorityLevel[] },
    onChange: (value: { [key in PeopleType]?: ContentPriorityLevel[] }) => void,
    onValidChange: (isValid: boolean) => void,
  ) => {
    let peopleType: PeopleType = PeopleType.User;
    if (items[PeopleType.Group]) {
      peopleType = PeopleType.Group;
    }
    let peopleItems = items[peopleType] || [];
    return (
      <PriorityLevelWithThumbnail
        peopleType={peopleType}
        item={{ ...peopleItems[0], title: contentItem.title, thumbnailUrl: contentItem.thumbnailUrl }}
        onChange={(propertyName: string, value: any) => {
          const updatedItems = peopleItems.map((item) => {
            return { ...item, [propertyName]: value };
          });
          onChange({ ...items, [peopleType]: updatedItems });
        }}
        onValidChange={onValidChange}
        disableDefault
      />
    );
  };

  const renderInheritedWarning = (multi: boolean) => <InheritedPriorityWarning context={"content"} multi={multi} />;

  return <ChangePriorityModal {...{ ...props, getContent, renderInheritedWarning }} />;
};

export default ChangeContentPeoplePriorityModal;
