import { createAsyncThunk } from "@reduxjs/toolkit";
import { type FetchActionPayload, type UpdateParams, type ResponseWithCancellation } from "../../../../../../interfaces";
import { type MessageOverview } from "../../types/state";
import { type GetMessagesRequest, type MessageOverviewRequest } from "../../types/requests";
import { getPrefix } from "../../../../../Application/slices/models";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums";
import { pluralize } from "../../../../../../utils/stringUtils";
import backgroundTask from "../../../../../BackgroundTasks/backgroundTask";
import { type AppDispatch, type AppThunk, type RootState } from "../../../../../Application/globaltypes/redux";
import { messagesAppliedFilterSelector, messagesSearchSelector } from "../../selectors";
import { escapeTerm } from "../../../../../../utils/searchUtils";
import { DEFAULT_LOAD_COUNT } from "../../types/constants";
import { type Filters } from "../../../../../../utils/queryUtils";
import CommunicationTypes from "../../../../../../enums/communicationTypes";
import { formatFilters } from "../../../../../../utils/filterMapUtils";
import DataService from "../../../../../Application/services/dataServices/typedDataService";
import { messageFilterTypes } from "../../types/models";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const sendGetMessageRequest = (options: {
  appliedFilter: Filters;
  skip: number;
  top: number;
  term: string;
  sortBy?: string;
  sortDirection?: string;
}): ResponseWithCancellation<MessageOverview> => {
  const { appliedFilter, skip, top, term, sortBy, sortDirection } = options;
  const filterQueryParams = formatFilters(appliedFilter, messageFilterTypes);
  const requestV3: GetMessagesRequest = {
    top,
    skip,
    sortBy,
    sortOrder: sortDirection,
    filterQueryParams,
    term,
    showPurchased: true,
    contentType: CommunicationTypes.Message,
  };
  return DataService.messages.getMessagesV4Async(requestV3);
};

export const fetchOverviewMessages = createAsyncThunk<FetchActionPayload<MessageOverview>, MessageOverviewRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Messages,
    name: "overview",
  }),
  async (request: MessageOverviewRequest, { getState, signal }) => {
    const rootState = getState() as RootState;
    const appliedFilter = messagesAppliedFilterSelector(rootState);
    const searchTerm = escapeTerm(messagesSearchSelector(rootState));

    const { getDataPromise, cancel } = sendGetMessageRequest({
      appliedFilter,
      skip: request.skip ?? 0,
      top: request.top ?? DEFAULT_LOAD_COUNT,
      term: searchTerm ?? "",
      sortBy: request.sortBy,
      sortDirection: request.sortDirection,
    });

    signal.addEventListener("abort", cancel);

    const result = await getDataPromise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
      append: request.append,
    };
  },
);

export const deleteMessages =
  (ids: number[], fetchMessages: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getMessageIds = (messageIds: number[]) => async () => {
      const result = await DataService.messages.deleteMessages(messageIds);
      return result?.data.messageIds;
    };

    const idsCount = ids.length;
    const entity = pluralize("Message", idsCount);

    let successMessage = `${entity} ${idsCount === 1 ? "has" : "have"} been deleted!`;
    let failureMessage = `${entity} delete failed!`;

    const updateParams: UpdateParams = {
      id: "DeleteMessage",
      title: `Deletion of messages`,
      getMessageIds: getMessageIds(ids),
      onCompleted: () => fetchMessages(),
      successTransientMessage: successMessage,
      failureTransientMessage: failureMessage,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
