import { type FC, type SyntheticEvent, useEffect, useMemo, useState } from "react";
import { type CheckboxProps, Loader, type TabProps } from "semantic-ui-react";
import { Tabs, TriggerableConfirmationModal } from "../../../../../components";
import { AddButton } from "../../../../../components/buttons/iconButtons/AddButton/AddButton";
import { RemoveLinkButton } from "../../../../../components/buttons/linkButtons";
import Observable from "../../../../../utils/Observable";
import PackVisibilityForAccounts from "./Accounts/PackVisibilityForAccounts";
import PackVisibilityForAccountsTypes from "./AccountTypes/PackVisibilityForAccountTypes";
import { PackVisibilityToggleButton } from "./PackVisibilityToggleButton/PackVisibilityToggleButton";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { type ConnectedProps, connect } from "react-redux";
import { bindAction } from "interfaces";
import { getPackIsPrivate, updatePackIsPrivate } from "../../state/actions/packDetailsActions";
import "./packVisibilityTabs.scss";

export interface PackVisibilityTabsProps {
  packId: number;
  isReadOnlyMode: boolean;
}

const ACCOUNTS_TAB_INDEX = 1;

export type PackVisibilityTabsPropsAll = PackVisibilityTabsProps & PropsFromRedux;

export const PackVisibilityTabs: FC<PackVisibilityTabsPropsAll> = ({
  packId,
  isReadOnlyMode,
  isPrivatePackLoading,
  getPackIsPrivate,
  updatePackIsPrivate,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showAddAccountsModal, setShowAddAccountsModal] = useState(false);
  const [showRemoveAccountsButton, setShowRemoveAccountsButton] = useState(false);
  const [showRemoveAccountsConfirmationModal, setShowRemoveAccountsConfirmationModal] = useState(false);

  const [showAddAccountTypesModal, setShowAddAccountTypesModal] = useState(false);
  const [showRemoveAccountTypesButton, setShowRemoveAccountTypesButton] = useState(false);
  const [showRemoveAccountTypesConfirmationModal, setShowRemoveAccountTypesConfirmationModal] = useState(false);

  const [isPrivate, setIsPrivate] = useState<boolean>(false);

  const onIsPrivateChangeObservable = useMemo(() => {
    return new Observable<(onConfirm: () => void) => void>();
  }, []);

  useEffect(() => {
    const fetchPackIsPrivate = async () => {
      const isPrivatePack = await getPackIsPrivate(packId);
      setIsPrivate(isPrivatePack);
    };

    if (isPackCreated) {
      fetchPackIsPrivate();
    } else {
      setIsPrivate(true);
    }
    // eslint-disable-next-line
  }, [packId]);

  const onTabChangeObserver = useMemo(() => new Observable(), []);

  const handleTabChange = async (_: SyntheticEvent, data: TabProps) => {
    setActiveTabIndex(data.activeIndex === 1 ? 1 : 0);
    onTabChangeObserver.notify();
  };

  const isAccountsTab = activeTabIndex === ACCOUNTS_TAB_INDEX;
  const isPackCreated = packId > 0;

  const onAddVisibilityBtnClick = () =>
    isAccountsTab ? setShowAddAccountsModal(true) : setShowAddAccountTypesModal(true);

  const onRemoveVisibilityBtnClick = () =>
    isAccountsTab ? setShowRemoveAccountsConfirmationModal(true) : setShowRemoveAccountTypesConfirmationModal(true);

  const renderAddVisibilityButton = () => {
    return (
      <AddButton
        disabled={isReadOnlyMode}
        onClick={onAddVisibilityBtnClick}
        label={isAccountsTab ? "Add Accounts" : "Add Account Types"}
      />
    );
  };

  const renderRemoveVisibilityButton = () => {
    return <RemoveLinkButton onClick={onRemoveVisibilityBtnClick} />;
  };

  const renderVisibilityActionButton = () => {
    return showRemoveAccountTypesButton || showRemoveAccountsButton
      ? renderRemoveVisibilityButton()
      : renderAddVisibilityButton();
  };

  const savePackIsPrivate = (isPrivate: boolean) => {
    setIsPrivate(isPrivate);
    updatePackIsPrivate(packId, isPrivate);
  };

  const onPrivateChange = (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const value = data.checked;
    if (!value) {
      onIsPrivateChangeObservable.notify(() => savePackIsPrivate(true));
    } else {
      savePackIsPrivate(false);
    }
  };

  return (
    <div className="pack-visibility-container">
      {(!isPackCreated || isPrivatePackLoading) && <Loader active />}
      {isPackCreated && !isPrivatePackLoading && (
        <>
          <PackVisibilityToggleButton checked={!isPrivate} onChange={onPrivateChange} disabled={isReadOnlyMode} />
          {!isPrivate && (
            <div className="pack-visibility-header">
              <div>
                You can choose to add specific accounts or account types to control visibility in the marketplace. If
                none are selected, the pack will be available to everyone in the marketplace.
              </div>
              <div className="pack-visibility-buttons">{renderVisibilityActionButton()}</div>
            </div>
          )}
          {!isPrivate && (
            <Tabs className="pack-visibility-tabs" renderActiveOnly={false} onTabChange={handleTabChange}>
              <Tabs.Pane className="pack-visibility-account-types-tab" label="Account Types">
                <PackVisibilityForAccountsTypes
                  setAccountTypesModalVisibility={setShowAddAccountTypesModal}
                  showAddAccountTypesModal={showAddAccountTypesModal}
                  setRemoveConfirmationModalVisibility={setShowRemoveAccountTypesConfirmationModal}
                  showRemoveConfirmationModal={showRemoveAccountTypesConfirmationModal}
                  setShowRemoveButton={setShowRemoveAccountTypesButton}
                  id={packId}
                  isReadOnlyMode={isReadOnlyMode}
                  onTabChangeObserver={onTabChangeObserver}
                />
              </Tabs.Pane>
              <Tabs.Pane className="pack-visibility-accounts-tab" label="Accounts">
                <PackVisibilityForAccounts
                  setAccountsModalVisibility={setShowAddAccountsModal}
                  showAddAccountsModal={showAddAccountsModal}
                  setRemoveConfirmationModalVisibility={setShowRemoveAccountsConfirmationModal}
                  showRemoveConfirmationModal={showRemoveAccountsConfirmationModal}
                  setShowRemoveButton={setShowRemoveAccountsButton}
                  id={packId}
                  isReadOnlyMode={isReadOnlyMode}
                  onTabChangeObserver={onTabChangeObserver}
                />
              </Tabs.Pane>
            </Tabs>
          )}
        </>
      )}
      <TriggerableConfirmationModal
        title="Clear Visibility Settings?"
        content="Are you sure you want to make this pack private? Your current visibility settings will be lost."
        confirmButtonLabel="Clear Settings"
        onTriggerModalObserver={onIsPrivateChangeObservable}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    isPrivatePackLoading: state.packs.packDetailsReducer.isPrivatePackLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPackIsPrivate: bindAction(getPackIsPrivate, dispatch),
  updatePackIsPrivate: bindAction(updatePackIsPrivate, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackVisibilityTabs);
export default ConnectedComponent;
