import React from "react";
import PropTypes from "prop-types";
import EllipsisPopupButton from "../ellipsisPopupButton/EllipsisPopupButton";

import {
  EditPriorityLinkButton,
  EditLinkButton,
  DuplicateLinkButton,
  AddToLinkButton,
  PublishLinkButton,
  UnpublishLinkButton,
  DeleteLinkButton,
  RemoveAssignmentLinkButton,
} from "../linkButtons";

class AssetMenuButtons extends React.Component {
  renderButton = (linkButton, handler) => {
    if (!handler) {
      return null;
    }

    const { item } = this.props;
    const properties = {
      onClick: () => handler.onClick?.(item),
      isDisabled: handler.isDisabled?.(item),
    };

    if (handler.peopleType && handler.showTooltip) {
      properties.peopleType = handler.peopleType;
      properties.showTooltip = handler.showTooltip;
    }

    return React.createElement(linkButton, properties);
  };

  renderPublishUnpublishButton = () => {
    const { item, publish, unpublish } = this.props;
    return item.isDraft
      ? this.renderButton(PublishLinkButton, publish)
      : this.renderButton(UnpublishLinkButton, unpublish);
  };

  render() {
    const {
      edit,
      duplicate,
      addTo,
      deleteButton,
      circle,
      outlinedEllipsis,
      editPriorityLevel,
      removeAssignment,
      disabled,
    } = this.props;

    return (
      <EllipsisPopupButton circle={circle} outlinedEllipsis={outlinedEllipsis} disabled={disabled}>
        {this.renderButton(EditLinkButton, edit)}
        {this.renderButton(DuplicateLinkButton, duplicate)}
        {this.renderButton(AddToLinkButton, addTo)}
        {this.renderPublishUnpublishButton()}
        {this.renderButton(DeleteLinkButton, deleteButton)}
        {this.renderButton(EditPriorityLinkButton, editPriorityLevel)}
        {this.renderButton(RemoveAssignmentLinkButton, removeAssignment)}
      </EllipsisPopupButton>
    );
  }
}

AssetMenuButtons.propTypes = {
  item: PropTypes.object.isRequired,
  edit: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  duplicate: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  addTo: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  publish: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  unpublish: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  deleteButton: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
    showTooltip: PropTypes.bool,
    peopleType: PropTypes.string,
  }),
  editPriorityLevel: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  removeAssignment: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default AssetMenuButtons;
