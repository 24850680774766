import * as updateVideoActionTypes from "../actionTypes/updateVideoActionTypes";
import * as videoEntityStateActionTypes from "../actionTypes/videoEntityStateActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { type UploadingStatus, type VideoInfo, type VideoInfoState, type VideoInfoTags } from "../../types/state";
import { type VideoInfoPayload } from "../../types/models";
import { type PayloadAction } from "../../../../../interfaces/redux";

const initialState: VideoInfoState = {
  videoInfo: {
    id: -1,
    title: "",
    description: "",
    fileName: "",
    uploadedVideos: [],
    labels: [],
    softwareApplications: [],
    isPurchased: false,
    isDraft: false,
    flows: [],
    packs: [],
  },
  isLoadingVideoInfo: false,
  isLoadedVideoInfo: false,
  uploadingStatus: {
    isAssetVideoUploading: false,
    isAssetVideoUploaded: false,
    progress: 0,
    isAborted: false,
  },
};

const getVideoInfoHandlers = () => {
  const {
    GET_VIDEO_INFO_BEGIN,
    GET_VIDEO_INFO_SUCCESS,
    GET_VIDEO_INFO_FAIL,

    SAVE_VIDEO_INFO,
    SAVE_VIDEO_INFO_TAGS,
    RESET_VIDEO_INFO,
  } = updateVideoActionTypes;

  const getVideoInfoBegin = (state: VideoInfoState) => {
    return { ...state, error: null, isLoadingVideoInfo: true };
  };

  const getVideoInfoSuccess = (state: VideoInfoState, action: PayloadAction<VideoInfoPayload>) => {
    const videoInfo = action.payload;

    return {
      ...state,
      videoInfo: {
        ...videoInfo,
        packs: videoInfo.packs.map((pack) => ({ title: pack.name, id: pack.id })),
        description: videoInfo.description || "",
        uploadedVideos: [{ name: videoInfo.fileName, size: 1, type: "video/mp4" }] as File[],
      },
      isLoadingVideoInfo: false,
      isLoadedVideoInfo: true,
    };
  };

  const getVideoInfoFail = (state: VideoInfoState, action: PayloadAction<Error>) => {
    return {
      ...state,
      error: action.payload,
      isLoadingVideoInfo: false,
    };
  };

  const saveVideoInfo = (state: VideoInfoState, action: PayloadAction<VideoInfo>) => {
    return {
      ...state,
      videoInfo: {
        ...state.videoInfo,
        ...action.payload,
      },
    };
  };

  const saveVideoInfoTagsHandler = (state: VideoInfoState, action: PayloadAction<VideoInfoTags>) => ({
    ...state,
    videoInfo: {
      ...state.videoInfo,
      softwareApplications: action.payload.softwareApplications || state.videoInfo.softwareApplications,
      labels: action.payload.labels || state.videoInfo.labels,
    },
  });

  const resetVideoInfo = () => {
    return { ...initialState };
  };

  return {
    [GET_VIDEO_INFO_BEGIN]: getVideoInfoBegin,
    [GET_VIDEO_INFO_SUCCESS]: getVideoInfoSuccess,
    [GET_VIDEO_INFO_FAIL]: getVideoInfoFail,

    [SAVE_VIDEO_INFO]: saveVideoInfo,
    [SAVE_VIDEO_INFO_TAGS]: saveVideoInfoTagsHandler,
    [RESET_VIDEO_INFO]: resetVideoInfo,
  };
};

const getVideoUploadHandlers = () => {
  const { UPLOAD_VIDEO_BEGIN, UPLOAD_VIDEO_SUCCESS, UPLOAD_VIDEO_FAIL, UPLOADING_VIDEO_PROGRESS, CANCEL_VIDEO_UPLOAD } =
    updateVideoActionTypes;

  const updateVideoBegin = (state: VideoInfoState) => {
    return {
      ...state,
      uploadingStatus: {
        isAssetVideoUploading: true,
        isAssetVideoUploaded: false,
        progress: 0,
        error: null,
      },
    };
  };

  const uploadVideoSuccess = (state: VideoInfoState) => {
    return {
      ...state,
      uploadingStatus: {
        isAssetVideoUploading: false,
        isAssetVideoUploaded: true,
        progress: 100,
        error: null,
      },
    };
  };

  const uploadVideoFail = (state: VideoInfoState, action: PayloadAction<UploadingStatus>) => {
    let error = action.payload.error;
    return {
      ...state,
      uploadingStatus: {
        isAssetVideoUploading: false,
        isAssetVideoUploaded: false,
        progress: 0,
        error,
      },
    };
  };

  const uploadingVideoProgress = (state: VideoInfoState, action: PayloadAction<UploadingStatus>) => {
    return {
      ...state,
      uploadingStatus: {
        progress: action.payload.progress,
        isAssetVideoUploading: true,
        isAssetVideoUploaded: false,
      },
    };
  };

  const cancelVideoUpload = (state: VideoInfoState) => {
    return {
      ...state,
      uploadingStatus: {
        isAssetVideoUploading: false,
        isAborted: true,
      },
    };
  };

  return {
    [UPLOAD_VIDEO_BEGIN]: updateVideoBegin,
    [UPLOAD_VIDEO_SUCCESS]: uploadVideoSuccess,
    [UPLOAD_VIDEO_FAIL]: uploadVideoFail,
    [UPLOADING_VIDEO_PROGRESS]: uploadingVideoProgress,
    [CANCEL_VIDEO_UPLOAD]: cancelVideoUpload,
  };
};

const videoInfoHandler = () => {
  const { CREATE_VIDEO_DRAFT_SUCCESS } = videoEntityStateActionTypes;

  const createAssetDraftSuccess = (state: VideoInfoState, action: PayloadAction<VideoInfo>) => {
    return {
      ...state,
      videoInfo: {
        ...state.videoInfo,
        id: action.payload.id,
      },
    };
  };

  return {
    [CREATE_VIDEO_DRAFT_SUCCESS]: createAssetDraftSuccess,
    ...getVideoInfoHandlers(),
    ...getVideoUploadHandlers(),
  };
};

export const videoInfoReducer = createReducer(initialState, [videoInfoHandler]);
