export const saveEmail = "SAVE_EMAIL";
export const saveEmailTemplate = "SAVE_EMAIL_TEMPLATE";
export const clearEmail = "CLEAR_EMAIL";

export const getEmailBegin = "GET_EMAIL_BEGIN";
export const getEmailSuccess = "GET_EMAIL_SUCCESS";
export const getEmailFailure = "GET_EMAIL_FAILURE";

export const getEmailTemplateBegin = "GET_EMAIL_TEMPLATE_BEGIN";
export const getEmailTemplateSuccess = "GET_EMAIL_TEMPLATE_SUCCESS";
export const getEmailTemplateFailure = "GET_EMAIL_TEMPLATE_FAILURE";

export const setEmail = "SET_EMAIL";

export const fetchTagsSuccess = "FETCH_TAGS_SUCCESS";
export const fetchTagsFailure = "FETCH_TAGS_FAILURE";
