import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import { buildFetchAction, buildRootEntityActionType } from "../../../Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../Application/actions/fetchingItems/fetchingItemsActionTypes";
import * as searchActionTypes from "../../../Application/actions/search/searchActionTypes";

const delimiter = "__";
export const GroupUsers = "GROUP_USERS";
export const GroupUsersToAdd = "GROUP_USERS_TO_ADD";
export const GroupOwners = "GROUP_OWNERS";
export const GroupAssignedOwners = "ASSIGNED_GROUP_OWNERS";

export const FETCH_GROUP_USERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup,
  GroupUsers,
);

export const RESET_GROUP_USERS = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup.concat(delimiter, GroupUsers),
  fetchingItemsActionTypes.RESET_ITEMS,
);

export const FETCH_GROUP_USERS_TO_ADD = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup,
  GroupUsersToAdd,
);

export const FETCH_GROUP_OWNERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup,
  GroupOwners,
);

export const FETCH_ASSIGNED_GROUP_OWNERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup,
  GroupAssignedOwners,
);

export const PEOPLE_EDIT_GROUP_USERS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup.concat(delimiter, GroupUsers),
  searchActionTypes.SET_SEARCH,
);

export const PEOPLE_EDIT_GROUP_USERS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup.concat(delimiter, GroupUsers),
  searchActionTypes.RESET_SEARCH,
);

export const PEOPLE_EDIT_GROUP_USERS_TO_ADD_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup.concat(delimiter, GroupUsersToAdd),
  searchActionTypes.SET_SEARCH,
);

export const PEOPLE_EDIT_GROUP_USERS_TO_ADD_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditGroup.concat(delimiter, GroupUsersToAdd),
  searchActionTypes.RESET_SEARCH,
);
