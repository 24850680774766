import { type FC, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { assessmentsStateSelector } from "../../state/selectors";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { noop } from "lodash";
import Restricted from "features/Application/Restricted";
import { RolePermissions } from "enums";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

interface DetailsHeaderContentProps {
  onErrorClick: () => void;
  canAddPacks: boolean;
  showAddPacksButton: boolean;
  assessmentId: number;
}

export type DetailsHeaderContentPropsBase = DetailsHeaderContentProps & PropsFromRedux;

export const DetailsHeaderContent: FC<DetailsHeaderContentPropsBase> = ({
  contentErrorMode,
  contentResolved,
  onErrorClick,
  canAddPacks,
  showAddPacksButton,
  assessmentId,
}) => {
  const [packsModalShown, setPacksModalShown] = useState<boolean>(false);
  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={!canAddPacks} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  const renderCustomHeader = () => {
    const hasActiveErrors = !contentResolved;
    return (
      contentErrorMode && (
        <PublishValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          onErrorButtonClick={onErrorClick}
        />
      )
    );
  };

  return (
    <>
      {renderCustomHeader()}
      {showAddPacksButton && (
        <Restricted
          permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
          renderContent={(hasAnyPermission) => renderAddPackButton(hasAnyPermission)}
          permissionPredicate={permissionPredicateForPacks}
        />
      )}
      <AddAssetsToPackModal
        showModal={packsModalShown}
        onClose={() => setPacksModalShown(false)}
        selectedItemIds={[assessmentId]}
        contentType="Assessment"
        onComplete={noop}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = assessmentsStateSelector(state).base;

  return {
    isContentLoaded: base.assessmentDetailsReducer.isContentLoaded,
    contentErrorMode: base.assessmentContentValidatorReducer.errorMode,
    contentResolved: base.assessmentContentValidatorReducer.resolved,
  };
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(DetailsHeaderContent);
export default ConnectedComponent;
