import { type FC, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { ConfirmLicensingStep, PacksContextStep } from "../commonSteps";
import { RolePermissions, Strings, type UsersGroupsContext } from "../../../enums";
import {
  bindAction,
  type LicenseConfirmationData,
  type NotifyStepSettings,
  type PacksContextItem,
} from "../../../interfaces";
import ModalWithSteps from "../../modal/ModalWithSteps";
import {
  type ModalActionConfig,
  type RenderActions,
  type RenderModalActionsType,
  type StepsOptions,
} from "components/assignmentModals/types";
import Restricted from "features/Application/Restricted";
import { renderSteps } from "components/assignmentModals/utils/renderSteps";
import { type AppDispatch } from "features/Application/globaltypes/redux";
import { resetContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { isEmpty, once } from "lodash";

import "./packIssueLicenseToPeopleModal.scss";

export interface PackIssueLicenseToPeopleModalProps {
  className: string;
  usersGroupsContext: UsersGroupsContext;
  showModal: boolean;
  itemsPacks: PacksContextItem[];
  selectedIds: number[];
  licenseConfirmationData: LicenseConfirmationData;
  onSecondStep: (selectedIds: number[]) => Promise<void>;
  onConfirm: (notificationSettings?: NotifyStepSettings) => Promise<void>;
  onCancel: () => void;
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  setIssueLicensePackIds: (ids: number[]) => void;
  renderFirstStep: (renderModalActions: RenderModalActionsType) => JSX.Element;
}

export type PackIssueLicenseToPeopleModalAllProps = PropsFromRedux & PackIssueLicenseToPeopleModalProps;

export const PackIssueLicenseToPeopleModal: FC<PackIssueLicenseToPeopleModalAllProps> = (
  props: PackIssueLicenseToPeopleModalAllProps,
) => {
  const [isDataValid, setIsDataValid] = useState(true);
  const [skipPacksContextStep, setSkipPacksContextStep] = useState(false);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();

  const { className, showModal, itemsPacks, selectedIds, licenseConfirmationData } = props;
  const {
    onCancel,
    renderTrigger,
    onSecondStep,
    onConfirm,
    setIssueLicensePackIds,
    renderFirstStep,
    resetContentPacks,
  } = props;

  const onClose = () => {
    setSkipPacksContextStep(false);
    resetContentPacks();
  };

  const renderPacksContextStep = (renderModalActions: RenderModalActionsType) => (
    <PacksContextStep
      header={packsContextStepHeader}
      info={itemsPacks}
      renderModalActions={renderModalActions}
      onIsDataValidChange={setIsDataValid}
      noDataLoaded={() => setSkipPacksContextStep(true)}
      setModalStepHeader={() => setPacksContextStepHeader(Strings.modalTitles.licensing)}
    />
  );

  const renderConfirmLicensingStep = (renderModalActions: RenderModalActionsType) => (
    <ConfirmLicensingStep
      header={Strings.modalTitles.licenseConfirmation}
      info={licenseConfirmationData}
      renderModalActions={renderModalActions}
      onIsDataValidChange={setIsDataValid}
      setIssueLicensePackIds={setIssueLicensePackIds}
    />
  );

  const renderModalActionsFirstStep =
    ({ next }: ModalActionConfig) =>
    (nextStep: () => void) =>
    (closeModal: Function) => {
      return (
        <Restricted permissions={[RolePermissions.UsersView]}>
          <Button
            basic
            color="blue"
            content="Cancel"
            className="cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
          <Button
            primary
            content="Next"
            className="next"
            disabled={next?.disabled}
            onClick={() => {
              next?.onClick?.();
              nextStep();
            }}
          />
        </Restricted>
      );
    };

  const renderModalActionsMiddleStep =
    ({ previous, next }: ModalActionConfig) =>
    (nextStep: () => void, prevStep: () => void) =>
    () => (
      <>
        <Button
          blur
          primary
          content="Previous"
          className="previous"
          disabled={previous?.disabled}
          onClick={() => {
            previous?.onClick?.();
            prevStep();
          }}
        />
        <Button
          blur
          primary
          content="Next"
          className="next"
          disabled={next?.disabled}
          onClick={() => {
            next?.onClick?.();
            nextStep();
          }}
        />
      </>
    );

  const renderModalActionsLastStep =
    ({ previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => {
      const confirmHandler = async () => {
        const onClickResult = await confirm?.onClick?.();
        onConfirm(onClickResult);
        closeModal();
      };
      return (
        <>
          <Button
            blur
            primary
            content="Previous"
            className="previous"
            onClick={() => {
              previous?.onClick?.();
              prevStep();
            }}
          />
          <Button
            primary
            content="Finish"
            className="confirm"
            disabled={confirm?.disabled}
            onClick={once(confirmHandler)}
          />
        </>
      );
    };

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsMiddleStep,
    renderModalActionsLastStep,
  };

  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderFirstStep,
      modalActionConfig: {
        next: {
          disabled: isEmpty(selectedIds),
          onClick: () => onSecondStep(selectedIds),
        },
      },
    },
    {
      renderStep: renderPacksContextStep,
      skipStep: skipPacksContextStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        next: {
          disabled: !isDataValid,
          onClick: () => setIsDataValid(false),
        },
      },
    },
    {
      renderStep: renderConfirmLicensingStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        confirm: {
          disabled: !isDataValid,
        },
      },
    },
  ];

  return (
    <ModalWithSteps
      className={className}
      scrolling={false}
      renderTrigger={renderTrigger}
      isLoading={false}
      showModal={showModal}
      onCancel={onCancel}
      onBeforeClose={onClose}
    >
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetContentPacks: bindAction(resetContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackIssueLicenseToPeopleModal);
export default ConnectedComponent;
