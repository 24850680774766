import { Button } from "components/buttons/button/Button";

export interface PeopleListFirstStepButtonsProps {
  onCancel: () => void;
  onNext: () => void;
  nextDisabled: boolean;
}

export const PeopleListFirstStepButtons = (props: PeopleListFirstStepButtonsProps) => {
  const { onCancel, onNext, nextDisabled } = props;
  return (
    <>
      <Button basic color="blue" className="cancel" content="Cancel" onClick={onCancel} />
      <Button primary className="next" content="Next" onClick={onNext} disabled={nextDisabled} />
    </>
  );
};

export default PeopleListFirstStepButtons;
