import React from "react";
import type ModalSizes from "../../../enums/modalSizes";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface RemoveUsersConfirmationModalProps {
  onCancel(): void;
  onContinue(): void;
  size?: ModalSizes;
  open: boolean;
  count: number
}

const RemoveUsersConfirmationModal = ({ onCancel, onContinue, size, open, count }: RemoveUsersConfirmationModalProps) => {
  return <ActionConfirmationModal
    open={open}
    onCancel={onCancel}
    onContinue={onContinue}
    title={`Delete User(s)?`}
    size={size}
    withWarningLabel={false}
    confirmLabel="Delete"
  >
    Are you sure you want to delete the selected user(s)?
  </ActionConfirmationModal>
};

export default RemoveUsersConfirmationModal;
