import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { isEmpty } from "lodash";

import { ItemsView } from "../../../../views";
import UsersNoResultsDragNDrop from "../../../../views/people/users/UsersNoResultsDragNDrop";
import { SearchInput } from "../../../../components";
import { DragAndDropArea } from "../../../../components/dragAndDrop";
import UsersFilterForm from "../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import userListUtils from "../../../../utils/userListUtils";
import { type FiltersMap } from "../../../../utils/filterUtils";
import { type AddPeopleToContentTypes, type RolePermissions, SortingDirection, ViewType } from "../../../../enums";
import { UserListRow } from "./UserListRow";
import { columnOptions } from "./getColumnOptions";
import UserListDragAndDropSectionFactory from "./DragAndDropSections/DragAndDropSectionFactory";
import { type GetUsersListAction } from "../../types";

import "./userList.scss";

const defaultSortingColumnName = "added";

export interface UserListProps {
  accountId: number;
  users: any;
  usersCount: number;
  isLoading: boolean;
  filterOptions: FiltersMap;
  appliedFilter: FiltersMap;
  search: string;
  onSearchChange: (search: string) => void;
  onDeleteUser: (id: number) => void;
  onAddToGroup: (id: number) => void;
  onAddToRole: (id: number) => void;
  onAddToContent: (id: number, contentType: AddPeopleToContentTypes) => void;
  onFileDropped: (file: any) => void;
  selectedIds: number[];
  usersOverviewActions: {
    fetchUsers: GetUsersListAction;
  };
  usersFilterActions: any;
  onSelectedUsersChanged: (ids: number[]) => void;
  renderAddUserButton: () => React.ReactElement | undefined;
  goToEditUser: (id: number) => void;
  hasDragAndDrop: boolean;
  disabledByPermission: boolean;
  listViewRtnEvents: string[];
  dndFactory?: UserListDragAndDropSectionFactory;
  setReloadListItems?: (reloadListItems: (enableSorting: boolean) => void) => void;
  permissions?: RolePermissions[];
}

const UserList = ({
  users,
  usersCount,
  isLoading,
  filterOptions,
  appliedFilter,
  search,
  onSearchChange,
  usersFilterActions,
  onSelectedUsersChanged,
  renderAddUserButton,
  usersOverviewActions,
  goToEditUser,
  onDeleteUser,
  onAddToGroup,
  onAddToRole,
  onAddToContent,
  onFileDropped,
  selectedIds,
  hasDragAndDrop,
  disabledByPermission,
  listViewRtnEvents,
  setReloadListItems,
  dndFactory = new UserListDragAndDropSectionFactory(onFileDropped),
  permissions,
}: UserListProps) => {
  let getUsers = userListUtils.getOverviewUsers(usersOverviewActions.fetchUsers, search);

  const buildTableBody = (user: any) => (
    <UserListRow
      user={user}
      goToEditUser={goToEditUser}
      onAddToGroup={onAddToGroup}
      onAddToRole={onAddToRole}
      onAddToContent={onAddToContent}
      onDeleteUser={onDeleteUser}
      isEllipsisMenuDisabled={selectedIds.includes(user.id) || disabledByPermission}
      hasPermission={!disabledByPermission}
    />
  );

  const getFilterOptions = () => usersFilterActions.getFilterOptions();

  // @ts-ignore
  const getFilterForm = () => <UsersFilterForm showLastLoginFilter />;

  const isFiltered = () => !!search || !isEmpty(appliedFilter);

  const onSearchTermChange = (term: string) => {
    getUsers = userListUtils.getUsersFactory(usersOverviewActions.fetchUsers, search);
    onSearchChange(term);
  };

  const getSearchInput = () => (
    <SearchInput placeholder="Search for Users..." defaultValue={search} onChange={onSearchTermChange} />
  );

  return (
    <ItemsView
      className="alignment-padding"
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      getData={getUsers}
      itemsInlineCount={usersCount}
      isLoading={isLoading}
      items={users}
      dragAndDrop={
        hasDragAndDrop ? (
          <DndProvider backend={HTML5Backend}>
            <DragAndDropArea {...dndFactory.getDragAndDropSection()} />
          </DndProvider>
        ) : null
      }
      buildTableBody={buildTableBody}
      renderFilterForm={getFilterForm}
      filterOptions={filterOptions}
      filterOptionsLoading={filterOptions.isLoading}
      appliedFilter={appliedFilter}
      resetFilter={usersFilterActions.resetUserFilter}
      applyFilter={usersFilterActions.setUserFilter}
      getFilterOptions={getFilterOptions}
      onSelectedListItemsChanged={onSelectedUsersChanged}
      sortingDirection={SortingDirection.Descending}
      sortingColumnName={defaultSortingColumnName}
      noResultsContent={<UsersNoResultsDragNDrop createUserButton={renderAddUserButton()} filtered={isFiltered()} />}
      selectedIds={selectedIds}
      renderSearch={getSearchInput}
      setReloadListItems={setReloadListItems}
      isSelectDisabled={() => disabledByPermission}
      listViewRtnEvents={listViewRtnEvents}
      permissions={permissions}
    />
  );
};

export default UserList;
