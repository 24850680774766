import { PriorityLevels } from "enums";
import { Icon } from "semantic-ui-react";

export const getPriorityLevels = (disableDefault?: boolean) => {
  return [
    {
      text: <span className="priority priority-levels-element">None</span>,
      value: PriorityLevels.none,
      disabled: disableDefault,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-arrow-square-down" /> Recommended
        </span>
      ),
      value: PriorityLevels.recommended,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-arrow-square-up" /> Important
        </span>
      ),
      value: PriorityLevels.important,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-exclamation-square" /> Required
        </span>
      ),
      value: PriorityLevels.required,
    },
  ];
};
