import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { getPrefix } from "features/Application/slices/models";
import { fetchGroupEnrollmentUsers } from "../thunks/enrollmentUsersThunk";
import { type EnrollmentUser, type EnrollmentUsersState } from "../types";

const initialState: EnrollmentUsersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: undefined,
  error: undefined,
};

const groupEnrollmentUsersSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.Groups,
    name: "enrollmentUsers",
  }),
  initialState,
  reducers: {
    fetchBegin(state) {
      state.isLoading = true;
    },
    fetchSuccess(state: EnrollmentUsersState, action: PayloadAction<{ items: EnrollmentUser[]; itemsCount: number }>) {
      state.items = action.payload.items;
      state.itemsCount = action.payload.itemsCount;
      state.isLoading = false;
    },
    fetchFailure(state: EnrollmentUsersState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetEnrollmentUsers() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupEnrollmentUsers.pending, (state, action) => {
        state.isLoading = true;
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchGroupEnrollmentUsers.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.isLoading && state.currentRequestId === requestId) {
          state.currentRequestId = undefined;
          state.isLoading = false;
          state.items = action.payload.items;
          state.itemsCount = action.payload.itemsCount;
        }
      })
      .addCase(fetchGroupEnrollmentUsers.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.isLoading && state.currentRequestId === requestId) {
          state.isLoading = false;
          state.error = action.error as Error;
        }
      });
  },
});

export const { fetchBegin, fetchSuccess, resetEnrollmentUsers, fetchFailure } = groupEnrollmentUsersSlice.actions;

export const enrollmentUsersReducer = groupEnrollmentUsersSlice.reducer;
