import React from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { type MessagesNoResultsProps } from "../types";

function MessagesNoResults(props: MessagesNoResultsProps) {
  const description = (
    <span>
      Looks like you don't have any messages yet.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults title="You don’t have any messages" description={description} iconClassName="fa-messages">
      {props.createButton}
    </NoResults>
  );
}

export default MessagesNoResults;
