import { type FC } from "react";
import { Button } from "components/buttons/button/Button";
import { pluralize } from "../../../utils/stringUtils";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface Props {
  packUsageCount: number;
  triggerOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const DeletePackConfirmationDialog: FC<Props> = (props: Props) => {
  const renderModalActions = (closeModal: Function) => {
    return (
      <>
        <Button
          primary
          content={"Close"}
          onClick={() => {
            props.onCancel();
            closeModal();
          }}
        />
      </>
    );
  };

  return props.packUsageCount > 0 ? (
    <>
      <ActionConfirmationModal
        open={props.triggerOpen}
        title="Pack can't be deleted"
        customModalActions={renderModalActions}
        withWarningLabel={false}
        onCancel={props.onCancel}
      >
        Content in this Pack is purchased by {props.packUsageCount} {pluralize("Account", props.packUsageCount)} and
        must be removed from all Accounts before this Pack can be removed.
      </ActionConfirmationModal>
    </>
  ) : (
    <>
      <ActionConfirmationModal
        open={props.triggerOpen}
        title="Delete this pack?"
        withWarningLabel={false}
        confirmLabel="Delete"
        onContinue={props.onContinue}
        onCancel={props.onCancel}
      >
        This will remove Pack access for all users and delete all user data relating to this Pack. This Pack will still
        be available to customers who have a current subscription to this Pack until their subscription expires.
      </ActionConfirmationModal>
    </>
  );
};

export default DeletePackConfirmationDialog;
