import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import "./baseCircleIconButton.scss";

function BaseCircleIconButton(props) {
  const { icon, tooltipText } = props;

  return (
    <Popup
      className="circle-icon-button-tooltip"
      trigger={<span className={"base-circle-icon-button"}>{icon}</span>}
      content={tooltipText}
      inverted
      position="top center"
      size="small"
    />
  );
}

BaseCircleIconButton.propTypes = {
  icon: PropTypes.element,
};

export default BaseCircleIconButton;
