import { useCallback, useEffect, useMemo, useState } from "react";

type Props = {
  sortingColumnName?: string;
  defaultSortingColumn: string;
};

type ReturnVal = {
  /**
   * Pass this into defaultSortingColumnName
   */
  sortingColumn: string;
  /**
   * Call this inside your onSearchChange handler to update the column
   * to sort by
   * @param newSearchTerm
   * @returns
   */
  onSearchChange: (newSearchTerm: string) => void;
};

export const useSearchSorting = ({ sortingColumnName, defaultSortingColumn }: Props): ReturnVal => {
  const activeSortColumn = useMemo(
    () => (sortingColumnName || defaultSortingColumn),
    [defaultSortingColumn, sortingColumnName],
  );

  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [columnToReturn, setColumnToReturn] = useState(sortingColumnName || defaultSortingColumn);

  const onSearchChange = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm !== currentSearchTerm) {
        // If there is a term, then no column should be used for sorting
        // (so most relevant results return at the top)
        setColumnToReturn(newSearchTerm ? "" : activeSortColumn);
      } else {
        setColumnToReturn(activeSortColumn);
      }
      setCurrentSearchTerm(newSearchTerm);
    },
    [activeSortColumn, currentSearchTerm],
  );

  // Enables the sort menu to be changed when
  // a search query is entered
  useEffect(() => {
    sortingColumnName && setColumnToReturn(sortingColumnName);
  }, [sortingColumnName]);

  return { sortingColumn: columnToReturn, onSearchChange };
};
