import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { getPrefix } from "../../../../../Application/slices/models";

export interface UploadCsvFileState {
  isUploading: boolean;
  progress: number;
  error: Error | null;
}

const initialState: UploadCsvFileState = {
  isUploading: false,
  progress: 0,
  error: null,
};

export const uploadCsvFileSlice = createSlice({
  name: getPrefix({
    entity: ReducerEntityPrefixTypes.AccountUploadCsvFile,
    name: "UploadStatus",
    namespace: ReducerNamespaceTypes.Accounts,
  }),
  initialState: initialState,
  reducers: {
    uploadBegin(state: UploadCsvFileState) {
      state.isUploading = true;
    },
    uploadSuccess(state: UploadCsvFileState) {
      state.isUploading = false;
      state.progress = 100;
      state.error = null;
    },
    uploadFail(state: UploadCsvFileState, action: PayloadAction<Error>) {
      state.isUploading = false;
      state.progress = 100;
      state.error = action.payload;
    },
    updateUploadProgress(state: UploadCsvFileState, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { uploadBegin, uploadSuccess, uploadFail, updateUploadProgress, reset } = uploadCsvFileSlice.actions;

export default uploadCsvFileSlice.reducer;

export type UploadCsvFileActions = typeof uploadCsvFileSlice.actions;
