import * as Yup from "yup";

import { labelValues, softwareApplicationValues } from "./tagsValidationSchema";
import { uploadedPdfs } from "./filesValidationSchema";
import { requiredTextFieldMinMax } from "./commonValidationSchemas";

const titleLengths = {
  min: 3,
  max: 120,
};

export const descriptionLengths = {
  min: 3,
  max: 256,
};

const pdfConfiguration = Yup.object().shape({
  title: requiredTextFieldMinMax("Title", titleLengths.min, titleLengths.max),
  description: requiredTextFieldMinMax("Description", descriptionLengths.min, descriptionLengths.max),
});

const requiredFileName = Yup.string().trim().required("PDF file is required");

export const getPdfConfigurationSchema = (shouldValidateFiles: boolean) =>
  shouldValidateFiles
    ? pdfConfiguration.concat(Yup.object().shape({ uploadedPdfs }))
    : pdfConfiguration.concat(Yup.object().shape({ fileName: requiredFileName }));

export const getUploadedPdfConfigurationSchema = () =>
    pdfConfiguration.concat(Yup.object().shape({ uploadedPdfs }));

export const pdfSettings = Yup.object().shape({
  labels: labelValues,
  softwareApplications: softwareApplicationValues,
});
