import { isEmpty } from "lodash";
import { type SortingDirection } from "../enums";
import { Filter, type FiltersMap, operators } from "./filterUtils";
import { type Filters } from "./queryUtils";

const getArrayFilters = (filters: Filters) =>
  Object.fromEntries(Object.entries(filters).filter(([_, value]) => Array.isArray(value) && value.length > 0));

const utils = {
  formatOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection) => {
    const name = sortingColumnName.toLowerCase();
    if (name === "modified") {
      return `dateModified ${sortingDirection}`;
    }
    return `${name} ${sortingDirection}`;
  },
  formatFilters: (filters: Filters) => {
    let queryParams: FiltersMap = {};

    if (!isEmpty(filters)) {
      queryParams = getArrayFilters(filters);
      let filterParameters = [
        {
          key: "dateCreated",
          operator: operators.range,
        },
        {
          key: "dateModified",
          operator: operators.range,
        },
      ];
      const filterObject = new Filter(filterParameters);
      const filterParam = filterObject.buildFilterQuery(filters);
      if (!isEmpty(filterParam)) {
        queryParams.filter = filterParam;
      }
    }

    return queryParams;
  },
};

export default utils;
