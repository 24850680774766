import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type EventUser } from "../../types/state";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface EventUsersState extends SelectedItemsState<EventUser> {}

const initialState: EventUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const eventUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Events, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  eventUsersSlice.actions;

export default eventUsersSlice.reducer;
export type eventUsersSliceStateType = ReturnType<typeof eventUsersSlice.reducer>;
