import { type FC, useEffect, useMemo } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Dimmer, Segment } from "semantic-ui-react";
import { FormSectionHeader, RestrictedResource } from "../../../../components";
import SubscriptionAgreementList from "./SubscriptionAgreementsList/SubscriptionAgreementsList";
import { connect, type ConnectedProps } from "react-redux";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import {
  subscriptionAgreementLoadingSelector,
  subscriptionAgreementSelector,
  subscriptionHasAccessSelector,
} from "../../state/slices/subscriptionAgreementSlice";
import * as subscriptionAgreementThunk from "../../state/thunks/subscriptionAgreementThunk";

import "./subscriptionAgreement.scss";

interface SubscriptionAgreementOwnProps {
  accountId: number;
}

export type SubscriptionAgreementProps = PropsFromRedux & SubscriptionAgreementOwnProps;

export const SubscriptionAgreement: FC<SubscriptionAgreementProps> = ({
  fetchSubscription,
  subscriptionAgreement,
  isAccessAuthorized,
  accountId,
  isLoading,
}) => {
  useEffect(() => {
    fetchSubscription(accountId);
  }, [fetchSubscription, accountId]);

  const contractSignatures = useMemo(
    () => (subscriptionAgreement.isSigned ? [subscriptionAgreement.signature!] : []),
    [subscriptionAgreement],
  );

  return (
    <RestrictedResource isAuthorized={isAccessAuthorized}>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted />
        <section className="subscription-agreement">
          <FormSectionHeader title="Subscription Agreement" />
          <SubscriptionAgreementList contractSignatures={contractSignatures} isLoading={isLoading} />
          <FormSectionHeader title="Legal Resources" />
          <a
            className="policy-link"
            href="https://www.brainstorminc.com/legal#privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>

          <a href="mailto:Legal@brainstorminc.com" target="_blank" rel="noreferrer noopener">
            Legal@brainstorminc.com
          </a>
        </section>
      </Dimmer.Dimmable>
    </RestrictedResource>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    subscriptionAgreement: subscriptionAgreementSelector(state),
    isLoading: subscriptionAgreementLoadingSelector(state),
    isAccessAuthorized: subscriptionHasAccessSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchSubscription: bindActionCreators(subscriptionAgreementThunk.fetchSubscriptionAgreement, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SubscriptionAgreement);
export default ConnectedComponent;
