import * as actionTypes from "../actionTypes/threatDefenceLandingPagesOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import { type ThreatDefenceLandingPagesOverviewState, type ThreatDefenceLandingPageOverview } from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../../interfaces/redux";

const initialState: ThreatDefenceLandingPagesOverviewState = {
  landingPages: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const threatDefenceLandingPagesOverviewHandlers = () => {
  const {
    getThreatDefenceLandingPagesBegin: getLandingPagesBegin,
    getThreatDefenceLandingPagesSuccess: getLandingPagesSuccess,
    getThreatDefenceLandingPagesFailure: getLandingPagesFailure,
  } = actionTypes;

  const getLandingPagesBeginHandler = (
    state: ThreatDefenceLandingPagesOverviewState,
  ): ThreatDefenceLandingPagesOverviewState => ({
    ...state,
    landingPages: new Array<ThreatDefenceLandingPageOverview>(),
    isLoading: true,
  });

  const getLandingPagesSuccessHandler = (
    state: ThreatDefenceLandingPagesOverviewState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceLandingPageOverview>>,
  ): ThreatDefenceLandingPagesOverviewState => ({
    ...state,
    landingPages: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getLandingPagesFailureHandler = (
    state: ThreatDefenceLandingPagesOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceLandingPagesOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getLandingPagesBegin]: getLandingPagesBeginHandler,
    [getLandingPagesSuccess]: getLandingPagesSuccessHandler,
    [getLandingPagesFailure]: getLandingPagesFailureHandler,
  };
};

export const threatDefenceLandingPagesOverview = createReducer(initialState, [
  threatDefenceLandingPagesOverviewHandlers,
]);
