import { createSlice, type SerializedError } from "@reduxjs/toolkit";

import { type ApiClient } from "../../../types";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import {
  createApiClient,
  fetchApiClients,
  regenerateApiClientSecret,
  updateApiClientStatus,
} from "../thunks/apiKeysThunk";

export interface ApiKeysState {
  items: ApiClient[];
  isLoading: boolean;
  error?: SerializedError;
}

interface CreateApiClientCompletedMessage {
  payload: {
    id: number;
    clientId: string;
    clientSecret: string;
  };
  operationId: string;
}

const initialState: ApiKeysState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const apiKeysSlice = createSlice({
  name: getPrefix({ namespace: ReducerNamespaceTypes.Accounts, entity: ReducerEntityPrefixTypes.API, name: "keys" }),
  initialState,
  reducers: {
    createClientCompleted: (state, { payload }: PayloadAction<CreateApiClientCompletedMessage>) => {
      const client = state.items.find((c) => c.pendingState?.operationId === payload.operationId);
      if (client) {
        const p = payload.payload;
        client.pendingState = undefined;
        client.id = p.id;
        client.clientId = p.clientId;
        client.clientSecret = p.clientSecret;
      }
    },
    updateClientStatusCompleted: (state, { payload }: PayloadAction<{ operationId: string }>) => {
      const client = state.items.find((c) => c.pendingState?.operationId === payload.operationId);
      if (client) {
        client.pendingState = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchApiClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload;
      })
      .addCase(fetchApiClients.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(createApiClient.pending, (state, action) => {
        const accessType = action.meta.arg.apiAccessType;
        state.items.push({
          id: 0,
          clientId: "",
          enabled: true,
          accessType,
          pendingState: { loading: true },
        });
      })
      .addCase(createApiClient.fulfilled, (state, action) => {
        const accessType = action.meta.arg.apiAccessType;
        const operationId = action.payload.operationId;
        const client = state.items.find((c) => c.accessType === accessType);

        if (client?.pendingState) {
          client.pendingState.operationId = operationId;
        }
      })
      .addCase(createApiClient.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(updateApiClientStatus.pending, (state, action) => {
        const { moboId, enabled } = action.meta.arg;
        const client = state.items.find((c) => c.id === moboId);
        if (client) {
          client.enabled = enabled;
          client.pendingState = { loading: true };
        }
      })
      .addCase(updateApiClientStatus.fulfilled, (state, action) => {
        const { moboId } = action.meta.arg;
        const operationId = action.payload.operationId;
        const client = state.items.find((c) => c.id === moboId);

        if (client?.pendingState) {
          client.pendingState.operationId = operationId;
        }
      })
      .addCase(updateApiClientStatus.rejected, (state, action) => {
        state.error = action.error;
      })

      .addCase(regenerateApiClientSecret.pending, (state, action) => {
        const id = action.meta.arg;
        const client = state.items.find((c) => c.id === id);
        if (client) {
          client.pendingState = { loading: true };
        }
      })
      .addCase(regenerateApiClientSecret.fulfilled, (state, action) => {
        const id = action.meta.arg;
        const client = state.items.find((c) => c.id === id);
        if (client) {
          client.clientSecret = action.payload;
          client.pendingState = undefined;
        }
      })
      .addCase(regenerateApiClientSecret.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export const { createClientCompleted, updateClientStatusCompleted } = apiKeysSlice.actions;

export type ApiKeysSliceType = ReturnType<typeof apiKeysSlice.reducer>;

export default apiKeysSlice.reducer;
