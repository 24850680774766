import { type FC, useState } from "react";
import Activity from "./sections/Activity";
import { GoalArea } from "../shared/GoalArea";
import ReportingFilter from "components/reportingFilter/ReportingFilter";
import * as performanceUtils from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { connect, type ConnectedProps } from "react-redux";
import { type RootState } from "features/Application/globaltypes/redux";
import { selectMenuItemsData } from "features/Reporting/state/menu/integrationMenuSlice";

export interface Props extends PropsFromRedux {}

export const OnedriveReport: FC<Props> = ({ accountId, integrations }) => {
  const isBsiAccount = performanceUtils.isBsi(accountId);

  const [dateFilter, setDateFilter] = useState(
    performanceUtils.defaultDateFilter({ accountId, includeAccounts: isBsiAccount, includeAccountsDropdown: false }),
  );
  const goalAreaEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  const isMicrosoftIntegrationEnabled = integrations.MSGraph;

  const getAllCustomerAccounts = dateFilter?.showCustomers === true;

  return (
    <div className="onedrive-root">
      <div className="onedrive-heading">
        <h2>Summary</h2>
        <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} includeAccounts={isBsiAccount} />
      </div>
      {goalAreaEnabled && (
        <>
          <GoalArea
            dateFilter={dateFilter}
            header="Files Shared"
            application="OneDrive"
            activity="Files Shared"
            isBsi={getAllCustomerAccounts}
          />
          <GoalArea
            dateFilter={dateFilter}
            header="Files Synced"
            application="OneDrive"
            activity="Files Synced"
            isBsi={getAllCustomerAccounts}
          />
          <GoalArea
            dateFilter={dateFilter}
            header="Files Viewed"
            application="OneDrive"
            activity="Files Viewed"
            isBsi={getAllCustomerAccounts}
          />
        </>
      )}
      {isMicrosoftIntegrationEnabled && !getAllCustomerAccounts && <Activity dateFilter={dateFilter} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  integrations: selectMenuItemsData(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(OnedriveReport);
export default ConnectedComponent;
