import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";
import { TextTruncate } from "../textTruncators/TextTruncators";
import cn from "classnames";

import { Tooltip } from "../common/tooltip";

import "./multipleItems.scss";

const MultipleItems = (props) => {
  const { items, singleLine, className } = props;

  const renderItems = () => {
    const itemsText = items.join(", ");

    if (singleLine) {
      return <span className="items single-line">{itemsText}</span>;
    }

    return (
      <Tooltip
        target={
          <TextTruncate className="items" lines={2}>
            {itemsText}
          </TextTruncate>
        }
        content={itemsText}
        position="top center"
      />
    );
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={cn("multiple-items", className)}>
      <Label circular color="grey" className="count">
        {items.length}
      </Label>
      {renderItems()}
    </div>
  );
};

MultipleItems.defaultProps = {
  items: [],
};

MultipleItems.propTypes = {
  items: PropTypes.array.isRequired,
  singleLine: PropTypes.bool,
  className: PropTypes.string,
};

export default MultipleItems;
