export const saveThreatDefenceSmtpAuthSendingProfile = "SAVE_THREAT_DEFENCE_SMTP_SENDING_PROFILE";
export const saveThreatDefenceSmtpAuthSendingProfileSettings = "SAVE_THREAT_DEFENCE_SMTP_SENDING_PROFILE_SETTINGS";
export const saveSmtpAuthSendingProfileVerification = "SAVE_THREAT_DEFENCE_SMTP_SENDING_PROFILE_VERIFICATION";
export const updateSmtpAuthSendingProfileVerificationStatus = "UPDATE_THREAT_DEFENCE_SMTP_SENDING_PROFILE_VERIFICATION";
export const resetSmtpAuthSendingProfileVerificationStatus = "RESET_THREAT_DEFENCE_SMTP_SENDING_PROFILE_VERIFICATION";
export const clearThreatDefenceSmtpAuthSendingProfile = "CLEAR_THREAT_DEFENCE_SMTP_SENDING_PROFILE";
export const clearSmtpAuthSendingProfileVerificationStatus = "CLEAR_SMTP_SENDING_PROFILE_VERIFICATION";

export const getSmtpAuthSendingProfileBegin = "GET_SMTP_SENDING_PROFILE_BEGIN";
export const getSmtpAuthSendingProfileSuccess = "GET_SMTP_SENDING_PROFILE_SUCCESS";
export const getSmtpAuthSendingProfileFailure = "GET_SMTP_SENDING_PROFILE_FAILURE";

export const getSmtpAuthSendingProfileSettingsBegin = "GET_SMTP_SENDING_PROFILE_SETTINGS_BEGIN";
export const getSmtpAuthSendingProfileSettingsSuccess = "GET_SMTP_SENDING_PROFILE_SETTINGS_SUCCESS";
export const getSmtpAuthSendingProfileSettingsFailure = "GET_SMTP_SENDING_PROFILE_SETTINGS_FAILURE";

export const setSmtpAuthSendingProfile = "SET_SMTP_SENDING_PROFILE";
export const setSmtpAuthSendingProfileIsLoaded = "SET_SMTP_SENDING_PROFILE_ISLOADED";
