import React from "react";
import { type FormikProps } from "formik";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../../../../Application/globaltypes/redux";
import { ValidatedForm } from "../../../../../../../components";
import FormSectionHeader from "../../../../../../../components/forms/FormSectionHeader";
import VerificationStatus from "../../../../../../../components/common/verification/verificationStatus/VerificationStatus";
import VerificationStatusTypes from "../../../../../../../enums/verificationStatusTypes";
import { type ThreatDefenceSmtpAuthSendingProfileVerificationView } from "../../types/state";
import { type VerificationFormProps } from "../types";
import * as notificationsActions from "../../../../../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../../../../../BackgroundTasks/state/backgroundTasksActions";
import * as smtpAuthSendingProfileDetailsActions from "../../state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";
import backgroundTask from "../../../../../../BackgroundTasks/backgroundTask";
import sendingProfileDataService from "../../../services/threatDefenceSendingProfilesDataService";
import "./verificationForm.scss";

export type VerificationFormPropsAll = FormikProps<ThreatDefenceSmtpAuthSendingProfileVerificationView> &
  VerificationFormProps &
  PropsFromRedux;

const VerificationForm: React.FC<VerificationFormPropsAll> = (props: VerificationFormPropsAll) => {
  const getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      props;
    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };

  const renderVerificationStatusInfo = (): any => {
    const {
      verification: { isVerified, isFailed, errorMessage },
    } = props;
    let verificationStatus = VerificationStatusTypes.Unverified;
    if (isFailed) {
      verificationStatus = VerificationStatusTypes.Failed;
    } else if (isVerified) {
      verificationStatus = VerificationStatusTypes.Verified;
    }
    return <VerificationStatus verificationStatus={verificationStatus} verificationError={errorMessage} />;
  };

  const verify = async () => {
    const {
      id,
      values: { email },
      sendingProfile: { senderName, senderEmail },
    } = props;
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = props;

    const params = {
      id: "VerifySmtpSendingProfile",
      title: `Verifying ${senderName}(${senderEmail})`,
      indeterminate: true,
      getMessageIds: async () => {
        const response = await sendingProfileDataService.verifySmtpAuthSendingProfileAsync(id, email);
        return [response.data];
      },
      successTransientMessage: "Sending profile has been verified successfully.",
      failureTransientMessage: "Sending profile verification failed!",
    };

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });

    const verificationData = { ...props.values, id: props.id };
    props.detailsActions.saveSmtpAuthSendingProfileVerification(verificationData);
  };

  const renderFields = (): any => {
    const { values, onIsValidChange, disabled, errors } = props;
    const validatedFieldProps = getValidateFieldProps();
    const canVerify = values.email && !errors.hasOwnProperty("email");

    return (
      <div className="smtp-sending-profile-verification-form-container">
        <ValidatedForm.InputField
          label="Email"
          value={values.email}
          propertyName="email"
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
          {...validatedFieldProps}
        />
        {canVerify && (
          <div className="ui button blue verify-button" data-testid="verify-button" onClick={verify}>
            Verify
          </div>
        )}
      </div>
    );
  };

  const { isLoading } = props;
  return (
    <ValidatedForm dirty={props.dirty} disablePreventTransitionPrompt isLoading={isLoading} formWidthComputer={6}>
      <FormSectionHeader title="Verify" />
      {renderVerificationStatusInfo()}
      {renderFields()}
    </ValidatedForm>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const sendingProfileEntityStateReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer;
  const sendingProfileDetailsReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer;

  return {
    id: sendingProfileEntityStateReducer.entityId,
    sendingProfile: sendingProfileDetailsReducer.sendingProfile,
    verification: sendingProfileDetailsReducer.verification,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  detailsActions: bindActionCreators(smtpAuthSendingProfileDetailsActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(VerificationForm);
export default ConnectedComponent;
