import axios, { type AxiosResponse } from "axios";
import { type NotifyStepSettings } from "../../../interfaces";

export interface INotificationSettingsService {
  saveSettings(settings: NotifyStepSettings): Promise<AxiosResponse<string>>;
}

export const notificationSettingsService: INotificationSettingsService = {
  async saveSettings(settings: NotifyStepSettings) {
    return axios.put(`/api/system-notifications/notification-options/${settings.id}`, settings);
  },
};

export default notificationSettingsService;
