import { type PayloadAction } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, type FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { type SurveyFiltersEnum } from "../../types/models";
import { SortingDirection } from "enums";

export interface SurveyFiltersState extends FiltersState<SurveyFiltersEnum> {
  search?: string;
}

export const initialState: SurveyFiltersState = {
  filterOptions: {} as GenericFiltersMap<SurveyFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<SurveyFiltersEnum>,
  isLoading: false,
  pagination: 1,
  sortingColumnName: "added",
  sortingDirection: SortingDirection.Descending,
};

const surveyFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Surveys,
    name: "overviewFilters",
  },
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    resetSearch(state) {
      state.search = "";
    },
  },
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
} = surveyFiltersSlice.actions;

export default surveyFiltersSlice.reducer;
