import { type ChangeEvent, useEffect, useState } from "react";
import { Accordion } from "semantic-ui-react";
import { connect, type ConnectedProps } from "react-redux";
import NavigationMenuItem from "../navigationMenuItem/NavigationMenuItem";
import environmentConfig from "../../../configuration/environmentConfig";
import ReportingNavigationMenu from "../reportingNavigationMenu/ReportingNavigationMenu";

import { fetchMostAccessedAccounts as fetchMostAccessedAccountsRedux } from "../../../features/Accounts/MostAccessedAccounts/state/mostAccessedAccountsActions";
import { RolePermissions, RouteNames } from "../../../enums";
import { Tooltip } from "../../common/tooltip";
import { useRestrictedCheck } from "../../../features/Application/hooks";

import { bindAction } from "../../../interfaces";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag";
import { FeatureFlags } from "../../../featureFlags";
import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";

export type TopMenuAccordionProps = {
  activeItems: string[];
  collapsed: boolean;
  fetchMostAccessedAccounts: () => void;
  topLevelItemClick: (path?: string) => void;
} & PropsFromRedux;

const renderNavigationMenuItems = (hideNavigation: () => void) => {
  const items = [
    { label: "Flows", path: "flows" },
    { label: "Events", path: "events" },
    { label: "Communications", path: "communications" },
    { label: "Videos", path: "assets/videos" },
    { label: "Surveys", path: "assets/surveys" },
    { label: "Assessments", path: "assets/assessments" },
    { label: "PDFs", path: "assets/pdfs" },
  ];

  return items.map((item) => (
    <NavigationMenuItem withMobo key={item.path} label={item.label} path={item.path} hideNavigation={hideNavigation} />
  ));
};

type ContentProps = {
  topLevelItemClick: (path?: string) => void;
  collapsed: boolean;
  activeItems: string[];
  toggleNavigation: boolean;
  hideNavigation: () => void;
};

function renderContents({ topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation }: ContentProps) {
  return (
    <NavigationMenuItem
      label="Content"
      icon="fa-folder-open"
      className="content-menu-item"
      path={RouteNames.content}
      onClick={topLevelItemClick}
      expanded={!collapsed && activeItems.includes(RouteNames.content)}
      toggleNavigation={toggleNavigation}
    >
      {collapsed && (
        <NavigationMenuItem
          label="Content"
          path="content"
          onClick={topLevelItemClick}
          expanded={activeItems.includes(RouteNames.content)}
          container
        >
          {renderNavigationMenuItems(hideNavigation)}
        </NavigationMenuItem>
      )}

      {!collapsed && <NavigationMenuItem withMobo label="Flows" path={"flows"} />}
      {!collapsed && <NavigationMenuItem withMobo label="Events" path={"events"} />}
      {!collapsed && <NavigationMenuItem withMobo label="Communications" path={"communications"} />}
      {!collapsed && <NavigationMenuItem withMobo label="Videos" path={"assets/videos"} />}
      {!collapsed && <NavigationMenuItem withMobo label="Surveys" path={"assets/surveys"} />}
      {!collapsed && <NavigationMenuItem withMobo label="Assessments" path={"assets/assessments"} />}
      {!collapsed && <NavigationMenuItem withMobo label="PDFs" path={"assets/pdfs"} />}

      <NavigationMenuItem
        label="Simulated Phishing"
        onClick={topLevelItemClick}
        path={RouteNames.simulatedPhishing}
        expanded={activeItems.includes(RouteNames.contentSimulatedPhishing)}
        toggleNavigation={toggleNavigation}
      >
        <NavigationMenuItem label="Campaigns" path="campaigns" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Email Templates" path="email-templates" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Landing Pages" path="landing-pages" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Feedback Pages" path="feedback-pages" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Sending Profiles" path="sending-profiles" hideNavigation={hideNavigation} />
      </NavigationMenuItem>
    </NavigationMenuItem>
  );
}

function renderRestrictedContents({
  topLevelItemClick,
  collapsed,
  activeItems,
  toggleNavigation,
  hideNavigation,
}: ContentProps) {
  return (
    <NavigationMenuItem
      label="Content"
      icon="fa-folder-open"
      className="content-menu-item"
      path={RouteNames.content}
      onClick={topLevelItemClick}
      expanded={!collapsed && activeItems.includes(RouteNames.content)}
      toggleNavigation={toggleNavigation}
    >
      {renderNavigationMenuItems(hideNavigation)}
    </NavigationMenuItem>
  );
}

const accountsPermissions = [RolePermissions.AccountsSettingsCreate, RolePermissions.TemplatesManage];

export function TopMenuAccordion({
  activeItems,
  collapsed,
  topLevelItemClick,
  fetchMostAccessedAccounts,
  hasAnyLinkedAccount,
}: TopMenuAccordionProps) {
  const [toggleNavigation, setToggleNavigation] = useState(true);
  const linkedAccountPath = `${RouteNames.accounts}/${RouteNames.linkedAccounts}`;
  const showTemplates = useRestrictedCheck([RolePermissions.TemplatesView, RolePermissions.TemplatesManage]);
  const showAccounts = useRestrictedCheck(accountsPermissions) || hasAnyLinkedAccount;
  const templatesFlag = useFeatureFlag(FeatureFlags.GroupTemplates);

  useEffect(() => {
    fetchMostAccessedAccounts();
  }, [fetchMostAccessedAccounts]);

  const hideNavigation = () => {
    setToggleNavigation(false);
  };

  const showNavigation = () => {
    setToggleNavigation(true);
  };

  const renderAccounts = () => {
    if (!showAccounts) return null;

    if (!templatesFlag) {
      return (
        <Tooltip
          content={"Accounts"}
          showAlways={collapsed}
          position={"right center"}
          target={
            <NavigationMenuItem
              key="allAccounts"
              label="Accounts"
              icon="building"
              path={linkedAccountPath}
              activePath={linkedAccountPath}
              onClick={topLevelItemClick}
              expanded={activeItems.includes(linkedAccountPath)}
            />
          }
        />
      );
    }

    return (
      <NavigationMenuItem
        label="Accounts"
        icon="building"
        path={RouteNames.accounts}
        onClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.accounts)}
        toggleNavigation={toggleNavigation}
        container
      >
        {showAccounts && (
          <NavigationMenuItem withMobo label="All" path={RouteNames.linkedAccounts} hideNavigation={hideNavigation} />
        )}

        {showTemplates && (
          <NavigationMenuItem withMobo label="Templates" path={RouteNames.templates} hideNavigation={hideNavigation} />
        )}
      </NavigationMenuItem>
    );
  };

  return (
    <Accordion fluid onMouseOver={showNavigation} className="global-navigation">
      {renderAccounts()}

      <NavigationMenuItem
        label="People"
        icon="fa-user"
        path={RouteNames.people}
        onClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.people)}
        toggleNavigation={toggleNavigation}
      >
        <NavigationMenuItem withMobo label="Users" path={"users"} hideNavigation={hideNavigation} />
        <NavigationMenuItem withMobo label="Roles" path={"roles"} hideNavigation={hideNavigation} />
        <NavigationMenuItem withMobo label="Groups" path={"groups"} hideNavigation={hideNavigation} />
      </NavigationMenuItem>
      <ReportingNavigationMenu
        topLevelItemClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.reporting)}
        toggleNavigation={toggleNavigation}
        hideNavigation={hideNavigation}
      />

      <Tooltip
        content={"Marketplace"}
        showAlways={collapsed}
        position={"right center"}
        target={
          <NavigationMenuItem
            withMobo
            label="Marketplace"
            icon="fa-shopping-cart"
            path={RouteNames.availablePacks}
            activePath={RouteNames.licensingPacks}
            onClick={topLevelItemClick}
            expanded={activeItems.includes(RouteNames.availablePacks)}
          />
        }
      />

      {useRestrictedCheck([RolePermissions.CampaignsManage])
        ? renderContents({ topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation })
        : renderRestrictedContents({ topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation })}

      <Tooltip
        content={"Help"}
        showAlways={collapsed}
        position={"right center"}
        target={
          <NavigationMenuItem
            blur
            label="Help"
            onClick={(e: ChangeEvent<HTMLButtonElement>) => e.currentTarget?.blur()}
            icon="fa-life-ring"
            className="help-menu-item"
            path={environmentConfig.helpCenterUrl}
            external
          />
        }
      />
    </Accordion>
  );
}

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  hasAnyLinkedAccount: state.accounts.mostAccessed.items.length > 0,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchMostAccessedAccounts: bindAction(fetchMostAccessedAccountsRedux, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(TopMenuAccordion);
export default ConnectedComponent;
