import React, { type FC, type SyntheticEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { type DropdownProps } from "semantic-ui-react";

import { ItemsTypes, RolePermissions, SortingDirection, SortOptions, ViewType } from "../../../enums";
import { ItemsView } from "../../../views";
import { GroupCard } from "../../cards";
import { GroupFilterForm } from "../../filterForms";
import SearchInput from "../../searchInput/SearchInput";
import GroupListUtils, { groupFilterParametersWithOwners } from "../../../utils/groupListUtils";
import { type Filters } from "../../../utils/queryUtils";
import GroupListRow from "./GroupListRow";
import { columnOptions } from "./getColumnOptions";
import { type ItemsViewGrid, type ItemsViewList } from "../../../features/Application/globaltypes/ItemsView";
import { type Group, type GroupActionHandlers } from "../../../interfaces";
import { type RootState } from "../../../features/Application/globaltypes/redux";
import {
  AddUserToGroupAllSuccess,
  GroupDeleted,
} from "../../../features/Application/services/realTimeNotification/events/people/peopleEvents";
import { type CardsViewerItem } from "../../cardsViewer/types";

import "./groupList.scss";

const defaultSortingColumnName = "Added";
const sortOptions = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Title A-Z",
    value: "name asc",
  },
];

export interface GroupListProps {
  viewType?: ViewType;
  groupsList: ItemsViewList<Group>;
  groupsGrid: ItemsViewGrid<Group>;
  accountId: number;
  selectedIds: number[];
  onSelectedGroupsChanged?: (groupIds: number[]) => void;
  fetchGroups: (skip: number, top: number, orderParams: string | null, filterParams: Filters) => void;
  fetchGroupsLazy: (top: number, orderBy: string, filter: Filters) => void;
  resetGridItems: () => void;
  noResultsContent: () => void;
  permanentFilter?: Filters;
  onViewTypeChange?: (viewType: ViewType) => void;
  appliedGroupsFilter: Filters;
  filterOptions: Filters;
  withEnrollmentFilter: boolean;
  buttonHandlers?: GroupActionHandlers;
  filterActions: {
    fetchFilterOptions: (accountId: number) => void;
  };
  setGroupsFilter: (filter: Filters) => void;
  resetGroupsFilter: () => void;
  disableViewHeader?: boolean;
  renderContentSwitcher?: () => React.ReactElement;
}

const GroupList: FC<GroupListProps> = (props: GroupListProps) => {
  const [viewType, setViewType] = useState<ViewType>(props.viewType || ViewType.LIST);
  const [cardViewOrderBy, setCardViewOrderBy] = useState<string>(SortOptions.CreatedDateDesc);

  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  useEffect(() => {
    if (viewType === ViewType.GRID) {
      props.resetGridItems();
      getGroupsForGrid(0, props.appliedGroupsFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, cardViewOrderBy]);

  const getFilterParams = (filter?: Filters) => {
    return GroupListUtils.formatFilterParams(
      { ...(filter || {}), ...(props.permanentFilter || {}) },
      groupFilterParametersWithOwners,
    );
  };

  const getGroupsForGrid = (loadedGroupsCount: number, filter?: Filters) => {
    const filterParams = getFilterParams(filter);

    props.fetchGroupsLazy(loadedGroupsCount, cardViewOrderBy, filterParams);
  };

  const onViewTypeChange = (view: ViewType) => {
    props.onSelectedGroupsChanged?.([]);
    props.onViewTypeChange?.(view);

    setViewType(view);
    setCardViewOrderBy(SortOptions.CreatedDateDesc);
  };

  const onCardViewSortChange = (_: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    props.resetGridItems();
    props.onSelectedGroupsChanged?.([]);

    setCardViewOrderBy(data.value as string);
    getGroupsForGrid(0, props.appliedGroupsFilter);
  };

  const getFilterOptions = () => {
    const { accountId, filterActions } = props;
    filterActions.fetchFilterOptions(accountId);
  };

  const isSelectDisabled = (group: Group) =>
    !group.canEdit &&
    !userPermissions.includes(RolePermissions.GroupsCreate) &&
    !userPermissions.includes(RolePermissions.UsersManage);

  const getGroups = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    if (viewType === ViewType.GRID) {
      getGroupsForGrid(props.groupsGrid.items.length, props.appliedGroupsFilter);
    } else {
      loadPage(skip, top, sortingColumnName, sortingDirection, props.appliedGroupsFilter);
    }
  };

  const loadPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: Filters,
  ) => {
    let orderParams = null;
    let filterParams = getFilterParams(filter);

    if (sortingColumnName) {
      orderParams = GroupListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    }

    props.fetchGroups(skip, top, orderParams, filterParams);
  };

  const applyFilter = (filter: Filters) => {
    const { setGroupsFilter, resetGridItems, onSelectedGroupsChanged } = props;

    onSelectedGroupsChanged?.([]);
    setGroupsFilter(filter);

    if (viewType === ViewType.GRID) {
      resetGridItems();
      getGroupsForGrid(0, filter);
    }
  };

  const resetFilter = () => {
    const { resetGroupsFilter, resetGridItems, onSelectedGroupsChanged } = props;

    onSelectedGroupsChanged?.([]);
    resetGroupsFilter();

    if (viewType === ViewType.GRID) {
      resetGridItems();
      getGroupsForGrid(0);
    }
  };

  const getListRow = (group: Group) => (
    <GroupListRow group={group} selected={props.selectedIds.includes(group.id)} {...props.buttonHandlers} />
  );

  const getFilterForm = () => <GroupFilterForm withEnrollmentFilter={props.withEnrollmentFilter} />;

  const getSearchInput = () => <SearchInput placeholder="Search for Groups..." disabled />;

  const groups = viewType === ViewType.LIST ? props.groupsList : props.groupsGrid;

  const renderCard = (params: CardsViewerItem<Group>) => {
    return <GroupCard {...params} {...props.buttonHandlers} url={params.item.id.toString()} />;
  };

  return (
    <ItemsView
      className="alignment-padding"
      viewType={viewType}
      renderCard={renderCard}
      onViewTypeChange={onViewTypeChange}
      columnOptions={columnOptions}
      getData={getGroups}
      itemsInlineCount={groups.itemsCount}
      isLoading={groups.isLoading}
      isAllDataLoaded={props.groupsGrid.isAllLoaded}
      items={groups.items}
      itemsType={ItemsTypes.Group}
      buildTableBody={getListRow}
      noResultsContent={props.noResultsContent()}
      sortingColumnName={defaultSortingColumnName}
      sortingDirection={SortingDirection.Descending}
      selectedIds={props.selectedIds}
      onSelectedListItemsChanged={props.onSelectedGroupsChanged}
      onSelectedItemsChanged={props.onSelectedGroupsChanged}
      renderFilterForm={getFilterForm}
      filterOptions={props.filterOptions}
      filterOptionsLoading={props.filterOptions.isLoading}
      getFilterOptions={getFilterOptions}
      applyFilter={applyFilter}
      resetFilter={resetFilter}
      appliedFilter={props.appliedGroupsFilter}
      sortOptions={sortOptions}
      blur
      onSortChange={onCardViewSortChange}
      listViewRtnEvents={[GroupDeleted, AddUserToGroupAllSuccess]}
      renderSearch={getSearchInput}
      onLoad={viewType === ViewType.GRID ? getGroups : null}
      permissions={[RolePermissions.GroupsManage]}
      isSelectDisabled={isSelectDisabled}
      disableViewHeader={props.disableViewHeader}
      customHeaderContent={props.renderContentSwitcher?.()}
    />
  );
};

export default GroupList;
