import React from "react";
import PropTypes from "prop-types";

export default function ImageWrapper(props) {
  const {
    imageInfo: { className, alt, src, onError },
  } = props;

  return (
    <img
      className={className}
      alt={alt}
      src={src}
      onError={(e) => {
        e.target.onerror = null;
        e.target.style.display = "none";
        onError && onError();
      }}
    />
  );
}

ImageWrapper.propTypes = {
  imageInfo: PropTypes.shape({
    src: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
    onError: PropTypes.func,
  }).isRequired,
};
