import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type Dispatch } from "@reduxjs/toolkit";
import { Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import Segments from "../../../../../components/navigation/segments/Segments";
import FormFooter from "../../../../../components/forms/FormFooter";
import ActivityLogs from "../ActivityLogs/ActivityLogs";

import { type RootState } from "../../../../Application/globaltypes/redux";
import { GenericSectionHeader, PreventTransitionPrompt } from "../../../../../components";
import { IntegrationTypes } from "../../types";
import { IntegrationDtoMap, overviewMoboPath } from "../../Shared";
import { RouteNames } from "../../../../../enums";
import {
  fetchIntegrationConfigsAction,
  updateIntegrationConfigsAction,
} from "../../state/thunks/integrationDetailsThunk";
import { bindAction } from "../../../../../interfaces";
import { reset, fetchSuccess } from "../../state/slices/integrationDetailsSlice";
import { integrationDetailsSelector } from "../../state/selectors";
import { useRtn } from "../../../../../hooks/useRtn";
import * as accountsEventTypes from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { type SfIntegrationConfigs } from "../../types/successFactors";
import SfIntegrationConfiguration from "../../Configuration/SfIntegrationConfiguration";
import { useEventCallback } from "../../../../../hooks/useEventCallback";
import { isEmpty, isEqualWith, noop } from "lodash";
import nameof from "utils/nameof";
import navigationUtils from "../../../../../utils/navigationUtils";

export interface EditSfProps extends PropsFromRedux {}

enum SegmentPaths {
  configuration = "configuration",
  activityLog = "activity-log",
}

const rtnEvents = [accountsEventTypes.UpdateSfSuccess, accountsEventTypes.UpdateSfFailure];

export const EditSf = (props: EditSfProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchConfigs, resetConfigs, originConfigs, isLoading, updateData, dispatch, asyncOperations } = props;
  const [configs, setConfigs] = useState<SfIntegrationConfigs>(originConfigs);
  const { url, title } = IntegrationDtoMap[IntegrationTypes.SuccessFactors];
  const dirty = useMemo(() => !isEqualConfigs(configs, originConfigs), [originConfigs, configs]);
  const [isValid, setIsValid] = useState(true);
  const resetForm = useRef(noop);
  const { moboId } = useParams();
  const ownAccount = Number.isNaN(moboId) || moboId === undefined;

  const overviewPath = url(ownAccount, moboId);

  const onSave = () => {
    props.updateConfigs(IntegrationTypes.SuccessFactors, configs, moboId);
  };

  const onCancel = () => {
    setConfigs(originConfigs);
    resetForm.current();
  };

  const bindReset = useCallback(
    (reset: () => void) => {
      resetForm.current = reset;
    },
    [resetForm],
  );

  const handleOperationFinished = useEventCallback(() => {
    updateData(configs);
  }, [updateData, configs]);

  useEffect(() => {
    setConfigs(originConfigs);
  }, [originConfigs]);

  useEffect(() => {
    fetchConfigs(moboId);

    return () => {
      resetConfigs();
    };
  }, [resetConfigs, fetchConfigs, moboId]);

  useRtn(rtnEvents, handleOperationFinished, moboId, dispatch, asyncOperations);

  return (
    <section className="nested-content">
      <GenericSectionHeader
        title={title}
        goBackAction={() =>
          navigationUtils.goBackOrDefault(
            location,
            navigate,
            moboId ? overviewMoboPath(moboId) : RouteNames.integrationsOverviewUrl,
          )
        }
      />
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <Segments to={overviewPath}>
        <Segments.Segment label="Configuration" />
        <Segments.Segment label="Activity Log" to={SegmentPaths.activityLog} />
      </Segments>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {!isEmpty(originConfigs) && (
                <>
                  <SfIntegrationConfiguration
                    integrationName={title}
                    onUpdate={setConfigs}
                    initialConfig={originConfigs}
                    enableReinitialize
                    onIsValidChange={setIsValid}
                    bindResetForm={bindReset}
                  />
                  <FormFooter
                    isSaveBtnDisabled={!dirty || !isValid}
                    isCancelBtnDisabled={!dirty}
                    onSave={onSave}
                    onCancel={onCancel}
                    saveButtonTitle="Save Changes"
                  />
                </>
              )}
              <PreventTransitionPrompt
                when={dirty}
                title="Exit Without Saving?"
                message="Are you sure you want to exit without saving your changes? All information entered will be lost."
              />
            </>
          }
        />
        <Route path="activity-log" element={<ActivityLogs type={IntegrationTypes.SuccessFactors} />} />
      </Routes>
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const details = integrationDetailsSelector<SfIntegrationConfigs>(state);
  return {
    asyncOperations: state.asyncOperations,
    originConfigs: details.data,
    isLoading: details.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    fetchConfigs: bindAction((id) => fetchIntegrationConfigsAction(IntegrationTypes.SuccessFactors, id), dispatch),
    updateData: bindAction(fetchSuccess, dispatch),
    updateConfigs: bindAction(updateIntegrationConfigsAction, dispatch),
    resetConfigs: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditSf);
export default ConnectedComponent;

const isEqualConfigs = (value: SfIntegrationConfigs, another: SfIntegrationConfigs) =>
  isEqualWith(value, another, (_0, _1, key) => {
    if (key === nameof<SfIntegrationConfigs>("completionHistorySettings") && !value.isHistoryEnabled) {
      return true;
    }
    return undefined;
  });
