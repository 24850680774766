import { type ColumnOptions } from "interfaces";

export const columnOptions: ColumnOptions[] = [
  {
    name: "name",
    width: 7,
    isSortable: true,
    className: "group-name-header",
  },
  {
    name: "members",
    width: 2,
    isSortable: false,
  },
  {
    name: "Added",
    width: 4,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
  },
];
