import cn from "classnames";

import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";

import "./title.scss";
import { MoboLink } from "components/MoboLink/MoboLink";

type TitleProps = {
  title?: string;
  className?: string;
  clamp?: number;
  moboAccountId?: number;
  target?: string;
} & ({ onTitleClick?: () => void; url?: never } | { url?: string; onTitleClick?: never });

const Title = ({ title, onTitleClick, className, url, target, moboAccountId, clamp = 1 }: TitleProps) => {
  return (
    <>
      {url ? (
        <MoboLink to={url} accountId={moboAccountId} target={target}>
          <Tooltip
            target={
              <TextTruncate
                lines={clamp}
                className={cn("row-title", className, {
                  clickable: onTitleClick || url,
                })}
              >
                {title}
              </TextTruncate>
            }
            content={title}
            className="list-view-row-title"
          />
        </MoboLink>
      ) : (
        <Tooltip
          target={
            <TextTruncate
              lines={clamp}
              className={cn("row-title", className, {
                clickable: onTitleClick,
              })}
              onClick={onTitleClick}
            >
              {title}
            </TextTruncate>
          }
          content={title}
          className="list-view-row-title"
        />
      )}
    </>
  );
};

export default Title;
