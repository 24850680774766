import { type AppDispatch } from "features/Application/globaltypes/redux";
import { beginAsyncOperation } from "features/Application/slices/asyncOperationSlice";
import { updateLockedFlowCommandBegin } from "../actions/flowEntityStateActionCreators";
import { type UpdateGoalPayload } from "../../types/requests";
import { FlowEditGoalsSuccess } from "features/Application/services/realTimeNotification/events/library/libraryEvents";
import { gotGoal, gotOptions, initialState, reqGoal, reqOptions, setIsLoaded } from "../slices/flowGoalSlice";
import DataService from "features/Application/services/dataServices/typedDataService";

export const fetchGoalOptions = () => async (dispatch: AppDispatch) => {
  dispatch(reqOptions());
  try {
    const options = await DataService.flows.getGoalOptions();
    dispatch(gotOptions(options.data));
    dispatch(setIsLoaded(true));
  } catch {}
};

export const fetchGoalData = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(reqOptions());
  dispatch(reqGoal());
  try {
    const [optionsRes, valuesRes] = await Promise.all([
      DataService.flows.getGoalOptions(),
      DataService.flows.getGoals(id),
    ]);
    dispatch(gotOptions(optionsRes.data));
    const values = valuesRes.data;
    // populating values with null and initial metricId
    dispatch(gotGoal({ ...initialState.values, ...values, metricId: values.metricId || initialState.values.metricId }));
  } catch {}
};

export const updateGoal = (payload: UpdateGoalPayload) => async (dispatch: AppDispatch) => {
  dispatch(updateLockedFlowCommandBegin());
  dispatch(beginAsyncOperation({ id: payload.id, action: FlowEditGoalsSuccess }));
  try {
    await DataService.flows.updateGoals(payload);
  } catch {}
};
