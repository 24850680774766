import React from "react";
import "./verificationStatus.scss";
import VerificationStatusTypes from "../../../../enums/verificationStatusTypes";
import Strings from "../../../../enums/strings";
import { ReactComponent as CloseIcon } from "../close-circle.svg";
import { ReactComponent as CheckIcon } from "../check-circle-28.svg";
import { ReactComponent as FailIcon } from "../exclamation-circle-28.svg";

export interface VerificationStatusProps {
  verificationStatus: VerificationStatusTypes;
  verificationError?: string;
}

const VerificationStatus: React.FC<VerificationStatusProps> = (props: VerificationStatusProps) => {
  const renderStatusInfo = (): any => {
    const { verificationStatus, verificationError } = props;
    let statusIcon = <CloseIcon />;
    let statusMessage = Strings.library.threatDefence.verification.unverified;
    switch (verificationStatus) {
      case VerificationStatusTypes.Verified:
        statusIcon = <CheckIcon />;
        statusMessage = Strings.library.threatDefence.verification.verified;
        break;
      case VerificationStatusTypes.Failed:
        statusIcon = <FailIcon />;
        statusMessage = verificationError ?? Strings.library.threatDefence.verification.failed;
        break;
      default:
        break;
    }

    return (
      <div className="verification-status">
        {statusIcon} <span className="verification-status-name">{verificationStatus}</span>
        <div className="verification-status-message">{statusMessage}</div>
      </div>
    );
  };

  return renderStatusInfo();
};

export default VerificationStatus;
