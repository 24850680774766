import { Icon, TransitionablePortal } from "semantic-ui-react";
import classNames from "classnames/bind";
import styles from "./AiChatBot.module.scss";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ChatWindow } from "../ChatWindow/ChatWindow";
import { useRef, useState } from "react";

const cx = classNames.bind(styles);

export const AiChatBot = () => {
  const isEnabled = useFeatureFlag(FeatureFlags.AiFeatures);
  const [open, setOpen] = useState(false);
  const [answered, setAnswered] = useState(false);
  const transitionRef = useRef<HTMLDivElement>(null);

  if (!isEnabled) return null;

  return (
    <TransitionablePortal
      closeOnTriggerClick
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnTriggerClick
      trigger={
        <button className={styles.bot} tabIndex={-1} title="Open Chat">
          <Icon className={styles.icon} name="chat" fitted size="huge" />
        </button>
      }
      transition={{ animation: "scale", duration: 300, unmountOnHide: true }}
    >
      <div ref={transitionRef} className={cx(styles.popover, { [styles.answered]: answered })}>
        <ChatWindow open={open} setAnswered={setAnswered} transitionRef={transitionRef} />
      </div>
    </TransitionablePortal>
  );
};
