import cn from "classnames";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./basicLinkButton.scss";

interface BasicLinkButtonProps {
  text: string;
  onClick?: () => void;
  isDisabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconAfterLabelClassName?: string;
  icon?: Parameters<typeof FontAwesomeIcon>[0]["icon"];
}

function BasicLinkButton(props: BasicLinkButtonProps) {
  const { text, onClick, iconClassName, isDisabled, className, iconAfterLabelClassName, icon } = props;

  return (
    <Button className={cn("basic-link-button", className)} onClick={onClick} disabled={isDisabled}>
      {icon && (
        <div className="icon-container">
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
      )}
      {iconClassName && <Icon size="large" className={iconClassName} />}
      <span className="label">{text}</span>
      {iconAfterLabelClassName && <Icon className={iconAfterLabelClassName} />}
    </Button>
  );
}

export default BasicLinkButton;
