import React from "react";
import { type IAutosave } from "../interfaces/IAutosave";
import Autosave from "./Autosave";

export interface AutosaveEntityProps {
  isLoaded: boolean;
  isCreating: boolean;
  isDraft: boolean;
}

export interface InputAutosaveProps<TValues> extends AutosaveEntityProps {
  id: number;
  entityId?: number;
  values: TValues;
  isValid: boolean;
}

export interface AutosaveProps {
  onBlur: (propertyName: string) => Promise<void>;
  save: () => Promise<void>;
}

export interface AutosaveAllProps<TValues> extends InputAutosaveProps<TValues>, AutosaveProps {}

export interface WithAutosaveConfig<
  TProps extends InputAutosaveProps<TValues>,
  TValues,
  TState,
  TStateProjection = TState,
> {
  stateProvider: (values: TValues, props: TProps) => TState;
  stateProviderWithCallback?: (values: TValues, props: TProps, callback: (state: TState) => void) => void;
  entityUpdater: (props: TProps) => (input: TStateProjection) => void;
  stateProjector?: (values: TState, props: TProps) => TStateProjection;
  isValid?: (props: TProps) => boolean;
  isDeffered?: (props: TProps) => boolean;
  isPeriodicUpdateEnabled?: boolean;
  isStateLoaded?: (prevProps: TProps, props: TProps) => boolean;
  getInitValues?: (props: TProps) => TState;
}

const getDefaults = <TProps extends InputAutosaveProps<TValues>, TValues, TState, TStateProjection = TState>() => ({
  validator: (props: TProps) => props.isDraft && props.isValid && props.id > 0,
  stateProjector: (state: TState, props: TProps) => ({ ...state, id: props.id } as unknown as TStateProjection),
  isDeffered: (props: TProps) => props.isCreating || (props.entityId !== undefined && !props.isLoaded),
  isPeriodicUpdateEnabled: true,
  isStateLoaded: (prev: TProps, props: TProps) => {
    const { isLoaded, isCreating } = props;
    return (isLoaded && prev.isLoaded !== isLoaded) || (!isCreating && prev.isCreating !== isCreating);
  },
});

const getHandlers = <TProps extends InputAutosaveProps<TValues>, TValues, TState, TStateProjection = TState>(
  config: WithAutosaveConfig<TProps, TValues, TState, TStateProjection>,
) => {
  const defaultHandlers = getDefaults<TProps, TValues, TState, TStateProjection>();
  const stateProvider = (props: TProps) => config.stateProvider(props.values, props);
  const stateProviderWithCallback = config?.stateProviderWithCallback
    ? (props: TProps, callback: (state: TState) => void) =>
        config?.stateProviderWithCallback?.(props.values, props, callback)
    : undefined;

  return {
    stateProvider,
    stateProviderWithCallback,
    isValid: config.isValid ?? defaultHandlers.validator,
    stateProjector: config.stateProjector ?? defaultHandlers.stateProjector,
    isDeffered: config.isDeffered ?? defaultHandlers.isDeffered,
    isStateLoaded: config.isStateLoaded ?? defaultHandlers.isStateLoaded,
    getInitValues: config.getInitValues ?? stateProvider,
    isPeriodicUpdateEnabled: config.isPeriodicUpdateEnabled ?? defaultHandlers.isPeriodicUpdateEnabled,
  };
};

type AutosaveDecorator<TProps extends AutosaveAllProps<TValues>, TValues> = (
  component: React.ComponentType<TProps>,
) => React.ComponentClass<Omit<TProps, keyof AutosaveProps>>;

export const withAutosave =
  <TProps extends AutosaveAllProps<TValues>, TValues, TState = TValues, TStateProjection = TState>(
    config: WithAutosaveConfig<Omit<TProps, keyof AutosaveProps>, TValues, TState, TStateProjection>,
  ): AutosaveDecorator<TProps, TValues> =>
  (Component: React.ComponentType<TProps>) =>
    class WithAutosave extends React.Component<Omit<TProps, keyof AutosaveProps>> {
      private autosave: IAutosave<TState, TStateProjection>;

      handlers = getHandlers(config);

      constructor(props: Omit<TProps, keyof AutosaveProps>) {
        super(props);

        this.autosave = new Autosave<TState, TStateProjection>({
          stateProvider: () => this.handlers.stateProvider(this.props),
          stateProviderWithCallback: this.handlers.stateProviderWithCallback
            ? (callback: (state: TState) => void) => this.handlers.stateProviderWithCallback?.(this.props, callback)
            : undefined,
          isValid: () => this.handlers.isValid(this.props),
          stateProjector: (state) => this.handlers.stateProjector(state, this.props),
          updateHandler: config.entityUpdater(this.props),
          isDeferredInitialization: this.handlers.isDeffered(this.props),
          isPeriodicUpdateEnabled: this.handlers.isPeriodicUpdateEnabled,
        });
      }

      componentWillUnmount() {
        this.autosave.dispose();
      }

      componentDidUpdate(prev: Omit<TProps, keyof AutosaveProps>) {
        if (this.handlers.isStateLoaded(prev, this.props)) {
          this.autosave.ensureInitialization(this.handlers.getInitValues(this.props));
        }
      }

      

      render() {
        return <Component {...(this.props as TProps)} onBlur={this.autosave.onBlur} save={this.autosave.save} />;
      }
    };
