import { Button } from "components/buttons/button/Button";
import { Component } from "react";
import { withRouter, type WithRouterProps } from "../../../adapters/withRouter/withRouter";
import DeleteAccountConfirmationModal from "../../../components/account/deleteAccountConfirmationModal/DeleteAccountConfirmationModal";
import RestrictedToAnyAccount from "../../../components/restricted/RestrictedToAnyAccount";
import { OverviewHeader } from "../../../components/sectionHeader";
import { RolePermissions, type SortingDirection } from "../../../enums";
import { type Account } from "../../../interfaces/Account";
import AccountList from "../AccountList";
import moboConfigurator from "../../../moboConfigurator";

export class AccountsOverview extends Component<AccountsOverviewProps> {
  private static createPermissions = [RolePermissions.AccountsSettingsCreate, RolePermissions.TemplatesManage];

  state = {
    selectedAccountsAmount: 0,
    showDeleteAccountConfirmation: false,
    focusedAccountId: 0,
    focusedAccountName: "",
  };

  goToAccountCreation = () => {
    this.props.navigate("create");
  };

  openMobo = (accountId: number) => {
    moboConfigurator.openMobo(accountId);
  };

  updateSelectedAccountsAmount = (selectedIds: number[]) => {
    this.setState({ selectedAccountsAmount: selectedIds.length });
  };

  onDeleteAccountConfirmed = () => {
    const { focusedAccountId, focusedAccountName } = this.state;
    const { onDeleteAccount } = this.props;
    onDeleteAccount(focusedAccountId, focusedAccountName);
    this.closeDeleteAccountModal();
  };

  closeDeleteAccountModal = () =>
    this.setState({
      showDeleteAccountConfirmation: false,
      focusedAccountId: 0,
      focusedAccountName: "",
    });

  onDeleteAccountClick = (id: number, name: string) => {
    this.setState({
      showDeleteAccountConfirmation: true,
      focusedAccountId: id,
      focusedAccountName: name,
    });
  };

  renderDeleteAccountConfirmationModal = () => (
    <DeleteAccountConfirmationModal
      open={this.state.showDeleteAccountConfirmation}
      onContinue={this.onDeleteAccountConfirmed}
      onCancel={() => {
        this.setState({ showDeleteAccountConfirmation: false });
      }}
    />
  );

  renderCreateAccountButton = () => {
    return (
      <RestrictedToAnyAccount
        permissions={AccountsOverview.createPermissions}
        renderContent={(hasAnyPermissions) => (
          <Button
            disabled={!hasAnyPermissions}
            floated="right"
            primary
            content="Create Account"
            onClick={this.goToAccountCreation}
            className="create-button accounts"
          />
        )}
      />
    );
  };

  render() {
    const { selectedAccountsAmount } = this.state;
    const { onFetchAccounts, accounts, accountsTotalCount, isLoading, isMobo } = this.props;

    return (
      <section className="nested-content accounts">
        <OverviewHeader title="Accounts" itemName="Account" selectedItemsAmount={selectedAccountsAmount}>
          {!isMobo && this.renderCreateAccountButton()}
        </OverviewHeader>
        <AccountList
          accounts={accounts}
          accountsTotalCount={accountsTotalCount}
          isLoading={isLoading}
          onFetchAccounts={onFetchAccounts}
          onOpenMobo={this.openMobo}
          onDeleteAccount={this.onDeleteAccountClick}
          renderCreateAccountButton={this.renderCreateAccountButton}
          onSelectedAccountsChanged={this.updateSelectedAccountsAmount}
          selectedAccountsAmount={selectedAccountsAmount}
          isMobo={isMobo}
        />
        {this.renderDeleteAccountConfirmationModal()}
      </section>
    );
  }
}

const ConnectedComponent = withRouter(AccountsOverview);

export default ConnectedComponent;

export interface AccountsOverviewProps extends WithRouterProps {
  accounts: Account[];
  accountsTotalCount: number;
  isLoading: boolean;
  onDeleteAccount: (accountId: number, accontName: string) => void;
  onFetchAccounts: (
    skip: number,
    top: number,
    sortingPropName: string,
    sortingDirection: SortingDirection,
    searchText?: string,
  ) => void;
  isMobo: boolean;
}
