import React from "react";
import PropTypes from "prop-types";

import { ValidatedForm } from "../../forms";
import validationSchemas from "../../../utils/validationSchemas";
import ImageCropper from "./imageCropper/ImageCropper";
import fileUtils from "../../../utils/fileUtils";
import ImageAspectRatio from "../../../enums/imageAspectRatio";

function UploadImage(props) {
  const {
    propertyName,
    onCroppedImageChange,
    file,
    onFileChanged,
    fileName,
    onFileNameChanged,
    aspectRatio,
    getImageCustomValidator,
  } = props;

  const handleCroppedImageChange = (image) => {
    onCroppedImageChange && onCroppedImageChange(image);
  };

  const handleFileChange = (files) => {
    if (needToUpdateFile(files)) {
      // to-do remove here usage of callback. Use useEffect and update Formik related logic to pass arguments
      onFileNameChanged(files);
    }

    let validator = getImageCustomValidator
      ? getImageCustomValidator().validator
      : validationSchemas.image.fields.uploadedImages;
    return validator
      .validate(files)
      .then(() => {
        fileUtils.readFileAsDataUrl(files[0]).then((result) => {
          onFileChanged(result);
        });
      })
      .catch(() => {
        onFileChanged(null);
        handleCroppedImageChange(null);
      });
  };

  const needToUpdateFile = (uploadedFiles) => {
    if (!uploadedFiles || !uploadedFiles.length) {
      return false;
    }
    if (!fileName.length) {
      return true;
    }
    return fileUtils.isDifferentFile(uploadedFiles[0], fileName[0]);
  };

  return (
    <>
      <ValidatedForm initialValues={{ uploadedImages: {} }} validationSchema={validationSchemas.image}>
        {(formProps) => {
          const { validatedFieldProps } = formProps;
          return (
            <ValidatedForm.UploadFileField
              id="imageUpload"
              label="File"
              accept="image/*"
              propertyName={getImageCustomValidator ? getImageCustomValidator().propName : propertyName}
              value={fileName}
              onChangeHandler={handleFileChange}
              filesValidator={
                getImageCustomValidator
                  ? getImageCustomValidator().validator
                  : validationSchemas.image.fields.uploadedImages
              }
              {...validatedFieldProps}
            />
          );
        }}
      </ValidatedForm>
      {file && <ImageCropper file={file} onCroppedImageChange={handleCroppedImageChange} aspectRatio={aspectRatio} />}
    </>
  );
}

UploadImage.defaultProps = {
  propertyName: "uploadedImages",
  aspectRatio: ImageAspectRatio.HD_16X9,
  getImageCustomValidator: null,
};

UploadImage.propTypes = {
  propertyName: PropTypes.string,
  onCroppedImageChange: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onFileChanged: PropTypes.func,
  fileName: PropTypes.object,
  onFileNameChanged: PropTypes.func,
  aspectRatio: PropTypes.oneOf([ImageAspectRatio.HD_16X9, ImageAspectRatio.SQUARE_1X1]),
  getImageCustomValidator: PropTypes.func,
};

export default UploadImage;
