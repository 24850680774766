import * as actionTypes from "../actionTypes/surveyOverviewActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { type SurveyOverviewState } from "../../types/state";
import { type PayloadAction, type FetchActionPayload } from "../../../../../interfaces/redux";
import { type SurveyOverview } from "../../types/models";

const initialState: SurveyOverviewState = {
  surveys: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
  selectedItems: [],
};

const surveysOverviewHandlers = () => {
  const { getSurveysBegin, getSurveysSuccess, getSurveysFailure, selectedItemsChanged, reset } = actionTypes;

  const getSurveysBeginHandler = (state: SurveyOverviewState): SurveyOverviewState => ({
    ...state,
    surveys: new Array<SurveyOverview>(),
    isLoading: true,
  });

  const getSurveysSuccessHandler = (
    state: SurveyOverviewState,
    action: PayloadAction<FetchActionPayload<SurveyOverview>>,
  ): SurveyOverviewState => ({
    ...state,
    surveys: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getSurveysFailureHandler = (state: SurveyOverviewState, action: PayloadAction<Error>): SurveyOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const selectedItemsChangedHandler = (
    state: SurveyOverviewState,
    action: PayloadAction<number[]>,
  ): SurveyOverviewState => ({
    ...state,
    selectedItems: [...action.payload],
  });

  const handlerReset = () => ({
    ...initialState,
  });

  return {
    [getSurveysBegin]: getSurveysBeginHandler,
    [getSurveysSuccess]: getSurveysSuccessHandler,
    [getSurveysFailure]: getSurveysFailureHandler,
    [selectedItemsChanged]: selectedItemsChangedHandler,
    [reset]: handlerReset,
  };
};

export const surveysOverviewReducer = createReducer(initialState, [surveysOverviewHandlers]);
