import React, { Component, createRef } from "react";
import { Icon, Segment, Sidebar, Label } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import FilterPanel from "../filterPanel/FilterPanel";
import { calculateAppliedFilters } from "../../utils/filterUtils";

import cn from "classnames";

import "./filterSidebar.scss";

class FilterSidebar extends Component {
  constructor(props) {
    super(props);

    this.filterPanel = createRef();
  }

  onVisible = () => {
    this.filterPanel.current.getFilterOptions();
  };

  render() {
    const {
      children,
      hideFilter,
      appliedFilter,
      defaultFilter,
      isLoading,
      filterOptions,
      visible,
      applyFilter,
      resetFilter,
      getFilterOptions,
      className,
      isCompact,
      shouldDisableApply,
    } = this.props;

    return (
      <Sidebar
        className={cn("filter-sidebar", className, { isCompact })}
        as={Segment}
        direction="right"
        animation="overlay"
        icon="labeled"
        inverted
        onHide={hideFilter}
        vertical
        visible={visible}
        onVisible={this.onVisible}
      >
        <div className="filter-title-container">
          <span className="filter-title">
            Filter by
            {isCompact && !isEmpty(appliedFilter) && <Label circular>{calculateAppliedFilters(appliedFilter)}</Label>}
          </span>
          <Icon name="close" className="close-icon" onClick={hideFilter} />
        </div>
        <FilterPanel
          ref={this.filterPanel}
          isLoading={isLoading}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          appliedFilter={appliedFilter}
          defaultFilter={defaultFilter}
          filterOptions={filterOptions}
          isCompact={isCompact}
          getFilterOptions={getFilterOptions}
          className={"content"}
          shouldDisableApply={shouldDisableApply}
        >
          {children}
        </FilterPanel>
      </Sidebar>
    );
  }
}

FilterSidebar.defaultProps = {
  appliedFilter: {},
  isCompact: false,
};

FilterSidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  appliedFilter: PropTypes.object, // used to help control reset logic when an initial value should be selected
  getFilterOptions: PropTypes.func,
  className: PropTypes.string,
  isCompact: PropTypes.bool,
  shouldDisableApply: PropTypes.bool, // if component managing filter has opinion on filter being applied (should as with dates)
  defaultFilter: PropTypes.object,
};

export default FilterSidebar;
