import React from "react";

import styles from "./Input.module.scss";

export const Input = (props: React.PropsWithChildren<{ style?: React.CSSProperties }>) => {
  return (
    <div className={styles.root} style={props.style}>
      {props.children}
    </div>
  );
};
