import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import { Checkbox, Popup } from "semantic-ui-react";
import LabelWithInfoTooltip from "../../../../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";

import "./groupSettingsToggleButton.scss";

const style = {
  fontSize: "14px",
  lineHeight: 1.5,
  whiteSpace: "normal",
};

function GroupSettingsToggleButton(props) {
  const { checked, disabled, onChange, label, hint, labelTooltip, disabledTooltip } = props;

  const checkbox = <Checkbox label={hint} checked={checked} disabled={disabled} onChange={onChange} toggle />;

  return (
    <div className="group-setting">
      <LabelWithInfoTooltip label={label} info={labelTooltip} width={17} />
      <div>
        <Popup
          content={disabledTooltip}
          trigger={checkbox}
          hideOnScroll
          on="click"
          position="top center"
          style={style}
          disabled={isEmpty(disabledTooltip)}
          inverted
        />
      </div>
    </div>
  );
}

GroupSettingsToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.string,
  disabledTooltip: PropTypes.string,
  hint: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default GroupSettingsToggleButton;
