import { Tooltip } from "../../../../../../../components/common/tooltip";
import { AddButton } from "../../../../../Common/base/AddButton";
import { MAX_ANSWERS_LENGTH } from "../../../../../Common/constants";
import { answersLimitReached } from "../../utils";
import { type ISurveyQuestion } from "../types";

import "./addSurveyAnswerButton.scss";

export interface AddSurveyAnswerButtonProps {
  question: ISurveyQuestion<any>;
  disabled?: boolean;
  onAddAnswer: () => void;
}

export const AddSurveyAnswerButton = (props: AddSurveyAnswerButtonProps) => {
  const { disabled, question, onAddAnswer } = props;

  const inactive = answersLimitReached(question);
  const tooltipMessage = inactive
    ? "A branching question cannot have more than 5 answers. Turn off the “Branching Question” toggle at the top to add more answers."
    : "";

  return !disabled && question.answers.length < MAX_ANSWERS_LENGTH ? (
    <div className="add-survey-answer-button">
      <Tooltip
        target={<AddButton disabled={inactive} onClick={onAddAnswer} />}
        content={tooltipMessage}
        position="top center"
        styles={{ width: `${20}rem` }}
        hideOnScroll
        showAlways={!!tooltipMessage}
      />
    </div>
  ) : null;
};
