import { Component } from "react";

import PriorityLevelItem from "../priorityLevelItem/PriorityLevelWithThumbnail";
import { type PriorityLevel } from "../../../interfaces";
import { type PeopleType } from "features/Library/PeopleAssignments/types";

import "./priorityLevelItemsBlock.scss";

export interface PriorityLevelItemsBlockProps {
  peopleType: PeopleType;
  items: PriorityLevel[];
  onItemsUpdate: Function;
  onIsDataValidChange: Function;
}

interface PriorityLevelItemsBlockState {
  invalidItemIds: number[];
  isDataValid: boolean;
}

export class PriorityLevelItemsBlock extends Component<PriorityLevelItemsBlockProps, PriorityLevelItemsBlockState> {
  constructor(props: PriorityLevelItemsBlockProps) {
    super(props);

    this.state = {
      invalidItemIds: [],
      isDataValid: true,
    };
  }

  onFieldChange = (id: number) => (propertyName: string, value: any) => {
    const newItems = this.props.items.map((item) =>
      item.id === id ? { ...item, [propertyName]: value } : { ...item },
    );
    this.props.onItemsUpdate(newItems);
  };

  onValidChange = (id: number) => {
    const { onIsDataValidChange } = this.props;
    const { invalidItemIds, isDataValid } = this.state;

    return (isValid: boolean) => {
      let newInvalidItemIds;
      let newIsDataValid;

      if (isValid) {
        newInvalidItemIds = invalidItemIds.filter((itemId) => itemId !== id);
      } else {
        newInvalidItemIds = [...invalidItemIds];
        newInvalidItemIds.push(id);
      }

      newIsDataValid = newInvalidItemIds.length === 0;

      if (isDataValid !== newIsDataValid) {
        onIsDataValidChange(newIsDataValid);
      }

      this.setState({
        invalidItemIds: newInvalidItemIds,
        isDataValid: newIsDataValid,
      });
    };
  };

  renderItems = () => {
    const { items, peopleType } = this.props;

    return items.map((item) => (
      <PriorityLevelItem
        peopleType={peopleType}
        key={item.id}
        item={item}
        onChange={this.onFieldChange(item.id)}
        onValidChange={this.onValidChange(item.id)}
      />
    ));
  };

  render() {
    return (
      <div id="priority-level-items-block" className="priority-level-items-block">
        {this.renderItems()}
      </div>
    );
  }
}

export default PriorityLevelItemsBlock;
