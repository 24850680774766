import React, { type FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { type FilterFormPropsBase } from "../types/props";
import { getVideosFilters } from "./videosFilters";
import { type VideoFiltersEnum } from "../../../features/Library/Videos/types/models";

export interface VideosFilterFormProps extends FilterFormPropsBase<VideoFiltersEnum> {
  disableDateFilters: boolean;
}

const VideosFilterForm: FC<VideosFilterFormProps> = ({ filterOptions, filter, updateFilter, disableDateFilters }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getVideosFilters(true, disableDateFilters)}
  />
);

export default VideosFilterForm;
