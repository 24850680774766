import * as editUserGroupsActionTypes from "./editUserGroupsActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import usersDataService from "../../../../Application/services/dataServices/usersDataService";

const takeCount = process.env.REACT_APP_LOAD_ITEMS_COUNT ? parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT) : 30;

interface PagingOptions {
  skip: number;
  top: number;
  orderByParams: string;
  filter: string;
  owners: number[];
}

export const fetchUserGroups = (
  userId: number,
  skip: number,
  top: number,
  orderByParams: string,
  filterParams: { filter: string; owners: number[] },
) => {
  const { filter, owners } = filterParams || {};

  return fetchGroups(
    userId,
    { skip, top, orderByParams, filter, owners },
    editUserGroupsActionTypes.fetchUserEditGroupsItemsActions,
    editUserGroupsActionTypes.fetchUserEditGroupOwnersItemsActions,
  );
};

export const fetchUserGroupsLazy = (
  userId: number,
  skip: number,
  orderByParams: string,
  filterParams: { filter: string; owners: number[] },
) => {
  const { filter, owners } = filterParams || {};

  return fetchGroups(
    userId,
    { skip, top: takeCount, orderByParams, filter, owners },
    editUserGroupsActionTypes.fetchUserEditGroupsItemsGridActions,
    editUserGroupsActionTypes.fetchUserEditGroupOwnersItemsActions,
  );
};

export const resetGridItems = makeActionCreator(editUserGroupsActionTypes.fetchUserGroupsGridAction.RESET_GRID_ITEMS);

const fetchGroups =
  (userId: number, pagingOptions: PagingOptions, groupActions: any, _: any) => async (dispatch: any) => {
    try {
      dispatch(groupActions.begin());

      const result = await usersDataService.getUserGroups(userId, pagingOptions);
      dispatch(groupActions.success(result.items, result.itemsCount));
    } catch (err) {
      dispatch(groupActions.failure(err));
    }
  };
