import { type Dispatch } from "@reduxjs/toolkit";
import { type SortingDirection } from "enums";
import usersDataService from "features/Application/services/dataServices/usersDataService";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/editAccountUsersSlice";

/* istanbul ignore next */
export const fetchAccountUsers =
  (accountId: number, top: number, skip: number, sortBy: string, sortOrder: SortingDirection) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const { items, totalCount } = await usersDataService.getAccountUsers(accountId, skip, top, sortBy, sortOrder);
      dispatch(
        fetchSuccess({
          items,
          totalCount,
        }),
      );
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
