import { type AssignedUser } from "../../../../../interfaces";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "../../../../Application/slices/createFetchingItemsSlice";
import { fetchPotentialContacts, fetchPotentialContactsNamePayload } from "../thunks/potentialContactsThunk";

export type PotentialContactsState = FetchingItemsState<AssignedUser>;

const potentialContactsInitialState: PotentialContactsState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const potentialContactsSlice = createFetchingItemsSlice({
  namePayload: fetchPotentialContactsNamePayload,
  initialState: potentialContactsInitialState,
  thunk: fetchPotentialContacts,
  reducers: {},
});

export default potentialContactsSlice.reducer;
