import { type FC, useEffect } from "react";

import { RolePermissions, type UsersGroupsContext } from "../../../../../enums";
import { type IWizardStep } from "../../../../../interfaces";

import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";

export interface EditAssessmentPeoplePropsBase extends IWizardStep {
  entityId: number;
  title: string;
  usersGroupsContext: UsersGroupsContext;
  onContextChanged: (value: UsersGroupsContext) => void;
}

export type EditAssessmentPeopleProps = EditAssessmentPeoplePropsBase;

export const EditAssessmentPeople: FC<EditAssessmentPeopleProps> = ({
  entityId,
  acceptHandlers,
  onContextChanged,
  usersGroupsContext,
}) => {
  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.AssetsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={onContextChanged}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview assessmentId={entityId} customHeaderContent={customHeaderContent} />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview assessmentId={entityId} customHeaderContent={customHeaderContent} />
        )}
      />
    </>
  );
};

export default EditAssessmentPeople;
