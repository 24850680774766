import React from "react";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";
import cn from "classnames";
import "./tabs.scss";

import TabsPane from "./TabsPane";

function Tabs(props) {
  const { children, defaultActiveIndex, activeIndex, onTabChange, className, renderActiveOnly, ...rest } = props;

  const panes = children.map((child) => {
    const { label, children: childrenProp, ...restProps } = child.props;
    const paneItem = (
      <Tab.Pane attached={false} {...restProps} key={label}>
        {childrenProp}
      </Tab.Pane>
    );

    return {
      menuItem: label,
      [renderActiveOnly ? "render" : "pane"]: renderActiveOnly ? () => paneItem : paneItem,
    };
  });
  return (
    <Tab
      defaultActiveIndex={defaultActiveIndex}
      className={cn("tabs-control", className)}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      onTabChange={onTabChange}
      {...rest}
      renderActiveOnly={renderActiveOnly}
    />
  );
}

Tabs.defaultProps = {
  defaultActiveIndex: 0,
  renderActiveOnly: true,
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(TabsPane),
  className: PropTypes.string,
  onTabChange: PropTypes.func,
  renderActiveOnly: PropTypes.bool,
  activeIndex: PropTypes.number,
};

Tabs.Pane = TabsPane;

export default Tabs;
