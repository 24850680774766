import type { XYCoord } from "react-dnd";
import type { AlignedWith } from "../../../AlignmentSmartGuides/types";

export const snapToAlignmentGuideLines = (
  clientOffset: XYCoord | null,
  {
    leftAligned,
    rightAligned,
    topAligned,
    bottomAligned,
  }: {
    leftAligned: AlignedWith | null;
    rightAligned: AlignedWith | null;
    topAligned: AlignedWith | null;
    bottomAligned: AlignedWith | null;
  },
) => {
  if (!clientOffset) return clientOffset;

  const clientOffsetCopy: XYCoord | null = { ...clientOffset };
  // snapping item to alignment guidelines
  if (leftAligned?.diff) {
    clientOffsetCopy.x -= leftAligned?.diff;
  }

  if (!leftAligned && rightAligned?.diff) {
    clientOffsetCopy.x -= rightAligned?.diff;
  }

  if (topAligned?.diff) {
    clientOffsetCopy.y -= topAligned?.diff;
  }

  if (!topAligned && bottomAligned?.diff) {
    clientOffsetCopy.y -= bottomAligned?.diff;
  }
  return clientOffsetCopy;
};
