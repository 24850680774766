import { type Dispatch } from "@reduxjs/toolkit";
import { type GetPackVisibilityForAccountsRequest } from "../../types/requests";
import accountsDataService from "../../../../Accounts/services/accountsDataService";
import packsDataService from "../../services/packsDataService";
import {
  fetchBegin,
  fetchSuccess,
  fetchFailure,
  fetchVisibleAccountIdsSuccess,
} from "../slices/packVisibilityForAccountsSlice";
import * as actionTypes from "../actionTypes/packEntityStateActionType";
import { getActionBaseProvider } from "../../../../Application/actions/actionsBuilder";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { EditAccountPackVisibilitySuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { type AppDispatch } from "features/Application/globaltypes/redux";

export const fetchPackVisibilityAccounts =
  (requestData: GetPackVisibilityForAccountsRequest) => async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const accountIdsResult = await packsDataService.getPackVisibilityAccountIdsAsync(requestData.packId);
      const accountIds = accountIdsResult.data;
      if (accountIds && accountIds.length > 0) {
        const result = await accountsDataService.getAccounts(
          requestData.skip,
          requestData.top,
          requestData.orderBy as string,
          requestData.orderDirection,
          requestData.searchTerm,
          "",
          accountIds,
        );
        dispatch(
          fetchSuccess({
            items: result.items,
            totalCount: result.itemsCount,
          }),
        );
      } else {
        dispatch(
          fetchSuccess({
            items: [],
            totalCount: 0,
          }),
        );
      }
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const fetchPackVisibilityAccountIds = (packId: number) => async (dispatch: Dispatch) => {
  dispatch(fetchBegin());
  try {
    const accountIdsResult = await packsDataService.getPackVisibilityAccountIdsAsync(packId);
    if (accountIdsResult.data.length > 0) {
      dispatch(fetchVisibleAccountIdsSuccess(accountIdsResult.data));
    } else {
      dispatch(fetchVisibleAccountIdsSuccess([]));
    }
  } catch (e) {
    dispatch(fetchFailure(e as Error));
  }
};

export const deletePackVisibilityForAccount =
  (packId: number, accountIdsToRemove: number[]) => async (dispatch: AppDispatch) => {
    const begin = getActionBaseProvider(actionTypes.updatePackBegin);
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: packId, action: EditAccountPackVisibilitySuccess }));
    try {
      const packVisibilityAccountIdsResult = await packsDataService.getPackVisibilityAccountIdsAsync(packId);
      await packsDataService.updatePackAccountsVisibilityAsync(packId, {
        visibleForAccountIds: packVisibilityAccountIdsResult.data.filter(
          (acountId) => !accountIdsToRemove.includes(acountId),
        ),
      });
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const addPackVisibilityForAccount =
  (packId: number, accountIdsToAdd: number[]) => async (dispatch: AppDispatch) => {
    const begin = getActionBaseProvider(actionTypes.updatePackBegin);
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: packId, action: EditAccountPackVisibilitySuccess }));
    try {
      const packVisibilityAccountIdsResult = await packsDataService.getPackVisibilityAccountIdsAsync(packId);
      await packsDataService.updatePackAccountsVisibilityAsync(packId, {
        visibleForAccountIds: packVisibilityAccountIdsResult.data.concat(accountIdsToAdd),
      });
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
