import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import * as filterOptions from "../../../../Application/actions/filterOptions/filterOptionsActionTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";

export const GROUPS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const GROUPS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const GROUPS_FILTER_OPTIONS_FETCH_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filterOptions.FETCH_OPTIONS_BEGIN,
);

export const GROUPS_FILTER_OPTIONS_FETCH_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filterOptions.FETCH_OPTIONS_SUCCESS,
);

export const GROUPS_FILTER_OPTIONS_FETCH_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Groups,
  filterOptions.FETCH_OPTIONS_FAILURE,
);
