import { Table } from "semantic-ui-react";
import { type IntegrationTypes, type IntegrationWithCompositeId } from "../../../types";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import Title from "../../../../../../components/listViewTemplates/title/Title";
import { IntegrationDtoMap } from "features/Accounts/Integrations/Shared";
import { columnOptions } from "../../../constants";
import StatusCell, { type Status } from "components/statusCell/StatusCell";

export interface IntegrationRowProps {
  integration: IntegrationWithCompositeId;
  moboId?: string;
  ownAccount: boolean;
  renderOptionsMenu: (integration: IntegrationWithCompositeId) => React.ReactElement;
}

const convertTypeToName = (type: string, ownAccount: boolean, moboId?: string) => {
  const integrationDto = IntegrationDtoMap[type.toLowerCase() as IntegrationTypes];
  if (!integrationDto?.title) {
    return type;
  }

  return (
    <Title title={integrationDto.title} url={integrationDto.url(ownAccount, moboId)} className="title normal-color" />
  );
};

export const IntegrationRow: React.FC<IntegrationRowProps> = ({
  integration,
  renderOptionsMenu,
  ownAccount,
  moboId,
}) => {
  const emptyCellContent = "—";
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        {convertTypeToName(integration.id.type, ownAccount, moboId)}
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        {dateTimeUtils.formatWithValidation(integration.lastSyncDate, emptyCellContent)}
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <StatusCell status={integration.status.toUpperCase() as Status} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <Title title={integration.details} />
      </Table.Cell>
      <Table.Cell className="align-right" width={columnOptions[4].width}>
        {renderOptionsMenu(integration)}
      </Table.Cell>
    </>
  );
};

export default IntegrationRow;
