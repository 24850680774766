import { type Dispatch } from "@reduxjs/toolkit";
import { getAxiosError } from "../../../../../utils/axiosError";
import packsDataService from "../../services/packsDataService";
import {
  reqLicenseUsage,
  reqTotalAccountLicenses,
  setLicenseUsage,
  setLicenseUsageError,
  setTotalAccountLicenses,
  setTotalAccountLicensesError,
} from "../slices/packPurchasedPerformanceSlice";

export const fetchTotalAccountLicenses = (packId: number, moboId?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(reqTotalAccountLicenses());
      const { data } = await packsDataService.getTotalAccountLicenses(packId, moboId);
      dispatch(setTotalAccountLicenses({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(
        setTotalAccountLicensesError({
          errorMessage,
        }),
      );
    }
  };
};

export const fetchLicenseUsage = (packId: number, moboId?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(reqLicenseUsage());
      const { data } = await packsDataService.getLicenseUsage(packId, moboId);
      dispatch(setLicenseUsage({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(
        setLicenseUsageError({
          errorMessage,
        }),
      );
    }
  };
};
