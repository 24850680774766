import { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "../../modal/ModalWithSteps";
import sortOptions from "../../../features/Licensing/Packs/Overview/sortOptions";
import PackGridStep from "./packAssignmentSteps/packGridStep/PackGridStep";
import SortOptions from "../../../enums/SortOptions";
import { type Filters } from "../../../utils/queryUtils";
import { type AssignAccountPack, type AccountPackOverview } from "./types";
import LicensesAddingStep from "./packAssignmentSteps/licensesAddingStep/LicensesAddingStep";
import { type SearchState } from "../../../features/Application/slices/createSearchSlice";
import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import {
  requestedPacksLicensesSelector,
  reset,
} from "../../../features/Licensing/Packs/state/slices/requestedPacksLicensesSlice";
import { fetchRequestedPacksLicenses } from "../../../features/Licensing/Packs/state/thunks/requestedPacksLicensesThunk";
import { type RequestedPackLicenses } from "../../../features/Licensing/Packs/types/state";
import { LicenseTypes, type ViewType } from "../../../enums";
import SearchInput from "../../searchInput/SearchInput";

import "./PackAssignmentModal.scss";

interface PackAssignmentState {
  orderBy: SortOptions;
  selectedPacks: AssignAccountPack[];
  isFinishBtnDisabled: boolean;
  currentStep: number;
}

interface PackAssignmentProps {
  onConfirm: (packs: AssignAccountPack[], selectedViewType: ViewType) => void;
  onCancel: () => void;
  selectAccountId: number;
  accountId: number;
  showModal: boolean;
  loadPacksForModal: (assignedAccountId: number, skip: number, orderBy: string, filters?: Filters) => void;
  loadPackForModal: (assignedAccountId: number, packIds: number[]) => void;
  applyFilter: (filter: Filters) => void;
  resetFilter: () => void;
  resetGrid: () => void;
  items: Array<AccountPackOverview>;
  isLoading: boolean;
  filterOptions: any;
  appliedFilter: Filters;
  itemsCount: number;
  fetchFilterOptions: () => void;
  packsModalSearch?: SearchState;
  selectedViewType: ViewType;
  isPacksModalFiltered: boolean;
  setPacksModalSearch?: (searchTerm: string) => void;
  predefinedPackIds: number[];
  areAllLoaded: boolean;
}

const initialState: PackAssignmentState = {
  orderBy: SortOptions.CreatedDateDesc,
  selectedPacks: [],
  isFinishBtnDisabled: true,
  currentStep: 0,
};

export type PackAssignmentAllProps = PropsFromRedux & PackAssignmentProps;

export class PackAssignmentModal extends Component<PackAssignmentAllProps, PackAssignmentState> {
  constructor(props: PackAssignmentAllProps) {
    super(props);
    this.state = {
      ...initialState,
      selectedPacks: props.predefinedPackIds.map((id) => {
        return { packId: id, isRequested: true, edited: false };
      }),
    };
  }

  resetModal = () => {
    const { resetGrid, resetFilter, setPacksModalSearch, resetRequestedPacks } = this.props;
    resetGrid();
    resetFilter();
    setPacksModalSearch?.("");
    this.setState({ selectedPacks: [] });
    resetRequestedPacks();
  };

  onCancelModal = () => {
    this.props.onCancel();
    this.resetModal();
  };

  async componentDidMount() {
    const { loadPackForModal, selectAccountId, loadRequestedPacksLicenses } = this.props;
    this.props.fetchFilterOptions();
    this.props.resetGrid();
    if (this.props.predefinedPackIds.length !== 0) {
      loadPackForModal(selectAccountId, this.props.predefinedPackIds);
      await loadRequestedPacksLicenses(selectAccountId, this.props.predefinedPackIds);
      this.setLicensesCountForSelectedPacks();
    } else {
      this.loadItemsToAddPage();
    }
  }

  getRequestedLicensesMap() {
    const licensesMap = new Map<number, RequestedPackLicenses>();
    this.props.requestedPacksLicenses.forEach((rpl) => {
      licensesMap.set(rpl.packId, rpl);
    });
    return licensesMap;
  }

  setLicensesCountForSelectedPacks() {
    const packLicensesMap = this.getRequestedLicensesMap();
    const selectedPacks = this.getSelectedPacks().map((sp) => {
      if (sp.isRequested && !sp.edited) {
        const request = packLicensesMap.get(sp.packId);
        sp.licensesCount = request?.licensesCount ?? sp.licensesCount;
        sp.expirationDate = request?.expirationDate ?? sp.expirationDate;
        sp.licenseType = request?.licenseTypeId ?? sp.licenseType;
      }
      return sp;
    });
    this.setState({ selectedPacks });
  }

  async onNextStep() {
    const { loadRequestedPacksLicenses, selectAccountId } = this.props;
    const requestedPackIds = this.getSelectedPacks()
      .filter((pack) => pack.isRequested)
      .map((rp) => rp.packId);

    if (requestedPackIds.length > 0) {
      await loadRequestedPacksLicenses(selectAccountId, requestedPackIds);
      this.setLicensesCountForSelectedPacks();
    }
  }

  onSelectedItemsChanged = (ids: number[]) => {
    const packLicensesMap = this.getRequestedLicensesMap();
    const selectedPacks: AssignAccountPack[] = ids.map((id) => {
      const pack = this.getSelectedPacks().find((sp) => sp.packId === id);
      if (pack) {
        return pack;
      }
      const requestedPack = this.props.items.find((i) => id === i.id);
      const info = packLicensesMap.get(id);
      return {
        packId: id,
        isRequested: requestedPack ? requestedPack.isRequested : false,
        edited: false,
        licenseType: info?.licenseTypeId,
        expirationDate: info?.expirationDate,
        licensesCount: info?.licensesCount,
      };
    });

    selectedPacks.sort((left, right) => (left.licensesCount ?? -1) - (right.licensesCount ?? -1));
    this.setState({ selectedPacks: selectedPacks });
  };

  validateFinishBtnEnabling = (maxLicensesCount: number) => {
    const isNotValid = this.getSelectedPacks().some(
      (p) =>
        !p.licensesCount ||
        p.licensesCount > maxLicensesCount ||
        (!p.expirationDate && (!p.licenseType || p.licenseType === LicenseTypes.CustomDate)),
    );
    this.setState({ isFinishBtnDisabled: isNotValid });
  };

  loadItemsToAddPage = () => {
    const { loadPacksForModal, selectAccountId, appliedFilter, items } = this.props;
    const { orderBy } = this.state;
    loadPacksForModal(selectAccountId, items.length, orderBy, appliedFilter);
  };

  resetPacks = (filters: Filters) => {
    this.onSelectedItemsChanged([]);
    const { loadPacksForModal, resetGrid, selectAccountId } = this.props;
    const { orderBy } = this.state;
    resetGrid();
    loadPacksForModal(selectAccountId, 0, orderBy, filters);
  };

  onSearchChange = async (search: string) => {
    const { appliedFilter } = this.props;
    await this.props.setPacksModalSearch?.(search);
    this.resetPacks(appliedFilter);
  };

  resetFilter = () => {
    if (!isEmpty(this.props.appliedFilter)) {
      this.resetPacks({});
      this.props.resetFilter();
    }
  };

  applyFilter = (filter: Filters) => {
    this.resetPacks(filter);
    this.props.applyFilter(filter);
  };

  onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    this.onSelectedItemsChanged([]);
    const { resetGrid } = this.props;
    resetGrid();
    this.setState({ orderBy: data.value }, this.loadItemsToAddPage);
  };

  onLicenseCountChange = (selectedPackId: number, licensesCount?: number) => {
    const packs = this.getSelectedPacks();
    const pack = packs.find((el) => el.packId === selectedPackId);
    if (pack) {
      pack.licensesCount = licensesCount;
      pack.edited = true;
      this.setState({ selectedPacks: packs });
    }
  };

  getSelectedPacks = (): AssignAccountPack[] => {
    const { selectedPacks } = this.state;
    const { predefinedPackIds } = this.props;

    const packLicensesMap = this.getRequestedLicensesMap();
    return predefinedPackIds.length !== 0 && selectedPacks.length === 0
      ? predefinedPackIds.map((id) => {
          const info = packLicensesMap.get(id);
          return {
            packId: id,
            isRequested: true,
            edited: false,
            licenseType: info?.licenseTypeId,
            expirationDate: info?.expirationDate,
            licensesCount: info?.licensesCount,
          };
        })
      : selectedPacks;
  };

  onExpirationDateChange = (selectedPackId: number, optionId?: number, date?: Date | null) => {
    const packs = this.getSelectedPacks();
    const pack = packs.find((el) => el.packId === selectedPackId);
    if (pack) {
      if (optionId) {
        pack.licenseType = optionId;
        pack.expirationDate = undefined;
      }
      pack.expirationDate = date ?? undefined;
      this.setState({ selectedPacks: packs });
    }
  };

  getSortOptionWithSelected() {
    const selectedOption = this.state.orderBy;
    sortOptions.forEach((option) => (option.default = selectedOption === option.value));
    return sortOptions;
  }

  renderPackListStepActions = (nextStep: () => void) => {
    const { selectedPacks } = this.state;
    return (closeModal: Function) => (
      <>
        <Button
          basic
          color="blue"
          className="cancel"
          content="Cancel"
          onClick={() => {
            closeModal();
            this.onCancelModal();
          }}
        />
        <Button
          primary
          className="next"
          content="Next"
          onClick={() => {
            nextStep();
            this.onNextStep();
          }}
          disabled={isEmpty(selectedPacks)}
        />
      </>
    );
  };

  renderModalLicensesAddingStep = (_: () => void, prevStep: () => void) => {
    const { onConfirm, selectedViewType, predefinedPackIds } = this.props;
    const { isFinishBtnDisabled, selectedPacks } = this.state;
    return (closeModal: Function) => {
      return (
        <>
          {predefinedPackIds.length === 0 ? (
            <Button blur basic className="previous" content="Previous" onClick={prevStep} />
          ) : (
            <Button
              basic
              color="blue"
              className="cancel"
              content="Cancel"
              onClick={() => {
                closeModal();
                this.onCancelModal();
              }}
            />
          )}

          <Button
            primary
            className="next"
            content="Finish"
            disabled={isFinishBtnDisabled}
            onClick={() => {
              closeModal();
              if (onConfirm) {
                onConfirm(selectedPacks, selectedViewType);
              }
              this.resetModal();
            }}
          />
        </>
      );
    };
  };
  render() {
    const {
      accountId,
      showModal,
      selectAccountId,
      items,
      itemsCount,
      isLoading,
      areAllLoaded,
      isLicensesCountLoading,
      filterOptions,
      appliedFilter,
      isPacksModalFiltered,
      predefinedPackIds,
    } = this.props;

    const selectedOrPredefinedPacks = this.getSelectedPacks();

    const isProvisionLicensesStep = (): boolean => this.state.currentStep === 1 || !!predefinedPackIds.length;

    return (
      <ModalWithSteps
        className={`pack-assignment-modal ${isProvisionLicensesStep() && "provision-licenses-step"} ${
          selectedOrPredefinedPacks.length > 2 && "multiple-packs"
        }`}
        scrolling
        showModal={showModal}
        onCancel={this.onCancelModal}
        onPrevStep={(currentStep) => this.setState({ currentStep })}
        onNextStep={(currentStep) => this.setState({ currentStep })}
      >
        {predefinedPackIds.length === 0 && (
          <PackGridStep
            header="Add Packs"
            renderModalActions={this.renderPackListStepActions}
            selectedIds={selectedOrPredefinedPacks?.map((pack) => pack.packId)}
            onSelectedItemsChanged={this.onSelectedItemsChanged}
            assignedAccountId={selectAccountId}
            applyFilter={this.applyFilter}
            loadItemsToAddPage={this.loadItemsToAddPage}
            blur
            onSortOptionsChanged={this.onSortOptionsChanged}
            resetFilter={this.resetFilter}
            accountId={accountId}
            appliedFilter={appliedFilter}
            filterOptions={filterOptions}
            isLoading={isLoading}
            areAllLoaded={areAllLoaded}
            items={items.map((item: any) => ({
              ...item,
            }))}
            itemsCount={itemsCount}
            sortOptions={this.getSortOptionWithSelected()}
            renderSearch={() => (
              <SearchInput
                placeholder="Search for packs..."
                onChange={this.onSearchChange}
                defaultValue={this.props.packsModalSearch?.term}
              />
            )}
            isPacksModalFiltered={isPacksModalFiltered}
          />
        )}
        <LicensesAddingStep
          header="Provision Licenses"
          isLoading={isLoading || isLicensesCountLoading}
          selectedPacks={selectedOrPredefinedPacks}
          renderModalActions={this.renderModalLicensesAddingStep}
          items={items.filter((i) => selectedOrPredefinedPacks?.some((p) => p.packId === i.id))}
          onLicenseCountChange={this.onLicenseCountChange}
          validateFinishBtnEnabling={this.validateFinishBtnEnabling}
          onExpirationDateChange={this.onExpirationDateChange}
          packRequestsData={this.getRequestedLicensesMap()}
        />
      </ModalWithSteps>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    requestedPacksLicenses: requestedPacksLicensesSelector(state),
    isLicensesCountLoading: state.packs.requestedPacksLicenses.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    loadRequestedPacksLicenses: (assignedAccountId: number, packIds: number[]) =>
      dispatch(fetchRequestedPacksLicenses(assignedAccountId, packIds)),
    resetRequestedPacks: () => dispatch(reset()),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackAssignmentModal);
export default ConnectedComponent;
