import { isEmpty } from "lodash";

import ExpandableLinks from "../../../../../../components/ExpandableItems/ExpandableLinks/ExpandableLinks";
import MultiSelectWithAdditionField from "../../../../../../components/forms/MultiSelectWithAdditionField";

import { type NormalizedDropdown } from "../../../../../../utils/miscellaneousUtils";
import { ValidatedForm } from "../../../../../../components/forms";

import styles from "./settingsForm.module.scss";
import { type TagsPayloadCamelPlural } from "../../../../../../interfaces/Tags";
import { type SettingsView } from "../../../types/state";
import { type FormikProps } from "formik";

export interface SettingsFormProps {
  entityId: number;
  settings: SettingsView;
  normalizedTags?: TagsPayloadCamelPlural;
  isDisabled: boolean;
  isLoading?: boolean;
  updateTags: (id: number, tags: string[], type: "labels" | "softwareApplications") => void;
  tagsFormik: FormikProps<SettingsView>;
}

const SettingsForm = (props: SettingsFormProps) => {
  const { settings, isLoading, tagsFormik, entityId } = props;

  const handleChangeTag = async (
    field: "softwareApplications" | "labels",
    value: NormalizedDropdown,
    shouldValidate?: boolean,
  ) => {
    const errors = await (tagsFormik.setFieldValue(field, value.selected, shouldValidate) as unknown as Promise<void>);

    if (isEmpty(errors?.[field])) {
      props.updateTags(entityId, value.selected, field);
    }
  };

  const renderFields = () => {
    const { isDisabled, normalizedTags } = props;
    return (
      <div className={styles.root}>
        <div className={styles["settings-fields"]}>
          <MultiSelectWithAdditionField
            {...tagsFormik}
            isCaseSensitive
            shouldValidate
            label="Software Application"
            propertyName="softwareApplications"
            disabled={isDisabled}
            options={normalizedTags?.softwareApplications}
            value={tagsFormik.values.softwareApplications}
            setFieldValue={handleChangeTag}
          />
          <MultiSelectWithAdditionField
            {...tagsFormik}
            isCaseSensitive
            shouldValidate
            label="Labels"
            propertyName="labels"
            disabled={isDisabled}
            options={normalizedTags?.labels}
            value={tagsFormik.values.labels}
            setFieldValue={handleChangeTag}
          />
          <ExpandableLinks
            label="Associated Flows"
            linkFormatter={(id) => `/content/flows/${id}/items`}
            iconName="fa-code-branch"
            links={settings.flows}
          />
        </div>
      </div>
    );
  };
  return (
    <ValidatedForm parentWithFormik isLoading={isLoading} formWidthComputer={6} disablePreventTransitionPrompt>
      {renderFields}
    </ValidatedForm>
  );
};
export default SettingsForm;
