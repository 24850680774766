import React, { Component } from "react";
import { Accordion, Icon, Label } from "semantic-ui-react";
import { PropTypes } from "prop-types";
import isNil from "lodash/isNil";

import "./expand.scss";

export default class Expand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: props.expandedByDefault || false,
    };
  }

  handleClick = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  renderLabel = () => {
    const { label } = this.props;
    if (isNil(label)) {
      return null;
    }

    return (
      <Label circular color="blue" size="medium">
        {label}
      </Label>
    );
  };

  render() {
    const { title, children } = this.props;
    return (
      <Accordion className="expand-control">
        <Accordion.Title active={this.state.isActive} onClick={this.handleClick}>
          {title}
          {this.renderLabel()}
          <Icon className="arrow-icon fa-chevron-down" />
        </Accordion.Title>
        <Accordion.Content active={this.state.isActive}>{children || ""}</Accordion.Content>
      </Accordion>
    );
  }
}

Expand.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expandedByDefault: PropTypes.bool,
};
