import isEqual from "lodash/isEqual";
import Get from "lodash/get";
import environmentConfig from "../configuration/environmentConfig";
import { type IAutosave, type IAutosaveConfiguration } from "../interfaces/IAutosave";
import Observable from "./Observable";
import { type Action } from "../interfaces/functionTypes/Action";

export default class Autosave<TState, TStateProjection = TState> implements IAutosave<TState, TStateProjection> {
  private state: TState | null;
  private readonly config: IAutosaveConfiguration<TState, TStateProjection>;
  private readonly delayInSeconds: number;
  private readonly isPeriodicUpdateEnabled: boolean;
  private readonly onSaveRequestedObservable = new Observable<Action<TStateProjection>>();
  private isDisposed = false;
  private intervalHandle: NodeJS.Timeout | null = null;

  constructor(configuration: IAutosaveConfiguration<TState, TStateProjection>) {
    this.config = configuration;
    this.delayInSeconds = environmentConfig.autosaveDelayInSeconds || 15;
    this.isPeriodicUpdateEnabled = configuration.isPeriodicUpdateEnabled ?? true;
    this.state = this.config.isDeferredInitialization ? null : this.config.stateProvider();
    this.startTimer();
    this.subscribeEvents();
  }

  ensureInitialization = (initValues?: TState) => {
    if (!this.state) {
      this.state = initValues || this.config.stateProvider();
    }
  };

  save = async () => {
    this.restartTimer();
    await this.doSave(true);
  };

  onBlur = async (propertyName: string) => {
    this.disposedGuard();
    if (!this.isEnabled()) {
      return;
    }
    this.restartTimer();
    if (this.isChanged(propertyName)) {
      await this.doSave(true);
    }
  };

  dispose = async () => {
    if (this.isDisposed) {
      return;
    }
    await this.doSave();
    this.clearTimer();
    this.unsubscribeEvents();
    this.onSaveRequestedObservable.dispose();
    this.isDisposed = true;
  };

  subscribeOnSaveRequested = this.onSaveRequestedObservable.subscribe;

  private doSave = async (avoidEqualityCheck?: boolean): Promise<boolean | void> => {
    this.disposedGuard();
    if (!this.isEnabled()) {
      return false;
    }
    const { stateProvider, stateProviderWithCallback } = this.config;

    if (stateProviderWithCallback !== undefined) {
      return stateProviderWithCallback(async (actualState: TState) => {
        await this.saveState(actualState, avoidEqualityCheck);
      });
    } else {
      return this.saveState(stateProvider(), avoidEqualityCheck);
    }
  };

  private async saveState(actualState: TState, avoidEqualityCheck?: boolean): Promise<boolean> {
    const { updateHandler } = this.config;

    const isStateChanged = avoidEqualityCheck || !isEqual(actualState, this.state);

    if (isStateChanged) {
      this.state = { ...actualState };
      const data = this.getDataForSaving(this.state);
      await updateHandler(data);
      this.onSaveRequestedObservable.notify(data);
    }

    return isStateChanged;
  }

  private isEnabled = () => this.state !== null && this.config.isValid();

  private getDataForSaving = (state: TState): TStateProjection => {
    const { stateProjector } = this.config;
    return (stateProjector && stateProjector(state)) || (state as unknown as TStateProjection);
  };

  private isChanged = (propertyName: string): boolean => {
    const actualState = this.config.stateProvider();

    const actualValue = Get(actualState, propertyName);
    const storedValue = Get(this.state, propertyName);
    return !isEqual(actualValue, storedValue);
  };

  private startTimer = () => {
    if (this.isPeriodicUpdateEnabled) {
      this.intervalHandle = setInterval(this.doSave, this.delayInSeconds * 1000);
    }
  };

  private clearTimer = () => {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  };

  private restartTimer = () => {
    this.clearTimer();
    this.startTimer();
  };

  private disposedGuard = () => {
    if (this.isDisposed) {
      throw new Error("Autosave have been already disposed");
    }
  };

  private onBeforUnload = async (event: BeforeUnloadEvent) => {
    const wasChanged = await this.doSave();
    if (wasChanged) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  private subscribeEvents = () => {
    window.addEventListener("beforeunload", this.onBeforUnload);
  };

  private unsubscribeEvents = () => {
    window.removeEventListener("beforeunload", this.onBeforUnload);
  };
}
