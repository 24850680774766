import { isEmpty } from "lodash";
import { type FC, useCallback, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { AssignmentPeopleContext, SortOptions, Strings } from "../../../enums";
import { type AppDispatch } from "../../../features/Application/globaltypes/redux";
import sortOptions from "../../../features/Licensing/Packs/Overview/sortOptions";
import { type PeopleAvailablePacksRequest } from "../../../features/Licensing/Packs/types/requests";
import { ModalWithSteps } from "../../modal";
import SearchInput from "../../searchInput/SearchInput";
import { ConfirmLicensingStep } from "../commonSteps";
import PackGridStep from "../packAssignmentModal/packAssignmentSteps/packGridStep/PackGridStep";
import { bindAction } from "../../../interfaces/redux";
import { Context } from "enums/licenseContext";
import { type LicenseConfirmationData } from "../../../interfaces";
import { type SearchState } from "../../../features/Application/slices/createSearchSlice";
import { type LazyFetchingItemsState } from "../../../features/Application/slices/createLazyFetchingItemsSlice";
import {
  setPeopleType,
  resetPeopleType,
} from "../../../features/Licensing/Packs/state/slices/peopleIssueLicenseModalSlice";
import { type PeopleAvailablePackOverview } from "../packAssignmentModal/types";
import { Button } from "components/buttons/button/Button";

import "./peopleLicenseModal.scss";

export interface PeopleIssueLicenseModalProps {
  loadPacks: (requestData: PeopleAvailablePacksRequest) => void;
  showModal: boolean;
  onCancel: () => void;
  accountId: number;
  onConfirm: (selectedPackIds: number[]) => void;
  peopleId: number;
  assignmentPeopleContext: AssignmentPeopleContext;
  packsGrid: LazyFetchingItemsState<PeopleAvailablePackOverview>;
  search?: SearchState;
  setSearch?: (payload: string) => void;
  resetGridItems: () => void;
}

export type PeopleIssueLicenseModalAllProps = PropsFromRedux & PeopleIssueLicenseModalProps;

export const EditPeopleIssueLicenseModal: FC<PeopleIssueLicenseModalAllProps> = (
  props: PeopleIssueLicenseModalAllProps,
) => {
  const [selectedPackIds, setSelectedPackIds] = useState<number[]>([]);
  const [orderBy, setOrderBy] = useState<SortOptions>(SortOptions.CreatedDateDesc);
  const [isDataValid, setIsDataValid] = useState(true);

  const {
    showModal,
    accountId,
    peopleId,
    assignmentPeopleContext,
    search,
    onCancel,
    packsGrid,
    loadPacks,
    setSearch,
    resetGridItems,
    onConfirm,
    setPeople,
    resetPeople,
  } = props;

  const onLoadFirstPageCallback = useCallback(() => {
    setSelectedPackIds([]);
    loadPacks({
      peopleId: peopleId,
      accountId: accountId,
      skip: 0,
      orderBy: orderBy,
      filters: {},
      searchTerm: search?.term,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, loadPacks, accountId]);

  useEffect(() => {
    return () => {
      resetGridItems();
      setSearch && setSearch("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPeople(assignmentPeopleContext);
    onLoadFirstPageCallback();
  }, [orderBy, assignmentPeopleContext, onLoadFirstPageCallback, setPeople]);

  useEffect(() => {
    if (!showModal) {
      setSelectedPackIds([]);
      setOrderBy(SortOptions.CreatedDateDesc);
      resetGridItems();
      resetPeople();
    }
  }, [showModal, resetGridItems, resetPeople]);

  const renderPackListStepActions = (nextStep: () => void) => {
    return (closeModal: Function) => (
      <>
        <Button
          basic
          color="blue"
          className="cancel"
          content="Cancel"
          onClick={() => {
            closeModal();
            onCancel();
          }}
        />
        <Button blur primary className="next" content="Next" onClick={nextStep} disabled={isEmpty(selectedPackIds)} />
      </>
    );
  };

  const renderLicensingConfirmationStepActions = (_nextStep: () => void, prevStep: () => void) => {
    return (closeModal: () => void) => (
      <>
        <Button blur primary className="previous" content="Previous" onClick={prevStep} />
        <Button
          primary
          className="confirm"
          content="Finish"
          disabled={!isDataValid}
          onClick={() => {
            onConfirm(selectedPackIds);
            closeModal();
          }}
        />
      </>
    );
  };

  const getSortOptionWithSelected = () => {
    const selectedOption = orderBy;
    sortOptions.forEach((option) => (option.default = selectedOption === option.value));
    return sortOptions;
  };

  const onSearchChange = (searchData: string) => {
    setSearch && setSearch(searchData);
    resetGridItems();
    onLoadFirstPageCallback();
  };

  const onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    resetGridItems();
    setOrderBy(data.value);
  };

  const loadItems = (loadedPacksCount: number) => {
    loadPacks({
      peopleId: peopleId,
      accountId: accountId,
      skip: loadedPacksCount,
      orderBy: orderBy,
      filters: {},
      searchTerm: search?.term,
    });
  };

  const loadItemsToAddPage = () => loadItems(packsGrid.items.length);

  const getConfirmationInfo = () => {
    const confirmationInfo: LicenseConfirmationData = {
      packIds: selectedPackIds,
      userIds: assignmentPeopleContext === AssignmentPeopleContext.User ? [peopleId] : [],
      groupIds: assignmentPeopleContext === AssignmentPeopleContext.Group ? [peopleId] : [],
    };
    return confirmationInfo;
  };

  const isFiltered = !isEmpty(search?.term);

  return (
    <ModalWithSteps className="people-license-modal" scrolling showModal={showModal} onCancel={onCancel}>
      {
        <PackGridStep
          header="Issue License"
          accountId={accountId}
          selectedIds={selectedPackIds}
          assignedAccountId={accountId}
          renderModalActions={renderPackListStepActions}
          onSelectedItemsChanged={setSelectedPackIds}
          loadItemsToAddPage={loadItemsToAddPage}
          blur
          onSortOptionsChanged={onSortOptionsChanged}
          applyFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          appliedFilter={/* istanbul ignore next */ () => {}} // NOSONAR
          filterOptions={/* istanbul ignore next */ {}} // NOSONAR
          hideFilterPanel
          isLoading={packsGrid.isLoading}
          areAllLoaded={packsGrid.areAllLoaded}
          items={packsGrid.items}
          itemsCount={packsGrid.itemsCount}
          sortOptions={getSortOptionWithSelected()}
          renderSearch={() => (
            <SearchInput
              placeholder="Search for packs..."
              disabled={!setSearch}
              onChange={onSearchChange}
              defaultValue={search?.term}
            />
          )}
          isPacksModalFiltered={isFiltered}
        />
      }
      {
        <ConfirmLicensingStep
          header={Strings.modalTitles.licenseConfirmation}
          renderModalActions={renderLicensingConfirmationStepActions}
          onIsDataValidChange={setIsDataValid}
          info={getConfirmationInfo()}
          context={Context.AddPacksToUserOrGroup}
        />
      }
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setPeople: bindAction(setPeopleType, dispatch),
    resetPeople: bindAction(resetPeopleType, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditPeopleIssueLicenseModal);
export default ConnectedComponent;
