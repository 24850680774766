import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildChildEntityActionType, buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";

const infoPrefix: string = "INFO";
export const GroupUsers: string = "GROUP_USERS";

export const RESET_USER_INFO: string = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  infoPrefix,
  "RESET",
);

export const FETCH_USER_INFO_BEGIN: string = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  infoPrefix,
  "FETCH_BEGIN",
);

export const FETCH_USER_INFO_SUCCESS: string = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  infoPrefix,
  "FETCH_SUCCESS",
);

export const FETCH_USER_INFO_FAILURE: string = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  infoPrefix,
  "FETCH_FAILURE",
);

export const FETCH_USER_INFO_FORBIDDEN = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  infoPrefix,
  "FETCH_USER_INFO_FORBIDDEN",
);

export const UPDATE_USER_INFO: string = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  "UPDATE_USER_INFO",
);
