import React, { type FC, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { type IObservable } from "../../../interfaces/IObservable";
import { PacksContextItemsBlock } from "../../../features/Licensing/ContentAssignmentModalLicensingSteps/PackContextModalContent/packsContextBlock/PacksContextItemsBlock";
import { resolveFlowConflict as resolveFlowConflictAction } from "../../../features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/packsContextSlice";
import { type RootState } from "../../../features/Application/globaltypes/redux";
import { flowPackContextItemsSelector } from "../../../features/Library/Flows/state/selectors";
import { bindAction } from "../../../interfaces/redux";
import {
  resetContentPacksAction,
  setContentPackAction,
} from "../../../features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";

import ModalSizes from "../../../enums/modalSizes";
import TriggeredConfirmationModal from "../../triggeredConfirmationModal/TriggerableConfirmationModal";

import styles from "./packConfirmationModal.module.scss";

export interface PacksConfirmationModalProps extends PropsFromRedux {
  onTriggerModalObserver: IObservable<(onConfirm: () => void) => void>;
}

export const PackConfirmationModal: FC<PacksConfirmationModalProps> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const targetItems = useMemo(() => props.items.filter((i) => i.packs.length > 1), [props.items]);
  const allPacksSelected = useMemo(() => !targetItems.some((i) => !i.packId), [targetItems]);
  const allFlowsResolved = useMemo(
    () =>
      props.flowAssignments.length > 0 &&
      Object.keys(props.flowConflicts).every((flowId) =>
        props.flowAssignments.some((assignment) => assignment.flowId === parseInt(flowId)),
      ),
    [props.flowAssignments, props.flowConflicts],
  );

  useEffect(() => {
    return () => {
      props.resetContentPacks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const haveFlowConflicts = !!Object.keys(props.flowConflicts).length;

    if (targetItems.length) {
      setDisabled(!allPacksSelected);
    } else if (haveFlowConflicts) {
      setDisabled(!allFlowsResolved);
    }
  }, [targetItems, allFlowsResolved, allPacksSelected, props.flowConflicts]);

  const getContent = (
    <PacksContextItemsBlock
      isLoading={props.isLoading}
      items={targetItems}
      setPack={props.setContentPack}
      info={props.packContextItems}
      flowConflicts={props.flowConflicts}
      flowAssignments={props.flowAssignments}
      resolveFlowConflict={props.resolveFlowConflict}
    />
  );

  return (
    <TriggeredConfirmationModal
      title="Licensing"
      scrolling
      content={getContent}
      confirmButtonLabel="Finish"
      onTriggerModalObserver={props.onTriggerModalObserver}
      onClose={props.resetContentPacks}
      size={ModalSizes.large}
      disabled={disabled}
      styles={{ content: styles["pack-confirmation-modal-content"] }}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.licensing?.contentPacks?.items ?? [],
    flowConflicts: state.licensing.contentPacks.flowConflicts,
    flowAssignments: state.licensing.contentPacks.flowAssignments,
    isLoading: state.licensing?.contentPacks?.isLoading ?? false,
    packContextItems: flowPackContextItemsSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  resolveFlowConflict: bindAction(resolveFlowConflictAction, dispatch),
  setContentPack: bindAction(setContentPackAction, dispatch),
  resetContentPacks: bindAction(resetContentPacksAction, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackConfirmationModal);
export default ConnectedComponent;
