import { Icon } from "semantic-ui-react";
import { Strings } from "../../../../enums";
import { Tooltip } from "../../../common/tooltip";
import DeleteLinkButton from "../deleteLinkButton/DeleteLinkButton";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface DeleteButtonProps {
  entityName: string;
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  onClick: () => void;
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const { entityName, purchasedSelected, undeletableSelected, onClick } = props;
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const deleteDisabled = purchasedSelected || (!deleteContentEnabled && undeletableSelected);
  const message = purchasedSelected
    ? Strings.tooltipMessages.purchased.operationsDisabled(entityName)
    : Strings.tooltipMessages.deleteDisabled(entityName, ["Flows", "Packs", "Users", "Groups"]);

  return (
    <Tooltip
      showAlways={deleteDisabled}
      inverted
      hideOnScroll
      position="left center"
      target={
        <DeleteLinkButton
          after={deleteDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={deleteDisabled}
          onClick={onClick}
        />
      }
      content={message}
    />
  );
};
