import * as filterActionTypes from "./filterActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { fetchGroupsFilterOptions } from "./fetchGroupsFilterOptions";

export const setGroupFilter = makeActionCreator(filterActionTypes.GROUPS_SET_ITEMS_FILTER, "filter");

export const resetGroupFilter = makeActionCreator(filterActionTypes.GROUPS_RESET_ITEMS_FILTER);

export const fetchFilterOptions = () =>
  fetchGroupsFilterOptions(
    filterActionTypes.GROUPS_FILTER_OPTIONS_FETCH_BEGIN,
    filterActionTypes.GROUPS_FILTER_OPTIONS_FETCH_SUCCESS,
    filterActionTypes.GROUPS_FILTER_OPTIONS_FETCH_FAILURE,
  );
