import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { TextDotDotDot, SearchInput } from "../../components";
import noop from "lodash/noop";

import { default as userListUtils } from "../../utils/userListUtils";
import dateTimeUtils from "../../utils/dateTimeUtils";

import UserInfoCell from "../../components/userInfoCell/UserInfoCell";
import { default as UsersFilterForm } from "../../components/filterForms/UsersFilterForm/UsersFilterForm";
import { Tooltip } from "../../components/common/tooltip";

import ItemsView from "../ItemsView/ItemsView";
import NoSearchResults from "../../components/noSearchResults";
import { SortingDirection, ViewType } from "../../enums";

import "./userList.scss";

const columnOptions = [
  {
    name: "Name",
    width: 6,
    isSortable: true,
  },
  {
    name: "Department",
    width: 4,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 4,
    isSortable: true,
  },
  {
    name: "Added",
    width: 2,
    isSortable: true,
  },
];

const defaultSortingColumnName = "added";

function UserList(props) {
  const {
    users,
    isLoading,
    usersCount,
    filterOptions,
    getFilterOptions,
    appliedFilter,
    applyFilter,
    resetFilter,
    idsOfInitiallySelectedUsers,
    onSelectedUsersChanged,
    fetchUsers,
    setReloadListItems,
    search,
    onSearchChanged,
    isSelectDisabled,
    defaultSortingColumn,
    defaultSortingDirection,
  } = props;

  const getUsers = (skip, top, sortingColumnName, sortingDirection, filter) => {
    const orderParams = userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection);

    fetchUsers(skip, top, orderParams, filter, search);
  };

  const buildTableBody = (user) => (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <UserInfoCell user={user} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Tooltip
          target={<TextDotDotDot useNativeClamp>{user.department}</TextDotDotDot>}
          content={user.department}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip target={<TextDotDotDot useNativeClamp>{user.title}</TextDotDotDot>} content={user.title} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
    </>
  );

  const getFilterForm = () => <UsersFilterForm />;

  const getSearchInput = () => (
    <SearchInput
      placeholder="Search for Users..."
      defaultValue={search}
      onChange={onSearchChanged}
      disabled={!search}
    />
  );

  return (
    <ItemsView
      className="user-selection-list"
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      getData={getUsers}
      itemsInlineCount={usersCount}
      isLoading={isLoading}
      items={users}
      buildTableBody={buildTableBody}
      onSelectedListItemsChanged={onSelectedUsersChanged}
      renderFilterForm={getFilterForm}
      filterOptions={filterOptions}
      filterOptionsLoading={filterOptions.isLoading}
      applyFilter={applyFilter}
      appliedFilter={appliedFilter}
      resetFilter={resetFilter}
      getFilterOptions={getFilterOptions}
      selectedIds={idsOfInitiallySelectedUsers}
      sortingDirection={defaultSortingDirection ?? SortingDirection.Descending}
      sortingColumnName={defaultSortingColumn ?? defaultSortingColumnName}
      renderSearch={getSearchInput}
      setReloadListItems={setReloadListItems}
      noResultsContent={<NoSearchResults />}
      isSelectDisabled={isSelectDisabled}
    />
  );
}

UserList.defaultProps = {
  idsOfInitiallySelectedUsers: [],
  fetchUsers: noop,
  onSelectedUsersChanged: noop,
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      department: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  usersCount: PropTypes.number,
  fetchUsers: PropTypes.func,
  error: PropTypes.string,
  idsOfInitiallySelectedUsers: PropTypes.arrayOf(PropTypes.number),
  onSelectedUsersChanged: PropTypes.func,
  onNavigate: PropTypes.func,
  filterOptions: PropTypes.object,
  getFilterOptions: PropTypes.func,
  appliedFilter: PropTypes.object,
  resetFilter: PropTypes.func,
  applyFilter: PropTypes.func,
  search: PropTypes.string,
  onSearchChanged: PropTypes.func,
  isSelectDisabled: PropTypes.func,
  setReloadListItems: PropTypes.func,
  defaultSortingColumn: PropTypes.string,
  defaultSortingDirection: PropTypes.oneOf([SortingDirection.Ascending, SortingDirection.Descending]),
};

export default UserList;
