import midnightService from "../../../../Application/services/midnightService/midnightService";
import * as videoEntityStateActionTypes from "../actionTypes/videoEntityStateActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../enums/draftEntityTypes";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  VideoAssetPublishSuccess,
  LockVideoAssetSuccess,
  VideoAssetsDiscardSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createDraft = (data, cancellationToken) => {
  const createDraftBegin = makeActionCreator(videoEntityStateActionTypes.CREATE_VIDEO_DRAFT_BEGIN);
  const createDraftSuccess = makeActionCreator(videoEntityStateActionTypes.CREATE_VIDEO_DRAFT_SUCCESS, "entityId");
  const createDraftFailure = makeActionCreator(videoEntityStateActionTypes.CREATE_VIDEO_DRAFT_FAILURE, "error");

  return async (dispatch) => {
    dispatch(createDraftBegin());
    try {
      let result = await midnightService.createLock(draftEntityTypes.Videos, data);
      if (cancellationToken.isCancelRequested) {
        return -1;
      }
      const id = result.data;
      dispatch(createDraftSuccess(id));
      return id;
    } catch (error) {
      dispatch(createDraftFailure(error));
    }
  };
};

export const resetVideoEntityState = makeActionCreator(videoEntityStateActionTypes.RESET_VIDEO_ENTITY_STATE);

export const getEntityLock = (assetId) => {
  const { GET_VIDEO_LOCK_ENTITY_BEGIN, GET_VIDEO_LOCK_ENTITY_FAILURE } = videoEntityStateActionTypes;

  const getEntityLockBegin = makeActionCreator(GET_VIDEO_LOCK_ENTITY_BEGIN);
  const getEntityLockFailure = makeActionCreator(GET_VIDEO_LOCK_ENTITY_FAILURE, "error");

  return async (dispatch) => {
    dispatch(getEntityLockBegin());
    dispatch(beginAsyncOperation({ id: assetId, action: LockVideoAssetSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.Videos, assetId);
    } catch (error) {
      dispatch(getEntityLockFailure(error));
    }
  };
};

export const getEntityLockSuccess = makeActionCreator(
  videoEntityStateActionTypes.GET_VIDEO_LOCK_ENTITY_SUCCESS,
  "entityId",
);

export const draftVideoInfoUpdated = (payload) => ({
  type: videoEntityStateActionTypes.DRAFT_VIDEO_INFO_UPDATED,
  payload: {
    videoAssetId: payload.entityId,
  },
});

export const draftVideoInfoUpdateCompleted = (payload) => ({
  type: videoEntityStateActionTypes.UPDATE_VIDEO_PROPERTIES_SUCCEED,
  payload: {
    entityId: payload.id,
  },
});

export const publishVideoAsset = (id, notificationSettings, notifyTypes) => {
  const { PUBLISH_VIDEO_BEGIN, PUBLISH_VIDEO_FAILURE } = videoEntityStateActionTypes;

  const publishVideoAssetBegin = makeActionCreator(PUBLISH_VIDEO_BEGIN, "entityId");
  const publishVideoAssetFailure = makeActionCreator(PUBLISH_VIDEO_FAILURE, "error");

  return async (dispatch) => {
    dispatch(publishVideoAssetBegin(id));
    dispatch(beginAsyncOperation({ id, action: VideoAssetPublishSuccess }));

    try {
      await midnightService.releaseLock(
        draftEntityTypes.Videos,
        id,
        notificationSettings ? { notificationSettings, notifyTypes } : undefined,
      );
    } catch (error) {
      dispatch(publishVideoAssetFailure(error));
    }
  };
};

export const revertVideoAsset = (id) => {
  const { REVERT_VIDEO_BEGIN, REVERT_VIDEO_FAILURE } = videoEntityStateActionTypes;

  const begin = makeActionCreator(REVERT_VIDEO_BEGIN);
  const publshVideoAssetFailure = makeActionCreator(REVERT_VIDEO_FAILURE, "error");

  return (dispatch) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: VideoAssetsDiscardSuccess }));
    midnightService.discardLock(draftEntityTypes.Videos, id).catch((error) => dispatch(publshVideoAssetFailure(error)));
  };
};

export const draftVideoAssetReverted = (payload) => ({
  type: videoEntityStateActionTypes.REVERT_VIDEO_SUCCESS,
  payload,
});

export const draftVideoAssetPublished = (payload) => ({
  type: videoEntityStateActionTypes.PUBLISH_VIDEO_SUCCESS,
  payload,
});

export const resetUpdateEntityCommandState = makeActionCreator(
  videoEntityStateActionTypes.RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE,
);
