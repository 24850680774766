import * as events from "../events/library/libraryEvents";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";
import {
  fetchLockedMessageSuccess,
  messageUpdated,
  publishDraftMessageSuccess,
  updateMessageCommandSuccess,
} from "../../../../Library/Communications/Messages/state/actions/messageEntityStateActions";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { AlertTypes } from "../../../../../enums";

export const messagesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
    dispatchPublishSuccess,
    dispatchMap,
  } = createDispatcher(dispatcher);

  mapping[events.MessageDeleteSuccess] = dispatchBatchOperationSuccess;
  mapping[events.MessageDeleteFailure] = dispatchBatchOperationFailure;
  mapping[events.MessageEditSuccess] = dispatch(messageUpdated);
  mapping[events.MessageContentEditSuccess] = dispatch(messageUpdated);
  mapping[events.MessageContentEditFailure] = (payload) => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.error,
        message: `Failed to create a send session! ${payload.errorMessage}`,
      }),
    );
  };
  mapping[events.MessageUpdateCommandCompleted] = dispatch(updateMessageCommandSuccess);
  mapping[events.MessagePublishSuccess] = dispatchPublishSuccess<{ id: number; title: string }>(
    publishDraftMessageSuccess,
    (data) => `${data.title} was published!`,
  );
  mapping[events.MessageMadeDraftSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedMessageSuccess,
    (message) => ({ entityId: message.id }),
  );
  mapping[events.CreateMessageSendSessionSuccess] = () => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.success,
        message: "Send session was successfully created!",
      }),
    );
  };
  mapping[events.CreateMessageSendSessionFailure] = (payload) => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.error,
        message: `Failed to create a send session! ${payload.errorMessage}`,
      }),
    );
  };
};
