import React, { useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { noop } from "lodash";

import { type AppDispatch, type RootState } from "../../../../../../../Application/globaltypes/redux";
import { flowDesignerSelector } from "../../../../../state/selectors";
import { bindAction, type IObservable } from "../../../../../../../../interfaces";
import {
  removeExternalTriggerAction,
  updateExternalTriggerGroupsAction,
} from "../../../../state/thunks/flowDesignerExternalTriggersThunk";

import ExternalTriggerCard from "./ExternalTriggerCard/ExternalTriggerCard";
import Observable from "../../../../../../../../utils/Observable";
import StartOfTheFlowCard from "./StartOfTheFlowCard/StartOfTheFlowCard";
import ObjectUtils from "../../../../../../../../utils/objectUtils";
import ExternalTriggerModal from "../ExternalTriggerModal/ExternalTriggerModal";
import { useReactFlowCanvasState } from "../../../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";

export interface StartOfTheFlowCardWrapperProps extends PropsFromRedux {}
export type OnTriggerModalObserver = IObservable<(onConfirm: () => void) => void>;

export const StartOfTheFlowCardWrapper = (props: StartOfTheFlowCardWrapperProps) => {
  const onTriggerModalObserver = useRef<OnTriggerModalObserver>(new Observable());
  const { isReadOnly } = useReactFlowCanvasState();

  const showExternalTriggersModal = () => {
    onTriggerModalObserver.current.notify(noop);
  };

  return (
    <div>
      {ObjectUtils.isNotEmpty(props.externalTriggerGroups) ? (
        <ExternalTriggerCard
          isReadOnly={isReadOnly}
          externalTriggerGroups={props.externalTriggerGroups}
          showExternalTriggersModal={showExternalTriggersModal}
          removeExternalTrigger={props.removeExternalTrigger}
        />
      ) : (
        <StartOfTheFlowCard
          showExternalTriggersModal={showExternalTriggersModal}
          isReadOnly={isReadOnly || props.loading.triggerGroups}
        />
      )}
      <ExternalTriggerModal
        updateExternalTriggerGroups={props.updateExternalTriggerGroups}
        externalTriggerGroups={props.externalTriggerGroups}
        onTriggerModalObserver={onTriggerModalObserver.current}
        isLoading={props.loading.events}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  loading: flowDesignerSelector(state).externalTriggers.loading,
  externalTriggerGroups: flowDesignerSelector(state).externalTriggers.externalTriggerGroups,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateExternalTriggerGroups: bindAction(updateExternalTriggerGroupsAction, dispatch),
  removeExternalTrigger: bindAction(removeExternalTriggerAction, dispatch),
});
/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(StartOfTheFlowCardWrapper);
export default ConnectedComponent;
