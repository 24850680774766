import { BasicLinkButton, RestrictedByTooltip } from "components";
import MultiSelectWithAdditionField from "components/forms/MultiSelectWithAdditionField";
import { Title } from "components/listViewTemplates";
import UserInfoCell from "components/userInfoCell/UserInfoCell";
import { type ColumnOption } from "interfaces/columnOptions";
import dateTimeUtils from "utils/dateTimeUtils";
import { TextDotDotDot } from "components/textTruncators/TextTruncators";
import { renderCustomLabel } from "components/multiSelect/helpers";
import type { RoleUser } from "interfaces/user";
import type { UserRoles } from "./EditAccountUsersList";
import type { NormalizedDropdown } from "utils/miscellaneousUtils";
import environmentConfig from "configuration/environmentConfig";
import type Role from "interfaces/role";

const helpCenterLink = environmentConfig.helpCenterUrl;

export enum Columns {
  Name = "Name",
  Role = "Role",
  Department = "Department",
  JobTitle = "Job title",
  Added = "Added",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  role: "role",
  department: "department",
  added: "added",
  "job title": "jobtitle",
};

export const getColumnOptions = ({
  handleDelete,
  hasPermission,
  selectedIds,
  removeButtonDisabled,
  moboAccountId,
  target,
  isNavigationEnabled,
  defaultRoles,
  handleChangeRole,
  usersWithNewRoles,
}: {
  hasPermission: boolean;
  removeButtonDisabled: boolean;
  selectedIds: number[];
  handleDelete: (id: number) => void;
  moboAccountId?: number;
  target?: string;
  isNavigationEnabled: boolean;
  defaultRoles: Role[];
  usersWithNewRoles: UserRoles[];
  handleChangeRole: (userId: string, value: NormalizedDropdown) => void;
}): ColumnOption<RoleUser>[] => {
  const learnMoreItem = {
    text: "Learn More",
    value: "footer",
    disabled: false,
    className: "sticky-dropdown-footer",
  };

  return [
    {
      name: Columns.Name,
      width: 4,
      isSortable: true,
      className: "user-name-header",
      render: (user) => (
        <UserInfoCell moboAccountId={moboAccountId} target={target} deepLink={isNavigationEnabled} user={user} />
      ),
    },
    {
      name: Columns.Role,
      width: 4,
      isSortable: false,
      render: (user) => {
        const allUserRoleIds = user.assignedRoles.map((role) => role.id.toString()) || [];

        const userDefaultRoleIds = allUserRoleIds.filter((roleId) =>
          defaultRoles.some((dr) => dr.id.toString() === roleId),
        );

        const newRoleIds =
          usersWithNewRoles.find((userWithNewRole) => userWithNewRole.userId == user.id.toString())?.roleIds || [];

        const value = [...userDefaultRoleIds, ...newRoleIds];

        const roleOptions = [
          ...defaultRoles.map((role) => ({
            key: role.id.toString(),
            value: role.id.toString(),
            text: role.name,
            disabled: userDefaultRoleIds.includes(role.id.toString()),
            content: (
              <div>
                <div className="role-name">{role.name}</div>
                {role.description && (
                  <TextDotDotDot clamp={2} className="role-description text-muted">
                    {role.description}
                  </TextDotDotDot>
                )}
              </div>
            ),
          })),
          learnMoreItem,
        ];

        return (
          <MultiSelectWithAdditionField
            options={roleOptions}
            value={value}
            propertyName={user.id.toString()}
            setFieldValue={handleChangeRole}
            renderLabel={renderCustomLabel}
            footerUrl={`${helpCenterLink}/Enterprise/Roles.htm?Highlight=Roles#Default_Roles`}
            placeholder="None"
          />
        );
      },
    },
    {
      name: Columns.Department,
      width: 2,
      isSortable: true,
      render: (user) => <Title title={user.department} />,
    },
    {
      name: Columns.JobTitle,
      width: 2,
      isSortable: true,
      render: (user) => <Title title={user.title} />,
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (user) => dateTimeUtils.formatDate(user.createDate),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      render: (user) => (
        <RestrictedByTooltip hasPermission={hasPermission}>
          <BasicLinkButton
            key={"Remove"}
            text=""
            iconClassName={"fa-trash-alt"}
            onClick={() => handleDelete(user.id)}
            isDisabled={removeButtonDisabled || selectedIds.includes(user.id)}
          />
        </RestrictedByTooltip>
      ),
    },
  ];
};
