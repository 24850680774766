import { type DropdownProps } from "semantic-ui-react";
import MultiStateDropdown, { type MultiStateDropdownOption } from "../multiStateDropdown/MultiStateDropdown";
import { ContentTypesEnum } from "../../../enums";
import { Icons } from "../contentSegmentationDropdown/constants";

const communicationOptions: MultiStateDropdownOption<ContentTypesEnum>[] = [
  {
    label: ContentTypesEnum.Emails,
    iconName: Icons.Emails,
  },
  {
    label: ContentTypesEnum.Messages,
    iconName: Icons.Messages,
  },
];

interface CommunicationSegmentationDropdownProps extends DropdownProps {
  options?: MultiStateDropdownOption<ContentTypesEnum>[];
  defaultValue?: ContentTypesEnum;
}

const getOption = (label: ContentTypesEnum) => ({
  label: label,
  iconName: Icons[label],
});

export default function CommunicationSegmentationDropdown(props: CommunicationSegmentationDropdownProps) {
  return (
    <MultiStateDropdown
      className="communication-segmentation-dropdown"
      options={communicationOptions}
      defaultOption={props.defaultValue ? getOption(props.defaultValue) : communicationOptions[0]}
      {...props}
    />
  );
}
