import * as Yup from "yup";

import { labels, softwareApplications } from "./tagsValidationSchema";

const urlLengths = {
  min: 4,
  max: 512,
};

const titleLengths = {
  min: 3,
  max: 64,
};

const descriptionMaxLength = 256;

const url = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Url is required")
    .min(urlLengths.min, `Url must have at least ${urlLengths.min} characters`)
    .max(urlLengths.max, `Url must be maximum of ${urlLengths.max} characters long`)
    .url("Invalid url");

const title = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Title is required")
    .min(titleLengths.min, `Title must have at least ${titleLengths.min} characters`)
    .max(titleLengths.max, `Title must be maximum of ${titleLengths.max} characters long`);

const description = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .max(descriptionMaxLength, `Description must be maximum of ${descriptionMaxLength} characters long`);

export const linkInfo = Yup.object().shape({
  url: url(),
  title: title(),
  description: description(),
  labels,
  softwareApplications,
});
