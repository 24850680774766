import { VideoCardLayout } from "../../../../../components/cards/VideoCard/VideoCardLayout";
import { type VideoCardBaseProps } from "../../../../../components/cards/VideoCard/types";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import { PublishedStatusTypes } from "../../../../../enums";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { type VideoOverview } from "../../types/models";
import { VideoContentItemMenu } from "../VideoContentItemMenu/VideoContentItemMenu";

import "./videoOverviewCard.scss";

export interface VideoOverviewCardProps extends VideoCardBaseProps<VideoOverview> {
  handleEditClick: (id: number, isDraft: boolean) => () => void;
  handlePublishClick: (
    id: number,
    isDraft: boolean,
    flowsCount: number,
    assignmentsCount: number,
    hasBeenPublished: boolean,
  ) => () => void;
  handleDeleteClick: (id: number[], flowsCount?: number, packsCount?: number) => () => void;
  onToggleVisibility: (id: number[], hidden: boolean) => () => void;
}

export const VideoOverviewCard = (props: VideoOverviewCardProps) => {
  const { item, disabled, selected, onSelect } = props;

  return (
    <VideoCardLayout
      onSelect={onSelect}
      item={item}
      selected={selected}
      disabled={disabled}
      url={item.id.toString()}
      className="video-overview-card no-hover-state"
    >
      <div className="top-row">Added {dateTimeUtils.formatDate(item.dateCreated)}</div>

      <div className="bottom-row">
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item?.isDraft)} />
        <VideoContentItemMenu circle {...props} item={item} isChecked={selected} readonly={disabled || selected} />
      </div>
    </VideoCardLayout>
  );
};
