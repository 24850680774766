import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { getMessageBegin, getMessageSuccess, getMessageFailure } from "../slices/messageDetailsSlice";
import { setDataForDetailsMode } from "../actions/messageEntityStateActions";
import { type AppDispatch } from "features/Application/globaltypes/redux";

export const getMessage = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(getMessageBegin(id));
  try {
    const request = dataService.messages.getMessageWithCancel({ id });
    const result = await request.getDataPromise;
    const message = result.data;
    dispatch(getMessageSuccess(message));
    dispatch(
      setDataForDetailsMode({
        id: message.id,
        isDraft: message.isDraft,
        dateModified: message.dateModified,
        dateCreated: message.dateCreated,
        hasBeenPublished: message.hasBeenPublished,
      }),
    );
  } catch (error: any) {
    dispatch(getMessageFailure(error));
  }
};
