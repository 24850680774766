import React from "react";
import PropTypes from "prop-types";

import propUtils from "../../../utils/propValidationUtils";
import Segment from "./Segment";

import "./segments.scss";

function Segments(props) {
  const { children, to } = props;
  return (
    <div className="segment-control" role="navigation">
      {children
        .filter((child) => child)
        .map((child) => {
          let key = child.props.to || "/";
          return React.cloneElement(child, {
            key: key,
            to: `${to}${child.props.to ? `/${  child.props.to}` : ""}`,
          });
        })}
    </div>
  );
}

Segments.Segment = Segment;

Segments.propTypes = {
  children: propUtils.childrenType(Segment),
  to: PropTypes.string,
};

export default Segments;
