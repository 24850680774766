import axios, { type AxiosResponse } from "axios";
import {
  type Filters,
  serializeArrayAndFilterNullable,
  mapToV2Filters,
  overviewFiltering,
  formatFiltersV2api as formatUserFiltersV2api,
} from "../../../../utils/queryUtils";
import { type IFlowDetails, type IFlowTags, type FlowTagsWithType } from "../types";
import { type GetFlowsRequest, type UpdateGoalPayload } from "../types/requests";
import { v2FilterMap } from "../../../People/types";
import { type ConnectedFlow, type FlowDefinitionData } from "../Designer/types";
import { type GetPagedDataByIdRequestV3, type WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import { escapeTerm } from "../../../../utils/searchUtils";
import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import { type GetPublishedSurveysRequest } from "../../Surveys/types/requests";
import { type FlowOverview, type GoalOptions, type GoalView, type TagsToContent } from "../types/models";
import { type SankeyData } from "../../../../components/charts/types/Sankey";
import { type DistributedOperationResult } from "../../../../types";
import { type BasePerformanceRequestFilterParams } from "../../Common/models";
import { type DateRange, type FlowPeopleDetailsSansId } from "../types/performance";
import {
  type BaseEvent,
  type ExternalTriggerGroupPayload,
  type SourceEvent,
  type SourceType,
} from "../Designer/types/externallTriggersTypes";
import { type Integration, type ReceiveUpdatesTypes } from "../types/flowBase";
import { type FlowGoalFilterParams } from "../state/slices/flowPerformanceSlice";

// to-do: Get rid of old BackendFlowsConfig when it will be fully tested
export interface BackendFlowsConfig {
  params: {
    skip?: number;
    top?: number;
    orderBy?: string;
    filter?: string;
  };
}

export interface BackendFlowsConfigV2 {
  params: {
    skip?: number;
    top?: number;
    sortOrder?: string;
    sortBy?: string;
    term?: string;
    filter?: Filters;
  };
}

export interface ResponseWithCancellation<T> {
  getDataPromise: Promise<AxiosResponse<T[]>>;
  cancel: () => void;
}

let exportAbortController = new AbortController();
let engagementAbortController = new AbortController();
let dateRangeAbortController = new AbortController();
let peopleDetailsAbortController = new AbortController();

const service = {
  getFlowsWithCancel({ filterQueryParams, ...rest }: GetFlowsRequest) {
    const source = axios.CancelToken.source();
    const flowsEndpoint = "/api/v4/flows";
    return {
      promise: axios.get<FlowOverview[]>(flowsEndpoint, {
        params: { ...rest, ...filterQueryParams },
        paramsSerializer: overviewFiltering,
      }),
      cancel: source.cancel,
    };
  },

  getPublishedFlowsByIdsForItemsToDropDesignerAsync(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        ids,
        showPurchased,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get("/api/v2/flows/published", config);
  },

  saveFlowDetails(flowDetails: IFlowDetails) {
    return axios.put(`/api/v2/flows/${flowDetails.id}`, flowDetails);
  },

  saveFlowTagDetails(id: number, flowTags: IFlowTags) {
    return axios.put(`/api/flows/${id}/tags`, flowTags);
  },

  changeReceiveUpdatesType(flowId: number, updateType: ReceiveUpdatesTypes) {
    return axios.put(`/api/flows/${flowId}/update-settings`, { updateType });
  },

  applyFlow(flowId: number) {
    return axios.post(`/api/flows/${flowId}/apply`);
  },

  getFlowDetails(id: number) {
    return axios.get(`/api/v2/flows/${id}`);
  },

  getFlowPublishers() {
    return axios.get("/api/flows/publishers");
  },

  getFilterOptions(tagTypes: string[], showPurchased: boolean = true) {
    const tagTypeArray = tagTypes.map((tagType) => `type=${tagType}`);
    const queryParams = tagTypeArray.join("&");

    return axios.get<FlowTagsWithType[]>(`/api/flows/tags?showPurchased=${showPurchased}&${queryParams}`);
  },

  getFlowUsersV2<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get(`/api/v3/content/flow/${entityId}/users`, config);
  },

  getFlowGroupsV2(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;
    return axios.get(`/api/v3/content/flow/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatUserFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async getFlowUsersToAddV2(flowIds: number[], config: BackendFlowsConfigV2) {
    const { filter, term, ...params } = config.params;
    const response = await axios.get(`/api/v4/content/flow/available-users`, {
      params: {
        ...params,
        ...formatUserFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
        id: flowIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getFlowGroupsToAddV2(flowIds: number[], config: BackendFlowsConfigV2) {
    const { filter, term, ...params } = config.params;
    return axios.get(`/api/v4/content/flow/available-groups`, {
      params: {
        ...params,
        ...formatUserFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
        id: flowIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  deleteFlows(flowIds: number[]) {
    return axios.delete("/api/flows", {
      data: { flowIds: flowIds },
    });
  },

  duplicateFlowsV2(flowIds: number[]) {
    return axios.post("/api/v2/flows/duplicate", {
      flowIds,
    });
  },

  changeVisibility(flowIds: number[], hide: boolean) {
    return axios.put("/api/flows/visibility", {
      flowIds,
      hide,
    });
  },

  startFlow(flowId: number, userIds: number[]) {
    return axios.post<DistributedOperationResult>(`/api/flows/${flowId}/start`, {
      userIds,
    });
  },

  startGroupFlow(flowId: number, groupIds: number[]) {
    return axios.post<DistributedOperationResult>(`/api/flows/${flowId}/group-start`, {
      groupIds,
    });
  },

  updateFlowDesigner(id: number, data: string) {
    return axios.put(`/api/flows/${id}/definition`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  addFlowTags(data: TagsToContent) {
    return axios.post("/api/flows/tags", data);
  },

  getFlowDesigner(flowId: number) {
    return axios.get<FlowDefinitionData>(`/api/v2/flows/${flowId}/definition`);
  },

  getExternalTriggerGroups(flowId: number) {
    return axios.get<ExternalTriggerGroupPayload[]>(`/api/flows/${flowId}/external-triggers`);
  },

  updateExternalTriggerGroups(id: number, externalTriggerGroups: ExternalTriggerGroupPayload[]) {
    return axios.put<ExternalTriggerGroupPayload[]>(`/api/flows/${id}/external-triggers`, externalTriggerGroups);
  },

  getTriggerTypes() {
    return axios.get("/api/flows/definitions/trigger-types");
  },

  getTimeUnits() {
    return axios.get("/api/flows/definitions/time-units");
  },

  getSurveysAsync(request: GetPublishedSurveysRequest, abortController: AbortController) {
    return axios.get("/api/flows/surveys", {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: abortController.signal,
    });
  },

  getFlowChanges(flowId: number) {
    return axios.get(`/api/flows/${flowId}/changes`);
  },

  geIntegrations() {
    return axios.get<Integration[]>("/api/accounts/integrations");
  },

  // Performance tab requests
  getFlowEngagement(flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) {
    engagementAbortController.abort();
    engagementAbortController = new AbortController();
    return axios.get<SankeyData>("/api/reports/v3/flows/progress", {
      params: {
        flowId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: engagementAbortController.signal,
    });
  },

  getDateRanges(flowId: number) {
    dateRangeAbortController.abort();
    dateRangeAbortController = new AbortController();
    return axios.get<DateRange[]>("/api/reports/v1/flows/date-ranges", {
      params: {
        flowId,
      },
      signal: dateRangeAbortController.signal,
    });
  },

  getFlowExport(flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) {
    exportAbortController.abort();
    exportAbortController = new AbortController();

    return axios.get("/api/dataexport/v3/flows/user-progress", {
      responseType: "blob",
      params: {
        flowId,
        ...filterParams,
      },
      signal: exportAbortController.signal,
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getFlowPeopleDetails(flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) {
    peopleDetailsAbortController.abort();
    peopleDetailsAbortController = new AbortController();

    return axios.get<FlowPeopleDetailsSansId[]>("/api/reports/v3/flows/people-details", {
      params: { flowId, ...filterParams },
      paramsSerializer: overviewFiltering,
      signal: peopleDetailsAbortController.signal,
    });
  },

  getFlowGoalLineChart(
    flowId: number,
    filterParams: BasePerformanceRequestFilterParams,
    goalParams: FlowGoalFilterParams,
  ) {
    return axios.get("/api/reports/v2/flows/goals/linechart", {
      params: { flowId, ...filterParams, ...goalParams },
    });
  },
  getFlowGoalTotals(
    flowId: number,
    filterParams: BasePerformanceRequestFilterParams,
    goalParams: FlowGoalFilterParams,
  ) {
    return axios.get("/api/reports/v2/flows/goals/totals", {
      params: { flowId, ...filterParams, ...goalParams },
    });
  },
  getFlowGoalCards(flowId: number, filterParams: BasePerformanceRequestFilterParams, goalParams: FlowGoalFilterParams) {
    return axios.get("/api/reports/v2/flows/goals/cards", {
      params: { flowId, ...filterParams, ...goalParams },
    });
  },

  // End performance requests

  getSourceEvents(sourceType: SourceType) {
    return axios.get<SourceEvent<BaseEvent>[]>(`/api/triggers/sources/${sourceType.toLowerCase()}/events`);
  },

  getConnectedFlows(flowId: number) {
    return axios.get<ConnectedFlow[]>(`/api/flows/${flowId}/connected`);
  },

  // Goals

  getGoals(id: number) {
    return axios.get<GoalView>(`/api/flows/${id}/goals`);
  },

  getGoalOptions() {
    return axios.get<GoalOptions>(`/api/flows/goal-options`);
  },

  updateGoals(payload: UpdateGoalPayload) {
    return axios.put(`/api/flows/${payload.id}/goals`, payload);
  },
};

export default service;
