import { useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { type SortingDirection } from "../../../../../../../enums";
import { Enrollment } from "../../../../../../../enums/groups";
import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { type Filters } from "../../../../../../../utils/queryUtils";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { type PeopleListStepPropsBase } from "../../models";
import { fetchFlowGroupsToAdd } from "../../../../state/thunks/flowGroupsToAddThunk";
import { bindAction } from "../../../../../../../interfaces/redux";

import GroupListStep from "../../../../../../../components/assignmentModals/peopleAssignmentModal/groupListStep/GroupListStep";
import groupListUtils from "../../../../../../../utils/groupListUtils";
import { getCustomTooltipMessage } from "../helpers/tooltipMessageHelper";
import { getEntityData } from "../helpers/entityMapHelper";

export type GroupListStepContainerProps = PropsFromRedux & PeopleListStepPropsBase;

export const GroupListStepContainer = (props: GroupListStepContainerProps) => {
  const { renderModalActions, fetchGroupsToAdd, groupsToAdd, setIds, selectedIds, flowIds } = props;

  const loadGroupsToAddPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: Filters,
  ) => {
    const filter = groupListUtils.formatFilterParams({
      ...appliedFilter,
      isWithAutoEnroll: Enrollment.Manual,
    }).filter;

    const config = {
      params: { skip, top, sortBy: sortingColumnName, sortOrder: sortingDirection, filter },
    };

    fetchGroupsToAdd(flowIds, config);
  };

  const [appliedFilters, setFilters] = useState<FiltersMap>({});

  return (
    <GroupListStep
      renderModalActions={renderModalActions}
      loadPage={loadGroupsToAddPage}
      isLoading={groupsToAdd.isLoading}
      groups={getEntityData(groupsToAdd, flowIds.length)}
      groupsAmount={groupsToAdd.itemsCount}
      onSelectedListItemsChanged={setIds}
      selectedIds={selectedIds}
      applyFilter={setFilters}
      filter={appliedFilters}
      resetFilter={() => setFilters({})}
      getCustomTooltipMessage={getCustomTooltipMessage("group", flowIds.length)}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  groupsToAdd: state.library.flows.edit.items.groupsToAdd,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchGroupsToAdd: bindAction(fetchFlowGroupsToAdd, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(GroupListStepContainer);
export default ConnectedComponent;
