import { type FormikErrors } from "formik";
import { useEffect } from "react";
import ObjectUtils from "../../../../utils/objectUtils";
import { isEqual } from "lodash";

interface ValidationParams<T> {
  errors: FormikErrors<T>;
  resolved: boolean;
  initialized: boolean;
  activeErrors: {
    [key: string]: string;
  };
  isFormValid?: boolean;
}

interface ValidationActions {
  setInitializedAction: (initialized: boolean) => void;
  setActiveErrors: (errors: object) => void;
  setIsValidAction: (isValid: boolean) => void;
  checkForResolvedErrors: (errors: object) => void;
}

export const useFormikValidation = <T>(params: ValidationParams<T>, actions: ValidationActions) => {
  const { resolved, initialized, errors, isFormValid, activeErrors } = params;
  const { setInitializedAction, setActiveErrors, setIsValidAction, checkForResolvedErrors } = actions;
  useEffect(() => {
    return () => {
      setInitializedAction(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!resolved && initialized) {
      setActiveErrors(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved, initialized]);

  useEffect(() => {
    const isEmptyErrors = ObjectUtils.isEmptyDeep(errors);

    if (isEmptyErrors !== isFormValid) {
      setIsValidAction(isEmptyErrors);
      setActiveErrors(errors);
    }

    if (!resolved && !isEqual(activeErrors, errors)) {
      setActiveErrors(errors);
    }

    if (!resolved && initialized) {
      checkForResolvedErrors(errors);
    }

    if (!initialized) {
      setInitializedAction(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
};
