import { connect } from "react-redux";

import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { addExternalEventTags, fetchEventsTags } from "../../state/thunks/eventCommonThunk";
import { eventsTagsSelector } from "../../state/selectors/eventCommonSelector";
import TagsManage from "../../../Common/TagsManage/TagsManage";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: eventsTagsSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchEventsTags, dispatch),
  onConfirmAction: bindAction(addExternalEventTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
