import { type FC, useEffect, useMemo, useState } from "react";

import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { type RegisterBreadcrumbCallback } from "../../../Common/Hooks/usePerformanceBreadcrumbs";
import {
  type PerformanceFilter,
  createDateRange,
  type dailyActivity,
  formattedAverageTime,
  getBarDomain,
  lineChartTitles,
  noBarData,
  noData,
  opensColor,
  startsColor,
  totalActivity,
  validLineData,
} from "../../../Common/utils/performanceUtils";
import { type EventSessionsEntity } from "./types";
import * as eventPerformanceActions from "../../state/thunks/eventPerformanceThunk";

import {
  type IconDefinition,
  faCircleCheck,
  faCircleThreeQuartersStroke,
  faClock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import {
  setIsNavigationEnabled,
  setIsReportEnabled,
  reset as toolbarReset,
} from "features/Reporting/state/toolbar/toolbarSlice";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type ColumnOption } from "interfaces/columnOptions";
import "../../../Common/utils/performanceSCSSUtils.scss";
import EventPerformanceDrilldown from "./Drilldown/EventPerformanceDrilldown";
import { useEventEngagementQuery, useEventLineChartQuery, useEventTableQuery } from "./Queries/queries";
import styles from "./eventPerformanceBody.module.scss";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import { type Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { type ConnectedProps, connect } from "react-redux";
import moment from "moment";
import moboConfigurator from "moboConfigurator";
import { legendLabelFormat } from "./constants";

export type Props = PropsFromRedux & {
  eventId: number;
  eventTitle: string;
  filter: PerformanceFilter;
  isRecurring: boolean;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  flowId?: number;
};

enum EventIcons {
  Completed = "Completed",
  InProgress = "In Progress",
  Pending = "Pending",
}

const EventStatusToIcon: { [key in EventIcons]: IconDefinition } = {
  [EventIcons.Completed]: faCircleCheck,
  [EventIcons.InProgress]: faCircleThreeQuartersStroke,
  [EventIcons.Pending]: faClock,
};

export type EventChartStates = typeof dailyActivity | typeof totalActivity;

const EventPerformanceBody: FC<Props> = ({
  eventId,
  eventTitle,
  filter,
  flowId,
  isRecurring,
  registerBreadcrumb,
  setExportAction,
  actions,
  setIsReportEnabled,
  resetIsReportEnabled,
  setIsNavigationEnabled,
  setHasExportData,
}) => {
  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );

  const [lineChartState, setLineChartState] = useState<EventChartStates>(totalActivity);
  const [selectedSessionEntity, setSelectedSessionEntity] = useState<EventSessionsEntity>();
  const reportEnabled = useFeatureFlag(FeatureFlags.EventReport);
  const recurringEventReportEnabled = useFeatureFlag(FeatureFlags.RecurringEventReport);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const lineChartQuery = useEventLineChartQuery({ filter, eventId, chartPeriod, reportEnabled, flowId });
  const engagementQuery = useEventEngagementQuery({ filter, eventId, reportEnabled, flowId });
  const tableQuery = useEventTableQuery({ filter, eventId, reportEnabled, flowId });

  useEffect(() => {
    setHasExportData(tableQuery.isSuccess && !!tableQuery.data.length);
  }, [setHasExportData, tableQuery.isSuccess, tableQuery.data]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setIsNavigationEnabled(!selectedSessionEntity);
    if (selectedSessionEntity) {
      const fileName = `${eventTitle} ${moment(selectedSessionEntity.SessionDatetime).format("MM-DD-YYYY h-mm A")}`;
      setExportAction({
        method: actions.doDrilldownExport,
        args: [
          eventId,
          selectedSessionEntity.SessionId,
          selectedSessionEntity.SessionDatetime,
          { ...filter, flowId },
          fileName,
        ],
        isExportEnabled: true,
      });
      return;
    }
    setExportAction({
      method: actions.doExport,
      args: [eventId, { ...filter, flowId }, eventTitle],
      isExportEnabled: true,
    });
  }, [
    actions.doExport,
    actions.doDrilldownExport,
    filter,
    eventId,
    setExportAction,
    eventTitle,
    selectedSessionEntity,
    flowId,
    setIsNavigationEnabled,
  ]);

  const lineData = useMemo(() => {
    if (lineChartState !== totalActivity) {
      return {
        yData: [lineChartQuery.data?.lineData.Registered, lineChartQuery.data?.lineData.Attended],
      };
    }
    return {
      yData: [lineChartQuery.data?.lineData.CumulativeRegistered, lineChartQuery.data?.lineData.CumulativeAttended],
    };
  }, [lineChartState, lineChartQuery.data]);

  const renderTopLevel = useMemo(() => {
    const selectSession = (entity: EventSessionsEntity) => {
      setSelectedSessionEntity(entity);
      registerBreadcrumb({
        text: <>{dateTimeUtils.localFormatWithValidation(new Date(entity.SessionDatetime))} </>,
        action: () => {
          setSelectedSessionEntity(undefined);
        },
      });
    };

    const columns: ColumnOption<EventSessionsEntity>[] = [
      {
        name: "Date/Time",
        width: 4,
        isSortable: false,
        render: (entity: EventSessionsEntity) => {
          return (
            <Tooltip
              target={
                <TextTruncate>
                  <a
                    className={"event-sessions-title"}
                    href={moboConfigurator.withMobo("")}
                    onClick={(e) => {
                      e.preventDefault();
                      selectSession(entity);
                    }}
                  >
                    {dateTimeUtils.localFormatWithValidation(new Date(entity.SessionDatetime))}
                  </a>
                </TextTruncate>
              }
              content={dateTimeUtils.localFormatWithValidation(new Date(entity.SessionDatetime))}
            />
          );
        },
      },
      {
        name: "Duration",
        width: 3,
        isSortable: false,
        render: (entity: EventSessionsEntity) =>
          entity.SessionDuration ? formattedAverageTime(Math.round(entity.SessionDuration)) : "-",
      },
      {
        name: "Registrations",
        width: 3,
        isSortable: false,
        render: (entity: EventSessionsEntity) => entity.Registered ?? "-",
      },
      {
        name: "Attended",
        width: 3,
        isSortable: false,
        render: (entity: EventSessionsEntity) => entity.Attended ?? "-",
      },
      {
        name: "Not Attended",
        width: 3,
        isSortable: false,
        render: (entity: EventSessionsEntity) => entity.NotAttended ?? "-",
      },
      {
        name: "Session Status",
        width: 3,
        isSortable: false,
        render: (entity: EventSessionsEntity) => (
          <div className={styles.icon}>
            <FontAwesomeIcon
              icon={EventStatusToIcon[entity.SessionStatus]}
              className={styles[`${EventStatusToIcon[entity.SessionStatus]}`]}
            />
            {entity.SessionStatus}
          </div>
        ),
      },
    ];

    return (
      <div className="performanceBody">
        <div className="graphs">
          <div className="lineChartContainer" ref={measureRef}>
            <ChartWrapper
              titles={lineChartTitles}
              showLegend
              legendLabels={["Registered", "Attended"]}
              legendTooltip={legendLabelFormat}
              onChange={(value) => setLineChartState(value as EventChartStates)}
              colorRange={[startsColor, opensColor]}
            >
              <RequestStatusRenderer state={lineChartQuery.status}>
                {lineChartQuery.isSuccess &&
                validLineData([lineChartQuery.data.lineData.Registered, lineChartQuery.data.lineData.Attended]) ? (
                  <LineChart
                    {...sharedAccountReportingLineProps}
                    {...lineData}
                    xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
                    colorRange={[startsColor, opensColor]}
                    xFormatterFunc={getDateFormatByCount(lineChartQuery.data.lineData.Date.length)}
                  />
                ) : (
                  noData(filter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className={"funnelChartContainer"}>
            <ChartWrapper titles={["Engagement"]}>
              <RequestStatusRenderer state={engagementQuery.status}>
                {engagementQuery.isSuccess && !noBarData(...engagementQuery.data.engagement.map((d) => d.value)) ? (
                  <HorizontalBarChart
                    {...sharedAccountReportingHorizontalBarProps}
                    data={engagementQuery.data.engagement}
                    domain={getBarDomain(engagementQuery.data.engagement)}
                    yAxisTickTooltipFormat={(l) => legendLabelFormat(l as string)}
                  />
                ) : (
                  noData(filter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className={styles.cards}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && <CardReporting items={engagementQuery.data.cards} />}
          </RequestStatusRenderer>
        </div>
        {(!isRecurring || recurringEventReportEnabled) && (
          <div className={styles.table}>
            <h2 className={styles.sessionsHeader}>Event Sessions</h2>
            <RequestStatusRenderer state={tableQuery.status}>
              {tableQuery.isSuccess && (
                <GenericPerformanceList rows={tableQuery.data} columns={columns} filter={filter} />
              )}
            </RequestStatusRenderer>
          </div>
        )}
      </div>
    );
  }, [
    tableQuery.data,
    tableQuery.isSuccess,
    tableQuery.status,
    filter,
    engagementQuery.data?.engagement,
    engagementQuery.isSuccess,
    engagementQuery.status,
    lineChartQuery.data?.lineData.Date,
    lineChartQuery.data?.lineData.Registered,
    lineChartQuery.data?.lineData.Attended,
    lineChartQuery.isSuccess,
    lineChartQuery.status,
    measureRef,
    registerBreadcrumb,
    engagementQuery.data?.cards,
    lineData,
    recurringEventReportEnabled,
    isRecurring,
  ]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return selectedSessionEntity ? (
    <EventPerformanceDrilldown
      eventId={eventId}
      sessionId={selectedSessionEntity.SessionId}
      sessionDatetime={selectedSessionEntity.SessionDatetime}
      filter={filter}
      flowId={flowId}
    />
  ) : (
    renderTopLevel
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setExportAction: bindAction(setExportAction, dispatch),
    actions: bindActionCreators(eventPerformanceActions, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(toolbarReset, dispatch),
    setIsNavigationEnabled: bindAction(setIsNavigationEnabled, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EventPerformanceBody);
export default ConnectedComponent;
