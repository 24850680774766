import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { getPrefix } from "../../../../Application/slices/models";
import { type AccountPackLicense } from "../../../types";

interface EditAccountPackLicenseState {
  accountPackLicense: AccountPackLicense | undefined;
  isLoading: boolean;
  error: Error | undefined;
}

const initialState: EditAccountPackLicenseState = {
  accountPackLicense: undefined,
  isLoading: false,
  error: undefined,
};

export const editAccountPackLicenseSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AccountPacks,
    name: "edit",
  }),
  initialState,
  reducers: {
    req(state: EditAccountPackLicenseState) {
      state.isLoading = true;
    },
    got(state: EditAccountPackLicenseState, action: PayloadAction<AccountPackLicense>) {
      return {
        ...state,
        isLoading: false,
        accountPackLicense: action.payload,
      };
    },
    err(state: EditAccountPackLicenseState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { req, got, err, reset } = editAccountPackLicenseSlice.actions;

export const accountPackLicenseSelector = (state: RootState) => state.accounts.edit.accountPackLicense;

export default editAccountPackLicenseSlice.reducer;

export type EditAccountPackLicenseSliceType = ReturnType<typeof editAccountPackLicenseSlice.reducer>;
