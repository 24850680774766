import * as Yup from "yup";
import awesomeDebouncePromise from "awesome-debounce-promise";
import identityProviderDataService from "../../features/Accounts/services/identityProvidersDataService";
import validationRules from "../validationRules";

const maxNameLength = 512;
const urlLengths = {
  min: 4,
  max: 512,
};
const debounceTimeMs = 300;
export const validIdentityProviders = [
  { text: "Microsoft Entra ID", value: "Microsoft Entra ID" },
  { text: "ADFS", value: "ADFS" },
  { text: "Google or SAML", value: "Google or SAML" },
  { text: "Okta", value: "Okta" },
  { text: "Other", value: "Other" },
] as const;

export const identityMapping: Record<string, { mapTo: string; mapFrom: string }[]> = {
  "Microsoft Entra ID": [
    {
      mapTo: "Email",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    },
    {
      mapTo: "First Name",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
    },
    {
      mapTo: "Last Name",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
    },
    {
      mapTo: "Title",
      mapFrom: "Title",
    },
    {
      mapTo: "Department",
      mapFrom: "Department",
    },
  ],
  ADFS: [
    {
      mapTo: "Email",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    },
    {
      mapTo: "First Name",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
    },
    {
      mapTo: "Last Name",
      mapFrom: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
    },
    {
      mapTo: "Title",
      mapFrom: "http://schemas.microsoft.com/ws/2008/06/identity/claims/title",
    },
    {
      mapTo: "Department",
      mapFrom: "http://schemas.microsoft.com/ws/2008/06/identity/claims/department",
    },
  ],
  "Google or SAML": [
    {
      mapTo: "Email",
      mapFrom: "emailaddress",
    },
    {
      mapTo: "First Name",
      mapFrom: "givenname",
    },
    {
      mapTo: "Last Name",
      mapFrom: "surname",
    },
    {
      mapTo: "Title",
      mapFrom: "title",
    },
    {
      mapTo: "Department",
      mapFrom: "department",
    },
  ],
  Okta: [
    {
      mapTo: "Email",
      mapFrom: "Email",
    },
    {
      mapTo: "First Name",
      mapFrom: "FirstName",
    },
    {
      mapTo: "Last Name",
      mapFrom: "LastName",
    },
    {
      mapTo: "Title",
      mapFrom: "Title",
    },
    {
      mapTo: "Department",
      mapFrom: "Department",
    },
  ],
};

const isProviderNameExists = awesomeDebouncePromise(identityProviderDataService.isProviderNameExists, debounceTimeMs);

const ssoProviderName = (nameInitialValue?: string, moboId?: string) =>
  Yup.string()
    .trim()
    .required("SSO Provider name is required")
    .max(maxNameLength, `SSO Provider name must be maximum of ${maxNameLength} characters long`)
    .test("unique-company-name", "SSO provider name should be unique", async (name) => {
      if (name && name !== nameInitialValue) {
        const isNameExists = await isProviderNameExists(name, moboId);
        return !isNameExists;
      }

      return true;
    });

const identityProvider = (isEdit?: boolean) => {
  const init = Yup.string().oneOf(validIdentityProviders.map((v) => v.text));

  if (!isEdit) {
    return init.required();
  }
  return init;
};

const ssoTypeId = Yup.number().required("Please select SSO type").min(1, "Please select SSO type");

const metadataUrl = Yup.string().when("metadataFile", {
  is: undefined,
  then: () =>
    Yup.string()
      .default("")
      .trim()
      .required("URL is required")
      .min(urlLengths.min, `URL must have at least ${urlLengths.min} characters`)
      .max(urlLengths.max, `URL must be maximum of ${urlLengths.max} characters long`)
      .url("Invalid url"),
});

const metadataFileUpload = Yup.mixed()
  .required("File is required")
  .test("file_size_equals_zero", "File size is equal to zero", validationRules.fileNotEmpty)
  .test("wrong_file_type", "Only XML file type is allowed", validationRules.xmlUploadFileType);

export const multipleMetadataFileUpload = Yup.mixed()
  .test("file_does_not_exist", "File is required", validationRules.fileListLength)
  .test("file_size_equals_zero", "File size is equal to zero", validationRules.fileMinSize)
  .test("wrong_file_type", "Only XML file type is allowed", validationRules.xmlUploadFileTypeMultiple);

export const identityProviderSchema = (nameInitialValue?: string, moboId?: string, isEdit?: boolean) =>
  Yup.object().shape(
    {
      name: ssoProviderName(nameInitialValue, moboId),
      identityProvider: identityProvider(isEdit),
      type: ssoTypeId,
      metadataUrl,
      metadataFile: Yup.mixed().when("metadataUrl", {
        is: undefined,
        then: () => metadataFileUpload,
      }),
      metadataAutoRefresh: Yup.boolean().required(),
    },
    [["metadataUrl", "metadataFile"]],
  );
