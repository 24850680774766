import React from "react";
import { Icon } from "semantic-ui-react";

import "./publishValidationInfo.scss";

function ReadyToPublish() {
  return (
    <div className="publish-validation-info-container">
      <div className="publish-validation-info-success-icon">
        <Icon className="check circle outline error-icon" size="huge" color="green" />
      </div>
      <div>
        <div className="publish-validation-info-header">Ready to publish!</div>
        All errors have been fixed
      </div>
    </div>
  );
}

export default ReadyToPublish;
