import { type FC, useCallback } from "react";
import { Checkbox, type CheckboxProps } from "semantic-ui-react";

interface Props extends CheckboxProps {
  filter: any;
  updateFilter: (newFilter: any) => void;
  propertyName: string;
  noToggle?: boolean;
}

export const FilterCheckbox: FC<Props> = ({ filter, updateFilter, propertyName, className, noToggle }) => {
  const checked = !!filter[propertyName];

  const handleFilterChange = useCallback(
    (_: unknown, { checked }: CheckboxProps) => {
      let newFilter = { ...filter };
      if (checked) {
        newFilter[propertyName] = checked;
      } else {
        delete newFilter[propertyName];
      }

      updateFilter(newFilter);
    },
    [filter, updateFilter, propertyName],
  );

  return <Checkbox className={className} onChange={handleFilterChange} checked={checked} toggle={!noToggle} />;
};
