import React, { type FC } from "react";
import { connect } from "react-redux";

import { type RootState } from "../../../../../../features/Application/globaltypes/redux";
import { pluralize } from "../../../../../../utils/stringUtils";
import { AddPeopleToContentTypes, type AssignmentPeopleContext } from "../../../../../../enums";

import PublishStatusCard from "../../../../../cards/PublishStatusCard";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import cn from "classnames";
import { type AvailableItemBase, type WithPeopleContext } from "../../../../../../features/People/ContentAssignments/models";

import "./addToItemCard.scss";

export interface AddToItemCardProps {
  item: WithPeopleContext<AvailableItemBase>;
  selected: boolean;
  disabled: boolean;
  onSelect: (id: number) => void;
  selectedPeopleType: AssignmentPeopleContext;
  selectedContentType: AddPeopleToContentTypes;
}

const peopleContentToContentLabelMap = {
  [AddPeopleToContentTypes.Flows]: "flow",
  [AddPeopleToContentTypes.Videos]: "video",
  [AddPeopleToContentTypes.Events]: "event",
  [AddPeopleToContentTypes.Surveys]: "survey",
  [AddPeopleToContentTypes.Assessments]: "assessment",
  [AddPeopleToContentTypes.PDFs]: "PDF",
};

export const AddToItemCard: FC<AddToItemCardProps> = (props) => {
  const { selectedPeopleType, selectedContentType, item, onSelect, selected, disabled } = props;

  const { assignsCount, usersCount, dateCreated, isDraft } = item;

  const someAssigned = assignsCount > 0;
  const allAssigned = someAssigned && assignsCount === usersCount;

  const getIcons = () => {
    if (!someAssigned) {
      return [];
    }
    const belong = usersCount > 1 ? "belong" : "belongs";
    const generateTooltipMessage = () =>
      allAssigned
        ? `This ${pluralize(selectedPeopleType, usersCount)} already ${belong} to this ${
            peopleContentToContentLabelMap[selectedContentType]
          }.`
        : `(${assignsCount}) of the ${pluralize(selectedPeopleType, usersCount)} selected already belong to this ${
            peopleContentToContentLabelMap[selectedContentType]
          } and will be skipped.`;

    return [
      {
        iconClassName: "fa fa-exclamation-circle",
        tooltipContent: generateTooltipMessage(),
        tooltipClassName: "warning",
        tooltipPosition: "top center",
      },
    ];
  };

  return (
    <PublishStatusCard
      {...item}
      className={cn("item-card", { warning: someAssigned })}
      published={!isDraft}
      onSelectionChanged={onSelect}
      disabled={allAssigned || disabled}
      icons={getIcons()}
      selected={selected}
    >
      Added {dateTimeUtils.formatDate(dateCreated)}
    </PublishStatusCard>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  selectedPeopleType: state.people.addToContentModal.selectedPeopleType,
  selectedContentType: state.people.addToContentModal.selectedContentType,
});

export default connect(mapStateToProps)(AddToItemCard);
