import * as Yup from "yup";
import { isEmpty, uniq } from "lodash";
import { type FileLike } from "../interfaces";
import fileUtils from "./fileUtils";

const hasExtension = (file: FileLike, extension: string) => file?.name.split(".").pop() === extension;

const isFileListDefined = (value: any) => !!(value && value.length);

const isMp4MovAviFileType = (file: File) =>
  file.type === "video/mp4" || file.type === "video/quicktime" || file.type === "video/avi";

const isMp4FileType = (file: File) => file.type === "video/mp4";

const isMp4OrM4vFileType = (file: File) => file.type === "video/mp4" || file.type === "video/m4v";

const isCsvFileType = (file: File) => file.type === "text/csv";

const validateAllFilesInList = (value: any, validator: any) =>
  isFileListDefined(value) && Array.from(value).every(validator);

const validateAnyFileInList = (value: any, validator: any) =>
  isFileListDefined(value) && Array.from(value).some(validator);

const xmlUploadFileType = (file: any) => hasExtension(file, "xml");

const htmlWithoutDomains = (domainsValidator: (value: any) => boolean): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Body is required")
    .test("body_restricted_domains", "Body contains restricted domain(s)", domainsValidator);

/* istanbul ignore next */
const validateImageFileDimensionsAsync = async (
  imageFile: File,
  maxWidth: number,
  maxHeight: number,
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    fileUtils
      .readFileAsDataUrl(imageFile)
      .then((file) => {
        let img = new Image();
        img.onload = () => {
          const isDimensionsValid = img.width <= maxWidth && img.height <= maxHeight;
          resolve(isDimensionsValid);
        };
        img.src = file as string;
      })
      .catch(() => {
        resolve(false);
      });
  });
};

const rules = {
  fileMaxSize(value: any) {
    const maxFileSize = 4294967296;
    return validateAllFilesInList(value, (file: File) => file.size <= maxFileSize);
  },
  imageFileMaxSize(value: any) {
    const maxFileSize = 5242880;
    return validateAllFilesInList(value, (file: File) => file.size <= maxFileSize);
  },
  teamsImageFileMaxSize(value: any) {
    const maxFileSize = 1048576;
    return validateAllFilesInList(value, (file: File) => file.size <= maxFileSize);
  },
  /* istanbul ignore next */
  async teamsImageFileDimensions(value: any) {
    if (!isFileListDefined(value)) {
      return false;
    }

    const imageFile = value[0] as File;
    const maxWidth = 1024;
    const maxHeight = 1024;
    return await validateImageFileDimensionsAsync(imageFile, maxWidth, maxHeight);
  },
  closedCaptionsFileMaxSize(value: any) {
    const maxFileSize = 1048576;
    return validateAllFilesInList(value, (file: File) => file.size <= maxFileSize);
  },
  pdfFileMaxSize(value: any) {
    const maxFileSize = 31457280;
    return validateAllFilesInList(value, (file: File) => file.size <= maxFileSize);
  },
  fileMinSize(value: any) {
    return validateAllFilesInList(value, (file: File) => file.size !== 0);
  },
  fileNotEmpty(file: any) {
    return file?.size > 0;
  },
  mp4FileType(value: any) {
    return validateAllFilesInList(value, isMp4FileType);
  },
  mp4MovAviFileType(value: any) {
    return validateAllFilesInList(value, isMp4MovAviFileType);
  },
  mp4m4vFileTypes(value: any) {
    return validateAllFilesInList(value, isMp4OrM4vFileType);
  },
  csvFileType(value: any) {
    return validateAllFilesInList(value, isCsvFileType);
  },
  anyMp4FileType(value: any) {
    return validateAnyFileInList(value, isMp4MovAviFileType);
  },
  imageFileType(value: any) {
    const imageType = /image\/*/;
    return validateAllFilesInList(value, (file: File) => imageType.test(file.type)); // NOSONAR
  },
  fileListLength(value: any) {
    return isFileListDefined(value) && value.length >= 1;
  },
  fileNameSize(value: any) {
    const maxFileNameLength = 120;
    return validateAllFilesInList(value, (file: File) => file.name.length <= maxFileNameLength);
  },
  maxLabelLength: 128,
  getMultiSelectItemsValidator(maxLength: any) {
    return (value: any) => value.selected.every((item: any) => item.length <= maxLength);
  },

  getMultiSelectValuesValidator(maxLength: any) {
    return (value: any) => value.every((item: any) => item.length <= maxLength);
  },

  passwordUniqueCharacters(password: any) {
    const minUniqueCharactersAmount = 3;
    return isEmpty(password) || uniq(password).length >= minUniqueCharactersAmount;
  },
  usersUploadFileType(value: any) {
    return validateAllFilesInList(value, (file: FileLike) => hasExtension(file, "csv"));
  },
  closedCaptionsUploadFileType(value: any) {
    return validateAllFilesInList(value, (file: FileLike) => hasExtension(file, "vtt"));
  },
  pdfUploadFileType(value: any) {
    return validateAllFilesInList(value, (file: FileLike) => hasExtension(file, "pdf"));
  },
  xmlUploadFileType,
  xmlUploadFileTypeMultiple(value: any | FileList | FileLike[]) {
    return validateAllFilesInList(value, xmlUploadFileType);
  },
  hasNoBrainstormUrls(value: any) {
    return isEmpty(value) || !value.match("(brainstorm|quickhelp|brainstorminc)(\\.com)");
  },
  htmlWithoutBraistormDomains() {
    return htmlWithoutDomains(this.hasNoBrainstormUrls);
  },
};

export default rules;
