import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { getRootEntityActionTypeBuilder } from "../../../../../Application/actions/actionsBuilder";
import * as actionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

const messageActionsBuilder = getRootEntityActionTypeBuilder(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Messages,
);

export const fetchMessageBegin = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_BEGIN);
export const fetchMessageSuccess = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_SUCCESS);
export const fetchMessageFailure = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_FAILURE);

export const createMessageBegin = messageActionsBuilder(actionTypes.CREATE_DRAFT_ENTITY_BEGIN);
export const createMessageSuccess = messageActionsBuilder(actionTypes.CREATE_DRAFT_ENTITY_SUCCESS);
export const createMessageFailure = messageActionsBuilder(actionTypes.CREATE_DRAFT_ENTITY_FAILURE);

export const updateMessageBegin = messageActionsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN);
export const updateMessageCommandSuccess = messageActionsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS);
export const updateMessageFailure = messageActionsBuilder(actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE);

export const messageUpdated = messageActionsBuilder(actionTypes.LOCKED_ENTITY_UPDATED);
export const resetMessageEntityState = messageActionsBuilder(actionTypes.RESET_ENTITY_STATE);

export const fetchLockedMessageBegin = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_BEGIN);
export const fetchLockedMessageSuccess = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_SUCCESS);
export const fetchLockedMessageFailure = messageActionsBuilder(actionTypes.FETCH_LOCKED_ENTITY_FAILURE);

export const publishMessageBegin = messageActionsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);
export const publishMessageSuccess = messageActionsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS);
export const publishMessageFailure = messageActionsBuilder(actionTypes.PUBLISH_LOCKED_ENTITY_FAILURE);

export const revertMessageBegin = messageActionsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN);
export const revertMessageFailure = messageActionsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE);
export const revertMessageSuccess = messageActionsBuilder(actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS);

export const setMessageEntityId = messageActionsBuilder(actionTypes.SET_ENTITY_ID);

export const setDataForDetailsMode = messageActionsBuilder(actionTypes.SET_DATA_FOR_DETAILS_MODE);
