import type { AlignedWith } from "../../types";
import { ELEMENT_HALF_WIDTH, ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../../constants";
import type { XYCoord } from "react-dnd";
import type { Viewport } from "@reactflow/core";

interface IStyleOptions {
  nodePosition?: XYCoord;
  containerBounds?: XYCoord;
  viewport: Viewport;
  shift: number;
}

const getDelta = (axis: "x" | "y"): number => (axis === "x" ? ELEMENT_HEIGHT : ELEMENT_WIDTH);

export const getBaseStyles = (alignedOptions: AlignedWith | null, axis: "x" | "y", delta: number) => {
  if (!alignedOptions) {
    return {};
  }

  return alignedOptions.distance > 0
    ? {
        [axis === "x" ? "height" : "width"]: `${alignedOptions.distance + delta}px`,
        [axis === "x" ? "top" : "left"]: "0px",
      }
    : {
        [axis === "x" ? "height" : "width"]: `${-alignedOptions.distance + delta}px`,
        [axis === "x" ? "top" : "left"]: `${alignedOptions.distance}px`,
      };
};

export const getDeltaByAxis = (alignedOptions: AlignedWith | null, axis: "x" | "y", options: IStyleOptions): number => {
  if (!alignedOptions) {
    return 0;
  }

  if (axis === "x") {
    return options.shift > ELEMENT_HALF_WIDTH && alignedOptions.distance > 0 ? 10 : 0;
  } else {
    return options.shift === 0 && alignedOptions.distance < 0 ? 10 : 0;
  }
};

export const getStyles = (alignedOptions: AlignedWith | null, axis: "x" | "y", options: IStyleOptions) => {
  if (!alignedOptions) {
    return {};
  }

  const delta = getDelta(axis);
  const deltaByAxis = getDeltaByAxis(alignedOptions, axis, options);
  const styles = getBaseStyles(alignedOptions, axis, delta);

  if (options.nodePosition) {
    const { x, y } = options.nodePosition;
    const { x: containerBoundX, y: containerBoundY } = options.containerBounds || { x: 0, y: 0 };
    const { x: transformX, y: transformY, zoom: scale } = options.viewport;
    const distance = Math.abs(alignedOptions.distance);

    if (axis === "x") {
      styles.height = `${(distance + delta - deltaByAxis) * scale}px`;
      styles.left = `${(x + options.shift) * scale + transformX + containerBoundX}px`;
      styles.top = `${
        (y + deltaByAxis - (alignedOptions.distance < 0 ? -alignedOptions.distance : 0)) * scale +
        transformY +
        containerBoundY
      }px`;
    }

    if (axis === "y") {
      styles.width = `${(distance + delta - deltaByAxis) * scale}px`;
      styles.top = `${(y + options.shift) * scale + transformY + containerBoundY}px`;
      styles.left = `${
        (x - (alignedOptions.distance < 0 ? -alignedOptions.distance : 0)) * scale + transformX + containerBoundX
      }px`;
    }
  }

  return styles;
};
