import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import cn from "classnames";

import {
  baseEnterTransition,
  baseUpdateTransition,
  roundedEnterTransition,
  roundedUpdateTransition,
} from "./helpers/horizontalBarHelpers";

import { type DataPoint, type HorizontalFuncs, type NodeType, type Props } from "../types/HorizontalBarChart";
import { DEFAULT_MARGIN } from "../shared/constants";
import { useDimensions } from "../../../hooks/useDimensions";
import { useChartResizeTransition } from "../hooks/useChartResizeTransition";
import { APPEND_ONCE, drawCenteringGroup, getTranslateString, isLogScale, textEllipsis } from "../shared/helpers";
import { HorizBarTooltip, type HorizontalBarTooltipRef } from "./Tooltip/HorizontalBarTooltip";
import { ChartPopup } from "../shared/ChartPopup/ChartPopup";
import { widthAndHeightInvalid } from "../utils/utils";
import { getTicksFromValueTypes } from "../shared/public";

import "./horizontalBarChart.scss";

function getBrushExtent(extent: [[number, number], [number, number]]): [number, number] {
  return [extent[0][1], extent[1][1]];
}

const MAX_SCROLL_DISTANCE = 3;
const LEFT_TICK_OFFSET = 50; // How far the tick sits from the bar
const LEFT_TICK_PADDING = 25; // How much space from the left side of the chart should the ellipsis go
const BRUSH_SIZE = 10;
const ICON_LEFT_OFFSET = -35;
const ICON_SIZE = 16;
const DEFAULT_FILL = "#686569";
const DEFAULT_FORMATTER = (data: DataPoint) => `${data.category}: ${data.value}`;

function GET_Y_TICK_WITHOUT_INDEX(d: string) {
  let splitWords = d.split(" ");
  let allExceptIndex = splitWords.slice(0, splitWords.length - 1).join(" ");
  return allExceptIndex;
}

const getEllipsisPadding = (excludeIcons: boolean) => (excludeIcons ? 10 : LEFT_TICK_PADDING);

export const HorizontalBarChart = forwardRef<HorizontalFuncs, Props>(
  (
    {
      margins = { ...DEFAULT_MARGIN, left: 200 },
      domain = [0, 100],
      data,
      barPadding = 0.6,
      barRounded = true,
      valueScale = d3.scaleLinear,
      transitionDurationInMs = 750,
      tooltipFormatter = DEFAULT_FORMATTER,
      defaultBarColor = DEFAULT_FILL,
      xAxisTickFormat = (d) => d?.toLocaleString() || (d as string),
      yAxisTickFormat = (d) => d?.toLocaleString() || (d as string),
      yAxisTickTooltipFormat,
      onClick,
      countBarsToShowAtOnce,
      excludeIcons = false,
      xTickValueType,
    },
    ref,
  ) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const formatterRef = useRef(tooltipFormatter);
    const tooltipRef = useRef<HorizontalBarTooltipRef>(null);
    const onClickRef = useRef(onClick);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipText, setTooltipText] = useState("");
    const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
    const [tooltipColor, setTooltipColor] = useState(defaultBarColor);
    const [selectedAnswerIndex, setSelectedAnswer] = useState<number | null>(null);

    const [measurements, measuredRef] = useDimensions();
    const initializedBrush = useRef(false);
    const [transitionDurationRef, resizeRef] = useChartResizeTransition(transitionDurationInMs);

    const [currentZoomDomain, setCurrentZoomDomain] = useState([0, 0]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
    const [popupText, setPopupText] = useState("");

    const [shouldScrollToNewPosition, setShouldScrollToNewPosition] = useState(false);

    useImperativeHandle<unknown, HorizontalFuncs>(
      ref,
      () => ({
        setSelectedAnswerIndex(index) {
          setSelectedAnswer(index);
          setShouldScrollToNewPosition(true);
        },
        clearSelectedAnswerIndex() {
          setSelectedAnswer(null);
        },
      }),
      [],
    );

    useEffect(() => {
      onClickRef.current = onClick;
    }, [onClick]);

    useEffect(() => {
      formatterRef.current = tooltipFormatter;
    }, [tooltipFormatter]);

    const { width, height, margin } = useMemo(() => {
      return {
        margin: { ...margins },
        width: measurements?.width ?? 0,
        height: measurements?.height ?? 0,
      };
    }, [measurements, margins]);

    const innerWidth = useMemo(() => width - margin.left - margin.right, [width, margin.left, margin.right]);
    const innerHeight = useMemo(() => height - margin.top - margin.bottom, [height, margin.top, margin.bottom]);

    const { scrollSize, shouldScroll } = useMemo(() => {
      const calculation = countBarsToShowAtOnce ? (countBarsToShowAtOnce / data.length) * innerHeight : 0;
      return {
        scrollSize: calculation,
        shouldScroll: countBarsToShowAtOnce && calculation < innerHeight,
      };
    }, [countBarsToShowAtOnce, data.length, innerHeight]);

    // Used because yScale would normally put the first
    // item in the list at the bottom, when you'd expect
    // it to be on top.
    const reverseData = useMemo(() => data.slice().reverse(), [data]);

    // Resetting brush size in certain situations
    // ie when something affecting the size of the y-axis would change
    useEffect(() => {
      initializedBrush.current = false;
    }, [reverseData, innerHeight, countBarsToShowAtOnce]);

    const xScale = useMemo(() => {
      const minDomain = isLogScale(valueScale) ? 1 : domain[0];
      return valueScale().domain([minDomain, domain[1]]).range([0, innerWidth]).nice();
    }, [valueScale, innerWidth, domain]);

    const mainYZoomScale = useMemo(
      () => d3.scaleLinear().domain(currentZoomDomain).range([innerHeight, 0]),
      [innerHeight, currentZoomDomain],
    );

    const yScale = useMemo(
      () =>
        d3
          .scaleBand()
          .domain(reverseData.map((d, i) => `${d.category} ${i}`))
          .range(
            shouldScroll
              ? [mainYZoomScale(mainYZoomScale.range()[0])!, mainYZoomScale(mainYZoomScale.range()[1])!]
              : [innerHeight, 0],
          )
          .padding(barPadding),
      [reverseData, shouldScroll, mainYZoomScale, innerHeight, barPadding],
    );

    const xAxis = useMemo(() => {
      const ticks = getTicksFromValueTypes(xScale, xTickValueType, 5);
      return d3
        .axisBottom(xScale)
        .ticks(5)
        .tickSizeInner(-innerHeight)
        .tickSizeOuter(0)
        .tickValues(ticks)
        .tickFormat(xAxisTickFormat);
    }, [xScale, innerHeight, xAxisTickFormat, xTickValueType]);
    const yAxis = useMemo(
      () =>
        d3
          .axisLeft(yScale)
          .tickPadding(excludeIcons ? LEFT_TICK_PADDING : LEFT_TICK_OFFSET)
          .tickSize(0)
          .tickFormat((d, i) => yAxisTickFormat(GET_Y_TICK_WITHOUT_INDEX(d), i)),
      [excludeIcons, yScale, yAxisTickFormat],
    );

    const brushMove = useCallback(
      function (this: SVGGElement) {
        let extent = d3.brushSelection(this);
        if (!extent) return;

        const newDomain = [
          // since it is just a brushY, extent is not a 2D array
          (extent[1] as number) - margin.top,
          (extent[0] as number) - margin.top,
        ];
        setCurrentZoomDomain(newDomain);
        mainYZoomScale.domain(newDomain);
        const zoomRange = mainYZoomScale.range();
        yScale.range([mainYZoomScale(zoomRange[0])!, mainYZoomScale(zoomRange[1])!]);
      },
      [margin.top, mainYZoomScale, yScale],
    );

    const brushExtent = useMemo<[[number, number], [number, number]]>(
      () => [
        [0, margin.top],
        [BRUSH_SIZE, height - margin.bottom],
      ],
      [height, margin.bottom, margin.top],
    );

    const brush = useMemo(() => {
      return d3.brushY<boolean>().extent(brushExtent).on("brush", brushMove);
    }, [brushMove, brushExtent]);

    // Used for computing the brush position when auto-scrolling from
    // dropdown selection (this is the same size as the brush extent)
    const constantZoomScale = useMemo(
      () =>
        d3
          .scaleBand()
          .domain(reverseData.map((d) => d.category))
          .range(getBrushExtent(brushExtent))
          .padding(barPadding),
      [barPadding, brushExtent, reverseData],
    );

    const totalValue = useMemo(() => reverseData.reduce((prev, curr) => prev + curr.value, 0), [reverseData]);

    const xAxisMouseOver = useCallback(
      (_: string, index: number, nodes: ArrayLike<SVGTextElement>) => {
        // Is there ellipsis? If no, no need to show tooltip
        if (nodes[index].textContent?.endsWith("\u2026") === false) {
          setPopupOpen(false);
          return;
        }
        const tooltipLeftOffset = -3.5; // absolutely a magic number here
        const tick = xAxis.tickValues()![index];
        setPopupText(xAxisTickFormat(tick, index));
        const { top, left, width: textWidth } = nodes[index].getBoundingClientRect();
        setPopupPosition({
          top: top - 5,
          left: left + textWidth / 2 - tooltipLeftOffset,
        });
        setPopupOpen(true);
      },
      [xAxis, xAxisTickFormat],
    );

    const axisMouseOver = useCallback(
      (hoveredText: string, index: number, nodes: ArrayLike<SVGTextElement>) => {
        const tooltipTopOffset = -5;
        setPopupText(
          yAxisTickTooltipFormat
            ? yAxisTickTooltipFormat(GET_Y_TICK_WITHOUT_INDEX(hoveredText), index)
            : yAxisTickFormat(GET_Y_TICK_WITHOUT_INDEX(hoveredText), index),
        );
        const { top, left, width: textWidth } = nodes[index].getBoundingClientRect();
        setPopupPosition({
          top: top + tooltipTopOffset,
          left: left + textWidth / 2,
        });
        setPopupOpen(true);
      },
      [yAxisTickFormat, yAxisTickTooltipFormat],
    );

    const axisMouseLeave = useCallback(() => {
      setPopupOpen(false);
    }, []);

    // Added for scrolling
    const zoomBehavior = useMemo(() => d3.zoom<SVGSVGElement, unknown>().on("zoom", null), []);

    const scroll = useCallback(() => {
      if (!shouldScroll) return;
      const e = d3.event as WheelEvent;
      e.stopPropagation();
      e.preventDefault();

      let extent: [number, number] = [mainYZoomScale.domain()[1], mainYZoomScale.domain()[0]];

      // clamp dy between -scrolldistance and +scrolldistance
      let dy = Math.min(MAX_SCROLL_DISTANCE, Math.max(-MAX_SCROLL_DISTANCE, -e.deltaY));

      let topSection: number;
      if (extent[0] - dy < 0) {
        topSection = 0;
      } else if (extent[1] - dy > innerHeight) {
        topSection = innerHeight - scrollSize;
      } else {
        topSection = extent[0] - dy;
      }
      topSection += margin.top;

      brush.move(d3.select<SVGSVGElement, boolean>(svgRef.current!).select<SVGGElement>(".brushGroup"), [
        topSection,
        topSection + scrollSize,
      ]);
    }, [shouldScroll, scrollSize, margin.top, mainYZoomScale, innerHeight, brush]);

    const preflightChecksFailed = useMemo<boolean>(
      () => widthAndHeightInvalid(innerWidth, innerHeight),
      [innerHeight, innerWidth],
    );

    // Draw the brush
    useEffect(() => {
      if (preflightChecksFailed) return;
      if (!shouldScroll) {
        d3.select(".brushGroup").remove();
        return;
      }
      const svg = d3.select(svgRef.current!);

      const brushGroup = svg
        .selectAll<SVGGElement, boolean>(".brushGroup")
        .data(APPEND_ONCE)
        .join(
          (enter) =>
            enter
              .append("g")
              .attr("class", "brushGroup")
              .attr("transform", getTranslateString(width - BRUSH_SIZE, 0)),
          (update) => update.attr("transform", getTranslateString(width - BRUSH_SIZE, 0)),
        );

      brushGroup.call(brush);
      brushGroup
        .select(".overlay")
        .attr("rx", BRUSH_SIZE / 2)
        .attr("ry", BRUSH_SIZE / 2)
        .attr("cursor", "default")
        .attr("pointer-events", "none")
        // For test queries
        .attr("data-testid", "chart scrollbar");
      brushGroup
        .select(".selection")
        .attr("rx", BRUSH_SIZE / 2)
        .attr("ry", BRUSH_SIZE / 2)
        .attr("cursor", "default")
        .attr("pointer-events", "none");
      brushGroup.selectAll(".handle").remove();
      if (!initializedBrush.current) {
        initializedBrush.current = true;

        brush.move(brushGroup, [
          margin.top, // first element
          margin.top + scrollSize,
        ]);
      }
    }, [brush, margin.top, preflightChecksFailed, scrollSize, shouldScroll, width]);

    // Draw the chart
    useEffect(() => {
      if (preflightChecksFailed) return;

      const xAxisTextEllipsis = (_data: unknown, index: number, elements: ArrayLike<SVGTextElement>) => {
        textEllipsis(elements[index], 15, -8, [elements[index - 1], elements[index], elements[index + 1]]);
      };

      // Function definition to help us later
      const getYValueForRect = (d: DataPoint, index: number) => yScale(`${d.category} ${index}`)!;
      function yTextTween(this: SVGGElement) {
        let _this = this as unknown as SVGGElement;
        return function () {
          const padding = getEllipsisPadding(excludeIcons);
          d3.select(_this)
            .selectAll<SVGTextElement, string>("text")
            .each((_, i1, n1) => textEllipsis(n1[i1], margin.left - padding, padding));
        };
      }

      const svg = d3.select(svgRef.current!);

      svg.call(zoomBehavior).on("zoom", null).on("wheel.zoom", scroll);

      const xAxisGroup = svg
        .selectAll<SVGGElement, boolean[]>(".x-axis-group")
        .data(APPEND_ONCE)
        .join(
          (enter) => {
            enter
              .append("g")
              .attr("class", "x-axis-group")
              // normally 0, innerHeight
              .attr("transform", getTranslateString(margin.left, innerHeight + margin.top))
              .call(xAxis)
              .transition()
              .duration(transitionDurationRef.current)
              .tween("x_text", function () {
                // @ts-ignore
                const innerText = d3.select(this).selectAll<SVGTextElement, unknown>("text");

                return function () {
                  innerText.each(xAxisTextEllipsis);
                };
              });

            return enter;
          },
          (update) => {
            // Only do transitions if not scrollable
            let conditionalUpdate = !shouldScroll
              ? update
                  .transition()
                  .duration(transitionDurationRef.current)
                  .tween("x_text", function () {
                    const innerText = d3.select(this).selectAll<SVGTextElement, unknown>("text");

                    return function () {
                      innerText.each(xAxisTextEllipsis);
                    };
                  })
              : update;

            conditionalUpdate.attr("transform", getTranslateString(margin.left, innerHeight + margin.top)).call(xAxis);
            return update;
          },
        );

      const yAxisGroup = svg
        .selectAll<SVGGElement, boolean[]>(".y-axis-group")
        .data(APPEND_ONCE)
        .join(
          (enter) => {
            enter
              .append("g")
              .attr("class", "y-axis-group")
              .attr("transform", getTranslateString(margin.left, margin.top))
              .attr("clip-path", "url(#clip)")
              .style("clip-path", "url(#clip)")
              .call(yAxis)
              // Ensures text measurement is correct after mounting initially
              .transition()
              .duration(transitionDurationRef.current)
              .tween("text", yTextTween);
            return enter;
          },
          (update) => {
            // Only transition if not scrollable
            if (!shouldScroll) {
              update
                .transition()
                .duration(transitionDurationRef.current)
                .attr("transform", getTranslateString(margin.left, margin.top))
                .tween("text", yTextTween)
                .call(yAxis);
            } else {
              update.attr("transform", getTranslateString(margin.left, margin.top)).call(yAxis);
            }
            return update;
          },
        );

      // Remove line above x-axis
      xAxisGroup.selectAll(".domain").remove();
      xAxisGroup.selectAll("line").attr("class", "axisLine");
      const xAxisText = xAxisGroup
        .selectAll<SVGTextElement, string>("text")
        .attr("class", "axisText")
        .on("mouseover", xAxisMouseOver)
        .on("mouseleave", axisMouseLeave);

      yAxisGroup.selectAll(".domain").remove();
      yAxisGroup.selectAll("line").attr("class", "axisLine");
      const axisText = yAxisGroup
        .selectAll<SVGTextElement, string>("text")
        .attr("class", "axisText")
        .each((_, i, n) => {
          const padding = getEllipsisPadding(excludeIcons);
          textEllipsis(n[i], margin.left - padding, padding);
        })
        .on("mouseover", axisMouseOver)
        .on("mouseleave", axisMouseLeave);

      const mainArea = drawCenteringGroup(svg, margin.left, margin.top, transitionDurationRef.current);

      mainArea.attr("clip-path", "url(#clip)").style("clip-path", "url(#clip)");

      const iconYPosition = (d: DataPoint) => {
        // If there's a difference between the band and the
        // size of the icon, then we need to offset the position
        // slightly to maintain its center
        let difference = yScale.bandwidth() - 14;
        let yScalePosition = getYValueForRect(d, reverseData.indexOf(d));
        // Band position is reliable
        if (difference >= 0) {
          return yScalePosition;
        }
        // Band is smaller, icon needs adjustment
        return yScalePosition + difference / 2;
      };
      // Attach icons
      mainArea
        .selectAll(".iconContainer")
        // Consider including "index" in here for use in iconYPosition as a field in d
        .data(reverseData.filter((d) => d.iconClass))
        .join(
          (enter) =>
            enter
              .append("foreignObject")
              .attr("class", "iconContainer")
              .attr("width", ICON_SIZE)
              // 14 is the icon size
              .attr("height", Math.max(14, yScale.bandwidth()))
              .attr("x", ICON_LEFT_OFFSET)
              .attr("y", iconYPosition)
              // Append the icon with a div for centering
              .append("xhtml:div")
              .attr("class", "horizontalIconCentering")
              .append("xhtml:i")
              .attr("class", (d) => cn("icon", d.iconClass))
              .style("font-size", "14px"),

          (update) => {
            // Transitions should only occur if it is not scrollable
            // (due to some delayed transitions otherwise)
            let conditionalUpdate = !shouldScroll
              ? update.transition().duration(transitionDurationRef.current)
              : update;
            conditionalUpdate
              .attr("height", Math.max(14, yScale.bandwidth()))
              .attr("y", iconYPosition)
              // In case an icon name changes for an icon on screen already
              // (such as if icon 0 moves to another row)
              // Have to ignore because "section" for transition
              // and normal selection don't match signatures (but still work fine)
              // @ts-ignore
              .select("div")
              .select("i")
              .attr("class", (d: DataPoint) => cn("icon", d.iconClass));
            return update;
          },
        );

      // Provides click handler for the entire row the bar is on
      mainArea
        .selectAll(".rowBackground")
        .data(reverseData)
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("class", "rowBackground")
              .attr("x", 0)
              .attr("y", getYValueForRect)
              .attr("height", yScale.bandwidth())
              .attr("width", innerWidth)
              .attr("fill", "none")
              .style("pointer-events", "all")
              .style("cursor", "pointer"),
          (update) => update.attr("y", getYValueForRect).attr("height", yScale.bandwidth()).attr("width", innerWidth),
        );

      // Covers up the leftmost rect radius
      mainArea
        .selectAll(".baseRect")
        .data(reverseData)
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("class", "baseRect")
              .attr("x", 0)
              .attr("y", getYValueForRect)
              .attr("height", yScale.bandwidth())
              .attr("width", 0)
              .attr("fill", (d) => d.fillColor ?? defaultBarColor)
              .call(baseEnterTransition, xScale, shouldScroll, transitionDurationRef),
          (update) =>
            update
              .attr("y", getYValueForRect)
              .attr("height", yScale.bandwidth())
              .attr("fill", (d) => d.fillColor ?? defaultBarColor)
              .call(baseUpdateTransition, xScale, shouldScroll, transitionDurationRef, getYValueForRect),
        );

      const barRoundness = barRounded ? yScale.bandwidth() / 2 : 0;

      mainArea
        .selectAll(".roundedRect")
        .data(reverseData)
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("class", "roundedRect")
              .attr("x", 0)
              .attr("y", getYValueForRect)
              .attr("height", yScale.bandwidth())
              .attr("width", 0)
              .attr("fill", (d) => d.fillColor ?? defaultBarColor)
              .attr("rx", barRoundness)
              .attr("ry", barRoundness)
              .style("cursor", "pointer")
              .call(roundedEnterTransition, xScale, shouldScroll, transitionDurationRef),
          (update) =>
            update
              .attr("y", getYValueForRect)
              .attr("height", yScale.bandwidth())
              .attr("fill", (d) => d.fillColor ?? defaultBarColor)
              .attr("rx", barRoundness)
              .attr("ry", barRoundness)
              .call(roundedUpdateTransition, xScale, shouldScroll, transitionDurationRef, getYValueForRect),
        );

      return () => {
        xAxisText.on("mouseover", null).on("mouseleave", null);
        axisText.on("mouseover", null).on("mouseleave", null);
      };
    }, [
      preflightChecksFailed,
      innerHeight,
      width,
      margin.left,
      margin.top,
      xAxis,
      yAxis,
      xScale,
      yScale,
      reverseData,
      defaultBarColor,
      transitionDurationRef,
      xAxisMouseOver,
      axisMouseLeave,
      axisMouseOver,
      scroll,
      zoomBehavior,
      shouldScroll,
      excludeIcons,
      barRounded,
      innerWidth,
    ]);

    useEffect(() => {
      const svg = d3.select(svgRef.current!);
      if (shouldScroll) {
        const clipPathOffset = 200; // to include y-axis
        svg
          .selectAll("defs")
          .data(APPEND_ONCE)
          .join(
            (enter) => {
              let defs = enter.append("defs");
              defs
                .append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("class", "mainClipRect")
                .attr("x", -clipPathOffset)
                .attr("width", width + clipPathOffset)
                .attr("height", innerHeight);
              defs
                .append("clipPath")
                .attr("id", "selectionClip")
                .append("rect")
                .attr("class", "selectionClipRect")
                .attr("x", 0)
                .attr("y", margin.top)
                .attr("width", width)
                .attr("height", innerHeight);

              return enter;
            },
            (update) => {
              update
                .select(".mainClipRect")
                .attr("width", width + clipPathOffset)
                .attr("height", innerHeight);
              update
                .select(".selectionClipRect")
                .attr("y", margin.top)
                .attr("width", width)
                .attr("height", innerHeight);
              return update;
            },
          );
      } else {
        svg.selectAll("defs").remove();
      }
    }, [innerHeight, margin.top, shouldScroll, width]);

    // hover states
    useEffect(() => {
      if (preflightChecksFailed) return;

      const svg = d3.select(svgRef.current!);
      const mouseOver = (point: DataPoint, index: number, nodes: NodeType) => {
        const LEFT_PADDING = 15;
        const hoveredRect = nodes[index] as SVGRectElement;
        const bounds = hoveredRect.getBoundingClientRect();
        setTooltipVisible(true);
        setTooltipPosition({
          left: window.scrollX + bounds.left + Math.max(xScale(point.value), xScale(domain[0])) + LEFT_PADDING,
          top: window.scrollY + bounds.top + yScale.bandwidth() / 2,
        });
        setTooltipColor(point.fillColor ?? defaultBarColor);
        setTooltipText(formatterRef.current(point));
      };

      const mouseLeave = () => {
        // Set timeout is to make sure the tooltipRef has updated
        // the hovered state before we check it
        setTimeout(() => {
          if (tooltipRef.current?.isHovered()) return;
          setTooltipVisible(false);
        }, 0);
      };

      const mouseClick = (d: DataPoint, i: number) => onClickRef.current?.(d, reverseData.length - 1 - i);
      const rowBackgrounds = svg
        .selectAll<SVGRectElement, DataPoint>(".rowBackground")
        .on("click", mouseClick)
        .on("mousemove", mouseOver)
        .on("mouseleave", mouseLeave);
      const roundedRects = svg
        .selectAll<SVGRectElement, DataPoint>(".roundedRect")
        .on("mousemove", mouseOver)
        .on("mouseleave", mouseLeave)
        .on("click", mouseClick);
      return () => {
        rowBackgrounds.on("click", null).on("mousemove", null).on("mouseleave", null);
        roundedRects.on("mousemove", null).on("mouseleave", null).on("click", null);
      };
    }, [
      defaultBarColor,
      preflightChecksFailed,
      domain,
      reverseData.length,
      totalValue,
      transitionDurationRef,
      xScale,
      yScale,
    ]);

    // Active selection rectangle
    useEffect(() => {
      if (preflightChecksFailed) return;

      const svg = d3.select(svgRef.current!);
      const selectedAnswerInBounds = selectedAnswerIndex !== null && selectedAnswerIndex < data.length;
      const selectedCategory = selectedAnswerInBounds && data[selectedAnswerIndex].category;
      const additionalSelectionSizing = yScale.bandwidth() / 4;
      svg
        .selectAll<SVGRectElement, boolean[]>(".activeQuestionSelection")
        .data(APPEND_ONCE)
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("class", "activeQuestionSelection")
              .attr("height", yScale.bandwidth() + additionalSelectionSizing)
              .attr("width", width - margin.right)
              .attr(
                "y",
                selectedCategory
                  ? yScale(`${selectedCategory} ${data.length - 1 - selectedAnswerIndex}`)! +
                      margin.top -
                      additionalSelectionSizing / 2
                  : 0,
              )
              .attr("clip-path", "url(#selectionClip)")
              .style("display", selectedAnswerIndex !== null ? "block" : "none"),
          (update) => {
            update
              .attr("height", yScale.bandwidth() + additionalSelectionSizing)
              .attr("width", width - margin.right)
              .attr(
                "y",
                selectedCategory
                  ? yScale(`${selectedCategory} ${data.length - 1 - selectedAnswerIndex}`)! +
                      margin.top -
                      additionalSelectionSizing / 2
                  : 0,
              )
              .style("display", selectedAnswerIndex !== null ? "block" : "none");
            return update;
          },
        );
    }, [
      innerWidth,
      innerHeight,
      yScale,
      selectedAnswerIndex,
      data,
      width,
      transitionDurationRef,
      margin.right,
      margin.top,
      preflightChecksFailed,
    ]);

    /* instanbul ignore next */
    useEffect(() => {
      if (!shouldScrollToNewPosition || selectedAnswerIndex === null) return;

      const selectedCategory = data[selectedAnswerIndex].category;

      // Test if we're currently in the viewable area
      const zoomRange = mainYZoomScale.range();
      const barPosition = yScale(`${selectedCategory} ${reverseData.length - 1 - selectedAnswerIndex}`)!;
      // These roundings are to compensate for bars being partially in
      const barInBounds = Math.floor(barPosition) > zoomRange[1] && Math.ceil(barPosition) < zoomRange[0];
      if (barInBounds) {
        return;
      }

      const brushGroup = d3.select(svgRef.current!).selectAll<SVGGElement, boolean>(".brushGroup");
      const categoryForScale = reverseData[selectedAnswerIndex].category;

      let startPosition = constantZoomScale(categoryForScale)! - 2;
      let endPosition = startPosition + scrollSize;

      // Test end position, is it past the bottom of available space?
      if (endPosition > getBrushExtent(brushExtent)[1]) {
        endPosition = getBrushExtent(brushExtent)[1];
        startPosition = endPosition - scrollSize;
      }

      brush.move(brushGroup, [startPosition, endPosition]);
      setShouldScrollToNewPosition(false);
    }, [
      barPadding,
      brush,
      brushExtent,
      constantZoomScale,
      data,
      mainYZoomScale,
      reverseData,
      scrollSize,
      selectedAnswerIndex,
      shouldScrollToNewPosition,
      yScale,
    ]);

    return (
      <div className="horizontalBarRoot" ref={measuredRef}>
        <div ref={resizeRef} className="resizeContainer">
          <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
            <HorizBarTooltip
              ref={tooltipRef}
              visible={tooltipVisible}
              text={tooltipText}
              color={tooltipColor}
              {...tooltipPosition}
            />
          </svg>
          {/* For y-axis hover state */}
          <ChartPopup open={popupOpen} {...popupPosition} useTop>
            {popupText}
          </ChartPopup>
        </div>
      </div>
    );
  },
);
