export enum RStatus {
  Idle = "idle",
  Pending = "pending",
  Got = "got",
  Error = "error",
}

export type RequestState<T> = {
  status: RStatus;
  value: T;
  errorMessage?: string;
};
