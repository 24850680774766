import { useLocation, useNavigate } from "react-router-dom";

const utils = {
  goBackOrDefault: (
    location: ReturnType<typeof useLocation>,
    navigate: ReturnType<typeof useNavigate>,
    defaultUrl: string,
  ) => (location.key !== "default" ? navigate(-1) : navigate(defaultUrl)),
  goBackOrUpSegment: (location: ReturnType<typeof useLocation>, navigate: ReturnType<typeof useNavigate>) =>
    location.key !== "default" ? navigate(-1) : navigate("..", { relative: "path" }),
};

export const useBackNavigation = (defaultUrl?: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => (location.key !== "default" ? navigate(-1) : navigate(defaultUrl ?? "..", { relative: "path" }));
};
export default utils;
