import { type AppDispatch } from "../../../../../Application/globaltypes/redux";
import messageDataService from "../../services/messageDataService";
import { createBegin, createSuccess, createFailure } from "../slices/messageCreateSendSlice";
import { type PostSendSessionRequest } from "../../types/requests";

const createMessageSend = (messageId: number, config: PostSendSessionRequest) => async (dispatch: AppDispatch) => {
  dispatch(createBegin());
  try {
    await messageDataService.createMessageSend(messageId, config);
    dispatch(createSuccess());
  } catch (e) {
    dispatch(createFailure(e.message));
  }
};

export { createMessageSend };
