export const getPackOverviewBegin = "GET_PACK_OVERVIEW_BEGIN";
export const getPackOverviewSuccess = "GET_PACK_OVERVIEW_SUCCESS";
export const getPackOverviewFailure = "GET_PACK_OVERVIEW_FAILURE";

export const requestPackBegin = "REQUEST_PACK_BEGIN";
export const requestPackSuccess = "REQUEST_PACK_SUCCESS";
export const requestPackFailure = "REQUEST_PACK_FAILURE";

export const enablePackTrialBegin = "ENABLE_PACK_TRIAL_BEGIN";
export const enablePackTrialSuccess = "ENABLE_PACK_TRIAL_SUCCESS";
export const enablePackTrialFailure = "ENABLE_PACK_TRIAL_FAILURE";

export const showPackRequestModal = "SHOW_PACK_REQUEST_MODAL";
export const hidePackRequestModal = "HIDE_PACK_REQUEST_MODAL";
export const enablePackBegin = "ENABLE_PACK_BEGIN";
export const enablePackSuccess = "ENABLE_PACK_SUCCESS";
export const enablePackFailure = "ENABLE_PACK_FAILURE";
