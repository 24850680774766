import { get, set } from "lodash";
import { batch } from "react-redux";
import validationSchemas from "../../../../../utils/validationSchemas";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { actionTypes } from "../actionTypes/surveyContentValidationActionTypes";
import { publishDraftSurveyEntity } from "./surveyEntityStateActions";
import { getAllErrorsLeafs } from "../../../Common/utils/validationUtils";
import { surveysStateSelector } from "../surveyReducer";
import { type NotifyStepSettings } from "../../../../../interfaces";
import { type ShouldNotifyWithProgressIds } from "../../../../SystemNotifications/types";

export const setIsValidAction = getActionProvider<boolean>(actionTypes.setIsValid);
export const setErrorsResolvedAction = getActionProvider<boolean>(actionTypes.setResolved);
export const setActiveErrorsAction = getActionProvider<object>(actionTypes.setActiveErrors);
export const setTouchedSnapshotAction = getActionProvider<object>(actionTypes.setTouchedSnapshot);
export const setErrorModeAction = getActionProvider<boolean>(actionTypes.setErrorMode);
export const setInitializedAction = getActionProvider<boolean>(actionTypes.setInitialized);
export const resetAction = getActionBaseProvider(actionTypes.reset);

const turnErrorMode = (on: boolean) => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(setErrorModeAction(on));
    dispatch(setErrorsResolvedAction(!on));
  });
};

export const tryPublish =
  (id: number, notifySettings?: NotifyStepSettings, peopleToNotifyTypes?: ShouldNotifyWithProgressIds[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const surveys = surveysStateSelector(getState());
    const survey = surveys.base.surveysDetailsReducer;
    const surveyValidation = surveys.base.surveysContentValidationReducer;

    if (surveyValidation.initialized && !surveyValidation.isFormValid) {
      dispatch(turnErrorMode(true));
      return;
    }

    if (!surveyValidation.initialized) {
      try {
        await validationSchemas.surveysContent.validate(survey.content, { abortEarly: true });
      } catch {
        dispatch(turnErrorMode(true));
        return;
      }
    }

    batch(() => {
      dispatch(publishDraftSurveyEntity(id, notifySettings, peopleToNotifyTypes));
      dispatch(resetAction());
    });
  };

export const setActiveErrors = (errors: object) => (dispatch: AppDispatch) => {
  let activeErrors: { [key: string]: string } = {};
  let touched: object = {};
  getAllErrorsLeafs(errors, (key, value) => {
    activeErrors[key] = value;
    set(touched, key, true);
  });

  batch(() => {
    dispatch(setTouchedSnapshotAction(touched));
    dispatch(setActiveErrorsAction(activeErrors));
  });
};

export const checkForResolvedErrors = (errors: object) => (dispatch: AppDispatch, getState: () => RootState) => {
  const surveyValidation = surveysStateSelector(getState()).base.surveysContentValidationReducer;

  for (let item in surveyValidation.activeErrors) {
    if (get(errors, item)) {
      return;
    }
  }

  batch(() => {
    dispatch(setErrorsResolvedAction(true));
    dispatch(setTouchedSnapshotAction({}));
    dispatch(setActiveErrorsAction({}));
  });
};
