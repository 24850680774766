import { type AxiosResponse } from "axios";
import { type SortingDirection } from "../../../../../enums";
import { type GetPagedDataRequestV2, type WithPagedDataV2 } from "../../../../../interfaces/getPagedDataRequest";
import { type FetchActionPayload } from "../../../../../interfaces/redux";
import { type DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import { pluralize } from "../../../../../utils/stringUtils";
import { type AppDispatch, type AppThunk, type RootState } from "../../../../Application/globaltypes/redux";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { type Columns, ColumnToParamMap } from "../../Overview/getColumnOptions";
import { type SurveyOverview, surveyFilterTypes } from "../../types/models";
import {
  getSurveysBeginAction,
  getSurveysFailureAction,
  getSurveysSuccessAction,
} from "../actions/surveyOverviewActions";
import { type SurveysState, surveysStateSelector } from "../surveyReducer";
import { escapeTerm } from "../../../../../utils/searchUtils";
import { formatFilters } from "../../../../../utils/filterMapUtils";
import { type ContentResponse } from "features/Application/services/dataServices/contentDataService";

export const duplicateSurvey =
  (ids: number[], fetchSurveys: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = (surveyIds: number[]) => async () => {
      const result = await DataService.surveys.duplicateSurveyAsync(surveyIds);
      return result;
    };

    const idsCount = ids.length;
    const bannerPrefix = pluralize("Survey", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DuplicateSurvey",
      title: `Duplication of surveys`,
      onCompleted: () => fetchSurveys(),
      getOperationProps: getOperationProps(ids),
      successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been duplicated!`,
      failureTransientMessage: `${bannerPrefix} duplicate failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const deleteSurvey =
  (ids: number[], fetchSurveys: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const getOperationProps = (surveyIds: number[]) => async () => {
      const result = await DataService.surveys.deleteSurveyAsync(surveyIds);
      return result;
    };

    const idsCount = ids.length;
    const bannerPrefix = pluralize("Survey", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "DeleteSurvey",
      title: `Deletion of surveys`,
      onCompleted: () => fetchSurveys(),
      getOperationProps: getOperationProps(ids),
      successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${bannerPrefix} delete failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

const sendGetSurveysRequest = (
  state: SurveysState,
  skip: number,
  top: number,
  sortingColumnName: string,
  sortOrder: SortingDirection,
): Promise<AxiosResponse<ContentResponse[]>> => {
  const searchTerm = state.overview.filters.search;
  const sortBy = ColumnToParamMap[sortingColumnName as Lowercase<Columns>];
  const requestV2 = formatV2Request({ skip, top, sortBy, sortOrder, term: searchTerm! }, state);

  return DataService.content.getContent({ ...requestV2, showPurchased: true, contentType: "survey" });
};

export const getSurveys =
  (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(getSurveysBeginAction());
    const surveys = surveysStateSelector(getState());
    try {
      const result = await sendGetSurveysRequest(surveys, skip, top, sortingColumnName, sortingDirection);

      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      const data: FetchActionPayload<SurveyOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(getSurveysSuccessAction(data));
    } catch (error: any) {
      dispatch(getSurveysFailureAction(error));
    }
  };

function formatV2Request(params: GetPagedDataRequestV2, state: SurveysState) {
  const { top, skip, sortBy, sortOrder, term } = params;
  const filters = formatFilters(state.overview.filters.appliedFilter, surveyFilterTypes);
  const searchTerm = escapeTerm(term);

  const request: WithPagedDataV2<any> = {
    top,
    skip,
    sortBy,
    sortOrder,
    term: searchTerm!,
    ...filters,
  };
  return request;
}

export const changeVisibility =
  (ids: number[], visible: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    const idsCount = ids.length;
    const survey = pluralize("Survey", idsCount);
    const asset = pluralize("Asset", idsCount);

    const updateParams: DistributedOpUpdateParams = {
      id: "VisibilitySurveysDistributedOperation",
      title: `${visible ? "Unhiding" : "Hiding"} ${asset}`,
      getOperationProps: async () => {
        const { data } = await DataService.surveys.changeVisibility(ids, visible);
        return data;
      },
      successTitle:`${survey} ${visible ? "Unhidden" : "Hidden"}!`,
      successTransientMessage: `${survey} ${idsCount === 1 ? "has" : "have"} been ${visible ? "unhidden" : "hidden"} successfully!`,
      failureTransientMessage: `${survey} ${visible ? "unhiding" : "hiding"} failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
