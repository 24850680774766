import React from "react";

import "./SendEmailConfirmationModal.scss";
import SuppressibleConfirmationModalTs from "../SuppressibleConfirmationModalTs";
import { type IModalManager } from "../../../interfaces/IModalManager";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { Loader } from "semantic-ui-react";
import { type PublishInfo } from "../../../features/Library/Communications/Emails/types/models";

export interface SendEmailConfirmationModalProps {
  emailSubject: string;
  publishInfo: PublishInfo;
  modalManager: IModalManager;
  isLoading: boolean;
}

export default class SendEmailConfirmationModal extends React.Component<SendEmailConfirmationModalProps> {
  getSubject = () => {
    const maxLength = 64;
    const { emailSubject } = this.props;
    const shouldBeTruncated = emailSubject.length > maxLength;
    return shouldBeTruncated ? (
      <Tooltip target={<TextTruncate>{emailSubject}</TextTruncate>} content={emailSubject} />
    ) : (
      emailSubject
    );
  };

  render() {
    const { isLoading } = this.props;

    const message = (
      <div>
        Are you sure you want to send this Email? Email with {this.getSubject()} will be sent to{" "}
        {this.props.publishInfo.notNotifiedPeopleCount} users immediately.
      </div>
    );

    return (
      <>
        <Loader active={isLoading} />
        {!isLoading && (
          <SuppressibleConfirmationModalTs
            className="send-email-popup"
            title={`Send this Email`}
            message={message}
            confirmButtonName={"Send"}
            checkboxIsHidden
            modalManager={this.props.modalManager}
          />
        )}
      </>
    );
  }
}
