import React from "react";
import "./alignmentHelpers.scss";
import { type AlignedWith } from "../types";
import { type XYCoord } from "react-dnd";
import { useReactFlow } from "reactflow";
import { ELEMENT_HALF_HEIGHT, ELEMENT_HALF_WIDTH, ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";
import { getStyles } from "./utils/getStyles";

interface Props {
  leftAligned: AlignedWith | null;
  middleVerticalAligned: AlignedWith | null;
  rightAligned: AlignedWith | null;
  topAligned: AlignedWith | null;
  middleHorizontalAligned: AlignedWith | null;
  bottomAligned: AlignedWith | null;
  nodePosition?: XYCoord;
  containerBounds?: XYCoord;
}

export const AlignmentHelpers: React.FC<Props> = ({
  leftAligned,
  middleVerticalAligned,
  rightAligned,
  topAligned,
  middleHorizontalAligned,
  nodePosition,
  containerBounds,
  bottomAligned,
}) => {
  const { getViewport } = useReactFlow();
  const viewport = getViewport();

  const leftStyles = getStyles(leftAligned, "x", { nodePosition, viewport, containerBounds, shift: 0 });
  const middleVerticalStyles = getStyles(middleVerticalAligned, "x", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HALF_WIDTH,
  });
  const rightStyles = getStyles(rightAligned, "x", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_WIDTH - 2,
  });

  const topStyles = getStyles(topAligned, "y", { nodePosition, viewport, containerBounds, shift: 0 });
  const middleHorizontalStyles = getStyles(middleHorizontalAligned, "y", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HALF_HEIGHT,
  });
  const bottomStyles = getStyles(bottomAligned, "y", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HEIGHT - 2,
  });

  return (
    <>
      {leftAligned && <div className="x-alignment-helper left" style={leftStyles} />}
      {middleVerticalAligned && <div className="x-alignment-helper middle" style={middleVerticalStyles} />}
      {rightAligned && <div className="x-alignment-helper right" style={rightStyles} />}

      {topAligned && <div className="y-alignment-helper top" style={topStyles} />}
      {middleHorizontalAligned && <div className="y-alignment-helper middle" style={middleHorizontalStyles} />}
      {bottomAligned && <div className="y-alignment-helper bottom" style={bottomStyles} />}
    </>
  );
};
