import { useMemo } from "react";
import { useSelector } from "react-redux";
import intersection from "lodash/intersection";

import { type RootState } from "../../../Application/globaltypes/redux";
import { RolePermissions } from "../../../../enums";

export function useHasEnablePackPermissions() {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  return useMemo(() => {
    return (
      intersection(userPermissions, [RolePermissions.PacksPurchase, RolePermissions.AccountsSettingsManage]).length ===
      2
    );
  }, [userPermissions]);
}
