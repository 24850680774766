import React from "react";
import { Popup, List } from "semantic-ui-react";
import AreaIcon from "../../../../components/areaIcon/AreaIcon";

import "./PermissionsList.scss";

interface Permission {
  rightName: string;
  limitationName: string;
  featureName: string;
}

interface PermissionArea {
  id: string | number;
  name: string;
  permissions: Permission[];
}

export interface PermissionsListProps {
  areas: PermissionArea[];
}

const renderPopupContent = (permissions: Permission[]) => {
  const renderPopupColumn = (header: string, selector: (permission: Permission) => any) => (
    <div className="popup-column">
      <div className="popup-item header">{header}</div>
      {permissions.map((p, i) => (
        <div key={i} className="popup-item">
          {selector(p)}
        </div>
      ))}
    </div>
  );

  return (
    <div className="popup-content">
      {renderPopupColumn("Features", (p) => p.featureName)}
      {renderPopupColumn("Rights", (p) => p.rightName)}
      {renderPopupColumn("Limitation Type", (p) => p.limitationName)}
    </div>
  );
};

const PermissionsList: React.FC<PermissionsListProps> = (props) => (
  <List horizontal>
    {props.areas.map((area) => (
      <List.Item key={area.id}>
        <Popup
          className="permissions-popup"
          inverted
          position="top center"
          key={area.id}
          size="small"
          content={renderPopupContent(area.permissions)}
          header={area.name.toUpperCase()}
          wide
          trigger={<AreaIcon areaName={area.name} />}
        />
      </List.Item>
    ))}
  </List>
);

export default PermissionsList;
