import { Component } from "react";
import PropTypes from "prop-types";

import { ItemsViewHeader } from "../../itemsViewHeader";
import ViewType from "../../../enums/ViewType";
import miscUtils from "../../../utils/miscellaneousUtils";
import ListViewBase from "../listViewBase/ListViewBase";
import FilterPanel from "../../filterPanel/FilterPanel";

import "./listViewWithHeaderAndPanel.scss";

export default class ListViewWithHeaderAndPanel extends Component {
  static defaultActivePage = 1;

  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 0,
      activePage: props.activePage ?? ListViewWithHeaderAndPanel.defaultActivePage,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activePage && this.props.activePage !== prevProps.activePage) {
      this.setState({ activePage: this.props.activePage });
    }
  }

  nextPage = () => {
    this.setState((state) => {
      return { activePage: state.activePage + 1 };
    });
  };

  prevPage = () => {
    this.setState((state) => {
      return { activePage: state.activePage - 1 };
    });
  };

  pageChanged = (newActivePage) => {
    this.setState({ activePage: newActivePage });
  };

  itemsPerPageAmountChange = (newItemsPerPage) => {
    this.setState({ itemsPerPage: newItemsPerPage });
  };

  render() {
    const {
      applyFilter,
      isLoading,
      columnOptions,
      loadPage,
      itemsAmount,
      items,
      onSelectedListItemsChanged,
      buildTableBody,
      onFilterPress,
      filter,
      selectedItemIds,
      noResultsContent,
      sortingColumnName,
      sortingDirection,
      renderFilterForm,
      filterOptions,
      filterOptionsLoading,
      resetFilter,
      getFilterOptions,
      withSelection,
      isSelectDisabled,
      isWarning,
      renderSearch,
      setReloadListItems,
    } = this.props;

    const { activePage, itemsPerPage } = this.state;

    let itemsPageRange = null;

    if (itemsAmount) {
      itemsPageRange = miscUtils.getItemsPageRange(activePage, itemsPerPage, itemsAmount);
    }

    return (
      <div className={"list-view-header-panel"}>
        <ItemsViewHeader
          viewType={ViewType.LIST}
          onFilterPress={onFilterPress}
          itemsInlineCount={itemsAmount}
          activePage={activePage}
          onNextPage={this.nextPage}
          onPrevPage={this.prevPage}
          itemsPageRange={itemsPageRange}
          applyFilter={applyFilter}
          appliedFilter={filter}
          isLoading={isLoading}
          search={renderSearch ? <div className="items-view-search">{renderSearch()}</div> : null}
        />

        <div className={"list-view-n-panel"}>
          <ListViewBase
            columnOptions={columnOptions}
            loadPage={loadPage}
            itemsAmount={itemsAmount}
            isLoading={isLoading}
            items={items}
            updateSelectedItems={onSelectedListItemsChanged}
            buildTableBody={buildTableBody}
            onFilterPress={onFilterPress}
            onItemsPerPageAmountChange={this.itemsPerPageAmountChange}
            onPageChanged={this.pageChanged}
            filter={filter}
            selectedItemIds={selectedItemIds}
            noResultsContent={noResultsContent}
            applyFilter={applyFilter}
            sortingColumnName={sortingColumnName}
            sortingDirection={sortingDirection}
            activePage={activePage}
            withSelection={withSelection}
            isSelectDisabled={isSelectDisabled}
            isWarning={isWarning}
            setReloadItems={setReloadListItems}
          />

          <FilterPanel
            isLoading={filterOptionsLoading}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            appliedFilter={filter}
            filterOptions={filterOptions}
            getFilterOptions={getFilterOptions}
            compactFilterPanel
          >
            {renderFilterForm()}
          </FilterPanel>
        </div>
      </div>
    );
  }
}

ListViewWithHeaderAndPanel.propTypes = {
  columnOptions: PropTypes.array.isRequired,
  loadPage: PropTypes.func.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateSelectedItems: PropTypes.func,
  withSelection: PropTypes.bool,
  filter: PropTypes.object,
  applyFilter: PropTypes.func,
  onPageChange: PropTypes.func,
  activePage: PropTypes.number,
  selectedItemIds: PropTypes.array,
  noResultsContent: PropTypes.node,
  sortingColumnName: PropTypes.string,
  sortingDirection: PropTypes.string,
  filterOptions: PropTypes.object,
  filterOptionsLoading: PropTypes.bool,
  resetFilter: PropTypes.func,
  getFilterOptions: PropTypes.func,
  isSelectDisabled: PropTypes.func,
  isWarning: PropTypes.func,
};
