import { Component } from "react";
import { Table } from "semantic-ui-react";
import { Title } from "../../../../components/listViewTemplates";
import { type ColumnOptions } from "../../../../interfaces";
import { type AddOn } from "../../types";
import AddOnIcon from "./AddOnIcon/AddOnIcon";

import "./addOnRow.scss";

export interface AddOnRowProps {
  addOn: AddOn;
  renderEnabledCell(addOn: AddOn): React.ReactElement;
  renderExpirationDateCell(addOn: AddOn): React.ReactElement;
}

class AddOnRow extends Component<AddOnRowProps> {
  static columnOptions: ColumnOptions[] = [
    {
      name: "Title",
      width: 4,
      className: "addon-header",
    },
    {
      name: "Description",
      width: 5,
    },
    {
      name: "Enabled",
      width: 3,
      className: "aligned-addon-header",
    },
    {
      name: "Expiration date",
      width: 4,
    },
  ];

  render() {
    const { addOn, renderEnabledCell, renderExpirationDateCell } = this.props;

    return (
      <>
        <Table.Cell width={AddOnRow.columnOptions[0].width} collapsing>
          <AddOnIcon id={addOn.id} />
          <Title title={addOn.name} className="addon-title" />
        </Table.Cell>
        <Table.Cell width={AddOnRow.columnOptions[1].width}>{addOn.description}</Table.Cell>
        <Table.Cell width={AddOnRow.columnOptions[2].width} collapsing className="enabled-addons">
          {renderEnabledCell(addOn)}
        </Table.Cell>
        <Table.Cell width={AddOnRow.columnOptions[3].width}>{renderExpirationDateCell(addOn)}</Table.Cell>
      </>
    );
  }
}

export default AddOnRow;
