import * as Yup from "yup";

const stringLengths = {
  min: 1,
  titleMax: 128,
  headingMax: 256,
  bodyMax: 2048,
  callToActionMax: 64,
  destinationUrlMax: 2048,
};

export const msTeamsMessageSchema = Yup.object().shape({
  headline: Yup.string()
    .trim()
    .required("Heading is required")
    .min(stringLengths.min, `Heading must have at least ${stringLengths.min} characters`)
    .max(stringLengths.headingMax, `Heading must be maximum of ${stringLengths.headingMax} characters long`),
  body: Yup.string()
    .required("Body is required")
    .min(stringLengths.min, `Body must have at least ${stringLengths.min} characters`)
    .max(stringLengths.bodyMax, `Body must be maximum of ${stringLengths.bodyMax} characters long`),
  callToAction: Yup.string()
    .required("Call to Action is required")
    .min(stringLengths.min, `Call to Action must have at least ${stringLengths.min} characters`)
    .max(
      stringLengths.callToActionMax,
      `Call to Action must be maximum of ${stringLengths.callToActionMax} characters long`,
    ),
  destinationUrl: Yup.string()
    .required("Destination URL is required")
    .min(stringLengths.min, `Destination URL must have at least ${stringLengths.min} characters`)
    .max(
      stringLengths.destinationUrlMax,
      `Destination URL must be maximum of ${stringLengths.destinationUrlMax} characters long`,
    ),
  imageUrl: Yup.string().required("Image Cap is required"),
});
