import { NavLink, useLocation } from "react-router-dom";
import TopMenuItem from "./TopMenuItem";
import cn from "classnames";
import moboConfigurator from "moboConfigurator";
import { useMemo } from "react";

export interface Props {
  label?: string;
  path?: string;
  /**
   * Provides a way to set a route as "active"
   * while having a more specific initial route
   */
  activePath?: string;
  className?: string;
  iconName: string;
  notificationsCount?: number;
  external?: boolean;
  onClick?(): void;
  blur?: boolean;
  withMobo?: boolean;
}

const TopMenuLinkItem: React.FC<Props> = (props) => {
  const location = useLocation();
  const accountId = useMemo(() => moboConfigurator.getMoboId(), []);

  const { label, path, activePath, className, iconName, notificationsCount, onClick, blur, external, withMobo } = props;
  const renderTopMenuItem = () => (
    <TopMenuItem label={label} iconName={iconName} notificationsCount={notificationsCount} />
  );

  if (external) {
    return (
      <a className={className} href={path} onClick={onClick} target="_blank" rel="noreferrer noopener">
        {renderTopMenuItem()}
      </a>
    );
  }

  return (
    <NavLink
      end
      to={{ pathname: `/${path}`, hash: withMobo ? `#moboId=${accountId}` : undefined }}
      className={() => {
        let pathToMatch = activePath ?? path;
        if (!pathToMatch) {
          return className;
        }
        return cn(className, { "active-route": location.pathname.includes(`/${pathToMatch}`) });
      }}
      onClick={(e) => {
        onClick?.();
        if (blur) e.currentTarget.blur();
      }}
    >
      {renderTopMenuItem()}
    </NavLink>
  );
};

export default TopMenuLinkItem;
