import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums/reducer";
import { getPrefix } from "../../../Application/slices/models";
import identityProvidersDataService from "../../services/identityProvidersDataService";

export const fetchIdentityProvidersPrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.IdentityProviders,
  name: "fetchIdentityProviders",
};

export const fetchIdentityProviders = createAsyncThunk(
  getPrefix(fetchIdentityProvidersPrefixPayload),
  async (moboId?: string) => {
    const items = await identityProvidersDataService.getIdentityProviders(moboId);
    return { items, totalCount: items.length };
  },
);
