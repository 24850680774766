import { type FilterTypesMap, FilterType } from "utils/filterUtils";
import { type PackName } from "../../../../../interfaces";
import { type FlowBase } from "../../../../../interfaces/flowInfo";
import { type EntityStateProps, type PurchasableContent } from "../../../../../types";

export interface MessageDetails extends EntityStateProps, PurchasableContent {
  body: string;
  callToAction: string;
  destinationUrl: string;
  flows: FlowBase[];
  headline: string;
  imageUrl: string;
  ownerId: number;
  packs: PackName[];
  primaryId: number;
  publisherId: number;
  title: string;
  utmCampaign: string;
}

export enum MessageFiltersEnum {
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const messageFilterTypes: FilterTypesMap<MessageFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
};

export interface ModalHandlers {
  revert: () => void;
}
