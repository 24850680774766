import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import {
  createFetchingItemsSlice,
  type FetchingItemsState,
} from "features/Application/slices/createFetchingItemsSlice";
import { fetchUploadedFileColumns } from "../../thunks/csvImportThunk";

export const initialState: FetchingItemsState<string> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const uploadCsvFileColumnsSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.AccountUploadCsvFile,
    name: "CsvColumns",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  thunk: fetchUploadedFileColumns,
  reducers: {},
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset } = uploadCsvFileColumnsSlice.actions;

export default uploadCsvFileColumnsSlice.reducer;
