import React, { useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { ButtonWithIcon } from "../../../../../../components/buttons/buttonWithIcon";
import EllipsisPopupButton from "../../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { RemoveLinkButton } from "../../../../../../components/buttons/linkButtons";
import { ListViewBase } from "../../../../../../components/listView";
import { Title } from "../../../../../../components/listViewTemplates";
import { ViewType } from "../../../../../../enums";
import PackVisibilityTypes from "../../../../../../enums/licensing/packVisibilityTypes";
import { useObserver } from "../../../../../../hooks/useObserver";
import { bindAction } from "../../../../../../interfaces/redux";
import PackVisibilityForAccountTypesNoResults from "../../../../../../views/packs/PackVisibility/PackVisibilityForAccountTypesNoResults";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { reset } from "../../../state/slices/packAccountTypesVisibilitySlice";
import {
  addAccountTypesVisibilityForPack as addAccountTypesVisibilityForPackAction,
  deleteAccountTypesVisibilityForPack as deleteAccountTypesVisibilityForPackAction,
  fetchAccountTypesVisibilityForPack as fetchAccountTypesVisibilityForPackAction,
  fetchAvailableAccountTypesVisibilityForPack as fetchAvailableAccountTypesVisibilityForPackAction,
} from "../../../state/thunks/packAccountTypesVisibilityThunk";
import RemovePackVisibilityConfirmationModal from "../RemovePackVisibilityConfirmationModal/RemovePackVisibilityConfirmationModal";
import columnOptions from "./columnOptions";
import PackVisibilityForAccountTypesModal from "./PackVisibilityForAccountTypesModal/PackVisibilityForAccountTypesModal";
import { type PackAccountType, type PackVisibilityForAccountTypesProps } from "./types";

import styles from "./packVisibilityForAccountTypes.module.scss";

export type PackVisibilityForAccountTypesPropsWithRedux = PropsFromRedux & PackVisibilityForAccountTypesProps;

export const PackVisibilityForAccountTypes: React.FC<PackVisibilityForAccountTypesPropsWithRedux> = ({
  id,
  showAddAccountTypesModal,
  showRemoveConfirmationModal,
  isReadOnlyMode,
  setAccountTypesModalVisibility,
  setRemoveConfirmationModalVisibility,
  setShowRemoveButton,
  fetchAccountTypesVisibilityForPack,
  fetchAvailableAccountTypesVisibilityForPack,
  addAccountTypesVisibilityForPack,
  deleteAccountTypesVisibilityForPack,
  resetPackAccountTypes,
  packAccountTypesVisibility,
  accountTypesAvailableForPack,
  onTabChangeObserver,
  isSaving,
  isPublicToAll,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const resetAccountTypesTab = () => {
    setSelectedIds([]);
    setShowRemoveButton(false);
  };

  useEffect(() => {
    return () => {
      resetAccountTypesTab();
      resetPackAccountTypes();
    };
    // eslint-disable-next-line
  }, []);

  const [subscribeOnTabChange] = useObserver(onTabChangeObserver);

  useEffect(() => {
    return subscribeOnTabChange(resetAccountTypesTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnTabChange]);

  const getAccountTypePackVisibilities = () => {
    fetchAccountTypesVisibilityForPack(id);
  };

  const onConfirmAddAccountTypesModal = (accountTypeIds: number[]) => {
    setAccountTypesModalVisibility(false);
    addAccountTypesVisibilityForPack(id, accountTypeIds);
  };

  const getAvailableAccountTypePackVisibilities = () => {
    fetchAvailableAccountTypesVisibilityForPack(id);
  };

  const onSelectedItemsChanged = (ids: number[]) => {
    const anySelected = ids.length > 0;
    setShowRemoveButton(anySelected);
    setSelectedIds(ids);
  };

  const onRemoveAccountTypes = (accountTypeId: number) => {
    setSelectedIds([accountTypeId]);
    setRemoveConfirmationModalVisibility(true);
  };

  const onConfirmRemoveAccountTypesModal = () => {
    setShowRemoveButton(false);
    setRemoveConfirmationModalVisibility(false);
    deleteAccountTypesVisibilityForPack(id, selectedIds);
    setSelectedIds([]);
  };

  const renderModals = () => {
    return (
      <>
        <PackVisibilityForAccountTypesModal
          loadPage={getAvailableAccountTypePackVisibilities}
          isLoading={accountTypesAvailableForPack.isLoading}
          accountTypes={accountTypesAvailableForPack.items}
          onConfirm={onConfirmAddAccountTypesModal}
          showModal={showAddAccountTypesModal}
          onCancel={() => setAccountTypesModalVisibility(false)}
          id={id}
        />
        <RemovePackVisibilityConfirmationModal
          open={showRemoveConfirmationModal}
          onContinue={onConfirmRemoveAccountTypesModal}
          onCancel={() => setRemoveConfirmationModalVisibility(false)}
          packVisibilityType={PackVisibilityTypes.AccountType}
          itemsCount={selectedIds.length}
        />
      </>
    );
  };

  const renderDeleteButton = (accountTypeId: number) => {
    return <RemoveLinkButton onClick={() => onRemoveAccountTypes(accountTypeId)} isDisabled={isReadOnlyMode} />;
  };

  const buildTableBody = (packAccountType: PackAccountType) => {
    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <Title title={packAccountType.name} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>{packAccountType.customerCount}</Table.Cell>
        <Table.Cell className={styles["align-right"]} width={columnOptions[2].width}>
          <EllipsisPopupButton circle outlinedEllipsis disabled={selectedIds.includes(packAccountType.id)}>
            {renderDeleteButton(packAccountType.id)}
          </EllipsisPopupButton>
        </Table.Cell>
      </>
    );
  };

  const addAccountTypesButton = (
    <ButtonWithIcon
      disabled={isReadOnlyMode}
      id="add-account-types-modal"
      label="Add Account Types"
      onClick={() => setAccountTypesModalVisibility(true)}
      primary
    />
  );

  return (
    <section className="scrollable-content">
      <ListViewBase
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        buildTableBody={buildTableBody}
        loadPage={getAccountTypePackVisibilities}
        items={packAccountTypesVisibility.items}
        isLoading={packAccountTypesVisibility.isLoading || isSaving}
        withFooter={false}
        noResultsContent={
          <PackVisibilityForAccountTypesNoResults
            addAccountTypesButton={addAccountTypesButton}
            isPublicToAll={isPublicToAll}
          />
        }
        updateComponentRtnEvents={[rtnEvents.EditAccountTypePackVisibilitySuccess]}
        updateSelectedItems={onSelectedItemsChanged}
        selectedItemIds={selectedIds}
        isSelectDisabled={() => isReadOnlyMode}
      />
      {renderModals()}
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    packAccountTypesVisibility: state.packs.accountTypesVisibility,
    accountTypesAvailableForPack: state.packs.availableAccountTypesForPack,
    isSaving: state.packs.packEntityStateReducer.isEntityCommandInProgress,
    isPublicToAll:
      state.packs.packsVisibilityForAccount.itemsCount === 0 && state.packs.accountTypesVisibility.itemsCount === 0,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchAccountTypesVisibilityForPack: bindAction(fetchAccountTypesVisibilityForPackAction, dispatch),
    fetchAvailableAccountTypesVisibilityForPack: bindAction(
      fetchAvailableAccountTypesVisibilityForPackAction,
      dispatch,
    ),
    addAccountTypesVisibilityForPack: bindAction(addAccountTypesVisibilityForPackAction, dispatch),
    deleteAccountTypesVisibilityForPack: bindAction(deleteAccountTypesVisibilityForPackAction, dispatch),
    resetPackAccountTypes: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PackVisibilityForAccountTypes);
export default ConnectedComponent;
