import React, { type FC, useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { type AppDispatch, type RootState } from "../../../../../Application/globaltypes/redux";
import { columnsMap, getColumnOptions } from "./columnOptions";
import { SearchInput, TextTruncate } from "../../../../../../components";
import ViewType from "../../../../../../enums/ViewType";
import { ItemsView } from "../../../../../../views";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { type PackUserLicenseView } from "../../../types/state";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { ItemsTypes } from "../../../../../../enums/itemsTypes";
import { fetchPackUserLicenses } from "../../../state/thunks/packUserLicensesThunk";
import UserInfoCell from "../../../../../../components/userInfoCell/UserInfoCell";
import { type RolePermissions, SortingDirection, UsersGroupsContext } from "../../../../../../enums";
import PeopleLicensesEmpty from "../PeopleLicensesEmpty/PeopleLicensesEmpty";
import {
  AddAllPackUserSuccess,
  AddAllUserLicenseMessageSuccess,
  RemoveAllUserLicenseMessageSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { resetSearchTerm, setSearchTerm } from "features/Licensing/Packs/state/slices/packUserLicensesSlice";
import { bindAction } from "interfaces";
import { isEmpty } from "lodash";
import { packPurchasedViewSelector } from "features/Licensing/Packs/state/slices/packPurchasedViewSlice";
import { RemoveLicenseButton } from "components/packs/packsList/removeLicenseButton/RemoveLicenseButton";
import styles from "../peopleLicenses.module.scss";

export interface PackUserLicensesOwnProps {
  isOwn: boolean;
  packId: number;
  items: PackUserLicenseView[];
  itemsCount: number;
  isLoading: boolean;
  error?: Error;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
  renderIssueLicenseBtn: () => React.ReactElement;
  customHeaderContent: React.ReactElement;
  permissionPredicate: (userPermissions: RolePermissions[]) => boolean;
  selectedUserIds: number[];
  onSelectedUsersChanged: (ids: number[]) => void;
  onRemoveLicenseClick: (userId: number) => void;
  licenseRemovalEnabled: boolean;
}

export type PackUserLicensesProps = PackUserLicensesOwnProps & PropsFromRedux;

const rtnEvents = [AddAllPackUserSuccess, AddAllUserLicenseMessageSuccess, RemoveAllUserLicenseMessageSuccess];

export const UserLicenses: FC<PackUserLicensesProps> = (props) => {
  const {
    isOwn,
    packId,
    renderIssueLicenseBtn,
    searchTerm,
    setSearch,
    resetSearch,
    permissionPredicate,
    selectedUserIds,
    onSelectedUsersChanged,
    onRemoveLicenseClick,
    licenseRemovalEnabled,
  } = props;

  const columnOptions = getColumnOptions(licenseRemovalEnabled);
  const defaultSortingColumn = "name";
  const defaultSortingDirection = SortingDirection.Ascending;

  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const renderRemoveButton = (userId: number) => {
    const isSelected = selectedUserIds.includes(userId);
    return (
      <Table.Cell className={styles["align-right"]} width={columnOptions[5].width}>
        <RemoveLicenseButton
          id={userId}
          isSelected={isSelected}
          onRemoveLicenseClick={onRemoveLicenseClick}
          issueLicensePermissionPredicate={permissionPredicate}
        />
      </Table.Cell>
    );
  };

  useEffect(
    () => {
      return () => {
        resetSearch();
        onSelectedUsersChanged([]);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const buildTableBody = (userLicense: PackUserLicenseView) => {
    return (
      <React.Fragment key={userLicense.id}>
        <Table.Cell width={columnOptions[0].width}>
          <UserInfoCell user={userLicense} deepLink />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Tooltip target={<TextTruncate>{userLicense.department}</TextTruncate>} content={userLicense.department} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          <Tooltip target={<TextTruncate>{userLicense.jobTitle}</TextTruncate>} content={userLicense.jobTitle} />
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(userLicense.dateAdded)}</Table.Cell>
        <Table.Cell width={columnOptions[4].width}>
          {isOwn ? "Never" : dateTimeUtils.formatEmptyDate(userLicense.licenseExpirationDate)}
        </Table.Cell>
        {licenseRemovalEnabled && renderRemoveButton(userLicense.id)}
      </React.Fragment>
    );
  };

  const getPackUserLicenses = (
    skip = 0,
    top = 10,
    sortingColumnName = defaultSortingColumn,
    sortingDirection = defaultSortingDirection,
  ) => {
    const request = {
      skip,
      top,
      sortBy: `${columnsMap[sortingColumnName]}`,
      sortOrder: sortingDirection,
      term: searchTerm,
    };

    props.fetchPackUserLicenses(packId, request);
  };

  // implement after search & filters are implemented
  const isFiltered = () => false;

  const { items, isLoading, itemsCount, customHeaderContent } = props;

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = async (searchTerm: string) => {
    setSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder={"Search for user licenses..."} onChange={onSearchChange} defaultValue={searchTerm} />
  );

  return (
    <section className="scrollable-content">
      <ItemsView
        itemsType={ItemsTypes.User}
        items={items}
        searchPlaceholder="Search for Users..."
        getData={getPackUserLicenses}
        noResultsContent={
          <PeopleLicensesEmpty
            isFiltered={isFiltered()}
            issueLicenseButton={renderIssueLicenseBtn()}
            usersGroupsContext={UsersGroupsContext.Users}
          />
        }
        isLoading={isLoading}
        isAllDataLoaded={false}
        className="packs-user-licenses-view"
        buildTableBody={buildTableBody}
        columnOptions={columnOptions}
        itemsInlineCount={itemsCount}
        viewType={ViewType.LIST}
        renderSearch={renderSearchInput}
        withSelection={licenseRemovalEnabled}
        setReloadListItems={createReloadListItems}
        hideListGridViewSwitcherButton
        doNotLoadPersistentViewType
        customHeaderContent={customHeaderContent}
        sortingColumnName={defaultSortingColumn}
        sortingDirection={defaultSortingDirection}
        listViewRtnEvents={rtnEvents}
        selectedIds={selectedUserIds}
        onSelectedListItemsChanged={onSelectedUsersChanged}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  ...state.packs.purchasedPack.packUserLicenses,
  accountId: state.userProfile.accountId,
  isOwn: packPurchasedViewSelector(state).isOwn,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPackUserLicenses: bindActionCreators(fetchPackUserLicenses, dispatch),
  setSearch: bindAction(setSearchTerm, dispatch),
  resetSearch: bindAction(resetSearchTerm, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UserLicenses);
export default ConnectedComponent;
