import * as events from "../events/library/libraryEvents";
import {
  updateThreatDefenceLandingPageCommandSuccess,
  threatDefenceLandingPageUpdated,
  publishDraftThreatDefenceLandingPageSuccess,
  fetchDiscardThreatDefenceLandingPageSuccess,
  fetchLockedTdLandingPageSuccess,
} from "../../../../Library/SimulatedPhishing/LandingPages/state/actions/threatDefenceLandingPageEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const landingPagesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.LandingPageEditSuccess] = dispatch(threatDefenceLandingPageUpdated);
  mapping[events.LandingPageContentEditSuccess] = dispatch(threatDefenceLandingPageUpdated);
  mapping[events.LandingPageUpdateCommandCompleted] = dispatch(updateThreatDefenceLandingPageCommandSuccess);

  mapping[events.LandingPagePublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftThreatDefenceLandingPageSuccess,
    (data) => `${data.name} was published.`,
  );

  mapping[events.LandingPageDiscardSuccess] = dispatch(fetchDiscardThreatDefenceLandingPageSuccess);

  mapping[events.LandingPageLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedTdLandingPageSuccess,
    (message) => ({ entityId: message.id }),
  );
};
