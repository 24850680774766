import React from "react";
import cn from "classnames";

import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import CardThumbnail from "../cardThumbnail/CardThumbnail";

import { type PdfAsset } from "../../../interfaces/assetToDropInfo";

import { SelectableAssetCard } from "../SelectableAssetCard/SelectableAssetCard";
import PdfDefaultIcon from "../../../images/pdf-default-icon.png";

import "./FlowDesignerPdfAssetCard.scss";

export interface Props {
  id: PdfAsset["id"];
  title: PdfAsset["title"];
  thumbnailUrl: PdfAsset["thumbnailUrl"];
  className?: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const FlowDesignerPdfAssetCard: React.FunctionComponent<Props> = ({ className, ...props }) => {
  const { id, title, thumbnailUrl } = props;

  return (
    <SelectableAssetCard
      key={id}
      className={cn("flow-designer-pdf-asset-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-pdf-asset-card"
      data-hook-id={id}
      {...props}
    >
      <CardThumbnail thumbnailUrl={thumbnailUrl || PdfDefaultIcon} draggable={false} />
      <div className="type-block">
        <span className="type">PDF</span>
      </div>
      <div className="title">
        <Tooltip target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
      </div>
    </SelectableAssetCard>
  );
};

export default FlowDesignerPdfAssetCard;
