import { Icon } from "semantic-ui-react";
import { Strings } from "../../../../enums";
import { Tooltip } from "../../../common/tooltip";
import DuplicateLinkButton from "../duplicateLinkButton/DuplicateLinkButton";

export interface DuplicateButtonProps {
  entityName: string;
  purchasedSelected: boolean;
  onClick: () => void;
}

export const DuplicateButton = (props: DuplicateButtonProps) => {
  const { entityName, purchasedSelected, onClick } = props;
  const message = Strings.tooltipMessages.purchased.operationsDisabled(entityName);

  return (
    <Tooltip
      showAlways={purchasedSelected}
      inverted
      hideOnScroll
      position="left center"
      target={
        <DuplicateLinkButton
          after={purchasedSelected && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={purchasedSelected}
          onClick={onClick}
        />
      }
      content={message}
    />
  );
};
