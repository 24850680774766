import type { IFlowEdge, IFlowNode } from "../../nodes/types";
import React from "react";

interface ReactFlowStateContextProps {
  edges: IFlowEdge[];
  nodes: IFlowNode[];
}

export const ReactFlowStateContext = React.createContext<ReactFlowStateContextProps>({
  edges: [],
  nodes: [],
});
