import React from "react";

import { type FlowItemFlowEnd } from "../../../../features/Library/Flows/Designer/types";
import { TextTruncate } from "../../../textTruncators/TextTruncators";
import { Icon, Image } from "semantic-ui-react";
import { getFlowEndMessage } from "../../../../features/Library/Flows/Designer/utils/getFlowEndMessage";

import Tooltip from "../../../common/tooltip/Tooltip";
import FlowEndIcon from "../../../../images/flow-end-icon.png";

import styles from "./flowEndCard.module.scss";

export interface FlowEndCardProps {
  item: FlowItemFlowEnd;
  children?: React.ReactNode
}

const FlowEndCard: React.FC<FlowEndCardProps> = ({ item }) => {
  const completedMessage = getFlowEndMessage(item.flowEndInfo);
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span>End of Flow</span>
        {item.flowEndInfo?.nextFlow && <Icon data-testid="next-flow-connected" name="linkify" />}
      </div>
      <div className={styles.content}>
        <div className={styles["content-thumbnail"]}>
          <Image src={FlowEndIcon} />
        </div>
        <Tooltip
          className={styles["content-text"]}
          target={<TextTruncate lines={2}>{completedMessage}</TextTruncate>}
          content={completedMessage}
        />
      </div>
    </div>
  );
};

export default FlowEndCard;
