import React, { memo } from "react";
import { type ConnectionLineComponentProps } from "reactflow";

import { calculateHalfWayToTarget } from "./utils";
import arrowImageBlue from "../../../../../../images/endpoints/endpoint_blue_arrow_down@3x.png";

const ConnectionLine: React.FC<ConnectionLineComponentProps> = ({ fromX, fromY, toX, toY }) => {
  const { x: halfToTargetX, y: halfToTargetY } = calculateHalfWayToTarget({
    fromX,
    fromY,
    toX,
    toY,
  });
  return (
    <g>
      <defs>
        <pattern id="connectionImage" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
          <image x="0%" y="0%" width="512" height="512" xlinkHref={arrowImageBlue as any} />
        </pattern>
      </defs>
      <path
        fill="none"
        stroke="#288bed"
        strokeWidth={2}
        d={`M ${fromX} ${fromY} L ${fromX} ${halfToTargetY} L ${halfToTargetX} ${halfToTargetY} L ${toX} ${halfToTargetY} L ${toX} ${toY}`}
      />
      <circle cx={fromX} cy={fromY} fill="#999888" r={10} />
      <circle cx={toX} cy={toY} fill="url(#connectionImage)" r={10} />
    </g>
  );
};

export default memo(ConnectionLine);
