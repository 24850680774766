import * as Yup from "yup";

import { labelValues, softwareApplicationValues } from "./tagsValidationSchema";
import { uploadedClosedCaptions, uploadedVideos } from "./filesValidationSchema";
import { requiredTextFieldMinMax } from "./commonValidationSchemas";

const titleLengths = {
  min: 3,
  max: 120,
};

export const descriptionLengths = {
  min: 3,
  max: 256,
};

export const videoInfo = Yup.object().shape({
  title: requiredTextFieldMinMax("Title", titleLengths.min, titleLengths.max),
  description: requiredTextFieldMinMax("Description", descriptionLengths.min, descriptionLengths.max),
  uploadedVideos,
});

export const closedCaptions = Yup.object().shape({
  uploadedClosedCaptions,
});

export const videoSettings = Yup.object().shape({
  labels: labelValues,
  softwareApplications: softwareApplicationValues,
});
