import { useEffect, useState, useCallback, useMemo } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { type RootState } from "../../../Application/globaltypes/redux";
import { type Dispatch } from "redux";
import { RolePermissions } from "../../../../enums";
import Restricted from "../../../Application/Restricted";
import IntegrationsList from "./IntegrationsList/IntegrationsList";
import {
  type Integration,
  type IntegrationKey,
  IntegrationStatuses,
  IntegrationTypes,
  type IntegrationWithCompositeId,
} from "../types";
import { fetchIntegrationsAction } from "../state/thunks/integrationsOverviewThunk";
import { DeleteLinkButton, EditLinkButton } from "../../../../components/buttons/linkButtons";
import { some } from "lodash";
import DeleteIntegrationsConfirmationModal from "./DeleteIntegrationsConfirmationModal/DeleteIntegrationsConfirmationModal";
import EllipsisPopupButton from "../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import {
  DeleteIntegrationsSuccess,
  UpdateIntegrationsIsActiveFailure,
  UpdateIntegrationsIsActiveSuccess,
  UpdateMsGraphIsActiveStateFailure,
  UpdateMsGraphIsActiveStateSuccess,
} from "../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { useRtn } from "hooks/useRtn";
import MakeIntegrationActiveLinkButton from "../../../../components/buttons/linkButtons/makeIntegrationActiveLinkButton/MakeIntegrationActiveLinkButton";
import MakeIntegrationInactiveLinkButton from "../../../../components/buttons/linkButtons/makeIntegrationInactiveLinkButton/MakeIntegrationInactiveLinkButton";
import ChangeIntegrationIsActiveStateConfirmationModal from "./ChangeIntegrationIsActiveStateConfirmationModal/ChangeIntegrationIsActiveStateConfirmationModal";
import integrationsDataService from "../../services/integrationsDataService";
import { integrationBaseSelector } from "../state/selectors";
import { reset } from "../state/slices/integrationsOverviewSlice";
import { bindAction } from "../../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { IntegrationDtoMap } from "../Shared";

import styles from "./integrationsOverview.module.scss";

const mapIntegrations = (items: Integration[]) =>
  items.map((x) => {
    return { ...x, key: x.id, id: { type: x.type, id: x.id } };
  });

const init: IntegrationsOverviewState = {
  selectedIds: [],
  showDeleteConfirmation: false,
  showChangeIsActiveConfirmation: false,
  makeIntegrationActive: false,
};

const events = [
  DeleteIntegrationsSuccess,
  UpdateMsGraphIsActiveStateSuccess,
  UpdateMsGraphIsActiveStateFailure,
  UpdateIntegrationsIsActiveSuccess,
  UpdateIntegrationsIsActiveFailure,
];

export const IntegrationsOverview: React.FC<IntegrationsOverviewProps & PropsFromRedux> = (props) => {
  const navigate = useNavigate();
  const { moboId } = useParams();
  const { fetchIntegrations, resetIntegrations, dispatch, asyncOperations } = props;

  useRtn(events, fetchIntegrations, moboId, dispatch, asyncOperations);

  useEffect(() => {
    if (moboId) {
      fetchIntegrations(moboId);
    } else {
      fetchIntegrations();
    }
  }, [fetchIntegrations, moboId]);

  useEffect(() => {
    return () => {
      resetIntegrations();
    };
  }, [resetIntegrations]);

  const { items, isLoading, accountId, isReadOnly } = props;

  const ownAccount = moboId === undefined || parseInt(moboId) === accountId;

  const [state, setState] = useState<IntegrationsOverviewState>(init);
  const { selectedIds, showDeleteConfirmation, selectedItem, showChangeIsActiveConfirmation, makeIntegrationActive } =
    state;
  const integrations = useMemo(() => mapIntegrations(items), [items]);
  const itemsSelected = useMemo(() => some(selectedIds), [selectedIds]);
  const selectedIntegrations = useMemo(
    () => (selectedItem ? [selectedItem] : selectedIds),
    [selectedItem, selectedIds],
  );

  const goToIntegrationCreation = () => {
    navigate("create");
  };

  const onDelete = useCallback(() => {
    integrationsDataService.deleteIntegrations(
      selectedIntegrations.map((x) => x.type),
      moboId,
    );
    setState((prevState) => ({
      ...prevState,
      selectedIds: [],
      showDeleteConfirmation: false,
      selectedItem: undefined,
      showChangeIsActiveConfirmation: false,
    }));
  }, [selectedIntegrations, moboId]);

  const onIsActiveChange = useCallback(() => {
    integrationsDataService.updateIntegrationsIsActiveState(
      selectedIntegrations.map((x) => x.type),
      makeIntegrationActive,
      moboId,
    );
    setState((prevState) => ({
      ...prevState,
      selectedIds: [],
      selectedItem: undefined,
      showChangeIsActiveConfirmation: false,
    }));
  }, [makeIntegrationActive, selectedIntegrations, moboId]);

  const updateSelectedItems = useCallback(
    (newItems: IntegrationKey[]) => {
      const selectedIds = newItems.filter((x) => x.type.toLocaleLowerCase() !== IntegrationTypes.Viva);
      setState({ ...state, selectedIds });
    },
    [state],
  );

  const renderAddIntegrationButton = () => (
    <Restricted
      permissions={[RolePermissions.AccountsSettingsManage]}
      renderContent={(hasPermission) => (
        <Button
          floated="right"
          primary
          content="Add Integration"
          onClick={goToIntegrationCreation}
          className="create-button"
          disabled={!hasPermission}
        />
      )}
    />
  );

  const onEdit = useCallback(
    (integration: IntegrationWithCompositeId) => {
      const url = IntegrationDtoMap[integration.type.toLowerCase() as IntegrationTypes].url;
      navigate(url(ownAccount, moboId));
    },
    [navigate, ownAccount, moboId],
  );

  const renderDeleteIntegrationButton = (integration?: IntegrationWithCompositeId) => {
    return (
      <DeleteLinkButton
        isDisabled={integration?.type.toLocaleLowerCase() === IntegrationTypes.Viva}
        onClick={() => setState({ ...state, showDeleteConfirmation: true, selectedItem: integration })}
      />
    );
  };

  const renderBulkIsActiveChangeButton = () => {
    const shouldRenderMakeActiveBulk = integrations.every((x) => x.status === IntegrationStatuses.Inactive);
    const shouldRenderMakeInactiveBulk = integrations.every((x) => x.status === IntegrationStatuses.Active);
    if (!shouldRenderMakeActiveBulk && !shouldRenderMakeInactiveBulk) {
      return;
    }

    if (shouldRenderMakeActiveBulk) {
      return renderMakeActiveButton();
    }

    return renderMakeInactiveButton();
  };

  const renderOverviewHeaderButtons = () => {
    if (!itemsSelected) {
      return renderAddIntegrationButton();
    }

    return (
      <>
        {renderDeleteIntegrationButton()}
        {renderBulkIsActiveChangeButton()}
      </>
    );
  };

  const renderMakeActiveButton = (integration?: IntegrationWithCompositeId) => {
    return (
      <MakeIntegrationActiveLinkButton
        onClick={() =>
          setState({
            ...state,
            showChangeIsActiveConfirmation: true,
            makeIntegrationActive: true,
            selectedItem: integration,
          })
        }
      />
    );
  };

  const renderMakeInactiveButton = (integration?: IntegrationWithCompositeId) => {
    return (
      <MakeIntegrationInactiveLinkButton
        onClick={() =>
          setState({
            ...state,
            showChangeIsActiveConfirmation: true,
            makeIntegrationActive: false,
            selectedItem: integration,
          })
        }
      />
    );
  };

  const renderOptions = (integration: IntegrationWithCompositeId) => (
    <Restricted
      permissions={[RolePermissions.AccountsSettingsManage]}
      renderContent={(hasPermission) => (
        <EllipsisPopupButton circle outlinedEllipsis disabled={itemsSelected || !hasPermission}>
          <EditLinkButton onClick={() => onEdit(integration)} isDisabled={!hasPermission} />
          {integration?.type.toLocaleLowerCase() !== IntegrationTypes.Viva &&
            (integration.status === IntegrationStatuses.Inactive
              ? renderMakeActiveButton(integration)
              : renderMakeInactiveButton(integration))}
          {renderDeleteIntegrationButton(integration)}
        </EllipsisPopupButton>
      )}
    />
  );

  return (
    <section className={styles["nested-content"]}>
      <div className={styles.overviewButton}>{!isReadOnly && renderOverviewHeaderButtons()}</div>
      <IntegrationsList
        ownAccount={ownAccount}
        moboId={moboId}
        selectedIds={selectedIds}
        integrations={integrations}
        isLoading={isLoading}
        renderAddIntegrationButton={renderAddIntegrationButton}
        renderOptionsMenu={renderOptions}
        updateSelectedItems={updateSelectedItems}
      />
      <DeleteIntegrationsConfirmationModal
        isOpen={showDeleteConfirmation}
        selectedIntegrations={selectedIntegrations}
        onCancel={() => setState({ ...state, showDeleteConfirmation: false })}
        onContinue={onDelete}
      />
      <ChangeIntegrationIsActiveStateConfirmationModal
        isOpen={showChangeIsActiveConfirmation}
        selectedIntegrations={selectedIntegrations}
        makeActive={makeIntegrationActive}
        onCancel={() => setState({ ...state, showChangeIsActiveConfirmation: false })}
        onContinue={onIsActiveChange}
      />
    </section>
  );
};

export type IntegrationsOverviewProps = {
  userPermissions: RolePermissions[];
  isReadOnly: boolean;
};

export interface IntegrationsOverviewState {
  selectedIds: IntegrationKey[];
  selectedItem?: IntegrationWithCompositeId;
  showDeleteConfirmation: boolean;
  showChangeIsActiveConfirmation: boolean;
  makeIntegrationActive: boolean;
}

const mapStateToProps = (state: RootState) => {
  const { items, isLoading } = integrationBaseSelector(state).overview.integrationsOverviewReducer;
  return {
    accountId: state.userProfile.accountId,
    asyncOperations: state.asyncOperations,
    items,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    fetchIntegrations: bindAction(fetchIntegrationsAction, dispatch),
    resetIntegrations: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(IntegrationsOverview);
export default ConnectedComponent;
