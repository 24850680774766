import cn from "classnames";
import { type ButtonProps } from "semantic-ui-react";
import { Button } from "../button/Button";

import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";

interface Props {
  buttonText: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
  hasPermission: boolean;
  className?: string;
  disabled?: boolean;
}

const RestrictedAddButton: React.FC<Props> = ({ buttonText, onClick, hasPermission, className, disabled }) => {
  return (
    <RestrictedByTooltip hasPermission={hasPermission}>
      <Button
        primary
        className={cn("create-button", className)}
        onClick={onClick}
        content={buttonText}
        disabled={disabled || !hasPermission}
      />
    </RestrictedByTooltip>
  );
};

export default RestrictedAddButton;
