import React, { type FC } from "react";
import { AssignmentPeopleContext, SortingDirection, ViewType } from "../../../../../enums";
import AddToAccountPackCard, {
  type Item,
} from "../../../../../components/assignmentModals/packAssignmentModal/packAssignmentSteps/packGridStep/addToAccountPackCard/AddToAccountPackCard";
import { ItemsTypes } from "../../../../../enums/itemsTypes";
import { gridSortingOptions } from "../../../../Accounts/CreateAccount/CreateAccountPacksList/sortOptions";
import { isEmpty } from "lodash";
import NoResults from "../../../../../components/noResults/NoResults";
import NoSearchResults from "../../../../../components/noSearchResults";
import { connect, type ConnectedProps } from "react-redux";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { type PacksRequest } from "../../types/requests";
import { ItemsView } from "../../../../../views/ItemsView/ItemsView";
import SearchInput from "../../../../../components/searchInput/SearchInput";
import { fetchAccountPackLicenses } from "../../state/thunks/accountPackLicensesThunk";
import { reset } from "../../state/slices/accountPackLicensesSlice";
import { useLicensesGrid } from "./hooks";
import { usePermissions } from "./usePermissions";
import "./licensesStep.scss";
import { type CardsViewerItem } from "../../../../../components/cardsViewer/types";
import { type PackOverview } from "../../types/state";
import { type SelectionChangedType } from "interfaces/onSelectionChanged";

const DEFAULT_SORTING_COLUMN_NAME = "DateCreated";

export interface Props extends PropsFromRedux {
  selection: {
    items: PackOverview[];
    onSelect(selectionProps: { items: PackOverview[]; type: SelectionChangedType }): void;
  };
  stepContext: AssignmentPeopleContext;
}

export const LicensesStep: FC<Props> = (props) => {
  const { packsGrid, selection } = props;
  const { search, fetchPackGrid, sorting } = useLicensesGrid(props);
  const havePermissions = usePermissions[props.stepContext](props.userPermissions);

  if (!havePermissions) {
    return <EmptyState isFiltered={false} context={props.stepContext} />;
  }

  const renderCard = (props: CardsViewerItem<Item>) => <AddToAccountPackCard {...props} />;

  return (
    <ItemsView
      blur
      className="items-view_people-packs"
      viewType={ViewType.GRID}
      renderCard={renderCard}
      getData={fetchPackGrid}
      itemsInlineCount={packsGrid.itemsCount}
      isLoading={packsGrid.isLoading}
      isAllDataLoaded={packsGrid.areAllLoaded}
      items={packsGrid.items}
      itemsType={ItemsTypes.Pack}
      sortingColumnName={DEFAULT_SORTING_COLUMN_NAME}
      sortingDirection={SortingDirection.Ascending}
      noResultsContent={<EmptyState isFiltered={!isEmpty(search.term)} context={props.stepContext} />}
      withSelection
      renderSearch={() => <SearchInput placeholder="Search for packs..." onChange={search.onChange} defaultValue="" />}
      sortOptions={gridSortingOptions}
      onSortChange={sorting.onChange}
      doNotLoadPersistentViewType
      hideListGridViewSwitcherButton
      selectedIds={selection.items.map((x) => x.id)}
      onSelectionChanged={selection.onSelect}
    />
  );
};

export function EmptyState({ isFiltered, context }: { isFiltered: boolean; context: AssignmentPeopleContext }) {
  const entityType = context === AssignmentPeopleContext.User ? "user" : "group";
  const title = `No packs for this ${entityType}`;

  return isFiltered ? (
    <NoSearchResults />
  ) : (
    <NoResults
      title={title}
      description={<span>You don't have any packs that can be added to this {entityType}.</span>}
      iconClassName="fa-box-full"
    />
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    packsGrid: state.packs.shared.accountPacks,
    search: state.packs.packsModalSearch,
    userPermissions: state.userProfile.permissions,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchLicenses: (requestData: PacksRequest) => dispatch(fetchAccountPackLicenses(requestData)),
  resetPacks: () => dispatch(reset()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(LicensesStep);
export default ConnectedComponent;
