import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildFetchAction, buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";
import * as fetchingItemsActionTypes from "../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";
import * as searchActionTypes from "../../../../Application/actions/search/searchActionTypes";

export const EnrollmentUsers = "ENROLLMENT_USERS";
export const ManualSelectionUsers = "MANUAL_SELECTION_USERS";
export const GroupAssets = "GROUP_ASSETS";

export const Owners = "OWNERS";

export const Assets = "ASSETS";
export const AssetsLazy = "ASSETS_LAZY";

export const Flows = "FLOWS";
export const FlowsLazy = "FLOWS_LAZY";

const delimiter = "__";

export const FETCH_ENROLLMENT_USERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup,
  EnrollmentUsers,
);

export const RESET_ENROLLMENT_USERS = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, EnrollmentUsers),
  fetchingItemsActionTypes.RESET_ITEMS,
);

export const FETCH_GROUP_OWNERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup,
  Owners,
);

export const PEOPLE_ADD_GROUP_ENROLLMENT_USERS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, EnrollmentUsers),
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const PEOPLE_ADD_GROUP_ENROLLMENT_USERS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, EnrollmentUsers),
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const FETCH_MANUAL_SELECTION_USERS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup,
  ManualSelectionUsers,
);

export const PEOPLE_ADD_GROUP_MANUAL_USERS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, ManualSelectionUsers),
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const PEOPLE_ADD_GROUP_MANUAL_USERS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, ManualSelectionUsers),
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const PEOPLE_ADD_GROUP_MANUAL_USERS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, ManualSelectionUsers),
  searchActionTypes.SET_SEARCH,
);

export const PEOPLE_ADD_GROUP_MANUAL_USERS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, ManualSelectionUsers),
  searchActionTypes.RESET_SEARCH,
);

export const PEOPLE_ADD_GROUP_OWNERS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, Owners),
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const PEOPLE_ADD_GROUP_OWNERS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, Owners),
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const PEOPLE_ADD_GROUP_OWNERS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, Owners),
  searchActionTypes.SET_SEARCH,
);

export const PEOPLE_ADD_GROUP_OWNERS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddGroup.concat(delimiter, Owners),
  searchActionTypes.RESET_SEARCH,
);

export const CREATE_GROUP_BEGIN = "CREATE_GROUP_BEGIN";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const RESET_GROUP_STATE = "RESET_GROUP_STATE";

export const CREATE_GROUP_NOTIFICATION_SETTINGS_BEGIN = "CREATE_GROUP_NOTIFICATION_SETTINGS_BEGIN";
export const CREATE_GROUP_NOTIFICATION_SETTINGS_SUCCESS = "CREATE_GROUP_NOTIFICATION_SETTINGS_SUCCESS";
export const CREATE_GROUP_NOTIFICATION_SETTINGS_FAILURE = "CREATE_GROUP_NOTIFICATION_SETTINGS_FAILURE";

export const PEOPLE_FETCH_AUTHENTICATED_USER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  "AUTHENTICATED_USER",
  "FETCH",
);
