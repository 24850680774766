import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { getPrefix } from "features/Application/slices/models";
import { type AutocompleteAccount } from "interfaces/Account";

const getNewAccount = (name: string): AutocompleteAccount => {
  return {
    id: -1,
    name,
    exactMatch: true,
    logoUri: "",
    isNew: true,
  };
};

export const createAccountApi = createApi({
  reducerPath: getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.AccountCreation,
    name: "api",
  }),
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getAutocomplete: builder.query<AutocompleteAccount[], string>({
      queryFn: async (term: string, { signal }) => {
        try {
          const accounts = await DataService.accounts.getAutocomplete(term, signal);
          const res =
            !!term && accounts.every((acc) => !acc.exactMatch) ? [getNewAccount(term), ...accounts] : accounts;
          return { data: res };
        } catch (error: any) {
          return { error };
        }
      },
      keepUnusedDataFor: 30,
    }),
  }),
});

export const { useGetAutocompleteQuery } = createAccountApi;
