import { ButtonGroup, ButtonOr } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

export interface Props {
  onFinishAsync: () => Promise<void>;
  getPublishAndSendHandler?: (onFinishAsync: () => Promise<void>) => () => void;
  isValid: boolean;
}

export const PublishAndSendWizardButtons: React.FC<Props> = ({ onFinishAsync, getPublishAndSendHandler, isValid }) => {
  return (
    <ButtonGroup>
      <Button
        primary
        fluid
        data-testid="wizard-finish"
        id="wizard-finish"
        className="finish control"
        onClick={onFinishAsync}
        disabled={!isValid}
      >
        Publish
      </Button>
      <ButtonOr />
      <Button
        primary
        fluid
        id="wizard-finish-and-schedule"
        className="finish control"
        onClick={getPublishAndSendHandler?.(onFinishAsync)}
        disabled={!isValid}
      >
        Schedule a Send
      </Button>
    </ButtonGroup>
  );
};
