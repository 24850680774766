import cn from "classnames";
import { ContentView } from "components";
import { type Node } from "components/charts/types/Sankey";
import { type RootState } from "features/Application/globaltypes/redux";
import { type PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { useState } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { selectFlowGoalPerformanceInfo } from "../../state/slices/flowGoalSlice";
import { isAllowedGoal } from "../../state/slices/flowPerformanceSlice";
import FlowApplicationUsage from "./FlowApplicationUsage";
import FlowEngagement from "./FlowEngagement";
import styles from "./FlowPerformanceTabs.module.scss";

export interface Props extends PropsFromRedux {
  dateFilter: PerformanceFilter;
  onClick: (node: Node) => void;
  includeAccounts?: boolean;
}

export const FlowPerformanceTabs = ({ dateFilter, goalInfo, onClick, includeAccounts }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const goalAllowed = isAllowedGoal(goalInfo!);

  const body = goalAllowed ? (
    <ContentView
      activeIndex={activeTab}
      onTabChange={(_e, { activeIndex }) => setActiveTab(activeIndex)}
      tabs={[
        {
          label: "Flow Engagement",
          component: <FlowEngagement includeAccounts={includeAccounts} dateFilter={dateFilter} onClick={onClick} />,
        },
        {
          label: "Application Usage",
          component: <FlowApplicationUsage />,
        },
      ]}
    />
  ) : (
    <FlowEngagement includeAccounts={includeAccounts} dateFilter={dateFilter} onClick={onClick} />
  );

  return (
    <div className={cn("sankeySection", { noMax: activeTab > 0, [styles.sankeySection]: goalAllowed })}>{body}</div>
  );
};

const mapStateToProps = (state: RootState) => ({
  goalInfo: selectFlowGoalPerformanceInfo(state),
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(FlowPerformanceTabs);
export default ConnectedComponent;
