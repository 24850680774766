import React, { useCallback, useEffect, useState } from "react";
import { isEmpty, times } from "lodash";
import { isEmptyOrWhiteSpace } from "../../../../utils/stringUtils";
import { type MappedField, type MapToField } from "./types";
import { type ClaimType } from "../../types";
import AttributeMapper from "./AttributeMapper/AttributeMapper";
import { identityMapping } from "utils/validationSchemas/identityProviderValidationSchemas";

export interface AttributeMappingProps {
  claimTypes: ClaimType[];
  onMappedFieldsChange(fields: MappedField[], isMappingValid: boolean): void;
  mappedFields: MappedField[];
  updateUserInfo: boolean;
  onUpdateUserInfoChange(): void;
  isReadOnly?: boolean;
  identityProvider?: string;
  identityProviderChanged?: boolean;
}

const defaultRowCount = 7;

const createDefaultMappedField = (): MappedField => ({
  mapFrom: "", // Ensures mapFrom input is cleared (undefined will leave a previous value in the input field)
  mapTo: undefined,
  isCustomField: false,
});

const AttributeMapping: React.FC<AttributeMappingProps> = ({
  claimTypes,
  updateUserInfo,
  onUpdateUserInfoChange,
  onMappedFieldsChange,
  mappedFields,
  isReadOnly = false,
  identityProvider = "",
  identityProviderChanged = false,
}) => {
  const [mapToFields, setMapToFields] = useState<MapToField[]>([]);

  const createDefaultMappedFields = useCallback(() => {
    let initialMappedFields = times(defaultRowCount, () => null).map(() => createDefaultMappedField());

    const requiredClaims = claimTypes.filter((x) => x.isRequired);
    for (let i = 0; i < requiredClaims.length; i++) {
      const mapToField = requiredClaims[i];

      if (mapToField && mapToField.isRequired) {
        initialMappedFields[i].mapTo = mapToField.alias;
      }
    }
    return initialMappedFields;
  }, [claimTypes]);

  const setMappedFields = useCallback(
    (fields: MappedField[]) => {
      const nonDefaultFields = fields.filter((f) => !isEmpty(f.mapFrom) || !isEmpty(f.mapTo));
      const nonWhiteSpaceFields = nonDefaultFields.filter(
        (f) => !isEmptyOrWhiteSpace(f.mapFrom) && !isEmptyOrWhiteSpace(f.mapTo),
      );
      const isMappingValid = nonWhiteSpaceFields.length === nonDefaultFields.length;

      onMappedFieldsChange(fields, isMappingValid);
    },
    [onMappedFieldsChange],
  );

  useEffect(() => {
    if (claimTypes && claimTypes.length > 0) {
      setMapToFields(
        claimTypes.map((x) => ({
          name: x.alias,
          isRequired: x.isRequired,
        })),
      );
    }
  }, [claimTypes]);

  useEffect(() => {
    if (claimTypes && claimTypes.length && !mappedFields.length) {
      const initialMappedFields = createDefaultMappedFields();

      setMappedFields(initialMappedFields);
    }

    if (claimTypes && mappedFields.length && claimTypes.length && mappedFields.length < defaultRowCount) {
      let emptyMappedFields = times(defaultRowCount - mappedFields.length, () => createDefaultMappedField());
      let fullMappedFields = mappedFields.concat(emptyMappedFields);
      setMappedFields(fullMappedFields);
    }
  }, [claimTypes, createDefaultMappedFields, mappedFields, setMappedFields]);

  // Identity provider was updated, so change fields to new default values
  useEffect(() => {
    if (!identityProviderChanged) return;

    let initialMappedFields = createDefaultMappedFields();
    if (identityProvider === "Other") {
      setMappedFields(initialMappedFields);
    } else if (identityProvider in identityMapping) {
      const requiredClaims = claimTypes.filter((x) => x.isRequired);
      let requiredClaimIndex = requiredClaims.length;
      identityMapping[identityProvider].forEach((m) => {
        const field = initialMappedFields.find((f) => f.mapTo === m.mapTo);
        if (field) {
          field.mapFrom = m.mapFrom;
        }
        // We haven't made the field yet, but we should
        if (!field && claimTypes.find((c) => c.alias === m.mapTo) !== undefined) {
          initialMappedFields[requiredClaimIndex] = {
            isCustomField: false,
            mapFrom: m.mapFrom,
            mapTo: m.mapTo,
          };
          requiredClaimIndex++;
        }
      });
      setMappedFields(initialMappedFields);
    }
  }, [claimTypes, createDefaultMappedFields, identityProvider, identityProviderChanged, setMappedFields]);

  const addNewMappedField = () => {
    setMappedFields([...mappedFields, createDefaultMappedField()]);
  };

  return (
    <AttributeMapper
      mapToFields={mapToFields}
      updateUserInfo={updateUserInfo}
      onUpdateUserInfoChange={onUpdateUserInfoChange}
      onMappingChange={setMappedFields}
      mappedFields={mappedFields}
      addNewMappedField={addNewMappedField}
      isReadOnly={isReadOnly}
    />
  );
};

export default AttributeMapping;
