import { useMemo, useState } from "react";
import TriggerableConfirmationModal from "../../triggeredConfirmationModal/TriggerableConfirmationModal";
import { ModalSizes } from "../../../enums";
import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { type ContentPriorityLevel } from "../../../interfaces";
import { type ChangePriorityModalCommonProps } from "./types";

import styles from "./changePriorityModal.module.scss";

export const ChangePriorityModal: React.FC<ChangePriorityModalCommonProps> = (props) => {
  const { onTriggerModalObserver, onSave, getContent, renderInheritedWarning } = props;

  const [isValid, setIsValid] = useState(true);
  const [items, setItems] = useState<{ [key in PeopleType]?: ContentPriorityLevel[] }>({
    [PeopleType.User]: [],
    [PeopleType.Group]: [],
  });

  const onClose = () => {
    setIsValid(true);
  };

  const userLength = items.user?.length || 0;
  const anyInherited = useMemo(() => {
    return !!userLength && items.user?.some((x) => x.inherited);
  }, [items.user, userLength]);

  const content = (
    <>
      {anyInherited && renderInheritedWarning(userLength > 1)}
      {getContent(items, setItems, setIsValid)}
    </>
  );

  return (
    <TriggerableConfirmationModal
      title="Priority Settings"
      scrolling={false}
      content={content}
      confirmButtonLabel={"Save"}
      onTriggerModalObserver={onTriggerModalObserver}
      onTrigger={(input: { [key in PeopleType]?: ContentPriorityLevel[] }) => setItems(input)}
      onConfirmed={() => onSave(items)}
      onClose={onClose}
      disabled={!isValid}
      size={ModalSizes.large}
      styles={styles}
    />
  );
};

export default ChangePriorityModal;
