import { useEffect, useState } from "react";

import { type UseLazyLoadingControllerProps } from "./types";
import arrayUtils from "../../utils/arrayUtils";

export const useLazyLoadingController = <T extends { id: number }>(props: UseLazyLoadingControllerProps<T>) => {
  const [selectedItems, setSelectedItems] = useState(new Set<T>());
  const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);
  const onSelect = (item: T) => {
    const selectedItemsCopy = new Set(selectedItems);

    arrayUtils.pushOrRemoveIfExist(selectedItemsCopy, item);
    handleChange(props.items.length !== 0 && props.items.length === selectedItemsCopy.size, selectedItemsCopy);
  };

  useEffect(() => {
    if (props.selectedItems && props.items.length) {
      setSelectedItems(new Set(props.selectedItems));
    }
  }, [props.items, props.selectedItems]);

  const onSelectAll = () => {
    const newIsAllItemsSelected = !isAllItemsSelected;
    let newSelectedItems: Set<T>;

    if (newIsAllItemsSelected) {
      newSelectedItems = new Set([...selectedItems, ...props.items]);
    } else {
      newSelectedItems = new Set([...props.items].filter((x) => !selectedItems.has(x)));
    }

    handleChange(newIsAllItemsSelected, newSelectedItems);
  };

  const handleChange = (newIsAllItemsSelected: boolean, newSelectedItems: Set<T>) => {
    props.onChangeSelected?.([...newSelectedItems]);

    setIsAllItemsSelected(newIsAllItemsSelected);
    setSelectedItems(newSelectedItems);
  };

  const reset = () => {
    setSelectedItems(new Set());
    setIsAllItemsSelected(false);
    props.onChangeSelected?.([]);
  };

  return { onSelect, onSelectAll, reset, isAllItemsSelected, selectedItems };
};
