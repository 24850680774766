import { type QueryFunctionContext, type UseQueryResult } from "@tanstack/react-query";
import { type ColumnDataPoint } from "components/charts/types/ColumnChart";
import { type DataPoint } from "components/charts/types/HorizontalBarChart";
import { type AxisDomain, format as d3Formatter, scaleLinear, scaleLog } from "d3";
import { AccountTypes, type AddOn } from "features/Accounts/types";
import { type ChartPeriod } from "hooks/useChartPeriodMeasure";
import { intersection, isEqual } from "lodash";
import moment from "moment";
import sanitize from "sanitize-filename";
import { NoDataMonth, NoFilteredData } from "../../../../components/charts";
import { type getFormatFromValueTypes } from "../../../../components/charts/shared/public";
import { RStatus } from "../../../Application/globaltypes/fetchRequest";
import { type BasePerformanceRequestFilterParams } from "../models";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";
import { AddOnIds, AlertTypes, RolePermissions, SortingDirection } from "enums";
import environmentConfig from "configuration/environmentConfig";
import { type AxiosResponse } from "axios";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { setExporting } from "features/Reporting/state/export/exportSlice";
import { type AppDispatch } from "features/Application/globaltypes/redux";
import accountsDataService from "../../../Accounts/services/accountsDataService";

export type selectedType = "outreach" | "interaction" | undefined;

export type LineChartState = {
  yScale: typeof scaleLinear | typeof scaleLog;
  yFormatterFunc: string | undefined;
  yExtraTickFormat?: ReturnType<typeof getFormatFromValueTypes>;
};

export type Accounts = { value: string | number; text: string };

export type AccountIds = number;

export interface PerformanceFilter {
  dateFrom: string;
  dateTo: string;
}

export type AccountType = "bsi" | "sp" | "account";

export interface PerformanceWithAccountFilter {
  dateFrom: string;
  dateTo: string;
  showCustomers?: boolean;
  accounts?: AccountIds[] | Accounts[];
  includeMyData?: boolean;
  type?: AccountType;
  isDistinct?: boolean;
  aggregation?: string;
}

const emptyFilter = { dateFrom: "", dateTo: "" };

export const columnDateFormatter = (d: AxisDomain): string => {
  const asDate = new Date(d.toString());
  return `${asDate.getMonth() + 1}/${asDate.getDate()}`;
};

export const yAxisNumberFormatter = (d: d3.AxisDomain) => {
  if (typeof d === "number" || !isNaN(Number(d))) {
    return d3Formatter(".2~s")(+d);
  }

  return d.toLocaleString();
};

export const linearChartState: LineChartState = {
  yScale: scaleLinear,
  yFormatterFunc: ",d",
};

export const longFormDateFilter = (dateFrom: string, dateTo: string, ...other: any) => ({
  dateFrom: moment(dateFrom).format("MM/DD/YYYY"),
  dateTo: moment(dateTo).format("MM/DD/YYYY"),
  ...other,
});

export const logChartState: LineChartState = {
  yScale: scaleLog,
  yFormatterFunc: "10",
};

export const dateRange30 = (): PerformanceFilter => {
  const today = moment().toISOString();
  const todayMinus30 = moment().subtract(30, "days").toISOString();
  let formattedDates = longFormDateFilter(today, todayMinus30);
  return {
    dateTo: formattedDates.dateFrom,
    dateFrom: formattedDates.dateTo,
  };
};

export function sendExportRequest(
  request: (abortController: AbortController) => Promise<AxiosResponse<unknown>>,
  fileName: string,
  abortController: AbortController,
) {
  abortController.abort();
  abortController = new AbortController();
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await request(abortController);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, fileName);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
}

const validDateFormats = [
  "M/D/YY",
  "MM/D/YY",
  "M/DD/YY",
  "MM/DD/YY",
  "M/D/YYYY",
  "MM/D/YYYY",
  "M/DD/YYYY",
  "MM/DD/YYYY",
];
export const validDateWithMoment = (dateString: string) => moment(dateString, validDateFormats, true).isValid();

export const noData = (dateFilter: PerformanceFilter | BasePerformanceRequestFilterParams | null) =>
  dateFilter === null || dateFilter.dateFrom || dateFilter.dateTo ? <NoFilteredData /> : <NoDataMonth />;

export const normalizeDateString = (date: string) => {
  const parsed = new Date(date);
  return new Date(new Date(parsed.getTime() - parsed.getTimezoneOffset() * -60000));
};

export const filterCallback = (filter: PerformanceFilter, emailFilter: PerformanceFilter) => {
  if (emailFilter.dateFrom !== filter.dateFrom || emailFilter.dateTo !== filter.dateTo) {
    return { dateFrom: filter.dateFrom, dateTo: filter.dateTo };
  }
  return emptyFilter;
};

export const isLoading = (status: RStatus) => {
  return status === RStatus.Pending;
};

export const invalidFilterData = (funnelData: { [key: string]: number | undefined }, filter: PerformanceFilter) => {
  const zeroValues = Object.values(funnelData).every((value) => value === 0 || value === undefined);
  const filterValues = Object.values(filter).every((value) => value !== "");

  return zeroValues && filterValues;
};

export const isFilterPresent = (dateFilter: PerformanceFilter) => {
  return !isEqual(dateFilter, emptyFilter);
};

export const dataPresent = (data?: { [key: string]: number }) => {
  if (data === undefined) return false;

  const keysExist = Object.keys(data).length !== 0;
  const someValueHasData = Object.values(data).some((item) => item !== 0);

  return keysExist && someValueHasData;
};

export const validLineData = (lines: number[][]) => {
  return (
    lines.length &&
    lines.every((p) => p !== undefined) &&
    lines.some((line) => line.some((num) => num !== null)) &&
    lines.some((line) => line.some((num) => num !== 0))
  );
};

export const noBarData = (...data: number[]) => data.every((num) => num <= 0 || num === undefined);
export const validBarData = (data: DataPoint[] | ColumnDataPoint[]) => {
  return data.length > 0 && data.some((d) => d.value > 0);
};

export const noTableData = <T,>(rows: Array<T>): boolean => rows.length === 0;

export function formattedAverageTime(num: number) {
  if (num || num === 0) {
    const millisecondsPerSecond = 1000;
    const secondsPerMinute = 60;
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;
    const minutesPerHour = 60;
    const hoursPerDay = 24;

    const fromTime = new Date(+0);
    const toTime = new Date(num * millisecondsPerSecond);

    // get total seconds between the times
    let delta = Math.abs(toTime.getTime() - fromTime.getTime()) / millisecondsPerSecond;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / secondsPerDay);
    delta -= days * secondsPerDay;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / secondsPerHour) % hoursPerDay;
    delta -= hours * secondsPerHour;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / minutesPerHour) % minutesPerHour;
    delta -= minutes * minutesPerHour;

    const seconds = Math.floor(delta % secondsPerMinute);

    if (days !== 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else if (hours !== 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes !== 0) {
      return `${minutes}m ${seconds}s`;
    } else if (seconds !== 0) {
      return `${seconds}s`;
    }
    return "0s";
  }
  return "";
}

export function downloadExcelExport(data: any, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", sanitize(`${fileName}.xlsx`));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const createDateRange = (dateFrom: string, dateTo: string, dateFormat = "MM/DD/YYYY") => {
  const start = moment(dateFrom, dateFormat).local(true);
  const end = moment(dateTo, dateFormat).local(true);
  const dates = [];
  let current = start.clone();

  while (current.isSameOrBefore(end)) {
    dates.push(current.toDate());
    current.add(1, "day");
  }

  return dates;
};

export const getRange = <T,>(
  dateRange: Date[],
  boilerplateObject: T,
  dateFormat = "YYYY-MM-DDTHH:mm:ssZ",
): (T & { Date: string })[] => {
  const dates = [];
  for (const date of dateRange) {
    const boiler = {
      ...boilerplateObject,
      Date: moment(date).local(true).format(dateFormat),
    };
    dates.push(boiler);
  }
  return dates;
};

export const findIndexInRange = (date: string | null | undefined, state: { Date: string }[]) => {
  if (!date) return -1;

  const formattedDate = moment(date)
    .startOf("day")
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDTHH:mm:ssZ");

  return state.findIndex(
    (item) => moment(item.Date).format("YYYY-MM-DDTHH:mm:ssZ") === moment(formattedDate).format("YYYY-MM-DDTHH:mm:ssZ"),
  );
};

export const transitionTime = 500;
export const barPadding = 0.2;

export const lineChartMargins = { top: 34, left: 75, right: 55, bottom: 39 };
export const groupedBarChartMargins = lineChartMargins;
export const horizontalBarChartMargins = { top: 12, right: 50, bottom: 40, left: 120 };
export const columnChartMargins = { top: 20, left: 80, right: 20, bottom: 30 };
export const sankeyWrapperMargins = { top: 50, left: 50, right: 50, bottom: 50 };

export const totalActivity = "Total Activity";
export const dailyActivity = "Daily Activity";
export const lineChartTitles = [totalActivity, dailyActivity];
export const packTitles = ["Trials", "Purchased"];
export const starts = "Starts";
export const inProgress = "In Progress";
export const completes = "Completes";
export const completions = "Completions";
export const chartLegendLabels = [starts, completes];

export const startsColor = "#288bed";
export const completesColor = "#ef9e08";
export const performanceGray = "#686569";
export const chartColorScale = [startsColor, completesColor];
export const sendsColor = startsColor;
export const opensColor = "#58a57b";
export const clicksColor = completesColor;
export const emailReportColorScale = [sendsColor, opensColor, clicksColor];
export const fourthColor = "#f26a5e";

type LineChartKeys<T> = "Date" | keyof T;
type IncomingLineChartValues<Key extends string | number | symbol> = Key extends "Date" ? string : number;
type OutgoingLineChartValues<Key extends string | number | symbol> = Key extends "Date" ? Date : number;

type IncomingData<T> = {
  [key in LineChartKeys<T>]: IncomingLineChartValues<key>;
};

export type FormattedLineData<T extends IncomingData<T>> = {
  [key in LineChartKeys<T>]: OutgoingLineChartValues<key>[];
};
type OtherFormattedDataOptional<T extends IncomingData<T>> = Partial<FormattedLineData<T>>;

export const lineChartFactory = <T extends IncomingData<T>>(dataPoints: T[]) => {
  if (dataPoints.length === 0) return {} as FormattedLineData<T>;
  const getKeys = Object.keys(dataPoints[0]) as (keyof T)[];
  const formattedData: OtherFormattedDataOptional<T> = {};

  for (const key of getKeys) {
    formattedData[key] = [];
  }

  for (const dataPoint of dataPoints) {
    for (const [key, value] of Object.entries(dataPoint)) {
      if (key === "Date") {
        (formattedData as FormattedLineData<T>).Date.push(moment(value).local(true).toDate());
      } else {
        (formattedData as FormattedLineData<T>)[key as keyof T].push(value);
      }
    }
  }

  return formattedData as FormattedLineData<T>;
};

// If there is a decimal, show 2 decimals
// otherwise, leave as a whole integer
export const roundToTwoDigits = (num: number) =>
  num % 1 === 0
    ? num.toLocaleString()
    : num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const canViewCustomersInformation = (accountType: AccountTypes, addOns?: AddOn[]) => {
  const customerTypeValid = accountType !== AccountTypes.Customer;
  const addOnsPresent = Array.isArray(addOns);
  if (!addOnsPresent) {
    return customerTypeValid;
  }
  const foundAddon = addOns.find((addon) => addon.id === AddOnIds.CreateAccounts);
  const addOnValid = !!foundAddon?.isEnabled;
  return customerTypeValid || addOnValid;
};

export const getBarDomain = (datapoints: DataPoint[] | ColumnDataPoint[]): [number, number] => {
  return [0, Math.max(...datapoints.map((d) => d.value)) || 1];
};

export type defaultDateFilterType = {
  dateFrom: string;
  dateTo: string;
  showCustomers: boolean;
};

export type defaultDateFilterWithAccountsType = {
  dateFrom: string;
  dateTo: string;
  accounts: AccountIds[] | Accounts[];
  includeMyData: boolean;
};

// temp until sp reports are able to filter type by account permissions. Once not temp, combine into accountType function below.
export const isBsi = (accountId: number) => environmentConfig.bsiAccountIds.includes(accountId);

export const accountType = (accountId: number): AccountType => {
  if (isBsi(accountId)) {
    return "bsi";
  } else {
    return "account";
  }
  // This will include 'sp' and 'account' logic in the future.
};

type DefaultDateFilterParams = {
  includeAccounts?: boolean;
  includeDistinct?: boolean;
  initialDateRange?: PerformanceFilter;
} & (
  | {
      includeAccountsDropdown?: never;
      accountId?: never;
    }
  | {
      includeAccountsDropdown: boolean;
      accountId: number;
    }
);

export const defaultDateFilter = ({
  includeAccounts,
  initialDateRange = dateRange30(),
  includeAccountsDropdown,
  includeDistinct,
  accountId,
}: DefaultDateFilterParams = {}) => {
  let type;
  if (accountId) {
    type = accountType(accountId);
  }

  return {
    ...initialDateRange,
    ...(includeAccounts && { showCustomers: false }),
    ...(includeAccountsDropdown && { accounts: ["All Customer Accounts"] as any }),
    ...(includeAccountsDropdown && { includeMyData: true }),
    ...(includeAccountsDropdown && { type }),
    ...(includeDistinct && { isDistinct: false }),
  };
};

export type QueryFilter = QueryFunctionContext<
  [_: string, filter: PerformanceFilter | PerformanceWithAccountFilter, accountId?: number],
  unknown
>;

export type QueryFilterFlow = QueryFunctionContext<[_: string, filter: BasePerformanceRequestFilterParams], unknown>;

export type QueryFilterWithAccount = QueryFunctionContext<
  [_name: string, filter: PerformanceWithAccountFilter],
  unknown
>;

export const getFormattedTimeStringFromPeriod = (d: Date | string, period: ChartPeriod, dates: Date[]): string => {
  let dateToFormat = typeof d === "string" ? moment(d, SERVER_DATE_ONLY_FORMAT).local(true) : moment(d).local(true);
  switch (period) {
    case "WEEK":
      let startMoment = moment(dates[0]);
      let startDate = dateToFormat.isBefore(startMoment, "day") ? startMoment : dateToFormat;
      // Test one week later
      let final = moment(dates.at(-1));
      let endDate = dateToFormat.clone().add(1, "week").subtract(1, "day");
      if (endDate.isAfter(final)) {
        endDate = final;
      }
      if (startDate.isSame(endDate, "day")) {
        return startDate.format("M/D");
      }

      return `${startDate.format("M/D")} - ${endDate.format("M/D")}`;
    case "MONTH":
    case "DAY":
    default:
      return dateToFormat.format("MMM DD").replace(",", "");
  }
};

export const largestToSmallest = (a: DataPoint, b: DataPoint) => b.value - a.value;
export const periodFormat = (period: ChartPeriod) => (period === "MONTH" ? "M/YY" : "M/D");

export const permissionPredicateForPacks = (userPermissionsList: RolePermissions[]) => {
  const possiblePermissionCombinations = [
    [RolePermissions.AssetsCreate, RolePermissions.FlowsCreate],
    [RolePermissions.AssetsCreate, RolePermissions.PacksManage],
    [RolePermissions.AssetsManage, RolePermissions.FlowsCreate],
    [RolePermissions.AssetsManage, RolePermissions.PacksManage],
  ];

  return possiblePermissionCombinations.some(
    (permissionCombination) => intersection(userPermissionsList, permissionCombination).length === 2,
  );
};

type User = { FirstName: string; LastName: string };
export function isUserDeleted(user: User): boolean;
export function isUserDeleted(fullName: string): boolean;
export function isUserDeleted(firstName: string, lastName: string): boolean;
export function isUserDeleted(firstNameOrFullName: string | User, lastName?: string): boolean {
  if (typeof firstNameOrFullName === "string") {
    if (lastName === undefined) {
      return firstNameOrFullName.toLowerCase() === "removed user";
    }
    return `${firstNameOrFullName} ${lastName}`.toLowerCase() === "removed user";
  }
  // User type
  return isUserDeleted(firstNameOrFullName.FirstName, firstNameOrFullName.LastName);
}
export const queryHasData = (result: UseQueryResult<unknown[]>) => {
  return result.isSuccess && result.data.length > 0;
};
export const allCustomerAccounts = { text: "All Customer Accounts", value: "All Customer Accounts" };

export const fetchAccounts = async (setListOfAccounts: any) => {
  const top = 10000;
  let skip = 0;
  let listOfAccounts: Accounts[] = [];
  let foundAllAccounts = false;
  while (foundAllAccounts === false) {
    const { items } = await accountsDataService.getAccounts(skip, top, "name", SortingDirection.Ascending);
    if (items.flat().length === 0) {
      foundAllAccounts = true;
    } else {
      const tempListOfAccounts = items.flat().map((account) => {
        return { value: account.id, text: account.name };
      });
      listOfAccounts = [...listOfAccounts, ...tempListOfAccounts];
      if (items.flat().length === top) {
        skip += top;
      } else {
        foundAllAccounts = true;
      }
    }
  }

  listOfAccounts.unshift(allCustomerAccounts);
  setListOfAccounts(listOfAccounts.flat());
};
