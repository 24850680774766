import { type FC, useState } from "react";
import { type DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import TriggeredConfirmationModal from "../triggeredConfirmationModal/TriggerableConfirmationModal";
import { type IObservable } from "../../interfaces/IObservable";
import { tagEntityPlural } from "./helpers";
import { type TagsEnum } from "../../interfaces/TagsEnum";
import MultiSelectWithAdditionField from "../forms/MultiSelectWithAdditionField";
import { useFormik } from "formik";
import {
  labelValues as labels,
  softwareApplicationValues as softwareApplications,
} from "../../utils/validationSchemas/tagsValidationSchema";
import { isEmpty, noop } from "lodash";
import { type NormalizedDropdown } from "../../utils/miscellaneousUtils";
import * as Yup from "yup";
import styles from "./addTagsModal.module.scss";
import cn from "classnames";

const tagSettingsValidationSchema = Yup.object().shape({
  labels,
  softwareApplications,
});

export interface AddTagsModalModalProps {
  onTriggerModalObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number[]) => void>;
  onConfirmAction: (selectedItems: number[], tags: string[], entityType: TagsEnum) => void;
  options: DropdownItemProps[];
  tagType: TagsEnum;
  propertyName: string;
}

const AddTagsModal: FC<AddTagsModalModalProps> = (props) => {
  const { onTriggerModalObserver, options, onConfirmAction, tagType, propertyName } = props;
  const [value, setValue] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [errorsFound, setErrorsFound] = useState(false);

  const bodyClassName = cn({ [styles.bodyError]: errorsFound });
  const headerClassName = cn(styles.header, { [styles.headerError]: errorsFound });

  const onConfirmed = () => {
    onConfirmAction(selectedItems, value, tagType);
    setValue([]);
  };

  const onClose = () => {
    setErrorsFound(false);
    setValue([]);
  };

  const tagsFormik = useFormik<any>({
    initialValues: { labels: [], softwareApplications: [] },
    validationSchema: tagSettingsValidationSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const handleChangeTag = async (
    field: "Label" | "SoftwareApplication",
    value: NormalizedDropdown,
    shouldValidate?: boolean,
  ) => {
    let itemsMap = value.selected.map((item) => {
      return { text: item, value: item };
    });

    let newValue = {
      items: itemsMap,
      selected: value.selected,
    };
    const errors = await (tagsFormik.setFieldValue(
      field,
      newValue.selected,
      shouldValidate,
    ) as unknown as Promise<void>);
    if (!isEmpty(errors?.[field])) {
      setErrorsFound(true);
    } else {
      setErrorsFound(false);
      setValue(value.selected);
    }
  };

  const getContent = (
    <>
      <div className={headerClassName}>{tagEntityPlural[tagType]}</div>
      <MultiSelectWithAdditionField
        {...tagsFormik}
        options={options as any}
        value={value}
        shouldValidate
        isCaseSensitive
        propertyName={propertyName}
        setFieldValue={handleChangeTag}
        className={bodyClassName}
        errorPosition={"top"}
        errors={errorsFound ? tagsFormik.errors : {}}
      />
    </>
  );

  return (
    <TriggeredConfirmationModal
      title={`Add ${tagEntityPlural[tagType]}`}
      scrolling={false}
      content={getContent}
      confirmButtonLabel={"Apply"}
      onTriggerModalObserver={onTriggerModalObserver}
      onConfirmed={onConfirmed}
      onClose={onClose}
      onTrigger={(input: number[]) => setSelectedItems(input)}
      disabled={errorsFound}
    />
  );
};

export default AddTagsModal;
