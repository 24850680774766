export function formatSortingV2api(orderBy?: string): { sortBy?: string; sortOrder?: string } {
  const [sortBy, sortOrder] = orderBy?.split(" ") || [undefined, undefined];
  let res: { sortBy?: string; sortOrder?: string } = {};
  if (sortBy) {
    res.sortBy = sortBy;
  }
  if (sortOrder) {
    res.sortOrder = sortOrder;
  }
  return res;
}
