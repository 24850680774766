import cn from "classnames";
import { TextTruncate } from "components";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  type AccountType,
  type PerformanceWithAccountFilter,
  type QueryFilter,
  dailyActivity,
  emailReportColorScale,
  getBarDomain,
  lineChartFactory,
  lineChartTitles,
  noBarData,
  noData,
  roundToTwoDigits,
  totalActivity,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { EntityType } from "features/Library/Flows/Designer/types";
import { type EmailDetails } from "features/Reporting/types/content";
import { type ColumnOption } from "interfaces/columnOptions";
import { type FC, useEffect, useMemo, useState } from "react";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { type CommonAccountReportProps } from "../../types";

import { useQuery } from "@tanstack/react-query";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";

import { bindActionCreators } from "@reduxjs/toolkit";
import { type AppDispatch } from "features/Application/globaltypes/redux";
import { doEmailExport } from "features/Reporting/state/content/contentActions";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { type ConnectedProps, connect } from "react-redux";

import { useEmailEngagementQuery } from "features/Reporting/queries/sharedQueries";
import { reset, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import styles from "../../contentOverviewReports.module.scss";
import { Tooltip } from "components/common/tooltip";

export type Props = CommonAccountReportProps &
  PropsFromRedux & { filter: PerformanceWithAccountFilter & { type: AccountType } };

const fetchDailyData = async ({ queryKey }: QueryFilter) => {
  const result = await contentReportingService.getEmailUsage(queryKey[1]);
  return lineChartFactory(result.data);
};

const fetchDetailsQuery = async ({ queryKey }: QueryFilter) => {
  let result = (await contentReportingService.getEmailDetails(queryKey[1])).data;
  return result.map((item, i) => {
    return {
      ...item,
      // Should update later to a more unique id
      // For now, index is actually unique without sorting ability
      id: i,
    };
  });
};

export const EmailReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  emailExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailPerformanceOverview);
  const [chartState, setChartState] = useState(totalActivity);
  const lineChartQuery = useQuery({
    queryKey: ["EmailsReportDailyData", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useEmailEngagementQuery({ filter, enabled: reportEnabled });

  const detailsQuery = useQuery({
    queryKey: ["EmailsReportDetails", filter],
    queryFn: fetchDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: emailExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [emailExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const columns: ColumnOption<EmailDetails>[] = useMemo(
    () => [
      {
        name: "Email",
        width: 7,
        isSortable: false,
        render(email) {
          const hideLink = filter.showCustomers;
          const emailTitle = (
            <Tooltip target={<TextTruncate>{email.EmailTitle}</TextTruncate>} content={`${email.EmailTitle}`} />
          );
          if (hideLink) {
            return emailTitle;
          }
          return (
            <button
              className={styles.linkButton}
              onClick={() => setSelectedContent({ id: email.EmailId, type: EntityType.Email, name: email.EmailTitle })}
            >
              {emailTitle}
            </button>
          );
        },
      },
      {
        name: "Last Activity",
        width: 7,
        isSortable: false,
        render(email) {
          return (
            <div className={styles["email-send"]}>{dateTimeUtils.localFormatWithValidation(email.LastActivity)}</div>
          );
        },
      },
      {
        name: "Recipients",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Sends.toLocaleString();
        },
      },
      {
        name: "Opens",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Opens.toLocaleString();
        },
      },
      {
        name: "Clicks",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Clicks.toLocaleString();
        },
      },
      {
        name: "CTOR",
        width: 3,
        isSortable: false,
        render(email) {
          return `${roundToTwoDigits(email.ClickToOpenRate * 100)}%`;
        },
      },
      {
        name: "CTR",
        width: 3,
        isSortable: false,
        render(email) {
          return `${roundToTwoDigits(email.ClickThroughRate * 100)}%`;
        },
      },
    ],
    [filter.showCustomers, setSelectedContent],
  );

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date, lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.Sends, lineChartQuery.data?.Opens, lineChartQuery.data?.Clicks],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [
        lineChartQuery.data?.CumulativeSends,
        lineChartQuery.data?.CumulativeOpens,
        lineChartQuery.data?.CumulativeClicks,
      ],
    };
  }, [chartState, lineChartQuery.data]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={cn(styles.lineChartContainer)}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          colorRange={emailReportColorScale}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
            validLineData([
              lineChartQuery.data.Sends,
              lineChartQuery.data.Opens,
              lineChartQuery.data.Clicks,
              lineChartQuery.data.CumulativeSends,
              lineChartQuery.data.CumulativeOpens,
              lineChartQuery.data.CumulativeClicks,
            ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineData}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={emailReportColorScale}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={cn(styles.funnelChartContainer)}>
        <ChartWrapper
          titles={["Engagement"]}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          colorRange={emailReportColorScale}
        >
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess &&
            !noBarData(
              engagementQuery.data.raw.Sends,
              engagementQuery.data.raw.Opens,
              engagementQuery.data.raw.Clicks,
            ) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                data={engagementQuery.data.barData}
                domain={getBarDomain(engagementQuery.data.barData)}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && <CardReporting items={engagementQuery.data.cardData} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="Emails" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  emailExport: bindActionCreators(doEmailExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EmailReport);
export default ConnectedComponent;
