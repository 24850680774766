import { type SliceCaseReducers, type ValidateSliceCaseReducers } from "@reduxjs/toolkit";
import type ReducerEntityPrefixTypes from "../../../enums/reducer/reducerEntityPrefixTypes";
import type ReducerNamespaceTypes from "../../../enums/reducer/reducerNamespaceTypes";

export interface NamePayload {
  namespace: ReducerNamespaceTypes;
  entity: ReducerEntityPrefixTypes;
  name: string;
}

export interface CreateSlicePayload<TState, TReducers extends SliceCaseReducers<TState>> {
  namePayload: NamePayload;
  initialState: TState;
  reducers: ValidateSliceCaseReducers<TState, TReducers>;
}

export interface CreateThunkSlice<TState, TReducers extends SliceCaseReducers<TState>, AsyncThunk>
  extends CreateSlicePayload<TState, TReducers> {
  thunk?: AsyncThunk;
}

export interface CreateAsyncSlicePayload<TState, TReducers extends SliceCaseReducers<TState>, AsyncThunk> {
  namePayload: NamePayload;
  initialState: TState;
  reducers: ValidateSliceCaseReducers<TState, TReducers>;
  thunk: AsyncThunk;
}

export const getPrefix = (payload: NamePayload) => `${payload.namespace}/${payload.entity}/${payload.name}`;
