import { combineReducers } from "redux";
import { threatDefenceSmtpAuthSendingProfileDetailsReducer } from "./threatDefenceSmtpAuthSendingProfileDetailsReducer";
import threatDefenceSmtpAuthSendingProfileEntityStateReducer from "./threatDefenceSmtpAuthSendingProfileEntityStateReducer";
import sendingProfileSettingsValidationSlice from "../slices/sendingProfileSettingsValidationSlice";

export default combineReducers({
  threatDefenceSmtpAuthSendingProfileDetailsReducer,
  threatDefenceSmtpAuthSendingProfileEntityStateReducer,
  settingsValidation: sendingProfileSettingsValidationSlice,
});
