import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type AvailableLanguage, type ClosedCaptions, type ClosedCaptionsActionPayload } from "../../types/models";
import { type FetchActionPayload } from "../../../../../interfaces/redux";

export interface ClosedCaptionsState {
  captions: ClosedCaptionsActionPayload;
  languages: AvailableLanguage[];
  error?: Error;
}

const initialState: ClosedCaptionsState = {
  captions: {
    items: [],
    isLoading: false,
    areAllLoaded: false,
    selectedItems: [],
  },
  languages: [],
};

const closedCaptionsSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Videos}/closedCaptions`,
  initialState: initialState,
  reducers: {
    fetchAssignedCaptionsBegin(state: ClosedCaptionsState) {
      state.captions.isLoading = true;
    },

    fetchClosedCaptionsSuccess: (
      state: ClosedCaptionsState,
      action: PayloadAction<FetchActionPayload<ClosedCaptions>>,
    ) => {
      state.captions.isLoading = false;
      state.captions.items = state.captions.items.concat(action.payload.items);
      state.captions.areAllLoaded = state.captions.items.length >= action.payload.totalCount;
    },

    refetchClosedCaptionsSuccess: (
      state: ClosedCaptionsState,
      action: PayloadAction<FetchActionPayload<ClosedCaptions>>,
    ) => {
      state.captions.isLoading = false;
      state.captions.items = action.payload.items;
      state.captions.areAllLoaded = state.captions.items.length >= action.payload.totalCount;
      state.captions.selectedItems = state.captions.items.filter((c) =>
        state.captions.selectedItems.some((s) => s.id === c.id),
      );
    },

    selectedItemsChanged: (state: ClosedCaptionsState, action: PayloadAction<ClosedCaptions[]>) => {
      state.captions.selectedItems = action.payload;
    },

    resetSelectedItems: (state: ClosedCaptionsState) => {
      state.captions.selectedItems = [];
    },

    fetchClosedCaptionsFailure(state: ClosedCaptionsState, action: PayloadAction<Error>) {
      state.captions.isLoading = false;
      state.error = action.payload;
    },

    resetClosedCaptions: (state: ClosedCaptionsState) => {
      state.captions = initialState.captions;
    },

    fetchAvailableLanguages: (state: ClosedCaptionsState, action: PayloadAction<AvailableLanguage[]>) => {
      state.languages = action.payload;
    },

    resetLanguages: (state: ClosedCaptionsState) => {
      state.languages = initialState.languages;
    },

    fetchFailure(state: ClosedCaptionsState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
  },
});

export const {
  fetchAssignedCaptionsBegin,
  fetchClosedCaptionsSuccess,
  refetchClosedCaptionsSuccess,
  fetchClosedCaptionsFailure,
  selectedItemsChanged,
  resetSelectedItems,
  resetClosedCaptions,
  fetchAvailableLanguages,
  resetLanguages,
  fetchFailure,
} = closedCaptionsSlice.actions;

export default closedCaptionsSlice.reducer;
export type ClosedCaptionsType = ReturnType<typeof closedCaptionsSlice.reducer>;
