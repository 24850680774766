import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarCheck,
  faCircleCheck,
  faEnvelopeOpen,
  faBullseyePointer,
  faCircleExclamation,
  faCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { EmailSendDetailStatus } from "../types";

export const EmailSendDetailStatusToString: { [key in EmailSendDetailStatus]: string } = {
  [EmailSendDetailStatus.Scheduled]: "Scheduled",
  [EmailSendDetailStatus.Send]: "Sent",
  [EmailSendDetailStatus.Sent]: "Sent",
  [EmailSendDetailStatus.Opened]: "Opened",
  [EmailSendDetailStatus.Clicked]: "Clicked",
  [EmailSendDetailStatus.Failed]: "Failed",
  [EmailSendDetailStatus.Cancelled]: "Cancelled",
};

export const EmailSendDetailStatusToIcon: { [key in EmailSendDetailStatus]: IconDefinition } = {
  [EmailSendDetailStatus.Scheduled]: faCalendarCheck,
  [EmailSendDetailStatus.Send]: faCircleCheck,
  [EmailSendDetailStatus.Sent]: faCircleCheck,
  [EmailSendDetailStatus.Opened]: faEnvelopeOpen,
  [EmailSendDetailStatus.Clicked]: faBullseyePointer,
  [EmailSendDetailStatus.Failed]: faCircleExclamation,
  [EmailSendDetailStatus.Cancelled]: faCircleXmark,
};

export const EmailSendDetailStatusToColor: { [key in EmailSendDetailStatus]: string } = {
  [EmailSendDetailStatus.Scheduled]: "icon-send-scheduled",
  [EmailSendDetailStatus.Send]: "icon-send-complete",
  [EmailSendDetailStatus.Sent]: "icon-send-complete",
  [EmailSendDetailStatus.Opened]: "icon-send-complete",
  [EmailSendDetailStatus.Clicked]: "icon-send-complete",
  [EmailSendDetailStatus.Failed]: "icon-send-failed",
  [EmailSendDetailStatus.Cancelled]: "icon-send-canceled",
};
