import { type RolePermissions } from "enums";
import Restricted from "features/Application/Restricted";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import styles from "./ToggleVisibilityButton.module.scss";

interface Props {
  onClick: () => void;
  isDisabled?: boolean;
  hidden: boolean;
  requiredPermissions?: RolePermissions[];
}

export const ToggleVisibilityButton = (props: Props) => {
  const { onClick, isDisabled, hidden, requiredPermissions } = props;

  const renderButton = () => (
    <BasicLinkButton
      text={hidden ? "Unhide" : "Hide"}
      iconClassName={hidden ? "fa-regular fa-eye" : "fa-solid fa-eye-slash"}
      onClick={onClick}
      isDisabled={isDisabled}
      className={styles.root}
    />
  );

  return requiredPermissions ? (
    <Restricted permissions={requiredPermissions}>{renderButton()}</Restricted>
  ) : (
    renderButton()
  );
};
