import { WeekDays } from "enums/weekDays";

export const defaultWeekDayOrder = [
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
  WeekDays.Saturday,
  WeekDays.Sunday,
];

export const dayOfWeekMap = {
  [WeekDays.Monday]: 1,
  [WeekDays.Tuesday]: 2,
  [WeekDays.Wednesday]: 3,
  [WeekDays.Thursday]: 4,
  [WeekDays.Friday]: 5,
  [WeekDays.Saturday]: 6,
  [WeekDays.Sunday]: 0,
};

export const dayOfWeeks = [
  WeekDays.Sunday,
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
  WeekDays.Saturday,
];
