import { useCallback, useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import miscUtils from "../../utils/miscellaneousUtils";
import ErrorView from "components/ErrorView/ErrorView";
import { SendPageView } from "utils/googleAnalytics";

import styles from "./productBoard.module.scss";

type LoadType = "loading" | "loaded" | "error";

export const ProductBoard = () => {
  const [loadState, setLoadState] = useState<LoadType>("loading");

  const handleLoaded = useCallback(() => {
    setLoadState("loaded");
  }, []);

  const handleError = useCallback(() => {
    setLoadState("error");
  }, []);

  // Set and log page
  useEffect(() => {
    const title = "Feedback";
    miscUtils.setPageTitle(title);
    SendPageView({
      title,
    });
  }, []);

  return (
    <div className={styles.root}>
      {loadState === "loading" && <Loader active data-testid="feedback loader" />}
      {loadState === "error" && <ErrorView>Try again later</ErrorView>}
      {loadState !== "error" && (
        <iframe
          data-testid="feedback portal"
          className={styles.noBorder}
          src="https://portal.productboard.com/zeq3hbwatwxausgutmnbsrmx"
          title="Product Board"
          // While this property is deprecated, no harm in including it
          frameBorder={0}
          width="100%"
          height="100%"
          onLoad={handleLoaded}
          onError={handleError}
        />
      )}
    </div>
  );
};
