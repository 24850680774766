import { type ColumnOptions } from "interfaces";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 8,
    isSortable: true,
  },
  {
    name: "Members",
    width: 8,
    isSortable: true,
  },
];
