import * as createRoleActionsTypes from "./createRoleActionsTypes";
import accountsDataService from "../../../Accounts/services/accountsDataService";
import groupsDataService from "../../services/groupsDataService";

export const fetchPermissionsBegin = () => ({
  type: createRoleActionsTypes.FETCH_PERMISSIONS_BEGIN,
});

export const fetchPermissionsSuccess = (permissions) => ({
  type: createRoleActionsTypes.FETCH_PERMISSIONS_SUCCESS,
  payload: { permissions },
});

export const fetchPermissionsFailure = (error) => ({
  type: createRoleActionsTypes.FETCH_PERMISSIONS_FAILURE,
  payload: { error },
});

export const fetchPermissions = () => {
  return async (dispatch) => {
    dispatch(fetchPermissionsBegin());

    try {
      const result = await accountsDataService.getPermissions();
      dispatch(fetchPermissionsSuccess(result));
    } catch (error) {
      dispatch(fetchPermissionsFailure(error));
    }
  };
};

export const fetchAvailableAccountsBegin = () => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_ACCOUNTS_BEGIN,
});

export const fetchAvailableAccountsSuccess = (availableAccounts) => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_ACCOUNTS_SUCCESS,
  payload: { availableAccounts },
});

export const fetchAvailableAccountsFailure = (error) => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_ACCOUNTS_FAILURE,
  payload: { error },
});

export const fetchAvailableAccounts = () => {
  return async (dispatch) => {
    dispatch(fetchAvailableAccountsBegin());

    try {
      const result = await accountsDataService.getAvailableAccounts();
      dispatch(fetchAvailableAccountsSuccess(result));
    } catch (error) {
      dispatch(fetchAvailableAccountsFailure(error));
    }
  };
};

export const fetchAvailableGroupsBegin = () => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_GROUPS_BEGIN,
});

export const fetchAvailableGroupsSuccess = (availableGroups) => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_GROUPS_SUCCESS,
  payload: { availableGroups },
});

export const fetchAvailableGroupsFailure = (error) => ({
  type: createRoleActionsTypes.FETCH_AVAILABLE_GROUPS_FAILURE,
  payload: { error },
});

export const fetchAvailableGroups = () => {
  return async (dispatch) => {
    dispatch(fetchAvailableGroupsBegin());

    try {
      const result = await groupsDataService.getGroups({
        fields: "id,name",
        orderByParams: "id asc",
      });
      dispatch(fetchAvailableGroupsSuccess(result.items));
    } catch (error) {
      dispatch(fetchAvailableGroupsFailure(error));
    }
  };
};
