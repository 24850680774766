import DefaultDragAndDropSection from "./DefaultDragAndDropSection";
import type DragAndDropSection from "./DragAndDropSection";
import { size, isEmpty, values } from "lodash";
import { MultipleFileErrorDrapAndDropSection } from "./MultipleFileErrorDrapAndDropSection";
import validationRules from "../../../../../utils/validationRules";
import fileUtils from "../../../../../utils/fileUtils";
import { NativeTypes } from "react-dnd-html5-backend";
import { multipleFileError } from "./constants";

export interface DragAndDropSectionHandler {
  canDrop(source: any): boolean;
  onDrop(source: any): void;
  renderDragAndDropSection(): React.ReactElement;
  acceptType: string;
}

export interface DragAndDropSectionFactory {
  type: string | null;
  sections: Array<DragAndDropSection>;
  default: DragAndDropSection;
  onFileDropped: Function;
  acceptTypeProvider(): string;
  validate(items: DataTransferItemList): void;
  getDragAndDropSection(): DragAndDropSectionHandler;
}

export default class UserListDragAndDropSectionFactory implements DragAndDropSectionFactory {
  type: string | null;
  sections: Array<DragAndDropSection>;
  default: DragAndDropSection;
  onFileDropped: Function;

  constructor(onFileDropped: Function) {
    this.type = null;
    this.sections = [new MultipleFileErrorDrapAndDropSection()];
    this.default = new DefaultDragAndDropSection();

    this.onFileDropped = onFileDropped;
  }

  acceptTypeProvider = () => NativeTypes.FILE;

  validate(items: DataTransferItemList): void {
    this.type = null;

    if (size(items) > 1) {
      this.type = multipleFileError;
    }
  }

  getDragAndDropSection(): DragAndDropSectionHandler {
    const _self = this;
    return {
      acceptType: this.acceptTypeProvider(),
      canDrop(source): boolean {
        const { items } = source;
        _self.validate(items);

        const csvExists = validationRules.csvFileType(items);

        // Using check for empty MIME to support chromium CSV format mapping
        // https://bugs.chromium.org/p/chromium/issues/detail?id=155455
        const emptyExists = !!(window as any).chrome && values(source.items).some((item) => isEmpty(item.type));

        return !items || items.length === 0 || csvExists || emptyExists;
      },
      onDrop(source): void {
        const { files } = source;

        if (files?.length !== 1) {
          return;
        }

        const file = files[0];

        if (!fileUtils.isCsvExtension(file)) {
          return;
        }

        _self.onFileDropped(file);
      },
      renderDragAndDropSection() {
        for (const section of _self.sections) {
          if (section.compareType(_self.type)) {
            return section.getSection();
          }
        }
        return _self.default.getSection();
      },
    };
  }
}
