import { Icon } from "semantic-ui-react";
import { TitleSubtitle } from "../../../../../../components/listViewTemplates";

import "./identityProviderTitle.scss";

export interface IdentityProviderTitleProps {
  name: string;
  identityId: string;
  url: string;
}

const IdentityProviderTitle: React.FC<IdentityProviderTitleProps> = ({ name, identityId, url }) => {
  return (
    <div className="identity-provider-title-container">
      <Icon className="fa-solid fa-user-lock identity-provider-title-icon" />
      <TitleSubtitle
        title={name}
        subtitleTitle="Identity Provider ID:"
        subtitle={identityId}
        className="identity-provider-title-subtitle-container"
        url={url}
      />
    </div>
  );
};

export default IdentityProviderTitle;
