import React, { memo } from "react";
import BaseNode from "../Base/Base";
import { type FlowItemBase } from "../types";
import EventCard from "../../../../../../../components/cards/flowDesignerDropCard/EventCard/EventCard";
import { type FlowItemEvent } from "../../../types";
import type EventTypes from "../../../../../../../enums/eventTypes";

interface IEventNodeOwnProps {
  type: EventTypes.ExternalEvent;
  data: FlowItemEvent & FlowItemBase;
}

const EventNode: React.FC<IEventNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <EventCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(EventNode);
