import { useEffect, useState } from "react";
import { SectionHeaderOverlay } from "../SectionHeaderOverlay/SectionHeaderOverlay";
import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";

export const useSectionHeaderOnNode = (nodeId: string) => {
  const [show, toggle] = useState(false);
  const { itemForSectionHeader } = useReactFlowCanvasState();
  useEffect(() => {
    if (itemForSectionHeader && itemForSectionHeader.id === nodeId) {
      toggle(true);
    } else {
      toggle(false);
    }
  }, [itemForSectionHeader, nodeId]);
  return { sectionHeaderOverlay: show && <SectionHeaderOverlay /> };
};
