import moment from "moment";
import momentTZ from "moment-timezone";

type MomentInput =
  | string
  | number
  | moment.Moment
  | Date
  | (string | number)[]
  | moment.MomentInputObject
  | undefined
  | null;

export const weekLength = 7;
export const SERVER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const SERVER_DATE_ONLY_FORMAT = "YYYY-MM-DD";

const exported = {
  formatCurrentDate(format: string) {
    const date = moment();

    return date.format(format);
  },

  formatCurrentDateToUtc(format: string) {
    const date = moment();

    return date.utc().format(format);
  },

  formatDate(dateString: MomentInput) {
    const date = moment(dateString);

    // MM/DD/YYYY
    return date.format("MM/DD/YYYY");
  },

  formatDateWithTimeZone(dateString: MomentInput, timeZone: string) {
    const date = moment(dateString).tz(timeZone);

    // MM/DD/YYYY
    return date.format("MM/DD/YYYY");
  },

  formatEmptyDate(dateString: MomentInput) {
    if (dateString) {
      const date = moment(dateString);

      // MM/DD/YYYY
      return date.format("MM/DD/YYYY");
    }
    return dateString;
  },

  formatDateWithHour(dateString: string) {
    const date = moment(dateString);

    return date.format("MM/DD/YYYY h:mm A");
  },

  formatCurrentTime(formatString: string) {
    return moment().format(formatString);
  },

  formatWithMonthName(dateString: string) {
    const date = moment(dateString);

    // MMM DD, YYYY
    return date.format("MMM DD, YYYY");
  },

  formatWithValidation(dateString: MomentInput | null, defaultValue = "-") {
    return dateString ? this.formatDate(dateString) : defaultValue;
  },

  localFormatWithValidation(dateString?: Date | string, defaultValue = "-", format = "MM/DD/YYYY h:mm A") {
    return dateString ? this.toLocalFormatDateTime(dateString, format) : defaultValue;
  },

  // formatting duration in seconds to time
  // e.g.: 4000 - 01:06:40
  durationSecondsToTime(seconds: number) {
    const duration = moment.duration(seconds, "seconds");

    let formattedDuration = "";
    if (duration.hours() > 0) {
      formattedDuration = moment.utc(duration.asMilliseconds()).format("hh:");
    }

    formattedDuration += moment.utc(duration.asMilliseconds()).format("mm:ss");
    return formattedDuration;
  },

  dateToTimeString(date: Date | string, format = "h:mm A", timeZone?: string | null): string {
    return momentTZ.tz(date, timeZone ?? momentTZ.tz.guess()).format(format);
  },

  dateToLastSavedTime(date: Date | string, format = "h:mm A") {
    const normalizedDate = moment.utc(date).toDate();
    return moment(normalizedDate).local().format(format);
  },

  msToTime(duration: number) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  },

  toLocalFormatDateTime(dateString: Date | string, format = "MM/DD/YYYY h:mm A") {
    const date = moment.utc(dateString).local();

    return date.format(format);
  },

  toServerFormatDate(dateString: Date) {
    const date = moment(dateString);

    return date.format(SERVER_DATE_ONLY_FORMAT);
  },

  normalizeUtcDate(rawValue: Date | string) {
    return moment.utc(rawValue).toDate().toISOString();
  },

  getCurrentDate() {
    return moment.utc().toDate();
  },

  getEmptyDate() {
    return null;
  },

  getWeekNumber: (date: MomentInput) => {
    return Math.floor(moment(date).date() / weekLength);
  },

  getWeekDayName: (date: MomentInput) => {
    return moment(date).format("dddd");
  },

  getMonthName: (date: MomentInput) => {
    return moment(date).format("MMMM").replace(/,\s*$/, "");
  },

  isLastWeekInMonth: (date: MomentInput) => {
    return moment(date).endOf("month").diff(date, "days") < weekLength;
  },

  formatSeconds(seconds: number) {
    return moment.utc(seconds * 1000).format("m:ss");
  },

  formatDateForServer: (date: string, range: "from" | "to"): string => {
    const INPUT_DATE_FORMAT = "MM/DD/YYYY";

    if (range === "from") {
      return moment(date, INPUT_DATE_FORMAT).startOf("day").format(SERVER_DATE_FORMAT);
    }
    return moment(date, INPUT_DATE_FORMAT).endOf("day").format(SERVER_DATE_FORMAT);
  },

  roundTime: (date: Date, nearestMinutes: number): Date => {
    const coeff = 1000 * 60 * nearestMinutes;
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
  },

  toEndDateTime: (date: string) => {
    return moment.utc(date).add(1, "days").add(-1, "milliseconds").toISOString(false);
  },

  getDuration(seconds: number): string {
    const durationObj = moment.utc(seconds * 1000);

    let output: string;

    if (durationObj.hour()) {
      output = `${durationObj.hour()}h ${durationObj.minute()}m ${durationObj.second()}s`;
    } else if (durationObj.minute()) {
        output = `${durationObj.minute()}m ${durationObj.second()}s`;
      } else {
        output = `${durationObj.second()}s`;
      }

    return output;
  },

  dateFromNow(days: number): string {
    return this.formatDate(moment(new Date()).add(days, "d"));
  },

  getDaysTillDate: (date: Date | string): number => {
    return moment.utc(date).diff(moment.utc(), "days");
  },

  convertMinutesToDaysHoursMinutes: (totalMinutes: number) => {
    const duration = moment.duration(totalMinutes, "minutes");
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return { days, hours, minutes };
  },

  getBrowserTimezone: () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
};

export type DateTimeUtils = typeof exported;

export default exported;
