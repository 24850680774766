import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import {
  type ThreatDefenceEmailTemplatesDeleteModalState,
  EmailTemplateDeleteModalType,
  type ThreatDefenceCampaignOption,
} from "../../types/state";

export const initialState: ThreatDefenceEmailTemplatesDeleteModalState = {
  emailTemplateCampaigns: [],
  emailTemplateDeleteModalType: EmailTemplateDeleteModalType.None,
  isLoading: false,
  error: undefined,
};

const emailTemplateDeleteModalSlice = createSlice({
  name: "emailTemplateDeleteModalSlice",
  initialState: initialState,
  reducers: {
    getCampaignsForEmailTempaltesBegin(state: ThreatDefenceEmailTemplatesDeleteModalState) {
      return {
        ...state,
        emailTemplateCampaigns: [],
        isLoading: true,
      };
    },
    getCampaignsForEmailTempaltesSuccess(
      state: ThreatDefenceEmailTemplatesDeleteModalState,
      action: PayloadAction<ThreatDefenceCampaignOption[]>,
    ) {
      return {
        ...state,
        emailTemplateCampaigns: action.payload,
        isLoading: false,
      };
    },
    setEmailTemplateDeleteModalType(
      state: ThreatDefenceEmailTemplatesDeleteModalState,
      action: PayloadAction<EmailTemplateDeleteModalType>,
    ) {
      return {
        ...state,
        emailTemplateDeleteModalType: action.payload,
      };
    },
    closeEmailTemplateDeleteModal(state: ThreatDefenceEmailTemplatesDeleteModalState) {
      return {
        ...state,
        emailTemplateCampaigns: [],
        emailTemplateDeleteModalType: EmailTemplateDeleteModalType.None,
      };
    },
    setError(state: ThreatDefenceEmailTemplatesDeleteModalState, action: PayloadAction<Error>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  getCampaignsForEmailTempaltesBegin,
  getCampaignsForEmailTempaltesSuccess,
  setEmailTemplateDeleteModalType,
  setError,
  closeEmailTemplateDeleteModal,
} = emailTemplateDeleteModalSlice.actions;

export default emailTemplateDeleteModalSlice.reducer;
