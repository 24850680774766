import { type FC, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { intersection, isEmpty } from "lodash";
import { bindAction } from "../../../../../../../interfaces/redux";

import { type FiltersMap } from "../../../../../../../utils/filterUtils";
import { type Filters } from "../../../../../../../utils/queryUtils";
import userListUtils from "../../../../../../../utils/userListUtils";
import { getCustomTooltipMessage } from "../helpers/tooltipMessageHelper";
import { getEntityData } from "../helpers/entityMapHelper";

import { SearchInput } from "../../../../../../../components";
import { UserListStep } from "../../../../../../../components/assignmentModals/commonSteps";
import { RolePermissions, type SortingDirection } from "../../../../../../../enums";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { type PeopleListStepPropsBase } from "../../models";

import { type BackendFlowsConfigV2 } from "../../../../services/flowsDataService";
import { fetchFlowUsersToAdd } from "../../../../state/thunks/flowUsersToAddThunk";

export type UserListStepContainerProps = PropsFromRedux & PeopleListStepPropsBase;

export const UserListStepContainer: FC<UserListStepContainerProps> = ({
  renderModalActions,
  fetchUsersToAdd,
  usersToAdd,
  setIds,
  selectedIds,
  flowIds,
}: UserListStepContainerProps) => {
  const [search, setSearch] = useState<string>("");
  const [appliedFilters, setFilters] = useState<FiltersMap>({});
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const loadUsersToAddPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: Filters,
  ) => {
    const sortBy = userListUtils.formatOrderParamsByColumnName(sortingColumnName);
    const config: BackendFlowsConfigV2 = {
      params: { skip, top, sortBy, sortOrder: sortingDirection, term: search, filter: appliedFilter },
    };

    fetchUsersToAdd(flowIds, config);
  };

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = (searchTerm: string): void => {
    setSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  return (
    <UserListStep
      search={search}
      renderSearch={() => <SearchInput placeholder="Search for Users..." onChange={onSearchChange} />}
      renderModalActions={renderModalActions}
      loadPage={loadUsersToAddPage}
      isLoading={usersToAdd.isLoading}
      users={getEntityData(usersToAdd, flowIds.length)}
      usersCount={usersToAdd.itemsCount}
      onSelectedListItemsChanged={setIds}
      selectedIds={selectedIds}
      applyFilter={setFilters}
      filter={appliedFilters}
      resetFilter={() => setFilters({})}
      hasPermissionPredicate={(userPermissions: string[]) =>
        intersection(userPermissions, [RolePermissions.UsersView]).length > 0
      }
      setReloadListItems={createReloadListItems}
      getCustomTooltipMessage={getCustomTooltipMessage("user", flowIds.length)}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  usersToAdd: state.library.flows.edit.items.usersToAdd,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsersToAdd: bindAction(fetchFlowUsersToAdd, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UserListStepContainer);
export default ConnectedComponent;
