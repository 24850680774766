import { type FC, useEffect } from "react";

import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";
import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import { RolePermissions, type UsersGroupsContext } from "../../../../../enums";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";

interface EditPdfPeoplePropsBase extends IWizardStep {
  pdfId: number;
  usersGroupsContext: UsersGroupsContext;
  onContextChanged: (value: UsersGroupsContext) => void;
}

export type EditPdfPeopleProps = EditPdfPeoplePropsBase;

export const EditPdfPeople: FC<EditPdfPeopleProps> = ({
  pdfId,
  usersGroupsContext,
  acceptHandlers,
  onContextChanged,
}) => {
  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.AssetsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={onContextChanged}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview pdfId={pdfId} customHeaderContent={customHeaderContent} />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview pdfId={pdfId} customHeaderContent={customHeaderContent} />
        )}
      />
    </>
  );
};

export default EditPdfPeople;
