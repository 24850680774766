import isEmpty from "lodash/isEmpty";
import ViewType from "../../../../../enums/ViewType";
import sortDirections from "../../../../../enums/sortDirections";
import RolesNoResults from "../../../RoleList/RolesNoResults";
import { ItemsView } from "../../../../../views";
import RolesFilterForm from "../../../../../components/filterForms/RolesFilterForm/RolesFilterForm";
import { SearchInput } from "../../../../../components";
import UserRoleRow from "./RoleRowInfo";
import type Role from "../../../../../interfaces/role";
import { type Filters } from "../../../../../utils/queryUtils";
import { getColumnOptions } from "features/People/RoleList/RoleRow/getColumnOptions";

export interface UserRoleListProps {
  listViewRtnEvents: string[];
  roles: Role[];
  rolesCount: number;
  isLoading: boolean;
  onSelectedRolesChanged: (ids: number[]) => void;
  filterOptions: any;
  appliedFilter: Filters;
  selectedRoleIds: number[];
  createRoleButton?: React.ReactNode;
  filterActions: {
    setRolesFilter: Function;
    resetRolesFilter: Function;
    fetchFilterOptions: Function;
  };
  dataLoader: (skip: number, top: number, orderProp: string, orderDirection: string, filter: Filters) => void;
  onRemoveMemberClick: (id: number) => void;
  readOnly: boolean;
}

const UserRoleList = (props: UserRoleListProps) => {
  const {
    selectedRoleIds,
    dataLoader,
    rolesCount,
    roles,
    onSelectedRolesChanged,
    filterOptions,
    listViewRtnEvents,
    readOnly,
    onRemoveMemberClick,
    createRoleButton,
    appliedFilter,
    filterActions,
    isLoading,
  } = props;

  const renderFilterForm = () => <RolesFilterForm />;

  const buildTableBody = (role: Role) => {
    const isSelected = selectedRoleIds.some((x) => x === role.id);
    return (
      <UserRoleRow
        role={role}
        isSelected={isSelected}
        isDisabled={readOnly}
        onRemoveMemberClick={onRemoveMemberClick}
      />
    );
  };

  return (
    <ItemsView
      className="user-selection-list"
      viewType={ViewType.LIST}
      columnOptions={getColumnOptions()}
      noResultsContent={<RolesNoResults createRoleButton={createRoleButton} filtered={!isEmpty(appliedFilter)} />}
      getData={dataLoader}
      itemsInlineCount={rolesCount}
      isLoading={isLoading}
      items={roles}
      buildTableBody={buildTableBody}
      onSelectedListItemsChanged={onSelectedRolesChanged}
      renderFilterForm={renderFilterForm}
      applyFilter={filterActions.setRolesFilter}
      appliedFilter={appliedFilter}
      resetFilter={filterActions.resetRolesFilter}
      filterOptions={filterOptions}
      filterOptionsLoading={filterOptions.isLoading}
      getFilterOptions={filterActions.fetchFilterOptions}
      selectedIds={selectedRoleIds}
      sortingDirection={sortDirections.Desc}
      sortingColumnName={"Added"}
      isSelectDisabled={() => readOnly}
      renderSearch={() => <SearchInput placeholder="Search for Roles..." disabled />}
      listViewRtnEvents={listViewRtnEvents}
    />
  );
};

export default UserRoleList;
