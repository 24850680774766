import { type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FetchingItemsState, createFetchingItemsSlice } from "features/Application/slices/createFetchingItemsSlice";
import { type CustomerView } from "../types/models";

export interface TemplateCustomersState extends FetchingItemsState<CustomerView> {
  customersToAdd: number[];
  search: string;
}

export const initialState: TemplateCustomersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  customersToAdd: [],
  search: "",
};

const slice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "AvailableCustomers",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {
    setCustomersToAdd: (state, action: PayloadAction<number[]>) => {
      state.customersToAdd = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, setCustomersToAdd, setSearch } =
  slice.actions;

export default slice.reducer;
