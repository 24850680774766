import { shape, array } from "prop-types";
import { commonFilterFormPropTypes } from "./commonFilterFormPropTypes";

export const packFilterFormPropTypes = {
  filterOptions: shape({
    publisherIds: array,
    ownership: array,
    categoryIds: array,
    industryIds: array,
  }),
  filter: shape({
    publisherIds: array,
    categoryIds: array,
    industryIds: array,
  }),
  ...commonFilterFormPropTypes,
};
