import React, { type FC } from "react";
import { Icon } from "semantic-ui-react";
import { AddLabelsLinkButton } from "../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../components/common/tooltip";

export interface AddLabelsButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

export const AddLabelsEmailButton: FC<AddLabelsButtonProps> = ({ isDisabled, onClick }) => {
  const targetContent = (
    <AddLabelsLinkButton
      onClick={onClick}
      isDisabled={isDisabled}
      after={isDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
    />
  );

  return (
    <Tooltip
      showAlways={isDisabled}
      inverted
      hideOnScroll
      position="left center"
      target={targetContent}
      content={`One or more selected emails have Published status and can’t be added in a bulk`}
    />
  );
};
