import { type ColumnOption } from "interfaces/columnOptions";
import { Title } from "components/listViewTemplates";
import dateTimeUtils from "utils/dateTimeUtils";
import { type AvailableTemplate } from "../CreateAccount/types";

import { TemplateImage } from "components/people/templates/TemplateImage";

export enum Columns {
  Name = "Name",
  Added = "Added",
  Modified = "Modified",
  Customers = "Customers",
  Users = "Users",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  modified: "dateModified",
  added: "dateCreated",
  customers: "customers",
  users: "users",
};

export const getColumnOptions = (): ColumnOption<AvailableTemplate>[] => {
  return [
    {
      name: "",
      width: 1,
      isSortable: false,
      render: (item) => {
        return <TemplateImage imageUrl={item.imageUrl} />;
      },
    },
    {
      name: Columns.Name,
      width: 4,
      isSortable: true,
      render: (item) => <Title title={item.name} clamp={2} className="normal-color" />,
    },
    {
      name: Columns.Customers,
      width: 2,
      isSortable: false,
      render: (item) => item.customers,
    },
    {
      name: Columns.Users,
      width: 2,
      isSortable: false,
      render: (item) => item.users,
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.dateCreated),
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.dateModified),
    },
  ];
};
