import type { ModalActionConfig } from "../../../../../components/assignmentModals/types";
import { Button } from "../../../../../components/buttons/button/Button";
import { once } from "lodash";

export const renderModalActionsLastStep =
  ({ previous, confirm }: ModalActionConfig) =>
  (_: () => void, prevStep: () => void) =>
  (closeModal: Function) => {
    const confirmHandler = () => {
      confirm?.onClick?.();
      closeModal();
    };
    return (
      <>
        <Button
          primary
          blur
          className="previous"
          content="Previous"
          onClick={() => {
            previous?.onClick?.();
            prevStep();
          }}
        />
        <Button
          primary
          className="confirm"
          content="Finish"
          disabled={confirm?.disabled}
          onClick={once(confirmHandler)}
        />
      </>
    );
  };
