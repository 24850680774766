import intersection from "lodash/intersection";
import RolePermissions, { toFeatureRight } from "./rolePermissions";

enum Rights {
  View = "View",
  Manage = "Manage",
}

export const contentRoles = [
  RolePermissions.AssetsView,
  RolePermissions.AssetsManage,
  RolePermissions.AssetsCreate,
  RolePermissions.EventsView,
  RolePermissions.EventsManage,
  RolePermissions.EventsCreate,
  RolePermissions.FlowsView,
  RolePermissions.FlowsManage,
  RolePermissions.FlowsCreate,
  RolePermissions.CommunicationsView,
  RolePermissions.CommunicationsManage,
  RolePermissions.CommunicationsCreate,
];

const CONTENT_FEATURES_COUNT = 4;

export const userRoles = [RolePermissions.UsersCreate, RolePermissions.UsersManage, RolePermissions.UsersView];
export const groupRoles = [RolePermissions.GroupsCreate, RolePermissions.GroupsManage, RolePermissions.GroupsView];

export function hasContentRight(userPermissions: RolePermissions[], right: Rights) {
  const userContentRoleRights = intersection(userPermissions, contentRoles).map(
    (role: RolePermissions) => toFeatureRight(role).right,
  );
  return userContentRoleRights.filter((r) => r === right).length === CONTENT_FEATURES_COUNT;
}

export function hasUserRight(userPermissions: RolePermissions[], right: Rights) {
  return hasRoleWithRight(userPermissions, userRoles, right);
}

export function hasGroupRight(userPermissions: RolePermissions[], right: Rights) {
  return hasRoleWithRight(userPermissions, groupRoles, right);
}

export function hasRoleWithRight(
  userPermissions: RolePermissions[],
  specificRoles: RolePermissions[],
  specificRight: Rights,
) {
  return userPermissions.some((role) => specificRoles.includes(role) && toFeatureRight(role).right === specificRight);
}

export default Rights;
