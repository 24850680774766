import React from "react";
import PropTypes from "prop-types";
import { Placeholder } from "semantic-ui-react";

import "./placeholderLoader.scss";

function PlaceholderLoader(props) {
  let { children, isLoading } = props;

  const placeholder = (
    <Placeholder>
      <Placeholder.Line />
    </Placeholder>
  );

  return <div className="placeholder-loader">{isLoading ? placeholder : children}</div>;
}

PlaceholderLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.object,
};

export default PlaceholderLoader;
