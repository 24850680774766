import { type ISegment } from "../../../../interfaces/segment";

export interface PageInfo {
  index: number;
  segment: ISegment;
}

export interface Pages {
  performance: PageInfo;
  configure: PageInfo;
  content: PageInfo;
  people: PageInfo;
  associatedPacks: PageInfo;
}

export enum EditPdfSteps {
  Performance,
  Configuration,
  Settings,
  People,
  AssociatedPacks
}

export const showLastModified = new Set([EditPdfSteps.Configuration]);
