import React from "react";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { ImageFallbackPlaceholder } from "components/imageFallbackPlaceholder/ImageFallbackPlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./TemplateImage.module.scss";

interface Props {
  imageUrl: Nullable<string>;
}

export const TemplateImage = (props: Props) => {
  return (
    <div className={styles.image}>
      {props.imageUrl ? (
        <div className={styles["image-container"]}>
          <ImageFallbackPlaceholder
            imageInfo={{
              className: styles["image-wrapper"],
              alt: "avatar",
              src: props.imageUrl,
            }}
          />
        </div>
      ) : (
        <FontAwesomeIcon icon={faBuildingUser} className={styles["image-placeholder"]} size="2x" />
      )}
    </div>
  );
};
