import { type ColumnOption } from "interfaces/columnOptions";
import { PackColumns, type PackView } from "../../types/models";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { PackTitleCell, RemoveLinkButton, EditLinkButton } from "components";
import cn from "classnames";
import styles from "./groupTemplatePacks.module.scss";
import dateTimeUtils from "utils/dateTimeUtils";
import { RouteNames } from "enums";

export interface GetColumnOptionsParams {
  selectedIds: number[];
  buttonHandlers: {
    onRemovePackClick?: (id: number) => void;
    onEditLicensingClick?: (pack: PackView) => void;
  };
  disabled?: boolean;
}

export const getColumnOptions = (props: GetColumnOptionsParams): ColumnOption<PackView>[] => {
  const {
    selectedIds,
    buttonHandlers: { onRemovePackClick, onEditLicensingClick },
    disabled,
  } = props;

  const removePackButton = (id: number) => (
    <RemoveLinkButton key={cn("RemovePackButton-", id)} onClick={() => onRemovePackClick?.(id)} />
  );

  const editLicensingButton = (pack: PackView) => (
    <EditLinkButton key="edit" onClick={() => onEditLicensingClick?.(pack)} text="Edit Licensing" />
  );

  return [
    {
      name: PackColumns.Name,
      width: 5,
      isSortable: true,
      render: (item) => (
        <PackTitleCell pack={{ ...item, isTrial: false }} url={`/${RouteNames.purchasedPacks}/${item.id}`} />
      ),
      className: styles["pack-name-header"],
    },
    {
      name: PackColumns.Licenses,
      width: 4,
      isSortable: false,
      render: () => "Auto-increment",
    },
    {
      name: PackColumns.Added,
      width: 3,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.dateAdded),
    },
    {
      name: PackColumns.Expiration,
      width: 3,
      isSortable: false,
      render: () => "-",
    },
    {
      name: "",
      width: 1,
      isSortable: false,
      className: styles["align-right"],
      render: (item) => (
        <EllipsisPopupButton circle disabled={selectedIds.includes(item.id) || disabled}>
          {[editLicensingButton(item), removePackButton(item.id)]}
        </EllipsisPopupButton>
      ),
    },
  ];
};
