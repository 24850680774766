import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { type AppThunk } from "../../../../Application/globaltypes/redux";
import surveysDataService from "../../services/surveysDataService";

export const updateTags = (id: number, tags: string[], type: "labels" | "softwareApplications"): AppThunk => {
  const tagTypeMap = {
    labels: TagsEnum.Label,
    softwareApplications: TagsEnum.SoftwareApplication,
  };

  return async () => {
    try {
      await surveysDataService.saveTags({ id, tags, tagType: tagTypeMap[type] });
    } catch (error) {}
  };
};
