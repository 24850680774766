import ReportingFilter from "components/reportingFilter/ReportingFilter";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createDateRange, dateRange30 } from "../../../Library/Common/utils/performanceUtils";
import UserPerformanceBody from "./UserPerformanceBody";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import UserPerformanceDrilldown from "./UserPerformanceDrilldown";
import { NotImplemented } from "components";
import { type SelectedView, type UserAssetTypes } from "./types";

import styles from "./userPerformance.module.scss";
import { ReportingExport } from "components/reportingExport/ReportingExport";
import {
  selectExportInfo,
  selectExporting,
  setExportAction,
  selectHasDataToExport,
  setHasDataToExport,
  reset,
} from "features/Reporting/state/export/exportSlice";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { type ConnectedProps, connect } from "react-redux";
import {
  getPeopleOutreachExport as getUserOutreachExport,
  getPeopleInteractionExport as getUserInteractionExport,
  getPeopleActivityExport as getUserActivityExport,
} from "features/Reporting/People/actions/peopleActions";

export type Props = PropsFromRedux & {
  userId: number;
};

export const UserPerformance: React.FC<Props> = ({
  userId,
  setExport,
  exporting,
  exportInfo,
  getUserActivityExport,
  getUserInteractionExport,
  getUserOutreachExport,
  hasDataToExport,
}) => {
  const [filter, setFilter] = useState(dateRange30());
  const [activeTab, setActiveTab] = useState(0);
  const [selectedType, setSelectedType] = useState<UserAssetTypes>();
  const [selectedView, setSelectedView] = useState<SelectedView>();
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();
  const reportEnabled = !!useFeatureFlag(FeatureFlags.UserPerformance);

  const dateRange = useMemo(() => createDateRange(filter.dateFrom, filter.dateTo), [filter.dateFrom, filter.dateTo]);

  const handleContentSelection = useCallback(
    (type: UserAssetTypes, view: SelectedView) => {
      setSelectedType(type);
      setSelectedView(view);
      registerBreadcrumb({
        text: type,
        action: () => {
          setSelectedType(undefined);
        },
      });
    },
    [registerBreadcrumb],
  );

  // Setting appropriate export method
  useEffect(() => {
    if (selectedView) {
      setExport({
        // @ts-ignore
        method: selectedView === "interaction" ? getUserInteractionExport : getUserOutreachExport,
        args: [{ ...filter, type: selectedType, userId }],
      });
    } else {
      setExport({
        // @ts-ignore
        method: getUserActivityExport,
        args: [{ ...filter, userId }],
      });
    }
  }, [
    filter,
    getUserActivityExport,
    getUserInteractionExport,
    getUserOutreachExport,
    selectedView,
    selectedType,
    setExport,
    userId,
  ]);

  const handleExportClick = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const contentSelected = selectedType && selectedView;

  if (!reportEnabled) {
    return (
      <div className={styles.reportUnavailable}>
        <NotImplemented message="Check back for analytics, reports and more!" />;
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        {hasBreadcrumbsToDisplay ? <div>{domElements}</div> : <h2>Summary</h2>}
        <div className={styles.performanceHeader}>
          <ReportingFilter
            currentFilter={filter}
            callback={({ dateFrom, dateTo }) => setFilter({ dateFrom, dateTo })}
          />
          <ReportingExport onClick={handleExportClick} currentlyExporting={exporting} disabled={!hasDataToExport} />
        </div>
      </div>
      {contentSelected ? (
        <UserPerformanceDrilldown userId={userId} type={selectedType} view={selectedView} filter={filter} />
      ) : (
        <UserPerformanceBody
          activeTab={activeTab}
          userId={userId}
          setActiveTab={setActiveTab}
          filter={filter}
          dateRange={dateRange}
          handleContentSelection={handleContentSelection}
        />
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    exportInfo: selectExportInfo(state),
    exporting: selectExporting(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getUserOutreachExport: bindAction(getUserOutreachExport, dispatch),
    getUserInteractionExport: bindAction(getUserInteractionExport, dispatch),
    getUserActivityExport: bindAction(getUserActivityExport, dispatch),
    setIsExportEnabled: bindAction(setHasDataToExport, dispatch),
    setExport: bindAction(setExportAction, dispatch),
    resetExport: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(UserPerformance);
export default ConnectedComponent;
