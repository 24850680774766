import axios from "axios";
import { type DistributedOperationResult } from "../../../types";
import { type ApiAccessType, type ApiClient } from "../types";

interface ExternalApiDataService {
  getExternalApiClients(moboId: string | undefined): Promise<ApiClient[]>;

  createExternalApiClient(accessType: ApiAccessType, MoboId?: string | undefined): Promise<DistributedOperationResult>;

  updateExternalApiClient(moboId: number, updates: { enabled: boolean }): Promise<DistributedOperationResult>;

  regenerateClientSecret(moboId: number): Promise<string>;
}

export const externalApiRootUrl = "/api/external-api";

export const regenerateSecretUrl = (moboId: number) => `/api/external-api/${moboId}/secrets`;

export default {
  async getExternalApiClients(MoboId?: string) {
    const response = await axios.get(externalApiRootUrl, MoboId ? { headers: { MoboId } } : undefined);
    return response.data;
  },

  async createExternalApiClient(apiAccessType: ApiAccessType, MoboId?: string) {
    const { data: result } = await axios.post<DistributedOperationResult>(
      externalApiRootUrl,
      { apiAccessType },
      MoboId ? { headers: { MoboId } } : undefined,
    );
    return result;
  },
  async updateExternalApiClient(id: number, updates: { enabled: boolean }) {
    const { data: result } = await axios.put<DistributedOperationResult>(`${externalApiRootUrl}/${id}`, updates);
    return result;
  },
  async regenerateClientSecret(moboId: number) {
    const response = await axios.put<{ clientSecret: string }>(regenerateSecretUrl(moboId));
    const { clientSecret } = response.data;

    return clientSecret;
  },
} as ExternalApiDataService;
