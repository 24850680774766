import * as actionTypes from "../actionTypes/eventEntityStateActionTypes";
import midnightService from "../../../../Application/services/midnightService/midnightService";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import DraftEntityTypes from "../../../../../enums/draftEntityTypes";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { type CreateEventData, type EventPayload, type SessionsPayload } from "../../types/state";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  EventInfoEditSuccess,
  EventEditSessionsSuccess,
  EventPublishSuccess,
  ExternalEventEditModeSuccess,
  EventDiscardedSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { type NotifyStepSettings } from "../../../../../interfaces";

export const createEvent = (data: CreateEventData) => {
  const begin = getActionBaseProvider(actionTypes.createEventBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createEventSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createEventFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(DraftEntityTypes.Events, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateEvent = (metadata: EventPayload) => {
  const begin = getActionBaseProvider(actionTypes.updateEventBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateEventFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: EventInfoEditSuccess }));
    try {
      await dataService.events.updateEventAsync(metadata);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateSessions = (data: SessionsPayload) => {
  const begin = getActionBaseProvider(actionTypes.updateEventBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateEventFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: EventEditSessionsSuccess }));
    try {
      await dataService.events.updateSessionsAsync(data);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftEventEntity = (id: number, notificationSettings?: NotifyStepSettings) => {
  const begin = getActionBaseProvider(actionTypes.publishEventBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishEventFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EventPublishSuccess }));
    try {
      await midnightService.releaseLock(
        DraftEntityTypes.Events,
        id,
        notificationSettings ? { notificationSettings } : undefined,
      );
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchDraftEventEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedEventBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedEventFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: ExternalEventEditModeSuccess }));
    try {
      await midnightService.getEntityLock(DraftEntityTypes.Events, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchLockedEventSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedEventSuccess);

export const fetchDiscardEventEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardEventBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardEventFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EventDiscardedSuccess }));
    try {
      await midnightService.discardLock(DraftEntityTypes.Events, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const resetEventEntityState = getActionBaseProvider(actionTypes.resetEventEntityState);
export const setEventEntityIdAction = getActionProvider<number>(actionTypes.setEventEntityId);
export const fetchDiscardEventSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardEventSuccess);
export const publishDraftEventSuccess = getActionProvider<MidnightActionPayload>(actionTypes.publishEventSuccess);

export const updateEventCommandSuccess = getActionProvider(
  actionTypes.updateEventCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const eventUpdated = getActionProvider(actionTypes.eventUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));
