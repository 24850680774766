import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { selectOnedriveActivityFileCounts } from "../graphSlice";
import { type ColumnDataPoint } from "../../../../../components/charts/types/ColumnChart";
import { startsColor } from "features/Library/Common/utils/performanceUtils";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";

export const selectActiveUsersOneDriveSummations = createSelector(selectOnedriveActivityFileCounts, (state) => {
  return state.value.map((s) => ({
    date: moment(s.ReportDate).toDate(),
    totalCount: s.SharedExternally + s.SharedInternally + s.Synced + s.ViewedOrEdited,
  }));
});
export const selectActiveUsersOneDrive = createSelector(
  selectActiveUsersOneDriveSummations,
  (state): ColumnDataPoint[] => {
    return state.map((a, i) => ({
      id: i.toString(),
      category: moment(a.date).format(SERVER_DATE_ONLY_FORMAT),
      value: a.totalCount,
      fillColor: startsColor,
    }));
  },
);

export const selectFilesViewedOrEdited = createSelector(selectOnedriveActivityFileCounts, (state) => {
  const totalSize = state.value.length;
  let returnVal = {
    dates: Array<Date>(totalSize),
    ViewedOrEdited: Array<number>(totalSize),
  };

  state.value.forEach((s, index) => {
    returnVal.dates[index] = moment(s.ReportDate).toDate();
    returnVal.ViewedOrEdited[index] = s.ViewedOrEdited;
  });

  return returnVal;
});

export const selectFileSharing = createSelector(selectOnedriveActivityFileCounts, (state) => {
  const totalSize = state.value.length;
  let returnVal = {
    dates: Array<Date>(totalSize),
    SharedInternally: Array<number>(totalSize),
    SharedExternally: Array<number>(totalSize),
  };

  state.value.forEach((s, index) => {
    returnVal.dates[index] = moment(s.ReportDate).toDate();
    returnVal.SharedInternally[index] = s.SharedInternally;
    returnVal.SharedExternally[index] = s.SharedExternally;
  });

  return returnVal;
});

export const selectFilesSynced = createSelector(selectOnedriveActivityFileCounts, (state) => {
  const totalSize = state.value.length;
  let returnVal = {
    dates: Array<Date>(totalSize),
    Synced: Array<number>(totalSize),
  };

  state.value.forEach((s, index) => {
    returnVal.dates[index] = moment(s.ReportDate).toDate();
    returnVal.Synced[index] = s.Synced;
  });

  return returnVal;
});
