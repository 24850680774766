import { FETCH_MOST_ACCESSED_ACCOUNTS_SUCCESS } from "./mostAccessedAccountsActionTypes";

export interface MostAccessedAccountsState {
  items: any[];
}

const initialState = {
  items: [],
};

const mostAccessedAccountsReducer = (currentState: MostAccessedAccountsState | undefined, action: any) => {
  const state = currentState || initialState;

  if (action.type === FETCH_MOST_ACCESSED_ACCOUNTS_SUCCESS) {
    return {
      ...state,
      items: action.items,
    };
  }

  return state;
};

export default mostAccessedAccountsReducer;
