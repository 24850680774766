import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

export interface Props {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
}

const RestoreLinkButton: React.FC<Props> = (props) => {
  const { onClick, isDisabled, className } = props;

  return (
    <BasicLinkButton
      text={"Restore"}
      iconClassName="fa fa-undo"
      onClick={onClick}
      isDisabled={isDisabled}
      className={className}
    />
  );
};

export default RestoreLinkButton;
