import type { IFlowEdge, IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import type { EntityTriggerTypes, EntityType, Trigger, TriggerTimeUnit } from "../../types";
import {
  getDestinationOption,
  getResponseTypeContent,
  getTimeUnitsOptions,
  getTypeOptions,
} from "../../../utils/triggersDataUtils";

const prepareTrigger = (
  edgeId: string,
  nodes: IFlowNode[],
  edges: IFlowEdge[],
  triggerTypes: EntityTriggerTypes[],
  triggerTimeUnits: TriggerTimeUnit[],
): Trigger | undefined => {
  const selectedEdge: IFlowEdge | undefined = edges.find((edge) => edge.id === edgeId) as IFlowEdge;

  if (edges) {
    const { source, target, data, sourceHandle } = selectedEdge;
    const sourceNodeOfSelectedEdge = nodes.find((node) => node.id === selectedEdge?.data?.inId);
    const targetNode = nodes.find((node) => node.id === selectedEdge?.data?.outId);
    const destinationOption = getDestinationOption(targetNode);
    const typeId = data?.typeId || 0;
    const triggerOptions = getTypeOptions(
      triggerTypes,
      (sourceNodeOfSelectedEdge?.type !== "StartOfTheFlow" && sourceNodeOfSelectedEdge?.type !== "Placeholder"
        ? sourceNodeOfSelectedEdge?.type || ""
        : "") as "" | EntityType,
      typeId,
    );
    const unitsOptions = getTimeUnitsOptions(triggerTimeUnits);
    const timeUnitId = data?.timeUnitId || 0;
    const responseTypeContent = getResponseTypeContent(
      typeId,
      sourceNodeOfSelectedEdge?.data?.branchingQuestion,
      sourceHandle,
    );

    // to-do Extend triggerTypeContent property
    return {
      inId: source,
      outId: target,
      bulletId: sourceHandle,
      timeSpan: data?.timeSpan || 0,
      typeId,
      timeUnitId,
      triggerTypeContent: { ...responseTypeContent },
      source,
      destination: target,
      destinationOption,
      trigger: typeId,
      triggerOptions,
      units: timeUnitId,
      unitsOptions,
      delay: data?.timeSpan?.toString() || "",
      isAction: data?.isAction || false,
      isDefault: data?.isDefault || false,
    };
  }
};

export const prepareTriggers = (
  nodes: IFlowNode[],
  edges: IFlowEdge[],
  selectedEdges: IFlowEdge[],
  triggerTypes: EntityTriggerTypes[],
  triggerTimeUnits: TriggerTimeUnit[],
): Trigger[] => {
  const updatedTriggersData: Trigger[] = [];

  if (selectedEdges && selectedEdges.length) {
    selectedEdges.forEach(({ id }) => {
      const trigger = prepareTrigger(id, nodes, edges, triggerTypes, triggerTimeUnits);
      trigger && updatedTriggersData.push(trigger);
    });
  }
  return updatedTriggersData;
};
