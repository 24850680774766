import React from "react";

import { baseIconButtonPropTypes } from "../baseIconButtonPropTypes";
import BaseIconButton from "../BaseIconButton";

function CropButton(props) {
  return <BaseIconButton iconClassName="crop" {...props} />;
}

CropButton.propTypes = baseIconButtonPropTypes;

export default CropButton;
