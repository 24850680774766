import { TriggerableConfirmationModal } from "components";
import { type IObservable } from "interfaces";
import React, { useState } from "react";

interface Props {
  assetType: string;
  observer: IObservable<(onConfirm: () => void, visible: boolean, ids: number[]) => void>;
}

export const AssetVisibilityConfirmationModal = (props: Props) => {
  const { observer, assetType} = props;
  const [cfg, setCfg] = useState({ visible: false, multi: false });
  const entity = cfg.multi ? `${assetType}s` : assetType;
  const getContent = () => {
    if (!cfg.visible) {
      return `This action will make the content undiscoverable for end-users, 
                though their progress will remain intact. Are you sure you want to proceed?`;
    }

    return `This action will make the content discoverable for end-users, 
        allowing them to resume their progress. Are you sure you want to proceed?`;
  };

  return (
    <TriggerableConfirmationModal
      content={getContent()}
      onTriggerModalObserver={observer}
      title={!cfg.visible ? `Hide ${entity}` : `Unhide ${entity}`}
      onTrigger={(visible: boolean, ids: number[]) => {
        setCfg({ visible: visible, multi: ids.length > 1 });
      }}
    />
  );
};
