import React from "react";
import PropTypes from "prop-types";
import { some } from "lodash";

import "./fileUploadSection.scss";

import { ValidatedForm } from "../forms";
import fileUtils from "../../utils/fileUtils";

class FileUploadSection extends React.Component {
  onUploadFileProgress = (progressEvent) => {
    const { updateProgress } = this.props;
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    updateProgress(percentCompleted);
  };

  handleFileChange = async (files) => {
    const { uploadFile, accountId, acceptCancellationHandler, actorId, onFileChange } = this.props;

    if (files.length === 1) {
      const uploadResult = await uploadFile(
        accountId,
        actorId,
        fileUtils.resolveMimeType(files[0]),
        files[0],
        this.onUploadFileProgress,
        acceptCancellationHandler,
      );

      let fileName = uploadResult && uploadResult.fileName;
      onFileChange && onFileChange(fileName, files[0].name);
    }
  };

  async componentDidMount() {
    const { initialFiles } = this.props;

    if (some(initialFiles)) {
      try {
        await this.handleFileChange(initialFiles);
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const {
      uploadProgress,
      uploadError,
      isUploading,
      acceptFileFormat,
      label,
      propertyName,
      validationSchema,
      children,
      cancelFileUpload,
      initialFiles,
    } = this.props;

    return (
      <section className="file-upload-section">
        {children}
        <ValidatedForm
          validationSchema={validationSchema}
          initialValues={{ [propertyName]: initialFiles }}
          formWidthComputer={10}
          isInitialValid={false}
        >
          {(props) => {
            const { validatedFieldProps, values } = props;
            return (
              <ValidatedForm.UploadFileField
                id="file-upload-section"
                label={label}
                loadingButtonLabel="Loading..."
                accept={acceptFileFormat}
                value={values[propertyName]}
                propertyName={propertyName}
                isUploading={isUploading}
                uploadingProgress={uploadProgress}
                uploadFilesHandler={this.handleFileChange}
                onCancelFileUploading={cancelFileUpload}
                uploadingError={uploadError}
                filesValidator={validationSchema.fields[propertyName]}
                showPercents={false}
                persistProgressBarOnSuccess
                {...validatedFieldProps}
              />
            );
          }}
        </ValidatedForm>
      </section>
    );
  }
}

FileUploadSection.propTypes = {
  propertyName: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  actorId: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  cancelFileUpload: PropTypes.func.isRequired,
  updateProgress: PropTypes.func.isRequired,
  acceptCancellationHandler: PropTypes.func.isRequired,

  uploadError: PropTypes.object,
  acceptFileFormat: PropTypes.string,
  label: PropTypes.string,
  onFileChange: PropTypes.func,
};

export default FileUploadSection;
