import { type DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import accountsDataService from "../../../services/accountsDataService";

export const deleteUsers = (userIds: number[], accountId: number) => {
  return async (dispatch: AppDispatch) => {
    const usersCount = userIds.length;

    if (!usersCount) {
      return;
    }

    const updateParams: DistributedOpUpdateParams = {
      id: `DeleteUserFromAccount`,
      title: `Delete user(s) from account`,
      indeterminate: true,
      getOperationProps: async () => {
        const { data } = await accountsDataService.removeUsersFromAccount(accountId, userIds);
        return data;
      },
      successTransientMessage: `The selected user(s) have been removed successfully.`,
      failureTransientMessage: `Removing user(s) from the account failed!`,
      successTitle: `User(s) Removed!`,
    };
    backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
