import PropTypes from "prop-types";

function TabsPane(_) {
  return null; // parent will take care of rendering
}

TabsPane.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default TabsPane;
