import React from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { type SendingProfilesNoResultsProps as ThreatDefenceSendingProfilesNoResultsProps } from "../types";

function ThreatDefenceSendingProfilesNoResults(props: ThreatDefenceSendingProfilesNoResultsProps) {
  const description = (
    <span>
      Looks like you don't have any sending profiles yet.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults title="You don’t have any sending profiles" description={description} iconClassName="fa-envelope">
      {props.createButton}
    </NoResults>
  );
}

export default ThreatDefenceSendingProfilesNoResults;
