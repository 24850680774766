import { connect, type ConnectedProps } from "react-redux";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";

import { bindAction } from "interfaces";

import {
  selectSendDensityState,
  selectEngagmentState,
  selectSendDensityLineData,
  selectEngagementChart,
} from "../../../state/slices/emailPerformanceSlice";
import { fetchEmailSendDensity, fetchEmailSendFunnel } from "../../../state/thunks/emailPerformanceThunk";
import { useEffect, useMemo, useState } from "react";
import {
  dailyActivity,
  emailReportColorScale,
  getBarDomain,
  lineChartMargins,
  lineChartTitles,
  noBarData,
  noData,
  type PerformanceWithAccountFilter,
  totalActivity,
  transitionTime,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, LineChart, TickValueType, getDateFormatByCount, HorizontalBarChart } from "components/charts";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";

export interface Props extends PropsFromRedux {
  dateFilter: PerformanceWithAccountFilter;
  emailId: number;
  sendSessionId: number | undefined;
  flowId?: number;
}

export const EmailPerformanceCharts = ({
  dateFilter,
  emailId,
  sendSessionId,
  flowId,
  sendDensityState,
  sendFunnelState,
  sendDensityLineData,
  engagementData,
  fetchEmailSendDensity,
  fetchEmailSendFunnel,
}: Props) => {
  const [lineChartType, setLineChartType] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  useEffect(() => {
    if (reportEnabled) {
      fetchEmailSendDensity({ emailId, sendSessionId }, { ...dateFilter, flowId });
      fetchEmailSendFunnel({ emailId, sendSessionId }, { ...dateFilter, flowId });
    }
  }, [dateFilter, emailId, sendSessionId, fetchEmailSendDensity, fetchEmailSendFunnel, flowId, reportEnabled]);

  const lineData = useMemo(() => {
    const xData = [sendDensityLineData.Date, sendDensityLineData.Date, sendDensityLineData.Date];
    if (lineChartType === dailyActivity) {
      return {
        xData,
        yData: [sendDensityLineData.Sends, sendDensityLineData.Opens, sendDensityLineData.Clicks],
      };
    } else {
      return {
        xData,
        yData: [
          sendDensityLineData.CumulativeSends,
          sendDensityLineData.CumulativeOpens,
          sendDensityLineData.CumulativeClicks,
        ],
      };
    }
  }, [lineChartType, sendDensityLineData]);

  return (
    <div className="graphs">
      <div className="lineChartContainer">
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          onChange={setLineChartType}
          colorRange={emailReportColorScale}
        >
          <RequestStatusRenderer state={sendDensityState}>
            {validLineData([
              sendDensityLineData.Sends,
              sendDensityLineData.Opens,
              sendDensityLineData.Clicks,
              sendDensityLineData.CumulativeSends,
              sendDensityLineData.CumulativeOpens,
              sendDensityLineData.CumulativeClicks,
            ]) ? (
              <LineChart
                margins={lineChartMargins}
                {...lineData}
                xScaleRatio={timeScale}
                yTickValueType={TickValueType.IntegersOnly}
                xFormatterFunc={getDateFormatByCount(sendDensityState.value.length)}
                transitionDuration={transitionTime}
                colorRange={emailReportColorScale}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="funnelChartContainer funnel-chart-container">
        <ChartWrapper
          titles={["Engagement"]}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          colorRange={emailReportColorScale}
        >
          <RequestStatusRenderer state={sendFunnelState}>
            {!noBarData(...engagementData.map((d) => d.value)) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                data={engagementData}
                domain={getBarDomain(engagementData)}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    sendDensityState: selectSendDensityState(state),
    sendFunnelState: selectEngagmentState(state),
    sendDensityLineData: selectSendDensityLineData(state),
    engagementData: selectEngagementChart(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchEmailSendDensity: bindAction(fetchEmailSendDensity, dispatch),
    fetchEmailSendFunnel: bindAction(fetchEmailSendFunnel, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EmailPerformanceCharts);
export default ConnectedComponent;
