import * as Yup from "yup";

import { labels, softwareApplications } from "./tagsValidationSchema";
import { CUSTOM_EMAIL } from "../emailUtils";

const titleLengths = {
  min: 3,
  max: 128,
};

const subjectLengths = {
  min: 3,
  max: 256,
};

const senderLengths = {
  min: 1,
  max: 128,
};

const previewTextMaxLength = 256;

export const subject = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Subject Line is required")
    .min(subjectLengths.min, `Subject Line must have at least ${subjectLengths.min} characters`)
    .max(subjectLengths.max, `Subject Line must be maximum of ${subjectLengths.max} characters long`);

const title = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Title is required")
    .min(titleLengths.min, `Title must have at least ${titleLengths.min} characters`)
    .max(titleLengths.max, `Title must be maximum of ${titleLengths.max} characters long`);

const utmCampaign = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Campaign Name is required")
    .min(titleLengths.min, `Campaign Name must have at least ${titleLengths.min} characters`)
    .max(titleLengths.max, `Campaign Name must be maximum of ${titleLengths.max} characters long`);

export const previewText = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .max(previewTextMaxLength, `Preview Text must be maximum of ${previewTextMaxLength} characters long`);

export const senderName = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Sender name is required")
    .min(senderLengths.min, `At least one character should be inserted`)
    .max(senderLengths.max, `Sender Name is too long. The maximum allowed length is ${senderLengths.max} characters`);

export const senderEmail = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Sender email is required")
    .matches(CUSTOM_EMAIL, "Invalid Email")
    .max(senderLengths.max, `Sender Email is too long. Maximum allowed length is ${senderLengths.max} characters`)
    .test("valid-domain", `This email domain is not allowed`, (emailValue) => {
      const forbiddenDomainsList = [
        "gmail",
        "googlemail",
        "outlook",
        "hotmail",
        "live",
        "msn",
        "yahoo",
        "ymail",
        "rocketmail",
        "icloud",
        "me",
        "aol",
        "aim",
        "mail",
        "email",
        "protonmail",
        "zoho",
        "zoho.eu",
        "gmx",
        "hotmail",
      ].join("|");
      return !String(emailValue).toLowerCase().match(`(${forbiddenDomainsList})(\\.com)`);
    });

export const emailInfo = Yup.object().shape({
  title: title(),
  subject: subject(),
  previewText: previewText(),
  labels,
  softwareApplications,
  utmCampaign: utmCampaign(),
});

export const emailTemplate = Yup.object().shape({
  subject: subject(),
  previewText: previewText(),
});

export const senderInfo = Yup.object().shape({
  senderName: senderName(),
  senderEmail: senderEmail(),
});
