import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import {
  createLazyFetchingItemsSlice,
  type LazyFetchingItemsState,
} from "../../../../../Application/slices/createLazyFetchingItemsSlice";
import { type ItemsToDropBase } from "../../types";

export type FlowDesignerItemsToDropState = LazyFetchingItemsState<ItemsToDropBase>;

export const initialState: FlowDesignerItemsToDropState = {
  itemsCount: 0,
  items: [],
  isLoading: false,
  areAllLoaded: false,
};

const flowDesignerItemsToDropSlice = createLazyFetchingItemsSlice<ItemsToDropBase>({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "itemsToDrop",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = flowDesignerItemsToDropSlice.actions;

export default flowDesignerItemsToDropSlice.reducer;
