import { type ThreatDefenceFeedbackPageOverview } from "../../features/Library/SimulatedPhishing/FeedbackPages/types/state";
import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { EditLinkButton } from "../buttons/linkButtons";

export interface FeedbackPageEllipsisPopupButtonProps {
  feedbackPage: ThreatDefenceFeedbackPageOverview;
  circle: boolean;
  outlinedEllipsis: boolean;
  disabled?: boolean;
  onEdit: (id: number, isDraft: boolean) => void;
}

function FeedbackPageEllipsisPopupButton(props: FeedbackPageEllipsisPopupButtonProps) {
  const { onEdit, feedbackPage, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={() => onEdit(feedbackPage.id, feedbackPage.isDraft)} />
    </EllipsisPopupButton>
  );
}

export default FeedbackPageEllipsisPopupButton;
