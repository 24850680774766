import React from "react";
import { pluralize } from "../../../utils/stringUtils";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface RemoveGroupsConfirmationModalProps {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
  groupsCount: number;
}

export default function RemoveGroupsConfirmationModal(props: RemoveGroupsConfirmationModalProps) {
  const { onCancel, onContinue, groupsCount, open } = props;

  return (
    <ActionConfirmationModal
      open={open}
      onCancel={onCancel}
      onContinue={onContinue}
      title={`Remove ${pluralize("Group", groupsCount)}?`}
      size={"tiny"}
      withWarningLabel={false}
      confirmLabel="Remove"
    >
      Are you sure you want to remove ({groupsCount}) {pluralize("group", groupsCount)} from this user? User will loose
      access to all content, etc...
    </ActionConfirmationModal>
  );
}

RemoveGroupsConfirmationModal.defaultProps = {
  open: false,
};
