import { isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import { type Dispatch } from "@reduxjs/toolkit";
import { type RootState } from "features/Application/globaltypes/redux";
import { useRtn } from "hooks/useRtn";
import { type ConnectedProps, connect } from "react-redux";
import { GenericSectionHeader } from "../../../../../components";
import FormFooter from "../../../../../components/forms/FormFooter";
import Segments from "../../../../../components/navigation/segments/Segments";
import EditNotifySettings from "../../../../../components/notifyStep/EditNotifySettings/EditNotifySettings";
import PreventTransitionPrompt from "../../../../../components/preventTransition/PreventTransitionPrompt";
import { RouteNames, TemplateTypes } from "../../../../../enums";
import RolePermissions from "../../../../../enums/rolePermissions";
import navigationUtils from "../../../../../utils/navigationUtils";
import Restricted from "../../../../Application/Restricted";
import IntegrationConfiguration from "../../Configuration/IntegrationConfiguration";
import { NotifyBannerText } from "../../CreateIntegration/NotifyBannerText";
import { IntegrationDtoMap, overviewMoboPath } from "../../Shared";
import { generateNotificationId } from "../../connectors/factory";
import {
  type BaseIntegrationConfigs,
  IntegrationTypes,
  type MsGraphIntegrationConfigs,
  type NotificationIntegrationConfig,
  type UsageIntegrationConfig,
} from "../../types";
import ActivityLogs from "../ActivityLogs/ActivityLogs";

import MsGraphIntegrationConfiguration from "../../Configuration/MsGraphIntegrationConfiguration";
import "./editIntegration.scss";

export interface EditIntegrationProps extends PropsFromRedux {
  isLoading: boolean;
  rtnEvents: Array<string>;
  integration?: BaseIntegrationConfigs;
  onSaveHandler: Function;
  integrationUrl: string;
  integrationType: IntegrationTypes;
  isAuthorized?: boolean;
  moboId?: string;
}

const EditIntegrationBase: React.FC<EditIntegrationProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { integration, isLoading, rtnEvents, integrationType, integrationUrl, moboId, dispatch, asyncOperations } =
    props;

  const integrationDto = IntegrationDtoMap[integrationType.toLowerCase() as IntegrationTypes];
  const isAuthorized = props.isAuthorized === true || props.isAuthorized === undefined;
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [originConfigs, setOriginConfigs] = useState<BaseIntegrationConfigs>();
  const [configs, setConfigs] = useState<BaseIntegrationConfigs | undefined>(integration);

  const isDirty = !isEqual(configs, originConfigs);

  const ensureUserNotificationIdExists = (currentConfigs: NotificationIntegrationConfig) => {
    if (isEmpty(currentConfigs.userNotificationId)) {
      return { ...currentConfigs, userNotificationId: generateNotificationId(integrationType) };
    }

    return currentConfigs;
  };

  useEffect(() => {
    const integrationConfigPath = `${RouteNames.integrationsOverviewUrl}/${integrationType}`;
    if (location.pathname === integrationConfigPath && isDirty) {
      resetConfigs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isLoading && integration) {
      const updatedConfigs = ensureUserNotificationIdExists(integration as NotificationIntegrationConfig);
      setConfigs(updatedConfigs);
      setOriginConfigs(integration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration]);

  const resetConfigs = () => setConfigs(originConfigs);
  const onSave = async () => {
    try {
      setIsSavingInProgress(true);
      await props.onSaveHandler(originConfigs!, configs, moboId);
      setOriginConfigs(configs);
    } catch {
      setIsSavingInProgress(false);
    }
  };

  const useOnSuccessfulUpdate = useCallback(() => setIsSavingInProgress(false), []);

  useRtn(rtnEvents, useOnSuccessfulUpdate, moboId, dispatch, asyncOperations);

  const onNotifySettingsSaved = () => {
    if (
      (configs as NotificationIntegrationConfig)?.userNotificationId !==
      (originConfigs as NotificationIntegrationConfig)?.userNotificationId
    ) {
      return onSave();
    }
  };

  const renderLoader = () => (
    <Dimmer active={isLoading || isSavingInProgress} inverted>
      <Loader active={isLoading || isSavingInProgress} />
    </Dimmer>
  );

  const renderNotifySettings = () => {
    if (!configs || isLoading) {
      return;
    }

    return (
      <EditNotifySettings
        templateType={TemplateTypes.WelcomeEmail}
        notificationId={(configs as NotificationIntegrationConfig).userNotificationId}
        onSaved={onNotifySettingsSaved}
        switchLabelText="Notify recipient(s) once synced"
        infoBannerContent={<NotifyBannerText />}
      />
    );
  };

  const renderIntegrationConfiguration = () => {
    return integrationType === IntegrationTypes.MsGraph ? (
      <MsGraphIntegrationConfiguration
        integrationName={integrationDto.title}
        setConfigs={setConfigs}
        configs={configs as MsGraphIntegrationConfigs}
      />
    ) : (
      <IntegrationConfiguration
        integrationName={integrationDto.title}
        setConfigs={setConfigs}
        integrationType={integrationType}
        configs={configs as UsageIntegrationConfig}
      />
    );
  };

  return (
    <Restricted permissions={[RolePermissions.AccountsSettingsManage]}>
      <section className="edit-integration nested-content">
        <GenericSectionHeader
          title={integrationDto.title}
          goBackAction={() =>
            navigationUtils.goBackOrDefault(
              location,
              navigate,
              moboId ? overviewMoboPath(moboId) : RouteNames.integrationsOverviewUrl,
            )
          }
        />
        <Segments to={integrationUrl}>
          <Segments.Segment label="Configuration" />
          <Segments.Segment label="Activity Log" to="activity-log" />
          <Segments.Segment label="Notify" to="notify" />
        </Segments>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {renderLoader()}
                {configs && !isLoading && renderIntegrationConfiguration()}
                <FormFooter
                  isSaveBtnDisabled={!isDirty && isAuthorized}
                  isCancelBtnDisabled={!isDirty}
                  onSave={onSave}
                  onCancel={resetConfigs}
                  saveButtonTitle={isAuthorized ? "Save Changes" : "Authorize"}
                />
                <PreventTransitionPrompt
                  when={isDirty}
                  title="Exit Without Saving?"
                  message="Are you sure you want to exit without saving your changes? All information entered will be lost."
                />
              </>
            }
          />
          <Route path="activity-log" element={<ActivityLogs type={integrationType} />} />
          <Route
            path="notify"
            element={
              <>
                {renderLoader()}
                {renderNotifySettings()}
              </>
            }
          />
        </Routes>
      </section>
    </Restricted>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    asyncOperations: state.asyncOperations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditIntegrationBase);
export default ConnectedComponent;
