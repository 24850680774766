import { useCallback, useMemo } from "react";
import { useNodesFilter, ViewPortType } from "../../AlignmentSmartGuides/hooks/useNodesFilter";
import { type IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import { type FlowValidationError, ValidatorErrorTypes } from "../../validator/flowValidatorReducer";
import { useReactFlow } from "reactflow";

const nodeFilterOptions = { viewPortType: ViewPortType.ValidationErrors, ignoreStartOfTheFlow: true };

export default function useErrorNavigationUtils() {
  const { getNodes, getNode } = useReactFlow();
  const { nodesFilter, refreshViewPort } = useNodesFilter(nodeFilterOptions);

  const visibleNodes: IFlowNode[] = useMemo(() => {
    const nodes = getNodes();
    return nodes.filter(nodesFilter);
  }, [nodesFilter, getNodes]);

  const getTargetNode = useCallback<(id: string) => IFlowNode | undefined>(
    (id: string) => {
      return getNode(id);
    },
    [getNode],
  );

  const getItemId = useCallback((error: FlowValidationError): string | undefined => {
    if (error.type === ValidatorErrorTypes.StartError) {
      return "start-of-the-flow-element";
    }

    if (error.type === ValidatorErrorTypes.WithinError) {
      return error.inId;
    }

    if (
      error.type === ValidatorErrorTypes.ItemError ||
      error.type === ValidatorErrorTypes.TriggerError ||
      error.type === ValidatorErrorTypes.FlowEndError ||
      error.type === ValidatorErrorTypes.SectionHeaderNameError ||
      error.type === ValidatorErrorTypes.SectionHeaderDescriptionError ||
      error.type === ValidatorErrorTypes.ConnectionActionTriggersError ||
      error.type === ValidatorErrorTypes.DeletedItemError
    ) {
      return error.itemId;
    }
  }, []);

  return { getTargetNode, getItemId, visibleNodes, refreshViewPort };
}
