import { type PayloadAction } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, type FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { type VideoFiltersEnum } from "../../../../Library/Videos/types/models";

export type PackContentFilters = VideoFiltersEnum;
export interface PackContentFiltersState extends FiltersState<VideoFiltersEnum> {
  search: string;
}

export const initialState: PackContentFiltersState = {
  filterOptions: {} as GenericFiltersMap<PackContentFilters>,
  appliedFilter: {} as GenericFiltersMap<PackContentFilters>,
  isLoading: false,
  search: "",
};

export const packContentFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "contentFilters",
  },
  initialState: initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
  },
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  resetFilter,
  setFilterOptions,
  setSearch,
} = packContentFiltersSlice.actions;

export default packContentFiltersSlice.reducer;
