import React from "react";
import isEmpty from "lodash/isEmpty";
import { LazyCardsViewerWithHeaderAndPanel } from "../../../../cardsViewer";
import {
  VideosFilterForm,
  FlowFilterForm,
  SurveyFilterForm,
  AssessmentFilterForm,
  PdfsFilterForm,
} from "../../../../filterForms";
import sortOptions from "../../../../../views/library/flows/flowsOverview/sortOptions";
import FlowsNoResults from "../../../../../views/library/flows/flowsOverview/FlowsNoResults";
import AddToFlowCard from "./addToFlowCard/AddToFlowCard";
import AddToEventCard from "./addToEventCard/AddToEventCard";
import AddToVideoCard from "./addToAssetCard/AddToVideoCard";
import AddToItemCard from "./addToItemCard/AddToItemCard";
import { type Filters } from "../../../../../utils/queryUtils";
import {
  ContentTypesEnum,
  AddPeopleToContentTypes,
  RolePermissions,
  type AssignmentPeopleContext,
  RouteNames,
} from "../../../../../enums";
import { CreateFlowButton } from "../../../../../views/library/flows/flowsOverview/FlowsOverviewHeader/CreateFlowButton/CreateFlowButton";
import { VideoAssetsNoResults } from "../../../../../views/library/videos/VideoAssetsNoResults/VideoAssetsNoResults";
import { AddButtonWithPermission } from "../../../../buttons/addButtonWithPermission/AddButtonWithPermission";
import SurveysNoResults from "../../../../../views/library/surveys/Overview/SurveysNoResults";
import EventsNoResults from "../../../../../views/library/events/Overview/EventsNoResults";
import { EventFilterForm } from "../../../../filterForms/EventFilterForm/EventFilterForm";
import AssessmentsNoResults from "../../../../../views/library/assessments/Overview/AssessmentsNoResults";
import PdfsNoResults from "../../../../../views/library/pdfs/contentAssignment/PdfsNoResults";
import { type CardsViewerItem } from "../../../../cardsViewer/types";
import {
  type AvailableItemBase,
  type PeopleAvailableEvent,
  type PeopleAvailableFlow,
  type PeopleAvailableVideo,
  type WithPeopleContext,
} from "../../../../../features/People/ContentAssignments/models";
import { AddContentSegmentationDropdown } from "../../../../dropdowns";

export interface ContentListStepProps {
  renderModalActions: (nextStep: () => void, prevStep: () => void) => Function;
  header: string;
  isLoading: boolean;
  isAllLoaded: boolean;
  loadData: () => void;
  items: WithPeopleContext<AvailableItemBase>[];
  itemsCount: number;
  filter: Filters;
  renderSearch: () => React.ReactNode;
  searchValue: string;
  orderBy?: string;
  filterOptions: any;
  applyFilter: (filter: Filters) => void;
  resetFilter: () => void;
  selectedIds: number[];
  onSelectedItemsChanged: (ids: number[]) => void;
  onSortOptionsChanged: (event: React.SyntheticEvent<HTMLElement>, data: { [key: string]: any; }) => void;
  onContentSegmentationChange: (context: AddPeopleToContentTypes) => void;
  contentType: AddPeopleToContentTypes;
  accessRestricted: boolean;
  selectedPeopleType: AssignmentPeopleContext;
  blur?: boolean;
}

function getPropsByContentType(contentType: AddPeopleToContentTypes, filter: Filters, searchValue: string) {
  switch (contentType) {
    case AddPeopleToContentTypes.Flows:
      return {
        // @ts-ignore
        renderFilterForm: () => <FlowFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<PeopleAvailableFlow>>) => {
          return <AddToFlowCard {...props} item={{ ...props.item, isDraft: props.item.published }} />;
        },
        noResultsContent: (
          <FlowsNoResults byCriteria={!isEmpty(filter) || !!searchValue} createFlowButton={<CreateFlowButton />} />
        ),
      };
    case AddPeopleToContentTypes.Videos:
      return {
        // @ts-ignore
        renderFilterForm: () => <VideosFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<PeopleAvailableVideo>>) => {
          return <AddToVideoCard {...props} />;
        },
        noResultsContent: (
          <VideoAssetsNoResults
            filtered={!isEmpty(filter) || !!searchValue}
            createAssetButton={
              <AddButtonWithPermission
                permissions={[RolePermissions.AssetsCreate]}
                buttonText={"Create Video"}
                goToLink={`/${RouteNames.contentVideos}/createAsset`}
              />
            }
          />
        ),
      };
    case AddPeopleToContentTypes.Events:
      return {
        // @ts-ignore
        renderFilterForm: () => <EventFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<PeopleAvailableEvent>>) => {
          return <AddToEventCard {...props} />;
        },
        noResultsContent: (
          <EventsNoResults
            filtered={!isEmpty(filter)}
            createEventButton={
              <AddButtonWithPermission
                permissions={[RolePermissions.EventsCreate]}
                buttonText={"Create Event"}
                goToLink={`/${RouteNames.contentEvents}/create`}
              />
            }
          />
        ),
      };
    case AddPeopleToContentTypes.Surveys:
      return {
        // @ts-ignore
        renderFilterForm: () => <SurveyFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<AvailableItemBase>>) => {
          return <AddToItemCard {...props} />;
        },
        noResultsContent: (
          <SurveysNoResults
            filtered={!isEmpty(filter)}
            createSurveyButton={
              <AddButtonWithPermission
                permissions={[RolePermissions.AssetsCreate]}
                buttonText={"Create Survey"}
                goToLink={`/${RouteNames.contentSurveys}/create`}
              />
            }
          />
        ),
      };
    case AddPeopleToContentTypes.Assessments:
      return {
        // @ts-ignore
        renderFilterForm: () => <AssessmentFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<AvailableItemBase>>) => {
          return <AddToItemCard {...props} />;
        },
        noResultsContent: (
          <AssessmentsNoResults
            filtered={!isEmpty(filter) || !!searchValue}
            createButton={
              <AddButtonWithPermission
                permissions={[RolePermissions.AssetsCreate]}
                buttonText={"Create Assessment"}
                goToLink="/content/assets/assessments/create"
              />
            }
          />
        ),
      };
    case AddPeopleToContentTypes.PDFs:
      return {
        // @ts-ignore
        renderFilterForm: () => <PdfsFilterForm />,
        renderCard: (props: CardsViewerItem<WithPeopleContext<AvailableItemBase>>) => {
          return <AddToItemCard {...props} />;
        },
        noResultsContent: (
          <PdfsNoResults
            filtered={!isEmpty(filter) || !!searchValue}
            createButton={
              <AddButtonWithPermission
                permissions={[RolePermissions.AssetsCreate]}
                buttonText={"Create PDF"}
                goToLink={`/${RouteNames.contentPdfs}/create`}
              />
            }
          />
        ),
      };
  }
}

const ContentListStep = (props: ContentListStepProps) => {
  const {
    blur,
    renderSearch,
    searchValue,
    loadData,
    contentType,
    items,
    itemsCount,
    isLoading,
    isAllLoaded,
    filter,
    selectedIds,
    applyFilter,
    resetFilter,
    filterOptions,
    onSelectedItemsChanged,
    onSortOptionsChanged,
    onContentSegmentationChange,
    accessRestricted,
    orderBy,
  } = props;

  const renderHeaderContent = () => (
    <AddContentSegmentationDropdown
      onContextChange={onContentSegmentationChange}
      defaultValue={ContentTypesEnum[contentType]}
    />
  );

  const specificProps = getPropsByContentType(contentType, filter, searchValue);

  return (
    <div className="assignment-grid-step">
      <LazyCardsViewerWithHeaderAndPanel
        blur={blur}
        accessRestricted={accessRestricted}
        renderSearch={renderSearch}
        orderBy={orderBy}
        getData={loadData}
        items={items}
        itemsAmount={itemsCount}
        isLoading={isLoading}
        isAllLoaded={isAllLoaded}
        onSelectedItemsChanged={onSelectedItemsChanged}
        onSortOptionsChanged={onSortOptionsChanged}
        selectedItemIds={selectedIds}
        sortOptions={sortOptions}
        filter={filter}
        filterOptions={filterOptions}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        hidePopupMenu
        renderHeaderContent={renderHeaderContent}
        {...specificProps}
      />
    </div>
  );
};

export default ContentListStep;
