import React, { type FC } from "react";
import { Button } from "components/buttons/button/Button";
import { useNavigate } from "react-router-dom";

import { RolePermissions, RouteNames } from "../../../enums";
import Restricted from "../../../features/Application/Restricted";

export interface Props {}

const CreatePackButton: FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
      <Button
        primary
        className="create-pack create-button"
        onClick={() => navigate(`/${RouteNames.licensingPacks}/create`)}
      >
        Create Pack
      </Button>
    </Restricted>
  );
};

export default CreatePackButton;
