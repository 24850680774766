import React, { type PropsWithChildren } from "react";
import { isEqual } from "lodash";
import { Icon } from "semantic-ui-react";

import EllipsisPopupButton from "../../../../../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import Tooltip from "../../../../../../../../../components/common/tooltip/Tooltip";
import { CompletionStatusEnum } from "../../../../../../../../../enums/сompletionStatusEnum";

import styles from "./flowItemMenu.module.scss";

interface FlowModelBase {
  completionStatus: CompletionStatusEnum;
}

export interface FlowItemMenuProps {
  item: FlowModelBase;
  isChecked?: boolean;
  readonly?: boolean;
  circle?: boolean;
  outlinedEllipsis?: boolean;
}

export const FlowItemMenu = (props: PropsWithChildren<FlowItemMenuProps>) => {
  const getTooltip = () => {
    const { item, isChecked } = props;

    if (!isChecked) {
      return undefined;
    }

    if (isEqual(item.completionStatus, CompletionStatusEnum.InProgress)) {
      return (
        <Tooltip
          showAlways
          inverted
          hideOnScroll
          position="top right"
          target={<Icon link className="fa fa-exclamation-circle" size="big" />}
          content="Flow has already started and will be ignored upon manually start."
          tooltipClassName={styles["auto-start-tooltip"]}
        />
      );
    }
  };

  const getMenu = () => {
    const { circle, outlinedEllipsis, readonly, isChecked } = props;
    return (
      <EllipsisPopupButton circle={circle} outlinedEllipsis={outlinedEllipsis} disabled={readonly || isChecked}>
        {props.children}
      </EllipsisPopupButton>
    );
  };

  return <div className={styles.root}>{getTooltip() || getMenu()} </div>;
};
