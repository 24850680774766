import React, { memo, type RefObject, useEffect } from "react";
import { type XYCoord } from "react-dnd";
import { useReactFlow } from "reactflow";
import FlowDesignerDragCard from "../../../../../../components/cards/flowDesignerDragCard/FlowDesignerDragCard";
import { useGuidesOnOuterLayer } from "../../AlignmentSmartGuides/hooks/useGuidesOnOuterLayer";
import { DRAG_SHIFT, ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";
import { useSectionHeaderOnOuterLayer } from "../../SectionHeaders/hooks/useSectionHeaderOnOuterLayer";
import { useReplacementOnOuterLayer } from "../../ItemReplacement/hooks/useReplacementOnOuterLayer";
import { snapToAlignmentGuideLines } from "./utils/snapToAlignmentGuideLines";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";

function getItemStyles(container: RefObject<HTMLDivElement>, zoom: number, clientOffset: XYCoord | null) {
  if (!clientOffset) {
    return {
      display: "none",
      zIndex: 999,
    };
  }
  const containerBounding = container?.current?.getBoundingClientRect() || {
    left: 0,
    top: 0,
  };
  const left = Math.round(clientOffset.x) - containerBounding.left - DRAG_SHIFT * zoom;
  const top = Math.round(clientOffset.y) - containerBounding.top - DRAG_SHIFT * zoom;
  const transformStyle = `translate(${left}px, ${top}px) scale(${zoom}, ${zoom})`;
  const transformOrigin = "top left";
  return {
    width: ELEMENT_WIDTH,
    height: ELEMENT_HEIGHT,
    transform: transformStyle,
    transformOrigin,
    zIndex: 999,
  };
}
export interface Props {
  item: any;
  clientOffset: XYCoord | null;
  container: React.RefObject<HTMLDivElement>;
}
const AssetDragLayerView: React.FC<Props> = ({ item, clientOffset, container }): React.ReactElement | null => {
  useReplacementOnOuterLayer({
    draggedItem: item,
    clientOffset,
  });
  useSectionHeaderOnOuterLayer({
    draggedItem: item,
    clientOffset,
  });
  const { helpers, leftAligned, rightAligned, topAligned, bottomAligned } = useGuidesOnOuterLayer({
    draggedItem: item,
    clientOffset,
  });
  const { setDraggedItemFromInspector } = useReactFlowCanvasActions();
  const { getZoom } = useReactFlow();
  const zoom = getZoom();

  useEffect(() => {
    setDraggedItemFromInspector(item.elementType);
  }, [item, setDraggedItemFromInspector]);

  const styles = getItemStyles(
    container,
    zoom,
    snapToAlignmentGuideLines(clientOffset, { leftAligned, rightAligned, topAligned, bottomAligned }),
  );

  return (
    <>
      <div style={styles}>
        {helpers}
        <FlowDesignerDragCardShot item={item} />
      </div>
    </>
  );
};

const FlowDesignerDragCardShot = memo(FlowDesignerDragCard, () => true);

export default AssetDragLayerView;
