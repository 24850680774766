import { type ColumnOptions } from "interfaces";
import styles from "./packListRow.module.scss";

export const getColumnOptions = (licenseRemovalEnabled: boolean): ColumnOptions[] => {
  const options: ColumnOptions[] = [
    {
      name: "Pack",
      width: 5,
      isSortable: true,
      className: styles["pack-title-header"],
    },
    {
      name: "License Issued",
      width: 4,
      isSortable: true,
    },
  ];

  licenseRemovalEnabled &&
    options.push({
      name: "",
      width: 1,
      isSortable: false,
    });

  return options;
};
