import React, { type FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { type FilterFormPropsBase } from "../types/props";
import { getPdfsFilters } from "./pdfsFilters";
import { type PdfFiltersEnum } from "../../../features/Library/Pdfs/types/models";

export interface PdfsFilterFormProps extends FilterFormPropsBase<PdfFiltersEnum> {
  disableDateFilters: boolean;
}

const PdfsFilterForm: FC<PdfsFilterFormProps> = ({ filterOptions, filter, updateFilter, disableDateFilters }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getPdfsFilters(true, disableDateFilters)}
  />
);

export default PdfsFilterForm;
