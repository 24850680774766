import _, { every, isEmpty, isEqual, isNull, isObject, isString, isUndefined, map } from "lodash";

export namespace ObjectUtils {
  export const typedEntries = <K extends number | string, V>(object: Object): Array<[K, V]> => {
    return _.entries(object) as Array<[K, V]>;
  };

  export const isNotEmpty = <T>(input?: T): input is T => {
    return !isEmpty(input);
  };

  export const isNotNull = <T>(input?: T | null): input is T => {
    return !isNull(input);
  };

  export const isEmptyDeep = (obj: object): boolean => {
    if (isNull(obj) || isUndefined(obj)) {
      return true;
    } else if (isObject(obj)) {
      if (Object.keys(obj).length === 0) return true;
      return every(map(obj, (v) => isEmptyDeep(v)));
    } else if (isString(obj)) {
      return !(obj as string).length;
    }
    return false;
  };

  export const isEqualBy = <T, U extends keyof T>(first: T | undefined, second: T | undefined, ...fields: U[]) => {
    if (!first || !second) {
      return false;
    }

    for (const key of fields) {
      if (!isEqual(first[key], second[key])) {
        return false;
      }
    }
    return true;
  };

  // For some reason lodash's pick does not validate input args
  export const pick = <T, U extends keyof T>(object: T, ...args: U[]): Pick<T, U> => {
    const res = {} as Pick<T, U>;
    args.forEach((x) => (res[x] = object[x]));
    return res;
  };
}

export default ObjectUtils;
