import { type ColumnOptions } from "interfaces";

export const getColumnOptions = (): ColumnOptions[] => [
  {
    name: "Name",
    width: 6,
    isSortable: true,
    className: "role-name-header",
  },
  {
    name: "People",
    width: 2,
    isSortable: false,
  },
  {
    name: "Permissions",
    width: 4,
    isSortable: false,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];
