import React, { useMemo } from "react";
import { type SavedFlowData } from "../../../types";
import { useElements } from "../../hooks/useElements";
import { ReactFlowStateContextActions } from "./ReactFlowStateContextActions";
import { ReactFlowStateContext } from "./ReactFlowStateContext";

interface ReactFlowStateProviderProps {
  flowData: SavedFlowData | undefined;
  children: React.ReactNode;
}

export const ReactFlowStateProvider: React.FC<ReactFlowStateProviderProps> = ({ flowData, children }) => {
  const [nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange] = useElements(flowData);

  const canvasContextValue = useMemo(() => ({ edges, nodes }), [edges, nodes]);
  const canvasContextValueActions = useMemo(
    () => ({
      setNodes,
      setEdges,
      onNodesChange,
      onEdgesChange,
    }),
    [setNodes, setEdges, onNodesChange, onEdgesChange],
  );
  return (
    <ReactFlowStateContextActions.Provider value={canvasContextValueActions}>
      <ReactFlowStateContext.Provider value={canvasContextValue}>{children}</ReactFlowStateContext.Provider>
    </ReactFlowStateContextActions.Provider>
  );
};
