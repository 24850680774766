import { Ref } from "semantic-ui-react";
import { StaticCommunicationCard } from "../../../../../../components/cards";
import type DragNDropTypes from "../../../../../../enums/dragNDropItemTypes";
import CommunicationTypes from "../../../../../../enums/communicationTypes";
import { useDraggableAssetCard } from "../../../../../../hooks/useDraggableAssetCard";
import { SendTypeEnum } from "../../../../../../enums/sendTypeEnum";

export interface Props {
  id: number;
  title: string;
  publisher?: string;
  dragNDropItemType: DragNDropTypes;
  cardType: CommunicationTypes.Email | CommunicationTypes.Message;
}

function DraggableCommunicationCard({ id, title, cardType, publisher = "Publisher", dragNDropItemType }: Props) {
  const [dragRef, isSelected, { handleClick }] = useDraggableAssetCard({
    type: dragNDropItemType,
    item: {
      id: id,
      type: dragNDropItemType,
      title: title,
      elementType: cardType,
      publisher,
      bag: {
        SendType: cardType === CommunicationTypes.Message ? SendTypeEnum.Teams : undefined,
      },
    },
  });

  return (
    <Ref innerRef={dragRef}>
      <StaticCommunicationCard
        id={id}
        title={title}
        className="asset-draggable-item"
        cardType={cardType}
        isSelected={isSelected}
        onClick={handleClick}
      />
    </Ref>
  );
}

export default DraggableCommunicationCard;
