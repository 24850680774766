import LabelWithInfoTooltip from "../../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";

import "./mainContactInfo.scss";

const MainContactInfo = () => {
  return (
    <LabelWithInfoTooltip
      info={
        <div className="main-contact-info">
          <b>MAIN CONTACT</b>
          <ul>
            <li>
              Authorized to accept Terms and Conditions or other agreements with BrainStorm for your organization.
            </li>
            <li>
              Owns all rights and permission to make changes to your organization's BrainStorm account and platform.
            </li>
            <li>Allowed to make additional platform purchases for your organization.</li>
            <li>
              Designates the user to be the primary point-of-contact for account notices and other communications from
              BrainStorm unless otherwise designated.
            </li>
          </ul>
        </div>
      }
      width={44}
      styles={{ maxWidth: "44rem" }}
      position="bottom right"
    />
  );
};

export default MainContactInfo;
