export const saveThreatDefenceCampaign = "SAVE_THREAT_DEFENCE_CAMPAIGN";
export const saveThreatDefenceCampaignContent = "SAVE_THREAT_DEFENCE_CAMPAIGN_CONTENT";
export const clearThreatDefenceCampaign = "CLEAR_THREAT_DEFENCE_CAMPAIGN";

export const getThreatDefenceCampaignBegin = "GET_THREAT_DEFENCE_CAMPAIGN_BEGIN";
export const getThreatDefenceCampaignSuccess = "GET_THREAT_DEFENCE_CAMPAIGN_SUCCESS";
export const getThreatDefenceCampaignFailure = "GET_THREAT_DEFENCE_CAMPAIGN_FAILURE";

export const getThreatDefenceCampaignContentBegin = "GET_THREAT_DEFENCE_CAMPAIGN_CONTENT_BEGIN";
export const getThreatDefenceCampaignContentSuccess = "GET_THREAT_DEFENCE_CAMPAIGN_CONTENT_SUCCESS";
export const getThreatDefenceCampaignContentFailure = "GET_THREAT_DEFENCE_CAMPAIGN_CONTENT_FAILURE";

export const getThreatDefenceCampaignRunsBegin = "GET_THREAT_DEFENCE_CAMPAIGN_RUNS_BEGIN";
export const getThreatDefenceCampaignRunsSuccess = "GET_THREAT_DEFENCE_CAMPAIGN_RUNS_SUCCESS";
export const getThreatDefenceCampaignRunsFailure = "GET_THREAT_DEFENCE_CAMPAIGN_RUNS_FAILURE";

export const setCampaign = "SET_CAMPAIGN";
