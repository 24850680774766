import { Divider, Grid, Icon } from "semantic-ui-react";
import { ValidatedForm } from "../../../../../../../components/forms";
import { WeekDayPicker } from "../../../../../../../components/weekDayPicker";
import {
  PeriodicRule,
  PeriodType,
  type RecurrenceSession,
  type SessionPayload,
  type SessionView,
} from "../../../../types/state";
import { getPropName } from "../../Utils/getSessionPropName";
import { periodTypes, periodValueOptions } from "../../constants";
import moment from "moment";
import dateTimeUtils from "../../../../../../../utils/dateTimeUtils";
import { dayOfWeekMap } from "components/weekDayPicker/constants";
import eventSessionUtils from "../../../../../../../utils/eventSessionUtils";
import { pluralize } from "../../../../../../../utils/stringUtils";
import { useMemo } from "react";
import { RecurringSessionDescription } from "./RecurringSessionDescription";
import { isUndefined } from "lodash";
import { TimezoneDropdown } from "features/Library/Common/Fields/timezone-dropdown/TimezoneDropdown";
import { type WeekDays } from "enums/weekDays";

export type RecurringSessionFormBuilderProps = {
  session: SessionView;
  onBlur: (propertyName: string) => void;
  disabled?: boolean;
  validatedFieldProps: any;
  onChanged: () => void;
};

export const RecurringSessionFormBuilder: React.FC<RecurringSessionFormBuilderProps> = (
  props: RecurringSessionFormBuilderProps,
) => {
  const { session, disabled, onBlur, validatedFieldProps, onChanged } = props;
  const { startDate, recurrenceSession } = session;

  const periodType = recurrenceSession?.periodType ?? PeriodType.Day;

  const [optionsMap, options] = useMemo(() => {
    const radioOptions = eventSessionUtils.getRadioOptions(moment.utc(startDate), periodType);
    const optionsProp = Object.entries(radioOptions).map(([key, value]) => {
      return { text: value, value: Number(key) };
    });
    return [radioOptions, optionsProp];
  }, [periodType, startDate]);

  const { periodicRule } = recurrenceSession || {};

  const onPeriodTypeChange = async (_: string, value: PeriodType, shouldValidate: boolean) => {
    const recurrenceSessionNew = {
      ...getDefaultRecurrenceData(value, startDate),
      periodType: value,
      periodValue: session.recurrenceSession?.periodValue || 1,
    };

    await validatedFieldProps.setFieldValue(
      getPropName<SessionPayload>("recurrenceSession"),
      recurrenceSessionNew,
      shouldValidate,
    );
    onChanged?.();
  };

  const periodValue = recurrenceSession?.periodValue ?? 1;

  const periodTypeOptions = useMemo(
    () =>
      periodTypes.map((type) => ({
        text: pluralize(PeriodType[type], periodValue),
        value: type,
      })),
    [periodValue],
  );

  const getRecurrentSessionPropName = (name: keyof RecurrenceSession): string =>
    `${getPropName<SessionPayload>("recurrenceSession")}.${name}`;

  return (
    <div className="recurring-event-fields">
      <Grid stackable columns="equal" verticalAlign="bottom" className="overridden-bottom-margin">
        <Grid.Row>
          <Grid.Column width={14}>
            <div className="container-fields">
              <div className="session-field session-url">
                <ValidatedForm.InputField
                  label="Event URL"
                  value={session.webinarUrl}
                  propertyName={getPropName<SessionView>("webinarUrl")}
                  markAsRequired
                  onBlur={onBlur}
                  disabled={disabled}
                  errorPosition="bottom"
                  {...validatedFieldProps}
                />
              </div>
              <div className="session-field session-start-date">
                <ValidatedForm.DatePickerField
                  label="Start date"
                  selected={session.startDate || ""}
                  propertyName={getPropName<SessionView>("startDate")}
                  disabled={disabled}
                  markAsRequired
                  errorPosition="bottom"
                  {...validatedFieldProps}
                  onBlur={() => {
                    onBlur(getPropName<SessionPayload>("sessionStartDate"));
                  }}
                />
              </div>
              <div className="session-field session-end-date">
                <ValidatedForm.DatePickerField
                  label="End date"
                  selected={session.recurrenceSession?.seriesEndDate || ""}
                  propertyName={getRecurrentSessionPropName("seriesEndDate")}
                  disabled={disabled}
                  onBlur={onBlur}
                  errorPosition="bottom"
                  {...validatedFieldProps}
                />
              </div>
              <div className="session-field session-starts-at">
                <ValidatedForm.TimePickerField
                  label="Starts at"
                  selected={session.startsAt || ""}
                  propertyName={getPropName<SessionView>("startsAt")}
                  markAsRequired
                  disabled={disabled}
                  errorPosition="bottom"
                  {...validatedFieldProps}
                  onBlur={() => {
                    onBlur(getPropName<SessionPayload>("startsAtTicksMinutes"));
                  }}
                />
              </div>
              <div className="session-field session-ends-at">
                <ValidatedForm.TimePickerField
                  label="Ends at"
                  selected={session.endsAt || ""}
                  propertyName={getPropName<SessionView>("endsAt")}
                  markAsRequired
                  disabled={disabled}
                  errorPosition="bottom"
                  {...validatedFieldProps}
                  onBlur={() => {
                    onBlur(getPropName<SessionPayload>("endsAtTicksMinutes"));
                  }}
                />
              </div>
              <div className="session-field session-timezone">
                <TimezoneDropdown
                  className={"timezone-width"}
                  label="Time zone"
                  propertyName={getPropName<SessionView>("timeZone")}
                  value={session.timeZone || ""}
                  markAsRequired
                  disabled={disabled}
                  error=""
                  touched={false}
                  icon={<Icon className="fa-clock" />}
                  {...validatedFieldProps}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div className="repeat-every">
              <label className="validated-field-label">Repeat every</label>
              <div className="period-dropdowns">
                <ValidatedForm.DropdownField
                  propertyName={getRecurrentSessionPropName("periodValue")}
                  value={periodValue}
                  options={periodValueOptions}
                  disabled={disabled}
                  {...validatedFieldProps}
                  handleBlur={() => {
                    onBlur(getRecurrentSessionPropName("periodValue"));
                  }}
                />
                <ValidatedForm.DropdownField
                  propertyName={getRecurrentSessionPropName("periodType")}
                  value={periodType}
                  options={periodTypeOptions}
                  onBlur={onBlur}
                  disabled={disabled}
                  {...validatedFieldProps}
                  setFieldValue={onPeriodTypeChange}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="dynamic-options">
          <Grid.Column>
            {periodValue === 1 && periodType === PeriodType.Day && (
              <WeekDayPicker selectedPredicate={(_) => true} disabled />
            )}
            {periodType === PeriodType.Week && (
              <ValidatedForm.WeekDayPickerField
                propertyName={getRecurrentSessionPropName("weekDays")}
                value={recurrenceSession?.weekDays}
                selectedPredicate={(x) => recurrenceSession?.weekDays?.some((wd) => wd === dayOfWeekMap[x])}
                onBlur={onBlur}
                disabled={disabled}
                minSelectionCount={1}
                {...validatedFieldProps}
              />
            )}
            {(periodType === PeriodType.Month || periodType === PeriodType.Year) && (
              <div className="radio-section">
                <ValidatedForm.RadioGroupField
                  propertyName={getRecurrentSessionPropName("periodicRule")}
                  value={periodicRule}
                  options={options}
                  onBlur={onBlur}
                  disabled={disabled}
                  {...validatedFieldProps}
                />
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <RecurringSessionDescription
          session={session}
          occursOn={!isUndefined(periodicRule) ? optionsMap[periodicRule] : ""}
          weekDays={recurrenceSession?.weekDays || []}
        />
      </Grid>
    </div>
  );
};

const getDefaultRecurrenceData = (value: PeriodType, startDate: string | null) => {
  switch (value) {
    case PeriodType.Week: {
      const startWeekDay = dateTimeUtils.getWeekDayName(moment.utc(startDate));
      return {
        weekDays: [dayOfWeekMap[startWeekDay as WeekDays]],
      };
    }
    case PeriodType.Month: {
      return {
        periodicRule: PeriodicRule.MonthlyOnExactDayInMonth,
      };
    }
    case PeriodType.Year: {
      return {
        periodicRule: PeriodicRule.YearlyOnExactDayAndMonth,
      };
    }
    default: {
      return {};
    }
  }
};
