import { type IWizardStep, bindAction } from "interfaces";
import { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { type ConnectedProps, connect } from "react-redux";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { ItemsTypes } from "../../../../enums/itemsTypes";
import { RolePermissions, RouteNames, ViewType } from "../../../../enums";
import { CardContent, CardThumbnail, SelectableCard } from "components";
import NoResults from "components/noResults/NoResults";
import { type CardsViewerItem } from "components/cardsViewer/types";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { PackPublisherInfo } from "components/packs/packPublisherInfo/PackPublisherInfo";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { reset } from "../state/slices/associatedPacksSlice";
import cn from "classnames";
import { noop, partial } from "lodash";
import { fetchAssociatedPacks } from "../state/thunks/associatedPacksThunk";
import { type AssociatedPack } from "features/Licensing/Packs/types/state";
import { AddPacksButton } from "./AddPacksButton";
import Restricted from "features/Application/Restricted";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useRtn } from "hooks/useRtn";

interface OwnProps extends IWizardStep {
  contentType: string;
  contentId: number;
  canAddPacks: boolean;
  onMount?: () => void;
  permissions: RolePermissions[];
  permissionPredicate?: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}
export type AssociatedPacksProps = OwnProps & PropsFromRedux;

const events = [rtnEvents.AddContentToPackSuccess];

export const AssociatedPacks = (props: AssociatedPacksProps) => {
  const {
    acceptHandlers,
    reset,
    canAddPacks,
    contentType,
    contentId,
    items,
    itemsCount,
    isLoading,
    permissions,
    fetchAssociatedPacks,
    permissionPredicate,
  } = props;
  const [packsModalShown, setPacksModalShown] = useState<boolean>(false);

  const getAssociatedPacks = useCallback(() => {
    fetchAssociatedPacks(contentType, contentId);
  }, [contentType, contentId, fetchAssociatedPacks]);

  useRtn(events, getAssociatedPacks);

  useEffect(() => {
    acceptHandlers?.({});
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton
      disabled={!canAddPacks}
      hasPermission={hasAnyPermission}
      onClick={partial(setPacksModalShown, true)}
    />
  );

  const renderNoResults = () => {
    return (
      <NoResults title="No Associated Packs" iconClassName="fa-box-full">
        <Restricted
          permissions={[...permissions, RolePermissions.PacksManage]}
          permissionPredicate={permissionPredicate}
          renderContent={(hasAnyPermission) => renderAddPackButton(hasAnyPermission)}
        />
      </NoResults>
    );
  };

  const renderCard = (params: CardsViewerItem<AssociatedPack>) => {
    const { id, title, publisher, description, thumbnailImageUrl } = params.item;
    return (
      <>
        <SelectableCard
          id={id}
          url={`/${RouteNames.listingsPacks}/${id}`}
          className={cn("pack-card")}
          onSelect={null}
          disabled
        >
          <CardThumbnail thumbnailUrl={thumbnailImageUrl} />
          <CardContent title={title} preHeader={<PackPublisherInfo publisherName={publisher} />} skipDefaultFiller>
            <div className="description-row">{description}</div>
            <div className="filler" />
          </CardContent>
        </SelectableCard>
      </>
    );
  };

  return (
    <>
      <section className="scrollable-content">
        <GenericItemsView
          blur
          itemsType={ItemsTypes.Pack}
          items={items}
          fetchData={getAssociatedPacks}
          onLoad={getAssociatedPacks}
          noResultsContent={renderNoResults()}
          isLoading={isLoading}
          renderCard={renderCard}
          dataCount={itemsCount}
          viewType={ViewType.GRID}
          isSelectDisabled={() => true}
          isAllDataLoaded
          columnOptions={[]}
        />
      </section>
      {packsModalShown && (
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={partial(setPacksModalShown, false)}
          onComplete={noop}
          selectedItemIds={[contentId]}
          contentType={contentType}
        />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const packs = state.packs.associatedPacks;
  return {
    items: packs.items,
    itemsCount: packs.itemsCount,
    isLoading: packs.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAssociatedPacks: bindActionCreators(fetchAssociatedPacks, dispatch),
  reset: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AssociatedPacks);
export default ConnectedComponent;
