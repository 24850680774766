import React from "react";
import PropTypes from "prop-types";
import "./formSectionHeader.scss";

const FormSectionHeader = (props) => (
  <div className="form-section-header">
    <div className="header">{props.title}</div>
    <div className="details text-muted">{props.details}</div>
  </div>
);
FormSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string,
};
export default FormSectionHeader;
