type ValidationInput = any;

interface ValidationError {
  message: string;
  [key: string]: any;
}

interface IValidator {
  validator: (input: ValidationInput) => boolean;
  getErrorMessage: (propertyName: string, value: any) => string;
}

const throwErrors = (errors: ValidationError[]) => {
  if (errors.length > 0) {
    throw new Error(errors.join("\n"));
  }
};

const getValueMessage = (value: number | string) => `Actual value: ${value}. Type: ${typeof value}`;

const getValue = (input: ValidationInput, propertyName: string) => {
  if (input instanceof Object) {
    input = input[propertyName];
  }
  return typeof input === "function" ? "function" : input;
};

export default class Validator {
  private validators: Array<IValidator>;

  constructor() {
    this.validators = [];
  }

  isPositiveInteger() {
    this.validators.push({
      validator: (input) => Number.isInteger(input) && input > 0,
      getErrorMessage: (propertyName: string, value: number) =>
        `"${propertyName}" should be a positive number. ${getValueMessage(value)}`,
    });
    return this;
  }

  isPositiveIntegerOrZero() {
    this.validators.push({
      validator: (input) => Number.isInteger(input) && parseInt(input) >= 0,
      getErrorMessage: (propertyName, value) =>
        `"${propertyName}" should be positive number or zero. ${getValueMessage(value)}`,
    });
    return this;
  }

  isFunction() {
    this.validators.push({
      validator: (input) => typeof input === "function",
      getErrorMessage: (propertyName, value) => `"${propertyName}" should be a function. ${getValueMessage(value)}`,
    });
    return this;
  }

  isArray() {
    this.validators.push({
      validator: (input) => Array.isArray(input),
      getErrorMessage: (propertyName, value) => `"${propertyName}" should be an array. ${getValueMessage(value)}`,
    });
    return this;
  }

  isDefinedInObject(obj: { [key: string]: any }) {
    // use keys without numeric because converting enums give additional digital keys
    // @see https://www.crojach.com/blog/2019/2/6/getting-enum-keys-in-typescript
    const keys = Object.keys(obj).filter((x) => !this.isNumeric(x));
    this.validators.push({
      validator: (input) =>
        keys.some((item) => {
          return obj[item] === input;
        }),
      getErrorMessage: (propertyName, value) =>
        `"${propertyName}" should be one of following: [${keys.map((key) => obj[key]).join(", ")}]. ${getValueMessage(
          value,
        )}`,
    });
    return this;
  }

  isNumeric = (val: string): boolean => {
    return parseInt(val) >= 0;
  };

  isNotNullOrUndefined() {
    this.validators.push({
      validator: (input) => input !== null && input !== undefined,
      getErrorMessage: (propertyName, value) => `"${propertyName}" is null or undefiend. ${getValueMessage(value)}`,
    });
    return this;
  }

  validate(input: ValidationInput, propertyName: string) {
    let errors: any[] = [];
    this.validators.forEach((item) => {
      if (!item.validator(input)) {
        errors.push(item.getErrorMessage(propertyName, getValue(input, propertyName)));
      }
    });
    return errors;
  }

  validateAndThrow(input: ValidationInput, propertyName: string) {
    const errors = this.validate(input, propertyName);
    throwErrors(errors);
  }

  static validateSchema(validationSchema: any, input: any) {
    let errors: any[] = [];
    Object.keys(validationSchema).forEach((key) => {
      let propertyErrors = validationSchema[key].validate(input[key], key);
      errors = errors.concat(propertyErrors);
    });
    return errors;
  }

  static validateSchemaAndThrow(validationSchema: any, input: any) {
    const errors = Validator.validateSchema(validationSchema, input);
    throwErrors(errors);
  }

  static create() {
    return new Validator();
  }
}
