import type SortOptions from "../../../../../../enums/SortOptions";
import { useMemo } from "react";
import { type PacksRequest } from "../../../types/requests";

interface Props {
  accountId: number;
  loadedItemsCount: number;
  sorting: SortOptions;
  searchTerm?: string;
}

/**
 * `useRequestOptions` is expected to return new request options only when
 * the sort or search value changes. Since the return value is being
 * listened for changes, make sure you don't add extra dependencies.
 */
export function useRequestOptions({ accountId, sorting, searchTerm }: Props) {
  return useMemo<PacksRequest>(
    () => ({
      accountId: accountId,
      skip: 0,
      orderBy: sorting,
      filters: {
        isExpired: "false",
      },
      searchTerm: searchTerm,
    }),
    [
      // list of deps that can trigger options changes
      accountId,
      sorting,
      searchTerm,
    ],
  );
}
