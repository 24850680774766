import * as actionTypes from "../actionTypes/threatDefenceCampaignOptionsActionTypes";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { type ThreatDefenceCampaignOption, type ThreatDefenceCampaignOptions } from "../../types/state";

export const getCampaignOptions = () => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignOptionsBegin);
  const success = getActionProvider<ThreatDefenceCampaignOptions>(actionTypes.getThreatDefenceCampaignOptions);
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignOptionsFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const campaignTypes = await dataService.threatDefence.getCampaignTypes();
      const options: ThreatDefenceCampaignOptions = {
        types: campaignTypes.data,
        senderProfiles: [{ id: 1, name: "DirectSend" }],
      };

      dispatch(success(options));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const getCampaignLandingPages = () => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignLandingPagesBegin);
  const success = getActionProvider<ThreatDefenceCampaignOption[]>(
    actionTypes.getThreatDefenceCampaignLandingPagesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignLandingPagesFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const landingPages = await dataService.threatDefence.getCampaignLandingPages();
      dispatch(success(landingPages.data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const getCampaignEmailTemplates = () => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignEmailTemplatesBegin);
  const success = getActionProvider<ThreatDefenceCampaignOption[]>(
    actionTypes.getThreatDefenceCampaignEmailTemplatesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignEmailTemplatesFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const landingPages = await dataService.threatDefence.getCampaignEmailTemplates();
      dispatch(success(landingPages.data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
