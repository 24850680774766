import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import "./userProfilePicture.scss";

export default function UserProfilePicture(props) {
  const { imageUrl } = props;

  if (imageUrl) {
    return <img className="user-profile-picture avatar" src={imageUrl} alt="avatar" />;
  }

  return <Icon className="fa-user-circle user-profile-picture avatar" />;
}

UserProfilePicture.propTypes = {
  imageUrl: PropTypes.string,
};
