import axios from "axios";
import { type IStripoClient } from "./IStripoClient";
import { type StripoInitOpts } from "./stripoInitOpts";
import { type IStripoScript } from "./stripoScript";

// Stripo Plugin Reference https://stripo.email/pt/plugin-api/

export class StripoClient implements IStripoClient {
  private _isInitialized: boolean = false;
  private _isInitializing: boolean = false;
  private script: IStripoScript;

  constructor(script: IStripoScript) {
    this.script = script;
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }

  get isInitializing(): boolean {
    return this._isInitializing;
  }

  public initOnce(opts: StripoInitOpts) {
    if (!opts.template) return;

    if (!this._isInitialized) {
      this._isInitializing = true;
      this.script.load().then(() => this._init(opts));
    }
  }

  private _init({ onTemplateLoaded, template, accountId, ...rest }: StripoInitOpts) {
    (window as any).Stripo.init({
      ...rest,
      html: template,
      apiRequestData: {
        emailId: `account_${accountId}`,
      },
      getAuthToken: (callback: (token: string | null) => void) => {
        axios
          .get("/api/system-notifications/stripo-auth")
          .then((data) => {
            callback(data.data);
          })
          .catch(() => {
            callback(null);
            this._isInitializing = false;
            this._isInitialized = false;
          });
      },
      onTemplateLoaded: () => {
        this._isInitializing = false;
        this._isInitialized = true;
        onTemplateLoaded?.();
      },
    });
  }

  public stop(): void {
    (window as any).StripoApi?.stop();
  }

  public getTemplate(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const api = (window as any).StripoApi;
      if (api) {
        api.getTemplate((html: string, _: string) => {
          resolve(html);
        });
      } else {
        reject("Can't get template. Stripo API has not been initialized yet.");
      }
    });
  }

  public compileEmail(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const api = (window as any).StripoApi;
      if (api) {
        api.compileEmail((_: unknown, html: string) => {
          resolve(html);
        });
      } else {
        reject("Can't compile the template.");
      }
    });
  }

  public reInitialize(opts: StripoInitOpts): void {
    this._isInitializing = true;
    this.script.load().then(() => this._init(opts));
  }
}
