import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type GroupInfo } from "../../../../../interfaces";

export interface PackGroupsAvailableForLicensingState extends FetchingItemsState<GroupInfo> {}

const initialState: PackGroupsAvailableForLicensingState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const PackGroupsAvailableForLicensingSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.PackGroupsAvailableForLicensing,
    name: "PackGroupsAvailableForLicensing",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = PackGroupsAvailableForLicensingSlice.actions;

export default PackGroupsAvailableForLicensingSlice.reducer;
export type packGroupsAvailableForLicensingSliceType = ReturnType<typeof PackGroupsAvailableForLicensingSlice.reducer>;
