import TaskActions from "../../../../enums/TaskActions";
import TaskStatuses from "../../../../enums/taskStatuses";
import fileUtils from "../../../../utils/fileUtils";
import { getFilenameWithoutExtension as getFileName } from "../../../../utils/stringUtils";
import taskPool from "../../../../utils/taskPool";
import backgroundTasksEventsEmitter from "../../../BackgroundTasks/events/backgroundTasksEventsEmitter";
import BackgroundTaskLocalStorage from "../../../BackgroundTasks/storage/BackgroundTaskLocalStorage";
import videoAssetTasks from "../../../BackgroundTasks/videoAssetTasks";
import dataService from "./videoDataService";

function VideoUploaderService(
  propsProvider,
  idProvider,
  cancellationToken,
  onVideoAssetCreated,
  onTaskIdChanged,
  isAwsEncodingEnabled = false,
) {
  let aborter = null;
  let abortController = null;
  idProvider = idProvider || (() => -1);
  cancellationToken = cancellationToken || {};
  const storage = new BackgroundTaskLocalStorage();
  const errorLabel = "File upload failed!";

  const uploadFilesHandler = async (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        const fileName = file.name;
        propsProvider().backgroundTasksActions.addTask({
          id: fileName,
          title: fileName,
          label: "Uploading...",
          errorLabel,
        });
        return uploadFileHandler(fileName, file);
      }),
    );
  };

  const uploadFileHandler = async (taskId, file) => {
    const { videoEntityStateActions, videosActions, backgroundTasksActions, uploadAwsVideo } = propsProvider();

    const createDraftHandler = (asset) => {
      return videoEntityStateActions.createDraft(
        {
          id: asset.id,
          fileKey: asset.fileKey,
          fileName: file.name,
        },
        cancellationToken,
      );
    };

    const updateVideoHandler = (asset) => {
      return videosActions.updateVideo(idProvider(), {
        fileKey: asset.fileKey,
        fileName: file.name,
      });
    };

    const onUploadFileProgress = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      backgroundTasksActions.updateProgress(taskId, percentCompleted);
    };

    const acceptUploadAborter = (aborterHandler) => {
      aborter = aborterHandler;
      backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.cancel, taskId, () => {
        if (aborterHandler && !aborterHandler.aborted) {
          aborterHandler.abort();
        }
        backgroundTasksActions.deleteTask(taskId);
      });
    };

    /* istanbul ignore next */
    const acceptUploadAbortController = (abortHandler) => {
      abortController = abortHandler;
      backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.cancel, taskId, () => {
        if (abortHandler && !abortHandler.signal.aborted) {
          abortHandler.abort();
        }
        backgroundTasksActions.deleteTask(taskId);
      });
    };

    const getAssetResourceInfo = async (id) => {
      let sasToken,
        presignedUrl,
        isNew = id <= 0;
      if (isNew) {
        ({
          data: { sasToken, id, presignedUrl },
        } = await dataService.allocateVideoResources());
      } else {
        sasToken = isAwsEncodingEnabled ? null : (await dataService.generateSas(id)).data;
        presignedUrl = isAwsEncodingEnabled ? (await dataService.generatePresignedUrl(id)).data : null;
      }

      return { id, sasToken, presignedUrl, exist: !isNew };
    };

    const uploadFile = async (videoInfo) => {
      const { id, presignedUrl, sasToken } = videoInfo;

      const task = {
        id: id,
        status: TaskStatuses.queued,
        title: file.name,
        label: "Uploading...",
        errorLabel,
        canCancel: true,
        onCancel: () => propsProvider().backgroundTasksActions.deleteTask(task.id),
      };

      propsProvider().backgroundTasksActions.addTask(task);

      backgroundTasksEventsEmitter.subscribeOnce(TaskActions.cancel, task.id, taskPool.cancelTask.bind(taskPool));

      backgroundTasksActions.updateTask(task.id, videoAssetTasks.getStarted(task.id));

      await (isAwsEncodingEnabled
        ? uploadAwsVideo(file, presignedUrl.url, onUploadFileProgress, acceptUploadAbortController)
        : videosActions.uploadAssetVideo(file, onUploadFileProgress, acceptUploadAborter, sasToken.uri));

      backgroundTasksActions.updateTask(task.id, videoAssetTasks.getUploadedSuccessfuly(task.id));
    };

    const assetResources = await getAssetResourceInfo(idProvider());
    backgroundTasksActions.updateId(taskId, assetResources.id);
    taskId = assetResources.id;

    onTaskIdChanged && onTaskIdChanged(taskId);

    try {
      await uploadFile(assetResources);

      const asset = {
        id: assetResources.id,
        uri: isAwsEncodingEnabled ? assetResources.presignedUrl.url : assetResources.sasToken.uri,
        fileKey: isAwsEncodingEnabled ? assetResources.presignedUrl.fileName : assetResources.sasToken.fileName,
      };

      if (assetResources.exist) {
        await updateVideoHandler(asset);
      } else {
        await createDraftHandler(asset);
        onVideoAssetCreated && onVideoAssetCreated(assetResources.id);
      }

      storage.saveTask(assetResources.id);
      backgroundTasksActions.updateTask(taskId, videoAssetTasks.getProcessing(taskId));
    } catch (e) {
      const id = taskId;
      const task = videoAssetTasks.getFailed(taskId, errorLabel, backgroundTasksActions.deleteTask);
      backgroundTasksActions.updateTask(id, task);
      throw e;
    }
  };

  const acceptFiles = (fileName = "", files = []) => {
    const { values, setValues, videosActions } = propsProvider();
    const title = getFileName(fileName);
    let newValues = {
      ...values,
      fileName: fileName,
      uploadedVideos: [...files].map(fileUtils.toFileLike),
    };
    if (!values.title || idProvider() <= 0) {
      newValues.title = title;
      newValues.description = title;
    }

    videosActions.saveVideoInfo(newValues);
    setValues(newValues);
  };

  const onCancelFileUploading = () => {
    if ((!aborter || aborter.aborted) && !abortController) {
      return;
    }

    abortController && abortController.abort();
    aborter && aborter.abort();
    propsProvider().videosActions.cancelVideoAssetUpload();
    acceptFiles();
  };

  const handleFileChange = (files) => {
    if (!files || files.length === 0) {
      return;
    }
    acceptFiles(files[0].name, files);
  };

  return {
    uploadFilesHandler,
    uploadFileHandler,
    handleFileChange,
    onCancelFileUploading,
  };
}

export default VideoUploaderService;
