function withoutItem<T>(array: Array<T>, item: T) {
  return array.filter((i) => i !== item);
}

function sortArrayAlphabeticallyByProperty<T>(array: Array<T>, propertySelector: (item: T) => string) {
  return array.sort((a: T, b: T) => {
    const aProp = propertySelector(a);
    const bProp = propertySelector(b);
    if (!aProp) {
      return -1;
    } else if (!bProp) {
      return 1;
    }

    const textA = aProp.toUpperCase();
    const textB = bProp.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    return textA > textB ? 1 : 0;
  });
}

function count<T>(array: Array<T>, filter: (el: T) => boolean) {
  let cnt = 0;
  array.forEach((item) => {
    if (filter(item)) {
      ++cnt;
    }
  });
  return cnt;
}

function removeByIndex<Type>(array: Type[], index: number) {
  if (index !== -1) {
    array.splice(index, 1);
  }
}

function pushOrRemoveIfExist<Type>(array: Set<Type>, item: Type, pushCondition: (item: Type) => boolean = () => true) {
  const itemExist = array.has(item);
  if (itemExist) {
    array.delete(item);
  } else if (pushCondition(item)) {
    array.add(item);
  }
  return array;
}

function sequencesEqualBy<T>(left: T[], right: T[], areEqual: (item: T, other: T) => boolean) {
  if (left.length !== right.length) {
    return false;
  }

  for (let i = 0; i < left.length; ++i) {
    if (!areEqual(left[i], right[i])) {
      return false;
    }
  }

  return true;
}

const utils = {
  withoutItem,
  sortNumbers: (array: Array<any>) => {
    return array.sort((a, b) => a - b);
  },
  pushOrRemoveIfExist,
  count,
  removeByIndex,
  sortArrayAlphabeticallyByProperty,
  sequencesEqualBy,
};

export default utils;
