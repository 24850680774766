import React, { useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { FieldArray } from "formik";
import { last } from "lodash";

import { type ExternalTrigger, type ExternalTriggerGroup } from "../../../../../types/externallTriggersTypes";
import { getColumnTitles, getDefaultTrigger, onAdd } from "../helpers/utils";
import { flowDesignerSelector } from "../../../../../../state/selectors";
import { type RootState } from "../../../../../../../../Application/globaltypes/redux";
import { MAX_TRIGGERS_COUNT } from "../helpers/constants";
import { Integration } from "../../Integration/Integration";

import TriggerRow from "./TriggerRow/TriggerRow";
import ExpressionBuilder from "../../../../../../../../../components/expressionBuilder/ExpressionBuilder";

import styles from "./externalTriggerForm.module.scss";

export interface ExternalTriggerFormProps extends PropsFromRedux {
  index: number;
  isReadOnly: boolean;
  isValid: boolean;
  group: ExternalTriggerGroup<ExternalTrigger[]>;
  onGroupChange: (group: ExternalTriggerGroup<ExternalTrigger[]>, index: number) => void;
}

export const ExternalTriggerForm = (props: ExternalTriggerFormProps) => {
  const { isReadOnly, group, isValid, onGroupChange, index, vendorOptions } = props;
  const [vendors, setVendors] = useState(vendorOptions);

  const handleChange = (field: string, trigger: ExternalTrigger, index: number) => {
    const combinedData: ExternalTrigger[] = [...group.triggers];
    combinedData[index] = trigger;
    onTriggersChange(combinedData);
  };

  const onTriggersChange = (items: ExternalTrigger[]) => {
    const combinedData: ExternalTriggerGroup<ExternalTrigger[]> = { ...group };
    combinedData.triggers = items;
    onGroupChange(combinedData, index);
  };

  const canAdd = useMemo(() => {
    const isGoogle = group.triggers.some((trigger) => trigger.software === "Google");
    return isValid && group.triggers.length < MAX_TRIGGERS_COUNT && !isGoogle;
  }, [isValid, group.triggers]);

  useEffect(() => {
    if (group.triggers.length > 1) {
      setVendors(vendorOptions.filter((option) => option.value !== "Google"));
    } else {
      setVendors(vendorOptions);
    }
  }, [group.triggers, vendorOptions]);

  const columns = useMemo(() => {
    const software = last(group?.triggers)?.software || undefined;
    return getColumnTitles(software);
  }, [group.triggers]);

  const renderRow = ({ index, items }: { index: number; items: ExternalTrigger[] }) => (
    <>
      {!items[index].hasIntegration && <Integration className={styles.integration} />}
      <TriggerRow
        vendorOptions={vendors}
        isReadOnly={isReadOnly}
        handleChange={handleChange}
        item={items[index]}
        index={index}
      />
    </>
  );

  return (
    <div className={styles.root}>
      <FieldArray
        name={`externalTriggerGroups.${[index]}.triggers`}
        render={(arrayHelpers) => (
          <ExpressionBuilder
            onChange={onTriggersChange}
            onAdd={onAdd(arrayHelpers.unshift)}
            onRemove={arrayHelpers.remove}
            items={group.triggers}
            columnTitles={columns}
            getDefaultNewItem={getDefaultTrigger}
            columnsAmount={5}
            isReadOnly={isReadOnly}
            canAdd={canAdd}
          >
            {renderRow}
          </ExpressionBuilder>
        )}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  vendorOptions: flowDesignerSelector(state).externalTriggers.vendorOptions,
});

/* istanbul ignore next */
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ExternalTriggerForm);
export default ConnectedComponent;
