import { type PriorityLevel } from "../../../interfaces";
import MultipleAssetsThumbnail from "./MultipleAssetsThumbnail";
import { PriorityLevel as PriorityLevelCommon, type PriorityLevelProps } from "./PriorityLevel";

import "./priorityLevel.scss";

export interface PriorityLevelBulkProps<T> extends PriorityLevelProps<T> {
  count: number;
}

const PriorityLevelBulk = <T extends PriorityLevel>(props: PriorityLevelBulkProps<T>) => {
  const { count, ...priorityLevelProps } = props;

  return (
    <div id="priority-level-item" className="priority-level-item priority-level-with-thumbnail">
      <MultipleAssetsThumbnail count={count} />
      <PriorityLevelCommon {...priorityLevelProps} />
    </div>
  );
};

export default PriorityLevelBulk;
