import cn from "classnames";

import Form from "./components/Form";
import Preview from "./components/Preview";
import Tabs from "../../tabs/Tabs";
import { type TemplateTypes } from "enums";

import editorTabsStyles from "../editorTabs.module.scss";

export interface NotifyByTeamsProps {
  templateType?: TemplateTypes;
  onValidChange?(isValid: boolean): void;
  onIsDirtyChange?(isDirty: boolean): void;
}

const PREVIEW_TAB_INDEX = 1;

const NotifyByTeams = ({ onValidChange, onIsDirtyChange }: NotifyByTeamsProps) => {
  return (
    <Tabs
      className={cn(editorTabsStyles["editor-tabs"], "teams")}
      renderActiveOnly={false}
      defaultActiveIndex={PREVIEW_TAB_INDEX}
    >
      <Tabs.Pane label="Edit">
        <Form onValidChange={onValidChange} onIsDirtyChange={onIsDirtyChange} />
      </Tabs.Pane>
      <Tabs.Pane label="Preview">
        <Preview />
      </Tabs.Pane>
    </Tabs>
  );
};

export default NotifyByTeams;
