import { useEffect } from "react";
import { type FormikProps } from "formik";
import { Tab } from "semantic-ui-react";
import cn from "classnames";
import { type Template } from "../../features/Library/Communications/Emails/types/state";
import { type TemplateFormProps } from "../../features/Library/Communications/Emails/EmailDetails/types";
import { StripoApi } from "./stripo/stripoApi";
import { DEFAULT_EMAIL_TEMPLATE } from "./defaultEmailTemplate";
import communicationVariables from "./communicationVariables.json";
import communicationLinks from "./communicationLinks.json";
import { templateUtils } from "../../components/notifyStep/utils/templateUtils";
import { inlineStyles } from "../../components/notifyStep/EmailEditor/inlineStyles";
import { defaultTemplateBody } from "./defaultTemplateBody";

import "./templateForm.scss";

export type TemplateFormPropsAll = TemplateFormProps & FormikProps<Template>;

export const TemplateForm = ({
  disabled,
  onDataChanged,
  isLoaded,
  accountId,
  forceReinitialize,
  setForceReinitialize,
  templateBody,
  ...props
}: TemplateFormPropsAll) => {
  const initialize = (template: string) => {
    const { css, setInitialized } = props;
    StripoApi.init({
      html: templateUtils.mergeTemplate(DEFAULT_EMAIL_TEMPLATE, template),
      css: css ?? inlineStyles,
      mergeTags: communicationVariables,
      specialLinks: communicationLinks,
      onTemplateLoaded: () => setInitialized(true),
      onDataChanged,
      accountId,
    });
  };

  useEffect(() => {
    const { initialized } = props;
    if (initialized && forceReinitialize && templateBody) {
      initialize(templateBody);
      setForceReinitialize?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateBody]);

  useEffect(() => {
    const { emailCreating, initialized } = props;

    if (initialized) {
      return;
    }

    // 1. if the user creates a new email, we should use the template from
    // the state (to cover the case when the user changed the tab and then went back),
    // otherwise use the default template
    // 2. if the creation of the email was interrupted, we need the ability to use
    // the default template during editing, because the template from the state will be empty
    if (emailCreating || isLoaded) {
      initialize(templateBody || defaultTemplateBody);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const panes = [
    {
      menuItem: "Edit",
      pane: {
        key: "tab1",
        content: (
          <div className={cn("edit-pane", disabled ? "containerDisabled" : "containerEnabled")}>
            <div id="stripoSettingsContainer" className="preview-area" />
            <div className="editor-area">
              <label className="editor-label">Email Body</label>
              <div id="stripoPreviewContainer" />
            </div>
          </div>
        ),
      },
    },
  ];

  return (
    <div className="stripo-container">
      <div className="email-form-container">
        <Tab panes={panes} menu={{ secondary: true, pointing: true }} renderActiveOnly={false} />
      </div>
    </div>
  );
};
