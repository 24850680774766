import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { type EnrollmentRule } from "features/People/Groups/AddEditGroupForms/EnrollmentBuilder/utils";
import { useEventCallback } from "hooks/useEventCallback";
import { bindAction, type IWizardStep } from "interfaces";
import { useEffect, useRef } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { GroupTemplateEnrollment } from "../Details/GroupTemplateEnrollment";
import { setEnrollmentRules, setIsEnrollmentValid, setTemplateUsersOnly } from "../state/GroupTemplateSlice";
import { saveRulesAction } from "../state/thunks/TemplateEnrollmentThunk";

interface Props extends IWizardStep, PropsFromRedux {
  disabled?: boolean;
}

export const CreateEnrollment = (props: Props) => {
  const {
    disabled,
    setIsEnrollmentValid,
    acceptHandlers,
    setEnrollmentRules,
    enrollmentRules,
    templateUsersOnly,
    setTemplateUsersOnly,
    groupId,
    accountType,
    saveRules,
  } = props;
  const getValuesRef = useRef<() => { enrollmentRules: EnrollmentRule[]; templateUsersOnly: boolean | null }>(() => ({
    enrollmentRules,
    templateUsersOnly,
  }));

  const save = useEventCallback(async () => {
    const currentValues = getValuesRef.current?.();
    const currentEnrollmentRules = currentValues.enrollmentRules;
    const currentTemplateUsersOnly = currentValues.templateUsersOnly;

    await saveRules(groupId, currentEnrollmentRules, currentTemplateUsersOnly);
  }, [groupId]);

  useEffect(() => {
    const setEnrollment = () => {
      const currentValues = getValuesRef.current();
      setEnrollmentRules(currentValues.enrollmentRules);
      setTemplateUsersOnly(currentValues.templateUsersOnly);
    };
    acceptHandlers?.({
      onNext: setEnrollment,
      onPrevious: setEnrollment,
      onFinish: save,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GroupTemplateEnrollment
      disabled={disabled}
      bindGetValues={(getValues) => (getValuesRef.current = getValues)}
      enrollmentRules={enrollmentRules}
      templateUsersOnly={templateUsersOnly}
      onIsValidChange={setIsEnrollmentValid}
      accountType={accountType}
      disablePreventTransitionPrompt
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const groupTemplateState = state.people.groupTemplate.base;
  return {
    groupId: groupTemplateState.groupId,
    enrollmentRules: groupTemplateState.enrollmentRules,
    templateUsersOnly: groupTemplateState.templateUsersOnly,
    accountType: state.userProfile.accountTypeId,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setIsEnrollmentValid: (isValid: boolean) => dispatch(setIsEnrollmentValid(isValid)),
    setEnrollmentRules: (rules: EnrollmentRule[]) => dispatch(setEnrollmentRules(rules)),
    setTemplateUsersOnly: (templateUsersOnly: boolean | null) => dispatch(setTemplateUsersOnly(templateUsersOnly)),
    saveRules: bindAction(saveRulesAction, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(CreateEnrollment);
export default ConnectedComponent;
