import axios from "axios";

export const chatService = {
  getChatResponse: async (input: string): Promise<string> => {
    const { data } = await axios.get<{ completion: string }>("/api/bsi-help/chat", {
      params: {
        message: input,
      },
    });

    // Remove instances of [doc#]  from the response
    data.completion = data.completion.replaceAll(/\s\[doc+\d\]/g, "");

    return data.completion;
  },
};
