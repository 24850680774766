import PropTypes from "prop-types";
import cn from "classnames";

import "./sectionHeader.scss";

import { PublishedStatus, publishedStatusPropType } from "../common/publishedStatus";

export function SectionHeader(props) {
  const { children, className, style, header, publishedStatus } = props;

  return (
    <div className={cn("section-header", className)} style={style}>
      <div className="left-part">
        {header}
        <PublishedStatus altLabel="Published Status" publishedStatus={publishedStatus} />
      </div>
      <div className="header-content-container">{children}</div>
    </div>
  );
}

SectionHeader.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  publishedStatus: publishedStatusPropType,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default SectionHeader;
