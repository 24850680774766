import { Icon } from "semantic-ui-react";
import EllipsisPopupButton from "../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { DeleteLinkButton, BasicLinkButton } from "../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../components/common/tooltip";

export interface AccountEllipsisPopupButtonProps {
  circle?: boolean;
  position?: string;
  outlinedEllipsis?: boolean;
  disabled?: boolean;
  accountId: number;
  accountName: string;
  canView?: boolean;
  canDelete?: boolean;
  hasAssignedPacks: boolean;
  onOpenMobo?: (id: number) => void;
  onDelete?: (id: number, name: string) => void;
}

function AccountEllipsisPopupButton(props: AccountEllipsisPopupButtonProps) {
  const { accountId, accountName, hasAssignedPacks, onOpenMobo, onDelete, canView, canDelete, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton circle outlinedEllipsis {...ellipsisProps}>
      <BasicLinkButton onClick={() => onOpenMobo?.(accountId)} isDisabled={!canView} text="Manage" iconClassName="fa-solid fa-key" />
      <DeleteLinkButton
        onClick={() => onDelete?.(accountId, accountName)}
        isDisabled={!canDelete || hasAssignedPacks}
        after={
          hasAssignedPacks && (
            <Tooltip
              inverted
              hideOnScroll
              position="bottom left"
              showAlways
              target={<Icon link className="fa fa-exclamation-circle" size="big" />}
              content="This account cannot be deleted because it has Pack(s) that have been purchased in the Marketplace."
            />
          )
        }
      />
    </EllipsisPopupButton>
  );
}

AccountEllipsisPopupButton.defaultProps = {
  position: "bottom right",
  disabled: false,
};

export default AccountEllipsisPopupButton;
