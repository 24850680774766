import React from "react";

import { type FlowItemPdf } from "../../../../features/Library/Flows/Designer/types";

import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface IPdfCardOwnProps {
  item: FlowItemPdf;
}

const PdfCard: React.FC<IPdfCardOwnProps> = ({ item }) => (
  <>
    <TopContent {...item} />
    <BottomContent cardLabel={item.elementType} />
  </>
);

export default PdfCard;
