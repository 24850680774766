import React from "react";
import PropTypes from "prop-types";

import CroppedThumbnail from "../../../../croppedThumbnail/CroppedThumbnail";
import { CropButton } from "../../../../buttons/iconButtons";

import "./croppedImagePreview.scss";
import ImageAspectRatio from "../../../../../enums/imageAspectRatio";

function CroppedImagePreview(props) {
  return (
    <div className="cropped-image-preview">
      <CroppedThumbnail {...props}>
        <CropButton isDisabled={false} />
      </CroppedThumbnail>
    </div>
  );
}

CroppedImagePreview.defaultProps = {
  aspectRatio: ImageAspectRatio.HD_16X9,
};

CroppedImagePreview.propTypes = {
  thumbnailUrl: PropTypes.string,
  onClick: PropTypes.func,
  aspectRatio: PropTypes.oneOf([ImageAspectRatio.HD_16X9, ImageAspectRatio.SQUARE_1X1]),
};

export default CroppedImagePreview;
