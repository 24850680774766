import { DetailsHeader, NotFoundMessage, Segments } from "components";
import { Routes, Route, Navigate } from "react-router-dom";
import { GoogleFiles } from "./files/GoogleFiles";
import { GoogleMeetings } from "./meetings/GoogleMeetings";
import { GoogleCollaborations } from "./collaboration/GoogleCollaborations";

const pages = [
  { to: "files", label: "Files" },
  { to: "meetings", label: "Meetings" },
  { to: "collaborations", label: "Collaborations" },
];

export const GoogleReport = () => (
  <div className="nested-content">
    <DetailsHeader title="Google Graph" />
    <Segments to="/reporting/google">
      {pages.map((page) => (
        <Segments.Segment {...page} key={page.label} />
      ))}
    </Segments>
    <Routes>
      <Route path="files" element={<GoogleFiles />} />
      <Route path="meetings" element={<GoogleMeetings />} />
      <Route path="collaborations" element={<GoogleCollaborations />} />
      <Route index element={<Navigate to="files" />} />
      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  </div>
);
