import React from "react";
import { Icon } from "semantic-ui-react";
import type DragAndDropSection from "./DragAndDropSection";

import "./multipleFileErrorDrapAndDropSection.scss";
import { multipleFileError } from "./constants";

class MultipleFileErrorDrapAndDropSection implements DragAndDropSection {
  getSection(): React.ReactElement {
    return (
      <div className="drop-section error">
        <div className="overlay" />
        <div className="drop-box multiple-file-error">
          <div>
            <Icon className="fa-exclamation-triangle fa-lg" />
            <span>Only one file can be uploaded at a time</span>
          </div>
        </div>
      </div>
    );
  }
  compareType(type: string | null): boolean {
    return multipleFileError === type;
  }
}

export { MultipleFileErrorDrapAndDropSection };
