import React, { useEffect, useState } from "react";
import { Checkbox, Grid } from "semantic-ui-react";
import DropdownWrapper from "../../../../components/dropdowns/dropdownWrapper/DropdownWrapper";
import { type CertificateValidationType, type EncodingType, type SignatureAlgorithm, type SigningCertificate } from "../../types";

import "./miscellaneous.scss";

type DropdownItem = {
  text: string;
  value: string;
};

export type ItemsCollection<T> = {
  fetch(): void;
  items: T[];
  current?: T;
  isLoading: boolean;
  onChange(item: T): void;
};

export interface MiscellaneousProps {
  omitAssertionSignatureCheck: boolean;
  useSiteMinder: boolean;
  signRequest: boolean;
  forceAuth: boolean;
  isPassive: boolean;
  onOmitAssertionSignatureCheckChange(): void;
  onUseSiteMinderChange(): void;
  onSignRequestChange(): void;
  onForceAuthChange(): void;
  onIsPassiveChange(): void;
  signingCertificates: ItemsCollection<SigningCertificate>;
  signatureAlgorithms: ItemsCollection<SignatureAlgorithm>;
  encodingTypes: ItemsCollection<EncodingType>;
  certificateValidationTypes: ItemsCollection<CertificateValidationType>;
  isReadOnly: boolean;
}

const Miscellaneous: React.FC<MiscellaneousProps> = ({
  omitAssertionSignatureCheck,
  onOmitAssertionSignatureCheckChange,
  useSiteMinder,
  onUseSiteMinderChange,
  signRequest,
  onSignRequestChange,
  signingCertificates,
  signatureAlgorithms,
  forceAuth,
  onForceAuthChange,
  isPassive,
  onIsPassiveChange,
  encodingTypes,
  certificateValidationTypes,
  isReadOnly,
}) => {
  const [signingCertificatesOptions, setSigningCertificatesOptions] = useState<DropdownItem[]>([]);
  const [signatureAlgorithmsOptions, setSignatureAlgorithmsOptions] = useState<DropdownItem[]>([]);
  const [encodingTypeOptions, setEncodingTypeOptions] = useState<DropdownItem[]>([]);
  const [certificateValidationTypeOptions, setCertificateValidationTypeOptions] = useState<DropdownItem[]>([]);

  useEffect(() => {
    !signingCertificates.items.length && signingCertificates.fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !signatureAlgorithms.items.length && signatureAlgorithms.fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !encodingTypes.items.length && encodingTypes.fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !certificateValidationTypes.items.length && certificateValidationTypes.fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSigningCertificatesOptions(mapOptions(signingCertificates.items));
  }, [signingCertificates.items]);

  useEffect(() => {
    setSignatureAlgorithmsOptions(mapOptions(signatureAlgorithms.items));
  }, [signatureAlgorithms.items]);

  useEffect(() => {
    setEncodingTypeOptions(mapOptions(encodingTypes.items));
  }, [encodingTypes.items]);

  useEffect(() => {
    setCertificateValidationTypeOptions(mapOptions(certificateValidationTypes.items));
  }, [certificateValidationTypes.items]);

  const mapOptions = <T extends { name: string }>(items: T[]) =>
    items.map((i) => {
      return { text: i.name, value: i.name };
    });
  const onChange = <T extends { name: string }>(items: T[], value: any, callback: (item: T) => void) => {
    const item = items.find((i) => i.name === value);
    item && callback(item);
  };

  return (
    <Grid className="miscellaneous-step">
      <Grid.Row>
        <Grid.Column computer={8} mobile={16} tablet={12}>
          <Checkbox
            data-testid="omitAssertionSignatureCheck"
            type="checkbox"
            className="miscellaneous-checkbox"
            toggle
            label="Omit Assertion Signature Check"
            checked={omitAssertionSignatureCheck}
            onChange={onOmitAssertionSignatureCheckChange}
            disabled={isReadOnly}
          />
          <Checkbox
            fluid="true"
            data-testid="useSiteMinder"
            className="miscellaneous-checkbox"
            type="checkbox"
            toggle
            label="Use SiteMinder"
            checked={useSiteMinder}
            onChange={onUseSiteMinderChange}
            disabled={isReadOnly}
          />
          <Checkbox
            fluid="true"
            data-testid="signRequest"
            className="miscellaneous-checkbox"
            type="checkbox"
            toggle
            label="Sign Request"
            checked={signRequest}
            onChange={onSignRequestChange}
            disabled={isReadOnly}
          />
          <section className="dropdown-block">
            <label className="dropdown-title">Signing Certificate</label>
            <DropdownWrapper
              blur
              className="dropdown"
              data-testid="signingCertificates"
              items={signingCertificatesOptions}
              selection
              value={signingCertificates.current && signingCertificates.current.name}
              handleOptionChange={(e, data) =>
                onChange(signingCertificates.items, data.value, signingCertificates.onChange)
              }
              loading={signingCertificates.isLoading}
              disabled={isReadOnly}
            />
            <label className="dropdown-title">Signature Algorithm</label>
            <DropdownWrapper
              blur
              className="dropdown"
              data-testid="signatureAlgorithms"
              items={signatureAlgorithmsOptions}
              selection
              value={signatureAlgorithms.current && signatureAlgorithms.current.name}
              handleOptionChange={(_, data) =>
                onChange(signatureAlgorithms.items, data.value, signatureAlgorithms.onChange)
              }
              loading={signatureAlgorithms.isLoading}
              disabled={isReadOnly}
            />
          </section>
          <Checkbox
            fluid="true"
            data-testid="forceAuth"
            className="miscellaneous-checkbox"
            type="checkbox"
            toggle
            label="Force Auth"
            checked={forceAuth}
            onChange={onForceAuthChange}
            disabled={isReadOnly}
          />
          <Checkbox
            fluid="true"
            data-testid="isPassive"
            className="miscellaneous-checkbox"
            type="checkbox"
            toggle
            label="Is Passive"
            checked={isPassive}
            onChange={onIsPassiveChange}
            disabled={isReadOnly}
          />
          <section className="dropdown-block">
            <label className="dropdown-title">Response Encoding</label>
            <DropdownWrapper
              blur
              className="dropdown"
              data-testid="encodingTypes"
              items={encodingTypeOptions}
              selection
              value={encodingTypes.current && encodingTypes.current.name}
              handleOptionChange={(_, data) => onChange(encodingTypes.items, data.value, encodingTypes.onChange)}
              loading={encodingTypes.isLoading}
              disabled={isReadOnly}
            />
            <label className="dropdown-title">Certificate Validation</label>
            <DropdownWrapper
              blur
              className="dropdown"
              data-testid="certificateValidationTypes"
              items={certificateValidationTypeOptions}
              selection
              value={certificateValidationTypes.current && certificateValidationTypes.current.name}
              handleOptionChange={(_, data) =>
                onChange(certificateValidationTypes.items, data.value, certificateValidationTypes.onChange)
              }
              loading={certificateValidationTypes.isLoading}
              disabled={isReadOnly}
            />
          </section>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Miscellaneous;
