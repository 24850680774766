import * as actionTypes from "../actionTypes/threatDefenceLandingPageDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import {
  type ThreatDefenceLandingPage,
  type ThreatDefenceLandingPageContent,
  type ThreatDefenceLandingPageView,
  type LoadThreatDefenceLandingPageContentResult,
} from "../../types/state";
import { type Dispatcher } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import {
  setThreatDefenceLandingPageEntityIdAction,
  updateThreatDefenceLandingPageCommandSuccess,
} from "./threatDefenceLandingPageEntityStateActions";
import azureStorageUtils from "../../../../../../utils/azureStorageUtils";

export const saveThreatDefenceLandingPage = (landingPageMetadata: ThreatDefenceLandingPageView) => {
  const saveThreatDefenceLandingPageAction = getActionProvider<ThreatDefenceLandingPageView>(
    actionTypes.saveThreatDefenceLandingPage,
  );

  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceLandingPageAction(landingPageMetadata));
  };
};

export const saveThreatDefenceLandingPageContent = (
  threatDefenceLandingPageContent: ThreatDefenceLandingPageContent,
) => {
  const saveThreatDefenceLandingPageContentAction = getActionProvider<ThreatDefenceLandingPageContent>(
    actionTypes.saveThreatDefenceLandingPageContent,
  );

  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceLandingPageContentAction(threatDefenceLandingPageContent));
  };
};

export const clearThreatDefenceLandingPage = () => {
  const resetThreatDefenceLandingPageAction = getActionBaseProvider(actionTypes.clearThreatDefenceLandingPage);

  return (dispatch: Dispatcher) => {
    dispatch(resetThreatDefenceLandingPageAction());
  };
};

export const getThreatDefenceLandingPage = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceLandingPageBegin);
  const success = getActionProvider<ThreatDefenceLandingPage>(actionTypes.getThreatDefenceLandingPageSuccess);
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceLandingPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dataService.threatDefence
      .getThreatDefenceLandingPageAsync(id)
      .then((result: { data: ThreatDefenceLandingPage }) => {
        dispatch(success(result.data));
        dispatch(setThreatDefenceLandingPageEntityIdAction(result.data.id || 0));
        dispatch(updateThreatDefenceLandingPageCommandSuccess({ id: result.data.id || 0 }));
      })
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const getThreatDefenceLandingPageContent = (id: number, getLandingPageOnly: boolean = false) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceLandingPageContentBegin);
  const success = getActionProvider<LoadThreatDefenceLandingPageContentResult>(
    actionTypes.getThreatDefenceLandingPageContentSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceLandingPageContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const contentUrlWithSas: string =
        await dataService.threatDefence.getThreatDefenceLandingPageContentUrlWithSasAsync(id);

      const html = await azureStorageUtils.downloadAsString(contentUrlWithSas);
      dispatch(success({ content: { id, contentUrlWithSas, html }, getLandingPageOnly: getLandingPageOnly }));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const setLandingPageAction = getActionProvider<ThreatDefenceLandingPage>(actionTypes.setLandingPage);
