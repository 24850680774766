import axios from "axios";
import {
  type AddedUsers,
  type CompletedUsers,
  type StartedUsers,
  type ActiveUsers,
  type ConsumedLicenses,
  type EmailActivity,
  type EmailPerformance,
} from "../types/overview";
import { type CustomerCards, type CustomerPacks, type OwnerPacksLicensing, type PackLicenses } from "../types/packs";

export const addedUsersEndpoint = "/api/reports/flows/added-users";
export const completedUsersEndpoint = "/api/reports/flows/completed";
export const startedUsersEndpoint = "/api/reports/flows/started";
export const activeUsersEndpoint = "/api/reports/users/active";
export const consumedLicensesEndpoint = "/api/reports/packs/consumed-licenses";
export const emailActivityEndpoint = "/api/reports/communications/email-activity";
export const emailPerformanceEndpoint = "/api/reports/communications/email-performance";

export const packPerformanceEndpoint = "/api/reports/v2/packs/owner-packs-licensing";
export const packCustomersEndpoint = "/api/reports/v2/packs/pack-licenses";
export const customerPackEndpoint = "/api/reports/v1/packs/customer-packs";
export const customerCardsEndpoint = "/api/reports/v1/packs/customer-cards";

let ownerPacksLicensingAbortController = new AbortController();

export const reportingDataService = {
  getAddedUsers() {
    return axios.get<AddedUsers[]>(addedUsersEndpoint);
  },
  getCompletedUsers() {
    return axios.get<CompletedUsers[]>(completedUsersEndpoint);
  },
  getStartedUsers() {
    return axios.get<StartedUsers[]>(startedUsersEndpoint);
  },
  getActiveUsers() {
    return axios.get<ActiveUsers[]>(activeUsersEndpoint);
  },
  getConsumedLicenses() {
    return axios.get<ConsumedLicenses[]>(consumedLicensesEndpoint);
  },
  getEmailActivity() {
    return axios.get<EmailActivity[]>(emailActivityEndpoint);
  },
  getEmailPerformance() {
    return axios.get<EmailPerformance>(emailPerformanceEndpoint);
  },
  // For BSI / Partners
  getOwnerPacksLicensing() {
    ownerPacksLicensingAbortController.abort();
    ownerPacksLicensingAbortController = new AbortController();
    return axios.get<OwnerPacksLicensing[]>(packPerformanceEndpoint, {
      signal: ownerPacksLicensingAbortController.signal,
    });
  },
  // For drilldown
  getPackLicenses(packId: number) {
    return axios.get<PackLicenses[]>(packCustomersEndpoint, { params: { packId } });
  },
  // For normal customers
  getCustomerPacks(customerId: number) {
    return axios.get<CustomerPacks[]>(customerPackEndpoint, { params: { customerId } });
  },
  getCustomerCards(customerId: number) {
    return axios.get<CustomerCards>(customerCardsEndpoint, { params: { customerId } });
  },
};
