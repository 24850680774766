import type PacksFilters from "../../../../../enums/licensing/PacksFilters";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../utils/filterUtils";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { createFiltersSlice, type FiltersState } from "../../../../Application/slices/createFiltersSlice";

export const initialState: FiltersState<PacksFilters> = {
  filterOptions: {} as GenericFiltersMap<PacksFilters>,
  appliedFilter: {} as GenericFiltersMap<PacksFilters>,
  isLoading: false,
};

export const availablePacksFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AvailablePacks,
    name: "filters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  availablePacksFiltersSlice.actions;

export const appliedFiltersSelector = (state: RootState) => state.packs.packsModalFilters.appliedFilter;
export const filterOptionsSelector = (state: RootState) => state.packs.packsModalFilters.filterOptions;
export const isLoadingSelector = (state: RootState) => state.packs.packsModalFilters.isLoading;

export default availablePacksFiltersSlice.reducer;
