import { type FC } from "react";
import ActionConfirmationModal from "../../../../../../components/modal/ActionConfirmationModal/ActionConfirmationModal";
import type PackVisibilityTypes from "../../../../../../enums/licensing/packVisibilityTypes";
import { pluralize } from "../../../../../../utils/stringUtils";

export interface RemovePackVisibilityConfirmationModalProps {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
  itemsCount: number;
  packVisibilityType: PackVisibilityTypes;
}

const RemovePackVisibilityConfirmationModal: FC<RemovePackVisibilityConfirmationModalProps> = ({
  open,
  onContinue,
  onCancel,
  itemsCount,
  packVisibilityType,
}) => {
  const visibilityTypePluralized = pluralize(packVisibilityType, itemsCount);
  return (
    <ActionConfirmationModal
      open={open}
      title={`Remove ${visibilityTypePluralized}?`}
      onContinue={onContinue}
      onCancel={onCancel}
      withWarningLabel={false}
      confirmLabel="Remove"
    >
      {`Remove ${visibilityTypePluralized}?
      ${
        itemsCount === 1 ? "This" : "These"
      } ${visibilityTypePluralized} will lose the access to see this Pack on the Marketplace.`}
    </ActionConfirmationModal>
  );
};

export default RemovePackVisibilityConfirmationModal;
