import { isEmpty } from "lodash";
import React, { type FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { AddPeopleToContentTypes, ItemsTypes, SortingDirection, SortOptions, ViewType } from "../../../enums";
import { fetchContentAssignmentsOverviewFilterOptions } from "../../../features/People/ContentAssignmentsOverview/thunks/contentAssignmentsOverviewFiltersThunk";
import { type AppDispatch, type RootState } from "../../../features/Application/globaltypes/redux";
import { type IPdfAssignmentModelItem } from "../../../features/People/types";
import PdfsFilterForm from "../../filterForms/PdfsFilterForm/PdfsFilterForm";
import { type Filters } from "../../../utils/queryUtils";
import GenericItemsView from "../../../views/ItemsView/GenericItemsView";
import PdfsNoResults from "../../../views/library/pdfs/contentAssignment/PdfsNoResults";
import SearchInput from "../../searchInput/SearchInput";
import { updateSelectedContentItems } from "../utils/contentAssignmentUtils";
import {
  DEFAULT_FETCH_SORT_COLUMN,
  DEFAULT_FETCH_SORT_DIRECTION,
  START_POSITION,
  DEFAULT_FETCH_ORDER_BY,
  DEFAULT_FETCH_GRID_TOP,
} from "./constants";
import { type Columns, ColumnToParamMap, getColumnOptions } from "./getColumnOptions";
import { bindAction } from "../../../interfaces/redux";
import {
  resetAppliedFilter,
  setAppliedFilter,
} from "../../../features/People/ContentAssignmentsOverview/slices/contentAssignmentsOverviewFiltersSlice";
import { type CardsViewerItem } from "../../cardsViewer/types";
import { PeoplePdfCard } from "../cards/peoplePdfCard/PeoplePdfCard";
import { defaultSortingColumn, SortDirectionLong, sortOptionsListView } from "../ContentAssignmentConstants";
import { type AssignmentListPropsBase, type SelectedItem } from "../types";
import { type LDProps } from "../../../features/LDProps";

interface PdfsAssignmentListOwnProps
  // NOSONAR TODO: would be nice to have uniform props for all assignment lists
  extends Omit<AssignmentListPropsBase, "selectedIds" | "resetList" | "onSelectedChanged"> {
  resetPdfsGrid: () => void;
  resetPdfsList: () => void;
  onSelectedPdfsChanged: (selectedItems: SelectedItem[]) => void;
  fetchPdfsList: (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => void;
  fetchPdfsGrid: (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => void;
  selectedPdfIds: number[];
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  deepLink?: boolean;
}

export type PdfsAssignmentListProps = PdfsAssignmentListOwnProps & PropsFromRedux & LDProps;

export const PdfAssignmentList: FC<PdfsAssignmentListProps> = (props: PdfsAssignmentListProps): React.ReactElement => {
  const {
    isReadOnly,
    customHeaderContent,
    peopleType,
    selectedPdfIds,
    createButton,
    resetFilter,
    filters,
    accessRestricted,
    fetchPdfsGrid,
    fetchPdfsList,
    gridState,
    deepLink,
  } = props;
  const [search, setSearch] = useState("");
  const [selectedViewType, setSelectedViewType] = useState(ViewType.GRID);
  const [gridViewOrderByParams, setGridViewOrderByParams] = useState(DEFAULT_FETCH_ORDER_BY);

  const reloadPdfListItems = useRef<(condition: boolean) => void>();
  const isGridView = useMemo(() => selectedViewType === ViewType.GRID, [selectedViewType]);

  const loadPdfCards = useCallback(
    (skip: number, sortByParams: string, filters?: Filters, searchTerm?: string, top?: number) => {
      const [sortBy, sortDirection] = sortByParams?.split(" ");
      fetchPdfsGrid(skip, DEFAULT_FETCH_GRID_TOP, sortBy, SortDirectionLong[sortDirection], filters, searchTerm);
    },
    [fetchPdfsGrid],
  );

  const getPdfs = useCallback(
    (
      skip?: number,
      top?: number,
      sortingColumnName: string = DEFAULT_FETCH_SORT_COLUMN,
      sortingDirection: SortingDirection = DEFAULT_FETCH_SORT_DIRECTION,
      filters?: Filters,
    ): void => {
      if (isGridView) {
        loadPdfCards(skip ?? gridState.items.length, gridViewOrderByParams, filters, search, top);
      } else {
        const sortColumn = ColumnToParamMap[sortingColumnName as Lowercase<Columns>];
        fetchPdfsList(skip, top, sortColumn, sortingDirection, filters, search);
      }
    },
    [isGridView, loadPdfCards, gridState.items.length, gridViewOrderByParams, search, fetchPdfsList],
  );

  const getFilterOptions = () => props.getFilterOptionsAction(AddPeopleToContentTypes.PDFs);

  const refetchItemsFromStart = () => reloadPdfListItems.current?.(isEmpty(search));

  const refetchGridItemsFromStart = (search: string) => {
    props.resetPdfsGrid();
    loadPdfCards(START_POSITION, gridViewOrderByParams, props.filters.appliedFilter, search);
  };

  const createReloadListItems = (reloadListItems: (condition: boolean) => void) => {
    reloadPdfListItems.current = reloadListItems;
  };

  const onSelectedPdfsListChanged = (ids: number[]) => updateSelectedItems(props.listState.items, ids);

  const onSelectedPdfsGridChanged = (ids: number[]) => updateSelectedItems(props.gridState.items, ids);

  const updateSelectedItems = (collection: IPdfAssignmentModelItem[], ids: number[]) => {
    const updatedSelectedPdfs = updateSelectedContentItems(collection, ids);
    props.onSelectedPdfsChanged?.(updatedSelectedPdfs);
  };

  const onSearchChange = (newSearchText: string) => {
    setSearch(newSearchText);
    if (isGridView) {
      onSelectedPdfsGridChanged([]);
      refetchGridItemsFromStart(newSearchText);
    } else {
      onSelectedPdfsListChanged([]);
      refetchItemsFromStart();
    }
  };

  const onSortChange = (_: any, data: { value?: SortOptions }) => {
    props.resetPdfsGrid();
    setGridViewOrderByParams(data.value || DEFAULT_FETCH_ORDER_BY);
    loadPdfCards(START_POSITION, data.value || DEFAULT_FETCH_ORDER_BY, props.filters.appliedFilter, search);
  };

  useEffect(() => {
    return () => {
      props.resetPdfsList();
      props.resetPdfsGrid();
    };
    // eslint-disable-next-line
  }, []);

  const applyAssignmentFilter = (filter: Filters) => {
    props.applyFilter(filter);
    onSelectedPdfsGridChanged([]);
    onSelectedPdfsListChanged([]);
    props.onSelectedPdfsChanged([]);

    if (isGridView) {
      props.resetPdfsGrid();
      loadPdfCards(START_POSITION, gridViewOrderByParams, filter, search);
    }
  };

  const onViewTypeChange = (viewType: ViewType) => {
    setSelectedViewType(viewType);
    if (viewType === ViewType.GRID) {
      props.resetPdfsGrid();
      loadPdfCards(START_POSITION, SortOptions.ModifiedDateDesc);
    }
    props.onViewTypeChange?.(viewType);
  };

  // @ts-ignore
  const getFilterForm = () => <PdfsFilterForm />;

  const viewState = isGridView ? props.gridState : props.listState;

  return (
    <GenericItemsView
      viewType={selectedViewType}
      columnOptions={getColumnOptions(deepLink)}
      noResultsContent={<PdfsNoResults filtered={false} createButton={createButton} />}
      fetchData={getPdfs}
      setReloadListItems={createReloadListItems}
      blur
      onSortChange={onSortChange}
      sortOptions={sortOptionsListView}
      orderBy={gridViewOrderByParams}
      isLoading={viewState.isLoading}
      items={viewState.items}
      itemsType={ItemsTypes.Pdf}
      dataCount={viewState.itemsCount}
      getFilterForm={getFilterForm}
      resetFilter={resetFilter}
      applyFilter={applyAssignmentFilter}
      appliedFilter={filters.appliedFilter}
      filterOptionsLoading={filters.isLoading}
      filterOptions={filters.filterOptions}
      getFilterOptions={getFilterOptions}
      onSelectedItemChanged={onSelectedPdfsListChanged}
      onSelectedGridItemsChanged={onSelectedPdfsGridChanged}
      renderCard={(props: CardsViewerItem<IPdfAssignmentModelItem>) => {
        return (
          <PeoplePdfCard
            {...props}
            peopleType={peopleType}
            showExpirationDate={false}
            showAdded={false}
            disabled={isReadOnly || props.item.inherited}
            selectable={false}
            deepLink={deepLink}
          />
        );
      }}
      selectedIds={selectedPdfIds}
      isAllDataLoaded={props.gridState.isAllLoaded}
      onViewTypeChange={onViewTypeChange}
      sortingDirection={SortingDirection.Descending}
      defaultSortingColumnName={defaultSortingColumn}
      onLoad={getPdfs}
      tabAlias="pdfs"
      customHeaderContent={customHeaderContent}
      isSelectDisabled={() => true}
      renderSearch={() => (
        <SearchInput placeholder="Search for PDFs..." onChange={onSearchChange} defaultValue={search} />
      )}
      accessRestricted={accessRestricted}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const contentAssignment = state.people.contentAssignment;
  return {
    gridState: contentAssignment.pdfGrid,
    listState: contentAssignment.pdfList,
    filters: state.people.contentAssignmentsOverview.filters,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetFilter: bindAction(resetAppliedFilter, dispatch),
  applyFilter: bindAction(setAppliedFilter, dispatch),
  getFilterOptionsAction: bindAction(fetchContentAssignmentsOverviewFilterOptions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PdfAssignmentList);
export default ConnectedComponent;
