import PropTypes from "prop-types";

import { publishedStatusPropType } from "../common/publishedStatus";

const types = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  titleForGA: PropTypes.string,
  children: PropTypes.object,
  onCancel: PropTypes.func,
  onFinishAsync: PropTypes.func,
  onFinish: PropTypes.func,
  onPreviewFinishAsync: PropTypes.func,
  onProgressAsync: PropTypes.func,
  onProgress: PropTypes.func,
  onRegressAsync: PropTypes.func,
  onRegress: PropTypes.func,
  canProceedAsync: PropTypes.func,
  canRecedeAsync: PropTypes.func,
  wizardActions: PropTypes.shape({
    openWizard: PropTypes.func,
    closeWizard: PropTypes.func,
  }),
  finishButtonLabel: PropTypes.string,
  finishTooltipMessage: PropTypes.string,
  publishedStatus: publishedStatusPropType,
  progressSavedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  isSaveInProgress: PropTypes.bool,
  isFinishButtonDisabled: PropTypes.bool,
  renderCustomFinishButton: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  isPreviousDisabled: PropTypes.bool,
};

export default types;
