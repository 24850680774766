import { type PackOverview } from "../../../features/Licensing/Packs/types/state";

export interface ModalMeta {
  title: string;
  text: string;
}

export const getModalMeta = (packs: PackOverview[]): ModalMeta => {
  if (packs.every((pack) => pack.isDraft)) {
    return {
      title: "Add to Draft?",
      text: "Content is being added to one or more packs with a Draft status. Pack(s) will remain in a Draft state.",
    };
  }

  if (packs.every((pack) => !pack.isDraft)) {
    return {
      title: "Add to Published?",
      text: "Content is being added to one or more packs with a Published status. Pack(s) will be updated and republished.",
    };
  }

  return {
    title: "Add Content?",
    text: "Content is being added to one or more packs with a Published or Draft status. Draft pack(s) will be updated and left in a Draft state. Published pack(s) will be updated and republished.",
  };
};
