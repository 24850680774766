import axios from "axios";
import { type StripoMergeTag, type StripoSpecialLink } from "../../../lib/stripo";
import { inlineStyles } from "../../../components/notifyStep/EmailEditor/inlineStyles";
import { datadogRum } from "@datadog/browser-rum";

const axiosWithoutAuthInterceptor = axios.create();

let loaded: Promise<boolean> | undefined;
let initialized: Promise<boolean> | undefined;
let authToken: string | null = null;

interface CompressOptions {
  minimize: boolean;
  html: string;
  css: string;
}

// Zone will not patch Promise
// https://github.com/angular/zone.js/blob/master/MODULE.md
(window as any).__Zone_disable_ZoneAwarePromise = true;

const getTemplate = () => {
  if (!initialized) {
    return Promise.reject("The Stripo plugin has not been initialized yet.");
  }

  return new Promise<{ html: string; css: string }>((resolve) => {
    initialized!.then(() => {
      (window as any).StripoApi.getTemplate((html: string, css: string) => {
        resolve({ html, css });
      });
    });
  });
};

const compileEmail = async (opts: CompressOptions) => {
  if (!initialized) {
    return Promise.reject("The Stripo plugin has not been initialized yet.");
  }

  const url = "https://plugins.stripo.email/api/v1/cleaner/v1/compress";

  const { data } = await axiosWithoutAuthInterceptor.post<{ html: string }>(url, opts, {
    headers: {
      "ES-PLUGIN-AUTH": `Bearer ${authToken}`,
    },
  });

  return data.html;
};

const load = async (): Promise<boolean> => {
  if (loaded) {
    return loaded;
  }

  const script = document.createElement("script");
  script.id = "stripoScript";
  script.type = "text/javascript";
  script.src = "https://plugins.stripo.email/static/rev/1.81.0/stripo.js";
  script.integrity = "sha384-aE0/BSNmJbo74ncfeAOS7RGYAmy/NJOIXEbgVems9ftANekp6kZ3v4gHqUlco7+8";
  script.crossOrigin = "anonymous";

  loaded = new Promise<boolean>((resolve) => {
    script.onload = () => {
      datadogRum.addAction("On stripoApi loaded");
      resolve(true);
    };
  });

  document.body.appendChild(script);

  return loaded;
};

export interface InitStripoOptions {
  html: string;
  css?: string;
  pluginId?: string;
  accountId: number;
  secretKey?: string;
  mergeTags?: StripoMergeTag[];
  specialLinks?: StripoSpecialLink[];
  onDataChanged?: () => void;
  onTemplateLoaded?: () => void;
}

const init = async (options: InitStripoOptions): Promise<boolean> => {
  const { html, mergeTags, specialLinks, onDataChanged, onTemplateLoaded, accountId } = options;

  await load();

  initialized = new Promise<boolean>((resolve, reject) => {
    (window as any).Stripo.init({
      settingsId: "stripoSettingsContainer",
      previewId: "stripoPreviewContainer",
      html: html,
      specialLinks: specialLinks,
      mergeTags: mergeTags,
      css: inlineStyles,
      apiRequestData: {
        emailId: `account_${accountId}`,
      },
      getAuthToken: (callback: (token: string | null) => void) => {
        axios
          .get("/api/communications/emails/stripo-auth")
          .then(({ data }) => {
            authToken = data;
            callback(data);
          })
          .catch(() => {
            callback(null);
            reject(false);
          });
      },
      onTemplateLoaded: () => {
        resolve(true);
        onTemplateLoaded?.();
      },
      onDataChanged,
    });
  });

  return initialized;
};

const stop = async (): Promise<void> => {
  if (initialized) {
    await initialized;
    (window as any).StripoApi.stop();
    initialized = undefined;
  }
};

export const StripoApi = { getTemplate, init, load, stop, compileEmail };
