const fontVolatilityTolerance = 2;

const htmlUtils = {
  isTextTruncated: (divEl: HTMLDivElement | null) => {
    if (!divEl) {
      return false;
    }
    // the client and scroll heights just differ for some fonts, read more:
    // https://stackoverflow.com/questions/52815758/why-are-an-elements-scrollheight-and-clientheight-not-the-same-for-some-fonts
    // to circumvent this annoyance a value smaller than reasonable line height is substracted
    return divEl.clientHeight < divEl.scrollHeight - fontVolatilityTolerance;
  },
};

export default htmlUtils;
