import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import * as sidebarActionsRedux from "./state/sidebarActions";
import {
  dismissCompleted as dismissCompletedAction,
  hideProgressDrawer as hideProgressDrawerAction,
  showProgressDrawer as showProgressDrawerAction,
} from "../BackgroundTasks/state/backgroundTasksActions";
import { Navigation } from "../../components/navigation";
import { type AppDispatch, type RootState } from "../Application/globaltypes/redux";
import { type PropsWithChildren } from "react";
import { bindAction } from "../../interfaces";

export type SidebarProps = PropsWithChildren & {
  onSignout: () => void;
} & PropsFromRedux;

export const Sidebar = ({
  accountId,
  backgroundTasks,
  dismissCompleted,
  showProgressDrawer,
  hideProgressDrawer,
  children,
  collapsed,
  isCollapsedByUser,
  isProgressDrawerVisible,
  isWizardOpened,
  moboAccountLogoUrl,
  moboAccountName,
  onSignout,
  sidebarActions,
}: SidebarProps) => {
  return (
    <Navigation
      accountId={accountId}
      isWizardOpened={isWizardOpened}
      collapsed={collapsed}
      isCollapsedByUser={isCollapsedByUser}
      onCollapse={sidebarActions.collapseSidebarByUser}
      autoCollapseSidebar={sidebarActions.autoCollapseSidebar}
      onExpand={sidebarActions.expandSidebar}
      children={children}
      onSignout={onSignout}
      backgroundTasks={backgroundTasks}
      isProgressDrawerVisible={isProgressDrawerVisible}
      onDismissAllClick={dismissCompleted}
      showProgressDrawer={showProgressDrawer}
      hideProgressDrawer={hideProgressDrawer}
      moboAccountName={moboAccountName}
      moboAccountLogoUrl={moboAccountLogoUrl}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  isWizardOpened: state.wizard.isWizardOpened,
  collapsed: state.sidebar.collapsed,
  isCollapsedByUser: state.sidebar.isCollapsedByUser,
  moboAccountName: state.userProfile.moboAccountName,
  moboAccountLogoUrl: state.userProfile.moboAccountLogoUrl || "",
  backgroundTasks: state.backgroundTasks.tasks,
  isProgressDrawerVisible: state.backgroundTasks.isProgressDrawerVisible,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sidebarActions: bindActionCreators(sidebarActionsRedux, dispatch),
  dismissCompleted: bindAction(dismissCompletedAction, dispatch),
  showProgressDrawer: bindAction(showProgressDrawerAction, dispatch),
  hideProgressDrawer: bindAction(hideProgressDrawerAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Sidebar);
export default ConnectedComponent;
