import { createAsyncThunk } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type FetchActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { type GetPacksRequest, type GroupPacksRequest } from "../../types/requests";
import { type PackOverview } from "../../types/state";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

export const fetchGroupPacksGrid = createAsyncThunk<FetchActionPayload<PackOverview>, GroupPacksRequest>(
  getPrefix({ namespace: ReducerNamespaceTypes.Licensing, entity: ReducerEntityPrefixTypes.Packs, name: "groupPacks" }),
  async (requestData: GroupPacksRequest, { signal }) => {
    const request: GetPacksRequest = {
      top: requestData.top ?? loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
      sortBy: requestData.sortBy,
      sortOrder: requestData.sortOrder,
    };

    const { getDataPromise, cancel } = dataService.packs.getGroupPacksWithCancel(requestData.groupId, request);

    signal.addEventListener("abort", cancel);

    const result = await getDataPromise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
    };
  },
);

export type GroupPacksGridFetchType = ReturnType<ReturnType<typeof fetchGroupPacksGrid>>;
