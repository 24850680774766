import React, { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { type PackListingView } from "../../types/state";
import "./packListingsOverview.scss";
import { RichTextEditor, useRichTextEditor } from "../../../../../components/TextEditor";

interface Props {
  pack: PackListingView;
  accountId: number;
  isLoading: boolean;
}

export const PackListingsOverview: React.FC<Props> = ({ pack, isLoading }) => {
  const { longDescription } = pack;
  const [editor, editorValue, handlers, utils] = useRichTextEditor(longDescription);

  useEffect(() => {
    editor.children = utils.convertToDescendant(longDescription);
  }, [longDescription, editor, utils]);

  return (
    <div className="scrollable-content">
      <div className="listing-overview-content packs-listing-overview-content">
        <div className="pack-listing-overview-info">
          <div className="overview-info-text read-only-text-editor">
            <RichTextEditor
              editor={editor}
              value={editorValue}
              isReadOnly
              onChange={handlers.onChange}
              showToolbar={false}
            />
          </div>
        </div>
      </div>

      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
    </div>
  );
};
