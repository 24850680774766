import ReducerNamespaceTypes from "../../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../../Application/actions/entityState/entityStateActionTypes";

const buildRootSendingProfileActionType = (actionType: string) =>
  buildRootEntityActionType(
    ReducerNamespaceTypes.Library,
    ReducerEntityPrefixTypes.ThreatDefenceSendingProfiles,
    actionType,
  );

export const createThreatDefenceSendingProfileBegin = buildRootSendingProfileActionType(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);
export const createThreatDefenceSendingProfileSuccess = buildRootSendingProfileActionType(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);
export const createThreatDefenceSendingProfileFailure = buildRootSendingProfileActionType(
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const updateThreatDefenceSendingProfileBegin = buildRootSendingProfileActionType(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);
export const updateThreatDefenceSendingProfileCommandSuccess = buildRootSendingProfileActionType(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);
export const updateThreatDefenceSendingProfileFailure = buildRootSendingProfileActionType(
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const threatDefenceSendingProfileUpdated = buildRootSendingProfileActionType(
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);
export const resetThreatDefenceSendingProfileEntityState = buildRootSendingProfileActionType(
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const fetchLockedThreatDefenceSendingProfileBegin = buildRootSendingProfileActionType(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);
export const fetchLockedThreatDefenceSendingProfileSuccess = buildRootSendingProfileActionType(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);
export const fetchLockedThreatDefenceSendingProfileFailure = buildRootSendingProfileActionType(
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const publishThreatDefenceSendingProfileBegin = buildRootSendingProfileActionType(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishThreatDefenceSendingProfileSuccess = buildRootSendingProfileActionType(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishThreatDefenceSendingProfileFailure = buildRootSendingProfileActionType(
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const revertLockedThreatDefenceSendingProfileBegin = buildRootSendingProfileActionType(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);
export const revertLockedThreatDefenceSendingProfileSuccess = buildRootSendingProfileActionType(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);
export const revertLockedThreatDefenceSendingProfileFailure = buildRootSendingProfileActionType(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const setSendingProfileEntityId = buildRootSendingProfileActionType(entityStateActionTypes.SET_ENTITY_ID);
