import { MAX_ANSWER_FIELD_LENGTH } from "../../constants";
import { type AnswerPropsBase } from "../types";
import InputField from "../../Fields/InputField";
import { WithFormikPayload } from "../../Fields/WithFormikPayload";

export type TextAnswerProps = AnswerPropsBase<string>;

export const TextAnswer = (props: TextAnswerProps) => {
  const { index, answer, onBlur, disabled, validatedFieldProps, propertyNamePrefix } = props;

  const { setFieldValue, setFieldTouched } = validatedFieldProps;

  const propertyName = `${propertyNamePrefix}answer`;

  return (
    <div className="answer-text-block">
      <WithFormikPayload
        propertyName={propertyName}
        {...validatedFieldProps}
        selfValidation
        render={(formikProps) => (
          <InputField
            placeholder={`Answer ${index + 1} text`}
            value={answer.answer}
            maxLength={MAX_ANSWER_FIELD_LENGTH}
            errorPosition={"bottom"}
            onBlur={onBlur}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
    </div>
  );
};
