import { type HttpResponse } from "../../../lib/http";
import { type AppDispatch } from "../globaltypes/redux";
import { setError } from "../slices/appErrorSlice";

export const createHttpErrorHandler =
  (dispatch: AppDispatch) =>
  async <T>(call: () => Promise<HttpResponse<T>>) => {
    const result = await call();

    if (result.isSuccess) {
      return result.data;
    }

    dispatch(setError({ errorCode: result.code, message: result.message }));
  };
