import { type FormikTouched } from "formik";
import { type Descendant } from "slate";
import { type PackView } from "../../features/Licensing/Packs/types/state";
import { RichTextEditor, useRichTextEditor } from "../TextEditor";
import ValidatedField from "./ValidatedField";

export interface TextEditorFieldProps {
  label: string;
  value: string;
  propertyName: string;
  placeholder: string;
  isFormValid: boolean;
  disabled: boolean;
  touched: FormikTouched<PackView>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (propertyNmae: string, value: string, shouldValidate: boolean) => void;
  onIsValidChange?: (isValid: boolean) => void;
  onBlur?: (propertyName: string) => void;
  handleBlur: (event: any) => void;
  handleChange: (event: React.ChangeEvent<any>) => void;
}

export default function TextEditorField(props: TextEditorFieldProps) {
  const {
    value,
    propertyName,
    placeholder,
    disabled,
    setFieldValue,
    onBlur,
    handleBlur,
    setFieldTouched,
    handleChange,
  } = props;
  const [editor, , handlers, utils] = useRichTextEditor();

  const handleOnBlur = (event: any) => {
    handleBlur?.(event);
    onBlur?.(propertyName);
    setTouched();
  };

  const setTouched = () => {
    setFieldTouched(propertyName, true, true);
  };

  const onChange = (newValue: Descendant[]) => {
    const jsonValue = JSON.stringify(newValue);
    setFieldValue(propertyName, jsonValue, true);
    handlers.onChange(newValue);
  };

  const formattedValue = utils.convertToDescendant(value);

  return (
    <ValidatedField {...props}>
      <RichTextEditor
        editor={editor}
        onBlur={handleOnBlur}
        handleChange={handleChange}
        propertyName={propertyName}
        placeholder={placeholder}
        value={formattedValue}
        onChange={onChange}
        isReadOnly={disabled}
        showToolbar
      />
    </ValidatedField>
  );
}
