import { type FC } from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

export interface EditPriorityLinkButtonProps {
  className?: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const EditPriorityLinkButton: FC<EditPriorityLinkButtonProps> = ({ className = "", onClick, isDisabled }) => (
  <BasicLinkButton
    className={className}
    iconClassName="fal fa-cog"
    text="Priority Settings"
    onClick={onClick}
    isDisabled={isDisabled}
  />
);

export default EditPriorityLinkButton;
