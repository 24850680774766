import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import PropTypes from "prop-types";

function PublishLinkButton(props) {
  const { onClick, isDisabled } = props;

  return <BasicLinkButton text="Publish" iconClassName="fal globe" onClick={onClick} isDisabled={isDisabled} />;
}

PublishLinkButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default PublishLinkButton;
