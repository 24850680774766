import React from "react";
import { Accordion } from "semantic-ui-react";
import "./navigationMenuItem.scss";
import SubMenuAccordionItem from "./SubMenuAccordionItem";

export interface SubMenuItemProps {
  classNames: string;
  expanded: boolean;
  label: string;
  nestedItems: React.ReactElement[];
  path: string;
  onClick: () => void;
}

export const SubMenuItem: React.FC<SubMenuItemProps> = ({
  classNames,
  expanded,
  label,
  nestedItems,
  path,
  onClick,
}: SubMenuItemProps) => (
  <React.Fragment key={path}>
    <SubMenuAccordionItem path={path} label={label} className={classNames} expanded={expanded} onClick={onClick} />
    <Accordion.Content className="parent-menu-content" active={expanded}>
      {nestedItems}
    </Accordion.Content>
  </React.Fragment>
);
