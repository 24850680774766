import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Icon, Input, type InputOnChangeData } from "semantic-ui-react";

import "./searchInput.scss";
import { DEBOUNCE_TIME } from "./constants";

export interface SearchInputProps {
  placeholder: string;
  className?: string;
  expandable?: boolean;
  expandToContainer?: string;
  id?: string;
  disabled?: boolean;
  onChange?: (val: any) => void;
  defaultValue?: string;
}

const SearchInput = ({
  placeholder,
  className,
  id,
  disabled,
  onChange,
  expandable,
  expandToContainer,
  defaultValue,
}: SearchInputProps) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [style, setStyle] = useState<any>({});
  const [value, setValue] = useState(defaultValue || "");
  const prevSearchRef = useRef<string>(defaultValue || "");
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const inputRef = useRef<Input | null>(null);
  const searchInputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (value.trim() !== defaultValue) {
      setValue(defaultValue || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    prevSearchRef.current = value;
  }, [value]);
  const prevSearch = prevSearchRef.current;

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  const searchFormatting = (_event: React.ChangeEvent<HTMLInputElement> | null, data: InputOnChangeData): void => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (data.value.trim() === prevSearch.trim()) {
        return;
      }
      onChange && onChange(data.value.trim() ?? "");
    }, DEBOUNCE_TIME);
  };

  const onFocusHandler = useCallback(() => {
    setFocus(true);

    if (searchInputRef.current) {
      const delta = expandToContainer
        ? searchInputRef.current.getBoundingClientRect().left -
          (searchInputRef.current.closest(expandToContainer)?.getBoundingClientRect()?.left || 0)
        : 0;

      expandToContainer &&
        setStyle({
          width:
            (searchInputRef.current.closest(expandToContainer)?.clientWidth || 0 - searchInputRef.current.clientWidth) -
            16 -
            delta,
        });
    }
  }, [expandToContainer, searchInputRef]);

  const onBlurHandler = () => {
    setFocus(false);
    setStyle({});
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    searchFormatting(event, data);
    setValue(data.value);
  };

  const onActionClick = () => {
    setTimeout(() => {
      searchFormatting(null, { value: "" });
      setValue("");
      setFocus(true);
      inputRef.current?.focus();
    }, 0);
  };

  const action = (
    <div className="close-icon" onMouseDown={onActionClick}>
      <Icon name="times circle" />
    </div>
  );

  return (
    <div ref={searchInputRef} className={cn("search-container", className, { focus, expandable })} style={style}>
      <Input
        ref={inputRef}
        icon="search"
        action={value ? action : undefined}
        actionPosition={undefined}
        iconPosition={focus ? undefined : "left"}
        className="search-input"
        placeholder={focus ? undefined : placeholder}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        value={value}
        fluid
        id={id}
        disabled={disabled}
      />
    </div>
  );
};
export default SearchInput;
