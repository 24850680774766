import { SurveyFiltersEnum } from "../../../features/Library/Surveys/types/models";
import { type FilterItemBase } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterViewTypes } from "../../../enums/filterViewTypes";

export const getSurveyFilters = (includePublisher: boolean = true): FilterItemBase<SurveyFiltersEnum>[] => {
  let filters: FilterItemBase<SurveyFiltersEnum>[] = [
    {
      type: FilterViewTypes.DateRange,
      label: "Date Added",
      propertyName: SurveyFiltersEnum.DateCreated,
    },
    {
      type: FilterViewTypes.DateRange,
      label: "Last Modified",
      propertyName: SurveyFiltersEnum.DateModified,
    },
  ];

  if (includePublisher) {
    filters.push({
      type: FilterViewTypes.MultiSelect,
      label: "Publisher",
      propertyName: SurveyFiltersEnum.Publishers,
      placeholder: "All Publishers",
    });
  }

  filters.push(
    {
      type: FilterViewTypes.MultiSelect,
      label: "Packs",
      propertyName: SurveyFiltersEnum.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Software Applications",
      propertyName: SurveyFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewTypes.MultiSelect,
      label: "Labels",
      propertyName: SurveyFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  );

  return filters;
};
