import { useEffect, useRef, type MutableRefObject } from "react";

import { type NotifyStepSettings } from "../../../../interfaces";
import { type SwitchPropsConfig } from "../../../notifyStep/NotifyStepContent/switches/types";
import NotifyWizardStep from "../../../notifyStep/NotifyWizardStep/NotifyWizardStep";
import { type NotifyByEmailController } from "../../../notifyStep/notifyByEmailController";
import { type ModalWithStepsStep } from "../../../modal/ModalWithSteps";
import { type CommunicationChannels, type TemplateTypes } from "../../../../enums";

import "./notifyStep.scss";

export interface NotifyStepProps extends ModalWithStepsStep {
  onIsDataValidChange: (isValid: boolean) => void;
  onIsDataLoaded: (isValid: boolean) => void;
  isDataLoaded: boolean;
  onPreviousNotifyStepRef: MutableRefObject<(() => Promise<void>) | undefined>;
  getNotifySettingsRef: MutableRefObject<(() => Promise<NotifyStepSettings | undefined>) | undefined>;
  notifyStepDisabled?: boolean;
  notifyTemplateType: TemplateTypes;
  renderSwitch: (props: SwitchPropsConfig) => React.ReactElement;
  shouldNotify: boolean;
  communicationChannel: CommunicationChannels;
}

export const NotifyStep = (props: NotifyStepProps) => {
  const {
    onIsDataValidChange,
    onIsDataLoaded,
    isDataLoaded,
    onPreviousNotifyStepRef,
    getNotifySettingsRef,
    notifyStepDisabled,
    notifyTemplateType,
    renderSwitch,
    shouldNotify,
    communicationChannel,
  } = props;
  const controllerRef = useRef<NotifyByEmailController>();

  useEffect(() => {
    const onPreviousNotifyStep = async () => {
      const controller = controllerRef.current;

      if (controller && isDataLoaded) {
        await controller.saveChanges();
      }
    };

    if (onPreviousNotifyStepRef) {
      onPreviousNotifyStepRef.current = onPreviousNotifyStep;
    }
  }, [onPreviousNotifyStepRef, isDataLoaded]);

  useEffect(() => {
    const getNotifySettings = async () => {
      try {
        return await controllerRef.current?.getSettings(shouldNotify, communicationChannel, notifyStepDisabled);
      } catch (error) {}
    };

    if (getNotifySettingsRef) {
      getNotifySettingsRef.current = getNotifySettings;
    }
  }, [getNotifySettingsRef, shouldNotify, communicationChannel, notifyStepDisabled]);

  const onNotifyStepLoaded = (controller: NotifyByEmailController) => {
    controllerRef.current = controller;
    onIsDataLoaded(true);
  };

  return (
    <div className="notify-step">
      <NotifyWizardStep
        templateType={notifyTemplateType}
        onLoaded={onNotifyStepLoaded}
        onValidChange={onIsDataValidChange}
        isDisabled={notifyStepDisabled}
        renderSwitch={renderSwitch}
        shouldNotify={shouldNotify}
        communicationChannel={communicationChannel}
      />
    </div>
  );
};

export default NotifyStep;
