import { connect } from "react-redux";
import { noop } from "lodash";

import GenericItemsView, { type GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { type AppDispatch, type RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/flowUsersSlice";
import { type FlowUser } from "../../../../../types";
import { flowsStateSelector } from "../../../../../state/selectors";
import { bindAction } from "../../../../../../../../interfaces/redux";
import { resetAppliedFilter, setAppliedFilter } from "../../../../../state/slices/flowUserFiltersSlice";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const users = flowsStateSelector(state).edit.users;
  const userFilters = flowsStateSelector(state).edit.userFilters;
  return {
    appliedFilter: userFilters.appliedFilter,
    filterOptions: userFilters.filterOptions,
    items: users.items,
    isLoading: users.isLoading,
    dataCount: users.itemsCount,
    selectedIds: users.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getFilterOptions: noop,
  applyFilter: bindAction(setAppliedFilter, dispatch),
  resetFilter: bindAction(resetAppliedFilter, dispatch),
  onSelectedItemChanged: bindAction(selectedItemsChanged, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<FlowUser>) => React.ReactElement);
