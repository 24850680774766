import { bindActionCreators } from "@reduxjs/toolkit";
import { format } from "d3";
import { type FC, useState, useEffect, useMemo, useCallback } from "react";
import { connect, type ConnectedProps } from "react-redux";
import {
  ChartWrapper,
  ColumnChart,
  HorizontalBarChart,
  LineChart,
  TickValueType,
} from "../../../../../components/charts";
import { type DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { fetchTeamsDeviceUsage } from "../../../state/msgraph/graphActions";
import { selectTeamsDeviceUsage } from "../../../state/msgraph/graphSlice";
import {
  selectActiveUsersSummations,
  selectFormattedBarData,
  selectFormattedColumnData,
  selectUsageByDevicePercentages,
  selectUsageLineData,
} from "../../../state/msgraph/selectors/deviceSelectors";
import {
  barDataHasValues,
  deviceList,
  deviceUsageColorRange,
  formatNumberString,
  leftBlackBoxFormatter,
  lineDataIndexLookup,
  lookupDeviceFriendlyName,
} from "../../utils/utils";
import { type BasePerformanceRequestFilterParams } from "../../../../Library/Common/models";
import {
  linearChartState,
  transitionTime,
  noData,
  createDateRange,
  getFormattedTimeStringFromPeriod,
  columnChartMargins,
  lineChartMargins,
} from "../../../../Library/Common/utils/performanceUtils";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

import "../teamsReport.scss";

enum ActiveUsersChartType {
  Column,
  Line,
}

enum DeviceUsageChartType {
  Bar,
  Line,
}

export interface Props extends PropsFromRedux {
  dateFilter: BasePerformanceRequestFilterParams;
}

export const Usage: FC<Props> = ({
  dateFilter,
  teamsDeviceUsage,
  teamsDeviceSummation,
  teamsDevicePercentages,
  formattedColumnData,
  formattedBarData,
  deviceUsageLineChartData,
  getTeamsDeviceUsage,
}) => {
  const [activeUsersChartType, setActiveUsersChartType] = useState<ActiveUsersChartType>(ActiveUsersChartType.Column);
  const [deviceUsageChartType, setDeviceUsageChartType] = useState<DeviceUsageChartType>(DeviceUsageChartType.Bar);

  const dateRange = useMemo(
    () => createDateRange(dateFilter.dateFrom!, dateFilter.dateTo!),
    [dateFilter.dateFrom, dateFilter.dateTo],
  );

  const [chartPeriod, measureRef] = useChartPeriodMeasure(dateRange, 250);

  const { lineChartFormatter, groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartTickFormat = useCallback(
    (xValue: string) => getFormattedTimeStringFromPeriod(xValue, chartPeriod, dateRange),
    [chartPeriod, dateRange],
  );

  const handleActiveUsersChange = useCallback((option: string) => {
    option === "Active Users - Column Chart"
      ? setActiveUsersChartType(ActiveUsersChartType.Column)
      : setActiveUsersChartType(ActiveUsersChartType.Line);
  }, []);

  const handleDeviceUsageChange = useCallback((option: string) => {
    option === "Usage By Device - Bar Chart"
      ? setDeviceUsageChartType(DeviceUsageChartType.Bar)
      : setDeviceUsageChartType(DeviceUsageChartType.Line);
  }, []);

  const horizontalBarFormatter = useCallback(
    (d: DataPoint): string => {
      type percentageType = keyof typeof teamsDevicePercentages.categoryPercentages;
      const percentage = teamsDevicePercentages.categoryPercentages[d.category as percentageType];
      const value = teamsDevicePercentages.categorySums[d.category as percentageType];

      return `${format(".2s")(value)} | ${percentage}%`;
    },
    [teamsDevicePercentages],
  );

  const byDeviceLineFormatter = useCallback(
    (date: Date, percentage: number, dataIndex: number) => {
      // Convert date object to "YYYY-MM-DD" with padded 0's if needed
      // This is the format of the API "ReportDate"
      const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      // Data index is hardset in the Line chart below. If that is changed, this needs to be updated
      const valueIndex = teamsDeviceUsage.value.findIndex((v) => v.ReportDate === dateString);
      const categoryValue = lineDataIndexLookup(dataIndex, teamsDeviceUsage.value[valueIndex]);
      return `${formatNumberString(categoryValue)} | ${percentage}%`;
    },
    [teamsDeviceUsage.value],
  );

  const lineXAxis = useMemo(() => teamsDeviceSummation.map((d) => d.date), [teamsDeviceSummation]);
  const lineYAxis = useMemo(() => teamsDeviceSummation.map((d) => d.totalCount), [teamsDeviceSummation]);

  useEffect(() => {
    if (chartPeriod !== "UNSET") getTeamsDeviceUsage({ ...dateFilter, aggregation: chartPeriod });
  }, [getTeamsDeviceUsage, dateFilter, chartPeriod]);

  const activeUsersColumnChart = useMemo(
    () =>
      barDataHasValues(formattedColumnData) ? (
        <ColumnChart
          margins={columnChartMargins}
          data={formattedColumnData}
          domain={[0, Math.max(...formattedColumnData.map((d) => d.value))]}
          xAxisTickFormat={(d) => getFormattedTimeStringFromPeriod(d as string, chartPeriod, dateRange)}
          tooltipFormatter={groupedBarFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [formattedColumnData, groupedBarFormatter, dateFilter, chartPeriod, dateRange],
  );

  const activeUsersLineChart = useMemo(
    () =>
      lineXAxis.length > 0 ? (
        <LineChart
          margins={lineChartMargins}
          xData={[lineXAxis]}
          yData={[lineYAxis]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={linearChartState.yFormatterFunc}
          tooltipFormatter={lineChartFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [lineXAxis, lineYAxis, lineChartTickFormat, lineChartFormatter, dateFilter],
  );

  const deviceUsageBarChart = useMemo(
    () =>
      barDataHasValues(formattedBarData) ? (
        <HorizontalBarChart
          {...sharedAccountReportingHorizontalBarProps}
          data={formattedBarData}
          domain={[0, Math.max(...formattedBarData.map((d) => d.value))]}
          xAxisTickFormat={(d) => `${d}%`}
          yAxisTickFormat={lookupDeviceFriendlyName}
          tooltipFormatter={horizontalBarFormatter}
          transitionDurationInMs={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [formattedBarData, horizontalBarFormatter, dateFilter],
  );

  const deviceUsageLineChart = useMemo(
    () =>
      deviceUsageLineChartData.dates.length > 0 ? (
        <LineChart
          margins={lineChartMargins}
          xData={[
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
            deviceUsageLineChartData.dates,
          ]}
          yData={[
            deviceUsageLineChartData.Android,
            deviceUsageLineChartData.ChromeOS,
            deviceUsageLineChartData.iOS,
            deviceUsageLineChartData.Linux,
            deviceUsageLineChartData.Mac,
            deviceUsageLineChartData.Web,
            deviceUsageLineChartData.Windows,
            deviceUsageLineChartData.WindowsPhone,
          ]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yBlackBoxFormat={leftBlackBoxFormatter}
          yExtraTickFormat={(d) => `${d}%`}
          yTickValueType={TickValueType.IntegersOnly}
          colorRange={deviceUsageColorRange}
          tooltipFormatter={byDeviceLineFormatter}
          transitionDuration={transitionTime}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      deviceUsageLineChartData.dates,
      deviceUsageLineChartData.Android,
      deviceUsageLineChartData.ChromeOS,
      deviceUsageLineChartData.iOS,
      deviceUsageLineChartData.Linux,
      deviceUsageLineChartData.Mac,
      deviceUsageLineChartData.Web,
      deviceUsageLineChartData.Windows,
      deviceUsageLineChartData.WindowsPhone,
      lineChartTickFormat,
      byDeviceLineFormatter,
      dateFilter,
    ],
  );

  return (
    <>
      <div className="teams-chart-container" ref={measureRef}>
        <ChartWrapper
          titles={["Active Users - Column Chart", "Active Users - Line Chart"]}
          onChange={handleActiveUsersChange}
        >
          <RequestStatusRenderer state={teamsDeviceUsage}>
            {activeUsersChartType === ActiveUsersChartType.Column ? activeUsersColumnChart : activeUsersLineChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="teams-chart-container">
        <ChartWrapper
          titles={["Usage By Device - Bar Chart", "Usage By Device - Line Chart"]}
          onChange={handleDeviceUsageChange}
          showLegend={deviceUsageChartType === DeviceUsageChartType.Line}
          legendLabels={deviceList}
          colorRange={deviceUsageColorRange}
        >
          <RequestStatusRenderer state={teamsDeviceUsage}>
            {deviceUsageChartType === DeviceUsageChartType.Bar ? deviceUsageBarChart : deviceUsageLineChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  teamsDeviceUsage: selectTeamsDeviceUsage(state),
  teamsDeviceSummation: selectActiveUsersSummations(state),
  teamsDevicePercentages: selectUsageByDevicePercentages(state),
  formattedColumnData: selectFormattedColumnData(state),
  formattedBarData: selectFormattedBarData(state),
  deviceUsageLineChartData: selectUsageLineData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTeamsDeviceUsage: bindActionCreators(fetchTeamsDeviceUsage, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Usage);
export default ConnectedComponent;
