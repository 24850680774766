import ActionConfirmationModal from "../../../../../components/modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "../../../../../utils/stringUtils";

export interface ChangeIntegrationIsActiveStateConfirmationModalProps {
  isOpen: boolean;
  selectedIntegrations: any[];
  makeActive: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

const ChangeIntegrationIsActiveStateConfirmationModal: React.FC<
  ChangeIntegrationIsActiveStateConfirmationModalProps
> = (props: ChangeIntegrationIsActiveStateConfirmationModalProps) => {
  const { isOpen, selectedIntegrations, makeActive, onCancel, onContinue } = props;
  const pluralizedIntegrationWord = pluralize("integration", selectedIntegrations.length);
  const title = makeActive ? `Make ${pluralizedIntegrationWord} Active` : `Make ${pluralizedIntegrationWord} Inactive`;
  const modalDescription = makeActive
    ? `${title}? By clicking on this option, synchronization will start and ${pluralizedIntegrationWord} status will be changed after the sync is completed`
    : `${title}? By clicking on this option, usage data and user synchronization will be paused`;
  return (
    <ActionConfirmationModal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      onContinue={onContinue}
      withWarningLabel={false}
      confirmLabel="Confirm"
    >
      {modalDescription}
    </ActionConfirmationModal>
  );
};

export default ChangeIntegrationIsActiveStateConfirmationModal;
