export enum FlowCreateSteps {
  Configuration,
  Settings,
  Content,
  Goal,
}

export const CreateFlowStepConfig = {
  [FlowCreateSteps.Configuration]: { showAutoSaveOnTab: true },
  [FlowCreateSteps.Settings]: { showAutoSaveOnTab: false },
  [FlowCreateSteps.Content]: { showAutoSaveOnTab: true },
  [FlowCreateSteps.Goal]: { showAutoSaveOnTab: true },
};

export const pageIndexes = [
  FlowCreateSteps.Configuration,
  FlowCreateSteps.Settings,
  FlowCreateSteps.Content,
  FlowCreateSteps.Goal,
];
