import { type FC } from "react";
import { pluralize } from "../../../utils/stringUtils";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";
import ModalSizes from "../../../enums/modalSizes";

export interface RemoveMembersConfirmationModalProps {
  open?: boolean;
  onContinue: () => void;
  onCancel: () => void;
  usersCount: number;
  size?: ModalSizes;
  featureName: string;
}

const RemoveMembersConfirmationModal: FC<RemoveMembersConfirmationModalProps> = ({
  onCancel,
  onContinue,
  usersCount,
  featureName,
  open = false,
  size = ModalSizes.tiny,
}) => (
  <ActionConfirmationModal
    open={open}
    onCancel={onCancel}
    onContinue={onContinue}
    title={`Remove ${pluralize("User", usersCount)}?`}
    size={size}
    withWarningLabel={false}
    confirmLabel="Remove"
  >
    Are you sure you want to remove ({usersCount}) {pluralize("user", usersCount)} from this {featureName}?
  </ActionConfirmationModal>
);

export default RemoveMembersConfirmationModal;
