import { type PacksContextItem } from "../../../../../../interfaces";
import { type FC } from "react";
import cn from "classnames";
import { PacksDropdown } from "./PacksDropdown";
import { type PackCombination } from "../../../../../../interfaces/licenseConfirmation";
import { type FlowAssignment } from "../../../state/slices/packsContextSlice";
import { isEmpty } from "lodash";
import { Thumbnail } from "./Thumbnail";
import styles from "./assetList.module.scss";

export interface Props {
  items: PacksContextItem[];
  conflicts: Record<number, PackCombination[]>;
  assignments: FlowAssignment[];
  resolveFlowConflict(assignment: FlowAssignment): void;
}

export const FlowAssetList: FC<Props> = ({ items, resolveFlowConflict, assignments, conflicts }) => {
  const flowItems = items.filter((flow) => !isEmpty(conflicts[parseInt(flow.id)]));

  const getValue = (item: PacksContextItem) => {
    const flowAssignment = assignments.find((assignment) => assignment.flowId === parseInt(item.id));
    return flowAssignment?.packIds.join(":") ?? undefined;
  };

  return (
    <div>
      {flowItems.map((item) => (
        <div key={item.id} className={cn("pack-context-item", styles["asset-item"])} data-testid="context-items-row">
          <Thumbnail label={item.title} type={item.type} url={item.thumbnailUrl} />

          <PacksDropdown
            options={conflicts[parseInt(item.id)] ?? []}
            value={getValue(item)}
            withTruncation={false}
            onChange={(newIds: string) => {
              resolveFlowConflict({ flowId: parseInt(item.id), packIds: toPackIds(newIds) });
            }}
          />
        </div>
      ))}
    </div>
  );
};

function toPackIds(packIds: string): number[] {
  return packIds.split(":").map((id) => parseInt(id));
}
