import React, { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Icon } from "semantic-ui-react";
import {
  removeFixedError as removeFixedErrorActionCreator,
  toggleErrorMessagesBar as toggleErrorMessagesBarActionCreator,
} from "../validator/flowValidatorActionCreators";
import { type FlowValidationError, type IFixedError, ValidatorErrorTypes } from "../validator/flowValidatorReducer";

import "./designerErrorsView.scss";
import { ActualError } from "./ActualError/ActualError";
import { FixedError } from "./FixedError/FixedError";
import { type AppDispatch } from "features/Application/globaltypes/redux";
import { useReactFlowCanvasActions } from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useErrorNavigationState } from "../FlowComponentsPanel/Providers/hooks/useErrorNavigationState";
import { useErrorNavigationActions } from "../FlowComponentsPanel/Providers/hooks/useErrorNavigationActions";

// @see texts in Acceptance Criteria https://brainstorm.atlassian.net/browse/SAAS-7155
const ActualErrorsMapping: Record<ValidatorErrorTypes, string> = {
  [ValidatorErrorTypes.WithinError]: "Within field error",
  [ValidatorErrorTypes.StartError]: "No Start of Flow",
  [ValidatorErrorTypes.ItemError]: "Missing Trigger",
  [ValidatorErrorTypes.TriggerError]: "Invalid triggers",
  [ValidatorErrorTypes.FlowEndError]: "End of flow required",
  [ValidatorErrorTypes.ConnectionActionTriggersError]: "Input trigger type error",
  [ValidatorErrorTypes.SectionHeaderNameError]: "Section Header Name error",
  [ValidatorErrorTypes.SectionHeaderDescriptionError]: "Section Header Description error",
  [ValidatorErrorTypes.DeletedItemError]: "Item was Deleted",
};

// @see texts in Acceptance Criteria https://brainstorm.atlassian.net/browse/SAAS-9309
const FixedErrorsMapping: Record<ValidatorErrorTypes, { title: string; message: string }> = {
  [ValidatorErrorTypes.WithinError]: { title: "Error fixed", message: "Within field is valid" },
  [ValidatorErrorTypes.StartError]: { title: "Error fixed", message: "Flow has a start" },
  [ValidatorErrorTypes.ItemError]: { title: "Error fixed", message: "Asset has a trigger" },
  [ValidatorErrorTypes.TriggerError]: { title: "Error fixed", message: "Asset has valid triggers" },
  [ValidatorErrorTypes.FlowEndError]: { title: "Error fixed", message: "End of flow is valid" },
  [ValidatorErrorTypes.ConnectionActionTriggersError]: { title: "Error fixed", message: "Valid input triggers" },
  [ValidatorErrorTypes.SectionHeaderNameError]: { title: "Error fixed", message: "Section Header name is valid" },
  [ValidatorErrorTypes.SectionHeaderDescriptionError]: {
    title: "Error fixed",
    message: "Section Header description  is valid",
  },
  [ValidatorErrorTypes.DeletedItemError]: { title: "Error fixed", message: "Item was Removed" },
};

interface IDesignerErrorsViewProps extends PropsFromRedux {
  navigateTo: (error: FlowValidationError) => void;
  errorViewErrors: FlowValidationError[];
  fixedErrors: IFixedError[];
}

export const DesignerErrorsView: React.FC<IDesignerErrorsViewProps> = ({
  toggleErrorMessagesBar,
  errorViewErrors,
  fixedErrors,
  removeFixedError,
  navigateTo,
}) => {
  const { currentError } = useErrorNavigationState();
  const { setCurrentError } = useErrorNavigationActions();
  const { setElementInvalid } = useReactFlowCanvasActions();

  const onCloseHandler = () => {
    toggleErrorMessagesBar(false);
  };

  useEffect(() => {
    return () => {
      setElementInvalid("");
      setCurrentError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="designer-errors-view" data-hook="designer-errors-view">
      <div className="designer-errors-total">
        {errorViewErrors.length} Errors found{" "}
        <div className="close-bar-icon" role="button" data-hook-type="close-errors" onClick={onCloseHandler}>
          <Icon className="times outline with-label" size="large" />
        </div>
      </div>
      {fixedErrors.map((m) => (
        <FixedError
          key={m.id}
          id={m.id}
          message={FixedErrorsMapping[m.type].message}
          title={FixedErrorsMapping[m.type].title}
          onClose={removeFixedError}
        />
      ))}
      {errorViewErrors.map((m) => (
        <ActualError
          key={m.id}
          message={m.errorMessage}
          title={ActualErrorsMapping[m.type]}
          selected={m.id === currentError?.id}
          onClick={() => navigateTo(m)}
        />
      ))}
    </div>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleErrorMessagesBar: (visible: boolean) =>
      dispatch(toggleErrorMessagesBarActionCreator({ showErrorMessagesBar: visible })),
    removeFixedError: (id: string) => dispatch(removeFixedErrorActionCreator({ id })),
  };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(DesignerErrorsView);
export default ConnectedComponent;
