import midnightService from "../../../../Application/services/midnightService/midnightService";
import DraftEntityTypes from "../../../../../enums/draftEntityTypes";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/pdfEntityStateActionTypes";

import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { type NotifyStepSettings } from "../../../../../interfaces";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { type PdfPayload } from "../../types/models";
import { type CreatePdfRequest } from "../../types/requests";
import {
  PdfDiscardSuccess,
  PdfEditSuccess,
  PdfLockSuccess,
  PdfPublishSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const updatePdfCommandSuccess = getActionProvider(
  actionTypes.updatePdfCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const pdfUpdated = getActionProvider(actionTypes.pdfUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const publishDraftPdfSuccess = getActionProvider<MidnightActionPayload>(actionTypes.publishPdfSuccess);
export const fetchDiscardPdfSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardPdfSuccess);
export const fetchLockedPdfSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedPdfSuccess);
export const setPdfEntityIdAction = getActionProvider<number>(actionTypes.setPdfEntityId);
export const resetPdf = getActionBaseProvider(actionTypes.resetPdfEntityState);

export const publishPdf = (id: number, notificationSettings?: NotifyStepSettings, notifyTypes?: number[]) => {
  const begin = getActionBaseProvider(actionTypes.publishPdfBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishPdfFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PdfPublishSuccess }));
    try {
      await midnightService.releaseLock(
        DraftEntityTypes.Pdfs,
        id,
        notificationSettings ? { notificationSettings, notifyTypes } : undefined,
      );
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchDraftPdfEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedPdfBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedPdfFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PdfLockSuccess }));
    try {
      await midnightService.getEntityLock(DraftEntityTypes.Pdfs, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updatePdfTags = (id: number, tags: string[], type: "labels" | "softwareApplications") => {
  const begin = getActionBaseProvider(actionTypes.updatePdfBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updatePdfFailure);

  const tagTypeMap = {
    labels: TagsEnum.Label,
    softwareApplications: TagsEnum.SoftwareApplication,
  };

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: id, action: PdfEditSuccess }));
    try {
      await dataService.pdfs.savePdfTagsAsync(id, { tags, tagType: tagTypeMap[type] });
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updatePdf = (metadata: PdfPayload) => {
  const begin = getActionBaseProvider(actionTypes.updatePdfBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updatePdfFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: PdfEditSuccess }));
    try {
      await dataService.pdfs.updatePdfAsync(metadata);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const createPdf = (data: CreatePdfRequest) => {
  const begin = getActionBaseProvider(actionTypes.createPdfBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createPdfSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createPdfFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(DraftEntityTypes.Pdfs, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const discardPdf = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardPdfBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardPdfFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PdfDiscardSuccess }));
    try {
      await midnightService.discardLock(DraftEntityTypes.Pdfs, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};
