import React, { Component } from "react";
import PropTypes from "prop-types";

import ImageWrapper from "./imageWrapper/ImageWrapper";

export class ImageFallbackPlaceholder extends Component {
  state = {
    isDefaultIcon: !this.props.imageInfo.src,
  };

  setDefaultIcon = () => {
    this.setState({ isDefaultIcon: true });
  };

  render() {
    let { children, imageInfo } = this.props;

    imageInfo.onError = this.setDefaultIcon;
    return <>{this.state.isDefaultIcon ? children : <ImageWrapper imageInfo={imageInfo} />}</>;
  }
}

ImageFallbackPlaceholder.propTypes = {
  imageInfo: PropTypes.shape({
    src: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
};
