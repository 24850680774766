import React from "react";
import cn from "classnames";
import CroppedThumbnail from "../../croppedThumbnail/CroppedThumbnail";
import { EditButton } from "../../buttons/iconButtons";
import ImageAspectRatio from "../../../enums/imageAspectRatio";
import { noop } from "lodash";

export interface BasePreviewProps {
  label: string;
  value: string;
  className: string;
  disabled: boolean;
  isImagePreview: boolean;
  isProcessing: boolean;
  placeholder: React.ReactElement;
  aspectRatio: ImageAspectRatio;
  markAsRequired?: boolean;
  editBtnEnabled: boolean;
  removeBtnEnabled: boolean;
  onCroppedThumbnailClick?: () => void;
  onClick: () => void;
  onRemove: () => void;
}

function BasePreview(props: BasePreviewProps) {
  const {
    label,
    value,
    className,
    placeholder,
    disabled,
    editBtnEnabled,
    removeBtnEnabled,
    isImagePreview,
    isProcessing,
    onClick,
    onRemove,
    onCroppedThumbnailClick,
    aspectRatio,
    markAsRequired,
  } = props;

  const preview = isProcessing ? (
    <>
      <div className="processing-container" onClick={onCroppedThumbnailClick} />
      <span className="processing-label">Processing...</span>
    </>
  ) : (
    <CroppedThumbnail
      thumbnailUrl={value}
      onClick={onCroppedThumbnailClick}
      placeholderContent={placeholder}
      className={cn({ disabled: disabled })}
      aspectRatio={aspectRatio}
     />
  );

  const onEditButtonClick = () => {
    onCroppedThumbnailClick?.();
    onClick();
  };

  const remove = (ev: React.MouseEvent<HTMLElement>): void => {
    ev.stopPropagation();
    onRemove?.();
  };

  const labelClasses = {
    "validated-field-label": true,
    "preview-label": true,
    "required-asterisk": markAsRequired,
  };

  return (
    <>
      <>
        {label && <label className={cn(labelClasses)}>{label}</label>}

        {editBtnEnabled && !disabled && value && <EditButton isDisabled={disabled} onClick={onEditButtonClick} />}
        <div
          className={cn(
            isImagePreview ? "image-preview" : "thumbnail-preview",
            { "no-thumbnail": !value },
            "field",
            "validated-field",
            className,
            {
              disabled: disabled,
            },
          )}
          onClick={disabled ? () => null : onClick}
        >
          {removeBtnEnabled && !disabled && !!value && <i className="icon window close" onClick={remove} />}
          {preview}
        </div>
      </>
    </>
  );
}

BasePreview.defaultProps = {
  isImagePreview: false,
  isProcessing: false,
  aspectRatio: ImageAspectRatio.HD_16X9,
  onCroppedThumbnailClick: noop,
};

export default BasePreview;
