import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface WithRouterProps {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string | undefined>;
  navigate: ReturnType<typeof useNavigate>;
}

export const withRouter = <TProps extends WithRouterProps>(
  Component: React.ComponentType<TProps>,
): React.ComponentType<Omit<TProps, keyof WithRouterProps>> => {
  return (props: Omit<TProps, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return <Component {...(props as TProps)} location={location} params={params} navigate={navigate} />;
  };
};
