import { bindActionCreators } from "@reduxjs/toolkit";
import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import {
  selectFileSharing,
  selectFilesViewedOrEdited,
  selectFilesSynced,
  selectActiveUsersOneDriveSummations,
  selectActiveUsersOneDrive,
} from "../../../state/msgraph/selectors/onedriveActivitySelectors";
import { fetchOnedriveActivityFileCounts } from "../../../state/msgraph/graphActions";
import { ChartWrapper, ColumnChart, LineChart, PieChart, TickValueType } from "../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { selectOnedriveActivityFileCounts } from "../../../state/msgraph/graphSlice";
import { barDataHasValues, lineDataHasValues } from "../../utils/utils";
import {
  lineChartMargins,
  columnChartMargins,
  completesColor,
  createDateRange,
  getFormattedTimeStringFromPeriod,
  linearChartState,
  noData,
  startsColor,
  validLineData,
  type PerformanceFilter,
} from "../../../../Library/Common/utils/performanceUtils";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";

import "../onedriveReport.scss";

export interface Props extends PropsFromRedux {
  dateFilter: PerformanceFilter;
}

const pieColors = [startsColor, completesColor];
enum ActiveUsersChartType {
  Column,
  Line,
}

export const Activity: FC<Props> = ({
  dateFilter,
  onedriveActivityFileCount,
  onedriveSummation,
  activeUsers,
  filesViewedOrEdited,
  fileSharing,
  filesSynced,
  getOneDriveUserActivityFileCounts,
}) => {
  const dateRange = useMemo(() => createDateRange(dateFilter.dateFrom, dateFilter.dateTo), [dateFilter]);
  const [chartPeriod, measureRef] = useChartPeriodMeasure(dateRange, lineChartMargins.left + lineChartMargins.right);

  const { lineChartFormatter, groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartTickFormat = useCallback(
    (xValue: string) => getFormattedTimeStringFromPeriod(xValue, chartPeriod, dateRange),
    [chartPeriod, dateRange],
  );

  useEffect(() => {
    if (chartPeriod !== "UNSET") getOneDriveUserActivityFileCounts({ ...dateFilter, period: chartPeriod });
  }, [getOneDriveUserActivityFileCounts, dateFilter, chartPeriod]);

  // Chart states
  const filesViewedOrEditedLineChart = useMemo(
    () =>
      lineDataHasValues(filesViewedOrEdited.ViewedOrEdited) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[filesViewedOrEdited.dates]}
          yData={[filesViewedOrEdited.ViewedOrEdited]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={linearChartState.yFormatterFunc}
          tooltipFormatter={lineChartFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      filesViewedOrEdited.ViewedOrEdited,
      filesViewedOrEdited.dates,
      lineChartTickFormat,
      lineChartFormatter,
      dateFilter,
    ],
  );

  const fileSharingLineChart = useMemo(
    () =>
      lineDataHasValues(fileSharing.SharedInternally) || lineDataHasValues(fileSharing.SharedExternally) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[fileSharing.dates, fileSharing.dates]}
          yData={[fileSharing.SharedInternally, fileSharing.SharedExternally]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={linearChartState.yFormatterFunc}
          colorRange={pieColors}
          tooltipFormatter={lineChartFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      fileSharing.SharedInternally,
      fileSharing.SharedExternally,
      fileSharing.dates,
      lineChartTickFormat,
      lineChartFormatter,
      dateFilter,
    ],
  );

  const fileSharingPieChart = useMemo(() => {
    const totalSharedInternally = fileSharing.SharedInternally.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    const totalSharedExternally = fileSharing.SharedExternally.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    const tooltipText = (k: string, v: number) => {
      const sum = totalSharedInternally + totalSharedExternally;
      return `Files Shared ${k}ly | ${v.toLocaleString()} | ${Math.round((v / sum) * 100)}%`;
    };

    return lineDataHasValues(fileSharing.SharedInternally) || lineDataHasValues(fileSharing.SharedExternally) ? (
      <PieChart
        sizePercentage={75}
        innerRadiusPercentage={75}
        data={{ Internal: totalSharedInternally, External: totalSharedExternally }}
        innerText={{
          title: (totalSharedInternally + totalSharedExternally).toLocaleString(),
          value: "Total",
          reverseStyle: true,
        }}
        colorRange={pieColors}
        tooltipFormatter={tooltipText}
      />
    ) : (
      noData(dateFilter)
    );
  }, [fileSharing, dateFilter]);

  const filesSyncedLineChart = useMemo(
    () =>
      validLineData([filesSynced.Synced]) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[filesSynced.dates]}
          yData={[filesSynced.Synced]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={linearChartState.yFormatterFunc}
          tooltipFormatter={lineChartFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [filesSynced.Synced, filesSynced.dates, lineChartTickFormat, lineChartFormatter, dateFilter],
  );

  const [activeUsersChartType, setActiveUsersChartType] = useState<ActiveUsersChartType>(ActiveUsersChartType.Column);

  const handleActiveUsersChange = useCallback((option: string) => {
    option === "Active Users - Column Chart"
      ? setActiveUsersChartType(ActiveUsersChartType.Column)
      : setActiveUsersChartType(ActiveUsersChartType.Line);
  }, []);

  // Chart states
  const activeUsersColumnChart = useMemo(() => {
    return barDataHasValues(activeUsers) ? (
      <ColumnChart
        margins={columnChartMargins}
        data={activeUsers}
        domain={[0, Math.max(...activeUsers.map((d) => d.value))]}
        xAxisTickFormat={(d) => getFormattedTimeStringFromPeriod(d as string, chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
        shouldScroll={false}
      />
    ) : (
      noData(dateFilter)
    );
  }, [activeUsers, chartPeriod, dateFilter, dateRange, groupedBarFormatter]);

  const lineXAxis = useMemo(() => onedriveSummation.map((d) => d.date), [onedriveSummation]);
  const lineYAxis = useMemo(() => onedriveSummation.map((d) => d.totalCount), [onedriveSummation]);

  const activeUsersLineChart = useMemo(
    () =>
      validLineData([lineYAxis]) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[lineXAxis]}
          yData={[lineYAxis]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={linearChartState.yFormatterFunc}
          tooltipFormatter={lineChartFormatter}
        />
      ) : (
        noData(dateFilter)
      ),
    [lineYAxis, lineXAxis, lineChartTickFormat, lineChartFormatter, dateFilter],
  );

  return (
    <>
      <div>
        <h2 className="activity-section">Usage</h2>
      </div>
      <div className="onedrive-chart-container">
        <ChartWrapper
          titles={["Active Users - Column Chart", "Active Users - Line Chart"]}
          onChange={handleActiveUsersChange}
        >
          <RequestStatusRenderer state={onedriveActivityFileCount}>
            {activeUsersChartType === ActiveUsersChartType.Column ? activeUsersColumnChart : activeUsersLineChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <h2 className="activity-section">Activity</h2>
      <div className="onedrive-chart-container">
        <ChartWrapper titles={["Files Viewed Or Edited"]}>
          <RequestStatusRenderer state={onedriveActivityFileCount}>
            {filesViewedOrEditedLineChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="onedrive-chart-container" ref={measureRef}>
        <ChartWrapper
          titles={["File Sharing"]}
          showLegend
          legendLabels={["Shared Internally", "Shared Externally"]}
          colorRange={pieColors}
        >
          <RequestStatusRenderer state={onedriveActivityFileCount}>{fileSharingLineChart}</RequestStatusRenderer>
        </ChartWrapper>
        <div className="pie-container">
          <ChartWrapper
            titles={["File Sharing"]}
            showLegend
            legendLabels={["Internal", "External"]}
            colorRange={pieColors}
          >
            <RequestStatusRenderer state={onedriveActivityFileCount}>{fileSharingPieChart}</RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
      <div className="onedrive-chart-container">
        <ChartWrapper titles={["Files Synced"]}>
          <RequestStatusRenderer state={onedriveActivityFileCount}>{filesSyncedLineChart}</RequestStatusRenderer>
        </ChartWrapper>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  onedriveActivityFileCount: selectOnedriveActivityFileCounts(state),
  onedriveSummation: selectActiveUsersOneDriveSummations(state),
  activeUsers: selectActiveUsersOneDrive(state),
  filesViewedOrEdited: selectFilesViewedOrEdited(state),
  fileSharing: selectFileSharing(state),
  filesSynced: selectFilesSynced(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOneDriveUserActivityFileCounts: bindActionCreators(fetchOnedriveActivityFileCounts, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(Activity);
export default ConnectedComponent;
