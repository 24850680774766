import environmentConfig from "configuration/environmentConfig";
import { IntegrationTypes } from "../types";
import "./tooltipsHelpers.scss";

const helpCenterLink = environmentConfig.helpCenterUrl;

const syncUsersHelpCenterLinks: Partial<{ [key in IntegrationTypes]: string }> = {
  [IntegrationTypes.Google]: `${helpCenterLink}/Enterprise/Google-Integration.htm`,
  [IntegrationTypes.MsGraph]: `${helpCenterLink}/Enterprise/Microsoft-Graph-Integration.htm`,
  [IntegrationTypes.Webex]: `${helpCenterLink}/Enterprise/Integrations.htm`,
};

export const getSyncUsersTooltipMessage = (integrationType: string) => {
  const integration = integrationType as IntegrationTypes;
  const supportLink = syncUsersHelpCenterLinks[integration];
  return (
    <>
      Sync all users{integration === IntegrationTypes.MsGraph && ", or only those with a license in your account,"} from
      this integration. For more details, visit our{" "}
      <a className="tooltip-link" href={supportLink} target="_blank" rel="noreferrer">
        Help Center.
      </a>
    </>
  );
};

const importUsageHelpCenterLinks: Partial<{ [key in IntegrationTypes]: string }> = {
  [IntegrationTypes.Google]: `${helpCenterLink}/Enterprise/Google-Integration.htm`,
  [IntegrationTypes.MsGraph]: `${helpCenterLink}/Enterprise/Microsoft-Graph-Integration.htm#Data_BSI_to_Graph`,
  [IntegrationTypes.Webex]: `${helpCenterLink}/Enterprise/Integrations.htm`,
};

export const getImportUsageDataTooltipMessage = (integrationType: string) => {
  const supportLink = importUsageHelpCenterLinks[integrationType as IntegrationTypes];
  return (
    <>
      Activate this option to include application usage data. For more details, visit our{" "}
      <a className="tooltip-link" href={supportLink} target="_blank" rel="noreferrer">
        Help Center.
      </a>
    </>
  );
};

export const getImportLicensesTooltipMessage = () => {
  const supportLink = `${helpCenterLink}/Enterprise/Microsoft-Graph-Integration.htm#Data`;
  return (
    <>
      Enable this option to include Microsoft licensing data and enable automatic group enrollment based on license
      type. For more details, visit our{" "}
      <a className="tooltip-link" href={supportLink} target="_blank" rel="noreferrer">
        Help Center.
      </a>
    </>
  );
};

export const getImportGroupsTooltipMessage = () => {
  const supportLink = `${helpCenterLink}/Enterprise/Microsoft-Graph-Integration.htm#Data`;
  return (
    <>
      Enable this option to include Microsoft group data and enable automatic group enrollment based on Graph 
      groups. For more details, visit our{" "}
      <a className="tooltip-link" href={supportLink} target="_blank" rel="noreferrer">
        Help Center.
      </a>
    </>
  );
};
