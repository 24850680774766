import React from "react";
import { Grid } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { type FormikProps, withFormik } from "formik";
import { ValidatedForm } from "../../../../../components";
import validationSchemas from "../../../../../utils/validationSchemas";
import { type GlobalRole } from "../../../../../interfaces/role";
import type NewContact from "../../../../../interfaces/NewContact";

interface AddContactPropsOwn {
  onIsValidChange?: (isValid: boolean) => void;
  onContactAdded: (...args: any[]) => any;
  globalRoles: GlobalRole[];
  contacts: Array<NewContact>;
  isLoadingGlobalRoles: boolean;
}

export type AddContactProps = AddContactPropsOwn & FormikProps<NewContact>;

export const AddContact: React.FC<AddContactProps> = (props) => {
  const {
    values,
    errors,
    dirty,
    touched,
    isValid,
    onIsValidChange,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm,
    onContactAdded,
    globalRoles,
    isLoadingGlobalRoles,
  } = props;

  const validatedFieldProps = {
    errors,
    touched,
    dirty,
    isFormValid: isValid,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  const addContact = () => {
    const castedValues = validationSchemas.getContactSchema(props.contacts).cast(values);

    props.handleReset();

    onContactAdded(castedValues);
  };

  return (
    <ValidatedForm formWidthComputer={16} formWidthTablet={16} disablePreventTransitionPrompt {...props}>
      <Grid stackable columns="equal" verticalAlign="bottom">
        <Grid.Column>
          <ValidatedForm.InputField
            markAsRequired
            errorPosition="top"
            value={values.firstName}
            label="First Name"
            propertyName="firstName"
            placeholder="Enter First Name"
            onIsFormValidChange={onIsValidChange}
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column>
          <ValidatedForm.InputField
            markAsRequired
            errorPosition="top"
            value={values.lastName}
            label="Last Name"
            propertyName="lastName"
            placeholder="Enter Last Name"
            onIsFormValidChange={onIsValidChange}
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column>
          <ValidatedForm.InputField
            markAsRequired
            errorPosition="top"
            value={values.email}
            label="Email"
            propertyName="email"
            placeholder="Enter Email"
            onIsFormValidChange={onIsValidChange}
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column>
          <ValidatedForm.InputField
            errorPosition="top"
            value={values.department}
            label="Department"
            propertyName="department"
            placeholder="Enter Department"
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column>
          <ValidatedForm.InputField
            errorPosition="top"
            value={values.title}
            label="Job Title"
            propertyName="title"
            placeholder="Enter Job Title"
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column>
          <ValidatedForm.ChecklistDropdownField
            label="Roles"
            propertyName="roles"
            errorPosition="top"
            value={values.roles}
            items={globalRoles}
            placeholder="Select Roles"
            isLoading={isLoadingGlobalRoles}
            {...validatedFieldProps}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button primary fluid content="Add" disabled={!isValid} onClick={addContact} />
        </Grid.Column>
      </Grid>
    </ValidatedForm>
  );
};

const ConnectedComponent = withFormik<AddContactPropsOwn, NewContact>({
  validationSchema: (props: AddContactPropsOwn) => {
    return validationSchemas.getContactSchema(props.contacts);
  },
  isInitialValid: false,
  mapPropsToValues: () => {
    return {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      department: "",
      title: "",
      isMain: false,
      roles: [],
      autoAssignedRoleIds: [],
    } as NewContact;
  },
  handleSubmit: () => {}, // NOSONAR
  enableReinitialize: true,
})(AddContact);

export default ConnectedComponent;
