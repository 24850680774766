import React, { memo } from "react";

import BaseNode from "../Base/Base";
import VideoCard from "../../../../../../../components/cards/flowDesignerDropCard/VideoCard/VideoCard";
import type AssetTypes from "../../../../../../../enums/assetTypes";
import { type FlowItemVideo } from "../../../types";
import { type FlowItemBase } from "../types";

interface IVideoNodeOwnProps {
  type: AssetTypes.Video;
  data: FlowItemVideo & FlowItemBase;
}

const VideoNode: React.FC<IVideoNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <VideoCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(VideoNode);
