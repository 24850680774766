import {
  FlowDesignerVideoAssetCard,
  FlowDesignerAssessmentAssetCard,
  FlowDesignerSurveyAssetCard,
  FlowDesignerPdfAssetCard,
} from "../../../../../../components/cards";
import AssetTypes from "../../../../../../enums/assetTypes";
import { type AssetToDropType } from "../../../../../../interfaces/assetToDropInfo";

function StaticAssetCard(props: AssetToDropType) {
  if (props.cardType === AssetTypes.Video) {
    return (
      <FlowDesignerVideoAssetCard
        id={props.id}
        title={props.title}
        thumbnailUrl={props.thumbnailUrl}
        durationInSeconds={props.durationInSeconds}
      />
    );
  }

  if (props.cardType === AssetTypes.Assessment) {
    return <FlowDesignerAssessmentAssetCard id={props.id} title={props.title} />;
  }

  if (props.cardType === AssetTypes.Survey) {
    return (
      <FlowDesignerSurveyAssetCard id={props.id} title={props.title} branchingQuestion={props.branchingQuestion} />
    );
  }

  if (props.cardType === AssetTypes.Pdf) {
    return <FlowDesignerPdfAssetCard id={props.id} title={props.title} thumbnailUrl={props.thumbnailUrl} />;
  }

  return null;
}

export default StaticAssetCard;
