import { type Dispatch } from "@reduxjs/toolkit";
import dataService from "../../../../Application/services/dataServices/dataService";
import * as updateVideoActionTypes from "../actionTypes/updateVideoActionTypes";

const uploadAssetVideoBegin = () => ({
  type: updateVideoActionTypes.UPLOAD_VIDEO_BEGIN,
});

const uploadAssetVideoSuccess = (videoUrl: string) => ({
  payload: { videoUrl },
  type: updateVideoActionTypes.UPLOAD_VIDEO_SUCCESS,
});

const uploadAssetVideoFail = (error: any) => ({
  type: updateVideoActionTypes.UPLOAD_VIDEO_FAIL,
  payload: { error },
});

export const uploadVideoToAws = (
  file: File,
  presignedUrl: string,
  onUploadProgressHandler: (progressEvent: any) => void,
  acceptAborterHandler: (aborter: AbortController) => void,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadAssetVideoBegin());
    try {
      const abortController: AbortController = new AbortController();
      acceptAborterHandler(abortController);
      await dataService.uploadFileByPresignedUrl(presignedUrl, file, onUploadProgressHandler, abortController.signal);
      dispatch(uploadAssetVideoSuccess(presignedUrl));
    } catch (error) {
      dispatch(uploadAssetVideoFail(error));
    }
  };
};
