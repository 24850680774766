import * as events from "../events/library/libraryEvents";
import {
  updateAssessmentCommandSuccess,
  assessmentUpdated,
  publishDraftAssessmentSuccess,
  fetchDiscardAssessmentSuccess,
  fetchLockedAssessmentSuccess,
} from "../../../../Library/Assessments/state/actions/assessmentEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces";
import { createDispatcher } from "../dispatcher";
import {
  updateFailedTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

export const assessmentsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchMap,
    dispatchPublishSuccess,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
  } = createDispatcher(dispatcher);

  mapping[events.AssessmentInfoEditSuccess] = dispatch(assessmentUpdated);
  mapping[events.AssessmentQuestionsEditSuccess] = dispatch(assessmentUpdated);
  mapping[events.AssessmentUpdateCommandCompleted] = dispatch(updateAssessmentCommandSuccess);
  mapping[events.AssessmentTagsEditSuccess] = dispatch(updateAssessmentCommandSuccess);

  mapping[events.AssessmentPublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftAssessmentSuccess,
    (data) => `${data.name} was published.`,
  );

  mapping[events.AssessmentDiscardSuccess] = dispatch(fetchDiscardAssessmentSuccess);

  mapping[events.AssessmentDuplicateSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AssessmentDuplicateAllSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentDuplicateFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentDuplicateAllFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.AssessmentDeleteSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AssessmentDeleteAllSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentDeleteFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentDeleteAllFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.AssessmentLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedAssessmentSuccess,
    (message) => ({ entityId: message.id }),
  );

  mapping[events.AssessmentTagsAddSuccess] = dispatchBatchOperationSuccess;

  mapping[events.AssessmentTagsAddFailure] = dispatchBatchOperationFailure;

  mapping[events.AssessmentEditVisibilitySuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AssessmentEditVisibilityAllSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentEditVisibilityFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AssessmentEditVisibilityAllFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
};
