import { type IObservable } from "../../../../../interfaces";

export interface ClosedCaptionsBase {
  entityId?: number;
  isReadOnly?: boolean;
}

export interface ClosedCaptionsListProps extends ClosedCaptionsBase {
  onDiscardedObserver?: IObservable<() => void>;
  onTriggerClosedCaptionsRemovalObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;
}

export interface UploadClosedCaptionsProps extends ClosedCaptionsBase {}

export enum UploadTypes {
  Manual = "Manual",
  Automatic = "Automatic",
}
