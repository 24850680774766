import React, { useCallback, useEffect, useState } from "react";
import { useReactFlow, useStore } from "reactflow";
import { useObserver } from "../../../../../hooks/useObserver";
import { type IObservable } from "../../../../../interfaces";
import FlowComponentsPanel from "../FlowComponentsPanel/FlowComponentsPanel";
import { type FlowItemBase, type IFlowEdge, type IFlowNode } from "../ReactFlowCanvas/nodes/types";
import {
  type EntityTriggerTypes,
  type Trigger,
  type TriggersChanges,
  type TriggersData,
  type TriggerTimeUnit,
} from "../types";
import { getSelectedNode } from "../ReactFlowCanvas/hooks/useReactFlowCanvas";
import "./assetInspector.scss";
import { setActionNode } from "../utils/nodesHelpers";
import { isEmpty } from "lodash";
import { prepareTriggers } from "./utils/prepareTriggers";
import { useReactFlowCanvasState } from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowCanvasActions } from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";
import { useReactFlowState } from "../ReactFlowCanvas/Providers/ReactFlowStateProvider/hooks/useReactFlowState";
import { useReactFlowActions } from "../ReactFlowCanvas/Providers/ReactFlowStateProvider/hooks/useReactFlowActions";

interface IAssetInspectorOwnProps {
  isReadOnly: boolean;
  triggerTypes: EntityTriggerTypes[];
  triggerTimeUnits: TriggerTimeUnit[];
  onResetSelectedElementsObserver?: IObservable<() => void>;
}

interface ITriggersToUpdate {
  [key: string]: TriggersData;
}

export interface IItemsToUpdate {
  [key: string]: FlowItemBase;
}

const initialTriggers: Trigger[] = [];
export const AssetInspector: React.FC<IAssetInspectorOwnProps> = ({
  isReadOnly,
  triggerTypes,
  triggerTimeUnits,
  onResetSelectedElementsObserver,
}): React.ReactElement => {
  const [triggers, setTriggers] = useState<Trigger[]>(initialTriggers);
  const { save, setShowTriggers, setFlowEndView, setSectionHeaderView } = useReactFlowCanvasActions();
  const { nodes, edges } = useReactFlowState();
  const { setNodes, setEdges } = useReactFlowActions();
  const { getNode } = useReactFlow();
  const { showTriggers, sectionHeaderView, flowEndView } = useReactFlowCanvasState();
  const resetSelectedElements = useStore((store) => store.resetSelectedElements);
  const [subscribeOnResetSelectedElements] = useObserver(onResetSelectedElementsObserver);

  const getSourceNodeByEdge = (edges?: IFlowEdge[]): IFlowNode | undefined => {
    const selectedEdge = edges?.find((edge) => edge.selected);
    return selectedEdge?.source ? getNode(selectedEdge?.source) : undefined;
  };

  const updateEdges = useCallback(
    (triggersData: TriggersChanges[]): void => {
      const triggersToUpdate: ITriggersToUpdate = {};
      const itemsToUpdate: IItemsToUpdate = {};

      triggersData.forEach(({ inId, outId, bulletId, ...rest }) => {
        const triggersToUpdateId = bulletId ? `el-${outId}-${inId}-${bulletId}` : `el-${outId}-${inId}`;
        triggersToUpdate[triggersToUpdateId] = rest as TriggersData;

        if (rest.isAction !== undefined) {
          itemsToUpdate[outId] = { canConnect: !rest.isAction } as FlowItemBase;
        }
      });
      setEdges((edges) => {
        const newEdges = edges
          .filter(({ data }) => data?.inId && (!itemsToUpdate[data.inId] || itemsToUpdate[data.inId]?.canConnect))
          .map((edge) =>
            triggersToUpdate[edge.id] ? { ...edge, data: { ...edge.data, ...triggersToUpdate[edge.id] } } : edge,
          );
        !isEmpty(itemsToUpdate) && setNodes((nodes) => setActionNode(newEdges, nodes, itemsToUpdate));
        return newEdges;
      });

      save();
    },
    [setEdges, setNodes, save],
  );

  const deselectFlowItems = useCallback(() => {
    resetSelectedElements();
    setShowTriggers(false);
    setSectionHeaderView(undefined);
    setFlowEndView(undefined);
  }, [resetSelectedElements, setShowTriggers, setSectionHeaderView, setFlowEndView]);

  let selectedNode = getSelectedNode(nodes);

  if (!selectedNode) {
    selectedNode = getSourceNodeByEdge(edges);
  }

  useEffect(() => {
    if (showTriggers) {
      const selectedEdges = edges.filter((edge) => edge.selected);

      const triggersToSet = selectedEdges.length
        ? prepareTriggers(nodes, edges, selectedEdges, triggerTypes, triggerTimeUnits)
        : initialTriggers;

      setTriggers(triggersToSet);
    }
  }, [
    // we need elements to get actual data for edges
    // it's not affect rendering
    // because rendering here depends on triggers and they don't change
    edges,
    nodes,
    triggerTypes,
    triggerTimeUnits,
    showTriggers,
  ]);

  useEffect(() => {
    return subscribeOnResetSelectedElements(deselectFlowItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnResetSelectedElements]);

  return (
    <div className="flow-components-panel-container">
      <FlowComponentsPanel
        sourceTitle={selectedNode?.data?.title || ""}
        triggers={triggers}
        updateTriggerDataChanges={updateEdges}
        triggerView={showTriggers}
        flowEndView={flowEndView}
        sectionHeaderView={sectionHeaderView}
        deselectFlowItems={deselectFlowItems}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default AssetInspector;
