import { bindActionCreators } from "redux";
import { useCallback } from "react";

import { type NotifyStepState, updateForm } from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { type FormChangeEvent, type EmailNotificationFormProps } from "../NotifySettingsForm/EmailNotificationForm";
import { type AppDispatch } from "features/Application/globaltypes/redux";

export interface Props {
  state: NotifyStepState;
  dispatch: AppDispatch;
  onValidChange(isValid: boolean): void;
  shouldNotify: boolean;
}

export type HookResult = EmailNotificationFormProps;

export function useForm({ state, dispatch, onValidChange, shouldNotify }: Props): HookResult {
  const handleFormChange = useCallback(
    (event: FormChangeEvent) => {
      bindActionCreators(updateForm, dispatch)(event);
    },
    [dispatch],
  );

  return {
    subject: state.form.subject,
    previewText: state.form.previewText,
    customizeSender: state.form.customizeSender,
    senderName: state.form.senderName,
    senderEmail: state.form.senderEmail,
    defaultSenderName: state.form.defaultSenderName,
    defaultSenderEmail: state.form.defaultSenderEmail,
    smtpSettingsExist: state.form.smtpSettingsExist,
    disabled: !shouldNotify,
    onChange: handleFormChange,
    onValidChange: onValidChange,
  };
}
