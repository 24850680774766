import { type AppDispatch } from "../../../Application/globaltypes/redux";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import { req, err, got } from "../slices/signingCertificatesSlice";

export const fetchSigningCertificates = () => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const data = await identityProvidersDataService.getSigningCertificates();
    dispatch(got(data));
  } catch (e) {
    let errorMessage = (e as Error).message;
    dispatch(err(errorMessage));
  }
};
