import type ColumnOption from "../../../../../../interfaces/columnOptions";

export const columnsMap: { [key: string]: string } = {
  name: "firstName",
  department: "department",
  "job title": "jobTitle",
  added: "dateAdded",
  expiration: "licenseExpirationDate",
  access: "isFullAccess",
};

export const getColumnOptions = (licenseRemovalEnabled: boolean): Array<ColumnOption> => {
  const options: ColumnOption[] = [
    {
      name: "Name",
      width: 4,
      isSortable: true,
      className: "user-name-header",
    },
    {
      name: "Department",
      width: 3,
      isSortable: true,
    },
    {
      name: "Job Title",
      width: 3,
      isSortable: true,
    },
    {
      name: "Added",
      width: 2,
      isSortable: false,
    },
    {
      name: "Expiration",
      width: 3,
      isSortable: false,
    },
  ];

  licenseRemovalEnabled &&
    options.push({
      name: "",
      width: 2,
      isSortable: false,
    });

  return options;
};
