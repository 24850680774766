import React, { memo, useRef } from "react";
import { useDragLayer } from "react-dnd";
import AssetDragLayerView from "./AssetDragLayerView/AssetDragLayerView";

import "./assetDragLayer.scss";

const AssetDragLayer: React.FC = (): React.ReactElement | null => {
  const container = useRef<HTMLDivElement>(null);
  // extracted this logic from AssetDragLayerView because of its re-renders
  const { isDragging, item, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  return isDragging && item?.id ? (
    <div ref={container} className="asset-dragging-layer">
      <AssetDragLayerView container={container} item={item} clientOffset={clientOffset} />
    </div>
  ) : null;
};

export default memo(AssetDragLayer);
