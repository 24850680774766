import axios from "axios";

import { type CommunicationChannels, type TemplateTypes } from "../../../enums";
import azureStorageUtils from "../../../utils/azureStorageUtils";
import { getEmailTemplateMetadata } from "./templateMetadata";
import { type GetNotificationOptionsPayload } from "../types";

export interface ITemplateService {
  getLayout(): Promise<string>;
  getTemplate(type: TemplateTypes): Promise<GetTemplateResponse>;
  getUrlWithSasTokenV2(): Promise<{ templateUri: string; rawTemplateUri: string }>;
  saveTemplate(urlWithSas: string, template: string): Promise<void>;
  getEmailPreview(notificationType: TemplateTypes, contentUrl: string): Promise<string>;
  getTemplateById(notificationId: string): Promise<GetTemplateResponse>;
  isNotifyOptionsSaved(notificationId: string): Promise<boolean>;
}

export interface GetTemplateResponse {
  layout: string;
  template: string;
  subject: string;
  previewText: string;
  shouldNotifyUsers?: boolean;
  communicationChannel?: CommunicationChannels;
  templateSasUrl?: string;
  headline?: string;
  imageUrl?: string;
  body?: string;
  callToAction?: string;
  destinationUrl?: string;
}

// We will receive an auth error when trying to send а request
// with Authorization header to azure blob storage.
// So axios without auth interceptor is required.
const axiosWithoutAuthInterceptor = axios.create();

export const templateService: ITemplateService = {
  async getLayout(): Promise<string> {
    const { data: url } = await axios.get<string>("/api/system-notifications/layout");
    const { data: layout } = await axiosWithoutAuthInterceptor.get<string>(url);
    return layout;
  },

  async getTemplate(type): Promise<GetTemplateResponse> {
    const layout = await this.getLayout();
    const { data: url } = await axios.get<string>(`/api/system-notifications/${type}/template`);
    const { data: template } = await axiosWithoutAuthInterceptor.get<string>(url);
    const { subject, previewText } = getEmailTemplateMetadata(type);
    return { layout, template, subject, previewText };
  },

  async getUrlWithSasTokenV2(): Promise<{ templateUri: string; rawTemplateUri: string }> {
    const { data: urls } = await axios.get("/api/v2/system-notifications/notification-options/template/sas");
    return urls;
  },

  async saveTemplate(urlWithSas, template): Promise<void> {
    await azureStorageUtils.uploadString(urlWithSas, template);
  },

  async getEmailPreview(notificationType, contentUrl): Promise<string> {
    const { data: emailPreview } = await axios.get<string>(
      `/api/system-notifications/${notificationType}/template/preview?contentUrl=${contentUrl}?`,
    );
    return emailPreview;
  },

  async getTemplateById(notificationId): Promise<GetTemplateResponse> {
    const layout = await this.getLayout();
    const { data: settings } = await axios.get<GetNotificationOptionsPayload>(
      `/api/system-notifications/notification-options/${notificationId}`,
    );
    const template = await azureStorageUtils.downloadAsString(settings.rawTemplateUri);
    return {
      layout,
      template,
      subject: settings.subject,
      previewText: settings.previewText,
      shouldNotifyUsers: !settings.suppressNotification,
      templateSasUrl: settings.rawTemplateUri,
      communicationChannel: settings.communicationChannel,
      headline: settings.headLine,
      imageUrl: settings.imageUrl,
      body: settings.body,
      callToAction: settings.callToAction,
      destinationUrl: settings.destinationUrl,
    };
  },

  async isNotifyOptionsSaved(notificationId): Promise<boolean> {
    try {
      await axios.head(`/api/system-notifications/notification-options/${notificationId}`);
      return true;
    } catch (error: any) {
      return false;
    }
  },
};
