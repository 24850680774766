import { type ConnectDragSource, useDrag } from "react-dnd";
import { useSelector, useStore } from "react-redux";
import { type RootState } from "../features/Application/globaltypes/redux";
import { addSelectedCards, removeSelectedCard } from "../features/Licensing/Packs/state/actions/packDetailsActions";
import { bindActionCreators } from "redux";
import { type DraggableAsset } from "../interfaces/assetToDropInfo";
import { useCallback, useEffect } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import DragNDropItemTypes from "../enums/dragNDropItemTypes";

export interface Props {
  type: DragNDropItemTypes;
  item: DraggableAsset | { [key: string]: unknown };
}

interface Handlers {
  handleClick(): void;
}

export type HookResult = [ConnectDragSource, boolean, Handlers];

export function useDraggableAssetCard({ type: dragNDropItemType, item }: Props): HookResult {
  const store = useStore<RootState>();

  const isSelected = useSelector<RootState, boolean>(
    (state) => state.packs.packDetailsReducer.selectedCards.findIndex((card) => card.id === item.id) >= 0,
  );

  const [, dragRef, preview] = useDrag(() => {
    return {
      type: dragNDropItemType,
      item: item,
    };
  }, [item]);

  const handleClick = useCallback(() => {
    if (dragNDropItemType !== DragNDropItemTypes.PACK) {
      return;
    }

    const { id, type, title, thumbnailUrl, elementType } = item as DraggableAsset;

    if (isSelected) {
      bindActionCreators(removeSelectedCard, store.dispatch)(id);
    } else {
      bindActionCreators(
        addSelectedCards,
        store.dispatch,
      )([
        {
          id: id,
          type: type,
          title: title,
          thumbnailUrl: thumbnailUrl,
          elementType: elementType,
          isReadOnly: true,
        },
      ]);
    }
  }, [isSelected, store.dispatch, item, dragNDropItemType]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return [
    dragRef,
    isSelected,
    {
      handleClick,
    },
  ];
}
