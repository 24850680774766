import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../../Application/globaltypes/redux";
import { WizardWrapper as Wizard } from "../../../../WizardWrapper";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import * as feedbackPageEntityStateActions from "../state/actions/FeedbackPageEntityStateActions";
import * as feedbackPageDetailsActions from "../state/actions/FeedbackPageDetailsActions";
import Configure from "../Details/Configure/Configure";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { RouteNames } from "../../../../../enums";
import Content from "../Details/Content/Content";
import navigationUtils from "../../../../../utils/navigationUtils";
import { useLocation, useNavigate } from "react-router-dom";

export type CreateFeedbackPagePropsAll = PropsFromRedux;

const feedbackPagesOverviewUrl = `/${RouteNames.feedbackPagesSimulatedPhishing}`;

export const CreateFeedbackPage = (props: CreateFeedbackPagePropsAll) => {
  const [isValid, setIsValid] = useState(false);
  const { isSaving, dateModified } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const stepsManager = useMemo(() => new WizardStepsManager(), []);

  const onCancel = useCallback(() => {
    navigationUtils.goBackOrDefault(location, navigate, "/content/simulated-phishing/feedback-pages");
  }, [location, navigate]);

  const onPublishedEvent = useCallback(() => {
    navigate(feedbackPagesOverviewUrl);
  }, [navigate]);

  const onIsValidChange = (isValidChanged: boolean) => {
    if (isValid !== isValidChanged) {
      setIsValid(isValidChanged);
    }
  };

  useEffect(() => {
    RtnEventsEmitter.subscribe(rtnEvents.FeedbackPagePublishSuccess, onPublishedEvent);
  }, [onPublishedEvent]);

  useEffect(() => {
    return () => {
      RtnEventsEmitter.unsubscribe(rtnEvents.FeedbackPagePublishSuccess, onPublishedEvent);
    };
  }, [onPublishedEvent]);

  useEffect(() => {
    return () => {
      stepsManager.dispose();
    };
  }, [stepsManager]);

  return (
    <Wizard
      title="Create Feedback Page"
      finishButtonLabel="Publish"
      onCancel={onCancel}
      onProgressAsync={(_: any, nextIndex: number) => stepsManager.onNext(nextIndex)}
      onRegressAsync={(_: any, nextIndex: number) => stepsManager.onPrevious(nextIndex)}
      onFinishAsync={stepsManager.onFinish}
      isSaveInProgress={isSaving}
      progressSavedDate={dateModified}
      isFinishButtonDisabled={!isValid}
    >
      <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
        <Configure
          acceptHandlers={(handlers) => stepsManager.acceptHandlers(handlers, 0)}
          onIsValidChange={onIsValidChange}
          disabled={false}
        />
      </Wizard.Step>
      <Wizard.Step label="Content" className="scrollable-content" isLocked={!isValid} required>
        <Content
          acceptHandlers={(handlers) => stepsManager.acceptHandlers(handlers, 1)}
          onIsValidChange={onIsValidChange}
          disabled={false}
        />
      </Wizard.Step>
    </Wizard>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isSaving: state.library.threatDefenceFeedbackPages.feedbackPageEntityState.isEntityCommandInProgress,
  dateModified: state.library.threatDefenceFeedbackPages.feedbackPageEntityState.lastModifiedDateTime,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(feedbackPageEntityStateActions, dispatch),
  detailsActions: bindActionCreators(feedbackPageDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(CreateFeedbackPage);
export default ConnectedComponent;
