import { type PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FetchingItemsState, createFetchingItemsSlice } from "features/Application/slices/createFetchingItemsSlice";
import { type TemplateOverview } from "../types/models";

export interface TemplateCustomersState extends FetchingItemsState<TemplateOverview> {
  selected: number[];
}

export const initialState: TemplateCustomersState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  selected: [],
};

const itemsSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "Overview",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, setSelected } = itemsSlice.actions;

export default itemsSlice.reducer;
