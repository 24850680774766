import { type FC, memo, useEffect, useMemo, useState } from "react";
import { type NotifyStepSettings, type PacksContextItem, type PriorityLevel } from "../../../../interfaces";
import { type EditPeopleAssignmentsProps, type EntityItemType } from "../types";
import PeopleAssignmentModal from "../../../../components/assignmentModals/peopleAssignmentModal/PeopleAssignmentModal";
import { getPriorityItem } from "../utils/peopleAssignmentUtils";

const ISSUING_LICENSE_COUNT = 5;

const EditPeopleAssignments: FC<EditPeopleAssignmentsProps> = ({
  entityItems,
  showModal,
  onCloseModal,
  onAddPeopleConfirm,
  renderFirstCustomStep,
  usersGroupsContext,
  selectedIds,
  contentType,
  skipNotifyStep,
  notifyStepDisabled,
  notifyTemplateType,
}) => {
  const initialPriorityLevel = useMemo((): PriorityLevel[] => {
    return entityItems.map((item: EntityItemType) => getPriorityItem(item));
  }, [entityItems]);

  const itemsPacks = useMemo((): PacksContextItem[] => {
    return entityItems.map((i) => {
      return { ...i, id: i.id.toString(), type: contentType };
    });
  }, [entityItems, contentType]);

  const [selectedPeopleToAddIds, setSelectedPeopleToAddIds] = useState<number[]>([]);
  const [priorityLevels, setPriorityLevels] = useState<PriorityLevel[]>(initialPriorityLevel);

  useEffect(() => {
    setPriorityLevels(initialPriorityLevel);
  }, [initialPriorityLevel]);

  const onPriorityItemsUpdate = (priorityLevelsToUpdate: PriorityLevel[]) => {
    setPriorityLevels(priorityLevelsToUpdate);
  };

  const onCancel = () => {
    setPriorityLevels(initialPriorityLevel);
    onCloseModal();
  };

  const onConfirm = (notificationSettings?: NotifyStepSettings) => {
    setPriorityLevels(initialPriorityLevel);
    onAddPeopleConfirm(selectedPeopleToAddIds, priorityLevels, notificationSettings);
  };

  return (
    <PeopleAssignmentModal
      showModal={showModal}
      isStepLoading={false}
      onPeopleSelected={setSelectedPeopleToAddIds}
      issuingLicensesCount={ISSUING_LICENSE_COUNT}
      onConfirm={onConfirm}
      onCancel={onCancel}
      priorityItems={priorityLevels}
      onItemsUpdate={onPriorityItemsUpdate}
      renderFirstCustomStep={renderFirstCustomStep}
      usersGroupsContext={usersGroupsContext}
      selectedIds={selectedIds}
      itemsPacks={itemsPacks}
      skipNotifyStep={skipNotifyStep}
      notifyStepDisabled={notifyStepDisabled}
      notifyTemplateType={notifyTemplateType}
    />
  );
};

export default memo(EditPeopleAssignments);
