import React, { memo } from "react";
import BaseNode from "../Base/Base";
import { type FlowItemBase } from "../types";
import type CommunicationTypes from "../../../../../../../enums/communicationTypes";
import { type FlowItemMessage } from "../../../types";
import MessageCard from "../../../../../../../components/cards/flowDesignerDropCard/MessageCard/MessageCard";

interface IEmailNodeOwnProps {
  type: CommunicationTypes.Message;
  data: FlowItemMessage & FlowItemBase;
}

const MessageNode: React.FC<IEmailNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <MessageCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(MessageNode);
