import React from "react";

import { type FlowItemVideo } from "../../../../features/Library/Flows/Designer/types";
import dateTimeUtils from "../../../../utils/dateTimeUtils";

import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface IVideoCardOwnProps {
  item: FlowItemVideo;
}

const VideoCard: React.FC<IVideoCardOwnProps> = ({ item }) => (
  <>
    <TopContent {...item} />
    <BottomContent
      cardLabel={item.elementType}
      metaLabel={item.durationInSeconds ? dateTimeUtils.durationSecondsToTime(item.durationInSeconds) : ""}
    />
  </>
);

export default VideoCard;
