import { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import UploadImage from "../upload/UploadImage";
import { Modal } from "../../modal";
import VideoThumbnailGenerator from "./videoThumbnailGenerator/videoThumbnailGenerator";
import fileUtils from "../../../utils/fileUtils";
import imageHelper from "../helper/imageHelper";
import ImageUploaderModalButtons from "../ImageUploaderModalButtons";
import ModalSizes from "../../../enums/modalSizes";
import Tabs from "../../tabs/Tabs";

import "./thumbnailUploaderModal.scss";

const defaultActiveIndex = 0;

export class ThumbnailUploaderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      croppedImage: null,
      isUploading: false,
      isLooksGoodButtonVisible: false,
      thumbnails: [],
      position: null,
      handleLooksGood: this.generateThumbnailFromPosition,
      selectedIndex: 0,
      selectedFile: null,
      fileName: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.assetManifests, prevProps.assetManifests)) {
      this.setState({ thumbnails: [] });
    }
  }

  setSelectedFile = (file) => {
    this.setState({ selectedFile: file });
  };

  setFileName = (fileName) => {
    this.setState({ fileName });
  };

  generateThumbnail = async () => {
    this.setState({ isUploading: true });
    const { videosActions } = this.props;
    videosActions.changeImageProcessingState(true);
    const uploadOptions = { contentType: this.state.croppedImage.type };
    const sas = await this.props.sasLinkProviderAsync();

    await fileUtils
      .uploadFileToBlob(sas.uri, this.state.croppedImage, null, null, uploadOptions)
      .then(() => {
        this.setState({
          isUploading: false,
          handleLooksGood: this.generateThumbnailFromPosition,
          thumbnails: [],
          selectedIndex: 0,
        });
        this.props.generateThumbnail(sas);
        this.closeModal();
      })
      .catch(() => {
        this.setState({ isUploading: false });
      });
  };

  generateThumbnailFromPosition = () => {
    this.setState({ isUploading: true });
    const position = this.state.position;
    const thumbnailUrl = this.state.thumbnails[this.state.selectedIndex].src;
    this.props.generateThumbnailFromPosition(position, thumbnailUrl);
    this.setState({
      isUploading: false,
      handleLooksGood: this.generateThumbnailFromPosition,
      thumbnails: [],
      selectedIndex: 0,
      isLooksGoodButtonVisible: false,
    });
    this.closeModal();
  };

  handleCroppedImageChange = (image) => {
    this.setState({
      croppedImage: image,
      isLooksGoodButtonVisible: image !== null,
    });
  };

  closeModalWindow(closeModal) {
    this.setState({
      handleLooksGood: this.generateThumbnailFromPosition,
      thumbnails: [],
      isLooksGoodButtonVisible: false,
      selectedIndex: 0,
      croppedImage: null,
      selectedFile: null,
      fileName: {},
    });
    closeModal();
  }

  renderModalActions = (closeModal) => {
    this.closeModal = closeModal;
    return (
      <ImageUploaderModalButtons
        closeModal={() => this.closeModalWindow(closeModal)}
        handleLooksGood={this.state.handleLooksGood}
        isUploading={this.state.isUploading}
        canSubmit={this.state.isLooksGoodButtonVisible}
      />
    );
  };

  handleGenerateThumbnail = (video, currentTime) => {
    const image = imageHelper.createImageFromCanvas(video, currentTime);
    const defaultImageCount = 30;
    if (defaultImageCount > this.state.thumbnails.length) {
      this.setState((state) => {
        const list = state.thumbnails.unshift(image);
        return {
          ...state,
          list,
          isLooksGoodButtonVisible: true,
          position: image.position,
        };
      });
    }
  };

  handleSelectThumbnail = (index) => {
    this.setState({
      position: this.state.thumbnails[index].position,
      selectedIndex: index,
      isLooksGoodButtonVisible: true,
    });
  };

  handleTabChanged = (_, data) => {
    const { activeIndex } = data;
    if (activeIndex === 0) {
      this.setState({
        handleLooksGood: this.generateThumbnailFromPosition,
        isLooksGoodButtonVisible: this.state.thumbnails.length > 0,
      });
    }
    if (activeIndex === 1) {
      this.setState({
        handleLooksGood: this.generateThumbnail,
        isLooksGoodButtonVisible: this.state.croppedImage !== null,
      });
    }
  };

  renderTabs = () => {
    const { assetManifests, textTracks, areTextTracksLoading } = this.props;
    const { selectedFile, fileName, thumbnails } = this.state;
    const isProcessing = !assetManifests || assetManifests.length === 0 || areTextTracksLoading;
    const selectedIndex = this.state.selectedIndex;

    return (
      <Tabs defaultActiveIndex={defaultActiveIndex} onTabChange={this.handleTabChanged}>
        <Tabs.Pane label="Video Frame">
          <VideoThumbnailGenerator
            isProcessing={isProcessing}
            videoTracks={assetManifests}
            selectedIndex={selectedIndex}
            addGenerateThumbnailButton
            thumbnails={thumbnails}
            handleGenerateThumbnail={this.handleGenerateThumbnail}
            handleSelectThumbnail={this.handleSelectThumbnail}
            textTracks={textTracks}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Upload" defaultActiveIndex>
          <div className="image-upload-section upload-image">
            <UploadImage
              onCroppedImageChange={this.handleCroppedImageChange}
              file={selectedFile}
              onFileChanged={this.setSelectedFile}
              fileName={fileName}
              onFileNameChanged={this.setFileName}
            />
          </div>
        </Tabs.Pane>
      </Tabs>
    );
  };

  render() {
    const { trigger } = this.props;
    return (
      <Modal
        title="Select Thumbnail"
        renderTrigger={trigger}
        className="upload-image-modal"
        renderModalActions={this.renderModalActions}
        onCancel={() => this.closeModalWindow(this.closeModal)}
        size={ModalSizes.large}
      >
        {this.renderTabs()}
      </Modal>
    );
  }
}

ThumbnailUploaderModal.propTypes = {
  trigger: PropTypes.func,
  onImageChange: PropTypes.func,
  entityId: PropTypes.number,
  sasLinkProviderAsync: PropTypes.func,
  videosActions: PropTypes.object,
  generateThumbnailFromPosition: PropTypes.func,
  generateThumbnail: PropTypes.func,
  textTracks: PropTypes.array,
  areTextTracksLoading: PropTypes.bool,
};

export default ThumbnailUploaderModal;
