import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import { pluralize } from "../../../utils/stringUtils";
import { type AssignedUserModal } from "../../assignmentModals/usersAssignmentModal/types";

export interface AssignedUserTooltipProps {
  content?: string;
  alreadyAssignedUser?: boolean;
  multipleAssignment?: boolean;
  assignedToNumberOfEntities?: number;
  assignmentEntityType?: string;
  selectedParentEntitiesLength?: number;
  user: AssignedUserModal;
  getCustomTooltipMessage?: (user: AssignedUserModal) => string;
}

const getTooltipMessage = (props: AssignedUserTooltipProps) => {
  const {
    content,
    alreadyAssignedUser,
    multipleAssignment,
    assignedToNumberOfEntities,
    assignmentEntityType,
    selectedParentEntitiesLength,
  } = props;

  let tooltipMessage;
  if (!multipleAssignment && alreadyAssignedUser && !!content) {
    tooltipMessage = content;
  } else if (assignedToNumberOfEntities) {
    if (content) {
      tooltipMessage = content;
    } else {
      const normalizedAssignmentEntityType = assignmentEntityType
        ? pluralize(assignmentEntityType, assignedToNumberOfEntities)
        : "";

      tooltipMessage =
        alreadyAssignedUser || selectedParentEntitiesLength === 1
          ? `This user already belongs to the selected ${normalizedAssignmentEntityType}`
          : `This user already belongs to (${assignedToNumberOfEntities}) of the ${assignmentEntityType}s selected and will be skipped`;
    }
  }

  return tooltipMessage;
};

export const AssignedUserTooltip = (props: AssignedUserTooltipProps) => {
  const message = props.getCustomTooltipMessage?.(props.user) ?? getTooltipMessage(props);

  return message ? (
    <Popup
      hideOnScroll
      position="top center"
      size="small"
      className="popup-warning warning"
      content={message}
      trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
    />
  ) : null;
};

export default AssignedUserTooltip;
