import { RolePermissions } from "../../../../enums";
import Rights, { hasContentRight, hasGroupRight } from "../../../../enums/rights";

export const issueLicensePermissionPredicate = (userPermissions: RolePermissions[]): boolean => {
  return (
    userPermissions.includes(RolePermissions.AccountsSettingsView) &&
    hasContentRight(userPermissions, Rights.View) &&
    hasGroupRight(userPermissions, Rights.Manage)
  );
};
