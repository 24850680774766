import { ticks, tickIncrement } from "d3-array";
import continuous, { copy } from "./linear-continious.js";
import { initRange } from "./init.js";
import tickFormat from "./tickFormat.js";

export function linearish(scale) {
  var domain = scale.domain;

  /**
   *
   * @param {number} count
   * @returns
   */
  scale.ticks = function (count) {
    var d = domain();
    // For case where bar or line chart is empty
    if (d.length === 2) {
      return ticks(d[0], d[1], count == null ? 5 : count);
    }

    var lineDomain = ticks(d[0], d[1], count == null ? 5 : count);
    var barDomain = ticks(d[1], d[2], count == null ? 5 : count);

    return [...new Set([...lineDomain, ...barDomain])].sort((a, b) => a - b);
  };

  scale.tickFormat = function (count, specifier) {
    var d = domain();
    return tickFormat(d[0], d[d.length - 1], count == null ? 10 : count, specifier);
  };

  /**
   *
   * @param {number} count
   * @returns
   */
  scale.nice = function (count) {
    if (count == null) count = 5;

    function getNice(index1, index2) {
      var d = domain();
      var i0 = index1;
      var i1 = index2;
      var start = d[i0];
      var stop = d[i1];
      var prestep;
      var step;
      var maxIter = 10;

      if (stop < start) {
        (step = start), (start = stop), (stop = step);
        (step = i0), (i0 = i1), (i1 = step);
      }

      while (maxIter-- > 0) {
        step = tickIncrement(start, stop, count);
        if (step === prestep) {
          d[i0] = start;
          d[i1] = stop;
          return domain(d);
        } else if (step > 0) {
          start = Math.floor(start / step) * step;
          stop = Math.ceil(stop / step) * step;
        } else if (step < 0) {
          start = Math.ceil(start * step) / step;
          stop = Math.floor(stop * step) / step;
        } else {
          break;
        }
        prestep = step;
      }
    }

    if (domain().length === 2) {
      getNice(0, 1);
      return scale;
    }

    getNice(0, 1);
    let d1 = domain()[1];
    getNice(1, 2);
    // Ensure we don't have any overlap on the "0" for line/bar charts
    const bestd1 = Math.max(d1, 1);
    domain([domain()[0], bestd1, Math.max(domain()[2], bestd1)]);
    return scale;
  };

  return scale;
}

export default function linear() {
  var scale = continuous();

  scale.copy = function () {
    return copy(scale, linear());
  };

  initRange.apply(scale, arguments);

  return linearish(scale);
}
