import React from "react";

import { ReactComponent as DropAreaPlaceholder } from "../../images/drop-area-placeholder.svg";
import { ReactComponent as DropAreaPlaceholderActive } from "../../images/drop-area-placeholder-active.svg";
import "./imageDropAreaPlaceholder.scss";

const ImageDropAreaPlaceholder = ({ isActive }) => {
  return (
    <div className="droparea-placeholder">
      {isActive ? <DropAreaPlaceholderActive /> : <DropAreaPlaceholder />}
      <div className="droparea-placeholder-title">Drag &amp; Drop Items</div>
    </div>
  );
};

export default ImageDropAreaPlaceholder;
