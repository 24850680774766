import { Component } from "react";
import { Table, Dimmer, Loader, Segment, Checkbox } from "semantic-ui-react";
import { isEmpty, keyBy, isEqual, isFunction, debounce } from "lodash";
import PropTypes from "prop-types";
import cn from "classnames";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import miscUtils from "../../../utils/miscellaneousUtils";
import SortDirections from "../../../enums/sortDirections";
import RtnEventsEmitter from "../../../features/Application/services/realTimeNotification/rtnEventsEmitter";
import { NoFilteredData, NoDataMonth } from "../../charts";
import { SelectionChangedType } from "../../../interfaces/onSelectionChanged";
import Restricted from "../../../features/Application/Restricted";

import "./listViewBase.scss";

export default class ListViewBase extends Component {
  static defaultActivePage = 1;
  static defaultProps = {
    withSelection: true,
    noResultsContent: null,
    permissions: [],
    withFooter: true,
    activePage: ListViewBase.defaultActivePage,
  };

  constructor(props) {
    super(props);

    const itemsPerPageAmount = localStorage.getItem("itemsPerPageAmount");

    this.state = {
      sortingColumnName: props.sortingColumnName && props.sortingColumnName.toLowerCase(),
      sortingDirection: props.sortingDirection,
      activePage: props.activePage,
      itemsPerPageAmount:
        props.withFooter && itemsPerPageAmount ? parseInt(itemsPerPageAmount) : this.itemsPerPageOptions[0].value,
      isAllItemsSelected: this.isAllItemsSelected(props.selectedItemIds),
      isHeaderWithShadow: false,
      selectedItemIds: (props.selectedItemIds && [...props.selectedItemIds]) || [],
      normalizedItems: this.getNormalizedItems(),
    };
  }

  itemsPerPageOptions = [
    { key: 1, value: 10, text: 10 },
    { key: 2, value: 20, text: 20 },
    { key: 3, value: 50, text: 50 },
    { key: 4, value: 100, text: 100 },
  ];

  componentDidMount() {
    const { onItemsPerPageAmountChange, updateComponentRtnEvents } = this.props;
    const { setReloadItems } = this.props;

    setReloadItems && setReloadItems(this.reloadItems);

    this.getPage();

    onItemsPerPageAmountChange && onItemsPerPageAmountChange(this.state.itemsPerPageAmount);

    RtnEventsEmitter.subscribe(updateComponentRtnEvents, this.debouncedGetPage);
  }

  componentWillUnmount() {
    const { updateComponentRtnEvents } = this.props;

    RtnEventsEmitter.unsubscribe(updateComponentRtnEvents, this.debouncedGetPage);
  }

  componentDidUpdate(prevProps, prevState) {
    const { items, filter, selectedItemIds: selectedItemIdsOuter, activePage, itemsAmount } = this.props;
    const { selectedItemIds } = this.state;

    if (selectedItemIdsOuter !== undefined && !isEqual(selectedItemIdsOuter, selectedItemIds)) {
      this.setState({
        selectedItemIds: selectedItemIdsOuter,
        isAllItemsSelected: this.isAllItemsSelected(selectedItemIdsOuter),
      });
    }

    if (items !== prevProps.items) {
      this.setState({
        isAllItemsSelected: this.isAllItemsSelected(selectedItemIds),
        normalizedItems: this.getNormalizedItems(),
      });
    }

    if (!isEqual(prevProps.filter, filter)) {
      this.resetSelection();
      this.setActivePage(ListViewBase.defaultActivePage, this.getPage);
    }

    if (activePage && activePage !== prevProps.activePage && activePage !== this.state.activePage) {
      this.setActivePage(this.props.activePage, this.getPage);
    } else if (itemsAmount && this.state.activePage > this.getTotalPagesAmount()) {
      this.setActivePage(this.getTotalPagesAmount(), this.getPage);
    }

    const { itemsPerPageAmount, sortingColumnName, sortingDirection, activePage: currentActivePage } = this.state;
    const {
      itemsPerPageAmount: prevItemsPerPageAmount,
      sortingColumnName: prevSortingColumnName,
      sortingDirection: prevSortingDirection,
      activePage: prevActivePage,
    } = prevState;
    if (
      itemsPerPageAmount !== prevItemsPerPageAmount ||
      sortingColumnName !== prevSortingColumnName ||
      sortingDirection !== prevSortingDirection ||
      currentActivePage !== prevActivePage
    ) {
      const skip = this.getSkipItemsAmount(itemsPerPageAmount, currentActivePage);
      this.props.onRequestParamsUpdated &&
        this.props.onRequestParamsUpdated(skip, itemsPerPageAmount, sortingColumnName, sortingDirection);
    }
  }

  getNormalizedItems = () => keyBy(this.props.items, (item) => item.id);

  getTotalPagesAmount = () => {
    return Math.ceil(this.props.itemsAmount / this.state.itemsPerPageAmount);
  };

  setActivePage = (newActivePage, callback) => {
    const pagesCount = this.getTotalPagesAmount();
    if (pagesCount > 0 && newActivePage > pagesCount) {
      newActivePage = pagesCount;
    }

    this.setState(
      {
        activePage: newActivePage,
      },
      callback,
    );
    const onPageChanged = this.props.onPageChanged;
    onPageChanged && onPageChanged(newActivePage);
  };

  footerPageChange = (newActivePage) => this.setActivePage(newActivePage, this.getPage);

  getItemsPageRange = (itemsAmount) => {
    const { activePage, itemsPerPageAmount } = this.state;

    return miscUtils.getItemsPageRange(activePage, itemsPerPageAmount, itemsAmount);
  };

  resetSelection = () => {
    const { updateSelectedItems } = this.props;
    const newSelectedItemIds = [];

    updateSelectedItems && updateSelectedItems(newSelectedItemIds);
    this.raiseOnSelectionChanged([], SelectionChangedType.Cleared);

    this.setState({
      selectedItemIds: newSelectedItemIds,
    });
  };

  onItemsPerPageChange = (_, { value }) => {
    const { onItemsPerPageAmountChange } = this.props;

    localStorage.setItem("itemsPerPageAmount", value);
    this.setState({ itemsPerPageAmount: value });
    this.setActivePage(ListViewBase.defaultActivePage, this.getPage);

    onItemsPerPageAmountChange && onItemsPerPageAmountChange(value);
  };

  getSkipItemsAmount = (itemsPerPageAmount, activePage) => {
    return itemsPerPageAmount * (activePage - 1);
  };

  onSort = (clickedColumn) => () => {
    const { sortingDirection, sortingColumnName } = this.state;
    const { setSortingColumnName, setSortingDirection } = this.props;
    let newSortingDirection;

    if (sortingColumnName !== clickedColumn) {
      newSortingDirection = SortDirections.Asc;
    } else {
      newSortingDirection = sortingDirection === SortDirections.Asc ? SortDirections.Desc : SortDirections.Asc;
    }

    this.setState(
      {
        sortingColumnName: clickedColumn,
        sortingDirection: newSortingDirection,
      },
      this.getPage,
    );
    setSortingColumnName?.(clickedColumn);
    setSortingDirection?.(newSortingDirection);
  };

  onSelectAll = () => {
    const { updateSelectedItems } = this.props;
    const { isAllItemsSelected, selectedItemIds, normalizedItems } = this.state;

    const newIsAllItemsSelected = !isAllItemsSelected;

    let newSelectedItemIds = selectedItemIds;

    if (newIsAllItemsSelected) {
      const selectedItems = [];
      const set = new Set(selectedItemIds);
      this.enabledItems.forEach((item) => {
        if (!set.has(item.id)) {
          selectedItems.push(item);
          set.add(item.id);
        }
      });
      newSelectedItemIds = [...set];
      this.raiseOnSelectionChanged(selectedItems, SelectionChangedType.Added);
    } else {
      const deselectedItems = [];
      newSelectedItemIds = newSelectedItemIds.filter((id) => {
        const matchedItem = normalizedItems[id];
        if (matchedItem) {
          deselectedItems.push(matchedItem);
          return false;
        }
        return true;
      });
      this.raiseOnSelectionChanged(deselectedItems, SelectionChangedType.Removed);
    }

    updateSelectedItems && updateSelectedItems(newSelectedItemIds);

    this.setState({
      isAllItemsSelected: newIsAllItemsSelected,
      selectedItemIds: newSelectedItemIds,
    });
  };

  onChangeSelectionHandler = (item) => {
    const id = item.id;
    return (_, { checked }) => {
      let { updateSelectedItems } = this.props;
      let selectedItemIdsCopy = [...this.state.selectedItemIds];
      let selectionChangedType;

      if (checked) {
        if (!selectedItemIdsCopy.includes(id)) {
          selectedItemIdsCopy.push(id);
        }
        selectionChangedType = SelectionChangedType.Added;
      } else {
        selectedItemIdsCopy = selectedItemIdsCopy.filter((itemId) => itemId !== id);
        selectionChangedType = SelectionChangedType.Removed;
      }

      updateSelectedItems && updateSelectedItems(selectedItemIdsCopy);
      this.raiseOnSelectionChanged([item], selectionChangedType);

      let isAllItemsSelected = this.isAllItemsSelected(selectedItemIdsCopy);

      this.setState({
        selectedItemIds: selectedItemIdsCopy,
        isAllItemsSelected: isAllItemsSelected,
      });
    };
  };

  get enabledItems() {
    let { items, isSelectDisabled } = this.props;

    return isFunction(isSelectDisabled) ? items.filter((i) => !isSelectDisabled(i)) : items;
  }

  isAllItemsSelected = (selectedItemIds) => {
    let { items } = this.props;
    const enabledItems = this.enabledItems;

    return (
      selectedItemIds &&
      items.length !== 0 &&
      enabledItems.length !== 0 &&
      enabledItems.every((i) => selectedItemIds.includes(i.id))
    );
  };

  get isRestricted() {
    return this.props.permissions?.length > 0;
  }

  renderCheckbox = (item, isItemChecked, isDisabled) => (
    <Checkbox
      checked={isItemChecked}
      readOnly={isDisabled}
      disabled={isDisabled}
      onChange={this.onChangeSelectionHandler(item)}
    />
  );

  buildTableBody = () => {
    const { withSelection, buildTableBody, items } = this.props;

    if (!withSelection) {
      return items.map((item) => {
        const key = JSON.stringify(item.id);
        return (
          <Table.Row
            className="list-view-row"
            key={key}
            as={this.props.rowAs}
            item={this.props.rowAs ? item : undefined}
          >
            {buildTableBody(item)}
          </Table.Row>
        );
      });
    }
    const { isSelectDisabled, isWarning } = this.props;
    const { selectedItemIds } = this.state;

    return items.map((item) => {
      const key = JSON.stringify(item.id);
      const isItemChecked = selectedItemIds.includes(item.id);

      const isDisabled = isFunction(isSelectDisabled) && isSelectDisabled(item);
      const isWarningRow = isFunction(isWarning) && isWarning(item);

      const trClass = cn("list-view-row", {
        "warning-row": isWarningRow,
        selected: isItemChecked,
      });
      return (
        <Table.Row
          key={key}
          className={trClass}
          data-id={key}
          as={this.props.rowAs}
          item={this.props.rowAs ? item : undefined}
          isChecked={this.props.rowAs ? isItemChecked : undefined}
        >
          <Table.Cell collapsing className="checkbox-cell">
            {this.renderCheckbox(item, isItemChecked, isDisabled)}
          </Table.Cell>
          {buildTableBody(item, isItemChecked)}
        </Table.Row>
      );
    });
  };

  getPage = () => {
    const { loadPage, filter } = this.props;
    const { itemsPerPageAmount, sortingColumnName, sortingDirection, activePage } = this.state;

    let skip = this.getSkipItemsAmount(itemsPerPageAmount, activePage);

    loadPage && loadPage(skip, itemsPerPageAmount, sortingColumnName, sortingDirection, filter);
  };

  debouncedGetPage = debounce(this.getPage, 500);

  reloadItems = (enableSorting) => {
    const { activePage } = this.state;
    const { paginateOnLoad } = this.props;
    if (enableSorting !== undefined) {
      this.setState({
        sortingColumnName: this.props.sortingColumnName?.toLowerCase(),
        sortingDirection: this.props.sortingDirection,
      });
    }
    // Determines if reloading stays on current page or returns to default.
    this.setActivePage(paginateOnLoad ? activePage : ListViewBase.defaultActivePage, this.getPage);
  };

  onScroll = (e) => {
    const { isHeaderWithShadow } = this.state;

    let isHeaderWithShadowNew = e.target.scrollTop > 0;

    if (isHeaderWithShadow !== isHeaderWithShadowNew) {
      this.setState({
        isHeaderWithShadow: isHeaderWithShadowNew,
      });
    }
  };

  raiseOnSelectionChanged = (items, type) => {
    const { onSelectionChanged } = this.props;
    onSelectionChanged && onSelectionChanged({ items, type });
  };

  renderTable = (hasPermission = true) => {
    const { itemsAmount, isLoading, columnOptions, withSelection, withFooter, loadSpinner } = this.props;

    const {
      sortingColumnName,
      sortingDirection,
      activePage,
      itemsPerPageAmount,
      isAllItemsSelected,
      isHeaderWithShadow,
    } = this.state;

    const sortingDirectionsLong = {
      asc: "ascending",
      desc: "descending",
    };

    if (loadSpinner && isLoading) {
      return <Loader active={isLoading} style={{ position: "relative" }} />;
    }

    return (
      <div className={"list-view"} onScroll={this.onScroll}>
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Table sortable className="list-table">
            <Header
              className={isHeaderWithShadow ? "shadow" : ""}
              columnOptions={columnOptions.filter((column) => !column.isHide)}
              sortingColumnName={sortingColumnName}
              sortingDirection={sortingDirectionsLong[sortingDirection]}
              onSort={this.onSort}
              onSelectAll={this.onSelectAll}
              isAllItemsSelected={isAllItemsSelected}
              isAllItemsDisabled={isEmpty(this.enabledItems)}
              withSelection={withSelection}
              hasPermission={hasPermission}
            />

            <Table.Body className="table-body">{this.buildTableBody(hasPermission)}</Table.Body>
          </Table>

          {withFooter && (
            <Footer
              itemsPerPageAmount={itemsPerPageAmount}
              itemsPerPageOptions={this.itemsPerPageOptions}
              itemsAmount={itemsAmount}
              activePage={activePage}
              onPageChange={this.footerPageChange}
              onItemsPerPageChange={this.onItemsPerPageChange}
              itemsPageRange={this.getItemsPageRange(itemsAmount)}
              isLoading={isLoading}
              totalPages={this.getTotalPagesAmount()}
            />
          )}
        </Dimmer.Dimmable>
      </div>
    );
  };

  render() {
    const { items, isLoading, filter, noResultsContent, permissions } = this.props;

    if (!items.length > 0 && !isLoading && (filter?.dateFrom || filter?.dateTo)) {
      return <NoFilteredData />;
    }

    if (!items.length > 0 && !isLoading && filter?.dateFrom === "" && filter?.dateTo === "") {
      return <NoDataMonth />;
    }

    if (!items.length > 0 && !isLoading) {
      return noResultsContent;
    }

    if (this.isRestricted) {
      return (
        <Restricted permissions={permissions} renderContent={(hasPermission) => this.renderTable(hasPermission)} />
      );
    }

    return this.renderTable();
  }
}

ListViewBase.propTypes = {
  columnOptions: PropTypes.array.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  loadPage: PropTypes.func,
  selectedItemIds: PropTypes.array,
  withFooter: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateSelectedItems: PropTypes.func,
  withSelection: PropTypes.bool,
  filter: PropTypes.object,
  onPageChange: PropTypes.func,
  activePage: PropTypes.number,
  noResultsContent: PropTypes.node,
  sortingColumnName: PropTypes.string,
  sortingDirection: PropTypes.string,
  onItemsPerPageAmountChange: PropTypes.func,
  updateComponentRtnEvents: PropTypes.array,
  isSelectDisabled: PropTypes.func,
  isWarning: PropTypes.func,
  setReloadItems: PropTypes.func,
  permissions: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  paginateOnLoad: PropTypes.bool,
  rowAs: PropTypes.func,
};
