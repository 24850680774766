import { bindActionCreators } from "@reduxjs/toolkit";
import cn from "classnames";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { reset, setIsNavigationEnabled, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import moment from "moment";
import { type FC, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Icon } from "semantic-ui-react";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { type AppDispatch } from "../../../../../Application/globaltypes/redux";
import { type RegisterBreadcrumbCallback } from "../../../../Common/Hooks/usePerformanceBreadcrumbs";
import { type AccountType, type PerformanceWithAccountFilter } from "../../../../Common/utils/performanceUtils";
import * as emailPerformanceActions from "../../state/thunks/emailPerformanceThunk";
import EmailPerformanceCharts from "./Charts/EmailPerformanceCharts";
import EmailPerformanceDrilldown from "./Drilldown/EmailPerformanceDrilldown";
import EmailSendsList from "./EmailSendsList/EmailSendsList";
import { type EmailSendSession, EmailStatusToColor, EmailStatusToIcon } from "./types";

import "../../../../Common/utils/performanceSCSSUtils.scss";
import "./emailPerformance.scss";

export interface Props extends PropsFromRedux {
  emailId: number;
  emailTitle: string;
  flowId?: number;
  dateFilter: PerformanceWithAccountFilter & { type: AccountType };
  registerBreadcrumb: RegisterBreadcrumbCallback;
}

export const EmailPerformanceBody: FC<Props> = ({
  emailId,
  emailTitle,
  flowId,
  dateFilter,
  registerBreadcrumb,
  setExportAction,
  actions,
  setIsReportEnabled,
  resetIsReportEnabled,
  setIsNavigationEnabled,
}) => {
  // Using full send session here to name the export file
  const [selectedSendEntity, setSelectedSendEntity] = useState<EmailSendSession>();

  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setIsNavigationEnabled(!selectedSendEntity);
    if (selectedSendEntity) {
      const fileName = `${emailTitle} ${moment(selectedSendEntity.SendSession).format("MM-DD-YYYY h-mm A")}`;
      setExportAction({
        method: actions.handleSendSessionExport,
        args: [selectedSendEntity.SendSessionId, { ...dateFilter, flowId }, fileName],
        isExportEnabled: true,
      });
      return;
    }

    setExportAction({
      method: actions.handleCommunicationsExport,
      args: [emailId, { ...dateFilter, flowId }, emailTitle],
      isExportEnabled: true,
    });
  }, [
    actions.handleCommunicationsExport,
    actions.handleSendSessionExport,
    dateFilter,
    emailId,
    flowId,
    selectedSendEntity,
    setExportAction,
    emailTitle,
    setIsNavigationEnabled,
  ]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceBody">
      <EmailPerformanceCharts
        emailId={emailId}
        dateFilter={dateFilter}
        sendSessionId={selectedSendEntity?.SendSessionId}
        flowId={flowId}
      />
      {selectedSendEntity ? (
        <EmailPerformanceDrilldown
          emailId={emailId}
          flowId={flowId}
          sendSessionId={selectedSendEntity.SendSessionId}
          dateFilter={dateFilter}
        />
      ) : (
        <div>
          <EmailSendsList
            emailId={emailId}
            emailFilter={dateFilter}
            flowId={flowId}
            selectSend={(entity) => {
              setSelectedSendEntity(entity);
              registerBreadcrumb({
                text: (
                  <>
                    {dateTimeUtils.localFormatWithValidation(new Date(entity.SendSession))}{" "}
                    <Icon
                      name={EmailStatusToIcon[entity.Status]}
                      className={cn(EmailStatusToColor[entity.Status], "offset")}
                    />
                  </>
                ),
                action: () => {
                  setSelectedSendEntity(undefined);
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = () => ({});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(emailPerformanceActions, dispatch),
    setExportAction: bindAction(setExportAction, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(reset, dispatch),
    setIsNavigationEnabled: bindAction(setIsNavigationEnabled, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EmailPerformanceBody);
export default ConnectedComponent;
