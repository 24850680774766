import { type UsersGroupsContext } from "../../../../enums";
import { type ISegment } from "../../../../interfaces";

export interface EditEventState {
  showAddPeopleModal: boolean;
  showAddGroupModal: boolean;
  showRevertModal: boolean;
  isValid: boolean;
  people: {
    usersGroupsContext: UsersGroupsContext;
  };
  activePage: EditEventSteps;
}

export interface PageInfo {
  index: number;
  segment: ISegment;
}

export interface PagesBase {
  configure: PageInfo;
  settings: PageInfo;
  content: PageInfo;
  reminders: PageInfo;
}

export interface EditPages extends PagesBase {
  people: PageInfo;
  performance: PageInfo;
}

export enum EditEventSteps {
  Performance,
  Configure,
  Settings,
  Content,
  Reminders,
  People,
  AssociatedPacks,
}

export const showLastModified = new Set([EditEventSteps.Configure, EditEventSteps.Content, EditEventSteps.Reminders]);
