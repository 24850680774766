import { useSelector } from "react-redux";
import { type RootState } from "./globaltypes/redux";
import { type RolePermissions } from "../../enums";
import { hasPermissionsPredicate } from "./hooks/useRestrictedCheck";

export interface RestrictedProps {
  permissions: RolePermissions[];
  children?: React.ReactElement | React.ReactElement[] | boolean | null;
  renderContent?: (hasAnyPermission: boolean) => React.ReactElement;
  placeholder?: React.ReactElement;
  permissionPredicate?: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}

export const Restricted: React.FC<RestrictedProps> = ({
  permissions,
  children,
  renderContent,
  placeholder,
  permissionPredicate,
}) => {
  const cb = permissionPredicate ?? hasPermissionsPredicate; // use hook directly
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  const renderPlaceholder = () => placeholder || null;

  const hasAnyPermission = cb(userPermissions, permissions);

  if (renderContent) {
    return renderContent(hasAnyPermission);
  }

  if (hasAnyPermission) {
    return <>{children}</>;
  }

  return renderPlaceholder();
};

export default Restricted;
