import React, { Component } from "react";
import { Dimmer, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import CardsViewer from "../CardsViewer";
import LazyLoader from "../../lazyLoader/LazyLoader";
import "./lazyCardsViewer.scss";

export default class LazyCardsViewer extends Component {
  render() {
    const {
      getData,
      items,
      isLoading,
      isAllLoaded,
      hidePopupMenu,
      onSelectedItemsChanged,
      selectedItemIds,
      noResultsContent,
      itemsPerRow,
      renderCard,
    } = this.props;
    let result = noResultsContent;

    if (items.length > 0 || isLoading) {
      result = (
        <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="grid-view">
          <LazyLoader
            getData={getData}
            isFirstLoad={items.length === 0}
            loadingData={isLoading}
            isAllDataLoaded={isAllLoaded}
          >
            <CardsViewer
              itemsPerRow={itemsPerRow}
              cardItems={items}
              hidePopupMenu={hidePopupMenu}
              onSelectedCardsChanged={onSelectedItemsChanged}
              selectedIds={selectedItemIds}
              renderCard={renderCard}
            />
          </LazyLoader>
        </Dimmer.Dimmable>
      );
    }

    return result;
  }
}

LazyCardsViewer.defaultProps = {
  items: [],
  noResultsContent: null,
};

LazyCardsViewer.propTypes = {
  getData: PropTypes.func,
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  isAllLoaded: PropTypes.bool,
  renderCard: PropTypes.func,
  selectedItemIds: PropTypes.array,
  noResultsContent: PropTypes.object,
  onSelectedItemsChanged: PropTypes.func,
  itemsPerRow: PropTypes.number,
};
