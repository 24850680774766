import { combineReducers, type Reducer } from "@reduxjs/toolkit";

import contentToPeopleAssignments from "../ContentAssignments/state/contentAssignmentsSlice";
import contentAssignmentsSurveyFiltersSlice from "../ContentAssignments/state/slices/contentAssignmentsFiltersSlice";
import contentAssignmentsOverviewFiltersSlice from "../ContentAssignmentsOverview/slices/contentAssignmentsOverviewFiltersSlice";
import { contentAssignmentReducer } from "../ContentAssignmentState/ContentAssignmentReducer";
import createRoleReducer from "../CreateRoles/state/createRoleReducer";
import { editRole } from "../EditRole/editRoleReducer";
import { addGroup } from "../Groups/AddGroup/state/addGroupReducer";
import { editGroup } from "../Groups/EditGroup/editGroupReducer";
import { groupsOverviewReducer } from "../Groups/GroupsOverview/state/groupsOverviewReducer";
import { enrollmentUsersReducer } from "../Groups/state/slices/enrollmentUsersSlice";
import { GroupTemplateReducer } from "../GroupTemplate/state/reducer";
import rolesOverviewReducer from "../RolesOverview/state/rolesOverviewReducer";
import addToContentModal from "./slices/addToContentSlice";
import { type PeopleState } from "../types";
import { addUserReducer } from "../Users/AddUser/state/addUserReducer";
import { editUserReducer } from "../Users/EditUser/state/editUserRootReducer";
import userCommonReducer from "../Users/state/userCommonReducer";
import { usersOverview } from "../Users/UsersOverview/state/usersOverviewReducer";

const peopleReducer = combineReducers({
  groupsOverview: groupsOverviewReducer,
  addUserReducer,
  editUser: editUserReducer,
  usersOverview,
  userCommon: userCommonReducer,
  addToContentModal,
  addGroup,
  createRole: createRoleReducer,
  editGroup,
  groupTemplate: GroupTemplateReducer,
  rolesOverview: rolesOverviewReducer,
  editRole,
  contentAssignment: contentAssignmentReducer,
  contentToPeople: combineReducers({
    assignments: contentToPeopleAssignments,
    filters: contentAssignmentsSurveyFiltersSlice,
  }),
  contentAssignmentsOverview: combineReducers({
    filters: contentAssignmentsOverviewFiltersSlice,
  }),
  enrollmentUsers: enrollmentUsersReducer
}) as unknown as Reducer<PeopleState>;

export default peopleReducer;

// NOSONAR TODO: resolve types from lower level reducers
// export type PeopleState = ReturnType<typeof peopleReducer>;
