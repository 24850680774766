import { Component } from "react";
import { isEmpty } from "lodash";
import { FilterDropdown } from "../../dropdowns";
import { FilterDateRange } from "../../datePicker";
import { array, shape, number, object, arrayOf, string } from "prop-types";
import { commonFilterFormPropTypes } from "../types/commonFilterFormPropTypes";
import Awareness from "../../../enums/groups/awareness";
import Visibility from "../../../enums/groups/visibility";
import Access from "../../../enums/groups/access";
import Enrollment from "../../../enums/groups/enrollment";
import { FilterMultiSelect } from "../../multiSelect";

import "./groupFilterForm.scss";

const filterPropertyNames = {
  privacy: "IsAware",
  visibility: "IsVisibleToAll",
  access: "IsOpen",
  enrollment: "IsWithAutoEnroll",
  dateCreated: "DateCreated",
  owners: "owners",
};

class GroupFilterForm extends Component {
  componentDidUpdate(prevProps) {
    const { filter, updateFilter } = this.props;

    if (prevProps.filter.IsVisibleToAll !== filter.IsVisibleToAll) {
      const isPrevVisibleToMembersOnly = prevProps.filter.IsVisibleToAll === Visibility.MembersOnly;
      const isVisibleToMembersOnly = filter.IsVisibleToAll === Visibility.MembersOnly;

      if (isVisibleToMembersOnly) {
        updateFilter({ ...filter, IsOpen: Access.Restricted });
      } else if (isPrevVisibleToMembersOnly) {
        let newFilter = { ...filter };
        delete newFilter.IsOpen;
        updateFilter(newFilter);
      }
    }
  }

  // updating filter according to selected value
  updateFilterOnPrivacyChange = (filter) => {
    const { updateFilter } = this.props;

    if (filter.IsAware === Awareness.Unaware) {
      let newFilter = { ...filter };
      delete newFilter.IsVisibleToAll;
      delete newFilter.IsOpen;
      updateFilter(newFilter);
    } else {
      updateFilter(filter);
    }
  };

  render() {
    const { filter, updateFilter, withEnrollmentFilter, filterOptions } = this.props;

    return (
      <div className="groups-filter-form">
        <div className="filter-item">
          <div className="label">Privacy</div>
          <FilterDropdown
            id="privacy"
            className="groups-filter-dropdown privacy"
            propertyName={filterPropertyNames.privacy}
            items={filterOptions.privacy}
            filter={filter}
            updateFilter={this.updateFilterOnPrivacyChange}
            defaultSelected={Awareness.None}
            selection
          />
        </div>

        {filter.IsAware !== Awareness.Unaware && (
          <div>
            <div className="filter-item">
              <div className="label">Visibility</div>
              <FilterDropdown
                id="visibility"
                className="groups-filter-dropdown visibility"
                propertyName={filterPropertyNames.visibility}
                items={filterOptions.visibility}
                filter={filter}
                updateFilter={updateFilter}
                defaultSelected={Visibility.None}
                selection
              />
            </div>
            <div className="filter-item">
              <div className="label">Access</div>
              <FilterDropdown
                id="access"
                className="groups-filter-dropdown access"
                propertyName={filterPropertyNames.access}
                items={
                  filter.IsVisibleToAll === Visibility.MembersOnly
                    ? [filterOptions.accessRestrictedFilterOption]
                    : filterOptions.access
                }
                filter={filter}
                updateFilter={updateFilter}
                defaultSelected={Access.None}
                selection
              />
            </div>
          </div>
        )}

        {withEnrollmentFilter && (
          <div className="filter-item">
            <div className="label">Enrollment</div>
            <FilterDropdown
              id="enrollment"
              className="groups-filter-dropdown enrollment"
              propertyName={filterPropertyNames.enrollment}
              items={filterOptions.enrollment}
              filter={filter}
              updateFilter={updateFilter}
              defaultSelected={Enrollment.None}
              selection
            />
          </div>
        )}
        {!isEmpty(filterOptions.owners) && (
          <div className="filter-item">
            <div className="label">Owner</div>
            <FilterMultiSelect
              placeholder="All"
              propertyName={filterPropertyNames.owners}
              items={filterOptions.owners}
              filter={filter}
              updateFilter={updateFilter}
            />
          </div>
        )}
        <FilterDateRange
          id="date-added"
          label="Added"
          propertyName={filterPropertyNames.dateCreated}
          filter={filter}
          updateFilter={updateFilter}
          className="filter-item"
          verticalPosition={filter.IsAware === Awareness.Unaware ? "bottom" : "top"}
          horizontalPositionSecondPicker="left"
        />
      </div>
    );
  }
}

GroupFilterForm.defaultProps = {
  withEnrollmentFilter: true,
};

GroupFilterForm.propTypes = {
  filterOptions: shape({
    privacy: array,
    access: array,
    enrollment: array,
    visibility: array,
    owners: arrayOf(
      shape({
        id: number,
        userName: string,
      }),
    ),
  }),
  filter: shape({
    privacy: number,
    access: number,
    enrollment: number,
    visibility: number,
    dateCreated: object,
    owners: array,
  }),
  ...commonFilterFormPropTypes,
};

export default GroupFilterForm;
