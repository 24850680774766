import { connect, type ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "components/buttons/button/Button";

import PacksOverview from "./PacksOverview";
import { Segments, OverviewHeader } from "../../../../components";
import { RolePermissions, RouteNames, type PacksOwnership } from "../../../../enums";
import Restricted from "../../../Application/Restricted";
import { setTerm } from "../state/slices/packsSearchSlice";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import { hasPacksManagePermissionSelector, hasFlowsCreatePermissionSelector } from "../state/selectors";

const basePacksUrl = `/${RouteNames.licensingPacks}`;

export interface PacksOverviewSegmentsOwnProps {
  hasPacksManagePermissions: boolean;
  hasFlowsCreatePermissions: boolean;
  ownership: PacksOwnership;
}

export type PacksOverviewSegmentsComponentPropsAll = PropsFromRedux & PacksOverviewSegmentsOwnProps;

export const PacksOverviewSegmentsComponent: React.FC<PacksOverviewSegmentsComponentPropsAll> = ({
  clearSearch,
  hasPacksManagePermissions,
  hasFlowsCreatePermissions,
  ownership,
}) => {
  const navigate = useNavigate();
  const renderCreatePackBtn = () => {
    const createPackBtnPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

    return (
      <Restricted permissions={createPackBtnPermissions}>
        <Button
          primary
          className="create-pack create-button"
          onClick={() => {
            clearSearch();
            navigate("/licensing/packs/create");
          }}
        >
          Create Pack
        </Button>
      </Restricted>
    );
  };
  const renderAvailablePacks = () => {
    return <PacksOverview key={`pack-overview-${ownership}`} ownership={ownership} />;
  };

  const renderOwnAndAvailablePacks = () => {
    return (
      <>
        <Segments to={basePacksUrl}>
          <Segments.Segment onClick={clearSearch} to={RouteNames.available} label="Available Packs" />
          <Segments.Segment onClick={clearSearch} label="My Packs" />
        </Segments>
        {renderAvailablePacks()}
      </>
    );
  };

  return (
    <section className="nested-content packs">
      <OverviewHeader title="Marketplace">{renderCreatePackBtn()}</OverviewHeader>
      {hasPacksManagePermissions || hasFlowsCreatePermissions ? renderOwnAndAvailablePacks() : renderAvailablePacks()}
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    hasPacksManagePermissions: hasPacksManagePermissionSelector(state),
    hasFlowsCreatePermissions: hasFlowsCreatePermissionSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearSearch: () => dispatch(setTerm("")),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PacksOverviewSegmentsComponent);
export default ConnectedComponent;
