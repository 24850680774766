import { bindActionCreators } from "@reduxjs/toolkit";
import { type FC, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import {
  accountPackLicenseSelector,
  reset,
} from "../../../../features/Accounts/EditAccount/state/slices/editAccountPackLicenseSlice";
import { fetchAccountPackLicense as fetchAccountPackLicenseAction } from "../../../../features/Accounts/EditAccount/state/thunks/editAccountPackLicenseThunk";
import { type AppDispatch, type RootState } from "../../../../features/Application/globaltypes/redux";
import { fetchPackLicenseTypes } from "../../../../features/Licensing/Packs/state/thunks/packLicenseTypesThunk";
import { useObserver } from "../../../../hooks/useObserver";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import { type EditLicensingModalProps } from "./types";
import { type AccountPackLicense } from "../../../../features/Accounts/types";
import LicenseEditingStep from "../packAssignmentSteps/licensesAddingStep/LicenseEditingStep";

import "./editLicensingModal.scss";
import { MAX_LICENSE_COUNT } from "../constants";

export type EditLicensingModalAllProps = PropsFromRedux & EditLicensingModalProps;

export const EditLicensingModal: FC<EditLicensingModalAllProps> = ({
  selectedViewType,
  onConfirm,
  showModalObserver,
  licenseTypes,
  accountPackLicense,
  fetchAccountPackLicense,
  loadLicenseTypes,
  resetAccountPackLicense,
  isLoading,
  error,
}) => {
  const [selectedPackId, setSelectedPackId] = useState<number>(0);
  const [selectedAccountPackLicense, setSelectedAccountPackLicense] = useState<AccountPackLicense>();
  const [isDataValid, setIsDataValid] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal && accountPackLicense) {
      setSelectedAccountPackLicense(accountPackLicense);
    }
  }, [showModal, accountPackLicense]);

  useEffect(() => {
    const isNotValid =
      !selectedAccountPackLicense?.totalLicensesCount ||
      selectedAccountPackLicense.totalLicensesCount > MAX_LICENSE_COUNT ||
      selectedAccountPackLicense.assingedLicensesCount > selectedAccountPackLicense.totalLicensesCount ||
      !selectedAccountPackLicense.expirationDate ||
      error;
    setIsDataValid(!isNotValid);
  }, [error, selectedAccountPackLicense]);

  const onEditLicensingButtonClick = (packId: number, accountId: number) => {
    setShowModal(true);
    setSelectedPackId(packId);
    fetchAccountPackLicense(packId, accountId);
    loadLicenseTypes();
  };
  const [subscribeOnEditLicensingButtonClick] = useObserver(showModalObserver);

  useEffect(() => {
    return subscribeOnEditLicensingButtonClick(onEditLicensingButtonClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnEditLicensingButtonClick]);

  const onCloseModal = () => {
    setShowModal(false);
    resetAccountPackLicense();
    setSelectedAccountPackLicense(undefined);
  };

  const renderEditLicensingStepActions = (_nextStep: () => void, _prevStep: () => void) => (closeModal: Function) => (
    <>
      <Button
        basic
        className="cancel"
        content="Cancel"
        onClick={() => {
          onCloseModal();
          closeModal();
        }}
      />

      <Button
        primary
        className="confirm"
        content="Save"
        onClick={() => {
          onConfirm(selectedAccountPackLicense!, selectedViewType);
          onCloseModal();
          closeModal();
        }}
        disabled={!isDataValid}
      />
    </>
  );

  /* istanbul ignore next */
  const onLicenseCountChange = (licensesCount?: number) => {
    if (selectedAccountPackLicense) {
      const updatedAccountPackLicense = { ...selectedAccountPackLicense, totalLicensesCount: licensesCount };
      setSelectedAccountPackLicense(updatedAccountPackLicense);
    }
  };

  /* istanbul ignore next */
  const onExpirationDateChange = (optionId?: number, date?: Date | null) => {
    if (selectedAccountPackLicense) {
      let updatedAccountPackLicense = { ...selectedAccountPackLicense };
      if (optionId) {
        updatedAccountPackLicense = { ...updatedAccountPackLicense, licenseTypeId: optionId };
      }
      if (date) {
        updatedAccountPackLicense = { ...updatedAccountPackLicense, expirationDate: date };
      }
      setSelectedAccountPackLicense(updatedAccountPackLicense);
    }
  };

  return (
    <ModalWithSteps className={"edit-licensing-modal"} scrolling={false} showModal={showModal} onCancel={onCloseModal}>
      <LicenseEditingStep
        header="Edit Licensing"
        packId={selectedPackId}
        renderModalActions={renderEditLicensingStepActions}
        isLoading={isLoading}
        error={error}
        accountPackLicense={selectedAccountPackLicense}
        licenseTypes={licenseTypes}
        onLicenseCountChange={onLicenseCountChange}
        onExpirationDateChange={onExpirationDateChange}
      />
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountPackLicense: accountPackLicenseSelector(state).accountPackLicense,
  isLoading: accountPackLicenseSelector(state).isLoading || state.packs.packLicenseTypes.isLoading,
  error: accountPackLicenseSelector(state).error,
  licenseTypes: state.packs.packLicenseTypes.items,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchAccountPackLicense: (packId: number, accountId: number) =>
      dispatch(fetchAccountPackLicenseAction(packId, accountId)),
    loadLicenseTypes: bindActionCreators(fetchPackLicenseTypes, dispatch),
    resetAccountPackLicense: () => dispatch(reset()),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditLicensingModal);
export default ConnectedComponent;
