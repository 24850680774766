import { Grid } from "semantic-ui-react";
import { type FC } from "react";

import { type PackRequestCommonWithFormProps } from "./types";
import { LicensesCountField } from "./fields/LicensesCountField";
import { TrialPackHeader } from "./TrialPackHeader";
import { TrialAgreementNotice } from "./TrialAgreementNotice";

export interface Props extends PackRequestCommonWithFormProps {
  trialDuration: number;
  hasPurchasingPower: boolean;
}

export const RequestOrEnableTrialForm: FC<Props> = (props) => {
  const { packTitle, trialDuration, hasPurchasingPower } = props;
  return (
    <div className="pack-request-container">
      <TrialPackHeader {...props} />
      <Grid stackable rows="equal" verticalAlign="bottom">
        <LicensesCountField {...props} />
        <TrialAgreementNotice
          packName={packTitle}
          trialDurationInDays={trialDuration}
          hasPurchasingPower={hasPurchasingPower}
        />
      </Grid>
    </div>
  );
};
