import React, { type FC } from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { type LandingPagesNoResultsProps } from "../types";
import NoSearchResults from "../../../../components/noSearchResults";

const noLandingPagesCreated = (createButton: React.ReactElement | null | undefined): React.ReactElement => {
  const description = (
    <span>
      Looks like you don't have any landing pages yet.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults title="You don’t have any landing pages" description={description} iconClassName="fa-envelope">
      {createButton}
    </NoResults>
  );
};

const ThreatDefenceLandingPagesNoResults: FC<LandingPagesNoResultsProps> = ({ createButton, filtered }): React.ReactElement =>
  filtered ? <NoSearchResults /> : noLandingPagesCreated(createButton);

export default ThreatDefenceLandingPagesNoResults;
