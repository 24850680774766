import { AddPeopleToContentTypes } from "../../../enums";
import { type EllipsisPopupOption } from "../../buttons/ellipsisPopup/EllipsisPopup";

type OnAddToContent = (type: AddPeopleToContentTypes) => void;

export const getAddContentEllipsisPopupOptions = (onAddToContent: OnAddToContent): EllipsisPopupOption[] => {
  const onClickHandler = (contentType: AddPeopleToContentTypes) => () => onAddToContent(contentType);

  return [
    {
      label: "Flows",
      onClick: onClickHandler(AddPeopleToContentTypes.Flows),
    },
  ];
};
