import { useCallback } from "react";
import { ELEMENT_HALF_HEIGHT, ELEMENT_HALF_WIDTH } from "../../constants";
import { Easing, Tween, update } from "@tweenjs/tween.js";
import { useReactFlow } from "reactflow";
import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";

const TRANSITION_TIME = 1000;
const EASING = Easing.Quadratic.Out;

function animate(time: number) {
  requestAnimationFrame(animate);
  update(time);
}
requestAnimationFrame(animate);

export default function useHandleTransform(refreshViewPort: () => void) {
  const { reactFlowBounds } = useReactFlowCanvasState();
  const { getViewport, setViewport } = useReactFlow();

  const handleTransform = useCallback(
    (transform: { x: number; y: number }) => {
      const { x, y, zoom } = getViewport();

      const targetTransform = {
        x: (-transform.x - ELEMENT_HALF_WIDTH) * zoom + reactFlowBounds.width / 2,
        y: (-transform.y - ELEMENT_HALF_HEIGHT) * zoom + reactFlowBounds.height / 2,
      };

      new Tween({ x, y, zoom })
        .to(targetTransform, TRANSITION_TIME)
        .easing(EASING)
        .onUpdate(({ x, y, zoom }) => {
          setViewport({ x, y, zoom });
        })
        .onComplete(() => {
          refreshViewPort();
        })
        .start();
    },
    [reactFlowBounds, refreshViewPort, getViewport, setViewport],
  );

  return { handleTransform };
}
