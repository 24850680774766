import { Checkbox, Table } from "semantic-ui-react";
import cn from "classnames";

import LazyLoader from "../lazyLoader/LazyLoader";
import Header from "../listView/listViewBase/header/Header";
import { sortingDirectionsLong } from "../../enums/sortingDirection";

import { type LazyLoadingListProps } from "./types";

import "./lazyLoadingList.scss";

export const LazyLoadingList = <T extends { id: number }>(props: LazyLoadingListProps<T>) => {
  const renderCheckbox = (item: T, isItemChecked: boolean) => {
    return <Checkbox checked={isItemChecked} onChange={() => props.onSelect(item)} disabled={props.disabled} />;
  };

  const buildTableRowCell = (listItem: T, isItemChecked: boolean) => (
    <>
      {props.columnOptions.map((item, index) => (
        <Table.Cell collapsing={item.collapsing} key={index} width={item.width} textAlign={item.textAlign}>
          {item.render(listItem, isItemChecked)}
        </Table.Cell>
      ))}
    </>
  );

  const buildTableBody = () => {
    return props.items.map((item) => {
      const isItemChecked = props.selectedItems.has(item);

      const trClass = cn("tr", {
        selected: isItemChecked,
      });

      return (
        <Table.Row key={item.id} className={trClass}>
          {props.withSelection && (
            <Table.Cell collapsing className="checkbox-cell">
              {renderCheckbox(item, isItemChecked)}
            </Table.Cell>
          )}
          {buildTableRowCell(item, isItemChecked)}
        </Table.Row>
      );
    });
  };

  const renderTable = () => {
    return (
      <Table className="lazy-loading-table">
        <Header
          className={"lazy-loading-table-head"}
          columnOptions={props.columnOptions}
          sortingColumnName={props.defaultSortingColumnName}
          sortingDirection={sortingDirectionsLong[props.sortingDirection]}
          onSelectAll={props.onSelectAll}
          isAllItemsSelected={props.isAllItemsSelected}
          isAllItemsDisabled={props.disabled}
          withSelection={props.withSelection}
          hasPermission={props.hasPermission}
        />
        <Table.Body className="table-body">{buildTableBody()}</Table.Body>
      </Table>
    );
  };

  const renderNoResults = () => !props.isLoading && props.noResultsContent;

  return (
    <div className="lazy-loading-list-scrollable-container">
      {props.items.length > 0 ? (
        <LazyLoader
          getData={props.fetchItems}
          isFirstLoad={props.items.length === 0}
          loadingData={props.isLoading}
          isAllDataLoaded={props.isAllDataLoaded}
          className="right-gutter"
        >
          {renderTable()}
        </LazyLoader>
      ) : (
        renderNoResults()
      )}
    </div>
  );
};
