import React from "react";
import { type DropdownProps, type CheckboxProps, Radio } from "semantic-ui-react";
import nameof from "utils/nameof";
import {
  type SfIntegrationConfigs,
  type ContentSettings,
  CustomContent,
  PackContent,
  type PackSettings,
  type SftpSettings,
  type CompletionHistorySettings,
  type ColumnValues,
} from "../../types/successFactors";
import InputField from "components/forms/InputField";
import { MultiSelect } from "components/multiSelect";
import environmentConfig from "configuration/environmentConfig";
import { type useFormik } from "formik";
import { type DropdownItem } from "utils/miscellaneousUtils";
import CheckboxField from "components/forms/CheckboxField";
import Form from "components/forms/layout";

interface Props {
  integrationName: string;
  renderHeader?: boolean;
  formik: ReturnType<typeof useFormik<SfIntegrationConfigs>>;
  packs: DropdownItem[];
  isPacksLoading: boolean;
}

export const SfConfigurationForm = (props: Props) => {
  const { integrationName, renderHeader, formik, packs, isPacksLoading } = props;

  const { values, setFieldValue, isValid, validateForm, resetForm, ...restFormikProps } = formik;
  const {
    packSettings,
    contentSettings,
    sftpSettings,
    tenantId,
    isHistoryEnabled,
    completionHistorySettings,
    columnValues,
  } = values;

  const handleFieldChange = async (fieldName: string, value: any, shouldValidate?: boolean) => {
    await setFieldValue(fieldName, value, shouldValidate);
  };

  const handleChangePacks = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    handleFieldChange(packsName("packIds"), data.value);

  const handleCustomContentChange = (_: any, data: CheckboxProps) =>
    handleFieldChange(
      `${nameof<SfIntegrationConfigs>("contentSettings")}.${nameof<ContentSettings>("contentType")}`,
      data.value,
    );

  const handlePackContentChange = (_: any, data: CheckboxProps) =>
    handleFieldChange(packsName("contentType"), data.value);

  return (
    <Form.Root>
      {renderHeader && <Form.Block title="Integration type">{integrationName}</Form.Block>}
      <Form.Block
        title="SFTP settings"
        tooltip={{
          content: (
            <>
              Enter the SuccessFactors&nbsp;
              <a href={SFTP_SETTINGS_URL} target="_blank" rel="noopener noreferrer">
                SFTP settings
              </a>
              &nbsp;configured for your organization.
            </>
          ),
          width: "16.1rem",
        }}
      >
        <Form.Input>
          <InputField
            {...restFormikProps}
            markAsRequired
            label="Host"
            placeholder="example.com"
            value={sftpSettings.host}
            setFieldValue={handleFieldChange}
            propertyName={`${nameof<SfIntegrationConfigs>("sftpSettings")}.${nameof<SftpSettings>("host")}`}
          />
        </Form.Input>
        <Form.Input>
          <InputField
            {...restFormikProps}
            markAsRequired
            label="Username"
            placeholder="Type username"
            value={sftpSettings.username}
            setFieldValue={handleFieldChange}
            propertyName={`${nameof<SfIntegrationConfigs>("sftpSettings")}.${nameof<SftpSettings>("username")}`}
          />
        </Form.Input>
        <Form.Input>
          <InputField
            {...restFormikProps}
            markAsRequired
            label="Password"
            inputType="password"
            placeholder="Type password"
            value={sftpSettings.password}
            setFieldValue={handleFieldChange}
            propertyName={`${nameof<SfIntegrationConfigs>("sftpSettings")}.${nameof<SftpSettings>("password")}`}
          />
        </Form.Input>
        <Form.Input>
          <InputField
            {...restFormikProps}
            label="Connector file location"
            placeholder="ex. folder1/folder2"
            value={sftpSettings.fileLocation}
            setFieldValue={handleFieldChange}
            propertyName={`${nameof<SfIntegrationConfigs>("sftpSettings")}.${nameof<SftpSettings>("fileLocation")}`}
          />
        </Form.Input>
      </Form.Block>
      <Form.Block
        title="TENANT ID"
        tooltip={{
          content: (
            <>
              Your SuccessFactors&nbsp;
              <a href={TENANT_ID_URl} target="_blank" rel="noopener noreferrer">
                Tenant ID
              </a>
              &nbsp;is needed to setup a unique data file configuration for this integration.
            </>
          ),
          width: "18.5rem",
        }}
      >
        <Form.Input>
          <InputField
            {...restFormikProps}
            markAsRequired
            label="ID"
            placeholder="ex. 12309586"
            value={tenantId}
            setFieldValue={handleFieldChange}
            propertyName={`${nameof<SfIntegrationConfigs>("tenantId")}`}
          />
        </Form.Input>
      </Form.Block>
      <Form.Block
        title="Custom Values"
        tooltip={{
          content: "Specify the custom values that should be used.",
        }}
      >
        <Form.Input>
          <InputField
            {...restFormikProps}
            label="Domain ID"
            tooltip={{ info: "Domain for the learning item (DMN_ID)." }}
            placeholder="PUBLIC"
            value={columnValues?.domain}
            setFieldValue={handleFieldChange}
            propertyName={columnValuesName("domain")}
          />
        </Form.Input>
      </Form.Block>
      <Form.Block title="Purchased Content" tooltip={{ content: "Push content contained within a pack." }}>
        <div>
          <span>Pack selection</span>
          <MultiSelect
            items={packs}
            handleMultiSelectChange={handleChangePacks}
            selected={isPacksLoading ? [] : packSettings.packIds}
            placeholder="Select pack(s) for more options"
            loading={isPacksLoading}
          />
        </div>
        {packSettings.packIds.length !== 0 && (
          <Form.SubBlock role="radiogroup">
            <Radio
              onChange={handlePackContentChange}
              label="Push all content (excludes communications)"
              checked={packSettings.contentType === PackContent.AllContent}
              value={PackContent.AllContent}
            />
            <Radio
              onChange={handlePackContentChange}
              label="Push only flows"
              checked={packSettings.contentType === PackContent.FlowsOnly}
              value={PackContent.FlowsOnly}
            />
          </Form.SubBlock>
        )}
      </Form.Block>

      <Form.Block
        title="Custom content"
        tooltip={{ content: "Push custom created content not contained within a pack." }}
      >
        <Radio
          onChange={handleCustomContentChange}
          label="None"
          checked={contentSettings.contentType === CustomContent.None}
          value={CustomContent.None}
        />
        <Radio
          onChange={handleCustomContentChange}
          label="Push all content (excludes communications)"
          checked={contentSettings.contentType === CustomContent.AllContent}
          value={CustomContent.AllContent}
        />
        <Radio
          onChange={handleCustomContentChange}
          label="Push only flows"
          checked={contentSettings.contentType === CustomContent.FlowsOnly}
          value={CustomContent.FlowsOnly}
        />
      </Form.Block>
      <Form.Block
        title="COMPLETION HISTORY"
        tooltip={{
          content: (
            <>
              Configure&nbsp;
              <a href={HISTORY_URL} target="_blank" rel="noopener noreferrer">
                Completion History
              </a>
              &nbsp; to allow SuccessFactors to show BrainStorm courses that have been completed.
            </>
          ),
          width: "16.7rem",
        }}
        before={
          <CheckboxField
            setFieldValue={setFieldValue}
            toggle
            value={isHistoryEnabled}
            propertyName={`${nameof<SfIntegrationConfigs>("isHistoryEnabled")}`}
          />
        }
      >
        {isHistoryEnabled && (
          <>
            <Form.Input>
              <InputField
                {...restFormikProps}
                markAsRequired
                label="Learning Administration URL"
                placeholder="https://domain.com"
                value={completionHistorySettings?.learningAdministrationUrl}
                setFieldValue={handleFieldChange}
                propertyName={historyName("learningAdministrationUrl")}
              />
            </Form.Input>
            <Form.Input>
              <InputField
                {...restFormikProps}
                markAsRequired
                label="Company ID"
                placeholder="Type ID here"
                value={completionHistorySettings?.companyId}
                setFieldValue={handleFieldChange}
                propertyName={historyName("companyId")}
              />
            </Form.Input>
            <Form.Input>
              <InputField
                {...restFormikProps}
                markAsRequired
                label="Client ID"
                placeholder="Type ID here"
                value={completionHistorySettings?.clientId}
                setFieldValue={handleFieldChange}
                propertyName={historyName("clientId")}
              />
            </Form.Input>
            <Form.Input>
              <InputField
                {...restFormikProps}
                markAsRequired
                label="Client Secret"
                placeholder="Type client secret here"
                value={completionHistorySettings?.clientSecret}
                setFieldValue={handleFieldChange}
                propertyName={historyName("clientSecret")}
              />
            </Form.Input>
            <Form.Input>
              <InputField
                {...restFormikProps}
                markAsRequired
                label="Admin User ID"
                placeholder="Type ID here"
                value={completionHistorySettings?.adminUserId}
                setFieldValue={handleFieldChange}
                propertyName={historyName("adminUserId")}
              />
            </Form.Input>
          </>
        )}
      </Form.Block>
    </Form.Root>
  );
};

const SFTP_SETTINGS_URL = `${environmentConfig.helpCenterUrl}/Enterprise/SuccessFactors.htm?Highlight=successfactors`;
const HISTORY_URL = `${environmentConfig.helpCenterUrl}/Enterprise/SuccessFactors.htm?Highlight=successfactors#Completion-History`;
const TENANT_ID_URl = `${environmentConfig.helpCenterUrl}/Enterprise/SuccessFactors.htm?Highlight=successfactors#SuccessFactors_TenantID`;

const packsName = (field: keyof PackSettings) => `${nameof<SfIntegrationConfigs>("packSettings")}.${field}`;

const historyName = (field: keyof CompletionHistorySettings) =>
  `${nameof<SfIntegrationConfigs>("completionHistorySettings")}.${field}`;

const columnValuesName = (field: keyof ColumnValues) => `${nameof<SfIntegrationConfigs>("columnValues")}.${field}`;
