import { type ISegment } from "../../../../interfaces";

export interface PageInfo {
  index: number;
  segment: ISegment;
}

export interface Pages {
  performance: PageInfo;
  configure: PageInfo;
  content: PageInfo;
  people: PageInfo;
}

export enum EditSurveySteps {
  Performance,
  Configuration,
  Settings,
  Content,
  People,
  AssociatedPacks,
}

export const showLastModified = new Set([EditSurveySteps.Configuration, EditSurveySteps.Content]);

export type EnumDictionary<T extends number, U> = {
  [K in T]: U;
};
