import { type FC } from "react";

import { ReactComponent as GroupIcon } from "../../images/group-icon.svg";
import { ImageFallbackPlaceholder } from "../imageFallbackPlaceholder/ImageFallbackPlaceholder";

import "./groupPicture.scss";

export interface GroupPictureProps {
  imageUrl?: string | null;
}

const GroupPicture: FC<GroupPictureProps> = ({ imageUrl }) => {
  const className = "avatar";
  const imageInfo = {
    className: className,
    alt: "avatar",
    src: imageUrl,
  };

  return (
    <div className="group-picture">
      <div className="picture">
        <ImageFallbackPlaceholder imageInfo={imageInfo}>
          <GroupIcon className={className} />
        </ImageFallbackPlaceholder>
      </div>
    </div>
  );
};

export default GroupPicture;
