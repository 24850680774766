import { type FC } from "react";
import { Table } from "semantic-ui-react";

import { type Group, type GroupOwner } from "../../../interfaces";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import GroupInfoCell from "../../groupInfoCell/GroupInfoCell";
import { Tooltip } from "../../common/tooltip";
import { getGroupsColumnOptions } from "./getGroupsColumnOptions";

export interface IGroupsTableBody {
  group: Group;
}

const getGroupOwners = (group: Group): string => {
  return group.owners && group.owners.map((x: GroupOwner) => `${x.firstName} ${x.lastName}`).join(", ");
};

const GroupsTableBody: FC<IGroupsTableBody> = ({ group }): React.ReactElement => {
  const columnOptions = getGroupsColumnOptions();
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <GroupInfoCell group={group} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Tooltip content={getGroupOwners(group)} target={<TextTruncate>{getGroupOwners(group)}</TextTruncate>} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>{group.membersCount}</Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(group.dateCreated)}</Table.Cell>
    </>
  );
};

export default GroupsTableBody;
