import React, { useEffect, useState } from "react";
import { DatePicker } from "../../components/datePicker";

export interface DatePickerProps {
  selected?: string | number | boolean | (string | number | boolean)[];
  disabled?: boolean;
  placeholder?: string;
  minDate?: string;
  maxDate?: string;
  format?: string;
  propertyName: string;
  onDateChange?: (event: React.SyntheticEvent<HTMLElement>, data: { name: string; value: string; }) => void;
}

const DatePickerWrapper: React.FC<DatePickerProps> = ({
  placeholder = "Select date",
  minDate,
  maxDate,
  disabled,
  selected,
  format,
  onDateChange,
}): React.ReactElement => {
  const [selectedDate, setSelectedDate] = useState<string>();

  useEffect(() => {
    setSelectedDate(selected as string);
  }, [selected]);

  const handleChange = (e: React.SyntheticEvent<HTMLElement>, data: { name: string; value: string; }) => {
    setSelectedDate(data.value);
    onDateChange?.(e, data);
  };

  return (
    <DatePicker
      closable
      name="date"
      placeholder={placeholder}
      value={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      iconPosition="left"
      closeOnMouseLeave={false}
      onChange={handleChange}
      disabled={disabled}
      format={format}
    />
  );
};

export default DatePickerWrapper;
