import { combineReducers } from "@reduxjs/toolkit";
import templatesFilterSlice from "./templatesFilterSlice";
import templatesSlice from "./templatesSlice";

const templatesReducer = combineReducers({
  filters: templatesFilterSlice,
  items: templatesSlice,
});

export default templatesReducer;
