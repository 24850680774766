import { type FC, useEffect, useState, useCallback } from "react";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import { ConfirmLicensingStep } from "../../commonSteps";
import { type GroupsAssignmentModalOneStepProps } from "./types";
import { Context } from "enums/licenseContext";

import "./groupsAssignmentModalOneStep.scss";

const GroupsAssignmentModalOneStep: FC<GroupsAssignmentModalOneStepProps> = (
  props: GroupsAssignmentModalOneStepProps,
) => {
  const [isDataValid, setIsDataValid] = useState(false);

  const { showModal, onCancel, onConfirm } = props;

  useEffect(() => {
    setIsDataValid(false);
  }, [showModal]);

  const renderModalActionsLicensingStep = useCallback(
    () => (closeModal: Function) => (
      <>
        <Button
          basic
          color="blue"
          className="cancel"
          content="Cancel"
          onClick={() => {
            onCancel();
            closeModal();
          }}
        />

        <Button
          primary
          className="confirm"
          content="Confirm"
          onClick={() => {
            onConfirm();
            closeModal();
          }}
          disabled={!isDataValid}
        />
      </>
    ),
    [isDataValid, onCancel, onConfirm],
  );

  return (
    <ModalWithSteps className={"groups-assignment-modal-one-step"} scrolling showModal={showModal} onCancel={onCancel}>
      <ConfirmLicensingStep
        header="License Confirmation"
        renderModalActions={renderModalActionsLicensingStep}
        info={{
          userIds: props.selectedUserIds,
          groupIds: props.selectedGroupIds,
        }}
        context={Context.AddPeopleToGroups}
        onIsDataValidChange={setIsDataValid}
      />
    </ModalWithSteps>
  );
};

export default GroupsAssignmentModalOneStep;
