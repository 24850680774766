import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import { Handle, Position } from "reactflow";
import { type FlowItemBase } from "../types";
import { useGuidesOnNode } from "../../../AlignmentSmartGuides/hooks/useGuidesOnNode";
import { EmitterEventTypes, type NodeEvent } from "../../emitter/ReactFlowEmitter";
import { useReplacementOnNode } from "../../../ItemReplacement/hooks/useReplacementOnNode";
import { ELEMENT_HEIGHT, SECTION_HEADER_HEIGHT } from "../../../constants";
import { useSectionHeaderOnNode } from "../../../SectionHeaders/hooks/useSectionHeaderOnNode";
import SectionHeaderAttached from "../../../SectionHeaders/SectionHeaderAttached/SectionHeaderAttached";
import { Popup } from "semantic-ui-react";
import { useReactFlowCanvasEventEmitter } from "../../hooks/useReactFlowCanvas";
import { useReactFlowCanvasState } from "../../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasState";
import { useReactFlowCanvasActions } from "../../Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";

interface IBaseNodeOwnProps {
  data: FlowItemBase;
  additionHandlers?: React.ReactElement[];
  children: React.ReactElement | React.ReactElement[];
  styles?: { [key: string]: string };
}

const BaseNode: React.FC<IBaseNodeOwnProps> = ({ data, additionHandlers, styles, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { removeNode, removeSectionHeader, setSectionHeaderView } = useReactFlowCanvasActions();
  const { reactFlowWrapper, headId, sectionHeaderView, isReadOnly } = useReactFlowCanvasState();
  const isStartOfTheFlow = headId === data.id;
  const isSourceHandleVisible = data.canConnect;
  const [isPopupOpen, setPopupOpen] = useState(false);

  const removeNodeHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    removeNode(data.id);
  };

  const renderWithRequiredPacksTooltip = () => {
    if (data && data.hasAccess === false) {
      const content = (
        <span className={"base-card-required-tooltip"}>
          <b>You don't have access to this content.</b>
          {data.requiredPacks && data.requiredPacks.length > 0 && (
            <>
              <br />
              Contact BrainStorm for access to the following packs(s):&nbsp;
              {data.requiredPacks.join(", ")}.
            </>
          )}
        </span>
      );

      return <Popup context={ref} content={content} position="right center" hoverable open={isPopupOpen} />;
    }
  };

  // events subscription logic
  const events = useReactFlowCanvasEventEmitter();
  const { replacementView } = useReplacementOnNode(data.id);
  const { sectionHeaderOverlay } = useSectionHeaderOnNode(data.id);
  const { cb: guidesCallback, helpers } = useGuidesOnNode(data.id);
  const cb = useCallback(
    (m: NodeEvent) => {
      if (m.type === EmitterEventTypes.AlignmentUpdate) {
        guidesCallback(m);
      }
    },
    [guidesCallback],
  );
  useEffect(() => {
    let unsubscribe: Function;
    if (events) {
      unsubscribe = events.onNodeEvent(data.id, cb);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [data.id, events, cb]);

  const topHandleStyle = data.sectionHeader ? { height: ELEMENT_HEIGHT + SECTION_HEADER_HEIGHT } : undefined;

  return (
    <>
      <div
        ref={ref}
        data-hook-type="flow-designer-canvas-card"
        data-hook-id={data.entityId}
        className={cn("react-flow__node-container", {
          connected: data.connected.target || data.connected.source,
          "section-header-attached": data.sectionHeader,
          "has-required-packs": !data.hasAccess,
        })}
        onMouseEnter={() => setPopupOpen(true)}
        onMouseLeave={() => setPopupOpen(false)}
      >
        {ReactDOM.createPortal(helpers, reactFlowWrapper?.current || document.body)}
        <Handle
          type="target"
          position={Position.Top}
          className={cn({ connected: data.connected.target })}
          style={topHandleStyle}
          datatype={`data-hook-target-${data.entityId}`}
          isConnectable={!isReadOnly}
        >
          <div className="react-flow__handle-top__placeholder" />
        </Handle>
        {data.sectionHeader ? (
          <SectionHeaderAttached
            id={data.id}
            {...data.sectionHeader}
            removeHandler={(id: string) => {
              removeSectionHeader(id);
              setSectionHeaderView(undefined);
            }}
            showRemoveButton={!isStartOfTheFlow}
            onSelectHandler={() => {
              setSectionHeaderView(data.id);
            }}
            isSelected={sectionHeaderView === data.id}
          />
        ) : null}
        <div className={cn("react-flow__node-card", styles?.card)}>
          <div className="content" onClick={() => setSectionHeaderView(undefined)} data-testid="flow-node-content">
            {sectionHeaderOverlay}
            {replacementView}
            {children}
          </div>
          <div data-testid="remove-icon" className="remove-asset-icon" onClick={removeNodeHandle} />
        </div>
        <>
          <Handle
            type="source"
            datatype={`data-hook-source-${data.entityId}`}
            position={Position.Bottom}
            className={cn({
              connected: data.connected.source && isSourceHandleVisible,
              visible: isSourceHandleVisible,
            })}
            isConnectable={!isReadOnly && isSourceHandleVisible}
          />
          {additionHandlers}
        </>
      </div>
      {renderWithRequiredPacksTooltip()}
    </>
  );
};

export default memo(BaseNode);
