import { Component } from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";
import { noop, stubFalse, stubArray } from "lodash";
import FormSectionHeader from "../../../../components/forms/FormSectionHeader";
import ExpressionBuilder from "../../../../components/expressionBuilder/ExpressionBuilder";
import { PermissionRow } from "./Permissions/PermissionRow";
import { v4 } from "uuid";
import "./assignPermissions.scss";

const getDefaultPermission = () => {
  return {
    id: v4(),
    area: null,
    feature: null,
    limitation: null,
    right: null,
    limitationItems: undefined,
  };
};

const limitationTypes = {
  none: 1,
  specificAccounts: 2,
  group: 4,
  childAccounts: 5,
  ownAccount: 6,
};

function isFormValid(configuredPermissions) {
  return configuredPermissions.every(isEntityFilled);
}

function isEntityFilled(item) {
  return Object.values(item).every((x) => {
    if (Array.isArray(x)) {
      return x.length > 0;
    }
    const isMultiSelectInput = x && Array.isArray(x.selected);
    if (isMultiSelectInput) {
      return x.selected.length > 0;
    }

    return x || x === 0 || x === undefined;
  });
}

export default class AssignPermissions extends Component {
  constructor(props) {
    super(props);

    this.limitationItemsDefinition = {
      [limitationTypes.specificAccounts]: {
        isLoading: () => this.props.isLoadingAvailableAccounts,
        items: () => this.props.availableAccounts,
        onFetch: () => !this.props.availableAccounts.length && this.props.onFetchAvailableAccounts(),
      },
      [limitationTypes.childAccounts]: {
        isLoading: stubFalse,
        items: stubArray,
        onFetch: noop,
      },
      [limitationTypes.ownAccount]: {
        isLoading: stubFalse,
        items: stubArray,
        onFetch: noop,
      },
      [limitationTypes.group]: {
        isLoading: () => this.props.isLoadingAvailableGroups,
        items: () => this.props.availableGroups,
        onFetch: () => !this.props.availableGroups.length && this.props.onFetchAvailableGroups(),
      },
      [limitationTypes.none]: {
        isLoading: stubFalse,
        items: stubArray,
        onFetch: noop,
      },
    };
  }

  componentDidMount() {
    if (this.props.configuredPermissions.length === 0) {
      this.raisePermissionsChange([getDefaultPermission()]);
    }
  }

  raisePermissionsChange = (permissions) => {
    this.props.onPermissionsChanged(permissions);
    this.props.onIsValidChange(isFormValid(permissions));
  };

  anyAreaAvailable = () => {
    return this.props.permissions.some((item) => !!item.features.length);
  };

  renderBadge = () => {
    const filledPermissionsCount = this.props.configuredPermissions.filter(isEntityFilled).length;

    return filledPermissionsCount > 0 ? (
      <Label circular color="blue" className="badge">
        {filledPermissionsCount}
      </Label>
    ) : null;
  };

  renderRow = (index) => {
    const { configuredPermissions, permissions, isReadOnly, initialPermissions } = this.props;
    const rowObject = configuredPermissions[index];
    return (
      <PermissionRow
        availablePermissions={permissions}
        configuredPermissions={configuredPermissions}
        row={rowObject}
        index={index}
        isReadOnly={isReadOnly}
        limitationProvider={(id) => this.limitationItemsDefinition[id]}
        onPermissionsChange={this.raisePermissionsChange}
        initialPermissions={initialPermissions?.[rowObject.id]}
        getDefaultPermission={getDefaultPermission}
      />
    );
  };

  render() {
    const { configuredPermissions, isReadOnly, renderTitleWithBadge } = this.props;
    return (
      <div className="assign-permission">
        {renderTitleWithBadge && (
          <div className="title-container">
            <FormSectionHeader title="Add permissions" />
            {this.renderBadge()}
          </div>
        )}
        <ExpressionBuilder
          classNameButtons={this.props.classNameButtons}
          items={configuredPermissions}
          columnTitles={[
            { title: "Area", fieldName: "area" },
            { title: "Feature", fieldName: "feature" },
            { title: "Rights", fieldName: "right" },
            {
              title: "Limitation Type",
              fieldName: "limitation",
              info: "The type of limitation by which you want to restrict access",
            },
            {
              title: "Limited To",
              fieldName: "limitationItems",
              info: "Limit access based on these specific criteria",
            },
          ]}
          columnsAmount={5}
          onChange={this.raisePermissionsChange}
          getDefaultNewItem={getDefaultPermission}
          isReadOnly={isReadOnly}
          canAdd={isFormValid(configuredPermissions) && this.anyAreaAvailable()}
        >
          {({ index }) => this.renderRow(index)}
        </ExpressionBuilder>
      </div>
    );
  }
}

AssignPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
  availableAccounts: PropTypes.array.isRequired,
  isLoadingAvailableAccounts: PropTypes.bool.isRequired,
  availableGroups: PropTypes.array.isRequired,
  isLoadingAvailableGroups: PropTypes.bool.isRequired,
  configuredPermissions: PropTypes.array.isRequired,
  onPermissionsChanged: PropTypes.func,
  onIsValidChange: PropTypes.func,
  onFetchAvailableAccounts: PropTypes.func,
  onFetchAvailableGroups: PropTypes.func,
  isReadOnly: PropTypes.bool,
  renderTitleWithBadge: PropTypes.bool,
  classNameButtons: PropTypes.string,
};
