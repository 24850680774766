import { once } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";

import { TemplateTypes } from "../../../../enums";
import { type AppDispatch } from "../../../../features/Application/globaltypes/redux";
import { getNotifyConfig } from "../../../../features/SystemNotifications/helpers";
import useNotifyConfig from "../../../../features/SystemNotifications/hooks/useNotifyConfig";
import { resetState } from "../../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { NotifyStepConfig } from "../../../../features/SystemNotifications/types";
import { bindAction, type NotifyStepSettings } from "../../../../interfaces";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import { SwitchEntityTypes } from "enums/switchEntityTypes";
import { ConfirmLicensingStep, PacksContextStep } from "../../commonSteps";
import NotifyStep from "../../commonSteps/notifyStep/NotifyStep";
import {
  type ModalActionConfig,
  type RenderActions,
  type RenderModalActionsType,
  type StepsOptions,
} from "../../types";
import { renderSteps } from "../../utils/renderSteps";
import { renderModalActionsFirstStep, renderModalActionsMiddleStep } from "../utils/contentAssignmentModalActions";
import { type ContentAssignmentModalTwoStepsProps } from "./types";
import { ButtonGroup } from "../../../buttonGroup";
import { tabs } from "components/notifyStep/NotifyStepSwitch/constants";

import "./contentAssignmentModalTwoSteps.scss";

export type ContentAssignmentModalTwoStepsPropsWithRedux = ContentAssignmentModalTwoStepsProps & PropsFromRedux;

export const ContentAssignmentModalTwoSteps = ({
  showModal,
  onCancel,
  onConfirm,
  skipLicensing,
  skipNotifyStep,
  notifyStepDisabled,
  packContextItems,
  selectedUserIds,
  selectedGroupIds,
  resetNotificationState,
  notifyTemplateType,
  notifyConfigType,
  entityType,
  alert,
  flowId,
  skipPacksContext,
}: ContentAssignmentModalTwoStepsPropsWithRedux) => {
  const [isDataValid, setIsDataValid] = useState(false);
  const [isNotifyDataValid, setIsNotifyDataValid] = useState(false);
  const [skipPacksContextStep, setSkipPacksContextStep] = useState(!!skipPacksContext);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();
  const { config, NotifyConfigSwitch } = getNotifyConfig(notifyConfigType || NotifyStepConfig.Default);
  const [notifyConfig, { setNotifyConfig, resetNotifyConfig, shouldNotify, communicationChannel, notifyTypes }] =
    useNotifyConfig<any>(config);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const onPreviousNotifyStepRef = useRef<() => Promise<void>>();
  const getNotifySettingsRef = useRef<() => Promise<NotifyStepSettings | undefined>>();

  useEffect(() => {
    setIsDataLoaded(false);
    setIsDataValid(false);
    setIsNotifyDataValid(false);
    setSkipPacksContextStep(false);
  }, [showModal]);

  useEffect(() => {
    !showModal && resetNotifyConfig();
  }, [showModal, resetNotifyConfig]);

  const onResetNotifyStep = () => {
    resetNotificationState();
  };

  const renderModalActionsLastStep =
    ({ isOneStepInConfig, previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => (
      <>
        {isOneStepInConfig ? (
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
        ) : (
          <Button
            primary
            className="previous"
            content="Previous"
            onClick={async () => {
              await previous?.onClick?.();
              prevStep();
            }}
          />
        )}
        <Button
          primary
          className="confirm"
          content="Finish"
          onClick={once(async () => {
            const onClickResult = await confirm?.onClick?.();
            onConfirm({ notificationSettings: onClickResult, notifyTypes });
            closeModal();
          })}
          disabled={confirm?.disabled}
        />
      </>
    );

  const renderPacksContextStep = (renderModalActions: RenderModalActionsType) => (
    <PacksContextStep
      key={"Licensing"}
      header={packsContextStepHeader}
      renderModalActions={renderModalActions}
      info={packContextItems}
      onIsDataValidChange={setIsDataValid}
      noDataLoaded={() => setSkipPacksContextStep(true)}
      setModalStepHeader={() => setPacksContextStepHeader("Licensing")}
    />
  );

  const renderConfirmLicensingStep = (renderModalActions: RenderModalActionsType) => (
    <ConfirmLicensingStep
      key={"License confirmation"}
      header="License Confirmation"
      renderModalActions={renderModalActions}
      info={{
        userIds: selectedUserIds,
        groupIds: selectedGroupIds,
        flowId: flowId,
      }}
      onIsDataValidChange={setIsDataValid}
    />
  );

  const renderNotifyStep = (renderModalActions: RenderModalActionsType) => (
    <NotifyStep
      preRender
      key={"Notify"}
      header="Notify"
      renderModalActions={renderModalActions}
      onIsDataValidChange={setIsNotifyDataValid}
      onIsDataLoaded={setIsDataLoaded}
      isDataLoaded={isDataLoaded}
      onPreviousNotifyStepRef={onPreviousNotifyStepRef}
      getNotifySettingsRef={getNotifySettingsRef}
      notifyStepDisabled={notifyStepDisabled}
      notifyTemplateType={notifyTemplateType || TemplateTypes.Empty}
      renderSwitch={(switchProps) => (
        <div>
          <NotifyConfigSwitch
            config={notifyConfig}
            onNotifyConfigChange={setNotifyConfig}
            entityType={entityType || SwitchEntityTypes.Empty}
            {...switchProps}
          />
          {notifyConfigType === NotifyStepConfig.WithProgress && (
            <ButtonGroup
              items={tabs}
              defaultValue={tabs[communicationChannel]}
              isDisabled={
                !notifyConfig.shouldNotifyNotStarted &&
                !notifyConfig.shouldNotifyInProgress &&
                !notifyConfig.shouldNotifyCompleted
              }
              onChange={(value) =>
                setNotifyConfig({
                  key: "communicationChannel",
                  value: tabs.findIndex((name) => name === value),
                })
              }
            />
          )}
        </div>
      )}
      shouldNotify={shouldNotify}
      communicationChannel={communicationChannel}
    />
  );

  const notifyStepNotReady = !(isNotifyDataValid && isDataLoaded);
  const needNotification = !notifyStepDisabled && shouldNotify;

  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderPacksContextStep,
      skipStep: skipPacksContextStep,
      modalActionConfig: {
        previous: {
          onClick: () => onCancel(),
        },
        next: {
          disabled: !isDataValid,
          onClick: () => setIsDataValid(false),
        },
        confirm: {
          disabled: !isDataValid,
        },
        isOneStepInConfig: skipLicensing && skipNotifyStep,
      },
    },
    {
      renderStep: renderConfirmLicensingStep,
      skipStep: skipLicensing,
      modalActionConfig: {
        previous: {
          onClick: () => skipPacksContextStep && onCancel(),
        },
        next: {
          disabled: !isDataValid,
        },
        confirm: {
          disabled: !isDataValid,
        },
        isOneStepInConfig: skipPacksContextStep && skipNotifyStep,
      },
    },
    {
      renderStep: renderNotifyStep,
      skipStep: skipNotifyStep,
      modalActionConfig: {
        previous: {
          onClick: () => onPreviousNotifyStepRef?.current?.(),
        },
        confirm: {
          disabled: needNotification && notifyStepNotReady,
          onClick: () => getNotifySettingsRef?.current?.(),
        },
        isOneStepInConfig: skipPacksContextStep && skipLicensing,
      },
    },
  ];

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsLastStep,
    renderModalActionsMiddleStep,
  };

  if (!showModal) {
    // return null in order to not trigger renderSteps that is causing licensing state populating after Confirm
    return null;
  }

  return (
    <ModalWithSteps
      className={"flow-content-assignment-modal"}
      scrolling
      showModal={showModal}
      onCancel={onCancel}
      onBeforeClose={onResetNotifyStep}
      alert={alert}
    >
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetNotificationState: bindAction(resetState, dispatch),
});

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ContentAssignmentModalTwoSteps);
export default ConnectedComponent;
