import moboConfigurator from "moboConfigurator";
import { useMemo } from "react";
import { Link, type LinkProps } from "react-router-dom";

type MoboLinkProps = {
  accountId?: number;
} & LinkProps;

export const MoboLink = ({ to, children, accountId, ...props }: MoboLinkProps) => {
  const moboId = useMemo(() => moboConfigurator.getMoboId(), []);
  const id = accountId ?? moboId;
  const hash = id ? `#moboId=${accountId}` : undefined;

  return (
    <Link to={{ pathname: to as string, hash }} {...props}>
      {children}
    </Link>
  );
};
