import { connect } from "react-redux";

import AddTagsModal from "../../../../../components/addTagsModal/AddTagsModal";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { addTags } from "../state/thunks/emailOverviewThunk";

const getTags = (state: RootState) => state.library.emails.emailDetailsReducer.tags;

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    options: getTags(state)?.Label?.map((item) => ({ text: item.title, value: item.title })) || [],
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onConfirmAction: bindAction(addTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagsModal);
