import { type AssessmentUser } from "../../types/state";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface AssessmentUsersState extends SelectedItemsState<AssessmentUser> {}

const initialState: AssessmentUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const assessmentUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Assessments, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  assessmentUsersSlice.actions;

export default assessmentUsersSlice.reducer;
export type assessmentUsersSliceStateType = ReturnType<typeof assessmentUsersSlice.reducer>;
