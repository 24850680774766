import { PropTypes } from "prop-types";
import React, { Component } from "react";

import "./groupUsers.scss";

import { ValidatedForm } from "../../../../../components/forms";
import validationSchemas from "../../../../../utils/validationSchemas";
import AutomaticallyDetect from "../../AddEditGroupForms/AutomaticallyDetect/AutomaticallyDetect";
import ManuallySelect from "./ManuallySelect/ManuallySelect";

export default class GroupUsers extends Component {
  getManualSelection() {
    const {
      manualSelectionUsersOptions,
      loadManualSelectionUsers,
      setUserFilter,
      resetUsersFilter,
      groupUsers,
      updateSelectedItems,
      usersFilterOptions,
      usersAppliedFilter,
      getFilterOptions,
      search,
      onSearchChanged,
      setReloadListItems,
    } = this.props;

    return (
      <ManuallySelect
        usersList={manualSelectionUsersOptions}
        loadUsers={loadManualSelectionUsers}
        setUserFilter={setUserFilter}
        resetUserFilter={resetUsersFilter}
        updateSelectedItems={updateSelectedItems}
        selectedItemIds={groupUsers.userIds}
        usersFilterOptions={usersFilterOptions}
        usersAppliedFilter={usersAppliedFilter}
        getFilterOptions={getFilterOptions}
        setReloadListItems={setReloadListItems}
        search={search}
        onSearchChanged={onSearchChanged}
      />
    );
  }

  getAutomaticSelection() {
    const {
      enrollmentUsersOptions,
      setAutomaticEnrollmentGridFilter,
      resetAutomaticEnrollmentGridFilter,
      loadEnrollmentUsers,
      onIsValidChange,
      bindGetDraftEnrollmentRulesValues,
      isGroupSaved,
      resetEnrollmentUsers,
      fetchGroupEnrollmentUsers,
      groupEnrollmentUsers,
      rulesEngineOnCosmos,
    } = this.props;

    return (
      <ValidatedForm
        onSubmit={this.props.onSubmit}
        bindGetValues={bindGetDraftEnrollmentRulesValues}
        bindSubmitForm={this.props.bindSubmitForm}
        isInitialValid={this.props.isInitialValid}
        initialValues={{
          enrollmentRules: this.props.groupUsers.enrollmentRules,
        }}
        validationSchema={validationSchemas.automaticEnrollment}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this group? All information entered will be lost.",
        }}
        formWidthComputer={16}
      >
        {
          /* instanbul ignore next */
          (formProps) => {
            const { values, validatedFieldProps } = formProps;
            return (
              <AutomaticallyDetect
                onIsValidChange={onIsValidChange}
                values={values}
                validatedFieldProps={validatedFieldProps}
                users={rulesEngineOnCosmos ? groupEnrollmentUsers.items : enrollmentUsersOptions.items}
                disabled={isGroupSaved}
                usersCount={rulesEngineOnCosmos ? groupEnrollmentUsers.itemsCount : enrollmentUsersOptions.itemsCount}
                isLoading={rulesEngineOnCosmos ? groupEnrollmentUsers.isLoading : enrollmentUsersOptions.isLoading}
                loadUsers={loadEnrollmentUsers}
                gridFilter={enrollmentUsersOptions.appliedFilter || {}}
                setGridFilter={setAutomaticEnrollmentGridFilter}
                resetGridFilter={resetAutomaticEnrollmentGridFilter}
                resetEnrollmentUsers={resetEnrollmentUsers}
                fetchGroupEnrollmentUsers={fetchGroupEnrollmentUsers}
                rulesEngineOnCosmos={rulesEngineOnCosmos}
              />
            );
          }
        }
      </ValidatedForm>
    );
  }

  render() {
    const { isWithAutoEnroll } = this.props;
    return (
      <section className="group-users">
        <div className="stretch">{isWithAutoEnroll ? this.getAutomaticSelection() : this.getManualSelection()}</div>
      </section>
    );
  }
}

GroupUsers.propTypes = {
  groupUsers: PropTypes.shape({
    enrollmentRules: PropTypes.array,
    userIds: PropTypes.array,
  }).isRequired,
  enrollmentUsersOptions: PropTypes.shape({
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    appliedFilter: PropTypes.object,
  }).isRequired,
  loadEnrollmentUsers: PropTypes.func.isRequired,
  resetEnrollmentUsers: PropTypes.func.isRequired,
  setAutomaticEnrollmentGridFilter: PropTypes.func.isRequired,
  resetAutomaticEnrollmentGridFilter: PropTypes.func.isRequired,

  manualSelectionUsersOptions: PropTypes.shape({
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    appliedFilter: PropTypes.object,
  }).isRequired,
  loadManualSelectionUsers: PropTypes.func.isRequired,

  setUserFilter: PropTypes.func.isRequired,
  resetUsersFilter: PropTypes.func.isRequired,
  isWithAutoEnroll: PropTypes.bool.isRequired,
  bindSubmitForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onIsValidChange: PropTypes.func,
  updateSelectedItems: PropTypes.func,
  isGroupSaved: PropTypes.bool,
  search: PropTypes.string,
  onSearchChanged: PropTypes.func,
  setReloadListItems: PropTypes.func,
  isInitialValid: PropTypes.bool,
  fetchGroupEnrollmentUsers: PropTypes.func,
  groupEnrollmentUsers: PropTypes.shape({
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  rulesEngineOnCosmos: PropTypes.bool,
};
