import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import PropTypes from "prop-types";

function UnpublishLinkButton(props) {
  const { onClick, isDisabled } = props;

  return (
    <BasicLinkButton text="Unpublish" iconClassName="fal fa-eye-slash" onClick={onClick} isDisabled={isDisabled} />
  );
}

UnpublishLinkButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default UnpublishLinkButton;
