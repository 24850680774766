import { type FC, useEffect, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import isEmpty from "lodash/isEmpty";

import AssessmentsNoResults from "../../../../views/library/assessments/Overview/AssessmentsNoResults";
import SearchInput from "../../../searchInput/SearchInput";
import AssignmentList from "../AssignmentList";

import { type AppDispatch, type RootState } from "../../../../features/Application/globaltypes/redux";
import { AddPeopleToContentTypes, SortingDirection } from "../../../../enums";
import { type GenericFiltersMap } from "../../../../utils/filterUtils";
import { type AssignmentListFiltersEnum, type AssignmentListPropsBase } from "../../types";
import { Columns, ColumnToParamMap, getColumnOptions } from "../assetColumnOptions/assetColumnOptions";
import { AssessmentFilterForm } from "../../../filterForms";
import { ContentType } from "../../../../features/Library/PeopleAssignments/types";
import { type AssignmentModelItemBase } from "../../../../features/People/types";
import { updateSelectedContentItems } from "../../utils/contentAssignmentUtils";
import { sortOptionsListView } from "../../ContentAssignmentConstants";
import {
  resetAppliedFilter,
  setAppliedFilter,
} from "../../../../features/People/ContentAssignmentsOverview/slices/contentAssignmentsOverviewFiltersSlice";
import { bindAction } from "../../../../interfaces";
import { fetchContentAssignmentsOverviewFilterOptions } from "../../../../features/People/ContentAssignmentsOverview/thunks/contentAssignmentsOverviewFiltersThunk";

interface AssessmentListOwn extends AssignmentListPropsBase {
  fetchAssessments: (
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    term?: string,
    appliedFilter?: GenericFiltersMap<AssignmentListFiltersEnum>,
  ) => void;
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  deepLink?: boolean;
}

export type AssessmentListProps = AssessmentListOwn & PropsFromRedux;

export const AssessmentList: FC<AssessmentListProps> = ({
  fetchAssessments,
  createButton,
  resetList,
  onSelectedChanged,
  getFilterOptionsAction,
  resetFilter,
  isReadOnly,
  showExpirationDate,
  disablePopupMenu,
  deepLink,
  ...rest
}) => {
  const reloadAssessmentListItems = useRef<(condition: boolean) => void>();

  const [search, setSearch] = useState<string>("");

  const getAssessments = (
    skip: number = 0,
    top: number = 30,
    sortingColumnName: string = Columns.Modified.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
    appliedFilter?: GenericFiltersMap<AssignmentListFiltersEnum>,
  ): void => {
    const sortColumn = ColumnToParamMap[sortingColumnName as Lowercase<Columns>];
    fetchAssessments(skip, top, sortColumn, sortingDirection, search, appliedFilter);
  };

  const onSelectedAssessmentListChanged = (ids: number[]) => {
    updateSelectedItems(rest.items, ids);
  };

  const updateSelectedItems = (collection: AssignmentModelItemBase[], ids: number[]) => {
    const updatedSelectedAssessments = updateSelectedContentItems(collection, ids);
    onSelectedChanged?.(updatedSelectedAssessments);
  };

  // @ts-ignore
  const getFilterForm = () => <AssessmentFilterForm />;

  const onSearchChange = (newSearchText: string) => {
    onSelectedAssessmentListChanged([]);
    setSearch(newSearchText);
    refetchItemsFromStart(isEmpty(newSearchText));
  };

  const getSearchBar = (accessRestricted: boolean) => (
    <SearchInput
      placeholder="Search for Assessments..."
      disabled={accessRestricted}
      onChange={onSearchChange}
      defaultValue={search}
    />
  );

  const refetchItemsFromStart = (enableSorting: boolean) => {
    reloadAssessmentListItems.current?.(enableSorting);
  };

  const createReloadListItems = (reloadListItems: (condition: boolean) => void) => {
    reloadAssessmentListItems.current = reloadListItems;
  };

  const getFilterOptions = () => {
    getFilterOptionsAction(AddPeopleToContentTypes.Assessments);
  };

  useEffect(() => {
    return () => resetFilter();
  }, [resetFilter]);

  return (
    <AssignmentList
      {...rest}
      resetList={resetList}
      onSelectedChanged={onSelectedChanged}
      setReloadListItems={createReloadListItems}
      fetchData={getAssessments}
      columnOptions={getColumnOptions({
        assetType: "assessments",
        peopleType: rest.peopleType,
        contentType: ContentType.Assessment,
        selectedIds: rest.selectedIds,
        buttonHandlers: {},
        isPriorityHide: true,
        isReadOnly: isReadOnly,
        isExpiresHide: true,
        isAddedHide: true,
        disablePopupMenu: disablePopupMenu,
      })}
      resetFilter={resetFilter}
      getFilterOptions={getFilterOptions}
      getFilterForm={getFilterForm}
      getSearchBar={getSearchBar}
      noResultsContent={
        <AssessmentsNoResults filtered={!isEmpty(rest.appliedFilter) || !!search} createButton={createButton} />
      }
      defaultSortingColumnName={Columns.Modified}
      sortingDirection={SortingDirection.Descending}
      sortOptions={sortOptionsListView}
      isReadOnly
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.contentAssignment.assessmentList.items,
    isLoading: state.people.contentAssignment.assessmentList.isLoading,
    dataCount: state.people.contentAssignment.assessmentList.itemsCount,
    filterOptions: state.people.contentAssignmentsOverview.filters.filterOptions,
    filterOptionsLoading: state.people.contentAssignmentsOverview.filters.isLoading,
    appliedFilter: state.people.contentAssignmentsOverview.filters.appliedFilter,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    getFilterOptionsAction: bindAction(fetchContentAssignmentsOverviewFilterOptions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AssessmentList);
export default ConnectedComponent;
