import * as editRoleActionTypes from "./editRoleActionTypes";
import dataService from "../services/rolesDataService";
import { makeActionCreator, makeFetchActionCreatorForItems } from "../../Application/actions/actionsBuilder";

export const fetchRoleInfo = (id) => {
  const fetchRoleInfoBegin = makeActionCreator(editRoleActionTypes.FETCH_ROLE_INFO_BEGIN);

  const fetchRoleInfoSuccess = makeActionCreator(editRoleActionTypes.FETCH_ROLE_INFO_SUCCESS, "info");

  const fetchRoleInfoFailure = makeActionCreator(editRoleActionTypes.FETCH_ROLE_INFO_FAILURE, "error");

  return async (dispatch) => {
    dispatch(fetchRoleInfoBegin());
    try {
      const role = await dataService.getRoleInfo(id);
      dispatch(fetchRoleInfoSuccess(role));
    } catch (error) {
      dispatch(fetchRoleInfoFailure(error.message));
    }
  };
};

export const fetchRoleUsers = (roleId, skip, top, orderByParams, queryParams) =>
  makeFetchActionCreatorForItems(
    () => dataService.getRoleUsers(roleId, skip, top, orderByParams, queryParams),
    editRoleActionTypes.fetchRoleUsers,
  );

export const updateRoleInfo = (roleId, name, description) => {
  const updateRole = makeActionCreator(editRoleActionTypes.UPDATE_ROLE_INFO, "name", "description");

  return async (dispatch) => {
    const data = await dataService.updateRoleInfo(roleId, name, description);
    dispatch(updateRole(name, description));

    return data;
  };
};

export const updateRolePermissions = (roleId, permissions) => {
  const updateRole = makeActionCreator(editRoleActionTypes.UPDATE_ROLE_PERMISSIONS, "permissions");

  return async (dispatch) => {
    const data = await dataService.updateRolePermissions(roleId, permissions);
    const statePermissions = permissions.map((i) => {
      const { limitationItems, ...permission } = i;
      permission.limitationItemIds = limitationItems;
      return permission;
    });
    dispatch(updateRole(statePermissions));

    return data;
  };
};

export const updateUpdatedOnRoleUpdate = (roleId, updated) => {
  const updateRoleSuccess = makeActionCreator(editRoleActionTypes.UPDATE_ROLE_UPDATED, "updated");

  return async (dispatch, getState) => {
    const editRoleId = getState().people.editRole.roleInfo.data.id;
    if (editRoleId === roleId) {
      dispatch(updateRoleSuccess(new Date(updated)));
    }
  };
};

export const updateUsersOnRoleUnassign = (unassignedUsersRoles) => {
  const updateUsersList = makeActionCreator(editRoleActionTypes.fetchRoleUsers.UPDATE_ITEMS, "items", "itemsCount");

  return (dispatch, getState) => {
    const editRole = getState().people.editRole;
    const usersList = editRole.usersList.items;
    const roleId = editRole.roleInfo.data.id;
    const usersToDelete = [];
    for (const userRoles of unassignedUsersRoles) {
      if (userRoles.unassignedRoleIds.includes(roleId)) {
        usersToDelete.push(userRoles.userId);
      }
    }

    if (usersToDelete.length !== 0) {
      const newUsersList = usersList.filter((user) => !usersToDelete.includes(user.id));
      dispatch(updateUsersList(newUsersList, newUsersList.length));
    }
  };
};
