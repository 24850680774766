import { Component } from "react";
import cn from "classnames";
import _, { noop, range } from "lodash";

import Section from "./Section";
import wizardPropTypes from "./wizardPropTypes";
import WizardFooter from "./WizardFooter";
import WizardHeader from "./WizardHeader";
import WizardSubHeader from "./WizardSubHeader";
import WizardStep from "./WizardStep";
import "./wizard.scss";

const getAvailableStepIndexes = (children, activeIndex) => {
  const activeStep = children[activeIndex];
  const availableIndexes = range(activeIndex + 1);

  if ((activeStep.props.required && activeStep.props.isLocked) || activeStep.props.isLockNavigation) {
    return availableIndexes;
  }

  const nextRequiredInvalidStepIndex = children.findIndex(
    (x, i) => i > activeIndex && x.props.isLocked && x.props.required,
  );

  const lastAvailableStepIndex =
    nextRequiredInvalidStepIndex !== -1 ? nextRequiredInvalidStepIndex : children.length - 1;
  return [...availableIndexes, ...range(activeIndex + 1, lastAvailableStepIndex + 1)];
};

// IMPORTANT - use WizardWrapper container instead of this Wizard component - it will provide
// additional functionallity: collapse/expand navigation bar when wizard is opened/closed
class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.deferredQueue = {};
    this.onRegressAsync = props.onRegressAsync || (() => Promise.resolve());
    this.canProceedAsync = props.canProceedAsync || (() => Promise.resolve(true));
    this.canRecedeAsync = props.canRecedeAsync || (() => Promise.resolve(true));
    this.onFinishAsync = props.onFinishAsync || (() => Promise.resolve());
  }

  componentDidUpdate(prevProps) {
    const { isSaveInProgress } = this.props;
    if (prevProps.isSaveInProgress && !isSaveInProgress) {
      const methodsNames = Object.keys(this.deferredQueue);
      methodsNames.forEach((methodName) => {
        this.deferredQueue[methodName]();
      });
      this.deferredQueue = {};
    }
  }

  async onProgressAsync(activeIndex, nextIndex) {
    if (this.props.onProgressAsync) {
      const errors = await this.props.onProgressAsync(activeIndex, nextIndex);
      return !(errors && errors.length > 0);
    }

    return true;
  }

  closeHandler = () => {
    const { isSaveInProgress, onCancel } = this.props;
    if (isSaveInProgress) {
      this.deferredQueue = { ...this.deferredQueue, onCancel };
    } else {
      onCancel();
    }
  };

  componentDidMount() {
    this.props.wizardActions && this.props.wizardActions.openWizard();
  }

  componentWillUnmount() {
    this.props.wizardActions && this.props.wizardActions.closeWizard();
  }

  onStepSelected = async (nextIndex) => {
    const { activeIndex } = this.state;
    if (activeIndex === nextIndex) {
      return;
    }

    if (nextIndex > activeIndex) {
      if (!(await this.canProceedAsync(activeIndex, nextIndex))) {
        return;
      }

      const succeed = await this.onProgressAsync(activeIndex, nextIndex);
      if (!succeed) {
        return;
      }
      this.props.onProgress?.(activeIndex, nextIndex);
    }

    if (nextIndex < activeIndex) {
      if (!(await this.canRecedeAsync(activeIndex, nextIndex))) {
        return;
      }

      this.props.onRegress?.(activeIndex, nextIndex);
      await this.onRegressAsync(activeIndex, nextIndex);
    }

    this.setState({
      activeIndex: nextIndex,
    });
  };

  onNext = async () => {
    const { activeIndex } = this.state;
    const nextIndex = this.state.activeIndex + 1;

    const canProceed = await this.canProceedAsync(activeIndex, nextIndex);
    if (canProceed) {
      const succeed = await this.onProgressAsync(activeIndex, nextIndex);
      if (succeed) {
        this.props.onProgress?.(activeIndex, nextIndex);
        this.setState({
          activeIndex: nextIndex,
        });
      }
    }
  };

  onPrev = async () => {
    const { activeIndex } = this.state;
    const newIndex = this.state.activeIndex - 1;

    const canRecede = await this.canRecedeAsync(activeIndex, newIndex);
    if (canRecede) {
      this.props.onRegress?.(activeIndex, newIndex);
      await this.onRegressAsync(activeIndex, newIndex);
      this.setState({
        activeIndex: newIndex,
      });
    }
  };

  onFinish = async () => {
    this.props.onFinish?.();
    await this.onFinishAsync();
  };

  onChangeActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  render() {
    const {
      id,
      title,
      titleForGA,
      publishedStatus,
      progressSavedDate,
      isSaveInProgress,
      className,
      finishButtonLabel,
      isFinishButtonDisabled,
      renderCustomFinishButton,
      onPreviewFinishAsync,
      renderCustomHeader,
      isPreviousDisabled,
      finishTooltipMessage,
    } = this.props;
    const { activeIndex } = this.state;
    const children = _.compact(this.props.children);
    const availableStepIndexes = getAvailableStepIndexes(children, activeIndex);
    const activeStep = children[activeIndex];

    return (
      <div id={id} className={cn("main-content wizard", `active-step-${activeIndex}`, className)}>
        <WizardHeader
          title={title}
          titleForGA={titleForGA}
          publishedStatus={publishedStatus}
          onClose={this.closeHandler}
          changeActiveIndex={this.onChangeActiveIndex}
          renderCustomHeader={renderCustomHeader}
        />
        <WizardSubHeader
          progressSavedDate={progressSavedDate}
          isSaveInProgress={isSaveInProgress}
          children={children}
          activeIndex={activeIndex}
          onStepSelected={this.onStepSelected}
          availableStepIndexes={availableStepIndexes}
        />
        <WizardStep steps={children} activeStepIndex={activeIndex} />
        <WizardFooter
          isNavigationLocked={activeStep.props.isLocked}
          activeIndex={activeIndex}
          stepsCount={children.length}
          onNext={this.onNext}
          onPrev={this.onPrev}
          onFinish={this.onFinish}
          onPreviewFinishAsync={onPreviewFinishAsync}
          finishButtonLabel={finishButtonLabel}
          isFinishButtonDisabled={isFinishButtonDisabled}
          renderCustomFinishButton={renderCustomFinishButton}
          nextButtonLabel={activeStep.props.nextButtonLabel}
          isPreviousDisabled={isPreviousDisabled}
          finishTooltipMessage={finishTooltipMessage}
        />
      </div>
    );
  }

  static Step = Section;
}

Wizard.propTypes = wizardPropTypes;

Wizard.defaultProps = {
  isFinishButtonDisabled: false,
  isPreviousDisabled: false,
  renderCustomHeader: noop,
};

export default Wizard;
