import { type IWizardStepsManager, type WizardStepHandlers, type WizardStepHandlersMap } from "../interfaces/IWizardStepsManager";
import { type PropertyChangedEvent } from "../interfaces/functionTypes/PropertyChangedEvent";
import Observable from "./Observable";

export default class WizardStepsManager implements IWizardStepsManager {
  private stepsHandlers: WizardStepHandlersMap = {};
  private activeStepIndex: number = -1;
  private activeStepIndexChangedObservable = new Observable<PropertyChangedEvent<number>>();
  private historyRef: number[] = [];

  acceptHandlers = (handlers: WizardStepHandlers, wizardStepIndex: number) => {
    this.stepsHandlers[wizardStepIndex] = handlers;

    if (this.activeStepIndex === -1) {
      this.historyRef.push(wizardStepIndex);
      this.updateActiveStepIndexAndNotify(wizardStepIndex);
    }
  };

  // Handles when the user uses the back button
  goBack = () => {
    if (this.historyRef.length > 1) {
      this.historyRef.pop();
      const previousPage = this.historyRef[this.historyRef.length - 1];
      this.goToPage(previousPage, false);
      return true;
    }
    return false;
  };

  goToPage = (pageIndex: number, saveHistory = true) => {
    if (pageIndex === this.activeStepIndex) {
      return;
    }
    if (saveHistory) this.historyRef.push(pageIndex);
    const action = pageIndex > this.activeStepIndex ? this.onNext : this.onPrevious;
    action(pageIndex);
  };

  onNext = (nextStepIndex: number) => {
    if (nextStepIndex <= this.activeStepIndex) {
      return;
    }
    this.stepsHandlers[this.activeStepIndex]?.onNext?.();
    this.updateActiveStepIndexAndNotify(nextStepIndex);
  };

  onPrevious = (nextStepIndex: number) => {
    if (nextStepIndex >= this.activeStepIndex) {
      return;
    }
    this.stepsHandlers[this.activeStepIndex]?.onPrevious?.();
    this.updateActiveStepIndexAndNotify(nextStepIndex);
  };

  onFinish = () => {
    this.stepsHandlers[this.activeStepIndex]?.onFinish?.();
  };

  subscribeOnActiveIndexChanged = this.activeStepIndexChangedObservable.subscribe;

  unsubscribeOnActiveIndexChanged = this.activeStepIndexChangedObservable.unsubscribe;

  dispose = () => {
    Object.keys(this.stepsHandlers).forEach((key) => {
      this.stepsHandlers[Number.parseInt(key)]?.onClose?.();
    });
    this.stepsHandlers[this.activeStepIndex]?.onActivePageClose?.();
    this.activeStepIndex = 0;
    this.stepsHandlers = {};
    this.activeStepIndexChangedObservable.dispose();
  };

  private updateActiveStepIndexAndNotify(nextStepIndex: number) {
    const oldValue = this.activeStepIndex;
    this.activeStepIndex = nextStepIndex;
    this.activeStepIndexChangedObservable.notify(oldValue, this.activeStepIndex);
  }
}
