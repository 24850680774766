import { type Dispatch } from "redux";
import { getAxiosError } from "../../../../../utils/axiosError";
import { type BasePerformanceRequestFilterParams } from "../../../Common/models";
import DataService from "../../services/assessmentsDataService";
import {
  setCardData,
  setQuestionResponsesData,
  setEngagementLineData,
  setEngagementFunnelData,
  setEngagementQuestionsData,
  reqQuestionResponsesPeople,
  setQuestionResponsesPeople,
  setQuestionResponsesPeopleError,
  reqCardData,
  reqQuestionResponsesData,
  reqEngagementLineData,
  reqEngagementFunnelData,
  reqEngagementQuestionsData,
  setCardError,
  setEngagementError,
  setEngagementFunnelError,
  setEngagementQuestionsError,
  setQuestionResponsesError,
  reqPeopleDetails,
  setPeopleDetails,
  setPeopleDetailsError,
} from "../slices/assessmentPerformanceSlice";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { AlertTypes } from "../../../../../enums";
import { downloadExcelExport } from "../../../Common/utils/performanceUtils";
import { EmptyAssessmentEngagementCardsData, EmptyAssessmentEngagementFunnelData } from "../../types/mocks";
import { setExporting } from "features/Reporting/state/export/exportSlice";

export const fetchCardData = (entityId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqCardData());
    try {
      let data = await DataService.getAssessmentCardData(entityId, filterParams);
      data = {
        ...EmptyAssessmentEngagementCardsData,
        ...data,
      };

      dispatch(setCardData({ data }));
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setCardError({ errorMessage }));
      }
    }
  };
};

export const fetchQuestionResponsesData = (
  assessmentId: number,
  questionId: string,
  filterParams: BasePerformanceRequestFilterParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqQuestionResponsesData());
    try {
      const data = await DataService.getQuestionResponses(assessmentId, questionId, filterParams);
      dispatch(
        setQuestionResponsesData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        // intended for when the user switches questions, as the previous request will throw an error when aborted. This allows us to give a better visual transition when that occurs.
        dispatch(reqQuestionResponsesData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setQuestionResponsesError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementLineData = (entityId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementLineData());
    try {
      const data = await DataService.getAssessmentEngagementLine(entityId, filterParams);

      dispatch(
        setEngagementLineData({
          data,
        }),
      );
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementFunnelData = (entityId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementFunnelData());
    try {
      let data = await DataService.getAssessmentEngagementFunnel(entityId, filterParams);
      data = {
        ...EmptyAssessmentEngagementFunnelData,
        ...data,
      };

      dispatch(
        setEngagementFunnelData({
          data,
        }),
      );
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementFunnelError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementQuestionsData = (entityId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementQuestionsData());
    try {
      const data = await DataService.getAssessmentEngagementQuestions(entityId, filterParams);

      dispatch(
        setEngagementQuestionsData({
          data,
        }),
      );
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementQuestionsError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementQuestionsPeopleData = (
  entityId: number,
  questionId: string,
  answerId: string,
  filterParams: BasePerformanceRequestFilterParams = {},
) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqQuestionResponsesPeople());
    try {
      const { data } = await DataService.getAssessmentEngagementQuestionsPeople(
        entityId,
        questionId,
        answerId,
        filterParams,
      );

      dispatch(setQuestionResponsesPeople({ data }));
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setQuestionResponsesPeopleError({ errorMessage }));
      }
    }
  };
};

export const fetchPeopleDetails = (assessmentId: number, filterParams: BasePerformanceRequestFilterParams) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqPeopleDetails());

    try {
      const results = await DataService.getAssessmentPeopleDetails(assessmentId, filterParams);

      const resultsWithId = results.map((item) => {
        return {
          ...item,
          id: `${item.UserId}${item.CompletionDate}`,
        };
      });
      dispatch(setPeopleDetails({ data: resultsWithId }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setPeopleDetailsError({ errorMessage }));
      }
    }
  };
};

export const doExport = (
  assessmentId: number,
  filterParams: BasePerformanceRequestFilterParams,
  assessmentTitle: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getExport(assessmentId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, assessmentTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};
