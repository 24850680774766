import cn from "classnames";
import { type FC } from "react";
import { isFunction } from "lodash";
import EllipsisPopupButton from "../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import {
  ArchiveLinkButton,
  EditLinkButton,
  RemoveLinkButton,
  RestoreLinkButton,
} from "../../../../../components/buttons/linkButtons";

export interface Props {
  id: number;
  title: string;
  thumbnailImageUrl: string;
  isExpired: boolean;
  isTrial: boolean;
  isArchived: boolean;
  hasAccountManageRole: boolean;
  onEditLicensing: (id: number) => void;
  onExtendLicenses?: (id: number, title: string, thumbnailImageUrl: string) => void;
  onRemovePackFromAccount?: (id: number) => void;
  isAllowedToRemovePackFromAccount?: () => boolean;
  onArchivePack?: (id: number) => void;
  onRestorePack?: (id: number, title: string, thumbnailImageUrl: string) => void;
  hasPurchasePower: boolean;
  isAccountHome: boolean;
  isOwnPack: boolean;
  hasSource: boolean;
}

export const AccountPackMenu: FC<Props> = (props: Props) => {
  const {
    id,
    title,
    thumbnailImageUrl,
    isExpired,
    isTrial,
    isArchived,
    onEditLicensing,
    onExtendLicenses,
    onRemovePackFromAccount,
    isAllowedToRemovePackFromAccount,
    onArchivePack,
    hasAccountManageRole,
    onRestorePack,
    hasPurchasePower,
    isAccountHome,
    isOwnPack,
    hasSource
  } = props;
  const editLicensingButton = (
    <EditLinkButton
      key={cn("EditLicensingButton-", id)}
      onClick={() => {
        onEditLicensing(id);
      }}
      text="Edit Licensing"
    />
  );

  const extendLicensesButton = (
    <EditLinkButton
      key={cn("ExtendLicensesButton-", id)}
      onClick={() => {
        onExtendLicenses?.(id, title, thumbnailImageUrl);
      }}
      text="Edit Licensing"
    />
  );

  const removeButton = (
    <RemoveLinkButton
      key={cn("RemovePackButton-", id)}
      onClick={() => {
        onRemovePackFromAccount?.(id);
      }}
    />
  );

  const archiveButton = (
    <ArchiveLinkButton
      key={cn("ArchivePackButton-", id)}
      onClick={() => {
        onArchivePack?.(id);
      }}
    />
  );

  const restoreButton = (
    <RestoreLinkButton
      key={cn("RestorePackButton-", id)}
      onClick={() => {
        onRestorePack?.(id, title, thumbnailImageUrl);
      }}
    />
  );

  const getButtons = () => {
    let actionButtons = [];

    if (!isTrial && !isExpired && (!isAccountHome || hasPurchasePower) && !isOwnPack) {
      actionButtons.push(editLicensingButton);
    }

    if (isExpired && !isArchived && onExtendLicenses && !isOwnPack && (!isAccountHome || hasPurchasePower)) {
      actionButtons.push(extendLicensesButton);
    }

    if (
      isFunction(onRemovePackFromAccount) &&
      isFunction(isAllowedToRemovePackFromAccount) &&
      isAllowedToRemovePackFromAccount?.()
    ) {
      actionButtons.push(removeButton);
    }

    if (onArchivePack && !isOwnPack) {
      actionButtons.push(archiveButton);
    }

    if (onRestorePack && (!isAccountHome || hasPurchasePower) && !isOwnPack) {
      actionButtons.push(restoreButton);
    }

    return actionButtons;
  };

  const buttons = getButtons();

  if (hasAccountManageRole && !hasSource) {
    return buttons.length > 0 ? <EllipsisPopupButton circle>{[buttons]}</EllipsisPopupButton> : null;
  }

  return (
    <div data-testid="disabledCardEllipsis" onClick={(e) => e.stopPropagation()}>
      <EllipsisPopupButton disabled circle>
        {[]}
      </EllipsisPopupButton>
    </div>
  );
};
