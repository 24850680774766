import accountsDataService from "../../services/accountsDataService";
import { FETCH_MOST_ACCESSED_ACCOUNTS_SUCCESS } from "./mostAccessedAccountsActionTypes";
import { type Dispatch } from "redux";
import { SortingDirection } from "../../../../enums";

export const fetchMostAccessedAccounts = () => {
  return async (dispatch: Dispatch) => {
    const result = await accountsDataService.getAccounts(0, 3, "added", SortingDirection.Descending);
    dispatch({
      type: FETCH_MOST_ACCESSED_ACCOUNTS_SUCCESS,
      items: result.items,
    });
  };
};
