import axios from "axios";
import DraftEntityTypes from "../../../../enums/draftEntityTypes";

const service = {
  createLock: async (entityType, payload) => {
    const createLockRequest = {
      payload: JSON.stringify(payload),
    };

    const urls = {
      [DraftEntityTypes.Videos]: `/api/assets/videos`,
      [DraftEntityTypes.Assessments]: `/api/assets/assessments`,
      [DraftEntityTypes.Surveys]: `/api/assets/surveys`,
      [DraftEntityTypes.Pdfs]: `/api/assets/pdfs`,
      [DraftEntityTypes.DirectSendSendingProfile]: `/api/threat-defence/sending-profiles/smtp-direct`,
      [DraftEntityTypes.SmtpAuthSendingProfile]: `/api/threat-defence/sending-profiles/smtp-auth`,
      [DraftEntityTypes.Events]: `/api/external-events`,
      [DraftEntityTypes.Emails]: `/api/communications/emails`,
      [DraftEntityTypes.Messages]: `/api/communications/messages`,
    };

    const url = urls[entityType] || `/api/${entityType}`;

    return axios.post(url, createLockRequest);
  },
  getEntityLock: async (entityType, entityId) => {
    const urls = {
      [DraftEntityTypes.Videos]: `/api/v2/assets/videos/${entityId}/edit`,
      [DraftEntityTypes.Assessments]: `/api/v2/assets/assessments/${entityId}/edit`,
      [DraftEntityTypes.Surveys]: `/api/v2/assets/surveys/${entityId}/edit`,
      [DraftEntityTypes.Pdfs]: `/api/assets/pdfs/${entityId}/edit`,
      [DraftEntityTypes.DirectSendSendingProfile]: `/api/v2/threat-defence/sending-profiles/smtp-direct/${entityId}/edit`,
      [DraftEntityTypes.SmtpAuthSendingProfile]: `/api/v2/threat-defence/sending-profiles/smtp-auth/${entityId}/edit`,
      [DraftEntityTypes.Events]: `/api/v2/external-events/${entityId}/edit`,
      [DraftEntityTypes.Emails]: `/api/v2/communications/emails/${entityId}/edit`,
      [DraftEntityTypes.Messages]: `/api/communications/messages/${entityId}/edit`,
      [DraftEntityTypes.Pdfs]: `/api/assets/pdfs/${entityId}/edit`,
    };

    const url = urls[entityType] || `/api/v2/${entityType}/${entityId}/edit`;

    return axios.post(url);
  },
  releaseLock: async (entityType, entityId, payload) => {
    const urls = {
      [DraftEntityTypes.Videos]: `/api/assets/videos/${entityId}/publish`,
      [DraftEntityTypes.Assessments]: `/api/assets/assessments/${entityId}/publish`,
      [DraftEntityTypes.Surveys]: `api/assets/surveys/${entityId}/publish`,
      [DraftEntityTypes.Pdfs]: `api/assets/pdfs/${entityId}/publish`,
      [DraftEntityTypes.DirectSendSendingProfile]: `/api/threat-defence/sending-profiles/smtp-direct/${entityId}/publish`,
      [DraftEntityTypes.SmtpAuthSendingProfile]: `/api/threat-defence/sending-profiles/smtp-auth/${entityId}/publish`,
      [DraftEntityTypes.Events]: `/api/external-events/${entityId}/publish`,
      [DraftEntityTypes.Emails]: `/api/communications/emails/${entityId}/publish`,
      [DraftEntityTypes.Messages]: `api/communications/messages/${entityId}/publish`,
    };

    const url = urls[entityType] || `/api/${entityType}/${entityId}/publish`;

    return axios.post(url, payload);
  },
  discardLock: async (entityType, entityId) => {
    const urls = {
      [DraftEntityTypes.Videos]: `/api/assets/videos/${entityId}/revert`,
      [DraftEntityTypes.Assessments]: `/api/assets/assessments/${entityId}/revert`,
      [DraftEntityTypes.Surveys]: `api/assets/surveys/${entityId}/revert`,
      [DraftEntityTypes.Pdfs]: `api/assets/pdfs/${entityId}/revert`,
      [DraftEntityTypes.DirectSendSendingProfile]: `/api/threat-defence/sending-profiles/smtp-direct/${entityId}/revert`,
      [DraftEntityTypes.SmtpAuthSendingProfile]: `/api/threat-defence/sending-profiles/smtp-auth/${entityId}/revert`,
      [DraftEntityTypes.Events]: `/api/external-events/${entityId}/revert`,
      [DraftEntityTypes.Emails]: `/api/communications/emails/${entityId}/revert`,
      [DraftEntityTypes.Messages]: `api/communications/messages/${entityId}/revert`,
    };

    const url = urls[entityType] || `/api/${entityType}/${entityId}/revert`;

    return axios.post(url);
  },
};

export default service;
