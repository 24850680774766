import { type FiltersMap, mapFilterOption } from "../../../../../../utils/filterUtils";
import { setError, setFilterOptions, setIsLoading } from "../slices/emailFilterSlice";
import { type DataItem, type EmailTagsPayload, type PublisherDataItem } from "../../types/state";
import { EmailsFilterEnum } from "../../types/models";
import { type Dispatch } from "redux";
import { emailsDataService } from "../../services/emailsDataService";
import objUtils from "../../../../../../utils/objectUtils";
import { TagsEnum } from "../../../../../../interfaces/TagsEnum";
import { type Tag } from "../../../../../../interfaces/Tag";
import { type IPackDataItem, type IPublisherDataItem } from "../../../../Events/types/state";
import { batch } from "react-redux";
import { getRejected, hasFulfilled } from "../../../../../../utils/commonThunkUtils";
import DataService from "../../../../../Application/services/dataServices/typedDataService";

interface TemplateMethod {
  begin: () => void;
  success: (options: any) => void;
  failure: (error: any) => void;
}

function initActions(dispatch: Dispatch, template?: TemplateMethod) {
  if (!template) {
    return {
      begin: () => dispatch(setIsLoading(true)),
      success: (f: FiltersMap) => dispatch(setFilterOptions(f)),
      failure: (item: PromiseRejectedResult) => dispatch(setError(item?.reason)),
    };
  }
  return template;
}

export const getFilterOptions = (
  template?: TemplateMethod,
  includePublishers: boolean = true,
  showPurchased: boolean = true,
) => {
  let filterOptions: FiltersMap = {
    publisherIds: [],
    packIds: [],
    labels: [],
    softwareApplications: [],
  };

  const readPublishersData = (data: PublisherDataItem[], propertyName: string) => {
    generateFilterOption(data, propertyName, (item: PublisherDataItem) => ({ text: item.name, value: item.id }));
  };

  const readPacksData = (data: IPackDataItem[], propertyName: string) => {
    generateFilterOption(data, propertyName, (item: IPackDataItem) => ({ text: item.name, value: item.id }));
  };

  const tagToFilterMap: { [key in TagsEnum]?: EmailsFilterEnum } = {
    [TagsEnum.Label]: EmailsFilterEnum.Labels,
    [TagsEnum.SoftwareApplication]: EmailsFilterEnum.SoftwareApplications,
  };

  const readTagsData = (tags: EmailTagsPayload) => {
    for (let [key, value] of objUtils.typedEntries<TagsEnum, Tag[]>(tags)) {
      if (tagToFilterMap[key]) {
        filterOptions = mapFilterOption(filterOptions, value, tagToFilterMap[key], (item: Tag) => ({
          text: item.title,
          value: item.title,
        }));
      }
    }
  };

  const generateFilterOption = <T>(data: T[], propertyName: string, dataItemMapping: (item: T) => DataItem): void => {
    filterOptions[propertyName] = data.map((item) => dataItemMapping(item));
  };

  return async (dispatch: Dispatch) => {
    const dispatchError = (item: PromiseSettledResult<void>) => failure(item as PromiseRejectedResult);
    const { begin, success, failure } = initActions(dispatch, template);

    begin();

    let promises = [];

    if (includePublishers) {
      const publishersPromise = emailsDataService
        .getPublishersFilterOptions()
        .then((getPublishersResult: { data: IPublisherDataItem[]; }) =>
          readPublishersData(getPublishersResult.data, EmailsFilterEnum.Publishers),
        );

      promises.push(publishersPromise);
    }

    const packsPromise = DataService.packs
      .getPacksFilterOptions("email", showPurchased)
      .then((getPacksResult: { data: IPackDataItem[]; }) => readPacksData(getPacksResult.data, EmailsFilterEnum.Packs));

    promises.push(packsPromise);

    const tagsPromise = emailsDataService
      .getTags(showPurchased)
      .then((getTagsResult: { data: EmailTagsPayload; }) => readTagsData(getTagsResult.data));

    promises.push(tagsPromise);

    const result = await Promise.allSettled(promises);
    batch(() => {
      result.filter(getRejected).forEach((item) => dispatchError(item));

      if (result.some(hasFulfilled)) {
        success(filterOptions);
      }

      dispatch(setIsLoading(false));
    });
  };
};
