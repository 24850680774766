import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { type FetchingItemsState, createFetchingItemsSlice } from "features/Application/slices/createFetchingItemsSlice";
import { type UserPreview } from "features/People/types";

export const initialState: FetchingItemsState<UserPreview> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const csvImportPreviewSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.AccountUploadCsvFile,
    name: "Preview",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  reducers: {},
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset } = csvImportPreviewSlice.actions;

export default csvImportPreviewSlice.reducer;
