import { type AssignedGroup, type AssignedUser, type PackName } from "../../../../interfaces";
import { FilterType, type FilterTypesMap, type FiltersMap } from "../../../../utils/filterUtils";
import { type Filters } from "../../../../utils/queryUtils";
import { type PeopleGroupAssignment } from "../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { type Link } from "../../../../components/ExpandableItems/types";
import { type PeopleAssignment } from "../../PeopleAssignments/types";

type EventFlows = {
  id: number;
  title: string;
};

export interface EventRegistration {
  id: number;
  name: string;
  email: string;
  account: string;
  sessionTime: string;
  flowName: string;
  registrationDate: string;
  status: string;
}

export interface SessionReporting {
  id: number;
  startDate: string;
  startsAt: string;
  duration: string;
  registrations: number;
  attendees: number;
  status: string;
}

export interface EventOverview {
  id: number;
  title: string;
  publisher: string;
  isDraft: boolean;
  dateCreated: string;
  dateModified: string;
  hasBeenPublished: boolean;
  ownerName?: string;
  thumbnailUrl?: string;
  isPurchased: boolean;
  canBeDeleted: boolean;
  dependencies: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
    isEmpty: boolean;
  };
  visibility: boolean;
}

export interface PublishedEventOverview {
  id: number;
  title: string;
  publisherId: number;
  publisher: string;
  dateCreated: Date;
  dateModified: Date;
}

export interface EventFilterOptions {
  filterOptions: FiltersMap;
  appliedFilter: Filters;
  isLoading: boolean;
  error?: Error;
  searchTerm?: string;
}

export enum EventFilterPropertyNames {
  Publishers = "publisherIds",
  Packs = "packIds",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const eventFilterTypes: FilterTypesMap<EventFilterPropertyNames> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
};

export type EventsOverviewState = {
  events: Array<EventOverview>;
  isAllLoaded: boolean;
  isLoading: boolean;
  totalCount: number;
  error?: Error;
};

export interface Event {
  id: number;
  isDraft: boolean;
  hasBeenPublished: boolean;
  title: string;
  thumbnailUrl: string;
  description: string;
  dateCreated?: Date;
  dateModified?: Date;
  isPurchased?: boolean;
  labels: string[];
  softwareApplications: string[];
  packs: PackName[];
  flows: EventFlows[];
  externalEventSessions: SessionsPayload;
}

export interface EventView {
  id: number;
  isDraft: boolean;
  hasBeenPublished: boolean;
  title: string;
  thumbnailUrl: string;
  description: string;
  dateCreated?: Date;
  dateModified?: Date;
  externalEventSessions: SessionsView;
  isPurchased?: boolean;
}

export interface SettingsView {
  labels: string[];
  softwareApplications: string[];
  packs: Link[];
  flows: EventFlows[];
}

export interface EventDetailsState {
  event: EventView;
  settings: SettingsView;
  isLoading: boolean;
  error?: Error;
  isEventLoaded: boolean;
  isSessionsLoaded: boolean;
}

export interface CreateEventData {}

export interface EventPayload {
  id?: number;
  title: string;
  description?: string;
  thumbnailUrl?: string;
  isDraft?: boolean;
  hasBeenPublished?: boolean;
  externalEventSessions: SessionsPayload;
}

export interface EventUser extends PeopleAssignment {
  id: number;
  department: string;
  email: string;
  firstName: string;
  imageUrl: string;
  lastName: string;
  title: string;
}

export interface EventGroup extends PeopleGroupAssignment {
  groupId: number;
}

export type EventGroupsState = {
  groupsOverview: {
    groups: Array<EventGroup>;
    isLoading: boolean;
    totalCount: number;
  };
  groupsToAdd: {
    items: Array<AssignedGroup>;
    isLoading: boolean;
    itemsCount: number;
  };
  error?: Error;
};

export type EventUsersState = {
  usersOverview: {
    users: Array<EventUser>;
    isLoading: boolean;
    totalCount: number;
  };
  usersToAdd: {
    items: Array<AssignedUser>;
    isLoading: boolean;
    itemsCount: number;
  };
  error?: Error;
};

export interface EventPeopleAssignment {
  peopleId: number;
  contentId: number;
  priority: number;
  dueDate: number | null;
  contentType: string;
  peopleType: string;
  packIds: number[];
}

export interface SessionsView {
  sessions: SessionView[];
}

export interface SessionsPayload {
  id: number;
  sessions: SessionPayload[];
}

export interface SessionPayload {
  id: string | null;
  orderIndex: number;
  webinarUrl: string;
  sessionStartDate: string | null;
  startsAtTicksMinutes: number;
  endsAtTicksMinutes: number;
  timeZone: string | null;
  isPeriodic: boolean;
  recurrenceSession?: RecurrenceSession;
}

export interface SessionView {
  id: string | null;
  webinarUrl: string;
  startDate: string | null;
  startsAt: string | null;
  endsAt: string | null;
  timeZone: string | null;
  isPeriodic: boolean;
  recurrenceSession?: RecurrenceSession;
}

export interface RecurrenceSession {
  periodType: PeriodType;
  periodValue: number;
  seriesEndDate?: string | null;
  weekDays?: number[];
  periodicRule?: PeriodicRule;
}

export enum PeriodType {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
}

export enum PeriodicRule {
  // Monthly rules
  MonthlyOnExactDayInMonth = 0, // The 23th day of every Month
  MonthlyLastDayOfWeekInMonth = 1, // Last Monday in Month
  MonthlyFirstDayOfWeekInMonth = 2, // First Monday in Month
  MonthlySecondDayOfWeekInMonth = 3, // Second Monday in Month
  MonthlyThirdDayOfWeekInMonth = 4, // Third Monday in Month
  MonthlyFourthDayOfWeekInMonth = 5, // Fourth Monday in Month

  // Yearly
  YearlyOnExactDayAndMonth = 10, // On August 23
  YearlyLastDayOfWeekInMonth = 11, // Last Monday in August
  YearlyFirstDayOfWeekInMonth = 12, // First Monday in August
  YearlySecondDayOfWeekInMonth = 13, // Second Monday in August
  YearlyThirdDayOfWeekInMonth = 14, // Third Monday in August
  YearlyFourthDayOfWeekInMonth = 15, // Fourth Monday in August
}

export interface EventTagsPayload {
  [key: string]: ITagDataItem[];
}

export interface IPublisherDataItem {
  name: string;
  id: number;
}

export interface IPackDataItem {
  name: string;
  id: number;
}

export interface ITagDataItem {
  title: string;
  id: number;
}

export interface IDataItem {
  text: string;
  value: number | string;
}

export enum Tags {
  Label = "Label",
  SoftwareApplication = "SoftwareApplication",
}

export enum EventSteps {
  Configuration = "configure",
  Sessions = "content",
  People = "people",
}

export interface FetchEventsActionPayload<T> {
  items: T[];
  totalCount: number;
  concatItems: boolean;
}

export interface FetchRegistrationsActionPayload<T> {
  items: T[];
  totalCount: number;
}
