import { type FC, useCallback, useEffect, useRef } from "react";
import { noop } from "lodash";

import { ContentTypesEnum, TemplateTypes } from "../../../../enums";
import { NotifyStepConfig } from "../../../SystemNotifications/types";
import { SwitchEntityTypes } from "enums/switchEntityTypes";
import { type IObservable } from "../../../../interfaces/IObservable";
import { type NotifyStepSettings } from "../../../../interfaces";
import { type PdfPublishConfirmationParams } from "../types/models";

import PublishConfirmationModal, {
  type EntityState,
} from "../../../../components/modal/PublishConfirmationModal/PublishConfirmationModal";
import DeleteContentConfirmationModal, {
  type RemoveContentObserverInput,
} from "../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import Observable from "../../../../utils/Observable";
import RevertConfirmationModalTs from "../../../../components/modal/RevertConfirmationModalTs";
import ModalManager from "../../../../utils/ModalManager";
import ModalTypes from "../../../../components/modal/ModalTypes";

export interface PdfModalsProps {
  publishModal: {
    acceptHandler: (handler: (params: PdfPublishConfirmationParams) => void) => void;
    onConfirm: (id: number, notificationSettings?: NotifyStepSettings, notifyTypes?: number[]) => void;
  };
  deleteModal?: {
    acceptHandler: (handler: (ids: number[], flowsCount?: number, packsCount?: number) => void) => void;
    onConfirm: (ids: number[]) => void;
  };
  revertModal?: {
    acceptHandler: (handler: () => void) => void;
    onConfirm: () => void;
    userId: number;
    enabled: boolean;
  };
}

export const PdfModalsContainer: FC<PdfModalsProps> = ({ publishModal, deleteModal, revertModal }) => {
  const onTriggerPdfPublishObserver = useRef<IObservable<(onConfirm: () => void, entityState: EntityState) => void>>(
    new Observable(),
  );

  const onTriggerPdfDeleteObserver = useRef<
    IObservable<(onConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void>
  >(new Observable());

  const revertModalManager = useRef<ModalManager>(
    new ModalManager(ModalTypes.RevertPdf, revertModal?.onConfirm ?? noop, false, revertModal?.userId),
  );

  const triggerPublish = useCallback(
    (condition: boolean, entityState: EntityState) => {
      return condition
        ? onTriggerPdfPublishObserver.current.notify(noop, entityState)
        : publishModal?.onConfirm(entityState.entityId);
    },
    [publishModal],
  );

  const handlePublishClick = useCallback(
    ({ id, isDraft, flowsCount, assignmentsCount, hasBeenPublished }: PdfPublishConfirmationParams) => {
      if (isDraft) {
        const entityState = {
          itemsCount: flowsCount,
          entityId: id,
          assignmentsCount: assignmentsCount,
          templateType: hasBeenPublished ? TemplateTypes.PdfChanged : TemplateTypes.AddedToPdf,
          notifyConfigType: hasBeenPublished ? NotifyStepConfig.WithProgress : NotifyStepConfig.Default,
          entityType: SwitchEntityTypes.Pdf,
        };

        triggerPublish(Boolean(entityState.assignmentsCount > 0), entityState);
      }
    },
    [triggerPublish],
  );
  const handleRevertClick = useCallback(() => {
    revertModalManager.current.execute();
  }, []);

  const handleDeleteClick = useCallback(
    (ids: number[], flowsCount?: number, packsCount?: number) => {
      onTriggerPdfDeleteObserver.current.notify(
        () => {
          deleteModal?.onConfirm(ids);
        },
        {
          selectedItemsCount: ids.length,
          flowsCount,
          packsCount,
        },
      );
    },
    [deleteModal],
  );

  useEffect(() => {
    publishModal.acceptHandler(handlePublishClick);
  }, [publishModal, handlePublishClick]);

  useEffect(() => {
    deleteModal?.acceptHandler(handleDeleteClick);
  }, [deleteModal, handleDeleteClick]);

  useEffect(() => {
    revertModal?.acceptHandler(handleRevertClick);
  }, [revertModal, handleRevertClick]);

  return (
    <>
      <PublishConfirmationModal
        onTriggerPublishContentObserver={onTriggerPdfPublishObserver.current}
        onConfirmAction={publishModal?.onConfirm ?? noop}
        contentType="PDF"
        dependencyType="flow"
      />

      {deleteModal && (
        <DeleteContentConfirmationModal
          contentType={ContentTypesEnum.PDFs}
          onTriggerRemoveContentObserver={onTriggerPdfDeleteObserver.current}
        />
      )}
      {revertModal?.enabled && <RevertConfirmationModalTs modalManager={revertModalManager.current} />}
    </>
  );
};
