import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type EventRegistration } from "../../types/state";

const initialState: FetchingItemsState<EventRegistration> = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
};

const eventRegistrationsSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Events,
    name: "registrations",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = eventRegistrationsSlice.actions;

export type EventRegistrationsState = ReturnType<typeof eventRegistrationsSlice.reducer>;

export const eventRegistrationsReducer = eventRegistrationsSlice.reducer;
