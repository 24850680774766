import { type AbortController } from "@azure/abort-controller";
import TaskActions from "../../../../../enums/TaskActions";
import TaskStatuses from "../../../../../enums/taskStatuses";
import fileUtils from "../../../../../utils/fileUtils";
import taskPool from "../../../../../utils/taskPool";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import backgroundTasksEventsEmitter from "../../../../BackgroundTasks/events/backgroundTasksEventsEmitter";
import { type VideoFeedbackPageTask } from "../../../../BackgroundTasks/taskPool";

let aborter: AbortController;
let taskId: string;
let tasksActions: any;

export const acceptUploadAborter = (abortedHandler: AbortController) => {
  aborter = abortedHandler;
  return aborter;
};

export const onUploadProgressHandler = (progressEvent: { loaded: number; total: number }) => {
  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  tasksActions.updateProgress(taskId, percentCompleted);
  return percentCompleted;
};

export const uploadFilesHandler = (
  backgroundTasksActions: any,
  files: FileList,
  setVideoUploadTaskId: Function,
): Promise<string> => {
  const uploadFileHandler = async (file: File): Promise<string> => {
    tasksActions = backgroundTasksActions;
    taskId = file.name + Date.now();
    const task: VideoFeedbackPageTask = {
      id: taskId,
      status: TaskStatuses.queued,
      state: TaskStatuses.queued,
      title: file.name,
      label: "Uploading...",
      errorLabel: "File upload failed!",
      canClose: true,
      percent: 0,
      isFeedbackPageVideoUploading: true,
      isFeedbackPageVideoUploaded: false,
      indeterminate: false,
      onCancel: () => {
        backgroundTasksActions.deleteTask(taskId);
      },
      operation: {
        messages: [],
        succeeded: [],
        failed: [],
      },
    };

    const getStarted = (videoUploadTask: VideoFeedbackPageTask) => {
      let startedTask: VideoFeedbackPageTask = {
        ...videoUploadTask,
        status: TaskStatuses.inProgress,
        isFeedbackPageVideoUploading: true,
      };

      return startedTask;
    };

    const getUploadedSuccessfuly = (videoUploadTask: VideoFeedbackPageTask) => {
      let uploadedTask: VideoFeedbackPageTask = {
        ...videoUploadTask,
        label: "Uploaded successfully!",
        errorLabel: "Create failed!",
        percent: 100,
        status: TaskStatuses.successful,
        isFeedbackPageVideoUploading: false,
        isFeedbackPageVideoUploaded: true,
      };
      return uploadedTask;
    };

    const getFailed = (videoUploadTask: VideoFeedbackPageTask) => {
      let failedTask: VideoFeedbackPageTask = {
        ...videoUploadTask,
        status: TaskStatuses.failed,
        errorLabel: "Video processing failed!",
        percent: 100,
        indeterminate: false,
      };
      return failedTask;
    };

    try {
      backgroundTasksActions.addTask(task);
      backgroundTasksActions.updateTask(taskId, getStarted(task));
      setVideoUploadTaskId(taskId);

      backgroundTasksEventsEmitter.subscribeOnce(TaskActions.cancel, task.id, taskPool.cancelTask.bind(taskPool));

      let uploadOptions = { contentType: file.type };
      let sasTokenUri = (await dataService.threatDefence.generateSas()).data;
      await fileUtils.uploadFileToBlob(sasTokenUri, file, onUploadProgressHandler, acceptUploadAborter, uploadOptions);

      backgroundTasksActions.updateTask(taskId, getUploadedSuccessfuly(task));
      return sasTokenUri;
    } catch (error: any) {
      if (error.code === "REQUEST_ABORTED_ERROR") {
        return "";
      }
      backgroundTasksActions.updateTask(taskId, getFailed(task));
      return "";
    }
  };

  return uploadFileHandler(files[0]);
};

export const cancelFileUploading = (backgroundTasksActions: any, id: string) => {
  if (!aborter || aborter.signal.aborted) {
    return;
  }

  aborter.abort();
  backgroundTasksActions.deleteTask(id);
};
