import { cloneDeep, isEqual } from "lodash";
import { type BranchingQuestion } from "../../../../interfaces";
import { DEFAULT_HANDLER_ID, OTHER_ANSWER } from "../Designer/constants";
import {
  type EntityTriggerTypes,
  EntityType,
  type TriggerTimeUnit,
  type TriggerType,
  type SavedSurvey,
  type FlowDefinitionData,
} from "../Designer/types";
import { TriggerTypeEnum } from "../../../../enums";
import { type IFlowNode, type IReactFlowEntityType } from "../Designer/ReactFlowCanvas/nodes/types";

const getTriggerFilterStrategy = (typeId: TriggerTypeEnum) => {
  return typeId === TriggerTypeEnum.Response
    ? (currentTriggerType: TriggerType) => currentTriggerType.id === TriggerTypeEnum.Response
    : (currentTriggerType: TriggerType) => currentTriggerType.id !== TriggerTypeEnum.Response;
};

export function getTypeOptions(
  entityTriggerTypes: EntityTriggerTypes[],
  selectedItemType: EntityType | "",
  typeId: TriggerTypeEnum,
) {
  const currentTriggerTypes = cloneDeep(entityTriggerTypes).find((e) => e.entityType === selectedItemType);
  if (!currentTriggerTypes) {
    return [];
  }

  currentTriggerTypes.triggerTypes = currentTriggerTypes.triggerTypes.filter(getTriggerFilterStrategy(typeId));

  return currentTriggerTypes.triggerTypes.map((item, i) => ({
    key: i,
    text: item.title,
    value: item.id,
  }));
}

export function getResponseTypeContent(
  typeId: TriggerTypeEnum,
  branchingQuestion?: BranchingQuestion,
  sourceHandle?: string | null,
) {
  if (typeId !== TriggerTypeEnum.Response || !branchingQuestion) {
    return;
  }

  const { answers, questionText } = branchingQuestion;

  if (sourceHandle === DEFAULT_HANDLER_ID) {
    return {
      [TriggerTypeEnum.Response]: {
        answerText: OTHER_ANSWER.answerText,
        questionText,
        answerIndex: answers.length,
      },
    };
  }

  const answerIndex = answers.findIndex((currentAnswer) => currentAnswer.id === sourceHandle);
  const { answerText } = answers[answerIndex];

  return {
    [TriggerTypeEnum.Response]: {
      answerText,
      questionText,
      answerIndex,
    },
  };
}

export const getDestinationOption = (node: IFlowNode | undefined) => {
  if (!node) return;
  return {
    text: isEqual(EntityType.FlowEnd, node.type) ? "End of Flow" : node.data?.title || "",
    type: node.type as IReactFlowEntityType,
    thumbnailUrl: node.data?.thumbnailUrl,
  };
};

export function getTimeUnitsOptions(triggerTimeUnits: TriggerTimeUnit[]) {
  return triggerTimeUnits.map((item, i) => {
    return {
      key: i,
      text: item.name,
      value: item.id,
    };
  });
}

export function filterResponseTriggers(flowDataResponse: FlowDefinitionData) {
  const { ...rest } = flowDataResponse;
  const answerIds = flowDataResponse.flowItems.flatMap((item) => {
    const answers = (item.entity as SavedSurvey)?.branchingQuestion?.answers || [];
    return answers.map((q) => q.id);
  });
  const surveyWithOtherAnswerIds = flowDataResponse.flowItems
    .filter((item) => (item.entity as SavedSurvey)?.branchingQuestion?.includeOtherAsAnswer)
    .map((item) => item.id);

  const triggers = flowDataResponse.flowTriggers
    ? flowDataResponse.flowTriggers.filter(
        (t) =>
          t.typeId !== TriggerTypeEnum.Response ||
          (t.bulletId && answerIds.includes(t.bulletId)) ||
          (!t.bulletId && surveyWithOtherAnswerIds.includes(t.inId)),
      )
    : null;

  return {
    ...rest,
    flowTriggers: triggers,
  };
}
