import React from "react";
import { type ThreatDefenceSendingProfileOverview } from "../types/state";
import { bindActionCreators, type Dispatch } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";

import * as directSendEntityStateActions from "../DirectSend/state/actions/threatDefenceSendingProfileEntityStateActions";
import * as smtpAuthEntityStateActions from "../SmtpAuth/state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import columnOptions from "./columnOptions";
import { TextTruncate } from "../../../../../components";
import sendingProfileUtils from "../../../../../utils/threatDefenceSendingProfileUtils";
import { PublishedStatusTypes } from "../../../../../enums";
import SendingMethods from "../../../../../enums/sendingMethods";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import SendingProfileEllipsisPopupButton from "../../../../../components/sendingProfile/SendingProfileEllipsisPopupButton";
import { VerifiedStatus } from "../../../../../components/common/verification/verifiedStatus";
import { Tooltip } from "../../../../../components/common/tooltip";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import { useRtn } from "../../../../../hooks/useRtn";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useNavigate } from "react-router-dom";

interface ThreatDefenceSendingProfilesOverviewTableBuilderOwnProps {
  sendingProfile: ThreatDefenceSendingProfileOverview;
}

export type ThreatDefenceSendingProfilesOverviewTableBuilderProps =
  ThreatDefenceSendingProfilesOverviewTableBuilderOwnProps & PropsFromRedux;

export const ThreatDefenceSendingProfilesOverviewTableBuilder = (
  props: ThreatDefenceSendingProfilesOverviewTableBuilderProps,
) => {
  const navigate = useNavigate();
  const goToEditEntity = (payload: { id: number }) => navigate(payload.id.toString());
  useRtn([rtnEvents.SmtpSendingProfileLockSuccess, rtnEvents.DirectSendSendingProfileLockSuccess], goToEditEntity);

  const handleEditClick = (threadDefenceSendingProfile: ThreatDefenceSendingProfileOverview) => {
    const id = threadDefenceSendingProfile.id;
    if (!threadDefenceSendingProfile.isDraft) {
      if (threadDefenceSendingProfile.sendingMethodId === SendingMethods.DirectSend) {
        props.directSendEntityStateActions.fetchDraftThreatDefenceSendingProfileEntity(id);
      } else {
        props.smtpAuthEntityStateActions.fetchDraftSendingProfileEntity(id);
      }
      return;
    }
    goToEditEntity({ id });
  };

  const { sendingProfile } = props;

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <Tooltip
          target={
            <TextTruncate className={"clickable"} onClick={() => handleEditClick(sendingProfile)} lines={2}>
              {sendingProfileUtils.getSenderName(sendingProfile)}
            </TextTruncate>
          }
          content={sendingProfileUtils.getSenderName(sendingProfile)}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Tooltip
          target={<TextTruncate lines={2}>{sendingProfileUtils.getSenderEmail(sendingProfile)}</TextTruncate>}
          content={sendingProfileUtils.getSenderEmail(sendingProfile)}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip
          target={<TextTruncate lines={2}>{sendingProfile.sendingMethod}</TextTruncate>}
          content={sendingProfile.sendingMethod}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <VerifiedStatus isVerified={sendingProfile.isVerified} />
      </Table.Cell>
      <Table.Cell width={columnOptions[4].width}>
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!sendingProfile.isDraft)} />
      </Table.Cell>
      <Table.Cell width={columnOptions[5].width}>{dateTimeUtils.formatDate(sendingProfile.dateModified)}</Table.Cell>
      <Table.Cell width={columnOptions[6].width}>
        <SendingProfileEllipsisPopupButton
          onEditClicked={() => handleEditClick(sendingProfile)}
          outlinedEllipsis
          circle
        />
      </Table.Cell>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  directSendEntityStateActions: bindActionCreators(directSendEntityStateActions, dispatch),
  smtpAuthEntityStateActions: bindActionCreators(smtpAuthEntityStateActions, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ThreatDefenceSendingProfilesOverviewTableBuilder);
export default ConnectedComponent;
