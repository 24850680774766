import React, { useMemo, useReducer } from "react";
import { contentReducer } from "./ContentReducer";
import { ContentContext } from "./ContentContext";

export const ContentContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [contentState, dispatch] = useReducer(contentReducer, { branchingQuestionId: undefined });

  const memorizedValue = useMemo(() => {
    return {
      ...contentState,
      toggleBranchingQuestion: (index: string) => dispatch({ type: "TOGGLE_BRANCHING_QUESTION", payload: index }),
      turnOffBranchingQuestion: (index: string) => dispatch({ type: "TURN_OFF_BRANCHING_QUESTION", payload: index }),
    };
  }, [contentState]);

  return <ContentContext.Provider value={memorizedValue}>{props.children}</ContentContext.Provider>;
};
