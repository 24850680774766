import { type SortingDirection } from "../../../../../../enums";
import { type Account } from "../../../../../../interfaces/Account";
import type ColumnOption from "../../../../../../interfaces/columnOptions";
import { type FiltersMap } from "../../../../../../utils/filterUtils";
import { type Filters } from "../../../../../../utils/queryUtils";

export interface PackVisibilityForAccountsModalOneStepProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (selectedIds: number[]) => void;
  loadPage: (skip: number, top: number, orderBy: string, orderDirection: SortingDirection, filters: FiltersMap) => void;
  id: number;
  accounts: AccountVisibility[];
  accountsAmount: number;
  isListLoading: boolean;
}

export interface PackVisibilityForAccountsModalOneStepState {
  selectedIds: number[];
  appliedFilter: Filters;
}

export interface AccountVisibility extends Account {
  isPackVisible: boolean;
}

export const columnsMap: { [key: string]: string } = {
  name: "name",
  "account type": "accountTypeName",
  "user count": "userCount",
};

export const columnOptions: Array<ColumnOption> = [
  {
    name: "Name",
    width: 7,
    isSortable: true,
    className: "account-name-header",
  },
  {
    name: "Account Type",
    width: 4,
    isSortable: true,
  },
  {
    name: "User Count",
    width: 3,
    isSortable: false,
  },
  {
    name: "",
    width: 2,
    isSortable: false,
  },
];
