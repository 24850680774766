import React from "react";

import BaseIconButton, { type BaseIconButtonProps } from "../BaseIconButton";

type Props = BaseIconButtonProps & { alternate: boolean };

function DeleteButton({ alternate = true, ...props }: Props) {
  return <BaseIconButton iconClassName={`trash ${alternate ? "alternate" : ""}`} {...props} />;
}

export default DeleteButton;
