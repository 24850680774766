import { DetailsHeader, Segments } from "components";
import { Navigate, Route, Routes } from "react-router-dom";
import PeopleActivityReport from "./Activity/PeopleActivityReport";
import { UsersReport } from "./Users/PeopleUsersReport";
import PeopleOverviewReport from "./Overview/PeopleOverviewReport";
import { useCallback, useMemo, useRef } from "react";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import AcquisitionReport from "./Acquisition/PeopleAcquisitionReport";

export const ReportingPeople = () => {
  const overviewReportEnabled = !!useFeatureFlag(FeatureFlags.MauReports);

  const pages = useMemo(
    () =>
      overviewReportEnabled
        ? [
          { to: "overview", label: "Overview" },
          { to: "activity", label: "Activity" },
          { to: "acquisition", label: "Acquisition" },
          { to: "users", label: "Users" },
        ]
        : [
          { to: "activity", label: "Activity" },
          { to: "acquisition", label: "Acquisition" },
          { to: "users", label: "Users" },
        ],
    [overviewReportEnabled],
  );

  const rootRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    rootRef.current?.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="nested-content people-reporting-root" ref={rootRef}>
      <DetailsHeader title="People" />
      <Segments to="/reporting/people">
        {pages.map((page) => (
          <Segments.Segment key={page.to} {...page} />
        ))}
      </Segments>
      <Routes>
        {overviewReportEnabled && (
          <Route path="overview" element={<PeopleOverviewReport scrollToTop={scrollToTop} />} />
        )}
        <Route path="activity" element={<PeopleActivityReport />} />
        <Route path="acquisition" element={<AcquisitionReport />} />
        <Route path="users" element={<UsersReport />} />
        <Route path="*" element={<Navigate to={overviewReportEnabled ? "overview" : "activity"} replace />} />
      </Routes>
    </div>
  );
};
