import { type LinkDetailsState, type LinkView } from "../../types/state";
import * as actionTypes from "../actionTypes/linkDetailsActionTypes";
import miscUtils from "../../../../../utils/miscellaneousUtils";
import { type PayloadAction } from "../../../../../interfaces/redux";
import { createReducer } from "../../../../../utils/reduxUtils";

const initialState: LinkDetailsState = {
  link: {
    url: "",
    title: "",
    dateModified: undefined,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
    dateCreated: undefined,
    description: "",
    hasBeenPublished: false,
    id: -1,
    isDraft: false,
    previewUrl: "",
  },
  isLoading: false,
  error: undefined,
  isLinkLoaded: false,
};

const linkDetailsHandlers = () => {
  const { saveLink, clearLink } = actionTypes;

  const saveLinkHandler = (state: LinkDetailsState, action: PayloadAction<LinkView>): LinkDetailsState => ({
    ...state,
    link: action.payload,
  });

  const clearLinkHandler = (): LinkDetailsState => ({
    ...initialState,
  });
  return {
    [saveLink]: saveLinkHandler,
    [clearLink]: clearLinkHandler,
  };
};

export const linkDetailsReducer = createReducer(initialState, [linkDetailsHandlers]);
