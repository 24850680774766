import { Section, WizardNew } from "components/wizard";
import { ExistingAccount } from "../../Configuration/ExistingAccount";
import { useWizardStepsManager } from "hooks/useWizardStepsManager";
import CreateAccountTemplates from "../Templates/CreateAccountTemplates";
import { useNavigate, useResolvedPath } from "react-router-dom";
import {
  resetAppliedFilter as resetTemplatesFilter,
  resetOverview as resetTemplates,
} from "../../state/slices/availableTemplatesSlice";
import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import { useEffect, useMemo } from "react";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { Observable } from "utils";
import ConfirmTemplatesPacksModal from "../../Templates/ConfirmTemplatesPacksModal";
import { bindAction } from "interfaces";
import { saveTemplatesPacks } from "../../state/thunks/AccountTemplatesThunk";
import { type PackAccountsContextItem } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";

interface Props extends PropsFromRedux {
  account: { name: string; id: number; logoUri: Nullable<string> };
  onRemoveName: () => void;
}

enum Pages {
  Configuration,
  Templates,
}
const pageIndexes = [Pages.Configuration, Pages.Templates];

const ConfigureExisting = (props: Props) => {
  const templateFlag = useFeatureFlag(FeatureFlags.GroupTemplates);
  const { onRemoveName, account, reset, selectedTemplates, addTemplatePacksToAccount } = props;
  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager(pageIndexes);
  const onTriggerConfirmLicensingObserver = useMemo(() => new Observable(), []);
  const rootPath = useResolvedPath("..").pathname;
  const navigate = useNavigate();
  const handleFinish = () => {
    onFinish();
    navigate(rootPath);
  };

  const handleOnFinish = () => {
    if (selectedTemplates.length) {
      onTriggerConfirmLicensingObserver.notify((packs: PackAccountsContextItem[]) => {
        addTemplatePacksToAccount(account.id, packs);
        handleFinish();
      }, selectedTemplates);
    } else {
      handleFinish();
    }
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WizardNew
        id="create-account-wizard"
        className="create-account"
        title="Create Account"
        finishButtonLabel="Next"
        isSaveInProgress={false}
        onCancel={() => navigate(rootPath)}
        onFinish={handleOnFinish}
        onProgress={onNext}
        onRegress={onPrevious}
      >
        <Section label="Configuration" required isLocked={!templateFlag}>
          <ExistingAccount {...account} acceptHandlers={wizardPages[Pages.Configuration]} onRemoved={onRemoveName} />
        </Section>
        {templateFlag && (
          <Section label="Templates">
            <CreateAccountTemplates acceptHandlers={wizardPages[Pages.Templates]} accountId={account.id} />
          </Section>
        )}
      </WizardNew>
      <ConfirmTemplatesPacksModal onTriggerModalObserver={onTriggerConfirmLicensingObserver} />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    selectedTemplates: state.accounts.availableTemplates.overview.selected,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    reset: () => {
      dispatch(resetTemplatesFilter());
      dispatch(resetTemplates());
    },
    addTemplatePacksToAccount: bindAction(saveTemplatesPacks, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ConfigureExisting);

export default ConnectedComponent;
