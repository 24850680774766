import * as userPermissionActionTypes from "../actionTypes/userPermissionActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";

const userPermissionActions = (section: string) => {
  const entityType = section.slice(0, section.length - 1).toUpperCase();
  let types: { [index: string]: string } = userPermissionActionTypes;

  return {
    updateUsersAssignment: getActionProvider<number[]>(types[`${entityType}_ASSIGN_USERS_PERMISSIONS`]),
    resetUsersAssignment: getActionBaseProvider(types[`${entityType}_RESET_USERS_PERMISSIONS`]),
  };
};

export default userPermissionActions;
