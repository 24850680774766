import { Table } from "semantic-ui-react";
import PermissionsList from "../Permissions/PermissionsList";
import RoleIcon from "./RoleIcon/RoleIcon";
import { AddToLinkButton, EditLinkButton, DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import TitleSubtitle from "../../../../components/listViewTemplates/titleSubtitle/TitleSubtitle";
import EllipsisPopupButton from "../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import dateTimeUtils from "../../../../utils/dateTimeUtils";

import "./roleRow.scss";
import type Role from "../../../../interfaces/role";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { getColumnOptions } from "./getColumnOptions";

export type ButtonHandler = (role: Role) => void;

export interface RoleRowProps {
  role: Role;
  url?: string;
  rowButtonHandlers?: {
    onDeleteRole: ButtonHandler;
    onEditRole: ButtonHandler;
    onAddUsers: ButtonHandler;
  };
  rowButtonsDisabled?: boolean;
  hasPermission?: boolean;
}

const RoleRow = ({ role, url, rowButtonHandlers, rowButtonsDisabled, hasPermission = true }: RoleRowProps) => {
  const renderDeleteButton = () => {
    if (!role.isDefault) {
      return <DeleteLinkButton onClick={() => rowButtonHandlers?.onDeleteRole(role)} />;
    }

    return null;
  };

  const columnOptions = getColumnOptions();

  return (
    <>
      <Table.Cell width={columnOptions[0].width} className="role-info">
        <RoleIcon isDefaultRole={role.isDefault} />
        <div className="role-title-subtitle">
          <TitleSubtitle title={role.name} subtitle={role.description} subtitleClamp={2} url={url} />
        </div>
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{role.usersCount}</Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <PermissionsList areas={role.areas} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(role.added)}</Table.Cell>
      <Table.Cell className="align-right-role-overview" width={columnOptions[4].width}>
        {rowButtonHandlers && (
          <RestrictedByTooltip hasPermission={hasPermission}>
            <EllipsisPopupButton circle outlinedEllipsis disabled={rowButtonsDisabled}>
              {!role.isDefault && <EditLinkButton onClick={() => rowButtonHandlers.onEditRole(role)} />}
              {rowButtonHandlers.onAddUsers && (
                <AddToLinkButton text="Add People" onClick={() => rowButtonHandlers.onAddUsers(role)} />
              )}
              {renderDeleteButton()}
            </EllipsisPopupButton>
          </RestrictedByTooltip>
        )}
      </Table.Cell>
    </>
  );
};

export default RoleRow;
