import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { createFiltersSlice, type FiltersState } from "../../../../../Application/slices/createFiltersSlice";
import { type EmailTemplateFiltersEnum } from "../../types/models";

export const initialState: FiltersState<EmailTemplateFiltersEnum> = {
  filterOptions: {} as GenericFiltersMap<EmailTemplateFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<EmailTemplateFiltersEnum>,
  isLoading: false,
};

const emailTemplateFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
    name: "filters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  emailTemplateFiltersSlice.actions;

export default emailTemplateFiltersSlice.reducer;
