import { fetchAccountRoles } from "../../state/peopleActions";
import * as rolesOverviewActionTypes from "./rolesOverviewActionTypes";
import rolesDataService from "../../services/rolesDataService";
import dataService from "../../../Application/services/dataServices/dataService";
import {
  makeActionCreator,
  makeFetchActionCreatorForItems,
  makeFetchActionCreator,
} from "../../../Application/actions/actionsBuilder";

export const fetchRoles = (skip, top, orderProp, orderDirection, filter, searchText) =>
  fetchAccountRoles(
    { skip, top, orderProp, orderDirection, filter, searchText },
    rolesOverviewActionTypes.fetchRolesItemsActions,
  );

export const fetchPotentialUsers = (skipCount, loadCount, orderByParams, filterParams) =>
  makeFetchActionCreatorForItems(
    () => dataService.getUsers(skipCount, loadCount, orderByParams, filterParams),
    rolesOverviewActionTypes.fetchPotentialUsers,
  );

export const usersAddedToRole = (usersWithRoles) => {
  const updateUsersCountOnRole = makeActionCreator(rolesOverviewActionTypes.USERS_ADDED_TO_ROLE, "usersWithRoles");
  return updateUsersCountOnRole(usersWithRoles);
};

export const fetchRoleUsersAssignments = (roleIds) =>
  makeFetchActionCreatorForItems(
    () => rolesDataService.getRoleUsersAssignments(roleIds),
    rolesOverviewActionTypes.FETCH_ROLE_USERS_ASSIGNMENTS,
  );

export const fetchRoleUsersToAdd = (roleIds, skip, top, orderByParams, queryParams, search) =>
  makeFetchActionCreator(
    () =>
      search
        ? rolesDataService.getRolesUsersToAddV2(roleIds, skip, top, orderByParams, queryParams, search)
        : rolesDataService.getRolesUsersToAdd(roleIds, skip, top, orderByParams, queryParams),
    rolesOverviewActionTypes.FETCH_ROLES_USERS_TO_ADD,
  );

export const setAddUsersSearch = makeActionCreator(
  rolesOverviewActionTypes.PEOPLE_ROLES_USERS_TO_ADD_SET_SEARCH,
  "search",
);

export const resetAddUsersSearch = makeActionCreator(rolesOverviewActionTypes.PEOPLE_ROLES_USERS_TO_ADD_RESET_SEARCH);
