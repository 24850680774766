import { Label, type SemanticSIZES } from "semantic-ui-react";
import cn from "classnames";

import styles from "./Badge.module.scss";

interface Props {
  count: number;
  size?: SemanticSIZES;
}

export const Badge: React.FC<Props> = ({ count, size }) => {
  const labelSize = size || "medium";
  return (
    <div className={cn("badge", styles.root)}>
      <div className={styles.half} />
      <div className={styles.half}>
        <Label className="notifications-label" size={labelSize} circular>
          {count}
        </Label>
      </div>
    </div>
  );
};
