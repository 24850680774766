import React from "react";
import { ReactComponent as CloseIcon } from "../../../../images/close-circle.svg";
import BaseCircleIconButton from "../BaseCircleIconButton";
import PropTypes from "prop-types";

function CloseButton(props) {
  let { onClick } = props;
  const tooltipText = "Dismiss";

  let icon = <CloseIcon id="close-circle-button" onClick={onClick} />;

  return <BaseCircleIconButton icon={icon} tooltipText={tooltipText} />;
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default CloseButton;
