import AssetTypes from "../../../../../../../../enums/assetTypes";
import { type DesignerContextSwitcherOption } from "./models";

export const assetsOptions: DesignerContextSwitcherOption[] = [
  {
    label: "Video",
    iconName: "fa-video",
    key: AssetTypes.Video,
  },
  {
    label: "Survey",
    iconName: "list ul",
    key: AssetTypes.Survey,
  },
  {
    label: "Assessment",
    iconName: "list ol",
    key: AssetTypes.Assessment,
  },
  {
    label: "PDF",
    iconName: "fa-file-pdf",
    key: AssetTypes.Pdf,
  },
];
