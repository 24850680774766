import type ColumnOption from "../../../../interfaces/columnOptions";

const columnOptions: Array<ColumnOption> = [
  {
    name: "Title",
    width: 4,
    isSortable: true,
  },
  {
    name: "Status",
    width: 3,
    isSortable: true,
  },
  {
    name: "Added",
    width: 4,
    isSortable: true,
  },
  {
    name: "Modified",
    width: 4,
    isSortable: true,
  },
  {
    name: "Pack Type",
    width: 4,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default columnOptions;
