import { type RenderModalActionsParams } from "components/assignmentModals/commonSteps/packsContextStep/PacksContextStep";
import { useEffect } from "react";
import { type ModalWithStepsStep } from "../../../../../../components/modal/ModalWithSteps";

import "./deletePackItemsStep.scss";

export interface DeletePackItemsRenderModalActionsParams extends RenderModalActionsParams {
  onConfirm(): void;
}

export interface DeletePackItemsStepProps extends ModalWithStepsStep {
  onIsDataValidChange?: (isValid: boolean) => void;
}

function DeletePackItemsStep(props: DeletePackItemsStepProps) {
  const message =
    "You are removing content from a Pack that is published. Those who purchased Packs may be affected and any Performance data associated with the Pack will be lost. Do you want to remove this content and publish anyway?";
  const { onIsDataValidChange } = props;

  useEffect(() => {
    onIsDataValidChange && onIsDataValidChange(true);
  }, [onIsDataValidChange]);

  return <div className="delete-pack-items-step">{message}</div>;
}

export default DeletePackItemsStep;
