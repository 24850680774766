import midnightService from "../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/threatDefenceLandingPageEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { type ThreatDefenceLandingPage, type ThreatDefenceLandingPageContent } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { setLandingPageAction } from "./threatDefenceLandingPageDetailsActions";
import azureStorageUtils from "../../../../../../utils/azureStorageUtils";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import {
  LandingPageEditSuccess,
  LandingPageContentEditSuccess,
  LandingPagePublishSuccess,
  LandingPageLockSuccess,
  LandingPageDiscardSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createThreatDefenceLandingPage = (data: ThreatDefenceLandingPage) => {
  const begin = getActionBaseProvider(actionTypes.createThreatDefenceLandingPageBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceLandingPageSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceLandingPageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.LandingPages, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceLandingPageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishThreatDefenceLandingPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishThreatDefenceLandingPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: LandingPagePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.LandingPages, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceLandingPageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishThreatDefenceLandingPageSuccess,
);

export const updateThreatDefenceLandingPage = (metadata: ThreatDefenceLandingPage) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceLandingPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceLandingPageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: LandingPageEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceLandingPageAsync(metadata);
      dispatch(setLandingPageAction(metadata));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateThreatDefenceLandingPageContent = (data: ThreatDefenceLandingPageContent) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceLandingPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceLandingPageFailure);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceLandingPageSuccess);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: LandingPageContentEditSuccess }));
    try {
      await azureStorageUtils.uploadString(data.contentUrlWithSas, data.html);
      dispatch(success({ entityId: data.id }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateThreatDefenceLandingPageCommandSuccess = getActionProvider(
  actionTypes.updateThreatDefenceLandingPageSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const threatDefenceLandingPageUpdated = getActionProvider(
  actionTypes.threatDefenceLandingPageUpdated,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const resetThreatDefenceLandingPageEntityState = getActionBaseProvider(
  actionTypes.resetThreatDefenceLandingPageEntityState,
);

export const setThreatDefenceLandingPageEntityIdAction = getActionProvider<number>(actionTypes.setLandingPageEntityId);

export const fetchDiscardThreatDefenceLandingPageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardThreatDefenceLandingPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardThreatDefenceLandingPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: LandingPageDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.LandingPages, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchDraftThreatDefenceLandingPageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedThreatDefenceLandingPageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedThreatDefenceLandingPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: LandingPageLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.LandingPages, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedTdLandingPageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedThreatDefenceLandingPageSuccess,
);

export const fetchDiscardThreatDefenceLandingPageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchDiscardThreatDefenceLandingPageSuccess,
);
