import { type IntegrationDto, type IntegrationTypes } from "./types";
import routeNames from "../../../enums/routeNames";

export const overviewMoboPath = (moboId: string) => `/${routeNames.linkedAccountsManagement}/${moboId}/integrations`;
const buildLinked = (route: string, moboId: string) => `/${routeNames.linkedAccountsManagement}/${moboId}/${route}`;
const google: IntegrationDto = {
  title: "Google",
  url: (own: boolean, moboId?: string) =>
    own || moboId === undefined
      ? routeNames.googleIntegrationUrl
      : buildLinked(routeNames.subAccountGoogleIntegrationUrl, moboId),
};
const msGraph: IntegrationDto = {
  title: "Microsoft Graph",
  url: (own: boolean, moboId?: string) =>
    own ? routeNames.msGraphIntegrationUrl : buildLinked(routeNames.subAccountMsGraphIntegrationUrl, moboId!),
};
const webex: IntegrationDto = {
  title: "Webex",
  url: (own: boolean, moboId?: string) =>
    own ? routeNames.webexIntegrationUrl : buildLinked(routeNames.subAccountWebexIntegrationUrl, moboId!),
};
const viva: IntegrationDto = {
  title: "Microsoft Viva Learning",
  url: (own: boolean, moboId?: string) =>
    own ? routeNames.vivaIntegrationUrl : buildLinked(routeNames.subAccountVivaIntegrationUrl, moboId!),
};
const sf: IntegrationDto = {
  title: "SuccessFactors",
  url: (own: boolean, moboId?: string) =>
    own ? routeNames.sfIntegrationUrl : buildLinked(routeNames.subAccountSfIntegrationUrl, moboId!),
};

export const IntegrationDtoMap: {
  [key in IntegrationTypes]: IntegrationDto;
} = {
  google: google,
  msgraph: msGraph,
  webex: webex,
  viva: viva,
  successfactors: sf,
};
