import * as groupAssignmentActionTypes from "../actionTypes/groupAssignmentActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { type Dispatcher } from "../../../../../interfaces/redux";
import { type Filters } from "../../../../../utils/queryUtils";
import DataService from "../../../../Application/services/dataServices/typedDataService";

const fetchGroupsBegin = makeActionCreator(groupAssignmentActionTypes.LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_BEGIN);
const fetchGroupsSuccess = makeActionCreator(
  groupAssignmentActionTypes.LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_SUCCESS,
  "items",
  "itemsCount",
);
const fetchGroupsFailure = makeActionCreator(
  groupAssignmentActionTypes.LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_FAILURE,
  "error",
);

export const fetchGroups = (skip: number, top: number, orderBy: string, filterParams: Filters) => {
  const { filter } = filterParams ?? {};

  return async (dispatch: Dispatcher) => {
    dispatch(fetchGroupsBegin());
    try {
      const result = await DataService.groups.getGroups({ skip, top, orderBy, filter });
      let recordsCount = result.count;

      dispatch(fetchGroupsSuccess(result.items, recordsCount));
    } catch (error) {
      dispatch(fetchGroupsFailure(error));
    }
  };
};

export const setFilter = makeActionCreator(
  groupAssignmentActionTypes.LIBRARY_GROUP_ASSIGNMENTS_SET_ITEMS_FILTER,
  "filter",
);
export const resetFilter = makeActionCreator(groupAssignmentActionTypes.LIBRARY_GROUP_ASSIGNMENTS_RESET_ITEMS_FILTER);
