import * as actionTypes from "./flowValidatorActionTypes";
import { v4 } from "uuid";
import { createReducer } from "../../../../../utils/reduxUtils";

interface Action<T> {
  type: string;
  payload: T;
}

export enum ValidatorErrorTypes {
  WithinError = "WithinError",
  StartError = "StartError",
  ItemError = "ItemError",
  TriggerError = "TriggerError",
  FlowEndError = "FlowEndError",
  SectionHeaderNameError = "SectionHeaderNameError",
  ConnectionActionTriggersError = "ConnectionActionTriggersError",
  SectionHeaderDescriptionError = "SectionHeaderDescriptionError",
  DeletedItemError = "DeletedItemError",
}

interface FlowValidatorErrorBase {
  type: ValidatorErrorTypes;
  id: string; // here id is always the same from the same error data
}

export interface IFixedError {
  type: ValidatorErrorTypes;
  id: string; // here id is always unique (generated via v4())
}
export interface WithinError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.WithinError;
  inId: string;
  outId: string;
  errorMessage: string;
}
export interface FlowError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.StartError;
  errorMessage: string;
}
export interface ItemError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.ItemError;
  itemId: string;
  errorMessage: string;
}

export interface ConnectionActionTriggersError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.ConnectionActionTriggersError;
  itemId: string;
  errorMessage: string;
}

export interface TriggerError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.TriggerError;
  itemId: string;
  errorMessage: string;
}

export interface FlowEndError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.FlowEndError;
  itemId?: string;
  errorMessage: string;
}

export interface SectionHeaderNameError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.SectionHeaderNameError;
  itemId: string;
  errorMessage: string;
}

export interface SectionHeaderDescriptionError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.SectionHeaderDescriptionError;
  itemId: string;
  errorMessage: string;
}

export interface DeletedItemError extends FlowValidatorErrorBase {
  type: ValidatorErrorTypes.DeletedItemError;
  itemId: string;
  errorMessage: string;
}

export type FlowValidationError =
  | WithinError
  | FlowError
  | ItemError
  | TriggerError
  | SectionHeaderNameError
  | SectionHeaderDescriptionError
  | FlowEndError
  | ConnectionActionTriggersError
  | DeletedItemError;
export interface FlowValidatorState {
  isFlowValid: boolean;
  areErrorsResolved: boolean;
  isErrorViewMode: boolean;
  showErrorMessagesBar: boolean;

  // all errors
  currentItemsMap: { [id: string]: true };
  currentErrors: FlowValidationError[];

  // visible errors
  errorViewItemsMap: { [id: string]: true };
  errorViewErrors: FlowValidationError[];
  fixedErrors: IFixedError[];
}

const initialState: FlowValidatorState = {
  isFlowValid: false,
  areErrorsResolved: false,
  isErrorViewMode: false,
  showErrorMessagesBar: false,
  currentItemsMap: {},
  errorViewItemsMap: {},
  currentErrors: [],
  errorViewErrors: [],
  fixedErrors: [],
};

function pushFixedErrors(oldErrors: FlowValidationError[], newErrors: FlowValidationError[]): IFixedError[] {
  return oldErrors
    .filter((old) => {
      if (newErrors.find((newErr) => old.id === newErr.id) === undefined) {
        return true;
      }
      return false;
    })
    .map((err) => ({ type: err.type, id: v4() }));
}

const flowValidationHandlers = () => {
  const { setIsFlowValid, setIsErrorModeEnabled, setErrorsResolved, reset, showErrorMessagesBar, removeFixedError } =
    actionTypes;

  const setIsFlowValidHandler = (
    state: FlowValidatorState,
    value: Action<{
      isFlowValid: boolean;
      currentItemsMap: FlowValidatorState["currentItemsMap"];
      currentErrors: FlowValidationError[];
      errorViewErrors: FlowValidationError[];
    }>,
  ): FlowValidatorState => ({
    ...state,
    isFlowValid: value.payload.isFlowValid,
    currentItemsMap: value.payload.currentItemsMap,
    currentErrors: value.payload.currentErrors,
    errorViewErrors: value.payload.errorViewErrors,
    fixedErrors: [...state.fixedErrors, ...pushFixedErrors(state.errorViewErrors, value.payload.errorViewErrors)],
  });

  const setIsErrorModeEnabledHandler = (
    state: FlowValidatorState,
    value: Action<{ isErrorViewMode: boolean }>,
  ): FlowValidatorState => ({
    ...state,
    areErrorsResolved: false,
    isErrorViewMode: value.payload.isErrorViewMode,
    errorViewItemsMap: value.payload.isErrorViewMode ? { ...state.currentItemsMap } : state.errorViewItemsMap,
    errorViewErrors: value.payload.isErrorViewMode ? [...state.currentErrors] : state.errorViewErrors,
  });

  const setErrorsResolvedHandler = (
    state: FlowValidatorState,
    value: Action<{
      areErrorsResolved: boolean;
    }>,
  ): FlowValidatorState => ({
    ...state,
    areErrorsResolved: value.payload.areErrorsResolved,
  });

  const showErrorMessagesBarHandler = (
    state: FlowValidatorState,
    value: Action<{
      showErrorMessagesBar: boolean;
    }>,
  ): FlowValidatorState => ({
    ...state,
    showErrorMessagesBar: value.payload.showErrorMessagesBar,
  });
  const removeFixedErrorHandler = (
    state: FlowValidatorState,
    value: Action<{
      id: string;
    }>,
  ): FlowValidatorState => ({
    ...state,
    fixedErrors: state.fixedErrors.filter((err) => err.id !== value.payload.id),
  });

  const resetHandler = (): FlowValidatorState => initialState;

  return {
    [setIsFlowValid]: setIsFlowValidHandler,
    [setIsErrorModeEnabled]: setIsErrorModeEnabledHandler,
    [setErrorsResolved]: setErrorsResolvedHandler,
    [showErrorMessagesBar]: showErrorMessagesBarHandler,
    [removeFixedError]: removeFixedErrorHandler,
    [reset]: resetHandler,
  };
};

export const flowValidatorReducer = createReducer(initialState, [flowValidationHandlers]);
