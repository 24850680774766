import React from "react";
import NoResults from "../../../components/noResults/NoResults";
import NoSearchResults from "../../../components/noSearchResults";

export interface PackVisibilityForAccountsNotFoundProps {
  filtered: boolean;
  addAccountsButton?: React.ReactElement | null;
  isPublicToAll?: boolean;
}

const PackVisibilityForAccountsNoResults: React.FC<PackVisibilityForAccountsNotFoundProps> = ({
  addAccountsButton,
  filtered,
  isPublicToAll,
}) => {
  const addAccountsButtonText = addAccountsButton ? "You can customize it by clicking the button below." : "";

  return filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults
      title={isPublicToAll ? "Not Customized: Public to All" : "No Accounts Selected"}
      description={
        <span>
          Visibility not yet customized for specific accounts. <br />
          {addAccountsButtonText}
        </span>
      }
      iconClassName="building"
    >
      {addAccountsButton}
    </NoResults>
  );
};

export default PackVisibilityForAccountsNoResults;
