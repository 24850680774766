import { BRANCHING_QUESTION_ANSWERS_MAX_COUNT } from "../../../../types/constants";
import { SurveyQuestionType } from "../../../../types/models";
import { allowedBranchingQuestionTypes, getAnswersCount, isAllowedBranchingQuestionType } from "../../utils";
import { type ISurveyQuestion } from "../types";

const labelToType: { [type in SurveyQuestionType]: string } = {
  [SurveyQuestionType.MultipleChoiceTextQuestion]: "Multi-Select",
  [SurveyQuestionType.SingleChoiceTextQuestion]: "Single-Select",
  [SurveyQuestionType.PictureQuestion]: "Picture",
};

export const getBranchingQuestionTooltipMessage = (question: ISurveyQuestion<any>) => {
  if (!isAllowedBranchingQuestionType(question.questionType)) {
    const allowedTypes = allowedBranchingQuestionTypes
      .map((x) => `"${labelToType[x]}"`)
      .join(", ")
      .trim();

    return `A "Branching Question" toggle is supported for ${allowedTypes} Question Type(s) only`;
  }

  const count = getAnswersCount(question);
  if (count > BRANCHING_QUESTION_ANSWERS_MAX_COUNT) {
    return `Branching questions can have no more than ${BRANCHING_QUESTION_ANSWERS_MAX_COUNT} answers. Remove (${
      count - BRANCHING_QUESTION_ANSWERS_MAX_COUNT
    }) answer(s) to enable this toggle.`;
  }

  return "";
};
