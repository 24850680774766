import { type ColumnOptions } from "interfaces";

export const getUserColumnOptions = (): ColumnOptions[] => [
  {
    name: "Name",
    width: 6,
    isSortable: true,
  },
  {
    name: "Department",
    width: 4,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 4,
    isSortable: true,
  },
  {
    name: "Added",
    width: 2,
    isSortable: true,
  },
];
