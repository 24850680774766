enum UserSource {
  None = 0,
  Manual = 1,
  Sso = 2,
  MsGraph = 3,
  Google = 4,
  Zoom = 5,
}

export default UserSource;
