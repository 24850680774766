import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import cn from "classnames";
import get from "lodash/get";

import "../validatedField.scss";

import ValidationErrorPromptExperimental from "./ValidationErrorPromptExperimental";

class ValidatedFieldExperimental extends React.Component {
  componentDidUpdate(prevProps) {
    const { isFormValid, onIsFormValidChange } = this.props;
    if (prevProps.isFormValid !== isFormValid) {
      onIsFormValidChange && onIsFormValidChange(isFormValid);
    }
  }

  render() {
    const { children, label, propertyName, errors, touched, markAsRequired, inputRef } = this.props;
    const isInErrorState = get(errors, propertyName) && isPropertyTouched(touched, propertyName);
    const labelClasses = {
      "validated-field-label": true,
      error: isInErrorState,
      "required-asterisk": markAsRequired,
    };
    return (
      <>
        {label && <label className={cn(labelClasses)}>{label}</label>}
        <Form.Field error={isInErrorState} className="validated-field">
          {children}
          <ValidationErrorPromptExperimental
            text={get(errors, propertyName)}
            open={isInErrorState}
            context={inputRef}
          />
        </Form.Field>
      </>
    );
  }
}

function isPropertyTouched(touched, propertyName) {
  let value = get(touched, propertyName);
  // To work around the issue with multivalue field validation:
  // https://github.com/jaredpalmer/formik/issues/793
  if (value instanceof Array && value.length === 0) {
    return true;
  }
  // Otherwise should work fine
  return !!value;
}

ValidatedFieldExperimental.defaultProps = {
  placeholder: "",
};

ValidatedFieldExperimental.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  markAsRequired: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  propertyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isFormValid: PropTypes.bool,
  onIsFormValidChange: PropTypes.func,
};

export default ValidatedFieldExperimental;
