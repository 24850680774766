import { useSelector } from "react-redux";
import intersection from "lodash/intersection";
import { useMemo } from "react";
import { type RolePermissions } from "../../../enums";
import { type RootState } from "../globaltypes/redux";

export const hasPermissionsPredicate = (
  userPermissionsList: RolePermissions[],
  permissionsList: RolePermissions | RolePermissions[] = [],
) => intersection(userPermissionsList, Array.isArray(permissionsList) ? permissionsList : [permissionsList]).length > 0;

export const useRestrictedCheck = (
  permissions: RolePermissions | RolePermissions[],
  permissionPredicate?: typeof hasPermissionsPredicate,
) => {
  const cb = permissionPredicate ?? hasPermissionsPredicate;
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  const hasAnyPermission = useMemo(() => {
    return cb(userPermissions, permissions);
  }, [cb, permissions, userPermissions]);

  return hasAnyPermission;
};
