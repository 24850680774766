import PropTypes from "prop-types";

const assetInfoPropTypes = PropTypes.shape({
  id: PropTypes.number,
  published: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  labels: PropTypes.array,
  softwareApplications: PropTypes.array,
});

export default assetInfoPropTypes;
