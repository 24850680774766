import * as actionTypes from "./editUserActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { type EditUserInfoState, type UserInfoAction } from "../../../types";
import { type AxiosError } from "axios";

const initialState: EditUserInfoState = {
  info: {},
  isLoading: false,
  error: null,
  isAccessAuthorized: true,
};

const getUserInfoHandlers = () => {
  const {
    FETCH_USER_INFO_BEGIN,
    FETCH_USER_INFO_FAILURE,
    FETCH_USER_INFO_SUCCESS,
    FETCH_USER_INFO_FORBIDDEN,
    RESET_USER_INFO,
  } = actionTypes;

  const getUserInfoBegin = (state: EditUserInfoState) => {
    return { ...state, error: null, isLoading: true, isAccessAuthorized: true };
  };

  const getUserInfoSuccess = (state: EditUserInfoState, action: UserInfoAction<EditUserInfoState>) => {
    const info = action.payload.info;

    return {
      ...state,
      info: { ...info, jobTitle: info?.title },
      isLoading: false,
    };
  };

  const getUserInfoFailure = (state: EditUserInfoState, action: UserInfoAction<Error>) => {
    return {
      ...state,
      error: action.payload.message,
      isLoading: false,
    };
  };

  const getUserInfoForbidden = (state: EditUserInfoState, action: UserInfoAction<AxiosError>) => {
    return {
      ...state,
      error: action.payload.message,
      isLoading: false,
      isAccessAuthorized: false,
    };
  };

  const resetUserInfo = () => {
    return { ...initialState };
  };

  return {
    [FETCH_USER_INFO_BEGIN]: getUserInfoBegin,
    [FETCH_USER_INFO_SUCCESS]: getUserInfoSuccess,
    [FETCH_USER_INFO_FAILURE]: getUserInfoFailure,
    [FETCH_USER_INFO_FORBIDDEN]: getUserInfoForbidden,
    [RESET_USER_INFO]: resetUserInfo,
  };
};

const editUserInfoHandlers = () => {
  const { UPDATE_USER_INFO } = actionTypes;

  const updateUserInfo = (state: EditUserInfoState, action: UserInfoAction<EditUserInfoState>) => {
    const info = action.payload.info;

    return {
      ...state,
      info: { ...info },
    };
  };

  return {
    [UPDATE_USER_INFO]: updateUserInfo,
  };
};

export const editUserInfoReducer = createReducer(initialState, [editUserInfoHandlers, getUserInfoHandlers]);
