import { type FC } from "react";
import { Icon } from "semantic-ui-react";
import cs from "classnames";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { Tooltip } from "../../common/tooltip";
import { ContentType, PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { PriorityLevels } from "../../../enums";
import { pluralize } from "utils/stringUtils";

import "./PriorityCell.scss";

export interface PriorityCellProps {
  inherited?: boolean;
  peopleType: PeopleType;
  contentType: ContentType;
  priorityLevel: PriorityLevels;
  daysToComplete?: number;
  dueDate?: Nullable<string>;
}

const toString = (type: ContentType | PeopleType) =>
  ({
    [ContentType.Assessment]: "Assessment",
    [ContentType.Survey]: "Survey",
    [ContentType.Video]: "Video",
    [ContentType.Pdf]: "PDF",
    [ContentType.Flow]: "Flow",
    [ContentType.Event]: "Event",
    [PeopleType.User]: "User",
    [PeopleType.Group]: "Group",
    [PeopleType.TemplateGroup]: "Template",
  })[type];

const getPriorityTooltipContent = (peopleType: PeopleType, contentType: ContentType): string =>
  `This ${toString(peopleType)} has access to this ${toString(
    contentType,
  )} from another source and cannot be removed from here.`;

export const PriorityCell: FC<PriorityCellProps> = ({
  inherited,
  priorityLevel,
  peopleType,
  contentType,
  dueDate,
  daysToComplete,
}) => {
  if (!inherited) {
    return getPriorityCell(priorityLevel, dueDate, daysToComplete, peopleType);
  }
  return (
    <span className={cs("priority-cell", { disabled: inherited })}>
      <Tooltip
        showAlways
        inverted
        hideOnScroll
        position="top center"
        size="tiny"
        target={<Icon className="priority-icon fa-exclamation-circle big" data-testid="exclamation-circle-icon" />}
        content={getPriorityTooltipContent(peopleType, contentType)}
        tooltipClassName="list-priority-tooltip"
      />
      <i>Inherited</i>
    </span>
  );
};

const getPriorityLabel = (priority: string) => <span data-name="priority-level-cell">{priority}</span>;
const getDueDateValue = (
  dueDate: string | undefined | null,
  daysToComplete: number | undefined,
  peopleType: PeopleType,
) => {
  switch (peopleType) {
    case PeopleType.User:
      return dateTimeUtils.formatDate(dueDate);
    case PeopleType.Group:
      return daysToComplete != null
        ? `${daysToComplete} ${pluralize("day", daysToComplete)}`
        : dateTimeUtils.formatDate(dueDate);
  }
};

const getTooltipText = (
  dueDate: string | undefined | null,
  daysToComplete: number | undefined,
  peopleType: PeopleType,
) => {
  switch (peopleType) {
    case PeopleType.User:
      return `This Flow must be completed by ${dateTimeUtils.formatDate(dueDate)}.`;
    case PeopleType.Group:
      return daysToComplete != null
        ? `This Flow must be completed within ${daysToComplete} ${pluralize("day", daysToComplete)} of users being added to the group.`
        : `This Flow must be completed by ${dateTimeUtils.formatDate(dueDate)}.`;
  }
};

const getPriorityCell = (
  priority: PriorityLevels,
  dueDate: string | undefined | null,
  daysToComplete: number | undefined,
  peopleType: PeopleType,
) => {
  switch (priority) {
    case PriorityLevels.none:
      return <span className="priority-cell">{getPriorityLabel("None")}</span>;
    case PriorityLevels.recommended:
      return (
        <span className="priority-cell">
          <Icon className="priority-icon fa-arrow-square-down big" />
          {getPriorityLabel("Recommended")}
        </span>
      );
    case PriorityLevels.important:
      return (
        <span className="priority-cell">
          <Icon className="priority-icon fa-arrow-square-up big" />
          {getPriorityLabel("Important")}
        </span>
      );
    case PriorityLevels.required:
      return (
        <Tooltip
          showAlways
          inverted
          hideOnScroll
          position="top center"
          size="tiny"
          content={getTooltipText(dueDate, daysToComplete, peopleType)}
          target={
            <span className="priority-cell">
              <Icon className="priority-icon fa-exclamation-square big" />
              {getPriorityLabel("Required")}
              <span className="pre-warp"> - </span>
              <span data-name="due-date-cell">{getDueDateValue(dueDate, daysToComplete, peopleType)}</span>
            </span>
          }
        />
      );
    default:
      return null;
  }
};

export default PriorityCell;
