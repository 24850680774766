import { type GroupedBarProps, TickValueType } from "components/charts";
import {
  completesColor,
  startsColor,
  transitionTime,
  groupedBarChartMargins
} from "features/Library/Common/utils/performanceUtils";

export const COLOR_SCALE = [startsColor, completesColor];

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sharedGoogleReportingBarProps: Partial<GroupedBarProps<object>> = {
  margins: groupedBarChartMargins,
  yTickValueType: TickValueType.IntegersOnly,
  yTickFormatter: (d) => d.toLocaleString(),
  transitionDurationInMs: transitionTime,
  colorRange: COLOR_SCALE,
  groupPadding: 0.6,
  innerPadding: 0.01,
  tooltipFormatter: (d) => `${capitalizeFirstLetter(d.group)}: ${d.value}`,
};

export type ChartState = "line" | "column";

export const handleDropdownChange = (dropdownValue: string, setFunc: (newState: ChartState) => void) => {
  dropdownValue.indexOf("Column") === -1 ? setFunc("line") : setFunc("column");
};
