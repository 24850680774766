import { NoResultsWithButton } from "../../../../../components";

export interface GroupsNoResultsProps {
  description?: string;
  createGroupButton?: React.ReactElement;
  filtered?: boolean;
}

const GroupsNoResults: React.FC<GroupsNoResultsProps> = ({
  createGroupButton,
  filtered,
  description = "Looks like you don’t have any groups.",
}) => {
  return (
    <NoResultsWithButton
      title="You don’t have any groups"
      description={description}
      iconClassName="fal fa-users"
      actionButton={createGroupButton}
      filtered={filtered}
    />
  );
};

export default GroupsNoResults;
