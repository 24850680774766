import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import environmentConfig from "configuration/environmentConfig";
import { CopyableInput, FormSectionHeader } from "../../../components";
import ValidatedForm from "../../../components/forms/ValidatedFormWithoutFormik";
import FeatureFlag from "../../../components/featureFlag/FeatureFlag";
import ValidatedField from "components/forms/ValidatedField";
import { EndpointsToGetSasLink, RolePermissions } from "../../../enums";
import ImageAspectRatio from "../../../enums/imageAspectRatio";
import { FeatureFlags } from "../../../featureFlags";
import nameof from "../../../utils/nameof";
import { ensureTrimmed } from "../../../utils/stringUtils";
import { getAccountSchema } from "../../../utils/validationSchemas/accountValidationSchema";
import { type AccountConfigurationInfo, type AccountType, type ContractType, type ParentAccount } from "../types";
import Tooltip from "../../../components/common/tooltip/Tooltip";
import styles from "./accountConfiguration.module.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { TimezoneDropdown } from "features/Library/Common/Fields/timezone-dropdown/TimezoneDropdown";
import { WithFormikPayload } from "features/Library/Common/Fields/WithFormikPayload";
import { AccountInfoLayout } from "./AccountInfoLayout";
import { useRestrictedCheck } from "features/Application/hooks";

export interface AccountConfigurationProps {
  onIsValidChange: (isValid: boolean) => void;
  onDirtyChanged: (isDirty: boolean) => void;
  accountTypes: AccountType[];
  contractTypes: ContractType[];
  parentAccounts: ParentAccount[];
  isLoadingAccountTypes?: boolean;
  isLoadingContractTypes?: boolean;
  isLoadingParentAccounts?: boolean;
  isOwnAccount?: boolean;
  isEdit?: boolean;
  accountId?: number;
  onFetchParentAccounts?: (accountTypeId: number) => void;
  currentConfiguration?: AccountConfigurationInfo;
  isMobo: boolean;
  hasGlobalAccess: boolean;
  bindResetForm?: (resetForm: () => void) => void;
  bindSubmitForm?: (submitForm: () => void) => void;
  onSubmit: (info: AccountConfigurationInfo) => void;
  showAccountId?: boolean;
  prefilled?: {
    name: string;
    logoUri?: Nullable<string>;
  };
  onRemoveName?: () => void;
}

export const AccountConfiguration = (props: AccountConfigurationProps) => {
  const {
    currentConfiguration,
    isMobo,
    hasGlobalAccess,
    parentAccounts,
    accountId,
    isEdit,
    onFetchParentAccounts,
    isOwnAccount,
    prefilled,
  } = props;
  const { name, alias, canChangeAccountType, canEdit } = currentConfiguration || {};
  const hasCreatePermissions = useRestrictedCheck(RolePermissions.AccountsSettingsCreate);

  const isRequiredParent = !!isEdit && (isMobo || !hasGlobalAccess) && !isOwnAccount;
  const hideParent = !hasCreatePermissions && !isEdit;
  const accountTimeZone = useFeatureFlag(FeatureFlags.AccountTimezone);
  const accountDuplication = useFeatureFlag(FeatureFlags.AccountDuplication);

  const schema = useMemo(() => {
    return getAccountSchema(isRequiredParent, name, alias, isEdit);
  }, [alias, isRequiredParent, name, isEdit]);

  const defaultAccountConfiguration = useMemo(() => {
    return defaultAccount(
      hasCreatePermissions && !hasGlobalAccess ? accountId : null,
      prefilled?.name,
      prefilled?.logoUri,
    );
  }, [hasCreatePermissions, hasGlobalAccess, accountId, prefilled?.name, prefilled?.logoUri]);

  const formik = useFormik<Partial<AccountConfigurationInfo>>({
    initialValues: isEmpty(currentConfiguration) ? defaultAccountConfiguration : currentConfiguration,
    onSubmit: (values: Partial<AccountConfigurationInfo>) => {
      const casted = schema.cast(values) as AccountConfigurationInfo;

      props.onSubmit(casted);
    },
    validationSchema: schema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const setTimeoutFieldValue = (name: string, value: any, shouldValidate?: boolean) => {
    formik.setTouched({
      sessionTimeoutDays: true,
      sessionTimeoutHours: true,
      sessionTimeoutMinutes: true,
      sessionTimeoutInMinutes: true,
    });

    return formik.setValues((prev) => {
      const result: Partial<AccountConfigurationInfo> = { ...prev, [name]: value };
      result.sessionTimeoutInMinutes = moment
        .duration({
          days: result.sessionTimeoutDays,
          hours: result.sessionTimeoutHours,
          minutes: result.sessionTimeoutMinutes,
        })
        .asMinutes();
      return result;
    }, shouldValidate);
  };

  const { dirty, setFieldValue, values, isValid, submitForm, resetForm } = formik;
  const { parentAccountId, accountTypeId } = values;
  const { onDirtyChanged, isLoadingParentAccounts, onIsValidChange, bindResetForm, bindSubmitForm } = props;

  const parents = useMemo(() => {
    return {
      hash: new Set(parentAccounts.map((x) => x.id)),
      options: getOptions(parentAccounts),
    };
  }, [parentAccounts]);

  useEffect(() => {
    if (bindResetForm) {
      bindResetForm(resetForm);
    }
  }, [bindResetForm, resetForm]);

  useEffect(() => {
    if (bindSubmitForm) {
      bindSubmitForm(submitForm);
    }
  }, [bindSubmitForm, submitForm]);

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  useEffect(() => {
    onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    if (!accountTypeId || accountTypeId <= 0 || (isEdit && (!hasGlobalAccess || isOwnAccount))) {
      return;
    }
    onFetchParentAccounts?.(accountTypeId);
  }, [accountTypeId, hasGlobalAccess, isOwnAccount, isEdit, onFetchParentAccounts]);

  useEffect(() => {
    if (isOwnAccount) return;
    if (parentAccountId && parentAccountId > 0 && !parents.hash.has(parentAccountId)) {
      setFieldValue(nameof<AccountConfigurationInfo>("parentAccountId"), null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parents.hash]);

  const getInfoForm = (isDisabled: boolean) => {
    const { name, logoUri } = formik.values;
    const image = (
      <ValidatedForm.ImagePreview
        value={logoUri}
        propertyName={nameof<AccountConfigurationInfo>("logoUri")}
        label="Logo"
        placeholder="Logo"
        endpointToGetSasLink={EndpointsToGetSasLink.Account}
        disabled={isEdit && !canEdit}
        isHidden
        aspectRatio={ImageAspectRatio.SQUARE_1X1}
        shouldValidate={false}
        {...formik}
      />
    );

    const handleDuplication = !isEdit && accountDuplication;

    const inputProps = handleDuplication
      ? {
          setFieldValue: (_: any, value: string) => {
            if (isEmpty(value)) {
              props.onRemoveName?.();
            }
          },
        }
      : formik;
    return (
      <AccountInfoLayout
        image={image}
        name={
          <ValidatedForm.InputField
            label="Company Name"
            value={name}
            propertyName={nameof<AccountConfigurationInfo>("name")}
            placeholder="Enter Company Name"
            markAsRequired
            disabled={isDisabled}
            clearable={handleDuplication}
            onBlur={() =>
              ensureTrimmed(name, (value) => setFieldValue(nameof<AccountConfigurationInfo>("name"), value))
            }
            {...inputProps}
          />
        }
      />
    );
  };

  const renderBody = () => {
    const { isLoadingAccountTypes, accountTypes, isOwnAccount, contractTypes, isLoadingContractTypes, showAccountId } =
      props;
    const {
      accountTypeId,
      alias,
      contractTypeId,
      contractTypeName,
      isDemo,
      magicLinkLogin,
      sessionTimeoutDays,
      sessionTimeoutHours,
      sessionTimeoutMinutes,
      sessionTimeoutInMinutes,
      timeZone,
    } = formik.values;

    const isNonExistentContractType = !contractTypes.some((contract) => contract.id === contractTypeId);

    const isDisabled = !!isEdit && (!canEdit || !!isOwnAccount);

    const accountTypesOptions = getOptions(accountTypes);

    return (
      <>
        <FormSectionHeader title="Account Information" />
        {showAccountId && (
          <ValidatedField propertyName="" label="Account ID">
            <CopyableInput value={accountId!.toString()} readonly />
          </ValidatedField>
        )}
        <ValidatedForm.DropdownField
          placeholder="Select Account Type"
          label="Account Type"
          value={accountTypeId}
          propertyName={nameof<AccountConfigurationInfo>("accountTypeId")}
          className={styles["account-type"]}
          options={accountTypesOptions}
          isLoading={isLoadingAccountTypes}
          disabled={isLoadingAccountTypes || (isEdit && (!canChangeAccountType || !canEdit || isOwnAccount))}
          markAsRequired
          {...formik}
        >
          {hasGlobalAccess && (
            <div className={styles.demo}>
              <ValidatedForm.CheckboxField
                toggle
                propertyName={nameof<AccountConfigurationInfo>("isDemo")}
                value={isDemo}
                fieldClassName={styles.demo__toggle}
                disabled={isDisabled}
                {...formik}
              />
              Demo
            </div>
          )}
        </ValidatedForm.DropdownField>
        {!hideParent && (
          <ValidatedForm.DropdownField
            placeholder="Select Parent Account"
            label="Parent Account"
            value={parentAccountId}
            minCharacters={3}
            propertyName={nameof<AccountConfigurationInfo>("parentAccountId")}
            options={parents.options}
            isLoading={isLoadingParentAccounts}
            disabled={isLoadingParentAccounts || !accountTypeId || (isEdit && (!hasGlobalAccess || isOwnAccount))}
            search
            clearable
            markAsRequired={isRequiredParent}
            {...formik}
          />
        )}
        {getInfoForm(isDisabled)}
        <ValidatedForm.InputField
          label="Account Alias"
          value={alias}
          propertyName={nameof<AccountConfigurationInfo>("alias")}
          placeholder={isEdit ? undefined : "e.g. company"}
          disabled={isDisabled}
          onBlur={() =>
            ensureTrimmed(alias, (value) => setFieldValue(nameof<AccountConfigurationInfo>("alias"), value))
          }
          {...formik}
        />
        <FormSectionHeader title="Contract Information" />
        <ValidatedForm.DropdownField
          text={isNonExistentContractType ? contractTypeName : ""}
          placeholder="Select One"
          label="Contract Type"
          value={contractTypeId}
          propertyName={nameof<AccountConfigurationInfo>("contractTypeId")}
          options={getOptions(contractTypes)}
          isLoading={isLoadingContractTypes}
          disabled={isLoadingContractTypes || isDisabled}
          markAsRequired
          {...formik}
        />
        <FormSectionHeader title="Authentication Settings" />
        <FeatureFlag flag={FeatureFlags.MagicLogin}>
          <div className={styles["magic-login"]}>
            <ValidatedForm.CheckboxField
              toggle
              propertyName={nameof<AccountConfigurationInfo>("magicLinkLogin")}
              value={magicLinkLogin}
              fieldClassName={styles["magic-login__toggle"]}
              disabled={isEdit && !canEdit}
              {...formik}
            />
            <label
              htmlFor={nameof<AccountConfigurationInfo>("magicLinkLogin")}
              className={styles["magic-login__label"]}
            >
              Enable Magic Link Authentication
            </label>
            <Tooltip
              tooltipClassName={styles["magic-login__tooltip"]}
              className={styles["magic-login__tooltip-target"]}
              target={<Icon className="info circle" />}
              content={magicLoginTooltip}
              position="top center"
              hoverable
              hideOnScroll
              showAlways
            />
          </div>
        </FeatureFlag>
        {isEdit && (
          <>
            <div className={styles["section-header"]}>
              <FormSectionHeader title="Session Timeout" />
              <Tooltip
                tooltipClassName={styles["section-header__tooltip"]}
                className={styles["section-header__tooltip-target"]}
                target={<Icon className="info circle" />}
                content={sessionTimeoutTooltip}
                position="top center"
                hoverable
                hideOnScroll
                showAlways
              />
            </div>
            <div className={styles["session-timeout"]}>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Days"
                  value={sessionTimeoutDays}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutDays")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Hours"
                  value={sessionTimeoutHours}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutHours")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  label="Minutes"
                  value={sessionTimeoutMinutes}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutMinutes")}
                  disabled={!canEdit}
                  errorPosition={"top"}
                  inputType={"number"}
                  {...formik}
                  setFieldValue={setTimeoutFieldValue}
                />
              </div>
              <div className={styles["timeout-input"]}>
                <ValidatedForm.InputField
                  className={styles["total-minutes"]}
                  value={sessionTimeoutInMinutes}
                  propertyName={nameof<AccountConfigurationInfo>("sessionTimeoutInMinutes")}
                  disabled={!canEdit}
                  {...formik}
                />
              </div>
            </div>
          </>
        )}
        {accountTimeZone && (
          <>
            <div className={styles["section-header"]}>
              <FormSectionHeader title="DEFAULT TIMEZONE" />
              <Tooltip
                tooltipClassName={styles["section-header__tooltip"]}
                className={styles["section-header__tooltip-target"]}
                target={<Icon className="info circle" />}
                content={
                  "Sets the default timezone for this account. End-users can override it in their individual settings."
                }
                position="top center"
                hoverable
                hideOnScroll
                showAlways
              />
            </div>
            <WithFormikPayload
              propertyName={nameof<AccountConfigurationInfo>("timeZone")}
              {...formik}
              render={(props) => (
                <TimezoneDropdown
                  label="Timezone"
                  placeholder="Default is UTC. Select to customize for this account."
                  setFieldTouched={formik.setFieldTouched}
                  setFieldValue={formik.setFieldValue}
                  value={timeZone}
                  clearable
                  disabled={isEdit && !canEdit}
                  {...props}
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <section className="account-info-form">
      <ValidatedForm
        loaderOnSubmit={!isEdit}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this account? All information entered will be lost.",
        }}
        {...formik}
      >
        {renderBody()}
      </ValidatedForm>
    </section>
  );
};

const defaultAccount = (
  parentAccountId?: number | null,
  name?: string,
  logoUri?: Nullable<string>,
): Partial<AccountConfigurationInfo> => {
  return {
    name: name || "",
    isDemo: false,
    magicLinkLogin: false,
    parentAccountId: parentAccountId,
    logoUri: logoUri,
  };
};

const getOptions = (options: { id: number; name: string }[]) => {
  return options.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.name,
  }));
};

const magicLoginTooltip = (
  <span>
    Activate this feature to offer users a login link via email for a simplified login process.&nbsp;
    <a href={environmentConfig.helpCenterUrl} target="_blank" rel="noopener noreferrer">
      Visit our Help Center
    </a>
    &nbsp;for more information.
  </span>
);

const sessionTimeoutTooltip = <span>Adjust the session timeout duration. Minimum: 10 minutes. Maximum: 365 days.</span>;
