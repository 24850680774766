import {
  createCancelingLazyFetchingItemsSlice,
  type CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import { type PackOverview } from "../../types/state";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchavAilablePacks } from "../thunks/availablePacksThunk";

export type OverviewState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: OverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const availablePacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AvailablePacks,
    name: "availablePacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchavAilablePacks,
});

export const { reset } = availablePacksGridSlice.actions;

export default availablePacksGridSlice.reducer;
