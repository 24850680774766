import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type AccountInfo } from "../../../../Accounts/types";
import { type RootState } from "../../../../Application/globaltypes/redux";

export interface AccountInfoState {
  info: AccountInfo;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: AccountInfoState = {
  info: {} as AccountInfo,
  isLoading: false,
  error: undefined,
};

const ownAccountInfoSlice = createSlice({
  name: "ownAccountInfo",
  initialState: initialState,
  reducers: {
    req(state: AccountInfoState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: AccountInfoState, action: PayloadAction<AccountInfo>) {
      return {
        ...state,
        isLoading: false,
        info: action.payload,
      };
    },
    err(state: AccountInfoState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = ownAccountInfoSlice.actions;

export const ownAccountNameSelector = (state: RootState) => state.packs.packListingReducer.ownAccountInfo.info.name;

export default ownAccountInfoSlice.reducer;
