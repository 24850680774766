import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import ValidatedForm from "../../../components/forms/ValidatedFormWithoutFormik";

import { getEmailSettingsSchema, getVerifyEmailSchema } from "utils/validationSchemas/emailSettingsSchema";
import nameof from "utils/nameof";
import FormFooter from "components/forms/FormFooter";
import { Checkbox, Dimmer, Segment } from "semantic-ui-react";
import { type EmailSetting, type VerificationResult, type VerifyEmailModel } from "../types";
import styles from "./emailSettings.module.scss";
import { VerifyEmail } from "./VerifyEmail/VerifyEmail";
import noop from "lodash/noop";

export interface EmailSettingsProps {
  currentConfiguration?: EmailSetting;
  onSubmit: (emailSetting: EmailSetting) => void;
  isLoading: boolean;
  getEmailSettings: () => void;
  deleteEmailSettings: () => void;
  verifyEmailSettings: (receiverValidationEmail: string, settings: EmailSetting) => Promise<VerificationResult>;
  canEdit: boolean;
  error: string | undefined;
}

export const EmailSettings = (props: EmailSettingsProps) => {
  const { currentConfiguration, getEmailSettings, deleteEmailSettings, onSubmit, verifyEmailSettings } = props;
  const [isEnabled, setEnabled] = useState(!!currentConfiguration?.id);
  const [isVerified, setIsVerified] = useState<boolean | undefined>(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [credentialsRequired, setCredentialsRequired] = useState(
    (!!currentConfiguration?.userName && !currentConfiguration?.id) || !!currentConfiguration?.password,
  );

  const entityExists = !!currentConfiguration?.id;
  useEffect(() => {
    setEnabled(!!currentConfiguration?.id);
    setCredentialsRequired(
      (!!currentConfiguration?.userName && !currentConfiguration?.id) || !!currentConfiguration?.password,
    );
    setIsVerified(getIsVerified(currentConfiguration));
  }, [setEnabled, setCredentialsRequired, currentConfiguration]);
  useEffect(() => {
    getEmailSettings();
  }, [getEmailSettings]);
  const schema = useMemo(() => {
    return getEmailSettingsSchema(isEnabled, credentialsRequired);
  }, [isEnabled, credentialsRequired]);

  const formik = useFormik<Partial<EmailSetting>>({
    initialValues: currentConfiguration ?? defaultSettings,
    onSubmit: (values: Partial<EmailSetting>) => {
      if (isEnabled) {
        const casted = schema.cast(values) as EmailSetting;
        casted.password = casted.password || undefined;
        casted.userName = casted.userName || undefined;
        onSubmit(casted);
      } else if (currentConfiguration?.id) {
        deleteEmailSettings();
      }
    },
    validationSchema: schema,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const { senderName, senderEmail, userName, password, host, port, isSsl } = formik.values;

  const verifyEmailSchema = useMemo(() => getVerifyEmailSchema(), []);
  const verifyFormik = useFormik<Partial<VerifyEmailModel>>({
    initialValues: defaultVerifyEmail,
    onSubmit: noop,
    validationSchema: verifyEmailSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const { receiverValidationEmail } = verifyFormik.values;

  const { resetForm, submitForm } = formik;

  const getIsVerified = (configuration: EmailSetting | undefined) => {
    return !!configuration?.id || undefined;
  };

  const resetVerify = () => {
    setIsVerified(undefined);
  };

  useEffect(() => {
    setCredentialsRequired(!!password || !!userName);
  }, [setCredentialsRequired, password, userName]);

  const toggleConfiguration = () => {
    setEnabled(!isEnabled);
    if (isEnabled) {
      resetForm();
    }
  };

  const resetFullForm = () => {
    if (entityExists !== isEnabled) {
      setEnabled(entityExists);
    }
    resetForm();
    verifyFormik.resetForm();
    setIsVerified(getIsVerified(currentConfiguration));
  };

  const verify = async () => {
    setIsVerifying(true);
    const verificationResult = await verifyEmailSettings(
      receiverValidationEmail!,
      schema.cast(formik.values) as EmailSetting,
    );
    setIsVerifying(false);
    setErrorMessage(verificationResult.message);
    setIsVerified(verificationResult.verified);
  };

  const { dirty, isValid } = formik;
  const renderSenderInformation = () => (
    <>
      <br />
      <br />
      <div className={styles["email-settings-header"]}>SENDER INFORMATION</div>
      <ValidatedForm.InputField
        markAsRequired
        disabled={!props.canEdit}
        label="Default Sender Name"
        value={senderName}
        propertyName={nameof<EmailSetting>("senderName")}
        placeholder="Type sender name"
        onValueChanged={resetVerify}
        {...formik}
      />
      <ValidatedForm.InputField
        markAsRequired
        disabled={!props.canEdit}
        label="Default Sender Email"
        value={senderEmail}
        propertyName={nameof<EmailSetting>("senderEmail")}
        placeholder="Type sender email"
        onValueChanged={resetVerify}
        {...formik}
      />
      <br />
      <div className={styles["email-settings-header"]}>CONFIGURE SERVER</div>
      <ValidatedForm.InputField
        markAsRequired
        disabled={!props.canEdit}
        label="Host"
        value={host}
        propertyName={nameof<EmailSetting>("host")}
        placeholder="mail.domain.com"
        onValueChanged={resetVerify}
        {...formik}
      />
      <ValidatedForm.InputField
        markAsRequired
        disabled={!props.canEdit}
        label="Port"
        value={port}
        propertyName={nameof<EmailSetting>("port")}
        placeholder="Ex. 25"
        onValueChanged={resetVerify}
        {...formik}
      />
      <ValidatedForm.CheckboxField
        toggle
        disabled={!props.canEdit}
        propertyName={nameof<EmailSetting>("isSsl")}
        value={isSsl}
        label={"Use SSL"}
        {...formik}
        handleChange={resetVerify}
      />
      <ValidatedForm.InputField
        label="Username"
        disabled={!props.canEdit}
        value={userName}
        propertyName={nameof<EmailSetting>("userName")}
        placeholder="Type username"
        markAsRequired={credentialsRequired}
        autoComplete={"off"}
        onValueChanged={resetVerify}
        {...formik}
      />
      <ValidatedForm.InputField
        label="Password"
        disabled={!props.canEdit}
        value={password}
        propertyName={nameof<EmailSetting>("password")}
        placeholder="Type password"
        inputType="password"
        markAsRequired={credentialsRequired}
        autoComplete={"off"}
        onValueChanged={resetVerify}
        {...formik}
      />
      <br />
    </>
  );

  const renderBody = () => {
    return (
      <>
        <div className={styles["email-settings-header"]}>EMAIL SETTINGS</div>
        <Checkbox
          toggle
          checked={isEnabled}
          disabled={!props.canEdit}
          label={"Send using custom configuration"}
          onChange={() => toggleConfiguration()}
          data-testid={"send-configuration"}
          className={styles["checkbox-style"]}
        />
        {!isEnabled && (
          <div className={styles.info}>
            BrainStorm is configured to email your end users. Choose "Send using custom configuration" if you'd like to
            use your own messaging service instead.
          </div>
        )}
        {isEnabled && (
          <>
            {renderSenderInformation()}
            <VerifyEmail
              disabled={
                !(
                  (isValid && dirty && verifyFormik.isValid && verifyFormik.dirty) ||
                  (verifyFormik.isValid && verifyFormik.dirty)
                ) || isVerifying
              }
              isVerified={isVerified}
              isVerifying={isVerifying}
              receiverValidationEmail={receiverValidationEmail}
              formik={verifyFormik}
              verify={verify}
              errorMessage={errorMessage}
            />
          </>
        )}
      </>
    );
  };

  const renderForm = () => {
    return (
      <section className="edit-form scrollable-content">
        <ValidatedForm
          isLoading={props.isLoading}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message:
              "Are you sure you want to exit without saving this settings? All information entered will be lost.",
          }}
          {...formik}
        >
          {renderBody()}
        </ValidatedForm>
      </section>
    );
  };
  return (
    <>
      <section className="edit-form scrollable-content">
        <Dimmer.Dimmable as={Segment} dimmed={props.isLoading} className={styles.container}>
          {renderForm()}
        </Dimmer.Dimmable>
      </section>
      {
        props.canEdit && (
          <FormFooter
            isSaveBtnDisabled={!((!isEnabled && entityExists) || (isValid && dirty)) || (isEnabled && !isVerified)}
            isCancelBtnDisabled={(!dirty && isEnabled === entityExists) || isVerifying}
            onSave={submitForm}
            onCancel={resetFullForm}
          />
        )
      }
    </>
  );
};

const defaultSettings: Partial<EmailSetting> = {
  senderName: "",
};

const defaultVerifyEmail: Partial<VerifyEmailModel> = {
  receiverValidationEmail: "",
};
