import accountDataService from "../../../../Accounts/services/accountsDataService";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import { req, err, got } from "../slices/ownAccountInfoSlice";

export const fetchOwnAccountInfo = () => async (dispatch: AppDispatch) => {
  dispatch(req());
  const res = await accountDataService.getOwnAccountInfo();

  if (res.isSuccess) {
    dispatch(got(res.data));
  } else {
    dispatch(err(res.message));
  }
};
