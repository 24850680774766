import axios from "axios";
import { type GetThreatDefenceSendingProfilesRequest } from "../types/requests";
import { type IThreatDefenceSendingProfilesDataService } from "../types/services";
import { type ThreatDefenceDirectSendSendingProfile } from "../DirectSend/types/state";
import {
  type ThreatDefenceSmtpAuthSendingProfile,
  type ThreatDefenceSmtpAuthSendingProfileSettingsView,
} from "../SmtpAuth/types/state";

const instance: IThreatDefenceSendingProfilesDataService = {
  getThreatDefenceSendingProfilesAsync(data: GetThreatDefenceSendingProfilesRequest) {
    const config = {
      params: data,
    };

    return axios.get("/api/threat-defence/sending-profiles", config);
  },

  updateThreatDefenceDirectSendSendingProfileAsync(data: ThreatDefenceDirectSendSendingProfile) {
    return axios.put(`/api/threat-defence/sending-profiles/smtp-direct/${data.id}`, data);
  },

  getThreatDefenceDirectSendSendingProfileAsync(id: number) {
    return axios.get(`/api/threat-defence/sending-profiles/${id}`);
  },

  getSmtpAuthSendingProfileAsync(id: number) {
    return axios.get(`/api/threat-defence/sending-profiles/smtp/${id}/configuration`);
  },

  getSmtpAuthSendingProfileSettingsAsync(id: number) {
    return axios.get(`/api/threat-defence/sending-profiles/smtp/${id}/settings`);
  },

  hasAccountDirectSendSendingProfileAsync() {
    return axios.get(`/api/threat-defence/sending-profiles/smtp-direct/exists`);
  },

  updateThreatDefenceSmtpAuthSendingProfileAsync(data: ThreatDefenceSmtpAuthSendingProfile) {
    return axios.put(`/api/threat-defence/sending-profiles/smtp-auth/${data.id}/configuration`, data);
  },

  updateThreatDefenceSmtpAuthSendingProfileSettingsAsync(data: ThreatDefenceSmtpAuthSendingProfileSettingsView) {
    return axios.put(`/api/threat-defence/sending-profiles/smtp-auth/${data.id}/settings`, data);
  },

  verifySmtpAuthSendingProfileAsync(id: number, email: string) {
    return axios.post(`/api/threat-defence/sending-profiles/smtp-auth/${id}/verify`, { email });
  },
};

export default instance;
