import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { type AppThunk } from "../../../../Application/globaltypes/redux";
import eventsDataService from "../../services/eventsDataService";

export const updateTags = (id: number, tags: string[], type: "labels" | "softwareApplications"): AppThunk => {
  const tagTypeMap = {
    labels: TagsEnum.Label,
    softwareApplications: TagsEnum.SoftwareApplication,
  };

  return async () => {
    try {
      await eventsDataService.saveTags({ id, tags, tagType: tagTypeMap[type] });
    } catch (error) {}
  };
};
