import { combineReducers } from "redux";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums";
import withGroupsAssignment from "../../../../../Application/reducers/hoc/withGroupsAssignment";
import withUsersAssignment from "../../../../../Application/reducers/hoc/withUsersAssignment";
import messageEntityStateReducer from "./messageEntityStateReducer";
import { messageCreateSendReducer } from "../slices/messageCreateSendSlice";
import messageDetailsSlice from "../slices/messageDetailsSlice";
import messageFiltersSlice from "../slices/messageFiltersSlice";
import messagesOverviewSlice from "../slices/messagesOverviewSlice";
import { messageDetailsReducer } from "./messageDetailsReducer";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Messages;

const details = combineReducers({
  messageEntityStateReducer,
  values: messageDetailsSlice,
});

const overview = combineReducers({
  messages: messagesOverviewSlice,
  filters: messageFiltersSlice,
});

const reducers = combineReducers({
  details,
  overview,
  assignedUsers: withUsersAssignment(namespace, entityPrefix),
  assignedGroups: withGroupsAssignment(namespace, entityPrefix),
  sendSession: combineReducers({
    create: messageCreateSendReducer,
  }),
  messageDetails: messageDetailsReducer, // NOSONAR TODO: rename to messageSendSession
});

export type MessagesState = ReturnType<typeof reducers>;

export default reducers;
