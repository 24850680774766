import { Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";
import { isString } from "lodash";

import { CloseButton } from "../../buttons/closeButton";

import "./modalHeader.scss";

const displayTitle = (title) => {
  return isString(title) ? (
    <span className="title-text">{title}</span>
  ) : (
    <Placeholder data-testid="loading-modal-header">
      <Placeholder.Header />
    </Placeholder>
  );
};

function ModalHeader(props) {
  const { title, onClose } = props;
  return (
    <div className="modal-header">
      {title ? displayTitle(title) : <div />}

      <CloseButton onClick={onClose} className="close-button" />
    </div>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.any,
  onClose: PropTypes.func,
};

export default ModalHeader;
