import { SortingDirection } from "../../../../enums";

export const CLOSED_CAPTIONS = {
  TOP: 30,
  SORT_BY: "datecreated",
};

export const DEFAULT_LOAD_COUNT = Number(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);
export const DEFAULT_SORT_BY = "DateCreated";
export const DEFAULT_SORT_DIRECTION = SortingDirection.Descending;
export const DEFAULT_SORT_BY_PARAMS = `${DEFAULT_SORT_BY} ${DEFAULT_SORT_DIRECTION}`;
