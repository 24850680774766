import { type FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { FlowFilterForm } from "../../../../components/filterForms";
import { SearchInput } from "../../../../components";
import { ItemsView } from "../../..";
import { ContentTypesEnum, ItemsTypes, RolePermissions, SortingDirection } from "../../../../enums";
import { bindAction } from "../../../../interfaces";
import { CreateFlowButton } from "./FlowsOverviewHeader/CreateFlowButton/CreateFlowButton";
import { type Filters } from "../../../../utils/queryUtils";
import { ContentType } from "../../../../features/Library/PeopleAssignments/types";
import { type AppDispatch, type RootState } from "../../../../features/Application/globaltypes/redux";
import { type DropdownProps } from "semantic-ui-react";
import { type IFlowAssignmentModelItem } from "../../../../features/People/types";
import { FlowLockSuccess } from "../../../../features/Application/services/realTimeNotification/events/library/libraryEvents";
import { reset } from "../../../../features/Library/Flows/state/slices/flowOverviewSlice";
import { resetFlowBaseEntity } from "../../../../features/Library/Flows/state/slices/flowBaseSlice";
import { type FlowOverview } from "../../../../features/Library/Flows/types/models";
import {
  flowPaginationSelector,
  flowsFilterSelector,
  flowSortingColumnNameSelector,
  flowSortingDirectionSelector,
  flowsStateSelector,
  flowsShouldSort,
} from "../../../../features/Library/Flows/state/selectors";
import { type CardsViewerItem } from "../../../../components/cardsViewer/types";
import { OverviewCard } from "./Card/OverviewCard";
import { HandleOnSelectionChanged, type SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import {
  changeVisibility,
  deleteFlows,
  duplicateFlowsV2,
  fetchOverviewFlows,
} from "../../../../features/Library/Flows/state/thunks/flowOverviewThunk";
import { getOverviewFilterOptions } from "../../../../features/Library/Flows/state/thunks/flowFiltersThunk";
import {
  resetAppliedFilter,
  resetSearch,
  setAppliedFilter,
  setSearch,
  setPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
  setShouldSort,
} from "../../../../features/Library/Flows/state/slices/flowFiltersSlice";

import Observable from "../../../../utils/Observable";
import ViewType from "../../../../enums/ViewType";
import FlowsNoResults from "./FlowsNoResults";
import SortOptions from "../../../../enums/SortOptions";
import sortOptions from "./sortOptions";
import { getColumnOptions, ColumnToParamMap, type Columns } from "./columnOptions";
import FlowRow from "./FlowRow";
import DeleteContentConfirmationModal from "../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import { useRestrictedCheck } from "../../../../features/Application/hooks";
import { withRouter, type WithRouterProps } from "../../../../adapters/withRouter/withRouter";

import FlowsOverviewHeader from "./FlowsOverviewHeader/FlowsOverviewHeader";

import * as flowEntityStateActions from "../../../../features/Library/Flows/state/actions/flowEntityStateActionCreators";
import { useSearchSorting } from "components/listView/hooks/useSearchSorting";
import { useRtn } from "hooks/useRtn";
import { type SelectionContext } from "./types";

import "./flowsOverview.scss";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FlowsVisibilityModal } from "./FlowsOverviewHeader/FlowsVisibility/FlowsVisibilityModal";
import { resetOverview } from "features/Library/Flows/state";

const DEFAULT_PARAMS = {
  orderBy: SortOptions.ModifiedDateDesc.split(" ") as [string, string],
  column: "modified",
  direction: SortingDirection.Descending,
  pageSize: 10,
  gridPageSize: 30,
  skip: 0,
  flowLockSuccess: [FlowLockSuccess],
};

const defaultSelectionCtx: SelectionContext = {
  drafts: 0,
  neverPublished: 0,
  purchased: 0,
  undeletable: 0,
  withPack: 0,
  hidden: 0,
};

export type FlowsOverviewProps = PropsFromRedux & WithRouterProps;

export const FlowsOverview: FC<FlowsOverviewProps> = (props) => {
  const {
    navigate,
    resetOverviewFlows,
    fetchOverviewFlows,
    search,
    flows,
    flowEntityStateActions,
    filterOptions,
    appliedFilter,
    isFilterLoading,
    getFilterOptions,
    sortingDirection,
    sortingColumnName,
    pagination,
    setSortingColumnName,
    setPagination,
    setSortingDirection,
    resetFilter,
    applyFilter,
    duplicateFlows,
    deleteFlows,
    setSearch,
    resetSearchFilters,
    shouldSort,
    setShouldSort,
    resetFlowsItems,
  } = props;

  const flowMigrationEnabled = useFeatureFlag(FeatureFlags.FlowVersioningMigration);
  const reloadListItemsRef = useRef<((enableSorting: boolean) => void) | undefined>(undefined);
  const selectedFlows = useRef(new Map());
  const hasPermissions = useRestrictedCheck([RolePermissions.FlowsCreate, RolePermissions.FlowsManage]);

  const [selectedFlowIds, setSelectedFlowIds] = useState<number[]>([]);
  const [selectedViewType, setSelectedViewType] = useState(ViewType.GRID);
  const [orderBy, setOrderBy] = useState<[string, string]>(["", ""]);
  const [selectionCtx, setSelectionCtx] = useState<SelectionContext>(defaultSelectionCtx);
  // Used to handle resetting sort terms
  const { sortingColumn, onSearchChange: sortingSearchChange } = useSearchSorting({
    sortingColumnName: props.sortingColumnName,
    defaultSortingColumn: DEFAULT_PARAMS.column,
  });

  const isGridView = useMemo(() => selectedViewType === ViewType.GRID, [selectedViewType]);
  const onTriggerFlowRemovalObserver = useMemo(() => new Observable(), []);
  const onTriggerChangeVisibilityObserver = useMemo(
    () => new Observable<(onConfirm: () => void, hidden: boolean, ids: number[]) => void>(),
    [],
  );

  const goToEditEntity = useCallback(
    (payload: { id: number }) => navigate(`${payload.id.toString()}/configuration`),
    [navigate],
  );

  useRtn(DEFAULT_PARAMS.flowLockSuccess, goToEditEntity);

  useEffect(() => {
    return () => {
      resetFlowsItems();
      if (!window.location.pathname.includes("/content/flows")) resetSearchFilters();
    };
  }, [resetSearchFilters, resetFlowsItems]);

  const onSortChange = (_ev: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setShouldSort(true);
    resetOverviewFlows();
    const dataString = data.value as string;
    setOrderBy(dataString.split(" ") as [string, string]);
    setSelectedFlowIds([]);
    selectedFlows.current = new Map();

    const gridViewSort = {
      [SortOptions.ModifiedDateDesc]: () => {
        onSortingDirectionChange(SortingDirection.Descending);
        onSortingColumnNameChange("modified");
        setOrderBy(SortOptions.ModifiedDateDesc.split(" ") as [string, string]);
      },
      [SortOptions.CreatedDateDesc]: () => {
        onSortingDirectionChange(SortingDirection.Descending);
        onSortingColumnNameChange("added");
        setOrderBy(SortOptions.CreatedDateDesc.split(" ") as [string, string]);
      },
      [SortOptions.Title]: () => {
        onSortingDirectionChange(SortingDirection.Ascending);
        onSortingColumnNameChange("title");
        setOrderBy(SortOptions.Title.split(" ") as [string, string]);
      },
    };

    gridViewSort[data.value as keyof typeof gridViewSort]();
    const [column, direction] = dataString.split(" ");
    loadFlowCards(undefined, undefined, column, direction);
  };

  const onClearedSelection = () => {
    setSelectionCtx({ ...defaultSelectionCtx });
    selectedFlows.current = new Map();
    setSelectedFlowIds([]);
  };

  const onSelectionChanged = (args: SelectionChangedArgs<FlowOverview>) => {
    const newSelectionCtx = { ...defaultSelectionCtx };
    const onAdded = (flow: FlowOverview) => {
      !flow.isEditable && ++newSelectionCtx.purchased;
      !flow.canBeDeleted && ++newSelectionCtx.undeletable;
      !flow.published && ++newSelectionCtx.drafts;
      !flow.hasBeenPublished && ++newSelectionCtx.neverPublished;
      !!flow.dependencies?.packsCount && ++newSelectionCtx.withPack;
      flow.hidden && ++newSelectionCtx.hidden;

      selectedFlows.current.set(flow.id, {
        id: flow.id,
        title: flow.title,
        thumbnailUrl: flow.thumbnailUrl ?? null,
        published: flow.published,
        hasBeenPublished: flow.hasBeenPublished,
        canBeDeleted: flow.canBeDeleted,
        isEditable: flow.isEditable,
        canAutoStart: flow.canAutoStart,
        contentType: ContentType.Flow,
      });
    };

    const onRemoved = (flow: FlowOverview) => {
      !flow.isEditable && --newSelectionCtx.purchased;
      !flow.canBeDeleted && --newSelectionCtx.undeletable;
      !flow.published && --newSelectionCtx.drafts;
      !flow.hasBeenPublished && --newSelectionCtx.neverPublished;
      !!flow.dependencies?.packsCount && --newSelectionCtx.withPack;
      flow.hidden && --newSelectionCtx.hidden;
      selectedFlows.current.delete(flow.id);
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, onClearedSelection);
    setSelectionCtx({
      drafts: selectionCtx.drafts + newSelectionCtx.drafts,
      undeletable: selectionCtx.undeletable + newSelectionCtx.undeletable,
      purchased: selectionCtx.purchased + newSelectionCtx.purchased,
      neverPublished: selectionCtx.neverPublished + newSelectionCtx.neverPublished,
      withPack: selectionCtx.withPack + newSelectionCtx.withPack,
      hidden: selectionCtx.hidden + newSelectionCtx.hidden,
    });
  };

  const onSelectedFlowsChanged = (ids: number[]) => {
    setSelectedFlowIds(ids);
  };

  const listViewSort = {
    modified: () => {
      const [column, direction] = SortOptions.ModifiedDateDesc.split(" ");
      setOrderBy([column, direction]);
      return [column, direction];
    },
    added: () => {
      const [column, direction] = SortOptions.CreatedDateDesc.split(" ");
      setOrderBy([column, direction]);
      return [column, direction];
    },
    title: () => {
      const [column, direction] = SortOptions.Title.split(" ");
      setOrderBy([column, direction]);
      return [column, direction];
    },
    status: () => {
      const [column, direction] = DEFAULT_PARAMS.orderBy;
      setOrderBy([column, direction]);
      return [column, direction];
    },
    objective: () => {
      const [column, direction] = DEFAULT_PARAMS.orderBy;
      setOrderBy([column, direction]);
      return [column, direction];
    },
    noSort: () => {
      setOrderBy(["", ""]);
      return [undefined, undefined];
    },
    "": () => {
      const [column, direction] = DEFAULT_PARAMS.orderBy;
      setOrderBy([column, direction]);
      return [column, direction];
    },
  };

  const onViewTypeChange = (viewType: ViewType) => {
    resetOverviewFlows();
    onClearedSelection();
    setSelectedViewType(viewType);
    let column;
    let direction;
    if (shouldSort) {
      [column, direction] = listViewSort[sortingColumnName as keyof typeof listViewSort]();
    } else {
      [column, direction] = listViewSort["noSort" as keyof typeof listViewSort]();
    }

    viewType === ViewType.GRID && loadFlowCards(undefined, undefined, column, direction);
  };

  const loadFlowList = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    fetchOverviewFlows({
      skip,
      top,
      sortBy: ColumnToParamMap[sortingColumnName as Lowercase<Columns>],
      sortDirection: sortingDirection,
    });
  };

  const loadFlowCards = useCallback(
    (skip?: number, top?: number, sortBy?: string, sortDirection?: string) => {
      fetchOverviewFlows({ skip, top, sortBy, sortDirection, append: true });
    },
    [fetchOverviewFlows],
  );

  const reloadItems = () => {
    let sort: string | undefined;

    if (sortingColumnName === undefined) {
      sort = undefined;
    } else if (shouldSort) {
      sort = sortingColumnName === "" ? "added" : sortingColumnName;
    } else {
      sort = "";
    }

    if (selectedViewType === ViewType.GRID) {
      if (orderBy[0] !== "" && orderBy[1] !== "") {
        [sort] = orderBy;
      }
      resetOverviewFlows();
      loadFlowCards(DEFAULT_PARAMS.skip, DEFAULT_PARAMS.gridPageSize, sort, sortingDirection);
    } else {
      const paginationPresent = pagination ?? DEFAULT_PARAMS.skip;
      const resultsPerPagePresent = parseInt(localStorage.getItem("itemsPerPageAmount")!) ?? DEFAULT_PARAMS.pageSize;
      const skip = (paginationPresent - 1) * resultsPerPagePresent;
      loadFlowList(skip, resultsPerPagePresent, sort, sortingDirection);
    }
  };

  const loadFlows = (
    skip: number = DEFAULT_PARAMS.skip,
    top: number = DEFAULT_PARAMS.pageSize,
    sortingColumn?: string,
    sortingDirection?: SortingDirection,
  ) => {
    if (isGridView) {
      let cardColumn, cardDirection;
      const [gridViewColumn, gridViewDirection] = orderBy;
      if (shouldSort && gridViewColumn === "") {
        [cardColumn, cardDirection] = DEFAULT_PARAMS.orderBy;
        setOrderBy(DEFAULT_PARAMS.orderBy);
      } else {
        cardColumn = gridViewColumn;
        cardDirection = gridViewDirection;
      }
      loadFlowCards(flows.items.length, undefined, cardColumn, cardDirection);
    } else {
      let column, direction;
      if (shouldSort) {
        column = sortingColumn;
        direction = sortingDirection;
      } else {
        column = undefined;
        direction = undefined;
      }
      loadFlowList(skip, top, column, direction);
    }
  };

  const handleEditClick = (id: number, isDraft?: boolean) => () => {
    if (isDraft) {
      flowEntityStateActions.fetchDraftFlowEntity(id);
    } else {
      flowEntityStateActions.useExistingDraftFlowEntity(navigate, id);
    }
  };

  // @ts-ignore need to figure it out, why we don't provide props here
  const getFilterForm = () => <FlowFilterForm />;

  const applyFilterFunc = (filter: Filters) => {
    onClearedSelection();
    applyFilter(filter);

    if (selectedViewType === ViewType.GRID) {
      resetOverviewFlows();
      const [column, direction] = orderBy;
      loadFlowCards(undefined, undefined, column, direction);
    }
  };

  const resetFilterFunc = () => {
    onClearedSelection();
    resetFilter();
    if (selectedViewType === ViewType.GRID) {
      resetOverviewFlows();
      const [column, direction] = orderBy;
      loadFlowCards(undefined, undefined, column, direction);
    }
  };

  const handleDuplicateClick = (ids: number[]) => () => {
    onClearedSelection();
    duplicateFlows(ids, reloadItems);
  };

  const handleDeleteClick = (ids: number[], packsCount?: number) => () => {
    onTriggerFlowRemovalObserver.notify(
      () => {
        onClearedSelection();
        deleteFlows(ids, reloadItems);
      },
      {
        selectedItemsCount: ids.length,
        packsCount,
      },
    );
  };

  const handleToggleVisibility = (ids: number[], hidden: boolean) => () => {
    onTriggerChangeVisibilityObserver.notify(
      () => {
        props.toggleVisibility(ids, hidden, reloadItems);
      },
      hidden,
      ids,
    );
  };

  const createReloadListMethod = (reloadListItems: (enableSorting: boolean) => void) => {
    reloadListItemsRef.current = reloadListItems;
  };

  const onSearchChange = (search: string) => {
    if (search === "") {
      setShouldSort(true);
      // restore default sorting for cleared search (grid view state sorting)
      resetSortingColumnName();
      resetSortingDirection();
      sortingSearchChange(search);
      setSearch(search);
      const [column, direction] = listViewSort[sortingColumnName as keyof typeof listViewSort]();
      refetchGridOrListItemsFromStart(undefined, undefined, column, direction);
    } else if (search) {
      setShouldSort(false);
      // disable sorting for search (grid view state sorting)
      setOrderBy(["", ""]);
      resetSortingColumnName();
      resetSortingDirection();
      sortingSearchChange(search);
      setSearch(search);
      refetchGridOrListItemsFromStart();
    }
  };

  const onPaginationChange = (page: number) => setPagination(page);
  const onSortingColumnNameChange = (name: string) => {
    setShouldSort(true);
    setSortingColumnName(name);
  };
  const onSortingDirectionChange = (direction: SortingDirection) => setSortingDirection(direction);

  const refetchGridOrListItemsFromStart = async (
    skip?: number,
    top?: number,
    sortBy?: string,
    sortDirection?: string,
  ) => {
    if (selectedViewType === ViewType.GRID) {
      resetOverviewFlows();
      loadFlowCards(skip, top, sortBy, sortDirection);
    } else {
      reloadListItemsRef.current?.(isEmpty(search));
    }
  };

  const viewFlows = useMemo(() => {
    return flows.items.map((flow: FlowOverview) => ({
      ...flow,
      readonly: !hasPermissions,
      isChecked: selectedFlowIds.includes(flow.id),
      restrictDeletion: true,
    }));
  }, [flows.items, hasPermissions, selectedFlowIds]);

  return (
    <section className="nested-content flows">
      <FlowsOverviewHeader
        selectedIds={selectedFlowIds}
        selectionCtx={selectionCtx}
        flowMap={selectedFlows.current}
        onDuplicateFlows={handleDuplicateClick}
        flowDeletionHandler={handleDeleteClick}
        setSelectedIds={onSelectedFlowsChanged}
        clearSelection={onClearedSelection}
        onToggleVisibility={handleToggleVisibility}
      />

      <ItemsView
        className="alignment-padding"
        blur
        paginateOnLoad
        viewType={selectedViewType}
        columnOptions={getColumnOptions()}
        noResultsContent={
          <FlowsNoResults byCriteria={!isEmpty(appliedFilter) || !!search} createFlowButton={<CreateFlowButton />} />
        }
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        getData={loadFlows}
        itemsInlineCount={flows.itemsCount}
        isLoading={flows.isLoading}
        items={viewFlows}
        itemsType={ItemsTypes.Flow}
        buildTableBody={(flow: IFlowAssignmentModelItem) =>
          FlowRow({
            flow: flow,
            hasPermission: hasPermissions,
            buttonHandlers: {
              handleEditClick,
              handleDuplicateClick,
              handleDeleteClick,
              handleToggleVisibility,
            },
            isDuplicateDisabled: flowMigrationEnabled,
          })
        }
        renderFilterForm={getFilterForm}
        filterOptions={filterOptions}
        resetFilter={resetFilterFunc}
        appliedFilter={appliedFilter}
        filterOptionsLoading={isFilterLoading}
        applyFilter={applyFilterFunc}
        getFilterOptions={getFilterOptions}
        onSelectedListItemsChanged={onSelectedFlowsChanged}
        onSelectedItemsChanged={onSelectedFlowsChanged}
        renderCard={(props: CardsViewerItem<FlowOverview>) => {
          return (
            <OverviewCard
              {...props}
              {...{
                onEdit: handleEditClick,
                onCopy: handleDuplicateClick,
                onDelete: handleDeleteClick,
                onToggleVisibility: handleToggleVisibility,
              }}
              readonly={!hasPermissions}
            />
          );
        }}
        hasCardURL
        selectedIds={selectedFlowIds}
        isAllDataLoaded={flows.areAllLoaded}
        onViewTypeChange={onViewTypeChange}
        orderBy={shouldSort ? orderBy.join(" ") ?? DEFAULT_PARAMS.orderBy.join(" ") : ""}
        sortingDirection={shouldSort ? sortingDirection ?? DEFAULT_PARAMS.direction : ""}
        sortingColumnName={shouldSort ? sortingColumn : ""}
        onSelectionChanged={onSelectionChanged}
        onLoad={selectedViewType === ViewType.GRID ? loadFlows : undefined}
        renderSearch={() => (
          <SearchInput placeholder="Search for Flows..." onChange={onSearchChange} defaultValue={search} />
        )}
        isSelectDisabled={() => !hasPermissions}
        setReloadListItems={createReloadListMethod}
        permissions={[RolePermissions.FlowsManage]}
        setPagination={onPaginationChange}
        pagination={pagination}
        setSortingColumnName={onSortingColumnNameChange}
        setSortingDirection={onSortingDirectionChange}
      />

      <DeleteContentConfirmationModal
        contentType={ContentTypesEnum.Flows}
        onTriggerRemoveContentObserver={onTriggerFlowRemovalObserver}
      />

      <FlowsVisibilityModal observer={onTriggerChangeVisibilityObserver} />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flows = flowsStateSelector(state);
  return {
    flows: flows.overview.flowOverview,
    filterOptions: flowsFilterSelector(state).filterOptions,
    pagination: flowPaginationSelector(state),
    sortingColumnName: flowSortingColumnNameSelector(state),
    sortingDirection: flowSortingDirectionSelector(state),
    isFilterLoading: flowsFilterSelector(state).isLoading,
    appliedFilter: flowsFilterSelector(state).appliedFilter,
    isLoading: flows.base.entity.changingEntityState,
    search: flowsFilterSelector(state).search,
    shouldSort: flowsShouldSort(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getFilterOptions: bindAction(getOverviewFilterOptions, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    setShouldSort: bindAction(setShouldSort, dispatch),
    setPagination: bindAction(setPagination, dispatch),
    setSortingColumnName: bindAction(setSortingColumnName, dispatch),
    setSortingDirection: bindAction(setSortingDirection, dispatch),
    resetSearch: bindAction(resetSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    flowEntityStateActions: bindActionCreators(flowEntityStateActions, dispatch),
    fetchOverviewFlows: bindAction(fetchOverviewFlows, dispatch),
    resetOverviewFlows: bindAction(reset, dispatch),
    resetFlowBaseEntity: bindAction(resetFlowBaseEntity, dispatch),
    duplicateFlows: bindAction(duplicateFlowsV2, dispatch),
    deleteFlows: bindAction(deleteFlows, dispatch),
    resetSearchFilters: () => resetOverview(dispatch),
    resetFlowsItems: bindAction(reset, dispatch),
    toggleVisibility: bindAction(changeVisibility, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(withRouter(FlowsOverview));
export default ConnectedComponent;
