import midnightService from "../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/packEntityStateActionType";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../enums/draftEntityTypes";
import { type Pack } from "../../types/state";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { setPackAction } from "./packDetailsActions";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  PackUpdateSuccess,
  PackPublishSuccess,
  PackLockSuccess,
  PackDiscardSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createPack = (data: Pack) => {
  const begin = getActionBaseProvider(actionTypes.createPackBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createPackSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createPackFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.Packs, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftPackEntity = (id: number, packIds?: number[]) => {
  const begin = getActionBaseProvider(actionTypes.publishPackBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishPackFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PackPublishSuccess }));
    try {
      packIds ??= [];
      const payload = { packIds };
      await midnightService.releaseLock(draftEntityTypes.Packs, id, payload);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftPackSuccess = getActionProvider<MidnightActionPayload>(actionTypes.publishPackSuccess);

export const updatePack = (metadata: Pack) => {
  const begin = getActionBaseProvider(actionTypes.updatePackBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updatePackFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id, action: PackUpdateSuccess }));
    try {
      await dataService.packs.updatePackAsync(metadata);
      dispatch(setPackAction(metadata));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updatePackCommandSuccess = getActionProvider(
  actionTypes.updatePackCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const packUpdated = getActionProvider(actionTypes.packUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const resetPackEntityState = getActionBaseProvider(actionTypes.resetPackEntityState);

export const setPackEntityId = getActionProvider<number>(actionTypes.setPackEntityId);

export const fetchDraftPackEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedPackBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedPackFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PackLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.Packs, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchLockedPackSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedPackSuccess);

export const fetchDiscardPackEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardPackBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardPackFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PackDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.Packs, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchDiscardPackSuccess = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardPackSuccess);
