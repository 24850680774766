import React from "react";

import "./requiredLabel.scss";

function RequiredLabel() {
  return (
    <div className="required-label">
      <span className="star">*</span>
      <span className="label"> Required </span>
    </div>
  );
}
export default RequiredLabel;
