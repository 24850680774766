import { IntegrationTypes } from "../features/Accounts/Integrations/types";

export default class RouteNames {
  static accounts = "accounts";
  static home = "home";
  static accountsHome = `${this.accounts}/${this.home}`;
  static linkedAccounts = "linked";
  static templates = "templates";
  static linkedAccountsManagement = `${this.accounts}/${this.linkedAccounts}`;
  static accountSettings = "home";
  static accountSettingsManagement = `${this.accounts}/${this.accountSettings}`;
  static people = "people";
  static users = "users";
  static groups = "groups";
  static roles = "roles";
  static peopleUsers = `${this.people}/${this.users}`;
  static peopleGroups = `${this.people}/${this.groups}`;
  static peopleRoles = `${this.people}/${this.roles}`;
  static reporting = "reporting";
  static marketplace = "marketplace";
  static content = "content";
  static assets = "assets";
  static flows = "flows";
  static events = "events";
  static communications = "communications";
  static videos = "videos";
  static surveys = "surveys";
  static assessments = "assessments";
  static pdfs = "pdfs";
  static emails = "emails";
  static simulatedPhishing = "simulated-phishing";
  static emailTemplates = "email-templates";
  static landingPages = "landing-pages";
  static feedbackPages = "feedback-pages";
  static campaigns = "campaigns";
  static sendingProfiles = "sending-profiles";
  static preview = "preview";
  static enrollment = "enrollment";
  static contentAssets = `${this.content}/${this.assets}`;
  static contentFlows = `${this.content}/${this.flows}`;
  static contentEvents = `${this.content}/${this.events}`;
  static contentEmails = `${this.content}/${this.emails}`;
  static contentCommunications = `${this.content}/${this.communications}`;
  static contentVideos = `${this.contentAssets}/${this.videos}`;
  static contentSurveys = `${this.contentAssets}/${this.surveys}`;
  static contentAssessments = `${this.contentAssets}/${this.assessments}`;
  static contentPdfs = `${this.contentAssets}/${this.pdfs}`;
  static contentSimulatedPhishing = `${this.content}/${this.simulatedPhishing}`;
  static emailTemplatesSimulatedPhishing = `${this.content}/${this.simulatedPhishing}/${this.emailTemplates}`;
  static landingPagesSimulatedPhishing = `${this.content}/${this.simulatedPhishing}/${this.landingPages}`;
  static feedbackPagesSimulatedPhishing = `${this.content}/${this.simulatedPhishing}/${this.feedbackPages}`;
  static campaignsSimulatedPhishing = `${this.content}/${this.simulatedPhishing}/${this.campaigns}`;
  static sendingProfilesSimulatedPhishing = `${this.content}/${this.simulatedPhishing}/${this.sendingProfiles}`;
  static licensing = "licensing";
  static available = "available";
  static overview = "overview";
  static packs = "packs";
  static purchased = "purchased";
  static listings = "listings";
  static reportingPacks = `${this.reporting}/${this.packs}`;
  static licensingPacks = `${this.licensing}/${this.packs}`;
  static availablePacks = `${this.licensingPacks}/${this.available}`;
  static purchasedPacks = `${this.licensingPacks}/${this.purchased}`;
  static listingsPacks = `${this.licensingPacks}/${this.listings}`;
  static integrationsOverviewUrl = `/${this.accountSettingsManagement}/integrations`;
  static msGraphIntegrationUrl = `${this.integrationsOverviewUrl}/${IntegrationTypes.MsGraph}`;
  static googleIntegrationUrl = `${this.integrationsOverviewUrl}/${IntegrationTypes.Google}`;
  static webexIntegrationUrl = `${this.integrationsOverviewUrl}/${IntegrationTypes.Webex}`;
  static vivaIntegrationUrl = `${this.integrationsOverviewUrl}/${IntegrationTypes.Viva}`;
  static sfIntegrationUrl = `${this.integrationsOverviewUrl}/${IntegrationTypes.SuccessFactors}`;
  static subAccountMsGraphIntegrationUrl = `integrations/${IntegrationTypes.MsGraph}`;
  static subAccountGoogleIntegrationUrl = `integrations/${IntegrationTypes.Google}`;
  static subAccountWebexIntegrationUrl = `integrations/${IntegrationTypes.Webex}`;
  static subAccountVivaIntegrationUrl = `integrations/${IntegrationTypes.Viva}`;
  static subAccountSfIntegrationUrl = `integrations/${IntegrationTypes.SuccessFactors}`;
  static productFeedbackUrl = "feedback";
  static inheritedGroupsUrl = "people/groups/inherited";
  static allRoutes = Object.values(RouteNames);
}
