import { useMemo } from "react";
import { useDimensions } from "./useDimensions";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import moment from "moment";
import { getTickCountBySize } from "components/charts/shared/helpers";

const dimensionsValid = (dimensions: DOMRect | null) => {
  return dimensions && dimensions.width > 0;
};

export type ChartPeriod = "DAY" | "WEEK" | "MONTH" | "UNSET";

export const useChartPeriodMeasure = (dates: Date[], horizontalMarginWidth = 0) => {
  const [dimensions, ref] = useDimensions();

  const ticks = useMemo<string[]>(() => {
    const upperRange = dimensions ? dimensions.width - horizontalMarginWidth : 0;
    return timeScale().domain(dates).range([0, upperRange]).ticks(getTickCountBySize(upperRange));
  }, [dates, dimensions, horizontalMarginWidth]);

  /**
   * Use d3 time scale to test the difference between dates
   */
  const diff = useMemo<ChartPeriod>(() => {
    if (!dimensionsValid(dimensions)) {
      return "UNSET";
    }

    const first = moment(ticks[0]),
      second = moment(ticks[1]);

    if (Math.floor(second.diff(first, "months")) > 0) {
      return "MONTH";
    }

    if (Math.floor(second.diff(first, "days")) > 2) {
      return "WEEK";
    }

    return "DAY";
  }, [dimensions, ticks]);

  return [diff, ref] as const;
};
