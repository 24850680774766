import axios from "axios";
import { serializeArrayAndFilterNullable } from "../../../../../utils/queryUtils";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import { type DeleteThreatDefenceEmailTemplatesRequest, type GetThreatDefenceEmailTemplatesRequest } from "../types/requests";
import { type IThreatDefenceEmailTemplatesDataService } from "../types/services";
import { type ThreatDefenceEmailTemplate } from "../types/state";

const instance: IThreatDefenceEmailTemplatesDataService = {
  getThreatDefenceEmailTemplatesAsync(data: GetThreatDefenceEmailTemplatesRequest) {
    const config = {
      params: {
        top: data.top,
        skip: data.skip,
        orderBy: data.orderBy,
        ...threatDefenceFetchDataUtils.formatFilters(data.filters),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get("/api/threat-defence/email-templates", config);
  },

  deleteThreatDefenceEmailTemplate(request: DeleteThreatDefenceEmailTemplatesRequest) {
    return axios.delete(`/api/threat-defence/email-templates/${request.id}`);
  },

  updateThreatDefenceEmailTemplateAsync(data: ThreatDefenceEmailTemplate) {
    return axios.put(`/api/threat-defence/email-templates/${data.id}`, data);
  },

  getThreatDefenceEmailTemplateAsync(id: number) {
    return axios.get(`/api/threat-defence/email-templates/${id}`);
  },

  getThreatDefenceEmailTemplateContentUrlWithSasAsync: async (
    id: number,
    includeWritePermission: boolean = true,
  ): Promise<string> => {
    const contentResp = await axios.get<{ contentUrlWithSas: string }>(
      `/api/threat-defence/email-templates/${id}/sas?includeWritePermission=${includeWritePermission}`,
    );

    return contentResp.data?.contentUrlWithSas;
  },
};

export default instance;
