import { BasicLinkButton, DeleteLinkButton, EditLinkButton, TextTruncate } from "components";
import { type TemplateOverview, TemplateStatusEnum } from "../types/models";
import { type ColumnOption } from "interfaces/columnOptions";
import { Title } from "components/listViewTemplates";
import dateTimeUtils from "utils/dateTimeUtils";
import { Tooltip } from "components/common/tooltip";
import { TemplateStatus } from "./TemplateStatus";
import { faCircleCheck, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { TemplateImage } from "components/people/templates/TemplateImage";

export enum Columns {
  Name = "Name",
  Owner = "Owner",
  Added = "Added",
  Modified = "Modified",
  Customers = "Customers",
  Users = "Users",
  Status = "Status",
}

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "name",
  owner: "publisher",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  customers: "customers",
  users: "users",
};

export const getColumnOptions = ({
  handleEdit,
  handleTitleClick,
  hasManagePermission,
  hasGroupManagePermission,
  processTemplate,
  handleDelete,
  onlyOwn,
}: {
  handleEdit: (id: number) => void;
  handleTitleClick: (item: TemplateOverview) => string;
  hasManagePermission: boolean;
  hasGroupManagePermission: boolean;
  processTemplate: (id: number, approve: boolean, owner: string) => () => void;
  handleDelete: (id: number) => () => void;
  onlyOwn: boolean;
}): ColumnOption<TemplateOverview>[] => {
  return [
    {
      name: "",
      width: 1,
      isSortable: false,
      render: (item) => {
        return <TemplateImage imageUrl={item.imageUrl} />;
      },
    },
    {
      name: Columns.Name,
      width: 4,
      isSortable: true,
      render: (item) => {
        const url = handleTitleClick(item);
        return <Title title={item.name} url={url} clamp={2} className="normal-color" />;
      },
    },
    {
      name: Columns.Owner,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Added,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateCreated) : "-",
    },
    {
      name: Columns.Modified,
      width: 2,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateModified) : "-",
    },
    {
      name: Columns.Customers,
      width: 2,
      isSortable: false,
      render: (item) => item.customers,
    },
    {
      name: Columns.Users,
      width: 2,
      isSortable: false,
      render: (item) => item.users,
    },

    {
      name: Columns.Status,
      width: 2,
      isSortable: false,
      isHide: onlyOwn,
      render: (item) => (item.isOwn ? "-" : <TemplateStatus status={item.status} />),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      render: (item) => (
        <EllipsisPopupButton
          circle
          outlinedEllipsis
          disabled={!item.isOwn && item.status !== TemplateStatusEnum.Pending}
        >
          {item.isOwn
            ? [
                <EditLinkButton onClick={() => handleEdit(item.id)} isDisabled={!hasManagePermission} key="edit" />,
                <DeleteLinkButton onClick={handleDelete(item.id)} isDisabled={!hasManagePermission} key="delete" />,
              ]
            : [
                <BasicLinkButton
                  text="Approve"
                  onClick={processTemplate(item.id, true, item.publisher)}
                  icon={faCircleCheck}
                  key="approve"
                  isDisabled={!hasGroupManagePermission}
                />,
                <BasicLinkButton
                  text="Decline"
                  onClick={processTemplate(item.id, false, item.publisher)}
                  icon={faCircleX}
                  key="decline"
                  isDisabled={!hasGroupManagePermission}
                />,
              ]}
        </EllipsisPopupButton>
      ),
    },
  ];
};

export const getReadonlyColumnOptions = (): ColumnOption<TemplateOverview>[] => {
  return [
    {
      name: Columns.Name,
      width: 1,
      isSortable: true,
      render: (item) => {
        return <TemplateImage imageUrl={item.imageUrl} />;
      },
    },
    {
      name: "",
      width: 5,
      isSortable: false,
      render: (item) => <Title title={item.name} clamp={2} className="normal-color" />,
    },
    {
      name: Columns.Owner,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Added,
      width: 3,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateCreated) : "-",
    },
    {
      name: Columns.Modified,
      width: 3,
      isSortable: true,
      render: (item) =>
        item.isOwn || item.status === TemplateStatusEnum.Approved ? dateTimeUtils.formatDate(item.dateModified) : "-",
    },
  ];
};
