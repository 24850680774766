import React, { useMemo } from "react";
import { defaultText, getTriggersInfo } from "./helpers";
import { DropdownWrapper as Dropdown } from "../../../../../../../../components";
import { type DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import FieldLabel from "../../../../../../../../components/forms/FieldLabel";
import { type DefaultTriggerBase } from "./types";

export const DefaultTriggerForm = (props: DefaultTriggerBase) => {
  const { triggers, isReadOnly, onIsDefaultChange } = props;
  const { options, selected } = useMemo(() => getTriggersInfo(triggers), [triggers]);

  const onSelectDefaultTrigger = async (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onIsDefaultChange(data.value as string);
  };

  return (
    <>
      <FieldLabel label="Trigger" />
      <Dropdown
        fluid
        selection
        items={options}
        value={selected}
        placeholder={defaultText}
        onChange={onSelectDefaultTrigger}
        disabled={isReadOnly}
        className="dropdown-control small"
      />
    </>
  );
};

export default DefaultTriggerForm;
