import { type AppDispatch } from "features/Application/globaltypes/redux";
import { reset } from "./slices/flowOverviewSlice";
import {
  resetAppliedFilter,
  resetPagination,
  resetSearch,
  resetShouldSort,
  resetSortingColumnName,
  resetSortingDirection,
} from "./slices/flowFiltersSlice";

export const resetOverview = (dispatch: AppDispatch) => {
  dispatch(reset());
  dispatch(resetSearch());
  dispatch(resetPagination());
  dispatch(resetAppliedFilter());
  dispatch(resetSortingColumnName());
  dispatch(resetSortingDirection());
  dispatch(resetShouldSort());
};
