import { useNavigate } from "react-router-dom";
import { Button } from "components/buttons/button/Button";

import { RolePermissions } from "../../../../../enums";
import routeNames from "../../../../../enums/routeNames";
import Restricted from "../../../../Application/Restricted";
import GroupsNoResults from "./GroupsNoResults";

export interface GroupsNoResultsWithCreateProps {
  filtered?: boolean;
}

const GroupsNoResultsWithCreate: React.FC<GroupsNoResultsWithCreateProps> = ({ filtered }) => {
  const navigate = useNavigate();

  const navigateToCreate = () => {
    navigate(`/${routeNames.people}/groups/add`);
  };

  const renderCreateGroupButton = () => (
    <Restricted
      permissions={[RolePermissions.GroupsCreate]}
      renderContent={(hasAnyPermission) => (
        <Button
          onClick={navigateToCreate}
          className="create-button group"
          content="Create Group"
          primary
          disabled={!hasAnyPermission}
        />
      )}
    />
  );

  return <GroupsNoResults filtered={filtered} createGroupButton={renderCreateGroupButton()} />;
};

export default GroupsNoResultsWithCreate;
