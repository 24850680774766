import { type AnyAction, type Dispatch } from "redux";
import getStore from "../../../../../store";
import { sectionHeaderInfo } from "../../../../../utils/validationSchemas/sectionHeaderValidationSchema";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { EntityType, type FlowDesignerData, type TriggersData } from "../types";
import * as actionTypes from "./flowValidatorActionTypes";
import {
  type ConnectionActionTriggersError,
  type DeletedItemError,
  type FlowError,
  type FlowValidationError,
  type FlowValidatorState,
  type ItemError,
  type SectionHeaderDescriptionError,
  type SectionHeaderNameError,
  ValidatorErrorTypes,
  type WithinError,
} from "./flowValidatorReducer";
import { validateTriggers } from "./rules/validateTriggers";
import TriggerType from "../../../../../enums/flowDesigner/triggerType";
import { validateFlowEnd } from "./rules/validateFlowEnd";

type DebugFunc = (r: { isFlowValid: boolean; areErrorsResolved: boolean }) => void;

type Rule = (data: FlowDesignerData) => FlowValidationError[];

interface IFlowValidator {
  validate: (data: any) => void;
  clearState: () => void;
}

interface InitOptions {
  dispatch?: Dispatch<AnyAction>;
  getValidationState?: () => FlowValidatorState;
  logger?: DebugFunc;
}

export class FlowValidator implements IFlowValidator {
  private logger: DebugFunc;
  private dispatch: Dispatch<AnyAction>;
  private setIsFlowValidAction = getActionProvider<{
    isFlowValid: boolean;
    currentItemsMap: FlowValidatorState["currentItemsMap"];
    currentErrors: FlowValidationError[];
    errorViewErrors: FlowValidationError[];
  }>(actionTypes.setIsFlowValid);
  private setIsErrorModeEnabledAction = getActionProvider<{
    isErrorViewMode: boolean;
  }>(actionTypes.setIsErrorModeEnabled);
  private setAreErrorsResolved = getActionProvider<{
    areErrorsResolved: boolean;
  }>(actionTypes.setErrorsResolved);
  private reset = getActionBaseProvider(actionTypes.reset);
  private rules: Rule[];
  private getValidationState: () => FlowValidatorState;

  constructor(options?: InitOptions) {
    const store = getStore();

    this.logger = options?.logger ?? ((params) => console.log("[FLOWDESIGNER VALIDATOR] log: ", params));
    this.dispatch = options?.dispatch ?? store.dispatch;
    this.getValidationState = options?.getValidationState ?? (() => store.getState().library.flows.base.validation);
    this.rules = [
      validateDeletedItems,
      validateHeadId,
      validateTriggerTimeSpan,
      validateConnectedItems,
      validateTriggers,
      validateSectionHeader,
      validateFlowEnd,
      validateConnectionActionTriggers,
      validateActionItems,
    ];
  }

  private validateData(data: FlowDesignerData): FlowValidationError[] {
    let errors: FlowValidationError[] = [];

    for (const rule of this.rules) {
      const ruleErrors = rule(data);
      if (ruleErrors.length > 0) {
        errors = [...errors, ...ruleErrors];
      }
    }

    return errors;
  }

  validate(data: FlowDesignerData): void {
    const { errorViewItemsMap, isErrorViewMode } = this.getValidationState();

    // validate all data
    const currentErrors = this.validateData(data);
    const isFlowValid = currentErrors.length === 0;

    // validate data portion, where user should fix errors that were left after publish attempt
    let areErrorsResolved = false;
    let currentErrorsTouchedFields: FlowValidationError[] = [];
    if (isErrorViewMode) {
      const thereAreTouchedFields = Object.keys(errorViewItemsMap).length > 0;
      currentErrorsTouchedFields = thereAreTouchedFields
        ? this.validateData(extractWorkingItems(data, errorViewItemsMap))
        : currentErrors;
      areErrorsResolved = currentErrorsTouchedFields.length === 0;
    }

    this.logger({
      isFlowValid, // is the whole form valid ?
      areErrorsResolved, // are errors fixed that were left after publish attempt ?
    });

    const currentItemsMap = data.items.reduce((acc: FlowValidatorState["currentItemsMap"], curr) => {
      acc[curr.id] = true;
      return acc;
    }, {});
    this.dispatch(
      this.setIsFlowValidAction({
        isFlowValid,
        currentItemsMap,
        currentErrors,
        errorViewErrors: currentErrorsTouchedFields,
      }),
    );
    if (areErrorsResolved) {
      // hide errors showing
      this.dispatch(this.setIsErrorModeEnabledAction({ isErrorViewMode: false }));
      this.dispatch(this.setAreErrorsResolved({ areErrorsResolved: true }));
    }
  }

  clearState(): void {
    this.dispatch(this.reset());
  }
}

export function validateHeadId(data: FlowDesignerData): FlowError[] {
  const errors: FlowError[] = [];
  if (!data.headId) {
    errors.push({
      errorMessage: "Drop the asset in Start of Flow area",
      type: ValidatorErrorTypes.StartError,
      id: `type-${ValidatorErrorTypes.StartError}`,
    });
  }
  return errors;
}

export function validateTriggerTimeSpan(data: FlowDesignerData): WithinError[] {
  const errors: WithinError[] = [];
  data.triggers
    .filter((t) => t.typeId === 1 && (!t.timeSpan || t.timeSpan <= 0))
    .forEach((t) => {
      errors.push({
        inId: t.inId,
        outId: t.outId,
        errorMessage:
          t.timeSpan === 0 ? "Within field is required and must be greater than 0" : "This value is required",
        type: ValidatorErrorTypes.WithinError,
        id: `type-${ValidatorErrorTypes.WithinError}-in-${t.inId}-out-${t.outId}`,
      });
    });
  return errors;
}

export function validateConnectedItems(data: FlowDesignerData): ItemError[] {
  // please don't confuse inId and outId in triggers
  // [asset]inId -------------->outId[asset]
  const errors: ItemError[] = [];
  let outIdsMap: { [key: string]: true } = {};
  let bulletIdMap: { [key: string]: number } = {};

  const pushItemError = ({ inId, errorMessage }: { inId: string; errorMessage: string }) => {
    errors.push({
      itemId: inId,
      errorMessage,
      type: ValidatorErrorTypes.ItemError,
      id: `type-${ValidatorErrorTypes.ItemError}-itemId-${inId}`,
    });
  };

  data.triggers?.forEach((tr) => {
    outIdsMap[tr.outId] = true;
    tr.typeId === TriggerType.Response && (bulletIdMap[tr.inId] = bulletIdMap[tr.inId] ? ++bulletIdMap[tr.inId] : 1);
  });
  data.items
    .filter((item) => item.id !== data.headId && !outIdsMap[item.id])
    .forEach((item) => {
      pushItemError({ errorMessage: "Connect Trigger from previous asset", inId: item.id });
    });

  data.items
    .filter((item) => item.entityType === EntityType.Survey && item.branchingQuestion)
    .forEach((item) => {
      const answersLength = item.branchingQuestion?.includeOtherAsAnswer
        ? item.branchingQuestion?.answers.length + 1 // OtherAsAnswer
        : item.branchingQuestion?.answers.length;

      const hasNotResponseTrigger = bulletIdMap[item.id] && bulletIdMap[item.id] !== answersLength;
      hasNotResponseTrigger && pushItemError({ errorMessage: "Connect all Response Triggers", inId: item.id });
    });

  return errors;
}

export function validateConnectionActionTriggers(data: FlowDesignerData): ConnectionActionTriggersError[] {
  // please don't confuse inId and outId in triggers
  // [asset]inId -------------->outId[asset]
  const errors: ConnectionActionTriggersError[] = [];

  data.items
    .filter((item) => item.id !== data.headId)
    .forEach((item) => {
      let inTriggers = data.triggers?.filter((tr) => tr.outId === item.id);
      if (!inTriggers.every((tr) => tr.isAction) && !inTriggers.every((tr) => !tr.isAction)) {
        errors.push({
          itemId: item.id,
          errorMessage: "The Destination item can’t be the Connection & Action",
          type: ValidatorErrorTypes.ConnectionActionTriggersError,
          id: `type-${ValidatorErrorTypes.ConnectionActionTriggersError}-itemId-${item.id}`,
        });
      }
    });
  return errors;
}

export function validateSectionHeader(data: FlowDesignerData): any {
  const errors: SectionHeaderNameError[] & SectionHeaderDescriptionError[] = [];

  data.items
    .filter((item) => item.sectionHeader)
    .forEach((item) => {
      try {
        sectionHeaderInfo.validateSyncAt("name", item.sectionHeader);
      } catch (e) {
        errors.push({
          itemId: item.id,
          errorMessage: e.errors,
          type: ValidatorErrorTypes.SectionHeaderNameError,
          id: `type-${ValidatorErrorTypes.SectionHeaderNameError}-name-itemId-${item.id}`,
        });
      }

      try {
        sectionHeaderInfo.validateSyncAt("description", item.sectionHeader);
      } catch (e) {
        errors.push({
          itemId: item.id,
          errorMessage: e.errors,
          type: ValidatorErrorTypes.SectionHeaderDescriptionError,
          id: `type-${ValidatorErrorTypes.SectionHeaderDescriptionError}-description-itemId-${item.id}`,
        });
      }
    });

  return errors;
}

export function validateActionItems(data: FlowDesignerData): any {
  const noActionItems = data.triggers.filter((t) => !t.isAction);
  const noActivityItems = noActionItems.filter((t) => t.typeId === 1);
  const toHours = (timeUnitId: number, timeSpan: number) => {
    switch (timeUnitId) {
      case 2:
        return 24 * timeSpan;
      case 3:
        return 24 * 7 * timeSpan;
      default:
        return timeSpan;
    }
  };
  const errors: WithinError[] = [];

  data.triggers.forEach((trigger: TriggersData) => {
    if (
      trigger.isAction &&
      trigger.typeId === 1 &&
      noActivityItems &&
      noActivityItems.find(
        (t) =>
          t.inId === trigger.inId &&
          t.timeSpan !== undefined &&
          trigger.timeSpan !== undefined &&
          toHours(t.timeUnitId, t.timeSpan) < toHours(trigger.timeUnitId, trigger.timeSpan),
      )
    ) {
      errors.push({
        inId: trigger.inId,
        outId: trigger.outId,
        errorMessage: "Within time for Action item can’t be more than Within time for Connection item",
        type: ValidatorErrorTypes.WithinError,
        id: `type-${ValidatorErrorTypes.WithinError}-in-${trigger.inId}-out-${trigger.outId}`,
      });
    }
  });

  return errors;
}

export function extractWorkingItems(
  data: FlowDesignerData,
  errorViewItemsMap: FlowValidatorState["errorViewItemsMap"],
): FlowDesignerData {
  return {
    ...data,
    items: data.items.filter((item) => errorViewItemsMap[item.id] || item.entityType === EntityType.FlowEnd),
    triggers: data.triggers.filter((tr) => errorViewItemsMap[tr.inId] || errorViewItemsMap[tr.outId]),
  };
}

export function validateDeletedItems(data: FlowDesignerData): DeletedItemError[] {
  const errors: DeletedItemError[] = [];

  data.items.forEach((item) => {
    if (!item.hasEntity) {
      errors.push({
        itemId: item.id,
        errorMessage: "Remove the Deleted item from the canvas",
        type: ValidatorErrorTypes.DeletedItemError,
        id: `type-${ValidatorErrorTypes.DeletedItemError}-itemId-${item.id}`,
      });
    }
  });

  return errors;
}
