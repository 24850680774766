// Generated with ProcessTimeZones.ps1 from cldr-45.0.0-json-full\cldr-bcp47\bcp47\timezone.json
// https://github.com/unicode-org/cldr-json/releases/tag/45.0.0
/* instanbul ignore next */
export const mappings: { [k: string]: string[] } =
  {
    "Abidjan, Côte d\u0027Ivoire": ["Africa/Abidjan"],
    "Accra, Ghana": ["Africa/Accra"],
    "Adak (Alaska), United States": ["America/Adak", "America/Atka", "US/Aleutian"],
    "Addis Ababa, Ethiopia": ["Africa/Addis_Ababa"],
    "Adelaide, Australia": ["Australia/Adelaide", "Australia/South"],
    "Aden, Yemen": ["Asia/Aden"],
    "Algiers, Algeria": ["Africa/Algiers"],
    "Almaty, Kazakhstan": ["Asia/Almaty"],
    "Amman, Jordan": ["Asia/Amman"],
    "Amsterdam, Netherlands": ["Europe/Amsterdam"],
    "Anadyr, Russia": ["Asia/Anadyr"],
    "Anchorage, United States": ["America/Anchorage", "US/Alaska"],
    "Andorra": ["Europe/Andorra"],
    "Anguilla": ["America/Anguilla"],
    "Antananarivo, Madagascar": ["Indian/Antananarivo"],
    "Antigua": ["America/Antigua"],
    "Ürümqi, China": ["Asia/Urumqi", "Asia/Kashgar"],
    "Apia, Samoa": ["Pacific/Apia"],
    "Aqtau, Kazakhstan": ["Asia/Aqtau"],
    "Aqtobe, Kazakhstan": ["Asia/Aqtobe"],
    "Araguaína, Brazil": ["America/Araguaina"],
    "Aruba": ["America/Aruba"],
    "Ashgabat, Turkmenistan": ["Asia/Ashgabat", "Asia/Ashkhabad"],
    "Asmara, Eritrea": ["Africa/Asmera", "Africa/Asmara"],
    "Astrakhan, Russia": ["Europe/Astrakhan"],
    "Asunción, Paraguay": ["America/Asuncion"],
    "Athens, Greece": ["Europe/Athens"],
    "Atikokan, Canada": ["America/Coral_Harbour", "America/Atikokan"],
    "Atyrau (Guryev), Kazakhstan": ["Asia/Atyrau"],
    "Auckland, New Zealand": ["Pacific/Auckland", "Antarctica/South_Pole", "NZ"],
    "Azores, Portugal": ["Atlantic/Azores"],
    "Baghdad, Iraq": ["Asia/Baghdad"],
    "Bahía de Banderas, Mexico": ["America/Bahia_Banderas"],
    "Bahia, Brazil": ["America/Bahia"],
    "Bahrain": ["Asia/Bahrain"],
    "Baku, Azerbaijan": ["Asia/Baku"],
    "Bamako, Mali": ["Africa/Bamako", "Africa/Timbuktu"],
    "Bangkok, Thailand": ["Asia/Bangkok"],
    "Bangui, Central African Republic": ["Africa/Bangui"],
    "Banjul, Gambia": ["Africa/Banjul"],
    "Barbados": ["America/Barbados"],
    "Barnaul, Russia": ["Asia/Barnaul"],
    "Beirut, Lebanon": ["Asia/Beirut"],
    "Belém, Brazil": ["America/Belem"],
    "Belgrade, Serbia": ["Europe/Belgrade"],
    "Belize": ["America/Belize"],
    "Berlin, Germany": ["Europe/Berlin"],
    "Bermuda": ["Atlantic/Bermuda"],
    "Beulah (North Dakota), United States": ["America/North_Dakota/Beulah"],
    "Bishkek, Kyrgyzstan": ["Asia/Bishkek"],
    "Bissau, Guinea-Bissau": ["Africa/Bissau"],
    "Blanc-Sablon, Canada": ["America/Blanc-Sablon"],
    "Blantyre, Malawi": ["Africa/Blantyre"],
    "Boa Vista, Brazil": ["America/Boa_Vista"],
    "Bogotá, Colombia": ["America/Bogota"],
    "Boise (Idaho), United States": ["America/Boise"],
    "Bonaire, Sint Estatius and Saba": ["America/Kralendijk"],
    "Bougainville, Papua New Guinea": ["Pacific/Bougainville"],
    "Bratislava, Slovakia": ["Europe/Bratislava"],
    "Brazzaville, Republic of the Congo": ["Africa/Brazzaville"],
    "Brisbane, Australia": ["Australia/Brisbane", "Australia/Queensland"],
    "Broken Hill, Australia": ["Australia/Broken_Hill", "Australia/Yancowinna"],
    "Brunei": ["Asia/Brunei"],
    "Brussels, Belgium": ["Europe/Brussels"],
    "Bucharest, Romania": ["Europe/Bucharest"],
    "Budapest, Hungary": ["Europe/Budapest"],
    "Buenos Aires, Argentina": ["America/Buenos_Aires", "America/Argentina/Buenos_Aires"],
    "Bujumbura, Burundi": ["Africa/Bujumbura"],
    "Busingen, Germany": ["Europe/Busingen"],
    "Córdoba, Argentina": ["America/Cordoba", "America/Argentina/Cordoba", "America/Rosario"],
    "Cairo, Egypt": ["Africa/Cairo", "Egypt"],
    "Cambridge Bay, Canada": ["America/Cambridge_Bay"],
    "Campo Grande, Brazil": ["America/Campo_Grande"],
    "Canary Islands, Spain": ["Atlantic/Canary"],
    "Cancún, Mexico": ["America/Cancun"],
    "Cape Verde": ["Atlantic/Cape_Verde"],
    "Caracas, Venezuela": ["America/Caracas"],
    "Casablanca, Morocco": ["Africa/Casablanca"],
    "Casey Station, Bailey Peninsula": ["Antarctica/Casey"],
    "Catamarca, Argentina": ["America/Catamarca", "America/Argentina/Catamarca", "America/Argentina/ComodRivadavia"],
    "Cayenne, French Guiana": ["America/Cayenne"],
    "Cayman Islands": ["America/Cayman"],
    "Center (North Dakota), United States": ["America/North_Dakota/Center"],
    "Ceuta, Spain": ["Africa/Ceuta"],
    "Chagos Archipelago": ["Indian/Chagos"],
    "Chatham Islands, New Zealand": ["Pacific/Chatham", "NZ-CHAT"],
    "Chişinău, Moldova": ["Europe/Chisinau", "Europe/Tiraspol"],
    "Chicago, United States": ["America/Chicago", "US/Central"],
    "Chihuahua, Mexico": ["America/Chihuahua"],
    "Chita Zabaykalsky, Russia": ["Asia/Chita"],
    "Choibalsan, Mongolia": ["Asia/Choibalsan"],
    "Christmas Island": ["Indian/Christmas"],
    "Chuuk, Micronesia": ["Pacific/Truk", "Pacific/Chuuk", "Pacific/Yap"],
    "Ciudad Juárez, Mexico": ["America/Ciudad_Juarez"],
    "Cocos (Keeling) Islands": ["Indian/Cocos"],
    "Colombo, Sri Lanka": ["Asia/Colombo"],
    "Comoros": ["Indian/Comoro"],
    "Conakry, Guinea": ["Africa/Conakry"],
    "Copenhagen, Denmark": ["Europe/Copenhagen"],
    "Costa Rica": ["America/Costa_Rica"],
    "Creston, Canada": ["America/Creston"],
    "Cuiabá, Brazil": ["America/Cuiaba"],
    "Curaçao": ["America/Curacao"],
    "Dakar, Senegal": ["Africa/Dakar"],
    "Damascus, Syria": ["Asia/Damascus"],
    "Danmarkshavn, Greenland": ["America/Danmarkshavn"],
    "Dar es Salaam, Tanzania": ["Africa/Dar_es_Salaam"],
    "Darwin, Australia": ["Australia/Darwin", "Australia/North"],
    "Davis Station, Vestfold Hills": ["Antarctica/Davis"],
    "Dawson Creek, Canada": ["America/Dawson_Creek"],
    "Dawson, Canada": ["America/Dawson"],
    "Denver, United States": ["America/Denver", "America/Shiprock", "Navajo", "US/Mountain"],
    "Detroit, United States": ["America/Detroit", "US/Michigan"],
    "Dhaka, Bangladesh": ["Asia/Dhaka", "Asia/Dacca"],
    "Dili, East Timor": ["Asia/Dili"],
    "Djibouti": ["Africa/Djibouti"],
    "Dominica": ["America/Dominica"],
    "Douala, Cameroon": ["Africa/Douala"],
    "Dubai, United Arab Emirates": ["Asia/Dubai"],
    "Dublin, Ireland": ["Europe/Dublin", "Eire"],
    "Dumont d\u0027Urville Station, Terre Adélie": ["Antarctica/DumontDUrville"],
    "Dushanbe, Tajikistan": ["Asia/Dushanbe"],
    "Easter Island, Chile": ["Pacific/Easter", "Chile/EasterIsland"],
    "Edmonton, Canada": ["America/Edmonton", "Canada/Mountain", "America/Yellowknife"],
    "Efate, Vanuatu": ["Pacific/Efate"],
    "Eirunepé, Brazil": ["America/Eirunepe"],
    "El Aaiún, Western Sahara": ["Africa/El_Aaiun"],
    "El Salvador": ["America/El_Salvador"],
    "Enderbury Island, Kiribati": ["Pacific/Enderbury", "Pacific/Kanton"],
    "Eucla, Australia": ["Australia/Eucla"],
    "Fakaofo, Tokelau": ["Pacific/Fakaofo"],
    "Famagusta, Cyprus": ["Asia/Famagusta"],
    "Faroe Islands": ["Atlantic/Faeroe", "Atlantic/Faroe"],
    "Fernando de Noronha, Brazil": ["America/Noronha", "Brazil/DeNoronha"],
    "Fiji": ["Pacific/Fiji"],
    "Fort Nelson, Canada": ["America/Fort_Nelson"],
    "Fortaleza, Brazil": ["America/Fortaleza"],
    "Freetown, Sierra Leone": ["Africa/Freetown"],
    "Funafuti, Tuvalu": ["Pacific/Funafuti"],
    "Gaborone, Botswana": ["Africa/Gaborone"],
    "Galápagos Islands, Ecuador": ["Pacific/Galapagos"],
    "Gambiera Islands, French Polynesia": ["Pacific/Gambier"],
    "Gaza Strip, Palestinian Territories": ["Asia/Gaza"],
    "Gibraltar": ["Europe/Gibraltar"],
    "Glace Bay, Canada": ["America/Glace_Bay"],
    "Goose Bay, Canada": ["America/Goose_Bay"],
    "Grand Turk, Turks and Caicos Islands": ["America/Grand_Turk"],
    "Greenwich Mean Time": ["GMT"],
    "Grenada": ["America/Grenada"],
    "Guadalcanal, Solomon Islands": ["Pacific/Guadalcanal"],
    "Guadeloupe": ["America/Guadeloupe"],
    "Guam": ["Pacific/Guam"],
    "Guatemala": ["America/Guatemala"],
    "Guayaquil, Ecuador": ["America/Guayaquil"],
    "Guernsey": ["Europe/Guernsey"],
    "Guyana": ["America/Guyana"],
    "Halifax, Canada": ["America/Halifax", "Canada/Atlantic"],
    "Harare, Zimbabwe": ["Africa/Harare"],
    "Havana, Cuba": ["America/Havana", "Cuba"],
    "Helsinki, Finland": ["Europe/Helsinki"],
    "Hermosillo, Mexico": ["America/Hermosillo"],
    "Ho Chi Minh City, Vietnam": ["Asia/Saigon", "Asia/Ho_Chi_Minh"],
    "Hobart, Australia": ["Australia/Hobart", "Australia/Tasmania", "Australia/Currie"],
    "Hong Kong SAR China": ["Asia/Hong_Kong", "Hongkong"],
    "Honolulu, United States": ["Pacific/Honolulu", "US/Hawaii", "Pacific/Johnston"],
    "Indianapolis, United States": ["America/Indianapolis", "America/Fort_Wayne", "America/Indiana/Indianapolis", "US/East-Indiana"],
    "Inuvik, Canada": ["America/Inuvik"],
    "Iqaluit, Canada": ["America/Iqaluit", "America/Pangnirtung"],
    "Irkutsk, Russia": ["Asia/Irkutsk"],
    "Isle of Man": ["Europe/Isle_of_Man"],
    "Istanbul, Türkiye": ["Europe/Istanbul", "Asia/Istanbul", "Turkey"],
    "Ittoqqortoormiit (Scoresbysund), Greenland": ["America/Scoresbysund"],
    "Jakarta, Indonesia": ["Asia/Jakarta"],
    "Jamaica": ["America/Jamaica", "Jamaica"],
    "Jayapura, Indonesia": ["Asia/Jayapura"],
    "Jersey": ["Europe/Jersey"],
    "Jerusalem": ["Asia/Jerusalem", "Asia/Tel_Aviv", "Israel"],
    "Johannesburg, South Africa": ["Africa/Johannesburg"],
    "Juba, South Sudan": ["Africa/Juba"],
    "Jujuy, Argentina": ["America/Jujuy", "America/Argentina/Jujuy"],
    "Juneau (Alaska), United States": ["America/Juneau"],
    "Kabul, Afghanistan": ["Asia/Kabul"],
    "Kaliningrad, Russia": ["Europe/Kaliningrad"],
    "Kamchatka Peninsula, Russia": ["Asia/Kamchatka"],
    "Kampala, Uganda": ["Africa/Kampala"],
    "Karachi, Pakistan": ["Asia/Karachi"],
    "Kathmandu, Nepal": ["Asia/Katmandu", "Asia/Kathmandu"],
    "Kerguelen Islands, French Southern Territories": ["Indian/Kerguelen"],
    "Khandyga Tomponsky, Russia": ["Asia/Khandyga"],
    "Khartoum, Sudan": ["Africa/Khartoum"],
    "Khovd (Hovd), Mongolia": ["Asia/Hovd"],
    "Kigali, Rwanda": ["Africa/Kigali"],
    "Kinshasa, Democratic Republic of the Congo": ["Africa/Kinshasa"],
    "Kiritimati, Kiribati": ["Pacific/Kiritimati"],
    "Kirov, Russia": ["Europe/Kirov"],
    "Knox (Indiana), United States": ["America/Indiana/Knox", "America/Knox_IN", "US/Indiana-Starke"],
    "Kolkata, India": ["Asia/Calcutta", "Asia/Kolkata"],
    "Kosrae, Micronesia": ["Pacific/Kosrae"],
    "Krasnoyarsk, Russia": ["Asia/Krasnoyarsk"],
    "Kuala Lumpur, Malaysia": ["Asia/Kuala_Lumpur"],
    "Kuching, Malaysia": ["Asia/Kuching"],
    "Kuwait": ["Asia/Kuwait"],
    "Kwajalein, Marshall Islands": ["Pacific/Kwajalein", "Kwajalein"],
    "Kyiv, Ukraine": ["Europe/Kyiv", "Europe/Zaporozhye", "Europe/Uzhgorod"],
    "Kyzylorda, Kazakhstan": ["Asia/Qyzylorda"],
    "La Paz, Bolivia": ["America/La_Paz"],
    "La Rioja, Argentina": ["America/Argentina/La_Rioja"],
    "Lagos, Nigeria": ["Africa/Lagos"],
    "Libreville, Gabon": ["Africa/Libreville"],
    "Lima, Peru": ["America/Lima"],
    "Lindeman Island, Australia": ["Australia/Lindeman"],
    "Lisbon, Portugal": ["Europe/Lisbon", "Portugal"],
    "Ljubljana, Slovenia": ["Europe/Ljubljana"],
    "Lomé, Togo": ["Africa/Lome"],
    "London, United Kingdom": ["Europe/London", "Europe/Belfast", "GB", "GB-Eire"],
    "Longyearbyen, Svalbard": ["Arctic/Longyearbyen", "Atlantic/Jan_Mayen"],
    "Lord Howe Island, Australia": ["Australia/Lord_Howe", "Australia/LHI"],
    "Los Angeles, United States": ["America/Los_Angeles", "US/Pacific", "US/Pacific-New"],
    "Louisville (Kentucky), United States": ["America/Louisville", "America/Kentucky/Louisville"],
    "Luanda, Angola": ["Africa/Luanda"],
    "Lubumbashi, Democratic Republic of the Congo": ["Africa/Lubumbashi"],
    "Lusaka, Zambia": ["Africa/Lusaka"],
    "Luxembourg": ["Europe/Luxembourg"],
    "Mérida, Mexico": ["America/Merida"],
    "Macau SAR China": ["Asia/Macau", "Asia/Macao"],
    "Maceió, Brazil": ["America/Maceio"],
    "Macquarie Island Station, Macquarie Island": ["Antarctica/Macquarie"],
    "Madeira, Portugal": ["Atlantic/Madeira"],
    "Madrid, Spain": ["Europe/Madrid"],
    "Magadan, Russia": ["Asia/Magadan"],
    "Mahé, Seychelles": ["Indian/Mahe"],
    "Majuro, Marshall Islands": ["Pacific/Majuro"],
    "Makassar, Indonesia": ["Asia/Makassar", "Asia/Ujung_Pandang"],
    "Malabo, Equatorial Guinea": ["Africa/Malabo"],
    "Maldives": ["Indian/Maldives"],
    "Malta": ["Europe/Malta"],
    "Managua, Nicaragua": ["America/Managua"],
    "Manaus, Brazil": ["America/Manaus", "Brazil/West"],
    "Manila, Philippines": ["Asia/Manila"],
    "Maputo, Mozambique": ["Africa/Maputo"],
    "Marengo (Indiana), United States": ["America/Indiana/Marengo"],
    "Mariehamn, Åland, Finland": ["Europe/Mariehamn"],
    "Marigot, Saint Martin": ["America/Marigot"],
    "Marquesas Islands, French Polynesia": ["Pacific/Marquesas"],
    "Martinique": ["America/Martinique"],
    "Maseru, Lesotho": ["Africa/Maseru"],
    "Matamoros, Mexico": ["America/Matamoros"],
    "Mauritius": ["Indian/Mauritius"],
    "Mawson Station, Holme Bay": ["Antarctica/Mawson"],
    "Mayotte": ["Indian/Mayotte"],
    "Mazatlán, Mexico": ["America/Mazatlan", "Mexico/BajaSur"],
    "Mbabane, Swaziland": ["Africa/Mbabane"],
    "McMurdo Station, Ross Island": ["Antarctica/McMurdo"],
    "Melbourne, Australia": ["Australia/Melbourne", "Australia/Victoria"],
    "Mendoza, Argentina": ["America/Mendoza", "America/Argentina/Mendoza"],
    "Menominee (Michigan), United States": ["America/Menominee"],
    "Metlakatla (Alaska), United States": ["America/Metlakatla"],
    "Mexico City, Mexico": ["America/Mexico_City", "Mexico/General"],
    "Midway Islands, U.S. Minor Outlying Islands": ["Pacific/Midway"],
    "Minsk, Belarus": ["Europe/Minsk"],
    "Mogadishu, Somalia": ["Africa/Mogadishu"],
    "Monaco": ["Europe/Monaco"],
    "Moncton, Canada": ["America/Moncton"],
    "Monrovia, Liberia": ["Africa/Monrovia"],
    "Monterrey, Mexico": ["America/Monterrey"],
    "Montevideo, Uruguay": ["America/Montevideo"],
    "Monticello (Kentucky), United States": ["America/Kentucky/Monticello"],
    "Montserrat": ["America/Montserrat"],
    "Moscow, Russia": ["Europe/Moscow", "W-SU"],
    "Muscat, Oman": ["Asia/Muscat"],
    "Nairobi, Kenya": ["Africa/Nairobi"],
    "Nassau, Bahamas": ["America/Nassau"],
    "Nauru": ["Pacific/Nauru"],
    "N\u0027Djamena, Chad": ["Africa/Ndjamena"],
    "New Salem (North Dakota), United States": ["America/North_Dakota/New_Salem"],
    "New York, United States": ["America/New_York", "US/Eastern"],
    "Niamey, Niger": ["Africa/Niamey"],
    "Nicosia, Cyprus": ["Asia/Nicosia", "Europe/Nicosia"],
    "Niue": ["Pacific/Niue"],
    "Nome (Alaska), United States": ["America/Nome"],
    "Norfolk Island": ["Pacific/Norfolk"],
    "Nouakchott, Mauritania": ["Africa/Nouakchott"],
    "Noumea, New Caledonia": ["Pacific/Noumea"],
    "Novokuznetsk, Russia": ["Asia/Novokuznetsk"],
    "Novosibirsk, Russia": ["Asia/Novosibirsk"],
    "Nuuk (Godthåb), Greenland": ["America/Godthab", "America/Nuuk"],
    "Ojinaga, Mexico": ["America/Ojinaga"],
    "Omsk, Russia": ["Asia/Omsk"],
    "Oral, Kazakhstan": ["Asia/Oral"],
    "Oslo, Norway": ["Europe/Oslo"],
    "Ouagadougou, Burkina Faso": ["Africa/Ouagadougou"],
    "Pago Pago, American Samoa": ["Pacific/Pago_Pago", "Pacific/Samoa", "US/Samoa"],
    "Palau": ["Pacific/Palau"],
    "Palmer Station, Anvers Island": ["Antarctica/Palmer"],
    "Panama": ["America/Panama"],
    "Paramaribo, Suriname": ["America/Paramaribo"],
    "Paris, France": ["Europe/Paris"],
    "Perth, Australia": ["Australia/Perth", "Australia/West"],
    "Petersburg (Indiana), United States": ["America/Indiana/Petersburg"],
    "Phnom Penh, Cambodia": ["Asia/Phnom_Penh"],
    "Phoenix, United States": ["America/Phoenix", "US/Arizona"],
    "Pitcairn Islands": ["Pacific/Pitcairn"],
    "Podgorica, Montenegro": ["Europe/Podgorica"],
    "Pohnpei, Micronesia": ["Pacific/Ponape", "Pacific/Pohnpei"],
    "Pontianak, Indonesia": ["Asia/Pontianak"],
    "Port Moresby, Papua New Guinea": ["Pacific/Port_Moresby"],
    "Port of Spain, Trinidad and Tobago": ["America/Port_of_Spain"],
    "Port-au-Prince, Haiti": ["America/Port-au-Prince"],
    "Porto Velho, Brazil": ["America/Porto_Velho"],
    "Porto-Novo, Benin": ["Africa/Porto-Novo"],
    "Prague, Czech Republic": ["Europe/Prague"],
    "Puerto Rico": ["America/Puerto_Rico"],
    "Punta Arenas, Chile": ["America/Punta_Arenas"],
    "Pyongyang, North Korea": ["Asia/Pyongyang"],
    "Qaanaaq (Thule), Greenland": ["America/Thule"],
    "Qatar": ["Asia/Qatar"],
    "Qostanay (Kostanay), Kazakhstan": ["Asia/Qostanay"],
    "Réunion": ["Indian/Reunion"],
    "Rankin Inlet, Canada": ["America/Rankin_Inlet"],
    "Río Gallegos, Argentina": ["America/Argentina/Rio_Gallegos"],
    "Rarotonga, Cook Islands": ["Pacific/Rarotonga"],
    "Recife, Brazil": ["America/Recife"],
    "Regina, Canada": ["America/Regina", "Canada/East-Saskatchewan", "Canada/Saskatchewan"],
    "Resolute, Canada": ["America/Resolute"],
    "Reykjavik, Iceland": ["Atlantic/Reykjavik", "Iceland"],
    "Riga, Latvia": ["Europe/Riga"],
    "Rio Branco, Brazil": ["America/Rio_Branco", "America/Porto_Acre", "Brazil/Acre"],
    "Riyadh, Saudi Arabia": ["Asia/Riyadh"],
    "Rome, Italy": ["Europe/Rome"],
    "Rothera Station, Adelaide Island": ["Antarctica/Rothera"],
    "São Paulo, Brazil": ["America/Sao_Paulo", "Brazil/East"],
    "São Tomé, São Tomé and Príncipe": ["Africa/Sao_Tome"],
    "Saint Barthélemy": ["America/St_Barthelemy"],
    "Saint Helena": ["Atlantic/St_Helena"],
    "Saint Kitts": ["America/St_Kitts"],
    "Saint Lucia": ["America/St_Lucia"],
    "Saint Pierre and Miquelon": ["America/Miquelon"],
    "Saint Thomas, U.S. Virgin Islands": ["America/St_Thomas", "America/Virgin"],
    "Saint Vincent, Saint Vincent and the Grenadines": ["America/St_Vincent"],
    "Saipan, Northern Mariana Islands": ["Pacific/Saipan"],
    "Sakhalin, Russia": ["Asia/Sakhalin"],
    "Salta, Argentina": ["America/Argentina/Salta"],
    "Samara, Russia": ["Europe/Samara"],
    "Samarkand, Uzbekistan": ["Asia/Samarkand"],
    "San Juan, Argentina": ["America/Argentina/San_Juan"],
    "San Luis, Argentina": ["America/Argentina/San_Luis"],
    "San Marino": ["Europe/San_Marino"],
    "Santarém, Brazil": ["America/Santarem"],
    "Santiago, Chile": ["America/Santiago", "Chile/Continental"],
    "Santo Domingo, Dominican Republic": ["America/Santo_Domingo"],
    "Sarajevo, Bosnia and Herzegovina": ["Europe/Sarajevo"],
    "Saratov, Russia": ["Europe/Saratov"],
    "Seoul, South Korea": ["Asia/Seoul", "ROK"],
    "Shanghai, China": ["Asia/Shanghai", "Asia/Chongqing", "Asia/Chungking", "Asia/Harbin", "PRC"],
    "Singapore": ["Asia/Singapore", "Singapore"],
    "Sint Maarten": ["America/Lower_Princes"],
    "Sitka (Alaska), United States": ["America/Sitka"],
    "Skopje, Macedonia": ["Europe/Skopje"],
    "Sofia, Bulgaria": ["Europe/Sofia"],
    "South Georgia and the South Sandwich Islands": ["Atlantic/South_Georgia"],
    "Srednekolymsk, Russia": ["Asia/Srednekolymsk"],
    "St. John\u0027s, Canada": ["America/St_Johns", "Canada/Newfoundland"],
    "Stanley, Falkland Islands": ["Atlantic/Stanley"],
    "Stockholm, Sweden": ["Europe/Stockholm"],
    "Swift Current, Canada": ["America/Swift_Current"],
    "Sydney, Australia": ["Australia/Sydney", "Australia/ACT", "Australia/Canberra", "Australia/NSW"],
    "Syowa Station, East Ongul Island": ["Antarctica/Syowa"],
    "Tahiti, French Polynesia": ["Pacific/Tahiti"],
    "Taipei, Taiwan": ["Asia/Taipei", "ROC"],
    "Tallinn, Estonia": ["Europe/Tallinn"],
    "Tarawa, Kiribati": ["Pacific/Tarawa"],
    "Tashkent, Uzbekistan": ["Asia/Tashkent"],
    "Tbilisi, Georgia": ["Asia/Tbilisi"],
    "Tegucigalpa, Honduras": ["America/Tegucigalpa"],
    "Tehran, Iran": ["Asia/Tehran", "Iran"],
    "Tell City (Indiana), United States": ["America/Indiana/Tell_City"],
    "Thimphu, Bhutan": ["Asia/Thimphu", "Asia/Thimbu"],
    "Tijuana, Mexico": ["America/Tijuana", "America/Ensenada", "Mexico/BajaNorte", "America/Santa_Isabel"],
    "Tirane, Albania": ["Europe/Tirane"],
    "Tokyo, Japan": ["Asia/Tokyo", "Japan"],
    "Tomsk, Russia": ["Asia/Tomsk"],
    "Tongatapu, Tonga": ["Pacific/Tongatapu"],
    "Toronto, Canada": ["America/Toronto", "America/Montreal", "Canada/Eastern", "America/Nipigon", "America/Thunder_Bay"],
    "Tortola, British Virgin Islands": ["America/Tortola"],
    "Tripoli, Libya": ["Africa/Tripoli", "Libya"],
    "Troll Station, Queen Maud Land": ["Antarctica/Troll"],
    "Tucumán, Argentina": ["America/Argentina/Tucuman"],
    "Tunis, Tunisia": ["Africa/Tunis"],
    "Ulaanbaatar (Ulan Bator), Mongolia": ["Asia/Ulaanbaatar", "Asia/Ulan_Bator"],
    "Ulyanovsk, Russia": ["Europe/Ulyanovsk"],
    "US Central Time": ["CST6CDT"],
    "US Eastern Time": ["EST5EDT"],
    "US Mountain Time": ["MST7MDT"],
    "US Pacific Time": ["PST8PDT"],
    "Ushuaia, Argentina": ["America/Argentina/Ushuaia"],
    "Ust-Nera Oymyakonsky, Russia": ["Asia/Ust-Nera"],
    "UTC (Coordinated Universal Time)": ["UTC"],
    "Vaduz, Liechtenstein": ["Europe/Vaduz"],
    "Vancouver, Canada": ["America/Vancouver", "Canada/Pacific"],
    "Vatican City": ["Europe/Vatican"],
    "Vevay (Indiana), United States": ["America/Indiana/Vevay"],
    "Vienna, Austria": ["Europe/Vienna"],
    "Vientiane, Laos": ["Asia/Vientiane"],
    "Vilnius, Lithuania": ["Europe/Vilnius"],
    "Vincennes (Indiana), United States": ["America/Indiana/Vincennes"],
    "Vladivostok, Russia": ["Asia/Vladivostok"],
    "Volgograd, Russia": ["Europe/Volgograd"],
    "Vostok Station, Lake Vostok": ["Antarctica/Vostok"],
    "Wake Island, U.S. Minor Outlying Islands": ["Pacific/Wake"],
    "Wallis Islands, Wallis and Futuna": ["Pacific/Wallis"],
    "Warsaw, Poland": ["Europe/Warsaw", "Poland"],
    "West Bank, Palestinian Territories": ["Asia/Hebron"],
    "Whitehorse, Canada": ["America/Whitehorse", "Canada/Yukon"],
    "Winamac (Indiana), United States": ["America/Indiana/Winamac"],
    "Windhoek, Namibia": ["Africa/Windhoek"],
    "Winnipeg, Canada": ["America/Winnipeg", "Canada/Central", "America/Rainy_River"],
    "Yakutat (Alaska), United States": ["America/Yakutat"],
    "Yakutsk, Russia": ["Asia/Yakutsk"],
    "Yangon (Rangoon), Burma": ["Asia/Rangoon", "Asia/Yangon"],
    "Yekaterinburg, Russia": ["Asia/Yekaterinburg"],
    "Yerevan, Armenia": ["Asia/Yerevan"],
    "Zagreb, Croatia": ["Europe/Zagreb"],
    "Zurich, Switzerland": ["Europe/Zurich"]
  };
