import React from "react";

import SuppressibleConfirmationModalTs from "./SuppressibleConfirmationModalTs";
import { type ISimpleModalProps } from "../../interfaces";

export default class RevertConfirmationModalTs extends React.Component<ISimpleModalProps> {
  render() {
    const title = this.props.title || "Confirm Revert";
    const message =
      this.props.message || "Performing this operation will result in all changes being lost. Do you wish to continue?";
    return <SuppressibleConfirmationModalTs title={title} message={message} modalManager={this.props.modalManager} />;
  }
}
