import React, { Component } from "react";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import { ValidatedForm, FormSectionHeader, validatedFormPropTypes } from "../../../../components";
import validationSchemas from "../../../../utils/validationSchemas";
import { ensureTrimmed } from "../../../../utils/stringUtils";

export class RoleConfiguration extends Component {
  componentDidMount() {
    const { bindSubmitForm, submitForm, bindResetForm, resetForm } = this.props;
    if (bindSubmitForm) {
      bindSubmitForm(submitForm);
    }
    if (bindResetForm) {
      bindResetForm(resetForm);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dirty !== this.props.dirty && this.props.onDirtyChanged) {
      this.props.onDirtyChanged(this.props.dirty);
    }
  }

  renderFormBody = () => {
    const {
      values,
      errors,
      dirty,
      touched,
      isValid,
      onIsValidChange,
      handleChange,
      handleBlur,
      setFieldValue,
      resetForm,
      isReadOnly,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      dirty,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      resetForm,
    };

    return (
      <>
        <FormSectionHeader title="Role Information" />
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          placeholder="Enter Role Name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={isReadOnly}
          onBlur={() => ensureTrimmed(values.name, (value) => setFieldValue("name", value))}
          {...validatedFieldProps}
        />
        <ValidatedForm.TextAreaField
          label="Description"
          value={values.description}
          propertyName="description"
          placeholder="Enter Brief Description"
          disabled={isReadOnly}
          {...validatedFieldProps}
        />
      </>
    );
  };

  render() {
    const { handleSubmit, dirty, isEdit } = this.props;
    return (
      <section className="role-info-form">
        <ValidatedForm
          title="Role information"
          handleSubmit={handleSubmit}
          loaderOnSubmit={!isEdit}
          dirty={dirty}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving this role? All information entered will be lost.",
          }}
          {...this.props}
        >
          {this.renderFormBody()}
        </ValidatedForm>
      </section>
    );
  }
}

RoleConfiguration.defaultProps = {
  isReadOnly: false,
};

RoleConfiguration.propTypes = {
  ...validatedFormPropTypes,
  onIsValidChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

const ConnectedComponent = withFormik({
  validationSchema: (props) => {
    const { currentConfiguration = { name: "" } } = props;
    return validationSchemas.getRoleSchema(currentConfiguration.name);
  },
  isInitialValid: false,
  mapPropsToValues: (props) => {
    const { currentConfiguration = { name: "", description: "" } } = props;
    return {
      name: currentConfiguration.name,
      description: currentConfiguration.description,
    };
  },
  handleSubmit: (values, bag) => {
    const validatedValues = validationSchemas.getRoleSchema(values.name).cast(values);
    bag.props.onSubmit(validatedValues);
  },
  enableReinitialize: true,
})(RoleConfiguration);
export default ConnectedComponent;
