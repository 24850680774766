import { Component } from "react";

import { ViewType } from "../../../../../enums";
import { NoResults, ListViewBase, ListViewWithInMemorySorting } from "../../../../../components";
import ContactRow from "../../../EditAccount/EditContacts/AccountContactsList/ContactRow";
import columnOptions from "../../../EditAccount/EditContacts/AccountContactsList/columnOptions";
import type NewContact from "../../../../../interfaces/NewContact";

import "./ContactList.scss";

export interface ContactListProps {
  contacts: NewContact[];
  onMainContactSet: (contactId: number) => void;
  onContactRemoved: (contactId: number) => void;
}

const mapColumnName = (columnName: string) => {
  switch (columnName.toLowerCase()) {
    case columnOptions[3].name.toLowerCase():
      return "title";
    case columnOptions[1].name.toLowerCase():
      return "firstName";
    default:
      return columnName;
  }
};

export default class ContactList extends Component<ContactListProps> {
  renderNoResults = () => {
    return (
      <NoResults
        title="No Contacts"
        description={
          <div className="text-muted">
            <i>Use the form above to add contacts for this account</i>
          </div>
        }
        iconClassName="fal fa-user"
      />
    );
  };

  buildTableBody = (contact: NewContact) => (
    <ContactRow
      contact={contact}
      isReadOnly={false}
      onContactRemoved={this.props.onContactRemoved}
      onMainContactChanged={this.props.onMainContactSet}
      isMainContact={contact.isMain}
     />
  );

  render() {
    const { contacts } = this.props;

    return (
      <ListViewWithInMemorySorting
        as={ListViewBase}
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        mapColumnName={mapColumnName}
        items={contacts}
        withFooter={false}
        withSelection={false}
        noResultsContent={this.renderNoResults()}
        buildTableBody={this.buildTableBody}
      />
    );
  }
}
