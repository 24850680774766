import { type AppDispatch } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import PacksContextInfo from "features/Licensing/ContentAssignmentModalLicensingSteps/PackContextModalContent/packsContextInfo/PacksContextInfo";
import { type PacksContextItem } from "../../../../interfaces";
import { type ModalWithStepsStep } from "../../../modal/ModalWithSteps";
import { fetchContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";

import "./packsContextStep.scss";

export interface PacksContextStepProps extends ModalWithStepsStep {
  info: Array<PacksContextItem>;
  onIsDataValidChange: (isValid: boolean) => void;
  noDataLoaded: () => void;
  setModalStepHeader: () => void;
}

export type RenderModalActionsParams = {
  isDataValid: boolean;
  onIsDataValidChange: (isDataValid: boolean) => void;
};

export type PacksContextStepAllProps = PacksContextStepProps & PropsFromRedux;

function PacksContextStep(props: PacksContextStepAllProps) {
  return (
    <div className="packs-context-step">
      <PacksContextInfo {...props} />
    </div>
  );
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchContentPacks: bindActionCreators(fetchContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(PacksContextStep);
export default ConnectedComponent;
