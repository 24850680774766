import React, { useCallback, useEffect, useState } from "react";
import { type Dispatch } from "@reduxjs/toolkit";
import { connect, type ConnectedProps } from "react-redux";
import { reset } from "../state/slices/surveyPerformanceSlice";
import { type RootState } from "../../../Application/globaltypes/redux";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";
import SurveyPerformanceBody from "./SurveyPerformanceBody";
import { usePerformanceBreadcrumbs } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import { Tooltip } from "../../../../components/common/tooltip";
import { defaultDateFilter, isBsi } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "../../../../featureFlags";
import {
  reset as resetExport,
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  selectIsExportActive,
} from "features/Reporting/state/export/exportSlice";
import { bindAction, type IWizardStep } from "interfaces";

import "./SurveyPerformance.scss";
import "../../Common/utils/performanceSCSSUtils.scss";
import { useAccountFilterShouldShow } from "features/Reporting/Content/queries/useAccountFilterShouldShow";

export type Props = IWizardStep &
  PropsFromRedux & {
    surveyId: number;
    surveyTitle: string;
  };

export const SurveyPerformance: React.FC<Props> = ({
  surveyId,
  accountId,
  accountType,
  surveyTitle,
  resetState,
  resetExports,
  exportStatus,
  exportInfo,
  acceptHandlers,
  isExportEnabled,
  hasDataToExport,
}) => {
  const accountFilterShouldShow = useAccountFilterShouldShow(accountId, accountType);
  const [dateFilter, setDateFilter] = useState(
    defaultDateFilter({
      includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
      accountId,
      includeDistinct: true,
    }),
  );
  const reportEnabled = useFeatureFlag(FeatureFlags.SurveyReport);
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();

  useEffect(() => {
    setDateFilter(
      defaultDateFilter({
        includeAccountsDropdown: accountFilterShouldShow && isBsi(accountId),
        accountId,
        includeDistinct: true,
      }),
    );
  }, [accountFilterShouldShow, accountId]);

  useEffect(() => {
    return () => {
      resetState();
      resetExports();
    };
  }, [resetState, resetExports]);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo?.args);
  }, [exportInfo]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="surveyPerformanceRoot">
      <header className="surveyHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="surveyTitle">Summary</h2>
        )}
        <div className="surveyActions">
          <ReportingFilter
            currentFilter={dateFilter}
            callback={setDateFilter}
            includeAccountsDropdown={accountFilterShouldShow && isBsi(accountId)}
            includeDistinct
            ignoreInCountAndHide={["type"]}
          />
          {isExportEnabled && (
            <Tooltip
              target={
                <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={!hasDataToExport} />
              }
              content="Export is not available because no one has answered this survey."
              position="left center"
              showAlways
              disabled={hasDataToExport}
            />
          )}
        </div>
      </header>
      <div className={"performanceRoot"}>
        <SurveyPerformanceBody
          surveyId={surveyId}
          surveyTitle={surveyTitle}
          registerBreadcrumb={registerBreadcrumb}
          dateFilter={dateFilter}
          includeAccounts={accountFilterShouldShow && isBsi(accountId) && dateFilter.accounts?.length > 0}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    exportStatus: selectExporting(state),
    isExportEnabled: selectIsExportActive(state),
    exportInfo: selectExportInfo(state),
    hasDataToExport: selectHasDataToExport(state),
    accountId: state.userProfile.accountId,
    accountType: state.userProfile.accountTypeId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetState: bindAction(reset, dispatch),
    resetExports: bindAction(resetExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SurveyPerformance);
export default ConnectedComponent;
