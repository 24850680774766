import React from "react";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import Tooltip from "../../../../../../../../components/common/tooltip/Tooltip";
import styles from "./integration.module.scss";

const INTEGRATION_PATH = "/accounts/home/integrations";

interface IntegrationProps {
  className?: string;
}

export const Integration = (props: IntegrationProps) => {
  const navigate = useNavigate();

  const content = (
    <div className={styles.content}>
      <span className={styles.title}>Integration Not Configured</span>
      <span className={styles.description}>
        An integration must be set up to use an automatic flow trigger. Without an integration, users may only be added
        manually.
      </span>
      <Button basic className={styles.button} onClick={() => navigate(INTEGRATION_PATH)}>
        Add Integration
      </Button>
    </div>
  );

  return (
    <Tooltip
      className={cn(styles.root, props.className)}
      showAlways
      hideOnScroll
      hoverable
      position="top right"
      inverted={false}
      content={content}
      tooltipClassName={styles.integration}
      target={<Icon data-testid="warning-icon" name="warning sign" className={styles.icon} />}
    />
  );
};
