import { connect } from "react-redux";

import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { addSurveyTags, fetchSurveysTags } from "../../state/thunks/surveyCommonThunk";
import { surveysTagsSelector } from "../../state/selectors/surveyCommonSelectors";
import TagsManage from "../../../Common/TagsManage/TagsManage";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: surveysTagsSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchSurveysTags, dispatch),
  onConfirmAction: bindAction(addSurveyTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
