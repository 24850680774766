import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { type PerformanceWithAccountFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { type PeopleOverviewAccountsItem } from "features/Reporting/types/people";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type ColumnOption } from "interfaces/columnOptions";
import { fetchPeopleOverviewAccountsTable, fetchPeopleOverviewAccountsTableExport } from "../utils";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../../peopleStyles.scss";
import { bindActionCreators, type Dispatch } from "@reduxjs/toolkit";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { connect, type ConnectedProps } from "react-redux";
import { queryHasData } from "features/Library/Common/utils/performanceUtils";

export type Props = {
  filter: PerformanceWithAccountFilter;
  handleAccountSelection: (id: number, name: string) => void;
} & PropsFromRedux;

export const AccountsTable = ({
  filter,
  handleAccountSelection,
  setExport,
  setIsExportEnabled,
  accountsExport,
}: Props) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const accountsTableQuery = useQuery({
    queryFn: fetchPeopleOverviewAccountsTable,
    queryKey: ["PeopleOverviewAccountsTable", filter],
    enabled: reportEnabled,
  });

  // Setting appropriate export method
  useEffect(() => {
    setExport({
      method: accountsExport,
      args: [filter],
    });
  }, [filter, setExport, accountsExport]);

  useEffect(() => {
    setIsExportEnabled(queryHasData(accountsTableQuery));
  }, [setIsExportEnabled, accountsTableQuery]);

  const columns: ColumnOption<PeopleOverviewAccountsItem>[] = useMemo(
    () => [
      {
        name: "Name",
        width: 11,
        render(item) {
          return (
            <Tooltip
              target={
                <TextTruncate
                  className="invisibleButton"
                  onClick={() => handleAccountSelection(item.AccountId, item.AccountName)}
                >
                  {item.AccountName}
                </TextTruncate>
              }
              content={item.AccountName}
            />
          );
        },
      },
      {
        name: "Interactions",
        width: 4,
        render(item) {
          return <span>{item.InteractionCount.toLocaleString()}</span>;
        },
      },
    ],
    [handleAccountSelection],
  );

  return (
    <div className="tabSpacer">
      <RequestStatusRenderer state={accountsTableQuery.status}>
        <GenericPerformanceList
          title="Most Active Customers"
          rows={accountsTableQuery.data!}
          columns={columns}
          filter={filter}
        />
      </RequestStatusRenderer>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsExportEnabled: bindAction(setHasDataToExport, dispatch),
  setExport: bindAction(setExportAction, dispatch),
  accountsExport: bindActionCreators(fetchPeopleOverviewAccountsTableExport, dispatch),
});

const connector = connect(null, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AccountsTable);
export default ConnectedComponent;
