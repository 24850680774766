import { combineReducers } from "redux";
import surveysEntityStateReducer from "./reducers/surveysEntityStateReducer";
import { surveysOverviewReducer } from "./reducers/surveyOverviewReducer";
import { surveysDetailsReducer } from "./reducers/surveysDetailsReducer";
import { surveysContentValidationReducer } from "./reducers/surveyContentValidationReducer";
import surveyUsersSlice from "./slices/surveyUsersSlice";
import surveyGroupsSlice from "./slices/surveyGroupsSlice";
import overviewFilters from "./slices/surveyFiltersSlice";
import { type RootState } from "../../../Application/globaltypes/redux";
import surveyCommonSlice from "./slices/surveyCommonSlice";
import surveyPerformanceSlice from "./slices/surveyPerformanceSlice";

const base = combineReducers({
  surveysEntityStateReducer,
  surveysDetailsReducer,
  surveysContentValidationReducer,
  common: surveyCommonSlice,
  performance: surveyPerformanceSlice,
});

const overview = combineReducers({
  surveysOverview: surveysOverviewReducer,
  filters: overviewFilters,
});

const edit = combineReducers({
  users: surveyUsersSlice,
  groups: surveyGroupsSlice,
});

const reducers = combineReducers({
  base,
  edit,
  overview,
});

export type SurveysState = ReturnType<typeof reducers>;

export const surveysStateSelector = (state: RootState) => state.library.surveys;

export const surveyPaginationSelector = (state: RootState) => state.library.surveys.overview.filters.pagination;
export const surveySortingColumnNameSelector = (state: RootState) =>
  state.library.surveys.overview.filters.sortingColumnName;
export const surveySortingDirectionSelector = (state: RootState) =>
  state.library.surveys.overview.filters.sortingDirection;

export default reducers;
