import * as Yup from "yup";
import validationRules from "../validationRules";

const getErrorMessageForMultiSelect = (name: string) =>
  `${name} length should not exceed ${validationRules.maxLabelLength} characters`;

export const labels = Yup.mixed().test(
  "Labels length",
  getErrorMessageForMultiSelect("Label"),
  validationRules.getMultiSelectItemsValidator(validationRules.maxLabelLength),
);

export const softwareApplications = Yup.mixed().test(
  "Software Application length",
  getErrorMessageForMultiSelect("Software Application"),
  validationRules.getMultiSelectItemsValidator(validationRules.maxLabelLength),
);

export const labelValues = Yup.mixed().test(
  "Labels length",
  getErrorMessageForMultiSelect("Label"),
  validationRules.getMultiSelectValuesValidator(validationRules.maxLabelLength),
);

export const softwareApplicationValues = Yup.mixed().test(
  "Software Application length",
  getErrorMessageForMultiSelect("Software Application"),
  validationRules.getMultiSelectValuesValidator(validationRules.maxLabelLength),
);
