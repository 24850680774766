import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../../../enums/reducer";
import {
  createLazyFetchingItemsSlice,
  type LazyFetchingItemsState,
} from "../../../../../../../Application/slices/createLazyFetchingItemsSlice";
import { type NextFlowInfo } from "../../../../types";

export type NextFlowItemsSliceState = LazyFetchingItemsState<NextFlowInfo>;

export const initialState: NextFlowItemsSliceState = {
  itemsCount: 0,
  items: [],
  isLoading: false,
  areAllLoaded: false,
};

const nextFlowItemsSlice = createLazyFetchingItemsSlice<NextFlowInfo>({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "nextFlowItems",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = nextFlowItemsSlice.actions;

export default nextFlowItemsSlice.reducer;
