import React from "react";
import cn from "classnames";
import { ReactComponent as TeamsIcon } from "../../../images/teams-icon.svg";
import { ReactComponent as GenericMessagesIcon } from "../../../images/generic-messages-icon.svg";
import CardContent from "../cardContent/CardContent";
import CommunicationTypes from "../../../enums/communicationTypes";
import { SelectableAssetCard } from "../SelectableAssetCard/SelectableAssetCard";
import "./staticCommunicationCard.scss";

type Communications = {
  [key: string]: {
    icon: React.ReactNode;
    text: string;
  };
};

const communications: Communications = {
  [CommunicationTypes.Email]: {
    icon: <i className="big fa-envelope icon" />,
    text: "EMAIL",
  },
  [CommunicationTypes.Message]: {
    icon: <i className="big fa-message icon" />,
    text: "MESSAGE",
  },
  [CommunicationTypes.Teams]: {
    icon: <TeamsIcon className="filter-icon" />,
    text: "TEAMS",
  },
  [CommunicationTypes.GenericMessages]: {
    icon: <GenericMessagesIcon className="filter-icon" />,
    text: "GENERIC MESSAGE",
  },
};

export interface StaticCommunicationCardProps {
  id: number;
  title: string;
  className?: string;
  cardType: CommunicationTypes;
  isSelected?: boolean;
  onClick?: () => void;
}

const StaticCommunicationCard: React.FC<StaticCommunicationCardProps> = ({
  id,
  title,
  isSelected,
  className,
  cardType,
  onClick,
}) => {
  return (
    <SelectableAssetCard
      key={id}
      className={cn("flow-designer-communication-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-communication-card"
      data-hook-id={id}
      isSelected={isSelected}
      onClick={onClick}
    >
      <div className="card-icon">{communications[cardType].icon}</div>
      <span className="text">{communications[cardType].text}</span>
      <CardContent title={title} />
    </SelectableAssetCard>
  );
};

export default StaticCommunicationCard;
