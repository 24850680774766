import { type FC, useEffect, useState } from "react";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";

import "./confirmation.scss";
import { type IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import ContentAssignmentModalTwoSteps from "../../../../../components/assignmentModals/contentAssignmentModal/contentAssignmentModalTwoSteps/ContentAssignmentModalTwoSteps";
import { type PacksContextItem } from "../../../../../interfaces";
import { pluralize } from "../../../../../utils/stringUtils";

export interface ConfirmationProps extends IWizardStep {
  schedule: Date;
  scheduleTimeZone: string | null;
  communicationId: string;
  communicationTitle: string;
  communicationType: string;
  selectedUserIds: number[];
  selectedGroupIds: number[];
  selectedTemplateGroupIds?: number[];
  promptText: string;
  onConfirm: () => void;
  onCancel: () => void;
  shouldShowLicensingModal: (id: string, type: string, userIds: number[], groupIds: number[]) => Promise<boolean>;
}

export const Confirmation: FC<ConfirmationProps> = (props) => {
  const {
    schedule,
    scheduleTimeZone,
    communicationId,
    communicationTitle,
    communicationType,
    selectedUserIds,
    selectedGroupIds,
    selectedTemplateGroupIds,
    promptText,
    acceptHandlers,
    onConfirm,
    onCancel,
    shouldShowLicensingModal,
  } = props;

  const [modalVisible, showModal] = useState(false);

  useEffect(() => {
    acceptHandlers?.({
      onFinish: openLicenseModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmLicense = () => {
    showModal(false);
    onConfirm();
  };

  const cancelLicenseModal = () => {
    showModal(false);
    onCancel();
  };

  const openLicenseModal = async () => {
    const shouldShowModal = await shouldShowLicensingModal(
      communicationId,
      communicationType.toLowerCase(),
      selectedUserIds,
      selectedGroupIds,
    );

    if (shouldShowModal) {
      showModal(true);
    } else {
      onConfirm();
    }
  };

  const getEmailPackContextItem = (): PacksContextItem => {
    return {
      id: communicationId,
      title: communicationTitle,
      type: communicationType,
    };
  };

  const getDateTimeSection = () => {
    return (
      <li>
        On{" "}
        <b>
          {scheduleTimeZone
            ? dateTimeUtils.formatDateWithTimeZone(schedule, scheduleTimeZone)
            : dateTimeUtils.formatDate(schedule)}{" "}
        </b>
        at <b>{dateTimeUtils.dateToTimeString(schedule, "h:mm A z", scheduleTimeZone)}</b>
      </li>
    );
  };

  const getMessageForPeoples = (peoples: number[], peoplesTypeName: string) => {
    return (
      <>
        All users in <b>{peoples.length}</b> {pluralize(peoplesTypeName, peoples.length)}
      </>
    );
  };

  const getSectionForUser = () => {
    return (
      <li>
        And <b>{selectedUserIds.length}</b> individual {pluralize("user", selectedGroupIds.length)}
      </li>
    );
  };

  const getPeopleSection = () => {
    const usersSelected = selectedUserIds && selectedUserIds.length > 0;
    const groupsSelected = selectedGroupIds && selectedGroupIds.length > 0;
    const templateGroupsSelected = selectedTemplateGroupIds && selectedTemplateGroupIds.length > 0;
    return (
      <>
        {(() => {
          if (usersSelected && groupsSelected && templateGroupsSelected)
            return (
              <>
                <li>
                  {getMessageForPeoples(selectedTemplateGroupIds, "template")} and <b>{selectedGroupIds.length}</b>{" "}
                  {pluralize("group", selectedGroupIds.length)}
                </li>
                {getSectionForUser()}
              </>
            );
          if (usersSelected && groupsSelected)
            return (
              <>
                <li>{getMessageForPeoples(selectedGroupIds, "group")}</li>
                {getSectionForUser()}
              </>
            );
          if (groupsSelected && templateGroupsSelected)
            return (
              <>
                <li>
                  {getMessageForPeoples(selectedTemplateGroupIds, "template")} and <b>{selectedGroupIds.length}</b>{" "}
                  {pluralize("group", selectedGroupIds.length)}
                </li>
              </>
            );
          if (usersSelected && templateGroupsSelected)
            return (
              <>
                <li>{getMessageForPeoples(selectedTemplateGroupIds, "template")}</li>
                {getSectionForUser()}
              </>
            );
          if (usersSelected) return <>{getSectionForUser()}</>;
          if (groupsSelected) return <li>{getMessageForPeoples(selectedGroupIds, "group")}</li>;
          if (templateGroupsSelected) return <li>{getMessageForPeoples(selectedTemplateGroupIds, "template")}</li>;
          return <></>;
        })()}
      </>
    );
  };

  return (
    <>
      <p className="email-send-session-confirmation-title">
        <b>Almost done!</b>
      </p>
      <p className="email-send-session-confirmation-text" data-testid="email-send-session-confirmation-text">
        <b>{communicationTitle}</b> will be sent to:
        <br />
        <br />
        <ul>
          {getPeopleSection()}
          {getDateTimeSection()}
        </ul>
        <br />
        {promptText}
      </p>
      <ContentAssignmentModalTwoSteps
        showModal={modalVisible}
        flowHasOrigin={false}
        onCancel={cancelLicenseModal}
        onConfirm={confirmLicense}
        packContextItems={[getEmailPackContextItem()]}
        selectedUserIds={selectedUserIds}
        selectedGroupIds={selectedGroupIds}
        skipLicensing={false}
        skipNotifyStep
        notifyStepDisabled
      />
    </>
  );
};
