import React, { Component } from "react";
import PropTypes from "prop-types";
import MappingRow from "./mappingRow/MappingRow";
import { mapToDirectionName } from "./mappingRow/constants";

import "./fieldsMapper.scss";

class FieldsMapper extends Component {
  constructor(props) {
    super(props);

    const { mappedFields, customFields } = props;
    const rowsCount = this.getRowsCount();
    this.state = {
      rowsCount: rowsCount,
      customFields: customFields && customFields.length > 0 ? customFields : Array(rowsCount).fill(null),
      mappedFields: mappedFields && mappedFields.length > 0 ? mappedFields : this.getInitialMappedFields(rowsCount),
    };
  }

  getInitialMappedFields = (rowsCount) => {
    const { mapToFields } = this.props;
    const defaultMappedFieldData = {
      mapFrom: null,
      mapTo: null,
      isSkipped: false,
      isCustomField: false,
    };

    let initialMappedFields = Array(rowsCount)
      .fill(null)
      .map(() => ({ ...defaultMappedFieldData }));

    for (let i = 0; i < rowsCount; i++) {
      const mapToField = mapToFields[i];

      if (mapToField && mapToField.isRequired) {
        initialMappedFields[i].mapTo = mapToField.name;
      }
    }

    return initialMappedFields;
  };

  // designed that required fields are first in the list "mapToFields"
  getRowsCount = () => {
    const { mapFromFields, mapToFields } = this.props;
    const minRowsCount = mapToFields.filter((field) => field.isRequired).length;
    const maxRowsCount = mapToFields.length;

    if (mapFromFields.length > maxRowsCount) {
      return maxRowsCount;
    }

    if (mapFromFields.length < minRowsCount) {
      return minRowsCount;
    }

    return mapFromFields.length;
  };

  // not selected in another dropdowns
  getOptionsForDropdownMapFrom = (currentFieldIndex) => {
    const { mappedFields } = this.state;
    const { mapFromFields } = this.props;

    return mapFromFields
      .filter(
        (name) =>
          !mappedFields.some((mappedField) => mappedField.mapFrom === name) ||
          mappedFields[currentFieldIndex].mapFrom === name,
      )
      .map((name) => {
        return { text: name, value: name };
      });
  };

  // not required and not selected in another dropdowns
  getOptionsForDropdownMapTo = (currentFieldIndex) => {
    const { mappedFields, customFields } = this.state;
    const { mapToFields } = this.props;

    let filteredMapToFields = mapToFields
      .filter(
        (field) =>
          !field.isRequired &&
          (!mappedFields.some((mappedField) => mappedField.mapTo === field.name) ||
            mappedFields[currentFieldIndex].mapTo === field.name),
      )
      .map((field) => {
        return { text: field.name, value: field.name };
      });

    const currentCustomField = customFields[currentFieldIndex];

    if (currentCustomField) {
      filteredMapToFields.push({
        text: currentCustomField,
        value: currentCustomField,
      });
    }

    return filteredMapToFields;
  };

  isCustomFields = (field) => {
    const { mapToFields } = this.props;

    return !!field && mapToFields.filter((f) => f.name.toUpperCase() === field.toUpperCase()).length === 0;
  };

  onMappingChange = (index, mappingDirection, value) => {
    const { onMappingChange, onCustomFieldChange } = this.props;
    const { mappedFields, customFields } = this.state;

    let newCustomFields = [...customFields];
    let newMappedFields = [...mappedFields];
    let newCurrentMappedFields = newMappedFields[index];

    newCurrentMappedFields[mappingDirection] = value;

    if (mappingDirection === mapToDirectionName) {
      if (this.isCustomFields(value)) {
        newCurrentMappedFields.isCustomField = true;
      } else if (newCurrentMappedFields.isCustomField) {
        newCustomFields[index] = null;
        newCurrentMappedFields.isCustomField = false;
        onCustomFieldChange && onCustomFieldChange(newCustomFields);
      }
    }

    this.setState({
      mappedFields: newMappedFields,
      customFields: newCustomFields,
    });
    onMappingChange(newMappedFields);
  };

  onAddCustomField = (index, value) => {
    const { customFields } = this.state;
    const { onCustomFieldChange } = this.props;

    let newCustomFields = [...customFields];
    newCustomFields[index] = value;

    this.setState({ customFields: newCustomFields });
    onCustomFieldChange && onCustomFieldChange(newCustomFields);
  };

  onSkipChange = (index, isSkipped) => {
    const { onMappingChange } = this.props;
    const { mappedFields } = this.state;

    let newMappedFields = [...mappedFields];
    newMappedFields[index].isSkipped = isSkipped;
    this.setState({ mappedFields: newMappedFields });

    onMappingChange(newMappedFields);
  };

  buildMappingRow = (index) => {
    const { mapToFields, allowCustomFields } = this.props;
    const { mappedFields } = this.state;

    const currentMapToField = mapToFields[index];
    const currentMappedFields = mappedFields[index];

    return (
      <MappingRow
        key={index}
        index={index}
        mappedFields={currentMappedFields}
        mapToField={currentMapToField}
        onMappingChange={this.onMappingChange}
        onSkipChange={this.onSkipChange}
        mapFromDropdownOptions={this.getOptionsForDropdownMapFrom(index)}
        mapToDropdownOptions={this.getOptionsForDropdownMapTo(index)}
        allowCustomFields={allowCustomFields}
        onAddItem={this.onAddCustomField}
      />
    );
  };

  render() {
    const { mapFromColumnTitle, mapToColumnTitle } = this.props;
    const { rowsCount } = this.state;

    return (
      <div className="fields-mapper">
        <div className="titles">
          <div className="map-from-title">{mapFromColumnTitle}</div>
          <div className="map-to-title">{mapToColumnTitle}</div>
          <div>Skip Column</div>
        </div>

        {Array(rowsCount)
          .fill(null)
          .map((_, i) => this.buildMappingRow(i))}
      </div>
    );
  }
}

FieldsMapper.propTypes = {
  mapFromFields: PropTypes.array.isRequired,
  mapToFields: PropTypes.array.isRequired,
  onMappingChange: PropTypes.func.isRequired,
  mapFromColumnTitle: PropTypes.string,
  mapToColumnTitle: PropTypes.string,
  mappedFields: PropTypes.array,
  customFields: PropTypes.array,
  allowCustomFields: PropTypes.bool,
};

export default FieldsMapper;
