import React, { useMemo, useState } from "react";
import { type FlowValidationError } from "../../validator/flowValidatorReducer";
import { ErrorNavigationContext } from "./ErrorNavigationContext";

interface ErrorNavigationProviderProps {
  children: React.ReactNode;
}

export const ErrorNavigationProvider: React.FC<ErrorNavigationProviderProps> = ({ children }) => {
  const [currentError, setCurrentError] = useState<FlowValidationError | null>(null);

  const navigationContextValue = useMemo(
    () => ({
      state: { currentError },
      actions: {
        setCurrentError,
      },
    }),
    [currentError],
  );
  return <ErrorNavigationContext.Provider value={navigationContextValue}>{children}</ErrorNavigationContext.Provider>;
};
