import { Table } from "semantic-ui-react";
import cn from "classnames";
import { Tooltip } from "../common/tooltip";
import { type ModalWithStepsStep } from "../modal/ModalWithSteps";
import { TextTruncate } from "../textTruncators/TextTruncators";

import "./previewTable.scss";

interface RowData {
  name: string;
  clamp: number;
}

interface PreviewTableProps extends ModalWithStepsStep {
  titles: string[];
  items: any[];
  rowDataSequenceAndOptions: RowData[];
  itemsAmount: number;
  withGradient: boolean;
  className?: string;
}

export function PreviewTable(props: PreviewTableProps) {
  const { className, titles, items, itemsAmount, withGradient, rowDataSequenceAndOptions } = props;

  const buildTableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          {titles.map((title, index) => (
            <Table.HeaderCell key={index}> {title} </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    );
  };

  const buildCell = (content: string, lines: number, key: string | number) => {
    return (
      <Table.Cell key={key}>
        <Tooltip target={<TextTruncate lines={lines}>{content}</TextTruncate>} content={content} />
      </Table.Cell>
    );
  };

  const buildTableBody = () => {
    return items.map((rowData, rowIndex) => {
      let builtCellsInRowCount = 0;

      let cells = rowDataSequenceAndOptions.map((item) => {
        if (typeof rowData[item.name] === "string") {
          builtCellsInRowCount++;
          return buildCell(rowData[item.name], item.clamp, builtCellsInRowCount);
        }
        return null;
      });

      // if there are more titles then data - add an empty cell to last columns
      while (builtCellsInRowCount < titles.length) {
        builtCellsInRowCount++;
        cells.push(<Table.Cell key={builtCellsInRowCount} />);
      }

      return <Table.Row key={rowIndex}>{cells}</Table.Row>;
    });
  };

  return (
    <div className={cn("preview-table-component", className)}>
      <div className="label">
        Previewing{" "}
        <b>
          {items.length === 0 ? 0 : 1} - {items.length}
        </b>{" "}
        of <b>{itemsAmount}</b> entries
      </div>
      <div className="preview-table">
        <Table>
          {buildTableHeader()}
          <Table.Body className={cn({ "with-gradient": withGradient })}>{buildTableBody()}</Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default PreviewTable;
