import * as fetchingItemsActionTypes from "./fetchingItems/fetchingItemsActionTypes";
import * as filterOptionsActionTypes from "./filterOptions/filterOptionsActionTypes";
import { type HttpResponse, type PageOfItems } from "../../../interfaces";
import { type PayloadActionCreator, type PrepareAction, createAction } from "@reduxjs/toolkit";

type ApiCall = (...args: any[]) => Promise<HttpResponse>;
type ApiCallForItems = (...args: any[]) => Promise<PageOfItems<any>>;

interface GridFetchActions {
  FETCH_GRID_ITEMS_BEGIN: string;
  FETCH_GRID_ITEMS_SUCCESS: string;
  FETCH_GRID_ITEMS_FAILURE: string;
}

interface FetchActions {
  FETCH_ITEMS_BEGIN: string;
  FETCH_ITEMS_SUCCESS: string;
  FETCH_ITEMS_FAILURE: string;
  RESET_ITEMS: string;
  UPDATE_ITEMS?: string;
}

const delimiter = "__";

function throwIfNotValidActionParams(...params: any[]) {
  params.forEach((param) => {
    if (typeof param !== "string") {
      throw new Error(`Incorrect Action Creator ${Object.keys({ param })[0]}`);
    }
  });
}
/**
 * @deprecated
 */
export function buildRootEntityActionType(namespace: string, entityPrefix: string, type: string) {
  throwIfNotValidActionParams(namespace, entityPrefix, type);
  return [namespace, entityPrefix, type].join(delimiter);
}
/**
 * @deprecated
 */
export const getRootEntityActionTypeBuilder = (namespace: string, entityPrefix: string) => (type: string) =>
  buildRootEntityActionType(namespace, entityPrefix, type);
/**
 * @deprecated
 */
export function buildChildEntityActionType(
  namespace: string,
  mainEntityPrefix: string,
  childEntityPrefix: string,
  type: string,
) {
  throwIfNotValidActionParams(namespace, mainEntityPrefix, childEntityPrefix, type);
  return [namespace, mainEntityPrefix, childEntityPrefix, type].join(delimiter);
}

/**
 * @deprecated use toolkit creator
 */
export function makeActionCreator(type: string, ...payloadArgNames: any[]) {
  return function (...args: any[]) {
    const action: any = { type };
    if (payloadArgNames.length > 0) {
      const payload: any = {};
      payloadArgNames.forEach((_, index) => {
        payload[payloadArgNames[index]] = args[index];
      });
      action.payload = payload;
    }
    return action;
  };
}

// For backward compatibility
interface ActionProvider {
  <P = any, T extends string = string>(type: T): PayloadActionCreator<P, T>;
  <PA extends PrepareAction<any>, T extends string = string>(type: T, prepareAction: PA): PayloadActionCreator<
    ReturnType<PA>["payload"],
    T,
    PA
  >;
}

/**
 * @deprecated use create action directly
 */
export const getActionBaseProvider = createAction;
/**
 * @deprecated use create action directly
 */
export const getActionProvider: ActionProvider = createAction;

export interface FetchItems {
  items: any[];
  itemsCount: number;
  isLoading: boolean;
  error: string;
}

/**
 * @deprecated
 */
export function makeFetchItemsActionCreator(apiCall: ApiCall, typeToFetch: FetchActions) {
  const { FETCH_ITEMS_BEGIN, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } = typeToFetch;

  const fetchEntitiesBegin = makeActionCreator(FETCH_ITEMS_BEGIN);

  const fetchEntitiesSuccess = makeActionCreator(FETCH_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchEntitiesFailure = makeActionCreator(FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchEntitiesBegin());
    try {
      const response = await apiCall();

      dispatch(fetchEntitiesSuccess(response.data.items, response.data.count));
    } catch (error) {
      dispatch(fetchEntitiesFailure(error));
    }
  };
}
/**
 * @deprecated
 */
export function makeFetchActionCreator(apiCall: ApiCall, typeToFetch: FetchActions) {
  const { FETCH_ITEMS_BEGIN, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } = typeToFetch;

  const fetchEntitiesBegin = makeActionCreator(FETCH_ITEMS_BEGIN);

  const fetchEntitiesSuccess = makeActionCreator(FETCH_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchEntitiesFailure = makeActionCreator(FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchEntitiesBegin());
    try {
      const response = await apiCall();

      const recordsCount = parseInt(response.headers["x-bsi-recordscount"]);
      dispatch(fetchEntitiesSuccess(response.data, recordsCount));
    } catch (error) {
      dispatch(fetchEntitiesFailure(error));
    }
  };
}
/**
 * @deprecated
 */
export function makeFetchActionCreatorForItems(apiCall: ApiCallForItems, typeToFetch: FetchActions) {
  const { FETCH_ITEMS_BEGIN, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } = typeToFetch;

  const fetchEntitiesBegin = makeActionCreator(FETCH_ITEMS_BEGIN);

  const fetchEntitiesSuccess = makeActionCreator(FETCH_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchEntitiesFailure = makeActionCreator(FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchEntitiesBegin());
    try {
      const data = await apiCall();

      dispatch(fetchEntitiesSuccess(data.items, data.count));
    } catch (error) {
      dispatch(fetchEntitiesFailure(error));
    }
  };
}
/**
 * @deprecated
 */
export function makeFetchActionCreatorForArray<TResponse>(
  apiCall: (...args: any[]) => Promise<TResponse[]>,
  typeToFetch: FetchActions,
) {
  const { FETCH_ITEMS_BEGIN, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } = typeToFetch;

  const fetchEntitiesBegin = makeActionCreator(FETCH_ITEMS_BEGIN);
  const fetchEntitiesSuccess = makeActionCreator(FETCH_ITEMS_SUCCESS, "items", "itemsCount");
  const fetchEntitiesFailure = makeActionCreator(FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchEntitiesBegin());
    try {
      const data = await apiCall();
      dispatch(fetchEntitiesSuccess(data, data.length));
    } catch (error) {
      dispatch(fetchEntitiesFailure(error));
    }
  };
}
/**
 * @deprecated
 */
export function makeFetchGridActionCreator(apiCall: ApiCall, typeToFetch: GridFetchActions) {
  const { FETCH_GRID_ITEMS_BEGIN, FETCH_GRID_ITEMS_SUCCESS, FETCH_GRID_ITEMS_FAILURE } = typeToFetch;

  const fetchGridEntitiesBegin = makeActionCreator(FETCH_GRID_ITEMS_BEGIN);

  const fetchGridEntitiesSuccess = makeActionCreator(FETCH_GRID_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchGridEntitiesFailure = makeActionCreator(FETCH_GRID_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchGridEntitiesBegin());
    try {
      const response = await apiCall();

      const recordsCount = parseInt(response.headers["x-bsi-recordscount"]);
      dispatch(fetchGridEntitiesSuccess(response.data, recordsCount));
    } catch (error) {
      dispatch(fetchGridEntitiesFailure(error));
    }
  };
}
/**
 * @deprecated
 */
export function makeFetchGridActionCreatorForItems(apiCall: ApiCallForItems, typeToFetch: GridFetchActions) {
  const { FETCH_GRID_ITEMS_BEGIN, FETCH_GRID_ITEMS_SUCCESS, FETCH_GRID_ITEMS_FAILURE } = typeToFetch;

  const fetchGridEntitiesBegin = makeActionCreator(FETCH_GRID_ITEMS_BEGIN);

  const fetchGridEntitiesSuccess = makeActionCreator(FETCH_GRID_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchGridEntitiesFailure = makeActionCreator(FETCH_GRID_ITEMS_FAILURE, "error");

  return async (dispatch: Function) => {
    dispatch(fetchGridEntitiesBegin());
    try {
      const data = await apiCall();

      dispatch(fetchGridEntitiesSuccess(data.items, data.count));
    } catch (error) {
      dispatch(fetchGridEntitiesFailure(error));
    }
  };
}

function createActionFactory(namespace: string, page: string, fetchingItemName?: string) {
  return (actionName: string) => [namespace, page, fetchingItemName, actionName].filter(Boolean).join(delimiter);
}
/**
 * @deprecated
 */
export function buildFetchAction(namespace: string, page: string, fetchingItemName?: string): FetchActions {
  const createAction = createActionFactory(namespace, page, fetchingItemName);
  return {
    FETCH_ITEMS_BEGIN: createAction(fetchingItemsActionTypes.FETCH_ITEMS_BEGIN),
    FETCH_ITEMS_FAILURE: createAction(fetchingItemsActionTypes.FETCH_ITEMS_FAILURE),
    FETCH_ITEMS_SUCCESS: createAction(fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS),
    RESET_ITEMS: createAction(fetchingItemsActionTypes.RESET_ITEMS),
    UPDATE_ITEMS: createAction(fetchingItemsActionTypes.UPDATE_ITEMS),
  };
}
/**
 * @deprecated
 */
export function buildFetchGridAction(namespace: string, page: string, fetchingItemName: string) {
  const createAction = createActionFactory(namespace, page, fetchingItemName);

  return {
    FETCH_GRID_ITEMS_BEGIN: createAction(fetchingItemsActionTypes.FETCH_GRID_ITEMS_BEGIN),
    FETCH_GRID_ITEMS_FAILURE: createAction(fetchingItemsActionTypes.FETCH_GRID_ITEMS_FAILURE),
    FETCH_GRID_ITEMS_SUCCESS: createAction(fetchingItemsActionTypes.FETCH_GRID_ITEMS_SUCCESS),
    RESET_GRID_ITEMS: createAction(fetchingItemsActionTypes.RESET_GRID_ITEMS),
  };
}
/**
 * @deprecated
 */
export function buildFilterOptionsAction(namespace: string, page: string, fetchingItemName?: string) {
  const createAction = createActionFactory(namespace, page, fetchingItemName);

  return {
    FETCH_OPTIONS_BEGIN: createAction(filterOptionsActionTypes.FETCH_OPTIONS_BEGIN),
    FETCH_OPTIONS_FAILURE: createAction(filterOptionsActionTypes.FETCH_OPTIONS_FAILURE),
    FETCH_OPTIONS_SUCCESS: createAction(filterOptionsActionTypes.FETCH_OPTIONS_SUCCESS),
  };
}
