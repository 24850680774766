import React, { type FC } from "react";
import { Icon, Image } from "semantic-ui-react";
import { connect, type ConnectedProps } from "react-redux";

import { Tooltip } from "../../../../../../../../../components/common/tooltip";
import { type RootState } from "../../../../../../../../Application/globaltypes/redux";
import { flowDesignerSelector } from "../../../../../../state/selectors";
import { PublishedStatus } from "../../../../../../../../../components/common/publishedStatus";
import { TextTruncate } from "../../../../../../../../../components";
import { type ConnectedFlow } from "../../../../../types";
import { PublishedStatusTypes } from "../../../../../../../../../enums";

import styles from "./connectedFlows.module.scss";
import { isEmpty } from "lodash";

export type ConnectedFlowsProps = PropsFromRedux;

const getItem = (flow: ConnectedFlow) => {
  return (
    <div className={styles.item} key={flow.id}>
      <div className={styles["top-row"]}>
        <Image className={styles.thumbnail} src={flow.thumbnailUrl} />
        <div className={styles.description}>
          <div className={styles["description-header"]}>
            <span className={styles.label}>{flow.publisher}</span>
            <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!flow.isDraft)} />
          </div>
          <Tooltip
            className={styles.title}
            target={<TextTruncate lines={2}>{flow.title}</TextTruncate>}
            content={flow.title}
          />
        </div>
      </div>
    </div>
  );
};
export const ConnectedFlows: FC<ConnectedFlowsProps> = (props) => {
  if (isEmpty(props.connectedFlows.items)) {
    return null;
  }

  return (
    <Tooltip
      hoverable
      showAlways
      hideOnScroll
      inverted={false}
      position="top left"
      className={styles.root}
      styles={{ padding: "0" }}
      target={<Icon name="linkify" className={styles.icon} />}
      content={<div className={styles["connected-flows"]}>{props.connectedFlows.items.map(getItem)}</div>}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  connectedFlows: flowDesignerSelector(state).connectedFlows,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ConnectedFlows);
export default ConnectedComponent;
