import { type IQuestion, type IQuestionBase } from "../../../../Common/models";
import { asQuestionsMap } from "../../../../Common/Question/QuestionsBuilders/QuestionProvider";
import { type Picture, PictureQuestion } from "../../../../Common/Question/Questions/PictureQuestion";
import { TextQuestion } from "../Questions/TextQuestion";
import { AssessmentQuestionType, type MultipleChoiceQuestion, type SingleChoiceQuestion } from "../../../types/state";

/* istanbul ignore next */
export const QuestionsMap = asQuestionsMap({
  [AssessmentQuestionType.MultipleChoiceTextQuestion]: {
    Component: TextQuestion,
    castQuestion: (Question: IQuestionBase) => Question as IQuestion<SingleChoiceQuestion>,
  },
  [AssessmentQuestionType.SingleChoiceTextQuestion]: {
    Component: TextQuestion,
    castQuestion: (Question: IQuestionBase) => Question as IQuestion<MultipleChoiceQuestion>,
  },
  [AssessmentQuestionType.PictureQuestion]: {
    Component: PictureQuestion,
    castQuestion: (Question: IQuestionBase) => Question as IQuestion<Picture>,
  },
});
