import { useState } from "react";
import { partial } from "lodash";

import { OverviewHeader } from "../../../../../components/sectionHeader";
import { DuplicateLinkButton } from "../../../../../components/buttons/linkButtons";
import { DeleteFlowButton } from "./DeleteFlowButton/DeleteFlowButton";
import { CreateFlowButton } from "./CreateFlowButton/CreateFlowButton";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import { type FlowMap, type SelectionContext } from "../types";

import Restricted from "../../../../../features/Application/Restricted";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import FlowPeopleAssignment from "./FlowPeopleAssignment/FlowPeopleAssignment";
import TagsManageContainer from "../containers/TagsManageContainer";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ToggleFlowsVisibilityButton } from "./ToggleVisibilityButton/ToggleFlowsVisibilityButton";

export interface FlowOverviewHeaderProps {
  selectedIds: Array<number>;
  selectionCtx: SelectionContext;
  onDuplicateFlows: (selectedIds: number[]) => () => void;
  onToggleVisibility: (selectedIds: number[], hidden: boolean) => () => void;
  flowDeletionHandler: (selectedIds: number[]) => () => void;
  setSelectedIds: (ids: number[]) => void;
  clearSelection: () => void;
  flowMap: FlowMap;
}

export function FlowsOverviewHeader(props: FlowOverviewHeaderProps) {
  const { selectedIds, selectionCtx, flowMap } = props;
  const [packsModalShown, setPacksModalShown] = useState<boolean>(false);
  const purchasedSelected = selectionCtx.purchased > 0;
  const draftSelected = selectionCtx.drafts > 0;
  const undeletableSelected = selectionCtx.undeletable > 0;
  const flowMigrationEnabled = useFeatureFlag(FeatureFlags.FlowVersioningMigration);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);

  const renderBatchButtons = () => {
    return (
      <>
        {visibility && (
          <ToggleFlowsVisibilityButton
            hiddenSelected={selectionCtx.hidden}
            totalSeleted={selectedIds.length}
            onClick={props.onToggleVisibility(selectedIds, selectionCtx.hidden > 0)}
          />
        )}
        <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
          <AddToPacksButton
            onClick={partial(setPacksModalShown, true)}
            isDisabled={purchasedSelected || draftSelected}
          />
        </Restricted>
        <DuplicateLinkButton onClick={props.onDuplicateFlows(selectedIds)} isDisabled={flowMigrationEnabled} />
        <DeleteFlowButton
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSelected}
          onClick={props.flowDeletionHandler(selectedIds)}
        />
        <FlowPeopleAssignment
          flowMap={flowMap}
          setSelectedIds={props.setSelectedIds}
          selectedIds={selectedIds}
          selectionCtx={selectionCtx}
        />
        <TagsManageContainer
          entity="Flows"
          isDisabled={false}
          selectedIds={selectedIds}
          onComplete={props.clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={partial(setPacksModalShown, false)}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Flow"
        />
      </>
    );
  };

  return (
    <OverviewHeader selectedItemsAmount={selectedIds.length} itemName="Flow" title="Flows">
      {selectedIds.some(Boolean) ? renderBatchButtons() : <CreateFlowButton />}
    </OverviewHeader>
  );
}

export default FlowsOverviewHeader;
