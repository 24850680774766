import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { set, get } from "lodash";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { type TagsEnum, TagsEnumCamelPlural } from "../../../../../interfaces/TagsEnum";
import { type FlowBaseEntity, type FlowBasePayload, type FlowBaseEntities, TagTypes, type Tags, type Integration } from "../../types/flowBase";

export interface FlowBaseState {
  tags: Tags;
  integrations: Integration[];
  loading: FlowBaseEntity<boolean>;
  errors: FlowBaseEntity<Error | null>;
}

const initialState: FlowBaseState = {
  tags: {},
  integrations: [],
  loading: {},
  errors: {},
};

const flowBaseSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Flows}/base`,
  initialState,
  reducers: {
    startFetchFlowBaseEntity(state: FlowBaseState, action: PayloadAction<{ entity: FlowBaseEntities }>) {
      const { entity } = action.payload;
      set(state.errors, entity, null);
      set(state.loading, entity, true);
    },
    fetchFlowBaseEntitySuccess(
      state: FlowBaseState,
      action: PayloadAction<{ entity: FlowBaseEntities; data: FlowBasePayload }>,
    ) {
      const { entity, data } = action.payload;
      set(state, entity, data);
      set(state.loading, entity, false);
    },
    fetchFlowBaseEntityFailure(
      state: FlowBaseState,
      action: PayloadAction<{ entity: FlowBaseEntities; error: Error }>,
    ) {
      const { entity, error } = action.payload;
      set(state.errors, entity, error);
      set(state.loading, entity, false);
    },
    resetFlowBaseEntity: (state: FlowBaseState, action: PayloadAction<{ entity: FlowBaseEntities }>) => {
      const { entity } = action.payload;
      set(state, entity, get(initialState, entity));
    },
    resetFlowBase: () => {
      return initialState;
    },

    setNewTag: (state: FlowBaseState, action: PayloadAction<{ type: TagsEnum; value: string }>) => {
      const { type, value } = action.payload;
      const tagsGroup = TagsEnumCamelPlural[type];

      state.tags[tagsGroup] = state.tags[tagsGroup] ?? [];
      state.tags[tagsGroup].push({
        type: TagTypes[type],
        title: value,
        isPurchased: false,
      });
    },
  },
});

export const {
  startFetchFlowBaseEntity,
  fetchFlowBaseEntitySuccess,
  fetchFlowBaseEntityFailure,
  resetFlowBaseEntity,
  resetFlowBase,
  setNewTag,
} = flowBaseSlice.actions;

export default flowBaseSlice.reducer;
export type flowBaseSliceStateType = ReturnType<typeof flowBaseSlice.reducer>;
