import dataService from "../../../../../Application/services/dataServices/typedDataService";

import { type AppDispatch } from "../../../../../Application/globaltypes/redux";

import {
  getCampaignsForEmailTempaltesBegin,
  getCampaignsForEmailTempaltesSuccess,
  setEmailTemplateDeleteModalType,
  setError,
} from "../slices/emailTemplateDeleteModalSlice";
import { EmailTemplateDeleteModalType } from "../../types/state";

export const openDeleteEmailTemplateModal = (emailTemplateIds: number[]) => async (dispatch: AppDispatch) => {
  dispatch(getCampaignsForEmailTempaltesBegin());
  try {
    const result = await dataService.threatDefence.getCampaignsForEmailTemplates(emailTemplateIds);
    const modalType =
      result.data.length > 0 ? EmailTemplateDeleteModalType.Information : EmailTemplateDeleteModalType.Confirmation;

    dispatch(getCampaignsForEmailTempaltesSuccess(result.data));
    dispatch(setEmailTemplateDeleteModalType(modalType));
  } catch (e) {
    dispatch(setError(e as Error));
  }
};
