import { AbortSignal } from "@azure/abort-controller";
import { BlockBlobClient } from "@azure/storage-blob";

const downloadAsString = async (urlWithSas: string): Promise<string> => {
  const client = new BlockBlobClient(urlWithSas);

  const downloadBlockBlobResponse = await client.download(0, undefined, {
    abortSignal: AbortSignal.none,
  });
  const downloadedBlob = await downloadBlockBlobResponse.blobBody;
  const downloaded = await blobToString(downloadedBlob as Blob);

  // [Browsers only] A helper method used to convert a browser Blob into string.
  async function blobToString(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target?.result as string);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  return downloaded;
};

const uploadString = async (urlWithSas: string, content: string): Promise<void> => {
  const client = new BlockBlobClient(urlWithSas);
  await client.upload(content, content.length, { abortSignal: AbortSignal.none });
};

const utils = {
  downloadAsString,
  uploadString,
};

export default utils;
