import { createReducer } from "../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/FeedbackPageDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/FeedbackPageEntityStateActionTypes";
import { type FeedbackPageDetailsState, type FeedbackPageView, type FeedbackPage } from "../../types/state";
import { type PayloadAction } from "../../../../../../interfaces/redux";
import miscUtils from "../../../../../../utils/miscellaneousUtils";

const initialState: FeedbackPageDetailsState = {
  feedbackPage: {
    id: -1,
    isDraft: true,
    hasBeenPublished: false,
    name: "",
    dateModified: undefined,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
    uploadedVideos: [],
    videoUrl: "",
  },
  isLoading: false,
  isFeedbackPageLoaded: false,
};

const normalizeFeedbackPagePayload = (initial: FeedbackPageView, payload: FeedbackPage): FeedbackPageView => ({
  ...initial,
  ...payload,
  labels: miscUtils.normalizeDropdownItems(payload.labels),
  softwareApplications: miscUtils.normalizeDropdownItems(payload.softwareApplications),
});

const getHandlers = (failureHandler: Function) => {
  const { getFeedbackPageBegin, getFeedbackPageSuccess, getFeedbackPageFailure } = actionTypes;

  const getFeedbackPageBeginHandler = (state: FeedbackPageDetailsState): FeedbackPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getFeedbackPageSuccessHandler = (
    state: FeedbackPageDetailsState,
    action: PayloadAction<FeedbackPage>,
  ): FeedbackPageDetailsState => ({
    ...state,
    feedbackPage: {
      ...normalizeFeedbackPagePayload(state.feedbackPage, action.payload),
    },
    isFeedbackPageLoaded: true,
    isLoading: false,
  });

  return {
    [getFeedbackPageBegin]: getFeedbackPageBeginHandler,
    [getFeedbackPageSuccess]: getFeedbackPageSuccessHandler,
    [getFeedbackPageFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const { fetchLockedFeedbackPageBegin, fetchLockedFeedbackPageSuccess, fetchLockedFeedbackPageFailure } =
    entityStateActionTypes;

  const fetchLockedFeedbackPageBeginHandler = (state: FeedbackPageDetailsState): FeedbackPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedFeedbackPageSuccessHandler = (state: FeedbackPageDetailsState): FeedbackPageDetailsState => ({
    ...state,
    feedbackPage: {
      ...state.feedbackPage,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedFeedbackPageBegin]: fetchLockedFeedbackPageBeginHandler,
    [fetchLockedFeedbackPageSuccess]: fetchLockedFeedbackPageSuccessHandler,
    [fetchLockedFeedbackPageFailure]: failureHandler,
  };
};

const feedbackPageDetailsHandlers = () => {
  const { saveFeedbackPage, clearFeedbackPage, setFeedbackPage } = actionTypes;

  const failureHandler = (state: FeedbackPageDetailsState, action: PayloadAction<Error>): FeedbackPageDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveFeedbackPageHandler = (
    state: FeedbackPageDetailsState,
    action: PayloadAction<FeedbackPageView>,
  ): FeedbackPageDetailsState => {
    const video = action.payload.uploadedVideos[0];
    const uploadedVideos = video ? [video] : [];

    return {
      ...state,
      feedbackPage: {
        ...action.payload,
        uploadedVideos,
      },
    };
  };

  const clearFeedbackPageHandler = (): FeedbackPageDetailsState => ({
    ...initialState,
  });

  const setFeedbackPageHandler = (
    state: FeedbackPageDetailsState,
    action: PayloadAction<FeedbackPage>,
  ): FeedbackPageDetailsState => ({
    ...state,
    feedbackPage: {
      ...normalizeFeedbackPagePayload(state.feedbackPage, action.payload),
    },
  });

  return {
    [saveFeedbackPage]: saveFeedbackPageHandler,
    [clearFeedbackPage]: clearFeedbackPageHandler,
    [setFeedbackPage]: setFeedbackPageHandler,
    ...getHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
  };
};

export const feedbackPageDetails = createReducer(initialState, [feedbackPageDetailsHandlers]);
