import React, { Component } from "react";
import {
  UsersOverview,
  RolesOverview,
  AddUser,
  AddGroup,
  EditGroup,
  CreateRole,
  EditUser,
  EditRole,
} from "../../features/People";
import { NotFoundMessage } from "../../components";
import { RolePermissions } from "../../enums";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../../components/restrictedRoute/ProtectedRoute";
import CreateGroupTemplate from "features/People/GroupTemplate/Create/CreateGroupTemplate";
import EditTemplate from "../../features/People/GroupTemplate/Edit/EditTemplate";
import { GroupsSwitcher } from "features/People/GroupsSwitcher/GroupsSwitcher";
import ApproveTemplateGroup from "../../features/People/GroupTemplate/Overview/ApproveTemplateGroup";
import { GroupContentTypesEnum } from "features/People/GroupsSwitcher/constants";

class PeopleContent extends Component {
  render() {
    return (
      <div className="route-content-container">
        <Routes>
          <Route path="users">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.UsersView]}>
                  <UsersOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="add"
              element={
                <ProtectedRoute permissions={[RolePermissions.UsersCreate, RolePermissions.UsersManage]}>
                  <AddUser />
                </ProtectedRoute>
              }
            />
            <Route path=":id/*" element={<EditUser />} />
          </Route>
          <Route path="roles">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.RolesView]}>
                  <RolesOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.RolesCreate]}>
                  <CreateRole />
                </ProtectedRoute>
              }
            />
            <Route path=":id/*" element={<EditRole />} />
          </Route>

          <Route path="groups">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.GroupsView]}>
                  <GroupsSwitcher key={GroupContentTypesEnum.Groups} ctx={GroupContentTypesEnum.Groups} />
                </ProtectedRoute>
              }
            />
            <Route
              path="add"
              element={
                <ProtectedRoute permissions={[RolePermissions.GroupsCreate, RolePermissions.GroupsManage]}>
                  <AddGroup />
                </ProtectedRoute>
              }
            />
            <Route path=":id/*" element={<EditGroup />} />
            <Route path="inherited">
              <Route
                index
                element={
                  <ProtectedRoute permissions={[RolePermissions.GroupsView]}>
                    <GroupsSwitcher key={GroupContentTypesEnum.Templates} ctx={GroupContentTypesEnum.Templates} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="add-template"
                element={
                  <ProtectedRoute permissions={[RolePermissions.TemplatesManage]}>
                    <CreateGroupTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id/*"
                element={
                  <ProtectedRoute permissions={[RolePermissions.TemplatesView]}>
                    <EditTemplate />
                  </ProtectedRoute>
                }
              />
              <Route path="preview">
                <Route
                  path=":id/*"
                  element={
                    <ProtectedRoute permissions={[RolePermissions.GroupsView]}>
                      <EditTemplate preview />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path=":id/approve" element={<ApproveTemplateGroup />} />
            </Route>
            <Route path="*" element={<NotFoundMessage />} />
          </Route>
          <Route path="*" element={<NotFoundMessage />} />
        </Routes>
      </div>
    );
  }
}

export default PeopleContent;
