import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildFetchAction } from "../../../Application/actions/actionsBuilder";

export const Countries = "COUNTRIES";

export const FETCH_COUNTRIES = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.UserCommon,
  Countries,
);
