import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertTypes } from "enums";
import BaseAlert from "./BaseAlert";

import styles from "./alert.module.scss";

export type AlertProps = {
  id: number | string;
  type: AlertTypes;
  textOnly?: boolean;
  action?: () => void;
  actionLabel?: string;
  title?: string;
  message: string;
  dismissInSeconds?: number;
  visible?: boolean;
  onDismissCompleted?: (id: number | string) => void;
  animateOnMount?: boolean;
  animationDuration?: number;
  createTime?: number;
};

const Alert = (props: AlertProps) => {
  let type;

  switch (props.type) {
    case AlertTypes.success:
      type = {
        name: "success",
        icon: faCircleCheck,
        title: props.title ?? "Success!",
      };
      break;
    case AlertTypes.error:
      type = {
        name: "error",
        icon: faTriangleExclamation,
        title: "Error!",
      };
      break;
    case AlertTypes.warning:
      type = {
        name: "warning",
        icon: faCircleExclamation,
        title: props.title,
      };
      break;
    default:
      return;
  }

  return (
    <BaseAlert typeName={type.name} {...props}>
      <FontAwesomeIcon icon={type.icon} className={styles[type.name]} />
      <div className="message">
        <span className="title">{type.title}</span>
        {props.message}
      </div>
    </BaseAlert>
  );
};

export default Alert;
