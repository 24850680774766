import { isEmpty, pick } from "lodash";
import { Filter, operators } from "../../../utils/filterUtils";
import rolesDataService from "../services/rolesDataService";
import usersDataService from "../../Application/services/dataServices/usersDataService";
import permissionsHelper from "../CreateRoles/permissionsHelper";

interface PagingOptions {
  skip: number;
  top: number;
  orderProp: string;
  orderDirection: string;
  filter: { [key: string]: any };
  searchText?: string;
}

const buildRolesQueryParams = (pagingOptions: PagingOptions) => {
  const { skip, top, orderProp, orderDirection, filter, searchText } = pagingOptions;

  let params = {
    skip,
    top,
    orderBy: `${orderProp} ${orderDirection}`,
    searchTerm: !isEmpty(searchText) ? searchText : undefined,
  };

  if (!isEmpty(filter)) {
    const { isRemoved, newFilter } = tryRemoveAllAreaItemId(filter);

    const queryParams = pick(newFilter, "areaIds", "limitationIds") as any;

    const filterParameters = [
      {
        key: "added",
        operator: operators.range,
      },
      {
        key: "updated",
        operator: operators.range,
      },
    ];
    const filterObject = new Filter(filterParameters);
    const filterParam = filterObject.buildFilterQuery(newFilter);

    if (!isEmpty(filterParam)) {
      queryParams.filter = filterParam;
    }
    if (isRemoved) {
      queryParams.includeAllArea = true;
    }
    params = { ...params, ...queryParams };
  }
  return params;
};

const tryRemoveAllAreaItemId = (filter: any) => {
  const index = filter.areaIds?.findIndex((x: any) => permissionsHelper.isAllAreaOrAllFeature(x)) ?? -1;
  if (index !== -1) {
    const newFilter = { ...filter, areaIds: [...filter.areaIds] };
    newFilter.areaIds.splice(index, 1);
    return { isRemoved: true, newFilter };
  }
  return { isRemoved: false, newFilter: filter };
};

const fetchUserEditRoles = (userId: number, pagingOptions: PagingOptions, actions: any) => async (dispatch: any) =>
  fetchData(
    () => {
      const queryParams = buildRolesQueryParams(pagingOptions);
      return usersDataService.getUserRoles(userId, queryParams);
    },
    actions,
    dispatch,
  );

const fetchAccountRoles = (pagingOptions: PagingOptions, actions: any) => async (dispatch: any) => {
  const queryParams = buildRolesQueryParams(pagingOptions);
  await fetchData(() => rolesDataService.getRoles(queryParams), actions, dispatch);
};

const fetchData = async (getData: () => Promise<{ items: []; itemsCount: number }>, actions: any, dispatch: any) => {
  try {
    dispatch(actions.begin());
    const result = await getData();
    dispatch(actions.success(result.items, result.itemsCount));
  } catch (err) {
    dispatch(actions.failure(err));
  }
};

export { fetchAccountRoles, fetchUserEditRoles };
