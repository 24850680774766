import { Button } from "components/buttons/button/Button";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { Input, type InputOnChangeData } from "semantic-ui-react";
import { ClearInputButton } from "../buttons/clearInputButton/ClearInputButton";
import ValidatedField, { type ValidatedFieldProps } from "../forms/ValidatedField";
import { Progress } from "../progress";

import "./linkLoader.scss";

export enum LinkLoaderStatus {
  None,
  Loading,
  Success,
  Failure,
}

export interface LinkLoaderProps extends Omit<ValidatedFieldProps, "children"> {
  onChange?: (value: string) => void;
  onBlur: (propertyName: string) => void;
  disabled?: boolean;
  clearable?: boolean;
  onLinkParsed?: (data: ParsedLinkData) => void;
  onIsFormValidChange?: (isValid: boolean) => void;
  onProceedClick?: () => void;
  setFieldValue(field: string, value: string): void;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  maxLength?: number;
  buttonName?: string;
  indeterminate?: boolean;
  emulateParsing: boolean;
  loaderStatus: LinkLoaderStatus;
}

export interface LinkLoaderState {
  localLoaderStatus: LinkLoaderStatus;
  parsingProgress: number;
}

export interface ParsedLinkData {
  url: string;
  title?: string | null;
  description?: string | null;
}

export default class LinkLoader extends Component<LinkLoaderProps, LinkLoaderState> {
  public static defaultProps = {
    emulateParsing: true,
    loaderStatus: LinkLoaderStatus.None,
  };

  constructor(props: LinkLoaderProps) {
    super(props);
    this.state = {
      localLoaderStatus: this.props.loaderStatus,
      parsingProgress: 0,
    };
  }

  componentDidUpdate(prevProps: LinkLoaderProps) {
    if (!this.props.emulateParsing && prevProps.loaderStatus !== this.props.loaderStatus) {
      this.setState({
        localLoaderStatus: this.props.loaderStatus,
      });
    }
  }

  cancelParsing: (() => void) | null = null;

  isLoading = () => this.state.localLoaderStatus === LinkLoaderStatus.Loading;

  handleFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleBlur, onBlur, propertyName } = this.props;
    handleBlur && handleBlur(e);
    onBlur && onBlur(propertyName);
  };

  isInputDisabled = () => this.props.disabled || this.isLoading();

  clear = () => {
    const emptyValue = "";
    this.props.setFieldValue(this.props.propertyName, emptyValue);
    this.props.onChange?.(emptyValue);
  };

  renderClearButton = () => {
    const { value, clearable } = this.props;

    if (clearable && !isEmpty(value)) {
      return <ClearInputButton onClick={this.clear} disabled={this.isInputDisabled()} />;
    }
  };

  onLinkChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.props.handleChange?.(event);
    this.props.onChange?.(data.value);
  };

  renderProceedButton = () => {
    const { value, disabled, propertyName, errors, buttonName = "Proceed" } = this.props;
    const canProceed = !isEmpty(value) && !this.isLoading() && !disabled && !errors.hasOwnProperty(propertyName);

    return (
      <Button
        blur
        className="link-load-proceed"
        data-testid="link-load-btn"
        basic
        color="blue"
        attached="right"
        disabled={!canProceed}
        onClick={this.onProceed}
      >
        {buttonName}
      </Button>
    );
  };

  render() {
    const { value, propertyName, placeholder, autoFocus, maxLength } = this.props;

    return (
      <div className="link-load-field" data-testid="link-load">
        <ValidatedField {...this.props}>
          <div className="link-load-input-container">
            <Input
              className="link-load-input"
              data-testid="link-load-input"
              disabled={this.isInputDisabled()}
              value={value}
              onChange={this.onLinkChange}
              onBlur={this.handleFieldBlur}
              name={propertyName}
              placeholder={placeholder}
              autoFocus={autoFocus}
              maxLength={maxLength}
              icon={this.renderClearButton()}
            />
            {this.renderProceedButton()}
          </div>
        </ValidatedField>
        {this.renderProgress()}
      </div>
    );
  }

  onProceed = () => {
    const { onProceedClick, emulateParsing } = this.props;

    if (emulateParsing) {
      this.setState({
        localLoaderStatus: LinkLoaderStatus.Loading,
      });

      this.emulateParsing();
    } else {
      this.setState({
        parsingProgress: 100,
      });
      this.onParsedFinished();
    }

    if (onProceedClick) {
      onProceedClick();
    }
  };

  emulateParsing = () => {
    const randomBetween = (min: number, max: number) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    };
    const clear = () => {
      clearInterval(interval);
      clearTimeout(timeout);
      this.setState({ localLoaderStatus: LinkLoaderStatus.None, parsingProgress: 0 });
      this.cancelParsing = null;
    };

    const delay = randomBetween(2500, 4500);
    const start = Date.now();

    const interval = setInterval(() => {
      this.setState({
        parsingProgress: Math.round(((Date.now() - start) / delay) * 100),
      });
    }, 100);

    const timeout = setTimeout(() => {
      this.onParsedFinished();
      clear();
    }, delay);

    this.cancelParsing = clear;
  };

  onParsedFinished = () => {
    const { value } = this.props;
    const data = {
      url: value || "",
      title: "Some title given",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    };
    const { onLinkParsed } = this.props;
    if (!onLinkParsed) {
      return;
    }

    this.setState({
      localLoaderStatus: LinkLoaderStatus.Success,
    });

    onLinkParsed(data);
  };

  renderProgress() {
    const { parsingProgress, localLoaderStatus } = this.state;
    const { indeterminate } = this.props;
    if (localLoaderStatus !== LinkLoaderStatus.None) {
      return (
        <Progress
          label={this.resolveLabel(localLoaderStatus)}
          percent={parsingProgress}
          showPercents
          isUploading={this.isLoading()}
          onCancel={this.cancelParsing}
          indeterminate={indeterminate && this.isLoading()}
          error={localLoaderStatus === LinkLoaderStatus.Failure ? true : undefined}
        />
      );
    }
    return null;
  }

  resolveLabel(loaderStatus: LinkLoaderStatus) {
    switch (loaderStatus) {
      case LinkLoaderStatus.Loading:
        return "Uploading...";
      case LinkLoaderStatus.Failure:
        return "Failed!";
      default:
        return "Success!";
    }
  }
}
