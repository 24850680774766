import { type VideoUser } from "../../types";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, type SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface VideoUsersState extends SelectedItemsState<VideoUser> {}

const initialState: VideoUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const videoUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Videos, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  videoUsersSlice.actions;

export default videoUsersSlice.reducer;
export type videoUsersSliceStateType = ReturnType<typeof videoUsersSlice.reducer>;
