import packDataService from "../../services/packsDataService";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/associatedPacksSlice";

export const fetchAssociatedPacks = (contentType: string, contentId: number) => async (dispatch: AppDispatch) => {
  dispatch(fetchBegin());
  try {
    const packs = await packDataService.getAssociatedPacksAsync(contentType, contentId);
    dispatch(fetchSuccess({ items: packs.data, totalCount: packs.data.length }));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};
