import React from "react";
import boldIcon from "../../../images/text-editor/format_bold.svg";
import underlineIcon from "../../../images/text-editor/format_underlined.svg";
import italicIcon from "../../../images/text-editor/format_italic.svg";
import listBulletedIcon from "../../../images/text-editor/format_list_bulleted.svg";
import h1Icon from "../../../images/text-editor/looks_one.svg";
import h2Icon from "../../../images/text-editor/looks_two.svg";

export type iconType = "h1" | "h2" | "italic" | "bold" | "underline" | "quote" | "ul";

interface Props {
  className?: string;
  type: iconType;
}

export const Icon = ({ type }: Props) => {
  let url = "";

  switch (type) {
    case "h1":
      url = h1Icon;
      break;
    case "h2":
      url = h2Icon;
      break;
    case "bold":
      url = boldIcon;
      break;
    case "underline":
      url = underlineIcon;
      break;
    case "italic":
      url = italicIcon;
      break;
    case "ul":
      url = listBulletedIcon;
      break;
  }

  return <img src={url} alt="" />;
};
