import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import DeleteContentConfirmationModal, {
  type RemoveContentObserverInput,
} from "../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import { SurveyFilterForm } from "../../../../components/filterForms";
import SearchInput from "../../../../components/searchInput/SearchInput";
import { ContentTypesEnum, RolePermissions, RouteNames, SortingDirection } from "../../../../enums";
import { useReloadItemViewListItems } from "../../../../hooks/useReloadItemViewListItems";
import { useRtn } from "../../../../hooks/useRtn";
import { HandleOnSelectionChanged, type SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import { bindAction } from "../../../../interfaces";
import Observable from "../../../../utils/Observable";
import SurveysNoResults from "../../../../views/library/surveys/Overview/SurveysNoResults";
import { type AppDispatch, type RootState } from "../../../Application/globaltypes/redux";
import Restricted from "../../../Application/Restricted";
import { SurveyEditSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as entityStateActions from "../state/actions/surveyEntityStateActions";
import * as surveysOverviewActions from "../state/actions/surveyOverviewActions";
import {
  resetAppliedFilter,
  resetPagination,
  resetSearch,
  resetSortingColumnName,
  resetSortingDirection,
  setPagination,
  setSearch,
  setSortingColumnName,
  setSortingDirection,
} from "../state/slices/surveyFiltersSlice";
import {
  surveyPaginationSelector,
  surveySortingColumnNameSelector,
  surveySortingDirectionSelector,
  surveysStateSelector,
} from "../state/surveyReducer";
import { changeVisibility, deleteSurvey, duplicateSurvey, getSurveys } from "../state/thunks/surveysOverviewThunk";
import { type SurveyOverview } from "../types/models";
import CreateSurveyButton from "./containers/CreateSurveyButton";
import SurveysListContainer from "./containers/SurveysListContainer";
import { Columns, getColumnOptions } from "./getColumnOptions";
import SurveyOverviewHeader from "./SurveyOverviewHeader/SurveyOverviewHeader";
import { useNavigate } from "react-router-dom";
import { useSearchSorting } from "components/listView/hooks/useSearchSorting";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { AssetVisibilityConfirmationModal } from "components/assetVisibilityConfirmationModal/AssetVisibilityConfirmationModal";

export type SurveysOverviewProps = PropsFromRedux;

const DEFAULT_PARAMS = {
  column: "added",
};

export const SurveysOverview = (props: SurveysOverviewProps) => {
  const { resetSearchFilters } = props;
  const navigate = useNavigate();
  const goToSurveyEdit = (payload: { id: number }) => navigate(`${payload.id.toString()}/configuration`);

  const onTriggerSurveyRemovalObserver = useMemo(
    () => new Observable<(onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void>(),
    [],
  );

  const onTriggerChangeVisibilityObserver = useMemo(
    () => new Observable<(onConfirm: () => void, visible: boolean, ids: number[]) => void>(),
    [],
  );

  const [setReloadListItems, onSearchChanged] = useReloadItemViewListItems(props.setSearch);
  const [purchasedSelectedCount, setPurchasedSelectedCount] = useState(0);
  const [undeletableSelectedCount, setUndeletableSelectedCount] = useState(0);
  const [draftSelectedCount, setDraftSelectedCount] = useState(0);
  const [hiddenSelectedCount, setHiddenSelectedCount] = useState(0);
  const [visibleSelectedCount, setVisibleSelectedCount] = useState(0);

  // Used to handle resetting sort terms
  const { sortingColumn, onSearchChange: searchSortChange } = useSearchSorting({
    sortingColumnName: props.sortingColumnName,
    defaultSortingColumn: DEFAULT_PARAMS.column,
  });

  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const visibility = !!useFeatureFlag(FeatureFlags.ContentVisibility);

  useRtn([SurveyEditSuccess], goToSurveyEdit);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes(`/${RouteNames.contentSurveys}`)) resetSearchFilters();
    };
  }, [resetSearchFilters]);

  const onSearchHandler = useCallback(
    (searchTerm: string) => {
      searchSortChange(searchTerm);
      onSearchChanged(searchTerm);
    },
    [onSearchChanged, searchSortChange],
  );

  const getSearchBar = () => (
    <SearchInput placeholder="Search for Surveys..." onChange={onSearchHandler} defaultValue={props.search} />
  );

  const handleEditClick = (id: number, isDraft?: boolean) => () => {
    if (!isDraft) props.fetchDraftSurveyEntity(id);
    goToSurveyEdit({ id });
  };

  const clearSelection = () => {
    props.onSelectedItemsChanged([]);

    setPurchasedSelectedCount(0);
    setUndeletableSelectedCount(0);
    setDraftSelectedCount(0);
    setHiddenSelectedCount(0);
    setVisibleSelectedCount(0);
  };

  const loadSurveyList = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    props.getSurveys(skip, top, sortingColumnName, sortingDirection);
  };

  const handleToggleVisibility = (ids: number[], visible: boolean) => () => {
    onTriggerChangeVisibilityObserver.notify(
      () => {
        props.toggleVisibility(ids, visible);
        clearSelection();
        loadSurveyList();
      },
      visible,
      ids,
    );
  };

  const handleDuplicateClick = (ids: number[]) => async () => {
    props.duplicateSurveys(ids, loadSurveyList);
    clearSelection();
  };

  const handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => () => {
    onTriggerSurveyRemovalObserver.notify(
      () => {
        props.deleteSurveys(ids, loadSurveyList);
        clearSelection();
      },
      {
        selectedItemsCount: ids.length,
        flowsCount,
        packsCount,
      },
    );
  };

  const onSelectionChanged = (args: SelectionChangedArgs<SurveyOverview>) => {
    let purchasedCount = 0;
    let undeletableCount = 0;
    let draftCount = 0;
    let hiddenCount = 0;
    let visibleCount = 0;

    const onAdded = (survey: SurveyOverview) => {
      survey.isPurchased && ++purchasedCount;
      !survey.canBeDeleted && ++undeletableCount;
      survey.isDraft && ++draftCount;
      !survey.visibility && ++hiddenCount;
      survey.visibility && ++visibleCount;
    };
    const onRemoved = (survey: SurveyOverview) => {
      survey.isPurchased && --purchasedCount;
      !survey.canBeDeleted && --undeletableCount;
      survey.isDraft && --draftCount;
      !survey.visibility && --hiddenCount;
      survey.visibility && --visibleCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, clearSelection);

    setPurchasedSelectedCount(purchasedSelectedCount + purchasedCount);
    setUndeletableSelectedCount(undeletableSelectedCount + undeletableCount);
    setDraftSelectedCount(draftSelectedCount + draftCount);
    setHiddenSelectedCount(hiddenSelectedCount + hiddenCount);
    setVisibleSelectedCount(visibleSelectedCount + visibleCount);
  };

  const onPaginationChange = (page: number) => props.setPagination(page);
  const onSortingColumnNameChange = (name: string) => props.setSortingColumnName(name);
  const onSortingDirectionChange = (direction: SortingDirection) => props.setSortingDirection(direction);

  return (
    <section className="nested-content">
      <SurveyOverviewHeader
        selectedIds={props.selectedIds}
        draftSelected={draftSelectedCount > 0}
        hiddenSelected={hiddenSelectedCount > 0}
        visibleSelected={visibleSelectedCount > 0}
        undeletableSelected={undeletableSelectedCount > 0}
        purchasedSurveySelected={purchasedSelectedCount > 0}
        renderCreateButton={() => <CreateSurveyButton />}
        surveyDuplicationHandler={handleDuplicateClick}
        surveyDeletionHandler={handleDeleteClick}
        clearSelection={clearSelection}
        onToggleVisibility={handleToggleVisibility}
      />
      <Restricted
        permissions={[RolePermissions.AssetsCreate, RolePermissions.AssetsManage]}
        renderContent={(hasPermission) => (
          <SurveysListContainer
            renderSearch={getSearchBar}
            noResultsContent={
              <SurveysNoResults
                filtered={props.filtered || !isEmpty(props.search)}
                createSurveyButton={<CreateSurveyButton />}
              />
            }
            columnOptions={getColumnOptions({
              readonly: !hasPermission,
              visibility: visibility && !(visibleSelectedCount > 0 && hiddenSelectedCount > 0),
              deleteContentWithDependenciesFeatureEnabled: deleteContentEnabled,
              handleEditClick,
              handleDuplicateClick,
              handleDeleteClick,
              handleToggleVisibility,
            })}
            fetchData={loadSurveyList}
            defaultSortingColumnName={sortingColumn}
            onSelectedItemChanged={props.onSelectedItemsChanged}
            isSelectDisabled={() => !hasPermission}
            // @ts-ignore
            getFilterForm={() => <SurveyFilterForm />}
            setReloadListItems={setReloadListItems}
            onSelectionChanged={onSelectionChanged}
            permissions={[RolePermissions.AssetsManage]}
            setPagination={onPaginationChange}
            pagination={props.pagination}
            sortingDirection={props.sortingDirection}
            setSortingColumnName={onSortingColumnNameChange}
            setSortingDirection={onSortingDirectionChange}
          />
        )}
      />
      <AssetVisibilityConfirmationModal observer={onTriggerChangeVisibilityObserver} assetType="survey" />
      <DeleteContentConfirmationModal
        contentType={ContentTypesEnum.Surveys}
        onTriggerRemoveContentObserver={onTriggerSurveyRemovalObserver}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const surveysState = surveysStateSelector(state);
  const overview = surveysState.overview;
  const surveysOverview = overview.surveysOverview;
  const surveys = surveysOverview.surveys;
  return {
    surveys: surveys,
    selectedIds: surveysOverview.selectedItems,
    filtered: !isEmpty(overview.filters.appliedFilter),
    search: overview.filters.search,
    pagination: surveyPaginationSelector(state),
    sortingColumnName: surveySortingColumnNameSelector(state),
    sortingDirection: surveySortingDirectionSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSurveys: bindAction(getSurveys, dispatch),
  fetchDraftSurveyEntity: bindAction(entityStateActions.fetchDraftSurveyEntity, dispatch),
  onSelectedItemsChanged: bindAction(surveysOverviewActions.selectedItemsChangedAction, dispatch),
  setSearch: bindAction(setSearch, dispatch),
  setPagination: bindAction(setPagination, dispatch),
  setSortingColumnName: bindAction(setSortingColumnName, dispatch),
  setSortingDirection: bindAction(setSortingDirection, dispatch),
  resetSearch: bindAction(resetSearch, dispatch),
  resetSearchFilters: () => {
    dispatch(surveysOverviewActions.resetAction());
    dispatch(resetSearch());
    dispatch(resetAppliedFilter());
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
  },
  deleteSurveys: bindAction(deleteSurvey, dispatch),
  duplicateSurveys: bindAction(duplicateSurvey, dispatch),
  toggleVisibility: bindAction(changeVisibility, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(SurveysOverview);
export default ConnectedComponent;
