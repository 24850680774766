import React from "react";
import NoResults from "../noResults/NoResults";
import NoSearchResults from "../noSearchResults";

export interface NoResultsWithButtonProps {
  title: string;
  description: string;
  icon?: React.ReactElement;
  iconClassName?: string;
  actionButton?: React.ReactNode;
  actionText?: string;
  filtered?: boolean;
  fontAwesomeIcon?: string;
}

const NoResultsWithButton: React.FC<NoResultsWithButtonProps> = ({
  title,
  description,
  icon,
  iconClassName,
  actionButton,
  actionText = "Add some using the button below.",
  filtered,
  fontAwesomeIcon,
}) => {
  const descriptionMarkup = (
    <>
      <span>{description}</span>
      {!!actionButton && (
        <>
          <br />
          <span>{actionText}</span>
        </>
      )}
    </>
  );

  return filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults
      fontAwesomeIcon={fontAwesomeIcon}
      title={title}
      description={descriptionMarkup}
      icon={icon}
      iconClassName={iconClassName}
    >
      {actionButton}
    </NoResults>
  );
};

export default NoResultsWithButton;
