import * as actionTypes from "./sidebarActionTypes";

type SidebarState = {
  collapsed: boolean;
  isCollapsedByUser: boolean;
};

const initialState: SidebarState = {
  collapsed: true,
  isCollapsedByUser: true,
};

export default function sidebarReducer(
  currentState: SidebarState,
  action: actionTypes.CollapseSidebarByUser | actionTypes.AutoCollapseSidebar | actionTypes.ExpandSidebar,
): SidebarState {
  const state = currentState || initialState;

  switch (action.type) {
    case actionTypes.AUTO_COLLAPSE_SIDEBAR:
      return {...state,
        collapsed: true,};

    case actionTypes.COLLAPSE_SIDEBAR_BY_USER:
      return {...state,
        collapsed: true,
        isCollapsedByUser: true,};

    case actionTypes.EXPAND_SIDEBAR:
      return {...state,
        collapsed: false,
        isCollapsedByUser: false,};

    default:
      return state;
  }
}
