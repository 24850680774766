import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../Application/slices/models";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "../../../../../enums/reducer";
import accountsDataService from "../../../services/accountsDataService";

export const accountTypesPrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.AccountCreation,
  name: "accountTypes",
};

export const fetchAccountTypes = createAsyncThunk(getPrefix(accountTypesPrefixPayload), async () => {
  const items = await accountsDataService.getAccountTypes();
  return { items, totalCount: items.length };
});

export const globalRolesStatePrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.AccountCreation,
  name: "globalRoles",
};

export const childRolesStatePrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.AccountCreation,
  name: "childRoles",
};

export const fetchGlobalRoles = createAsyncThunk(
  getPrefix(globalRolesStatePrefixPayload),
  async (accountTypeId: number) => {
    const items = await accountsDataService.getAccountTypeRoles(accountTypeId);
    return { items, totalCount: items.length };
  },
);

export const fetchChildAccountRoles = createAsyncThunk(
  getPrefix(childRolesStatePrefixPayload),
  async (accountId: number) => {
    const items = await accountsDataService.getChildAccountRoles(accountId);
    return { items, totalCount: items.length };
  },
);
