import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

interface DeleteLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  after?: React.ReactNode;
  iconClassName?: string;
}

export function DeleteLinkButton(props: DeleteLinkButtonProps) {
  const { onClick, isDisabled, after, iconClassName = "fa-trash-alt" } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <BasicLinkButton text="Delete" iconClassName={iconClassName} onClick={onClick} isDisabled={isDisabled} />
      {after}
    </div>
  );
}

export default DeleteLinkButton;
