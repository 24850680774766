import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import { Dimmer } from "semantic-ui-react";
import { WizardWrapper as Wizard } from "../../WizardWrapper";
import * as createRoleActionsRedux from "./state/createRoleActions";
import * as notificationsActions from "../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import RoleConfiguration from "./RoleConfiguration/RoleConfiguration";
import AssignPermissions from "./AssignAccountPermissions/AssignPermissions";
import PreventTransitionPrompt from "../../../components/preventTransition/PreventTransitionPrompt";
import permissionsHelper from "./permissionsHelper";
import rolesDataService from "../services/rolesDataService";
import navigationUtils from "../../../utils/navigationUtils";
import { withRouter } from "../../../adapters/withRouter/withRouter";

export class CreateRole extends Component {
  state = {
    isRoleConfigValid: false,
    isRoleConfigDirty: false,
    isPermissionAssignmentValid: false,
    isWizardFinished: false,
    configuredPermissions: [],
  };

  componentDidMount() {
    const { createRoleActions } = this.props;
    createRoleActions.fetchPermissions();
  }

  fetchAvailableAccounts = async () => {
    const { createRoleActions } = this.props;
    await createRoleActions.fetchAvailableAccounts();
  };

  fetchAvailableGroups = async () => {
    const { createRoleActions } = this.props;
    await createRoleActions.fetchAvailableGroups();
  };

  onCancel = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, "/people/roles");
  };

  onFinishAsync = async () => {
    const {
      accountId,
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;
    const { configuredPermissions, roleConfiguration } = this.state;

    const permissions = permissionsHelper.mapConfiguredPermissionsToRolePermissions(configuredPermissions);
    const roleInfo = { ...roleConfiguration, permissions };
    const params = {
      id: "CreateRole",
      title: `Creating '${roleInfo.name}' role`,
      indeterminate: true,
      getMessageIds: async () => {
        const data = await rolesDataService.createRole(accountId, roleInfo);
        return [data];
      },
      successTransientMessage: "Role has been created successfully.",
      failureTransientMessage: "Role creation failed!",
    };

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });

    this.setState({ isWizardFinished: true }, () => {
      // This prevents the PreventTransitionPrompt from displaying when the user finishes the wizard
      setTimeout(() => {
        this.props.navigate("/people/roles");
      }, 0);
    });
  };

  onProgressAsync = async (activeIndex) => {
    if (activeIndex === 0) {
      await this.submitRoleConfiguration();
      if (!this.state.isRoleConfigValid) {
        return ["cannot submit invalid form"];
      }
    }
    return [];
  };

  updateIsRoleConfigValid = (isRoleConfigValid) => {
    this.setState({ isRoleConfigValid });
  };

  updateIsPermissionsAssignmentValid = (isPermissionAssignmentValid) => {
    this.setState({ isPermissionAssignmentValid });
  };

  bindSubmitForm = (submitForm) => {
    this.submitRoleConfiguration = submitForm;
  };

  onSubmitRoleConfiguration = (roleConfiguration) => {
    this.setState({ roleConfiguration });
  };

  updatePermissions = (configuredPermissions) => {
    this.setState({ configuredPermissions: configuredPermissions });
  };

  shouldPreventTransition = () =>
    !this.state.isWizardFinished &&
    (this.state.isRoleConfigDirty ||
      this.state.configuredPermissions.length > 0 ||
      !isEmpty(this.state.roleConfiguration));

  render() {
    const { isRoleConfigValid, isPermissionAssignmentValid, roleConfiguration, configuredPermissions } = this.state;

    const { permissions, availableAccounts, isLoadingAvailableAccounts, availableGroups, isLoadingAvailableGroups } =
      this.props;

    return (
      <>
        <Dimmer active={this.props.isLoading} inverted />
        <Wizard
          id="create-role-wizard"
          className="create-role"
          title="Create Role"
          onProgressAsync={this.onProgressAsync}
          onCancel={this.onCancel}
          onFinishAsync={this.onFinishAsync}
        >
          <Wizard.Step label="Configuration" isLocked={!isRoleConfigValid} required>
            <RoleConfiguration
              currentConfiguration={roleConfiguration}
              onIsValidChange={this.updateIsRoleConfigValid}
              bindSubmitForm={this.bindSubmitForm}
              onSubmit={this.onSubmitRoleConfiguration}
              onDirtyChanged={(dirty) => this.setState({ isRoleConfigDirty: dirty })}
            />
          </Wizard.Step>

          <Wizard.Step label="Permissions" isLocked={!isPermissionAssignmentValid} required>
            <AssignPermissions
              classNameButtons={"assign-buttons"}
              permissions={permissions}
              configuredPermissions={configuredPermissions}
              onPermissionsChanged={this.updatePermissions}
              onIsValidChange={this.updateIsPermissionsAssignmentValid}
              onFetchAvailableAccounts={this.fetchAvailableAccounts}
              availableAccounts={availableAccounts}
              isLoadingAvailableAccounts={isLoadingAvailableAccounts}
              onFetchAvailableGroups={this.fetchAvailableGroups}
              availableGroups={availableGroups}
              isLoadingAvailableGroups={isLoadingAvailableGroups}
              renderTitleWithBadge
            />
          </Wizard.Step>
        </Wizard>
        <PreventTransitionPrompt
          when={this.shouldPreventTransition()}
          title="Exit Without Saving?"
          message="Are you sure you want to exit without saving this role? All information entered will be lost."
        />
      </>
    );
  }
}

CreateRole.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  availableAccounts: PropTypes.array.isRequired,
  isLoadingAvailableAccounts: PropTypes.bool.isRequired,
  availableGroups: PropTypes.array.isRequired,
  isLoadingAvailableGroups: PropTypes.bool.isRequired,
  createRoleActions: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { createRole } = state.people;
  return {
    accountId: parseInt(state.userProfile.accountId),
    permissions: createRole.permissions,
    isLoading: createRole.isLoading,
    availableAccounts: createRole.availableAccounts,
    isLoadingAvailableAccounts: createRole.isLoadingAvailableAccounts,
    availableGroups: createRole.availableGroups,
    isLoadingAvailableGroups: createRole.isLoadingAvailableGroups,
    error: createRole.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRoleActions: bindActionCreators(createRoleActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateRole));
