import { OverviewHeader } from "../../../../../components/sectionHeader";
import { CreateAssessmentButton } from "./CreateAssessmentButton";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import { DuplicateButton } from "../../../../../components/buttons/linkButtons/headerButtons/DuplicateButton";
import { DeleteButton } from "../../../../../components/buttons/linkButtons/headerButtons/DeleteButton";

import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import TagsManageContainer from "../containers/TagsManageContainer";
import Restricted from "../../../../Application/Restricted";
import useToggle from "../../../../../hooks/useToggle";

import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

export interface AssessmentOverviewHeaderProps {
  selectedIds: Array<number>;
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  hiddenSelected: boolean;
  visibleSelected: boolean;
  deletionHandler: (selectedIds: number[]) => () => void;
  onDuplicate: (selectedIds: number[]) => () => void;
  clearSelection: () => void;
  onToggleVisibility: (selectedIds: number[], hidden: boolean) => () => void;
}

function AssessmentOverviewHeader(props: AssessmentOverviewHeaderProps) {
  const { 
    selectedIds,
    purchasedSelected, 
    undeletableSelected, 
    draftSelected, 
    hiddenSelected,
    visibleSelected,
    onToggleVisibility } = props;

  const [packsModalShown, togglePacksModal] = useToggle();
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility) && !(visibleSelected && hiddenSelected);

  const renderBatchButtons = () => {
    return (
      <>
        {visibility && (
          <ToggleVisibilityButton 
            onClick={onToggleVisibility(selectedIds, hiddenSelected)}
            hidden={hiddenSelected}
            requiredPermissions={[RolePermissions.AssetsCreate]}/>
        )}
        <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
          <AddToPacksButton onClick={togglePacksModal} isDisabled={purchasedSelected || draftSelected} />
        </Restricted>
        <DuplicateButton
          entityName="assessments"
          purchasedSelected={purchasedSelected}
          onClick={props.onDuplicate(selectedIds)}
        />
        <DeleteButton
          entityName="assessments"
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSelected}
          onClick={props.deletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSelected}
          selectedIds={selectedIds}
          onComplete={props.clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={togglePacksModal}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Assessment"
        />
      </>
    );
  };

  return (
    <OverviewHeader
      selectedItemsAmount={selectedIds.length}
      itemName="Assessment"
      title="Assessments"
      titleForGA="Assets - Assessments"
    >
      {selectedIds.some(Boolean) ? renderBatchButtons() : <CreateAssessmentButton />}
    </OverviewHeader>
  );
}

export default AssessmentOverviewHeader;
