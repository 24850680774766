import { type Dispatch } from "@reduxjs/toolkit";

import type UpdateParams from "../../../../../interfaces/updateParams";
import videoDataService from "../../services/videoDataService";
import backgroundTask, { type UpdateFeatureProps } from "../../../../BackgroundTasks/backgroundTask";

import { fetchTags, fetchTagsFailure } from "../slices/videoBaseSlice";
import { pluralize } from "../../../../../utils/stringUtils";
import { type AppDispatch, type AppThunk } from "../../../../Application/globaltypes/redux";
import { type AddOperationParamsV1 } from "../../../../../interfaces/backgroundAddOperationParams";
import { addOperationV1 } from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { type TagsEnum } from "../../../../../interfaces/TagsEnum";
import { tagEntity } from "../../../../../components/addTagsModal/helpers";

export const fetchVideoTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await videoDataService.getTagsFilterOptions(true);
    dispatch(fetchTags(res.data));
  } catch (e) {
    dispatch(fetchTagsFailure(e as Error));
  }
};

export const addTagsToVideos =
  (ids: number[], tags: string[], entityType: TagsEnum): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!tags.length) {
      return;
    }
    const getMessageIds = (videoIds: number[], _tags?: string[]) => async () => {
      const result = await videoDataService.addTagsToVideo({ ids: videoIds, tags, tagType: entityType });
      return result?.data.messageIds;
    };

    const transientMessagePrefix = pluralize(tagEntity[entityType], tags.length);

    const updateParams: UpdateParams = {
      id: "AddTagsToVideoAsset",
      title: `${transientMessagePrefix} adding`,
      getMessageIds: getMessageIds(ids),
      successTransientMessage: `${transientMessagePrefix} ${tags.length === 1 ? "has" : "have"} been added!`,
      failureTransientMessage: `${transientMessagePrefix} add failed!`,
    };

    await initializeBackgroundTask(updateParams, dispatch);
  };

const initializeBackgroundTask = async (updateParams: UpdateParams, dispatch: AppDispatch) => {
  /* istanbul ignore next */
  const updateFeatureProps: UpdateFeatureProps = {
    addOperation: (addOperationParams: AddOperationParamsV1) => dispatch(addOperationV1(addOperationParams)),
    sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
  };

  await backgroundTask.updateEntity(updateParams, updateFeatureProps);
};
