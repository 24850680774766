import { type IThreatDefenceEmailTemplatesDataService } from "./EmailTemplates/types/services";
import { type IThreatDefenceLandingPagesDataService } from "./LandingPages/types/services";
import { type IThreatDefenceFeedbackPagesDataService } from "./FeedbackPages/types/services";
import { type IThreatDefenceSendingProfilesDataService } from "./SendingProfiles/types/services";
import { type IThreatDefenceCampaignsDataService } from "./Campaigns/types/services";
import threatDefenceEmailTemplateService from "./EmailTemplates/services/threatDefenceEmailTemplatesDataService";
import threatDefenceSendingProfilesService from "./SendingProfiles/services/threatDefenceSendingProfilesDataService";
import threatDefenceLandingPagesService from "./LandingPages/services/threatDefenceLandingPagesDataService";
import threatDefenceFeedbackPagesService from "./FeedbackPages/services/threatDefenceFeedbackPagesDataService";
import threatDefenceCampaignsService from "./Campaigns/services/threatDefenceCampaignsDataService";

export const threatDefenceDataServices = Object.assign(
  threatDefenceEmailTemplateService as IThreatDefenceEmailTemplatesDataService,
  threatDefenceLandingPagesService as IThreatDefenceLandingPagesDataService,
  threatDefenceFeedbackPagesService as IThreatDefenceFeedbackPagesDataService,
  threatDefenceSendingProfilesService as IThreatDefenceSendingProfilesDataService,
  threatDefenceCampaignsService as IThreatDefenceCampaignsDataService,
);
