import React from "react";
import cn from "classnames";
import { func, string } from "prop-types";

import MultiSelect from "./MultiSelect";
import { multiSelectPropTypes } from "./multiSelectPropTypes";
import "./multiSelectWithAddition.scss";

function MultiSelectWithAddition(props) {
  const {
    id,
    additionLabel,
    additionPosition,
    handleMultiSelectAddition,
    handleMultiSelectClick,
    className,
    ...multiSelectProps
  } = props;

  return (
    <MultiSelect
      id={id}
      className={cn("multi-select-with-addition-control", className)}
      allowAdditions
      additionLabel={additionLabel}
      additionPosition={additionPosition}
      onAddItem={handleMultiSelectAddition}
      onClick={handleMultiSelectClick}
      {...multiSelectProps}
    />
  );
}

MultiSelect.defaultProps = {
  additionLabel: "(New Label)",
  additionPosition: "bottom",
  noResultsMessage: "",
  closeOnChange: true,
  openOnFocus: false,
};

MultiSelectWithAddition.propTypes = {
  id: string,
  additionLabel: string,
  handleMultiSelectClick: func,
  handleMultiSelectAddition: func,
  onBlur: func,
  renderLabel: func,
  ...multiSelectPropTypes,
};

export default MultiSelectWithAddition;
