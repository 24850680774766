/*
 for sanitizing search input:
  https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters
 Also see the task: https://brainstorm.atlassian.net/browse/SAAS-12294 to understand what symbols we should escape or what
 search features we can leave for the users
 */
export const escapeTerm = (term: string | undefined): string | undefined => {
  const symbols = ["+", "-", "=", "&", "|", "!", "(", ")", "{", "}", "[", "]", "^", '"', "~", "*", "?", ":", "\\", "/"];
  return term !== undefined
    ? term
        .split("")
        .filter((symbol) => symbol !== ">" && symbol !== "<")
        .map((symbol) => (symbols.includes(symbol) ? `\\${  symbol}` : symbol))
        .join("")
    : undefined;
};
