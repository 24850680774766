import { combineReducers } from "redux";
import TemplateOverviewSlice from "../slice/TemplateOverviewSlice";
import TemplateFiltersSlice from "../slice/TemplateFiltersSlice";

const groupTemplatesAssignmentReducer = combineReducers({
  templatesToBeAssigned: TemplateOverviewSlice,
  filters: TemplateFiltersSlice,
});

export default groupTemplatesAssignmentReducer;
