import cn from "classnames";
import { type FC } from "react";
import { connect } from "react-redux";

import SelectableCard from "../../../../../cards/selectableCard/SelectableCard";
import CardThumbnail from "../../../../../cards/cardThumbnail/CardThumbnail";
import CardContent from "../../../../../cards/cardContent/CardContent";

import { PackPublisherInfo } from "../../../../../packs/packPublisherInfo/PackPublisherInfo";
import { PackStatusLabelWithIcon } from "../../../../../packs/packStatusLabelWithIcon/PackStatusLabelWithIcon";
import { type RootState } from "../../../../../../features/Application/globaltypes/redux";
import { type AssignmentPeopleContext } from "../../../../../../enums";
import { type CardBase, type CardItemBase } from "../../../../../../interfaces";

import "./addToAccountPackCard.scss";

export type Item = CardItemBase & {
  description: string;
  logoUri: string;
  isRequested: boolean;
  isFullAccess: boolean;
  hasLicense: boolean;
  thumbnailImageUrl: string;
  isAddedToTemplateGroup?: boolean;
};

export interface AddToAccountPackCardProps extends CardBase<Item> {
  peopleType?: AssignmentPeopleContext;
}

const disabledIcon = {
  iconClassName: "fa fa-exclamation-circle",
  tooltipClassName: "warning",
};

export const AddToAccountPackCard: FC<AddToAccountPackCardProps> = (props) => {
  const { onSelect, className, selected, peopleType } = props;
  const {
    id,
    title,
    description,
    publisher,
    thumbnailImageUrl,
    isFullAccess,
    hasLicense,
    isRequested,
    isAddedToTemplateGroup,
  } = props.item;

  const getDisabledProps = () => {
    if (isAddedToTemplateGroup) {
      return { disabled: true, tooltipContent: "The pack is already added to the Group Template" };
    }

    if (isFullAccess || hasLicense) {
      return { disabled: true, tooltipContent: `The ${peopleType} already has full access to this pack.` };
    }

    return { disabled: false, tooltipContent: "" };
  };

  let icons = [];
  const { disabled, tooltipContent } = getDisabledProps();
  if (disabled) {
    icons.push({
      ...disabledIcon,
      tooltipContent: tooltipContent,
    });
  }

  return (
    <SelectableCard
      id={id}
      className={cn("add-to-account-pack-card", className)}
      onSelect={onSelect}
      disabled={disabled}
      selected={selected}
    >
      <>
        <CardThumbnail thumbnailUrl={thumbnailImageUrl} disabled={disabled} selected={selected} />
        <CardContent title={title} icons={icons} preHeader={<PackPublisherInfo publisherName={publisher} />}>
          <div className="description-row">{description}</div>
          <div className="bottom-row">
            <PackStatusLabelWithIcon isRequested={isRequested} />
          </div>
        </CardContent>
      </>
    </SelectableCard>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  peopleType: state.packs.peopleIssueLicenseModal.modal.selectedPeopleType,
});

export default connect(mapStateToProps)(AddToAccountPackCard);
