import Rights, { hasContentRight, hasGroupRight, hasUserRight } from "../../../../../enums/rights";
import { useMemo } from "react";
import { type AssignmentPeopleContext, RolePermissions } from "../../../../../enums";

function useUserPermissions(permissions: RolePermissions[]) {
  return useMemo(() => {
    return (
      permissions.includes(RolePermissions.AccountsSettingsView) &&
      hasContentRight(permissions, Rights.View) &&
      hasUserRight(permissions, Rights.Manage)
    );
  }, [permissions]);
}

function useGroupPermissions(permissions: RolePermissions[]) {
  return useMemo(() => {
    return (
      permissions.includes(RolePermissions.AccountsSettingsView) &&
      hasContentRight(permissions, Rights.View) &&
      hasGroupRight(permissions, Rights.Manage)
    );
  }, [permissions]);
}

export const usePermissions: Record<AssignmentPeopleContext, (permissions: RolePermissions[]) => boolean> = {
  user: useUserPermissions,
  group: useGroupPermissions,
  templateGroup: useGroupPermissions
} as const;
