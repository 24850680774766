import { Component } from "react";
import { bindActionCreators, type Dispatch } from "redux";
import { connect, type ConnectedProps } from "react-redux";
import { type RootState } from "../../../../Application/globaltypes/redux";
import isEmpty from "lodash/isEmpty";
import { ValidatedForm, FormSectionHeader } from "../../../../../components/forms";

import * as allUserCommonActions from "../../state/userCommonActions";
import * as filterActionCreators from "../../UsersOverview/state/filterActionCreators";

import validationSchemas from "../../../../../utils/validationSchemas";

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface UserInfoFormState {
  department?: string | null;
  jobTitle?: string | null;
  [key: string]: any;
}

export interface UserInfoFormProps {
  userDetails: any;
  onSubmit: (userInfo: any) => void;
  bindSubmitForm: (submitForm: any) => void;
  onIsValidChange: (isValid: boolean) => void;
  disablePreventTransitionPrompt?: boolean;
  isInitialValid?: boolean;
  onFieldValueChanged: (args: any[]) => void;
}

export class UserInfoForm extends Component<UserInfoFormProps & PropsFromRedux, UserInfoFormState> {
  constructor(props: UserInfoFormProps & PropsFromRedux) {
    super(props);
    this.state = {
      department: null,
      jobTitle: null,
    };
  }

  componentDidMount() {
    const { userCommonActions } = this.props;
    userCommonActions.fetchCountries();
    const { filterActions } = this.props;
    filterActions.getFilterOptions();
  }

  onSubmit = (userInfo: any) => {
    const { onSubmit } = this.props;
    const castedUserInfo = this.getValidationSchema().cast(userInfo);
    return onSubmit(castedUserInfo);
  };

  getValidationSchema = () => {
    const { userDetails } = this.props;
    return userDetails.id
      ? validationSchemas.getEditUserSchema(userDetails.id)
      : validationSchemas.getCreateUserSchema();
  };

  getCountries = () =>
    this.props.countryList.items.map((x: any) => ({
      key: x.id,
      value: x.name,
      text: x.name,
    }));

  handleItemAdded = (data: any, propertyName: string) => {
    let newState = { ...this.state };
    newState[propertyName] = { text: data.value, value: data.value };
    this.setState(newState);
  };

  render() {
    const {
      userDetails,
      countryList,
      bindSubmitForm,
      onIsValidChange,
      disablePreventTransitionPrompt,
      jobTitles,
      departments,
      isInitialValid,
      onFieldValueChanged,
    } = this.props;
    const { department, jobTitle } = this.state;

    return (
      <section className="user-info-form edit-form">
        <ValidatedForm
          onSubmit={this.onSubmit}
          isSubmitting={userDetails.isLoading}
          validationSchema={this.getValidationSchema()}
          initialValues={userDetails}
          bindSubmitForm={bindSubmitForm}
          disablePreventTransitionPrompt={disablePreventTransitionPrompt}
          isInitialValid={isInitialValid}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving this user? All information entered will be lost.",
          }}
        >
          {(props) => {
            const { validatedFieldProps, values } = props;
            return (
              <>
                <FormSectionHeader title="Personal Information" />
                <ValidatedForm.InputField
                  label="First Name"
                  value={values.firstName}
                  propertyName="firstName"
                  onValueChanged={onFieldValueChanged}
                  placeholder="Enter First Name"
                  markAsRequired
                  onIsFormValidChange={onIsValidChange}
                  {...validatedFieldProps}
                />
                <ValidatedForm.InputField
                  label="Last Name"
                  value={values.lastName}
                  onValueChanged={onFieldValueChanged}
                  propertyName="lastName"
                  placeholder="Enter Last Name"
                  markAsRequired
                  {...validatedFieldProps}
                />
                <ValidatedForm.InputField
                  label="Email"
                  onValueChanged={onFieldValueChanged}
                  value={values.email}
                  propertyName="email"
                  placeholder="Enter Email"
                  markAsRequired
                  {...validatedFieldProps}
                />
                <FormSectionHeader title="" />
                <ValidatedForm.DropdownField
                  label="Department"
                  value={values.department}
                  placeholder="Search/select a department"
                  propertyName="department"
                  options={isEmpty(department) ? departments : [...departments, department]}
                  onItemAdded={(_: any, data: any) => this.handleItemAdded(data, "department")}
                  onFieldValueChanged={onFieldValueChanged}
                  search
                  clearable
                  allowAdditions
                  {...validatedFieldProps}
                />
                <ValidatedForm.DropdownField
                  label="Job Title"
                  value={values.jobTitle}
                  onFieldValueChanged={onFieldValueChanged}
                  placeholder="Search/select a job title"
                  propertyName="jobTitle"
                  options={isEmpty(jobTitle) ? jobTitles : [...jobTitles, jobTitle]}
                  onItemAdded={(_: any, data: any) => this.handleItemAdded(data, "jobTitle")}
                  search
                  clearable
                  allowAdditions
                  {...validatedFieldProps}
                />
                <ValidatedForm.DropdownField
                  placeholder="Search/select a country"
                  label="Country"
                  isLoading={countryList.isLoading}
                  onFieldValueChanged={onFieldValueChanged}
                  search
                  minCharacters={3}
                  value={values.country}
                  propertyName="country"
                  options={this.getCountries()}
                  clearable
                  {...validatedFieldProps}
                />
              </>
            );
          }}
        </ValidatedForm>
      </section>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  countryList: state.people.userCommon.countriesList,
  departments: state.people.usersOverview.filterOptions.departments,
  jobTitles: state.people.usersOverview.filterOptions.jobTitles,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userCommonActions: bindActionCreators(allUserCommonActions, dispatch),
  filterActions: bindActionCreators(filterActionCreators, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedComponent = connector(UserInfoForm);
export default ConnectedComponent;
