import { SortingDirection } from "enums";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { type GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { createFiltersSlice, type FiltersState } from "../../../../../Application/slices/createFiltersSlice";
import { type EmailsFilterEnum } from "../../types/models";

export const initialState: FiltersState<EmailsFilterEnum> = {
  filterOptions: {} as GenericFiltersMap<EmailsFilterEnum>,
  appliedFilter: {} as GenericFiltersMap<EmailsFilterEnum>,
  isLoading: false,
  pagination: 1,
  sortingColumnName: "added",
  sortingDirection: SortingDirection.Descending,
};

const emailFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Emails,
    name: "overviewFilters",
  },
  initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
} = emailFiltersSlice.actions;

export type EmailFiltersState = ReturnType<typeof emailFiltersSlice.reducer>;

export const emailFiltersReducer = emailFiltersSlice.reducer;
