import { pluralize } from "./stringUtils";

export const getPeopleEntityLabel = (usersCount: number, groupsCount: number): string => {
  if (usersCount === 0 && groupsCount === 0) {
    return "";
  }

  const userGroupContext = usersCount > 0 ? pluralize("User", usersCount) : pluralize("Group", groupsCount);
  return usersCount > 0 && groupsCount > 0 ? "People" : userGroupContext;
};

export const getPeopleCountString = (usersCount: number, groupsCount: number): string => {
  if (usersCount === 0 && groupsCount === 0) {
    return "";
  }

  const peopleCount = usersCount + groupsCount;
  return peopleCount > 1 ? peopleCount.toString() : "";
};
