import { type BaseIntegrationConfigs, IntegrationTypes, type MsGraphIntegrationConfigs, type UsageIntegrationConfig } from "../types";
import { type SfIntegrationConfigs } from "../types/successFactors";
import { type VivaIntegrationConfigs } from "../types/viva";
import IntegrationConfiguration from "./IntegrationConfiguration";
import MsGraphIntegrationConfiguration from "./MsGraphIntegrationConfiguration";
import SfIntegrationConfiguration from "./SfIntegrationConfiguration";
import VivaIntegrationConfiguration from "./VivaIntegrationConfiguration";

export interface IntegrationConfigurationWrapperProps {
  connectorTitle: string;
  configuration: BaseIntegrationConfigs;
  updateConfig: (configs: BaseIntegrationConfigs) => void;
  integrationType: string;
  setIsValid: (isValid: boolean) => void;
}

export const IntegrationConfigurationWrapper: React.FC<IntegrationConfigurationWrapperProps> = (props) => {
  const { connectorTitle, configuration, updateConfig, integrationType, setIsValid } = props;

  const component = (() => {
    switch (integrationType) {
      case IntegrationTypes.Viva:
        return (
          <VivaIntegrationConfiguration
            isCreating
            integrationName={connectorTitle}
            setConfigs={updateConfig}
            configs={configuration as VivaIntegrationConfigs}
          />
        );
      case IntegrationTypes.SuccessFactors:
        return (
          <SfIntegrationConfiguration
            integrationName={connectorTitle}
            onUpdate={updateConfig}
            initialConfig={configuration as SfIntegrationConfigs}
            onIsValidChange={setIsValid}
            renderHeader
          />
        );
      case IntegrationTypes.MsGraph:
        return (
          <MsGraphIntegrationConfiguration
            isCreating
            integrationName={connectorTitle}
            setConfigs={updateConfig}
            configs={configuration as MsGraphIntegrationConfigs}
          />
        );
      default:
        return (
          <IntegrationConfiguration
            isCreating
            integrationName={connectorTitle}
            setConfigs={updateConfig}
            integrationType={integrationType}
            configs={configuration as UsageIntegrationConfig}
          />
        );
    }
  })();

  return component;
};

export default IntegrationConfigurationWrapper;
