import { type FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import { type RootState } from "../../../../../Application/globaltypes/redux";
import { type ThreatDefenceEmailTemplate, type ThreatDefenceEmailTemplateView } from "../../types/state";
import * as emailTemplateEntityStateActions from "../../state/actions/threatDefenceEmailTemplateEntityStateActions";
import * as emailTemplateDetailsActions from "../../state/actions/threatDefenceEmailTemplateDetailsActions";
import { type ConfigureEmailTemplateProps } from "../types";
import validationSchemas from "../../../../../../utils/validationSchemas";
import EmailTemplateForm from "../EmailTemplateForm/EmailTemplateForm";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { type AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ConfigureAllProps = ConfigureEmailTemplateProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceEmailTemplateView> &
  AutosaveProps;

export class Configure extends Component<ConfigureAllProps> {
  isEmailTemplateCreated = () => this.props.id > 0;

  componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    // Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onNext = () => {
    this.props.isValid && this.saveEmail();
    if (!this.isEmailTemplateCreated()) {
      this.props.entityStateActions.createThreatDefenceEmailTemplate(Configure.getEmailTemplate(this.props.values));
    }
  };

  onPrevious = () => {
    this.saveEmail();
  };

  onClose = () => {
    this.props.detailsActions.clearThreatDefenceEmailTemplate();
    this.props.entityStateActions.resetThreatDefenceEmailTemplateEntityState();
  };

  saveEmail = () => {
    this.props.detailsActions.saveThreatDefenceEmailTemplate(this.props.values);
  };

  static getEmailTemplate = (values: ThreatDefenceEmailTemplateView): ThreatDefenceEmailTemplate => {
    return {
      name: values.name.trim(),
      subject: values.subject.trim(),
      labels: values.labels.selected,
      softwareApplications: values.softwareApplications.selected,
    };
  };

  render() {
    return <EmailTemplateForm disablePreventTransitionPrompt={this.isEmailTemplateCreated()} {...this.props} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureEmailTemplateProps) => ({
  id:
    ownProps.entityId ||
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.entityId,
  email: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.email,
  isLoading: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.isLoading,
  isLoaded: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.isEmailLoaded,
  isCreating:
    state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer.changingEntityState,
  isDraft: state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateDetailsReducer.email.isDraft,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(emailTemplateEntityStateActions, dispatch),
  detailsActions: bindActionCreators(emailTemplateDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<
  ConfigureAllProps,
  ThreatDefenceEmailTemplateView,
  ThreatDefenceEmailTemplate
>({
  getInitValues: (props) => Configure.getEmailTemplate(props.email),
  stateProvider: Configure.getEmailTemplate,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceEmailTemplate,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.emailTemplateInfo,
  enableReinitialize: true,
  isInitialValid: (props: ConfigureEmailTemplateProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.emailTemplateInfo, props.email),
  mapPropsToValues: (props: ConfigureEmailTemplateProps & PropsFromRedux) => props.email,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
