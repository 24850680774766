import { type FC, useCallback } from "react";
import { sum } from "lodash";

import DeletionRestrictedTooltip from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";

import { ContentItemMenu } from "../../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { DeleteLinkButton, EditLinkButton, PublishLinkButton } from "../../../../../components/buttons/linkButtons";
import { type PdfOverview } from "../../types/state";
import { type PdfPublishConfirmationParams } from "../../types/models";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

const ENTITY_TYPE = "PDF";

export interface PdfContentItemMenuProps {
  item: PdfOverview;
  handleEditClick: (id: number, isDraft: boolean) => void;
  handlePublishClick: (params: PdfPublishConfirmationParams) => void;
  handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => void;
  readonly?: boolean;
  isChecked?: boolean;
  circle?: boolean;
  outlinedEllipsis?: boolean;
  hasPermission?: boolean;
  onToggleVisibility: (id: number[], hidden: boolean) => () => void;
  hideDisabled?: boolean;
}

export const PdfContentItemMenu: FC<PdfContentItemMenuProps> = (props) => {
  const {
    circle,
    outlinedEllipsis,
    readonly,
    isChecked,
    item,
    handleEditClick,
    handlePublishClick,
    handleDeleteClick,
    onToggleVisibility,
    hideDisabled,
    hasPermission = true,
  } = props;

  const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
  const dependenciesMap = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
  const hasDeletionDependencies = !!sum(Object.values(dependenciesMap || {}));
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);

  const onEditClick = useCallback(
    () => handleEditClick(item.id, item.isDraft),
    [handleEditClick, item.id, item.isDraft],
  );
  const onDeleteClick = useCallback(
    () => handleDeleteClick([item.id], flowsCount, packsCount),
    [handleDeleteClick, item.id, flowsCount, packsCount],
  );
  const onPublishClick = useCallback(
    () =>
      handlePublishClick({
        id: item.id,
        isDraft: item.isDraft,
        flowsCount: flowsCount,
        assignmentsCount: assignmentsCount,
        hasBeenPublished: item.hasBeenPublished,
      }),
    [handlePublishClick, item.id, item.isDraft, flowsCount, assignmentsCount, item.hasBeenPublished],
  );

  return (
    <RestrictedByTooltip hasPermission={hasPermission}>
      <ContentItemMenu
        circle={circle}
        outlinedEllipsis={outlinedEllipsis}
        isChecked={isChecked}
        readonly={readonly}
        dependencies={dependenciesMap}
        entityType={ENTITY_TYPE}
        item={item}
      >
        {visibility && !hideDisabled && (<ToggleVisibilityButton onClick={onToggleVisibility([item.id], !item.visibility)} hidden={!item.visibility} />)}
        {!item.isPurchased && <EditLinkButton onClick={onEditClick} isDisabled={item.isPurchased} />}
        {item.isDraft && !item.isPurchased && <PublishLinkButton onClick={onPublishClick} />}
        {!item.isPurchased && <DeleteLinkButton
          isDisabled={!deleteContentEnabled && !item.canBeDeleted}
          after={
            !deleteContentEnabled &&
            !item.isPurchased &&
            hasDeletionDependencies && (
              <DeletionRestrictedTooltip dependencies={dependenciesMap} entityType={ENTITY_TYPE} />
            )
          }
          onClick={onDeleteClick}
        />}
      </ContentItemMenu>
    </RestrictedByTooltip>
  );
};
