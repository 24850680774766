import { useCallback, useEffect, useRef, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import PreventTransitionPrompt from "../../../components/preventTransition/PreventTransitionPrompt";
import { Wizard } from "../../../components/wizard";
import { type AppDispatch, type RootState } from "../../Application/globaltypes/redux";
import AttributeMapping from "./AttributeMapping/AttributeMapping";
import Miscellaneous from "./Miscellaneous/Miscellaneous";
import * as claimTypesThunk from "../state/thunks/claimTypesThunk";
import * as signingCertificatesThunk from "../state/thunks/signingCertificatesThunk";
import * as encodingTypesThunk from "../state/thunks/encodingTypesThunk";
import * as signatureAlgorithmsThunk from "../state/thunks/signatureAlgorithmsThunk";
import { claimTypesSelector } from "../state/slices/claimTypesSlice";
import { signatureAlgorithmsSelector } from "../state/slices/signatureAlgorithmsSlice";
import { encodingTypesLoadingSelector, encodingTypesSelector } from "../state/slices/encodingTypesSlice";
import {
  signingCertificatesLoadingSelector,
  signingCertificatesSelector,
} from "../state/slices/signingCertificatesSlice";
import { type MappedField } from "./AttributeMapping/types";
import IdentityProviderConfiguration from "./IdentityProviderConfiguration/IdentityProviderConfiguration";
import {
  type CertificateValidationType,
  type ClaimType,
  type CreateIdentityProvider as CreateIdentityProviderType,
  type EncodingType,
  type IdentityProviderConfigInfo,
  IdentityProviderType,
  type SignatureAlgorithm,
  type SigningCertificate,
} from "../types";
import { isEmpty } from "lodash";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import * as notificationsActions from "../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import identityProvidersDataService from "../services/identityProvidersDataService";
import useToggle from "../../../hooks/useToggle";
import { type DistributedOpUpdateParams } from "../../../interfaces/updateParams";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import navigationUtils from "../../../utils/navigationUtils";
import { certificateValidationTypes } from "../constants";

export type CreateIdentityProviderPropsAll = PropsFromRedux;

export const CreateIdentityProvider: React.FC<CreateIdentityProviderPropsAll> = ({
  claimTypes,
  fetchClaimTypes,
  signatureAlgorithms,
  isSignatureAlgorithmsLoading,
  fetchSignatureAlgorithms,
  encodingTypes,
  isEncodingTypesLoading,
  fetchEncodingTypes,
  signingCertificates,
  isSigningCertificatesLoading,
  fetchSigningCertificates,
  backgroundTasksActions: { addOperationDistributedOp },
  notificationsActions: { sendTransientNotification },
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { moboId } = useParams();

  useEffect(() => {
    fetchClaimTypes(moboId);
  }, [fetchClaimTypes, moboId]);

  useEffect(() => {
    setSigningCertificate(signingCertificates[0]);
  }, [signingCertificates]);

  useEffect(() => {
    setSignatureAlgorithm(signatureAlgorithms[0]);
  }, [signatureAlgorithms]);

  useEffect(() => {
    setEncodingType(encodingTypes[0]);
  }, [encodingTypes]);

  useEffect(() => {
    setCertificateValidationType(certificateValidationTypes && certificateValidationTypes[1]);
  }, []);

  const [isWizardFinished, setIsWizardFinished] = useState(false);
  const [isFirstStepDirty, setIsFirstStepDirty] = useState(false);
  const [isAttributeMappingValid, setIsAttributeMappingValid] = useState(false);
  const [mappedFields, setMappedFields] = useState<MappedField[]>([]);
  const [isProviderConfigValid, setIsProviderConfigValid] = useState(false);
  const [metadataContentUrl, setMetadataContentUrl] = useState<string>();
  const [providerConfiguration, setProviderConfiguration] = useState({} as IdentityProviderConfigInfo);
  const submitFormRef = useRef<() => Promise<any>>();
  const resetFormRef = useRef<() => Promise<any>>();
  const [omitAssertionSignatureCheck, setOmitAssertionSignatureCheck] = useState<boolean>(true);
  const [updateUserInfo, setUpdateUserInfo] = useToggle();
  const [useSiteMinder, setUseSiteMinder] = useState(false);
  const [signRequest, setSignRequest] = useState(true);
  const [forceAuth, setForceAuth] = useState(false);
  const [isPassive, setIsPassive] = useState(false);
  const [signatureAlgorithm, setSignatureAlgorithm] = useState<SignatureAlgorithm>();
  const [signingCertificate, setSigningCertificate] = useState<SigningCertificate>();
  const [encodingType, setEncodingType] = useState<EncodingType>();
  const [certificateValidationType, setCertificateValidationType] = useState<CertificateValidationType>();
  const [currentIdentityProviderType, setIdentityProviderType] = useState<IdentityProviderType>();
  const [identityProvider, setIdentityProvider] = useState("Other");
  const oldIdentityProvider = useRef("Other");
  const [identityProviderChanged, setIdentityProviderChanged] = useState(false);

  const onFinishAsync = async () => {
    const claimCustomMapping = mappedFields
      .filter((x) => x.isCustomField)
      .filter(isMappedFieldConfigured)
      .map((x) => ({
        fieldName: x.mapFrom!,
        fieldValue: x.mapTo!,
      }));
    const claimsMappings = mappedFields
      .filter((x) => !x.isCustomField)
      .filter(isMappedFieldConfigured)
      .map((x) => ({
        claimTypeId: claimTypes.find((ct: ClaimType) => ct.alias === x.mapTo)!.id,
        accountClaimTypeName: x.mapFrom!,
      }));
    const idp: CreateIdentityProviderType = {
      name: providerConfiguration.name,
      type: providerConfiguration.type,
      metadataContentUrl: metadataContentUrl!,
      metadataAutoRefresh: providerConfiguration.metadataAutoRefresh,
      metadataUrl: providerConfiguration.metadataUrl || undefined,
      fileName: providerConfiguration.metadataFile?.name,
      claimsMappings: claimsMappings,
      claimsCustomMappings: claimCustomMapping,
      updateUserInfo: updateUserInfo,
    };

    if (providerConfiguration.type === IdentityProviderType.Saml2) {
      idp.omitAssertionSignatureCheck = omitAssertionSignatureCheck;
      idp.signRequest = signRequest;
      idp.isSiteMinder = useSiteMinder;
      idp.forceAuth = forceAuth;
      idp.encodingTypeId = encodingType?.id;
      idp.isPassive = isPassive;
      idp.certificateValidationType = certificateValidationType?.type;
      idp.signingCertificateThumbprint = signingCertificate?.thumbprint;
      idp.signatureAlgorithmId = signatureAlgorithm?.id;
    }

    const params: DistributedOpUpdateParams = {
      id: "CreateIdentityProvider",
      title: `Creating '${providerConfiguration.name}' identity provider`,
      indeterminate: true,
      getOperationProps: () => {
        return identityProvidersDataService.createIdentityProvider(idp, moboId);
      },
      successTransientMessage: "Identity provider has been created successfully.",
      failureTransientMessage: "Identity provider creation failed!",
    };

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: addOperationDistributedOp,
      sendTransientNotification,
    });

    setIsWizardFinished(true);
    navigationUtils.goBackOrUpSegment(location, navigate);
  };

  const onProgressAsync = async (activeIndex: number) => {
    if (activeIndex === 0) {
      await submitFormRef.current!();
      if (!isProviderConfigValid) {
        return ["cannot submit invalid form"];
      }

      // Has identity provider changed from the last time we were on attribute mapping?
      if (oldIdentityProvider.current !== identityProvider) {
        setIdentityProviderChanged(true);
        oldIdentityProvider.current = identityProvider;
      } else {
        setIdentityProviderChanged(false);
      }
    }

    return [];
  };

  const isMappedFieldConfigured = (field: MappedField) => !isEmpty(field.mapFrom) && !isEmpty(field.mapTo);

  const onCancel = () => navigationUtils.goBackOrUpSegment(location, navigate);

  const shouldPreventTransition = () => isFirstStepDirty && !isWizardFinished;

  const onMappedFieldsChange = useCallback((fields: MappedField[], isStepValid: boolean) => {
    setMappedFields(fields);
    setIsAttributeMappingValid(isStepValid);
  }, []);

  const bindResetForm = (resForm: any) => {
    resetFormRef.current = resForm;
  };
  const bindSubmitForm = (subForm: any) => {
    submitFormRef.current = subForm;
  };

  const getSteps = () => {
    return (
      <>
        <Wizard.Step
          label="Configuration"
          isLocked={!isProviderConfigValid || !metadataContentUrl}
          required
          nextButtonLabel="Next"
        >
          <IdentityProviderConfiguration
            onIsValidChange={setIsProviderConfigValid}
            bindSubmitForm={bindSubmitForm}
            bindResetForm={bindResetForm}
            providerConfiguration={providerConfiguration}
            onSubmit={setProviderConfiguration}
            onMetadataContentUrlChange={setMetadataContentUrl}
            onTypeChange={setIdentityProviderType}
            onDirtyChanged={setIsFirstStepDirty}
            moboId={moboId}
            onIdentityProviderChange={setIdentityProvider}
          />
        </Wizard.Step>
        <Wizard.Step label="Attribute Mapping" required isLocked={!isAttributeMappingValid}>
          <AttributeMapping
            onMappedFieldsChange={onMappedFieldsChange}
            updateUserInfo={updateUserInfo}
            onUpdateUserInfoChange={setUpdateUserInfo}
            mappedFields={mappedFields}
            claimTypes={claimTypes}
            identityProvider={identityProvider}
            identityProviderChanged={identityProviderChanged}
          />
        </Wizard.Step>
        {currentIdentityProviderType === IdentityProviderType.Saml2 && (
          <Wizard.Step
            label="Miscellaneous"
            isLocked={isSignatureAlgorithmsLoading || isEncodingTypesLoading || isSigningCertificatesLoading}
          >
            <Miscellaneous
              omitAssertionSignatureCheck={omitAssertionSignatureCheck}
              onOmitAssertionSignatureCheckChange={() => setOmitAssertionSignatureCheck(!omitAssertionSignatureCheck)}
              useSiteMinder={useSiteMinder}
              onUseSiteMinderChange={() => setUseSiteMinder(!useSiteMinder)}
              signRequest={signRequest}
              onSignRequestChange={() => setSignRequest(!signRequest)}
              signatureAlgorithms={{
                items: signatureAlgorithms,
                fetch: fetchSignatureAlgorithms,
                current: signatureAlgorithm,
                onChange: setSignatureAlgorithm,
                isLoading: isSignatureAlgorithmsLoading,
              }}
              signingCertificates={{
                items: signingCertificates,
                fetch: fetchSigningCertificates,
                current: signingCertificate,
                onChange: setSigningCertificate,
                isLoading: isSigningCertificatesLoading,
              }}
              forceAuth={forceAuth}
              onForceAuthChange={() => setForceAuth(!forceAuth)}
              isPassive={isPassive}
              onIsPassiveChange={() => setIsPassive(!isPassive)}
              encodingTypes={{
                items: encodingTypes,
                fetch: fetchEncodingTypes,
                current: encodingType,
                onChange: setEncodingType,
                isLoading: isEncodingTypesLoading,
              }}
              certificateValidationTypes={{
                items: certificateValidationTypes,
                fetch: () => {}, // NOSONAR
                current: certificateValidationType,
                onChange: setCertificateValidationType,
                isLoading: false,
              }}
              isReadOnly={false}
            />
          </Wizard.Step>
        )}
      </>
    );
  };

  return (
    <>
      <Wizard
        id="create-identity-provider-wizard"
        className="create-identity-provider"
        title="Add ID Provider"
        onCancel={onCancel}
        onProgressAsync={onProgressAsync}
        onFinish={onFinishAsync}
        finishButtonLabel="Finish"
      >
        {getSteps().props.children.filter((x: any) => !!x)}
      </Wizard>
      <PreventTransitionPrompt
        when={shouldPreventTransition()}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving this identity provider? All information entered will be lost."
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    claimTypes: claimTypesSelector(state),
    signatureAlgorithms: signatureAlgorithmsSelector(state),
    isSignatureAlgorithmsLoading: signingCertificatesLoadingSelector(state),
    signingCertificates: signingCertificatesSelector(state),
    isSigningCertificatesLoading: signingCertificatesLoadingSelector(state),
    encodingTypes: encodingTypesSelector(state),
    isEncodingTypesLoading: encodingTypesLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchClaimTypes: bindActionCreators(claimTypesThunk.fetchClaimTypes, dispatch),
    fetchSigningCertificates: bindActionCreators(signingCertificatesThunk.fetchSigningCertificates, dispatch),
    fetchEncodingTypes: bindActionCreators(encodingTypesThunk.fetchEncodingTypes, dispatch),
    fetchSignatureAlgorithms: bindActionCreators(signatureAlgorithmsThunk.fetchSignatureAlgorithms, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(CreateIdentityProvider);
export default ConnectedComponent;
