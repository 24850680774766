import { type ColumnOptions } from "interfaces";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 10,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
  {
    name: "Users",
    width: 2,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];
