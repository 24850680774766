import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import type AssignedGroup from "../../../../../interfaces/assignedGroup";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

export interface FlowGroupsToAddState extends FetchingItemsState<AssignedGroup> {}

export const initialState: FlowGroupsToAddState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const flowGroupsToAddSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "groupsToAdd",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = flowGroupsToAddSlice.actions;

export default flowGroupsToAddSlice.reducer;
export type flowGroupsToAddSliceStateType = ReturnType<typeof flowGroupsToAddSlice.reducer>;
