type ApiState = {
  status: "idle" | "loading" | "fetched" | "error";
  userText: string | null;
  data: string | null;
  error: string | null;
};

type ApiAction =
  | { type: "loading"; payload: string }
  | { type: "success"; payload: string }
  | { type: "error"; payload: string }
  | { type: "reset" };

export function apiReducer<T>(state: ApiState, action: ApiAction): ApiState {
  switch (action.type) {
    case "loading":
      return { ...state, status: "loading", userText: action.payload, error: null };
    case "success":
      return { ...state, status: "fetched", data: action.payload, error: null };
    case "error":
      return { ...state, status: "error", error: action.payload };
    case "reset":
      return { status: "idle", data: null, userText: null, error: null };
    default:
      return state;
  }
}
