import React from "react";
import PropTypes from "prop-types";

import { RightButton, LeftButton } from "../../buttons/doubleButtons";

function PaginationButtons(props) {
  const { onPrevPage, onNextPage, itemsPageRange, activePage, itemsInlineCount } = props;

  const firstPageNumber = 1;
  const isFirstPage = activePage === firstPageNumber;
  const isLastPage = itemsPageRange.to === itemsInlineCount;

  return (
    <>
      <LeftButton
        className="navigation-button"
        iconName="fa-chevron-left"
        onClick={onPrevPage}
        disabled={isFirstPage || !itemsInlineCount}
        aria-label="previous page"
      />
      <RightButton
        className="navigation-button"
        iconName="fa-chevron-right"
        onClick={onNextPage}
        disabled={isLastPage || !itemsInlineCount}
        aria-label="next page"
      />
    </>
  );
}

PaginationButtons.propTypes = {
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  itemsPageRange: PropTypes.object,
  activePage: PropTypes.number,
  itemsInlineCount: PropTypes.number,
};

export default PaginationButtons;
