import { type AsyncThunk, createSlice, type Draft, type PayloadAction, type SliceCaseReducers } from "@reduxjs/toolkit";
import { type FetchActionPayload } from "../../../interfaces/redux";
import { type CreateThunkSlice, getPrefix } from "./models";

export interface FetchingItemsState<T> {
  items: T[];
  itemsCount: number;
  isLoading: boolean;
  areAllLoaded?: boolean;
  currentRequestId?: string;
  error?: Error;
}

export interface FetchSuccessPayload<T> extends FetchActionPayload<T> {
  append?: boolean;
}

type TType<TState> = TState extends FetchingItemsState<infer U> ? U : TState;

export const createFetchingItemsSlice = <
  T extends TType<TState>,
  TArgs,
  TState extends FetchingItemsState<unknown>,
  Reducers extends SliceCaseReducers<TState>,
>({
  namePayload,
  initialState,
  reducers,
  thunk,
}: CreateThunkSlice<TState, Reducers, AsyncThunk<Draft<FetchSuccessPayload<T>>, TArgs, {}>>) => {
  return createSlice({
    name: getPrefix(namePayload),
    initialState,
    reducers: {
      fetchBegin(state) {
        state.isLoading = true;
      },
      fetchSuccess(state: TState, action: PayloadAction<FetchSuccessPayload<T>>) {
        if (action.payload.append) {
          state.items = state.items.concat(action.payload.items);
        } else {
          state.items = action.payload.items;
        }
        state.itemsCount = action.payload.totalCount;
        state.areAllLoaded = state.items.length >= action.payload.totalCount;
        state.isLoading = false;
      },
      fetchFailure(state: TState, action: PayloadAction<Error>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      reset() {
        return { ...initialState };
      },
      ...reducers,
    },
    extraReducers: (builder) => {
      if (!thunk) return;

      builder
        .addCase(thunk.pending, (state, action) => {
          state.isLoading = true;
          state.currentRequestId = action.meta.requestId;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          const { requestId } = action.meta;
          if (state.isLoading && state.currentRequestId === requestId) {
            state.currentRequestId = undefined;
            state.isLoading = false;

            if (action.payload.append) {
              state.items = state.items.concat(action.payload.items);
            } else {
              state.items = action.payload.items;
            }
            state.itemsCount = action.payload.totalCount;
            state.areAllLoaded = state.items.length >= state.itemsCount;
          }
        })
        .addCase(thunk.rejected, (state, action) => {
          const { requestId } = action.meta;
          if (state.isLoading && state.currentRequestId === requestId) {
            state.currentRequestId = undefined;
            state.areAllLoaded = false;
            state.isLoading = false;
            state.error = action.error as Error;
          }
        });
    },
  });
};
