import type UpdateParams from "../../../../interfaces/updateParams";
import accountsDataService from "../../services/accountsDataService";

export default class DeleteAccountTask implements UpdateParams {
  id: string;
  title: string;
  successTransientMessage: string;
  failureTransientMessage: string;
  indeterminate: boolean;
  accountId: number;

  constructor(accountId: number, accountName: string) {
    this.id = "DeleteAccount";
    this.title = `Deleting account '${accountName}'`;
    this.successTransientMessage = `Account '${accountName}' has been deleted!`;
    this.failureTransientMessage = `Account '${accountName}' deletion failed!`;
    this.indeterminate = true;
    this.accountId = accountId;
  }

  getMessageIds = async () => {
    const messageId = await accountsDataService.deleteAccount(this.accountId);
    return [messageId];
  };
}
