import { useMemo } from "react";
import cn from "classnames";

import type Icon from "../../../../../../interfaces/icon";
import { FlowCard } from "../../../../../cards";
import { pluralize } from "../../../../../../utils/stringUtils";
import { type PeopleAvailableFlow, type WithPeopleContext } from "../../../../../../features/People/ContentAssignments/models";
import { type FlowCardBaseProps } from "../../../../../cards/FlowCard/types";

import "./addToFlowCard.scss";

export interface AddToFlowCardProps extends FlowCardBaseProps<WithPeopleContext<PeopleAvailableFlow>> {}

export const AddToFlowCard = (props: AddToFlowCardProps) => {
  const { item, selected, onSelect, disabled } = props;
  const { usersCount, selectedPeopleType } = item;

  const someAssigned = item.isAssigned;
  const count = item.assignsCount;

  const allAssigned = someAssigned && count === usersCount;

  const icons = useMemo((): Icon[] => {
    if (!someAssigned) {
      return [];
    }
    const belong = usersCount > 1 ? "belong" : "belongs";
    const tooltipMessage = allAssigned
      ? `This ${pluralize(selectedPeopleType, usersCount)} already ${belong} to this flow.`
      : `(${count}) of the ${pluralize(
          selectedPeopleType,
          usersCount,
        )} selected already belong to this flow and will be skipped.`;

    return [
      {
        iconClassName: "fa fa-exclamation-circle",
        tooltipContent: tooltipMessage,
        tooltipClassName: "warning",
        tooltipPosition: "top center",
      },
    ];
  }, [allAssigned, count, selectedPeopleType, someAssigned, usersCount]);

  return (
    <FlowCard
      item={item}
      selected={selected}
      onSelect={onSelect}
      icons={icons}
      disabled={allAssigned || disabled}
      className={cn({ warning: someAssigned })}
    />
  );
};

export default AddToFlowCard;
