import { type ColumnOptions } from "interfaces";

export const getGroupsColumnOptions = (): ColumnOptions[] => [
  {
    name: "Name",
    width: 5,
    isSortable: true,
  },
  {
    name: "Owners",
    width: 4,
    isSortable: false,
  },
  {
    name: "Members",
    width: 4,
    isSortable: false,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
];
