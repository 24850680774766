import React, { memo, useMemo } from "react";
import cn from "classnames";

import BaseNode from "../Base/Base";
import SurveyCard from "../../../../../../../components/cards/flowDesignerDropCard/SurveyCard/SurveyCard";
import type AssetTypes from "../../../../../../../enums/assetTypes";
import { type FlowItemSurvey } from "../../../types";
import { type FlowItemBase } from "../types";
import { type Connection, Handle, Position, useEdges } from "reactflow";
import { DEFAULT_NODE_POSITION, NODE_INTERVAL, OTHER_ANSWER } from "../../../constants";
import { cloneDeep } from "lodash";

interface ISurveyNodeOwnProps {
  type: AssetTypes.Survey;
  data: FlowItemSurvey & FlowItemBase;
  isConnectable: boolean;
}

const getRightHandleStyle = (index: number, entityLength: number) => ({
  top: "auto",
  bottom: DEFAULT_NODE_POSITION + NODE_INTERVAL * (entityLength - index - 1),
});

const SurveyNode: React.FC<ISurveyNodeOwnProps> = ({ type, data }) => {
  const edges = useEdges();
  const { branchingQuestion, connected, hasEntity, canConnect } = data;
  const isSourceHandleVisible = hasEntity && canConnect;

  const isValidConnection = (connection: Connection) => {
    return !edges.find(
      ({ sourceHandle, source }) => sourceHandle === connection.sourceHandle && source === connection.source,
    );
  };

  const additionHandlers = useMemo(() => {
    const answersClone = cloneDeep(branchingQuestion?.answers);

    if (branchingQuestion?.includeOtherAsAnswer) {
      answersClone?.push(OTHER_ANSWER);
    }

    return answersClone?.map((answer, index) => (
      <Handle
        id={answer.id}
        key={answer.id}
        type="source"
        datatype={`data-hook-source-${answer.id}`}
        position={Position.Right}
        style={getRightHandleStyle(index, answersClone.length)}
        isValidConnection={isValidConnection}
        className={cn({
          connected: connected.source && isSourceHandleVisible,
          visible: isSourceHandleVisible,
        })}
        isConnectable={isSourceHandleVisible}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchingQuestion, connected]);

  return (
    <BaseNode data={data} additionHandlers={additionHandlers}>
      <SurveyCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(SurveyNode);
