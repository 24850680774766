import { bindActionCreators, type Dispatch } from "@reduxjs/toolkit";
import cn from "classnames";
import { max, scaleLinear } from "d3";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { type FC, useEffect, useMemo, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";

import { type RootState } from "../../../Application/globaltypes/redux";
import {
  reset,
  selectEngagementCompleted,
  selectEngagementCumulativeCompleted,
  selectEngagementCumulativeInProgress,
  selectEngagementDates,
  selectEngagementFunnelData,
  selectEngagementFunnelValue,
  selectEngagementInProgress,
  selectEngagementLineData,
  selectEngagementNotStart,
} from "../state/slices/videoPerformanceSlice";
import * as videoPerformanceActions from "../state/thunks/videoPerformanceThunk";

import { timeScale } from "components/charts/shared/bsi-time-scale";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { setIsReportEnabled, reset as toolbarReset } from "features/Reporting/state/toolbar/toolbarSlice";
import { bindAction } from "interfaces";
import CardReporting from "../../../../components/cards/CardReporting/CardReporting";
import {
  ChartWrapper,
  getDateFormatByCount,
  HorizontalBarChart,
  LineChart,
  TickValueType,
} from "../../../../components/charts";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import {
  chartColorScale,
  chartLegendLabels,
  lineChartMargins,
  completes,
  completesColor,
  dailyActivity,
  formattedAverageTime,
  lineChartTitles,
  noBarData,
  noData,
  type PerformanceFilter,
  roundToTwoDigits,
  starts,
  startsColor,
  totalActivity,
  transitionTime,
  validLineData,
} from "../../Common/utils/performanceUtils";
import VideoPerformancePeople from "./VideoPerformancePeople";

import "../../Common/utils/performanceSCSSUtils.scss";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

export interface Props extends PropsFromRedux {
  videoId: number;
  videoTitle: string;
  flowId?: number;
  videoFilter: PerformanceFilter;
  includeAccounts?: boolean;
}

export const VideoPerformanceBody: FC<Props> = ({
  accountId,
  videoId,
  videoTitle,
  flowId,
  videoFilter,
  engagementLineStatus,
  engagementDates,
  engagementNotStart,
  engagementInProgress,
  engagementCompleted,

  lineCumulativeInProgress,
  lineCumulativeCompleted,

  engagementFunnelStatus,
  engagementFunnelData,

  actions,
  setExportAction,
  setIsReportEnabled,
  resetIsReportEnabled,
  includeAccounts,
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.VideoReport);

  useEffect(() => {
    if (reportEnabled) {
      actions.fetchEngagementLineData(videoId, { ...videoFilter, flowId, allItems: !!flowId });
      actions.fetchEngagementFunnelData(videoId, { ...videoFilter, flowId, allItems: !!flowId });
    }
  }, [actions, videoId, flowId, videoFilter, reportEnabled, accountId]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: actions.doExport,
      args: [videoId, { ...videoFilter, flowId, allItems: false }, videoTitle],
      isExportEnabled: true,
    });
  }, [actions.doExport, videoFilter, videoId, setExportAction, flowId, videoTitle, accountId]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const horizontalBarDomain = useMemo(
    () => [0, max([engagementFunnelData.StartedCount, engagementFunnelData.CompletedCount]) || 1],
    [engagementFunnelData],
  );

  const horizontalBarData = useMemo(
    () => [
      {
        id: starts.toLowerCase(),
        category: starts,
        value: engagementFunnelData.StartedCount,
        fillColor: startsColor,
      },
      {
        id: completes.toLowerCase(),
        category: completes,
        value: engagementFunnelData.CompletedCount,
        fillColor: completesColor,
      },
    ],
    [engagementFunnelData],
  );

  const newVersionLineData = useMemo<number[][]>(() => {
    if (!reportEnabled) return [];
    if (newLineChartState === dailyActivity) {
      return [engagementInProgress, engagementCompleted];
    }
    return [lineCumulativeInProgress, lineCumulativeCompleted];
  }, [
    lineCumulativeInProgress,
    lineCumulativeCompleted,
    reportEnabled,
    newLineChartState,
    engagementInProgress,
    engagementCompleted,
  ]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceBody">
      <div className="performanceChartsSection">
        <div className="graphs">
          <div className={cn("lineChartContainer", "user_chart_container")}>
            <ChartWrapper
              titles={lineChartTitles}
              legendLabels={chartLegendLabels}
              showLegend
              onChange={setNewLineChartState}
              colorRange={chartColorScale}
            >
              <RequestStatusRenderer state={engagementLineStatus}>
                {validLineData([
                  engagementInProgress,
                  engagementCompleted,
                  lineCumulativeInProgress,
                  lineCumulativeCompleted,
                ]) ? (
                  <LineChart
                    margins={lineChartMargins}
                    xScaleRatio={timeScale}
                    xFormatterFunc={getDateFormatByCount(engagementDates.length)}
                    yTickValueType={TickValueType.IntegersOnly}
                    transitionDuration={transitionTime}
                    xData={[engagementDates, engagementDates]}
                    yData={newVersionLineData}
                    yFormatterFunc={",d"}
                    yScaleRatio={scaleLinear}
                    colorRange={chartColorScale}
                  />
                ) : (
                  noData(videoFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className={cn("funnelChartContainer", "user_chart_container")}>
            <ChartWrapper titles={["Engagement"]}>
              <RequestStatusRenderer state={engagementFunnelStatus}>
                {!noBarData(engagementFunnelData.StartedCount, engagementFunnelData.CompletedCount) ? (
                  <HorizontalBarChart
                    {...sharedAccountReportingHorizontalBarProps}
                    data={horizontalBarData}
                    domain={horizontalBarDomain}
                  />
                ) : (
                  noData(videoFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        <div className="performanceCardSection">
          <RequestStatusRenderer state={engagementFunnelStatus}>
            {engagementFunnelData && (
              <CardReporting
                items={[
                  {
                    statDescription: `Total ${starts}`,
                    stat: engagementFunnelData.StartedCount ?? 0,
                  },
                  {
                    statDescription: `Total ${completes}`,
                    stat: engagementFunnelData.CompletedCount ?? 0,
                  },
                  {
                    statDescription: "Completion Rate",
                    stat: engagementFunnelData.CompletionRate
                      ? `${roundToTwoDigits(engagementFunnelData.CompletionRate * 100)}%`
                      : "0%",
                  },
                  {
                    statDescription: "Avg. Video Duration",
                    stat: engagementFunnelData.VideoDuration
                      ? formattedAverageTime(engagementFunnelData.VideoDuration)
                      : "0s",
                  },
                ]}
              />
            )}
          </RequestStatusRenderer>
        </div>
        <div className="performanceTableSection">
          <VideoPerformancePeople
            includeAccounts={includeAccounts}
            videoId={videoId}
            flowId={flowId}
            filter={videoFilter}
          />
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    engagementLineStatus: selectEngagementLineData(state),
    engagementDates: selectEngagementDates(state),
    engagementNotStart: selectEngagementNotStart(state),
    engagementInProgress: selectEngagementInProgress(state),
    engagementCompleted: selectEngagementCompleted(state),

    lineCumulativeInProgress: selectEngagementCumulativeInProgress(state),
    lineCumulativeCompleted: selectEngagementCumulativeCompleted(state),

    engagementFunnelStatus: selectEngagementFunnelData(state),
    engagementFunnelData: selectEngagementFunnelValue(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setExportAction: bindAction(setExportAction, dispatch),
    actions: bindActionCreators(videoPerformanceActions, dispatch),
    resetState: bindAction(reset, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(toolbarReset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(VideoPerformanceBody);
export default ConnectedComponent;
