import { isEmpty } from "lodash";
import { type FC } from "react";
import { Input } from "semantic-ui-react";

import { CopyableInput } from "../../../../../components";

export interface Props {
  value?: string;
  loading?: boolean;
  readonly?: boolean;
  onRegenerate(): void;
}

export const SecretInput: FC<Props> = ({ loading, readonly, onRegenerate, value = "" }) => {
  if (isEmpty(value) && !loading) {
    return (
      <Input
        value=""
        disabled={readonly}
        placeholder="Secret has already been generated."
        action={{ color: "blue", content: "Regenerate", onClick: onRegenerate, disabled: readonly }}
      />
    );
  }

  return <CopyableInput value={value} loading={loading} />;
};
