import axios from "axios";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import { serializeArrayAndFilterNullable } from "../../../../../utils/queryUtils";
import { type GetThreatDefenceLandingPagesRequest } from "../types/requests";
import { type IThreatDefenceLandingPagesDataService } from "../types/services";
import { type ThreatDefenceLandingPage } from "../types/state";

const instance: IThreatDefenceLandingPagesDataService = {
  getThreatDefenceLandingPagesAsync(data: GetThreatDefenceLandingPagesRequest) {
    const config = {
      params: {
        top: data.top,
        skip: data.skip,
        orderBy: data.orderBy,
        ...threatDefenceFetchDataUtils.formatFilters(data.filters),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get("/api/threat-defence/landing-pages", config);
  },
  updateThreatDefenceLandingPageAsync(data: ThreatDefenceLandingPage) {
    return axios.put(`/api/threat-defence/landing-pages/${data.id}`, data);
  },

  getThreatDefenceLandingPageAsync(id: number) {
    return axios.get(`/api/threat-defence/landing-pages/${id}`);
  },

  getThreatDefenceLandingPageContentUrlWithSasAsync: async (
    id: number,
    includeWritePermission: boolean = true,
  ): Promise<string> => {
    const contentResp = await axios.get<{ contentUrlWithSas: string }>(
      `/api/threat-defence/landing-pages/${id}/sas?includeWritePermission=${includeWritePermission}`,
    );

    return contentResp.data?.contentUrlWithSas;
  },
};

export default instance;
