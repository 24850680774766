import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { type PackUserLicenseView } from "../../types/state";
import { type PayloadAction } from "../../../../../interfaces/redux";

export interface PackUserLicensesState extends FetchingItemsState<PackUserLicenseView> {
  searchTerm: string;
}

const initialState: PackUserLicensesState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
  searchTerm: "",
};

const packUserLicensesSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.PackUserLicenses,
    name: "packUserLicenses",
  },
  initialState: initialState,
  reducers: {
    setSearchTerm: (state: PackUserLicensesState, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    resetSearchTerm: (state: PackUserLicensesState) => {
      state.searchTerm = "";
    },
  },
});

export const { fetchBegin, fetchSuccess, fetchFailure, setSearchTerm, resetSearchTerm } = packUserLicensesSlice.actions;

export default packUserLicensesSlice.reducer;
export type packUserLicenseSliceType = ReturnType<typeof packUserLicensesSlice.reducer>;
