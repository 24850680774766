import { type ClosedCaptions } from "../../../../../../features/Library/Videos/types/models";
import utils from "./languageUtils";

export interface TextTrackOptions {
  src: string;
  kind: string;
  label: string | undefined;
  srclang: string;
}

const vjsLanguageUtils = {
  allLanguagesRemoteTextTracks: (captions: ClosedCaptions[]): TextTrackOptions[] => {
    return captions.map((caption) => ({
      src: caption.url,
      kind: "captions",
      label: utils.getLanguageByCode(caption.isoCode)?.name,
      srclang: caption.isoCode,
    }));
  },

  remoteTextTrack: (textTrack: TextTrackOptions) => ({
    langObject: {
      displayName: textTrack.label,
      code: textTrack.srclang,
    },
    textTrack,
  }),
};

export default vjsLanguageUtils;
