import { type FC, useEffect } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import { type FormikProps, withFormik } from "formik";
import Tooltip from "components/common/tooltip/Tooltip";
import * as Yup from "yup";

import ValidatedFormWithoutFormik from "../../forms/ValidatedFormWithoutFormik";
import {
  previewText as previewTextField,
  subject as subjectField,
  senderName as senderNameField,
  senderEmail as senderEmailField,
} from "../../../utils/validationSchemas/emailsValidationSchema";

import "./emailNotificationForm.scss";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface FormValues {
  subject: string;
  previewText: string;
  customizeSender: boolean;
  senderName: string;
  senderEmail: string;
  defaultSenderName: string;
  defaultSenderEmail: string;
  smtpSettingsExist: boolean;
}

export type FormChangeEvent = {
  subject: string;
  previewText: string;
  customizeSender: boolean;
  senderName: string;
  senderEmail: string;
  defaultSenderName: string;
  defaultSenderEmail: string;
  smtpSettingsExist: boolean;
};

export interface FormChangeListener {
  onChange(event: FormChangeEvent): void;
  onDirtyChange?: (dirty: boolean) => void;
}

export interface EmailNotificationFormProps extends FormChangeListener {
  disabled: boolean;
  subject: string;
  previewText: string;
  customizeSender: boolean;
  senderName: string;
  senderEmail: string;
  defaultSenderName: string;
  defaultSenderEmail: string;
  smtpSettingsExist: boolean;
  onValidChange(isValid: boolean): void;
}

export const EmailNotificationForm: FC<FormikProps<FormValues> & EmailNotificationFormProps> = ({
  validateField,
  onValidChange,
  onChange,
  isValid,
  values,
  handleChange,
  disabled,
  dirty,
  setFieldValue,
  onDirtyChange,
  setFieldTouched,
  ...props
}) => {
  const {
    subject,
    previewText,
    customizeSender,
    senderName,
    senderEmail,
    defaultSenderName,
    defaultSenderEmail,
    smtpSettingsExist,
  } = values;

  const smtpSettingsFeature = useFeatureFlag(FeatureFlags.SmtpDirectSend);

  useEffect(() => {
    onChange({
      subject,
      previewText,
      customizeSender,
      senderName,
      senderEmail,
      defaultSenderName,
      defaultSenderEmail,
      smtpSettingsExist,
    });

    if (smtpSettingsExist) {
      setTimeout(() => setFieldTouched("senderName", true, true));
      setTimeout(() => setFieldTouched("senderEmail", true, true));
    }
  }, [
    subject,
    previewText,
    customizeSender,
    senderName,
    senderEmail,
    defaultSenderName,
    defaultSenderEmail,
    smtpSettingsExist,
    onChange,
    setFieldTouched,
  ]);

  useEffect(() => {
    if (disabled) {
      onValidChange(true);
    } else {
      validateField("subject");
      if (customizeSender) {
        validateField("senderName");
        validateField("senderEmail");
      }

      onValidChange(isValid);
    }
  }, [disabled, onValidChange, validateField, isValid, customizeSender]);

  return (
    <ValidatedFormWithoutFormik className="notify-settings-form" disablePreventTransitionPrompt formWidthComputer={16}>
      {smtpSettingsFeature && smtpSettingsExist && (
        <>
          <Header>Sender Information</Header>
          <Grid className="grid-form">
            <Grid.Column className="grid-customize-sender">
              <ValidatedFormWithoutFormik.CheckboxField
                toggle
                label={"Customize Sender"}
                propertyName={"customizeSender"}
                handleChange={handleChange}
                value={values.customizeSender}
                {...props}
              />
              <Tooltip
                target={<Icon className="info circle" />}
                content={"Ensure that sender rewriting is configured on your SMTP server to enable this feature."}
                position="top center"
                styles={{ width: `${20}rem` }}
                hideOnScroll
                showAlways
              />
            </Grid.Column>
          </Grid>
          <Grid className="grid-form">
            <Grid.Column className="grid-sender-name">
              <ValidatedFormWithoutFormik.InputField
                markAsRequired
                propertyName={"senderName"}
                label="Sender Name"
                placeholder="Enter Sender Name"
                value={values.senderName}
                handleChange={handleChange}
                disabled={disabled || !values.customizeSender}
                errorPosition={"bottom"}
                {...props}
              />
            </Grid.Column>
            <Grid.Column className="grid-sender-email">
              <ValidatedFormWithoutFormik.InputField
                markAsRequired
                propertyName={"senderEmail"}
                label="Sender Email"
                placeholder="Enter Sender Email"
                value={values.senderEmail}
                handleChange={handleChange}
                disabled={disabled || !values.customizeSender}
                errorPosition={"bottom"}
                {...props}
              />
            </Grid.Column>
          </Grid>
        </>
      )}

      <Header>Content Summary</Header>
      <Grid className="grid-form">
        <Grid.Column className="grid-subject">
          <ValidatedFormWithoutFormik.InputField
            markAsRequired
            propertyName="subject"
            label="Subject"
            placeholder="Enter subject"
            value={values.subject}
            handleChange={handleChange}
            disabled={disabled}
            errorPosition={"bottom"}
            {...props}
            setFieldValue={(_e, data) => {
              setFieldValue("subject", data.value);
              onDirtyChange?.(true);
            }}
          />
        </Grid.Column>
        <Grid.Column className="grid-preview-text">
          <ValidatedFormWithoutFormik.InputField
            propertyName="previewText"
            label="Preview Text"
            placeholder="Enter preview text"
            tooltip={{
              info: "Enter email preview text next to the subject for recipients",
              width: 17,
            }}
            value={values.previewText}
            handleChange={handleChange}
            disabled={disabled}
            errorPosition={"bottom"}
            {...props}
            setFieldValue={(_e, data) => {
              setFieldValue("previewText", data.value);
              onDirtyChange?.(true);
            }}
          />
        </Grid.Column>
      </Grid>
    </ValidatedFormWithoutFormik>
  );
};

const validationSchemaWithSender = Yup.object().shape({
  subject: subjectField(),
  previewText: previewTextField(),
  senderName: senderNameField(),
  senderEmail: senderEmailField(),
});

const validationSchema = Yup.object().shape({
  subject: subjectField(),
  previewText: previewTextField(),
});

const ConnectedComponent = withFormik<EmailNotificationFormProps, FormValues>({
  validationSchema: (props: EmailNotificationFormProps) => {
    return props.smtpSettingsExist ? validationSchemaWithSender : validationSchema;
  },
  mapPropsToValues: ({
    subject,
    previewText,
    customizeSender,
    senderName,
    senderEmail,
    defaultSenderName,
    defaultSenderEmail,
    smtpSettingsExist,
  }) => ({
    subject,
    previewText,
    customizeSender,
    senderName,
    senderEmail,
    defaultSenderName,
    defaultSenderEmail,
    smtpSettingsExist,
  }),
  enableReinitialize: true,
  handleSubmit: () => {}, // NOSONAR
})(EmailNotificationForm);

export default ConnectedComponent;
