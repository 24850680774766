import Strings from "../../../../../../enums/strings";
import { Table } from "semantic-ui-react";
import PermissionsList from "../../../../RoleList/Permissions/PermissionsList";
import RoleIcon from "../../../../RoleList/RoleRow/RoleIcon/RoleIcon";
import TitleSubtitle from "../../../../../../components/listViewTemplates/titleSubtitle/TitleSubtitle";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import MinusButton from "../../../../../../components/buttons/iconButtons/minusButton/MinusButton";
import Tooltip from "../../../../../../components/common/tooltip/Tooltip";
import type Role from "../../../../../../interfaces/role";
import { type ColumnOptions } from "../../../../../../interfaces";
import { RouteNames } from "enums";

import "../../../../RoleList/RoleRow/roleRow.scss";

const formatUsersCountLabel = (usersCount: number | null) => {
  if (usersCount === null) {
    return Strings.notAvailable;
  }

  return usersCount;
};

export interface UserRoleRowProps {
  role: Role;
  isSelected: boolean;
  onRemoveMemberClick: (id: number) => void;
  isDisabled?: boolean;
}

const columnOptions: ColumnOptions[] = [
  {
    name: " ",
    width: 1,
    isSortable: false,
  },
  {
    name: "Name",
    width: 5,
    isSortable: true,
  },
  {
    name: "People",
    width: 2,
    isSortable: false,
  },
  {
    name: "Permissions",
    width: 4,
    isSortable: false,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

const UserRoleRow = (props: UserRoleRowProps) => {
  const removeRoleUser = () => {
    props.onRemoveMemberClick && props.onRemoveMemberClick(props.role.id);
  };

  return (
    <>
      <Table.Cell className="role-icons" width={columnOptions[0].width}>
        <RoleIcon isDefaultRole={props.role.isDefault} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <TitleSubtitle
          url={`/${RouteNames.peopleRoles}/${props.role.id}`}
          title={props.role.name}
          subtitle={props.role.description}
          subtitleClamp={2}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>{formatUsersCountLabel(props.role.usersCount)}</Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <PermissionsList areas={props.role.areas} />
      </Table.Cell>
      <Table.Cell width={columnOptions[4].width}>{dateTimeUtils.formatDate(props.role.added)}</Table.Cell>
      <Table.Cell className="align-right-user-role" width={columnOptions[5].width}>
        <Tooltip
          position="top center"
          hideOnScroll
          showAlways
          target={<MinusButton onClick={removeRoleUser} isDisabled={props.isDisabled || props.isSelected} />}
          content="Remove"
        />
      </Table.Cell>
    </>
  );
};

export default UserRoleRow;
