import React, { type RefObject } from "react";
import { Popup } from "semantic-ui-react";

export type PropmtPropType = {
  text: string;
  open: boolean;
  context: RefObject<HTMLElement>;
};

export default function ValidationErrorPromptExperimental(props: PropmtPropType) {
  return <Popup content={props.text} open={props.open} context={props.context} position="left center" />;
}
