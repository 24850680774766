import { videoAssetsMapping } from "./videoAssetsMapping";
import { peopleMapping } from "./peopleMapping";
import { accountsMapping } from "./accountsMapping";
import { emailsMapping } from "./emailsMapping";
import { emailTemplatesMapping } from "./emailTemplatesMapping";
import { landingPagesMapping } from "./landingPagesMapping";
import { sendingProfilesMapping } from "./sendingProfilesMapping";
import { smtpAuthSendingProfilesMapping } from "./smtpAuthSendingProfilesMapping";
import { assessmentsMapping } from "./assessmentsMapping";
import { flowMapping } from "./flowMapping";
import { surveysMapping } from "./surveysMapping";
import { eventsMapping } from "./eventsMapping";
import { packsMapping } from "./packsMapping";
import { campaignMapping } from "./campaignMapping";
import { feedbackPagesMapping } from "./feedbackPagesMapping";
import { pdfsMapping } from "./pdfsMapping";
import { messagesMapping } from "./messagesMapping";
import { templateMapping } from "./templateMapping";

export default function createMapping(dispatch) {
  const mapping = {};

  videoAssetsMapping(mapping, dispatch);
  peopleMapping(mapping, dispatch);
  accountsMapping(mapping, dispatch);
  emailsMapping(mapping, dispatch);
  emailTemplatesMapping(mapping, dispatch);
  landingPagesMapping(mapping, dispatch);
  sendingProfilesMapping(mapping, dispatch);
  smtpAuthSendingProfilesMapping(mapping, dispatch);
  assessmentsMapping(mapping, dispatch);
  eventsMapping(mapping, dispatch);
  flowMapping(mapping, dispatch);
  surveysMapping(mapping, dispatch);
  packsMapping(mapping, dispatch);
  campaignMapping(mapping, dispatch);
  feedbackPagesMapping(mapping, dispatch);
  pdfsMapping(mapping, dispatch);
  messagesMapping(mapping, dispatch);
  templateMapping(mapping, dispatch);

  return mapping;
}
