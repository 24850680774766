import PackType from "../../../enums/licensing/packType";
import { type PackResourceItem } from "../../../features/Licensing/Packs/types/state";
import { Label, type Color } from "components/common/label/Label";

export interface PackTypeProps {
  packType: PackResourceItem;
}

export const PackTypeLabel: React.FC<PackTypeProps> = (props) => {
  if (!props.packType || !(props.packType.id in PackType)) {
    return null;
  }

  const { id, name } = props.packType;

  return <Label {...getLabelProps(id)} text={name.toUpperCase()} />;
};

const getLabelProps = (typeId: number): { color: Color; icon?: string } => {
  switch (typeId) {
    case PackType.Core:
      return { color: "black", icon: "fa-circle" };
    case PackType.Fundamental:
      return { color: "blue", icon: "fa-star" };
    case PackType.Premium:
      return { color: "yellow", icon: "fa-diamond" };
    case PackType.Starter:
      return { color: "green", icon: "fa-triangle" };
    default:
      return { color: "black" };
  }
};
