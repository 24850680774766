import { Icon } from "semantic-ui-react";
import { type IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import cn from "classnames";

import "./addButton.scss";

export interface AddButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  label?: string;
  link?: boolean;
  size?: IconSizeProp;
}

export const AddButton = (props: AddButtonProps) => {
  const { onClick, disabled, className, label, link, size = "large" } = props;
  return (
    <div className={cn("add-icon-button", { disabled }, className, { link })} onClick={onClick}>
      <Icon aria-label="add-button" className={"plus circle"} size={size} color={"blue"} />
      <div className="add-icon-button__label">{label}</div>
    </div>
  );
};
