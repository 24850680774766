import TaskStatuses from "../../../../../enums/taskStatuses";
import TaskTypes from "../../../../../enums/taskTypes";

export default class ImportUsersTasks {
  static InProgress = (id: string, title: string) => {
    return {
      id,
      title,
      status: TaskStatuses.inProgress,
      type: TaskTypes.fileUpload,
      label: "Uploading...",
      percent: 0,
      canClose: true,
      canCancel: true,
      indeterminate: true,
      canClickOnFailure: true,
    };
  };
  static Success = (id: string) => {
    return {
      id: id,
      status: TaskStatuses.successful,
      label: "File upload succeeded!",
      percent: 100,
      indeterminate: false,
    };
  };
  static Failure = (id: string, fileUri: string) => {
    return {
      id,
      fileUri,
      title: "Download file to see errors",
      status: TaskStatuses.failed,
      errorLabel: "Users import failed!",
      percent: 100,
      indeterminate: false,
    };
  };
  static FailureWithoutFile = (id: string) => {
    return {
      id,
      status: TaskStatuses.failed,
      errorLabel: "Cannot parse file",
      canClickOnFailure: false,
      percent: 100,
      indeterminate: false,
    };
  };
}
