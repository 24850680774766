import { type Dispatch } from "redux";
import * as userCommonActionTypes from "./userCommonActionTypes";
import dataService from "../../../Application/services/dataServices/dataService";
import { makeActionCreator } from "../../../Application/actions/actionsBuilder";

export const fetchCountries = () => {
  const fetchCountriesBegin = makeActionCreator(userCommonActionTypes.FETCH_COUNTRIES.FETCH_ITEMS_BEGIN);

  const fetchCountriesSuccess = makeActionCreator(
    userCommonActionTypes.FETCH_COUNTRIES.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  );

  const fetchCountriesFailure = makeActionCreator(userCommonActionTypes.FETCH_COUNTRIES.FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Dispatch) => {
    dispatch(fetchCountriesBegin());

    try {
      const result = await dataService.getCountries();
      dispatch(fetchCountriesSuccess(result.data));
    } catch (error) {
      dispatch(fetchCountriesFailure(error));
    }
  };
};
