import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../../Application/slices/models";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

export interface CancelingSendState {
  canceled: boolean;
  canceling: boolean;
  error?: string;
}

export const emailCancelSendInitialState: CancelingSendState = {
  canceled: false,
  canceling: false,
  error: undefined,
};

const emailCancelSendSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Emails,
    name: "cancelSend",
  }),
  initialState: emailCancelSendInitialState,
  reducers: {
    cancelBegin(state: CancelingSendState) {
      state.canceling = true;
    },
    cancelSuccess(state: CancelingSendState) {
      state.canceled = true;
      state.canceling = false;
    },
    cancelFailure(state: CancelingSendState, action: PayloadAction<string>) {
      state.canceling = false;
      state.error = action.payload;
    },
    reset() {
      return { ...emailCancelSendInitialState };
    },
  },
});

export const { cancelBegin, cancelSuccess, cancelFailure, reset } = emailCancelSendSlice.actions;

export const emailCancelSendReducer = emailCancelSendSlice.reducer;
