import { type FC } from "react";
import { Grid } from "semantic-ui-react";

import { type PackRequestModalWithFormProps } from "./types";
import { ContactNameField, ContactPhoneField, LicensesCountField } from "./fields";

export const RequestPackForm: FC<PackRequestModalWithFormProps> = (props) => {
  return (
    <div className="pack-request-container">
      <Grid stackable rows="equal" verticalAlign="bottom">
        <LicensesCountField {...props} />
        <ContactNameField {...props} />
        <ContactPhoneField {...props} />
      </Grid>
    </div>
  );
};

export default RequestPackForm;
