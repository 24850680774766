import { connect } from "react-redux";

import { bindAction } from "../../../../../interfaces/redux";
import { addFlowTags, fetchFlowTags } from "../../../../../features/Library/Flows/state/thunks/flowBaseThunk";
import { getMapTagsSelector } from "../../../../../features/Application/selectors/tagSelectors";
import { type AppDispatch, type RootState } from "../../../../../features/Application/globaltypes/redux";
import TagsManage from "../../../../../features/Library/Common/TagsManage/TagsManage";

const getTags = (state: RootState) => state.library.flows.base.flowBase.tags;

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: getMapTagsSelector(getTags)(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchFlowTags, dispatch),
  onConfirmAction: bindAction(addFlowTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
