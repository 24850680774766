import * as actionTypes from "./sidebarActionTypes";

export function autoCollapseSidebar() {
  return {
    type: actionTypes.AUTO_COLLAPSE_SIDEBAR,
  };
}

export const collapseSidebarByUser = () => {
  return {
    type: actionTypes.COLLAPSE_SIDEBAR_BY_USER,
  };
};

export const expandSidebar = () => {
  return {
    type: actionTypes.EXPAND_SIDEBAR,
  };
};
