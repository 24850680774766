import { FilterType, type FilterTypesMap, type GenericFiltersMap, type RangeFilterValues } from "./filterUtils";
import dateTimeUtils from "./dateTimeUtils";
import ObjectUtils from "./objectUtils";

export const formatFilters = <T extends string | number>(
  filters: GenericFiltersMap<T>,
  filterOptions: FilterTypesMap<T>,
): Record<string | number, any> => {
  let queryParams = {} as Record<string | number, any>;
  ObjectUtils.typedEntries<T, any>(filters).forEach(([key, value]) => {
    switch (filterOptions[key]) {
      case FilterType.DateRange:
        const date = value as RangeFilterValues;
        date.from && (queryParams[`${key}From`] = dateTimeUtils.formatDateForServer(date.from, "from"));
        date.to && (queryParams[`${key}To`] = dateTimeUtils.formatDateForServer(date.to, "to"));
        break;
      case FilterType.Array:
      case FilterType.Value:
      default:
        queryParams[key] = value;
    }
  });

  return queryParams;
};
