import { type FC, type ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./infoBanner.scss";

export interface InfoBannerProps {
  className?: string;
  children: ReactNode;
}

export const InfoBanner: FC<InfoBannerProps> = ({ children, className }) => (
  <div className={cn("info-banner-root", className)}>
    <Icon className="info circle" />
    <div>{children}</div>
  </div>
);

export default InfoBanner;
