import { type StripoInitOpts } from "../../../lib/stripo";
import defaultMergeTags from "../config/globalMergeTags.json";
import defaultLinks from "../config/globalLinks.json";

export const STRIPO_DEFAULT_SETTINGS_ID = "stripo-settings-js";
export const STRIPO_DEFAULT_PREVIEW_ID = "stripo-preview-js";
export const STRIPO_DEFAULT_CODE_EDITOR_BTN_ID = "code-editor-js";
export const STRIPO_DEFAULT_ACCOUNT_ID = 0;

export function createEmailEditorOptions(opts: StripoInitOpts): StripoInitOpts {
  const newOpts = { ...opts };
  const contextTags = newOpts.mergeTags ?? [];
  const contextLinks = newOpts.specialLinks ?? [];

  newOpts.accountId = newOpts.accountId || STRIPO_DEFAULT_ACCOUNT_ID;
  newOpts.settingsId = newOpts.settingsId || STRIPO_DEFAULT_SETTINGS_ID;
  newOpts.previewId = newOpts.previewId || STRIPO_DEFAULT_PREVIEW_ID;
  newOpts.codeEditorButtonId = newOpts.codeEditorButtonId || STRIPO_DEFAULT_CODE_EDITOR_BTN_ID;

  newOpts.mergeTags = [...defaultMergeTags, ...contextTags];
  newOpts.specialLinks = [...defaultLinks, ...contextLinks];

  return newOpts;
}
