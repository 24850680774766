import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

const packActionBuilder = (type: string) => {
  return buildRootEntityActionType(ReducerNamespaceTypes.Licensing, ReducerEntityPrefixTypes.Packs, type);
};

export const createPackBegin = packActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN);

export const createPackSuccess = packActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS);

export const createPackFailure = packActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE);

export const updatePackBegin = packActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN);

export const updatePackCommandSuccess = packActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS);

export const updatePackFailure = packActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE);

export const setPackEntityId = packActionBuilder(entityStateActionTypes.SET_ENTITY_ID);

export const packUpdated = packActionBuilder(entityStateActionTypes.LOCKED_ENTITY_UPDATED);

export const resetPackEntityState = packActionBuilder(entityStateActionTypes.RESET_ENTITY_STATE);

export const publishPackBegin = packActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);

export const publishPackSuccess = packActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS);

export const publishPackFailure = packActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE);

export const fetchLockedPackBegin = packActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN);

export const fetchLockedPackSuccess = packActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS);

export const fetchLockedPackFailure = packActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE);

export const fetchDiscardPackBegin = packActionBuilder(entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN);

export const fetchDiscardPackSuccess = packActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const fetchDiscardPackFailure = packActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
