import { type FormikProps } from "formik";
import React, { useEffect } from "react";
import { connect, type ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { type AppDispatch, type RootState } from "../../../../../../Application/globaltypes/redux";
import { ValidatedForm } from "../../../../../../../components";
import { type ThreatDefenceSmtpAuthSendingProfileSettingsView } from "../../types/state";
import { type SettingsFormProps } from "../types";
import {
  sendingProfileSettingsValidationSelector,
  setIsValid,
  setInitialized,
} from "../../state/slices/sendingProfileSettingsValidationSlice";
import * as validationActions from "../../state/thunks/sendingProfileSettingsValidationThunk";
import ObjectUtils from "../../../../../../../utils/objectUtils";

export type SettingsFormPropsAll = FormikProps<ThreatDefenceSmtpAuthSendingProfileSettingsView> &
  SettingsFormProps &
  PropsFromRedux;

export const SettingsForm: React.FC<SettingsFormPropsAll> = (props: SettingsFormPropsAll) => {
  const { errors, activeErrors, validateActions, isFormValid, resolved, initialized } = props;
  const { setActiveErrorsAction, checkForResolvedErrors } = validateActions;

  useEffect(() => {
    return () => {
      props.setInitialized(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!resolved && initialized) {
      setActiveErrorsAction(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved, initialized]);

  useEffect(() => {
    const isEmptyErrors = ObjectUtils.isEmptyDeep(errors);

    if (isEmptyErrors !== isFormValid) {
      props.setIsValid(isEmptyErrors);
      setActiveErrorsAction(errors);
    }

    if (!resolved && ObjectUtils.isEmptyDeep(activeErrors) && !isEmptyErrors) {
      setActiveErrorsAction(errors);
    }

    if (!resolved && initialized) {
      checkForResolvedErrors(errors);
    }

    if (!initialized) {
      props.setInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const getValidateFieldProps = (): any => {
    const { touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } = props;
    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };

  const renderFields = (): any => {
    const { values, disabled, onBlur, save, onIsValidChange } = props;
    const validatedFieldProps = getValidateFieldProps();

    return (
      <div className="smtp-sending-profile-settings-form-container">
        <ValidatedForm.InputField
          label="Host"
          value={values.host}
          onIsFormValidChange={onIsValidChange}
          propertyName="host"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Port"
          value={values.port}
          onIsFormValidChange={onIsValidChange}
          propertyName="port"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          toggle
          label="Use SSL"
          value={values.isSsl}
          propertyName="isSsl"
          handleChange={save}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          label="Username"
          value={values.userName}
          onIsFormValidChange={onIsValidChange}
          propertyName="userName"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.PasswordField
          label="Password"
          value={values.password}
          onIsFormValidChange={onIsValidChange}
          propertyName="password"
          markAsRequired
          handleBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  const { isLoading } = props;
  return (
    <ValidatedForm dirty={props.dirty} disablePreventTransitionPrompt isLoading={isLoading} formWidthComputer={6}>
      {renderFields()}
    </ValidatedForm>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    ...sendingProfileSettingsValidationSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  validateActions: bindActionCreators(validationActions, dispatch),
  setIsValid: (payload: boolean) => dispatch(setIsValid(payload)),
  setInitialized: (payload: boolean) => dispatch(setInitialized(payload)),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedComponent = connector(SettingsForm);
export default ConnectedComponent;
