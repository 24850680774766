import { type AppDispatch, type RootState } from "features/Application/globaltypes/redux";
import { connect, type ConnectedProps } from "react-redux";
import { GroupTemplatePacks } from "../Details/Packs/GroupTemplatePacks";
import { type SortingDirection } from "enums";
import { getPacks } from "../state/thunks/TemplatePacksThunk";
import { bindAction } from "interfaces";
import { noop } from "lodash";

type Props = PropsFromRedux & {
  templateId: number;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  onRemovePackClick: (id: number) => void;
  disabled?: boolean;
};

export const EditTemplatePacks = (props: Props) => {
  const {
    templateId,
    items,
    itemsCount,
    isLoading,
    getData,
    selectedIds,
    setSelectedIds,
    onRemovePackClick,
    disabled,
  } = props;
  const loadPacks = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    getData(templateId, skip, top, sortingColumnName, sortingDirection);
  };

  return (
    <GroupTemplatePacks
      templateId={templateId}
      items={items}
      itemsCount={itemsCount}
      isLoading={isLoading}
      getGridData={noop}
      getListData={loadPacks}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onRemovePackClick={onRemovePackClick}
      isCreate={false}
      disabled={disabled}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.groupTemplate.packs.items,
    itemsCount: state.people.groupTemplate.packs.itemsCount,
    isLoading: state.people.groupTemplate.packs.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getData: bindAction(getPacks, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditTemplatePacks);
export default ConnectedComponent;
