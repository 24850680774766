import type ColumnOption from "../../../../../interfaces/columnOptions";

const columnOptions: Array<ColumnOption> = [
  {
    name: "Name",
    width: 4,
    isSortable: true,
  },
  {
    name: "Status",
    width: 4,
    isSortable: false,
  },
  {
    name: "Modified",
    width: 4,
    isSortable: true,
  },
  {
    name: "",
    width: 2,
    isSortable: false,
  },
];

export default columnOptions;
