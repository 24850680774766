import * as events from "../events/library/libraryEvents";
import {
  updateFeedbackPageCommandSuccess,
  feedbackPageUpdated,
  publishDraftFeedbackPageSuccess,
  fetchLockedFeedbackPageSuccess,
} from "../../../../Library/SimulatedPhishing/FeedbackPages/state/actions/FeedbackPageEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const feedbackPagesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.FeedbackPageEditSuccess] = dispatch(feedbackPageUpdated);
  mapping[events.FeedbackPageUpdateCommandCompleted] = dispatch(updateFeedbackPageCommandSuccess);
  mapping[events.FeedbackPagePublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftFeedbackPageSuccess,
    (data) => `${data.name} was published.`,
  );

  mapping[events.FeedbackPageLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedFeedbackPageSuccess,
    (message) => ({ entityId: message.id }),
  );
};
