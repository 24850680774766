import React, { useEffect, useState } from "react";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";
import environmentConfig from "./configuration/environmentConfig";
import { type RootState } from "./features/Application/globaltypes/redux";
import { type UserProfileState } from "features/Application/slices/userProfileSlice";
import { Loader } from "semantic-ui-react";

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: state.userProfile,
  };
};

interface WrappedLDProviderProps {
  userProfile: UserProfileState;
}

interface LdProviderProps extends JSX.IntrinsicAttributes {
  children?: React.ReactNode;
}

const TrackIdentity = (props: { children?: React.ReactNode } & WrappedLDProviderProps) => {
  const { userProfile } = props;
  const [initialized, setInitialized] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    if (userProfile.isUserProfileLoaded) {
      const { id, accountId, accountTypeId, originAccountId, department } = userProfile;
      ldClient?.identify(
        {
          key: id.toString(),
          custom: {
            accountId,
            accountTypeId,
            originAccountId,
            ...(department && { department }),
          },
        },
        undefined,
        () => setInitialized(true),
      );
    }
  }, [userProfile, ldClient]);

  return <>{initialized ? props.children : <Loader active />}</>;
};

const ConnectedComponent = withLDProvider<LdProviderProps>({
  clientSideID: environmentConfig.launchDarkly.clientId,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: environmentConfig.launchDarkly.defaultFlags,
})(connect(mapStateToProps)(TrackIdentity));
export default ConnectedComponent;
