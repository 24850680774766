import React, { type Dispatch, type SetStateAction } from "react";
import type { ElementAndPosition, EntityType, FlowEndInfo, FlowSectionHeader } from "../../../types";
import type { FlowValidationError } from "../../../validator/flowValidatorReducer";

interface ReactFlowCanvasContextActionsProps {
  setHeadId: Dispatch<SetStateAction<string | undefined>>;
  save: () => void;
  removeNode: (id: string) => void;
  setShowTriggers: (flag: boolean) => void;
  setSectionHeaderView: (id: string | undefined) => void;
  setFlowEndView: (id: string | undefined) => void;
  setItemToReplace: (element?: ElementAndPosition) => void;
  setItemForSectionHeader: (element?: ElementAndPosition) => void;
  setElementInvalid: (id: string, error?: FlowValidationError) => void;
  setSectionHeader: (id: string, sectionHeader: FlowSectionHeader) => void;
  removeSectionHeader: (id: string) => void;
  setFlowEnd: (id: string, flowEndInfo: FlowEndInfo) => void;
  setDraggedItemFromInspector: (type: EntityType) => void;
}

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};

export const ReactFlowCanvasContextActions = React.createContext<ReactFlowCanvasContextActionsProps>({
  setHeadId: defaultAction("setHeadId"),
  removeNode: defaultAction("removeNode"),
  setItemToReplace: defaultAction("setItemToReplace"),
  setItemForSectionHeader: defaultAction("setItemForSectionHeader"),
  save: defaultAction("save"),
  setShowTriggers: defaultAction("setShowTriggers"),
  setSectionHeaderView: defaultAction("setSectionHeaderView"),
  setFlowEndView: defaultAction("setFlowEndView"),
  setElementInvalid: defaultAction("setElementInvalid"),
  setSectionHeader: defaultAction("setSectionHeader"),
  removeSectionHeader: defaultAction("removeSectionHeader"),
  setFlowEnd: defaultAction("setFlowEnd"),
  setDraggedItemFromInspector: defaultAction("setDraggedItemFromInspector"),
});
