import { useEffect, useRef } from "react";

/**
 * `useLoadedItemsCountValue` indicates how many items are loaded in the state.
 * The return value is used to determine how many items can be skipped during
 * a pack load request.
 */
export function useLoadedItemsCount(loadedItemsCount: number) {
  const loadedItemsCountRef = useRef<number>(0);

  useEffect(() => {
    loadedItemsCountRef.current = loadedItemsCount;
  }, [loadedItemsCount, loadedItemsCountRef]);

  return loadedItemsCountRef;
}
