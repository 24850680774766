import React from "react";

import { baseIconButtonPropTypes } from "../baseIconButtonPropTypes";
import BaseIconButton from "../BaseIconButton";

function EditButton(props) {
  return <BaseIconButton iconClassName="edit" isOutlineIcon={false} {...props} />;
}

EditButton.propTypes = baseIconButtonPropTypes;

export default EditButton;
