import { type FC, useEffect, useState } from "react";
import { connect, type ConnectedProps } from "react-redux";

import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import EditFlowPeopleAssignment from "./Assignments/Modal/EditFlowPeopleAssignment";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";
import RemovePriorityConfirmationModal from "../../../PeopleAssignments/RemovePeopleConfirmationModal/RemovePriorityConfirmationModal";

import { RolePermissions, type UsersGroupsContext } from "../../../../../enums";
import { useObserver } from "../../../../../hooks/useObserver";
import { ContentType, type PeopleAssignment, PeopleType } from "../../../PeopleAssignments/types";
import { type AppDispatch, type RootState } from "../../../../Application/globaltypes/redux";
import { selectedItemsChanged as changeSelectedUserIds } from "../../state/slices/flowUsersSlice";
import { selectedItemsChanged as changeSelectedGroupIds } from "../../state/slices/flowGroupsSlice";
import { editPriority, removePeoplePrioritiesAction } from "../../state/actions/flowPeopleAssignmentsActions";
import { flowInformationSelector, flowsStateSelector } from "../../state/selectors";
import {
  type ContentPriorityLevel,
  type OnChangePriorityObserver,
  bindAction,
  type IObservable,
  type IWizardStep,
} from "../../../../../interfaces";
import ChangeContentPeoplePriorityModal from "../../../../../components/assignmentModals/changePriorityModal/ChangeContentPeoplePriorityModal";
import {
  getUpdatedPriorityItems,
  notifyChangePriorityObserver,
} from "../../../PeopleAssignments/utils/peopleAssignmentUtils";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";

export interface EditFlowPeoplePropsBase extends IWizardStep {
  flowId: number;
  title: string;
  thumbnailUrl: string;
  addPeopleClickObserver: IObservable<() => void>;
  removePeopleClickObserver: IObservable<
    (onRemoveConfirm: () => void, people: { [key in PeopleType]?: number[] }) => void
  >;
  onChangePriorityObserver: OnChangePriorityObserver;
  usersGroupsContext: UsersGroupsContext;
  setUsersGroupsContext: (viewType: UsersGroupsContext) => void;
}

export type EditFlowPeopleProps = EditFlowPeoplePropsBase & PropsFromRedux;

export const EditFlowPeople: FC<EditFlowPeopleProps> = ({
  flowId,
  title,
  thumbnailUrl,
  addPeopleClickObserver,
  removePeopleClickObserver,
  onChangePriorityObserver,
  selectedUserIds,
  selectedGroupIds,
  removePeoplePriorities,
  editPriority,
  updateSelectedUserIds,
  updateSelectedGroupIds,
  flowInfo,
  acceptHandlers,
  usersGroupsContext,
  setUsersGroupsContext,
}) => {
  const [showModal, setShowModal] = useState(false);

  /* istanbul ignore next */
  const onAddPeopleButtonClick = () => setShowModal(true);
  const [subscribeOnAddPeopleClick] = useObserver(addPeopleClickObserver);
  const flexiblePriorityDueDate = useFeatureFlag(FeatureFlags.FlexiblePriorityDueDate);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return subscribeOnAddPeopleClick(onAddPeopleButtonClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnAddPeopleClick]);

  const onRemovePeople = (people: { [key in PeopleType]?: number[] }) => {
    removePeopleClickObserver.notify(() => {
      removePeoplePriorities([flowId], people);
      // to-do: Remove this workaround after ListItemViewBase fix. Should handle removal case.
      updateSelectedUserIds(selectedUserIds.filter((id) => !people.user?.includes(id)));
      updateSelectedGroupIds(selectedGroupIds.filter((id) => !people.group?.includes(id)));
    }, people);
  };

  const onEditPeoplePriority =
    (peopleType: PeopleType) => (assignments: { [key in PeopleType]?: PeopleAssignment[] }) => {
      notifyChangePriorityObserver(
        assignments,
        peopleType,
        onChangePriorityObserver,
        flexiblePriorityDueDate,
        thumbnailUrl,
      );
    };

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.AssetsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={setUsersGroupsContext}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview
            flowId={flowId}
            customHeaderContent={customHeaderContent}
            onRemove={onRemovePeople}
            onEditPriority={onEditPeoplePriority(PeopleType.Group)}
          />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview
            flowId={flowId}
            customHeaderContent={customHeaderContent}
            onRemove={onRemovePeople}
            onEditPriority={onEditPeoplePriority(PeopleType.User)}
          />
        )}
      />
      <EditFlowPeopleAssignment
        entityItems={[
          { id: flowId, title, thumbnailUrl, contentType: ContentType.Flow, canAutoStart: flowInfo.canAutoStart },
        ]}
        flowIds={[flowId]}
        usersGroupsContext={usersGroupsContext}
        showModal={showModal}
        onShowModalChanged={setShowModal}
        notifyStepDisabled={flowInfo.isDraft && !flowInfo.hasOrigin}
      />
      <RemovePriorityConfirmationModal
        onTriggerRemovePeopleObserver={removePeopleClickObserver}
        peopleType={usersGroupsContext}
      />
      <ChangeContentPeoplePriorityModal
        onSave={(assignments: { [key in PeopleType]?: ContentPriorityLevel[] }) => {
          getUpdatedPriorityItems(assignments, (items: ContentPriorityLevel[], peopleType: PeopleType) => {
            editPriority(items, peopleType, flowId, flexiblePriorityDueDate);
          });
        }}
        onTriggerModalObserver={onChangePriorityObserver}
        contentItem={{ title, thumbnailUrl }}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flows = flowsStateSelector(state);
  return {
    selectedUserIds: flows.edit.users.selectedIds,
    selectedGroupIds: flows.edit.groups.selectedIds,
    flowInfo: flowInformationSelector(state).info,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  removePeoplePriorities: bindAction(removePeoplePrioritiesAction, dispatch),
  editPriority: bindAction(editPriority, dispatch),
  updateSelectedUserIds: bindAction(changeSelectedUserIds, dispatch),
  updateSelectedGroupIds: bindAction(changeSelectedGroupIds, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(EditFlowPeople);
export default ConnectedComponent;
