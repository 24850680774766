import { ContentView } from "components";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { useFlowGoalsEngagementQuery, useFlowGoalsLineChartQuery } from "features/Reporting/queries/sharedQueries";
import { useEffect, useState } from "react";
import { FlowGoalsCharts } from "./FlowGoalsCharts";
import { type PerformanceFilter, noBarData } from "features/Library/Common/utils/performanceUtils";

import styles from "./flowGoals.module.scss";
import CardReporting from "components/cards/CardReporting/CardReporting";

export type FlowGoalsProps = {
  filter: PerformanceFilter;
};

export const FlowGoals = ({ filter }: FlowGoalsProps) => {
  const flowGoalsLineQuery = useFlowGoalsLineChartQuery();
  const flowGoalsEngagementQuery = useFlowGoalsEngagementQuery();
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (flowGoalsEngagementQuery.isSuccess && activeTab === undefined) {
      if (!noBarData(...flowGoalsEngagementQuery.data.barData["onboarding/transition"].map((d) => d.value))) {
        setActiveTab(0);
      } else if (!noBarData(...flowGoalsEngagementQuery.data.barData.remediation.map((d) => d.value))) {
        setActiveTab(1);
      } else {
        setActiveTab(2);
      }
    }
  }, [activeTab, flowGoalsEngagementQuery.isSuccess, flowGoalsEngagementQuery.data]);

  const contentViewTabs = [
    {
      id: "onboardingTransition",
      label: "Onboarding / Transition",
      component: <FlowGoalsCharts filter={filter} type="onboarding/transition" icon="faShuffle" />,
    },
    {
      id: "remediation",
      label: "Remediation",
      component: <FlowGoalsCharts filter={filter} type="remediation" icon="faRepeat" />,
    },
    {
      id: "optimization",
      label: "Optimization",
      component: <FlowGoalsCharts filter={filter} type="optimization" icon="faChartLineUp" />,
    },
  ];

  return (
    <>
      <div>
        <RequestStatusRenderer state={flowGoalsEngagementQuery.status}>
          {flowGoalsEngagementQuery.data && <CardReporting items={flowGoalsEngagementQuery.data.cardData} />}
        </RequestStatusRenderer>
      </div>
      {activeTab !== undefined && (
        <div className={styles.root}>
          <RequestStatusRenderer state={flowGoalsLineQuery.status}>
            <ContentView
              tabs={contentViewTabs}
              activeIndex={activeTab}
              onTabChange={(_, r: { activeIndex: number }) => {
                setActiveTab(r.activeIndex);
              }}
            />
          </RequestStatusRenderer>
        </div>
      )}
    </>
  );
};
