import { type Slice } from "@reduxjs/toolkit";
import { type AppDispatch } from "../../../../Application/globaltypes/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { type FiltersState } from "../../../../Application/slices/createFiltersSlice";
import type PacksFilters from "../../../../../enums/licensing/PacksFilters";
import { type FiltersMap } from "../../../../../utils/filterUtils";
import type AccountPacksFilters from "../../../../../enums/licensing/AccountPacksFilters";

export const getFilterOptions = (
  slice: Slice<FiltersState<AccountPacksFilters | PacksFilters>>,
  isAccountPacks: boolean = false,
) => {
  let filterOptions: FiltersMap = {};

  const setData = (result: any, propertyName: string) => {
    filterOptions = {
      ...filterOptions,
      [propertyName]: result.map((item: any) => {
        return { text: item.name, value: item.id };
      }),
    };
  };

  const { setIsLoading, setError, setFilterOptions } = slice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      if (!isAccountPacks) {
        const publishers = await dataService.packs.getPacksPublishersAsync();
        setData(publishers.data, "Publishers");
      }

      dispatch(setFilterOptions(filterOptions));
    } catch (error: any) {
      dispatch(setError(error as Error));
    }

    dispatch(setIsLoading(false));
  };
};
