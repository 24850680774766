import { Icon } from "semantic-ui-react";
import cn from "classnames";

import { type SurveyAsset } from "../../../interfaces/assetToDropInfo";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { SelectableAssetCard } from "../SelectableAssetCard/SelectableAssetCard";

import "./FlowDesignerSurveyAssetCard.scss";

export interface Props {
  id: SurveyAsset["id"];
  title: SurveyAsset["title"];
  branchingQuestion?: SurveyAsset["branchingQuestion"];
  className?: string;
  isSelected?: boolean;
  onClick?: () => void;
}

function FlowDesignerSurveyAssetCard({ title, id, branchingQuestion, className, ...props }: Props) {
  return (
    <SelectableAssetCard
      key={id}
      className={cn("flow-designer-survey-asset-card", className)}
      tabIndex="0"
      data-hook-type="flow-designer-survey-asset-card"
      data-hook-id={id}
      {...props}
    >
      <div className="thumbnail">
        <Icon className="list ul" size="big" />
      </div>
      <div className="type-block">
        {branchingQuestion && <Icon className="fa-code-branch" size="small" />}
        <span className="text">Survey</span>
      </div>
      <div className="title">
        <Tooltip target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
      </div>
    </SelectableAssetCard>
  );
}

export default FlowDesignerSurveyAssetCard;
