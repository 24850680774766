import midnightService from "../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/messageEntityStateActionTypes";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../../interfaces/redux";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import { type MessageContent, type MessageDetails, type MessageFormValues } from "../../types";
import {
  MessageContentEditSuccess,
  MessageRevertSuccess,
  MessageEditSuccess,
  MessagePublishSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { saveMessage, saveMessageContent } from "../slices/messageDetailsSlice";
import { type EntityStateProps } from "../../../../../../types";

export const fetchDraftMessageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedMessageBegin);
  const success = getActionProvider<MessageDetails>(actionTypes.fetchLockedMessageSuccess);
  const failure = getActionProvider<Error>(actionTypes.fetchLockedMessageFailure);
  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.getEntityLock(draftEntityTypes.Messages, id);
      const message = result.data as MessageDetails;
      dispatch(success(message));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const fetchLockedMessageSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedMessageSuccess,
);

export const create = (data: MessageFormValues) => {
  const begin = getActionBaseProvider(actionTypes.createMessageBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createMessageSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createMessageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.Messages, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateMessage = (metadata: MessageDetails) => {
  const begin = getActionBaseProvider(actionTypes.updateMessageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateMessageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id, action: MessageEditSuccess }));
    try {
      dispatch(saveMessage(metadata));
      await dataService.messages.updateMessage(metadata);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateMessageCommandSuccess = getActionProvider(
  actionTypes.updateMessageCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const messageUpdated = getActionProvider(actionTypes.messageUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const updateMessageContent = (data: MessageContent) => {
  const begin = getActionBaseProvider(actionTypes.updateMessageBegin);
  const success = saveMessageContent;
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateMessageFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: MessageContentEditSuccess }));
    try {
      await dataService.messages.updateMessageContent(data);
      dispatch(success(data));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftMessageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishMessageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishMessageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: MessagePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.Messages, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftMessageSuccess = getActionProvider<MidnightActionPayload>(actionTypes.publishMessageSuccess);

export const revertMessageEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.revertMessageBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.revertMessageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: MessageRevertSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.Messages, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const revertMessageSuccess = getActionProvider<MidnightActionPayload>(actionTypes.revertMessageSuccess);

export const resetMessageEntityState = getActionBaseProvider(actionTypes.resetMessageEntityState);

export const setDataForDetailsMode = getActionProvider<EntityStateProps>(actionTypes.setDataForDetailsMode);
