import * as filteringItemsActionTypes from "../../actions/filteringItems/filteringItemsActionTypes";
import { type Reducer } from "@reduxjs/toolkit";
import { type Filters } from "../../../../utils/queryUtils";

// __ delimeter beetwen namespace, reducer entityPrefix and corresponding action type
// example LIBRARY__FLOWS__SET_ITEMS_FILTER

export interface FilterAction {
  payload: { filter: Filters; error?: Error };
  type: string;
}

interface StateType {
  [key: string]: any;
}

const withFilteringItems =
  <T extends StateType = StateType>(
    namespace: string,
    entityPrefix: string,
    reducer?: Reducer<any, any>,
  ): Reducer<T, FilterAction> =>
  (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case `${namespace}__${entityPrefix}__${filteringItemsActionTypes.SET_ITEMS_FILTER}`:
        return { ...state, appliedFilter: payload.filter };
      case `${namespace}__${entityPrefix}__${filteringItemsActionTypes.RESET_ITEMS_FILTER}`:
        return { ...state, appliedFilter: {} };
      default:
        return reducer ? reducer(state, action) : { ...state };
    }
  };
export default withFilteringItems;
