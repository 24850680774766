import React from "react";
import { Icon } from "semantic-ui-react";
import RemoveLinkButton from "../removeLinkButton/RemoveLinkButton";
import { Tooltip } from "../../../common/tooltip";
import { Strings } from "../../../../enums";

export interface Props {
  isDisabled: boolean;
  showTooltip: boolean;
  onClick: () => void;
  multiple?: boolean;
  entityType?: string;
  peopleType?: string;
  className?: string;
}

export const RemoveAssignmentLinkButton: React.FC<Props> = (props) => {
  const { isDisabled, showTooltip, multiple, entityType, peopleType, onClick, className } = props;
  const message = multiple
    ? Strings.tooltipMessages.people.removeAssignmet.manyRemoveDisabled(entityType!)
    : Strings.tooltipMessages.people.removeAssignmet.oneRemoveDisabled(peopleType!);
  const position = multiple ? "left center" : "bottom left";

  return (
    <Tooltip
      showAlways={showTooltip}
      inverted
      hideOnScroll
      position={position}
      target={
        <RemoveLinkButton
          className={className}
          after={showTooltip && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={isDisabled}
          onClick={onClick}
        />
      }
      content={message}
    />
  );
};

export default RemoveAssignmentLinkButton;
