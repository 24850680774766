import { type Dispatch } from "@reduxjs/toolkit";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import groupsDataService from "features/People/services/groupsDataService";
import { type UpdateParams } from "interfaces";
import enrollmentRulesUtils from "utils/enrollmentRulesUtils";
import { isForbidden } from "utils/statusCodeUtils";
import {
  fetchBegin,
  fetchFailure,
  fetchForbidden,
  fetchSuccess,
  type GroupInfo,
  type GroupSettings,
  updateEnrollmentRulesSuccess,
  updateGroupInfo,
} from "../slices/editGroupInfoSlice";

export const updateGroupEnrollmentRules = (groupId: number, enrollmentRules: any[]) => async (dispatch: Dispatch) => {
  try {
    const enrollmentRulesInServerFormat = enrollmentRulesUtils.mapRulesToSeverFormat(enrollmentRules);
    await groupsDataService.updateGroupEnrollmentRules(groupId, enrollmentRulesInServerFormat);
    dispatch(updateEnrollmentRulesSuccess(enrollmentRules));
  } catch (error) {}
};

export const fetchGroupInfo = (groupId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchBegin());
    const groupInfo = await groupsDataService.getGroupInfo(groupId);
    dispatch(fetchSuccess(groupInfo.data));
  } catch (error) {
    isForbidden(error?.response?.status) ? dispatch(fetchForbidden(error)) : dispatch(fetchFailure(error));
  }
};

export const saveGroupInfo =
  (groupId: number, groupInfo: Partial<GroupInfo>, groupSettings: GroupSettings) => async (dispatch: Dispatch) => {
    try {
      const getMessageIds = async () => {
        const updateRequestPayload = {
          ...groupInfo,
          ...groupSettings,
        };

        const response = await groupsDataService.updateGroupInfo(groupId, updateRequestPayload);
        return [response.data.messageId];
      };

      const updateParams: UpdateParams = {
        getMessageIds: getMessageIds,
        indeterminate: true,
        id: "UpdateGroupInformation",
        title: "Update group information",
        successTransientMessage: "Group information update succeeded!",
        failureTransientMessage: "Group information update failed!",
      };

      await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
      dispatch(updateGroupInfo({ info: groupInfo, settings: groupSettings }));
    } catch (error) {}
  };
