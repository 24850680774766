export enum SurveyDetailsActionTypes {
  SaveSurvey = "SAVE_SURVEY",
  ClearSurvey = "CLEAR_SURVEY",
  SaveContent = "SAVE_SURVEY_CONTENT",

  GetSurveyBegin = "GET_SURVEY_BEGIN",
  GetSurveySuccess = "GET_SURVEY_SUCCESS",
  GetSurveyFailure = "GET_SURVEY_FAILURE",

  GetSurveyContentBegin = "GET_SURVEY_CONTENT_BEGIN",
  GetSurveyContentSuccess = "GET_SURVEY_CONTENT_SUCCESS",
  GetSurveyContentFailure = "GET_SURVEY_CONTENT_FAILURE",

  SetSurveyBaseInfo = "SET_SURVEY_BASE_INFO",

  SaveSettings = "SAVE_SETTINGS",
}

export default SurveyDetailsActionTypes;
