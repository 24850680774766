import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect, type ConnectedProps } from "react-redux";

import { type AppDispatch, type RootState } from "../../Application/globaltypes/redux";
import { fetchAccounts } from "./state/thunks/allAccountsOverviewThunk";
import AccountsOverview from "../AccountsOverview/AccountsOverview";
import * as notificationsActions from "../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import DeleteAccountTask from "../AccountsOverview/backgroundTasks/deleteAccountTask";
import backgroundTask from "../../BackgroundTasks/backgroundTask";

export type AccountOverviewProps = PropsFromRedux;

export class AllAccountsOverview extends Component<AccountOverviewProps> {
  deleteAccount = (accountId: number, accontName: string) => {
    const task = new DeleteAccountTask(accountId, accontName);
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(task, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  render() {
    const { onFetchAccounts, accounts, accountsTotalCount, isLoading, isMobo } = this.props;

    return (
      <AccountsOverview
        onFetchAccounts={onFetchAccounts}
        onDeleteAccount={this.deleteAccount}
        accounts={accounts}
        accountsTotalCount={accountsTotalCount}
        isLoading={isLoading}
        isMobo={isMobo}
      />
    );
  }
}
const mapStateToProps = (state: RootState) => {
  const { userProfile } = state;

  return {
    accounts: state.accounts.overview.items,
    accountsTotalCount: state.accounts.overview.itemsCount,
    isLoading: state.accounts.overview.isLoading,
    isMobo: userProfile.isMobo,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onFetchAccounts: bindActionCreators(fetchAccounts, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(AllAccountsOverview);
export default ConnectedComponent;
