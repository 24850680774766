import "./replacementOverlay.scss";
import { Icon } from "semantic-ui-react";

export const ReplacementOverlay: React.FC = () => {
  return (
    <div className="flow-designer-replacement-overlay with-opacity">
      <div className="icon-placeholder">
        <Icon className="sync alternate outline replacement-icon" />
      </div>
    </div>
  );
};
