import { type Action } from "@reduxjs/toolkit";
import { createReducer } from "../../../../utils/reduxUtils";
import * as fetchingItemsActionTypes from "../../actions/fetchingItems/fetchingItemsActionTypes";

const DELIMITER = "__";

export interface IFetchingItemsState<T extends object = {}> {
  items: T[];
  itemsCount: number;
  isLoading: boolean;
  error?: any;
}

export interface IFetchingItemsAction {
  payload?: IFetchingItemsState;
  type: string;
}

const initialState: IFetchingItemsState<object> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: null,
};

const createActionName = (params: string[]): string => params.filter(Boolean).join(DELIMITER);

const commonHandles = <TState extends IFetchingItemsState>(params: string[]) => ({
  [createActionName([...params, fetchingItemsActionTypes.FETCH_ITEMS_BEGIN])]: (state: TState) => ({
    ...state,
    isLoading: true,
    error: null,
  }),
  [createActionName([...params, fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS])]: (
    state: TState,
    action: IFetchingItemsAction,
  ) => ({
    ...state,
    isLoading: false,
    items: action.payload?.items,
    itemsCount: action.payload?.itemsCount,
  }),
  [createActionName([...params, fetchingItemsActionTypes.FETCH_ITEMS_FAILURE])]: (
    state: TState,
    action: IFetchingItemsAction,
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload?.error,
  }),
  [createActionName([...params, fetchingItemsActionTypes.UPDATE_ITEMS])]: (
    state: TState,
    action: IFetchingItemsAction,
  ) => ({
    ...state,
    items: action.payload?.items,
    itemsCount: action.payload?.itemsCount,
  }),
  [createActionName([...params, fetchingItemsActionTypes.RESET_ITEMS])]: () => ({ ...initialState }),
});

// __ delimeter beetwen namespace, reducer entityPrefix and corresponding action type
// example LIBRARY__FLOWS__FETCH_ITEMS_BEGIN
// should be used for ViewType.LIST (example FlowsOverview)
export const withFetchingEntityListItems = <
  T extends IFetchingItemsState = IFetchingItemsState,
  ActionType extends Action = Action,
>(
  namespace: string,
  entityPrefix: string,
  additionalHandlers: any = [],
) => {
  const handlers = () => ({
    ...additionalHandlers,
    ...commonHandles([namespace, entityPrefix]),
  });
  return createReducer<T, ActionType>(initialState as T, [handlers]);
};

// example LIBRARY__FLOWS__ITEMS__FETCH_ITEMS_BEGIN
export const withFetchingChildEntityListItems = <T extends object = {}>(
  namespace: string,
  mainEntityPrefix: string,
  childEntityPrefix: string,
) => {
  const handlers = () => ({
    ...commonHandles([namespace, mainEntityPrefix, childEntityPrefix]),
  });
  return createReducer<IFetchingItemsState<T>>(initialState as IFetchingItemsState<T>, [handlers]);
};
