import { cloneDeep } from "lodash";

import { type NotifyStepState } from "features/SystemNotifications/state/slices/notifyStepSlice";
import { type NotifyConfigEntry } from "features/SystemNotifications/types";
import { type CommunicationChannels } from "enums";

export const getUpdatedNoficationOptionsClone = <T>(config: T, notifyConfigEntry: NotifyConfigEntry<T>) => {
  const { key, value } = notifyConfigEntry;
  const newConfig = cloneDeep(config);
  // @ts-ignore
  newConfig[key] = value;
  return newConfig;
};

export const getNotificationSettings = (
  notifyStepState: NotifyStepState,
  notificationId: string,
  shouldNotify: boolean,
  communicationChannel: CommunicationChannels,
) => {
  return {
    id: notificationId,
    subject: notifyStepState.form.subject,
    previewText: notifyStepState.form.previewText,
    senderName: notifyStepState.form.customizeSender ? notifyStepState.form.senderName : "",
    senderEmail: notifyStepState.form.customizeSender ? notifyStepState.form.senderEmail : "",
    templateUri: notifyStepState.templateSasUrl,
    rawTemplateUri: notifyStepState.rawTemplateSasUrl,
    suppressNotification: !shouldNotify,
    overrideNotification: shouldNotify,
    notificationType: notifyStepState.templateType,

    communicationChannel: communicationChannel,
    headline: notifyStepState.teamsForm.headline,
    body: notifyStepState.teamsForm.body,
    imageUrl: notifyStepState.teamsForm.imageUrl,
    callToAction: notifyStepState.teamsForm.callToAction,
    destinationUrl: notifyStepState.teamsForm.destinationUrl,
  };
};
