import { type IQuestion } from "../../../../Common/models";
import { getAnswerDefaultData } from "./defaultAnswerProvider";
import {
  AssessmentAnswerType,
  AssessmentQuestionType,
  type MultipleChoiceQuestion,
  type Question,
  type SingleChoiceQuestion,
} from "../../../types/state";

const defaultQuestionData = ((): { [key in AssessmentQuestionType]: () => IQuestion<any> } => {
  const getMultipleChoiceTextQuestion = (): IQuestion<MultipleChoiceQuestion> => ({
    questionType: AssessmentQuestionType.MultipleChoiceTextQuestion,
    questionOrder: undefined,
    question: {
      requireAllCorrectAnswers: false,
      text: "",
    },
    answers: [
      getAnswerDefaultData(AssessmentAnswerType.MultipleText),
      getAnswerDefaultData(AssessmentAnswerType.MultipleText),
    ],
  });

  const getSingleChoiceTextQuestion = (): IQuestion<SingleChoiceQuestion> => ({
    questionType: AssessmentQuestionType.SingleChoiceTextQuestion,
    questionOrder: undefined,
    question: {
      correctAnswer: 0,
      text: "",
    },
    answers: [
      getAnswerDefaultData(AssessmentAnswerType.SingleText),
      getAnswerDefaultData(AssessmentAnswerType.SingleText),
    ],
  });

  return {
    [AssessmentQuestionType.MultipleChoiceTextQuestion]: getMultipleChoiceTextQuestion,
    [AssessmentQuestionType.SingleChoiceTextQuestion]: getSingleChoiceTextQuestion,
    /* istanbul ignore next */
    [AssessmentQuestionType.PictureQuestion]: () => {
      throw new Error("Not implemented exception");
    },
  };
})();

export const getQuestionDefaultData = (questionType?: AssessmentQuestionType): IQuestion<Question> =>
  defaultQuestionData[questionType ?? AssessmentQuestionType.MultipleChoiceTextQuestion]();
