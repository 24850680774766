import React from "react";
import { Icon, Placeholder } from "semantic-ui-react";
import CroppedThumbnail from "../../croppedThumbnail/CroppedThumbnail";

import "./removableCard.scss";

export interface RemovableCardProps {
  id: number;
  onRemoveItemClick?: (id: number, type?: string) => void;
  type?: string;
  title?: string;
  thumbnailUrl?: string;
  meta?: string;
  isReadOnly?: boolean;
}

const RemovableCard: React.FC<RemovableCardProps> = (props) => {
  const handleRemoveIconClick = () => {
    props.onRemoveItemClick?.(props.id, props.type);
  };

  return (
    <div key={props.id} className="removable-card">
      <div className="image">
        {props.thumbnailUrl ? (
          <CroppedThumbnail thumbnailUrl={props.thumbnailUrl} altText="removable card thumbnail" />
        ) : (
          <Placeholder data-testid="loading-card-thumbnail">
            <Placeholder.Image />
          </Placeholder>
        )}
      </div>
      <div className="content">
        <div className="title">
          {props.title || props.title === "" ? (
            <span data-testid="removable-card-title">{props.title}</span>
          ) : (
            <Placeholder data-testid="loading-card-data">
              <Placeholder.Header>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          )}
        </div>
        <div className="meta-text">{props.meta}</div>
      </div>
      {props.onRemoveItemClick && !props.isReadOnly && (
        <Icon
          fitted
          name="close"
          link
          onClick={handleRemoveIconClick}
          corner
          top="true"
          right="true"
          aria-label="remove-item"
          data-testid="remove-item"
        />
      )}
    </div>
  );
};

export default RemovableCard;
