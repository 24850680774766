import { SortingDirection } from "../../../../../enums";

export const DEFAULT_REQUEST_PARAMS = {
  skip: 0,
  top: 10,
  sortingColumnName: "firstName",
  sortingDirection: SortingDirection.Ascending,
  appliedFilter: {},
};

export const DEFAULT_SORTING_COLUMN_NAME = "added";
