import { type Group, type User } from "../../../../interfaces";
import { type Removable, type WithId } from "../../../../types";

interface EventPeople extends Removable, WithId {
  priorityLevelId: number;
  dueDate?: Date;
  permissionIssuedDate: Date;
  editable: boolean;
  inherited: boolean;
}

export interface EventUser extends User, EventPeople {
  userId: number;
  isCompleted: boolean;
}

export interface EventGroup extends Group, EventPeople {
  groupId: string;
}

export enum ScheduleType {
  None = 0,

  MinutesBefore,
  HoursBefore,
  DaysBefore,
}

export interface ReminderBase {
  title: string;
  description: string;
  scheduleType: ScheduleType;
  time: number;
}

export interface Reminder extends ReminderBase {
  id: number;
}
