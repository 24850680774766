import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withEntityState } from "../../../../../Application/reducers/hoc/withEntityState";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.ThreatDefenceLandingPages;

const threatDefenceLandingPageEntityStateReducer = withEntityState(namespace, entityPrefix);

export default threatDefenceLandingPageEntityStateReducer;
