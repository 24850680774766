import { type Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { useQuery } from "@tanstack/react-query";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ChartWrapper, HorizontalBarChart } from "components/charts";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { RouteNames } from "enums";
import { FeatureFlags } from "featureFlags";
import { type RootState } from "features/Application/globaltypes/redux";
import { dateRange30, getBarDomain, noBarData, noData } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useEffect, useMemo } from "react";
import { sharedAccountReportingHorizontalBarProps } from "../../Content/shared";
import { fetchEngagementQuery } from "../../Content/queries/useFlowLineChartQuery";
import { useEmailEngagementQuery } from "../../queries/sharedQueries";
import { selectCustomerCardsState, selectOverviewFormattedCardsData } from "../../state/packs/packSlice";
import { type ConnectedProps, connect } from "react-redux";
import * as dashboardReportingService from "../../services/dashboardReportingService";
import * as reportingActions from "../../state/packs/packActions";
import { TrendingAssets } from "./Trending/TrendingAssets";
import { TrendingFlows } from "./Trending/TrendingFlows";
import { FlowGoals } from "./FlowGoals/FlowGoals";

import styles from "./overviewReport.module.scss";
import { MoboLink } from "components/MoboLink/MoboLink";

const filter = dateRange30();

const fetchTrendingFlowsQuery = async () => {
  const result = (await dashboardReportingService.fetchTrendingFlowsData()).data.slice(0, 5);
  return result.map((item) => {
    return {
      ...item,
      id: item.FlowId,
    };
  });
};

const fetchTrendingAssetsQuery = async () => {
  const result = (await dashboardReportingService.fetchTrendingAssetsData()).data.slice(0, 5);
  return result.map((item) => {
    return {
      ...item,
      id: item.ContentId,
    };
  });
};

export const ReportingOverview = (props: PropsFromRedux) => {
  const { actions, customerCardsFormattedData, customerCardsState, customerId } = props;
  const isReportingOverviewEnabled = !!useFeatureFlag(FeatureFlags.ReportingOverview);

  const cards = useMemo(
    () =>
      customerCardsFormattedData.map((card) => {
        return { ...card, url: `/${RouteNames.reportingPacks}` };
      }),
    [customerCardsFormattedData],
  );

  useEffect(() => {
    if (isReportingOverviewEnabled) {
      actions.fetchCustomerCards(customerId);
    }
  }, [actions, customerId, isReportingOverviewEnabled]);

  const flowEngagementQuery = useQuery({
    queryKey: ["OverviewFlowReportEngagement", { ...filter, isDistinct: false }],
    queryFn: fetchEngagementQuery,
    enabled: isReportingOverviewEnabled,
  });

  const emailEngagementQuery = useEmailEngagementQuery({
    filter: { ...filter, isDistinct: false, type: "account" },
    enabled: isReportingOverviewEnabled,
  });

  const trendingFlowsQuery = useQuery({
    queryKey: ["TrendingFlows"],
    queryFn: fetchTrendingFlowsQuery,
    enabled: isReportingOverviewEnabled,
  });

  const trendingAssetsQuery = useQuery({
    queryKey: ["TrendingAssets"],
    queryFn: fetchTrendingAssetsQuery,
    enabled: isReportingOverviewEnabled,
  });

  const barDomain = useMemo(() => {
    if (flowEngagementQuery.isSuccess) {
      let maxValue = Math.max(...flowEngagementQuery.data.barData.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [flowEngagementQuery.data?.barData, flowEngagementQuery.isSuccess]);

  if (!isReportingOverviewEnabled) {
    return (
      <div className={styles.reportUnavailable}>
        <ReportUnavailable />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>Flows With Objectives</h2>
      </div>
      <FlowGoals filter={filter} />
      <div className={styles.packsHeader}>
        <h2>Packs & Licensing</h2>
        <MoboLink to={`../${RouteNames.packs}`}>More Details</MoboLink>
      </div>
      <div className={styles.cards}>
        <RequestStatusRenderer state={customerCardsState}>
          <CardReporting items={cards} />
        </RequestStatusRenderer>
      </div>
      <div className={styles.header}>
        <h2>Content</h2>
      </div>
      <div className={styles.charts}>
        <MoboLink to={`../${RouteNames.contentFlows}`}>
          <MoboLink to={`../${RouteNames.contentFlows}`} onClick={(e) => e.stopPropagation()}>
            More Details
          </MoboLink>
          <ChartWrapper titles={["Flow Engagement"]}>
            <RequestStatusRenderer state={flowEngagementQuery.status}>
              {flowEngagementQuery.isSuccess && !noBarData(...flowEngagementQuery.data.barData.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  domain={barDomain}
                  data={flowEngagementQuery.data.barData}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </MoboLink>
        <MoboLink to={`../${RouteNames.contentEmails}`}>
          <MoboLink to={`../${RouteNames.contentEmails}`} onClick={(e) => e.stopPropagation()}>
            More Details
          </MoboLink>
          <ChartWrapper titles={["Email Engagement"]}>
            <RequestStatusRenderer state={emailEngagementQuery.status}>
              {emailEngagementQuery.isSuccess &&
              !noBarData(
                emailEngagementQuery.data.raw.Sends,
                emailEngagementQuery.data.raw.Opens,
                emailEngagementQuery.data.raw.Clicks,
              ) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  data={emailEngagementQuery.data.barData}
                  domain={getBarDomain(emailEngagementQuery.data.barData)}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </MoboLink>
      </div>
      <div className={styles.trending}>
        <RequestStatusRenderer state={trendingFlowsQuery.status}>
          {trendingFlowsQuery.isSuccess && <TrendingFlows trendingFlows={trendingFlowsQuery.data} filter={filter} />}
        </RequestStatusRenderer>
        <RequestStatusRenderer state={trendingAssetsQuery.status}>
          {trendingAssetsQuery.isSuccess && (
            <TrendingAssets trendingAssets={trendingAssetsQuery.data} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  customerId: state.userProfile.accountId,
  customerCardsState: selectCustomerCardsState(state),
  customerCardsFormattedData: selectOverviewFormattedCardsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(reportingActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedComponent = connector(ReportingOverview);
export default ConnectedComponent;
