import * as events from "../events/library/libraryEvents";
import {
  publishDraftThreatDefenceSendingProfileSuccess,
  updateThreatDefenceSendingProfileCommandSuccess,
  threatDefenceSendingProfileUpdated,
  fetchLockedTdSendingProfileSuccess,
} from "../../../../Library/SimulatedPhishing/SendingProfiles/DirectSend/state/actions/threatDefenceSendingProfileEntityStateActions";
import { type IMapping } from "./types";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const sendingProfilesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.DirectSendSendingProfileUpdateSuccess] = dispatch(updateThreatDefenceSendingProfileCommandSuccess);
  mapping[events.DirectSendSendingProfileUpdateCommandCompleted] = dispatch(threatDefenceSendingProfileUpdated);

  mapping[events.DirectSendSendingProfilePublishSuccess] = dispatchPublishSuccess<{ id: number }>(
    publishDraftThreatDefenceSendingProfileSuccess,
    () => "Direct Send Sending Profile was published.",
  );

  mapping[events.DirectSendSendingProfileLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedTdSendingProfileSuccess,
    (message) => ({
      entityId: message.id,
    }),
  );
};
