import { type ReactNode } from "react";
import classnames from "classnames/bind";
import { ReactComponent as BsiLogo } from "images/BSI_Symbol.svg";
import styles from "./SpeechBubble.module.scss";
import { useSelector } from "react-redux";
import { type RootState } from "features/Application/globaltypes/redux";

const cx = classnames.bind(styles);

interface Props {
  children: ReactNode;
  from: "bot" | "user";
  botAnswer?: boolean;
  verticallyTransitioning?: boolean;
}

export const SpeechBubble = ({ botAnswer, children, from, verticallyTransitioning }: Props) => {
  const userProfile = useSelector((state: RootState) => state.userProfile);

  return (
    <div
      className={cx(styles.chat, {
        [styles.alt]: from === "user",
      })}
    >
      {from === "bot" ? (
        <BsiLogo className={styles.logo} />
      ) : (
        <span className={styles.userLogo}>{userProfile.name.slice(0, 1)?.toLocaleUpperCase()}</span>
      )}
      <div
        className={cx(styles.bubble, {
          [styles.alt]: from === "user",
          [styles.autoHeight]: !botAnswer || verticallyTransitioning,
        })}
      >
        <div className={styles.txt}>{children}</div>
        <div
          className={cx(styles.bubbleArrow, {
            [styles.alt]: from === "user",
          })}
        />
      </div>
    </div>
  );
};
