import { type FC } from "react";
import { TitleSubtitle } from "../listViewTemplates";
import GroupPicture from "../groupPicture/GroupPicture";
import GroupIcons from "../groupIcons/GroupIcons";
import { type Group } from "../../interfaces";
import { RouteNames } from "enums";

import "./groupInfoCell.scss";

export interface GroupInfoCellProps {
  group: Group;
  deepLink?: boolean;
}

const GroupInfoCell: FC<GroupInfoCellProps> = ({ group, deepLink }) => {
  return (
    <div className="name-column-value-container">
      <GroupPicture imageUrl={group.imageUrl} />
      <div className="group-title-column">
        <TitleSubtitle url={deepLink ? `/${RouteNames.peopleGroups}/${group.id}` : undefined} title={group.name} />
        <GroupIcons
          isAware={group.isAware}
          isOpen={group.isOpen}
          isVisibleToAll={group.isVisibleToAll}
          isWithAutoEnroll={group.isWithAutoEnroll}
        />
      </div>
    </div>
  );
};

export default GroupInfoCell;
