import { type FC } from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import { Tooltip } from "components/common/tooltip";
import { Icon } from "semantic-ui-react";
import { type ContentType, PeopleType } from "features/Library/PeopleAssignments/types";

type Context = "people" | "content";
export interface RemovePriorityButtonProps {
  isDisabled: boolean;
  showTooltip: boolean;
  onClick: () => void;
  multiple: boolean;
  context: Context;
  entityType: ContentType;
  peopleType: PeopleType;
}

const RemovePriorityButton: FC<RemovePriorityButtonProps> = (props: RemovePriorityButtonProps) => {
  const { isDisabled, multiple, entityType, peopleType, onClick, context, showTooltip } = props;
  const tooltip = showTooltip ? getTooltip(context, multiple, entityType, peopleType) : "";
  return (
    <Tooltip
      showAlways={tooltip}
      inverted
      hideOnScroll
      position={"bottom left"}
      target={
        <div style={{ display: "flex", alignItems: "center" }}>
          <BasicLinkButton
            iconClassName="fal fa-undo"
            text="Clear Priority"
            onClick={onClick}
            isDisabled={isDisabled}
          />
          {isDisabled && tooltip && <Icon className="fa fa-exclamation-circle" size="large" />}
        </div>
      }
      content={tooltip}
    />
  );
};

const getTooltip = (ctx: Context, multi: boolean, contentType: string, peopleType: PeopleType) => {
  if (multi) {
    if (peopleType === PeopleType.Group) {
      return "At least one of the selected groups has no priority assigned.";
    }
    const type = ctx === "content" ? "user" : contentType;
    return `At least one of the selected ${type}s has a group-defined priority set, or has no priority assigned.`;
  }
  if (peopleType === PeopleType.User) {
    return "User has a group-defined priority set, or has no priority assigned.";
  }
  return "No priority has been assigned.";
};

export default RemovePriorityButton;
