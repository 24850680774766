import { some } from "lodash";
import { type NotifyStepSettings, type PriorityLevel } from "../../../../../interfaces";
import { type AppDispatch, type AppThunk } from "../../../../Application/globaltypes/redux";
import { ContentType, PeopleType } from "../../../PeopleAssignments/types";
import {
  assignPeopleDistributed,
  editContentPeoplePriority,
  removePeoplePrioritiesDistributed,
} from "../../../PeopleAssignments/state/actions/commonActions";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { resetSelectedItems } from "../slices/flowUsersSlice";
import { type DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import backgroundTask, { type UpdateFeatureDistributedOpProps } from "../../../../BackgroundTasks/backgroundTask";
import { type AddOperationParamsDistributedOp } from "../../../../../interfaces/backgroundAddOperationParams";
import { addOperationDistributedOp } from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";

export const assignPeopleOnCreate =
  (
    priorityLevels: PriorityLevel[],
    useFlexibleDueDate: boolean,
    getNotifySettings?: () => Promise<NotifyStepSettings | undefined>,
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    const notificationSettings = await getNotifySettings?.();

    dispatch(
      assignPeopleDistributed(
        priorityLevels,
        ContentType.Flow,
        {
          [PeopleType.User]: [],
          [PeopleType.Group]: [],
        },
        useFlexibleDueDate,
        notificationSettings,
      ),
    );
  };

export const assignPeopleOnEdit =
  (
    priorityLevels: PriorityLevel[],
    ids: number[],
    peopleType: PeopleType,
    useFlexibleDueDate: boolean,
    notificationSettings?: NotifyStepSettings,
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!some(priorityLevels)) {
      return;
    }

    dispatch(
      assignPeopleDistributed(
        priorityLevels,
        ContentType.Flow,
        { [peopleType]: ids },
        useFlexibleDueDate,
        notificationSettings,
      ),
    );
  };

export const removePeoplePrioritiesAction =
  (contentIds: number[], people: { [key in PeopleType]?: number[] }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(removePeoplePrioritiesDistributed(contentIds, ContentType.Flow, people));
  };

export const editPriority =
  (priorityLevels: PriorityLevel[], peopleType: PeopleType, contentId: number, useFlexibleDueDate: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(editContentPeoplePriority(priorityLevels, ContentType.Flow, contentId, peopleType, useFlexibleDueDate));
  };

export const startFlowAction = (flowId: number, ids: number[]): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedItems());

    const params: DistributedOpUpdateParams = {
      id: `StartFlow_${new Date()}`,
      title: `Start Flow`,
      getOperationProps: async () => (await DataService.flows.startFlow(flowId, ids)).data,
      successTransientMessage: "Flow has been started!",
      failureTransientMessage: "Flow starting failed!",
    };
    await initializeDistributedBackgroundTask(params, dispatch);
  };
};

export const startGroupFlowAction = (flowId: number, ids: number[]): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(resetSelectedItems());

    const params: DistributedOpUpdateParams = {
      id: `StartFlow_${new Date()}`,
      title: `Start Flow`,
      getOperationProps: async () => (await DataService.flows.startGroupFlow(flowId, ids)).data,
      successTransientMessage: "Flow has been started!",
      failureTransientMessage: "Flow starting failed!",
    };
    await initializeDistributedBackgroundTask(params, dispatch);
  };
};

/* istanbul ignore next */
const initializeDistributedBackgroundTask = async (params: DistributedOpUpdateParams, dispatch: AppDispatch) => {
  const updateFeatureProps: UpdateFeatureDistributedOpProps = {
    addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
      dispatch(addOperationDistributedOp(addOperationParams)),
    sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
  };

  await backgroundTask.updateEntityDistributedOp(params, updateFeatureProps);
};
