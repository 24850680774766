import * as actionTypes from "../actionTypes/packDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/packEntityStateActionType";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { type Pack, type PackView } from "../../types/state";
import { type GetPackDetailsResponse, type PackItems } from "../../types/requests";
import { type Dispatcher, type MidnightActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { type DraggableAsset, type DroppedItem } from "../../../../../interfaces/assetToDropInfo";
import { updatePackCommandSuccess, setPackEntityId } from "./packEntityStateActions";
import { type RootState } from "../../../../Application/globaltypes/redux";
import AssetTypes from "../../../../../enums/assetTypes";
import ItemTypes from "../../../../../enums/packItemsTypes";
import { type AxiosResponse } from "axios";
import EventTypes from "../../../../../enums/eventTypes";
import CommunicationTypes from "../../../../../enums/communicationTypes";
import {
  PackContentEditSuccess,
  EditPackIsPrivateSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";

export const savePack = (packMetadata: PackView) => {
  const savePackAction = getActionProvider<PackView>(actionTypes.savePack);

  return (dispatch: Dispatcher) => {
    dispatch(savePackAction(packMetadata));
  };
};

export const clearPack = () => {
  const resetPackAction = getActionBaseProvider(actionTypes.clearPack);

  return (dispatch: Dispatcher) => {
    dispatch(resetPackAction());
  };
};

export const getPack = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getPackBegin);
  const success = getActionProvider<Pack>(actionTypes.getPackSuccess);
  const failure = getActionProvider<Error>(actionTypes.getPackFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const result = await dataService.packs.getPackAsync(id);
      dispatch(success(result.data));
      dispatch(setPackEntityId(result.data.id || 0));
      dispatch(updatePackCommandSuccess({ id: result.data.id || 0 }));
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const getPackIsPrivate = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getPackIsPrivateBegin);
  const success = getActionProvider<boolean>(actionTypes.getPackIsPrivateSuccess);
  const failure = getActionProvider<Error>(actionTypes.getPackIsPrivateFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const result = await dataService.packs.getPackIsPrivate(id);
      dispatch(success(result));
      return result;
    } catch (error: any) {
      dispatch(failure(error));
      return false;
    }
  };
};

export const updatePackIsPrivate = (id: number, isPrivate: boolean) => {
  const begin = getActionBaseProvider(entityStateActionTypes.updatePackBegin);
  const failure = getActionProvider<MidnightActionPayload>(entityStateActionTypes.updatePackFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: EditPackIsPrivateSuccess }));

    try {
      await dataService.packs.updatePackIsPrivateAsync(id, { isPrivate: isPrivate });
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const getPackContent = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getPackContentBegin);
  const success = getActionProvider<GetPackDetailsResponse>(actionTypes.getPackContentSuccess);
  const failure = getActionProvider<Error>(actionTypes.getPackContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const result = await dataService.packs.getPackContentAsync(id);
      dispatch(success(result.data));
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

const getItemDetails = (
  type: string,
  items: DroppedItem[],
  getData: (ids: string[], showPurchased: boolean) => Promise<AxiosResponse<any>>,
  beginAction: string,
  successAction: string,
  failureAction: string,
) => {
  const begin = getActionBaseProvider(beginAction);
  const success = getActionProvider<DroppedItem[]>(successAction);
  const failure = getActionProvider<Error>(failureAction);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const ids = items?.map((a: DroppedItem) => a.id.toString());
      const result = await getData(ids, false);
      dispatch(
        success(
          result.data.map((f: DroppedItem) => {
            f.type = type;
            f.isReadOnly = items.find((item) => item.id === f.id)?.isReadOnly;
            return f;
          }),
        ),
      );
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const getPdfsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    AssetTypes.Pdf,
    items,
    dataService.pdfs.getPublishedPdfsByIdsAsync,
    actionTypes.getPdfsDetailsBegin,
    actionTypes.getPdfsDetailsSuccess,
    actionTypes.getPdfsDetailsFailure,
  );
};

export const getAssetsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    AssetTypes.Video,
    items,
    dataService.assets.getPublishedVideosByIds,
    actionTypes.getAssetsDetailsBegin,
    actionTypes.getAssetsDetailsSuccess,
    actionTypes.getAssetsDetailsFailure,
  );
};

export const getSurveysDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    AssetTypes.Survey,
    items,
    dataService.surveys.getPublishedSurveysByIds,
    actionTypes.getSurveysDetailsBegin,
    actionTypes.getSurveysDetailsSuccess,
    actionTypes.getSurveysDetailsFailure,
  );
};

export const getAssessmentsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    AssetTypes.Assessment,
    items,
    dataService.assessments.getPublishedAssessmentsByIdsV2,
    actionTypes.getAssessmentsDetailsBegin,
    actionTypes.getAssessmentsDetailsSuccess,
    actionTypes.getAssessmentsDetailsFailure,
  );
};

export const getFlowsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    ItemTypes.Flow,
    items,
    dataService.flows.getPublishedFlowsByIdsForItemsToDropDesignerAsync,
    actionTypes.getFlowsDetailsBegin,
    actionTypes.getFlowsDetailsSuccess,
    actionTypes.getFlowsDetailsFailure,
  );
};

export const getEventsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    EventTypes.ExternalEvent,
    items,
    dataService.events.getPublishedEventsByIdsAsync,
    actionTypes.getEventsDetailsBegin,
    actionTypes.getEventsDetailsSuccess,
    actionTypes.getEventsDetailsFailure,
  );
};

export const getEmailsDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    CommunicationTypes.Email,
    items,
    dataService.emails.getPublishedEmailsByIdsAsync,
    actionTypes.getEmailsDetailsBegin,
    actionTypes.getEmailsDetailsSuccess,
    actionTypes.getEmailsDetailsFailure,
  );
};

export const getMessagesDetails = (items: DroppedItem[]) => {
  return getItemDetails(
    CommunicationTypes.Message,
    items,
    dataService.messages.getPublishedMessagesByIds,
    actionTypes.getMessagesDetailsBegin,
    actionTypes.getMessagesDetailsSuccess,
    actionTypes.getMessagesDetailsFailure,
  );
};

export const getPackContentWithDetails = (id: number) => {
  return async (dispatch: Function, getState: Function) => {
    await dispatch(getPackContent(id));
    await dispatch(
      getPackContentDetails(
        getState().packs.packDetailsReducer.pack.items.slice(0, Number(process.env.REACT_APP_LOAD_ITEMS_COUNT)),
      ),
    );
  };
};

export const updatePackDisplayItemsCount = (count: number) => {
  const action = getActionProvider<number>(actionTypes.updatePackDisplayItemsCount);

  return (dispatch: Function) => {
    dispatch(action(count));
  };
};

export const getPackContentDetails = (items: DroppedItem[]) => {
  return (dispatch: Function) => {
    const tasks: Promise<unknown>[] = [];
    const getPackItemsByType = (type: string): DroppedItem[] => {
      return items.filter((a: DroppedItem) => {
        return a.type && a.type === type;
      });
    };

    const pdfs = getPackItemsByType(AssetTypes.Pdf);
    pdfs.length && tasks.push(dispatch(getPdfsDetails(pdfs)));

    const assets = getPackItemsByType(AssetTypes.Video);
    assets.length && tasks.push(dispatch(getAssetsDetails(assets)));

    const surveys = getPackItemsByType(AssetTypes.Survey);
    surveys.length && tasks.push(dispatch(getSurveysDetails(surveys)));

    const assessments = getPackItemsByType(AssetTypes.Assessment);
    assessments.length && tasks.push(dispatch(getAssessmentsDetails(assessments)));

    const flows = getPackItemsByType(ItemTypes.Flow);
    flows.length && tasks.push(dispatch(getFlowsDetails(flows)));

    const events = getPackItemsByType(EventTypes.ExternalEvent);
    events.length && tasks.push(dispatch(getEventsDetails(events)));

    const emails = getPackItemsByType(CommunicationTypes.Email);
    emails.length && tasks.push(dispatch(getEmailsDetails(emails)));

    const messages = getPackItemsByType(CommunicationTypes.Message);
    messages.length && tasks.push(dispatch(getMessagesDetails(messages)));

    return Promise.all(tasks);
  };
};

export const updatePackContent = (id: number, packContent: PackItems) => {
  const begin = getActionBaseProvider(entityStateActionTypes.updatePackBegin);
  const failure = getActionProvider<MidnightActionPayload>(entityStateActionTypes.updatePackFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: PackContentEditSuccess }));

    try {
      await dataService.packs.updatePackContentAsync(id, { packItems: packContent });
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const addItemToPack = (assets: DroppedItem[]) => {
  const addAssetAction = getActionProvider<DroppedItem[]>(actionTypes.addItemToPack);

  return (dispatch: Dispatcher) => {
    dispatch(addAssetAction(assets));
  };
};

const getPackContentFromState = (state: RootState): PackItems =>
  state.packs.packDetailsReducer.pack.items.reduce(function (packItems: PackItems, item) {
    let key = item.type || "";

    if (!packItems[key]) {
      packItems[key] = [item.id];
    } else {
      packItems[key].push(item.id);
    }

    return packItems;
  }, {});

export const addItemToPackAndUpdate = (packId: number, assets: DroppedItem[]) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(addItemToPack(assets));
    dispatch(updatePackContent(packId, getPackContentFromState(getState())));
  };
};

export const removeFromPackAndUpdate = (packId: number, id: number, type?: string) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(removeItemFromPack(id, type));
    dispatch(updatePackContent(packId, getPackContentFromState(getState()))).then(() => {
      const pack = getState().packs.packDetailsReducer.pack;
      dispatch(getPackContentDetails(pack.items.slice(pack.displayItemsCount, pack.displayItemsCount + 1)));
    });
  };
};

export const removeItemFromPack = (id: number, type?: string) => {
  const removeAssetAction = getActionProvider<{ id: number; type?: string }>(actionTypes.removeItemFromPack);

  return (dispatch: Dispatcher) => {
    dispatch(removeAssetAction({ id, type }));
  };
};

export const addSelectedCards = (cards: DraggableAsset[]) => {
  const addSelectedCardAction = getActionProvider<DraggableAsset[]>(actionTypes.addSelectedCards);

  return (dispatch: Dispatcher) => {
    dispatch(addSelectedCardAction(cards));
  };
};

export const removeSelectedCard = (id: number) => {
  const removeSelectedCardAction = getActionProvider<number>(actionTypes.removeSelectedCard);

  return (dispatch: Dispatcher) => {
    dispatch(removeSelectedCardAction(id));
  };
};

export const clearSelectedCards = () => {
  const clearSelectedCardsAction = getActionBaseProvider(actionTypes.clearSelectedCards);

  return (dispatch: Dispatcher) => {
    dispatch(clearSelectedCardsAction());
  };
};

export const setPackAction = getActionProvider<Pack>(actionTypes.setPack);
