import { Formik } from "formik";
import PropTypes from "prop-types";
import { Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import PreventTransitionPrompt from "../preventTransition/PreventTransitionPrompt";
import CheckboxField from "./CheckboxField";
import DropdownField from "./DropdownField";
import { ImagePreview } from "./ImagePreview";
import InputField from "./InputField";
import { MultiSelectField } from "./MultiSelectField";
import MultiSelectWithAdditionField from "./MultiSelectWithAdditionField";
import PasswordField from "./PasswordField";
import RadioGroupField from "./RadioGroupField";
import TextAreaField from "./TextAreaField";
import TimePickerField from "./TimePickerField";
import UploadFileField from "./UploadFileField";
import WeekDayPickerField from "./WeekDayPickerField";
import InputFieldExperimental from "./experimentalValidatedInput/InputFieldExperimental";
import ThumbnailPreview from "./thumbnailPreview/thumbnailPreview";

import DatePickerField from "./DatePickerField";
import "./validatedForm.scss";

function ValidatedForm(props) {
  const {
    initialValues,
    onSubmit,
    validationSchema,
    isSubmitting,
    isLoading,
    children,
    className,
    bindSubmitForm,
    bindGetValues,
    bindFormReset,
    style,
    formWidthComputer,
    unsavedChangesPrompt,
    validationChanged,
    disablePreventTransitionPrompt,
    validateOnMount,
    enableReinitialize,
  } = props;

  const renderPreventTransitionPrompt = (dirty) => {
    let isDirty = dirty;
    if (disablePreventTransitionPrompt) {
      isDirty = false;
    }

    return (
      <PreventTransitionPrompt
        when={isDirty}
        title={unsavedChangesPrompt.title}
        message={unsavedChangesPrompt.message}
      />
    );
  };

  const onSubmitHandler = (values, formikBag) => {
    onSubmit(values, formikBag);
  };

  const renderFormBody = (formProps) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      submitForm,
      setFieldValue,
      isValid,
      setFieldTouched,
      resetForm,
      dirty,
      setValues,
    } = formProps;

    const validatedFieldProps = {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
      setValues,
    };

    if (validationChanged) {
      validationChanged(isValid);
    }

    // to be able to submit form from outside of the component
    bindSubmitForm && bindSubmitForm(submitForm);
    bindGetValues && bindGetValues(() => values);
    bindFormReset && bindFormReset(resetForm);

    return (
      <Dimmer.Dimmable dimmed={isSubmitting || isLoading} className={className} style={style}>
        <Dimmer active={isSubmitting || isLoading} inverted>
          <Loader active={isLoading} />
        </Dimmer>
        <Form className="validated-form" onSubmit={handleSubmit} as={Grid}>
          <Grid.Row>
            <Grid.Column computer={formWidthComputer} tablet={12} mobile={16}>
              {children({ validatedFieldProps, values })}
            </Grid.Column>
          </Grid.Row>
        </Form>
        {renderPreventTransitionPrompt(dirty)}
      </Dimmer.Dimmable>
    );
  };

  return props.parentWithFormik ? (
    renderFormBody(props)
  ) : (
    <Formik
      initialValues={initialValues}
      isInitialValid={props.hasOwnProperty("isInitialValid") ? props.isInitialValid : false}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      enableReinitialize={enableReinitialize}
    >
      {(formProps) => renderFormBody(formProps)}
    </Formik>
  );
}

ValidatedForm.InputField = InputField;
// TO-DO: replace the original input with or remove
ValidatedForm.InputFieldExperimental = InputFieldExperimental;
ValidatedForm.TextAreaField = TextAreaField;
ValidatedForm.DropdownField = DropdownField;
ValidatedForm.PasswordField = PasswordField;
ValidatedForm.CheckboxField = CheckboxField;
ValidatedForm.UploadFileField = UploadFileField;
ValidatedForm.ImagePreview = ImagePreview;
ValidatedForm.ThumbnailPreview = ThumbnailPreview;
ValidatedForm.DatePickerField = DatePickerField;
ValidatedForm.MultiSelectWithAdditionField = MultiSelectWithAdditionField;
ValidatedForm.MultiSelectField = MultiSelectField;
ValidatedForm.TimePickerField = TimePickerField;
ValidatedForm.WeekDayPickerField = WeekDayPickerField;
ValidatedForm.RadioGroupField = RadioGroupField;

ValidatedForm.defaultProps = {
  unsavedChangesPrompt: {
    title: "Unsaved changes",
    message: "Leave now and lose all of the amazing changes?",
  },
  formWidthComputer: 8,
  disablePreventTransitionPrompt: false,
  parentWithFormik: false,
};

ValidatedForm.propTypes = {
  isSubmitting: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.object,
  bindSubmitForm: PropTypes.func,
  bindGetValues: PropTypes.func,
  formWidthComputer: PropTypes.number,
  unsavedChangesPrompt: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  style: PropTypes.object,
  isInitialValid: PropTypes.bool,
  disablePreventTransitionPrompt: PropTypes.bool,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  parentWithFormik: PropTypes.bool,
  validateOnMount: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  bindFormReset: PropTypes.func,
};

export default ValidatedForm;
