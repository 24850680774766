import React, { type FC } from "react";

import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { type FilterFormPropsBase } from "../types/props";
import { getAssessmentFilters } from "./assessmentFilters";
import { type AssessmentFiltersEnum } from "../../../features/Library/Assessments/types/state";

export interface AssessmentFilterFormProps extends FilterFormPropsBase<AssessmentFiltersEnum> {}

const AssessmentFilterForm: FC<AssessmentFilterFormProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getAssessmentFilters()}
  />
);

export default AssessmentFilterForm;
