import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LicensingModalState {
  isLoading: boolean;
  error: Error | undefined;
}

const initialState: LicensingModalState = {
  isLoading: false,
  error: undefined,
};
const licensingModalSlice = createSlice({
  name: "licensingModal",
  initialState,
  reducers: {
    req(state: LicensingModalState) {
      state.isLoading = true;
    },
    got(state: LicensingModalState) {
      state.isLoading = false;
    },
    err(state: LicensingModalState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { req, got, err, reset } = licensingModalSlice.actions;

export default licensingModalSlice.reducer;

export type LicensingModalSliceType = ReturnType<typeof licensingModalSlice.reducer>;
