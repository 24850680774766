import { debounce } from "lodash";
import { type InputOnChangeData } from "semantic-ui-react";
import { defaultSectionHeader } from "../../constants";
import { useCallback, useEffect, useState } from "react";
import { type FlowSectionHeader } from "../../types";
import { useReactFlow } from "reactflow";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider/hooks/useReactFlowCanvasActions";

const SAVE_DELAY = 700;

const headerSetter = (
  setter: (id: string, header: FlowSectionHeader) => void,
  header: FlowSectionHeader,
  sectionId: string,
): void => setter(sectionId, header);

const debouncedHeaderSetter = debounce(headerSetter, SAVE_DELAY, { leading: false, trailing: true });

export const useSectionHeader = (sectionHeaderId: string | undefined) => {
  const { setSectionHeader } = useReactFlowCanvasActions();
  const { getNode } = useReactFlow();
  const [headerName, setHeaderName] = useState(defaultSectionHeader.name);
  const [headerDescription, setHeaderDescription] = useState(defaultSectionHeader.description);

  const currentNode = sectionHeaderId ? getNode(sectionHeaderId) : undefined;
  const sectionHeader = currentNode?.data.sectionHeader ? currentNode?.data.sectionHeader : defaultSectionHeader;

  const onTitleChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, data?: InputOnChangeData) => {
      if (!sectionHeaderId) {
        return;
      }
      const newName = data ? data.value : _e.target.value;
      setHeaderName(newName);
      debouncedHeaderSetter(setSectionHeader, { name: newName, description: headerDescription }, sectionHeaderId);
    },
    [sectionHeaderId, setSectionHeader, headerDescription],
  );

  const onDescriptionChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, data?: InputOnChangeData) => {
      if (!sectionHeaderId) {
        return;
      }
      const newDescription = data ? data.value : _e.target.value;
      setHeaderDescription(newDescription);
      debouncedHeaderSetter(setSectionHeader, { name: headerName, description: newDescription }, sectionHeaderId);
    },
    [sectionHeaderId, setSectionHeader, headerName],
  );

  useEffect(() => {
    setHeaderName(sectionHeader.name);
    setHeaderDescription(sectionHeader.description);
    return () => {
      debouncedHeaderSetter.flush();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionHeaderId]);

  return { sectionHeader, onTitleChange, onDescriptionChange };
};
