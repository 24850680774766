import { type SortingDirection } from "enums";
import { type AppThunk } from "features/Application/globaltypes/redux";
import DataService from "features/Application/services/dataServices/typedDataService";
import { fetchBegin, fetchSuccess } from "../TemplateAvailableCustomersSlice";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { type UpdateParams } from "interfaces";

export const getAvailableCustomers =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection, templateId?: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getAvailableCustomers({
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.availableCustomers.search,
        templateId: templateId,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const saveCustomers =
  (templateId: number): AppThunk =>
  async (dispatch, getState) => {
    const customerIds = getState().people.groupTemplate.availableCustomers.customersToAdd;
    if (customerIds.length === 0) return;

    const multi = customerIds.length > 1;
    try {
      const updateParams: UpdateParams = {
        id: "AddTemplateCustomers",
        title: `Adding customers`,
        getMessageIds: async () => {
          const { data } = await DataService.groupTemplates.saveCustomers(templateId, customerIds);
          return data.messageIds;
        },
        successTransientMessage: `${multi ? "Customers have" : "Customer has"} been added to this template successfully.`,
        successTitle: `${multi ? "Customers" : "Customer"} added!`,
        failureTransientMessage: `Adding customers failed!`,
      };

      await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
    } catch {}
  };
