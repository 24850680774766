import { type FC, useMemo } from "react";
import cn from "classnames";

import { EditPriorityLinkButton } from "../../../buttons/linkButtons";
import { ContentType, type PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { type IFlowAssignmentModelItem } from "../../../../features/People/types";
import { type FlowCardBaseProps } from "../../../cards/FlowCard/types";

import getCardPriorityIcon from "../../priority/getCardPriorityIcon";
import PublishStatusCard from "../../../cards/PublishStatusCard";
import { RouteNames } from "enums";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";

import "./peopleFlowCard.scss";

export interface PeopleFlowCardProps extends FlowCardBaseProps<IFlowAssignmentModelItem> {
  peopleType: PeopleType;
  onRemove?: (id: number) => void;
  onEditPriority?: (id: number) => void;
  hasDeepLink?: boolean;
}

const PeopleFlowCard: FC<PeopleFlowCardProps> = (props: PeopleFlowCardProps) => {
  const { onSelect, onRemove, onEditPriority, item, selected, disabled, peopleType, disablePopupMenu, hasDeepLink } =
    props;
  const { id, published, priorityLevelId, dueDate, daysToComplete, isEditable, canRemove, goals } = item;

  const flowCardMenuButtons = useMemo(() => {
    const buttons = [];
    if (onEditPriority) {
      buttons.push(
        <EditPriorityLinkButton
          key="edit-priority-menu-button"
          onClick={() => onEditPriority?.(id)}
          isDisabled={!isEditable}
        />,
      );
    }
    if (onRemove) {
      buttons.push(
        <RemovePriorityButton
          onClick={() => onRemove?.(id)}
          isDisabled={!canRemove || !isEditable}
          peopleType={peopleType}
          context="people"
          entityType={ContentType.Flow}
          multiple={false}
          showTooltip={!canRemove}
        />,
      );
    }
    return buttons;
  }, [onEditPriority, onRemove, isEditable, id, peopleType, canRemove]);

  const icons = [
    getCardPriorityIcon({
      peopleType: peopleType,
      contentType: ContentType.Flow,
      priority: priorityLevelId,
      dueDate: dueDate,
      daysToComplete: daysToComplete,
      tooltipClass: "people-flow-priority-tooltip",
      showNone: false,
    }),
  ];

  return (
    <PublishStatusCard
      {...item}
      id={id}
      url={hasDeepLink ? `/${RouteNames.contentFlows}/${id}` : undefined}
      className={cn("people-flow-card")}
      onSelectionChanged={onSelect}
      popupMenuButtons={flowCardMenuButtons}
      disablePopupMenu={selected || disablePopupMenu}
      published={published}
      icons={icons}
      selected={selected}
      disabled={disabled}
      thumbnailContent={<ObjectiveLabel objective={goals?.objective} />}
    />
  );
};

export default PeopleFlowCard;
