import { type FC } from "react";
import { Modal as SemanticModal } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import cn from "classnames";
import ModalHeader from "../header/ModalHeader";
import "./confirmationModalBase.scss";
import { type ConfirmationModalBaseProps } from "./types";

const ConfirmationModalBase: FC<ConfirmationModalBaseProps> = (props: ConfirmationModalBaseProps) => {
  const { title, banner, cancelButtonLabel, confirmButtonLabel, isOpen, disabled, ...functionsFromProps } = props;
  const { onClose, onCancel, onConfirm, ...otherProps } = functionsFromProps;
  const { size, scrolling, content, actionButtons, styles, classNames } = otherProps;

  const modalClassNames = cn("modal-wrapper", "confirmation-modal-base", styles?.root, classNames);
  return (
    <SemanticModal role="dialog" open={isOpen} className={cn(modalClassNames)} size={size}>
      <SemanticModal.Header>
        <ModalHeader title={title} onClose={onClose} />
      </SemanticModal.Header>
      {banner}
      <SemanticModal.Content className={cn(styles?.content)} scrolling={scrolling ?? true}>
        {content}
      </SemanticModal.Content>
      <SemanticModal.Actions>
        {actionButtons || (
          <>
            <Button
              className={"cancel"}
              onClick={onCancel}
              basic
              color="blue"
              content={cancelButtonLabel || "Cancel"}
            />
            <Button
              className={"ok"}
              onClick={onConfirm}
              primary
              disabled={disabled}
              content={confirmButtonLabel || "OK"}
            />
          </>
        )}
      </SemanticModal.Actions>
    </SemanticModal>
  );
};

export default ConfirmationModalBase;
