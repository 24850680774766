import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { createFetchingItemsSlice, type FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { type LicenseConfirmationItem } from "../../../../../interfaces";

export interface LicenseConfirmationState extends FetchingItemsState<LicenseConfirmationItem> {}

export const initialState: LicenseConfirmationState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const licenseConfirmationSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AvailableLicenses,
    name: "availableLicenses",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = licenseConfirmationSlice.actions;

export default licenseConfirmationSlice.reducer;
